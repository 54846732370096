/*
 * React
 */
import React, { useContext, useEffect, useState } from "react";
import "./change-modal.css";
import Modal from "@material-ui/core/Modal";
import {
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import MultiSelect from "react-multi-select-component";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HeaderApi from "../../services/header-apis";
import jwt_decode from "jwt-decode";
import { StateContext } from "../../../../services/StateProvider";
import Globalization from "../../../../globalization";
import { useLocation ,useHistory} from "react-router-dom";
import CommonUtils from "../../../../services/utils";

const ChangeModal = (props) => {
  const commonUtils = new CommonUtils();

  const decoded = jwt_decode(
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
    )
  );
  const globalizationMapping = new Globalization();
  // stores essential Country data
  const [countryList, setCountryList] = useState([]);
  // stores essential PARENT ACCOUNT data
  const [parentAccountList, setParentAccountList] = useState([]);
  const [globalAccountState, globalAccountDispatch] = useContext(StateContext);
  // stores essential PARENT ACCOUNT data
  const [originalParentAccountList, setOriginalParentAccountList] = useState([]);
  // stores essential LOCATIONS data
  const [locationList, setLocationList] = useState([]);
  // stores LOCATIONS list multi-select dropdown
  const [multiSelectLocationList, setMultiSelectLocationList] = useState([]);
  // show/hide spinner variable
  const [showSpinner, setShowSpinner] = useState(false);
  // show/hide spinner variable
  const [showOwn, setShowOwn] = useState(true);
  // show/hide spinner variable
  const [showShared, setShowShared] = useState(true);
  const locationPath = useLocation();
  let history = useHistory();
  // PARENT ACCOUNT SELECTED
  const [parentAccount, setParentAccount] = useState(
    {accountName: globalAccountState.accountInfo.accountName ? globalAccountState.accountInfo.accountName : props.accountInfo.account,
      createdBy: "",
      parentAccountId: globalAccountState.accountInfo.accountId}
  );
  const initialAccountName = globalAccountState.accountInfo.accountName ? globalAccountState.accountInfo.accountName : props.accountInfo.account;
  // LOCATIONS SELECTED in location & setLocation handles onChange of multi-select
  const [location, setLocation] = useState(props.accountInfo.location);


  const compareStrings = (a, b) => {
    // Assuming you want case-insensitive comparison
    a = a.trim();
    b = b.trim();
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a < b) {
      return -1;
    } else {
      return a > b ? 1 : 0;
    }
  };

  const checkParentAccountFromList = (parentAccList) => {
    parentAccList.find(item => {
      if (item.accountName === initialAccountName) {
        setParentAccount({accountName: item.accountName,
          createdBy: item.createdBy,
          parentAccountId: item.parentAccountId})
      }}
    )
  }

  // used under useEffect and handleCountry Change
  const setParentAcctListFromResponseData = (responseData) => {
    let parentAccountListObject = [];
    const model = responseData.model;
    model.sort(function (a, b) {
      return compareStrings(a.accountName, b.accountName);
    });
    for (const item of model) {
      parentAccountListObject.push({
        accountName: item.accountName,
        createdBy: item.createdBy,
        parentAccountId: item.parentAccountId,
      });
    }
    setParentAccountList(parentAccountListObject);
    setOriginalParentAccountList(parentAccountListObject);
    checkParentAccountFromList(parentAccountListObject);
  }

  useEffect(() => {
    const locationListObject = [];
    const headerApi = new HeaderApi();
    if (!countryList.length) {
      setShowSpinner(true);
      headerApi.getCountryList().then((data) => {
        const model = data.model;
        model.sort(function (a, b) {
          return compareStrings(a.countryName, b.countryName);
        });
        model.splice(0, 0, { countryName: "--Select--", countryId: "" });
        setCountryList(model);
      });
    }
    setShowSpinner(false);
    if (!parentAccountList.length) {
      headerApi.getParentAccountList().then((data) => {
        setParentAcctListFromResponseData(data);
      });
    }
    if (!locationList.length) {
      headerApi.getLocationList().then((data) => {
        setShowSpinner(false);

        for (const item of data.model) {
          locationListObject.push({
            locationId: item.locationId,
            locationName: item.locationName,
            parentAccountId: item.parentAccountId,
            createdBy: item.createdBy,
          });
        }
        setLocationList(locationListObject);
      });
    }

    const initialAccountState = globalAccountState.accountInfo;
    if (initialAccountState.isUpdated === true) {
      initialAccountState.isUpdated = false;
      globalAccountDispatch({
        accountInfo: initialAccountState,
        type: "UPDATE",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);
  // update location based on parent account
  const updateLocationList = (selectedParentAccountId) => {
    setLocation([]);
    const updatedMultiSelectLocationObject = [];
    for (const item of locationList) {
      if (item.parentAccountId === selectedParentAccountId) {
        updatedMultiSelectLocationObject.push({
          value: item.locationId,
          label: item.locationName,
        });
      }
    }
    return updatedMultiSelectLocationObject;
  };
  // handles onChange event the ACCOUNT selected from dropdown
  const handleAccountChange = (_event, selectedOption) => {
    if (selectedOption) {
      setParentAccount({
        accountName: selectedOption.accountName,
        accountId: selectedOption.parentAccountId,
      });
      const headerApi = new HeaderApi();
      headerApi
        .getParentAccountDetails(selectedOption.parentAccountId)
        .then((_data) => { console.log('API call successful')});
      const updatedMultiSelectLocationObject = updateLocationList(
        selectedOption.parentAccountId
      );
      setMultiSelectLocationList(updatedMultiSelectLocationObject);
      setLocation([]);
    } else {
      setMultiSelectLocationList([]);
      setLocation([]);
    }
  };
  const handleCountryChange = (_event, selectedOption) => {
    //setShowSpinner(true)
    const headerApi = new HeaderApi();
    if (selectedOption && selectedOption.countryId !== "") {
      headerApi.getParentAccountListByCountry(selectedOption.countryId).then((data) => {
        setParentAcctListFromResponseData(data);
        });
    } else {
      headerApi.getParentAccountList().then((data) => {
        setParentAcctListFromResponseData(data);
      });
    }
  };
  const handleAccountTypeChange = (indicator) => {
    const parentAccounts = [];
    switch (indicator) {
      case "Own":
        if (!showOwn) {
          if (showShared) {
            setParentAccountList(originalParentAccountList);
          } else {
            Object.values(originalParentAccountList).forEach((val) => {
              if (val.createdBy === decoded.sub) {
                parentAccounts.push(val);
              }
              return true;
            });
            setParentAccountList(parentAccounts);
          }
        } else {
          if (showShared) {
            Object.values(originalParentAccountList).forEach((val) => {
              if (val.createdBy !== decoded.sub) {
                parentAccounts.push(val);
              }
              return true;
            });
            setParentAccountList(parentAccounts);
          } else {
            setParentAccountList([]);
          }
        }
        setShowOwn(!showOwn);
        break;

      case "Shared":
        switch (!showShared) {
          case true:
            if (showOwn) {
              setParentAccountList(originalParentAccountList);
            } else {
              Object.values(originalParentAccountList).forEach((val) => {
                if (val.createdBy !== decoded.sub) {
                  parentAccounts.push(val);
                }
                return true;
              });
              setParentAccountList(parentAccounts);
            }
            break;

          case false:
            if (showOwn) {
              Object.values(originalParentAccountList).forEach((val) => {
                if (val.createdBy === decoded.sub) {
                  parentAccounts.push(val);
                }
                return true;
              });
              setParentAccountList(parentAccounts);
            } else {
              setParentAccountList([]);
            }
            break;

          default:
            break;
        }
        setShowShared(!showShared);
        break;

      default:
        break;
    }
  };

  return (
    <div className="container">
      {!showSpinner && (
        <Modal
          open={props.accountInfo.show}
          onClose={props.hideChangeModal}
          className="header-change-modal-style"
          disableScrollLock={true}
        >
          <div className="header-change-modal-container center-modal">
            <div className="header-change-modal-title-container">
              <img
                src={require("../../../../assets/pin-icon.svg")}
                className="pin-icon"
                alt="pin-icon"
              />
              {/* {props.accountInfo.currentPath === "/home" && ( */}
              <div className="header-change-modal-title">
                {globalizationMapping.CHANGEMODEL.CHANGECOUNTRYTYPE}
              </div>
              {/* )} */}
              <div
                className="close-icon-container"
                onClick={props.hideChangeModal}
              >
                <img
                  src={require("../../../../assets/close-icon.svg")}
                  className="modal-close-icon"
                  alt="close-icon"
                />
              </div>
            </div>
            {/* {props.accountInfo.currentPath === "/home" && ( */}
            <div className="header-change-modal-description">
              {globalizationMapping.CHANGEMODEL.SELECTCOUNTRYCLICKCANCEL}
            </div>
            {/* )} */}
            {/* {props.accountInfo.currentPath === "/home" && ( */}
            <div className="change-modal-select-container" id="single-select">
              <InputLabel className="change-modal-select-label">
                {globalizationMapping.CHANGEMODEL.SELECTCOUNTRY}
              </InputLabel>
              <Autocomplete
                id="combo-box-demo"
                options={countryList}
                getOptionLabel={(option) => option.countryName}
                fullWidth={true}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" inputProps={{...params.inputProps, maxLength: 50 }}/>
                )}
                onChange={(event, newValue) => {
                  handleCountryChange(event, newValue);
                }}
              />
            </div>
            {/* )}/ */}
            {/* {props.accountInfo.currentPath === "/home" && ( */}
            <div className="change-modal-select-container" id="single-select">
              <InputLabel className="change-modal-select-label">
                {globalizationMapping.CHANGEMODEL.ACCOUNTTYPE}
              </InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOwn}
                    onChange={() => handleAccountTypeChange("Own")}
                    name="shared"
                    style={{
                      color: "#0055A4",
                    }}
                  />
                }
                label={globalizationMapping.CHANGEMODEL.OWN}
              />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showShared}
                    onChange={() => handleAccountTypeChange("Shared")}
                    name="shared"
                    style={{
                      color: "#0055A4",
                    }}
                  />
                }
                label={globalizationMapping.CHANGEMODEL.SHARED}
              />
            </div>
            {/* )} */}
            {/* Single Select */}

            <div className="change-modal-select-container" id="single-select">
              <InputLabel className="change-modal-select-label">
                {globalizationMapping.CHANGEMODEL.SELECTPARENTACCOUNT}
              </InputLabel>
              <Autocomplete
                id="combo-box-demo"
                value={parentAccountList ?  parentAccount : parentAccountList[0].accountName}
                label="parentAccount"
                options={parentAccountList}
                isOptionEqualToValue={(option, value) => option.accountName === value}
                getOptionLabel={(option) => option.accountName}
                fullWidth={true}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" inputProps={{...params.inputProps, maxLength: 50 }}/>
                )}
                onChange={(event, newValue) => {
                  handleAccountChange(event, newValue);
                }}
              />
            </div>

            {/* Multi Select */}

            <div
              className="multi-select-dropdown change-modal-select-container"
              id="header-change-modal-multi-select"
            >
              <InputLabel className="change-modal-select-label">
                {globalizationMapping.CHANGEMODEL.LOCATION}
              </InputLabel>
              <MultiSelect
                options={multiSelectLocationList}
                value={location}
                onChange={setLocation}
                overrideStrings={{
                  selectSomeItems:
                    globalizationMapping.REPORTCOMMONWORDS.SELECT,
                  allItemsAreSelected:
                    globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                  selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                  search: globalizationMapping.REPORTCOMMONWORDS["Search"],
                }}
              />
            </div>
              <div style={{paddingLeft:'60%', fontStyle:'italic', fontSize:'small',color:commonUtils.primaryBlue, marginTop:'-2%' }}>Changes in settings will re-route to "Home" for data update</div>
            <div className="modal-button-group">
              <div>
                <button
                  className="header-change-modal-cancel-btn"
                  onClick={props.hideChangeModal}
                >
                  {globalizationMapping.CHANGEMODEL.CANCEL}
                </button>
              </div>
              <div>
                <button
                  disabled={location.length <= 0}
                  className={
                    location.length <= 0
                      ? "header-change-modal-confirm-btn header-change-modal-confirm-btn-disabled"
                      : "header-change-modal-confirm-btn"
                  }
                  onClick={() =>{
                    ((locationPath.pathname.split("/")[1] === "view-tire") || (locationPath.pathname.split("/")[1] === "view-vehicle" )|| (locationPath.pathname.split("/")[1] === "view-rim") || (locationPath.pathname.split("/")[1] === "view-parent-account") || (locationPath.pathname.split("/")[1] === "view-location")) &&  (history.push("/home"));
                    props.confirmChangeAccount(parentAccount, location)                   
                  }}
                >
                  {globalizationMapping.CHANGEMODEL.CONFIRM}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ChangeModal;
