import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";

class TireRegisterDataSetter {
  constructor() {
    this.data = {};
    this.tireRegisterData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  formatData(registeredDate) {
    let date, formattedDate;
    if (registeredDate) {
      let dateString = registeredDate.split("/");
      date = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
      formattedDate = date;
    } else {
      date = null;
      formattedDate = date;
    }

    return formattedDate;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      if (headCell.value !== "BrandNumber") {
        updatedHeadCells.push(headCell);
      }
    });
    return updatedHeadCells;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "BrandNumber") {
        selectedColShow[headCell.value] = false;
      } else {
        selectedColShow[headCell.value] = true;
      }
    });

    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });

    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.BrandNumber.toString().toLowerCase().includes(searchText) ||
        item.TireSerialNo.toString().toLowerCase().includes(searchText) ||
        item.OriginalVehicleNumber.toString().toLowerCase().includes(searchText) ||
        item.WheelPosition.toString().toLowerCase().includes(searchText) ||
        item.ManufacturerName.toString().toLowerCase().includes(searchText) ||
        item.SizeName.toString().toLowerCase().includes(searchText) ||
        item.TypeName.toString().toLowerCase().includes(searchText) ||
        item.CompoundName.toString().toLowerCase().includes(searchText) ||
        item.TotalHours.toString().toLowerCase().includes(searchText) ||
        item.TotalMiles.toString().toLowerCase().includes(searchText) ||
        item.TreadDepth.toString().toLowerCase().includes(searchText) ||
        item.PercentageWorn.toString().toLowerCase().includes(searchText) ||
        item.Eval.toString().toLowerCase().includes(searchText) ||
        item.NewCost.toString().toLowerCase().includes(searchText) ||
        item.Disposition.toString().toLowerCase().includes(searchText) ||
        item.RegisteredDate.toString().toLowerCase().includes(searchText) ||
        item.FirstInstallationDate.toString().toLowerCase().includes(searchText) ||
        item.PoNumber.toString().toLowerCase().includes(searchText) ||
        item.RatingName.toString().toLowerCase().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });

    return filteredTable;
  }

  avgValues = (tireRegisterData) => {
    let NewCostSum = 0;

    tireRegisterData.forEach((item, index) => {
      NewCostSum += item.NewCost;
    });

    const avgValues = {
      BrandNumber: "",
      TireSerialNo: "",
      OriginalVehicleNumber: "",
      WheelPosition:"",
      ManufacturerName: "",
      SizeName: "",
      TypeName: "",
      CompoundName: "",
      RatingName: "",
      TotalHours: "",
      TotalMiles: "",
      TreadDepth: "",
      PercentageWorn: "",
      Eval: "",
      NewCost: NewCostSum,
      Disposition: "",
      RegisteredDate: "",
      FirstInstallationDate: "",
      PoNumber: ""
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.tireRegisterData.push({
        Key: index,
        BrandNumber: item.brandNumber ? item.brandNumber : "",
        TireSerialNo: item.tireSerialNo ? item.tireSerialNo : "",
        OriginalVehicleNumber: item.originalVehicleNumber ? item.originalVehicleNumber : "",
        WheelPosition: item.wheelPosition ? item.wheelPosition : "",
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        RatingName: item.ratingName ? item.ratingName : "",
        TotalHours: item.totalHours ? item.totalHours : 0,
        TotalMiles: item.totalMiles ? item.totalMiles : 0,
        TreadDepth: item.treadDepth ? item.treadDepth : "",
        PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
        Eval: item.eval ? item.eval : "",
        NewCost: item.newCost ? item.newCost : 0,
        Disposition: item.disposition ? item.disposition : "",
        RegisteredDate: item.registeredDate ? this.commonUtils.tableDateFormatter(item.registeredDate) : "",
        FirstInstallationDate: item.firstInstallationDate ? this.commonUtils.tableDateFormatter(item.firstInstallationDate) : "",
        PoNumber: item.poNumber ? item.poNumber : ""
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.tireRegisterData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.tireRegisterData),
      selectedColShow: selectedColShow
    };
  }
}

export default TireRegisterDataSetter;
