import { Backdrop, Button, makeStyles, Modal, Select } from '@material-ui/core';
import React, { useState } from 'react';
import Globalization from '../../../globalization';
import ExpandMoreStyledIcon from '../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon';

const useStyles = makeStyles({
    proceed: {
        "&:hover": {
            opacity: 1,
        },
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: 'Barlow',
        opacity: .8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none"
    },
    cancel: {
        "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none",
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: .8,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none",
    },
});

const ChangeLocationModal = props => {

    const styles = useStyles();
    const globalizationMapping = new Globalization();
    const titleTexts = globalizationMapping.FORMS.RIM.RIMFORM;
    const [locationID, setLocationID] = useState(props.locationData[0].value);
    const handleDeleteClick = () => {
        props.locationChanged(parseInt(locationID, 10));
    };
    const locationChanged = id => {
        setLocationID(id);
    };
    return (
        <div className="container">
            <Modal
                open={true}
                onClose={!props.isModalOpen}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 300,
                }}
                disableScrollLock={true}
            >
                <div className={`delete-vehicle-modal-container center-modal-vehicle`}>
                    <div className="delete-vehicle-title-container">
                        <div className="delete-vehicle-title">
                            {titleTexts.CHANGELOCATION}
                        </div>
                    </div>
                    <div className="delete-modal-description-vehicle">
                        <p className="vehicle-ids">{titleTexts.CHANGELOCATIONTEXT.replace("#1#1", props.locationData[0].label)}</p>
                    </div>
                    <div className="delete-modal-description-vehicle">
                        <p className="field-label">{titleTexts.LOCATION} <span className="required-mark">*</span></p>
                        <Select
                            native
                            IconComponent={ExpandMoreStyledIcon}
                            className="enter-detail-input-box"
                            variant="outlined"
                            value={locationID}
                            onChange={e => {
                                locationChanged(e.target.value);
                            }}>
                            {props.locationData.map((mfcr, key) =>
                                <option key={key + 1} value={mfcr.value}>{mfcr.label}</option>
                            )}
                        </Select>
                    </div>
                    <div className="delete-modal-description-vehicle" style={{ marginTop: '-25px' }}><p>{titleTexts.WISHPROCEED}</p></div>
                    <div className="modal-action-buttons">
                        <Button
                            className={styles.proceed}
                            variant="contained"
                            disableElevation={true}
                            color='primary'
                            onClick={
                                () => { handleDeleteClick(); }
                            }
                        >
                            {globalizationMapping.FORMS.PROCEED}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ChangeLocationModal;
