import NoDataGraph from '../../assets/noDataGraph.svg'
import NoDataTable from '../../assets/noDataTable.svg'
import React from "react"
import "./NoDataFound.css"
import Globalization from '../../globalization'


const NoDataFound = ({ noDataType }) => {
  const globalizationMapping = new Globalization()
  
  return (
    <div className="parent-no-data-found-div">
      {noDataType === 'graph' && <img src={NoDataGraph} alt="No Data" />}
      {noDataType === 'table' && <img src={NoDataTable} alt="No Data" />}
      <h3 className="no-data-text-display">{globalizationMapping.REPORTCOMMONWORDS.NoData}</h3>
      <h4 className="try-to-find-display">{globalizationMapping.REPORTCOMMONWORDS.AdjustFilter}</h4>
    </div>
  );
}
export default NoDataFound;