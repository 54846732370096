import React from "react";
import "../common-modal/CommonPopup.css";
import "./ImageModalComponent.css";
import { Modal, Backdrop, Fade } from "@material-ui/core";

class ImageModal extends React.Component {
  constructor(props) {
    super(props);
  }
  handleClose = () => {
    this.props.setModalHandlerImage(false);
  };

  previousImage = () => {
    if (this.props.selectedImage > 0) {
      let imageNo = this.props.selectedImage - 1;
      this.props.setSelectedImage(imageNo);
    }
  };

  nextImage = () => {
    if (this.props.selectedImage < this.props.modalData.imageData.length - 1) {
      let imageNo = this.props.selectedImage + 1;
      this.props.setSelectedImage(imageNo);
    }
  };

  render() {
    return (
      this.props.setModalHandlerImage &&
      this.props.modalHandlerImage.handleImage && (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalParent"
            open={this.props.modalHandlerImage.handleImage}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            closeAfterTransition
            disableScrollLock={true}
          >
            <Fade in={this.props.modalHandlerImage.handleImage}>
              <div className="modal">
                <div className="image-modal-container center-modal">
                  <div className="change-modal-header">
                    <div className="change-modal-title-container graph-title">
                      <div
                        className="image-previous-icon-container"
                        onClick={this.previousImage}
                      >
                        <img
                          src={require("../../../assets/Previous.svg")}
                          className="modal-previous-icon"
                          alt="close-icon"
                        />
                      </div>
                      <div className="image-modal-title">Zoom View</div>
                      <div
                        className="image-next-icon-container"
                        onClick={this.nextImage}
                      >
                        <img
                          src={require("../../../assets/next.svg")}
                          className="modal-next-icon"
                          alt="close-icon"
                        />
                      </div>
                      <div
                        className="close-icon-container graph-close"
                        onClick={this.handleClose}
                      >
                        <img
                          src={require("../../../assets/close-icon.svg")}
                          className="image-modal-close"
                          alt="close-icon"
                        />
                      </div>
                    </div>
                    <div className="image-modal-body-container">
                      <img
                        className="image-modal"
                        alt=""
                        src={
                          this.props.modalData.imageData[
                            this.props.selectedImage
                          ]
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      )
    );
  }
}

export default ImageModal;
