import DateFnsUtils from "@date-io/date-fns";
import {
  FormHelperText,
  Grid,
  TextField,
  makeStyles,
  Button,
} from "@material-ui/core";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useContext, useState } from "react";
import HeaderApi from "../../../common-components/header/services/header-apis";
import Spinner from "../../../common-components/spinner/spinner";
import { StateContext } from "../../../services/StateProvider";
import Globalization from "../../../globalization";
import UpdateHourPopup from "../confirm-popup/confirm-popup";
import CalendarMonthIcon from '@material-ui/icons/Today';
import Moment from "moment";
import "./update-hours-miles.css";
import {
  getAccountNames,
  getLocationNames,
} from "../../../services/AccountInfoHandler";
import { useHistory, useLocation } from "react-router";
import CommonUtils from "../../../services/utils";

const useStyles = makeStyles({
  width: "100%",
  checkbox: {
    display: "flex",
  },
  next: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5,
  },
});

const UpdateHoursMiles = () => {
  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [vehicleId, setVehicleId] = useState("");
  const [surveyDate, setSurveyDate] = useState(new Date());
  const [lastRecHours, setLastRecHours] = useState("0");
  const [updateHour, setUpdateHour] = useState();
  const [lastRecMiles, setLastRecMiles] = useState("0");
  const [locationName, setLocationName] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState("0");
  const [updatedDate, setUpdatedDate] = useState("0");
  const [updateMiles, setUpdateMiles] = useState();
  const [checked, setChecked] = useState(true);
  const [tempData, setTempData] = useState();
  const [isUpdateHourPopup, setIsUpdateHourPopup] = useState(false);
  const [accountName, setAccountName] = useState("");

  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const globalTexts = globalizationMapping.INSPECT;
  const locationObj = useLocation();
  const history = useHistory();
  const headerApi = new HeaderApi();
  const styling = useStyles();
  const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
  const commonUtils = new CommonUtils();
  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME,
    },
    {
      path: "quick-inspect/" + vehicleId,
      name: globalTexts.INSPECTHEADER,
    },
    {
      path: "",
      name: globalHeaderTexts.UPDATEHOURS,
    },
  ];

  useEffect(() => {
    setShowSpinner(true);
    if (
      locationObj.pathname === "/update-hours-miles" &&
      locationObj.state &&
      locationObj.state.vehicleId
    ) {
      if (locationObj.state && locationObj.state.from === "createVehicle") {
        setChecked(false);
      }
      setVehicleId(locationObj.state.vehicleId);
      setLocationName(getLocationNames());
      setAccountName(getAccountNames());
      headerApi.getDetailsById(locationObj.state.vehicleId).then((data) => {
        if (data && data.model) {
          setLastRecHours(data.model.currentHours);
          setLastRecMiles(data.model.currentMiles);
          setTempData(data.model);
          setVehicleNumber(data.model.vehicleNumber);
          if (sessionStorage.userLanguage === "en-uk") {
            setUpdatedDate(
              Moment(data.model.updatedDate).format(
                "DD/MM/YYYY"
              )
            );
          } else {
            setUpdatedDate(
              Moment(data.model.updatedDate).format(
                "MM/DD/YYYY"
              )
            );
          }
          setShowSpinner(false);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  function onChangeListener(from, value) {
    if (from === "hours") {
      setUpdateHour(value);
    } else {
      if (from === "miles") {
        setUpdateMiles(value);
      }
    }
  }
  const onDateChange = (value) => {
    setSurveyDate(value);
  };

  const actionSelector = (isChecked, location) => {
    if (isChecked) {
      return "UpdateHoursEditTire";
    } else {
      return location.state.from === "createVehicle"
        ? "MeterChange"
        : "UpdateHours";
    }
  };

  const handleCancel = () => {
    if (locationObj.state && locationObj.state.from === "createVehicle") {
      const updatedsection = "updateHours";
      const successmessage = notificationTexts.UPDATEHPOURSHEAD;
      const successdesc = notificationTexts.UPDATEHPOURSDES;
      const failuremsg = notificationTexts.UPDATEHPOURSHEAD;
      const successStatus = false;
      history.push({
        pathname: "/create-vehicle/" + vehicleId,
        state: {
          updatedSection: updatedsection,
          updateStatus: successStatus,
          successMessage: successmessage,
          successMessageDesc: successdesc,
          failureMessage: failuremsg,
        },
      });
    } else {
      history.push({ pathname: "/quick-inspect/" + vehicleId });
    }
  };
  const handleConfirmPopupClose = () => {
    setIsUpdateHourPopup(false);
  };
  const userRef = JSON.parse(
    localStorage.getItem("accountInfouserMeasurementSystemValue")
  );

  return (
    <div className="standalone-new-header-container">
      <div className="update-hour-parent-account-main-page-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image background-map-image-update-hm">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {" "}
                    {locationObj.state.from === "createVehicle"
                      ? "Meter Change"
                      : globalHeaderTexts.UPDATEHOURS}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="manage-vehicle-list-container">
          <div className="update-hour-parent-container">
            <div className="update-hour-parent-inner-container">
              <div className="header-inner-container">
                {/* this will contain the top heading box */}
                <Grid className="inner-container" container>
                  <Grid item>
                    <p className="field-label">
                      {globalTexts.SELECTPARENTACCOUNT}
                    </p>
                    <p id="recordedHour" className="field-label-p">
                      {`${accountName} - ${locationName[0]}`}
                    </p>
                  </Grid>
                  <Grid item>
                    <p className="field-label">{globalTexts.VEHICLEID}</p>
                    <p id="recordedHour" className="field-label-p">
                      {vehicleNumber}
                    </p>
                  </Grid>
                  <Grid item>
                    <p className="field-label">
                      {globalTexts.LASTRECOREDEDDATE}
                    </p>
                    <p id="recordedHour" className="field-label-p">
                      {updatedDate}
                    </p>
                  </Grid>
                </Grid>
              </div>

              <div className="helper-message">
                {globalTexts.MANDATORY}
                <span className="required-mark">*</span>
              </div>

              <div className="step-form-container">
                <Grid
                  className="step-form-inner-container"
                  container
                  spacing={2}
                >
                  <Grid item sm={12}>
                    <p className="field-label">
                      {globalizationMapping.INSPECT.SURVEYDATE}
                      <span className="required-mark">*</span>
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="enter-detail-input-box"
                        keyboardIcon={<CalendarMonthIcon />}
                        value={surveyDate}
                        onChange={onDateChange}
                        format={commonUtils.getDateFormat()}
                        helperText=""
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item sm={12}>
                    <p className="field-label">
                      {globalTexts.LASTRECORDEDHOURS}
                    </p>
                    <p id="recordedHour" className="disable-field-label">
                      {lastRecHours}
                    </p>
                  </Grid>
                  <Grid item sm={12}>
                    <p className="field-label">{globalTexts.UPDATEHOURS}</p>
                    <TextField
                      className="enter-detail-input-box"
                      variant="outlined"
                      value={updateHour}
                      onChange={(e) =>onChangeListener("hours", e.target.value)}
                      placeholder=" Enter Hour"
                      helperText=""
                      type="number"
                      inputProps={{ maxLength: 50 }}
                    />
                    {<FormHelperText></FormHelperText>}

                    {updateHour !== "" &&
                      updateHour !== null &&
                      parseFloat(updateHour) < parseFloat(lastRecHours) && (
                        <FormHelperText>
                          Please enter hours greater than or equal to last
                          recorded hour
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item sm={12}>
                    <p className="field-label">
                      {userRef.toLowerCase() === "metric"
                        ? globalizationMapping.INSPECT.LASTRECORDEDKILOMETERS
                        : globalizationMapping.INSPECT.LASTRECORDEDMILES}
                    </p>
                    <p id="recordedMiles" className="disable-field-label">
                      {lastRecMiles}
                    </p>
                  </Grid>
                  <Grid item sm={12}>
                    <p className="field-label">
                      {userRef.toLowerCase() !== "metric"
                        ? globalTexts.UPDATEMILES
                        : globalTexts.UPDATEKILOMETERS}
                    </p>
                    <TextField
                      className="enter-detail-input-box"
                      variant="outlined"
                      value={updateMiles}
                      onChange={(e) =>onChangeListener("miles", e.target.value)}
                      placeholder=" Enter Distance"
                      helperText=""
                      type="number"
                      inputProps={{ maxLength: 50 }}
                    />
                    {updateMiles !== "" &&
                      updateMiles !== null &&
                      parseFloat(updateMiles) < parseFloat(lastRecMiles) && (
                        <FormHelperText>
                          Please enter miles greater than or equal to last
                          recorded miles
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item sm={12} className={styling.checkbox}>
                  </Grid>
                </Grid>
              </div>

              <hr className="hr-divider-action" />

              <div className="action-buttons">
                <Button
                  className={styling.cancel}
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {globalizationMapping.FORMS.UPDATE}
                </Button>

                <Button
                  className={styling.next}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (
                      (updateMiles !== "" &&
                        parseFloat(updateMiles) > parseFloat(lastRecMiles)) ||
                      (updateHour !== "" &&
                        parseFloat(updateHour) > parseFloat(lastRecHours))
                    ) {
                      setIsUpdateHourPopup(true);
                    }
                  }}
                >
                  {globalizationMapping.INSPECT.UPDATE}
                </Button>
              </div>
            </div>
            {isUpdateHourPopup && (
              <UpdateHourPopup
                checked={checked}
                closeWindow={handleConfirmPopupClose}
                reqObj={{
                  vehicleId,
                  inspectionDate: surveyDate,
                  // currentHours: updateHour && updateHour!=="" && updateHour!==0 && parseFloat(updateHour) > parseFloat(lastRecHours)?updateHour:lastRecHours,
                  // currentMiles: updateMiles && updateMiles!=="" && updateMiles!==0 && parseFloat(updateMiles) > parseFloat(lastRecMiles)?updateMiles:lastRecMiles,
                  currentHours: updateHour
                    ? parseFloat(updateHour)
                    : lastRecHours,
                  currentMiles: updateMiles
                    ? parseFloat(updateMiles)
                    : lastRecMiles,
                  action: actionSelector(checked, locationObj),

                  locationId: tempData?.locationId,
                  parentAccountId: tempData?.parentAccountId,
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default UpdateHoursMiles;
