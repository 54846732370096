import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExpandMoreStyledIcon = () => {
    return (
        <ExpandMoreIcon
        style={{
          color: '#aaa',
          cursor: "pointer",
          top: "calc(50% - 12px)",
          position: "absolute",
          pointerEvents: "none",
          right: "7px"
        }}
      ></ExpandMoreIcon>
    )
}

export default ExpandMoreStyledIcon;