import React, { useState } from "react";
import "./Filter.css";
import { Drawer, Divider } from "@material-ui/core";
import CloseIcon from "../../../../assets/close-icon.svg";
import FilterTabs from "../filter-tab/FilterTabs";
import Globalization from "../../../../globalization";

const filterOptionsTemplate = (filterOptions) => {
  let selectedFilterOptions = {};
  filterOptions.categories.forEach((category) => {
    selectedFilterOptions[category.value] = [];
  });
  return selectedFilterOptions;
};

const Filter = ({
  setShowFilter,
  showFilter,
  filterOptions,
  confirmApplyFilter,
  resetTableFilter,
  onFilterUpdateMain,
  isEntryDate,
  confirmApplyDateFilter,
  setInServiceDateFilter,
  setOutServiceDateFilter,
  dateRangeFilter,
  setDateRangeFilter,
  inServiceDateRange,
  setInServiceDateRange,
  outServiceDateRange,
  setOutServiceDateRange,
  noFieldFilter,
  noDateFilter,
  tireNeedsForecast,
  isTireForecastFilter,
  steeringWheelsForecastType,
  steeringWheelsFixedRemovalHours,
  drivingWheelsForecastType,
  drivingWheelsFixedRemovalHours,
  handleSteeringWheelsForecastType,
  handleSteeringWheelsFixedRemovalHours,
  handleDrivingWheelsForecastType,
  handleDrivingWheelsFixedRemovalHours,
  handleRemoveAt,
  removeAt
}) => {

  // setting up filter selection options template dynamically (used for filter text fields)
  let selectedFilterOptionsInit = filterOptionsTemplate(filterOptions);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(
    selectedFilterOptionsInit
  );

  const globalizationMapping = new Globalization();
  const filterSectionWords = globalizationMapping.FILTERSECTION;
  const closeFilter = () => {
    setShowFilter(false);
  };
  const updateFilter = (filterVal) => {
    onFilterUpdateMain(filterVal);
  };


  const filterOptionsSelected = (_event, selectedItems, categoryName) => {
    const updatedOptions = [];
    // initiates the selections for the selected category
    selectedItems.forEach((selectedItem) => {
      setSelectedFilterOptions({ [selectedItem.category]: [] });
    });
    // adds selected items
    if (selectedItems.length > 0) {
      selectedItems.forEach((selectedItem) => {
        updatedOptions.push(selectedItem.value);
        setSelectedFilterOptions({
          ...selectedFilterOptions,
          [selectedItem.category]: updatedOptions,
        });
      });
    } else if (selectedItems.length === 0) {
      setSelectedFilterOptions({
        ...selectedFilterOptions,
        [categoryName]: [],
      });
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <Drawer
        className="filter-drawer"
        anchor="right"
        // variant="persistent"
        open={showFilter}
        onClose={closeFilter}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className="filter-container">
          <div className="filter-header">
            <div className="filter-title">{filterSectionWords.Filters}</div>
            <div className="filter-header-options">
              <div className="filter-cancel">
                <img src={CloseIcon} alt="" onClick={closeFilter} />
              </div>
            </div>
          </div>
          <Divider />
          <FilterTabs
            filterOptions={filterOptions}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
            filterOptionsSelected={filterOptionsSelected}
            onFilterUpdate={updateFilter}
            confirmApplyFilter={confirmApplyFilter}
            confirmApplyDateFilter={confirmApplyDateFilter}
            resetTableFilter={resetTableFilter}
            setShowFilter={setShowFilter}
            setInServiceDateFilter={setInServiceDateFilter}
            setOutServiceDateFilter={setOutServiceDateFilter}
            dateRangeFilter={dateRangeFilter}
            setDateRangeFilter={setDateRangeFilter}
            inServiceDateRange={inServiceDateRange}
            setInServiceDateRange={setInServiceDateRange}
            outServiceDateRange={outServiceDateRange}
            setOutServiceDateRange={setOutServiceDateRange}
            isEntryDate={isEntryDate}
            noFieldFilter={noFieldFilter}
            noDateFilter={noDateFilter}
            isTireForecastFilter={isTireForecastFilter}
            steeringWheelsForecastType={steeringWheelsForecastType}
            steeringWheelsFixedRemovalHours={steeringWheelsFixedRemovalHours}
            drivingWheelsForecastType={drivingWheelsForecastType}
            drivingWheelsFixedRemovalHours={drivingWheelsFixedRemovalHours}
            handleSteeringWheelsForecastType={handleSteeringWheelsForecastType}
            handleSteeringWheelsFixedRemovalHours={ handleSteeringWheelsFixedRemovalHours}
            handleDrivingWheelsForecastType={handleDrivingWheelsForecastType}
            handleDrivingWheelsFixedRemovalHours={handleDrivingWheelsFixedRemovalHours}
            tireNeedsForecast={tireNeedsForecast}
            handleRemoveAt={handleRemoveAt}
            removeAt={removeAt}
          /> 
        
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default Filter;
