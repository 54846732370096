import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
// eslint-disable-next-line no-unused-vars

const ByManufacturerGraph = ({ byManufacturerGraphData, label }) => {
  const [chartInfoData, setChartInfoData] = useState({});
  const [chartInfoDataForMile, setChartInfoDataForMile] = useState({});
  const commonUtils = new CommonUtils();
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const optionsForMile = JSON.parse(JSON.stringify(commonUtils.barOptions));
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["manufacturer-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["manufacturer-y"];
  options.title.text = globalizationHeaders["manufacturer-title"];
  optionsForMile.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["manufacturer-x"];
  optionsForMile.scales.yAxes[0].scaleLabel.labelString =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationHeaders["manufacturermiles-y"]
      : globalizationHeaders["manufacturer-y-miles-32"];
  optionsForMile.title.text =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationHeaders["manufacturer-title-mile"]
      : globalizationHeaders["manufacturer-title-32-mile"];

  const chart = () => {
    if (byManufacturerGraphData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();

      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byManufacturerGraphData.forEach((item) => {
        graphDataSum[item.ManufacturerName] = 0;
        graphDataAvg[item.ManufacturerName] = 0;
        graphDataCount[item.ManufacturerName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byManufacturerGraphData.forEach((item) => {
        graphDataSum[item.ManufacturerName] += item.TotalHours;
        graphDataCount[item.ManufacturerName]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((manufacturerName) => {
        graphDataAvg[manufacturerName] = Math.round(graphDataSum[manufacturerName] / graphDataCount[manufacturerName]);
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoData({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues
          }
        ]
      });
    } else {
      setChartInfoData({});
    }
  };
  const chartMiles = () => {
    if (byManufacturerGraphData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();

      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byManufacturerGraphData.forEach((item) => {
        graphDataSum[item.ManufacturerName] = 0;
        graphDataAvg[item.ManufacturerName] = 0;
        graphDataCount[item.ManufacturerName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byManufacturerGraphData.forEach((item) => {
        graphDataSum[item.ManufacturerName] += item.TotalMiles;
        graphDataCount[item.ManufacturerName]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((manufacturerName) => {
        graphDataAvg[manufacturerName] = Math.round(graphDataSum[manufacturerName] / graphDataCount[manufacturerName]);
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoDataForMile({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues
          }
        ]
      });
    } else {
      setChartInfoDataForMile({});
    }
  };
  useEffect(() => {
    chart();
    chartMiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byManufacturerGraphData]);

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && label === "hours" && <Bar data={chartInfoData} height={120} options={options} />}
        {Object.keys(chartInfoDataForMile).length > 0 && label === "miles" && <Bar data={chartInfoDataForMile} height={120} options={optionsForMile} />}
      </Grid>

      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ByManufacturerGraph;
