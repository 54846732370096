import React, { useState, useEffect } from "react";
import "./ScrapTiresNavigation.css";
import Breadcrumbs from "../../../../../../common-components/breadcrumbs/breadcrumbs";
import { IconButton, List, ListItem } from "@material-ui/core";
import Globalization from "../../../../../../globalization/index";
import { useHistory } from "react-router-dom";
import { ReportsRoutePaths } from "../../../../../../routing/ReportsRoutePaths";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';


const ScrapTiresNavigation = () => {
  const globalizationMapping = new Globalization();
  const scrapTiresGlobalTexts = globalizationMapping.TIREREPORTS.SCRAPTIRES;
  const scrapTiresNav = [
    scrapTiresGlobalTexts.ScrapSummary,
    scrapTiresGlobalTexts.ByRemovalReason,
    scrapTiresGlobalTexts.ByCompound,
    scrapTiresGlobalTexts.ByIndividualTire,
    scrapTiresGlobalTexts.ScrapAnalysis,
    scrapTiresGlobalTexts.ByTireType,
    scrapTiresGlobalTexts.ByTireSize,
    scrapTiresGlobalTexts.ScrapCostByTireSize,
    scrapTiresGlobalTexts.ScrapHoursByTireSize,
    scrapTiresGlobalTexts.ScrapCostByTireType,
    scrapTiresGlobalTexts.ScrapHoursByTireType,
    scrapTiresGlobalTexts.MonthlyTireLife,
  ];
  const scrapTiresPathNav = [
    "scrapSummary",
    "byRemovalReason",
    "byCompound",
    "byIndividualTire",
    "scrapAnalysis",
    "byTireType",
    "byTireSize",
    "scrapCostByTireSize",
    "scrapHoursByTireSize",
    "scrapCostByTireType",
    "scrapHoursByTireType",
    "monthlyTireLife",
  ];

  const getRouteName = (currentPathName) => {
    let pathSelected = ReportsRoutePaths.find(
      (route) => route.path === currentPathName
    );
    return pathSelected;
  };

  const getRoutePath = (scrapTireSelected, path) => {
    let pathSelected = ReportsRoutePaths.find((route) => route.path === path);
    return pathSelected;
  };

  let breadcrumbs = [];
  const history = useHistory();

  let currentPathName = history.location.pathname.split("/")[1];
  let currentRouteSelected = getRouteName(currentPathName);

  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line no-unused-vars
  const [selectedScrapTire, setSelectedScrapTire] = useState(
    currentRouteSelected.name
  );

  breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME,
    },
    {
      path: "reportsDashboard",
      name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.REPORTSDASHBOARD,
    },
    {
      path: "",
      name:
        globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.TIRESREPORTS +
        " - " + scrapTiresGlobalTexts.ScrapTires + ": " +
        selectedRoute.name,
      // name: "Tire Reports - Scrap Summary"
    },
  ];

  const scrapTiresNavigation = (scrapTireSelected, path) => {
    let routePath = getRoutePath(scrapTireSelected, path);

    setSelectedScrapTire(scrapTireSelected);
    setSelectedRoute(routePath);
  };

  const prev = () => {
    scrapTiresNav.forEach((item, index) => {
      if (selectedScrapTire === item && index !== 0) {
        setSelectedScrapTire(scrapTiresNav[index - 1]);
        scrapTiresNavigation(
          scrapTiresNav[index - 1],
          scrapTiresPathNav[index - 1]
        );
      }
    });
  };
  const next = () => {
    scrapTiresNav.forEach((item, index) => {
      if (selectedScrapTire === item && index !== scrapTiresNav.length - 1) {
        setSelectedScrapTire(scrapTiresNav[index + 1]);
        scrapTiresNavigation(
          scrapTiresNav[index + 1],
          scrapTiresPathNav[index + 1]
        );
      }
    });
  };

  useEffect(() => {
    history.push(selectedRoute.path);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  return (
    <div>
      <div className="scrap-tires-main-container">
        <div className="scrap-tires-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {scrapTiresGlobalTexts.ScrapTires}
                  </div>
                </div>
              </div>
              <div className=" scrap-button-scrap-tires-nav-container">
                <IconButton onClick={() => prev()}>
                   <ArrowBackIosRoundedIcon style={{color:'whitesmoke'}}></ArrowBackIosRoundedIcon>
                </IconButton>
                <div className="scrap-tires-nav-container">
                  {scrapTiresNav.map((item, index) => {
                    return (
                      <div
                        key={item}
                        className="scrap-tires-nav-card-container"
                        onClick={() =>
                          scrapTiresNavigation(item, scrapTiresPathNav[index])
                        }
                      >
                        <List
                          className={
                            selectedRoute.name === item
                              ? "active-scrap-tires-nav-card"
                              : "scrap-tires-nav-card"
                          }
                        >
                          <ListItem
                            button={selectedRoute.name === item}
                            autoFocus={selectedRoute.name === item}
                          >
                            <span
                              className={
                                selectedRoute.name === item
                                  ? "active-scrap-tires-nav-card-text"
                                  : "scrap-tires-nav-card-text"
                              }
                            >
                              {item}
                            </span>
                          </ListItem>
                        </List>
                      </div>
                    );
                  })}
                </div>
                <IconButton onClick={() => next()}>
                   <ArrowForwardIosRoundedIcon style={{color:'whitesmoke'}}></ArrowForwardIosRoundedIcon>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrapTiresNavigation;
