import React, { useEffect, useState, useContext } from "react";
import "./TireInflation.css";
import { Card, CardContent, Select, InputLabel, TextField, Button, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresInServiceNavigation from "../../../tires-in-service-navigation/TiresInServiceNavigation";
import TireInflationDataSetter from "../../../../../services/TireInflationUtils";
import TireInflationGraph from "../graph/TireInflationGraph";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import HeaderApi from "../../../../../../../common-components/header/services/header-apis";
import TireInflationFilter from "../filter/TireInflationFilter";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from "../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons";

const TireInflation = () => {

  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const tiresService = new TiresService();
  const tireInflationDataSetter = new TireInflationDataSetter();
  const commonUtils = new CommonUtils();
  const [showSpinner, setShowSpinner] = useState(true);
  const [tireInflationGraphData, setTireInflationGraphData] = useState();
  const [printData, setPrintData] = useState([]);
  const [pressureType, setPressureType] = useState("recommendedPressure");
  const [pressureUnit, setPressureUnit] = useState("PressureLimit");
  const [pressureUnitLabel, setPressureUnitLabel] = useState("");
  const [chartType, setChartType] = useState("Pie");
  const [pressureValue, setPressureValue] = useState(0);
  const [recommendedPressureValue, setRecommendedPressureValue] = useState(0);
  const [upperLimitHot, setUpperLimitHot] = useState(105);
  const [lowerLimitHot, setLowerLimitHot] = useState(90);
  const [upperLimitCold, setUpperLimitCold] = useState(100);
  const [lowerLimitCold, setLowerLimitCold] = useState(85);
  const [selectedLocationId, setSelectedLocationId] = useState();
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [masterData, setMasterData] = useState({});
  const [manufList, setManufList] = useState([]);
  const [selectedManuf, setSelectedManuf] = useState([]);
  const [vehManufList, setVehManufList] = useState([]);
  const [selectedVehManuf, setSelectedVehManuf] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const onlyFilterApplied = false;

  const TireInflationResponseData = () => {
    let locId = accountInfoIdsFormatter(globalAccountState).locationIds[0];
    setSelectedLocationId(locId);
    setShowSpinner(true);
    GetPressureFromLocationId(locId);
  };

  const GetPressureFromLocationId = (locationId) => {

    setShowSpinner(true);
    tiresService.getLocationById(locationId).then((locData) => {
      setPressureValue(locData.model.recommendedPressure);
      setRecommendedPressureValue(locData.model.recommendedPressure);
      OnChangeCallApi(locData.model.recommendedPressure);
    });
  };

  const OnChangeCallApi = (pressureVal) => {
    let requestObject;
    if (pressureType === "simplified") {
      requestObject = {
        accountIds: accountInfoIdsFormatter(globalAccountState).accountIds,
        dateRangeType: "AllTime",
        fromDate: "",
        graphType: "Pie",
        locationIds: accountInfoIdsFormatter(globalAccountState).locationIds,
        reportName: "GetTireInflationSimplifiedGraph",
        toDate: "",
        PressureUnit: pressureUnit,
        HotUpperLimit: upperLimitHot,
        ColdUpperLimit: upperLimitCold,
        HotLowerLimit: lowerLimitHot,
        ColdLowerLimit: lowerLimitCold,
        usedTires: excludeUsedTires.toString()
      };
    } else {
      requestObject = {
        accountIds: accountInfoIdsFormatter(globalAccountState).accountIds,
        dateRangeType: "AllTime",
        fromDate: "",
        graphType: "Pie",
        locationIds: accountInfoIdsFormatter(globalAccountState).locationIds,
        reportName: "GetTireInflationPressureGraph",
        toDate: "",
        pressureValue: pressureVal,
        usedTires: excludeUsedTires
      };
    }
    setShowSpinner(true);
    tiresService.getTiresGraph(requestObject).then((data) => {
      let tireInflationGraphInfo = tireInflationDataSetter.setGraphData(data);
      tireInflationGraphInfo.graphType = chartType;
      setTireInflationGraphData(tireInflationGraphInfo);

      // static preset printData to allow only graph printing
      setPrintData({
        reportType: "Tires in Services : ",
        reportName: "In Service Tire Inflation",
        rowsData: [],
        headCells: [],
        avgValues: [],
        selectedColShow: [],
        filterApplied: false,
        graphType: chartType
      });
      //
      setShowSpinner(false);
    });
  };

  useEffect(() => {
    TireInflationResponseData();
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let pressureUnitLabel = globalAccountState.accountInfo.userSetting.userPressureUnit;
    setPressureUnitLabel(pressureUnitLabel);
    if (pressureUnitLabel.toLowerCase() === "psi") {
      setPressureUnitLabel("PSI");
    } else if (pressureUnitLabel.toLowerCase() === "kpa") {
      setPressureUnitLabel("KPA");
    } else {
      setPressureUnitLabel("Bar");
    }
    let req = {
      parrentAccountId: accountInfoObject.accountIds,
      locationId: accountInfoObject.locationIds
    };

    tiresService.getDistinctTireSize(req).then((locData) => {
    });
    const headerApiObj = new HeaderApi();

    headerApiObj.getMasterDataWeb().then((masterData) => {
      setMasterData(masterData.model);
      let tempManuf = masterData.model.tireManufacturers.map((item) => {
        return {
          label: item.manufacturerName,
          value: item.manufacturerId
        };
      });
      setManufList(tempManuf);

      let tempVehicleManuf = [];
      tempVehicleManuf = masterData.model.vehicleManufacturers.map((item) => {
        return {
          label: item.manufacturerName,
          value: item.manufacturerId
        };
      });
      setVehManufList(tempVehicleManuf);
    });
  }, [globalAccountState]);

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };
  const globalizationMapping = new Globalization();

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const handleChartType = (event) => {
    setChartType(event.target.value);
    let tempData = tireInflationGraphData;
    tempData.graphType = event.target.value;
    let tempPrintData = printData;
    tempPrintData.graphType = event.target.value;
    setPrintData(tempPrintData);
    setTireInflationGraphData(tempData);
  };

  const handlePressureType = (event) => {
    setPressureType(event.target.value);
    if (event.target.value === "recommendedPressure") setPressureValue(recommendedPressureValue);
  };

  const handlePressureValue = (value) => {
    setPressureValue(value);
  };

  const handlePressureUnit = (event) => {
    setPressureUnit(event.target.value);
  };

  const handleUpperLimitHot = (value) => {
    setUpperLimitHot(value);
  };
  const handleLowerLimitHot = (value) => {
    setLowerLimitHot(value);
  };
  const handleUpperLimitCold = (value) => {
    setUpperLimitCold(value);
  };
  const handleLowerLimitCold = (value) => {
    setLowerLimitCold(value);
  };

  const handleUpdateChart = () => {
    OnChangeCallApi(pressureValue);
  };

  const handleApplyFilter = () => {
    setShowFilter(false);
    let requestObject;
    let tireManufacturerNames = selectedManuf.map((item) => item.value);
    let tireManufacturerLabel = selectedManuf.map((item) => item.label);
    let tireSizeNames = [],
      tireSizeIds = [];
    let tireSizeNameLabel = [];

    if (!sizeSelected.length) {
      masterData.tireSizes.map((size) => {
        let t = selectedManuf.some((item) => item.value === size.tireManufacturerId);
        if (t) {
          tireSizeNames.push(size.tireSizeId);
          tireSizeIds.push(size.tireSizeId);
          tireSizeIds.push(size.tireSizeName);
        }
      });
    } else {
      tireSizeNames = sizeSelected.map((item) => item.value);
      tireSizeNameLabel = sizeSelected.map((item) => item.label);
      tireSizeIds = sizeSelected.map((item) => item.value);
    }
    let tireTypeNames = [];
    let tireTypeNameLabel = [];

    if (!TypeSelected.length) {
      masterData.tireTypes.map((size) => {
        let isManf = selectedManuf.some((item) => {
          return item.value === size.tireManufacturerId;
        });
        let isSize = tireSizeIds.some((item) => {
          return item === size.tireSizeId;
        });

        if (isManf && isSize) {
          tireTypeNames.push(size.typeId);
        }
      });
    } else {
      tireTypeNames = TypeSelected.map((item) => item.value);
      tireTypeNameLabel = TypeSelected.map((item) => item.label);
    }

    let vehManufacturerNames = [],
      vehManufacturerIds = [];
    let vehicleManufacturerLable = []

    if (!selectedVehManuf.length) {
      masterData.vehicleManufacturers.map((size) => {
        vehManufacturerNames.push(size.manufacturerId);
        vehManufacturerIds.push(size.manufacturerId);
      });
    } else {
      vehManufacturerNames = selectedVehManuf.map((item) => item.value);
      vehicleManufacturerLable = selectedVehManuf.map((item) => item.label);
      vehManufacturerIds = selectedVehManuf.map((item) => item.value);
    }

    let vehTypeNames = [];
    let vehTypeNamesLabel = [];


    if (!vTypeSelected.length) {
      masterData.vehicleTypes.map((size) => {
        let t = vehManufacturerIds.some((item) => item === size.manufacturerId);
        if (t) vehTypeNames.push(size.typeId);
      });
    } else {
      vehTypeNames = vTypeSelected.map((item) => item.value);
      vehTypeNamesLabel = vTypeSelected.map((item) => item.label);

    }

    let filterData = {
      ManufacturerName: tireManufacturerLabel,
      SizeName: tireSizeNameLabel,
      TypeName: tireTypeNameLabel,
      VehicleManufacturer: vehicleManufacturerLable,
      VehicleType: vehTypeNamesLabel
    };

    if (pressureType === "simplified") {
      requestObject = {
        accountIds: accountInfoIdsFormatter(globalAccountState).accountIds,
        dateRangeType: "AllTime",
        fromDate: "",
        graphType: "Pie",
        locationIds: accountInfoIdsFormatter(globalAccountState).locationIds,
        reportName: "GetTireInflationSimplifiedGraphPerSize",
        toDate: "",
        PressureUnit: pressureUnit,
        HotUpperLimit: upperLimitHot,
        ColdUpperLimit: upperLimitCold,
        HotLowerLimit: lowerLimitHot,
        ColdLowerLimit: lowerLimitCold,
        tireManufacturerNames: [...new Set(tireManufacturerNames)].join(","),
        tireSizeName: [...new Set(tireSizeNames)].join(","),
        tireTypeName: [...new Set(tireTypeNames)].join(","),
        vehicleManufacturer: [...new Set(vehManufacturerNames)].join(","),
        vehicleTypeName: [...new Set(vehTypeNames)].join(","),
        usedTires: excludeUsedTires.toString()
      };
    } else {
      requestObject = {
        accountIds: accountInfoIdsFormatter(globalAccountState).accountIds,
        dateRangeType: "AllTime",
        fromDate: "",
        graphType: "Pie",
        locationIds: accountInfoIdsFormatter(globalAccountState).locationIds,
        reportName: "GetTireInflationPressureGraphPerSize",
        toDate: "",
        pressureValue: pressureValue,
        tireManufacturerNames: [...new Set(tireManufacturerNames)].join(","),
        tireSizeName: [...new Set(tireSizeNames)].join(","),
        tireTypeName: [...new Set(tireTypeNames)].join(","),
        vehicleManufacturer: vehManufacturerNames.join(","),
        vehicleTypeName: [...new Set(vehTypeNames)].join(","),
        usedTires: excludeUsedTires
      };
    }
    setShowSpinner(true);
    tiresService.getTiresGraph(requestObject).then((data) => {
      let tireInflationGraphInfo = tireInflationDataSetter.setGraphData(data);
      tireInflationGraphInfo.graphType = chartType;
      setTireInflationGraphData(tireInflationGraphInfo);


      // static preset printData to allow only graph printing
      setPrintData({
        reportType: "Tires in Services : ",
        reportName: "In Service Tire Inflation",
        rowsData: [],
        headCells: [],
        avgValues: [],
        selectedColShow: [],
        filterApplied: true,
        filterFieldsApplied: filterData,
        filterForGraphApplied: true,
        graphType: chartType
      });
      setShowSpinner(false);
    });
  };

  const resetFilter = () => {
    setShowFilter(false);
    setSelectedManuf([])
    setSizeSelected([])
    setTypeSelected([])
    setSelectedVehManuf([])
    setvTypeSelected([])
    TireInflationResponseData()
  }

  const handleLocationChange = (event) => {
    setSelectedLocationId(event.target.value);
    GetPressureFromLocationId(event.target.value);
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
  };
  const [sizeList, setSizeList] = useState([]);
  const [sizeSelected, setSizeSelected] = useState([]);

  const [typeList, setTypeList] = useState([]);
  const [TypeSelected, setTypeSelected] = useState([]);

  const [vtypeList, setvTypeList] = useState([]);
  const [vTypeSelected, setvTypeSelected] = useState([]);

  const onClickFilter = () => {
    setShowFilter(true);
  };

  return (
    <div>
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="tire-inflation-container">
          <Card className="tire-inflation-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  {pressureType !== "simplified" && accountInfoIdsFormatter(globalAccountState).locationIds.length > 1 && (
                    <div className="select-container location-container">
                      <InputLabel id="chartType" className="select-label">
                        {globalizationMapping.REPORTCOMMONWORDS.LocationId}
                      </InputLabel>
                      <Select 
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        fullWidth={true} 
                        value={selectedLocationId} 
                        onChange={handleLocationChange} 
                        labelId="chartType" 
                        id="selectLocation" 
                        variant="outlined">
                        {accountInfoIdsFormatter(globalAccountState).locationIds.map((location, i) => {
                          return (
                            <option value={location} key={i}>
                              {location}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                  <div className="search-table-container"></div>
                  <div className="display-columns-container" id="dashboard-display-column"></div>
                </div>
                <FilterPrintEmailExportSetButtons
                  onClickFilter={onClickFilter}
                  onClickEmail={onClickEmail}
                  onClickPrint={onClickPrint}
                  onClickExport={onClickExport}
                  toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)}
                  printData={printData}
                  ref={ref}
                  onlyFilterApplied={onlyFilterApplied}
                  noData={false}
                  isComponentVisible={isComponentVisible}
                />
              </div>

              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <div className="select-container">
                    <InputLabel id="chartType" className="select-label">
                      {globalizationMapping.REPORTCOMMONWORDS.ChartType}
                    </InputLabel>
                    <Select 
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      fullWidth={true} 
                      value={chartType} 
                      onChange={handleChartType} 
                      labelId="chartType" 
                      id="selectChartType" 
                      variant="outlined">
                      <option value="Pie">{globalizationMapping.REPORTCOMMONWORDS.PieChart}</option>
                      <option value="Bar">{globalizationMapping.REPORTCOMMONWORDS.BarChart}</option>
                    </Select>
                  </div>
                </Grid>
                {/* Pressure Type */}
                <Grid item xs={4}>
                  <div className="select-container">
                    <InputLabel id="pressureType" className="select-label">
                      {globalizationMapping.REPORTCOMMONWORDS.PressureType}
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      fullWidth={true}
                      value={pressureType}
                      name="Pressure Type"
                      variant="outlined"
                      onChange={handlePressureType}
                      labelId="pressureType"
                      id="selectPressureType"
                    >
                      <option value="recommendedPressure">{globalizationMapping.VIEW_LOCATION.RECOMMENDED}</option>
                      <option value="fixedTargetPressure">{globalizationMapping.REPORTCOMMONWORDS.FixedTargetPresure}</option>
                      <option value="simplified">{globalizationMapping.REPORTCOMMONWORDS.Simplified}</option>
                    </Select>
                  </div>
                </Grid>
                {/* Pressure Value */}
                {pressureType !== "simplified" && (
                  <Grid item xs={4}>
                    <div className="select-container">
                      {pressureType !== "simplified" && (
                        <InputLabel id="pressureValue" className="select-label">
                          {pressureUnitLabel}
                        </InputLabel>
                      )}
                      {pressureType === "recommendedPressure" && <TextField fullWidth={true} variant="outlined" id="pressureValue" defaultValue={pressureValue} inputProps={{ maxLength: 50 }}/>}
                      {pressureType === "fixedTargetPressure" && (
                        <TextField fullWidth={true} variant="outlined" type="number" id="pressureValue" defaultValue={pressureValue} onChange={(e)=> handlePressureValue(commonUtils.minmaxInt(e.target.value, 0, 999999))} inputProps={{ maxLength: 50 }}/>
                      )}
                    </div>
                  </Grid>
                )}
                {/* Pressure Unit */}

                {pressureType == "simplified" && (
                  <Grid item xs={4}>
                    <div className="select-container">
                      <InputLabel id="pressureUnit" className="select-label">
                        {globalizationMapping.USER_PREFERENCE.PRESSUREUNIT}
                      </InputLabel>
                      <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        fullWidth={true} 
                        variant="outlined" 
                        value={pressureUnit} 
                        onChange={handlePressureUnit} 
                        labelId="pressureUnit" 
                        id="selectPressureUnit">
                        <option value="PressureLimit">{globalizationMapping.REPORTCOMMONWORDS.PressureLimits}</option>
                        <option value="PressurePercent">{globalizationMapping.REPORTCOMMONWORDS.PercRecommended}</option>
                      </Select>
                    </div>
                  </Grid>
                )}
                {pressureType === "simplified" && (
                  <Grid item xs={4}>
                    <div className="select-container">
                      <InputLabel id="Upper Limit(Hot)" className="select-label">
                        {globalizationMapping.REPORTCOMMONWORDS.UpperLimitH}
                      </InputLabel>
                      <TextField fullWidth={true} variant="outlined" defaultValue={upperLimitHot} onChange={(e)=>handleUpperLimitHot(commonUtils.minmaxInt(e.target.value, 0, 999999))} type="number" inputProps={{ maxLength: 50 }}/>
                    </div>
                  </Grid>
                )}
                {pressureType === "simplified" && (
                  <Grid item xs={4}>
                    <div className="select-container">
                      <InputLabel id="Lower Limit(Hot)" className="select-label">
                        {globalizationMapping.REPORTCOMMONWORDS.LowerLimitH}
                      </InputLabel>
                      <TextField fullWidth={true} variant="outlined" defaultValue={lowerLimitHot} onChange={(e)=>handleLowerLimitHot(commonUtils.minmaxInt(e.target.value, 0, 999999))} type="number" inputProps={{ maxLength: 50 }}/>
                    </div>
                  </Grid>
                )}
                {pressureType === "simplified" && (
                  <Grid item xs={4}>
                    <div className="select-container">
                      <InputLabel id="Upper Limit(Cold)" className="select-label">
                        {globalizationMapping.REPORTCOMMONWORDS.UpperLimitC}
                      </InputLabel>
                      <TextField fullWidth={true} variant="outlined" defaultValue={upperLimitCold} onChange={(e)=>handleUpperLimitCold(commonUtils.minmaxInt(e.target.value, 0, 999999))} type="number" inputProps={{ maxLength: 50 }}/>
                    </div>
                  </Grid>
                )}
                {pressureType === "simplified" && (
                  <Grid item xs={4}>
                    <div className="select-container">
                      <InputLabel id="Lower Limit(Cold)" className="select-label">
                        {globalizationMapping.REPORTCOMMONWORDS.LowerLimitC}
                      </InputLabel>
                      <TextField fullWidth={true} variant="outlined" defaultValue={lowerLimitCold} onChange={(e)=>handleLowerLimitCold(commonUtils.minmaxInt(e.target.value, 0, 999999))} type="number" inputProps={{ maxLength: 50 }}/>
                    </div>
                  </Grid>
                )}
                {/* </div>} */}
                <Grid item xs={3} style={{ display: "flex" }}>
                  <div className="exclude-used-tires">
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                  <div className="exclude-used-tires">
                    <Button variant="contained" color="primary" onClick={handleUpdateChart}>
                      {globalizationMapping.INSPECT.UPDATE}
                    </Button>
                  </div>
                </Grid>

                {/* Update Chart   */}
                <Grid item xs={12}></Grid>
              </Grid>
              <TireInflationGraph tireInflationGraphData={tireInflationGraphData} />
            </CardContent>
          </Card>
        </div>
      )}
      <TireInflationFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        masterData={masterData}
        manufList={manufList}
        vehManufList={vehManufList}
        setSelectedManuf={setSelectedManuf}
        selectedManuf={selectedManuf}
        sizeList={sizeList}
        setSizeList={setSizeList}
        sizeSelected={sizeSelected}
        setSizeSelected={setSizeSelected}
        typeList={typeList}
        setTypeList={setTypeList}
        TypeSelected={TypeSelected}
        setTypeSelected={setTypeSelected}
        selectedVehManuf={selectedVehManuf}
        setSelectedVehManuf={setSelectedVehManuf}
        vtypeList={vtypeList}
        setvTypeList={setvTypeList}
        vTypeSelected={vTypeSelected}
        setvTypeSelected={setvTypeSelected}
        handleApplyFilter={handleApplyFilter}
        resetFilter={resetFilter}
      />
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        notable={true}
      // noGraph={true}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        notable={true}
      // noGraph={true}
      />
      <EmailModalComponent
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        notable={true}
      // noGraph={true}
      />
    </div>
  );
};

export default TireInflation;
