import React, { useEffect, useState, useContext } from "react";
import "./RemovedTire.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import OtherTiresNavigation from "../../../other-tires-navigation/OtherTiresNavigation";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Grid
} from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import RemovedTireDataSetter from "../../../../../services/RemovedTireUtils";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import RecordDetails from "../record-details/RecordDetails";
import TireDetails from "../tire-details/TireDetails";
import ImageContainer from "../image-container/ImageContainer";
import GraphContainer from "../graph-container/GraphContainer";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import Filter from "../../../../../../../common-components/filter/components/main-container/Filter";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'


const RemovedTire = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const tiresService = new TiresService();
  const removedTireDataSetter = new RemovedTireDataSetter();

  const [tireSerialColOptions, setTireSerialColOptions] = useState();
  const [tireSerial, setTireSerialColumns] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [onFirstLoad, setFirstLoad] = useState(0);
  const [removedTireInfo, setRemovedTireInfo] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [printData, setPrintData] = useState(removedTireInfo);
  const [filterApplied, setFilterApplied] = useState(false);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [noData, setNoData] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterOptionMainCategory, setFilterOptionMainCategory] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [tireSerialOptionsUpdated, setTireSerialOptionsUpdated] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [originalRemovedTireData, setOriginalRemovedTireData] = useState();


  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" }
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: []
    }
  });
  const globalizationMapping = new Globalization();

  useEffect(() => {
    RemovedTireResponseData(excludeUsedTires);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const RemovedTireResponseData = (excludeUsedTiresValue) => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    let tireSerialObject = {
      accountIds: accountIds.toString(),
      locationIds: locationIds.toString(),
      reportName: "removedTire"
    };
    setFirstLoad(1);
    setShowSpinner(true);
    tiresService.getTireSerial(tireSerialObject).then((data) => {
      let tireList = removedTireDataSetter.setTireData(data);
      const tireSerialOptionsUpdatedTemp = [];
      let i = 0;
      tireList.forEach((tire) => {
        let isDisabled = false;
        if (i >= 50) isDisabled = true;

        let tireOption = {
          ...tire,
          disabled: isDisabled
        };
        i++;
        tireSerialOptionsUpdatedTemp.push(tireOption);
      });
      setTireSerialOptionsUpdated(tireSerialOptionsUpdatedTemp);
      setTireSerialColOptions(tireList);
      setTireSerialColumns(tireList.slice(0, 1));
      let accountInfo = globalAccountState.accountInfo;
      let userSetting = accountInfo.userSetting;

      let requestObject = {
        userId: userSetting.userId,
        accountIds: accountIds,
        dateRangeType: filterOptionMainCategory,
        fromDate: dateRangeFilter.fromDate,
        locationIds: locationIds,
        reportName: "Removed_Tire",
        reportOption: "",
        toDate: dateRangeFilter.toDate,
        usedTires: excludeUsedTiresValue.toString(),
        tireIds: removedTireDataSetter.getTireIds(tireList)
      };
      tiresService.getTiresReport(requestObject).then((tireReportData) => {
        let removedTireInfo = removedTireDataSetter.setData(tireReportData);
        setRemovedTireInfo(removedTireInfo);
        setOriginalRemovedTireData(removedTireInfo);
        let filterOptions = {
          categories: [
            {
              name: globalizationMapping.REPORTTABLE.MANUFACTURER,
              value: "ManufacturerName"
            },
            { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
            { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
            {
              name: globalizationMapping.REPORTTABLE.COMPOUND,
              value: "CompoundName"
            }
          ],
          categoryData: {
            ManufacturerName: [],
            SizeName: [],
            TypeName: [],
            CompoundName: []
          }
        };
        let filterOptionsInfo = removedTireDataSetter.setFilterOptionsData(
          filterOptions,
          removedTireInfo.rowsData
        );
        setFilterOptions(filterOptionsInfo);
        removedTireInfo.rowsData.length === 0
          ? setNoData(true)
          : setNoData(false);
        setPrintData({
          reportType: "Other Tire Reports : ",
          reportName:
            globalizationMapping.TIREREPORTS.OTHERTIRES.RemoveTireReport,
          rowsData: removedTireInfo.rowsData,
          headCells: removedTireInfo.headCells,
          filterApplied: filterApplied
        });
        setShowSpinner(false);
      });
    });
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onBlurChangeTireSerial = () => {
    if (
      document.querySelector('#tire-serial-list div[aria-expanded="false"]') &&
      onFirstLoad !== 1
    ) {
      setShowLoading(true);
      let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
      let accountIds = accountInfoObject.accountIds;
      let locationIds = accountInfoObject.locationIds;
      let tireSerialObject = {
        accountIds: accountIds,
        dateRangeType: "AllTime",
        fromDate: "",
        locationIds: locationIds,
        reportName: "Removed_Tire",
        reportOption: "",
        toDate: "",
        usedTires: "true", 
        tireIds: removedTireDataSetter.getTireIds(tireSerial)
      };
      tiresService.getTiresReport(tireSerialObject).then((tireReportData) => {
        let removedTireInfo = removedTireDataSetter.setData(tireReportData);
        setRemovedTireInfo(removedTireInfo);
        setOriginalRemovedTireData(removedTireInfo);
        let filterOptions = {
          categories: [
            {
              name: globalizationMapping.REPORTTABLE.MANUFACTURER,
              value: "ManufacturerName"
            },
            { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
            { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
            {
              name: globalizationMapping.REPORTTABLE.COMPOUND,
              value: "CompoundName"
            }
          ],
          categoryData: {
            ManufacturerName: [],
            SizeName: [],
            TypeName: [],
            CompoundName: []
          }
        };
        filterOptions = removedTireDataSetter.setFilterOptionsData(
          filterOptions,
          removedTireInfo.rowsData
        );
        removedTireInfo.rowsData.length === 0
          ? setNoData(true)
          : setNoData(false);
        setFilterOptions(filterOptions);
        setPrintData({
          reportType: "Other Tire Reports : ",
          reportName:
            globalizationMapping.TIREREPORTS.OTHERTIRES.RemoveTireReport,
          rowsData: removedTireInfo.rowsData,
          headCells: removedTireInfo.headCells,
          filterApplied: filterApplied
        });
        setSearchValue("");
        setShowLoading(false);
      });
    }
    setFirstLoad(2);
  };

  const onClickPrint = () => {
    setModalHandler({ handlePrint: true });
  };
  const onClickEmail = () => {
    setModalHandler({ handleEmail: true });
  };
  const onClickExport = (type) => {
    if (type === "pdf") {
    setModalHandler({ handlePdf: true });
    }
  };
  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    searchText === "" ? setFilterApplied(false) : setFilterApplied(true);
    let originalRemovedTireDataStore, filteredData;
    originalRemovedTireDataStore = originalRemovedTireData?.rowsData;
    filteredData = removedTireDataSetter.search(
      originalRemovedTireDataStore,
      searchText
    );
    let updatedTableData = {
      rowsData: filteredData
    };

    setRemovedTireInfo(updatedTableData);
    setPrintData({
      reportType: "Other Tire Reports : ",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.RemoveTireReport,
      rowsData: filteredData,
      headCells: removedTireInfo.headCells,
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const changeTireSerial = (selected) => {
    let selectedColumns = selected;
    if (selected.length === 50) {
      const tireSerialOptionsUpdatedTemp = [];
      tireSerialColOptions &&
        tireSerialColOptions.forEach((tire) => {
          let isDisabled = true;
          selected.forEach((selectedTire) => {
            if (tire.value === selectedTire.value) isDisabled = false;
          });

          let tireOption = {
            ...tire,
            disabled: isDisabled
          };
          tireSerialOptionsUpdatedTemp.push(tireOption);
        });
      setTireSerialOptionsUpdated(tireSerialOptionsUpdatedTemp);
    } else {
      const tireSerialOptionsUpdatedTemp = [];
      tireSerialColOptions &&
        tireSerialColOptions.forEach((tire) => {
          let tireOption = {
            ...tire,
            disabled: false
          };
          tireSerialOptionsUpdatedTemp.push(tireOption);
        });
      setTireSerialOptionsUpdated(tireSerialOptionsUpdatedTemp);
    }
    setTireSerialColumns(selectedColumns);
  };
  // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    setSearchValue("");

    setFilterApplied(true);
    let originalTableDataStore = originalRemovedTireData?.rowsData;
    let filteredData = removedTireDataSetter.filterTable(
      appliedFilter,
      originalTableDataStore
    );
    setRemovedTireInfo({ ...removedTireInfo, rowsData: filteredData });
    setPrintData({ ...printData, rowsData: filteredData });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    confirmApplyDateFilter(appliedFilter);
  };
  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {

    setFilterApplied(true);
    let originalTableDataStore = originalRemovedTireData?.rowsData;
    let filteredData = removedTireDataSetter.filterTable(
      appliedFilter,
      originalTableDataStore
    );
    setRemovedTireInfo({ ...removedTireInfo, rowsData: filteredData });
    let data = {
      rowsData: filteredData
    };
    setPrintData({ ...printData, rowsData: data });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(appliedFilter).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setFilterApplied(false);
    setRemovedTireInfo(originalRemovedTireData);
    setNoData(false);
    setSearchValue("");
    setOnlyFilterApplied(false)
  };

  const updateFilterMain = (filterval) => {
    setFilterOptionMainCategory(filterval);
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    setSearchValue("");
    RemovedTireResponseData(event.target.checked);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  return (
    <div className="container">
      <OtherTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="removed-tire-container">
          {showLoading && <Spinner />}
          <Card className="removed-tire-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form
                      className="search-table-container"
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        {globalizationMapping.REPORTCOMMONWORDS.SearchTable}
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={
                          globalizationMapping.REPORTCOMMONWORDS.Search
                        }
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="keyword-search-icon-img"
                                alt=""
                                src={SearchIcon}
                              />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="tires-select-container" id="tire-serial-list">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">
                        {globalizationMapping.REPORTCOMMONWORDS.TiresSelected}
                      </span>
                      <span className="selection-limit-text">
                        (Max Limit : 50)
                      </span>
                    </InputLabel>
                    {tireSerialOptionsUpdated && tireSerial && (
                      <MultiSelect
                        options={tireSerialOptionsUpdated}
                        value={tireSerial}
                        onChange={changeTireSerial}
                        onMenuToggle={onBlurChangeTireSerial}
                        hasSelectAll={false}
                        overrideStrings={{
                          selectSomeItems:
                            globalizationMapping.REPORTCOMMONWORDS.SELECT,
                          allItemsAreSelected: "All Tires",
                          selectAll:
                            globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                          search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                        }}
                      />
                    )}
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={excludeUsedTires}
                          onChange={handleExcludeUsedTires}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              {removedTireInfo &&
                removedTireInfo.rowsData &&
                removedTireInfo.rowsData.map((eachTireData, index) => (
                  <>
                    <div className="record-details">
                      <RecordDetails eachTireData={eachTireData} key={index} />
                    </div>
                    <div className="image-details">
                      <ImageContainer
                        eachTireData={eachTireData.RemoveTireImages}
                        key={index}
                      />
                    </div>
                    <div className="tire-details">
                      <TireDetails eachTireData={eachTireData} key={index} />
                    </div>
                    <div className="graph-details">
                      <GraphContainer
                        eachTireData={eachTireData.TireGraphData}
                        key={index}
                      />
                    </div>
                  </>
                ))}
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <EmailModalComponent
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
        removedTire={true}
      />
      <Filter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        confirmApplyFilter={confirmApplyFilter}
        confirmApplyDateFilter={confirmApplyDateFilter}
        resetTableFilter={resetTableFilter}
        onFilterUpdateMain={updateFilterMain}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
      />
    </div>
  );
};

export default RemovedTire;
