import React, { useEffect, useState } from "react";
import "../../../../../common-styles/TableStyle.css";
import "./Table.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  InputLabel,
  TextField,
  FormHelperText,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../globalization";
import { Button } from "semantic-ui-react";
import Modal from "@material-ui/core/Modal";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import FlashMessage from "../../../../../common-components/pop-up-notification/notification-snackbar";
import CommonUtils from "../../../../../services/utils";
import Info from '@material-ui/icons/Info';

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  popper: {
    padding: 10,
    border: '1px solid #1C72D0',
    borderRadius: '5px',
    background: '#B1D2FF',
    fontSize: '12px',
    marginTop: 10
  },
  table: {
    minWidth: 600,
  },
  container: {
    maxHeight: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
  },
  next: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
}));

const VehicleHistoryListTable = ({
  tableData,
  showColumns,
  vehicleId,
  recallAPI,
  userPref,
}) => {
  const [vehicleHistoryListTableData, setVehicleHistoryListTableData] =
    useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateMiles, setUpdateMiles] = useState("");
  const [updateHour, setUpdateHour] = useState("");
  const [comments, setComments] = useState("");
  const [latestRecordData, setLatestRecordData] = useState([]);
  const styling = useStyles();
  const headerApi = new HeaderApi();
  const [flashMessageState, setFlashMessageState] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [errorsDistance, setErrorsDistance] = useState(false);
  const [errorsHours, setErrorsHours] = useState(false);
  const [checkDisabled, setCheckDisabled] = useState(false)
  const commonUtils = new CommonUtils();

  // USED FOR SORTING
  function descendingComparator(a, b, sOrderBy) {
    if (b[sOrderBy] < a[sOrderBy]) {
      return -1;
    }
    if (b[sOrderBy] > a[sOrderBy]) {
      return 1;
    }
    return 0;
  }

  function descendingComparatorDate(a, b, sOrderBy) {
    let aDay, aMonth, aYear, bMonth, bDay, bYear;
    if (sessionStorage.userLanguage === "en-uk") {
      [aDay, aMonth, aYear] = a[sOrderBy].split("/");
      [bDay, bMonth, bYear] = b[sOrderBy].split("/");
    } else {
      [aMonth, aDay, aYear] = a[sOrderBy].split("/");
      [bMonth, bDay, bYear] = b[sOrderBy].split("/");
    }
    if (new Date(bYear, bMonth - 1, bDay) < new Date(aYear, aMonth - 1, aDay)) {
      return -1;
    }
    if (new Date(bYear, bMonth - 1, bDay) > new Date(aYear, aMonth - 1, aDay)) {
      return 1;
    }
    return 0;
  }

  function getComparator(sorder, sOrderBy) {
    if (sOrderBy === "Date") {
      return sorder === "desc"
        ? (a, b) => descendingComparatorDate(a, b, sOrderBy)
        : (a, b) => -descendingComparatorDate(a, b, sOrderBy);
    } else {
      return sorder === "desc"
        ? (a, b) => descendingComparator(a, b, sOrderBy)
        : (a, b) => -descendingComparator(a, b, sOrderBy);
    }
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const sorder = comparator(a[0], b[0]);
      if (Number(order) !== 0) {
        return sorder;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    setVehicleHistoryListTableData(tableData);
    let arr = commonUtils.rowPerPageSelector(vehicleHistoryListTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  useEffect(() => {
    const maxObj = vehicleHistoryListTableData.rowsData.sort(
      (a, b) => a.EventId - b.EventId
    );
    if (maxObj && maxObj.length > 0 && maxObj[maxObj.length - 1].Event.toLowerCase() === "updatehours") {
      setCheckDisabled(false)
    } else {
      setCheckDisabled(true)
    }
  })

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const userPrefSelection = () => {
    return userPref.toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const editRecord = () => {
    const maxObj = vehicleHistoryListTableData.rowsData.sort(
      (a, b) => a.EventId - b.EventId
    );
    if (maxObj.length >= 1) {
      setModalOpen(true);
      setLatestRecordData(maxObj[maxObj.length - 1]);
    }
  };
  const modalClose = () => {
    setModalOpen(false);
  };
  function onChangeListener(from, value) {
    if (from === "hours") {
      setUpdateHour(value);
      setErrorsHours(false)
    } else if (from === "miles") {
      setUpdateMiles(value);
      setErrorsDistance(false)
    } else {
      if (from === "comment") {
        setComments(value);
      }
    }
  }

  const showError = (from) => {
    if (from === "miles") {
      return (
        (updateMiles == "" && updateMiles == null) || errorsDistance
      );
    } else {
      return (
        (updateHour == "" && updateHour == null) || errorsHours
      );
    }
  };


  const latestRecordSubmit = () => {
    const payload = {
      vehicleId: Number(vehicleId),
      updatedHours: Number(updateHour),
      updatedMiles: Number(updateMiles),
      vehicleEventId: Number(latestRecordData.EventId),
      comments,
    };
    headerApi.getLatestVehicleHistoryData(payload).then((_data) => {
      setFlashMessageState({
        status: true,
        message: globalizationMapping.VEHICLEHISTORY.SuccsMsg,
        description: `${globalizationMapping.VEHICLEHISTORY.SuccsMsg1} ${vehicleId} ${globalizationMapping.VEHICLEHISTORY.SuccsMsg2}`,
      });
      setShowNotification(true);
      setModalOpen(false);
      recallAPI();
    });
  };
  const globalizationMapping = new Globalization();
  return (
    <div className="container">
      <div className={classes.root}>
        {showNotification && (
          <FlashMessage
            status={flashMessageState.status}
            message={flashMessageState.message}
            description={flashMessageState.description}
          />
        )}
        <Paper className={classes.paper}>
          <InputLabel className="filter-table-label">
            <span>
              <button
                className={checkDisabled ? "header-edit-latest-record-change-modal-disabled" : "header-change-modal-confirm-btn"}
                style={{ width: "10em", float: "right", marginRight: "15px" }}
                onClick={editRecord}
                disabled={checkDisabled}
              >
                {globalizationMapping.VEHICLEHISTORY.EDITLATESTRECORD}
              </button>
            </span>
          </InputLabel>
          <Modal open={modalOpen} disableScrollLock={true} onBackdropClick={modalClose}>
            <div className="header-change-modal-container center-modal">
              <button
                aria-hidden="true"
                onClick={modalClose}
                className="bootbox-close-button close"
                type="button"
              >
                <img
                  src={require("../../../../../assets/close-icon.svg")}
                  className="modal-close-icon"
                  alt="close-icon"
                />
              </button>
              <div
                style={{ marginLeft: "1.3em" }}
                className="header-change-modal-title"
              >
                {/* Update Hours and Miles */}
                {globalizationMapping.VEHICLEHISTORY.EDITLABEL}
              </div>
              <div className="bootbox-body">
                {/* justifyContent: 'space-between', */}
                <div style={{ display: "flex", padding: "0em 1.7em" }}>
                  <div style={{ padding: "1em 0.7em" }}>
                    <div>
                      <div className="sub-heading1-UHM">
                        {globalizationMapping.REPORTCOMMONWORDS.LastHour}
                      </div>
                      <div
                        className="sub-heading1-UHM"
                        style={{ color: "#000" }}
                      >
                        {latestRecordData.Hours}
                      </div>
                    </div>
                    <div style={{ marginTop: "2em" }}>
                      <div className="sub-heading2-UHM">
                        {globalizationMapping.VEHICLEHISTORY.ENTERHOURS}
                        <span className="required-mark">*</span>
                      </div>
                      <TextField
                        className="datepicker-popup"
                        variant="outlined"
                        value={updateHour}
                        onChange={(e) =>
                          onChangeListener("hours", commonUtils.minmaxInt(e.target.value, 0, 999999))
                        }
                        placeholder="Enter Hour"
                        helperText=""
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        onKeyPress={(event) => {
                          if (event?.key === '-' || event?.key === '+') {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                      {showError("hours") && (
                        <FormHelperText>
                          {globalizationMapping.INSPECT.ERRMSGFOREDITMODELHOURS}
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                  <div style={{ padding: "1em 6.7em" }}>
                    <div>
                      <div className="sub-heading1-UHM">
                        {userPref.toLowerCase() !== "metric" ? "Last Miles" : "Last KM"}
                      </div>
                      <div
                        className="sub-heading1-UHM"
                        style={{ color: "#000" }}
                      >
                        {latestRecordData.Distance}
                      </div>
                    </div>
                    <div style={{ marginTop: "2em" }}>
                      <div className="sub-heading2-UHM">
                        {userPref.toLowerCase() !== "metric"
                          ? "Enter Miles"
                          : "Enter KM"}
                        <span className="required-mark">*</span>
                      </div>
                      <TextField
                        className="datepicker-popup"
                        variant="outlined"
                        value={updateMiles}
                        onChange={(e) =>
                          onChangeListener("miles", commonUtils.minmaxInt(e.target.value, 0, 999999))
                        }
                        placeholder={
                          userPref.toLowerCase() !== "metric"
                            ? "Enter Miles"
                            : "Enter KM"
                        }
                        helperText=""
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        onKeyPress={(event) => {
                          if (event?.key === '-' || event?.key === '+') {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 50 }}
                      />

                      {showError("miles") && (
                        <FormHelperText>
                          {globalizationMapping.INSPECT.ERRMSGFOREDITMODELMILES}
                        </FormHelperText>)
                      }
                    </div>
                  </div>
                  <div style={{ padding: "1em 0.7em" }}>
                    <div>
                      <div className="sub-heading2-UHM">
                        {globalizationMapping.REPORTTABLE.COMMENTS}
                        {/* <span className="required-mark">*</span> */}
                      </div>
                      <textarea
                        className="textArea"
                        rows="4"
                        cols="50"
                        name={comments}
                        onChange={(e) =>
                          onChangeListener("comment", commonUtils.minmaxInt(e.target.value, 0, 999999))
                        }
                        placeholder="Enter comments"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="footer-msg">
                <div className="action-buttons">
                  <Button
                    className={styling.cancel}
                    variant="contained"
                    color="secondary"
                    onClick={modalClose}
                  >
                    {globalizationMapping.FORMS.CANCEL}
                  </Button>
                  <Button
                    className="header-change-modal-confirm-btn1"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (updateMiles !== "" && updateHour !== "") {
                        latestRecordSubmit();
                      }
                      updateMiles === "" && setErrorsDistance(true);
                      updateHour === "" && setErrorsHours(true);
                    }}
                  >
                    {globalizationMapping.FORMS.SUBMIT}
                  </Button>
                </div>
              </footer>
            </div>
          </Modal>

          <InputLabel className="filter-table-label">
            <span className="table-toolbar-label-text-view-vehicle">{globalizationMapping.QUICKINSPECT.VEHICLEHISTORY}</span>
             <Tooltip title={globalizationMapping.INSPECT.NOTEMSGFORVEHICLEHISTORY} arrow>
                <IconButton size="small" style={{marginRight: '5px', }}>
                  <Info
                    style={{color: commonUtils.primaryBlue}}
                    alt="tooltip"
                  >
                  </Info>
                </IconButton>
              </Tooltip>
          </InputLabel>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {vehicleHistoryListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort(headCell.value)}
                        >
                          {headCell.value === "Distance"
                            ? userPrefSelection()
                            : headCell.label}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
                <tr className="table-bottom-line">
                  <td
                    colSpan={vehicleHistoryListTableData.headCells.length}
                  ></td>
                </tr>
              </TableHead>

              {vehicleHistoryListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    vehicleHistoryListTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          tabIndex={-1}
                          key={row.Key}
                        >
                          {showColumns.Date && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Date}
                            </TableCell>
                          )}
                          {showColumns.Event && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Event}
                            </TableCell>
                          )}
                          {showColumns.Hours && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Hours}
                            </TableCell>
                          )}
                          {showColumns.Distance && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Distance}
                            </TableCell>
                          )}
                          {showColumns.Comments && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Comments}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {vehicleHistoryListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={8}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={vehicleHistoryListTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default VehicleHistoryListTable;
