import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";

class ByVehicleModelDataSetter {
  constructor() {
    this.data = {};
    this.byVehicleModelData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  formatData(registeredDate) {
    let date, formattedDate;
    if (registeredDate) {
      let dateString = registeredDate.split("T");
      date = dateString[0];
      formattedDate = date;
    } else {
      date = null;
      formattedDate = date;
    }

    return formattedDate;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.BrandNumber.toLowerCase().includes(searchText) ||
        item.TireSerialNo.toLowerCase().includes(searchText) ||
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.VehicleManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.VehicleTypeName.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.WheelPosition.toLowerCase().includes(searchText) ||
        item.WheelPosition.toLowerCase().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value]) && rowData[category.value] !== "") {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData, dateRangeFilter) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (rowData.BrandNumber === "Avg") return false;
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });

    if (dateRangeFilter.fromDate !== "" && dateRangeFilter.toDate !== "") {
      let dateArray = [];
      filteredTable.forEach((item1, index1) => {
        let lowerLimit, upperLimit, currentLimit;
        lowerLimit = new Date(dateRangeFilter.fromDate);
        upperLimit = new Date(dateRangeFilter.toDate);
        currentLimit = new Date(item1.InstallationDate);

        if (currentLimit > lowerLimit && currentLimit < upperLimit) {
          dateArray.push(item1);
        }
      });
      filteredTable = dateArray;
    }

    let wheelPos = "";
    let avgs = {
      percentageWorn: 0,
      totalCost: 0,
      threadDepth: 0,
      costPerHour: 0,
      rows: 0
    };
    let tempArr = [];
    filteredTable.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */

      if (wheelPos === "") {
        wheelPos = item.WheelPosition;
      } else if (wheelPos !== item.WheelPosition) {

        let avgRow = {
          Key: index,
          BrandNumber: "Avg",
          TireSerialNo: "",
          ManufacturerName: "",
          VehicleManufacturerName: "",
          SizeName: "",
          TypeName: "",
          VehicleTypeName: "",
          CompoundName: "",
          RatingName: "",
          ActualWheelPosition: "",
          WheelPosition: "",
          OriginalTread: "",
          OutsideTread: "",
          InsideTread: "",
          PercentageWorn: this.UptoDecimal(avgs.percentageWorn / avgs.rows),
          EvaluationNo: "",
          InstallationDate: "",
          VehicleId: "",
          TotalCost: this.UptoDecimal(avgs.totalCost / avgs.rows),
          TotalHours: "",
          Hoursmm: this.UptoDecimal(avgs.threadDepth / avgs.rows),
          ProjectedHours: "",
          CostHourProj: this.UptoDecimal(avgs.costPerHour / avgs.rows),
          HoursLeft: ""
        };
        tempArr.push(avgRow);

        wheelPos = item.WheelPosition;
        avgs = {
          percentageWorn: 0,
          totalCost: 0,
          threadDepth: 0,
          costPerHour: 0,
          rows: 0
        };
      } else {
        return;
      }
      tempArr.push(item);

      avgs.costPerHour = avgs.costPerHour + Number(this.UptoDecimal(item.CostHourProj) ? this.UptoDecimal(item.CostHourProj) : 0);
      avgs.percentageWorn = avgs.percentageWorn + Number(item.PercentageWorn ? item.PercentageWorn : 0);
      avgs.rows++;
      avgs.threadDepth = avgs.threadDepth + Number(this.UptoDecimal(item.Hoursmm) ? this.UptoDecimal(item.Hoursmm) : 0);
      avgs.totalCost = avgs.totalCost + Number(item.TotalCost ? item.TotalCost : 0);

      if (index === filteredTable.length - 1) {
        let avgRow = {
          Key: index,
          BrandNumber: "Avg",
          TireSerialNo: "",
          ManufacturerName: "",
          VehicleManufacturerName: "",
          SizeName: "",
          TypeName: "",
          VehicleTypeName: "",
          CompoundName: "",
          RatingName: "",
          ActualWheelPosition: "",
          WheelPosition: "",
          OriginalTread: "",
          OutsideTread: "",
          InsideTread: "",
          PercentageWorn: this.UptoDecimal(avgs.percentageWorn / avgs.rows),
          EvaluationNo: "",
          InstallationDate: "",
          VehicleId: "",
          TotalCost: this.UptoDecimal(avgs.totalCost / avgs.rows),
          TotalHours: "",
          Hoursmm: this.UptoDecimal(avgs.threadDepth / avgs.rows),
          ProjectedHours: "",
          CostHourProj: this.UptoDecimal(avgs.costPerHour / avgs.rows),
          HoursLeft: ""
        };
        tempArr.push(avgRow);

        wheelPos = item.WheelPosition;
        avgs = {
          percentageWorn: 0,
          totalCost: 0,
          threadDepth: 0,
          costPerHour: 0,
          rows: 0
        };
      }
    });
    return tempArr;
  }
  UptoDecimal(item) {
    return Math.round(item * 100) / 100;
  }
  avgValues = (byVehicleModelData) => {
    let percentageWorn = 0,
      totalCost = 0,
      Hoursmm = 0,
      costPerHourProj = 0;
    byVehicleModelData.forEach((item, index) => {
      if (item.BrandNumber === "Avg") return;
      percentageWorn += Number(item.PercentageWorn);
      totalCost += Number(item.TotalCost);
      Hoursmm += Number(item.Hoursmm);
      costPerHourProj += Number(item.CostHourProj);
    });

    let totalCostAvg = Math.round(Number(totalCost) / byVehicleModelData.length);
    let HoursmmAvg = Math.round(Number(Hoursmm) / byVehicleModelData.length);
    let costPerHourProjAvg = parseFloat(costPerHourProj / byVehicleModelData.length).toFixed(2);
    let percentageWornAvg = Math.round(percentageWorn / byVehicleModelData.length);

    const avgValues = {
      BrandNumber: "",
      TireSerialNo: "",
      ManufacturerName: "",
      VehicleManufacturerName: "",
      SizeName: "",
      TypeName: "",
      VehicleTypeName: "",
      CompoundName: "",
      RatingName: "",
      WheelPosition: "",
      OriginalTread: "",
      OutsideTread: "",
      InsideTread: "",
      PercentageWorn: percentageWornAvg,
      EvaluationNo: "",
      InstallationDate: "",
      VehicleId: "",
      TotalCost: totalCostAvg,
      TotalHours: "",
      Hoursmm: HoursmmAvg,
      ProjectedHours: "",
      CostHourProj: costPerHourProjAvg,
      HoursLeft: ""
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.data;
    if (reportData !== null || reportData !== undefined) {
      reportData.sort((n, p) => {
        if (n.wheelPosition > p.wheelPosition) {
          return 1;
        } else if (n.wheelPosition === p.wheelPosition){
          return 0;
        } else {
          return -1;
        }
      });
      let wheelPos = reportData[0].wheelPosition;

      let avgs = {
        percentageWorn: 0,
        totalCost: 0,
        threadDepth: 0,
        costPerHour: 0,
        rows: 0
      };
      reportData.forEach((item, index) => {
        /** NOTE : the keys must match the headCells value to be able to SORT */

        if (wheelPos !== item.wheelPosition) {
          wheelPos = item.wheelPosition;
          let avgRow = {
            Key: index,
            BrandNumber: "Average",
            TireSerialNo: "",
            ManufacturerName: "",
            VehicleManufacturerName: "",
            SizeName: "",
            TypeName: "",
            VehicleTypeName: "",
            CompoundName: "",
            RatingName: "",
            ActualWheelPosition: "",
            WheelPosition: "",
            OriginalTread: "",
            OutsideTread: "",
            InsideTread: "",
            PercentageWorn: this.UptoDecimal(avgs.percentageWorn / avgs.rows),
            EvaluationNo: "",
            InstallationDate: "",
            VehicleId: "",
            TotalCost: this.UptoDecimal(avgs.totalCost / avgs.rows),
            TotalHours: "",
            Hoursmm: this.UptoDecimal(avgs.threadDepth / avgs.rows),
            ProjectedHours: "",
            CostHourProj: this.UptoDecimal(avgs.costPerHour / avgs.rows),
            HoursLeft: ""
          };
          this.byVehicleModelData.push(avgRow);

          wheelPos = item.wheelPosition;
          avgs = {
            percentageWorn: 0,
            totalCost: 0,
            threadDepth: 0,
            costPerHour: 0,
            rows: 0
          };
        }

          this.byVehicleModelData.push({
            Key: index,
            BrandNumber: item.brandNo ? item.brandNo : "",
            TireSerialNo: item.tireSerialNo ? item.tireSerialNo : "",
            ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
            VehicleManufacturerName: item.vehicleManufacturerName ? item.vehicleManufacturerName : "",
            SizeName: item.tireSizeName ? item.tireSizeName : "",
            TypeName: item.typeName ? item.typeName : "",
            VehicleTypeName: item.vehicleTypeName ? item.vehicleTypeName : "",
            CompoundName: item.compoundName ? item.compoundName : "",
            RatingName: item.ratingName ? item.ratingName : "",
            ActualWheelPosition: item.wheelPosition ? item.wheelPosition : "",
            WheelPosition: item.wheelPosition ? item.wheelPosition : "",
            OriginalTread: item.originalTread ? this.UptoDecimal(item.originalTread) : 0,
            OutsideTread: item.outsideTread ? this.UptoDecimal(item.outsideTread) : 0,
            InsideTread: item.insideTread ? this.UptoDecimal(item.insideTread) : 0,
            PercentageWorn: item.percentageWorn ? this.UptoDecimal(item.percentageWorn) : 0,
            EvaluationNo: item.evaluationNo ? item.evaluationNo : "",
            InstallationDate: item.installationDate ? this.formatData(item.installationDate) : "",
            VehicleId: item.vehicleNumber ? item.vehicleNumber : "",
            TotalCost: item.actualNetCost ? this.UptoDecimal(item.actualNetCost) : 0,
            TotalHours: item.totalHours ? this.UptoDecimal(item.totalHours) : 0,
            Hoursmm: this.UptoDecimal(item.hoursPerTreadDepth) ? this.UptoDecimal(item.hoursPerTreadDepth) : 0,
            ProjectedHours: this.UptoDecimal(item.projectedHours) ? this.UptoDecimal(item.projectedHours) : 0,
            CostHourProj: this.UptoDecimal(item.projectedCost) ? this.UptoDecimal(item.projectedCost) : 0,
            HoursLeft: this.UptoDecimal(item.hoursLeft) ? this.UptoDecimal(item.hoursLeft) : 0
          });
          avgs.costPerHour = avgs.costPerHour + Number(this.UptoDecimal(item.projectedCost) ? this.UptoDecimal(item.projectedCost) : 0);
          avgs.percentageWorn = avgs.percentageWorn + Number(item.percentageWorn ? item.percentageWorn : 0);
          avgs.rows++;
          avgs.threadDepth = avgs.threadDepth + Number(this.UptoDecimal(item.hoursPerTreadDepth) ? this.UptoDecimal(item.hoursPerTreadDepth) : 0);
          avgs.totalCost = avgs.totalCost + Number(item.actualNetCost ? item.actualNetCost : 0);


        if (index === reportData.length - 1) {
          let avgRow = {
            Key: index,
            BrandNumber: "Average",
            TireSerialNo: "",
            ManufacturerName: "",
            VehicleManufacturerName: "",
            SizeName: "",
            TypeName: "",
            VehicleTypeName: "",
            CompoundName: "",
            RatingName: "",
            ActualWheelPosition: "",
            WheelPosition: "",
            OriginalTread: "",
            OutsideTread: "",
            InsideTread: "",
            PercentageWorn: this.UptoDecimal(avgs.percentageWorn / avgs.rows),
            EvaluationNo: "",
            InstallationDate: "",
            VehicleId: "",
            TotalCost: this.UptoDecimal(avgs.totalCost / avgs.rows),
            TotalHours: "",
            Hoursmm: this.UptoDecimal(avgs.threadDepth / avgs.rows),
            ProjectedHours: "",
            CostHourProj: this.UptoDecimal(avgs.costPerHour / avgs.rows),
            HoursLeft: ""
          };
          this.byVehicleModelData.push(avgRow);

          wheelPos = item.wheelPosition;
          avgs = {
            percentageWorn: 0,
            totalCost: 0,
            threadDepth: 0,
            costPerHour: 0,
            rows: 0
          };
        }
      });
    }

    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.TiresByVehicleWheelPosition;

    if (columnDefinitions !== null  || columnDefinitions !== undefined) {
      columnDefinitions.forEach((item, index) => {
        let colDefnString = item.text.split(".");
        let masterKey = colDefnString[0];
        let key = colDefnString[1];
        let labelName = this.globalizationMapping[masterKey][key];
        let labelId = item.fieldName;

        this.headCells.push({
          key: labelId,
          keyIndex: index,
          value: labelId,
          label: labelName,
          numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
        });
      });
    }
    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.byVehicleModelData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.byVehicleModelData),
      selectedColShow: selectedColShow
    };
  }
}

export default ByVehicleModelDataSetter;
