/* eslint-disable no-unused-vars */
import { Card, CardContent, Grid, InputLabel, makeStyles, Menu, MenuItem,Popper,Paper ,Fade} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import HeaderApi from "../../../../common-components/header/services/header-apis";
import Spinner from "../../../../common-components/spinner/spinner";
import Globalization from "../../../../globalization";
import { StateContext } from "../../../../services/StateProvider";
import ViewTireNavigation from "../../navigation/view-tire-navigation";
import TireHistorySetter from "../../services/tire-history-list-utils";
import ViewVehicleListApis from "../../../view-vehicle/services/ViewVehicleListApis";
import "./ViewTire.css";
import TireHistoryListTableViewOnly from "../table/view-tire-history-table-ViewOnly";
import ViewTireGraphs from "../table/view-tire-graph";
import TransferTireModal from "../../../manage-tire/components/modals/transfer-tire/transfer-tire-modal";
import DeleteTireModal from "../../../manage-tire/components/modals/delete-tire/delete-tire-modal";
import ManageTireListApis from "../../../manage-tire/services/ManageTireListApis";
import ChangeTireDispositionModal from "../../../change-tire-disposition/components/modal/change-disp-modal";
import FlashMessage from "../../../../common-components/pop-up-notification/notification-snackbar";

import GreyDownArrowIcon from "../../../../assets/grey-down-arrow-icon.svg";
import ExportXls from "../../../../common-components/modal-components/export-types/ExportXls";
import BlueDownArrowIcon from "../../../../assets/blue-down-arrow-icon.svg";
import CommonUtils from "../../../../services/utils";
import infoLogo from "../../../../assets/Tooltip.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      marginBottom: "10px"
    }
  },
  inspect: {
    "&:hover": {
      opacity: 1
    },
    background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none"
  },
  edit: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "1px solid #EAF3FB",
      opacity: 1,
      boxShadow: "none"
    },
    background: "transparent",
    border: "1px solid #EAF3FB",
    borderRadius: 18,
    color: "#0079E6",
    fontSize: "14px !important",
    fontFamily: "Barlow  !important",
    opacity: 1,
    letterSpacing: 0,
    fontWeight: 500,
    zIndex: 0,
    boxShadow: "none"
  },
  action: {
    background: "transparent",
    color: "#0055A4",
    opacity: 1,
    zIndex: 0
  },
  popper: {
    padding: 10,
    border: '1px solid #1C72D0',
    borderRadius: '5px',
    background: '#B1D2FF',
    fontSize: '12px'
}
}));

const ViewTireViewOnly = () => {
  const headerApiObj = new HeaderApi();
  const manageTireServiceObj = new ManageTireListApis();
  const viewVehicleListService = new ViewVehicleListApis();
  const tireHistorySetter = new TireHistorySetter();
  const commonUtils = new CommonUtils();
  const locationObj = useLocation();
  const history = useHistory();
  const styles = useStyles();
  const globalizationMapping = new Globalization();

  const [globalAccountState] = useContext(StateContext);
  const [tireHistoryListTableData, setTireHistoryListTableData] = useState();
  const [showColumnsTireHistory, setShowColumnsTireHistory] = useState();
  const [accLocationDetails, setAccLocationDetails] = useState("");
  const [locationDetails, setLocationDetails] = useState({});
  const [masterDataSet, setMasterData] = useState({});
  const [userPreference, setUserPreference] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [tireDetails, setTireDetails] = useState({});
  const [dropDowns, setDropDowns] = useState({
    disposition: "-",
    status: "-",
    manufacturer: "-",
    size: "-",
    type: "-",
    star: "-",
    ind: "-",
    comp: "-",
    load: "-",
    speed: "-",
    fill: "-"
  });
  const [regDate, setRegDate] = useState("");
  const [storedImages, setStoredImages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperAnchorEl , setPopperAnchorEl] = useState(null);
  const [currentRoute, setCurrentRoute] = useState({ path: "identification" });
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);
  const [currLocationForTransfer, setCurrentLocationForTransfer] = useState("");
  const [isDisplayModalOpen, setDisplayModalOpen] = useState(false);
  const [modalActionDone, setModalActionDone] = useState(commonUtils.generateRandomNumber());
  const [showNotification, setShowNotification] = useState(false);
  const [flashMessageState, setFlashMessageState] = useState(locationObj);
  const [showSpinner, setShowSpinner] = useState(true);
  const [transferDone, setTransferDone] = useState(commonUtils.generateRandomNumber());
  const [noData, setNoData] = useState(false);
  const [printData, setPrintData] = useState(tireHistoryListTableData);
  const [exportDropdown, setExportDropdown] = useState(false);

  const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;

  useEffect(() => {
    ViewTireResponseData();
    InitNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, modalActionDone, transferDone]);

  const [popperState, setPopperState] = useState(false);

  const handlePopperOpen = target => {
      setPopperAnchorEl(popperAnchorEl ? null : target);
      setPopperState(true);
  };

  const handlePopperClose = () => {
      setPopperAnchorEl(null);
      setPopperState(false);
  };

  const ViewTireResponseData = () => {
    // eslint-disable-next-line no-unused-vars
    if (locationObj.state.tireId) {
      headerApiObj.getTireById(locationObj.state.tireId).then((tireData) => {
        if (tireData.model) {
          setTireDetails(tireData.model);

          let tireHistoryListTableInfo = tireHistorySetter.setData(tireData.model);
          setTireHistoryListTableData(tireHistoryListTableInfo);
          setShowColumnsTireHistory(tireHistoryListTableInfo.selectedColShow);
          tireHistoryListTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

          let arrImgs = [];
          if (tireData.model.imagesLocation !== null && tireData.model.imagesLocation !== "") arrImgs = tireData.model.imagesLocation.split(",");
          setStoredImages(arrImgs);

          let date = new Date(tireData.model.registeredDate);
          let newDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
          setRegDate(newDate);

          //Added code for XLS
          let rowData = tireHistoryListTableInfo.rowsData;
          setPrintData({
            reportType: "",
            reportName: "Tyre History",
            rowsData: rowData,
            headCells: tireHistoryListTableInfo.headCells,
            selectedColShow: tireHistoryListTableInfo.selectedColShow
          });
          //End code for XLS

          headerApiObj.getMasterDataWeb().then((masterData) => {
            if (masterData.model) {
              setMasterData(masterData.model);
              let dispo = masterData.model.tireDispositions.find((disp) => disp.dispositionId === tireData.model.dispositionId);
              let status = masterData.model.tireStatus.find((status) => status.statusId === tireData.model.tireStatusId);
              let manuf = masterData.model.tireManufacturers.find((manuf) => manuf.manufacturerId === tireData.model.manufacturerId);
              let size = masterData.model.tireSizes.find((size) => size.tireSizeId === tireData.model.sizeId);
              let type = masterData.model.tireTypes.find((type) => type.typeId === tireData.model.typeId);
              let ply = masterData.model.plyRating.find((ply) => ply.ratingId === tireData.model.plyRating);
              let star = masterData.model.starRating.find((star) => star.ratingId === tireData.model.starRatingId);
              let ind = masterData.model.tireIndCodes.find((ind) => ind.codeId === tireData.model.indCodeId);
              let comp = masterData.model.tireCompounds.find((comp) => comp.compoundId === tireData.model.compoundId);
              let load = masterData.model.tireLoadRatings.find((load) => load.ratingId === tireData.model.loadRatingId);
              let speed = masterData.model.tireSpeedRatings.find((speed) => speed.speedRatingId === tireData.model.speedRatingId);
              let fill = masterData.model.tireFillTypes.find((fill) => fill.fillTypeId === tireData.model.fillTypeId);

              const hasStarName = () => {
                if (ply !== undefined) {
                  return ply.ratingName;
                } else {
                  if (star !== undefined) {
                    return star.ratingName;
                  } else {
                    return "-";
                  }
                }
              };

              let starName = hasStarName();

              setDropDowns({
                disposition: dispo !== undefined ? dispo.dispositionName : "-",
                status: status ? status.statusName : "-",
                manufacturer: manuf ? manuf.manufacturerName : "-",
                size: size ? size.tireSizeName : "-",
                type: type ? type.typeName : "-",
                star: starName,
                ind: ind ? ind.codeName : "-",
                comp: comp ? comp.compoundName : "-",
                load: load ? load.ratingName : "-",
                speed: speed ? speed.speedRatingName : "-",
                fill: fill ? fill.fillTypeName : "-"
              });
            }
          });

          headerApiObj.getLocationById(tireData.model.locationId).then((data) => {
            if (data.model) {
              setLocationDetails(data.model);
            }
          });

          viewVehicleListService.manageVehicleGetDetailsByAccount(tireData.model.parentAccountId).then((accData) => {
            if (accData.model) {
              let loc = accData.model.locationList.find((location) => location.locationId === tireData.model.locationId);
              setAccLocationDetails(accData.model.accountName + " - " + loc.locationName);
              setCurrentLocationForTransfer(loc.locationName);
            }
          });

          manageTireServiceObj.getLocationListByAccount(tireData.model.parentAccountId).then((data) => {
            if (data.model) {
              setLocationList(data.model);
            }
          });
        }
      });

      headerApiObj.getUserPreference().then((data) => {
        if (data.model) {
          setUserPreference(data.model);
        }
      });
      setShowSpinner(false);
    } else {
      history.push("/home");
    }
  };

  const InitNotification = () => {
    if (locationObj.state && locationObj.state.updatedSection) {
      switch (locationObj.state.updatedSection) {
        case "editoldtire":
        case "successQuickInspect":
        case "inspecttire":
          if (locationObj.state.updateStatus) {
            setShowNotification(true);
          }
          break;
        case "removeinstalledtire":
          if (locationObj.state.updateStatus) {
            setShowNotification(true);
            setTransferDone(commonUtils.generateRandomNumber());
          }
          break;
        default:
          setTimeout(() => {
            setShowNotification(false);
          }, 5000);
          break;
      }
      setFlashMessageState(locationObj);
    }
  };

  //modal notifications
  const initModalNotifications = (notificationState) => {
    switch (notificationState.state.updatedSection) {
      case "tranfertire":
        if (notificationState.state.updateStatus) {
          setShowNotification(true);
          finishNotif(notificationState);
          setAccLocationDetails("");
        }
        break;
      case "changetiredisposition":
      case "updatedtirehistory":
      case "delteevent":
        if (notificationState.state.updateStatus) {
          setShowNotification(true);
          finishNotif(notificationState);
        }
        break;
      default:
        setTimeout(() => {
          setShowNotification(false);
        }, 5000);
        break;
    }
  };

  const toggleExport = () => {
    setExportDropdown(!exportDropdown);
  };

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
      }
    }
  };

  const finishNotif = (notificationState) => {
    if (notificationState.state.updateStatus) {
      window.scrollTo(0, 0);
      setModalActionDone(commonUtils.generateRandomNumber());
      setFlashMessageState(notificationState);
      setTimeout(() => {
        setShowNotification(false);
        setFlashMessageState({});
      }, 5000);
    }
  };

  const changedRoute = (selectedRoute) => {
    setCurrentRoute(selectedRoute);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDispModalClose = (dispNotificationData) => {
    setDisplayModalOpen(false);
    initModalNotifications({ state: dispNotificationData });
  };

  const handleTransferModalClose = (transferNotificationData) => {
    initModalNotifications({ state: transferNotificationData });
    setTransferModalOpen(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const tireFormCaptionsSetter = () => {
    if (locationDetails.treadDepthMethodId === 3) {
      return (<p className="field-label label-tire-details">{tireFormCaptions.MINTRD}</p>);
    } else if (locationDetails.treadDepthMethodId === 2) {
      return (<p className="field-label label-tire-details">{tireFormCaptions.MAXTRD}</p>)
    } else {
      return (<p className="field-label label-tire-details">{tireFormCaptions.AVGTRD}</p>)
    }
  }

  return (
    <React.Fragment>
      <div className="standalone-new-header-container">
        <ViewTireNavigation changedRoute={changedRoute} />

        {showNotification && flashMessageState.state && (
          <FlashMessage status={flashMessageState.state.updateStatus} message={flashMessageState.state.successMessage} description={flashMessageState.state.successMessageDesc} />
        )}

        {showSpinner || Object.keys(tireDetails).length === 0 || dropDowns.disposition === "-" ? (
          <Spinner />
        ) : (
          <div className="view-tire-installed-tires">
            <Card className="quick-inspect-list-card quick-inspect-list-card-tire">
              <CardContent>
                {currentRoute.path === "identification" && (
                  <div className="location-details-grid">
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.ACCOUNTLOCATION}</p>
                        <InputLabel>{accLocationDetails !== "" ? accLocationDetails : "Loading..."}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.TIRESERIALNO}</p>
                        <InputLabel>{tireDetails.tireSerialNo}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.BRANDNO}</p>
                        <InputLabel>{tireDetails.brandNo ? tireDetails.brandNo : "-"}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.REGDATE}</p>
                        <InputLabel>{regDate}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.EVALNO}</p>
                        <InputLabel>{tireDetails.evaluationNo ? tireDetails.evaluationNo : "-"}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.LOTNO}</p>
                        <InputLabel>{tireDetails.lotNo ? tireDetails.lotNo : "-"}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.PONO}</p>
                        <InputLabel>{tireDetails.poNo ? tireDetails.poNo : "-"}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.DISPO}</p>
                        <InputLabel>{dropDowns.disposition}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.STATUS}</p>
                        <InputLabel>{dropDowns.status}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.CURRHOURS}</p>
                        <InputLabel>{tireDetails.currentHours ? tireDetails.currentHours : 0}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">
                        {JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric" ? tireFormCaptions.CURRKM : tireFormCaptions.CURRMILES}
                        </p>
                        <InputLabel>{tireDetails.currentMiles ? tireDetails.currentMiles : 0}</InputLabel>
                      </Grid>
                    </Grid>
                  </div>
                )}

                {/* Desription Details */}
                {currentRoute.path === "description" && (
                  <div className="location-details-grid">
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.MANUFACTURER}</p>
                        <InputLabel>{dropDowns.manufacturer}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.SIZE}</p>
                        <InputLabel>{dropDowns.size}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.TYPE}</p>
                        <InputLabel>{dropDowns.type}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{dropDowns.size.includes("R") ? tireFormCaptions.STARRATING : tireFormCaptions.PLYRATING}</p>
                        <InputLabel>{dropDowns.star}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.INDCODE}</p>
                        <InputLabel>{dropDowns.ind}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.COMPOUND}</p>
                        <InputLabel>{dropDowns.comp}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.LOADRATING}</p>
                        <InputLabel>{dropDowns.load}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.SPEEDRATING}</p>
                        <InputLabel>{dropDowns.speed}</InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        {storedImages.length !== 0 && <p className="field-label label-tire-details">{tireFormCaptions.IMAGESCAPTION}</p>}
                        <Grid container spacing={1} className="uploaded-images-view">
                          {storedImages.length !== 0 &&
                            storedImages.map((source, key, index) => (
                              <Grid item sm={4} className="uploaded-image" key={index}>
                                <img alt={"stored-img-".concat((key + 1).toString())} src={source} width="160px" height="160px" />
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}

                {/* tread Details */}
                {currentRoute.path === "treaddepth" && (
                  <div className="location-details-grid">
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.ORGTREAD}</p>
                        <InputLabel>{tireDetails.originalTread}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.REMOVEAT}</p>
                        <InputLabel>{tireDetails.removeAt}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.PURCHASEDTREAD}</p>
                        <InputLabel>{tireDetails.purchasedTread}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.OUTTRD}</p>
                        <InputLabel>
                          {tireDetails.outsideTread +
                            " - " +
                            (isNaN(
                              Math.round(
                                ((parseInt(tireDetails.originalTread) - parseInt(tireDetails.outsideTread)) /
                                  (parseInt(tireDetails.originalTread) - parseInt(tireDetails.removeAt))) *
                                  100
                              )
                            )
                              ? 0
                              : Math.round(
                                  ((parseInt(tireDetails.originalTread) - parseInt(tireDetails.outsideTread)) /
                                    (parseInt(tireDetails.originalTread) - parseInt(tireDetails.removeAt))) *
                                    100
                                )) +
                            tireFormCaptions.PERCENTAGEWORN}
                        </InputLabel>
                      </Grid>
                      {locationDetails.noOfTreadDepthReadings === 3 && (
                        <Grid item xs={4}>
                          <p className="field-label label-tire-details">{tireFormCaptions.MIDTRD}</p>
                          <InputLabel>
                            {tireDetails.middleTread +
                              " - " +
                              (isNaN(
                                Math.round(
                                  ((parseInt(tireDetails.originalTread) - parseInt(tireDetails.middleTread)) /
                                    (parseInt(tireDetails.originalTread) - parseInt(tireDetails.removeAt))) *
                                    100
                                )
                              )
                                ? 0
                                : Math.round(
                                    ((parseInt(tireDetails.originalTread) - parseInt(tireDetails.middleTread)) /
                                      (parseInt(tireDetails.originalTread) - parseInt(tireDetails.removeAt))) *
                                      100
                                  )) +
                              tireFormCaptions.PERCENTAGEWORN}
                          </InputLabel>
                        </Grid>
                      )}
                      {(locationDetails.noOfTreadDepthReadings === 3 || locationDetails.noOfTreadDepthReadings === 2) && (
                        <Grid item xs={4}>
                          <p className="field-label label-tire-details">{tireFormCaptions.INTRD}</p>
                          <InputLabel>
                            {tireDetails.insideTread +
                              " - " +
                              (isNaN(
                                Math.round(
                                  ((parseInt(tireDetails.originalTread) - parseInt(tireDetails.insideTread)) /
                                    (parseInt(tireDetails.originalTread) - parseInt(tireDetails.removeAt))) *
                                    100
                                )
                              )
                                ? 0
                                : Math.round(
                                    ((parseInt(tireDetails.originalTread) - parseInt(tireDetails.insideTread)) /
                                      (parseInt(tireDetails.originalTread) - parseInt(tireDetails.removeAt))) *
                                      100
                                  )) +
                              tireFormCaptions.PERCENTAGEWORN}
                          </InputLabel>
                        </Grid>
                      )}
                      <Grid item xs={4}>
                        {tireFormCaptionsSetter()}
                        <InputLabel>{tireDetails.currentTreadDepth}</InputLabel>
                      </Grid>
                    </Grid>
                  </div>
                )}

                {/* Cost Details */}
                {currentRoute.path === "tirecosts" && (
                  <div className="location-details-grid">
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.PURCHASECOST}</p>
                        <InputLabel>{(tireDetails.purchaseCost ? tireDetails.purchaseCost : 0) + " " + (locationDetails.currency ?  locationDetails.currency : "")}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.CASINGVALUE}</p>
                        <InputLabel>{(tireDetails.casingValue ? tireDetails.casingValue : 0) + " " + (locationDetails.currency ?  locationDetails.currency : "")}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.FILLTYPE}</p>
                        <InputLabel>{dropDowns.fill ? dropDowns.fill : "-"}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.FILLCOST}</p>
                        <InputLabel>{(tireDetails.fillCost ? tireDetails.fillCost : 0) + " " + (locationDetails.currency ?  locationDetails.currency : "")}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.REPAIRCOUNT}</p>
                        <InputLabel>{tireDetails.repairCount ? tireDetails.repairCount : 0}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.REPAIRCOST}</p>
                        <InputLabel>{(tireDetails.repairCost ? tireDetails.repairCost : 0) + " " + (locationDetails.currency ?  locationDetails.currency : "")}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.RETREADCOUNT}</p>
                        <InputLabel>{tireDetails.retreadCount ? tireDetails.retreadCount : 0}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.RETREADCOST}</p>
                        <InputLabel>{(tireDetails.retreadCost ? tireDetails.retreadCost : 0) + " " + (locationDetails.currency ?  locationDetails.currency : "")}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.WARRANTYADJ}</p>
                        <InputLabel>{(tireDetails.warrantyAdjustment ? tireDetails.warrantyAdjustment : 0) + " " + (locationDetails.currency ?  locationDetails.currency : "")}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.COSTADJ}</p>
                        <InputLabel>{(tireDetails.costAdjustment ? tireDetails.costAdjustment : 0) + " " + (locationDetails.currency ?  locationDetails.currency : "")}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <p className="field-label label-tire-details">{tireFormCaptions.SOLDAMT}</p>
                        <InputLabel>{(tireDetails.soldAmount ? tireDetails.soldAmount : 0) + " " + (locationDetails.currency ?  locationDetails.currency : "")}</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                      <p className="field-label label-tire-details">{tireFormCaptions.NETCOST}
                        <img
                            style={{ marginInlineStart: '5px' }}
                            // onClick={handleInfoClick}
                            onMouseEnter={e => handlePopperOpen(e.currentTarget)}
                            onMouseLeave={handlePopperClose}
                            alt="info"
                            src={infoLogo}
                        // ref={anchorRef}
                        />
                          <Popper
                            open={popperState}
                            placement="top"
                            anchorEl={popperAnchorEl}
                            transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper className={styles.popper}>
                                        <p className="tirecosts-popper-title">{tireFormCaptions.FORMULA}</p>
                                        <p className="tirecosts-popper-desc" >
                                            ({tireFormCaptions.PURCHASECOST} + {tireFormCaptions.REPAIRCOST} + {tireFormCaptions.FILLCOST} + {tireFormCaptions.RETREADCOST})
                                        </p>
                                        <p className="tirecosts-popper-desc" >-
                                            ({tireFormCaptions.CASINGVALUE} + {tireFormCaptions.COSTADJ} + {tireFormCaptions.SOLDAMT} + {tireFormCaptions.WARRANTYADJ})
                                        </p>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                        </p>
                        <InputLabel>{(tireDetails.netCost ? tireDetails.netCost : 0) + " " + (locationDetails.currency ?  locationDetails.currency : "")}</InputLabel>
                      </Grid>
                    </Grid>
                  </div>
                )}

                <div className={styles.root}>
                  <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} disableScrollLock={true}>
                    <MenuItem
                      className={styles.action}
                      onClick={() => {
                        history.push({
                          pathname: "/createtire",
                          state: {
                            tireId: tireDetails.tireId,
                            toBeCloned: true,
                            serialTobeCloned: tireDetails.tireSerialNo,
                            fromPath: "/view-tire"
                          }
                        });
                        handleClose();
                      }}
                    >
                      {tireFormCaptions.CLONE}
                    </MenuItem>

                    {tireDetails.dispositionId !== 7 && (
                      <>
                        <MenuItem
                          className={styles.action}
                          onClick={() => {
                            setDisplayModalOpen(true);
                            handleClose();
                          }}
                        >
                          {globalizationMapping.FORMS.CHANGEDISPOSITION}
                        </MenuItem>
                        <MenuItem
                          className={styles.action}
                          onClick={() => {
                            setTransferModalOpen(true);
                            handleClose();
                          }}
                        >
                          {tireFormCaptions.TRANSFER}
                        </MenuItem>
                        {tireDetails.dispositionId !== 4 && (
                          <MenuItem
                            className={styles.action}
                            onClick={() => {
                              setDeleteModalOpen(true);
                              handleClose();
                            }}
                          >
                            {tireFormCaptions.DELETE}
                          </MenuItem>
                        )}
                      </>
                    )}
                  </Menu>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
        {!showSpinner && tireHistoryListTableData && Object.keys(tireDetails).length > 0 && (
          <div className="view-tire-history-table">
            <Card className="quick-inspect-list-card">
              <CardContent>
                <span className="table-toolbar-label-text view-tire-history-label">{tireFormCaptions.TIREHISTORY}</span>
              </CardContent>
              <CardContent>
                <div className="view-tire-graph-container">
                  <ViewTireGraphs tireGraphData={tireDetails.tireGraphData} />
                </div>
              </CardContent>
              <CardContent>
                <div className="table-function-title-view-tire">
                  <button
                    className={noData === true ? "table-function-item disabled-export-button" : "table-function-item export-button"}
                    disabled={noData ? true : false}
                    onClick={() => toggleExport()}
                  >
                    <div className="dropdown-icon-container">
                      <div className={noData === true ? "table-toolbar-text disabled-text" : "table-toolbar-text"}>{globalizationMapping.MANAGE_PARENT_ACCOUNT.EXPORT}</div>
                      <div className="export-down-arrow">
                        <img alt="" src={noData === true ? GreyDownArrowIcon : BlueDownArrowIcon} />
                      </div>
                      {exportDropdown && (
                        <div className="export-dropdown-content">
                          <div onClick={() => onClickExport("xlsx")} className="export-option">
                            <ExportXls exportXlsx={printData} />
                          </div>
                        </div>
                      )}
                    </div>
                  </button>
                </div>
                <div className="data-summary-table">
                  {tireHistoryListTableData && showColumnsTireHistory && (
                    <TireHistoryListTableViewOnly
                      rawHistoryData={tireDetails.tireHistory1}
                      tableData={tireHistoryListTableData}
                      showColumns={showColumnsTireHistory}
                      userPreference={userPreference}
                      masterData={masterDataSet}
                      initModalNotifs={(state) => initModalNotifications(state)}
                      deleteEventDone={(state) => initModalNotifications(state)}
                    />
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        )}

        {isDisplayModalOpen && (
          <ChangeTireDispositionModal
            closeDispModal={handleDispModalClose}
            tireId={tireDetails.tireId}
            tireSerial={tireDetails.tireSerialNo}
            tireDisposition={dropDowns.disposition}
            dispositionList={masterDataSet.tireDispositions}
          />
        )}

        {isTransferModalOpen && (
          <TransferTireModal
            closeTransferModal={handleTransferModalClose}
            currentTireLocation={currLocationForTransfer}
            locationList={locationList}
            tireId={tireDetails.tireId}
            tireSerial={tireDetails.tireSerialNo}
            isSelectionMultiple={false}
          />
        )}

        {isDeleteModalOpen && (
          <DeleteTireModal closeDeleteModal={handleDeleteModalClose} tireId={tireDetails.tireId} tireSerial={tireDetails.tireSerialNo} isSelectionMultiple={false} />
        )}
      </div>
    </React.Fragment>
  );
};

export default ViewTireViewOnly;
