import Globalization from '../globalization/index'

const globalizationMapping = new Globalization();

export const ViewRimPaths = [
    {
        name: globalizationMapping.VIEWRIMREPORTS.IDENTIFICATION,
        path: 'identification', 
        routeSelected: 1 
      },
      {
        name: globalizationMapping.VIEWRIMREPORTS.DESCRIPTION,
        path: 'description',
        routeSelected: 2
      },
      {
        name: globalizationMapping.VIEWRIMREPORTS.COST,
        path: 'cost',
        routeSelected: 3
      }
]