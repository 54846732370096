import Globalization from "../../../globalization/index";

class ByCompoundDataSetter {
  constructor() {
    this.data = {};
    this.byCompoundData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "CostPerMile" || headCell.value === "AverageMiles")
        selectedColShow[headCell.value] = false;
      else selectedColShow[headCell.value] = true;
    });
    //
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item, index) => {
      return (
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.TireCount.toString().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.AverageHours.toString().includes(searchText) ||
        item.AverageMiles.toString().includes(searchText) ||
        item.CostPerHour.toString().includes(searchText) ||
        item.CostPerMile.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;

    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });

    return filteredTable;
  }
  avgValues = (byCompoundData) => {
    let TotalTires = 0,
      HoursSum = 0,
      CostPerHourSum = 0,
      MilesSum = 0,
      CostPerMileSum = 0;
    byCompoundData.forEach((item, index) => {
      TotalTires += item.TireCount;
      HoursSum += item.TotalAverageHours * item.TireCount;
      CostPerHourSum += parseFloat(item.TotalCostPerHour * item.TireCount);
      MilesSum += item.TotalAverageMiles * item.TireCount;
      CostPerMileSum += parseFloat(item.TotalCostPerMile * item.TireCount);
    });
    let TotalTiresSum = TotalTires;
    let HoursAvg = Math.round(HoursSum / TotalTires);
    let CostPerHourAvg = parseFloat(CostPerHourSum / TotalTires).toFixed(2);
    let MilesAvg = Math.round(MilesSum / TotalTires);
    let CostPerMileAvg = parseFloat(CostPerMileSum / TotalTires).toFixed(2);

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      CompoundName: "",
      TireCount: TotalTiresSum,
      AverageHours: HoursAvg,
      CostPerHour: CostPerHourAvg,
      AverageMiles: MilesAvg,
      CostPerMile: CostPerMileAvg,
      AverageWearRate: "",
      AverageWorn: "",
      ReasonName: ""
    };
    return avgValues;
  };
  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;

    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.byCompoundData.push({
        Key: index,
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        TireCount: item.tireCount ? item.tireCount : 0,
        AverageHours: item.averageHours ? Math.round(item.averageHours * 100) / 100 : 0,
        AverageMiles: item.averageMiles ? Math.round(item.averageMiles * 100) / 100 : 0,
        TotalAverageHours: item.averageHours ? Math.round(item.averageHours * item.tireCount) : 0,
        TotalAverageMiles: item.averageMiles ? Math.round(item.averageMiles * item.tireCount) : 0,
        CostPerHour: item.costPerHour ? parseFloat((item.costPerHour * 100) / 100) : 0,
        TotalCostPerHour: item.costPerHour ? Math.round(item.costPerHour * item.tireCount) : 0,
        CostPerMile: item.costPerMile ? parseFloat((item.costPerMile * 100) / 100) : 0,
        TotalCostPerMile: item.costPerMile ? Math.round(item.costPerMile * item.tireCount) : 0,
        AverageWearRate: item.averageWearRate ? item.averageWearRate : 0,
        AverageWorn: item.averageWorn ? item.averageWorn : 0,
        ReasonName: item.reasonName ? item.reasonName : "",
        LocationName: item.locationName ? item.locationName : "",
        AccountName: item.accountName ? item.accountName : ""
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.byCompoundData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.byCompoundData),
      selectedColShow: selectedColShow
    };
  }

  multiAccountCall(reportName, accountIds, locationIds) {
    return {
      reportOption: "",
      reportName: reportName,
      dateRangeType: "AllTime",
      InServiceDateRangeType: "AllTime",
      OutServiceDateRangeType: "AllTime",
      fromDate: "",
      toDate: "",
      OutServiceFromDate: "",
      OutServiceToDate: "",
      InServiceFromDate: "",
      InServiceToDate: "",
      accountIds: accountIds,
      locationIds: locationIds,
      TireScrapType: "AllTire",
      usedTires: "false"
    };
  }
}
export default ByCompoundDataSetter;
