import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./view-tire-navigation.css";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import { List, ListItem } from "@material-ui/core";
import Globalization from "../../../globalization/index";
import { ViewTirePaths } from "../../../routing/ViewTirePaths";
import { StateContext } from "../../../services/StateProvider";
const ViewTireNavigation = ({ changedRoute }) => {
  const globalizationMapping = new Globalization();
  const [globalAccountState] = useContext(StateContext);
  const viewTireGlobalTexts = globalizationMapping.TIRES.CREATEFORM;
  const locationObj = useLocation();
  const viewTireNav = [
    {
      name: viewTireGlobalTexts.IDENTIFICATION,
      path: "identification"
    },
    {
      name: viewTireGlobalTexts.DESCRIPTION,
      path: "description"
    },
    {
      name: globalAccountState.accountInfo.userSetting.userMeasurementSystemValue === "imperial" ? viewTireGlobalTexts.TREADDEPTH32 : viewTireGlobalTexts.TREADDEPTHMM,
      path: "treaddepth"
    },
    {
      name: viewTireGlobalTexts.TIRECOSTS,
      path: "tirecosts"
    }
  ];

  const getRouteName = (currentPathName) => {
    let pathSelected = ViewTirePaths.find((route) => route.path === currentPathName);
    return pathSelected;
  };

  const getRoutePath = (viewTireSelected, path) => {
    let pathSelected = ViewTirePaths.find((route) => route.path === path);
    return pathSelected;
  };


  const getPrevPath = () => {
    const prePath = [];
    if (locationObj.state.fromPath) {
      if (locationObj.state.fromPath === "manage-tire") {
        prePath["path"] = "manage-tire"
        prePath["name"] = globalHeaderTexts.MANAGETIRES
      }
      else if (locationObj.state.fromPath.split("/")[1] === "quick-inspect") {
        prePath["path"] = `quick-inspect/${locationObj.state.fromPath.split("/")[2]}`
        prePath["name"] = globalizationMapping.INSPECT.QUICKINSPECT
      }
      else if (locationObj.state.fromPath.split("/")[1] === "view-vehicle") {
        prePath["path"] = `view-vehicle/${locationObj.state.fromPath.split("/")[2]}`
        prePath["name"] = globalizationMapping.HEADER.VIEWVEHICLE
      }
    } else {
      prePath["path"] = ""
      prePath["name"] = ""
    }
    return prePath
  }

  let currentRouteSelected = getRouteName("identification");
  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line no-unused-vars
  const globalHeaderTexts = globalizationMapping.HEADER;

  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME
    },
    {
      path: "",
      name: globalHeaderTexts.VIEWTIRE
    }
  ];

  if (getPrevPath().path !== "") {
    breadcrumbs.splice(1, 0, {
      path: getPrevPath().path,
      name: getPrevPath().name
    })
  }

  const viewTireNavigation = (selectedCard, path) => {
    let routePath = getRoutePath(selectedCard, path);
    setSelectedRoute(routePath);
    changedRoute(routePath);
  };

  return (
    <React.Fragment>
      <div className="manage-tires-main-container">
        <div className="manage-tires-upper-deck">
          <div className="background-img-container-view-tire">
            <div className="background-map-image background-map-image-view-tire">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="manage-tires-title">{globalHeaderTexts.VIEWTIRE}</div>
                </div>
              </div>
              <div className="manage-button-manage-tires-nav-container">
                <div className="manage-tires-nav-container">
                  {viewTireNav.map((nav) => {
                    return (
                      <div key={nav.name} className="manage-tires-nav-card-container" onClick={() => viewTireNavigation(nav.name, nav.path)}>
                        <List className={selectedRoute.path === nav.path ? "active-manage-tires-nav-card" : "manage-tires-nav-card"}>
                          <ListItem button={selectedRoute.path === nav.path} autoFocus={selectedRoute.path === nav.path}>
                            <span className={selectedRoute.path === nav.path ? "active-manage-tires-nav-card-text" : "manage-tires-nav-card-text"}>{nav.name}</span>
                          </ListItem>
                        </List>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewTireNavigation;
