import React, { useEffect, useState, useContext } from "react";
import OtherTiresNavigation from "../../../other-tires-navigation/OtherTiresNavigation";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import TiresSentToRepairDataSetter from "../../../../../services/TiresSentToRepairUtils";
import TiresSentToRepairTable from "../table/TiresSentToRepairTable";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import TiresSentToRepairFilter from '../filter/TiresSentToRepairFilter'

const TiresSentToRepair = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const tiresSentToRepairDataSetter = new TiresSentToRepairDataSetter();
  const commonUtils = new CommonUtils();
  const [tiresSentToRepairTableData, setTiresSentToRepairTableData] = useState();
  const [originalTiresSentToRepairTableData, setOriginalTiresSentToRepairTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(tiresSentToRepairTableData);
  const [noData, setNoData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [usePurchasedTread, setUsePurchasedTread] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [toggleHM, setToggleHM] = useState("Hours");

  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Eval #", value: "Eval" },
      { name: "Compound", value: "Compound" }
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      Eval: [],
      Compound: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });


  const globalizationMapping = new Globalization();
  const measurement = "Imperial - 32nd/in";

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")) === "metric" ? globalizationMapping.MANAGEVEHICLE.KM : globalizationMapping.MANAGEVEHICLE.MILES;
  const TiresSentToRepairResponseData = (measurementValue, excludeUsedTiresValue, usePurchasedTreadValue, filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      locationIds: locationIds,
      reportName: "Tires_Sent_To_Repair",
      reportOption: "",
      toDate: dateRangeFilter.toDate,
      measurement: measurementValue,
      usedTires: excludeUsedTiresValue.toString(),
      usePurchasedTread: usePurchasedTreadValue.toString()
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {

      let tiresSentToRepairTableInfo = tiresSentToRepairDataSetter.setData(data);
      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.MANUFACTURER,
            value: "ManufacturerName"
          },
          { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
          { name: globalizationMapping.REPORTTABLE.EVALNO, value: "Eval" },
          {
            name: globalizationMapping.REPORTTABLE.COMPOUND1,
            value: "Compound"
          }
        ],
        categoryData: {
          ManufacturerName: [],
          SizeName: [],
          TypeName: [],
          Eval: [],
          Compound: [],
          dateRangeFromDate: '',
          dateRangeToDate: '',
        }
      };
      let filterOptionsInfo = tiresSentToRepairDataSetter.setFilterOptionsData(filterOptions, tiresSentToRepairTableInfo.rowsData);
      setFilterOptions(filterOptionsInfo);
      tiresSentToRepairTableInfo.headCells.forEach((element) => {
        if (element.value === "TreadDepth") {
          if (usePurchasedTreadValue) element.label = 'NS Cur/Pur (32")O, C, I/Pur';
          else element.label = "Tread Cur/Org (mm)";
        }
      });
      setTiresSentToRepairTableData(tiresSentToRepairTableInfo);
      setOriginalTiresSentToRepairTableData(tiresSentToRepairTableInfo);
      setTableBeforeFilter(tiresSentToRepairTableInfo);
      let updatedHeadCells = tiresSentToRepairDataSetter.updatedHeadCells();
      tiresSentToRepairTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setDisplayColOptions(tiresSentToRepairTableInfo.headCells);
      setDisplayColumns(updatedHeadCells);
      setShowColumns(tiresSentToRepairTableInfo.selectedColShow);

      setPrintData({
        reportType: "Other Tires : ",
        reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToRepair,
        rowsData: tiresSentToRepairTableInfo.rowsData,
        headCells: tiresSentToRepairTableInfo.headCells,
        avgValues: tiresSentToRepairTableInfo.avgValues,
        selectedColShow: tiresSentToRepairTableInfo.selectedColShow,
        filterApplied: filterApplied
      });
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = tiresSentToRepairTableInfo.rowsData;
        let filteredData = tiresSentToRepairDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);

        setTiresSentToRepairTableData({
          ...tiresSentToRepairTableInfo,
          rowsData: filteredData,
          avgValues: tiresSentToRepairDataSetter.avgValues(filteredData)
        });
        setOriginalTiresSentToRepairTableData({
          ...tiresSentToRepairTableInfo,
          rowsData: filteredData,
          avgValues: tiresSentToRepairDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: tiresSentToRepairDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
      setShowSpinner(false);
    });
  };

  useEffect(() => {
    TiresSentToRepairResponseData(measurement, excludeUsedTires, usePurchasedTread, filterApplied, filterFieldsApplied);
  }, [globalAccountState, usePurchasedTread, excludeUsedTires, filterApplied, filterFieldsApplied]);

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  const actionChanged = (action) => {
    setToggleHM(action);

    if (action === "Miles") {
      handleMilesClick();
    } else {
      handleHoursClick();
    }
  };


  const handleMilesClick = () => {

    let avoidArr = [];
    avoidArr = ["TotalHours"];
    let repairDataInfo = tiresSentToRepairTableData;

    repairDataInfo.headCells.map((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      repairDataInfo.selectedColShow[item.value] = flag;
    });
    setTiresSentToRepairTableData(repairDataInfo);
    repairDataInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

    let updatedHeadCells = repairDataInfo.headCells.filter((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });

    setDisplayColumns(updatedHeadCells);
    setDisplayColOptions(updatedHeadCells);
    setShowColumns(repairDataInfo.selectedColShow);

    setPrintData({
      reportType: "Other Tires : ",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToRepair,
      rowsData: repairDataInfo.rowsData,
      headCells: repairDataInfo.headCells,
      avgValues: repairDataInfo.avgValues,
      selectedColShow: repairDataInfo.selectedColShow
    });
  };


  const handleHoursClick = () => {

    let avoidArr = [];
    avoidArr = ["TotalMiles"];
    let repairDataInfo = tiresSentToRepairTableData;

    repairDataInfo.headCells.map((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      repairDataInfo.selectedColShow[item.value] = flag;
    });
    setTiresSentToRepairTableData(repairDataInfo);
    repairDataInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

    let updatedHeadCells = repairDataInfo.headCells.filter((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });

    setDisplayColumns(updatedHeadCells);
    setDisplayColOptions(updatedHeadCells);
    setShowColumns(repairDataInfo.selectedColShow);

    setPrintData({
      reportType: "Other Tires : ",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToRepair,
      rowsData: repairDataInfo.rowsData,
      headCells: repairDataInfo.headCells,
      avgValues: repairDataInfo.avgValues,
      selectedColShow: repairDataInfo.selectedColShow
    });
  };


  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    let tableDataStore, filteredData;
    tableDataStore = originalTiresSentToRepairTableData?.rowsData;
    filteredData = tiresSentToRepairDataSetter.search(tableDataStore, searchText);
    setTiresSentToRepairTableData({
      ...tiresSentToRepairTableData,
      rowsData: filteredData,
      avgValues: tiresSentToRepairDataSetter.avgValues(filteredData)
    });

    setPrintData({
      ...printData,
      reportType: "Other Tires : ",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToRepair,
      rowsData: filteredData,
      headCells: originalTiresSentToRepairTableData?.headCells,
      avgValues: tiresSentToRepairDataSetter.avgValues(filteredData),
      selectedColShow: originalTiresSentToRepairTableData?.selectedColShow,
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };


  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalTiresSentToRepairTableData?.headCells;
    let showColumnsUpdated = tiresSentToRepairDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };


  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    actionChanged("Hours");
    setToggleHM("Hours");
    setSearchValue("");

    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}
    
    
    let originalTableDataStore = originalTiresSentToRepairTableData?.rowsData;
    let filteredData = tiresSentToRepairDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setTiresSentToRepairTableData({
      ...tiresSentToRepairTableData,
      rowsData: filteredData,
      avgValues: tiresSentToRepairDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: tiresSentToRepairDataSetter.avgValues(filteredData)
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);

    setFilterFieldsApplied(appliedFilter);
    TiresSentToRepairResponseData(measurement, excludeUsedTires, usePurchasedTread, true, appliedFilter);
  };


  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      Eval: [],
      Compound: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    });

    setFilterApplied(false);
    setTiresSentToRepairTableData(tableBeforeFilter);
    setOriginalTiresSentToRepairTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
  };


  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    actionChanged("Hours");
    setToggleHM("Hours");
    setSearchValue("");
    TiresSentToRepairResponseData(measurement, event.target.checked, usePurchasedTread, filterApplied, filterFieldsApplied);
  };

  const handleUsedPurchasedTread = (event) => {
    setUsePurchasedTread(event.target.checked);
    actionChanged("Hours");
    setToggleHM("Hours");
    TiresSentToRepairResponseData(measurement, excludeUsedTires, event.target.checked, filterApplied, filterFieldsApplied);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  return (
    <div>
      <div className="container">
        <OtherTiresNavigation />
        {showSpinner && <Spinner />}
        {!showSpinner && (
          <div className="tire-register-container">
            <Card className="tire-register-card">
              <CardContent>
                <div className="table-toolbar">
                  <div className="table-filters">
                    <div className="search-table-container">
                      <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                        </InputLabel>
                        <TextField
                          className="search-table-bar"
                          id="input-with-icon-textfield"
                          variant="outlined"
                          placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                          value={searchValue}
                          onChange={searchTableData}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                              </InputAdornment>
                            )
                          }}
                          inputProps={{ maxLength: 50 }}
                        />
                      </form>
                    </div>
                    <div className="display-columns-container" id="dashboard-display-column">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text"> {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                      </InputLabel>
                      <MultiSelect
                        options={displayColOptions}
                        value={displayColumns}
                        onChange={changeDisplayColumns}
                        overrideStrings={{
                          selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                          allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                          selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                          search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                        }}
                      />
                    </div>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      value={toggleHM}
                      onChange={(e) => actionChanged(e.target.value)}
                      helperText=""
                    >
                      <option value={"Hours"}>{globalizationMapping.INSPECT.HOURS}</option>
                      <option value={"Miles"}>{toggleMile}</option>
                    </Select>
                  </div>
                  <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
                </div>
                <Grid container>
                  <Grid item xs={3}>
                    <div
                      className="
                                    exclude-used-tires"
                    >
                      <FormControlLabel
                        control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} color="primary" />}
                        label={globalizationMapping.GRAPH.ExcludeUsedTires}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      className="
                                    exclude-used-tires"
                    >
                      <FormControlLabel
                        control={<Checkbox checked={usePurchasedTread} onChange={handleUsedPurchasedTread} color="primary" />}
                        label={globalizationMapping.REPORTCOMMONWORDS.UsePurchasedTread}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="tire-register-table">
                  <TiresSentToRepairTable tableData={tiresSentToRepairTableData} showColumns={showColumns} filterApplied={filterApplied} />
                </div>
              </CardContent>
            </Card>
          </div>
        )}
        <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <TiresSentToRepairFilter 
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          filterOptions={filterOptions}
          ApplyFilter={confirmApplyDateFilter}
          currentFilter={currentFilter}
          resetTableFilter={resetTableFilter}
          dateRangeFilter={dateRangeFilter}
          setDateRangeFilter={setDateRangeFilter}
          setDateRangeType={setDateRangeType}
        />
      </div>
    </div>
  );
};

export default TiresSentToRepair;
