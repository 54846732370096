import React, { useEffect, useState } from "react";
import "../../../../../common-styles/TableStyle.css";
import "./Table.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, InputLabel, Divider, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from '@material-ui/icons/MoreVertOutlined';
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import { useHistory } from "react-router-dom";
import Globalization from "../../../../../globalization/";
import CommonUtils from "../../../../../services/utils";

const commonUtils = new CommonUtils();
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    maxHeight: 600
  },
  noDataContainer: {
    height: 325
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  stickyHeader: {
    zIndex: "2!important"
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "14px !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important"
  }
}));

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const InstalledTiresListTable = ({ tableData, showColumns, listOfTires }) => {
  const [installedTiresListTableData, setInstalledTiresListTableData] = useState(tableData);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ManufacturerName");
  const [editClicked, setEditClicked] = useState([]);
  const history = useHistory();
  const globalizationMapping = new Globalization();
  const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;

  useEffect(() => {
    setInstalledTiresListTableData(tableData);
    let row = [];
    row.length = installedTiresListTableData.rowsData.length;
    for (let i = 0; i < installedTiresListTableData.rowsData.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  const handleRequestSort = (property) => (event) => {
    forceCloseAction();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const editRow = (index) => {
    let row = [...editClicked];
    row.length = installedTiresListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      if (row[i] === true && index !== i) {
        row[i] = false;
      }
    }
    if (editClicked[index]) {
      row[index] = !row[index];
      setEditClicked(row);
    } else {
      row[index] = true;
      setEditClicked(row);
    }
  };

  const forceCloseAction = () => {
    let row = [...editClicked];
    row.length = installedTiresListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
  }

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <InputLabel className="filter-table-label">
            <span className="table-toolbar-label-text-view-vehicle">{tireFormCaptions.TIRESINSTALLED}</span>
          </InputLabel>
          <TableContainer className={`${installedTiresListTableData.rowsData.length > 0 ? classes.container : classes.noDataContainer}`}>
            <Table 
              stickyHeader 
              className={classes.table} 
              aria-labelledby="tableTitle" 
              aria-label="a dense table"
              size="small"
              >
              <TableHead>
                <TableRow>
                  {installedTiresListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align="left"
                        sortDirection={orderBy === headCell.value ? order : false}
                      >
                        <TableSortLabel active={orderBy === headCell.value} direction={orderBy === headCell.value ? order : "asc"} onClick={handleRequestSort(headCell.value)}>
                          {headCell.label}
                          {orderBy === headCell.value ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              <tr className="table-bottom-line">
                <td colspan={installedTiresListTableData.headCells.length + 1}></td>
              </tr>
              {installedTiresListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(installedTiresListTableData.rowsData, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={row.Key}>
                          {showColumns.Position && (
                            <TableCell align="left" className="table-content-text">
                              {row.Position}
                            </TableCell>
                          )}
                          {showColumns.SerialNo && (
                            <TableCell align="left" className="table-content-text">
                              {row.SerialNo}
                            </TableCell>
                          )}
                          {showColumns.Type && (
                            <TableCell align="left" className="table-content-text">
                              {row.Type}
                            </TableCell>
                          )}
                          {showColumns.Size && (
                            <TableCell align="left" className="table-content-text">
                              {row.Size}
                            </TableCell>
                          )}
                          {showColumns.Hours && (
                            <TableCell align="left" className="table-content-text">
                              {row.Hours}
                            </TableCell>
                          )}
                          {showColumns.TreadDepth && (
                            <TableCell align="left" className="table-content-text">
                              {row.TreadDepth}
                            </TableCell>
                          )}
                          {showColumns.Worn && (
                            <TableCell align="left" className="table-content-text">
                              {row.Worn}
                            </TableCell>
                          )}

                          <TableCell align="left" className="table-content-text">
                            <IconButton aria-label="edit" onClick={() => editRow(index)}>
                              <MoreIcon style={{color:commonUtils.primaryBlue}} fontSize='small'></MoreIcon>
                            </IconButton>
                            {editClicked[index] === true && (
                              <div className="action-dropdown-content">
                                <div
                                  className="action-option"
                                  onClick={() => {
                                    history.push({
                                      pathname: "/inspect-tire",
                                      state: {
                                        tireId: listOfTires[row.Key].tireId,
                                        vehicleId: listOfTires[row.Key].vehicleId,
                                        fromPath: "/manage-tire"
                                      }
                                    });
                                  }}
                                >
                                  {tireFormCaptions.INSPECT}
                                </div>
                                <Divider />
                                <div
                                  className="action-option"
                                  onClick={() => {
                                    history.push({
                                      pathname: "/view-tire",
                                      state: {
                                        tireId: listOfTires[row.Key].tireId,
                                        fromPath: "/view-vehicle/" + listOfTires[row.Key].vehicleId,
                                      }
                                    });
                                  }}
                                >
                                  {tireFormCaptions.VIEW}
                                </div>
                                <Divider />
                                <div
                                  className="action-option"
                                  // onClick={() => history.pushS((listOfTires[row.Key].tireId, "row.Key"))}
                                  onClick={() => {
                                    history.push({
                                      pathname: "/createtire",
                                      state: {
                                        tireId: listOfTires[row.Key].tireId
                                      }
                                    });
                                  }}
                                >
                                  {tireFormCaptions.EDIT}
                                </div>
                                <Divider />
                                <div
                                  className="action-option"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/createtire",
                                      state: {
                                        tireId: listOfTires[row.Key].tireId,
                                        toBeCloned: true,
                                        serialTobeCloned: listOfTires[row.Key].tireSerialNo
                                        // fromPath: "/manage-tire",
                                      },
                                    })
                                  }
                                >
                                  {tireFormCaptions.CLONE}
                                </div>
                                <Divider />
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {installedTiresListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={8} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default InstalledTiresListTable;
