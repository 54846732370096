import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";

class InstalledTiresSetter {
  constructor() {
    this.data = {};
    this.installedTiresListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  setData(data) {
    const installedTiresData = data.model.installedTires;
    installedTiresData.forEach((tires, tiresIndex) => {
      this.installedTiresListData.push({
        Key: tiresIndex,
        TireId: tires.tireId ? tires.tireId : "",
        Position: tires.wheelPosition ? tires.wheelPosition : "",
        SerialNo: tires.tireSerialNo ? tires.tireSerialNo : "",
        Type: tires.typeName ? tires.typeName : "",
        Size: tires.sizeName ? tires.sizeName : "",
        Hours: tires.currentHours ? tires.currentHours : 0,
        TreadDepth: tires.currentTreadDepth ? tires.currentTreadDepth : 0,
        Worn: tires.percentageWorn ? tires.percentageWorn : 0,
        Action: "",
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.InstalledTires;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];

      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);
    return {
      rowsData: this.installedTiresListData,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.vehicleHistoryListData),
      selectedColShow: selectedColShow,
    };
  }
}
export default InstalledTiresSetter;
