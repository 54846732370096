import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";
class ManageVehicleListSetter {
  constructor() {
    this.data = {};
    this.manageVehicleListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "VehicleId")
        selectedColShow[headCell.value] = false;
      else selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item, _index) => {
      return (
        item.VehicleId.toLowerCase().includes(searchText) ||
        item.VehicleNumber.toLowerCase().includes(searchText) ||
        item.AssetNumber.toLowerCase().includes(searchText) ||
        item.Location.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.MakeName.toLowerCase().includes(searchText) ||
        item.ModelName.toLowerCase().includes(searchText) ||
        item.AxleConfig.toLowerCase().includes(searchText) ||
        item.CurrentHours.toString().includes(searchText) ||
        item.Kilometers.toString().includes(searchText) ||
        item.LastRead.toLowerCase().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, _index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data, userSetting) {
    // TABLE ROWS DATA
    const locationList = data.model.locationList;

    locationList.forEach((location, _index) => {
      let vehicleList = location.vehicleList;
      /** NOTE : the keys must match the headCells value to be able to SORT */
      vehicleList.forEach((vehicle, vehicleIndex) => {
        this.manageVehicleListData.push({
          Key: vehicleIndex,
          VehicleId: vehicle.vehicleId ? "" + vehicle.vehicleId : 0,
          VehicleNumber: vehicle.vehicleNumber
            ? "" + vehicle.vehicleNumber
            : "",
          AssetNumber: vehicle.assetNumber ? "" + vehicle.assetNumber : "",
          Location: location.locationName ? "" + location.locationName : "",
          TypeName: vehicle.typeName ? "" + vehicle.typeName : "",
          MakeName: vehicle.manufacturerName
            ? "" + vehicle.manufacturerName
            : "",
          ModelName: vehicle.modelName ? "" + vehicle.modelName : "",
          AxleConfig: vehicle.axleConfig ? vehicle.axleConfig : "",
          CurrentHours: vehicle.currentHours ? vehicle.currentHours : 0,
          Kilometers: vehicle.currentMiles ? vehicle.currentMiles : 0,
          LastRead: vehicle.lastRead
            ? "" + this.commonUtils.formatISODate(vehicle.lastRead)
            : "",
        });
      });
    });
    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.ManageVehicle;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;
      if (labelName === this.globalizationMapping.MANAGEVEHICLE.KM) {
        labelName =
          userSetting.userMeasurementSystemValue === "imperial"
            ? this.globalizationMapping.MANAGEVEHICLE.MILES
            : this.globalizationMapping.MANAGEVEHICLE.KM;
      }
      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.manageVehicleListData,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.manageVehicleListData),
      selectedColShow: selectedColShow,
    };
  }

  setDataByLocation(data, locationIds) {
    // TABLE ROWS DATA
    const locationList = data.model.locationList;
    let vehicleData = [];
    locationList.forEach((location, _index) => {
      if (locationIds.indexOf(location.locationId) > -1) {
        let vehicleList = location.vehicleList;
        vehicleList.forEach((vehicle, vehicleIndex) => {
          vehicleData.push({
            Key: vehicleIndex,
            VehicleId: vehicle.vehicleId ? "" + vehicle.vehicleId : 0,
            VehicleNumber: vehicle.vehicleNumber
              ? "" + vehicle.vehicleNumber
              : "",
            AssetNumber: vehicle.assetNumber ? "" + vehicle.assetNumber : "",
            Location: location.locationName ? "" + location.locationName : "",
            TypeName: vehicle.typeName ? "" + vehicle.typeName : "",
            MakeName: vehicle.manufacturerName
              ? "" + vehicle.manufacturerName
              : "",
            ModelName: vehicle.modelName ? "" + vehicle.modelName : "",
            AxleConfig: vehicle.axleConfig ? vehicle.axleConfig : "",
            CurrentHours: vehicle.currentHours ? vehicle.currentHours : 0,
            Kilometers: vehicle.currentMiles ? vehicle.currentMiles : 0,
            LastRead: vehicle.lastRead
              ? "" + this.commonUtils.formatISODate(vehicle.lastRead)
              : "",
          });
        });
      }
    });
    return vehicleData;
  }
}

export default ManageVehicleListSetter;
