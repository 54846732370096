import React, { useState, useEffect } from "react";
import "./FilterTireReport.css";
import { Drawer, Divider } from "@material-ui/core";
import CloseIcon from "../../../../assets/close-icon.svg";
import FilterTabsTireReport from "../filter-tab-tire-report/FilterTabsTireReport";
import Globalization from "../../../../globalization";

const filterOptionsTemplate = (filterOptions) => {
  let selectedFilterOptions = {};
  filterOptions.categories.forEach((category) => {
    selectedFilterOptions[category.value] = [];
  });
  return selectedFilterOptions;
};

const FilterTireReport = ({
  setShowFilter,
  showFilter,
  filterOptions,
  confirmApplyFilter,
  confirmApplyDateFilter,
  resetTableFilter,
  onFilterUpdateMain,
}) => {

  const selectedFilterOptionsInit = filterOptionsTemplate(filterOptions);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(selectedFilterOptionsInit);

  const globalizationMapping = new Globalization();
  const filterSectionWords = globalizationMapping.FILTERSECTION;

  const closeFilter = () => {
    setShowFilter(false);
  };
  const updateFilter = (filterval) => {
    onFilterUpdateMain(filterval);
  };


  useEffect(() => {
    // filterOptionsSelected()
  }, [selectedFilterOptions]);

  const filterOptionsSelected = (_event, selectedItems, categoryName) => {
    const updatedOptions = [];
    // initiates the selections for the selected category
    selectedItems.forEach((selectedItem) => {
      setSelectedFilterOptions({ [selectedItem.category]: [] });
    });
    // adds selected items
    if (selectedItems.length > 0) {
      selectedItems.forEach((selectedItem) => {
        updatedOptions.push(selectedItem.value);
        setSelectedFilterOptions({
          ...selectedFilterOptions,
          [selectedItem.category]: updatedOptions,
        });
      });
    } else if (selectedItems.length === 0) {
      setSelectedFilterOptions({
        ...selectedFilterOptions,
        [categoryName]: [],
      });
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <Drawer
        className="filter-drawer"
        anchor="right"
        // variant="persistent"
        open={showFilter}
        onClose={closeFilter}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className="filter-container">
          <div className="filter-header">
            <div className="filter-title">{filterSectionWords.Filters}</div>
            <div className="filter-header-options">
              <div className="filter-cancel">
                <img src={CloseIcon} alt="" onClick={closeFilter} />
              </div>
            </div>
          </div>
          <Divider />
          <FilterTabsTireReport
            filterOptions={filterOptions}
            selectedFilterOptions={selectedFilterOptions}
            filterOptionsSelected={filterOptionsSelected}
            onFilterUpdate={updateFilter}
            confirmApplyFilter={confirmApplyFilter}
            confirmApplyDateFilter={confirmApplyDateFilter}
            resetTableFilter={resetTableFilter}
            setShowFilter={setShowFilter}
          />
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default FilterTireReport;
