import Globalization from "../../../globalization/index";
const globalizationMapping = new Globalization();
const reportsNavGlobalTexts = globalizationMapping;

export const tabViewList = [
  {
    tabName: "reportsDashboard",
    reportsDashboard: [],
  },

  {
    tabName: "tireReports",
    tireReports: [
      {
        title: reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ScrapTires,
        mainTitle: "tireReports",
        links: [
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ScrapSummary,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ByRemovalReason,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ByCompound,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ByIndividualTire,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ScrapAnalysis,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ByTireType,
        ],
        path: [
          "scrapSummary",
          "byRemovalReason",
          "byCompound",
          "byIndividualTire",
          "scrapAnalysis",
          "byTireType",
        ],
        numVal: [1, 2, 3, 4, 5, 6],
      },
      {
        title: "",
        mainTitle: "tireReports",
        links: [
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ByTireSize,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ScrapCostByTireSize,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ScrapHoursByTireSize,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ScrapCostByTireType,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.ScrapHoursByTireType,
          reportsNavGlobalTexts.TIREREPORTS.SCRAPTIRES.MonthlyTireLife,
        ],
        path: [
          "byTireSize",
          "scrapCostByTireSize",
          "scrapHoursByTireSize",
          "scrapCostByTireType",
          "scrapHoursByTireType",
          "monthlyTireLife",
        ],
        numVal: [7, 8, 9, 10, 11, 12],
      },
      {
        title: reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.TiresInService,
        mainTitle: "tireReports",
        links: [
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.InServiceTireCostSummary,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.TireInstallation,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.TireHistory,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.ByPercentageWorn,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.ByWearCondition,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.ByManufacturer,
        ],
        path: [
          "inServiceTireCostSummary",
          "tireInstallation",
          "tireHistory",
          "byPercentageWorn",
          "byWearCondition",
          "byManufacturer",
        ],
        numVal: [1, 2, 3, 4, 5, 6],
      },
      {
        title: "",
        mainTitle: "tireReports",
        links: [
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.BySize,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.ByTiresInServiceTireType,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.ByVehicleModel,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.ByVehicleID,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.ProjectedCostByTireSize,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.ProjectedCostByTireType,
        ],
        path: [
          "bySize",
          "byTiresInServicesTireType",
          "byVehicleModel",
          "byVehicleId",
          "projectedCostByTireSize",
          "projectedCostByTireType",
        ],
        numVal: [7, 8, 9, 10, 11, 12],
      },
      {
        title: "",
        mainTitle: "tireReports",
        links: [
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.ByVehicleWheelPosition,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.TireForecastByTireType,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.TireInflation,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.DailyJobSummary,
          reportsNavGlobalTexts.TIREREPORTS.TIRESINSERVICE.TireNeedsForecast,
        ],
        path: [
          "byVehicleWheelPosition",
          "tireForecastByTireType",
          "tireInflation",
          "dailyJobSummary",
          "tireNeedsForecast",
        ],
        numVal: [13, 14, 15, 16, 17],
      },
      {
        title: reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.OtherTires,
        mainTitle: "tireReports",
        links: [
          reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.TireRegisterReport,
          reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.TireByDisposition,
          reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.RemoveTireReport,
          reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.TiresSentToRepair,
          reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.TiresSentToRetread,
          reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.TiresSentToInspectTireReport,
          reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.NewVSScrap,
          reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.AchievedHoursReport,
          reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.AllTireReport,

        ],
        path: [
          "tireRegister",
          "tiresByDisposition",
          "removedTire",
          "tiresSentToRepair",
          "tiresSentToRetread",
          "tiresSentToInspectTireReport",
          "newVSScrap",
          "achievedHoursReport",
          "allTireReport"
        ],
        numVal: [1, 2, 3, 4, 5, 6, 7 ,8, 9],
      },
      // {
      //   title: reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.OtherTires,
      //   mainTitle: "tireReports",
      //   links: [
      //     reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.NewVSScrap,
      //     reportsNavGlobalTexts.TIREREPORTS.OTHERTIRES.AchievedHoursReport,
      //   ],
      //   path: ["newVSScrap", "achievedHoursReport"],
      //   numVal: [7, 8],
      // },
    ],
  },
  {
    tabName: "vehicleReports",
    vehicleReports: [
      {
        title: reportsNavGlobalTexts.VEHICLEREPORTS.BYFLEET.ByFleet,
        mainTitle: "vehicleReports",
        links: [
          reportsNavGlobalTexts.VEHICLEREPORTS.BYFLEET.FleetSurvey,
          reportsNavGlobalTexts.VEHICLEREPORTS.BYFLEET.FleetDataAnalysis,
        ],
        path: ["fleetSurvey", "fleetData"],
        numVal: [1, 2],
      },
      {
        title: reportsNavGlobalTexts.VEHICLEREPORTS.BYTIRES.ByTires,
        mainTitle: "vehicleReports",
        links: [
          reportsNavGlobalTexts.VEHICLEREPORTS.BYTIRES.TireForecast,
          reportsNavGlobalTexts.VEHICLEREPORTS.BYTIRES.TireInflation,
          reportsNavGlobalTexts.VEHICLEREPORTS.BYTIRES.TireRotation,
          reportsNavGlobalTexts.VEHICLEREPORTS.BYTIRES.TireFlipping,
        ],
        path: [
          "tireForecast",
          "tireInflationReport",
          "tireRotation",
          "tireFlipping",
        ],
        numVal: [1, 2, 3, 4],
      },
    ],
  },
  {
    tabName: "rimReports",
    rimReports: [
      {
        title: reportsNavGlobalTexts.RIMREPORTS.RimReports.RimReports,
        mainTitle: "rimReports",
        links: [
          reportsNavGlobalTexts.RIMREPORTS.RimReports.RimReport,
          reportsNavGlobalTexts.RIMREPORTS.RimReports.RimsInService,
        ],
        path: ["rimReport", "rimsInService"],
        numVal: [1, 2],
      },
    ],
  },
  {
    tabName: "surveyTemplates",
    surveyTemplates: [
      {
        title:
          reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION
            .FleetDataCollection,
        mainTitle: "surveyTemplate",
        links: [
          // {
          //   subHeader: reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.OneAxel,
          //   subLinks: [reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.OneAxelTwoTires, reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.OneAxelFourTires],
          //   path : ['oneAxleTwoTires','oneAxleFourTires']
          // },
          {
            subHeader:
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxel,
            subLinks: [
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsFourTires,
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsSixTires,
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsSixTiresTwo,
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsEightTires,
            ],
            path: [
              "twoAxlesFourTires",
              "twoAxlesSixTires",
              "twoAxlesSixTiresTwo",
              "twoAxlesEightTires",
            ],
            numVal: [1, 2, 3, 4],
          },
          {
            subHeader:
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.ThreeAxel,
            subLinks: [
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.ThreeAxelsSixTires,
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.ThreeAxelsTenTires,
            ],
            path: ["threeAxlesSixTires", "threeAxlesTenTires"],
            numVal: [1, 2],
          },
          {
            subHeader:
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.FourAxel,
            subLinks: [
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.FourAxelsEightTires,
            ],
            path: ["fourAxlesEightTires"],
            numVal: [1],
          },
          {
            subHeader:
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxel,
            subLinks: [
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsTenTires,
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsSixteenTires,
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsEighteenTires,
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsTwentyTires,
            ],
            path: [
              "fiveAxlesTenTires",
              "fiveAxlesSixteenTires",
              "fiveAxlesEighteenTires",
              "fiveAxlesTwentyTires",
            ],
            numVal: [1, 2, 3, 4],
          },
          {
            subHeader:
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.SixAxel,
            subLinks: [
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.SixAxelsTwelveTires,
            ],
            path: ["sixAxlesTwelveTires"],
            numVal: [1],
          },
          {
            subHeader:
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.SevenAxel,
            subLinks: [
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.SevenAxelsFourteenTires,
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.SevenAxelsTwentySixTires,
            ],
            path: ["sevenAxlesFourteenTires", "sevenAxlesTwentySixTires"],
            numVal: [1, 2],
          },
          {
            subHeader:
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.EightAxel,
            subLinks: [
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.EightAxelsSixteenTires,
            ],
            path: ["eightAxlesSixteenTires"],
            numVal: [1],
          },
          {
            subHeader:
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.NineAxel,
            subLinks: [
              reportsNavGlobalTexts.SURVEYTEMPLATES.FLEETDATACOLLECTION.NineAxelsEighteenTires,
            ],
            path: ["nineAxlesEighteenTires"],
            numVal: [1],
          },
        ],
      },
    ],
  },
  // {
  //   tabName: 'graphs',
  //   graphs: [
  //     {
  //       title: "TBD",
  //       links: ["Report 1", "Report 2"]
  //     },
  //     {
  //       title: "TBD",
  //       links: ["Report 1", "Report 2"]
  //     },
  //     {
  //       title: "TBD",
  //       links: ["Report 1", "Report 2", "Report 3"]
  //     },
  //   ]
  // }
];
