import CommonService from "../../../services/common-api-service";
import { SERVER } from "../../../services/api-urls";

export default class HeaderApi {
  cs;
  constructor() {
    this.cs = new CommonService();
  }
  getUserPreference = () => {
    return this.cs.httpConnection(SERVER.SERVICES.getUserPreferences,"get",{});
  };
  getParentAccountList = () => {
    return this.cs.httpConnection(SERVER.SERVICES.getParentAccount, "get", {});
  };
  getParentAccountDetails = (accountId) => {
    return this.cs.httpConnection( `${SERVER.SERVICES.getParentAccountDetails}${accountId}`, "get", {});
  };
  getLocationList = () => {
    return this.cs.httpConnection(SERVER.SERVICES.getLocation, "get", {});
  };
  getLocationListByAccountType = (parentAccountId) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getLocationListByAccount}${parentAccountId}`, "get", {});
  };
  getLatestVehicleHistoryData = (payload) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getLatestVehicleHistoryData}`, "put", payload);
  }
  getVehicleTransfer = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.vehicleTransfer, "put", req);
  }
  getVehicleTransferWithTire = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.vehicleTransferWithTire, "put", req);
  }
  getUserProfile = () => {
    return this.cs.httpConnection(SERVER.SERVICES.getUserProfile, "get", {});
  };
  getBroadCastDetails = () => {
    return this.cs.httpConnection(SERVER.SERVICES.getBroadCastDetails, "get", {});
  }
  createUser = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.createUser, "post", req);
  };
  updateUser = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.updateUser, "put", req);
  };
  updateLocation = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.updateLocation, "put", req);
  };
  sendEmailReport = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.sendEmailReport, "post", req);
  };
  getCountryList = () => {
    return this.cs.httpConnection(SERVER.SERVICES.getCountryList, "get", {});
  };
  getLocationById = (locationId) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getLocationById}${locationId}`,"get",{});
  };
  updateParentAccountDetails = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.updateParentAccount,"put", req);
  };
  getDetailsById = (locationId) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getDetailsById}${locationId}`,"get",{});
  };
  updateHoursForVehicle = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.updateHours,"put", req);
  };
  getMasterDataWeb = () => {
    return this.cs.httpConnection(SERVER.SERVICES.getMasterDataWeb, "get", {});
  };

  getTreadDepthDefaultValue = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.getTreadDepthDefaultValue, "post", req);
  }
  putBroadCastAPI = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.putBroadCastAPI, "post", req);
  }
  createTire = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.editCreateTire, "post", req);
  };
  editTire = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.editCreateTire, "put", req);
  };
  createMasterData = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.createMasterData,"post",req);
  };
  createTireImages = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.createImages, "post", req);
  };
  getParentAccountListByCountry = (req) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getParentAccountListByCountry}${req}`,"get", {});
  };
  getUserActions = (req) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.userActions}${req}`, "get", {});
  };
  getTireById = (req) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getTireById}${req}`,"get",{});
  };
  updateUserPreference = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.updateUserPref, "put", req);
  };
  uploadCustomerLogo = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.uploadImg, "post", req);
  };
  updateMaster = (req) => {
    return this.cs.httpConnection( SERVER.SERVICES.updateMasterData, "post", req);
  };
  sendLogoRequestEmail = (req) => {
    return this.cs.httpConnection( SERVER.SERVICES.sendLogoRequestEmail,"post", req);
  };
  createVehicle = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.createVehicle, "post", req);
  };
  createVehicleImages = (req) => {
    return this.cs.httpConnection( SERVER.SERVICES.createVehicleImages,"post", req);
  };
  getVehicleDetails = (VehicleId) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getVehicleDetails}${VehicleId}`, "get", {});
  };
  updateVehicle = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.updateVehicle, "put", req);
  };
  createParentAccount = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.createParentAccount, "post", req);
  };
  createLocation = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.createLocation, "post", req);
  };
  getTiresByAccount = (accountId) => {
    return this.cs.httpConnection( `${SERVER.SERVICES.getTireByAccount}${accountId}`, "get", {});
  };
  updateLastAccessed = (req) => {
    return this.cs.httpConnection( `${SERVER.SERVICES.updateLastAccessed}`, "put", req);
  }
  saveVehicleFootPrintDetails = (req) =>{
    return this.cs.httpConnection( `${SERVER.SERVICES.saveVehicleFootPrintDetails}`, "put", req);
  }
  getInspectionRecordForVehicle = (vehicleId) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getInspectionRecordForVehicle}${vehicleId}`, 'get', {});
  };
  createHomeActivity = (req) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.createHomeActivity}`,'post', req);
  };
  createRim = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.createRim, "post", req);
  };
  getRim = (rimId) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getById}${rimId}`, 'get', {});
  };
  updateRim = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.createRim, "put", req);
  };
  detailsByAccount = (parentAccountId) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.manageVehicleGetDetailsByAccount}${parentAccountId}`,'get',{})
  };
  getInstalledTires = (VehicleId) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getInstalledTires}${VehicleId}`,'get',{})
  };
  getVehicleByLocation = (locationId) => {
    return this.cs.httpConnection(`${SERVER.SERVICES.getVehicleByLocation}${locationId}`,'get',{})
  };
  getAvailablePosForRim = (vehId) => {
    return this.cs.httpConnection(SERVER.SERVICES.getAvailablePosForRim + vehId,'get',{})
  };
}
