import React from "react";
import Moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../../common-modal/CommonPopup.css";
import { getAccountNames, getLocationNames } from "../../../../services/AccountInfoHandler";
import Globalization from "../../../../globalization";
import CommonUtils from "../../../../services/utils";

const commonUtils = new CommonUtils();
pdfMake.vfs = pdfFonts.pdfMake.vfs;
class NewVsScrapPDF extends React.Component {

  tableColumnWidths = [];
  globalization = new Globalization();
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  async PDFMake() {
    return this.makeTableData();
  }
  /** cell making */
  makeCell = (content, headerOrData, options = {}, rowIndex = -1) => {
    let count = this.getCount();

    const makeCellBorderColor = () => {
      if (headerOrData === "data") {
        return ["#ededed", "#ededed", "#ededed", "#ededed"];
      } else if (headerOrData === "newLine") {
        return ["#000", "#000", "#000", "#000"];
      } else {
        return ["#456CAD", "#456CAD", "#456CAD", "#456CAD"];
      }
    };

    const makeCellFontSize = () => {
      if (count >= 13) {
        if (count > 15) {
          return 7;
        } else {
          return 8;
        }
      } else {
        if (headerOrData === "data") {
          return 10;
        } else {
          return this.fontSize;
        }
      }
    };

    const makeCellFillColor = () => {
      if (headerOrData === "data" || headerOrData === "newLine") {
        if (rowIndex % 2) {
          return "#FAFAFB";
        } else {
          return "#FFFFFF";
        }
      } else {
        if (headerOrData === "total") {
          return "#E6ECF6";
        } else {
          return "#1250B0";
        }
      }
    };

    const makeCellColor = () => {
      if (headerOrData === "data" || headerOrData === "total") {
        return "#2E2E2E";
      } else {
        if (headerOrData === "newLine") {
          return "green";
        } else {
          return "white";
        }
      }
    };

    return Object.assign(
      {
        text: content,
        border: [false, false, false, false],
        borderColor: makeCellBorderColor(),
        fontSize: makeCellFontSize(),
        
        //alignment: "left",
        fillColor: makeCellFillColor(),
        color: makeCellColor()
      },
      options
    );
  };
  //-- Format the table cells for presentation.
  thl = (content, headerOrData, options = {}, rowIndex = -1) => {
    return this.makeCell(content, headerOrData, Object.assign({ bold: true, alignment: "left" }, options, rowIndex));
  };
  tdl = (content, headerOrData, options = {}, rowIndex = -1) => {
    return this.makeCell(content, headerOrData, Object.assign({ bold: false, alignment: "left" }, options, rowIndex));
  };
  fontSize = 10;

  getCount = () => {
    let count = 0;
    Object.values(this.props.tableData.selectedColShow).forEach((val) => {
      if (val === true) count++;
      return count;
    });
    return count;
  };
  /** Doc definition */
  async createDocumentDefinition(_reportDate, ...contentParts) {
    let count = this.getCount();
    const baseDocDefinition = {
      pageSize: "A4",
      pageMargins: [20, 90, 20, 55],
      pageOrientation: count >= 13 ? "landscape" : "portrait",
      content: []
    };
    const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
    docDefinition.header = await this.addPdfMakeHeader();
    docDefinition.footer = this.addPdfMakeFooter();

    if (this.props.value === "Table" || this.props.value === "All") docDefinition.content.push(...contentParts);

    const defaultFont = {defaultStyle: {
      font: 'Barlow'
    }}
    docDefinition.defaultStyle = defaultFont;
    
    return docDefinition;
  }


  /** header body and footer */
  async addPdfMakeHeader() {

    return (_currentPage, _pageCount) => {
      return {
        margin: [20, 20, 20, 0],
        stack: [
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    border: [false, false, false, false],
                    
                    color: "white",
                    fillColor: "#1250B0",
                    alignment: "right",
                    columns: [
                      {
                        svg: commonUtils.EmTrackSVGLogo,
                        fit: [50, 80],
                        width: "5%",
                        margin: [0, 2, 0, 2],
                        alignment: "left"
                      },
                      {
                        text: " " + commonUtils.pdfReportName(this.props.tableData.reportName),
                        bold: true,
                        alignment: "center",
                        fontSize: 14,
                        margin: [0, 10, 0, 2]
                      },
                      {
                        svg: commonUtils.GoodyearSVGLogo,
                        alignment: "right",
                        width: "10%",
                        fit: [90, 150]
                      },
                    ]
                  }
                ]
              ]
            }
          },
          {
            table: {
              widths: ["*"],
              margin: [20, 0, 20, 0],
              body: [
                [
                  {
                    
                    fontSize: this.fontSize,
                    border: [false, false, false, false],
                    color: "#074BA7",
                    fillColor: "#E5F0FF",
                    alignment: "left",
                    columns: [
                      {
                        text: this.globalization.REPORTTABLE.DATE + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: Moment(new Date()).format("DD/MM/YYYY"),
                        bold: true
                      },
                      {
                        text: this.globalization.MANAGEPARENTACCOUNT.ACCOUNT + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: getAccountNames().toString(),
                        bold: true
                      },
                      {
                        text: this.globalization.HEADER.LOCATION + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: getLocationNames().toString(),
                        bold: true,
                        width: "auto"
                      }
                    ]
                  }
                ]
              ]
            }
          }
        ]
      };
    };
  }

  tableBody = (pos) => {
    const body = [];
    const TableRow = [];
    let count = 0;
    Object.values(this.props.tableData.selectedColShow).forEach((val) => {
      if (val === true) count++;
      return count;
    });
    let lowerLimit = pos * 13 + 2;
    let upperLimit = lowerLimit + 13;
    let percent = 100 / count + "%";
    this.props.tableData.headCells.forEach((headCell, index) => {
      if (this.props.tableData.selectedColShow[headCell.value] === true) {

        let headCellName = commonUtils.pdfHeaderNameShorter( headCell.key);
        let headerText = headCellName;


        if ((index < 2 || index >= lowerLimit) && index < upperLimit) {
          TableRow.push(
            this.thl(headerText.replace(/([A-Z])/g, ' $1').trim(), "header", {
              border: [false, false, false, true],
              fillColor: "white",
              color: "#2E2E2E",
              bold: true
            }, -1)
          );

          if (count > 11) {
            if (index < 2) percent = "8%";
            else percent = "6.5%";
          }

          this.tableColumnWidths.push(percent);
        }
      }
    });
    body.push(TableRow);

    this.props.tableData.rowsData.forEach((row, index) => {
      const tableRow = [];
      let last = this.props.tableData.rowsData.length - 1 > index ? 0 : 1;
      if (last === 1 && !this.props.tableData.avgValues) {
        last = 0;
      }

      Object.keys(row).forEach((key, id) => {
        if (key !== "Key" && this.props.tableData.selectedColShow[key] === true) {
          let indexId = id - 1;

          if ((indexId < 2 || indexId >= lowerLimit) && indexId < upperLimit) {
            tableRow.push(
              this.tdl(row[key], "data", {
                fontWeight: 400,
                color: "#333333",
                border: last === 1 ? [false, false, false, false] : [false, false, false, true]
              }, indexId)
            );
          }
        }
      });

      body.push(tableRow);
    });
    return body;
  };

  addPdfMakeFooter = () => {
    let footerText = "©" + new Date().getFullYear() + " The Goodyear Tire & Rubber Company ";
    return (currentPage, pageCount) => {
      // you can apply any logic and return any valid pdfmake element
      return {
        margin: [20, 0, 20, 0],
        border: [false, false, false, false],
        table: {
          widths: ["*"],
          body: [
            [
              {
                border: [false, false, false, false],
                fontSize: 9,
                color: "white",
                fillColor: "#1250B0",
                alignment: "left",
                columns: [
                  {
                    text: footerText,
                    alignment: "left",
                    width: "40%",
                    margin: [0, 13, 0, 0],
                    bold: true
                  },
                  {
                    text: "PAGE " + currentPage.toString() + "/" + pageCount.toString(),
                    alignment: "right",
                    margin: [0, 13, 0, 0],
                    fontWeight: 400
                  }
                ]
              }
            ]
          ]
        }
      };
    };
  };


  async makeTableData() {
    // -- Create a base document template for the reports.

    let tableDataReport = {
      stack: []
    };

    let colCount = 0;
    Object.values(this.props.tableData.selectedColShow).forEach((val) => {
      if (val === true) colCount++;
      return colCount;
    });
    let countTable = Math.ceil(colCount / 15);
    let rowCount = this.props.tableData.rowsData.length / 2;

    for (let index = 0; index < countTable; index++) {
      if (rowCount < 6)
        tableDataReport.stack.push(
          {
            width: ["*"],
            table: {
              headerRows: 1,
              widths: this.tableColumnWidths,
              body: this.tableBody(index)
            }
          },
          {
            table: {
              headerRows: 1,
              body: [
                [" ", " ", " "],
                [" ", " ", " "],
                [" ", " ", " "],
                [" ", " ", " "]
              ]
            },
            layout: "noBorders"
          }
        );
      else {
        tableDataReport.stack.push(
          {
            width: ["*"],
            table: {
              headerRows: 1,
              widths: this.tableColumnWidths,
              body: this.tableBody(index)
            }
          },
          {
            pageBreak: "before",
            text: [{ text: " " }]
          }
        );
      }
    }

    const docDefinition = await this.createDocumentDefinition(new Date(), tableDataReport);
    return pdfMake.createPdf(docDefinition);
  }
  render() {
    return <div></div>;
  }
}

export default NewVsScrapPDF;
