import Globalization from "../../../globalization/index";

class RimReportDataSetter {
  constructor() {
    this.data = {};
    this.rimReportData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }

  tableHeaderOrderSetter(headCells) {
    let tableHeaders = headCells;
    // maintains the column order to be displayed (write them in the order you want to display table header)
    const headerOrder = [
      { columnNo: 1, value: "BrandNumber" },
      { columnNo: 2, value: "RimSerialNo" },
      { columnNo: 3, value: "ManufacturerName" },
      { columnNo: 4, value: "SizeName" },
      { columnNo: 5, value: "TypeName" },
      { columnNo: 9, value: "Disposition" },
      { columnNo: 9, value: "VehPos" },
      { columnNo: 8, value: "TotalCost" },
      { columnNo: 6, value: "TotalHours" },
      { columnNo: 7, value: "TotalMiles" },
      { columnNo: 10, value: "TorqueValue" },
      { columnNo: 11, value: "CheckInterval" },
      { columnNo: 12, value: "NextCheck" }
    ];

    let resultHeaderCell = [];
    headerOrder.forEach((headerOrder) => {
      let found = false;
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === headerOrder.value) {
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });

    return resultHeaderCell;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      updatedHeadCells.push(headCell);
    });
    return updatedHeadCells;
  }

  formatData(item) {
    let dateString, nextCheck;
    if (item.nextCheck) {
      dateString = item.nextCheck.split("/");
      nextCheck = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      nextCheck = null;
    }

    let formattedData = {
      nextCheck: nextCheck
    };
    return formattedData;
  }

  initDisplayColumnData(headCells) {
    const selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    const selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, _index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }
  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  calculateTotalAvg(rowsData) {
    let totalHours = 0;
    rowsData.forEach((rowData) => {
      totalHours += rowData.TotalHours;
    });
    return totalHours;
  }
  avgValues = (rimReportData) => {
    let totalHours = this.calculateTotalAvg(rimReportData);
    const avgValues = {
      BrandNumber: "",
      RimSerialNo: "Total / Average",
      ManufacturerName: "",
      SizeName: "",
      TypeName: "",
      Disposition: "",
      VehPos: "",
      TotalCost: "",
      TotalHours: totalHours,
      TotalMiles: "",
      TorqueValue: "",
      CheckInterval: "",
      NextCheck: ""
    };
    return avgValues;
  };
  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.rimReportData.push({
        Key: index,
        BrandNumber: item.brandNumber ? item.brandNumber : "",
        RimSerialNo: item.rimSerialNo ? item.rimSerialNo : "",
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeNameF ? item.typeName : "",
        Disposition: item.disposition ? item.disposition : "",
        VehPos: item.vehPos ? item.vehPos : "",
        TotalCost: item.totalCost ? item.totalCost : 0,
        TotalHours: item.totalHours ? item.totalHours : 0,
        TotalMiles: item.totalMiles ? item.totalMiles : 0,
        TorqueValue: item.torqueValue ? item.torqueValue : "",
        CheckInterval: item.checkInterval ? item.checkInterval : "",
        NextCheck: item.nextCheck ? item.nextCheck : ""
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      if (masterKey === "TYRE") masterKey = "REPORTTABLE";
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: !!(item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal")
      });
    });

    let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells);
    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.rimReportData,
      headCells: orderedHeadCells,
      avgValues: this.avgValues(this.rimReportData),
      selectedColShow: selectedColShow
    };
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.BrandNumber.toLowerCase().includes(searchText) ||
        item.RimSerialNo.toLowerCase().includes(searchText) ||
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.Disposition.toLowerCase().includes(searchText) ||
        item.VehPos.toLowerCase().includes(searchText) ||
        item.TotalCost.toString().includes(searchText) ||
        item.TotalHours.toString().includes(searchText) ||
        item.TotalMiles.toString().includes(searchText) ||
        item.TorqueValue.toLowerCase().includes(searchText) ||
        item.CheckInterval.toString().includes(searchText) ||
        item.NextCheck.toLowerCase().includes(searchText)
      );
    });
  }
}

export default RimReportDataSetter;
