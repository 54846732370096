import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import Globalization from '../../../../../globalization';
import CommonUtils from "../../../../../services/utils";
import './custom-graph.css';

const ByTireConditionGraph = ({ graphData, locationsData, setLocationsData, graphLocationIdSetter }) => {
  const commonUtils = new CommonUtils();
  const globalizationMapping = new Globalization();
  const [chartInfoData, setChartInfoData] = useState({});


  const chart = () => {

    const res = graphData.reduce(function (obj, item) {
      obj[item.WearConditionName] = (obj[item.WearConditionName] || 0) + 1;
      return obj;
    }, {});
    const labelData = Object.keys(res);
    let valueData = Object.values(res);

    let totalValue = 0;
    valueData.forEach(value => {
      totalValue += value;
    });

    valueData = valueData.map((value) => {
      return (value / totalValue * 100).toFixed(1);
    });

    setChartInfoData({
      labels: labelData,
      datasets: [
        {
          backgroundColor: commonUtils.getRandomColorHex(),
          data: valueData,
        }
      ]
    });
  };
  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    title: {
      display: true,
      text: globalizationMapping.GRAPH.TiresWithConditions,
      padding: 40,
      fontSize: 16,
      fontStyle: "bold",
      fontColor: '#000000'
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#666666',
        fontSize: window.innerWidth <= 1024 ? 8 : 12,
        padding: window.innerWidth <= 1024 ? 17 : 20,
        usePointStyle: true,
        boxWidth: 10
      }
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 20
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        labels: {
          value: {
            color: '#666666',
            font: {
              weight: 'bold',
              size: '10'
            }
          }
        },
        formatter: (value) => {
          return Math.round(value) + '%';
        }
      }
    }
  };

  const locationsHandler = selectedLocationIndex => {

    const locationsDataObject = locationsData;
    locationsDataObject.forEach((item, index) => {
      if (index === selectedLocationIndex) {
        item.selected = !item.selected;
      }
    });
    setLocationsData(locationsDataObject);
    graphLocationIdSetter(locationsDataObject);
  };

  return (
    <div className="container">
      {(chartInfoData.labels && chartInfoData.labels.length === 0) &&
        <div className="no-data-container">
          <NoDataFound noDataType="table"></NoDataFound>
        </div>}
      {(chartInfoData.labels && chartInfoData.labels.length !== 0) &&
          <Doughnut
            height={310}
            data={chartInfoData}
            options={options}
          >
          </Doughnut>
      }
      <span>{`${globalizationMapping.HEADER.LOCATION}: `}</span>
      {locationsData && locationsData.map((item, index) => {
        return (
          <span key={item.label} className={item.selected ? "locations-text" : "locations-text-strike"}
            onClick={() => locationsHandler(index)}>
            {item.label}
          </span>
        );
      })
      }
    </div>

  );

};

export default ByTireConditionGraph;
