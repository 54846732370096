import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";

class NewVSScrapSetter {
  constructor() {
    this.data = {};
    this.tireRegisterData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  formatData(registeredDate) {
    let date, formattedDate;
    if (registeredDate) {
      let dateString = registeredDate.split("/");
      date = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
      formattedDate = date;
    } else {
      date = null;
      formattedDate = date;
    }

    return formattedDate;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      updatedHeadCells.push(headCell);
      // }
    });
    return updatedHeadCells;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    //
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.BrandNumber.toString().toLowerCase().includes(searchText) ||
        item.TireSerialNo.toString().toLowerCase().includes(searchText) ||
        item.OriginalVehicleNumber.toString().toLowerCase().includes(searchText) ||
        item.ManufacturerName.toString().toLowerCase().includes(searchText) ||
        item.SizeName.toString().toLowerCase().includes(searchText) ||
        item.TypeName.toString().toLowerCase().includes(searchText) ||
        item.CompoundName.toString().toLowerCase().includes(searchText) ||
        item.TotalHours.toString().toLowerCase().includes(searchText) ||
        item.TotalMiles.toString().toLowerCase().includes(searchText) ||
        item.TreadDepth.toString().toLowerCase().includes(searchText) ||
        item.PercentageWorn.toString().toLowerCase().includes(searchText) ||
        item.Eval.toString().toLowerCase().includes(searchText) ||
        item.NewCost.toString().toLowerCase().includes(searchText) ||
        item.Disposition.toString().toLowerCase().includes(searchText) ||
        item.RegisteredDate.toString().toLowerCase().includes(searchText) ||
        item.FirstInstallationDate.toString().toLowerCase().includes(searchText) ||
        item.PoNumber.toString().toLowerCase().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    //
    //

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }

  avgValues = (tireRegisterData) => {
    let NewCostSum = 0;

    tireRegisterData.forEach((item, index) => {
      NewCostSum += item.NewCost;
    });

    return {
      BrandNumber: "",
      TireSerialNo: "",
      OriginalVehicleNumber: "",
      ManufacturerName: "",
      SizeName: "",
      TypeName: "",
      CompoundName: "",
      TotalHours: "",
      TotalMiles: "",
      TreadDepth: "",
      PercentageWorn: "",
      Eval: "",
      NewCost: NewCostSum,
      Disposition: "",
      RegisteredDate: "",
      FirstInstallationDate: "",
      PoNumber: ""
    };
  };
  foundMonth(m) {
    switch (m) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Aug";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        break;
    }
  }
  setData(data) {
    this.tireRegisterData = [];
    this.headCells = [
      {
        key: "tireSize",
        keyIndex: 0,
        value: "tireSize",
        label: this.globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM.SIZE,
        numeric: false
      },
      {
        key: "newscrap",
        keyIndex: 1,
        value: "newscrap",
        label: this.globalizationMapping.REPORTCOMMONWORDS.NewScrap,
        numeric: false
      }
    ];
    let selectedColShow = this.initDisplayColumnData(this.headCells);
    // TABLE ROWS DATA
    const reportData = data.data;
    if (reportData && reportData.length) {
      let counter = 2;
      let arrCounter = 0;
      //
      reportData.forEach((item, index) => {
        /** NOTE : the keys must match the headCells value to be able to SORT */
        let newCount = {};
        let scrapCount = {};
        newCount["Key"] = arrCounter++;
        scrapCount["Key"] = arrCounter++;
        newCount["tireSize"] = item.tireSize ? item.tireSize.trim() : "";
        scrapCount["tireSize"] = "";
        newCount["newscrap"] = "New";
        scrapCount["newscrap"] = "Scrap";

        let totalNewTire = 0;
        let totalScrapTire = 0;

        item.newTire.forEach((newObj, newIndex) => {
          newCount[this.foundMonth(newObj.month) + "-" + newObj.year.toString().substr(2)] = newObj.totalCount;
          totalNewTire += newObj.totalCount;
        });

        item.scrapTire.forEach((scrapTireObj, newIndex) => {
          scrapCount[this.foundMonth(scrapTireObj.month) + "-" + scrapTireObj.year.toString().substr(2)] = scrapTireObj.totalCount;
          totalScrapTire += scrapTireObj.totalCount;
        });
        newCount["Total"] = totalNewTire;
        scrapCount["Total"] = totalScrapTire;
        //
        this.tireRegisterData.push(newCount);
        this.tireRegisterData.push(scrapCount);
      });

      this.headCells = [
        {
          key: "tireSize",
          keyIndex: 0,
          value: "tireSize",
          label: this.globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM.SIZE,
          numeric: false
        },
        {
          key: "newscrap",
          keyIndex: 1,
          value: "newscrap",
          label: this.globalizationMapping.REPORTCOMMONWORDS.NewScrap,
          numeric: false
        }
      ];
      // TABLE HEADER DATA
      reportData[0].newTire.forEach((newObj, newIndex) => {
        this.headCells.push({
          key: this.foundMonth(newObj.month) + "-" + newObj.year.toString().substr(2),
          keyIndex: counter++,
          value: this.foundMonth(newObj.month) + "-" + newObj.year.toString().substr(2),
          label: this.foundMonth(newObj.month) + "-" + newObj.year.toString().substr(2),
          numeric: true
        });
      });

      this.headCells.push({
        key: "Total",
        keyIndex: counter++,
        value: "Total",
        label: "Total",
        numeric: true
      });

      selectedColShow = this.initDisplayColumnData(this.headCells);
    }
    return {
      rowsData: this.tireRegisterData,
      headCells: this.headCells,
      selectedColShow: selectedColShow
    };
  }
}

export default NewVSScrapSetter;
