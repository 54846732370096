import React, { useEffect, useState } from "react";
import "./AllTireReportTable.css";
import "../../../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../services/utils";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    height: 625
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));


const AllTireReportTable = ({ tableData, showColumns }) => {
  const [allTireReportTableData, setAllTireReportTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("NID");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const commonUtils = new CommonUtils();


  useEffect(() => {
    setAllTireReportTableData(tableData);
    setPage(0);
    let arr = commonUtils.rowPerPageSelector(allTireReportTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
  }, [tableData, showColumns]);

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
       let firstVal = a[orderBy].toLowerCase().trim()
       let secondVal = b[orderBy].toLowerCase().trim()
       if (secondVal < firstVal) {
         return -1;
       }
       if (secondVal > firstVal) {
         return 1;
       }
       return 0;
  
     } else {
      let firstVal = a[orderBy]
      let secondVal = b[orderBy]
      if (secondVal < firstVal) {
        return -1;
      }
      if (secondVal > firstVal) {
        return 1;
      }
      return 0;
    }
  }
  
  
  function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table 
              id="tire-history-table" 
              stickyHeader 
              className={classes.table} 
              aria-labelledby="tableTitle" 
              aria-label="a dense table"
              size="small"
              >
              <TableHead>
                <TableRow>
                  {allTireReportTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className="table-head-row"
                        key={headCell.keyIndex}
                        // align={headCell.numeric === true ? "right" : "left"}
                        align="left"
                        sortDirection={orderBy === headCell.value ? order : false}
                      >
                        <TableSortLabel active={orderBy === headCell.value} direction={orderBy === headCell.value ? order : "asc"} onClick={handleRequestSort(headCell.value)}>
                          {headCell.label}
                          {orderBy === headCell.value ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {allTireReportTableData.rowsData.length > 0 && (
                <TableBody>
                {stableSort(allTireReportTableData.rowsData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={row.Key}>
                        {showColumns.NID && <TableCell className="table-content-text">{row.NID}</TableCell>}
                        {showColumns.parentAccount && <TableCell className="table-content-text">{row.ParentAccount}</TableCell>}
                        {showColumns.Location && <TableCell className="table-content-text">{row.Location}</TableCell>}
                        {showColumns.BrandNo && <TableCell className="table-content-text">{row.BrandNo}</TableCell>}
                        {showColumns.TireSerialNo && <TableCell className="table-content-text">{row.TireSerialNo}</TableCell>}
                        {showColumns.Manufacturer && <TableCell className="table-content-text">{row.Manufacturer}</TableCell>}
                        {showColumns.Size && <TableCell className="table-content-text">{row.Size}</TableCell>}
                        {showColumns.Type && <TableCell className="table-content-text">{row.Type}</TableCell>}
                        {showColumns.Compound && <TableCell className="table-content-text">{row.Compound}</TableCell>}
                        {showColumns.OTD && <TableCell className="table-content-text">{row.OTD}</TableCell>}
                        {showColumns.OTDInside && <TableCell className="table-content-text">{row.OTDInside}</TableCell>}
                        {showColumns.OTDOutside && <TableCell className="table-content-text">{row.OTDOutside}</TableCell>}
                        {showColumns.EvalNo && <TableCell className="table-content-text">{row.EvalNo}</TableCell>}
                        {showColumns.PurchaseTD && <TableCell className="table-content-text">{row.PurchaseTD}</TableCell>}
                        {showColumns.PurchaseCost && <TableCell className="table-content-text">{row.PurchaseCost}</TableCell>}
                        {showColumns.CostAdjustment && <TableCell className="table-content-text">{row.CostAdjustment}</TableCell>}
                        {showColumns.Status && <TableCell className="table-content-text">{row.Status}</TableCell>}
                        {showColumns.LastActivityDate && <TableCell className="table-content-text">{row.LastActivityDate}</TableCell>}
                        {showColumns.CurrentTD && <TableCell className="table-content-text">{row.CurrentTD}</TableCell>}
                        {showColumns.CurrentTDInside && <TableCell className="table-content-text">{row.CurrentTDInside}</TableCell>}
                        {showColumns.CurrentTDOutside && <TableCell className="table-content-text">{row.CurrentTDOutside}</TableCell>}
                        {showColumns.CurrentHours && <TableCell className="table-content-text">{row.CurrentHours}</TableCell>}
                        {showColumns.CurrentDistance && <TableCell className="table-content-text">{row.CurrentDistance}</TableCell>}
                        {showColumns.CurrentUnit && <TableCell className="table-content-text">{row.CurrentUnit}</TableCell>}
                        {showColumns.CurrentPosition && <TableCell className="table-content-text">{row.CurrentPosition}</TableCell>}
                        {showColumns.CurrentDisposition && <TableCell className="table-content-text">{row.CurrentDisposition}</TableCell>}
                        {showColumns.HrsPerDistance && <TableCell className="table-content-text">{row.HrsPerDistance}</TableCell>}
                        {showColumns.FirstInstallDate && <TableCell className="table-content-text">{row.FirstInstallDate}</TableCell>}
                        {showColumns.FirstInstallUnit && <TableCell className="table-content-text">{row.FirstInstallUnit}</TableCell>}
                        {showColumns.FirstInstallPosition && <TableCell className="table-content-text">{row.FirstInstallPosition}</TableCell>}
                        {showColumns.FirstInstallTD && <TableCell className="table-content-text">{row.FirstInstallTD}</TableCell>}
                        {showColumns.FirstInstallTDInside && <TableCell className="table-content-text">{row.FirstInstallTDInside}</TableCell>}
                        {showColumns.FirstInstallTDOutside && <TableCell className="table-content-text">{row.FirstInstallTDOutside}</TableCell>}
                        {showColumns.Hours && <TableCell className="table-content-text">{row.Hours}</TableCell>}
                        {showColumns.Distance && <TableCell className="table-content-text">{row.Distance}</TableCell>}
                        {showColumns.RepairCost && <TableCell className="table-content-text">{row.RepairCost}</TableCell>}
                        {showColumns.NumberOfRepairs && <TableCell className="table-content-text">{row.NumberOfRepairs}</TableCell>}
                        {showColumns.ScrapDate && <TableCell className="table-content-text">{row.ScrapDate}</TableCell>}
                        {showColumns.ScrapReason && <TableCell className="table-content-text">{row.ScrapReason}</TableCell>}
                        {showColumns.DollarsLost && <TableCell className="table-content-text">{row.DollarsLost.toFixed(2)}</TableCell>}
                        {showColumns.ScrapUnit && <TableCell className="table-content-text">{row.ScrapUnit}</TableCell>}
                        {showColumns.ScrapPosition && <TableCell className="table-content-text">{row.ScrapPosition}</TableCell>}
                        {showColumns.ScrapHours && <TableCell className="table-content-text">{row.ScrapHours}</TableCell>}
                        {showColumns.ScrapTD && <TableCell className="table-content-text">{row.ScrapTD}</TableCell>}
                      </TableRow>
                    );
                  })}
              </TableBody>
              )}
              {allTireReportTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={8} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={allTireReportTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default AllTireReportTable;
