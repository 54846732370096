/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  withStyles,
} from "@material-ui/core";
import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded";
import deleteIcon from "../../assets/delete-icon.svg";
import Globalization from "../../globalization";
import CalendarMonthIcon from '@material-ui/icons/Today';
import CommonUtils from "../../services/utils";
import ExpandMoreStyledIcon from "../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";

const styles = makeStyles({
  buttons: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    width: "100px",
    textAlign: "center",
    boxShadow: "none",
  },
  adorn: {
    "& .MuiTypography-colorTextSecondary": {
      color: "#000000",
      fontWeight: "800",
    },
  },
  input: {
    display: "none",
  },
  deleteBadge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "10px",
      right: "17px",
      background: "transparent",
    },
  },
  buttonGroup: {
    width: "100%",
  },
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 23,
    padding: 0,
    margin: 5,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(36px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#D14449",
        borderColor: "#FFFFFF",
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 15,
    opacity: 1,
    backgroundColor: "#58CCE8",
  },
  checked: {},
}))(Switch);

const TireInspectionForm = (props) => {
  const classes = styles();
  let treadReadings = props.locationData.noOfTreadDepthReadings;
  const commonUtils = new CommonUtils();

  useEffect(() => {
    treadReadings = props.locationData.noOfTreadDepthReadings;
  }, [props.locationData]);

  const globalizationMapping = new Globalization();
  const formCaptions = globalizationMapping.INSPECTNREMOVETIRE;
  const formUnitCaptions = globalizationMapping.FORMS;
  const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
  const inspectionDate = globalizationMapping.INSPECT.INSPECTIONDATE;

  const handleDateChange = (value) => {
    props.onChangeListener("inspdate", value);
  };

  const [checkboxForPreviousComment, setCheckboxForPreviousComment] = useState(false);
  const handleCommentChange = () => {
    setCheckboxForPreviousComment(!checkboxForPreviousComment);
    // Previous Comments in Report
    props.onChangeListener("comments", props.storePreviousComments);
  }

  const treadCondition = () => {
    let treadDepthMethodId = props.locationData.treadDepthMethodId;
    if (treadDepthMethodId === 3) {
      return <p className="field-label">{tireFormCaptions.MINTRD}</p>
    } else if (treadDepthMethodId === 2) {
      return <p className="field-label">{tireFormCaptions.MAXTRD}</p>
    } else {
      return <p className="field-label">{tireFormCaptions.AVGTRD}</p>
    }
  }

  const unitPreferences = () => {
    let unitPreference = props.unitPreferences.pressure;
    if (unitPreference === "PSI") {
      return "PSI"
    } else if (unitPreference === "KPA") {
      return "KPA"
    } else {
      return "Bar"
    }
  }


  return (
    <Grid container className="step-form-inner-container" spacing={2}>
      <Grid item sm={12}>
        <p className="field-label">
          {inspectionDate} <span className="required-mark">*</span>
        </p>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className="enter-detail-input-box"
            // variant="inline"
            value={props.tireInspectionData.inspectionDate}
            onChange={handleDateChange}
            variant="outlined"
            format={commonUtils.getDateFormat()}
            placeholder={commonUtils.getDateFormat()}
            keyboardIcon={<CalendarMonthIcon />}
            helperText=""
            maxDate={new Date()}
            minDate={props.tireData.inspectionDate}
          />
        </MuiPickersUtilsProvider>
        <FormHelperText>{props.formHelpers.inspectionDate}</FormHelperText>
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{tireFormCaptions.MANUFACTURER}</p>
        <TextField
          className="enter-detail-input-box disabled-input"
          variant="outlined"
          value={props.tireData.manufacturerName}
          disabled
        />
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{tireFormCaptions.SIZE}</p>
        <TextField
          className="enter-detail-input-box disabled-input"
          variant="outlined"
          value={props.tireData.sizeName}
          disabled
        />
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{tireFormCaptions.TYPE}</p>
        <TextField
          className="enter-detail-input-box disabled-input"
          variant="outlined"
          value={props.tireData.typeName}
          disabled
        />
      </Grid>
      <Grid item sm={12}>
        <p style={{ display: "inline" }}>
          <span className="field-label additional1">
            {formCaptions.OUTTRD}&nbsp;<span className="required-mark">*</span>
          </span>
          <span className="field-label additional2">
            {isNaN(
              Math.round(
                ((parseInt(props.tireData.originalTread) -
                  parseInt(props.tireInspectionData.outsideTread)) /
                  (parseInt(props.tireData.originalTread) -
                    parseInt(props.tireData.removeAt))) *
                100
              )
            )
              ? 0
              : Math.round(
                ((parseInt(props.tireData.originalTread) -
                  parseInt(props.tireInspectionData.outsideTread)) /
                  (parseInt(props.tireData.originalTread) -
                    parseInt(props.tireData.removeAt))) *
                100
              )}
            {globalizationMapping.INSPECTNREMOVETIRE.PERCENTAGEWORN}
          </span>
        </p>
        <ButtonGroup
          size="large"
          className={classes.buttonGroup}
          aria-label="medium outlined button group"
        >
          <Button
            className={classes.buttons}
            onClick={() =>
              props.onChangeListener(
                "outtread",
                props.tireInspectionData.outsideTread - 1
              )
            }
          >
            -
          </Button>
          <FormControl
            variant="outlined"
            className="enter-detail-input-box buttoned-input"
          >
            <OutlinedInput
              value={
                !isNaN(parseInt(props.tireInspectionData.outsideTread))
                  ? props.tireInspectionData.outsideTread
                  : ""
              }
              onChange={(e) =>
                props.onChangeListener("outtread", commonUtils.minmaxInt(e.target.value, 0, 999999))
              }
              endAdornment={
                <InputAdornment className={classes.adorn} position="end">
                  {props.unitPreferences.treadDepth === "imperial"
                    ? formUnitCaptions["32"]
                    : formUnitCaptions.MM}
                </InputAdornment>
              }
              type="number"
              disabled={false}
            />
          </FormControl>
          <Button
            className={classes.buttons}
            onClick={() =>
              props.onChangeListener(
                "outtread",
                props.tireInspectionData.outsideTread + 1
              )
            }
          >
            +
          </Button>
        </ButtonGroup>
        <FormHelperText>{props.formHelpers.outsideTread}</FormHelperText>
      </Grid>
      {(treadReadings === 2 || treadReadings === 3) && (
        <Grid item sm={12}>
          <div style={{ display: "inline" }}>
            <span className="field-label additional1">
              {formCaptions.INTRD}&nbsp;<span className="required-mark">*</span>
            </span>
            <span className="field-label additional2">
              {isNaN(
                Math.round(
                  ((parseInt(props.tireData.originalTread) -
                    parseInt(props.tireInspectionData.insideTread)) /
                    (parseInt(props.tireData.originalTread) -
                      parseInt(props.tireData.removeAt))) *
                  100
                )
              )
                ? 0
                : Math.round(
                  ((parseInt(props.tireData.originalTread) -
                    parseInt(props.tireInspectionData.insideTread)) /
                    (parseInt(props.tireData.originalTread) -
                      parseInt(props.tireData.removeAt))) *
                  100
                )}
              {globalizationMapping.INSPECTNREMOVETIRE.PERCENTAGEWORN}
            </span>
          </div>
          <ButtonGroup
            size="large"
            className={classes.buttonGroup}
            aria-label="medium outlined button group"
          >
            <Button
              className={classes.buttons}
              onClick={() =>
                props.onChangeListener(
                  "intread",
                  props.tireInspectionData.insideTread - 1
                )
              }
            >
              -
            </Button>
            <FormControl
              variant="outlined"
              className="enter-detail-input-box buttoned-input"
            >
              <OutlinedInput
                value={
                  !isNaN(parseInt(props.tireInspectionData.insideTread))
                    ? props.tireInspectionData.insideTread
                    : ""
                }
                onChange={(e) =>
                  props.onChangeListener("intread", commonUtils.minmaxInt(e.target.value, 0, 999999))
                }
                endAdornment={
                  <InputAdornment className={classes.adorn} position="end">
                    {props.unitPreferences.treadDepth === "imperial"
                      ? formUnitCaptions["32"]
                      : formUnitCaptions.MM}
                  </InputAdornment>
                }
                type="number"
                disabled={false}
              />
            </FormControl>
            <Button
              className={classes.buttons}
              onClick={() =>
                props.onChangeListener(
                  "intread",
                  props.tireInspectionData.insideTread + 1
                )
              }
            >
              +
            </Button>
          </ButtonGroup>
          <FormHelperText>{props.formHelpers.insideTread}</FormHelperText>
        </Grid>
      )}
      {treadReadings === 3 && (
        <Grid item sm={12}>
          <div style={{ display: "inline" }}>
            <span className="field-label additional1">
              {formCaptions.MIDTRD}&nbsp;
              <span className="required-mark">*</span>
            </span>
            <span className="field-label additional2">
              {isNaN(
                Math.round(
                  ((parseInt(props.tireData.originalTread) -
                    parseInt(props.tireInspectionData.middleTread)) /
                    (parseInt(props.tireData.originalTread) -
                      parseInt(props.tireData.removeAt))) *
                  100
                )
              )
                ? 0
                : Math.round(
                  ((parseInt(props.tireData.originalTread) -
                    parseInt(props.tireInspectionData.middleTread)) /
                    (parseInt(props.tireData.originalTread) -
                      parseInt(props.tireData.removeAt))) *
                  100
                )}
              {globalizationMapping.INSPECTNREMOVETIRE.PERCENTAGEWORN}
            </span>
          </div>
          <ButtonGroup
            size="large"
            className={classes.buttonGroup}
            aria-label="medium outlined button group"
          >
            <Button
              className={classes.buttons}
              onClick={() =>
                props.onChangeListener(
                  "midtread",
                  props.tireInspectionData.middleTread - 1
                )
              }
            >
              -
            </Button>
            <FormControl
              variant="outlined"
              className="enter-detail-input-box buttoned-input"
            >
              <OutlinedInput
                value={
                  !isNaN(parseInt(props.tireInspectionData.middleTread))
                    ? props.tireInspectionData.middleTread
                    : ""
                }
                type="number"
                onChange={(e) =>
                  props.onChangeListener("midtread", commonUtils.minmaxInt(e.target.value, 0, 999999))
                }
                endAdornment={
                  <InputAdornment className={classes.adorn} position="end">
                    {props.unitPreferences.treadDepth === "imperial"
                      ? formUnitCaptions["32"]
                      : formUnitCaptions.MM}
                  </InputAdornment>
                }
                disabled={false}
              />
            </FormControl>
            <Button
              className={classes.buttons}
              onClick={() =>
                props.onChangeListener(
                  "midtread",
                  props.tireInspectionData.middleTread + 1
                )
              }
            >
              +
            </Button>
          </ButtonGroup>
          <FormHelperText>{props.formHelpers.middleTread}</FormHelperText>
        </Grid>
      )}
      <Grid item sm={12}>
        {treadCondition()}
        <TextField
          className="enter-detail-input-box disabled-input"
          variant="outlined"
          value={props.tireInspectionData.currentTreadDepth}
          disabled
        />
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">
          {formCaptions.AIRPRESSURETYPE}&nbsp;
          <span className="required-mark">*</span>
        </p>
        <FormControlLabel
          style={{ float: "left", marginBlockStart: "15px", fontWeight: "600" }}
          control={
            <AntSwitch
              checked={
                props.tireInspectionData.pressureType === "H" ? true : false
              }
              onChange={(e) =>
                props.onChangeListener("pressuretype", e.target.checked)
              }
            />
          }
          label={props.tireInspectionData.pressureType === "H" ? "Hot" : "Cold"}
          labelPlacement="end"
        />
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">
          {formCaptions.AIRPRESSURE}&nbsp;
          <span className="required-mark">*</span>
        </p>
        <ButtonGroup
          size="large"
          className={classes.buttonGroup}
          aria-label="medium outlined button group"
        >
          <Button
            className={classes.buttons}
            onClick={() =>
              props.onChangeListener(
                "pressure",
                props.unitPreferences.pressure === "bar" ||
                  props.unitPreferences.pressure === "Bar"
                  ? Math.round(
                    (Number(props.tireInspectionData.currentPressure) - 1) *
                    10
                  ) / 10
                  : props.tireInspectionData.currentPressure - 1
              )
            }
          >
            -
          </Button>
          <FormControl variant="outlined" className="enter-detail-input-box">
            <OutlinedInput
              value={
                !isNaN(parseInt(props.tireInspectionData.currentPressure))
                  ? props.tireInspectionData.currentPressure
                  : ""
              }
              type="number"
              endAdornment={
                <InputAdornment className={classes.adorn} position="end">
                  {unitPreferences()}
                </InputAdornment>
              }
              onChange={(e) =>
                props.onChangeListener(
                  "pressure",
                  props.unitPreferences.pressure === "bar" ||
                    props.unitPreferences.pressure === "Bar"
                    ? String(Math.round(Number(e.target.value) * 10) / 10)
                    : commonUtils.minmaxInt(e.target.value, 0, 999999)
                )
              }
            />
          </FormControl>
          <Button
            className={classes.buttons}
            onClick={() =>
              props.onChangeListener(
                "pressure",
                props.unitPreferences.pressure === "bar" ||
                  props.unitPreferences.pressure === "Bar"
                  ? Math.round(
                    (Number(props.tireInspectionData.currentPressure) + 1) *
                    10
                  ) / 10
                  : props.tireInspectionData.currentPressure + 1
              )
            }
          >
            +
          </Button>
        </ButtonGroup>
        <FormHelperText>{props.formHelpers.currentPressure}</FormHelperText>
      </Grid>
      {props.casingConditionList && props.casingConditionList.length > 0 && (
        <Grid item sm={12}>
          <p className="field-label">{formCaptions.CASINGCONDITION}</p>
          <Select
            native
            className="enter-detail-input-box"
            variant="outlined"
            IconComponent={ExpandMoreStyledIcon}
            value={props.tireInspectionData.casingConditionId}
            onChange={(e) =>
              props.onChangeListener("casing", parseInt(e.target.value))
            }
          >
            <option key={0} value={null}>
              {tireFormCaptions.DROPDOWNPLACEHOLDER}
            </option>
            {props.casingConditionList.map((condition) => (
              <option
                key={condition.casingConditionId}
                value={condition.casingConditionId}
              >
                {condition.casingConditionName}
              </option>
            ))}
          </Select>
        </Grid>
      )}
      {props.wearConditionList && props.wearConditionList.length > 0 && (
        <Grid item sm={12}>
          <p className="field-label">{formCaptions.WEARCONDITION}</p>
          <Select
            native
            className="enter-detail-input-box"
            variant="outlined"
            value={props.tireInspectionData.wearConditionId}
            IconComponent={ExpandMoreStyledIcon}
            onChange={(e) =>
              props.onChangeListener("wear", parseInt(e.target.value))
            }
          >
            <option key={0} value={null}>
              {tireFormCaptions.DROPDOWNPLACEHOLDER}
            </option>
            {props.wearConditionList.map((condition) => (
              <option
                key={condition.wearConditionId}
                value={condition.wearConditionId}
              >
                {condition.wearConditionName}
              </option>
            ))}
          </Select>
        </Grid>
      )}
      <Grid item sm={12}>
        <p className="field-label">{"List of " + formCaptions.PREVIOUSCOMMENTS}</p>
        <TextField
          className="enter-detail-input-box"
          variant="filled"
          value={props.storePreviousComments}
          multiline
          rows={2}
          disabled
        // disabled Checkbox for Previous comments
        />
      </Grid >


      <Grid item sm={12}>
        <p className="field-label">{"Select " + formCaptions.PREVIOUSCOMMENTS}</p>
        <p className="field-label" style={{
          margin: "auto"
        }}>
          <Checkbox
            style={{
              color: "#0055A4"
            }}

            onChange={handleCommentChange}
          />
          Still Relevant</p>
      </Grid >


      <Grid item sm={12}>
        <p className="field-label">
          {globalizationMapping.REPORTTABLE.COMMENTS}{" "}
        </p>
        <TextField
          className="enter-detail-input-box"
          variant="outlined"
          value={checkboxForPreviousComment ? props.storePreviousComments : props.tireInspectionData.comments}
          onChange={(e) => {
            props.onChangeListener("comments", commonUtils.removeLeadingSpaces(e.target.value));
          }}
          multiline
          rows={2}
          type="text"
          placeholder={globalizationMapping.INSPECT.TIREINSPECTCOMMENTS}
          inputProps={{ maxLength: 200 }}
        />
      </Grid>
      <Grid item sm={12}>
        <Grid
          className="upload-box-description-inspect-tire"
          container
          spacing={1}
        >
          <Grid item sm={6}>
            <InputLabel className="upload-label">
              {tireFormCaptions.IMAGESCAPTION}
            </InputLabel>
          </Grid>
          <Grid item sm={6}>
            <input
              accept="image/*"
              className={classes.input}
              id="image-input-hidden"
              onChange={(e) => {
                props.onChangeListener("images", e.target);
              }}
              type="file"
            />
            <label htmlFor="image-input-hidden">
              <Button
                className="upload-Button"
                variant="outlined"
                size="large"
                aria-label="upload picture"
                component="span"
                startIcon={<PhotoCameraRoundedIcon />}
              >
                {tireFormCaptions.UPLOAD}
              </Button>
            </label>
          </Grid>
        </Grid>
        <FormHelperText>{props.formHelpers.imgHelper}</FormHelperText>
      </Grid>
      <Grid item sm={12}>
        <Grid item sm={12}>
          {props.tireimages.length !== 0 && (
            <p className="field-label-upload">
              {tireFormCaptions.IMAGESCAPTION}
            </p>
          )}
          <Grid container spacing={1} className="uploaded-images">
            {props.tireimages.length !== 0 &&
              props.tireimages.map((source, key) => (
                <Badge
                  className={classes.deleteBadge}
                  key={key}
                  badgeContent={
                    <img
                      alt="delete"
                      src={deleteIcon}
                      onClick={() => props.deleteImg(key)}
                    />
                  }
                  color="primary"
                  variant="standard"
                >
                  <Grid item sm={4} className="uploaded-image">
                    <img
                      alt={"chosen-img-".concat((key + 1).toString())}
                      src={source}
                      width="160px"
                      height="160px"
                    />
                  </Grid>
                </Badge>
              ))}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item sm={12}>
                <Grid className="remove-box-description" container spacing={1}>
                    <Grid item sm={6}>
                        <InputLabel
                            className="upload-label"
                        >{formCaptions.REMOVETIRE}</InputLabel>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            className="upload-Button"
                            variant="outlined"
                            size="large"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => { props.gotoDelete() }}
                            startIcon={<DeleteIcon />} >
                            {formCaptions.REMOVE}
                        </Button>
                    </Grid>
                </Grid>
            </Grid> */}
    </Grid>
  );
};

export default TireInspectionForm;
