import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import "./GroupButton.css";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  button: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    font: "Barlow",
    opacity: .6,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
    width: '30%',
    minWidth: '30%',
  },
})

const GroupedButtons = ({ disabled, value, onChangeNum, pressureUnit, tabValue }) => {
  const styles = useStyles();
  const [num, setNum] = useStateWithCallbackLazy(value);
  const handleIncrement = () => {
    let isFloat = num.toString()
    if (isFloat.includes('.') && pressureUnit && pressureUnit.toLowerCase() === 'bar')
      setNum(parseFloat(num) + 1, (cnum) => {
        onChangeNum(cnum);
      });
    else
      setNum(parseInt(num, 10) + 1, (cnum) => {
        onChangeNum(cnum);
      });
  };
  const handleDecrement = () => {
    let isFloat = num.toString()
    if (isFloat.includes('.') && pressureUnit && pressureUnit.toLowerCase() === 'bar')
      setNum(parseFloat(num, 10) - 1, (cnum) => {
        onChangeNum(cnum);
      });
    else
      setNum(parseInt(num, 10) - 1, (cnum) => {
        onChangeNum(cnum);
      });
  };

  const onChangeValue = (e) => {
    let paramValue = e.target.value;
    let lastDigit = paramValue[paramValue.length - 1];
    if (!isNaN(lastDigit)) {
      if (e.target && e.target.value && e.target.value !== "") {
        let isFloat = e.target.value.toString()
        if (isFloat.includes('.') && pressureUnit && pressureUnit.toLowerCase() === 'bar') {
          setNum(parseFloat(e.target.value).toFixed(1), (cnum) => {
            onChangeNum(cnum);
          });
        }
        else
          setNum(parseInt(e.target.value, 10), (cnum) => {
            onChangeNum(cnum);
          });
      } else {
        setNum(0, (cnum) => {
          onChangeNum(cnum);
        });
      }
    }
    else {
      if (e.target && e.target.value && e.target.value !== "" && lastDigit === '.' &&
        pressureUnit && pressureUnit.toLowerCase() === 'bar')
        setNum((e.target.value), (cnum) => {
          onChangeNum(cnum);
        });

      else
        setNum(0, (cnum) => {
          onChangeNum(cnum);
        });
    }
  }

  const displayCounter = num >= 0;
  return (
    <ButtonGroup
      size="small"
      style={{ width: "100%", height: "100%" }}
      aria-label="small outlined button group"
    >

      {displayCounter && (
        <Button
          style={{ color: "#FFFFFF", background: (disabled || num <= 0) ? "#B7BCC3" : "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box", opacity: disabled ? 1 : .6 }}
          // disabled={disabled ? true : false}
          className={styles.button}
          tabIndex="-1"
          onClick={() => handleDecrement()}
          disabled={num <= 0}
        >
          -
        </Button>
      )}
      {displayCounter && num >= 0 && (
        <FormControl
          style={{
            width: "40%",
            minWidth: "40%",
            backgroundColor: "aliceblue",
            color: "black",
            textAlign: 'center',
            borderRadius: 5
          }}
          variant="outlined"
          className="digits"
          onChange={(e) => onChangeValue(e)}
        >
          <input disabled={disabled} tabIndex={tabValue} value={num} inputProps={{ min: 0, style: { textAlign: 'center' } }} ></input>
        </FormControl>
      )}

      <Button
        style={{ color:"#FFFFFF", background: disabled ? "#B7BCC3" : "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box", opacity: disabled ? 1 : .6 }}
        disabled={disabled}
        className={styles.button}
        tabIndex="-1"
        onClick={() => handleIncrement()}
      >
        +
      </Button>
    </ButtonGroup>
  );
};
export default GroupedButtons;
