import React, { useContext, useEffect, useState } from "react";
import Globalization from "../../../globalization";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import "./edit-location.css";
import {
  FormHelperText,
  Grid,
  TextField,
  Select,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { StateContext } from "../../../services/StateProvider";
import HeaderApi from "../../../common-components/header/services/header-apis";
import { accountInfoIdsFormatter } from "../../../services/AccountInfoHandler";
import Spinner from "../../../common-components/spinner/spinner";
import CancelModal from "./CancelModal";
import ExpandMoreStyledIcon from "../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import CommonUtils from "../../../services/utils";

const useStyles = makeStyles({
  save: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5,
  },
  FormHelperText: {
    marginTop: "60px",
  },
});

const EditLocation = () => {
  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const [globalAccountState, globalAccountDispatch] = useContext(StateContext);
  const history = useHistory();
  const styles = useStyles();
  const locationObj = useLocation();
  const headerApiObj = new HeaderApi();
  const commonUtils = new CommonUtils();
  const [countryList, setCountryList] = useState([{}]);
  const [locationDetails, setLocationDetails] = useState({});
  const [masterData, setMasterData] = useState({});
  const [appTypesByMarketSegment, setAppTypesByMarketSegment] = useState([]);
  const [materialByMarketSegment, setMaterialByMarketSegment] = useState([]);
  const [currentCountryIndex, setCurrentCountryIndex] = useState(-1);
  const [errors, setErrors] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [editLocation, setEditLocation] = useState(0)
  const [isLocationNameEdited, setILocationNameEdited] = useState(false);
  const [isAddressLine1Edited, setIsAddressLine1Edited] = useState(false);
  const [isCountryEdited, setIsCountryEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isZipCodeEdited, setIsZipCodeEdited] = useState(false);
  const [isPhoneNumberEdited, setIsPhoneNumberEdited] = useState(false);
  const [isEmailAddressEdited, setIsEmailAddressEdited] = useState(false);
  const [isContactPersonEdited, setIsContactPersonEdited] = useState(false);
  const [isNoOfTDEdited, setIsNoOfTDEdited] = useState(false);
  const [isTreadDepthMethodIdEdited, setIsTreadDepthMethodIdEdited] = useState(false);
  const [isInspectionOrderIdEdited, setIsInspectionOrderIdEdited] = useState(false);
  const [isMarketSegmentIdEdited, setIsMarketSegmentIdEdited] = useState(false);
  const [isApplicationTypeIdEdited, setIsApplicationTypeIdEdited] = useState(false);
  const [isMaterialEdited, setIsMaterialEdited] = useState(false);
  const [isSiteSeverityIndexEdited, setIsSiteSeverityIndexEdited] = useState(false);

  useEffect(() => {
    setShowSpinner(true);
    headerApiObj.getMasterDataWeb().then((mData) => {
      if (mData.model !== undefined) {
        setMasterData(mData.model);
      }
      getCountryList(mData);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);


  function getCountryList(mData) {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let locationIds = accountInfoObject.locationIds;

    headerApiObj.getCountryList().then((countryData) => {
      filterCurrency(countryData.model);
      setCountryList(countryData.model);
      if (locationObj.pathname === "/editlocation") {
        if (locationObj.state && locationObj.state.accountId)
          locationIds[0] = locationObj.state.accountId;

        getLocation(mData, countryData, locationIds);
        setShowSpinner(false);
      }
    });
  }
  function getLocation(mData, countryData, locationIds) {
    headerApiObj.getLocationById(locationIds[0]).then((accountData) => {
      if (accountData.model) {
        setLocationDetails(accountData.model);
        let appTypes = [];
        let materialBySegment = [];
        // eslint-disable-next-line array-callback-return
        mData.model.applicationTypes !== undefined &&
          mData.model.applicationTypes.forEach((appType) => {
            if (appType.marketSegmentId === accountData.model.marketSegmentId)
              appTypes.push(appType);
          });

        // eslint-disable-next-line array-callback-return
        mData.model.materials !== undefined &&
          mData.model.materials.forEach((material) => {
            if (material.marketSegmentId === accountData.model.marketSegmentId)
              materialBySegment.push(material);
          });

        setAppTypesByMarketSegment(appTypes);
        setMaterialByMarketSegment(materialBySegment);

        let currentCountryIndexLocal = countryData.model.findIndex(
          (country) =>
            parseInt(country.countryId) === parseInt(accountData.model.country)
        );
        setCurrentCountryIndex(currentCountryIndexLocal);
      }
      localStorage.setItem(
        "InspectionOrderId",
        JSON.parse(accountData.model.inspectionOrderId)
      );
    });
  }
  function filterCurrency(data) {
    let newArray = [];
    let uniqueObject = {};
    for (let i in data) {
      let objTitle = data[i]["currency"];
      uniqueObject[objTitle] = data[i];
    }
    for (let i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    setCurrencyList(newArray);
  }


  localStorage.setItem("editLocation", editLocation)
  const onClickSaveStatus = () => {
    setEditLocation(editLocation + 1)
    if (validateForm()) {
      let date = new Date();
      let setSuccess = false;
      date = date.toISOString();

      if (locationDetails.state === "" && currentCountryIndex !== -1 &&
      countryList[currentCountryIndex].states.length > 0) {
        locationDetails.state = countryList[currentCountryIndex].states[0].stateName;
        setLocationDetails({
          ...locationDetails,
          state: countryList[currentCountryIndex].states[0].stateName,
          updatedDate: date,
        });
      }


      setLocationDetails({ ...locationDetails, updatedDate: date });
      headerApiObj.updateLocation(locationDetails).then((data) => {
        if (data) {
          setSuccess = true;
          let accountState = globalAccountState;
          let index = accountState.accountInfo.location.findIndex(
            (loc) => locationDetails.locationId === loc.value
          );
          if (index !== -1) {
            accountState.accountInfo.location[index].label =
              locationDetails.locationName;
          }
          const initialAccountState = {
            account: globalAccountState.accountInfo.account,
            location: globalAccountState.accountInfo.location,
            userSetting: globalAccountState.accountInfo.userSetting,
            userProfile: globalAccountState.accountInfo.userProfile,
          };
          globalAccountDispatch({
            accountInfo: initialAccountState,
            type: "UPDATE",
          });
          history.push({
            pathname: "/home",
            state: {
              updatedSection: "editlocationdetails",
              updateStatus: setSuccess,
              successMessage: "Location Details Saved Successfully.",
              successMessageDesc:
                "The location details are updated and saved successfully.",
            },
          });
        }
      });
      localStorage.setItem(
        "InspectionOrderId",
        JSON.parse(locationDetails.inspectionOrderId)
      );
    }
    setILocationNameEdited(false);
    setIsAddressLine1Edited(false);
    setIsCountryEdited(false);
    setIsCityEdited(false);
    setIsZipCodeEdited(false);
    setIsPhoneNumberEdited(false);
    setIsEmailAddressEdited(false);
    setIsContactPersonEdited(false);
    setIsNoOfTDEdited(false);
    setIsTreadDepthMethodIdEdited(false);
    setIsInspectionOrderIdEdited(false);
    setIsMarketSegmentIdEdited(false);
    setIsApplicationTypeIdEdited(false);
    setIsMaterialEdited(false);
    setIsSiteSeverityIndexEdited(false);
  };

  const changeLocationDetails = (key, value) => {
    if (!locationDetails.isEditable) return;
    let date = new Date();
    switch (key) {
      case "locationName":
        setLocationDetails({
          ...locationDetails,
          locationName: value,
          updatedDate: date,
        });
        setILocationNameEdited(true);
        break;
      case "addLine1":
        setLocationDetails({
          ...locationDetails,
          addressLine1: value,
          updatedDate: date,
        });
        setIsAddressLine1Edited(true);
        break;
      case "addLine2":
        setLocationDetails({
          ...locationDetails,
          addressLine2: value,
          updatedDate: date,
        });
        break;
      case "country":
        {
          let currentCountryIndexLocal = countryList.findIndex(
            (country) => parseInt(country.countryId) === parseInt(value)
          );
          setCurrentCountryIndex(currentCountryIndexLocal);
          if (countryList[currentCountryIndexLocal].states.length > 0) {
            setLocationDetails({
              ...locationDetails,
              country: value,
              currency: countryList[currentCountryIndexLocal].currency,
              state: "",
            });
          } else {
            setLocationDetails({
              ...locationDetails,
              country: value,
              currency: countryList[currentCountryIndexLocal].currency,
              updatedDate: date,
            });
          }
        }
        setIsCountryEdited(true);
        break;
      case "currency":
        setLocationDetails({
          ...locationDetails,
          currency: value,
          updatedDate: date,
        });
        break;
      case "state":
        setLocationDetails({
          ...locationDetails,
          state: value,
          updatedDate: date,
        });
        break;
      case "city":
        setLocationDetails({
          ...locationDetails,
          city: value,
          updatedDate: date,
        });
        setIsCityEdited(true);
        break;
      case "zipCode":
        setLocationDetails({
          ...locationDetails,
          zipCode: value,
          updatedDate: date,
        });
        setIsZipCodeEdited(true);
        break;
      case "phone":
        setLocationDetails({
          ...locationDetails,
          phoneNumber: value,
          updatedDate: date,
        });
        setIsPhoneNumberEdited(true);
        break;
      case "fax":
        setLocationDetails({
          ...locationDetails,
          fax: value,
          updatedDate: date,
        });
        break;
      case "email":
        setLocationDetails({
          ...locationDetails,
          emailAddress: value,
          updatedDate: date,
        });
        setIsEmailAddressEdited(true);
        break;
      case "contactPerson":
        setLocationDetails({
          ...locationDetails,
          contactPerson: value,
          updatedDate: date,
        });
        setIsContactPersonEdited(true);
        break;
      case "marketsegment":
        let appTypes = [];
        let materialBySegment = [];
        // eslint-disable-next-line array-callback-return
        masterData.applicationTypes !== undefined &&
          masterData.applicationTypes.forEach((appType) => {
            if (parseInt(appType.marketSegmentId) === parseInt(value))
              appTypes.push(appType);
          });

        // eslint-disable-next-line array-callback-return
        masterData.materials !== undefined &&
          masterData.materials.forEach((material) => {
            if (parseInt(material.marketSegmentId) === parseInt(value))
              materialBySegment.push(material);
          });
        setAppTypesByMarketSegment(appTypes);
        setMaterialByMarketSegment(materialBySegment);

        if (value === 5) { // other
          setLocationDetails({
            ...locationDetails,
            marketSegmentId: value,
            applicationTypeId: 24, // other
            material:40, // other
            updatedDate: date,
          });
        } else {
          setLocationDetails({
            ...locationDetails,
            marketSegmentId: value,
            updatedDate: date,
          });
        }
        setIsMarketSegmentIdEdited(true);
        break;
      case "applicationtype":
        setLocationDetails({
          ...locationDetails,
          applicationTypeId: value,
          updatedDate: date,
        });
        setIsApplicationTypeIdEdited(true);
        break;
      case "material":
        setLocationDetails({
          ...locationDetails,
          material: value,
          updatedDate: date,
        });
        setIsMaterialEdited(true);
        break;
      case "siteseverityindex":
        setLocationDetails({
          ...locationDetails,
          siteSeverityIndex: value,
          updatedDate: date,
        });
        setIsSiteSeverityIndexEdited(true);
        break;
      case "severitycomments":
        setLocationDetails({
          ...locationDetails,
          severityComments: value,
          updatedDate: date,
        });
        break;
      case "noOftreaddepthreadings":
        if (value === 2 || value === 3) {
          setLocationDetails({
            ...locationDetails,
            noOfTreadDepthReadings: value,
            updatedDate: date,
          });
        } else {
          setLocationDetails({
            ...locationDetails,
            noOfTreadDepthReadings: value,
            treadDepthMethodId: 0,
            updatedDate: date,
          });
        }
        setIsNoOfTDEdited(true);
        break;
      case "treadDepthMethod":
        setLocationDetails({
          ...locationDetails,
          treadDepthMethodId: value,
          updatedDate: date,
        });
        setIsTreadDepthMethodIdEdited(true);
        break;
      case "defaultremovaltreaddepth":
        setLocationDetails({
          ...locationDetails,
          defaultRemovalTreadDepth: value,
          updatedDate: date,
        });
        break;
      case "inspectionorderid":
        setLocationDetails({
          ...locationDetails,
          inspectionOrderId: value,
          updatedDate: date,
        });
        setIsInspectionOrderIdEdited(true);
        break;
      case "recommendedpressure":
        setLocationDetails({
          ...locationDetails,
          recommendedPressure: value,
          updatedDate: date,
        });
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let errorsLocal = {};
    let isValid = true;
    Object.keys(locationDetails).forEach((key) => {
      if (
        key !== "addressLine2" &&
        key !== "state" &&
        key !== "currency" &&
        key !== "fax" &&
        key !== "severityComments"
      ) {
        if (
          (locationDetails[key] === "" && key === "locationName") ||
          (locationDetails[key] === "" && key === "addressLine1") ||
          (locationDetails[key] === 0 && key === "country") ||
          (locationDetails[key] === "" && key === "city") ||
          (locationDetails[key] === "" && key === "contactPerson") ||
          (locationDetails[key] === 0 && key === "noOfTreadDepthReadings") ||
          (locationDetails[key] === 0 && key === "inspectionOrderId") ||
          (locationDetails[key] === 0 && key === "marketSegmentId") ||
          (locationDetails[key] === 0 && key === "applicationTypeId") ||
          (locationDetails[key] === 0 && key === "material") ||
          (locationDetails[key] === "" && key === "zipCode") ||
          (locationDetails[key] === 0 && key === "siteSeverityIndex") ||
          (locationDetails[key] === "" && key === "emailAddress") ||
          (locationDetails[key] === "" && key === "phoneNumber")
        ) {
          errorsLocal[key] = "This field is required";
          isValid = false;
        } else if (locationDetails[key] === 0 && key === "treadDepthMethodId") {
          if (locationDetails.noOfTreadDepthReadings === 2 || locationDetails.noOfTreadDepthReadings === 3) {
            errorsLocal[key] = "This field is required";
            isValid = false;
          }
        } else if (
          key === "emailAddress" &&
          !locationDetails[key].match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
        ) {
          //^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$
          errorsLocal[key] =
            "Email Address is invalid. Must have @ and .com or the like";
          isValid = false;
        } 
        else if (
          key === "phoneNumber" &&
          !locationDetails[key].match(/\d{3}[ -]?\d{3}[ -]?\d{4}/)
        ) {
          errorsLocal[key] =
            "Phone number is invalid. Must be at least 10 digits";
          isValid = false;
        }
        // else {
        //   if (key === "city" && !locationDetails[key].match(/^[a-zA-Z',.\s-]{1,25}$/)) {
        //     errorsLocal[key] = "Alphabets only";
        //     isValid = false;
        //   }
        // }
      }

      return true;
    });
    setLocationDetails({
      ...locationDetails,
      locationName: locationDetails.locationName
        ? locationDetails.locationName.toString().trim()
        : "",
      addressLine1: locationDetails.addressLine1
        ? locationDetails.addressLine1.toString().trim()
        : "",
      addressLine2: locationDetails.addressLine2
        ? locationDetails.addressLine2.toString().trim()
        : "",
      country: locationDetails.country
        ? locationDetails.country.toString().trim()
        : "",
      currency: locationDetails.currency
        ? locationDetails.currency.toString().trim()
        : "",
      state: locationDetails.state
        ? locationDetails.state.toString().trim()
        : "",
      city: locationDetails.city ? locationDetails.city.toString().trim() : "",
      zipCode: locationDetails.zipCode
        ? locationDetails.zipCode.toString().trim()
        : "",
      phoneNumber: locationDetails.phoneNumber
        ? locationDetails.phoneNumber.toString().trim()
        : "",
      fax: locationDetails.fax ? locationDetails.fax.toString().trim() : "",
      emailAddress: locationDetails.emailAddress
        ? locationDetails.emailAddress.toString().trim()
        : "",
      contactPerson: locationDetails.contactPerson
        ? locationDetails.contactPerson.toString().trim()
        : "",
    });
    setErrors(errorsLocal);
    return isValid;
  };

  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME,
    },
    {
      path: "",
      name: globalHeaderTexts.EDITLOCATION,
    },
  ];

  const onCancelActionClick = () => {
    setCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setCancelModalOpen(false);
  };

  return (
    <div className="standalone-new-header-container">
      <div className="edit-location-main-page-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image background-map-image-edit-location">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {globalHeaderTexts.EDITLOCATION}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="edit-location-list-container">
          <div className="edit-location-container">
            <div className="edit-location-inner-container">
              <div className="sub-header">
                <span>{globalHeaderTexts.LOCATIONDETAILS}</span>
              </div>
              <div className="edit-details-message bottom-margin">
                <span>
                  {/* Edit and Update all the relevant fields and mandatory fields
                  to Edit Location */}
                  {globalizationMapping.FORMS.ACCOUNTS.PARENTFORM.ACCFORMDESC}
                </span>
              </div>
              <div className="create-location-details-grid location-mb">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.LOCATIONNAME}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="location"
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.locationName}
                      onChange={(e) => {
                        changeLocationDetails("locationName", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    {(locationDetails.locationName === "" && isLocationNameEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isLocationNameEdited ? "" : <FormHelperText>{errors.locationName}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.ADDRESSLINE1}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="add1"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.addressLine1}
                      onChange={(e) => {
                        changeLocationDetails("addLine1", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                    {(locationDetails.addressLine1 === "" && isAddressLine1Edited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isAddressLine1Edited ? "" : <FormHelperText>{errors.addressLine1}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.ADDRESSLINE2}
                    </p>
                    <TextField
                      id="add2"
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.addressLine2}
                      onChange={(e) => {
                        changeLocationDetails("addLine2", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.COUNTRY}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={locationDetails.country}
                      variant="outlined"
                      helperText={errors.country}
                      onChange={(e) => {
                        changeLocationDetails("country", e.target.value);
                      }}
                      inputProps={{
                        name: "Country",
                        id: "country-select-drop-down",
                      }}
                    >
                      {countryList.map((country) => (
                        <option key={country.countryId} value={country.countryId}>
                          {country.countryName}
                        </option>
                      ))}
                    </Select>
                    {(locationDetails.country === "" && isCountryEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isCountryEdited ? "" : <FormHelperText>{errors.country}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">{globalHeaderTexts.CURRENCY}</p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      id="currency"
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.currency}
                      onChange={(e) => {
                        changeLocationDetails("currency", e.target.value);
                      }}
                    // endAdornment={<InputAdornment position="end" style={{ paddingRight: "15px" }}>$</InputAdornment>}
                    >
                      {currencyList.map((countryDetail) => (
                        <option key={countryDetail.currency} value={countryDetail.currency}>
                          {countryDetail.currency}
                        </option>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{globalHeaderTexts.STATE}</p>
                    {currentCountryIndex !== -1 &&
                      countryList[currentCountryIndex].states.length > 0 && (
                        <Select
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className="edit-detail-input-box"
                          value={locationDetails.state}
                          variant="outlined"
                          onChange={(e) => {
                            changeLocationDetails("state", e.target.value);
                          }}
                          inputProps={{
                            name: "State",
                            id: "state-select-drop-down",
                          }}
                        >
                          {countryList[currentCountryIndex].states.map(
                            (stateDetail) => (
                              <option key={stateDetail.stateId} value={stateDetail.stateName}>
                                {stateDetail.stateName}
                              </option>
                            )
                          )}
                        </Select>
                      )}
                    {currentCountryIndex !== -1 &&
                      !countryList[currentCountryIndex].states.length && (
                        <div>
                          <TextField
                            className="edit-detail-input-box"
                            variant="outlined"
                            value={locationDetails.state}
                            onChange={(e) => {
                              changeLocationDetails("state", commonUtils.removeLeadingSpaces(e.target.value));
                            }}
                            inputProps={{ maxLength: 50 }}
                          />
                        </div>
                      )}
                    {currentCountryIndex === -1 && (
                      <div>
                        <TextField
                          className="edit-detail-input-box"
                          variant="outlined"
                          value={locationDetails.state}
                          onChange={(e) => {
                            changeLocationDetails("state", commonUtils.removeLeadingSpaces(e.target.value));
                          }}
                          type=""
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.CITY}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="city"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.city}
                      onChange={(e) => {
                        changeLocationDetails("city", e.target.value);
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    {(locationDetails.city === "" && isCityEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isCityEdited ? "" : <FormHelperText>{errors.city}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.ZIPPOSTAL}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="zip-code"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.zipCode}
                      onChange={(e) => {
                        changeLocationDetails("zipCode", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    {(locationDetails.zipCode === "" && isZipCodeEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isZipCodeEdited ? "" : <FormHelperText>{errors.zipCode}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.PHONE}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="phone"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.phoneNumber}
                      onChange={(e) => {changeLocationDetails("phone",e.target.value)}}
                      inputProps={{ maxLength: 12 }}
                    />
                    {(locationDetails.phoneNumber !== "" && !locationDetails.phoneNumber?.match(/\d{3}[ -]?\d{3}[ -]?\d{4}/) && isPhoneNumberEdited) ? <FormHelperText>Phone number is invalid. Must be at least 10 digits</FormHelperText> : ''}
                    {(locationDetails.phoneNumber === "" && isPhoneNumberEdited) ? <FormHelperText>This field is required and must be at least 10 digits</FormHelperText> : ''}
                    {(isPhoneNumberEdited ? "" : <FormHelperText>{errors.phoneNumber}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">{globalHeaderTexts.FAX}</p>
                    <TextField
                      id="fax"
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.fax}
                      onChange={(e) => {
                        changeLocationDetails("fax", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.EMAIL}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="email"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.emailAddress}
                      onChange={(e) => {
                        changeLocationDetails("email", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    {(locationDetails.emailAddress !== "" && !locationDetails.emailAddress?.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/) && isEmailAddressEdited) ? <FormHelperText>Email Address is invalid. Must have @ and .com or the like</FormHelperText> : ''}
                    {(locationDetails.emailAddress === "" && isEmailAddressEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isEmailAddressEdited ? "" : <FormHelperText>{errors.emailAddress}</FormHelperText>)}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.CONTACT}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    <TextField
                      id="contact-person"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.contactPerson}
                      onChange={(e) => {
                        changeLocationDetails("contactPerson", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                    {(locationDetails.contactPerson === "" && isContactPersonEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isContactPersonEdited ? "" : <FormHelperText>{errors.contactPerson}</FormHelperText>)}
                  </Grid>
                </Grid>
              </div>
              <br></br>
              <hr className="hr-divider" />
              <br></br>
              <div className="create-sub-header">
                <span>{globalHeaderTexts.LOCATIONPREFERENCE}</span>
              </div>
              <div className="create-location-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.TREADDEPTHREADINGS}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    {locationDetails.noOfTreadDepthReadings && (
                      <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="edit-detail-input-box"
                        variant="outlined"
                        value={locationDetails.noOfTreadDepthReadings}
                        onChange={(e) => {
                          changeLocationDetails(
                            "noOftreaddepthreadings",
                            parseInt(e.target.value)
                          );
                        }}
                      >
                        <option value={0} disabled>
                          {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                        </option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                      </Select>
                    )}
                    {(locationDetails.noOfTreadDepthReadings === 0 && isNoOfTDEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isNoOfTDEdited ? "" : <FormHelperText>{errors.noOfTreadDepthReadings}</FormHelperText>)}
                  </Grid>
                  {
                    <Grid item xs={4}>
                      <p className="field-label">
                        {globalHeaderTexts.TREADDEPTHMETHOD}{" "}
                        {(locationDetails.noOfTreadDepthReadings === 2 || locationDetails.noOfTreadDepthReadings === 3) ? <span className="required-mark">*</span> : null}
                      </p>
                      {masterData.tireDepthMethods &&
                        masterData.tireDepthMethods.length > 0 && (
                          <Select
                            native
                            IconComponent={ExpandMoreStyledIcon}
                            className={
                              (locationDetails.noOfTreadDepthReadings === 1 || locationDetails.noOfTreadDepthReadings === 0)
                                ? "edit-detail-input-box  disabled-input"
                                : "edit-detail-input-box"
                            }
                            value={locationDetails.treadDepthMethodId}
                            variant="outlined"
                            disabled={
                              locationDetails.noOfTreadDepthReadings === 1 || locationDetails.noOfTreadDepthReadings === 0
                            }
                            onChange={(e) => {
                              changeLocationDetails(
                                "treadDepthMethod",
                                e.target.value
                              );
                            }}
                          >
                            <option value={0} disabled>
                              {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                            </option>

                            {masterData.tireDepthMethods.map((methods) => (
                              <option
                                key={methods.treadDepthMethodId}
                                value={methods.treadDepthMethodId}
                              >
                                {methods.treadDepthMethodName}
                              </option>
                            ))}
                          </Select>
                        )}
                      {((locationDetails.treadDepthMethodId === 0 && (locationDetails.noOfTreadDepthReadings === 2 || locationDetails.noOfTreadDepthReadings === 3)) && isTreadDepthMethodIdEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                      {(isTreadDepthMethodIdEdited ? "" : <FormHelperText>{errors.treadDepthMethodId}</FormHelperText>)}
                    </Grid>
                  }
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.INSPECTIONORDER}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    {masterData.inspectionOrders !== undefined &&
                      locationDetails.inspectionOrderId && (
                        <Select
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className="edit-detail-input-box"
                          value={locationDetails.inspectionOrderId}
                          variant="outlined"
                          type="number"
                          onChange={(e) => {
                            changeLocationDetails(
                              "inspectionorderid",
                              e.target.value
                            );
                          }}
                        >
                          <option value={0} disabled>
                            {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                          </option>
                          {masterData.inspectionOrders.map(
                            (inspection, key) => (
                              <option
                                key={inspection.inspectionOrderId}
                                value={inspection.inspectionOrderId}
                              >
                                {inspection.inspectionOrderName}
                              </option>
                            )
                          )}
                        </Select>
                      )}
                    {(locationDetails.inspectionOrderId === "" && isInspectionOrderIdEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isInspectionOrderIdEdited ? "" : <FormHelperText>{errors.inspectionOrderId}</FormHelperText>)}
                  </Grid>
                  <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      Default Tread Depth Removal
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="contact-person"
                      type="number"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={
                        globalizationMapping.PLACEHOLDER.ContactPerson
                      }
                      value={locationDetails.defaultRemovalTreadDepth}
                      onChange={(e) => {
                        changeLocationDetails("defaultremovaltreaddepth", commonUtils.minmaxInt(e.target.value, 0, 99999));
                      }}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>
                </Grid>
                </Grid>
              </div>
              <hr className="hr-divider" />
              <br></br>
              <div className="create-sub-header">
                <span>{globalHeaderTexts.OPERATIONSPEC}</span>
              </div>
              <div className="create-location-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.MARKETSEGMENT}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    {masterData.marketSegments !== undefined &&
                      locationDetails.marketSegmentId && (
                        <Select
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className="edit-detail-input-box"
                          value={locationDetails.marketSegmentId}
                          variant="outlined"
                          onChange={(e) => {
                            changeLocationDetails(
                              "marketsegment",
                              e.target.value
                            );
                          }}
                        >
                          <option value={0} disabled>
                            {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                          </option>
                          {masterData.marketSegments !== undefined &&
                            masterData.marketSegments.map((segment) => (
                              <option
                                key={segment.marketSegmentId}
                                value={segment.marketSegmentId}
                              >
                                {segment.marketSegmentName}
                              </option>
                            ))}
                        </Select>
                      )}
                    {(locationDetails.marketSegmentId === "" && isMarketSegmentIdEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isMarketSegmentIdEdited ? "" : <FormHelperText>{errors.marketSegmentId}</FormHelperText>)}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.APPLICATIONTYPE}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    {locationDetails.applicationTypeId && (
                      <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className={locationDetails.marketSegmentId === 0
                          ? "edit-detail-input-box  disabled-input"
                          : "edit-detail-input-box"
                        }
                        value={locationDetails.applicationTypeId}
                        variant="outlined"
                        disabled={locationDetails.marketSegmentId === 0}
                        onChange={(e) => {
                          changeLocationDetails(
                            "applicationtype",
                            e.target.value
                          );
                        }}
                      >
                        <option value={0} disabled>
                          {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                        </option>
                        {appTypesByMarketSegment.map((type) => (
                          <option key={type.applicationTypeId} value={type.applicationTypeId}>
                            {type.applicationTypeName}
                          </option>
                        ))}
                      </Select>
                    )}
                    {(locationDetails.applicationTypeId === "" && isApplicationTypeIdEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isApplicationTypeIdEdited ? "" : <FormHelperText>{errors.applicationTypeId}</FormHelperText>)}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.MATERIAL}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    {materialByMarketSegment !== undefined &&
                      locationDetails.material && (
                        <Select
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className={locationDetails.applicationTypeId === 0
                            ? "edit-detail-input-box  disabled-input"
                            : "edit-detail-input-box"
                          }
                          value={locationDetails.material}
                          variant="outlined"
                          disabled={locationDetails.applicationTypeId === 0}
                          onChange={(e) => {
                            changeLocationDetails("material", e.target.value);
                          }}
                        >
                          <option value={0} disabled>
                            {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                          </option>
                          {materialByMarketSegment.map((mat) => (
                            <option key={mat.material} value={mat.material}>
                              {mat.materialName}
                            </option>
                          ))}
                        </Select>
                      )}
                    {(locationDetails.material === "" && isMaterialEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isMaterialEdited ? "" : <FormHelperText>{errors.material}</FormHelperText>)}
                  </Grid>
                </Grid>
              </div>
              <hr className="hr-divider" />
              <br></br>
              <div className="create-sub-header">
                <span>{globalHeaderTexts.VEHICLEOPERATINGSPEC}</span>
              </div>
              <div className="create-location-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.SITESEVERITYINDEX}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    {masterData.siteSeverityIndexes !== undefined &&
                      locationDetails.siteSeverityIndex && (
                        <Select
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className="edit-detail-input-box"
                          value={locationDetails.siteSeverityIndex}
                          variant="outlined"
                          onChange={(e) => {
                            changeLocationDetails(
                              "siteseverityindex",
                              e.target.value
                            );
                          }}
                        >
                          <option value={0} disabled>
                            {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                          </option>
                          {masterData.siteSeverityIndexes.map((index) => (
                            <option
                              key={index.siteSeverityIndex}
                              value={index.siteSeverityIndex}
                            >
                              {index.siteSeverityIndexName}
                            </option>
                          ))}
                        </Select>
                      )}
                    {(locationDetails.siteSeverityIndex === "" && isSiteSeverityIndexEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isSiteSeverityIndexEdited ? "" : <FormHelperText>{errors.siteSeverityIndex}</FormHelperText>)}
                  </Grid>
                  <Grid item xs={8}>
                    <p className="field-label">{globalHeaderTexts.COMMENTS}</p>
                    <TextField
                      id="contact-person"
                      // required
                      className="comment-input-box"
                      variant="outlined"
                      placeholder={globalizationMapping.PLACEHOLDER.CommentsMsg}
                      multiline
                      rows={2}
                      value={locationDetails.severityComments}
                      onChange={(e) => {
                        changeLocationDetails(
                          "severitycomments",
                          commonUtils.removeLeadingSpaces(e.target.value)
                        );
                      }}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>
                </Grid>
              </div>
              <br></br>
              <br></br>
              <hr className="hr-divider" />
              <div className="action-buttons">
                <Button
                  className={styles.cancel}
                  variant="contained"
                  disableElevation={true}
                  onClick={() => onCancelActionClick()}
                >
                  {globalizationMapping.FORMS.CANCEL}
                </Button>
                <Button
                  className={styles.save}
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  onClick={onClickSaveStatus}
                >
                  {globalizationMapping.FORMS.SAVE}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isCancelModalOpen && (
        <CancelModal closeDeleteModal={handleCancelModalClose}/>
      )}
    </div>
  );
};
export default EditLocation;
