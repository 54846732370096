import React, { useEffect, useState } from "react";
import "../../../../../common-styles/TableStyle.css";
import "./Table.css";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../globalization";
import CommonUtils from "../../../../../services/utils";
// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 600,
    },
    container: {
        'max-height': "100%",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    stickyHeader: {
        zIndex: "2!important",
    },
    tableHeadRow: {
        zIndex: "2!important",
        color: "#2E2E2E !important",
        fontSize: "1em !important",
        fontFamily: "Barlow  !important",
        fontWeight: "500 !important"
    }
}));

const InspectionHistoryTableViewOnly = ({ tableData, showColumns }) => {
    const [inspectionHistoryListTableData, setInspectionHistoryListTableData] = useState(tableData);
    const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("Date");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const commonUtils = new CommonUtils();

    function getComparator(sOrder, sOrderBy) {
        return (sOrder === "desc" ? (a, b) => descendingComparator(a, b, sOrderBy) : (a, b) => -descendingComparator(a, b, sOrderBy));
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const currentOrder = comparator(a[0], b[0]);
            if (currentOrder !== 0) {
                return currentOrder;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    // USED FOR SORTING
    function descendingComparator(a, b, sOrderBy) {
        if (b[sOrderBy] < a[sOrderBy]) {
            return -1;
        }
        if (b[sOrderBy] > a[sOrderBy]) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        setInspectionHistoryListTableData(tableData);
        let arr = commonUtils.rowPerPageSelector(inspectionHistoryListTableData.rowsData.length);
        setArrRowsPerPageOptions(arr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, showColumns]);

    const handleRequestSort = property => _event => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const globalizationMapping = new Globalization();
    return (
        <div className="container">
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                            {globalizationMapping.QUICKINSPECT.InspectionHistory}
                        </span>
                    </InputLabel>
                    <TableContainer className={classes.container}>
                        <Table
                            stickyHeader className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="a dense table"
                            size="small"
                            >
                            <TableHead>
                                <TableRow>
                                    {inspectionHistoryListTableData.headCells.map(headCell =>
                                        showColumns[headCell.value] ? (
                                            <TableCell
                                                className={classes.tableHeadRow}
                                                key={headCell.keyIndex} align="left" sortDirection={orderBy === headCell.value ? order : false} >
                                                <TableSortLabel
                                                    active={orderBy === headCell.value}
                                                    direction={orderBy === headCell.value ? order : "asc"}
                                                    onClick={handleRequestSort(headCell.value)}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.value ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ) : null
                                    )}
                                </TableRow>
                            </TableHead>
                            <tbody><tr className='table-bottom-line'>
                                <td colSpan={inspectionHistoryListTableData.headCells.length}></td>
                            </tr></tbody>
                            {inspectionHistoryListTableData.rowsData.length > 0 && (
                                <TableBody>
                                    {stableSort(inspectionHistoryListTableData.rowsData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            return (
                                                <TableRow
                                                    className={index % 2 === 0 ? "table-row" : "table-row-alternate"}
                                                    tabIndex={-1} key={row.Key} >
                                                    {showColumns.SerialNumber && (
                                                        <TableCell align="left" className="table-content-text clickable-id-inspection-history" >
                                                            {row.SerialNumber}
                                                        </TableCell>)}
                                                    {showColumns.WheelPosition && (
                                                        <TableCell align="left" className="table-content-text">
                                                            {row.WheelPosition}
                                                        </TableCell>)}
                                                    {showColumns.CurrentTread && (
                                                        <TableCell align="left" className="table-content-text" >
                                                            {row.CurrentTread}
                                                        </TableCell>)}
                                                    {showColumns.CurrentPressure && (
                                                        <TableCell align="left" className="table-content-text" >
                                                            {row.CurrentPressure}
                                                        </TableCell>)}
                                                    {showColumns.Event && (
                                                        <TableCell align="left" className="table-content-text">
                                                            {row.Event}
                                                        </TableCell>)}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>)}
                            {inspectionHistoryListTableData.rowsData.length === 0 && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="no-match-found" colSpan={8} align="center">
                                            <div className="no-data-container">
                                                <NoDataFound noDataType="table"></NoDataFound>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>)}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={arrRowsPerPageOptions}
                        component="div"
                        count={inspectionHistoryListTableData.rowsData.length}
                        rowsPerPage={rowsPerPage} 
                        page={page} 
                        onChangePage={handleChangePage} 
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        SelectProps={{
                            inputProps: {
                              'aria-label': 'rows per page',
                            },
                            native: true,
                          }}
                    />
                </Paper>
            </div>
        </div>
    );
};

export default InspectionHistoryTableViewOnly;
