import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import Globalization from "../../../../../../../globalization/index";
import Chart from "chart.js";
// eslint-disable-next-line no-unused-vars

const MonthlyTireLifeGraph = ({ monthlyTireLifeGraphData, targetValue, stretchValue }) => {
  const commonUtils = new CommonUtils();
  const options = commonUtils.barOptions;
  const optionsForMile = JSON.parse(JSON.stringify(commonUtils.barOptions));
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = "Months";
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["tire-size-x"];
  options.title.text = "Monthly Tire Life";

  const tempYAxis = JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric" ? "tire-size-y-perKM" : "tire-size-y-perMile";

  const tempTitle = JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric" ? "tire-size-title-perKM" : "tire-size-title-perMile";
  optionsForMile.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["tire-size-x"];
  optionsForMile.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders[tempYAxis];
  optionsForMile.title.text = globalizationHeaders[tempTitle];

  const chart = () => {
    const labels = [];
    monthlyTireLifeGraphData.headCells.map((item, index) => {
      if (index !== 0) labels.push(item.label);
    });
    const actualHours = Object.values(monthlyTireLifeGraphData.rowsData[0]);
    actualHours.shift();
    const ytd = Object.values(monthlyTireLifeGraphData.rowsData[1]);
    ytd.shift();

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "Actual Hours",
          data: actualHours,
          borderColor: "#ffbe00",
          backgroundColor: "#ffbe00",
          order: 1,
          datalabels: {
            align: "center",
            anchor: "center",
            color: "#bbb",
            clamp: true,
            labels: {
              value: {
                font: {
                  weight: 800,
                  family: "BarlowB",
                  size: window.innerWidth > 1800 ? 20 : 12
                  // size: 11,
                },
                color: "#000"
              }
            }
          }
        },
        {
          label: "YTD",
          data: ytd,
          borderColor: "#094aa5",
          backgroundColor: "#fff3",
          type: "line",
          order: 0,
          datalabels: {
            align: "end",
            anchor: "center",
            color: "#bbb",
            labels: {
              value: {
                font: {
                  weight: 600,
                  family: "BarlowB",
                  size: window.innerWidth > 1800 ? 20 : 12
                },
                color: "#000"
              }
            }
          }
        }
      ]
    };
    let ctx = document.getElementById("lineAndBarGraph");
    let chart = new Chart(ctx, {
      type: "bar",
      data: chartData,
      options: {
        responsive: true,
        // maintainAspectRatio: true,
        tooltips: {
          yAlign: "top",
          xAlign: "center"
        },
        title: {
          display: true,
          text: globalizationMapping.TIREREPORTS.SCRAPTIRES.MonthlyTireLife,
          fontSize: 25,
          fontStyle: "normal",
          padding: 20
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 20,
            bottom: 20
          }
        },
        annotation: {
          events: ["click"],
          annotations: [
            {
              drawTime: "afterDatasetsDraw",
              id: "tline",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: targetValue,
              borderColor: "#fafafa",
              borderWidth: 1,
              label: {
                fontColor: "#222",
                fontSize: window.innerWidth > 1800 ? 18 : 13,
                backgroundColor: "#ffffff",
                content: "Target Hours",
                position: "left",
                yAdjust: -10,
                enabled: true
              },
              onClick: function (_event) {
                // The annotation is is bound to the `this` variable
              }
            },
            {
              drawTime: "afterDatasetsDraw",
              id: "sline",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: stretchValue,
              borderColor: "#fafafa",
              borderWidth: 2,
              label: {
                fontColor: "#222",
                fontSize: window.innerWidth > 1800 ? 18 : 13,
                backgroundColor: "#ffff00",
                content: "Stretch Hours",
                position: "right",
                yAdjust: 10,
                enabled: true
              },
              onClick: function (_event) {
                // The annotation is is bound to the `this` variable
              }
            },
            {
              drawTime: "beforeDatasetsDraw",
              type: "box",
              xScaleID: "x-axis-0",
              yScaleID: "y-axis-0",
              backgroundColor: "#00000000",
              borderColor: "#00000000",
              borderWidth: 1,
              onClick: function (_event) {
                // Box", e.type, this);
              }
            }
          ]
        },
        plugins: {
          datalabels: {
            anchor: "center",
            align: "end",
            labels: {
              value: {
                color: "black",
                font: {
                  weight: "bold",
                  size: window.innerWidth > 1800 ? 22 : 16
                  // size: "12",
                }
              }
            },
            formatter: (value) => {
              return Math.round(value);
            }
          },
          legend: {
            position: "top"
          }
        },
        legend: {
          display: true
        },
        scales: {
          xAxes: [
            {
              // maxBarThickness: 80,
              align: "center",
              stacked: false,
              ticks: {
                display: true,
                fontSize: window.innerWidth > 1800 ? 22 : 16,
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: "Month",
                fontStyle: "bold",
                fontSize: window.innerWidth > 1800 ? 22 : 16,
                fontColor: "#000",
                align: "center"
              }
            }
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                display: true,
                fontSize: window.innerWidth > 1800 ? 22 : 16,
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: globalizationHeaders["tire-size-y"],
                fontStyle: "bold",
                fontSize: window.innerWidth > 1800 ? 22 : 16,
                fontColor: "#333"
              }
            }
          ]
        },
        cornerRadius: 10
      }
    });
  };

  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyTireLifeGraphData]);

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        <canvas id="lineAndBarGraph" width="400" height="200"></canvas>
      </Grid>
    </div>
  );
};

export default MonthlyTireLifeGraph;
