import React, { useEffect, useState, useContext } from "react";
import "./ByTireType.css";
import { Card, CardContent, InputLabel, TextField, InputAdornment, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import ScrapTiresNavigation from "../../../../scrap-tires/scrap-tires-navigation/components/ScrapTiresNavigation";
import ByTireTypeDataSetter from "../../../../../services/ByTireTypeUtils";
import ByTireTypeGraph from "../graph/ByTireTypeGraph";
import ByTireTypeTable from "../table/ByTireTypeTable";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ByTireTypeFilter from '../filter/ByTireTypeFilter.js'

const ByTireType = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const byTireTypeDataSetter = new ByTireTypeDataSetter();
  const commonUtils = new CommonUtils();
  const [searchValue, setSearchValue] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [byTireTypeData, setByTireTypeData] = useState();
  const [originalByTireTypeData, setOriginalByTireTypeData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(byTireTypeData);
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [noData, setNoData] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [toggleHM, setToggleHM] = useState("Hours");
  const globalizationMapping = new Globalization();

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" }

    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  useEffect(() => {
    ByTireTypeResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, excludeUsedTires, filterApplied, filterFieldsApplied]);

  const ByTireTypeResponseData = (excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      locationIds: locationIds,
      reportName: "Scrap_Tire_By_Tire_Type",
      reportOption: "",
      usedTires: excludeUsedTiresValue.toString()
    };
    let filterOptions = {
      categories: [
        {
          name: globalizationMapping.REPORTTABLE.MANUFACTURER,
          value: "ManufacturerName"
        },
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        { name: globalizationMapping.REPORTTABLE.COMPOUND, value: "CompoundName" }

      ],
      categoryData: {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        CompoundName: [],
        dateRangeFromDate: '',
        dateRangeToDate: '',
      }
    };

    let graphRequestObject = {
      accountIds: accountIds,
      dateRangeType: "AllTime",
      fromDate: "",
      graphType: "Bar",
      locationIds: locationIds,
      reportName: "GetScrapByTireType",
      toDate: ""
    };

    tiresService.getTiresReport(graphRequestObject).then((_data) => {
      // GRAPH API DATA"
    });

    setShowSpinner(true);

    tiresService.getTiresReport(requestObject).then((data) => {
      let byTireTypeInfo = byTireTypeDataSetter.setData(data);
      let filterOptionsInfo = byTireTypeDataSetter.setFilterOptionsData(filterOptions, byTireTypeInfo.rowsData);
      setByTireTypeData(byTireTypeInfo);
      setOriginalByTireTypeData(byTireTypeInfo);
      setTableBeforeFilter(byTireTypeInfo);
      byTireTypeInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setFilterOptions(filterOptionsInfo);
      let updatedHeadCells = byTireTypeInfo.headCells.filter((item) => item.value !== "AverageMiles");
      setDisplayColOptions(updatedHeadCells);
      setDisplayColumns(updatedHeadCells);

      setShowColumns(byTireTypeInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByTireType,
        rowsData: byTireTypeInfo.rowsData,
        headCells: byTireTypeInfo.headCells,
        avgValues: byTireTypeDataSetter.avgValues(byTireTypeInfo.rowsData),
        selectedColShow: byTireTypeInfo.selectedColShow
      });
      setShowSpinner(false);
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = byTireTypeInfo.rowsData;
        let filteredData = byTireTypeDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setByTireTypeData({
          ...byTireTypeInfo,
          rowsData: filteredData,
          avgValues: byTireTypeDataSetter.avgValues(filteredData)
        });
        setOriginalByTireTypeData({
          ...byTireTypeInfo,
          rowsData: filteredData,
          avgValues: byTireTypeDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: byTireTypeDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };


  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };
  const actionChanged = (action) => {
    if (action === "Miles") {
      handleMilesClick("AverageHours");
      setToggleHM(action);
    } else {
      handleMilesClick("AverageMiles");
      setToggleHM(action);
    }
  };
  const handleMilesClick = (avoidStr) => {
    let byTireTypeInfo = JSON.parse(JSON.stringify(byTireTypeData));

    byTireTypeInfo.headCells.forEach((headCell) => {
      byTireTypeInfo.selectedColShow[headCell.value] = headCell.value !== avoidStr ? true : false;
    });

    setByTireTypeData(byTireTypeInfo);
    setTableBeforeFilter(byTireTypeInfo);
    byTireTypeInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
    let updatedHeadCells = byTireTypeInfo.headCells.filter((item) => item.value !== avoidStr);
    setDisplayColOptions(updatedHeadCells);
    setDisplayColumns(updatedHeadCells);
    setShowColumns(byTireTypeInfo.selectedColShow);
    setPrintData({
      reportType: "",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByTireType,
      rowsData: byTireTypeInfo.rowsData,
      headCells: byTireTypeInfo.headCells,
      avgValues: byTireTypeDataSetter.avgValues(byTireTypeInfo.rowsData),
      selectedColShow: byTireTypeInfo.selectedColShow
    });
    setShowSpinner(false);
    if (filterApplied) {
      setFilterApplied(true);
      let originalTableDataStore = byTireTypeInfo.rowsData;
      let filteredData = byTireTypeDataSetter.filterTable(filterApplied, originalTableDataStore);
      setByTireTypeData({
        ...byTireTypeInfo,
        rowsData: filteredData,
        avgValues: byTireTypeDataSetter.avgValues(filteredData)
      });
      setOriginalByTireTypeData({
        ...byTireTypeInfo,
        rowsData: filteredData,
        avgValues: byTireTypeDataSetter.avgValues(filteredData)
      });
      setPrintData({
        ...printData,
        rowsData: filteredData,
        avgValues: byTireTypeDataSetter.avgValues(filteredData),
        filterFieldsApplied: filterApplied,
        filterApplied: true
      });
      filteredData.length === 0 ? setNoData(true) : setNoData(false);
      setOnlyFilterApplied(true);
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    setSearchValue(searchText);
    let tableDataStore, filteredData;
    tableDataStore = originalByTireTypeData?.rowsData;
    filteredData = byTireTypeDataSetter.search(tableDataStore, searchText);

    setByTireTypeData({
      ...byTireTypeData,
      rowsData: filteredData,
      avgValues: byTireTypeDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byTireTypeDataSetter.avgValues(filteredData),
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalByTireTypeData?.headCells;
    let showColumnsUpdated = byTireTypeDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    ByTireTypeResponseData(excludeUsedTires, true, appliedFilter);
    let originalTableDataStore = originalByTireTypeData?.rowsData;
    let filteredData = byTireTypeDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setByTireTypeData({
      ...byTireTypeData,
      rowsData: filteredData,
      avgValues: byTireTypeDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byTireTypeDataSetter.avgValues(filteredData)
    });
    setOnlyFilterApplied(true);
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };


  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        CompoundName: [],
        dateRangeFromDate: '',
        dateRangeToDate: '',
    });
    setFilterApplied(false);
    setByTireTypeData(tableBeforeFilter);
    setOriginalByTireTypeData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
    setNoData(false);
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    setSearchValue("");
    ByTireTypeResponseData(event.target.checked, filterApplied, filterFieldsApplied);
  };

  return (
    <div>
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-tire-type-container">
          <Card className="by-tire-type-card">
            <CardContent className="graph-card">
              <ByTireTypeGraph byTireTypeGraphData={byTireTypeData?.rowsData} label={toggleHM} />
            </CardContent>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        value={searchValue}
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="display-columns-container">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      onChange={(e) => actionChanged(e.target.value)}
                    >
                      <option value={"Hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                      <option value={"Miles"}>{toggleMile}</option>
                    </Select>
                  </div>
                </div>

                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>

              <div className="by-tire-type-table">
                <ByTireTypeTable tableData={byTireTypeData} showColumns={showColumns} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />

      <ByTireTypeFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default ByTireType;
