import React from 'react';
import './breadcrumbs.css';
const Breadcrumbs = props => {
  const onBreadcrumbClick = (path, e) => {
    if (path === "") {
      e.preventDefault()
    } else (
      window.location.href = "/" + path
    )

  };
  return (
    <div className="breadcrumbs-container">
      {props.breadcrumbs.map((item, index) =>
      (<div key={item.path}>
        <div >
          <span className={index !== (props.breadcrumbs.length - 1) ?
            "breadcrumb-path-item" : "breadcrumb-current-item"}
            onClick={(e) => onBreadcrumbClick(item.path, e)}>{item.name}</span>
          {index !== (props.breadcrumbs.length - 1) &&
            <span className="breadcrumb-indicator">
              {'>'}
            </span>}
        </div>
      </div>)
      )
      }
    </div>
  );
};

export default Breadcrumbs;
