import React, { useState } from "react";
import "./VideoContainer.css";
import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Globalization from "../../../globalization";
import ImageAllOk from "../../../assets/all-ok-image.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const VideoContainer = () => {
  const globalizationMapping = new Globalization();
  const globalTexts = globalizationMapping.HELP_JS;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" style={{color:"black", backgroundColor: "#033d90", borderTopLeftRadius:15, borderTopRightRadius:15}} >
          <Tab label="Info" {...a11yProps(0)} />
          <Tab label="Home" {...a11yProps(1)} />
          <Tab label="Create" {...a11yProps(2)} />
          <Tab label="Manage" {...a11yProps(3)} />
          <Tab label="Report" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={1}>
          <Grid item container justify="flex-start" sm={2} alignItems="stretch" direction="row">
              <img src={ImageAllOk} alt="tick-all-ok" height="100%" width="160%" />
          </Grid>
          <Grid item sm={1}></Grid>
          <Grid item sm={9} style={{maxHeight:800, overflowY:'auto'}}>
          <div class="chatBubble">
              <div class="message">
                <div class="broad-body">
                  <div className="header-change-modal-title-container1">
                    <div className="header-change-modal-title1"><span style={{ fontSize: "1.1em" }}>Need assistance how the EMTrack work?</span></div>
                  </div>
                  <div className="help-sub-header" style={{marginLeft:25}}>
                    <span className="general_help">If you need assistance on how to use the EMTrack, you can watch the videos below to get a better understanding of how the EMTrack works.</span>          
                    <span className="general_help">Just select which feature you want to see and there are videos that will help you to get through the entire app.</span> 
                  </div>              
                </div>
              </div>
            </div>          
            <div class="chatBubble">
              <div class="message">
                <div class="broad-body">
                  <div className="header-change-modal-title-container1">
                    <div className="header-change-modal-title1"><span style={{ fontSize: "1.1em" }}>{globalTexts.MESSAGE1}</span></div>
                  </div>
                  <div className="help-sub-header" style={{marginLeft:25}}>
                    <span className="general_help">Make sure you are using up to date 
                      <a
                        target="_blank"
                        href="https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en"
                        style={{marginLeft:5}}
                      >
                        Google Chrome
                      </a>. 
                    </span>           
                  </div>
                  <div className="help-sub-header" style={{marginLeft:25}}>
                    <span className="general_help">
                      If you are having login issues, try to 
                      <a
                        target="_blank"
                        href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en"
                        style={{marginLeft:5}}
                      >
                        clear cache/cookies
                      </a> files in your browser settings.</span>
                  </div>
                  <div className="help-sub-header" style={{marginLeft:25}}>
                    <span className="note_help_information">
                      {globalTexts.MESSAGE7}
                      <a href="mailto:ikenna_nwauwa@goodyear.com" target="_top">
                        ikenna_nwauwa@goodyear.com
                      </a>
                    </span>
                  </div>                  
                </div>
              </div>
            </div>                 
          </Grid>
        </Grid>
        
      </TabPanel>
      <TabPanel value={value} index={1}>
        <iframe width="100%" height="700" src="https://www.youtube.com/embed/gDS8oASVTAM?si=QYvRp1s0mAmObRyX" title="EmTrack Home Feature" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <iframe width="100%" height="700" src="https://www.youtube-nocookie.com/embed/U4vuNGazpk0?si=zAnYEavppdG9OI_m" title="EmTrack Create Feature" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <iframe width="100%" height="700" src="https://www.youtube.com/embed/Px-X9UFF6ac?si=XPr8RPxCeAh3WoeT" title="EmTrack Manage Feature" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <iframe width="100%" height="700" src="https://www.youtube.com/embed/2MDqfxnzoB8?si=ZwsL886DRSwJqHdJ" title="EmTrack Report Feature" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </TabPanel>
    </div>
  );
};

export default VideoContainer;