import React, { useContext, useEffect, useState } from "react";
import "./SearchInstallTireListTable.css";
import "../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../globalization";
import SearchInstallTireListApis from "../../service/SearchInstallTireListApis";
import { StateContext } from "../../../../services/StateProvider";
import { useHistory } from "react-router";
import CommonUtils from "../../../../services/utils";
import HeaderApi from "../../../../common-components/header/services/header-apis";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    height: 640
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  stickyHeader: {
    zIndex: "2!important"
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important"
  },
  install: {
    "&:hover": {
      opacity: 1
    },
    background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none"
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none"
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 1,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none"
  }
}));

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const SearchInstallTireListTable = ({ tableData, showColumns, senderDetails, selectedTire, setTireIdNull }) => {
  const [globalAccountState] = useContext(StateContext);
  const classes = useStyles();
  const [searchInstallTireListTableData, setSearchInstallTireListTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const [order, setOrder] = useState("asc");
  const [rowSelected, setRowSelected] = useState("asc");
  const [orderBy, setOrderBy] = useState("SerialNo");
  const [page, setPage] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState();
  const [vehicleId] = useState(senderDetails.id);
  const [wheelPosition] = useState(senderDetails.position);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const commonUtils = new CommonUtils();
  const styling = useStyles();
  const history = useHistory();
  const globalizationMapping = new Globalization();
  const globalTexts = globalizationMapping.SEARCH_INSTALL_TIRE;
  const headerApiObj = new HeaderApi();

  useEffect(() => {
    setSearchInstallTireListTableData(tableData);
    let arr = commonUtils.rowPerPageSelector(searchInstallTireListTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    if (selectedTire !== "") {
      let index = searchInstallTireListTableData.rowsData.findIndex((val) => val.TireId.toString().toLowerCase().trim() === selectedTire.toString().toLowerCase().trim());
      if (index !== -1) {
        let selectedRow = searchInstallTireListTableData.rowsData[index];
        let accountInfoObject = { accountIds: [], locationIds: [] };
        accountInfoObject.accountIds.push(globalAccountState.accountInfo.account.accountId);
        globalAccountState.accountInfo.location.forEach((item) => {
          accountInfoObject.locationIds.push(item.value);
        });
        let locationIds = accountInfoObject.locationIds[0];
        let accountIds = accountInfoObject.accountIds[0];
        headerApiObj.getTireById(selectedRow.TireId).then((tireData) => {
        let specificTireData = tireData.model;

        let tireDetails = {
          inspectionDate: new Date(),
          wheelPosition: wheelPosition,
          tireId: selectedRow.TireId,
          currentHours: selectedRow.Hours,
          currentTreadDepth: selectedRow.MaxTread,
          currentMiles: selectedRow.Miles,
          currentPressure: 0,
          tireSerialNo: selectedRow.SerialNo,
          action: "Install",
          vehicleId: vehicleId,
          locationId: locationIds,
          parentAccountId: accountIds,
          outsideTread: specificTireData.outsideTread,
          insideTread: specificTireData.insideTread,
          middleTread: specificTireData.middleTread,
          removeAt: specificTireData.removeAt,

        };
        if (rowSelected) {
          searchInstallTireListService.searchInstallTireToInstallTire(tireDetails).then((data) => {
            if (data.message) {
              history.push({
                pathname: "/quick-inspect/" + vehicleId
              });
            } else {
              alert("Please select any tire");
            }
          });
        } else {
          alert("Please select any tire");
        }
      })
      }
      setTireIdNull();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  const searchInstallTireListService = new SearchInstallTireListApis();
  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowClick = (row) => {
    setSelectedIndex(row.Key);
    setRowSelected(row);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCancel = () => {
    history.push({
      pathname: "/quick-inspect/" + vehicleId,
      state: {}
    });
  };

  function handleInstall() {
    let selectedRow = rowSelected;
    let accountInfoObject = { accountIds: [], locationIds: [] };
    accountInfoObject.accountIds.push(globalAccountState.accountInfo.account.accountId);
    globalAccountState.accountInfo.location.forEach((item) => {
      accountInfoObject.locationIds.push(item.value);
    });
    let locationIds = accountInfoObject.locationIds[0];
    let accountIds = accountInfoObject.accountIds[0];
    headerApiObj.getTireById(selectedRow.TireId).then((tireData) => {
      let specificTireData = tireData.model;

      let tireDetails = {
        vehicleId: vehicleId,
        tireId: selectedRow.TireId,
        locationId: locationIds,
        parentAccountId: accountIds,
        inspectionDate: new Date(),
        action: "Install",
        currentHours: selectedRow.Hours,
        currentMiles: selectedRow.Miles,
        wheelPosition: wheelPosition,
        currentTreadDepth: selectedRow.MaxTread,
        currentPressure: 0,
        tireSerialNo: selectedRow.SerialNo,
        outsideTread: specificTireData.outsideTread,
        insideTread: specificTireData.insideTread,
        middleTread: specificTireData.middleTread,
        removeAt: specificTireData.removeAt,
      };

      if (rowSelected) {
        searchInstallTireListService.searchInstallTireToInstallTire(tireDetails).then((data) => {
          if (data.message) {
            history.push({
              pathname: "/quick-inspect/" + vehicleId
            });
          } else {
            alert("Please select any tire");
          }
        });
      } else {
        alert("Please select any tire");
      }
    }) 
  }

  const searchInstallTireListTableClassIdentifier = (rowKey, selectedIndex, index) => {

    if (rowKey === selectedIndex) {
      return "table-row active";
    } else if (index % 2 === 0) {
      return "table-row";
    } else {
      return "table-row-alternate";
    }
  }

  return (
    <div className="container">
      <div className={classes.root}>
        <label className="filter-table-label">
          <p className="search-install-tire-list-heading">
            {globalTexts.SELECTTYRES}
            <span className="search-install-action-buttons">
              <Button className={styling.install} variant="contained" color="secondary" onClick={() => handleInstall()} style={{ float: "right", marginBottom: "5px" }}>
                {globalTexts.INSTALLBUTTON}
              </Button>
            </span>
          </p>
        </label>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table 
              stickyHeader 
              className={classes.table} 
              aria-labelledby="tableTitle" 
              aria-label="a dense table"
              size="small">
              <TableHead>
                <TableRow>
                  {searchInstallTireListTableData.headCells.map((headCell) => {
                    let header = headCell.value;
                    switch (header) {
                      case "TireId":
                        header = null;
                        break;
                      case "Pressure":
                        header = null;
                        break;

                      default:
                        header = headCell.value;
                        break;
                    }
                    return showColumns[header] ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align={headCell.numeric === true ? "right" : "left"}
                        sortDirection={orderBy === headCell.value ? order : false}
                      >
                        <TableSortLabel active={orderBy === headCell.value} direction={orderBy === headCell.value ? order : "asc"} onClick={handleRequestSort(headCell.value)}>
                          {headCell.label}
                          {orderBy === headCell.value ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null;
                  })}
                </TableRow>
                <tr className="table-bottom-line">
                  <td colSpan={searchInstallTireListTableData.headCells.length}></td>
                </tr>
              </TableHead>

              {searchInstallTireListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(searchInstallTireListTableData.rowsData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={searchInstallTireListTableClassIdentifier(row.Key, selectedIndex, index)}
                          onClick={() => handleRowClick(row)}
                          tabIndex={-1}
                          key={row.Key}
                        >
                          {showColumns.SerialNo && (
                            <TableCell align="left" className="table-content-text">
                              {row.SerialNo}
                            </TableCell>
                          )}
                          {showColumns.Brand && (
                            <TableCell align="left" className="table-content-text">
                              {row.Brand}
                            </TableCell>
                          )}
                          {showColumns.Manufacturer && (
                            <TableCell align="left" className="table-content-text">
                              {row.Manufacturer}
                            </TableCell>
                          )}
                          {showColumns.Type && (
                            <TableCell align="left" className="table-content-text">
                              {row.Type}
                            </TableCell>
                          )}
                          {showColumns.Size && (
                            <TableCell align="left" className="table-content-text">
                              {row.Size}
                            </TableCell>
                          )}
                          {showColumns.Hours && (
                            <TableCell align="right" className="table-content-text">
                              {row.Hours}
                            </TableCell>
                          )}
                          {showColumns.Miles && (
                            <TableCell align="right" className="table-content-text">
                              {row.Miles}
                            </TableCell>
                          )}
                          {showColumns.MaxTread && (
                            <TableCell align="right" className="table-content-text">
                              {row.MaxTread}
                            </TableCell>
                          )}
                          {showColumns.PercentageWorn && (
                            <TableCell align="right" className="table-content-text">
                              {row.PercentageWorn}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {searchInstallTireListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={8} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={searchInstallTireListTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
        <div className="search-install-action-buttons">
          <Button className={styling.cancel} variant="contained" color="secondary" onClick={handleCancel}>
            {globalTexts.CANCELBUTTON}
          </Button>
          <Button className={styling.install} variant="contained" color="secondary" onClick={() => handleInstall()}>
            {globalTexts.INSTALLBUTTON}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchInstallTireListTable;
