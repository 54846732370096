import React from "react";
import { useHistory } from "react-router-dom";
import { AuthConsumer } from "../services/AuthProvider";
const Callback = () => {
  const history = useHistory();
  return (
    <div
      style={{
        height: "1000px",
        width: "100%",
        background:
          "transparent linear-gradient(161deg, #0D54B5  0%, #003584 100%) 0% 0% no-repeat padding-box",
      }}
    >
      <AuthConsumer>
        {({ signinRedirectCallback }) => {
          signinRedirectCallback();
          /* for auth we are checking token is available or not*/
          setInterval(() => {
            if (
              sessionStorage.getItem(
                `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
              )
            ) {
              history.push("/main-routing");
            }
          }, 1000);

        }}
      </AuthConsumer>
    </div>
  );
};
export default Callback;
