import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";

class TireInstallationDataSetter {
  constructor() {
    this.data = {};
    this.tireInstallationData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  tableHeaderOrderSetter(headCells) {
    let tableHeaders = headCells;
    // maintains the column order to be displayed (write them in the order you want to display table header)
    let headerOrder = [
      { columnNo: 1, value: "BrandNumber" },
      { columnNo: 2, value: "SerialNo" },
      { columnNo: 3, value: "ManufacturerName" },
      { columnNo: 4, value: "SizeName" },
      { columnNo: 5, value: "TypeName" },
      { columnNo: 6, value: "Compound" },
      { columnNo: 7, value: "RatingName" },
      { columnNo: 8, value: "TreadDepth" },
      { columnNo: 9, value: "Eval" },
      { columnNo: 10, value: "RegisteredDate" },
      { columnNo: 11, value: "InstalledDate" },
      { columnNo: 12, value: "VehId" },
      { columnNo: 13, value: "PoNumber" },
      { columnNo: 14, value: "TotalCost" },
    ];

    let resultHeaderCell = [];
    headerOrder.forEach((headerOrder) => {
      let found = false;
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === headerOrder.value) {
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });

    return resultHeaderCell;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      if (headCell.value !== "BrandNumber") {
        updatedHeadCells.push(headCell);
      }
    });
    return updatedHeadCells;
  }

  formatData(item) {
    let dateString, registeredDate, installedDate;
    if (item.registeredDate) {
      dateString = item.registeredDate.split("/");
      registeredDate =
        dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      registeredDate = null;
    }
    if (item.installedDate) {
      dateString = item.installedDate.split("/");
      installedDate = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      installedDate = null;
    }

    let formattedData = {
      registeredDate: registeredDate,
      installedDate: installedDate,
    };
    return formattedData;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "BrandNumber") {
        selectedColShow[headCell.value] = false;
      } else {
        selectedColShow[headCell.value] = true;
      }
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));

    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value]) && rowData[category.value].toString()) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    //
    //

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }

  calculateTotalAvg(rowsData) {
    let totalCost = 0;
    rowsData.forEach((rowData) => {
      totalCost += rowData.TotalCost;
    });
    return totalCost;
  }
  avgValues = (tireInstallationData) => {
    let totalCost = this.calculateTotalAvg(tireInstallationData);
    const avgValues = {
      BrandNumber: "Total / Average",
      SerialNo: "Total / Average",
      ManufacturerName: "",
      SizeName: "",
      TypeName: "",
      Compound: "",
      TreadDepth: "",
      Eval: "",
      RegisteredDate: "",
      InstalledDate: "",
      VehId: "",
      PoNumber: "",
      TotalCost: totalCost,
    };
    return avgValues;
  };
  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.tireInstallationData.push({
        Key: index,
        BrandNumber: item.brandNumber ? item.brandNumber : "",
        SerialNo: item.serialNo ? item.serialNo : "",
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        Compound: item.compound ? item.compound : "",
        RatingName: item.ratingName ? item.ratingName : "",
        TreadDepth: item.treadDepth ? item.treadDepth : "",
        Eval: item.eval ? item.eval : "",
        RegisteredDate: item.registeredDate
          ? this.commonUtils.tableDateFormatter(item.registeredDate)
          : "",
        InstalledDate: item.installedDate
          ? this.commonUtils.tableDateFormatter(item.installedDate)
          : "",
        VehId: item.vehId ? item.vehId : "",
        PoNumber: item.poNumber ? item.poNumber : "",
        TotalCost: item.totalCost ? item.totalCost : 0,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells);
    let selectedColShow = this.initDisplayColumnData(this.headCells);
    this.data = {
      rowsData: this.tireInstallationData,
      headCells: orderedHeadCells,
      avgValues: this.avgValues(this.tireInstallationData),
      selectedColShow: selectedColShow,
    };

    return this.data;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.BrandNumber.toLowerCase().includes(searchText) ||
        item.SerialNo.toLowerCase().includes(searchText) ||
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.Compound.toLowerCase().includes(searchText) ||
        item.TreadDepth.toLowerCase().includes(searchText) ||
        item.Eval.toLowerCase().includes(searchText) ||
        item.RegisteredDate.toLowerCase().includes(searchText) ||
        item.InstalledDate.toLowerCase().includes(searchText) ||
        item.VehId.toLowerCase().includes(searchText) ||
        item.TotalCost.toString().toLowerCase().includes(searchText) ||
        item.PoNumber.toLowerCase().includes(searchText) ||
        item.RatingName.toLowerCase().includes(searchText)
      );
    });
  }
}

export default TireInstallationDataSetter;
