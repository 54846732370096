import React, { forwardRef } from "react";
import "./filterPrintEmailExport-SetButtons.css";
import CommonUtils from "../../services/utils";
import Globalization from "../../globalization";
import PrintIcon from "@material-ui/icons/Print";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MailIcon from "../../assets/mail-icon.svg";
import {
    Divider,
  } from "@material-ui/core";
const PrintEmailExportSetButtons = forwardRef(({
    onClickEmail,
    onClickPrint,
    onClickExport,
    noData,
    isComponentVisible,
    toggleExportDropdown    
}, ref) => {
    const commonUtils = new CommonUtils();
    const globalizationMapping = new Globalization();

    

    return (
        <div className="table-function-title">
        <button onClick={onClickPrint} className="table-function-item">
            <span style={{color: noData ? commonUtils.disabledColor : commonUtils.primaryBlue}}>
              <PrintIcon disabled={noData} fontSize="default" style={{verticalAlign:"middle", marginBottom:5,marginLeft:5}}></PrintIcon>
            </span>
        </button>
        {localStorage.getItem("isEmailBtnShow") === "true" ?
          <button onClick={onClickEmail} className="table-function-item">
            <img className="mail-icon-img" alt="" src={MailIcon} />
            <span className="table-toolbar-text">{globalizationMapping.MANAGE_PARENT_ACCOUNT.EMAIL}</span>
          </button>
        : null }
        <button className="table-function-item export-button" onClick={toggleExportDropdown} ref={ref}>
          <div className="dropdown-icon-container" ref={ref}>
            <div className="export-down-arrow">
                <span style={{color: noData ? commonUtils.disabledColor : commonUtils.primaryBlue}}>
                  <FileCopyIcon disabled={noData} fontSize="default" style={{verticalAlign:"middle", marginBottom:8}}></FileCopyIcon>
                </span>
            </div>
            {isComponentVisible && (
              <div className="export-dropdown-content">
                <div role="button" onClick={() => onClickExport("pdf")} className="export-option">
                  PDF
                </div>
                <Divider />
              </div>
            )}
          </div>
        </button>
      </div>
)});

export default PrintEmailExportSetButtons;