import React, { useState, useEffect } from "react";
import "./VehicleReportsNavigation.css";
import Breadcrumbs from "../../../../common-components/breadcrumbs/breadcrumbs";
import { Card, CardContent } from "@material-ui/core";
import Globalization from "../../../../globalization/index";
import { useHistory } from "react-router-dom";
import { ReportsRoutePaths } from "../../../../routing/ReportsRoutePaths";

const VehicleReportsNavigation = () => {
  const globalizationMapping = new Globalization();
  const vehicleReportsGlobalText = globalizationMapping.VEHICLEREPORTS.BYFLEET;
  const vehicleReportsNav = [vehicleReportsGlobalText.FleetSurvey, vehicleReportsGlobalText.FleetDataAnalysis];
  const vehicleReportsPathNav = ["fleetSurvey", "fleetData"];

  const getRouteName = (currentPathName) => {
    let pathSelected = ReportsRoutePaths.find((route) => route.path === currentPathName);
    return pathSelected;
  };

  const getRoutePath = (path) => {
    let pathSelected = ReportsRoutePaths.find((route) => route.path === path);
    return pathSelected;
  };

  let breadcrumbs = [];
  const history = useHistory();
  let currentPathName = history.location.pathname.split("/")[1];
  let currentRouteSelected = getRouteName(currentPathName);
  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);

  breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME
    },
    {
      path: "reportsDashboard",
      name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.REPORTSDASHBOARD
    },
    {
      path: "",
      name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.VEHICLEREPORTS + " - " 
      + vehicleReportsGlobalText.ByFleet + ": " + selectedRoute.name
    }
  ];

  const vehicleReportNavigation = (path) => {
    let routePath = getRoutePath(path);
    setSelectedRoute(routePath);
  };

  useEffect(() => {
    history.push(selectedRoute.path);
  }, [selectedRoute]);

  return (
    <div>
      <div className="other-tire-main-container">
        <div className="vehicle-reports-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="vehicle-reports-title">{vehicleReportsGlobalText.ByFleet}</div>
                </div>
              </div>
              <div className="scrap-time-nav-container">
                {vehicleReportsNav.map((item, index) => {
                  return (
                    <div key={item} className="vehicle-reports-nav-card-container" onClick={() => vehicleReportNavigation(vehicleReportsPathNav[index])}>
                      <Card className={selectedRoute.name === item ? "active-vehicle-reports-nav-card" : "vehicle-reports-nav-card"}>
                        <CardContent>
                          <span className={selectedRoute.name === item ? "active-vehicle-reports-nav-card-text" : "vehicle-reports-nav-card-text"}>{item}</span>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleReportsNavigation;
