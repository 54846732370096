import Globalization from '../globalization/index'

const globalizationMapping = new Globalization();

export const AdminPaths = [
    {
        name: globalizationMapping.ADMIN.MASTERDATAREQUEST,
        path: 'admin'
      },
      {
        name: globalizationMapping.ADMIN.ADDNEW,
        path: 'add-delete'
      },
      {
        name: globalizationMapping.ADMIN.UPDATEACCOUNTDETAILS,
        path: 'updateaccountdetails'
      },
      {
        name: globalizationMapping.ADMIN.UPDATEBROADCASTMSG,
        path: 'updatebroadcastmsg'
      },
]