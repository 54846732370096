import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';


export default class ManageLocationListApis {
    cs;
    constructor(){
         this.cs = new CommonService(); 
    }
    manageLocationGetDetailsByAccount = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getManageLocationList}${parentAccountId}`,'get',{})
    }

    deleteLocation = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.deleteLocation,'put',req)
    }
}