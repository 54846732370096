import React, { useEffect, useState } from "react";
import { AppBar } from "@material-ui/core";
import "./reports-nav.css";
import ResponsiveTabView from "../tab-view/responsive-tab-view/ResponsiveTabView";
import ReportsRouting from "../../../../services/reports-routing";
import { ReportsRoutePaths } from "../../../../routing/ReportsRoutePaths";
import Globalization from "../../../../globalization/index";
import CommonUtils from "../../../../services/utils";
import ReportsRouteList from "../../../search-report/searchReportUtils";
/**
 * ReportsNav is the main function that comprises of the reports navigation bar
 */
const ReportsNav = () => {
  const commonUtils = new CommonUtils();

  // detects route clicked by user to navigate in reports (CRITICAL IN ROUTING)
  const [selectedRoute, setSelectedRoute] = useState({
    link: "",
    path: "",
  });

  // Globalized text mapping
  const globalizationMapping = new Globalization();
  const reportNavHeadersText = globalizationMapping.REPORTSNAV.REPORTNAVHEADERS;
  // Reports navbar header titles
  const reportHeaders = [
    reportNavHeadersText.REPORTSDASHBOARD,
    reportNavHeadersText.TIRESREPORTS,
    reportNavHeadersText.VEHICLEREPORTS,
    reportNavHeadersText.RIMREPORTS,
    reportNavHeadersText.SURVEYTEMPLATES,
  ];
  // Reports navbar header keys to be dispayed by tab-views
  const reportHeadersKeys = [
    "reportsDashboard",
    "tireReports",
    "vehicleReports",
    "rimReports",
    "surveyTemplates",
  ];
  // React hooks to manage the state of the changing variables for reports navigation
  const [selectedTab, setSelectedTab] = useState({
    selectedTabIndex: 0,
    selectedHeader: reportHeadersKeys[0],
  });
  const [viewedTab, setViewedTab] = useState({
    viewedTabIndex: 0,
    viewedHeader: reportHeadersKeys[0],
  });

  useEffect(() => {
    const currentUrl = commonUtils.getCurrentUrl(window.location.href);
    // eslint-disable-next-line array-callback-return
    const currentNavPath = ReportsRouteList.find((x) => {
      if (currentUrl === x.path) {
        return x.groupNavPath;
      }
    });

    const navIndex = reportHeadersKeys.findIndex(
      (item) => currentNavPath.groupNavPath === item
    );

    setSelectedTab({
      selectedTabIndex: navIndex,
      selectedHeader: reportHeadersKeys[navIndex],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const onClickChange = (newSelectedTabIndex, view) => {
    // allows on click the reports nav to change color for reports-dashboard only and routing to reportsDashboard
    if (
      (view === "desktop" || view === "mobile") &&
      newSelectedTabIndex === 0
    ) {
      // NOTE : Reports Dashboard routing is explicitly handled
      setSelectedRoute({ path: "reportsDashboard" });
      setSelectedTab({
        selectedTabIndex: newSelectedTabIndex,
        selectedHeader: reportHeadersKeys[newSelectedTabIndex],
      });
    }
  };

  // DESKTOP VIEW HANDLING HOVERS
  const onHoverOrClickViewedHeader = (newViewedIndex) => {
    setViewedTab({
      viewedTabIndex: newViewedIndex,
      viewedHeader: reportHeadersKeys[newViewedIndex],
    });
  };
  const onHoverExit = (_newViewedIndex) => {
    setViewedTab({ viewedTabIndex: 0, viewedHeader: reportHeadersKeys[0] });
  };

  //  ROUTING PATH SETTER
  ReportsRoutePaths.forEach((route, _index) => {
    return route.name === selectedRoute.link;
  });

  return (
    <div className="tab-container">
      {/* DESKTOP VIEW */}
      <div className="desktop-view-reports-nav">
        <AppBar position="static">
          <div className="nav-container">
            <div className="nav-elements-container">
              {reportHeaders.map((title, index) => {
                return (
                  <div
                    key={index}
                    onMouseEnter={() => onHoverOrClickViewedHeader(index)}
                    onMouseLeave={() => onHoverExit(index)}
                  >
                    <div
                      className={
                        selectedTab.selectedTabIndex === index
                          ? "selected-tab tabs"
                          : "tabs"
                      }
                      onClick={() => onClickChange(index, "desktop")}
                    >
                      {title}
                    </div>
                    <div
                      className={
                        selectedTab.selectedTabIndex === index
                          ? "selected-tab-border"
                          : null
                      }
                    ></div>
                    <div
                      className={
                        selectedTab.selectedTabIndex !== index &&
                        viewedTab.viewedTabIndex === index &&
                        viewedTab.viewedTabIndex !== 0
                          ? "viewed-tab-border"
                          : null
                      }
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </AppBar>
      </div>

      <div className="mobile-view-reports-nav">
        <AppBar position="static">
          <div className="nav-container">
            <div className="nav-elements-container">
              {reportHeaders.map((name, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => onHoverOrClickViewedHeader(index)}
                    onMouseLeave={() => onHoverExit(index)}
                  >
                    <div
                      className={
                        selectedTab.selectedTabIndex === index
                          ? "selected-tab tabs"
                          : "tabs"
                      }
                      onClick={() => onClickChange(index, "mobile")}
                    >
                      {name}
                    </div>
                    <div
                      className={
                        selectedTab.selectedTabIndex === index
                          ? "selected-tab-border"
                          : null
                      }
                    ></div>
                    <div
                      className={
                        selectedTab.selectedTabIndex !== index &&
                        viewedTab.viewedTabIndex === index &&
                        viewedTab.viewedTabIndex !== 0
                          ? "viewed-tab-border"
                          : null
                      }
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </AppBar>
      </div>

      {/* Desktop Tab view comprises of the menu underneath the reports nav options */}

      <div className="tab-view-menu-container">
        <ResponsiveTabView
          viewedTab={viewedTab}
          setSelectedTab={setSelectedTab}
          setSelectedRoute={setSelectedRoute}
          reportHeadersKeys={reportHeadersKeys}
          onHoverEnter={onHoverOrClickViewedHeader}
          onHoverExit={onHoverExit}
        />
      </div>

      {/* ROUTING LOGIC HERE */}
      <ReportsRouting selectedRoute={selectedRoute} />
    </div>
  );
};

export default ReportsNav;
