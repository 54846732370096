import React from "react";
import { AuthConsumer } from "../services/AuthProvider";

export const Logout = () => (
    <AuthConsumer>
        {({ logout }) => {
            logout();
            return <span>logout loading</span>;
        }}
    </AuthConsumer>
);