import React, {useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./FilterTabsTireReport.css";
import FilterTextField from "../filter-textfields/FilterTextFields";
import Globalization from "../../../../globalization";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  gridContainer: {},
  gridItem: {
    margin: "0.5em 0 1em 1em"
  }
}));

const FilterTabsTireReport = ({
  filterOptions,
  selectedFilterOptions,
  noFieldFilter,
  isTireForecastFilter,
  filterOptionsSelected,
  confirmApplyDateFilter,
  setShowFilter,
  resetTableFilter,
  isEntryDate,
}) => {
  const classes = useStyles();

  const globalizationMapping = new Globalization();
  const filterSectionWords = globalizationMapping.FILTERSECTION;
  let useReferenceArray = useRef([]);
  useReferenceArray.current = Array.from({ length: filterOptions.categories.length }).map(
    (ref, index) => useReferenceArray.current[index] = React.createRef()
  )

  const closeFilter = () => {
    setShowFilter(false);
  };

  const applyDateFilter = () => {
    confirmApplyDateFilter(selectedFilterOptions);
    closeFilter();
  };
  const resetFilter = () => {
    useReferenceArray.current.forEach(ele => ele.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click())
    resetTableFilter();
  };

  const resetDateFilter = () => {
    resetFilter();
  };

  return (
    <div className={classes.root}>
      <div className="tab-header">
        <div className="filter-header-options">
          <div className="filter-reset" onClick={resetDateFilter}>
            {filterSectionWords.Reset}
          </div>
          <div className="filter-apply" onClick={applyDateFilter}>
            {!isEntryDate && !isTireForecastFilter && globalizationMapping.FILTERSECTION.Apply}
            {isEntryDate && globalizationMapping.FILTERSECTION.Apply}
            {isTireForecastFilter && globalizationMapping.FILTERSECTION.Apply}
          </div>
        </div>
      </div>

      {!noFieldFilter && (
        <>
          <div className="tab-header">
            <div className="tab-title">{filterSectionWords.Filters}</div>
          </div>
          <div className="date-tab-container">
            <FilterTextField 
              filterOptions={filterOptions} 
              selectedFilterOptions={selectedFilterOptions} 
              filterOptionsSelected={filterOptionsSelected} 
              refArray={useReferenceArray}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FilterTabsTireReport;
