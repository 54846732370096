import { Backdrop, Button, FormHelperText, Grid, InputAdornment, makeStyles, Modal, OutlinedInput, Select, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useContext, useEffect, useState } from "react";
import CalendarMonthIcon from '@material-ui/icons/Today';
import Globalization from "../../../../globalization";
import "./tire-history-modal.css";
import DateFnsUtils from "@date-io/date-fns";
import { StateContext } from "../../../../services/StateProvider";
import TireHistoryApis from "../../services/tire-history-update-apis";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommonUtils from "../../../../services/utils";
import ExpandMoreStyledIcon from "../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";

const useStyles = makeStyles({
  innerContainer: {
    display: "inline-grid"
  },
  proceed: {
    "&:hover": {
      opacity: 1
    },
    background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none"
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none"
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 1,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none"
  },
  wrapper: {
    position: "relative"
  },
  buttonProgress: {
    color: "#FFFFFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const EditTireHistoryModal = (props) => {
  const styles = useStyles();
  const globalizationMapping = new Globalization();
  const [globalAccountState] = useContext(StateContext);
  const formTexts = globalizationMapping.FORMS;
  const modalTexts = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
  const validationTexts = formTexts.VALIDATIONTEXTS;
  const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;

  const [unitPreferences, setUnitPreferences] = useState({});
  const [tireHistoryObj, setTireHistoryObj] = useState(props.tireHistoryDetails);
  const [toUpdateInspection, setUpdateInspection] = useState(false);
  const [tireInspectionObj, setTireInspectionObj] = useState({});
  const [sortedMasterLists, setSortedMasterLists] = useState({
    removalList: [],
    wearList: [],
    casingList: []
  });
  const [formHelperTexts, setFormHelperTexts] = useState({});
  const editTireHistoryApis = new TireHistoryApis();
  const [showSpinner, setShowSpinner] = useState(false);
  const commonUtils = new CommonUtils();

  useEffect(() => {
    setUnitPreferences({
      pressure: globalAccountState.accountInfo.userSetting.userPressureUnit,
      treadDepth: globalAccountState.accountInfo.userSetting.userMeasurementSystemValue
    });

    if (props.tireHistoryDetails.eventName === "Remove" || props.tireHistoryDetails.eventName === "SendTo" || props.tireHistoryDetails.eventName === "Send To") {
      setUpdateInspection(true);
      editTireHistoryApis.getInspectionRecordByEvent(tireHistoryObj.eventId).then((data) => {
        if (data.didError === false) setTireInspectionObj(data.model);
      });

      let casingListArr = props.masterDataSet.casingCondition;
      let wearListArr = props.masterDataSet.wearConditions;
      let removalListArr = props.masterDataSet.tireRemovalReasons;
      casingListArr.sort(sortFieldEntries("casingConditionName"));
      wearListArr.sort(sortFieldEntries("wearConditionName"));
      removalListArr.sort(sortFieldEntries("reasonName"));

      setSortedMasterLists({
        removalList: removalListArr,
        wearList: wearListArr,
        casingList: casingListArr
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tireHistoryDetails]);

  const sortFieldEntries = (property) => {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      else {
        return 0;
      }
    };
  };

  const handleModalClose = (stateData) => {
    props.closeEditHistoryModal(stateData);
  };

  const getNonNullVal = (val) => {
    if (val !== "") return parseInt(val);
    else return null;
  };

  const handleFieldChange = (field, value) => {
    switch (field) {
      case "date":
        if (value !== null) {
          let date = value;
          date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
          setTireHistoryObj({
            ...tireHistoryObj,
            eventDate: date
          });
        } else
          setTireHistoryObj({
            ...tireHistoryObj,
            eventDate: ""
          });
        break;
      case "pressure":
        setTireHistoryObj({
          ...tireHistoryObj,
          pressure: getNonNullVal(value)
        });
        break;
      case "hours":
        setTireHistoryObj({ ...tireHistoryObj, hours: getNonNullVal(value) });
        break;
      case "distance":
        setTireHistoryObj({ ...tireHistoryObj, km: getNonNullVal(value) });
        break;
      case "tread":
        setTireHistoryObj({
          ...tireHistoryObj,
          treadDepth: getNonNullVal(value)
        });
        break;
      case "comment":
        setTireHistoryObj({ ...tireHistoryObj, comments: value });
        break;
      case "removalreason":
        setTireInspectionObj({
          ...tireInspectionObj,
          removalReasonId: !isNaN(getNonNullVal(value)) ? getNonNullVal(value) : null
        });
        break;
      case "wear":
        setTireInspectionObj({
          ...tireInspectionObj,
          wearConditionId: !isNaN(getNonNullVal(value)) ? getNonNullVal(value) : null
        });
        break;
      case "casing":
        setTireInspectionObj({
          ...tireInspectionObj,
          casingConditionId: !isNaN(getNonNullVal(value)) ? getNonNullVal(value) : null
        });
        break;
      default:
        break;
    }
  };

  const handleDateChange = (value) => {
    handleFieldChange("date", value);
  };

  const validateForm = () => {
    let isValid = true;
    let formErrors = {};

    Object.keys(tireHistoryObj).forEach((key) => {
      if (key === "eventDate" && (isNaN(parseInt(tireHistoryObj[key])) || tireHistoryObj[key] === "")) {
        isValid = false;
        formErrors[key] = validationTexts.INVALIDFIELD;
      }
      if ((key === "pressure" || key === "hours" || key === "km" || key === "treadDepth") && tireHistoryObj[key] < 0) {
        isValid = false;
        formErrors[key] = validationTexts.INVALIDFIELD;
      }
    });

    setFormHelperTexts(formErrors);
    return isValid;
  };

  const handleSubmitClick = () => {
    setShowSpinner(true);
    if (validateForm()) {
      let successStatus = false;
      editTireHistoryApis.updateTireHistoryEntry(tireHistoryObj).then((resp) => {
        if (resp.didError === false) {
          successStatus = true;
          if (toUpdateInspection) {
            editTireHistoryApis.updateTireInspectionRecord(tireInspectionObj).then((response) => {
              //response.didError === false => inspection updated or else  inspection not updated
            });
          }
        } else {
          successStatus = false;
        }
        handleModalClose({
          updatedSection: "updatedtirehistory",
          updateStatus: successStatus,
          successMessage: notificationTexts.EDITEDTIREHISTORY,
          successMessageDesc: notificationTexts.EDITEDTIREHISTORYSUCCESSDESC
        });
      });
    }
  };

  return (
      <div className="container">
        <Modal
          open={true}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          disableScrollLock={true}
        >
          <div className="edit-history-modal-container center-modal">
            <div className="tire-history-title-container">
              <div className="delete-tire-title">{modalTexts.EDITTIREHISTORY}</div>
              <div className="close-icon-container" onClick={handleModalClose}>
                <img src={require("../../../../assets/close-icon.svg")} className="modal-close-icon" alt="close-icon" />
              </div>
            </div>

            <div className="edit-history-static-modal-description">
              <Grid container spacing={1}>
                <Grid item xs={4} className={styles.innerContainer}>
                  <span className="item-detail-label-edit-history-modal">{modalTexts.ACTION}</span>
                  <span className="item-detail-content-edit-history-modal">{tireHistoryObj.eventName}</span>
                </Grid>
                <Grid item xs={4} className={styles.innerContainer}>
                  <span className="item-detail-label-edit-history-modal">{modalTexts.DISPO}</span>
                  <span className="item-detail-content-edit-history-modal">{tireHistoryObj.disposition}</span>
                </Grid>
                <Grid item xs={4} className={styles.innerContainer}>
                  <span className="item-detail-label-edit-history-modal">{modalTexts.COST}</span>
                  <span className="item-detail-content-edit-history-modal">{tireHistoryObj.cost}</span>
                </Grid>
                <Grid item xs={4} className={styles.innerContainer}>
                  <span className="item-detail-label-edit-history-modal">{modalTexts.VEHICLEID}</span>
                  <span className="item-detail-content-edit-history-modal">{tireHistoryObj.vehicleNumber ? tireHistoryObj.vehicleNumber : "-"}</span>
                </Grid>
                <Grid item xs={4} className={styles.innerContainer}>
                  <span className="item-detail-label-edit-history-modal">{modalTexts.WHEELPOS}</span>
                  <span className="item-detail-content-edit-history-modal">{tireHistoryObj.wheelPosition !== "" ? tireHistoryObj.wheelPosition : "-"}</span>
                </Grid>
              </Grid>
            </div>

            <div className="edit-history-helper-message">
              {formTexts.MANDATORYFIELDS}&nbsp;
              <span className="required-mark">*</span>
            </div>

            <div className="edit-history-inner-form-container">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <p className="field-label-history-modal">
                    {modalTexts.DATE} <span className="required-mark">*</span>
                  </p>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="enter-detail-input-box"
                      // variant="inline"
                      value={tireHistoryObj.eventDate}
                      onChange={handleDateChange}
                      format={commonUtils.getDateFormat()}
                      placeholder={commonUtils.getDateFormat()}
                      variant="dialog"
                      keyboardIcon={<CalendarMonthIcon/>}
                      helperText=""
                    />
                  </MuiPickersUtilsProvider>
                  <FormHelperText>{formHelperTexts.eventDate}</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label-history-modal">{modalTexts.PRESSURE}</p>
                  <OutlinedInput
                    type="number"
                    className="enter-detail-input-box"
                    variant="outlined"
                    value={tireHistoryObj.pressure ? tireHistoryObj.pressure : ""}
                    onChange={(e) => handleFieldChange("pressure", e.target.value)}
                    endAdornment={
                      <InputAdornment className={styles.adorn} position="end">
                        {unitPreferences.pressure}
                      </InputAdornment>
                    }
                    placeholder={" " + modalTexts.ENTER + " " + modalTexts.PRESSURE}
                  />
                  <FormHelperText>{formHelperTexts.pressure}</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label-history-modal">{modalTexts.HOURS}</p>
                  <OutlinedInput
                    type="number"
                    className="enter-detail-input-box"
                    variant="outlined"
                    value={tireHistoryObj.hours ? tireHistoryObj.hours : ""}
                    onChange={(e) => handleFieldChange("hours", e.target.value)}
                    placeholder={" " + modalTexts.ENTER + " " + modalTexts.HOURS}
                  />
                  <FormHelperText>{formHelperTexts.hours}</FormHelperText>
                </Grid>
                {toUpdateInspection && (
                  <>
                    <Grid item xs={4}>
                      <p className="field-label-history-modal">{globalizationMapping.INSPECTNREMOVETIRE.REMOVALREASON}</p>
                      <Select
                        native
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={tireInspectionObj.removalReasonId ? tireInspectionObj.removalReasonId : ""}
                        IconComponent={ExpandMoreStyledIcon}
                        onChange={(e) => handleFieldChange("removalreason", e.target.value)}
                      >
                        <option key={0} value={""}>
                          {modalTexts.DROPDOWNPLACEHOLDER}
                        </option>
                        {sortedMasterLists.removalList.map((reason) => (
                          <option key={reason.reasonId} value={reason.reasonId}>
                            {reason.reasonName}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label-history-modal">{globalizationMapping.INSPECTNREMOVETIRE.WEARCONDITION}</p>
                      <Select
                        native
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={tireInspectionObj.wearConditionId ? tireInspectionObj.wearConditionId : ""}
                        IconComponent={ExpandMoreStyledIcon}
                        onChange={(e) => handleFieldChange("wear", e.target.value)}
                      >
                        <option key={0} value={""}>
                          {modalTexts.DROPDOWNPLACEHOLDER}
                        </option>
                        {sortedMasterLists.wearList.map((condition) => (
                          <option key={condition.wearConditionId} value={condition.wearConditionId}>
                            {condition.wearConditionName}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label-history-modal">{globalizationMapping.INSPECTNREMOVETIRE.CASINGCONDITION}</p>
                      <Select
                        native
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={tireInspectionObj.casingConditionId ? tireInspectionObj.casingConditionId : ""}
                        IconComponent={ExpandMoreStyledIcon}
                        onChange={(e) => handleFieldChange("casing", parseInt(e.target.value))}
                      >
                        <option key={0} value={""}>
                          {modalTexts.DROPDOWNPLACEHOLDER}
                        </option>
                        {sortedMasterLists.casingList.map((condition) => (
                          <option key={condition.casingConditionId} value={condition.casingConditionId}>
                            {condition.casingConditionName}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                  </>
                )}
                <Grid item xs={4}>
                  <p className="field-label-history-modal">{modalTexts.DISTANCE}</p>
                  <OutlinedInput
                    type="number"
                    className="enter-detail-input-box"
                    variant="outlined"
                    value={tireHistoryObj.km ? tireHistoryObj.km : ""}
                    onChange={(e) => handleFieldChange("distance", e.target.value)}
                    endAdornment={
                      <InputAdornment className={styles.adorn} position="end">
                        {unitPreferences.treadDepth === "imperial" ? modalTexts.MILES : modalTexts.KM}
                      </InputAdornment>
                    }
                    placeholder={" " + modalTexts.ENTER + " " + modalTexts.DISTANCE}
                  />
                  <FormHelperText>{formHelperTexts.km}</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label-history-modal">{globalizationMapping.INSPECTNREMOVETIRE.TREADDEPTH}</p>
                  <OutlinedInput
                    type="number"
                    className="enter-detail-input-box"
                    variant="outlined"
                    value={tireHistoryObj.treadDepth ? tireHistoryObj.treadDepth : ""}
                    onChange={(e) => handleFieldChange("tread", e.target.value)}
                    endAdornment={
                      <InputAdornment className={styles.adorn} position="end">
                        {console.log(unitPreferences.treadDepth,'unitPreferences.treadDepth')}
                        {unitPreferences.treadDepth === "imperial" ? formTexts["32"] : formTexts.MM }
                      </InputAdornment>
                    }
                    placeholder={" " + modalTexts.ENTER + " " + globalizationMapping.INSPECTNREMOVETIRE.TREADDEPTH}
                  />
                  <FormHelperText>{formHelperTexts.treadDepth}</FormHelperText>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label-history-modal">{modalTexts.COMMENTS}</p>
                  <TextField
                    className="enter-detail-input-box"
                    variant="outlined"
                    value={tireHistoryObj.comments}
                    onChange={(e) => handleFieldChange("comment", e.target.value)}
                    multiline
                    rows={2}
                    type="text"
                    placeholder={" " + modalTexts.ENTER + " " + modalTexts.COMMENTS}
                    inputProps={{ maxLength: 200 }}
                  />
                </Grid>
              </Grid>
            </div>

            <hr className="hr-divider-action" />
            <div className="history-modal-action-buttons">
              <Button className={styles.cancel} variant="contained" disableElevation={true} onClick={handleModalClose}>
                {formTexts.CANCEL}
              </Button>
              <div className={styles.wrapper}>
                <Button
                  className={styles.proceed}
                  variant="contained"
                  disableElevation={true}
                  disabled={showSpinner}
                  onClick={() => {
                    handleSubmitClick();
                  }}
                >
                  {formTexts.SUBMIT}
                </Button>
                {showSpinner && <CircularProgress size={24} className={styles.buttonProgress} />}
              </div>
            </div>
          </div>
        </Modal>
      </div>
  );
};

export default EditTireHistoryModal;
