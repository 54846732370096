import React, { createContext, useReducer } from 'react';

export const StateContext = createContext();

export const StateProvider = ({ children, reducer, initialState }) => {
    const mainReducer = useReducer(reducer, initialState);
    return (
        <StateContext.Provider value={mainReducer}>
            {children}
        </StateContext.Provider>
    );
};
