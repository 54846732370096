import CommonService from "../../../services/common-api-service";
import { SERVER } from "../../../services/api-urls";

export default class ManageParentAccountListApis {
  cs;
  constructor() {
    this.cs = new CommonService();
  }
  manageParentAccountGetDetailsByAccount = (_parentAccountId) => {
    return this.cs.httpConnection(
      `${SERVER.SERVICES.getManageAccountList}`,
      "get",
      {}
    );
  };
  deleteAccount = (req) => {
    return this.cs.httpConnection(SERVER.SERVICES.deleteAccount, "put", req);
  };
}
