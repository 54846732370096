import React, { useEffect, useState } from "react";
import "../../../../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    height: 625
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));
const commonUtils = new CommonUtils();

const ByVehicleModelTable = ({ tableData, showColumns }) => {
  const [byVehicleModelTableData, setByVehicleModelTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);


  useEffect(() => {
    setByVehicleModelTableData(tableData);
    setPage(0);
    let arr = commonUtils.rowPerPageSelector(byVehicleModelTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);


  const selectWheelPosition = (row, index) => {
    if (index !== 0 && row.ActualWheelPosition === byVehicleModelTableData.rowsData[index - 1].ActualWheelPosition) {
      return ""
    } else {
      return row.ActualWheelPosition
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table 
              stickyHeader 
              className={classes.table} 
              aria-labelledby="tableTitle" 
              aria-label="a dense table"
              size="small"
              >
              <TableHead>
                <TableRow>
                  {byVehicleModelTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className="table-head-row"
                        key={headCell.keyIndex}
                        align="left"
                      >
                        {headCell.label}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {byVehicleModelTableData.rowsData.length > 0 && (
                <TableBody>
                  {byVehicleModelTableData.rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    return row.BrandNumber !== "Average" ? (
                      <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={index}>
                        {showColumns.WheelPosition && <TableCell className="table-content-text">{selectWheelPosition(row, index)}</TableCell>}
                        {showColumns.BrandNumber && <TableCell className="table-content-text">{row.BrandNumber}</TableCell>}
                        {showColumns.TireSerialNo && <TableCell className="table-content-text">{row.TireSerialNo}</TableCell>}
                        {showColumns.ManufacturerName && <TableCell className="table-content-text">{row.ManufacturerName}</TableCell>}
                        {showColumns.VehicleManufacturerName && <TableCell className="table-content-text">{row.VehicleManufacturerName}</TableCell>}
                        {showColumns.SizeName && <TableCell className="table-content-text">{row.SizeName}</TableCell>}
                        {showColumns.TypeName && <TableCell className="table-content-text">{row.TypeName}</TableCell>}
                        {showColumns.VehicleTypeName && <TableCell className="table-content-text">{row.VehicleTypeName}</TableCell>}
                        {showColumns.CompoundName && <TableCell className="table-content-text">{row.CompoundName}</TableCell>}
                        {showColumns.RatingName && <TableCell className="table-content-text">{row.RatingName}</TableCell>}
                        {showColumns.OriginalTread && <TableCell className="table-content-text">{row.OriginalTread}</TableCell>}
                        {showColumns.OutsideTread && <TableCell className="table-content-text">{row.OutsideTread}</TableCell>}
                        {showColumns.InsideTread && <TableCell className="table-content-text">{row.InsideTread}</TableCell>}
                        {showColumns.PercentageWorn && 
                            <Tooltip title={commonUtils.wornPercentageInfo(row.PercentageWorn)} placement="top-end">
                              <TableCell className={commonUtils.wornPercentageClass(row.PercentageWorn)}>{row.PercentageWorn}%</TableCell>
                            </Tooltip>}
                        {showColumns.EvaluationNo && <TableCell className="table-content-text">{row.EvaluationNo}</TableCell>}
                        {showColumns.InstallationDate && <TableCell className="table-content-text">{row.InstallationDate}</TableCell>}
                        {showColumns.VehicleId && <TableCell className="table-content-text">{row.VehicleId}</TableCell>}
                        {showColumns.TotalCost && <TableCell className="table-content-text">{row.TotalCost}</TableCell>}
                        {showColumns.TotalHours && <TableCell className="table-content-text">{row.TotalHours}</TableCell>}
                        {showColumns.Hoursmm && <TableCell className="table-content-text">{row.Hoursmm}</TableCell>}
                        {showColumns.ProjectedHours && <TableCell className="table-content-text">{row.ProjectedHours}</TableCell>}
                        {showColumns.CostHourProj && <TableCell className="table-content-text">{row.CostHourProj}</TableCell>}
                        {showColumns.HoursLeft && <TableCell className="table-content-text">{row.HoursLeft}</TableCell>}
                      </TableRow>
                    ) : (
                      <TableRow className="total-avg-row" key={index}>
                        {showColumns.WheelPosition && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.BrandNumber && <TableCell className="total-avg-text">{row.BrandNumber}</TableCell>}
                        {showColumns.TireSerialNo && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.ManufacturerName && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.VehicleManufacturerName && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.SizeName && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.TypeName && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.VehicleTypeName && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.CompoundName && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.RatingName && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.OriginalTread && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.OutsideTread && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.InsideTread && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.PercentageWorn && <TableCell className="total-avg-text">{row.PercentageWorn}%</TableCell>}
                        {showColumns.EvaluationNo && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.InstallationDate && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.VehicleId && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.TotalCost && <TableCell className="total-avg-text">{row.TotalCost}</TableCell>}
                        {showColumns.TotalHours && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.Hoursmm && <TableCell className="total-avg-text">{row.Hoursmm}</TableCell>}
                        {showColumns.ProjectedHours && <TableCell className="total-avg-text"></TableCell>}
                        {showColumns.CostHourProj && <TableCell className="total-avg-text">{row.CostHourProj}</TableCell>}
                        {showColumns.HoursLeft && <TableCell className="total-avg-text"></TableCell>}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
              {byVehicleModelTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={12} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={byVehicleModelTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default ByVehicleModelTable;
