import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";

class VehicleHistorySetter {
  constructor() {
    this.data = {};
    this.vehicleHistoryListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    const selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    const selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  setData(data) {
    const vehicleHistory = data.model.vehicleHistory;
    vehicleHistory.forEach((vehicle, vehicleIndex) => {
      this.vehicleHistoryListData.push({
        Key: vehicleIndex,
        EventId: vehicle.eventId,
        Date: vehicle.eventDate
          ? this.commonUtils.formatISODate(vehicle.eventDate)
          : "",
        Event: vehicle.eventName ? vehicle.eventName : "",
        Hours: vehicle.hours ? vehicle.hours : 0,
        Distance: vehicle.miles ? vehicle.miles : 0,
        Comments: vehicle.comments ? vehicle.comments : "",
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.VehicleHistory;
    columnDefinitions.forEach((item, index) => {
      const colDefnString = item.text.split(".");
      const [masterKey, key] = colDefnString;
      const labelName = this.globalizationMapping[masterKey][key];
      const labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    const selectedColShow = this.initDisplayColumnData(this.headCells);
    this.data = {
      rowsData: this.vehicleHistoryListData,
      headCells: this.headCells,
      selectedColShow,
    };
    return this.data;
  }
}
export default VehicleHistorySetter;
