import { Backdrop, Button, makeStyles, Modal } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import Globalization from "../../../../globalization";
import ManageRimListApis from "../../services/ManageRimListApis";
const useStyles = makeStyles({
  proceed: {
    "&:hover": {
      opacity: 1
    },
    background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none"
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none"
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5
  }
});

const DeleteRimModal = (props) => {
  const history = useHistory();
  const styles = useStyles();
  const globalizationMapping = new Globalization();
  const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
  const titleTexts = globalizationMapping.FORMS.RIM.RIMFORM;
  const manageRimListService = new ManageRimListApis();

  const handleDeleteClick = () => {
    let successStatus = false;
    if (props.isSelectionMultiple) {
      let reqCount = 0;
      props.rimIds.forEach((rim) => {
        manageRimListService.deleteRim(rim.RimId).then((response) => {
          reqCount = reqCount + 1;
          if (response.didError === false) {
            successStatus = true;
          } else {
            successStatus = false;
          }
          if (reqCount === props.rimIds.length) {
            closeModal({
              updatedSection: "delterim",
              updateStatus: successStatus,
              successMessage: notificationTexts.MULTIPLERIMDELETED,
              successMessageDesc: notificationTexts.MULTIPLEDELETEDRIMSUCCESS
            });
          }
        });
      });
    } else {
      manageRimListService.deleteRim(props.rimId).then((response) => {
        if (response.didError === false) {
          successStatus = true;
        } else {
          successStatus = false;
        }
        if (props.fromView) {
          history.push({
            pathname: "/manage-rim",
            state: {
              updatedSection: "delterim",
              updateStatus: successStatus,
              successMessage: notificationTexts.RIMDELETED,
              successMessageDesc: notificationTexts.DELETERIMSUCCESS.replace("#1#1", props.rimNumber)
            }
          });
        } else {
          closeModal({
            updatedSection: "delterim",
            updateStatus: successStatus,
            successMessage: notificationTexts.RIMDELETED,
            successMessageDesc: notificationTexts.DELETERIMSUCCESS.replace("#1#1", props.rimNumber)
          });
        }
      });
    }
  };
  const closeModal = (state) => {
    props.closeDeleteModal(state);
  };

  return (
    <React.Fragment>
      <div className="container">
        <Modal
          open={true}
          onClose={!props.isModalOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300
          }}
          disableScrollLock={true}
        >
          <div className={`delete-vehicle-modal-container center-modal-vehicle ${props.isSelectionMultiple && props.rimIds.length > 50 ? "delete-multiple-selected" : ""}`}>
            <div className="delete-vehicle-title-container">
              <div className="delete-vehicle-title">{titleTexts.DELETERim}</div>
              <div className="close-icon-container" onClick={props.closeDeleteModal}>
                <img src={require("../../../../assets/close-icon.svg")} className="modal-close-icon" alt="close-icon" />
              </div>
            </div>
            <div className="delete-modal-description-vehicle">
              <p className="vehicle-ids">
                {!props.isSelectionMultiple
                  ? titleTexts.DELETRimDESC.replace("#1#1", props.rimNumber)
                  : titleTexts.DELETRimDESC.replace(
                      "#1#1",
                      Object.values(props.rimIds).map((value) => value.RimSerialNumber)
                    ).replaceAll(",", ", ")}
              </p>
            </div>
            <div className="delete-modal-description-vehicle" style={{ marginTop: "-25px" }}>
              <p>{titleTexts.WISHPROCEED}</p>
            </div>
            <div className="modal-action-buttons">
              <Button className={styles.cancel} variant="contained" disableElevation={true} onClick={props.closeDeleteModal}>
                {globalizationMapping.FORMS.CANCEL}
              </Button>
              <Button
                className={styles.proceed}
                variant="contained"
                disableElevation={true}
                color="primary"
                onClick={() => {
                  handleDeleteClick();
                }}
              >
                {globalizationMapping.FORMS.PROCEED}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DeleteRimModal;
