import React, { useEffect, useContext, useState } from "react";
import "./ViewRim.css";
import "../../../../../common-styles/TableFunctionOptions.css";
import {
    Card,
    CardContent,
    Grid,
    InputLabel,
} from "@material-ui/core";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import { useParams, useLocation } from "react-router-dom";
import Globalization from "../../../../../globalization";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import { StateContext } from "../../../../../services/StateProvider";
import Spinner from "../../../../../common-components/spinner/spinner";
import ViewRimNavigation from "../../../view-rim-navigation/ViewRimNavigation";
import CommonUtils from "../../../../../services/utils";
import FlashMessage from "../../../../../common-components/pop-up-notification/notification-snackbar";
import DeleteRimModal from "../../../../manage-rim/components/modals/DeleteRim";



const ViewRimViewOnly = () => {
    
    const [showSpinner, setShowSpinner] = useState(true);
    const { RimId } = useParams();
    const [currentRoute, setCurrentRoute] = useState({ path: 'identification' });
    const globalizationMapping = new Globalization();
    const headerApiObj = new HeaderApi();
    const globalCreateRimTexts = globalizationMapping.FORMS.RIM.RIMFORM;
    const [globalAccountState] = useContext(StateContext);
    const [rimDetails, setRimDetails] = useState({})
    const [accDetails, setAccDetails] = useState("");
    const [currency, setCurrency] = useState("");
    const commonUtils = new CommonUtils()
    const locationObj = useLocation();
    const [flashMessageState, setFlashMessageState] = useState(locationObj);
    const [showNotification, setShowNotification] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        setShowSpinner(true)
        let accountInfoObject = accountInfoIdsFormatter(globalAccountState)
        let locationIds = accountInfoObject.locationIds;

        headerApiObj.getLocationById(locationIds[0]).then((locationData) => {
            if (locationData && locationData.model) {
                setCurrency(locationData.model.currency);
            }
        });
        headerApiObj.getMasterDataWeb().then(data => {
            if (data.model !== undefined) {
                headerApiObj.getRim(RimId).then(rimData => {
                    if (rimData.model !== undefined) {
                        setShowSpinner(false)

                        headerApiObj.detailsByAccount(rimData.model.parentAccountId).then(parentData => {
                            let location = parentData.model.locationList.find(location => location.locationId === rimData.model.locationId);
                            setAccDetails(parentData.model.accountName + " - " + location.locationName)
                        });
                        let disposition = data.model.rimDispositions.find(disposition => disposition.dispositionId === rimData.model.dispositionId);
                        rimData.model['dispositionName'] = disposition['dispositionName'];
                        let vehiclePos = rimData.model.vehicleNumber
                        if (rimData.model.wheelPosition)
                            vehiclePos += " / " + rimData.model.wheelPosition
                        rimData.model['vehiclePos'] = vehiclePos;
                        let trackBy;
                        if (rimData.model.mileageType) {
                            if (parseInt(rimData.model.mileageType,10) === 1) {
                                trackBy = globalCreateRimTexts.HOURS
                            }
                            else if (parseInt(rimData.model.mileageType,10) === 3) {
                                trackBy = "Both";
                            }
                            else {
                                if (globalAccountState.accountInfo.userSetting.userMeasurementSystemValue === "imperial") {
                                trackBy = "Miles"
                                }
                                else {
                                    trackBy = "Km"
                                }
                            }
                        }
                        rimData.model['trackBy'] = trackBy;
                        let manufacture = data.model.rimManufacturers.find(rimManufacturer => rimManufacturer.manufacturerId === rimData.model.manufacturerId);
                        rimData.model['manfactureName'] = manufacture['manufacturerName'];
                        let size = data.model.rimSizes.find(rimSize => rimSize.sizeId === rimData.model.sizeId);
                        rimData.model['sizeName'] = size['sizeName'];
                        let type = data.model.rimTypes.find(rimType => rimType.typeId === rimData.model.typeId);
                        rimData.model['typeName'] = type['typeName'];
                        if (rimData.model.valveTypeId) {
                            let valve = data.model.rimValves.find(rimValve => rimValve.valveId === rimData.model.valveTypeId);
                            rimData.model['valveName'] = valve['valveName'];
                        }
                        rimData.model['registrationDate'] = commonUtils.formatISODate(rimData.model.registrationDate)
                        if (rimData.model.nextCheck)
                            rimData.model['nextCheck'] = commonUtils.formatISODate(rimData.model.nextCheck)
                        if (rimData.model.lastChecked)
                            rimData.model['lastChecked'] = commonUtils.formatISODate(rimData.model.lastChecked)
                        if (rimData.model.rimInstallDate)
                            rimData.model['rimInstallDate'] = commonUtils.formatISODate(rimData.model.rimInstallDate)
                        setRimDetails(rimData.model);
                    }
                });
            }
        });
        if (locationObj.state !== undefined) {
            switch (locationObj.state.updatedSection) {
                case "editoldrim":
                case "createnewrim":
                    if (locationObj.state.updateStatus) {
                        setShowNotification(true);
                    }
                    break;
                default:
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 5000);
                    break;
            }
            setFlashMessageState(locationObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const changedRoute = (selectedRoute) => {
        setCurrentRoute(selectedRoute);
    }

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
    };
    return (
        <div className="standalone-new-header-container">
            <ViewRimNavigation changedRoute={changedRoute} />
            {showNotification && flashMessageState.state && (
                <FlashMessage
                    status={flashMessageState.state.updateStatus}
                    message={flashMessageState.state.successMessage}
                    description={flashMessageState.state.successMessageDesc}
                />
            )}
            {showSpinner && <Spinner />}
            {!showSpinner && (
                <div className="view-rim-main view-vehicle-form">
                    <Card className="quick-inspect-list-card view-rim-list-card">
                        <CardContent>
                            {/* Identification Details */}
                            {currentRoute.path === 'identification' && <div className="location-details-grid-view-vehicle location-details-grid-view-rim">
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.ACCOUNTLOCATION}</p>
                                        <InputLabel>{accDetails}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.SERIAL}</p>
                                        <InputLabel>{rimDetails.rimSerialNo}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.BRAND}</p>
                                        <InputLabel>{rimDetails.brandNo}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.REGDATE}</p>
                                        <InputLabel>{rimDetails.registrationDate}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.PURCHASEORDER}</p>
                                        <InputLabel>{rimDetails.ponumber}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.BARCODE}</p>
                                        <InputLabel>{rimDetails.barCode}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.LOTNUMBER}</p>
                                        <InputLabel>{rimDetails.lotNo}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.DISPOSITION}</p>
                                        <InputLabel>{rimDetails.dispositionName}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.VEHICALIDPOS}</p>
                                        <InputLabel>{rimDetails.vehiclePos}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.STATUS}</p>
                                        <InputLabel>{rimDetails.rimStatusId === 1 && rimDetails.rimStatusId !== 0 ? globalCreateRimTexts.USED : globalCreateRimTexts.CONSIGNED}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.MOUNTTOTIRE}</p>
                                        <InputLabel>{rimDetails.isMountToTire ? "Yes" : "No"}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM.TIRESERIALNO}</p>
                                        <InputLabel>{rimDetails.tireSerialNo}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.TRACKINGMETHOD}</p>
                                        <InputLabel>{rimDetails.trackBy}</InputLabel>
                                    </Grid>
                                    {(rimDetails.mileageType === 1 || rimDetails.mileageType === 3) &&
                                        <Grid item xs={4}>
                                            <p className="field-label">{globalCreateRimTexts.CURRENT}</p>
                                            <InputLabel>{rimDetails.currentHours}</InputLabel>
                                        </Grid>
                                    }
                                    {(rimDetails.mileageType === 1 || rimDetails.mileageType === 3) &&
                                        <Grid item xs={4}>
                                            <p className="field-label">{globalCreateRimTexts.INSPINTERVAL}</p>
                                            <InputLabel>{rimDetails.inspectionIntervalHours}</InputLabel>
                                        </Grid>
                                    }
                                    {(rimDetails.mileageType === 1 || rimDetails.mileageType === 3) &&
                                        <Grid item xs={4}>
                                            <p className="field-label">{globalCreateRimTexts.HOURSLEFT}</p>
                                            <InputLabel>{rimDetails.hoursLeft}</InputLabel>
                                        </Grid>
                                    }
                                    {(rimDetails.mileageType === 2 || rimDetails.mileageType === 3) &&
                                        <Grid item xs={4}>
                                            <p className="field-label">{globalAccountState.accountInfo.userSetting.userMeasurementSystemValue === "imperial" ? globalCreateRimTexts.CURRMILES : globalCreateRimTexts.CURRKM}</p>
                                            <InputLabel>{rimDetails.currentMiles}</InputLabel>
                                        </Grid>
                                    }
                                    {(rimDetails.mileageType === 2 || rimDetails.mileageType === 3) &&
                                        <Grid item xs={4}>
                                            <p className="field-label">{globalCreateRimTexts.INSPINTERVAL}</p>
                                            <InputLabel>{rimDetails.inspectionIntervalMiles}</InputLabel>
                                        </Grid>
                                    }
                                    {(rimDetails.mileageType === 2 || rimDetails.mileageType === 3) &&
                                        <Grid item xs={4}>
                                            <p className="field-label">{globalAccountState.accountInfo.userSetting.userMeasurementSystemValue === "imperial" ? globalCreateRimTexts.MILESLEFT : globalCreateRimTexts.KMLEFT}</p>
                                            <InputLabel>{rimDetails.milesLeft}</InputLabel>
                                        </Grid>
                                    }
                                </Grid>
                            </div>}
                            {currentRoute.path === 'description' && <div className="location-details-grid-view-vehicle location-details-grid-view-rim">
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.MANUFACTURER}</p>
                                        <InputLabel>{rimDetails.manfactureName}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.SIZE}</p>
                                        <InputLabel>{rimDetails.sizeName}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.TYPE}</p>
                                        <InputLabel>{rimDetails.typeName}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.EVLNUM}</p>
                                        <InputLabel>{rimDetails.evalNo}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.VALTYPE}</p>
                                        <InputLabel>{rimDetails.valveName}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.MAXINFRAT}</p>
                                        <InputLabel>{rimDetails.maxInflationRating}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.TORSPEC}</p>
                                        <InputLabel>{rimDetails.torqueSpec}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.CHECKINV}</p>
                                        <InputLabel>{rimDetails.checkInterval}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.RIMINSDATE}</p>
                                        <InputLabel>{rimDetails.rimInstallDate}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.LASTCHECKED}</p>
                                        <InputLabel>{rimDetails.lastChecked}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.INSPECTOR}</p>
                                        <InputLabel>{rimDetails.inspector}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.NEXTCHECK}</p>
                                        <InputLabel>{rimDetails.nextCheck}</InputLabel>
                                    </Grid>
                                </Grid>
                            </div>}
                            {currentRoute.path === 'cost' && <div className="location-details-grid-view-vehicle location-details-grid-view-rim">
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.OFREPAIRS}</p>
                                        <InputLabel>{rimDetails.repairCount} {currency}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.PURCHASECOST}</p>
                                        <InputLabel>{rimDetails.purchaseCost} {currency}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.REPAIRCOST}</p>
                                        <InputLabel>{rimDetails.repairCost} {currency}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.COSTADJ}</p>
                                        <InputLabel>{rimDetails.costAdjustment} {currency}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.SOLDAMOUNT}</p>
                                        <InputLabel>{rimDetails.soldAmount} {currency}</InputLabel>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="field-label">{globalCreateRimTexts.NETCOST}</p>
                                        <InputLabel>{rimDetails.netCost} {currency}</InputLabel>
                                    </Grid>
                                </Grid>
                            </div>}
                        </CardContent>
                    </Card>
                </div>
            )}
            {isDeleteModalOpen &&
                <DeleteRimModal
                    closeDeleteModal={handleDeleteModalClose}
                    rimId={RimId}
                    rimNumber={rimDetails.rimSerialNo}
                    isSelectionMultiple={false}
                    isModalOpen={isDeleteModalOpen}
                    fromView={true}
                />}
        </div>
    );
}
export default ViewRimViewOnly;