import React from "react";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import Globalization from "../../../globalization";
import VideoContainer from "../video-container/VideoContainer";
import "./help.css";

const Help = () => {
  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;

  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME,
    },
    {
      path: "",
      name: globalHeaderTexts.HELP,
    },
  ];
  return (
    <div className="standalone-new-header-container">
      <div className="edit-parent-account-main-page-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {globalHeaderTexts.HELP}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="manage-vehicle-list-container">
        <div className="edit-parent-container">     
            <VideoContainer />
        </div>
      </div>

    </div>
  );
};

export default Help;
