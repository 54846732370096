import React, { useEffect, useState } from "react";
import "../../../../../../../common-styles/TableStyle.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../../services/utils";
import Globalization from "../../../../../../../globalization";
import "./TiresSentToRetreadTable.css";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    height: 625,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const commonUtils = new CommonUtils();

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}


function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => {
    el.DateSent = new Date(commonUtils.tableDateFormatter(el.DateSent));
    return [el, index];
  });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => {
    el[0].DateSent = GetFormattedDate(el[0].DateSent);
    return el[0];
  });
}

const GetFormattedDate = (inputDate) => {
  let todayTime = inputDate;
  let month = todayTime.getMonth() + 1;
  let day = todayTime.getDate();
  let year = todayTime.getFullYear();
  let newDate = month + "/" + day + "/" + year;
  newDate = commonUtils.tableDateFormatter(newDate);
  return newDate;
};

const TiresSentToRetreadTable = ({
  tableData,
  showColumns,
  _filterApplied,
}) => {
  const [tiresSentToRetreadTableData, setTiresSentToRetreadTableData] =
    useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ManufacturerName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const globalizationMapping = new Globalization();

  useEffect(() => {
    setTiresSentToRetreadTableData(tableData);
    setPage(0);
    let arr = commonUtils.rowPerPageSelector(tiresSentToRetreadTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
  }, [tableData, showColumns, tiresSentToRetreadTableData.rowsData.length]);

  let lastPage =
    Math.floor(tiresSentToRetreadTableData.rowsData.length / rowsPerPage) === 1
      ? 0
      : Math.floor(tiresSentToRetreadTableData.rowsData.length / rowsPerPage);

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {tiresSentToRetreadTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className="table-head-row"
                        key={headCell.keyIndex}
                        // align={headCell.numeric === true ? "right" : "left"}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort(headCell.value)}
                        >
                          {headCell.label}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {tiresSentToRetreadTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    tiresSentToRetreadTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          tabIndex={-1}
                          key={row.Key}
                        >
                          {showColumns.BrandNumber && (
                            <TableCell className="table-content-text">
                              {row.BrandNumber}
                            </TableCell>
                          )}
                          {showColumns.SerialNo && (
                            <TableCell className="table-content-text">
                              {row.SerialNo}
                            </TableCell>
                          )}
                          {showColumns.ManufacturerName && (
                            <TableCell className="table-content-text">
                              {row.ManufacturerName}
                            </TableCell>
                          )}
                          {showColumns.SizeName && (
                            <TableCell className="table-content-text">
                              {row.SizeName}
                            </TableCell>
                          )}
                          {showColumns.TypeName && (
                            <TableCell className="table-content-text">
                              {row.TypeName}
                            </TableCell>
                          )}
                          {showColumns.Compound && (
                            <TableCell className="table-content-text">
                              {row.Compound}
                            </TableCell>
                          )}
                          {showColumns.RatingName && (
                            <TableCell className="table-content-text">
                              {row.RatingName}
                            </TableCell>
                          )}
                          {showColumns.TreadDepth && (
                            <TableCell className="table-content-text">
                              {row.TreadDepth}
                            </TableCell>
                          )}
                           {showColumns.PercentageWorn && 
                            <Tooltip title={commonUtils.wornPercentageInfo(row.PercentageWorn)} placement="top-end">
                              <TableCell className={commonUtils.wornPercentageClass(row.PercentageWorn)}>{row.PercentageWorn}%</TableCell>
                            </Tooltip>}
                          {showColumns.HoursAtRemoval && (
                            <TableCell className="table-content-text">
                              {row.HoursAtRemoval}
                            </TableCell>
                          )}
                          {showColumns.MilesAtRemoval && (
                            <TableCell className="table-content-text">
                              {row.MilesAtRemoval}
                            </TableCell>
                          )}
                          {showColumns.VehId && (
                            <TableCell className="table-content-text">
                              {row.VehId}
                            </TableCell>
                          )}
                          {showColumns.NoOfRetread && (
                            <TableCell className="table-content-text">
                              {row.NoOfRetread}
                            </TableCell>
                          )}
                          {showColumns.DateSent && (
                            <TableCell className="table-content-text">
                              {row.DateSent}
                            </TableCell>
                          )}
                          {showColumns.RemovalReason && (
                            <TableCell className="table-content-text">
                              {row.RemovalReason}
                            </TableCell>
                          )}
                          {showColumns.Comments && (
                            <TableCell className="table-content-text">
                              {row.Comments}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {((page === lastPage - 1 &&
                    tiresSentToRetreadTableData.rowsData.length %
                      rowsPerPage ===
                      0) ||
                    (page === lastPage &&
                      tiresSentToRetreadTableData.rowsData.length %
                        rowsPerPage !==
                        0) ||
                    rowsPerPage ===
                      tiresSentToRetreadTableData.rowsData.length) && (
                    <TableRow className="total-avg-row">
                      {showColumns.BrandNumber && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.SerialNo && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.ManufacturerName && (
                        <TableCell className="total-avg-text">
                          {globalizationMapping.REPORTCOMMONWORDS.TotalAverage}
                        </TableCell>
                      )}
                      {showColumns.SizeName && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.TypeName && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.Compound && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.RatingName && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.TreadDepth && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.PercentageWorn && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.HoursAtRemoval && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.MilesAtRemoval && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.VehId && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.NoOfRetread && (
                        <TableCell className="total-avg-text">
                          {tiresSentToRetreadTableData.avgValues.NoOfRetread}
                        </TableCell>
                      )}
                      {showColumns.DateSent && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.RemovalReason && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.Comments && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              )}
              {tiresSentToRetreadTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={tiresSentToRetreadTableData.headCells.length}
                      align="centre"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={tiresSentToRetreadTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default TiresSentToRetreadTable;
