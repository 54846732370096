import Globalization from "../../../globalization/index";

class ByManufacturerDataSetter {
  constructor() {
    this.byManufacturerData = [];
    this.globalizationMapping = new Globalization();
    this.data = {};
    this.headCells = [];
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "TotalMiles" || headCell.value === "AverageMiles" || headCell.value === "TotalKm" || headCell.value === "AverageKm") selectedColShow[headCell.value] = false;
      else selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }
  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.TireCount.toString().includes(searchText) ||
        item.TotalHours.toString().includes(searchText) ||
        item.AverageHours.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);

    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  avgValues = (byManufacturerData) => {
    let TotalTires = 0,
      HoursSum = 0,
      AvgHoursSum = 0,
      milesSum = 0,
      averageMilesSum = 0,
      averageKmSum = 0;
    byManufacturerData.forEach((item) => {
      TotalTires += item.TireCount;
      HoursSum += item.AverageHours;
      AvgHoursSum += item.TotalHours;
      milesSum += item.TotalMiles;
      averageMilesSum += item.AverageMiles;
      averageKmSum += item.AverageKm;
    });

    let TotalTiresSum = TotalTires;
    let HoursAvg = Math.round(HoursSum / byManufacturerData.length);
    let AvgHoursAvg = Math.round(AvgHoursSum / byManufacturerData.length);
    let milesSumAvg = Math.round(milesSum / byManufacturerData.length);
    let averageMiles = Math.round(averageMilesSum / byManufacturerData.length);
    let averageKm = Math.round(averageKmSum / byManufacturerData.length);

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      CompoundName: "",
      TotalHours: AvgHoursAvg,
      TotalMiles: milesSumAvg,
      TireCount: TotalTiresSum,
      AverageHours: HoursAvg,
      AverageMiles: averageMiles,
      AverageKm: averageKm,
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.byManufacturerData.push({
        Key: index,
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        TotalHours: item.totalHours ? parseInt(item.totalHours) : 0,
        TotalMiles: item.totalMiles ? parseInt(item.totalMiles) : 0,
        TotalKm: item.totalKm ? parseInt(item.totalKm) : 0,
        TireCount: item.tireCount ? item.tireCount : 0,
        AverageHours: item.averageHours ? item.averageHours : 0,
        AverageMiles: item.averageMiles ? item.averageMiles : 0,
        AverageKm: item.averageKm ? item.averageKm : 0,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.byManufacturerData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.byManufacturerData),
      selectedColShow: selectedColShow
    };
  }
}

export default ByManufacturerDataSetter;
