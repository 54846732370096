import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import "../common-modal/CommonPopup.css";
import CommonUtils from "../../../services/utils";
import Globalization from "../../../globalization";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportXls(props) {
  const commonUtils = new CommonUtils();
  const globalizationMapping = new Globalization();
  const [rowsData, setRowsData] = useState(props.exportXlsx?.rowsData);
  const [headCells, setHeadCells] = useState(props.exportXlsx?.headCells);
  const [isReady, setIsReady] = useState(props.exportXlsx?.isReady);
  useEffect(() => {
    const headCellArr = [];
    let rowData = [];
    props.exportXlsx.headCells.forEach((headCell, _index) => {
      headCellArr.push(headCell);
    });
    setHeadCells(headCellArr);
    props.exportXlsx.rowsData.forEach((row, _index) => {
      let rowJson = {};
      Object.keys(row).forEach((key, _id) => {
        rowJson[key] = row[key];
        return true;
      });
      rowData.push(rowJson);
    });
    setRowsData(rowData);
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        { rowsData: rowsData, headCells: headCells },
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setRowsData(data.rowsData);
      if (props.exportXlsx && props.exportXlsx.isReady)
        setIsReady(props.exportXlsx.isReady);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const isNotReadyDisableClass = () => {
    if (!isReady) {
      return "header-change-modal-confirm-btn header-change-modal-confirm-btn-disabled";
    } else {
      return "header-change-modal-confirm-btn";
    }
  }

  const generateExcelSheet = () => {
    return (
      <ExcelSheet data={rowsData} headers={headCells} name="Exported Xlsx">
            {headCells.map((key) => (
              <ExcelColumn
                label={key.label}
                value={key.key}
                key={key.keyIndex}
              />
            ))}
          </ExcelSheet>
    )
  }

  return (
    <>
      {props.multireport ? (
        <ExcelFile
          filename={`${props.exportXlsx.reportName}`}
          element={
            <button
              onClick={() => setIsReady(false)}
              disabled={!isReady}
              className={isNotReadyDisableClass()}
            >
              {globalizationMapping.MULTIREPORTS.DownloadXLSX}
            </button>
          }
        >
          {generateExcelSheet()}
        </ExcelFile>
      ) : (
        <ExcelFile
          filename={`${props.exportXlsx.reportName}`}
          element={<div className="export-xlsx-button">XLS</div>}
        >
          {generateExcelSheet()}
        </ExcelFile>
      )}
    </>
  );
}
