import React, { useEffect, useState, useContext } from "react";
import "./profile-details.css";
import Breadcrumbs from "../../../../common-components/breadcrumbs/breadcrumbs";
import HeaderApi from "../../../../common-components/header/services/header-apis";
import { TextField, Select, FormHelperText, Grid } from "@material-ui/core";
import CountryCode from "../../../../globalization/country-code";
import { StateContext } from "../../../../services/StateProvider";
import Globalization from "../../../../globalization";
import FooterNavigation from "../../../../common-components/footer/footer";
import ExpandMoreStyledIcon from "../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import CommonUtils from "../../../../services/utils";

const MyProfile = () => {
  const [userDetails, setUserDetails] = useState({});
  const [globalAccountState, globalAccountDispatch] = useContext(StateContext);
  const globalizationMapping = new Globalization();
  const commonUtils = new CommonUtils();
  const validationTexts = globalizationMapping.FORMS.VALIDATIONTEXTS;
  const labelsTexts = globalizationMapping.PROFILE;

  const removeDuplicates =(arr) => {
    return arr.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.countryCode === value.countryCode
    )))
  }

  const nonDuplicateCountryCode = removeDuplicates(CountryCode);
  const countriesList =
    nonDuplicateCountryCode.length > 0 &&
    nonDuplicateCountryCode.map((item) => {
      if (userDetails.countryCode === item.countryCode) {
        return (
          <option key={item.countryCode} value={item.countryCode} selected>
            {item.countryCode}
          </option>
        );
      } else {
        return (
          <option key={item.countryCode} value={item.countryCode}>
            {item.countryCode}
          </option>
        );
      }
    });

  const headerApi = new HeaderApi();

  useEffect(() => {
    headerApi.getUserProfile().then(data => {
      if (data.model) {
        if (data.model.countryCode === null) {
          data.model.countryCode = "";
        }
        if (data.model.emailAddress === null) {
          data.model.emailAddress = "";
        }
        setUserDetails(data.model);
        setTempUserDetails(data.model);
        globalAccountStateUpdate(data.model);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const globalAccountStateUpdate = userProfile => {
    const initialAccountState = {
      account: globalAccountState.accountInfo.account,
      location: globalAccountState.accountInfo.location,
      userSetting: globalAccountState.accountInfo.userSetting,
      userProfile,
    };
    localStorage.setItem("accountInfo", JSON.stringify(initialAccountState));
    localStorage.setItem("accountInfouserMeasurementSystemValue", JSON.stringify(initialAccountState.userSetting.userMeasurementSystemValue));
    globalAccountDispatch({
      accountInfo: initialAccountState,
      type: "UPDATE",
    });
  };

  const [tempUserDetails, setTempUserDetails] = useState(userDetails);
  const [saveStatus, setSaveStatus] = useState(true);
  const [successStatus, setSuccessStatus] = useState(false);
  const [formHelperTexts, setFormHelperTexts] = useState({});
  const [isFirstNameEdited, setIsFirstNameEdited] = useState(false);
  const [isMiddleNameEdited, setIsMiddleNameEdited] = useState(false);
  const [isLastNameEdited, setIsLastNameEdited] = useState(false);
  const [isPhoneNumberEdited, setIsPhoneNumberEdited] = useState(false);
  const [isEmailAddressEdited, setIsEmailAddressEdited] = useState(false);

  const breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME,
    },
    {
      path: "",
      name: labelsTexts.MyProfile,
    },
  ];


  const resetTextFieldEditState = () => {
    setIsFirstNameEdited(false);
    setIsMiddleNameEdited(false);
    setIsLastNameEdited(false);
    setIsPhoneNumberEdited(false);
    setIsEmailAddressEdited(false);
  }
  const validateForm = () => {
    let isValid = true;
    const formHelpers = {};
    Object.keys(tempUserDetails).forEach(key => {
      if (tempUserDetails[key] === "") {
        isValid = false;
        formHelpers[key] = validationTexts.MISSINGFIELD;
      }
      if (key === 'phoneNumber' && tempUserDetails[key] === "" && !tempUserDetails[key].match(/\d{3}[ -]?\d{3}[ -]?\d{4}/)) {
        isValid = false;
        formHelpers[key] = validationTexts.INVALIDFIELD;
      }
      if (key === "emailAddress" && !tempUserDetails[key].match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        formHelpers[key] = "Email Address is invalid. Must have @ and .com or the like";
        isValid = false;
      }
    });

    setFormHelperTexts(formHelpers);
    return isValid;
  };

  const onClickSaveStatus = (event, value) => {
    switch (event) {
      case "save":
        if (validateForm()) {
          let date = new Date();
          date = date.toISOString();
          setUserDetails({ ...tempUserDetails, updatedDate: date });
          setTempUserDetails({ ...tempUserDetails, updatedDate: date });
          headerApi.updateUser(tempUserDetails).then(data => {
            if (data.message) {
              setSuccessStatus(true);
              setUserDetails(tempUserDetails);
              globalAccountStateUpdate(tempUserDetails);
            }
          });
          setSaveStatus(value);
          resetTextFieldEditState();
        }
        break;
      case "edit":
        setSuccessStatus(false);
        setSaveStatus(value);
        break;
      default:
        break;
    }

  };

  const changeUserDetails = (key, value) => {
    switch (key) {
      case "firstName":
        setTempUserDetails({ ...tempUserDetails, firstName: value });
        setIsFirstNameEdited(true);
        break;
      case "middleName":
        setTempUserDetails({ ...tempUserDetails, middleName: value });
        setIsMiddleNameEdited(true);
        break;
      case "lastName":
        setTempUserDetails({ ...tempUserDetails, lastName: value });
        setIsLastNameEdited(true);
        break;
      case "phoneNumber":
        setTempUserDetails({ ...tempUserDetails, phoneNumber: value });
        setIsPhoneNumberEdited(true);
        break;
      case "countryCode":
        setTempUserDetails({ ...tempUserDetails, countryCode: value });
        break;
      case "emailAddress":
        setTempUserDetails({ ...tempUserDetails, emailAddress: value });
        setIsEmailAddressEdited(true);
        break;
      default:
        break;
    }
  };
  const onCancel = value => {
    setTempUserDetails(userDetails);
    setSaveStatus(value);
    setSuccessStatus(false);
    resetTextFieldEditState();
  };
  return (
    <div className="my-profile-main-container">
      <div className="my-profile-upper-deck">
        <div className="background-map-image-profile-details">
          <div className="dashboard-header">
            <div>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <div className="scrap-tires-title">{labelsTexts.MyProfile}</div>
            </div>
          </div>
          <div className="profile-container">
            <div className="profile-inner-container">
              {successStatus && (
                <div className="success-message bottom-margin">
                  <span className="success-span-message">
                    <label>{labelsTexts.SuccseeMsg}</label>
                  </span>
                </div>
              )}
              <div className="sub-header">
                <span>{labelsTexts.PersonalDetails}</span>
              </div>
              <div className="edit-view-message bottom-margin">
                <span>{labelsTexts.InfoMsg}</span>
              </div>
              <div>
                <table className="user-profile-table">
                  <tbody>
                    <tr>
                      <td>
                        <p className="table-sub-header">
                          {labelsTexts.FirstName}{!saveStatus && <>&nbsp;<span className="required-mark">*</span></>}</p>
                        <>
                          <TextField
                            className={!saveStatus ? "sub-edit-textbox-myprofile" : "sub-edit-textbox-myprofile  disabled-input"}
                            defaultValue={userDetails.firstName}
                            variant="outlined"
                            onChange={e => {
                              changeUserDetails("firstName", commonUtils.removeLeadingSpaces(e.target.value));
                            }}
                            value={tempUserDetails.firstName}
                            disabled={saveStatus}
                            inputProps={{ maxLength: 150 }}
                          />
                          {(tempUserDetails.firstName === "" && isFirstNameEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                          {(isFirstNameEdited ? "" : <FormHelperText>{formHelperTexts.firstName}</FormHelperText>)} 
                        </>
                      </td>
                      <td>
                        <p className="table-sub-header">
                          {labelsTexts.MiddleName}{!saveStatus && <>&nbsp;<span className="required-mark">*</span></>}</p>
                       <>
                          <TextField
                            className={!saveStatus ? "sub-edit-textbox-myprofile" : "sub-edit-textbox-myprofile  disabled-input"}
                            defaultValue={userDetails.middleName}
                            variant="outlined"
                            onChange={e => {
                              changeUserDetails("middleName", commonUtils.removeLeadingSpaces(e.target.value));
                            }}
                            value={tempUserDetails.middleName}
                            disabled={saveStatus}
                            inputProps={{ maxLength: 50 }}
                          />
                            {(tempUserDetails.middleName === "" && isMiddleNameEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                            {(isMiddleNameEdited ? "" : <FormHelperText>{formHelperTexts.middleName}</FormHelperText>)} 
                          </>
                        
                      </td>
                      <td>
                        <p className="table-sub-header">
                          {labelsTexts.LastName}{!saveStatus && <>&nbsp;<span className="required-mark">*</span></>}</p>
                        <>
                          <TextField
                            className={!saveStatus ? "sub-edit-textbox-myprofile" : "sub-edit-textbox-myprofile  disabled-input"}
                            defaultValue={userDetails.lastName}
                            variant="outlined"
                            onChange={e => {
                              changeUserDetails("lastName", commonUtils.removeLeadingSpaces(e.target.value));
                            }}
                            value={tempUserDetails.lastName}
                            disabled={saveStatus}
                            inputProps={{ maxLength: 100 }}
                          />
                            {(tempUserDetails.lastName === "" && isLastNameEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                            {(isLastNameEdited ? "" : <FormHelperText>{formHelperTexts.lastName}</FormHelperText>)} 
                          </>
                        
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="table-sub-header">{labelsTexts.PhoneNumber}{!saveStatus && <>&nbsp;<span className="required-mark">*</span></>}</p>
                          <div>
                            <Grid container spacing={1}>
                              <Grid item xs={3}>
                                <Select
                                  native
                                  className={!saveStatus ? "sub-edit-textbox-myprofile isd-code-dropdown" : "sub-edit-textbox-myprofile isd-code-dropdown disabled-input"}
                                  variant="outlined"
                                  value={tempUserDetails.countryCode}
                                  IconComponent={ExpandMoreStyledIcon}
                                  onChange={e => {
                                    changeUserDetails(
                                      "countryCode",
                                      e.target.value
                                    );
                                  }}
                                  disabled={saveStatus}
                                >
                                  {countriesList}
                                </Select>
                              </Grid>
                              <Grid item xs={9}>
                                &nbsp;
                                <TextField
                                  className={!saveStatus ? "sub-edit-textbox-myprofile" : "sub-edit-textbox-myprofile  disabled-input"}
                                  defaultValue={userDetails.phoneNumber}
                                  variant="outlined"
                                  onChange={e => {changeUserDetails("phoneNumber",e.target.value);}}
                                  value={tempUserDetails.phoneNumber}
                                  disabled={saveStatus}
                                  inputProps={{ maxLength: 12 }}
                                />
                              </Grid>
                            </Grid>
                            {(tempUserDetails.phoneNumber !== "" && !tempUserDetails.phoneNumber?.match(/\d{3}[ -]?\d{3}[ -]?\d{4}/) && isPhoneNumberEdited) ? <FormHelperText>Phone number is invalid. Must be at least 10 digits</FormHelperText> : ''}
                            {(tempUserDetails.phoneNumber === "" && isPhoneNumberEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                            {(isPhoneNumberEdited ? "" : <FormHelperText>{formHelperTexts.phoneNumber}</FormHelperText>)} 
                          </div>
                      </td>
                      <td>
                        <p className="table-sub-header">
                          {labelsTexts.Email}{!saveStatus && <>&nbsp;<span className="required-mark">*</span></>}</p>
                        <>
                          <TextField
                            className={!saveStatus ? "sub-edit-textbox-myprofile" : "sub-edit-textbox-myprofile  disabled-input"}
                            defaultValue={userDetails.emailAddress}
                            variant="outlined"
                            onChange={e => {
                              changeUserDetails("emailAddress", commonUtils.removeLeadingSpaces(e.target.value));
                            }}
                            value={tempUserDetails.emailAddress}
                            disabled={saveStatus}
                            inputProps={{ maxLength: 50 }}
                          />
                            {(tempUserDetails.emailAddress !== "" && !tempUserDetails.emailAddress?.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/) && isEmailAddressEdited) ? <FormHelperText>Email Address is invalid. Must have @ and .com or the like</FormHelperText> : ''}
                            {(tempUserDetails.emailAddress === "" && isEmailAddressEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                            {(isEmailAddressEdited ? "" : <FormHelperText>{formHelperTexts.emailAddress}</FormHelperText>)} 
                          </>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Grid container justify="flex-end">
                <Grid item>
                  {saveStatus && (
                       <button
                         className="edit-save-button top-margin"
                         onClick={() => onClickSaveStatus("edit", false)}
                       >
                         {labelsTexts.Edit}
                       </button>
                     )}
                     {!saveStatus && (
                       <div className="top-margin">
                         <button
                           className="cancel-button"
                           onClick={() => onCancel(true)}
                         >
                           {labelsTexts.Cancel}
                         </button>
                         &nbsp;&nbsp;
                         <button
                           className="edit-save-button"
                           onClick={() => onClickSaveStatus("save", true)}
                         >
                           {labelsTexts.Save}
                         </button>                       
                        </div>
                     )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <div className="my-profile-footer-container" style={{marginTop:window.innerHeight - 112}}>
        <FooterNavigation />
      </div>
    </div>
  );
};
export default MyProfile;
