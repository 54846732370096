export const SERVER = {
    TOKEN: localStorage.getItem('token'),
    BASE_URL_TEST: 'https://em-track-test.azurewebsites.net/api/',
    REDIRECT_URL_TEST: 'https://emtrackotr-test.azurewebsites.net/',
    BASE_URL_PROD: 'https://emtrackotrapi.azurewebsites.net/api/',
    REDIRECT_URL_PROD: 'https://emtrackotr.azurewebsites.net/',
    REDIRECT_URL_LOCAL: 'https://emtrackotr-test.azurewebsites.net/',
    BASE_URL_STAGE: 'https://emtrackotrapi-staging.azurewebsites.net/api/',
    REDIRECT_URL_STAGE: 'https://emtrackotr-staging.azurewebsites.net/',

    SERVICES: {
        getLocation: 'Location/GetLocationListByUser/',
        getParentAccount: 'ParentAccount/GetAccountList/',
        getReportDashboardData: 'Report/GetReportDashboardData/',
        getTireReportDashboard: 'Report/GetTireReportDashboard/',
        getReport: 'Report/',
        getWheelPosition: 'Tire/TireByWheelPosition ',
        getUserReport: 'Inspection/GetTireInspectionReport1',
        getGraph: 'Graph/',
        getTireSerialNo: 'Report/GetTireList/',
        getUserProfile: 'UserProfile',
        getBroadCastDetails: 'BroadcastMessage/GetMessages',
        createUser: 'UserProfile/Create/',
        updateUser: 'UserProfile/Update/',
        sendEmailReport: 'Report/SendReportEmail/',
        putBroadCastAPI: 'BroadcastMessage/CreateMessages',
        surveyTemplateMasterConfig: 'MasterData/GetMasterDataWeb/',
        getUserPreferences: 'UserPreferences/',
        getLocationById: 'Location/GetLocationById/',
        getVehicleByLocation: 'Vehicle/GetVehicleByLocation/',
        getAvailablePosForRim: 'Vehicle/GetAvailablePosForRims/',
        getDistinctTireSize: 'Tire/GetDistinctTireSize',
        getDistinctTireSizeNS: 'Tire/TireSizeDropdownByAccountId',
        isBrandNumberUniqueInTire: "Tire/IsBrandNumberUniqueInTire",
        FilterAccount: "ParentAccount/FilterAccount",
        getLatestVehicleHistoryData: "Vehicle/UpdateLastVehicleHistoryRecord",
        getTiresByAccountList: "Tire/GetTiresByAccountList",
        getUserIdList: 'Inspection/GetUserListDropdownTireInspectionReport',
        manageVehicleGetDetailsByAccount: 'ParentAccount/GetDetailsByAccount/',
        getParentAccountDetails: 'ParentAccount/GetAccountById/',
        updateLocation: 'Location/Update/',
        getCountryList: 'MasterData/GetCountryList/',
        updateParentAccount: 'ParentAccount/Update/',
        getMasterDataWeb: 'MasterData/GetMasterDataWeb/',
        getTreadDepthDefaultValue: 'Tire/GetTireTreadSuggestionByManufacturer',
        editCreateTire: 'Tire/',
        getAchievedHoursReport: 'Report/AchievedHoursReport',
        createMasterData: 'MasterData/',
        createImages: 'Tire/CreateImages/',
        getParentAccountListByCountry: 'ParentAccount/GetAccountListByCountry/',
        manageTiresByAccount: 'Tire/GetTiresByAccount/',
        userActions: 'Home/GetUserActions/',
        getTireById: 'Tire/GetById/',
        createVehicle: 'Vehicle/Create/',
        createVehicleImages: 'Vehicle/CreateImages/',
        getVehicleDetails: 'Vehicle/GetDetailsById/',
        updateVehicle: 'Vehicle/Update/',
        createParentAccount: 'ParentAccount/Create/',
        createLocation: 'Location/Create/',
        updateUserPref: 'UserPreferences/Update/',
        updateMasterData: 'MasterData/',
        uploadImg: 'MasterData/CreateImages/',
        sendLogoRequestEmail: 'MasterData/SendLogoRequestEmail/',
        getDetailsById: 'Vehicle/GetDetailsById/',
        updateHours: 'Inspection/UpdateHoursForVehicle/',
        getTireByAccount: 'Tire/GetTiresByAccount/',
        putInstallTire: 'Inspection/InstallTire/',
        getInspectionRecordForVehicle: 'Inspection/GetInspectionRecordForVehicle/',
        getInstalledItemsOnVehiclePerPos:'Inspection/GetInstalledItemsOnVehiclePerPos/',
        getInspectionSummaryForVehicle: 'Inspection/GetInspectionSummaryForVehicle/',
        getManageAccountList: 'ParentAccount/GetManageAccountList/',
        getManageLocationList: 'Location/GetManageLocationList/',
        getLocationListByAccount: 'Location/GetLocationList/',
        transferTire: 'Tire/TransferTire/',
        transferTireWithParent: 'Tire/TransferParentOfTire',
        vehicleTransfer: "Vehicle/TransferVehicleParent",
        vehicleTransferWithTire: "Vehicle/TransferVehicleParent",
        transferVehicle: 'Vehicle/TransferVehicle/',
        deleteTire: 'Tire/DeleteTire/',
        updateLastAccessed: 'UserPreferences/UpdateLastAccessed',
        deleteVehicle: 'Vehicle/Delete/',
        getMasterDataRequests: 'MasterData/GetMasterDataRequests/',
        logoApproval: '/UserPreferences/GetUserPreferencesById/',
        addVehicleManufacturers: '/MasterData/AddVehicleManufacturer/',
        addVehicleType: '/MasterData/AddVehicleType/',
        addVehicleModel: '/MasterData/AddVehicleModel/',
        addAxleConfig: '/MasterData/AddAxleConfig/',
        addRemovalReason: '/MasterData/AddRemovalReason/',
        addTireManufacturers: '/MasterData/AddTireManufacturer/',
        addTireSize: '/MasterData/AddTireSize/',
        addRimSize: 'Rim/AddRimSize',
        addRimType: 'Rim/AddRimType',
        addRimValve: 'Rim/AddRimValve',
        addTireTreadDepth: 'Tire/AddTreadDepthSuggestion',
        addTireType: '/MasterData/AddTireType/',
        addTireCompound: '/MasterData/AddTireCompound/',
        addRimManufacturer: '/MasterData/AddRimManufacturer',
        deleteMaster: '/MasterData/DeleteMasterData?',
        deleteTreadDepth: 'Tire/DeleteTireTreadSuggestionById/',
        deleteBroadcastMessage: 'BroadcastMessage/DeleteMessage/',
        deleteRimSize: 'Rim/DeleteRimSize',
        deleteRimType: 'Rim/DeleteRimType',
        deleteRimValve: 'Rim/deleteRimValve',
        sendTireInspectionData: 'Inspection/InspectTire/',
        createTireInspectionImages: 'Inspection/CreateImages',
        sendInspectionData: 'Inspection',
        removeInstalledTire: 'Inspection/RemoveTire/',
        removeFromVehicle: 'Inspection/RemoveFromVehicle/',
        createHomeActivity: 'Home/',
        createRim: "Rim/",
        GetRimsByAccount: "Rim/GetRimsByAccount/",
        getVacantWheelPositions: 'Vehicle/GetVacantWheelPosition/',
        getInspectionRecordForTire: 'Inspection/GetInspectionRecordForTire/',
        addRepairCostTireHistory: 'Tire/AddRepairCostTireHistory/',
        saveVehicleFootPrintDetails: 'Inspection/SaveVehicleFootPrintDetails/',
        getById: "Rim/GetById/",
        vehicleFootPrint: 'Vehicle/CreateFootprintImage/',
        vehicleTireSize: 'Vehicle/UpdateVehicleTireSize/',
        deleteAccount: 'ParentAccount/Delete',
        deleteRim: "Rim/Delete/",
        updateTireHistory: "Tire/UpdateTireHistory/",
        getInspectionRecordByEvent: "Inspection/GetInspectionRecordByEvent/",
        updateTireInspectionRecord: "Inspection/UpdateInspection",
        deleteLocation: "Location/Delete",
        deleteEvent: "Tire/Delete/",
        getInstalledTires: "Inspection/GetInspectionRecordForVehicle/",
        getNewVsScrapReport: "Report/GetNewVsScrapReport",
        getPSAlertByVehicleId:"Vehicle/GetPSAlertByVehicleId/",
    }
};
