import { Backdrop, Button, makeStyles, Modal } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import Globalization from '../../../../../../globalization';
import ManageParentAccountListApis from '../../../../services/ManageParentAccountListApis';
import './DeleteAccount.css';

const useStyles = makeStyles({
    proceed: {
        "&:hover": {
            opacity: 1,
        },
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: 'Barlow',
        opacity: .8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none"
        },
    cancel: {
          "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none",
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: .8,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none",
        marginRight:5
    },
});

const DeleteAccountModal = (props) => {
    const history = useHistory();
    const styles = useStyles();
    const globalizationMapping = new Globalization();
    const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
    const titleTexts = globalizationMapping.FORMS.ACCOUNTS.PARENTFORM;
    const manageAccountApiServices = new ManageParentAccountListApis();

    const handleDeleteClick = () => {
        let successStatus = false;
        if (props.isSelectionMultiple) {
            let accIdsArray = [];
            props.accountIds.forEach(account => {
                accIdsArray.push(account.AccountID)
            });
            let payLoad = { "accountIds": accIdsArray }
            manageAccountApiServices
                .deleteAccount(payLoad)
                .then(response => {
                    if (response.didError === false) {
                        successStatus = true;
                    } else {
                        successStatus = false;
                    }
                    history.push({
                        pathname: '/home',
                        state: {
                            updatedSection: "deleteaccount",
                            updateStatus: successStatus,
                            successMessage: notificationTexts.MULTIPLEACCOUNTSDELETED,
                            successMessageDesc: notificationTexts.MULTIPLEDELETEDACCOUNTSSUCCESS.replace("#1#1", props.accountName)
                        }
                    });
                });

        } else {
            let payLoad = { "accountIds": [props.accountId] }
            manageAccountApiServices
                .deleteAccount(payLoad)
                .then(response => {
                    if (response.didError === false) {
                        successStatus = true;
                    } else {
                        successStatus = false;
                    }
                    history.push({
                        pathname: '/home',
                        state: {
                            updatedSection: "deleteaccount",
                            updateStatus: successStatus,
                            successMessage: notificationTexts.ACCOUNTDELETED,
                            successMessageDesc: notificationTexts.DELETEACCOUNTSUCCESS.replace("#1#1", props.accountName)
                        }
                    });
                });
        }
    }

    return (
        <div className="container">
            <Modal
                open={true}
                onClose={props.closeDeleteModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 300,
                }}
                disableScrollLock= {true}
            >
                <div className={`delete-vehicle-modal-container center-modal-vehicle ${props.isSelectionMultiple && props.accountIds.length > 50 ? 'delete-multiple-selected' : ''}`}>
                    <div className="delete-vehicle-title-container">
                        <div className="delete-vehicle-title">
                            {titleTexts.DELETEACCOUNT}
                        </div>
                        <div
                            className="close-icon-container"
                            onClick={props.closeDeleteModal}>
                            <img
                                src={require("../../../../../../assets/close-icon.svg")}
                                className="modal-close-icon"
                                alt="close-icon"
                            />
                        </div>
                    </div>
                    <div className="delete-modal-description-vehicle">
                        <p className="vehicle-ids">{!props.isSelectionMultiple
                            ? notificationTexts.DELETACCOUNTDESC.replace("#1#1", props.accountName)
                            : notificationTexts.DELETACCOUNTDESC.replace("#1#1", Object.values(props.accountIds).map(value => (value.AccountName))).replaceAll(',', ', ')}</p>
                    </div>
                    <div className="delete-modal-description-vehicle" style={{ marginTop: '-25px' }}><p>{titleTexts.WISHPROCEED}</p></div>
                    <div className="modal-action-buttons">
                        <Button
                            className={styles.cancel}
                            variant="contained"
                            disableElevation={true}
                            onClick={
                                props.closeDeleteModal
                            }
                        >
                            {globalizationMapping.FORMS.CANCEL}
                        </Button>
                        <Button
                            className={styles.proceed}
                            variant="contained"
                            disableElevation={true}
                            color='primary'
                            onClick={
                                () => { handleDeleteClick() }
                            }
                        >
                            {globalizationMapping.FORMS.PROCEED}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DeleteAccountModal