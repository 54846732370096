/* eslint-disable no-unused-vars */
import "./remove-tire.css";
import { Button, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import Spinner from "../../../common-components/spinner/spinner";
import Globalization from "../../../globalization";
import { StateContext } from "../../../services/StateProvider";
import TireRemovalForm from "./remove-tire-form";
import TireRemovalDetailsGrid from "./tire-removal-details";
import RemoveTireApis from "../services/remove-tire-apis";
import InspectTireApis from "../../inspect-tire/services/inspect-tire-apis";
import HeaderApi from "../../../common-components/header/services/header-apis";
import CancelConfirmationPopup from "../../cancel-confirmatipn-popup/cancel-popup";
import AlertPopup from "../../../common-components/alert-pop-up/alert-popup";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles({
    width: "100%",
    next: {
        "&:hover": {
            opacity: 1,
        },
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: "Barlow",
        opacity: 0.8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none",
    },
    cancel: {
        "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none",
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: 1,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none",
    },
    expandIcon: {
        color: "#0055A4",
        cursor: "pointer",
        top: "calc(50% - 12px)",
        position: "absolute",
        pointerEvents: "none",
        right: "7px",
    },
});

const RemoveTire = () => {
    const locationObj = useLocation();
    const history = useHistory();
    const styling = useStyles();
    const [globalAccountState] = useContext(StateContext);
    const globalizationMapping = new Globalization();
    const titleTexts = globalizationMapping.INSPECTNREMOVETIRE;
    const tireFormCaptions = globalizationMapping.FORMS;
    const validationTexts = globalizationMapping.FORMS.VALIDATIONTEXTS;
    const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
    let vehicleNotUpdated;

    const removeTireApi = new RemoveTireApis();
    const inspectTireApi = new InspectTireApis();
    const headerApiObj = new HeaderApi();

    const myBreadcrumbs = [
        {
            path: "home",
            name: globalizationMapping.HEADER.HOME,
        },
        {
            path: "",
            name: globalizationMapping.HEADER.INSPECTVEHICLE,
        },
        {
            path: "",
            name: titleTexts.REMOVETIRE,
        },
    ];

    const [prevPath, setPrevPath] = useState(
        locationObj.state && locationObj.state.fromPath
            ? locationObj.state.fromPath
            : "/manage-tire"
    );
    const [historyState, setHistoryState] = useState();

    const [homeObj, setHomeObj] = useState({});

    const [locationDetails, setLocationDetails] = useState({});
    const [masterData, setMasterData] = useState({});

    const [casingConditions, setCasingConditions] = useState([]);
    const [wearConditions, setWearConditions] = useState([]);
    const [accLocationDetails, setAccLocationDetails] = useState("");
    const [vehicleNum, setVehicleNum] = useState("");
    const [tireDetails, setTireDetails] = useState({});
    const [tireRemovalDetails, setTireRemovalDetails] = useState({});
    const [isFirstLoad, setFirstLoad] = useState(false);

    const [imgsrc, setImgSrc] = useState([]);
    const [tireImages, setTireImages] = useState([]);

    const [isRemovalReasonNew, setRemovalReasonNew] = useState(false);
    const [removalMasterData, setRemovalMasterData] = useState([]);
    const [isCancelModalOpen, setCancelModalOpen] = useState(false);
    const [unitPreferences, setUnitPreferences] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(true);
    const [submitFlag, setSubmitFlag] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);

    const initPath = (id, dispositionId) => {
        if (locationObj.state && locationObj.state.fromPath) {
            setPrevPath(locationObj.state.fromPath);
            if (locationObj.state.fromPath === "/manage-tire") {
                setHistoryState({ dispositionId: dispositionId });
            } else {
                if (locationObj.state.fromPath === "/view-tire") {
                    setHistoryState({ tireId: id });
                }
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setShowSpinner(true);

        setUnitPreferences({
            pressure: globalAccountState.accountInfo.userSetting.userPressureUnit,
            treadDepth:
                globalAccountState.accountInfo.userSetting.userMeasurementSystemValue,
        });

        if (locationObj.state) {
            vehicleNotUpdated = locationObj.state.vehicleNotUpdated;
            setAccLocationDetails(locationObj.state.accountLocationString);
            setLocationDetails(locationObj.state.locationDetailsObj);
            setTireRemovalDetails({
                ...locationObj.state.inspectionData,
                dispositionId: 8,
            });
            setTireDetails({ ...locationObj.state.tireData });
            setVehicleNum(locationObj.state.vehicleNo);
            setMasterData(locationObj.state.masterData);
            setHomeObj(locationObj.state.homeObjData);
            setAllowSubmit(!vehicleNotUpdated);
            setSubmitFlag(false);

            const casingListArr = locationObj.state.masterData.casingCondition;
            const wearListArr = locationObj.state.masterData.wearConditions;
            casingListArr.sort(sortFieldEntries("casingConditionName"));
            wearListArr.sort(sortFieldEntries("wearConditionName"));
            setCasingConditions(casingListArr);
            setWearConditions(wearListArr);
            initPath(
                locationObj.state.tireData.tireId,
                locationObj.state.tireData.dispositionId
            );
            setFirstLoad(true);
        }

        setTimeout(() => {
            setShowSpinner(false);
        }, 2500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalAccountState]);

    const sortFieldEntries = (property) => {
        return (a, b) => {
            if (a[property] > b[property]) {
                return 1;
            } else if (a[property] < b[property]) {
                return -1;
            } else {
                return 0;
            }
        };
    };

    const handleCancel = () => {
        setCancelModalOpen(true);
    };

    const handleFieldChange = (field, value) => {
        switch (field) {
            case "inspdate":
                if (value !== null) {
                    let date = value;
                    date = `${date.getFullYear()}-${date.getMonth() + 1
                        }-${date.getDate()}`;
                    setTireRemovalDetails({
                        ...tireRemovalDetails,
                        inspectionDate: date,
                    });
                    setHomeObj({ ...homeObj, actionDate: date });
                } else {
                    setTireRemovalDetails({ ...tireRemovalDetails, inspectionDate: "" });
                }
                break;
            case "removalreason":
                setTireRemovalDetails({
                    ...tireRemovalDetails,
                    removalReasonId: value,
                });
                break;
            case "disposition":
                setTireRemovalDetails({ ...tireRemovalDetails, dispositionId: value });
                break;
            case "rimdisposition":
                setTireRemovalDetails({
                    ...tireRemovalDetails,
                    rimDispositionId: value,
                });
                break;
            case "outtread":
                if (!isNaN(value)) {
                    setTireRemovalDetails({ ...tireRemovalDetails, outsideTread: value });
                } else {
                    setTireRemovalDetails({ ...tireRemovalDetails, insideTread: null });
                }
                break;
            case "intread":
                if (!isNaN(value)) {
                    setTireRemovalDetails({ ...tireRemovalDetails, insideTread: value });
                } else {
                    setTireRemovalDetails({ ...tireRemovalDetails, insideTread: null });
                }
                break;
            case "midtread":
                if (!isNaN(value)) {
                    setTireRemovalDetails({ ...tireRemovalDetails, middleTread: value });
                } else {
                    setTireRemovalDetails({ ...tireRemovalDetails, middleTread: null });
                }
                break;
            case "pressuretype":
                if (value) {
                    setTireRemovalDetails({ ...tireRemovalDetails, pressureType: "H" });
                } else {
                    setTireRemovalDetails({ ...tireRemovalDetails, pressureType: "C" });
                }
                break;
            case "pressure":
                if (!isNaN(value)) {
                    setTireRemovalDetails({
                        ...tireRemovalDetails,
                        currentPressure: value,
                    });
                } else {
                    setTireRemovalDetails({
                        ...tireRemovalDetails,
                        currentPressure: null,
                    });
                }
                break;
            case "casing":
                setTireRemovalDetails({
                    ...tireRemovalDetails,
                    casingConditionId: value,
                });
                break;
            case "wear":
                setTireRemovalDetails({
                    ...tireRemovalDetails,
                    wearConditionId: value,
                });
                break;
            case "comments":
                setTireRemovalDetails({ ...tireRemovalDetails, comments: value });
                break;
            case "images":
                if (tireImages.length < 5) {
                    if (value.files[0]) {
                        const reader = new FileReader();
                        reader.readAsDataURL(value.files[0]);
                        reader.onloadend = () => {
                            const imageSet = [...imgsrc];
                            imageSet.push(reader.result);
                            setImgSrc([...imageSet]);
                            const newImageSet = [...tireImages];
                            newImageSet.push(value.files[0]);
                            setTireImages(newImageSet);
                            value.value = null;
                        };
                    }
                } else {
                    setFormHelperTexts({
                        ...formHelperTexts,
                        imgHelper: "Images can only be uploaded upto 5",
                    });
                }
                break;
            default:
                break;
        }
    };

    const deleteImage = (index) => {
        const imgsArr = [...imgsrc];
        const imgfiles = [...tireImages];
        imgsArr.splice(index, 1);
        imgfiles.splice(index, 1);
        setTireImages(imgfiles);
        setImgSrc(imgsArr);
        setFormHelperTexts({ ...formHelperTexts, imgHelper: "" });
    };

    const handleRemovalChange = (field, value) => {
        if (field === "removalreason") {
            setRemovalMasterData([]);
            const idx = masterData.tireRemovalReasons.findIndex(
                (reason) => reason.reasonId === value
            );
            if (
                masterData.tireRemovalReasons[idx].reasonName.trim().toLowerCase() ===
                "other"
            ) {
                setRemovalReasonNew(true);
            } else {
                setRemovalReasonNew(false);
            }
            setTireRemovalDetails({ ...tireRemovalDetails, removalReasonId: value });
        } else {
            if (field === "otherreason") {
                let date = new Date();
                date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                setRemovalMasterData([
                    {
                        requestDate: date,
                        fieldId: 11,
                        description: value,
                        status: "New",
                    },
                ]);
            }
        }
    };

    useEffect(() => {
        if (isFirstLoad) {
            const outside =
                isNaN(parseInt(tireRemovalDetails.outsideTread, 10)) ||
                    tireRemovalDetails.outsideTread === null
                    ? 0
                    : parseInt(tireRemovalDetails.outsideTread, 10);
            const inside =
                isNaN(parseInt(tireRemovalDetails.insideTread, 10)) ||
                    tireRemovalDetails.insideTread === null
                    ? 0
                    : parseInt(tireRemovalDetails.insideTread, 10);
            const middle =
                isNaN(parseInt(tireRemovalDetails.middleTread, 10)) ||
                    tireRemovalDetails.middleTread === null
                    ? 0
                    : parseInt(tireRemovalDetails.middleTread, 10);

            if (locationDetails.noOfTreadDepthReadings === 3) {
                let result;
                if (locationDetails.treadDepthMethodId === 1) {
                    result = (outside + inside + middle) / 3;
                    if (!Number.isInteger(result)) {
                        result = result.toFixed(2);
                    }
                } else if (locationDetails.treadDepthMethodId === 2) {
                    result = Math.max(outside, inside, middle);
                } else {
                    result = Math.min(outside, inside, middle);
                }
                setTireRemovalDetails({
                    ...tireRemovalDetails,
                    currentTreadDepth: result,
                });
            } else if (locationDetails.noOfTreadDepthReadings === 2) {
                let result;
                if (locationDetails.treadDepthMethodId === 1) {
                    result = (outside + inside) / 2;
                    if (!Number.isInteger(result)) {
                        result = result.toFixed(2);
                    }
                } else if (locationDetails.treadDepthMethodId === 2) {
                    result = Math.max(outside, inside);
                } else {
                    result = Math.min(outside, inside);
                }
                setTireRemovalDetails({
                    ...tireRemovalDetails,
                    currentTreadDepth: result,
                });
            } else {
                setTireRemovalDetails({
                    ...tireRemovalDetails,
                    currentTreadDepth: outside,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        tireRemovalDetails.outsideTread,
        tireRemovalDetails.insideTread,
        tireRemovalDetails.middleTread,
        locationDetails,
    ]);

    //validation
    const [formHelperTexts, setFormHelperTexts] = useState({});

    const validateForm = () => {
        let isformvalid = true;
        const formErrors = {};

        Object.keys(tireRemovalDetails).forEach((key) => {
            if (key === "outsideTread" || key === "currentPressure") {
                if (tireRemovalDetails[key] < 0) {
                    isformvalid = false;
                    formErrors[key] = validationTexts.INVALIDFIELD;
                } else {
                    if (tireRemovalDetails[key] === null) {
                        isformvalid = false;
                        formErrors[key] = validationTexts.MISSINGFIELD;
                    }
                }
            }
            if (
                key === "inspectionDate" &&
                (isNaN(parseInt(tireRemovalDetails[key], 10)) ||
                    tireRemovalDetails[key] === "")
            ) {
                isformvalid = false;
                formErrors[key] = validationTexts.INVALIDFIELD;
            }
            if (key === "removalReasonId") {
                if (
                    tireRemovalDetails[key] === 0 ||
                    tireRemovalDetails[key] === undefined
                ) {
                    isformvalid = false;
                    formErrors[key] = "This field is Required";
                }
                if (tireRemovalDetails[key] === 69) {
                    if (removalMasterData.length === 0) {
                        isformvalid = false;
                        formErrors["otherreason"] = "This field is required";
                    }
                }
            }
            if (key === "dispositionId" && tireRemovalDetails[key] === 7) {
                isformvalid = false;
                formErrors[key] = validationTexts.INVALIDFIELD;
            }
            if (key === "insideTread" || key === "middleTread") {
                if (locationDetails.noOfTreadDepthReadings === 3) {
                    if (
                        tireRemovalDetails[key] < 0 ||
                        tireRemovalDetails[key] === null ||
                        tireRemovalDetails[key] === undefined
                    ) {
                        isformvalid = false;
                        formErrors[key] = validationTexts.INVALIDFIELD;
                    }
                }
                if (
                    (locationDetails.noOfTreadDepthReadings === 2 ||
                        locationDetails.noOfTreadDepthReadings === 3) &&
                    key === "insideTread"
                ) {
                    if (
                        tireRemovalDetails[key] < 0 ||
                        tireRemovalDetails[key] === null ||
                        tireRemovalDetails[key] === undefined
                    ) {
                        isformvalid = false;
                        formErrors[key] = validationTexts.INVALIDFIELD;
                    }
                }
            }
        });

        setFormHelperTexts(formErrors);
        return isformvalid;
    };

    const handleSubmit = (fromWhere) => {
        const fileUploadFormData = new FormData();

        setSubmitFlag(true);
        if (validateForm() && (allowSubmit || fromWhere === "Popup")) {
            setShowSpinner(true);
            let successStatus = false;
            let updatedTireRemovalDetails = {
                ...tireRemovalDetails,
                inspectionDate: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString(),
            }
            removeTireApi
                .removeInstalledTire(updatedTireRemovalDetails)
                .then((removalResponse) => {
                    if (removalResponse.didError === false) {
                        successStatus = true;
                        if (removalMasterData.length !== 0) {
                            headerApiObj
                                .createMasterData(removalMasterData)
                                .then((masterResponse) => {
                                    if (masterResponse.didError === false) {
                                        if (tireImages.length !== 0) {
                                            const timeStamp = new Date().getTime();
                                            fileUploadFormData.append(
                                                "inspectionId",
                                                removalResponse.model.inspectionId
                                            );

                                            Object.values(tireImages).forEach((img, index) => {
                                                fileUploadFormData.append(
                                                    "imgsrc",
                                                    img,
                                                    `${index}_${timeStamp}.png`
                                                );
                                            });

                                            inspectTireApi
                                                .createTireInspectionImages(fileUploadFormData)
                                                .then((imgResponse) => {
                                                    if (imgResponse.didError === false) {
                                                        headerApiObj
                                                            .createHomeActivity(homeObj)
                                                            .then((_response) => { });
                                                    }
                                                });
                                        } else {
                                            headerApiObj
                                                .createHomeActivity(homeObj)
                                                .then((_response) => { });
                                        }
                                    }
                                });
                        } else {
                            if (tireImages.length !== 0) {
                                const timeStamp = new Date().getTime();
                                fileUploadFormData.append(
                                    "inspectionId",
                                    removalResponse.model.inspectionId
                                );

                                Object.values(tireImages).forEach((img, index) => {
                                    fileUploadFormData.append(
                                        "imgsrc",
                                        img,
                                        `${index}_${timeStamp}.png`
                                    );
                                });

                                inspectTireApi
                                    .createTireInspectionImages(fileUploadFormData)
                                    .then((imgResponse) => {
                                        if (imgResponse.didError === false) {
                                            headerApiObj
                                                .createHomeActivity(homeObj)
                                                .then((_response) => { });
                                        }
                                    });
                            } else {
                                headerApiObj
                                    .createHomeActivity(homeObj)
                                    .then((_response) => { });
                            }
                        }
                    } else {
                        successStatus = false;
                    }
                    setShowSpinner(false);
                    history.push({
                        pathname: prevPath,
                        state: {
                            tireId: tireDetails.tireId,
                            dispositionId: tireRemovalDetails.dispositionId,
                            updatedSection: "removeinstalledtire",
                            updateStatus: successStatus,
                            successMessage: notificationTexts.TIREREMOVED,
                            successMessageDesc: notificationTexts.TIREREMOVEDDESC.replace(
                                "#1#1",
                                tireDetails.tireSerialNo
                            ).replace("#2#2", vehicleNum),
                        },
                    });
                });
        }
    };
    return (
        <React.Fragment>
            <div className="standalone-new-header-container">
                <div className="remove-tire-main-page-container">
                    <div className="my-profile-upper-deck">
                        <div className="background-map-image">
                            <div className="dashboard-header">
                                <div>
                                    <Breadcrumbs breadcrumbs={myBreadcrumbs} />
                                    <div className="scrap-tires-title">
                                        {titleTexts.REMOVETIRE}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showSpinner ? (
                <Spinner />
            ) : (
                <div className="manage-vehicle-create-container create-vehicle-form">
                    <div className="remove-tire-container">
                        <div className="remove-tire-inner-container">
                            <div className="vehicle-detail-container">
                                <div className="vehicle-details-grid">
                                    <TireRemovalDetailsGrid
                                        location={accLocationDetails}
                                        vehicleNo={vehicleNum}
                                        tireData={tireDetails}
                                    />
                                </div>
                            </div>
                            <div className="helper-message">
                                {tireFormCaptions.MANDATORYFIELDS}{" "}
                                <span className="required-mark">*</span>
                            </div>

                            <div className="inspect-tire-form-container">
                                {masterData && tireDetails && (
                                    <TireRemovalForm
                                        masterDataSet={masterData}
                                        casingConditionList={casingConditions}
                                        wearConditionList={wearConditions}
                                        tireData={tireDetails}
                                        tireRemovalData={tireRemovalDetails}
                                        onChangeListener={(field, val) =>
                                            handleFieldChange(field, val)
                                        }
                                        onRemovalReasonChange={(field, value) =>
                                            handleRemovalChange(field, value)
                                        }
                                        locationData={locationDetails}
                                        tireimages={imgsrc}
                                        formHelpers={formHelperTexts}
                                        removeUploadedPhoto={(i) => deleteImage(i)}
                                        isRemovalReasonNew={isRemovalReasonNew}
                                        removalMasterData={removalMasterData}
                                        unitPreferences={unitPreferences}
                                    />
                                )}
                            </div>

                            <hr className="hr-divider-action" />

                            <div className="action-buttons">
                                <Button
                                    className={styling.cancel}
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    {tireFormCaptions.CANCEL}
                                </Button>
                                <Button
                                    className={styling.next}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSubmit("Submit")}
                                >
                                    {tireFormCaptions.SUBMIT}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isCancelModalOpen && (
                <CancelConfirmationPopup
                    closeConfirmationModal={() => setCancelModalOpen(false)}
                    pathToRedirect={prevPath}
                    stateToPass={historyState}
                />
            )}

            <Modal
                open={!allowSubmit && submitFlag}
                onClose={() => setSubmitFlag(false)}
                className="header-change-modal-style"
                disableScrollLock={true}
            >
                <AlertPopup
                    description="Alert"
                    message="You haven't updated the hours and Miles of the Vehicle. Do you want to proceed?"
                    screen="RemoveTire"
                    allowSubmit={setAllowSubmit}
                    onSave={handleSubmit}
                    onClose={() => setSubmitFlag(false)}
                />
            </Modal>
        </React.Fragment>
    );
};

export default RemoveTire;
