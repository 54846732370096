import Globalization from '../globalization/index'

const globalizationMapping = new Globalization();

export const ViewTirePaths = [
    {
        name: globalizationMapping.TIRES.CREATEFORM.IDENTIFICATION,
        path: 'identification', 
        routeSelected: 1 
      },
      {
        name: globalizationMapping.TIRES.CREATEFORM.DESCRIPTION,
        path: 'description',
        routeSelected: 2
      },
      {
        name: globalizationMapping.TIRES.CREATEFORM.TREADDEPTH32,
        path: 'treaddepth',
        routeSelected: 3
      },
      {
        name: globalizationMapping.TIRES.CREATEFORM.TIRECOSTS,
        path: 'tirecosts',
        routeSelected: 4
      }
]