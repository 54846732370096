import Moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  getAccountNames,
  getLocationNames,
} from "../../services/AccountInfoHandler";
import CommonUtils from "../../services/utils";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const inspectionDateToday = new Date().toLocaleDateString();
const commonUtils = new CommonUtils();
/** header body and footer */
const addPdfMakeHeader = () => {

  return (_currentPage, _pageCount) => {
    return {
      margin: [20, 20, 20, 0],
      stack: [
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  border: [false, false, false, false],
                  color: "white",
                  fillColor: "#1250B0",
                  alignment: "right",
                  columns: [
                    {
                      svg: commonUtils.EmTrackSVGLogo,
                      fit: [50, 80],
                      width: "5%",
                      margin: [0, 2, 0, 2],
                      alignment: "left"
                    },
                    {
                      text: "Fleet Data Collection",
                      bold: true,
                      alignment: "center",
                      fontSize: 14,
                      margin: [0, 10, 0, 2]
                    },
                    {
                      svg: commonUtils.GoodyearSVGLogo,
                      alignment: "right",
                      width: "10%",
                      fit: [90, 150],
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ["*"],
            margin: [20, 0, 20, 0],
            body: [
              [
                {
                  fontSize: 10,
                  border: [false, false, false, false],
                  color: "#074BA7",
                  fillColor: "#E5F0FF",
                  alignment: "left",
                  columns: [
                    {
                      text: "Date: \u200B\t",
                      width: "auto",
                    },
                    {
                      text: Moment(new Date()).format("DD/MM/YYYY"),
                      bold: true,
                    },
                    {
                      text: "Account: \u200B\t",
                      width: "auto",
                    },
                    {
                      text: getAccountNames().toString(),
                      bold: true,
                    },
                    {
                      text: "Location List: \u200B\t",
                      width: "auto",
                    },
                    {
                      text: getLocationNames().toString(),
                      bold: true,
                      width: "auto",
                    },
                  ],
                },
              ],
            ],
          },
        },
      ],
    };
  };
};
const addPdfMakeFooter = () => {
  const footerText = `©${new Date().getFullYear()} The Goodyear Tire & Rubber Company `;
  return (currentPage, pageCount) => {
    // you can apply any logic and return any valid pdfmake element
    return {
      margin: [20, 0, 20, 0],
      border: [false, false, false, false],
      table: {
        widths: ["*"],
        body: [
          [
            {
              border: [false, false, false, false],
              fontSize: 9,
              color: "white",
              fillColor: "#1250B0",
              alignment: "left",
              columns: [
                {
                  text: footerText,
                  alignment: "left",
                  width: "40%",
                  margin: [0, 13, 0, 0],
                  bold: true,
                },
                {
                  text: `PAGE ${currentPage.toString()}/${pageCount.toString()}`,
                  alignment: "right",
                  // width: "30%",
                  margin: [0, 13, 0, 0],
                  fontWeight: 400,
                },
              ],
            },
          ],
        ],
      },
    };
  };
};
const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = 90;
      canvas.height = 25;
      let ctx = canvas.getContext("2d");
      let wrh = img.width / img.height;
      let newWidth = canvas.width;
      let newHeight = newWidth / wrh;
      if (newHeight > canvas.height) {
        newHeight = canvas.height;
        newWidth = newHeight * wrh;
      }
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      img.src = canvas.toDataURL("image/png");
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
};
export const generateFleetDataPDF = (fleetData) => {
  let docDefinition = {
    content: [],
  };
  for (let index = 0; index < fleetData.vehicleData.length; index++) {
    let eachData = fleetData.vehicleData[index];
    let recordNum = index;
    let recordDetails = {
      table: {
        widths: ["*"],
        margin: [20, 0, 20, 0],
        body: [
          [
            {
              fontSize: 9,
              border: [false, false, false, false],
              color: "#000",
              fillColor: "#E5F0FF",
              alignment: "left",
              columns: [
                {
                  text: "Record No.\u200B\t",
                  bold: true,
                  width: "auto",
                },
                {
                  text: ++recordNum,
                  width: "auto",
                  marginRight: 15,
                },
                {
                  text: "Inspection Name:\u200B\t",
                  bold: true,
                  width: "auto",
                },
                {
                  text: eachData.InspectionName,
                  width: "auto",
                  marginRight: 15,
                },
                {
                  text: "Inspection ID/ Record ID:\u200B\t",
                  bold: true,
                  width: "auto",
                },
                {
                  text: eachData.InspectionOrderId,
                  width: "auto",
                  marginRight: 15,
                },
                {
                  text: "Vehicle Location:\u200B\t",
                  bold: true,
                  width: "auto",
                },
                {
                  text: eachData.LocationName,
                  width: "auto",
                },
              ],
            },
          ],
        ],
      },
    };
    if (index > 0) {
      recordDetails.table.body[0][0].pageBreak = "before";
    }
    let tireDetails = {
      table: {
        widths: ["*"],
        margin: [40, 0, 20, 0],
        body: [
          [
            {
              fontSize: 11,
              color: "#074BA7",
              border: [false, false, false, false],
              alignment: "left",
              margin: [0, 2],
              columns: [
                {
                  text: "Vehicle Details",
                  bold: true,
                  width: "auto",
                },
              ],
            },
          ],
          [
            {
              fontSize: 9,
              border: [false, false, false, false],
              alignment: "left",
              margin: [0, 0, 0, 5],
              columns: [
                {
                  text: "Vehicle Id: " + eachData.VehicleId,
                  bold: true,
                  width: "20%",
                },
                {
                  text: "Asset no: " + eachData.AssetNumber,
                  bold: true,
                  width: "20%",
                },
                {
                  text:
                    "Make/Model:" +
                    eachData.ModelName +
                    "/" +
                    eachData.Manufacturer,
                  bold: true,
                  width: "20%",
                },
                {
                  text: "Last Hours : " + eachData.CurrentHours,
                  bold: true,
                  width: "20%",
                },
                {
                  text: "Current Hours : ",
                  bold: true,
                  width: "20%",
                },
              ],
            },
          ],
          [
            {
              fontSize: 9,
              border: [false, false, false, false],
              alignment: "left",
              margin: [0, 0, 0, 5],
              columns: [
                {
                  text: "Last Miles : " + eachData.CurrentMiles,
                  bold: true,
                  width: "20%",
                },
                {
                  text: "Current Miles : ",
                  bold: true,
                  width: "20%",
                },
                {
                  text: "Last Inspection Date : " + eachData.UpdatedDate,
                  bold: true,
                  width: "20%",
                },
                {
                  text: "Inspection Date : " + inspectionDateToday,
                  bold: true,
                  width: "20%",
                },
                {
                  text:
                    "Recommended Pressure : " + eachData.RecommendedPressure,
                  bold: true,
                  width: "20%",
                },
              ],
            },
          ],
          [
            {
              fontSize: 9,
              border: [false, false, false, false],
              alignment: "left",
              margin: [0, 0, 0, 5],
              columns: [
                {
                  text: "Vehicle Comments : " + eachData.VehicleComments,
                  bold: true,
                  width: "100%",
                  alignment: "justify",
                },
              ],
            },
          ],
        ],
      },
    };
    let currentNs = {
      table: {
        widths: ["*"],
        margin: [40, 0, 20, 0],
        body: [
          [
            {
              border: [false, false, false, false],
              alignment: "left",
              margin: [0, 1],
              columns: [
                {
                  width: "80%",
                  text: "",
                },
                {
                  width: "10%",
                  text: "Current NS",
                  fontSize: 8,
                  style: "innerContentSubHeader",
                },
                {
                  width: "5%",
                  text: "Hot/Cold",
                  fontSize: 8,
                  style: "innerContentSubHeader",
                  alignment: "right",
                },
              ],
            },
          ],
        ],
      },
    };
    let fleetRowsDataTemplate = [];
    fleetData.rowsData[index].forEach((item) => {
      fleetRowsDataTemplate.push(
        {
          margin: [0, 2],
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 5,
              x2: 1024,
              y2: 5,
              lineWidth: 1,
              lineColor: "lightgray",
            },
          ],
        },
        {
          columns: [
            {
              width: "5%",
              text: item.WheelPosition,
              fontSize: 11,
              style: "captionStyle",
            },
            {
              width: "15%",
              text: "Size : " + item.SizeName,
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "20%",
              text: "Make : " + item.ManufacturerName,
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "20%",
              text: "Type : " + item.TypeName,
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "20%",
              text: "Last NS : " + item.TreadDepth,
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "7.5%",
              text: "(O) \t\t\t",
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "7.5%",
              text: "\t\t  (H)",
              fontSize: 9,
              style: "innerContentStyle",
              alignment: "right",
            },
          ],
        },

        {
          margin: [0, 2],
          columns: [
            {
              width: "5%",
              text: "",
            },
            {
              width: "15%",
              text: "Brand# : " + item.BrandNumber,
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "20%",
              text: "Serial# : " + item.SerialNumber,
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "20%",
              text: "Total Hours : " + item.TotalHours,
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "20%",
              text: "Last PSI/Rec. : " + item.Pressure,
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "7.5%",
              text: "(C) \t\t\t",
              fontSize: 9,
              style: "innerContentStyle",
            },
            {
              width: "7.5%",
              text: "\t\t  (C)",
              fontSize: 9,
              style: "innerContentStyle",
              alignment: "right",
            },
          ],
        },
        {
          margin: [0, 2],
          columns: [
            {
              width: "5%",
              text: "",
            },
            {
              width: "15%",
              text: "Rating : " + item.RatingName,
              style: "innerContentStyle",
              fontSize: 9,
            },
            {
              width: "60%",
              text: "Comments : " + item.InspectionComments,
              style: "innerContentStyle",
              fontSize: 9,
            },
            {
              width: "20%",
              text: "(I)",
              style: "innerContentStyle",
              fontSize: 9,
            },
          ],
        }
      );
    });
    // generates the body content styles for survey templates
    docDefinition.content.push(recordDetails);
    docDefinition.content.push(tireDetails);
    docDefinition.content.push(currentNs);
    docDefinition.content.push(fleetRowsDataTemplate);
  }
  return docDefinition.content;
};
export const getFilename = (reportName) => {
  return reportName.trim().replace(/ /g, "_") + ".pdf";
};
/** Doc definition */
const createDocumentDefinition = (_reportDate, fleetData) => {
  const baseDocDefinition = {
    pageSize: "A4",
    pageMargins: [20, 90, 20, 55],
    pageOrientation: "landscape",
  };
  const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
  docDefinition.header = addPdfMakeHeader();
  docDefinition.footer = addPdfMakeFooter();
  let fleetDataTemplateFormDocument = generateFleetDataPDF(fleetData);
  docDefinition.content = fleetDataTemplateFormDocument;
  const defaultFont = {defaultStyle: {
    font: 'Barlow'
  }}
  docDefinition.defaultStyle = defaultFont;
  return docDefinition;
};
// creates the survey template pdf
export const MakeFleetDataAnalysisTemplate = (fleetData) => {
  // -- Create a base document template for the templates.
  const docDefinition = createDocumentDefinition(new Date(), fleetData);
  return pdfMake.createPdf(docDefinition);
};
