import React, { useEffect, useState } from "react";
import "../../../../common-styles/TableStyle.css";
import "./view-tire-history-table.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Divider,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from '@material-ui/icons/MoreVertOutlined';
import Globalization from "../../../../globalization";
import NoDataFound from "../../../../common-components/no-data-found/NoDataFound";
import EditTireHistoryModal from "../history-edit-modal/tire-history-modal";
import DeleteEventModal from "../modal/DeleteEvent";
import CommonUtils from "../../../../services/utils";
// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    height: 700,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "14px !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
  },
}));

const commonUtils = new CommonUtils();

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (orderBy === "CreatedDate" || orderBy === "Date") {
    let da = new Date(commonUtils.tableDateFormatter(a[orderBy]));
    let db = new Date(commonUtils.tableDateFormatter(b[orderBy]));
    if (da > db) {
      return -1;
    } else if (da < db) {
      return 1;
    }
    return 0;
  } else if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    let firstVal = a[orderBy].toLowerCase().trim()
    let secondVal = b[orderBy].toLowerCase().trim()
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  
  } else {
   let firstVal = a[orderBy]
   let secondVal = b[orderBy]
   if (secondVal < firstVal) {
     return -1;
   }
   if (secondVal > firstVal) {
     return 1;
   }
   return 0;
  }
}


function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TireHistoryListTable = ({
  tableData,
  showColumns,
  userPreference,
  rawHistoryData,
  masterData,
  initModalNotifs,
  deleteEventDone,
  forceActionPopupClose,
  forceActionPopupCloseToFalse,
}) => {
  const globalizationMapping = new Globalization();
  const classes = useStyles();
  const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
  const [tireHistoryListTableData, setTireHistoryListTableData] =
    useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("CreatedDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editClicked, setEditClicked] = useState([]);
  const [isEditHistoryModalOpen, setEditHistoryModalOpen] = useState(false);
  const [tireHistoryDetails, setTireHistoryDetails] = useState({});
  const [isEventDeleteOpen, setIsEventDeleteOpen] = useState(false);
  const [eventData, setEventData] = useState({});

  
  useEffect(() => {
    setTireHistoryListTableData(tableData);
    let arr = commonUtils.rowPerPageSelector(tireHistoryListTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  useEffect(()=> {
    forceCloseAction();
    forceActionPopupCloseToFalse();
  },[forceActionPopupClose]);

  const handleRequestSort = (property) => (event) => {
    forceCloseAction();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const editRow = (index) => {
    let row = [...editClicked];
    row.length = tireHistoryListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      if (row[i] === true && index !== i) {
        row[i] = false;
      }
    }
    if (editClicked[index]) {
      row[index] = !row[index];
      setEditClicked(row);
    } else {
      row[index] = true;
      setEditClicked(row);
    }
  };

  const handleEditClick = (eventid) => {
    setEditClicked([]);
    let myhistory = rawHistoryData.find(
      (history) => history.eventId === eventid
    );
    setTireHistoryDetails(myhistory);
    setEditHistoryModalOpen(true);
  };

  const handleEditModalClose = (notifData) => {
    initModalNotifs({ state: notifData });
    setEditHistoryModalOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    forceCloseAction();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    forceCloseAction();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEventDeleteClick = (index, data) => {
    editRow(index);
    setIsEventDeleteOpen(true);
    setEventData(data);
  };
  const closeEventDeleteModal = (deleteData) => {
    setIsEventDeleteOpen(false);
    let notification = { state: deleteData };
    deleteEventDone(notification);
  };

  const userPreferencePressureUnit = (headCell) => {
    if (headCell.value === "Pressure") {
      if (userPreference.userPressureUnit === "PSI") {
        return headCell.label + " (PSI)";
      } else if (userPreference.userPressureUnit === "KPA") {
        return headCell.label + " (KPA)";
      } else {
        return headCell.label + " (Bar)";
      }
    } else {
      return headCell.label;
    }
  };

  const forceCloseAction = () => {
    let row = [...editClicked];
    row.length = tireHistoryListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
  }


  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {tireHistoryListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort(headCell.value)}
                        >
                          {userPreferencePressureUnit(headCell)}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              <tr className="table-bottom-line">
                <td
                  colSpan={tireHistoryListTableData.headCells.length + 1}
                ></td>
              </tr>
              {tireHistoryListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    tireHistoryListTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          tabIndex={-1}
                          key={row.Key}
                        >
                          {showColumns.Date && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Date}
                            </TableCell>
                          )}
                          {showColumns.Event && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Event}
                            </TableCell>
                          )}
                          {showColumns.VehicleNumber && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.VehicleNumber}
                            </TableCell>
                          )}
                          {showColumns.TreadDepth && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Event !== "UpdateHours"
                                ? row.TreadDepth
                                : "N/A"}
                            </TableCell>
                          )}
                          {showColumns.Pressure && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Event !== "UpdateHours"
                                ? row.Pressure
                                : "N/A"}
                            </TableCell>
                          )}
                          {showColumns.Hours && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Hours}
                            </TableCell>
                          )}
                          {showColumns.Distance && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Distance +
                                " " +
                                (userPreference.userMeasurementSystemValue ===
                                  "imperial"
                                  ? tireFormCaptions.MILES
                                  : tireFormCaptions.KM)}
                            </TableCell>
                          )}
                          {showColumns.Cost && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Cost}
                            </TableCell>
                          )}
                          {showColumns.WheelPosition && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.WheelPosition}
                            </TableCell>
                          )}
                          {showColumns.Comments && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Comments}
                            </TableCell>
                          )}

                          <TableCell
                            align="left"
                            className="table-content-text"
                            style={{ position: "relative" }}
                          >
                            <IconButton
                              aria-label="edit"
                              onClick={() => editRow(index)}
                            >
                              <MoreIcon style={{color:commonUtils.primaryBlue}} fontSize='small'></MoreIcon>
                            </IconButton>
                            {editClicked[index] === true && (
                              <div className="action-dropdown-content">
                                <div
                                  className="action-option"
                                  onClick={() => {
                                    handleEditClick(row.EventId);
                                  }}
                                >
                                  {tireFormCaptions.EDIT}
                                </div>
                                <Divider />
                                <div
                                  className="action-option"
                                  onClick={() => {
                                    handleEventDeleteClick(index, row);
                                  }}
                                >
                                  {
                                    globalizationMapping.MANAGE_PARENT_ACCOUNT
                                      .OPTION3
                                  }
                                </div>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              <tr className="table-bottom-line">
                <td
                  colSpan={tireHistoryListTableData.headCells.length + 1}
                ></td>
              </tr>
              {tireHistoryListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-history-found"
                      colSpan={11}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {isEditHistoryModalOpen && (
            <EditTireHistoryModal
              closeEditHistoryModal={handleEditModalClose}
              tireHistoryDetails={tireHistoryDetails}
              masterDataSet={masterData}
            />
          )}
          {isEventDeleteOpen && (
            <DeleteEventModal
              closeEventDeleteModal={closeEventDeleteModal}
              eventData={eventData}
            />
          )}

          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={tireHistoryListTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};
export default TireHistoryListTable;
