import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
// eslint-disable-next-line no-unused-vars


const ByVehicleModelGraph = ({ byVehicleModelGraphData }) => {
  const commonUtils = new CommonUtils();
  const globalizationMapping = new Globalization();
  const [chartInfoData, setChartInfoData] = useState({});
  const [chartInfoDataMile, setChartInfoDataMile] = useState({});
  const borderColor = commonUtils.borderColor;
  const globalizationHeaders = globalizationMapping.GRAPH;
  const options = commonUtils.barOptions;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["vehicle-model-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["vehicle-model-y"];
  options.title.text = globalizationHeaders["vehicle-model-title"];
  const optionsMile = JSON.parse(JSON.stringify(commonUtils.barOptions));
  optionsMile.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["vehicle-model-x"];
  optionsMile.scales.yAxes[0].scaleLabel.labelString =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["vehicle-model-y-KM"]
      : globalizationHeaders["vehicle-model-y-mile"];
  optionsMile.title.text =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["vehicle-model-title-KM"]
      : globalizationHeaders["vehicle-model-title-mile"];


  const chart = () => {
    if (byVehicleModelGraphData.rowsData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();
      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byVehicleModelGraphData.rowsData.forEach((item) => {
        graphDataSum[item.VehicleModel] = 0;
        graphDataAvg[item.VehicleModel] = 0;
        graphDataCount[item.VehicleModel] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byVehicleModelGraphData.rowsData.forEach((item) => {
        graphDataSum[item.VehicleModel] += item.AverageHours;
        graphDataCount[item.VehicleModel]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((vehicleModel) => {
        graphDataAvg[vehicleModel] = Math.round(
          graphDataSum[vehicleModel] / graphDataCount[vehicleModel]
        );
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoData({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };

  const chartMile = () => {
    if (byVehicleModelGraphData.rowsData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();
      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byVehicleModelGraphData.rowsData.forEach((item) => {
        graphDataSum[item.VehicleModel] = 0;
        graphDataAvg[item.VehicleModel] = 0;
        graphDataCount[item.VehicleModel] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byVehicleModelGraphData.rowsData.forEach((item) => {
        graphDataSum[item.VehicleModel] += item.AverageMiles;
        graphDataCount[item.VehicleModel]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((vehicleModel) => {
        graphDataAvg[vehicleModel] = Math.round(
          graphDataSum[vehicleModel] / graphDataCount[vehicleModel]
        );
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoDataMile({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoDataMile({});
    }
  };
  useEffect(() => {
    chart();
    chartMile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byVehicleModelGraphData]);

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 &&
          byVehicleModelGraphData.selectedColShow.AverageHours && (
            <Bar data={chartInfoData} height={120} options={options} />
          )}
        {Object.keys(chartInfoData).length > 0 &&
          !byVehicleModelGraphData.selectedColShow.AverageHours && (
            <Bar data={chartInfoDataMile} height={120} options={optionsMile} />
          )}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ByVehicleModelGraph;
