import Globalization from "../../../globalization/index";

class ScrapCostByTireTypeDataSetter {
  constructor() {
    this.ScrapCostByTireTypeGraphData = [];
    this.globalizationMapping = new Globalization();
  }

  setGraphData(data) {
    let graphData = data.model.graphData;
    return graphData;
  }
  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    //
    rowsData.tireSizeLabel.forEach((row, index) => {
      filterOptions.categories.forEach((category) => {
        if (!filterInitCategoryData[category.value].includes(row)) {
          filterInitCategoryData[category.value].push(row.toString());
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = {
      chartlabels: [],
      chartData: [
        {
          data: [],
          label: "CostPerHour"
        },
        {
          data: [],
          label: ""
        }
      ],
      count: [],
      manufacturerLabel: [],
      tireSizeLabel: []
    };

    tableData.tireSizeLabel.map((rowData, index) => {
      let isValidItem = categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) {
          return true;
        }
        return appliedFilter[categoryKey].includes(rowData);
      });

      if (isValidItem) {
        filteredTable.chartData[0].data.push(tableData.chartData[0].data[index]);
        filteredTable.chartlabels.push(tableData.chartlabels[index]);
        filteredTable.count.push(tableData.count[index]);
        filteredTable.manufacturerLabel.push(tableData.manufacturerLabel[index]);
        filteredTable.tireSizeLabel.push(tableData.tireSizeLabel[index]);
      }

      return index;
    });

    return filteredTable;
  }

  setDisplayColumns(graphData) {
    let displayColumns = [];
    graphData.chartlabels.forEach((item, index) => {
      displayColumns.push({
        key: item,
        keyIndex: index,
        value: item,
        label: item,
        count: graphData.chartData[0].data[index],
        maker: graphData.manufacturerLabel[index]
      });
    });
    return displayColumns;
  }
  setMfgColumns(graphData) {
    let mfgArr = [];
    let mfg = {};
    graphData.manufacturerLabel.forEach((item, index) => {
      mfg[item] = {
        key: item,
        keyIndex: index,
        value: item,
        label: item
      };
    });
    for (const key in mfg) {
      if (Object.hasOwnProperty.call(mfg, key)) {
        const element = mfg[key];
        mfgArr.push(element);
      }
    }
    return mfgArr;
  }
  setDisplayColumnData(ScrapCostByTireTypeGraphData, selectedCols) {
    let bS = {
      chartlabels: [],
      chartData: [{ data: [] }],
      count: [],
      manufacturerLabel: []
    };
    for (let graphIndex = 0; graphIndex < ScrapCostByTireTypeGraphData.chartlabels.length; graphIndex++) {
      for (const element of selectedCols) {
        if (element.value === ScrapCostByTireTypeGraphData.chartlabels[graphIndex]) {
          bS.chartlabels.push(ScrapCostByTireTypeGraphData.chartlabels[graphIndex]);
          bS.chartData[0].data.push(ScrapCostByTireTypeGraphData.chartData[0].data[graphIndex]);
          bS.count.push(ScrapCostByTireTypeGraphData.count[graphIndex]);
          bS.manufacturerLabel.push(ScrapCostByTireTypeGraphData.manufacturerLabel[graphIndex]);
          break;
        }
      }
    }
    bS.chartlabels = bS.chartlabels.filter((item) => item !== undefined);
    bS.chartData[0].data = bS.chartData[0].data.filter((item) => item !== undefined);
    return bS;
  }
  setMfgColumnData(ScrapCostByTireTypeGraphData, selectedCols) {
    let bS = {
      chartlabels: [],
      chartData: [{ data: [] }],
      count: [],
      manufacturerLabel: []
    };
    let rawData = JSON.parse(JSON.stringify(ScrapCostByTireTypeGraphData));
    for (let graphIndex = 0; graphIndex < rawData.chartlabels.length; graphIndex++) {
      for (const element of selectedCols) {
        let sC = element.value;
        let mL = rawData.manufacturerLabel[graphIndex];
        if (mL === sC) {
          bS.chartlabels.push(rawData.chartlabels[graphIndex]);
          bS.chartData[0].data.push(rawData.chartData[0].data[graphIndex]);
          bS.count.push(rawData.count[graphIndex]);
          bS.manufacturerLabel.push(rawData.manufacturerLabel[graphIndex]);
          break;
        }
      }
    }
    bS.chartlabels = bS.chartlabels.filter((item) => item !== undefined);
    bS.chartData[0].data = bS.chartData[0].data.filter((item) => item !== undefined);
    return bS;
  }
}

export default ScrapCostByTireTypeDataSetter;
