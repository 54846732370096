import React, { useEffect } from "react";
import { StateProvider } from "../services/StateProvider";
import { AccountInfoUpdate, initialAccountState } from "../services/StateMap";
import { BrowserRouter, useHistory, withRouter } from "react-router-dom";
import HeaderApi from "../common-components/header/services/header-apis";

const mainReducer = (state, action) => {
  return {
    accountInfo: AccountInfoUpdate(state.accountInfo, action),
  };
};
const MainComponent = () => {
  const history = useHistory();

  useEffect(() => {
    let previousUserLang = "";
    if (sessionStorage.getItem("userLanguage")) {
      previousUserLang = sessionStorage.getItem("userLanguage");
    }
    const headerApi = new HeaderApi();

     
    headerApi.getUserPreference().then((data) => {
      if (data) {
        sessionStorage.setItem("userLanguage", data?.model?.userLanguage);
        let isOnlyView = JSON.parse(sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
        )).profile.group
        console.log( "isOnlyView", isOnlyView.some(item => {
          return item === "EMROUSR"
        }))
        isOnlyView = isOnlyView.some(item => item === "EMROUSR");
        // isOnlyView = true;
        sessionStorage.setItem("isOnlyView", JSON.stringify(isOnlyView));
        localStorage.setItem("logoUrl", data?.model?.logoLocation);
        sessionStorage.setItem("LogoutMsgShown", "false");

        if (previousUserLang !== sessionStorage.getItem("userLanguage")) {
          history.push(localStorage.getItem("loadedURL"));
          window.location.reload();
        } else {
          previousUserLang = "en-us";
        }
      }
    });
  });

  return (
    <StateProvider
      reducer={mainReducer}
      initialState={{ accountInfo: initialAccountState }}
    >
      <div className="app-content-container">
        <BrowserRouter></BrowserRouter>
      </div>
    </StateProvider>
  );
};
export default withRouter(MainComponent);
