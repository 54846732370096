import Globalization from "../../../globalization/index";

class BySizeDataSetter {
  constructor() {
    this.data = {};
    this.bySizeData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "WearRatePerMile" || headCell.value === "AverageMiles") selectedColShow[headCell.value] = false;
      else selectedColShow[headCell.value] = true;
    });

    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.TireCount.toString().includes(searchText) ||
        item.WearRate.toString().includes(searchText) ||
        item.AverageHours.toString().includes(searchText) ||
        item.AverageMiles.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });

    let chartLbl = [];
    for (const element of filteredTable) {
      let label = element.SizeName;
      let value = element.WearRate;
      chartLbl.push({
        label: label,
        value: value,
        totalTiresCount: element.TireCount
      });
    }

    chartLbl.sort((a, b) => {
      if (a.label < b.label) return -1;
      else if (a.label > b.label) return 1;
      else return 0;
    });

    let dataObj = {};
    let countObj = {};
    chartLbl.forEach((obj) => {
      let count = dataObj[obj.label] ? dataObj[obj.label] : 0;
      dataObj[obj.label] = count + obj.value; // * obj.totalTiresCount)
      let count2 = countObj[obj.label] ? countObj[obj.label] : 0;
      countObj[obj.label] = count2 + 1; //obj.totalTiresCount
    });
    let dataArrHours = {};
    dataArrHours.labels = [];
    dataArrHours.datas = [];
    for (const key in dataObj) {
      if (Object.hasOwnProperty.call(dataObj, key)) {
        const element = dataObj[key];
        const element2 = countObj[key];
        dataArrHours.labels.push(key);
        dataArrHours.datas.push(element / element2);
      }
    }
    return { rowData: filteredTable, graphData: dataArrHours };
  }
  avgValues = (bySizeData) => {
    let TireCountSum = 0,
      HoursSum = 0,
      WearRateSum = 0,
      MilesSum = 0,
      WearRateSumPerMile = 0;
    bySizeData.forEach((item, index) => {
      HoursSum += item.TotalAverageHours;
      MilesSum += item.TotalAverageMiles;
      TireCountSum += item.TireCount;
      WearRateSum += item.WearRate;
      WearRateSumPerMile += item.WearRatePerMile;
    });
    let TireCount = TireCountSum;
    let HoursAvg = Math.round(HoursSum / TireCountSum);
    let WearRateAvg = Math.round(WearRateSum / bySizeData.length);

    let MileAvg = Math.round(MilesSum / TireCountSum);
    let WearRateAvgPerMile = Math.round(WearRateSumPerMile / bySizeData.length);

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      CompoundName: "",
      WearRate: WearRateAvg,
      WearRatePerMile: WearRateAvgPerMile,
      TireCount: TireCount,
      AverageHours: HoursAvg,
      AverageMiles: MileAvg
    };
    //
    return avgValues;
  };

  toCompareOBJ = {};
  toCompareCount = {};

  setData(data) {
    this.toCompareOBJ = {};
    this.toCompareCount = {};

    // TABLE ROWS DATA
    const reportData = data.model && data.model.reportData;
    let dataArrHours = { labels: [], datas: [] };
    let dataArrMiles = { labels: [], datas: [] };

    if (reportData !== null || reportData.length !== 0|| reportData !== undefined) {
      reportData.forEach((item, index) => {
        const currentKey = item.manufacturerName + "|" + item.sizeName + "|" + item.typeName;
        /** NOTE : the keys must match the headCells value to be able to SORT */
        if (this.toCompareOBJ.hasOwnProperty(currentKey)) {
          this.toCompareOBJ[currentKey] = {
            Key: this.toCompareOBJ[currentKey].Key,
            ManufacturerName: this.toCompareOBJ[currentKey].ManufacturerName,
            SizeName: this.toCompareOBJ[currentKey].SizeName,
            TypeName: this.toCompareOBJ[currentKey].TypeName,
            CompoundName: this.toCompareOBJ[currentKey].CompoundName,
            WearRate: item.wearRate ? item.wearRate + Number(this.toCompareOBJ[currentKey]["WearRate"]) : 0,
            WearRatePerMile: item.wearRatePerMile ? item.wearRatePerMile + Number(this.toCompareOBJ[currentKey]["WearRatePerMile"]) : 123,
            TireCount: item.tireCount ? item.tireCount + Number(this.toCompareOBJ[currentKey]["TireCount"]) : 0,
            AverageHours: item.averageHours ? item.averageHours + Number(this.toCompareOBJ[currentKey]["AverageHours"]) : 0,
            AverageMiles: item.averageMiles ? item.averageMiles + Number(this.toCompareOBJ[currentKey]["AverageMiles"]) : 0
            // TotalAverageHours: item.averageHours ? Math.round(item.averageHours * 100 * (item.tireCount ? item.tireCount : 1)) / 100 : 0,
          };
          this.toCompareCount[currentKey]++;
        } else {
          this.toCompareOBJ[currentKey] = {
            Key: index,
            ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
            SizeName: item.sizeName ? item.sizeName : "",
            TypeName: item.typeName ? item.typeName : "",
            CompoundName: item.compoundName ? item.compoundName : "",
            WearRate: item.wearRate ? item.wearRate : 0,
            WearRatePerMile: item.wearRatePerMile ? item.wearRatePerMile : 0,
            TireCount: item.tireCount ? item.tireCount : 0,
            AverageHours: item.averageHours ? item.averageHours : 0,
            AverageMiles: item.averageMiles ? item.averageMiles : 0
            // TotalAverageHours: item.averageHours ? Math.round(item.averageHours * 100 * (item.tireCount ? item.tireCount : 1)) / 100 : 0,
          };
          this.toCompareCount[currentKey] = 1;
        }
      });

      const itemTireCount = (tireCount) => {
        if (tireCount) {
          return tireCount;
        } else {
          return 1;
        }
      }

      for (const key in this.toCompareOBJ) {
        if (Object.hasOwnProperty.call(this.toCompareOBJ, key)) {
          let item = JSON.parse(JSON.stringify(this.toCompareOBJ[key]));
          item["WearRate"] = item.WearRate / this.toCompareCount[key];
          item["WearRate"] = Math.round(item.WearRate * 100) / 100;
          item["WearRatePerMile"] = item.WearRatePerMile / this.toCompareCount[key];
          item["WearRatePerMile"] = item.WearRatePerMile ? Math.round(Number(item.WearRatePerMile) * 100) / 100 : 0;
          item["TotalAverageHours"] = item.AverageHours ? Math.round(item.AverageHours * 100 * (itemTireCount(item.TireCount))) / 100 : 0;
          item["TotalAverageMiles"] = item.AverageMiles ? Math.round(item.AverageMiles * 100 * (itemTireCount(item.TireCount))) / 100 : 0;
          this.bySizeData.push(item);
        }
      }

      let chartLbl = [];
      for (const element of reportData) {
        let label = element.sizeName;
        let value = element.wearRate;
        chartLbl.push({
          label: label,
          value: value,
          totalTiresCount: element.tireCount,
          mile: element.wearRatePerMile
        });
      }

      chartLbl.sort((a, b) => {
        if (a.label < b.label) return -1;
        else if (a.label > b.label) return 1;
        else return 0;
      });

      let dataObj = {};
      let countObj = {};
      chartLbl.forEach((obj) => {
        dataObj[obj.label] = dataObj[obj.label] ? dataObj[obj.label] : { hour: "", mile: "" };
        let count = dataObj[obj.label]["hour"] ? dataObj[obj.label]["hour"] : 0;
        dataObj[obj.label]["hour"] = count + obj.value;

        let countM = dataObj[obj.label]["mile"] ? dataObj[obj.label]["mile"] : 0;
        dataObj[obj.label]["mile"] = countM + obj.mile;
        let count2 = countObj[obj.label] ? countObj[obj.label] : 0;
        countObj[obj.label] = count2 + 1;
      });

      dataArrHours.datas = [];
      dataArrMiles.datas = [];
      for (const key in dataObj) {
        if (Object.hasOwnProperty.call(dataObj, key)) {
          const elementH = dataObj[key].hour;
          const elementM = dataObj[key].mile;
          const element2 = countObj[key];
          dataArrHours.labels.push(key);
          dataArrHours.datas.push(elementH / element2);

          dataArrMiles.labels.push(key);
          dataArrMiles.datas.push((parseFloat((elementM / element2) * 100) / 100).toFixed(2));
        }
      }
    }

    // TABLE HEADER DATA
    const columnDefinitions = data.model && data.model.columnDefinitions;
    if (columnDefinitions !== null && columnDefinitions.length !== 0 && columnDefinitions !== undefined) {
      columnDefinitions.forEach((item, index) => {
        let colDefnString = item.text.split(".");
        let masterKey = colDefnString[0];
        let key = colDefnString[1];
        let labelName = this.globalizationMapping[masterKey][key];
        let labelId = item.fieldName;

        this.headCells.push({
          key: labelId,
          keyIndex: index,
          value: labelId,
          label: labelName,
          numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
        });
      });
    }
    let selectedColShow = this.initDisplayColumnData(this.headCells);

    this.bySizeData.sort((a, b) => {
      if (a.SizeName < b.SizeName) return -1;
      else if (a.SizeName > b.SizeName) return 1;
      else return 0;
    });

    return {
      rowsData: this.bySizeData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.bySizeData),
      selectedColShow: selectedColShow,
      graphDataHours: dataArrHours,
      graphDataMiles: dataArrMiles
    };
  }
}

export default BySizeDataSetter;
