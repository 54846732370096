import React, { useEffect, useState, useContext } from "react";
import "../../../../../common-styles/TableStyle.css";
import "./AdminRequestListTable.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import AddLogoModal from "../modal/add-logo-modal";
import AdminListApis from "../../../services/AdminRequestListApis";
import { StateContext } from "../../../../../services/StateProvider";
import CommonUtils from "../../../../../services/utils";
// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    height: 530,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
    whiteSpace: "nowrap !important",
  },
}));

// USED FOR SORTING

const commonUtils = new CommonUtils();
function descendingComparator(a, b, orderBy) {
  if (orderBy === "Date") {
    let da = new Date(commonUtils.tableDateFormatter(a[orderBy]));
    let db = new Date(commonUtils.tableDateFormatter(b[orderBy]));
    if (db.getTime() < da.getTime()) {
      return -1;
    } else if (db.getTime() > da.getTime()) {
      return 1;
    } else {
      return 0;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    else if (b[orderBy] > a[orderBy]) {
      return 1;
    } else {
      return 0;
    }
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const AdminListTable = ({
  tableData,
  _filterApplied,
  showColumns,
  multipleRowsSelected,
  _locationList,
  _currentRoute,
}) => {
  const [globalAccountState] = useContext(StateContext);
  const [adminListTableData, setAdminListTableData] = useState(tableData);
  const adminListApis = new AdminListApis();
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ManufacturerName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [selectionIndex, setSelectionIndex] = useState([]);
  const [logoAddModal, setAddModal] = useState(false);
  const [logoData, setLogoData] = useState({});
  useEffect(() => {
    setAdminListTableData(null);
    setAdminListTableData(tableData);
    setPage(0);
    let row = [];
    row.length = adminListTableData.rowsData.length;
    for (let i = 0; i < adminListTableData.rowsData.length; i++) {
      row[i] = false;
    }
    setSelectionIndex(row);
    let arr = commonUtils.rowPerPageSelector(adminListTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowClick = (fieldType) => {
    if (fieldType === "Logo") {
      adminListApis
        .logoApproval(globalAccountState.accountInfo.userSetting.userId)
        .then((res) => {
          setLogoData(res.model);
          setAddModal(true);
        });
    }
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSelectAllClick = () => {
    let row = [];
    row.length = adminListTableData.rowsData.length;

    if (rowsSelected !== adminListTableData.rowsData.length) {
      setRowsSelected(adminListTableData.rowsData.length);
      Object.values(selectionIndex).forEach((_val, index) => {
        row[index] = true;
        return true;
      });
      setSelectionIndex(row);
      multipleRowsSelected(adminListTableData.rowsData);
    } else {
      setRowsSelected(0);
      Object.values(selectionIndex).forEach((_val, index) => {
        row[index] = false;
        return true;
      });
      setSelectionIndex(row);
      multipleRowsSelected([]);
    }
  };

  const onRowSelect = (index) => {
    let row = [...selectionIndex];
    let multiRow = [];
    let finalList = [];
    row.length = adminListTableData.rowsData.length;
    let rowCount = 0;
    if (selectionIndex[index]) {
      row[index] = !row[index];
      multiRow[index] = null;
      setSelectionIndex(row);
    } else {
      row[index] = true;
      setSelectionIndex(row);
    }
    Object.values(row).forEach((val, indexVal) => {
      if (val === true) {
        rowCount++;
        multiRow[indexVal] = adminListTableData.rowsData[indexVal];
      }
      return true;
    });
    Object.values(multiRow).forEach((val) => {
      if (val) {
        finalList.push(val);
      }
      return true;
    });

    multipleRowsSelected(finalList);
    setRowsSelected(rowCount);
  };

  const classNameSet = (_keyIndex, value) => {
    if (value === "Actions") {
      return "tire-table-head-row sticky-col last-col";
    }
    return "tire-table-head-row";
  };

  const handleAddModalClose = () => {
    setAddModal(false);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
              key={
                adminListTableData && adminListTableData.rowsData.length > 0
                  ? adminListTableData.rowsData[0].FieldType
                  : 1
              }
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.stickyHeader}
                    padding="checkbox"
                  >
                    <Checkbox
                      indeterminate={
                        rowsSelected > 0 &&
                        rowsSelected < adminListTableData.rowsData.length
                      }
                      checked={
                        adminListTableData.rowsData.length > 0 &&
                        rowsSelected === adminListTableData.rowsData.length
                      }
                      style={{
                        color: "#0055A4",
                        zIndex: 2,
                      }}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  </TableCell>
                  {adminListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className={classNameSet(
                          headCell.keyIndex,
                          headCell.value
                        )}
                        // className="tire-table-head-row"
                        key={headCell.keyIndex}
                        // align={headCell.numeric === true ? "right" : "left"}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort(headCell.value)}
                        >
                          {headCell.label}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {adminListTableData.rowsData.length > 0 && (
                <TableBody
                  key={`${"tablebody"}${
                    adminListTableData.rowsData[0].FieldType
                  }`}
                >
                  {stableSort(
                    adminListTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          // className={classNameSetBody(-2)}
                          onClick={() =>
                            handleRowClick(row.FieldType)
                          }
                          tabIndex={-1}
                          key={row.FieldType}
                        >
                          <TableCell
                            className="table-content-text"
                            padding="checkbox"
                          >
                            <Checkbox
                              checked={
                                rowsSelected ===
                                  adminListTableData.rowsData.length ||
                                selectionIndex[index] === true
                              }
                              style={{
                                color: "#0055A4",
                              }}
                              onChange={() => onRowSelect(index)}
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                            />
                          </TableCell>
                          {showColumns.Date && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Date}
                            </TableCell>
                          )}
                          {showColumns.FieldType && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.FieldType}
                            </TableCell>
                          )}
                          {showColumns.Description && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Description}
                            </TableCell>
                          )}
                          {showColumns.Status && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Status}
                            </TableCell>
                          )}
                          {showColumns.RequestedBy && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.RequestedBy}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}

              {adminListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={8}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {logoAddModal && (
            <AddLogoModal closeAddModal={handleAddModalClose} data={logoData} />
          )}
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={adminListTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default AdminListTable;
