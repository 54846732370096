import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  withStyles,
} from "@material-ui/core";
import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CalendarMonthIcon from '@material-ui/icons/Today';
import Globalization from "../../../../globalization";
import CommonUtils from "../../../../services/utils";
import ExpandMoreStyledIcon from "../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";

const Styles = makeStyles({
  root: {
    "& input.MuiAutocomplete-inputFocused": {
      color: "#000000 !important",
      fontWeight: "bold",
    },
  },
  buttons: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    font: "Barlow",
    opacity: 0.6,
    fontWeight: 500,
    color: "#FFFFFF",
    width: "100px",
    textAlign: "center",
  },
  adorn: {
    "& .MuiTypography-colorTextSecondary": {
      color: "#000000",
      fontWeight: "800",
    },
  },
  FormHelperText: {
    align: "center",
  },
  deleteBadge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "10px",
      right: "17px",
      background: "transparent",
    },
  },
});

const commonUtils = new CommonUtils();

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 24,
    padding: 0,
    margin: 5,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(36px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#D14449",
        borderColor: "#FFFFFF",
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 15,
    opacity: 1,
    backgroundColor: "#58CCE8",
  },
  checked: {},
}))(Switch);

const RepairDetails = (props) => {
  const classes = Styles();

  const treadReadings = props.locationData.noOfTreadDepthReadings;

  const globalizationMapping = new Globalization();
  const formCaptions = globalizationMapping.INSPECTNREMOVETIRE;
  const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;

  const handleDateChange = (value) => {
    props.onChangeListener("inspdate", value);
  };

  const wornCheckInside = () => {
    const originalTread = parseInt(props.tireData.originalTread, 10);
    const insideTread = parseInt(props.tireRemovalData.insideTread, 10);
    const removeAt = parseInt(props.tireRemovalData.removeAt, 10);
    return (
      Math.round((originalTread - insideTread) / (originalTread - removeAt)) *
      100
    );
  };

  const wornCheckMiddle = () => {
    const originalTread = parseInt(props.tireData.originalTread, 10);
    const middleTread = parseInt(props.tireRemovalData.middleTread, 10);
    const removeAt = parseInt(props.tireRemovalData.removeAt, 10);
    return (
      Math.round((originalTread - middleTread) / (originalTread - removeAt)) *
      100
    );
  };

  return (
    <Grid container className="step-form-inner-container" spacing={2}>
        <Grid item sm={12}>
          <p className="field-label">
            {formCaptions.DATE} <span className="required-mark">*</span>
          </p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="enter-detail-input-box"
              // variant="inline"
              value={props.tireRemovalData.inspectionDate}
              onChange={handleDateChange}
              format={commonUtils.getDateFormat()}
              placeholder={commonUtils.getDateFormat()}
              keyboardIcon={<CalendarMonthIcon />}
              helperText=""
            />
          </MuiPickersUtilsProvider>
          <FormHelperText>{props.formHelpers.inspectionDate}</FormHelperText>
        </Grid>
        <Grid item sm={12}>
          <p className="field-label">
            {globalizationMapping.REPORTTABLE.INSPID}
          </p>
          <TextField
            className="enter-detail-input-box"
            variant="outlined"
            defaultValue=""
            onChange={(e) =>
              props.onRemovalReasonChange("otherreason", commonUtils.removeLeadingSpaces(e.target.value))
            }
            placeholder={globalizationMapping.PLACEHOLDER.InspectionId}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        {props.masterDataSet &&
          props.masterDataSet.tireRemovalReasons &&
          props.masterDataSet.tireRemovalReasons.length > 0 && (
            <Grid item sm={12}>
              <p className="field-label">{formCaptions.REMOVALREASON} </p>
              <Select
                native
                className="enter-detail-input-box"
                variant="outlined"
                value={props.tireRemovalData.removalReasonId}
                IconComponent={ExpandMoreStyledIcon}
                onChange={(e) =>
                  props.onRemovalReasonChange("removalreason", parseInt(e.target.value, 10))
                }
              >
                <option key={0} value={0} disabled>
                  -{globalizationMapping.REPORTCOMMONWORDS.SELECT}-
                </option>
                {props.masterDataSet.tireRemovalReasons.map((reason) => (
                  <option key={reason.reasonId} value={reason.reasonId}>
                    {reason.reasonName}
                  </option>
                ))}
              </Select>
              <FormHelperText>
                {props.formHelpers.removalReasonId}
              </FormHelperText>
            </Grid>
          )}
        {props.isRemovalReasonNew && (
          <Grid item sm={12}>
            <TextField
              className="enter-detail-input-box"
              variant="outlined"
              value={
                props.removalMasterData[0] !== undefined
                  ? props.removalMasterData[0].description
                  : ""
              }
              onChange={(e) =>
                props.onRemovalReasonChange("otherreason", commonUtils.removeLeadingSpaces(e.target.value))
              }
              placeholder={`${tireFormCaptions.ENTER} ${formCaptions.REMOVALREASON}`}
              inputProps={{ maxLength: 200 }}
            />
            <FormHelperText>{props.formHelpers.otherreason}</FormHelperText>
          </Grid>
        )}
        <Grid item sm={12}>
          <p style={{ display: "inline" }}>
            <span className="field-label additional1">
              {formCaptions.OUTTRD}&nbsp;
              <span className="required-mark">*</span>
            </span>
            <span className="field-label additional2">
              {isNaN(
                Math.round(
                  ((parseInt(props.tireData.originalTread, 10) -
                    parseInt(props.tireRemovalData.outsideTread, 10)) /
                    (parseInt(props.tireData.originalTread, 10) -
                      parseInt(props.tireData.removeAt, 10))) *
                    100
                )
              )
                ? 0
                : Math.round(
                    ((parseInt(props.tireData.originalTread, 10) -
                      parseInt(props.tireRemovalData.outsideTread, 10)) /
                      (parseInt(props.tireData.originalTread, 10) -
                        parseInt(props.tireData.removeAt, 10))) *
                      100
                  )}
              {formCaptions.PERCENTAGEWORN}
            </span>
          </p>
          <ButtonGroup size="large" aria-label="medium outlined button group">
            <Button
              className={classes.buttons}
              onClick={() =>
                props.onChangeListener(
                  "outtread",
                  props.tireRemovalData.outsideTread - 1
                )
              }
            >
              -
            </Button>
            <FormControl
              variant="outlined"
              className="enter-detail-input-box buttoned-input"
            >
              <OutlinedInput
                value={
                  !isNaN(parseInt(props.tireRemovalData.outsideTread, 10))
                    ? props.tireRemovalData.outsideTread
                    : ""
                }
                type="number"
                onChange={(e) =>
                  props.onChangeListener("outtread",commonUtils.minmaxInt(e.target.value, 0, 999999))
                }
              />
            </FormControl>
            <Button
              className={classes.buttons}
              onClick={() =>
                props.onChangeListener(
                  "outtread",
                  props.tireRemovalData.outsideTread + 1
                )
              }
            >
              +
            </Button>
          </ButtonGroup>
          <FormHelperText>{props.formHelpers.outsideTread}</FormHelperText>
        </Grid>
        {(treadReadings === 2 || treadReadings === 3) && (
          <Grid item sm={12}>
            <div style={{ display: "inline" }}>
              <span className="field-label additional1">
                {formCaptions.INTRD}&nbsp;
                <span className="required-mark">*</span>
              </span>
              <span className="field-label additional2">
                {isNaN(wornCheckInside()) && 0}
                {!isNaN(wornCheckInside()) && wornCheckInside()}
                {formCaptions.PERCENTAGEWORN}
              </span>
            </div>
            <ButtonGroup size="large" aria-label="medium outlined button group">
              <Button
                className={classes.buttons}
                onClick={() =>
                  props.onChangeListener( "intread", props.tireRemovalData.insideTread - 1)}
              >
                -
              </Button>
              <FormControl
                variant="outlined"
                className="enter-detail-input-box buttoned-input"
              >
                <OutlinedInput
                  value={
                    !isNaN(parseInt(props.tireRemovalData.insideTread, 10))
                      ? props.tireRemovalData.insideTread
                      : ""
                  }
                  type="number"
                  onChange={(e) =>
                    props.onChangeListener(
                      "intread",
                      commonUtils.minmaxInt(e.target.value, 0, 999999)
                    )
                  }
                />
              </FormControl>
              <Button
                className={classes.buttons}
                onClick={() =>
                  props.onChangeListener(
                    "intread",
                    props.tireRemovalData.insideTread + 1
                  )
                }
              >
                +
              </Button>
            </ButtonGroup>
            <FormHelperText>{props.formHelpers.insideTread}</FormHelperText>
          </Grid>
        )}
        {treadReadings === 3 && (
          <Grid item sm={12}>
            <div style={{ display: "inline" }}>
              <span className="field-label additional1">
                {formCaptions.MIDTRD}&nbsp;
                <span className="required-mark">*</span>
              </span>
              <span className="field-label additional2">
                {isNaN(wornCheckMiddle()) && 0}
                {!isNaN(wornCheckMiddle()) && wornCheckMiddle()}
                {formCaptions.PERCENTAGEWORN}
              </span>
            </div>
            <ButtonGroup size="large" aria-label="medium outlined button group">
              <Button
                className={classes.buttons}
                onClick={() =>
                  props.onChangeListener(
                    "midtread",
                    props.tireRemovalData.middleTread - 1
                  )
                }
              >
                -
              </Button>
              <FormControl
                variant="outlined"
                className="enter-detail-input-box buttoned-input"
              >
                <OutlinedInput
                  value={
                    !isNaN(parseInt(props.tireRemovalData.middleTread, 10))
                      ? props.tireRemovalData.middleTread
                      : ""
                  }
                  type="number"
                  onChange={(e) =>
                    props.onChangeListener(
                      "midtread",
                      commonUtils.minmaxInt(e.target.value, 0, 999999)
                    )
                  }
                />
              </FormControl>
              <Button
                className={classes.buttons}
                onClick={() =>
                  props.onChangeListener(
                    "midtread",
                    props.tireRemovalData.middleTread + 1
                  )
                }
              >
                +
              </Button>
            </ButtonGroup>
            <FormHelperText>{props.formHelpers.middleTread}</FormHelperText>
          </Grid>
        )}
        <Grid item sm={12}>
          {props.locationData.treadDepthMethodId === 3 && (
            <p className="field-label">{tireFormCaptions.MINTRD}</p>
          )}
          {props.locationData.treadDepthMethodId === 2 ? (
            <p className="field-label">{tireFormCaptions.MAXTRD}</p>
          ) : (
            <p className="field-label">{tireFormCaptions.AVGTRD}</p>
          )}
          <TextField
            className="enter-detail-input-box disabled-input"
            variant="outlined"
            value={props.tireData.currentTreadDepth}
            disabled
          />
        </Grid>
        <Grid item sm={12}>
          <p className="field-label">
            {formCaptions.AIRPRESSURETYPE}&nbsp;&nbsp;
            <span className="required-mark">*</span>
          </p>
          <FormControlLabel
            style={{
              float: "left",
              marginBlockStart: "15px",
              fontWeight: "600",
            }}
            control={
              <AntSwitch
                checked={
                  props.tireRemovalData.pressureType === "H" ? true : false
                }
                onChange={(e) =>
                  props.onChangeListener("pressuretype", e.target.checked)
                }
              />
            }
            label={
              props.tireRemovalData.pressureType === "H"
                ? globalizationMapping.INSPECT.HOT
                : globalizationMapping.INSPECT.COLD
            }
            labelPlacement="end"
          />
        </Grid>
        <Grid item sm={12}>
          <p className="field-label">
            {formCaptions.AIRPRESSURE}&nbsp;&nbsp;
            <span className="required-mark">*</span>
          </p>
          <ButtonGroup size="large" aria-label="medium outlined button group">
            <Button
              className={classes.buttons}
              onClick={() =>
                props.onChangeListener(
                  "pressure",
                  props.tireRemovalData.currentPressure - 1
                )
              }
            >
              -
            </Button>
            <FormControl variant="outlined" className="enter-detail-input-box">
              <OutlinedInput
                value={
                  !isNaN(parseInt(props.tireRemovalData.currentPressure, 10))
                    ? props.tireRemovalData.currentPressure
                    : ""
                }
                type="number"
                endAdornment={
                  <InputAdornment className={classes.adorn} position="end">
                    PSI
                  </InputAdornment>
                }
                onChange={(e) =>
                  props.onChangeListener(
                    "pressure",
                    commonUtils.minmaxInt(e.target.value, 0, 999999)
                  )
                }
              />
            </FormControl>
            <Button
              className={classes.buttons}
              onClick={() =>
                props.onChangeListener(
                  "pressure",
                  props.tireRemovalData.currentPressure + 1
                )
              }
            >
              +
            </Button>
          </ButtonGroup>
          <FormHelperText>{props.formHelpers.currentPressure}</FormHelperText>
        </Grid>
        {props.masterDataSet &&
          props.masterDataSet.casingCondition &&
          props.masterDataSet.casingCondition.length > 0 && (
            <Grid item sm={12}>
              <p className="field-label">{formCaptions.CASINGCONDITION}</p>
              <Select
                native
                className="enter-detail-input-box"
                variant="outlined"
                value={props.tireRemovalData.casingConditionId}
                IconComponent={ExpandMoreStyledIcon}
                onChange={(e) =>
                  props.onChangeListener("casing", parseInt(e.target.value, 10))
                }
              >
                <option key={0} value={0} disabled>
                  {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                </option>
                {props.masterDataSet.casingCondition.map((condition) => (
                  <option
                    key={condition.casingConditionId}
                    value={condition.casingConditionId}
                  >
                    {condition.casingConditionName}
                  </option>
                ))}
              </Select>
            </Grid>
          )}
        {props.masterDataSet &&
          props.masterDataSet.casingCondition &&
          props.masterDataSet.casingCondition.length > 0 && (
            <Grid item sm={12}>
              <p className="field-label">{formCaptions.WEARCONDITION}</p>
              <Select
                native
                className="enter-detail-input-box"
                variant="outlined"
                value={props.tireRemovalData.wearConditionId}
                IconComponent={ExpandMoreStyledIcon}
                onChange={(e) =>
                  props.onChangeListener("wear", parseInt(e.target.value, 10))
                }
              >
                <option key={0} value={0} disabled>
                  {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                </option>
                {props.masterDataSet.wearConditions.map((condition) => (
                  <option
                    key={condition.wearConditionId}
                    value={condition.wearConditionId}
                  >
                    {condition.wearConditionName}
                  </option>
                ))}
              </Select>
            </Grid>
          )}
        <Grid item sm={12}>
          <p className="field-label">{tireFormCaptions.CASINGVALUE}</p>
          <TextField
            className="enter-detail-input-box"
            variant="outlined"
            value={props.tireData.casingValue}
            onChange={(e) => {
              props.onChangeListener("casingval", commonUtils.minmaxInt(e.target.value, 0, 999999));
            }}
            type="number"
          />
        </Grid>

        <Grid item sm={12}>
          <p className="field-label">{formCaptions.COMMENTS}</p>
          <TextField
            className="enter-detail-input-box"
            variant="outlined"
            value={props.tireRemovalData.comments}
            onChange={(e) => {
              props.onChangeListener("comments", commonUtils.removeLeadingSpaces(e.target.value));
            }}
            multiline
            rows={2}
            type="text"
            placeholder={globalizationMapping.PLACEHOLDER.Comments}
            inputProps={{ maxLength: 200 }}
          />
        </Grid>
      </Grid>
  );
};

export default RepairDetails;
