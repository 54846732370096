import React, { useEffect, useState } from "react";
import "../../../../../common-styles/TableStyle.css";
import "./Table.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../globalization";
import CommonUtils from "../../../../../services/utils";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    maxHeight: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
  },
  next: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
}));

const VehicleHistoryListTableViewOnly = ({
  tableData,
  showColumns,
  userPref,
}) => {
  const [vehicleHistoryListTableData, setVehicleHistoryListTableData] =
    useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const commonUtils = new CommonUtils();

  // USED FOR SORTING
  function descendingComparator(a, b, sOrderBy) {
    if (b[sOrderBy] < a[sOrderBy]) {
      return -1;
    }
    if (b[sOrderBy] > a[sOrderBy]) {
      return 1;
    }
    return 0;
  }

  function descendingComparatorDate(a, b, sOrderBy) {
    let aDay, aMonth, aYear, bMonth, bDay, bYear;
    if (sessionStorage.userLanguage === "en-uk") {
      [aDay, aMonth, aYear] = a[sOrderBy].split("/");
      [bDay, bMonth, bYear] = b[sOrderBy].split("/");
    } else {
      [aMonth, aDay, aYear] = a[sOrderBy].split("/");
      [bMonth, bDay, bYear] = b[sOrderBy].split("/");
    }
    if (new Date(bYear, bMonth - 1, bDay) < new Date(aYear, aMonth - 1, aDay)) {
      return -1;
    }
    if (new Date(bYear, bMonth - 1, bDay) > new Date(aYear, aMonth - 1, aDay)) {
      return 1;
    }
    return 0;
  }

  function getComparator(sorder, sOrderBy) {
    if (sOrderBy === "Date") {
      return sorder === "desc"
        ? (a, b) => descendingComparatorDate(a, b, sOrderBy)
        : (a, b) => -descendingComparatorDate(a, b, sOrderBy);
    } else {
      return sorder === "desc"
        ? (a, b) => descendingComparator(a, b, sOrderBy)
        : (a, b) => -descendingComparator(a, b, sOrderBy);
    }
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const sorder = comparator(a[0], b[0]);
      if (Number(order) !== 0) {
        return sorder;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    setVehicleHistoryListTableData(tableData);
    let arr = commonUtils.rowPerPageSelector(vehicleHistoryListTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const userPrefSelection = () => {
    return userPref.toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const globalizationMapping = new Globalization();
  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <InputLabel className="filter-table-label">
            <span className="table-toolbar-label-text">
              {globalizationMapping.QUICKINSPECT.VEHICLEHISTORY}
            </span>
          </InputLabel>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {vehicleHistoryListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort(headCell.value)}
                        >
                          {headCell.value === "Distance"
                            ? userPrefSelection()
                            : headCell.label}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
                <tr className="table-bottom-line">
                  <td
                    colSpan={vehicleHistoryListTableData.headCells.length}
                  ></td>
                </tr>
              </TableHead>

              {vehicleHistoryListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    vehicleHistoryListTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          tabIndex={-1}
                          key={row.Key}
                        >
                          {showColumns.Date && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Date}
                            </TableCell>
                          )}
                          {showColumns.Event && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Event}
                            </TableCell>
                          )}
                          {showColumns.Hours && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Hours}
                            </TableCell>
                          )}
                          {showColumns.Distance && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Distance}
                            </TableCell>
                          )}
                          {showColumns.Comments && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                            >
                              {row.Comments}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {vehicleHistoryListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={8}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={vehicleHistoryListTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default VehicleHistoryListTableViewOnly;
