import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";

// eslint-disable-next-line no-unused-vars
import Globalization from "../../../../../../../globalization";
const TireInflationGraph = ({ tireInflationGraphData }) => {
  const commonUtils = new CommonUtils();
  let emptyData = true;
  for (const element of tireInflationGraphData.count) {
    if (element !== "0") {
      emptyData = false;
      break;
    }
  }

  let totalValue = 0;
  let chartData = tireInflationGraphData.chartData;
  if (tireInflationGraphData.graphType === "Pie") {
    chartData.forEach((value) => {
      totalValue += value;
    });

    chartData = chartData.map((value) => {
      return ((value / totalValue) * 100).toFixed(1);
    });
  }
  //

  const graphData = {
    labels: tireInflationGraphData.chartlabels,
    datasets: [
      {
        backgroundColor: commonUtils.colorArr,
        hoverBackgroundColor: commonUtils.borderColor,
        data: chartData,
      },
    ],
  };


  const pieOptions = commonUtils.pieOptions;
  const barOptions = commonUtils.barOptions;
  pieOptions.title.text =
    new Globalization().VEHICLEREPORTS.BYTIRES.TireInflation;
  barOptions.title.text =
    new Globalization().VEHICLEREPORTS.BYTIRES.TireInflation;

  return (
    <div className="percentage-worn-graph-container">
      {!emptyData && (
        <Grid md={12} sm={12}>
          {tireInflationGraphData.graphType === "Pie" && (
            <Doughnut
              height={90}
              data={graphData}
              options={pieOptions}
            ></Doughnut>
          )}
          {tireInflationGraphData.graphType === "Bar" && (
            <Bar height={90} data={graphData} options={barOptions}></Bar>
          )}
        </Grid>
      )}
      {emptyData && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default TireInflationGraph;
