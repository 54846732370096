import React, { useEffect, useContext, useState } from "react";
import "./ManageRimList.css";
import "../../../../../common-styles/TableFunctionOptions.css";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ManageRimListApis from "../../../services/ManageRimListApis.js";
import ManageRimListSetter from "../../../services/ManageRimListUtils.js";
import { StateContext } from "../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import Spinner from "../../../../../common-components/spinner/spinner";
import ManageRimListTableViewOnly from "../table/ManageRimListTableViewOnly";
import CommonUtils from "../../../../../services/utils";
import SearchIcon from "../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import Breadcrumbs from "../../../../../common-components/breadcrumbs/breadcrumbs";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import FlashMessage from "../../../../../common-components/pop-up-notification/notification-snackbar";
import DeleteRimModal from "../../modals/DeleteRim";
import Globalization from "../../../../../globalization";
import { useComponentVisible } from "../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ManageRimListFilter from '../filter/ManageRimListFilter';

const ManageRimListViewOnly = () => {
  const headerApiObj = new HeaderApi();
  const commonUtils = new CommonUtils();
  const manageRimListDataSetter = new ManageRimListSetter();
  const manageRimListService = new ManageRimListApis();
  const globalizationMapping = new Globalization();

  const [manageRimListTableDataByLocation, setManageRimListTableDataByLocation] = useState();
  const [manageRimListTableCurrentData, setManageRimListTableCurrentData] = useState();
  const [transferDone, setTransferDone] = useState(commonUtils.generateRandomNumber());
  const locationObj = useLocation();
  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [manageRimListTableData, setManageRimListTableData] = useState();
  const [originalManageRimListTableData, setOriginalManageRimListTableData] =
    useState();
  const [showColumns, setShowColumns] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(manageRimListTableData);
  const [searchValue, setSearchValue] = useState("");
  const [noData, setNoData] = useState(false);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [action, setAction] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [flashMessageState, setFlashMessageState] = useState(locationObj);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false,
  });
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      {
        name: globalizationMapping.MANAGERIM.RIMSERIALNUMBER,
        value: "RimSerialNumber",
      },
      {
        name: globalizationMapping.MANAGERIM.DISPOSITION,
        value: "DispositionName",
      },
      {
        name: globalizationMapping.MANAGERIM.TIRESERIALNUMBER,
        value: "TireSerialNumber",
      },
    ],
    categoryData: {
      RimSerialNumber: [],
      DispositionName: [],
      TireSerialNumber: [],
    },
  });
  const breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME,
    },
    {
      path: "",
      name: globalizationMapping.MANAGERIM.ManageRim
    }
  ]
  const [forceActionPopupClose, setForceActionPopupClose] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  useEffect(() => {

    ManageRimResponseData();
    if (locationObj.state !== undefined) {
      switch (locationObj.state.updatedSection) {
        case "editoldrim":
        case "createnewrim":
        case "delterim":
          if (locationObj.state.updateStatus) {
            setShowNotification(true);
          }
          break;
        default:
          setTimeout(() => {
            setShowNotification(false);
          }, 5000);
          break;
      }
      setFlashMessageState(locationObj);
    }

    setTimeout(() => {
      window.localStorage.setItem("Count", 0)
    }, 3000);

    setAction([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, transferDone]);

  const multipleRowsSelected = (rows) => {
    setAction([...rows]);
  }

  const ManageRimResponseData = () => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    setShowSpinner(true);
    manageRimListService.GetRimsByAccount(accountIds[0]).then((data) => {
      headerApiObj.getMasterDataWeb().then((masterData) => {
        if (masterData.model !== undefined) {
          data.model.forEach((element, key) => {
            let index = masterData.model.rimDispositions.findIndex(
              (x) => x.dispositionId === element.dispositionId
            );
            data.model[key]["dispositionName"] = masterData.model.rimDispositions[index].dispositionName;
          });
          let manageRimListTableInfo = manageRimListDataSetter.setData(data, globalAccountState);
          let filterOptionsInfo = manageRimListDataSetter.setFilterOptionsData(manageRimListTableInfo.rowsData);
          let manageVehicleListTableInfoByLocation = manageRimListDataSetter.setDataByLocation(data, accountInfoObject.locationIds);
          let rimDataShown = { ...manageRimListTableInfo };
          rimDataShown.rowsData = manageVehicleListTableInfoByLocation;
          setManageRimListTableDataByLocation(rimDataShown);
          setManageRimListTableData(rimDataShown);
          setManageRimListTableCurrentData(rimDataShown.rowsData);
          setOriginalManageRimListTableData(manageRimListTableInfo);
          rimDataShown.rowsData.length === 0
            ? setNoData(true)
            : setNoData(false);
          setFilterOptions(filterOptionsInfo);
          setShowColumns(manageRimListTableInfo.selectedColShow);
          setPrintData({
            reportType: "",
            reportName: globalizationMapping.MANAGERIM.RimList,
            rowsData: rimDataShown.rowsData,
            headCells: manageRimListTableInfo.headCells,
            selectedColShow: manageRimListTableInfo.selectedColShow,
          });
          setShowSpinner(false);
        }
      });
    })
  }


  const onClickExport = (type) => {
    setForceActionPopupClose(true);
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]
        );
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    setForceActionPopupClose(true);
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);

      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    setForceActionPopupClose(true);
    setModalHandler({ handleEmail: true });
  };

  const onClickFilter = () => {
    setForceActionPopupClose(true);
    setShowFilter(true);
  };

  const forceActionPopupCloseToFalse = () => {
    setForceActionPopupClose(false);
  }

  // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    setCurrentFilter(appliedFilter);
    setSearchValue("");
    let originalTableDataStore = originalManageRimListTableData?.rowsData;
    let filteredData = manageRimListDataSetter.filterTable(
      appliedFilter,
      originalTableDataStore
    );
    setManageRimListTableData({
      ...manageRimListTableData,
      rowsData: filteredData,
    });
    setPrintData({ ...printData, rowsData: filteredData });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setManageRimListTableCurrentData(filteredData);
    Object.values(appliedFilter).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      RimSerialNumber: [],
      DispositionName: [],
      TireSerialNumber: [],
    }); // RESET CURRENT FILTER  
    setSearchValue("");
    setPrintData({
      ...printData,
      rowsData: manageRimListTableDataByLocation?.rowsData,
    });
    setManageRimListTableData(manageRimListTableDataByLocation);
    setNoData(false);
    setOnlyFilterApplied(false);
    setManageRimListTableCurrentData(
      manageRimListTableDataByLocation?.rowsData
    );
  };
  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setForceActionPopupClose(true);
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    let originalTableDataStore,
      filteredData = [];
    let updatedTableData = {};
    originalTableDataStore = manageRimListTableCurrentData;
    if (searchText) {
      filteredData = manageRimListDataSetter.search(
        originalTableDataStore,
        searchText
      );

      updatedTableData = {
        rowsData: filteredData,
        headCells: originalManageRimListTableData?.headCells,
        selectedColShow: originalManageRimListTableData?.selectedColShow,
      };
    } else {
      filteredData = originalTableDataStore;
      updatedTableData = {
        rowsData: originalTableDataStore,
        headCells: originalManageRimListTableData?.headCells,
        selectedColShow: originalManageRimListTableData?.selectedColShow,
      };
    }
    setManageRimListTableData(updatedTableData);
    setPrintData({
      reportType: "",
      reportName: globalizationMapping.MANAGERIM.RimList,
      rowsData: filteredData,
      headCells: originalManageRimListTableData?.headCells,
      selectedColShow: originalManageRimListTableData?.selectedColShow,
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };

  const [compareTranfer, setCompareTransfer] = useState({})
  const deleteRimDone = (transferState) => {

    if (transferState.state.updatedSection === "delterim") {
      if (transferState.state.updateStatus) {
        setShowNotification(true);
      }
    } else {
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }

    if (transferState.state.updateStatus) {
      setTransferDone(commonUtils.generateRandomNumber());
      setFlashMessageState(transferState);
      setCompareTransfer(transferState)
      setTimeout(() => {
        setShowNotification(false);
        setFlashMessageState({});
      }, 3000);
    }
  };
  const handleDeleteModalClose = (deleteData) => {
    setDeleteModalOpen(false);
    let notification = { state: deleteData };
    deleteRimDone(notification);
    if (deleteData.state && deleteData.state.updateStatus) setAction([]);
  };
  const actionChanged = (action) => {
    if (action === "Delete") {
      handleDeleteClick();
    }
  };

  const toggleExportDropdown = () => {
    setForceActionPopupClose(true);
    setIsComponentVisible(!isComponentVisible);
  }

  return (
    <div className="standalone-new-header-container">
      {
        compareTranfer?.state?.updatedSection !== undefined ?
          (compareTranfer.state.updatedSection === "delterim") &&
          showNotification && flashMessageState.state && (

            < FlashMessage
              status={flashMessageState.state.updateStatus}
              message={flashMessageState.state.successMessage}
              description={flashMessageState.state.successMessageDesc}
            />
          ) :
          showNotification && flashMessageState.state && Number(window.localStorage.getItem("Count")) > 0 && (
            <FlashMessage
              status={flashMessageState.state.updateStatus}
              message={flashMessageState.state.successMessage}
              description={flashMessageState.state.successMessageDesc}
            />
          )
      }
      <div className="manage-rim-main-page-container">
        <div className="manage-rim-upper-half">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {globalizationMapping.MANAGERIM.ManageRim}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="manage-rim-list-container">
          <Card className="manage-rim-list-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container-vehicle">
                    <form
                      className=""
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span
                          className={
                            action.length > 1
                              ? "enable-table-toolbar-label-text"
                              : "disabled-table-toolbar-label-text"
                          }
                        >
                          {`${action.length} ${globalizationMapping.ADMIN.RowsSelected}`}
                        </span>
                      </InputLabel>
                      <Select
                        native
                        placeholder="Actions"
                        defaultValue="Actions"
                        className={
                          action.length > 1
                            ? "enable-manage-vehicle-action-dropdown"
                            : "disabled-manage-vehicle-action-dropdown"
                        }
                        variant="outlined"
                        value={"Actions"}
                        IconComponent={ExpandMoreStyledIcon}
                        onChange={(e) => actionChanged(e.target.value)}
                        disabled={action.length <= 1}
                      >
                        <option value={"Actions"} disabled>
                          {globalizationMapping.MANAGE_PARENT_ACCOUNT.OPTION1}
                        </option>
                        <option value={"Delete"}>
                          {globalizationMapping.MANAGE_PARENT_ACCOUNT.OPTION3}
                        </option>
                      </Select>
                    </form>
                  </div>

                  <div className="search-table-container">
                    <form
                      className="search-table-container"
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.REPORTCOMMONWORDS.SearchTable}
                        </span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={
                          globalizationMapping.REPORTCOMMONWORDS.Search
                        }
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="keyword-search-icon-img"
                                alt=""
                                src={SearchIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                  onClickFilter={onClickFilter}
                  onClickEmail={onClickEmail}
                  onClickPrint={onClickPrint}
                  onClickExport={onClickExport}
                  toggleExportDropdown={toggleExportDropdown}
                  printData={printData}
                  ref={ref}
                  onlyFilterApplied={onlyFilterApplied}
                  noData={noData}
                  isComponentVisible={isComponentVisible}
                />
              </div>
              <div className="data-summary-table">
                {manageRimListTableData && (
                  <ManageRimListTableViewOnly
                    tableData={manageRimListTableData}
                    showColumns={showColumns}
                    multipleRowsSelected={multipleRowsSelected}
                    deleteRimDone={deleteRimDone}
                    forceActionPopupClose={forceActionPopupClose}
                    forceActionPopupCloseToFalse={forceActionPopupCloseToFalse}
                  />
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {isDeleteModalOpen && (
        <DeleteRimModal
          closeDeleteModal={handleDeleteModalClose}
          rimIds={action}
          isSelectionMultiple={true}
          isModalOpen={isDeleteModalOpen}
        />
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <EmailExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />

      <ManageRimListFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
      ></ManageRimListFilter>
    </div>
  );
};
export default ManageRimListViewOnly;
