import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';

export default class FleetService {
    cs;
    constructor(){
         this.cs = new CommonService(); 
    }
    getFleetData = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getReport,'post',req)
    }

}