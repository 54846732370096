import React from "react";
import { Doughnut } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization";
const ByPercentageWornGraph = ({ byPercentageWornGraphData }) => {
  const globalizationMapping = new Globalization();

  let veryLow = 0,
    low = 0,
    medium = 0,
    high = 0;
  byPercentageWornGraphData.forEach((item) => {
    if (item.PercentageWorn <= 25) {
      veryLow += item.TireCount;
    } else if (item.PercentageWorn > 25 && item.PercentageWorn <= 50) {
      low += item.TireCount;
    } else if (item.PercentageWorn > 50 && item.PercentageWorn <= 75) {
      medium += item.TireCount;
    } else {
      high += item.TireCount;
    }
  });

  let chartLabels = ["< 25%, " + veryLow + " Tire(s)", "< 50%, " + low + " Tire(s)", "< 75%, " + medium + " Tire(s)", "> 75%, " + high + " Tire(s)"];
  let chartData = [veryLow, low, medium, high];
  let totalValue = 0;
  chartData.forEach((value) => {
    totalValue += value;
  });

  chartData = chartData.map((value) => {
    return ((value / totalValue) * 100).toFixed(1);
  });

  const commonUtils = new CommonUtils();
  const graphData = {
    labels: chartLabels,
    datasets: [
      {
        backgroundColor: ["#00AF32", "#f0de00", "#EDBD00", "#ff0100"],
        // backgroundColor: commonUtils.colorArr,
        hoverBackgroundColor: ["#00AF32", "#f0de00", "#EDBD00", "#ff0100"],
        data: chartData
      }
    ]
  };

  const options = commonUtils.pieOptions;
  options.title.text = globalizationMapping.REPORTCOMMONWORDS.ByPercWorn;
  return (
    <div className="percentage-worn-graph-container">
      {byPercentageWornGraphData.length > 0 && (
        <Grid container item md={12} sm={12}>
          <Doughnut height={90} data={graphData} options={options}></Doughnut>
        </Grid>
      )}
      {byPercentageWornGraphData.length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ByPercentageWornGraph;
