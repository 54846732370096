import { Switch, Tooltip } from "@material-ui/core";
import React from "react";
import GroupedButtons from "../components/list-vehicles/groupedButtons/groupedButton";
import Dragula from "react-dragula";
import Globalization from "../../../globalization";
import { InspectionOrderConfiguration } from "../../../constants/InspectionOrder";
import TireAGreen from "../../../assets/Tire_a_green.png";
import TireAYellow from "../../../assets/Tire_a_yellow.png";
import TireAOrange from "../../../assets/Tire_a_orange.png";
import TireARed from "../../../assets/Tire_a_red.png";
import WarningIcon from '@material-ui/icons/Warning';
import PageViewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
class TireConfigUtils extends React.Component {
  constructor(props) {
    super(props);
    const inspOrder = props.inspectionOrder;
    this.configArrangement = InspectionOrderConfiguration.TirePositionConfig[inspOrder + ""][props.tireDetails.axleConfig + ""];
    this.tireDetails = {};
    this.positionArray = [];
    this.elementDiv = [];
    this.tirePositionArray = [];
    this.changedTire = [];
    this.vehicleNumber = "";
    this.positionVehicleIdDiv = "fixed";
    this.emptyTirePosition = [];
    this.filledTirePositions = [];
    this.state = {
      outsideTread: "",
      middleTread: "",
      insideTread: "",
      noOfReadings: 0,
      element: []
    };
    this.userPressureUnit = "PSI";
    if (this.props) {
      this.history = this.props.history;
    }
  }
  
  shouldComponentUpdate = (nextProps, _nextState) => nextProps.vehicleNumber !== this.vehicleNumber;

  UNSAFE_componentWillMount = () => {
    window.addEventListener("scroll", this.listenToScroll);
    this.userPressureUnit = this.props.userPressureUnit;
    this.tireConfigUI(this.props.vehicleNumber, this.props.tireDetails, this.props.positionArray);
    const options = {
      isContainer(_el) {
        return false;
      },
      moves(el, _source, _handle, _sibling) {
        // elements are always draggable by default
        if (el.classList.contains("rimOnlyImg")) {
          return false;
        } else return !(el.classList.contains("axleConfigBar") || el.classList.contains("empty-tire-space-quick-inspect"));

      },
      accepts(_el, target, _source, _sibling) {
        // elements can be dropped in any of the `containers` by default
        return target.classList.contains("empty-tire-space-quick-inspect");
      },
      invalid(el, _handle) {
        // prevent any drags from initiating by default
        return el.classList.contains("empty-tire-space-quick-inspect");
      },
      // something is wrong above
      direction: "horizontal", // Y axis is considered when determining where an element would be dropped
      copy: false, // elements are moved by default, not copied
      copySortSource: false, // elements in copy-source containers can be reordered
      revertOnSpill: true, // spilling will put the element back where it was dragged from, if this is true
      removeOnSpill: false, // spilling will `.remove` the element, if this is true
      mirrorContainer: document.body, // set the element that gets mirror elements appended
      ignoreInputTextSelection: true, // allows users to select input text, see details below
      slideFactorX: 0, // allows users to select the amount of movement on the X axis before it is considered a drag instead of a click
      slideFactorY: 0
    };
    this.dragula = new Dragula([], options);
    this.dragula.on("drop", this.handleDropDragula);
  };
  globalizationMapping = new Globalization();
  handleDropDragula = (_el, target, source, _sibling) => {
    let desIndex = target.dataset.type;
    let sourceIndex = source.dataset.type;

    const positionDesIndexName = desIndex.split(".")[0];
    const positionSourceIndexName = sourceIndex.split(".")[0];
    desIndex = desIndex.split(".")[1];
    sourceIndex = sourceIndex.split(".")[1];
    const result = {
      destination: {
        index: desIndex,
        out: positionDesIndexName === "Out" ? true : false
      },
      source: {
        index: sourceIndex,
        out: positionSourceIndexName === "Out" ? true : false
      }
    };
    this.dragula.cancel(true);
    this.handleDrag(result);
  };

  presentInArray = (element) => {
    let flag = false;
    this.positionArray.forEach((posArr) => {
      if (posArr === element) {
        flag = true;
      }
    });
    return flag;
  };

  handleDrag = (result) => {
    if (result.destination) {
      let tirePositionIndex = -1;
      const destination = parseInt(result.destination.index, 10);
      const source = parseInt(result.source.index, 10);
      if (result.source.out) {
        this.tireDetails.installedTires.forEach((item, tireIndex) => {
          if (item.wheelPosition === source + "Out") {
            item.wheelPosition = this.tirePositionArray[destination];
            tirePositionIndex = tireIndex;
            item.action = "Rotate";
            item.maxIn = item.insideTread;
            item.maxMid = item.middleTread;
            item.maxOut = item.outsideTread;
            item.minTread = item.removeAt;
            this.changedTire.push(item);
          }
        });
        const indexTireOut = this.tireDetails.installedTires.findIndex((val) => val.wheelPosition === `${source - 1}Out`);
        this.tireDetails.installedTires[indexTireOut].wheelPosition = this.tirePositionArray[destination];
        this.positionArray.push(this.tireDetails.installedTires[indexTireOut].wheelPosition);
      } else if (result.destination.out) {
        this.tireDetails.installedTires.forEach((item, tireIndex) => {
          if (item.wheelPosition === this.tirePositionArray[source]) {
            item.wheelPosition = "Out";
            tirePositionIndex = tireIndex;
            item.action = "Rotate";
            item.maxIn = item.insideTread;
            item.maxMid = item.middleTread;
            item.maxOut = item.outsideTread;
            item.minTread = item.removeAt;
            this.changedTire.push(item);
          }
        });
        const poistionArrayIndex = this.positionArray.findIndex((val) => val === this.tirePositionArray[source]);
        this.positionArray.splice(poistionArrayIndex, 1);
      } else {
        for (let index = 0; index < this.positionArray.length; index++) {
          if (this.positionArray[index] === this.tirePositionArray[source] && !this.presentInArray(this.tirePositionArray[destination])) {
            this.positionArray[index] = this.tirePositionArray[destination];
            // eslint-disable-next-line no-loop-func
            this.tireDetails.installedTires.forEach((item, tireIndex) => {
              if (item.wheelPosition === this.tirePositionArray[source]) {
                item.wheelPosition = this.tirePositionArray[destination];
                tirePositionIndex = tireIndex;
                item.action = "Rotate";
                item.maxIn = item.insideTread;
                item.maxMid = item.middleTread;
                item.maxOut = item.outsideTread;
                item.minTread = item.removeAt;
                this.changedTire.push(item);
              }
            });
          }
        }
      }
      if (tirePositionIndex !== -1) {
        this.tireDetails.installedTires[tirePositionIndex].wheelPosition =
          result.destination.out === true ? `${this.tirePositionArray.length - 1}Out` : this.tirePositionArray[destination];
      }
      this.setState({ tireDetails: this.tireDetails });
      this.setState({ changedTire: this.changedTire });
      this.props.updatedTireDetails(this.tireDetails, this.changedTire);
      this.populateLRTires(this.tireDetails);
    }
  };

  isAvailable = (index, side, extra) => {
    for (const element of this.positionArray) {
      if (index === parseInt(element[0], 10)) {
        if (side === element[1]) {
          if (extra !== -1) {
            if (extra === element[2]) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }
    return false;
  };

  wornImg = (tire) => {
    if (tire.percentageWorn < 25) {
      return TireAGreen;
    } else if (tire.percentageWorn < 50) {
      return TireAYellow;
    } else if (tire.percentageWorn < 75) {
      return TireAOrange;
    } else {
      return TireARed;
    }
  };

  wornPercentageClass = (wornPercentage) => {
    if (wornPercentage < 25) {
      return '#00af45';
    } else if (wornPercentage < 50) {
      return '#afaf00';
    } else if (wornPercentage < 75) {
      return '#dd8d00';
    } else {
      return '#ad0b0b';
    }
  };

  getCurrentInstalledTireDetails = (element) => {
    let details = {};
    this.tireDetails.installedTires.forEach((installedTire) => {
      if (installedTire.wheelPosition === element) {
        const cPtabValue = this.configArrangement.findIndex((pos) => pos === installedTire.wheelPosition);
        details = installedTire;
        let traversalNumb = 0;
        traversalNumb = cPtabValue * 4;
        details["cPtabValue"] = traversalNumb + 1;
        details["outsideTabValue"] = traversalNumb + 2;
        details["middleTabValue"] = traversalNumb + 3;
        details["insideTabValue"] = traversalNumb + 4;
      }
      details.vehicleNumber = this.vehicleNumber;
    });
    return details;
  };
  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    if (
      (parseInt(this.tireDetails.axleConfig.length, 10) > 3 && scrolled > 0.5) ||
      (parseInt(this.tireDetails.axleConfig.length, 10) > 2 && parseInt(this.tireDetails.axleConfig.length, 10) <= 3 && scrolled > 0.3) ||
      (parseInt(this.tireDetails.axleConfig.length, 10) >= 1 && parseInt(this.tireDetails.axleConfig.length, 10) <= 2 && scrolled > 0.25)
    ) {
      this.positionVehicleIdDiv = "absolute";
      this.populateLRTires(this.tireDetails);
      this.forceUpdate();
    } else {
      this.positionVehicleIdDiv = "fixed";
      this.populateLRTires(this.tireDetails);
      this.forceUpdate();
    }
  };

  populateRim = (tirePositionElement, withTire) => {
    for (let index = 0; index < this.tireDetails.installedRims.length; index++) {
      if (this.tireDetails.installedRims[index].wheelPosition === tirePositionElement) {
        if(withTire) {
          return <img className="tireImg img-background-tire" src={require("../../../assets/Rim_Tire_svg.svg")} alt="Rim" />
        } else {
          return <img className="rimOnlyImg" style={{marginBottom:72}} src={require("../../../assets/rim_only.png")} alt="Rim" />}
      }
    } 
  }

  populateRimInfo = (tirePositionElement) => {
    if (this.tireDetails.installedRims.length ) {
      for (let index = 0; index < this.tireDetails.installedRims.length; index++) {
        if (this.tireDetails.installedRims[index].wheelPosition == tirePositionElement) {
          return `RSN: ${this.tireDetails.installedRims[index].rimSerialNo}`
        }
      } 
    } else return null;
  }

  populateLRTires = (tireDetails) => {
    this.elementDiv = [];
    this.setState({ element: [] });
    if (tireDetails.axleConfig) {
      const axleMapping = tireDetails.axleConfig;
      const noOfAxles = axleMapping.length;
      const tiresEachAxle = [];
      tiresEachAxle.length = noOfAxles;
      const tirePosition = [];

      for (let index = 0; index < tiresEachAxle.length; index++) {
        tiresEachAxle[index] = axleMapping[index];
        const i = index + 1;
        if (parseInt(axleMapping[index], 10) === 4) {
          tirePosition.push(i + "LO");
          tirePosition.push(i + "LI");
          tirePosition.push(i + "RI");
          tirePosition.push(i + "RO");
        } else {
          if (parseInt(axleMapping[index], 10) === 2) {
            tirePosition.push(i + "L");
            tirePosition.push(i + "R");
          }
        }
      }
      tirePosition.push(tirePosition.length + "Out");
      this.tirePositionArray = tirePosition;
      const indexOutTire = tireDetails.installedTires.findIndex((val) => val.wheelPosition === tirePosition[tirePosition.length - 1]);

      if (indexOutTire === -1) {
        this.elementDiv.push(
          <div
            id={"out"}
            ref={this.addDraggable}
            data-type={"Out." + this.tirePositionArray.length}
            style={{
              display: "inline-block",
              cursor: "pointer",
              position: this.positionVehicleIdDiv,
              right: this.positionVehicleIdDiv == 'absolute' ? "5.2%" : "4%",
              bottom: "30%",
              top: "55%"
            }}
            key={"Out"}
            className="tire-container-temporary empty-tire empty-tire-space-quick-inspect"
          >
            <div>
              <div key={"out_tire"}>
                <div style={{ height: "100%", width: "0%" }}>
                  <div className="centered">
                    <span style={{color: "gray" }}>{this.globalizationMapping.QUICKINSPECT.RotateTirePosition}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        this.elementDiv.push(
          <div
            id={"out"}
            style={{
              display: "inline-block",
              cursor: "pointer",
              position: this.positionVehicleIdDiv,
              right: "4%",
              bottom: "30%",
              top: "55%"
            }}
            key={"Out"}
            ref={this.addDraggable}
            data-type={"Out." + this.tirePositionArray.length}
          >
            <div style={{ display: "inline-block", verticalAlign: "middle", cursor: "pointer" }}>
              <span
                onClick={() => {
                  this.props.tryingNavigation({
                    pathname: "/view-tire",
                    state: {
                      tireId: this.tireDetails.installedTires[indexOutTire].tireId,
                      fromPath: "/quick-inspect/" + this.tireDetails.vehicleId
                    }
                  });
                  if (this.props.allowNavigation) {
                    this.history.push({
                      pathname: "/view-tire",
                      state: {
                        tireId: this.tireDetails.installedTires[indexOutTire].tireId,
                        fromPath: "/quick-inspect/" + this.tireDetails.vehicleId
                      }
                    });
                  }
                }}
                className="onClick-link"
              >
                  { <Tooltip title="View Tire" placement="top" arrow>
                      <PageViewIcon style={{verticalAlign:'bottom'}} sx={{ fontSize: 20 }} />
                    </Tooltip>}
                  
                </span> |
                <span
                  onClick={() => {
                    this.props.tryingNavigation({
                      pathname: "/createtire",
                      state: {
                        tireId: this.tireDetails.installedTires[indexOutTire].tireId,
                        fromPath: "/quick-inspect/" + this.tireDetails.vehicleId
                      }
                    });
                    if (this.props.allowNavigation) {
                      this.history.push({
                        pathname: "/createtire",
                        state: {
                          tireId: this.tireDetails.installedTires[indexOutTire].tireId,
                          fromPath: "/quick-inspect/" + this.tireDetails.vehicleId
                        }
                      });
                    }
                  }}
                  className="onClick-link"
                >
                  { <Tooltip title="Edit Tire" placement="top" arrow>
                      <EditIcon style={{verticalAlign:'bottom'}}  sx={{ fontSize: 20 }} />
                    </Tooltip>}
                </span> |
                <span
                  onClick={() => {
                    this.props.tryingNavigation({
                      pathname: "/inspect-tire",
                      state: {
                        id: this.tireDetails.vehicleId,
                        tireDetails: this.tireDetails,
                        fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                        vehicleInspectionDate: this.props.vehicleInspectionDate,
                        vehicleNotUpdated: this.props.showUpdateMsg
                      }
                    });
                    if (this.props.allowNavigation) {
                      this.history.push({
                        pathname: "/inspect-tire",
                        state: {
                          id: this.tireDetails.vehicleId,
                          tireDetails: this.tireDetails,
                          fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                          vehicleInspectionDate: this.props.vehicleInspectionDate,
                          vehicleNotUpdated: this.props.showUpdateMsg
                        }
                      });
                    }
                  }}
                  className="onClick-link"
                >
                  { <Tooltip title="Inspect Tire" placement="top" arrow>
                      <PlaylistAddCheck style={{verticalAlign:'bottom'}}  sx={{ fontSize: 20 }} />
                    </Tooltip>}
                </span>
              </div>
            <div key={indexOutTire}>
              <div className="tire-container">
                <div style={{ height: "100%", width: "0%" }}>
                  <img
                    onClick={() => {
                      this.props.tryingNavigation({
                        pathname: "/inspect-tire",
                        state: {
                          id: this.tireDetails.vehicleId,
                          tireDetails: tireDetails.installedTires[indexOutTire],
                          vehicleInspectionDate: this.props.vehicleInspectionDate,
                          vehicleNotUpdated: this.props.showUpdateMsg
                        }
                      });
                      if (this.props.allowNavigation) {
                        this.history.push({
                          pathname: "/inspect-tire",
                          state: {
                            id: this.tireDetails.vehicleId,
                            tireDetails: tireDetails.installedTires[indexOutTire],
                            vehicleInspectionDate: this.props.vehicleInspectionDate,
                            vehicleNotUpdated: this.props.showUpdateMsg
                          }
                        });
                      }
                    }}
                    className="tireImg img-background-tire"
                    alt="Tire"
                    src={this.wornImg(tireDetails.installedTires[indexOutTire])}
                  />
                  {tireDetails.installedTires[indexOutTire].mountedRimId && (
                    <div
                      onClick={() => {
                        this.props.tryingNavigation({
                          pathname: "/inspect-tire",
                          state: {
                            id: this.tireDetails.vehicleId,
                            tireDetails: tireDetails.installedTires[indexOutTire],
                            vehicleInspectionDate: this.props.vehicleInspectionDate,
                            vehicleNotUpdated: this.props.showUpdateMsg
                          }
                        });
                        if (this.props.allowNavigation) {
                          this.history.push({
                            pathname: "/inspect-tire",
                            state: {
                              id: this.tireDetails.vehicleId,
                              tireDetails: tireDetails.installedTires[indexOutTire],
                              vehicleInspectionDate: this.props.vehicleInspectionDate,
                              vehicleNotUpdated: this.props.showUpdateMsg
                            }
                          });
                        }
                      }}
                      className="centered"
                      style={{
                        left: "1%",
                        width: "100%",
                        top: "50%",
                        height: "95%"
                      }}
                    >
                      <img className="tireImg img-background-tire" src={require("../../../assets/Rim_Tire_svg.svg")} alt="Rim" />
                    </div>
                  )}
                  <div className="centered">
                    <p
                      className="field-tire"
                      onClick={() => {
                        this.props.tryingNavigation({
                          pathname: "/inspect-tire",
                          state: {
                            id: this.tireDetails.vehicleId,
                            tireDetails: tireDetails.installedTires[indexOutTire],
                            vehicleInspectionDate: this.props.vehicleInspectionDate,
                            vehicleNotUpdated: this.props.showUpdateMsg
                          }
                        });
                        if (this.props.allowNavigation) {
                          this.history.push({
                            pathname: "/inspect-tire",
                            state: {
                              id: this.tireDetails.vehicleId,
                              tireDetails: tireDetails.installedTires[indexOutTire],
                              vehicleInspectionDate: this.props.vehicleInspectionDate,
                              vehicleNotUpdated: this.props.showUpdateMsg
                            }
                          });
                        }
                      }}
                    >
                      {this.globalizationMapping.INSPECTNREMOVETIRE.PRESSURE} ({this.userPressureUnit})
                    </p>
                    <div className="field-tire" style={{ height: "20px" }}>
                      <GroupedButtons
                        value={tireDetails.installedTires[indexOutTire].currentPressure ? tireDetails.installedTires[indexOutTire].currentPressure : 0}
                        pressureUnit={this.userPressureUnit}
                        onChangeNum={(cnum) => {
                          this.props.setNavigationFlag();
                          this.changeTreadDepth(tireDetails.installedTires[indexOutTire], "currentPressure", cnum);
                        }}
                      />
                    </div>
                    <label
                      onClick={() => {
                        this.props.tryingNavigation({
                          pathname: "/inspect-tire",
                          state: {
                            id: this.tireDetails.vehicleId,
                            tireDetails: tireDetails.installedTires[indexOutTire],
                            vehicleInspectionDate: this.props.vehicleInspectionDate,
                            vehicleNotUpdated: this.props.showUpdateMsg
                          }
                        });
                        if (this.props.allowNavigation) {
                          this.history.push({
                            pathname: "/inspect-tire",
                            state: {
                              id: this.tireDetails.vehicleId,
                              tireDetails: tireDetails.installedTires[indexOutTire],
                              vehicleInspectionDate: this.props.vehicleInspectionDate,
                              vehicleNotUpdated: this.props.showUpdateMsg
                            }
                          });
                        }
                      }}
                      style={{ marginTop: "10px", marginBottom: "0px" }}
                      className="field-tire"
                    >
                      {tireDetails.installedTires[indexOutTire].pressureType === "C" ? "Cold" : "Hot"}
                    </label>
                    <Switch
                      tabIndex="-1"
                      checked={tireDetails.installedTires[indexOutTire].pressureType === "C" ? false : true}
                      className={
                        tireDetails.installedTires[indexOutTire].pressureType === "C"
                          ? "field-tire switch-container switch-container-blue"
                          : "field-tire switch-container switch-container-red"
                      }
                      size="small"
                      onChange={() => {
                        this.props.setNavigationFlag();
                        this.changePressureHotCold(tireDetails.installedTires[indexOutTire], tireDetails.installedTires[indexOutTire].pressureType === "C" ? "H" : "C");
                      }}
                      color="default"
                      inputProps={{
                        "aria-label": "checkbox with default color"
                      }}
                    />
                    <p
                      className="field-tire"
                      onClick={() => {
                        this.props.tryingNavigation({
                          pathname: "/inspect-tire",
                          state: {
                            id: this.tireDetails.vehicleId,
                            tireDetails: tireDetails.installedTires[indexOutTire],
                            vehicleInspectionDate: this.props.vehicleInspectionDate,
                            vehicleNotUpdated: this.props.showUpdateMsg
                          }
                        });
                        if (this.props.allowNavigation) {
                          this.history.push({
                            pathname: "/inspect-tire",
                            state: {
                              id: this.tireDetails.vehicleId,
                              tireDetails: tireDetails.installedTires[indexOutTire],
                              vehicleInspectionDate: this.props.vehicleInspectionDate,
                              vehicleNotUpdated: this.props.showUpdateMsg
                            }
                          });
                        }
                      }}
                    >
                      {this.globalizationMapping.INSPECTNREMOVETIRE.TREADDEPTH}
                    </p>
                    {this.props.noOfReadings > 0 && (
                      <>
                        <p
                          className="field-tire"
                          onClick={() => {
                            this.props.tryingNavigation({
                              pathname: "/inspect-tire",
                              state: {
                                id: this.tireDetails.vehicleId,
                                tireDetails: tireDetails.installedTires[indexOutTire],
                                vehicleInspectionDate: this.props.vehicleInspectionDate,
                                vehicleNotUpdated: this.props.showUpdateMsg
                              }
                            });
                            if (this.props.allowNavigation) {
                              this.history.push({
                                pathname: "/inspect-tire",
                                state: {
                                  id: this.tireDetails.vehicleId,
                                  tireDetails: tireDetails.installedTires[indexOutTire],
                                  vehicleInspectionDate: this.props.vehicleInspectionDate,
                                  vehicleNotUpdated: this.props.showUpdateMsg
                                }
                              });
                            }
                          }}
                        >
                          {this.globalizationMapping.INSPECT.OUTSIDE}
                        </p>
                        <div className="field-tire" style={{ height: "20px" }}>
                          <GroupedButtons
                            value={tireDetails.installedTires[indexOutTire].outsideTread ? tireDetails.installedTires[indexOutTire].outsideTread : 0}
                            onChangeNum={(cnum) => {
                              this.props.setNavigationFlag();
                              this.changeTreadDepth(tireDetails.installedTires[indexOutTire], "outsideTread", cnum);
                            }}
                          />
                        </div>
                      </>
                    )}
                    {this.props.noOfReadings > 2 && (
                      <>
                        <p
                          className="field-tire"
                          onClick={() => {
                            this.props.tryingNavigation({
                              pathname: "/inspect-tire",
                              state: {
                                id: this.tireDetails.vehicleId,
                                tireDetails: tireDetails.installedTires[indexOutTire],
                                vehicleInspectionDate: this.props.vehicleInspectionDate,
                                vehicleNotUpdated: this.props.showUpdateMsg
                              }
                            });
                            if (this.props.allowNavigation) {
                              this.history.push({
                                pathname: "/inspect-tire",
                                state: {
                                  id: this.tireDetails.vehicleId,
                                  tireDetails: tireDetails.installedTires[indexOutTire],
                                  vehicleInspectionDate: this.props.vehicleInspectionDate,
                                  vehicleNotUpdated: this.props.showUpdateMsg
                                }
                              });
                            }
                          }}
                        >
                          {this.globalizationMapping.INSPECT.MIDDLE}
                        </p>
                        <div className="field-tire" style={{ height: "20px" }}>
                          <GroupedButtons
                            value={tireDetails.installedTires[indexOutTire].middleTread ? tireDetails.installedTires[indexOutTire].middleTread : 0}
                            onChangeNum={(cnum) => {
                              this.props.setNavigationFlag();
                              this.changeTreadDepth(tireDetails.installedTires[indexOutTire], "middleTread", cnum);
                            }}
                          />
                        </div>
                      </>
                    )}
                    {this.props.noOfReadings > 1 && (
                      <>
                        <p
                          className="field-tire"
                          onClick={() => {
                            this.props.tryingNavigation({
                              pathname: "/inspect-tire",
                              state: {
                                id: this.tireDetails.vehicleId,
                                tireDetails: tireDetails.installedTires[indexOutTire],
                                vehicleInspectionDate: this.props.vehicleInspectionDate,
                                vehicleNotUpdated: this.props.showUpdateMsg
                              }
                            });
                            if (this.props.allowNavigation) {
                              this.history.push({
                                pathname: "/inspect-tire",
                                state: {
                                  id: this.tireDetails.vehicleId,
                                  tireDetails: tireDetails.installedTires[indexOutTire],
                                  vehicleInspectionDate: this.props.vehicleInspectionDate,
                                  vehicleNotUpdated: this.props.showUpdateMsg
                                }
                              });
                            }
                          }}
                        >
                          {this.globalizationMapping.INSPECT.INSIDE}
                        </p>
                        <div className="field-tire" style={{ height: "20px" }}>
                          <GroupedButtons
                            value={tireDetails.installedTires[indexOutTire].insideTread ? tireDetails.installedTires[indexOutTire].insideTread : 0}
                            onChangeNum={(cnum) => {
                              this.props.setNavigationFlag();
                              this.changeTreadDepth(tireDetails.installedTires[indexOutTire], "insideTread", cnum);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <span><b>OUT</b> | {tireDetails.installedTires[indexOutTire].percentageWorn ? tireDetails.installedTires[indexOutTire].percentageWorn : 0}%</span>
            <br />
            <span>
              {tireDetails.installedTires[indexOutTire].currentMiles} {this.props.userPreference.toLowerCase() === "metric" ? "KM" : this.globalizationMapping.MANAGEVEHICLE.MILES}
            </span>
            <br />
            <span>
              {tireDetails.installedTires[indexOutTire].currentHours} {this.globalizationMapping.MANAGEVEHICLE.HOURS}
            </span>
            <br />
            <br></br>
          </div>
        );
      }
      let prevInd = 1;
      for (let index = 0; index < tirePosition.length - 1; index++) {
        const ind = parseInt(tirePosition[index][0], 10);
        const side = tirePosition[index][1];
        const extra = tirePosition[index].length === 3 ? tirePosition[index][2] : -1;
        if (side === "R" && (extra === -1 || extra === "I")) {
          this.elementDiv.push(
            <div key={index + "parentDiv"} style={{ display: "inline-flex"}}>
              <span></span>
              <div>
                <div key={index + "A"} className="axleConfigBar tire-container">
                  <div className="axle-inspect">
                    <span className="axleNumber">{ind}</span>
                    <img className="axleImg" alt="Axle" src={require("../../../assets/axle_b_middle_bar_inspection.svg")} />
                  </div>
                </div>
              </div>
            </div>
          );
        }
        const current = this.getCurrentInstalledTireDetails(ind + side + (extra === -1 ? "" : extra));
        if (this.isAvailable(ind, side, extra)) {
          if (prevInd !== ind) {
            this.elementDiv.push(<br key={"br" + ind}></br>);
            prevInd = ind;
          }
          this.elementDiv.push(
            <div
              id={tirePosition[index]}
              style={{ display: "inline-block" }}
              key={tirePosition[index]}
              ref={this.addDraggable}
              data-type={`${tirePosition[index]}.${index}`}
            >

              <div style={{ display: "inline-block", verticalAlign: "middle", cursor: "pointer" }}>
                <span
                  onClick={() => {
                    this.props.tryingNavigation({
                      pathname: "/view-tire",
                      state: {
                        tireId: current.tireId,
                        fromPath: "/quick-inspect/" + this.tireDetails.vehicleId
                      }
                    });
                    if (this.props.allowNavigation) {
                      this.history.push({
                        pathname: "/view-tire",
                        state: {
                          tireId: current.tireId,
                          fromPath: "/quick-inspect/" + this.tireDetails.vehicleId
                        }
                      });
                    }
                  }}
                  className="onClick-link"
                >
                  { <Tooltip title="View Tire" placement="top" arrow>
                      <PageViewIcon style={{verticalAlign:'bottom'}} sx={{ fontSize: 15 }} />
                    </Tooltip>}
                  
                </span> 
                <span
                  onClick={() => {
                    this.props.tryingNavigation({
                      pathname: "/createtire",
                      state: {
                        tireId: current.tireId,
                        fromPath: "/quick-inspect/" + this.tireDetails.vehicleId
                      }
                    });
                    if (this.props.allowNavigation) {
                      this.history.push({
                        pathname: "/createtire",
                        state: {
                          tireId: current.tireId,
                          fromPath: "/quick-inspect/" + this.tireDetails.vehicleId
                        }
                      });
                    }
                  }}
                  className="onClick-link"
                >
                  { <Tooltip title="Edit Tire" placement="top" arrow>
                      <EditIcon style={{verticalAlign:'bottom'}}  sx={{ fontSize: 15 }} />
                    </Tooltip>}
                </span> 
                <span
                  onClick={() => {
                    this.props.tryingNavigation({
                      pathname: "/inspect-tire",
                      state: {
                        id: this.tireDetails.vehicleId,
                        tireDetails: current,
                        fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                        vehicleInspectionDate: this.props.vehicleInspectionDate,
                        vehicleNotUpdated: this.props.showUpdateMsg
                      }
                    });
                    if (this.props.allowNavigation) {
                      this.history.push({
                        pathname: "/inspect-tire",
                        state: {
                          id: this.tireDetails.vehicleId,
                          tireDetails: current,
                          fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                          vehicleInspectionDate: this.props.vehicleInspectionDate,
                          vehicleNotUpdated: this.props.showUpdateMsg
                        }
                      });
                    }
                  }}
                  className="onClick-link"
                >
                  { <Tooltip title="Inspect Tire" placement="top" arrow>
                      <PlaylistAddCheck style={{verticalAlign:'bottom'}}  sx={{ fontSize: 15 }} />
                    </Tooltip>}
                </span>
              </div>
              <div className="onClick-link-tire-serial-number">
                <div>SN: {current.tireSerialNo === "-" ? "" : current.tireSerialNo}</div>
                <div>BN: {current.brandNo === "" ? "-" : current.brandNo}</div>
               {this.populateRimInfo(tirePosition[index]) ? <div>{this.populateRimInfo(tirePosition[index])}</div> : null}
              </div>

                <div key={index}>
                  <div className="tire-container">

                  
                  <div style={{ height: "100%", width: "0%" }}>
                    <img
                      // onClick={() => {
                      //   this.props.tryingNavigation({
                      //     pathname: "/inspect-tire",
                      //     state: {
                      //       id: this.tireDetails.vehicleId,
                      //       tireDetails: current,
                      //       fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                      //       vehicleInspectionDate: this.props.vehicleInspectionDate,
                      //       vehicleNotUpdated: this.props.showUpdateMsg
                      //     }
                      //   });
                      //   if (this.props.allowNavigation) {
                      //     this.history.push({
                      //       pathname: "/inspect-tire",
                      //       state: {
                      //         id: this.tireDetails.vehicleId,
                      //         tireDetails: current,
                      //         fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                      //         vehicleInspectionDate: this.props.vehicleInspectionDate,
                      //         vehicleNotUpdated: this.props.showUpdateMsg
                      //       }
                      //     });
                      //   }
                      // }}
                      className="tireImg img-background-tire"
                      alt="Tire"
                      src={this.wornImg(current)}
                    />
                    {this.populateRim(tirePosition[index], true)} 
                    <div className="centered">
                      <p
                        className="field-tire"
                        // onClick={() => {
                        //   this.props.tryingNavigation({
                        //     pathname: "/inspect-tire",
                        //     state: {
                        //       id: this.tireDetails.vehicleId,
                        //       tireDetails: current,
                        //       fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                        //       vehicleInspectionDate: this.props.vehicleInspectionDate,
                        //       vehicleNotUpdated: this.props.showUpdateMsg
                        //     }
                        //   });
                        //   if (this.props.allowNavigation) {
                        //     this.history.push({
                        //       pathname: "/inspect-tire",
                        //       state: {
                        //         id: this.tireDetails.vehicleId,
                        //         tireDetails: current,
                        //         fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                        //         vehicleInspectionDate: this.props.vehicleInspectionDate,
                        //         vehicleNotUpdated: this.props.showUpdateMsg
                        //       }
                        //     });
                        //   }
                        // }}
                      >
                        {this.globalizationMapping.INSPECTNREMOVETIRE.PRESSURE} ({this.userPressureUnit})
                      </p>
                      <div className="field-tire" style={{ height: "20px" }}>
                        <GroupedButtons
                          value={current.currentPressure ? current.currentPressure : 0}
                          tabValue={current.cPtabValue}
                          pressureUnit={this.userPressureUnit}
                          onChangeNum={(cnum) => {
                            this.props.setNavigationFlag();
                            this.changeTreadDepth(current, "currentPressure", cnum);
                          }}
                        />
                      </div>
                      <label
                        // onClick={() => {
                        //   this.props.tryingNavigation({
                        //     pathname: "/inspect-tire",
                        //     state: {
                        //       id: this.tireDetails.vehicleId,
                        //       tireDetails: current,
                        //       fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                        //       vehicleInspectionDate: this.props.vehicleInspectionDate,
                        //       vehicleNotUpdated: this.props.showUpdateMsg
                        //     }
                        //   });
                        //   if (this.props.allowNavigation) {
                        //     this.history.push({
                        //       pathname: "/inspect-tire",
                        //       state: {
                        //         id: this.tireDetails.vehicleId,
                        //         tireDetails: current,
                        //         fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                        //         vehicleInspectionDate: this.props.vehicleInspectionDate,
                        //         vehicleNotUpdated: this.props.showUpdateMsg
                        //       }
                        //     });
                        //   }
                        // }}
                        className="field-tire"
                        style={{ marginTop: "10px", marginBottom: "0px" }}
                      >
                        {current.pressureType === "C" ? "Cold" : "Hot"}
                      </label>
                      <Switch
                        tabIndex="-1"
                        checked={current.pressureType === "C" ? false : true}
                        className={current.pressureType === "C" ? "field-tire switch-container switch-container-blue" : "field-tire switch-container switch-container-red"}
                        size="small"
                        color="default"
                        onChange={() => {
                          this.props.setNavigationFlag();
                          this.changePressureHotCold(current, current.pressureType === "C" ? "H" : "C");
                        }}
                        inputProps={{
                          "aria-label": "checkbox with default color"
                        }}
                      />
                      <p
                        className="field-tire"
                        // onClick={() => {
                        //   this.props.tryingNavigation({
                        //     pathname: "/inspect-tire",
                        //     state: {
                        //       id: this.tireDetails.vehicleId,
                        //       tireDetails: current,
                        //       fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                        //       vehicleInspectionDate: this.props.vehicleInspectionDate,
                        //       vehicleNotUpdated: this.props.showUpdateMsg
                        //     }
                        //   });
                        //   if (this.props.allowNavigation) {
                        //     this.history.push({
                        //       pathname: "/inspect-tire",
                        //       state: {
                        //         id: this.tireDetails.vehicleId,
                        //         tireDetails: current,
                        //         fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                        //         vehicleInspectionDate: this.props.vehicleInspectionDate,
                        //         vehicleNotUpdated: this.props.showUpdateMsg
                        //       }
                        //     });
                        //   }
                        // }}
                      >
                        {this.globalizationMapping.INSPECTNREMOVETIRE.TREADDEPTH}
                      </p>
                      {this.props.noOfReadings > 0 && (
                        <>
                          <p
                            className="field-tire"
                            // onClick={() => {
                            //   this.props.tryingNavigation({
                            //     pathname: "/inspect-tire",
                            //     state: {
                            //       id: this.tireDetails.vehicleId,
                            //       tireDetails: current,
                            //       fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                            //       vehicleInspectionDate: this.props.vehicleInspectionDate,
                            //       vehicleNotUpdated: this.props.showUpdateMsg
                            //     }
                            //   });
                            //   if (this.props.allowNavigation) {
                            //     this.history.push({
                            //       pathname: "/inspect-tire",
                            //       state: {
                            //         id: this.tireDetails.vehicleId,
                            //         tireDetails: current,
                            //         fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                            //         vehicleInspectionDate: this.props.vehicleInspectionDate,
                            //         vehicleNotUpdated: this.props.showUpdateMsg
                            //       }
                            //     });
                            //   }
                            // }}
                          >
                            {this.globalizationMapping.INSPECT.OUTSIDE}
                          </p>
                          <div className="field-tire" style={{ height: "20px" }}>
                            <GroupedButtons
                              tabValue={current.outsideTabValue}
                              value={current.outsideTread ? current.outsideTread : 0}
                              onChangeNum={(cnum) => {
                                this.props.setNavigationFlag();
                                this.changeTreadDepth(current, "outsideTread", cnum);
                              }}
                            />
                          </div>
                        </>
                      )}
                      {this.props.noOfReadings > 2 && (
                        <>
                          <p
                            className="field-tire"
                            // onClick={() => {
                            //   this.props.tryingNavigation({
                            //     pathname: "/inspect-tire",
                            //     state: {
                            //       id: this.tireDetails.vehicleId,
                            //       tireDetails: current,
                            //       fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                            //       vehicleInspectionDate: this.props.vehicleInspectionDate,
                            //       vehicleNotUpdated: this.props.showUpdateMsg
                            //     }
                            //   });
                            //   if (this.props.allowNavigation) {
                            //     this.history.push({
                            //       pathname: "/inspect-tire",
                            //       state: {
                            //         id: this.tireDetails.vehicleId,
                            //         tireDetails: current,
                            //         fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                            //         vehicleInspectionDate: this.props.vehicleInspectionDate,
                            //         vehicleNotUpdated: this.props.showUpdateMsg
                            //       }
                            //     });
                            //   }
                            // }}
                          >
                            {this.globalizationMapping.INSPECT.MIDDLE}
                          </p>
                          <div className="field-tire" style={{ height: "20px" }}>
                            <GroupedButtons
                              tabValue={current.middleTabValue}
                              value={current.middleTread ? current.middleTread : 0}
                              onChangeNum={(cnum) => {
                                this.props.setNavigationFlag();
                                this.changeTreadDepth(current, "middleTread", cnum);
                              }}
                              
                            />
                          </div>
                        </>
                      )}
                      {this.props.noOfReadings > 1 && (
                        <>
                          <p
                            className="field-tire"
                            // onClick={() => {
                            //   this.props.tryingNavigation({
                            //     pathname: "/inspect-tire",
                            //     state: {
                            //       id: this.tireDetails.vehicleId,
                            //       tireDetails: current,
                            //       fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                            //       vehicleInspectionDate: this.props.vehicleInspectionDate,
                            //       vehicleNotUpdated: this.props.showUpdateMsg
                            //     }
                            //   });
                            //   if (this.props.allowNavigation) {
                            //     this.history.push({
                            //       pathname: "/inspect-tire",
                            //       state: {
                            //         id: this.tireDetails.vehicleId,
                            //         tireDetails: current,
                            //         fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`,
                            //         vehicleInspectionDate: this.props.vehicleInspectionDate,
                            //         vehicleNotUpdated: this.props.showUpdateMsg
                            //       }
                            //     });
                            //   }
                            // }}
                          >
                            {this.globalizationMapping.INSPECT.INSIDE}
                          </p>
                          <div className="field-tire" style={{ height: "20px" }}>
                            <GroupedButtons
                              value={current.insideTread ? current.insideTread : 0}
                              tabValue={current.insideTabValue}
                              onChangeNum={(cnum) => {
                                this.props.setNavigationFlag();
                                this.changeTreadDepth(current, "insideTread", cnum);
                              }}
                            />
                          </div>
                          {current.pSAlert? 
                          <Tooltip title={current.pSAlert.eventType.replace(/_/g, ' ')} arrow >
                            <WarningIcon style={{color:"orange", fontSize: 35}}/> 
                          </Tooltip> : null}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                </div>

              <span><b>{current.wheelPosition}</b> | <span style={{ color: this.wornPercentageClass(current.percentageWorn) }}> {current.percentageWorn ? current.percentageWorn : 0}%</span></span>
              <br />
              <span>
                {current.currentMiles} {this.props.userPreference.toLowerCase() === "metric" ? "KM" : this.globalizationMapping.MANAGEVEHICLE.MILES}
              </span>
              <br />
              <span>
                {current.currentHours} {this.globalizationMapping.MANAGEVEHICLE.HOURS}
              </span>
              <br />
              <br></br>
            </div>
          );
        } else {
          if (prevInd !== ind) {
            this.elementDiv.push(<br key={index}></br>);
            prevInd = ind;
          }
          
          this.elementDiv.push(
            <Tooltip title="Install Tire" placement="top" arrow>
            <div
              ref={this.addDraggable}
              id={tirePosition[index]}
              data-type={`${tirePosition[index]}.${index}`}
              style={{
                display: "inline-block",
                cursor: "pointer",
                marginTop: "15px",
                marginBottom: "65px"
              }}
              key={tirePosition[index]}
              className={this.populateRimInfo(tirePosition[index]) ? null : "tire-container empty-tire empty-tire-space-quick-inspect"}
              // className={this.populateRimInfo(tirePosition[index]) ? null : "tire-container empty-tire empty-tire-space-quick-inspect"}  
              onClick={() => {
                if (this.emptyTirePosition.includes(tirePosition[index])) {
                  this.props.tryingNavigation({
                    pathname: "/search-install-tires",
                    state: {
                      id: this.tireDetails.vehicleId,
                      position: ind + side + (extra === -1 ? "" : extra),
                      locationId: this.tireDetails.locationId ? this.tireDetails.locationId : "",
                      fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`
                    }
                  });
                  if (this.props.allowNavigation) {
                    this.history.push({
                      pathname: "/search-install-tires",
                      state: {
                        id: this.tireDetails.vehicleId,
                        position: ind + side + (extra === -1 ? "" : extra),
                        locationId: this.tireDetails.locationId ? this.tireDetails.locationId : "",
                        fromPath: `/quick-inspect/${this.tireDetails.vehicleId}`
                      }
                    });
                  }
                }
              }}
            >

              {this.populateRimInfo(tirePosition[index]) ? 
                <>
                <div className="onClick-link-tire-serial-number" >
                  {this.populateRimInfo(tirePosition[index])}</div>
                  <div className="tire-container empty-tire empty-tire-space-quick-inspect" 
                  ref={this.addDraggable}
                  id={tirePosition[index]}
                  data-type={`${tirePosition[index]}.${index}`}
                  style={{
                    display: "inline-block",
                    cursor: "pointer"
                  }}
                  key={tirePosition[index]}
                  >
                  {this.populateRim(tirePosition[index], false)} 
                </div> 
                </> : null}
              
            </div>
            </Tooltip>
          );
        }
      }

      this.setState({ element: this.elementDiv });

      this.forceUpdate();
    }
  };
  changePressureHotCold = (tire, value) => {
    this.tireDetails.installedTires.map((item, tireIndex) => {
      if (item.tireId === tire.tireId) {
        item.pressureType = value;
        item.inspectionDate = new Date();
        this.tireDetails.installedTires[tireIndex] = item;
        item.action = "Inspect";
        const indexChangedTire = this.changedTire.findIndex((val) => val.tireSerialNo === item.tireSerialNo);
        if (indexChangedTire !== -1) {
          this.changedTire[indexChangedTire] = item;
        } else {
          this.changedTire.push(item);
        }
        return item;
      } else {
        return item;
      }
    });
    this.props.updatedTireDetails(this.tireDetails, this.changedTire);
    this.populateLRTires(this.tireDetails);
    this.forceUpdate();
  };
  changeTreadDepth = (tire, updatedTread, value) => {
    this.tireDetails.installedTires.map((item, tireIndex) => {
      if (item.tireId === tire.tireId) {
        if (value === "") {
          value = 0;
        }
        if (parseInt(value, 10) <= parseInt(item.originalTread, 10) || updatedTread === "currentPressure") {
          item.maxIn = tire.insideTread;
          item.maxMid = tire.middleTread;
          item.maxOut = tire.outsideTread;
          item.insideTread = tire.insideTread;
          item.middleTread = tire.middleTread;
          item.outsideTread = tire.outsideTread;
          item.minTread = tire.removeAt;
          item.inspectionDate = new Date();
          item[updatedTread] = value;
          item.currentTreadDepth = this.calculateAverageTreadDepth(item.outsideTread, item.middleTread, item.insideTread);
          item.percentageWorn = this.calculatePercentageWorn(item.originalTread, item.outsideTread, item.insideTread, item.middleTread, item.removeAt);

          this.tireDetails.installedTires[tireIndex] = item;
          item.action = "Inspect";
          const indexChangedTire = this.changedTire.findIndex((val) => val.tireSerialNo === item.tireSerialNo);
          if (indexChangedTire !== -1) {
            this.changedTire[indexChangedTire] = item;
          } else {
            this.changedTire.push(item);
          }
        } else {
          item.maxIn = tire.insideTread;
          item.maxMid = tire.middleTread;
          item.maxOut = tire.outsideTread;
          item.insideTread = tire.insideTread;
          item.middleTread = tire.middleTread;
          item.outsideTread = tire.outsideTread;
          item.minTread = tire.removeAt;
          item.inspectionDate = new Date();
          item[updatedTread] = value;
          item.currentTreadDepth = this.calculateAverageTreadDepth(item.outsideTread, item.middleTread, item.insideTread);
          item.percentageWorn = this.calculatePercentageWorn(item.originalTread, item.outsideTread, item.insideTread, item.middleTread, item.removeAt);

          this.tireDetails.installedTires[tireIndex] = item;
          item.action = "Inspect";
          const indexChangedTire = this.changedTire.findIndex((val) => val.tireSerialNo === item.tireSerialNo);
          if (indexChangedTire !== -1) {
            this.changedTire[indexChangedTire] = item;
          } else {
            this.changedTire.push(item);
          }
          alert("Tread depth is greater than original tread depth");
        }
        return item;
      } else {
        return item;
      }
    });
    this.listenToScroll();
    this.props.updatedTireDetails(this.tireDetails, this.changedTire);
  };
  calculateAverageTreadDepth = (outside, middle, inside) => {
    outside = !isNaN(parseInt(outside, 10)) ? parseInt(outside, 10) : 0;
    middle = !isNaN(parseInt(middle, 10)) ? parseInt(middle, 10) : 0;
    inside = !isNaN(parseInt(inside, 10)) ? parseInt(inside, 10) : 0;
    let result = outside;
    if (this.props.noOfReadings === 3) {
      result = (outside + inside + middle) / 3;
    } else if (this.props.noOfReadings === 2) {
      result = (outside + inside) / 2;
    } else {
      if (this.props.noOfReadings === 1) {
        result = outside;
      }
    }
    return result;
  };
  calculatePercentageWorn = (originalTread, outsideTread, insideTread, middleTread, removeAt) => {
    const outside = isNaN(parseInt(outsideTread, 10)) || outsideTread === null ? 0 : parseInt(outsideTread, 10);
    const inside = isNaN(parseInt(insideTread, 10)) || insideTread === null ? 0 : parseInt(insideTread, 10);
    const middle = isNaN(parseInt(middleTread, 10)) || middleTread === null ? 0 : parseInt(middleTread, 10);
    let result;
    let worn;

    if (this.props.noOfReadings === 3) {
      if (this.props.tdMethod === 1) {
        result = (outside + inside + middle) / 3;
        if (!Number.isInteger(result)) {
          result = result.toFixed(2);
        }
      } else if (this.props.tdMethod === 2) {
        result = Math.max(outside, inside, middle);
      } else {
        result = Math.min(outside, inside, middle);
      }
      worn = Math.round(((parseInt(originalTread, 10) - result) / (parseInt(originalTread, 10) - parseInt(removeAt, 10))) * 100);
    } else if (this.props.noOfReadings === 2) {
      if (this.props.tdMethod === 1) {
        result = (outside + inside) / 2;
        if (!Number.isInteger(result)) {
          result = parseFloat(result.toFixed(2));
        }
      } else if (this.props.tdMethod === 2) {
        result = Math.max(outside, inside);
      } else {
        result = Math.min(outside, inside);
      }
      worn = Math.round(((parseInt(originalTread, 10) - result) / (parseInt(originalTread, 10) - parseInt(removeAt, 10))) * 100);
    } else {
      worn = Math.round(((parseInt(originalTread, 10) - outside) / (parseInt(originalTread, 10) - parseInt(removeAt, 10))) * 100);
    }
    return worn;
  };

  addDraggable = (el) => {
    this.dragula.containers.push(el);
  };

  tireConfigUI = (vehicleNumber, tireDetails, positionArray) => {
    this.tireDetails = tireDetails;
    this.vehicleNumber = vehicleNumber;
    this.positionArray = positionArray;
    tireDetails.installedTires.forEach((tire) => {
      this.filledTirePositions.push(tire.wheelPosition);
    });
    const axleMapping = tireDetails.axleConfig;
    const noOfAxles = axleMapping.length;
    const tiresEachAxle = [];
    tiresEachAxle.length = noOfAxles;

    for (let index = 0; index < tiresEachAxle.length; index++) {
      tiresEachAxle[index] = axleMapping[index];
      const i = index + 1;
      if (parseInt(axleMapping[index], 10) === 4) {
        if (!this.filledTirePositions.includes(i + "LO")) {
          this.emptyTirePosition.push(i + "LO");
        }
        if (!this.filledTirePositions.includes(i + "LI")) {
          this.emptyTirePosition.push(i + "LI");
        }
        if (!this.filledTirePositions.includes(i + "RI")) {
          this.emptyTirePosition.push(i + "RI");
        }
        if (!this.filledTirePositions.includes(i + "RO")) {
          this.emptyTirePosition.push(i + "RO");
        }
      } else {
        if (parseInt(axleMapping[index], 10) === 2) {
          if (!this.filledTirePositions.includes(i + "L")) {
            this.emptyTirePosition.push(i + "L");
          }
          if (!this.filledTirePositions.includes(i + "R")) {
            this.emptyTirePosition.push(i + "R");
          }
        }
      }
    }
    this.populateLRTires(tireDetails);
  };
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "auto",
          marginLeft: "auto",
          flexWrap: "nowrap",
          marginTop: "30px"
        }}
      >
        {this.state.element}
      </div>
    );
  }
}
export default TireConfigUtils;
