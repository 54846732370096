import React, { useEffect, useState } from "react";
import "../../../../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../../services/utils";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    height: 625
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const TireNeedsForecastSecondTable = ({ tableData, showColumns }) => {

  const [tireNeedsForecastSecondData, setTireNeedsForecastSecondData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const commonUtils = new CommonUtils();
 
  useEffect(() => {
    setTireNeedsForecastSecondData(tableData);
    setPage(0);
    let arr = commonUtils.rowPerPageSelector(tireNeedsForecastSecondData.rowsData.length);
    setArrRowsPerPageOptions(arr);
  }, [tableData, showColumns]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table 
              stickyHeader 
              className={classes.table} 
              aria-labelledby="tableTitle" 
              aria-label="a dense table"
              size="small"
              >
              <TableHead>
                <TableRow>
                  {tireNeedsForecastSecondData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className="table-head-row"
                        key={headCell.keyIndex}
                        align="left"
                      >
                          {headCell.label}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {tireNeedsForecastSecondData.rowsData.length > 0 && (
                <TableBody>
                  {tireNeedsForecastSecondData.rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={row.Key}>
                          {showColumns.Manufacturer && <TableCell className="table-content-text">{row.Manufacturer}</TableCell>}
                          {showColumns.Size && <TableCell className="table-content-text">{row.Size}</TableCell>}
                          {showColumns.Type && <TableCell className="table-content-text">{row.Type}</TableCell>}
                          {showColumns.HoursPerTreadDepth && <TableCell className="table-content-text">{row.HoursPerTreadDepth}</TableCell>}
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {tireNeedsForecastSecondData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={tireNeedsForecastSecondData.headCells.length} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={tireNeedsForecastSecondData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default TireNeedsForecastSecondTable;
