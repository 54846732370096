/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "./AdminRequestList.css";
import "../../../../../common-styles/TableFunctionOptions.css";
import "react-quill/dist/quill.core.css";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  Button,
  Grid,
  Select,
  makeStyles,
  Badge,
  List,
  ListItem,
  Input,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import DeleteIcon from '@material-ui/icons/Delete';
import FlashMessage from "../../../../../common-components/pop-up-notification/notification-snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AdminListApis from "../../../services/AdminRequestListApis";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import { StateContext } from "../../../../../services/StateProvider";
import Spinner from "../../../../../common-components/spinner/spinner";
import AdminListTable from "../table/AdminRequestListTable";
import AdminListSetter from "../../../services/AdminRequestListUtils";
import CommonUtils from "../../../../../services/utils";
import SearchIcon from "../../../../../assets/icons8-search.svg";
import Filter from "../../../../../common-components/filter/components/main-container/Filter";
import EmailExportModal from "../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import AdminNavigation from "../../../admin-navigation/components/AdminRequestNavigation";
import Globalization from "../../../../../globalization";
import FIELDID from "../../../../../globalization/userPreferences-code.json";
import { useComponentVisible } from "../../../../../services/CustomHooks";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import ExpandMoreStyledIcon from "../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'

Quill.register('modules/imageResize', ImageResize);

const useStyles = makeStyles({
  next: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  FormHelperText: {
    align: "center",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      boxShadow: "none",
      opacity: 1,
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
  },
  root: {
    "& input.MuiAutocomplete-inputFocused": {
      color: "#000000 !important",
      Height: "45px",
    },
    "& div.MuiFormControl-fullWidth": {
      width: "30%",
    },
    "& div.MuiListItem-button:hover": {
      backgroundColor: "transparent !important",
    },
    "& div.Mui-focusVisible": {
      backgroundColor: "transparent !important",
    },
  },
});
const useStylesBadge = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#B52A2A",
  },
  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
}));
const AdminList = () => {
  const styling = useStyles();
  const classes = useStylesBadge();
  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [adminListTableData, setAdminListTableData] = useState();
  const [originalAdminListTableData, setOriginalAdminListTableData] =
    useState();
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({
    notifmessage: "",
    notifdesc: "",
  });
  const globalizationMapping = new Globalization();
  const [printData, setPrintData] = useState(adminListTableData);
  const [noData, setNoData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [uniqueRequestType, setUniqueRequestType] = useState([]);
  const [uniqueIndex, setUniqueIndex] = useState(0);
  const [addDeleteIndex, setAddDeleteIndex] = useState(0);
  const [addDeleteBroadcastIndex, setAddDeleteBroadcastIndex] = useState(0);
  const validationTexts = globalizationMapping.FORMS.VALIDATIONTEXTS;

  const [parentAccountList, setParentAccountList] = useState([
    { accountName: "All", createdBy: "All", parentAccountId: "All" },
  ]);
  const [parentAccount, setParentAccount] = useState({
    accountName: "All",
    createdBy: "All",
    parentAccountId: "All",
  });
  // eslint-disable-next-line no-unused-vars
  // const [isFirstLoad, setIsFirstLoad] = useState(0);
  const [action, setAction] = useState([]);
  const [currentRoute, setCurrentRoute] = useState({
    name: globalizationMapping.ADMIN.MASTERDATAREQUEST,
    path: "admin",
  });
  const multipleRowsSelected = (rows) => {
    setAction(rows);
  };

  const [addNewDelete] = useState([
    globalizationMapping.ADMIN.ADDNEWOPTION,
    globalizationMapping.ADMIN.Delete,
  ]);
  const [addNewDeleteBroadCast] = useState([
    "Add",
    globalizationMapping.ADMIN.Delete,
  ]);

  const commonUtils = new CommonUtils();
  const adminListService = new AdminListApis();
  const adminListDataSetter = new AdminListSetter();
  const headerApiObj = new HeaderApi();
  const [masterData, setMasterData] = useState();
  const [dropdownManufacturer, setDropdownManufacturer] = useState([]);
  const [dropdownType, setDropdownType] = useState([]);
  const [dropdownSizeOrAxleConfig, setDropdownSizeOrAxleConfig] = useState([]);
  const [dropdownManufacturerValue, setDropdownManufacturerValue] = useState(0);
  const [dropdownTypeValue, setDropdownTypeValue] = useState(0);
  const [dropdownSizeOrAxleConfigValue, setDropdownSizeOrAxleConfigValue] = useState(0);
  const [textArea, setTextArea] = useState("");
  const [textTitle, setTextTitle] = useState("");
  const [textDescription, setTextDescription] = useState("");

  const [tireTreadDefaultId, setTireTreadId] = useState();
  const [treadDepthValue, setTreadDepthValue] = useState();

  const [textAreaConfigName, setTextAreaConfigName] = useState();
  const [textAreaConfigText, setTextAreaConfigText] = useState();
  const [fieldId, setFieldId] = useState(0);
  const availableHierarchy = [
    {
      id: FIELDID.FIELDID.VehicleManufacturer,
      hierarchy: globalizationMapping.ADMIN.VehicleManuf,
    },
    {
      id: FIELDID.FIELDID.VehicleType,
      hierarchy: globalizationMapping.ADMIN.VehicleType,
    },
    {
      id: FIELDID.FIELDID.VehicleModel,
      hierarchy: globalizationMapping.ADMIN.VehicleModel,
    },
    {
      id: FIELDID.FIELDID.AxleConfig,
      hierarchy: globalizationMapping.ADMIN.AxleConfig,
    },
    {
      id: FIELDID.FIELDID.TireManufacturer,
      hierarchy: globalizationMapping.ADMIN.TireManuf,
    },
    {
      id: FIELDID.FIELDID.TireSize,
      hierarchy: globalizationMapping.ADMIN.TireSize,
    },
    {
      id: FIELDID.FIELDID.TireType,
      hierarchy: globalizationMapping.ADMIN.TireType,
    },
    {
      id: FIELDID.FIELDID.TireCompound,
      hierarchy: globalizationMapping.ADMIN.TireCompound,
    },
    {
      id: FIELDID.FIELDID.RimManufacturer,
      hierarchy: globalizationMapping.ADMIN.RimManuf,
    },
    {
      id: FIELDID.FIELDID.RemovalReason,
      hierarchy: globalizationMapping.ADMIN.InspRemovalReason,
    },
    {
      id: FIELDID.FIELDID.RimSize,
      hierarchy: globalizationMapping.ADMIN.RimSize,
    },
    {
      id: FIELDID.FIELDID.RimType,
      hierarchy: globalizationMapping.ADMIN.RimType,
    },
    {
      id: FIELDID.FIELDID.RimValue,
      hierarchy: globalizationMapping.ADMIN.RimValve,
    },
    {
      id: FIELDID.FIELDID.TireTreadDepth,
      hierarchy: globalizationMapping.ADMIN.TireTreadDepth,
    },
  ];
  const [selectedUniqueType, setSelectedUniqueType] = useState("");
  const [addDeleteAction, setAddDeleteAction] = useState(false);
  const [adminListCurrentTableData, setAdminListCurrentTableData] = useState();
  const [othersCount, setOthersCount] = useState({});
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [broadCastData, setbroadCastData] = useState([]);
  const [isTitleEdited, setIsTitleEdited] = useState(false);
  const [isDescriptionEdited, setIsDescriptionEdited] = useState(false);

  useEffect(() => {
    setUniqueIndex(0);
    if (currentRoute.path === "admin") AdminListResponseData();
    else if (currentRoute.path === "add-delete") getMasterDataWeb();
    else getParentAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, currentRoute, addDeleteAction]);


  const resetTextFieldIsEditState = () => {
    setIsTitleEdited(false);
    setIsDescriptionEdited(false);
  }

  const compareStrings = (a, b) => {
    // Assuming you want case-insensitive comparison
    a = a.trim();
    b = b.trim();
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a < b) return -1;
    else if (a > b) return 1;
    else return 0;
  };
  const getParentAccountList = () => {
    adminListService.getParentAccountList().then((data) => {
      setParentAccountList([]);
      let model = data.model;
      model.sort(function (a, b) {
        return compareStrings(a.accountName, b.accountName);
      });
      let parentAccountListObject = [
        { accountName: "All", createdBy: "All", parentAccountId: "All" },
      ];
      for (let item of model) {
        parentAccountListObject.push({
          accountName: item.accountName,
          createdBy: item.createdBy,
          parentAccountId: item.parentAccountId,
        });
      }
      setParentAccountList(parentAccountListObject);
    });
  };
  const handleAccountChange = (_event, selectedOption) => {
    setParentAccount(selectedOption);
  };
  const getMasterDataWeb = () => {
    setShowSpinner(true);
    adminListService.getMasterDataWeb().then((data) => {
      setMasterData(data.model);
      setShowSpinner(false);
    });
  };
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: globalizationMapping.ADMIN.RequestedBy, value: "RequestedBy" },
      { name: globalizationMapping.ADMIN.DATE, value: "Date" },
    ],
    categoryData: {
      RequestedBy: [],
      Date: [],
    },
  });

  const removeDuplicateObjectFromArray = (array, key) => {
    let check = new Set();
    return array.filter((obj) => !check.has(obj[key]) && check.add(obj[key]));
  };

  const AdminListResponseData = () => {
    setAction([]);
    let filterOptions = {
      categories: [
        { name: globalizationMapping.ADMIN.RequestedBy, value: "RequestedBy" },
        { name: globalizationMapping.ADMIN.DATE, value: "Date" },
      ],
      categoryData: {
        RequestedBy: [],
        Date: [],
      },
    };

    setShowSpinner(true);
    adminListService.adminRequests().then((data) => {
      let adminListTableInfo = adminListDataSetter.setData(data);

      let filterOptionsInfo = adminListDataSetter.setFilterOptionsData(
        filterOptions,
        adminListTableInfo.rowsData
      );
      setAdminListTableData(adminListTableInfo);
      setOriginalAdminListTableData(adminListTableInfo);
      let count = {};
      let uniqueArray = adminListTableInfo.rowsData.reduce(function (a, d) {
        let s = d.FieldType;
        let replacedValue = globalizationMapping.ADMIN[s + "T"]
          ? globalizationMapping.ADMIN[s + "T"]
          : s.replace(/([A-Z])/g, " $1");
        let inputDate = new Date(d.Date);
        let todaysDate = new Date();
        if (
          inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
        ) {
          if (count[replacedValue.trim()]) {
            count[replacedValue.trim()] += 1;
          } else {
            count[replacedValue.trim()] = 1;
          }
        }
        let index = a.findIndex((item) => {
          return item.value.trim() === replacedValue.trim();
        });
        if (index === -1) {
          a.push({
            label: replacedValue.trim(),
            value: s.replace(/([A-Z])/g, " $1"),
          });
        }
        return a;
      }, []);
      let filteredArray = removeDuplicateObjectFromArray(uniqueArray, "value");
      setUniqueRequestType(filteredArray);
      setOthersCount(count);
      adminListTableInfo.rowsData.length === 0
        ? setNoData(true)
        : setNoData(false);
      setFilterOptions(filterOptionsInfo);
      setShowColumns(adminListTableInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: globalizationMapping.ADMIN.MASTERDATAREQUEST,
        rowsData: adminListTableInfo.rowsData,
        headCells: adminListTableInfo.headCells,
        selectedColShow: adminListTableInfo.selectedColShow,
      });
      let adminListInitialTableInfo;
      let selectedUniq = selectedUniqueType ? selectedUniqueType : ''
      adminListInitialTableInfo = adminListDataSetter.setFilteredData(
        adminListTableInfo.rowsData,
        selectedUniq
      );
      if (!uniqueIndex) setUniqueIndex(0);
      let uniqueArrayLabel = adminListInitialTableInfo;
      uniqueArrayLabel.rowsData = uniqueArrayLabel.rowsData.filter((item) => {
        return item.FieldType === selectedUniq;
      });
      setAdminListTableData(uniqueArrayLabel);
      setAdminListCurrentTableData(uniqueArrayLabel);
      setShowColumns(adminListInitialTableInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: globalizationMapping.ADMIN.MASTERDATAREQUEST,
        rowsData: uniqueArrayLabel.rowsData,
        headCells: adminListInitialTableInfo.headCells,
        selectedColShow: adminListInitialTableInfo.selectedColShow,
      });
      setShowSpinner(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false,
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]
        );
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);

      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    setModalHandler({ handleEmail: true });
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    setSearchValue("");
    setFilterApplied(true);
    let originalTableDataStore = adminListTableData?.rowsData;
    let filteredData = adminListDataSetter.filterTable(
      appliedFilter,
      originalTableDataStore
    );
    setAdminListTableData({
      ...adminListTableData,
      rowsData: filteredData,
    });
    setAdminListCurrentTableData({
      ...adminListTableData,
      rowsData: filteredData,
    });
    setPrintData({ ...printData, rowsData: filteredData });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    Object.values(appliedFilter).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setSearchValue("");
    setFilterApplied(false);
    let tableData = { ...originalAdminListTableData };
    let selectedUniq = selectedUniqueType ? selectedUniqueType: "";
    tableData.rowsData = tableData.rowsData.filter((item) => {
      return item.FieldType === selectedUniq;
    });
    setAdminListTableData(tableData);
    setPrintData(tableData);
    setNoData(false);
    setOnlyFilterApplied(false);
  };
  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    searchText === "" ? setFilterApplied(false) : setFilterApplied(true);
    let originalTableDataStore, filteredData;
    originalTableDataStore = adminListCurrentTableData?.rowsData;

    filteredData = adminListDataSetter.search(
      originalTableDataStore,
      searchText
    );

    let updatedTableData = {
      rowsData: filteredData,
      headCells: originalAdminListTableData?.headCells,
      selectedColShow: originalAdminListTableData?.selectedColShow,
    };
    setAdminListTableData(updatedTableData);
    setPrintData({
      reportType: "",
      reportName: globalizationMapping.ADMIN.MASTERDATAREQUEST,
      rowsData: filteredData,
      headCells: originalAdminListTableData?.headCells,
      selectedColShow: originalAdminListTableData?.selectedColShow,
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  const updateFilterMain = (_filterval) => {
    //function will on call on change of filters
  };

  const changedRoute = (selectedRoute) => {
    if (!adminListTableData) return;
    setCurrentRoute(selectedRoute);
    setSelectedUniqueType("");
    let adminListTableInfo = adminListDataSetter.setFilteredData( originalAdminListTableData?.rowsData,
      // uniqueRequestType[0].value.replace(/ /g, ""));
      "");
    setAdminListTableData(adminListTableInfo);
    setAdminListCurrentTableData(adminListTableInfo);
    setShowColumns(adminListTableInfo.selectedColShow);
    const filters = {
      categories: [
        { name: "RequestedBy", value: "RequestedBy" },
        { name: "Date", value: "Date" },
      ],
      categoryData: {
        RequestedBy: [],
        Date: [],
      },
    };

    let filterOptionsInfo = adminListDataSetter.setFilterOptionsData(
      filters,
      adminListTableInfo.rowsData
    );
    setFilterOptions(filterOptionsInfo);
    setPrintData({
      reportType: "",
      reportName: globalizationMapping.ADMIN.MASTERDATAREQUEST,
      rowsData: adminListTableInfo.rowsData,
      headCells: adminListTableInfo.headCells,
      selectedColShow: adminListTableInfo.selectedColShow,
    });
  };

  // eslint-disable-next-line no-unused-vars

  const labelClicked = (index, unique) => {
    setAdminListTableData(null);
    resetTableFilter();
    setUniqueIndex(index);
    if (typeof unique === "object") {
      unique = unique.value.replace(/ /g, "");
    }
    if (typeof unique === "string") {
      unique = unique.replace(/ /g, "");
    }
    setSelectedUniqueType(unique);
    let uniqueArray = adminListDataSetter.setFilteredData(
      originalAdminListTableData?.rowsData,
      unique
    );
    const filters = {
      categories: [
        { name: "RequestedBy", value: "RequestedBy" },
        { name: "Date", value: "Date" },
      ],
      categoryData: {
        RequestedBy: [],
        Date: [],
      },
    };

    let filterOptionsInfo = adminListDataSetter.setFilterOptionsData(
      filters,
      uniqueArray.rowsData
    );
    setFilterOptions(filterOptionsInfo);
    setAdminListTableData(uniqueArray);
    setAdminListCurrentTableData(uniqueArray);
    setPrintData(uniqueArray);
  };
  const handleMarkAsCompleted = () => {
    let requestObject = [];
    action.forEach((row) => {
      let date = new Date(commonUtils.tableDateFormatter(row.Date));
      date = date.toISOString();
      let req = {
        requestId: row.RequestId,
        requestDate: date,
        fieldId: row.Key,
        fieldName: row.FieldType,
        description: row.Description,
        status: "Completed",
        requestor: row.RequestedBy,
        updatedBy: globalAccountState.accountInfo.userSetting.userId,
        selected: "true",
      };
      requestObject.push(req);
      return requestObject;
    });
    adminListService.markAsCompleted(requestObject).then((response) => {
      let uniqueArray = requestObject.reduce(function (a, d) {
        let s = d.fieldName;
        if (a.indexOf(s.replace(/([A-Z])/g, " $1").trim()) === -1) {
          s = s.replace(/([A-Z])/g, " $1").trim();
          a.push(s);
        }
        return a;
      }, []);

      if (!response.didError) {
        setShowNotification(true);
        setNotification({
          notifmessage: globalizationMapping.ADMIN.NotificationMsg,
          notifdesc:
            globalizationMapping.ADMIN.NotificationDesc +
            uniqueArray +
            globalizationMapping.ADMIN.UpdatedSuccessfully,
        });
        AdminListResponseData();
        setTimeout(() => {
          setNotification({ notifmessage: "", notifdesc: "" });
          setShowNotification(false);
        }, 5000);
      }
    });
  };
  const addNewDeleteClicked = (index, _unique) => {
    setAddDeleteIndex(index);
    setTextArea("");
    setTextAreaConfigName("");
    setTextAreaConfigText("");
    setDropdownManufacturer([]);
    setDropdownType([]);
    setDropdownSizeOrAxleConfig([]);
    setFieldId(0);
    setDropdownManufacturerValue(0);
    setDropdownTypeValue(0);
    setDropdownSizeOrAxleConfigValue(0);
  };

  const handleAddNewDeleteSelectChange = (event) => {
    setFieldId(parseInt(event.target.value, 10));
    setTextArea("");
    setTextAreaConfigName("");
    setTextAreaConfigText("");
    setDropdownManufacturer([]);
    setDropdownManufacturerValue(0);
    setDropdownType([]);
    setDropdownTypeValue(0);
    setDropdownSizeOrAxleConfig([]);
    setDropdownSizeOrAxleConfigValue(0);


    switch (parseInt(event.target.value, 10)) {
      case 1:
      case 5:
      case 6:
      case 9:
      case 10:
        setTextArea("");
        setTextAreaConfigName("");
        setTextAreaConfigText("");
        setDropdownManufacturer([]);
        setDropdownType([]);
        setDropdownSizeOrAxleConfig([]);
        break;
      case 2:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.vehicleManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 3:
        {
          setDropdownManufacturer([]);
          setDropdownType([]);
          setDropdownSizeOrAxleConfig([]);
          let vM = [];
          masterData &&
            masterData.vehicleManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
          let vT = [];
          masterData &&
            masterData.vehicleTypes.map((vehicleTypes) => {
              if (vehicleTypes.activeFlag === true) {
                vT.push(vehicleTypes);
              }
              return vT;
            });
          setDropdownType(vT);
          let vC = [];
          masterData &&
            masterData.axleConfigs.map((config) => {
              if (config.activeFlag === true) {
                vC.push(config);
              }
              return vC;
            });
          setDropdownSizeOrAxleConfig(vC);
        }
        break;
      case 7:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.tireManufacturers.map((tireManufacturer) => {
              if (tireManufacturer.activeFlag === true) {
                vM.push(tireManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 8:
        {
          setDropdownManufacturer([]);
          setDropdownType([]);
          setDropdownSizeOrAxleConfig([]);
          let vM = [];
          masterData &&
            masterData.tireManufacturers.map((tireManufacturer) => {
              if (tireManufacturer.activeFlag === true) {
                vM.push(tireManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
          let vC = [];
          masterData &&
            masterData.tireSizes.map((size) => {
              if (size.activeFlag === true) {
                vC.push({
                  manufacturerId: size.tireManufacturerId,
                  sizeId: size.tireSizeId,
                  sizeName: size.tireSizeName,
                });
              }
              return vC;
            });
          setDropdownSizeOrAxleConfig(vC);
        }
        break;
      case 13:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.rimManufacturers.map((rimManufacturer) => {
              if (rimManufacturer.activeFlag === true) {
                vM.push(rimManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 14:
        {
          setDropdownManufacturer([]);
          setDropdownType([]);
          setDropdownSizeOrAxleConfig([]);
          let vM = [];
          masterData &&
            masterData.rimManufacturers.map((rimManufacturer) => {
              if (rimManufacturer.activeFlag === true) {
                vM.push(rimManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 15:
        {
          setDropdownManufacturer([]);
          setDropdownSizeOrAxleConfigValue(0);
          let vM = [];

          masterData &&
            masterData.rimValves.map((rimValue) => {
              if (rimValue.activeFlag === true) {
                vM.push(rimValue);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 16:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.tireManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
          setDropdownSizeOrAxleConfig([]);
          let vT = [];
          masterData &&
            masterData.tireSizes.map((size) => {
              if (size.activeFlag === true) {
                vT.push({
                  manufacturerId: size.tireManufacturerId,
                  sizeId: size.tireSizeId,
                  sizeName: size.tireSizeName,
                });
              }
              return vT;
            });
          setDropdownSizeOrAxleConfig(vT);
          setDropdownType([]);
          let vS = [];
          masterData &&
            masterData.tireTypes.map((size) => {
              if (size.activeFlag === true) {
                vS.push({
                  manufacturerId: size.tireManufacturerId,
                  sizeId: size.tireSizeId,
                  typeId: size.typeId,
                  typeName: size.typeName,
                });
              }
              return vS;
            });
          setDropdownType(vS);
        }
        break;
      default:
        break;
    }
  };
  const handleCancel = () => {
    setFieldId(0);
    setTextArea("");
    setTextAreaConfigName("");
    setTextAreaConfigText("");
    setDropdownManufacturer([]);
    setDropdownType([]);
    setDropdownSizeOrAxleConfig([]);
    setDropdownTypeValue(0);
    setDropdownManufacturerValue(0);
    setDropdownSizeOrAxleConfigValue(0);
  };
  const handleDeleteEntity = () => {
    setShowSpinner(true);
    let requestObj;
    switch (fieldId) {
      case 1:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownManufacturerValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.VehicleSuccesMsg,
          notifdesc:
            globalizationMapping.ADMIN.VehicleManufNotification +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 2:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownTypeValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.VehicleTypeMsg,
          notifdesc:
            globalizationMapping.ADMIN.VehicleTypeNotif +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 3:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownSizeOrAxleConfigValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.VehicleModelNotif,
          notifdesc:
            globalizationMapping.ADMIN.VehicleModelNotifMsg +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 5:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownSizeOrAxleConfigValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.AxleConfigMsg,
          notifdesc:
            globalizationMapping.ADMIN.AxleConfigNotification +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 6:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownManufacturerValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.TireManfSuccessMsg,
          notifdesc:
            globalizationMapping.ADMIN.TireManufMsg +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 7:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownSizeOrAxleConfigValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.TireSizeSuccessMsg,
          notifdesc:
            globalizationMapping.ADMIN.TireSizeMsg +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 8:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownTypeValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.TireTypeSuccessMsg,
          notifdesc:
            globalizationMapping.ADMIN.TireTypeMsg +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 9:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownManufacturerValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.TireCompoundSuccessMsg,
          notifdesc:
            globalizationMapping.ADMIN.TireCompoundMsg +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 10:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownManufacturerValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.RimManufSuccessMsg,
          notifdesc:
            globalizationMapping.ADMIN.RimManufMsg +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 11:
        requestObj =
          "fieldId=" +
          fieldId +
          "&entityId=" +
          dropdownManufacturerValue +
          "&comments=" +
          textArea;
        setNotification({
          notifmessage: globalizationMapping.ADMIN.RemovalReasonSuccessMsg,
          notifdesc:
            globalizationMapping.ADMIN.RemovalReasonMsg +
            textArea +
            globalizationMapping.ADMIN.DeleteSuccMsg,
        });
        break;
      case 13:
        requestObj = {
          rimSizeId: dropdownSizeOrAxleConfigValue,
          updationComments: textArea,
        };
        adminListService.deleteRimSize(requestObj).then((_res) => {
          setFieldId(0);
          setShowNotification(true);
          resetAddDelete();
          setShowSpinner(false);
          setAddDeleteAction(!addDeleteAction);
          setNotification({
            notifmessage: globalizationMapping.ADMIN.RimSizeSuccessMsg,
            notifdesc:
              globalizationMapping.ADMIN.RimSizeMsg +
              textArea +
              globalizationMapping.ADMIN.DeleteSuccMsg,
          });
          setTimeout(() => {
            setShowNotification(false);
            setNotification({
              notifmessage: "",
              notifdesc: "",
            });
          }, 5000);
        });
        break;
      case 14:
        requestObj = {
          rimTypeId: dropdownTypeValue,
          updationComments: textArea,
        };
        adminListService.deleteRimType(requestObj).then((_res) => {
          setFieldId(0);
          setShowNotification(true);
          resetAddDelete();
          setShowSpinner(false);
          setAddDeleteAction(!addDeleteAction);
          setNotification({
            notifmessage: globalizationMapping.ADMIN.RimTypeSuccessMsg,
            notifdesc:
              globalizationMapping.ADMIN.RimTypeMsg +
              textArea +
              globalizationMapping.ADMIN.DeleteSuccMsg,
          });
          setTimeout(() => {
            setShowNotification(false);
            setNotification({
              notifmessage: "",
              notifdesc: "",
            });
          }, 5000);
        });
        break;
      case 15:
        requestObj = {
          rimValveId: dropdownSizeOrAxleConfigValue,
          updationComments: textArea,
        };
        adminListService.deleteRimValve(requestObj).then((_res) => {
          setFieldId(0);
          setShowNotification(true);
          resetAddDelete();
          setShowSpinner(false);
          setAddDeleteAction(!addDeleteAction);
          setNotification({
            notifmessage: globalizationMapping.ADMIN.RimValveSuccessMsg,
            notifdesc:
              globalizationMapping.ADMIN.RimValveMsg +
              textArea +
              globalizationMapping.ADMIN.DeleteSuccMsg,
          });
          setTimeout(() => {
            setShowNotification(false);
            setNotification({
              notifmessage: "",
              notifdesc: "",
            });
          }, 5000);
        });
        break;
      //newly added features...
      case 16:
        requestObj = {
          comments: textArea,
        };

        adminListService
          .deleteTreadDepth(requestObj, tireTreadDefaultId)
          .then((_res) => {
            setFieldId(0);
            setShowNotification(true);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.TreadDeleteSuccessMsg,
              notifdesc:
                globalizationMapping.ADMIN.TireTreadMsg +
                tireTreadDefaultId +
                globalizationMapping.ADMIN.DeleteSuccMsg,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
          });
        break;
      default:
        break;
    }
    if (
      !(
        Number(fieldId) === 13 ||
        Number(fieldId) === 14 ||
        Number(fieldId) === 15 ||
        Number(fieldId) === 16
      )
    ) {
      adminListService.deleteMaster(requestObj).then((_res) => {
        setFieldId(0);
        setShowNotification(true);
        resetAddDelete();
        setShowSpinner(false);
        setAddDeleteAction(!addDeleteAction);
        setTimeout(() => {
          setShowNotification(false);
          setNotification({
            notifmessage: "",
            notifdesc: "",
          });
        }, 5000);
      });
    }
    getMasterDataWeb();
  };

  const handleDeleteChange = (event) => {
    setFieldId(parseInt(event.target.value, 10));
    setTextArea("");
    setTextAreaConfigName("");
    setTextAreaConfigText("");
    setDropdownManufacturer([]);
    setDropdownType([]);
    setDropdownSizeOrAxleConfig([]);
    setDropdownManufacturerValue(0);
    setDropdownTypeValue(0);
    setDropdownSizeOrAxleConfigValue(0);

    switch (parseInt(event.target.value, 10)) {
      case 1:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.vehicleManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 2:
        {
          setDropdownManufacturer([]);
          setDropdownType([]);
          let vM = [];
          masterData &&
            masterData.vehicleManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
          let vT = [];
          masterData &&
            masterData.vehicleTypes.map((size) => {
              if (size.activeFlag === true) {
                vT.push(size);
              }
              return vT;
            });
          setDropdownType(vT);
        }
        break;
      case 3:
        {
          setDropdownManufacturer([]);
          setDropdownType([]);
          let vM = [];
          masterData &&
            masterData.vehicleManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
          let vT = [];
          masterData &&
            masterData.vehicleTypes.map((size) => {
              if (size.activeFlag === true) {
                vT.push(size);
              }
              return vT;
            });
          setDropdownType(vT);
          let vMd = [];
          masterData &&
            masterData.vehicleModels.map((size) => {
              if (size.activeFlag === true) {
                vMd.push(size);
              }
              return vMd;
            });
          setDropdownSizeOrAxleConfig(vMd);
        }
        break;
      case 5:
        {
          setDropdownSizeOrAxleConfig([]);
          let vM = [];
          masterData &&
            masterData.axleConfigs.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownSizeOrAxleConfig(vM);
        }
        break;
      case 6:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.tireManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 7:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.tireManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
          setDropdownSizeOrAxleConfig([]);
          let vT = [];
          masterData &&
            masterData.tireSizes.map((size) => {
              if (size.activeFlag === true) {
                vT.push({
                  manufacturerId: size.tireManufacturerId,
                  sizeId: size.tireSizeId,
                  sizeName: size.tireSizeName,
                });
              }
              return vT;
            });
          setDropdownSizeOrAxleConfig(vT);
        }
        break;
      case 8:
      case 16:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.tireManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
          setDropdownSizeOrAxleConfig([]);
          let vT = [];
          masterData &&
            masterData.tireSizes.map((size) => {
              if (size.activeFlag === true) {
                vT.push({
                  manufacturerId: size.tireManufacturerId,
                  sizeId: size.tireSizeId,
                  sizeName: size.tireSizeName,
                });
              }
              return vT;
            });
          setDropdownSizeOrAxleConfig(vT);
          setDropdownType([]);
          let vS = [];
          masterData &&
            masterData.tireTypes.map((size) => {
              if (size.activeFlag === true) {
                vS.push({
                  manufacturerId: size.tireManufacturerId,
                  sizeId: size.tireSizeId,
                  typeId: size.typeId,
                  typeName: size.typeName,
                });
              }
              return vS;
            });
          setDropdownType(vS);
        }
        break;
      case 9:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.tireCompounds.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 10:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.rimManufacturers.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 11:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.tireRemovalReasons.map((vehicleManufacturer) => {
              if (vehicleManufacturer.activeFlag === true) {
                vM.push(vehicleManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      case 13:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.rimManufacturers.map((rimManufacturer) => {
              if (rimManufacturer.activeFlag === true) {
                vM.push(rimManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
          setDropdownSizeOrAxleConfig([]);
          let vT = [];
          masterData &&
            masterData.rimSizes.map((size) => {
              vT.push(size);
              return vT;
            });
          setDropdownSizeOrAxleConfig(vT);
        }
        break;
      case 14:
        {
          setDropdownManufacturer([]);
          let vM = [];
          masterData &&
            masterData.rimManufacturers.map((rimManufacturer) => {
              if (rimManufacturer.activeFlag === true) {
                vM.push(rimManufacturer);
              }
              return vM;
            });
          setDropdownManufacturer(vM);

          setDropdownType([]);
          let rT = [];
          masterData &&
            masterData.rimTypes.map((rimTypes) => {
              if (rimTypes.activeFlag === true) {
                rT.push(rimTypes);
              }
              return rT;
            });
          setDropdownType(rT);
        }
        break;
      case 15:
        {
          setDropdownManufacturer([]);
          setDropdownSizeOrAxleConfigValue(0);

          let vM = [];
          masterData &&
            masterData.rimValves.map((rimValue) => {
              if (rimValue.activeFlag === true) {
                vM.push(rimValue);
              }
              return vM;
            });
          setDropdownManufacturer(vM);
        }
        break;
      default:
        break;
    }
  };
  const handleAdd = () => {
    setShowSpinner(true);
    let requestObject = [];

    switch (fieldId) {
      case 1:
        {
          let manufactureName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && manufactureName.length > 0) {
            manufactureName.forEach((manuf) => {
              return requestObject.push({ manufacturerName: manuf });
            });
          } else {
            requestObject.push({ manufacturerName: textArea });
          }
          adminListService
            .addVehicleManufacturer(requestObject)
            .then((_res) => {
              setShowNotification(true);
              setNotification({
                notifmessage:
                  globalizationMapping.ADMIN.VehicleManufAddSuccessMsg,
                notifdesc:
                  globalizationMapping.ADMIN.VehicleManufNotification +
                  textArea +
                  globalizationMapping.ADMIN.AddedSuccesfully,
              });
              setTimeout(() => {
                setShowNotification(false);
                setNotification({
                  notifmessage: "",
                  notifdesc: "",
                });
              }, 5000);
              resetAddDelete();
              setShowSpinner(false);
              setAddDeleteAction(!addDeleteAction);
            });
        }
        break;
      case 2:
        {
          let manufactureId = dropdownManufacturerValue;
          let typeName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && typeName.length > 0) {
            typeName.forEach((manuf) => {
              return requestObject.push({
                typeName: manuf,
                manufacturerId: manufactureId,
              });
            });
          } else {
            requestObject.push({
              typeName: textArea,
              manufacturerId: manufactureId,
            });
          }
          adminListService.addVehicleType(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.VehicleTypeAddSuccessMsg,
              notifdesc:
                globalizationMapping.ADMIN.VehicleTypeNotif +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 3:
        {
          let modelName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && modelName.length > 0) {
            modelName.forEach((model) => {
              return requestObject.push({
                modelName: model,
                vehicleManufacturerId: dropdownManufacturerValue,
                vehicleTypeId: dropdownTypeValue,
                configurationId: dropdownSizeOrAxleConfigValue,
              });
            });
          } else {
            requestObject.push({
              modelName: textArea,
              vehicleManufacturerId: dropdownManufacturerValue,
              vehicleTypeId: dropdownTypeValue,
              configurationId: dropdownSizeOrAxleConfigValue,
            });
          }
          adminListService.addVehicleModel(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMINVehicleModelAddSucessMsg,
              notifdesc:
                globalizationMapping.ADMIN.VehicleModelNotifMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 5:
        requestObject.push({
          configurationName: textAreaConfigName,
          configurationText: textAreaConfigText,
          configurationPosition: textArea,
        });
        adminListService.addAxleConfig(requestObject).then((_res) => {
          setShowNotification(true);
          setNotification({
            notifmessage: globalizationMapping.ADMIN.AxleConfigAddSucessMsg,
            notifdesc:
              globalizationMapping.ADMIN.AxleConfigNotification +
              textArea +
              globalizationMapping.ADMIN.AddedSuccesfully,
          });
          setTimeout(() => {
            setShowNotification(false);
            setNotification({
              notifmessage: "",
              notifdesc: "",
            });
          }, 5000);
          resetAddDelete();
          setShowSpinner(false);
          setAddDeleteAction(!addDeleteAction);
        });
        break;
      case 6:
        {
          let manufactureName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && manufactureName.length > 0) {
            manufactureName.forEach((manuf) => {
              return requestObject.push({ manufacturerName: manuf });
            });
          } else {
            requestObject.push({ manufacturerName: textArea });
          }
          adminListService.addTireManufacturers(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.TireManufSuccessMsg,
              notifdesc:
                globalizationMapping.ADMIN.TireManufMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 7:
        {
          let manufactureId = dropdownManufacturerValue;
          let typeName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && typeName.length > 0) {
            typeName.forEach((manuf) => {
              return requestObject.push({
                tireSizeName: manuf,
                tireManufacturerId: manufactureId,
              });
            });
          } else {
            requestObject.push({
              tireSizeName: textArea,
              tireManufacturerId: manufactureId,
            });
          }
          adminListService.addTireSize(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.TireSizeAddSucessMsg,
              notifdesc:
                globalizationMapping.ADMIN.TireSizeMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 8:
        {
          let manufactureId = dropdownManufacturerValue;
          let sizeId = dropdownSizeOrAxleConfigValue;
          let typeName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && typeName.length > 0) {
            typeName.forEach((manuf) => {
              return requestObject.push({
                typeName: manuf,
                tireSizeId: sizeId,
                tireManufacturerId: manufactureId,
              });
            });
          } else {
            requestObject.push({
              typeName: textArea,
              tireSizeId: sizeId,
              tireManufacturerId: manufactureId,
            });
          }
          adminListService.addTireType(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.TireTypeAddSucessMsg,
              notifdesc:
                globalizationMapping.ADMIN.TireTypeMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 9:
        {
          let compoundName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && compoundName.length > 0) {
            compoundName.forEach((manuf) => {
              return requestObject.push({ compoundName: manuf });
            });
          } else {
            requestObject.push({ compoundName: textArea });
          }
          adminListService.addTireCompound(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.TireCompoundAddSucessMsg,
              notifdesc:
                globalizationMapping.ADMIN.TireCompoundMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 10:
        {
          let compoundName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && compoundName.length > 0) {
            compoundName.forEach((manuf) => {
              return requestObject.push({ manufacturerName: manuf });
            });
          } else {
            requestObject.push({ manufacturerName: textArea });
          }
          adminListService.addRimManufacturer(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.RimManufAddSucessMsg,
              notifdesc:
                globalizationMapping.ADMIN.RimManufMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 11:
        {
          let ReasonName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && ReasonName.length > 0) {
            ReasonName.forEach((manuf) => {
              return requestObject.push({ ReasonName: manuf });
            });
          } else {
            requestObject.push({ ReasonName: textArea });
          }
          adminListService.addRemovalReason(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage:
                globalizationMapping.ADMIN.RemovalReasonAddSuccessMsg,
              notifdesc:
                globalizationMapping.ADMIN.RemovalReasonMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });

            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 13:
        {
          let typeName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && typeName.length > 0) {
            typeName.forEach((manuf) => {
              return requestObject.push({
                rimSizeName: manuf,
              });
            });
          } else {
            requestObject.push({
              rimSizeName: textArea,
            });
          }
          adminListService.addRimSize(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.RimSizeAddSucessMsg,
              notifdesc:
                globalizationMapping.ADMIN.RimSizeMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 14:
        {
          let manufactureId = dropdownManufacturerValue;
          let typeName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && typeName.length > 0) {
            typeName.forEach((manuf) => {
              return requestObject.push({
                RimTypeName: manuf,
                manufacturerId: manufactureId,
              });
            });
          } else {
            requestObject.push({
              RimTypeName: textArea,
              manufacturerId: manufactureId,
            });
          }
          adminListService.addRimType(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.RimTypeAddSucessMsg,
              notifdesc:
                globalizationMapping.ADMIN.RimTypeMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 15:
        {
          let typeName = textArea?.split(",");
          if (textArea?.indexOf(",") !== -1 && typeName.length > 0) {
            typeName.forEach((manuf) => {
              return requestObject.push({
                rimValveName: manuf,
              });
            });
          } else {
            requestObject.push({
              rimValveName: textArea,
            });
          }
          adminListService.addRimValve(requestObject).then((_res) => {
            setShowNotification(true);
            setNotification({
              notifmessage: globalizationMapping.ADMIN.RimTypeValveSucessMsg,
              notifdesc:
                globalizationMapping.ADMIN.RimValveMsg +
                textArea +
                globalizationMapping.ADMIN.AddedSuccesfully,
            });
            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      case 16:
        {
          let manufactureId = dropdownManufacturerValue;
          let sizeId = dropdownSizeOrAxleConfigValue;
          let typeId = dropdownTypeValue;
          const index = masterData?.tireManufacturers.findIndex((item) => {
            return item.manufacturerId === manufactureId;
          });
          const index1 = masterData?.tireSizes.findIndex((item) => {
            return item.tireSizeId === sizeId;
          });
          const index2 = masterData?.tireTypes.findIndex((item) => {
            return item.typeId === typeId;
          });
          const manufacturerName =
            masterData?.tireManufacturers[index].manufacturerName;
          const sizeName = masterData?.tireSizes[index1].tireSizeName;
          const typeName = masterData?.tireTypes[index2].typeName;
          let requestObject = {
            ManufacturerName: manufacturerName,
            Size: sizeName,
            Type: typeName,
            TreadMM: textArea,
            Construction: "",
          };
          adminListService.addTireTreadDepth(requestObject).then((res) => {
            setShowNotification(true);
            if (res.data.added === true) {
              setNotification({
                notifmessage: globalizationMapping.ADMIN.TireTreadAddSucessMsg,
                notifdesc:
                  globalizationMapping.ADMIN.TireTreadMsg +
                  textArea +
                  globalizationMapping.ADMIN.AddedSuccesfully,
              });
            } else {
              setNotification({
                notifmessage: globalizationMapping.ADMIN.TireTreadAddExistsMsg,
                notifdesc:
                  globalizationMapping.ADMIN.TireTreadMsg +
                  textArea +
                  globalizationMapping.ADMIN.AlreadyExists,
              });
            }

            setTimeout(() => {
              setShowNotification(false);
              setNotification({
                notifmessage: "",
                notifdesc: "",
              });
            }, 5000);
            resetAddDelete();
            setShowSpinner(false);
            setAddDeleteAction(!addDeleteAction);
          });
        }
        break;
      default:
        break;
    }
    getMasterDataWeb();
  };
  const handleVFP = () => {
    setShowSpinner(true);
    let accountid =
      parentAccount.parentAccountId === "All"
        ? ""
        : parentAccount.parentAccountId;
    adminListService.vehicleFootPrint(accountid).then((_res) => {
      setShowSpinner(false);
    });
  };
  const handleVTS = () => {
    setShowSpinner(true);
    let accountid =
      parentAccount.parentAccountId === "All"
        ? ""
        : parentAccount.parentAccountId;
    adminListService.vehicleTireSize(accountid).then((_res) => {
      setShowSpinner(false);
    });
  };
  const handlePW = () => {
    setShowSpinner(true);
    adminListService
      .manageTiresByAccount(parentAccount.parentAccountId)
      .then((res) => {
        let reqCount = 0;
        res.model.forEach((element) => {
          adminListService.getTireById(element.tireId).then((tireDataRes) => {
            let tireData = tireDataRes.model;
            let oldPercentageWorn = tireData.percentageWorn;
            let finalPercentageWorn = Math.round(
              (1 -
                (tireData.currentTreadDepth - tireData.removeAt) /
                (tireData.originalTread - tireData.removeAt)) *
              100
            );
            if (oldPercentageWorn !== finalPercentageWorn) {
              tireData.percentageWorn = finalPercentageWorn;
              adminListService.updateTire(tireData).then((_tireDataRes) => {
                reqCount++;
              });
            } else {
              reqCount++;
            }
            if (reqCount === res.model.length) {
              setShowSpinner(false);
              setParentAccount({
                accountName: "All",
                createdBy: "All",
                parentAccountId: "All",
              });
            }
          });
        });

        // set since the forever loop spinner was stuck (reqCount is not qual to res.model.length)
        setShowSpinner(false);
        setParentAccount({
          accountName: "All",
          createdBy: "All",
          parentAccountId: "All",
        });

      });
  };
  const handleAT = () => {
    setShowSpinner(true);
    adminListService
      .manageTiresByAccount(parentAccount.parentAccountId)
      .then((res) => {
        let reqCount = 0;
        res.model.forEach((element) => {
          adminListService.getTireById(element.tireId).then((tireDataRes) => {
            let tireData = tireDataRes.model;
            let finalAvgTread;
            if (
              tireData.outsideTread !== null &&
              tireData.outsideTread !== undefined &&
              tireData.insideTread !== null &&
              tireData.insideTread !== undefined &&
              tireData.middleTread !== null &&
              tireData.middleTread !== undefined
            ) {
              finalAvgTread = Math.round(
                (tireData.insideTread +
                  tireData.outsideTread +
                  tireData.middleTread) /
                3
              );
            } else if (
              tireData.outsideTread !== null &&
              tireData.outsideTread !== undefined &&
              tireData.insideTread !== null &&
              tireData.insideTread !== undefined
            ) {
              finalAvgTread = Math.round(
                (tireData.insideTread + tireData.outsideTread) / 2
              );
            } else if (
              tireData.outsideTread !== null &&
              tireData.outsideTread !== undefined &&
              tireData.insideTread === null &&
              tireData.insideTread === undefined &&
              tireData.middleTread === null &&
              tireData.middleTread === undefined
            ) {
              finalAvgTread = Math.round(tireData.outsideTread);
            } else if (
              tireData.insideTread !== null &&
              tireData.insideTread !== undefined &&
              tireData.outsideTread === null &&
              tireData.outsideTread === undefined &&
              tireData.middleTread === null &&
              tireData.middleTread === undefined
            ) {
              finalAvgTread = Math.round(tireData.insideTread);
            } else if (
              tireData.middleTread !== null &&
              tireData.middleTread !== undefined &&
              tireData.outsideTread === null &&
              tireData.outsideTread === undefined &&
              tireData.insideTread === null &&
              tireData.insideTread === undefined
            ) {
              finalAvgTread = Math.round(tireData.middleTread);
            } else {
              finalAvgTread = null;
            }
            if (tireData.averageTreadDepth !== finalAvgTread) {
              tireData.averageTreadDepth = finalAvgTread;
              adminListService.updateTire(tireData).then((_tireDataRes) => {
                reqCount++;
                if (reqCount === res.model.length) {
                  setShowSpinner(false);
                  setParentAccount({
                    accountName: "All",
                    createdBy: "All",
                    parentAccountId: "All",
                  });
                }
              });
            } else {
              reqCount++;
              if (reqCount === res.model.length) {
                setShowSpinner(false);
                setParentAccount({
                  accountName: "All",
                  createdBy: "All",
                  parentAccountId: "All",
                });
              }
            }
          });
        });
        // set since the forever loop spinner was stuck (reqCount is not qual to res.model.length)
        setShowSpinner(false);
        setParentAccount({
          accountName: "All",
          createdBy: "All",
          parentAccountId: "All",
        });
      });
  };
  const resetAddDelete = () => {
    setFieldId(0);
    setTextArea("");
    setTextAreaConfigName("");
    setTextAreaConfigText("");
    setDropdownManufacturer([]);
    setDropdownType([]);
    setDropdownSizeOrAxleConfig([]);
  };
  const regexHandleFor = (e) => {
    let regexp = /^\d*(\.\d{0,2})?$/;
    if (regexp.test(e.target.value)) {
      setTextArea(e.target.value);
    }
  };
  const handleBroadCastMessage = () => {
    resetTextFieldIsEditState();
    let req = {
      title: textTitle,
      description: textDescription,
    };
    setShowSpinner(true);
    headerApiObj.putBroadCastAPI(req).then((_data) => {
      setShowSpinner(false);
      setShowNotification(true);
      setNotification({
        notifmessage: globalizationMapping.ADMIN.BroadCastMsgAddedSuccessfully,
        notifdesc: "Hey Checkout your latest updates",
      });
      setTimeout(() => {
        setShowNotification(false);
        setNotification({
          notifmessage: "",
          notifdesc: "",
        });
      }, 5000);
      setShowSpinner(false);
      handleBroadCastCancel();
    });
  };
  const handleBroadCastCancel = () => {
    resetTextFieldIsEditState();
    setTextTitle("");
    setTextDescription("");
  };

  const getByDefaultTreadValue = (typeId) => {
    let manufactureId = dropdownManufacturerValue;
    let sizeId = dropdownSizeOrAxleConfigValue;
    const index = masterData?.tireManufacturers.findIndex((item) => {
      return item.manufacturerId === manufactureId;
    });
    const index1 = masterData?.tireSizes.findIndex((item) => {
      return item.tireSizeId === sizeId;
    });
    const index2 = masterData?.tireTypes.findIndex((item) => {
      return item.typeId === typeId;
    });
    const manufacturerName =
      masterData?.tireManufacturers[index].manufacturerName;
    const sizeName = masterData?.tireSizes[index1].tireSizeName;
    const typeName = masterData?.tireTypes[index2].typeName;
    let requestObject = {
      ManufacturerName: manufacturerName,
      Size: sizeName,
      Type: typeName,
    };
    headerApiObj.getTreadDepthDefaultValue(requestObject).then((treaddepth) => {
      if (treaddepth.data === null) {
        let treadId = 0;
        setTireTreadId(treadId);
        setTreadDepthValue(treadId);
      } else {
        setTireTreadId(treaddepth.data.tireTreadId);
        setTreadDepthValue(treaddepth.data.treadDepth);
      }
    });
  };
  const getBroadCastData = () => {
    setShowSpinner(true);
    headerApiObj.getBroadCastDetails().then((data) => {
      setShowSpinner(false);
      setbroadCastData(data.data);
    });
  };
  const deleteMessage = (id) => {
    adminListService.deleteBroadcastMessage(id).then((_res) => {
      getBroadCastData();
      setShowNotification(true);
      setShowSpinner(false);
      setNotification({
        notifmessage: "Deleted Successfully",
        notifdesc: "BroadCast Message Deleted Successfully",
      });
      setTimeout(() => {
        setShowNotification(false);
        setNotification({
          notifmessage: "",
          notifdesc: "",
        });
      }, 5000);
    });
  };
  const addNewDeleteBroadCatClicked = (index, _unique) => {
    setAddDeleteBroadcastIndex(index);
    if (index === 1) {
      getBroadCastData();
    }
  };

  const handleBroadcastTitle = (e) => {
    setIsTitleEdited(true);
    setTextTitle(e.target.value)
  }

  const handleBroadcastDescription = (value) => {
    setIsDescriptionEdited(true);
    setTextDescription(value);
  }

  const handleOnchangeHandle = (e) => {
    let value = e.target.value;
    if (value === "VFP") {
      handleVFP();
    } else if (value === "VTS") {
      handleVTS();
    } else if (value === "PW") {
      handlePW();
    } else if (value === "AT") {
      handleAT();
    } else {
      return;
    }
  }



  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean'],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
    ],
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image',
    'clean',
    'size',
    'color', 'background',
    'font',
    'align'
  ];

  return (
    <div className="container">
      <AdminNavigation changedRoute={changedRoute} />
      {showSpinner && <Spinner />}
      {!showSpinner && currentRoute.path === "admin" && (
        <div className="admin-list-container">
          {showNotification && (
            <FlashMessage
              status={true}
              message={notification.notifmessage}
              description={notification.notifdesc}
            />
          )}

          <div className="manage-button-admin-nav-sub-container">
            <div className="admin-nav-sub-container">
              {uniqueRequestType.map((unique, index) => {
                return (
                  <div
                    key={unique.value}
                    className="admin-nav-card-sub-container"
                    onClick={() => labelClicked(index, unique.value)}
                  >
                    <List
                      className={
                        uniqueIndex === index
                          ? "active-admin-nav-sub-card"
                          : "admin-nav-sub-card"
                      }
                    >
                      <ListItem
                        button={uniqueIndex === index}
                        autoFocus={uniqueIndex === index}
                      >
                        <span
                          className={
                            uniqueIndex === index
                              ? "active-admin-nav-sub-card-text"
                              : "admin-nav-sub-card-text"
                          }
                        >
                          {unique.label}{" "}
                          {othersCount[unique.value] && (
                            <Badge
                              classes={{ badge: classes.customBadge }}
                              className={`${classes.margin
                                } ${"badge-style"}`}
                              max={999}
                              badgeContent={othersCount[unique]}
                              color="primary"
                            ></Badge>
                          )}
                        </span>
                      </ListItem>
                    </List>
                  </div>
                );
              })}
            </div>
          </div>

          <Card className="admin-list-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  {action.length > 0 && (
                    <div className="tire-search-table-container">
                      <form
                        className="tire-search-table-container"
                        noValidate
                        autoComplete="off"
                        onSubmit={formSubmitHandler}
                      >
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">
                            {action.length}{" "}
                            {globalizationMapping.ADMIN.RowsSelected}
                          </span>
                        </InputLabel>
                        <Button
                          className="admin-action-dropdown"
                          onClick={() => handleMarkAsCompleted()}
                        >
                          {globalizationMapping.ADMIN.MarkAsCompleted}
                        </Button>
                      </form>
                    </div>
                  )}
                  <div className="tire-search-table-container">
                    <form
                      className="tire-search-table-container"
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.REPORTCOMMONWORDS.SearchTable}
                        </span>
                      </InputLabel>
                      <TextField
                        className="search-admin  search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.PLACEHOLDER.SEARCH}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="keyword-search-icon-img"
                                alt=""
                                src={SearchIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                  onClickFilter={onClickFilter}
                  onClickEmail={onClickEmail}
                  onClickPrint={onClickPrint}
                  onClickExport={onClickExport}
                  toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                  printData={printData}
                  ref={ref}
                  onlyFilterApplied={onlyFilterApplied}
                  noData={noData}
                  isComponentVisible={isComponentVisible}
                />
              </div>

              <div className="data-summary-table">
                {adminListTableData && (
                  <AdminListTable
                    tableData={adminListTableData}
                    showColumns={showColumns}
                    filterApplied={filterApplied}
                    multipleRowsSelected={multipleRowsSelected}
                    currentRoute={currentRoute}
                  />
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {currentRoute.path === "admin" && (
        <PrintExportModal
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          tableData={printData}
          noGraph={true}
        />
      )}
      {currentRoute.path === "admin" && (
        <PdfExportModal
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          tableData={printData}
          noGraph={true}
        />
      )}
      {currentRoute.path === "admin" && (
        <EmailExportModal
          modalHandler={modalHandler}
          setModalHandler={setModalHandler}
          tableData={printData}
          noGraph={true}
        />
      )}
      {currentRoute.path === "admin" && (
        <Filter
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          filterOptions={filterOptions}
          confirmApplyDateFilter={confirmApplyFilter}
          resetTableFilter={resetTableFilter}
          onFilterUpdateMain={updateFilterMain}
          noDateFilter={true}
        />
      )}
      {!showSpinner && currentRoute.path === "add-delete" && (
        <div className="admin-container-height">
          <div className="admin-list-container" >
            {showNotification && (
              <FlashMessage
                status={true}
                message={notification.notifmessage}
                description={notification.notifdesc}
              />
            )}
            <div className="manage-button-admin-nav-sub-container">
              <div className="admin-nav-sub-container">
                {addNewDelete.map((unique, index) => (
                  <div
                    key={unique}
                    sm={2}
                    lg={2}
                    md={2}
                    xl={2}
                    onClick={() => {
                      addNewDeleteClicked(index, unique);
                    }}
                    className={
                      addDeleteIndex === index
                        ? "active-admin-nav-sub-card-text"
                        : "admin-nav-sub-card-text"
                    }
                    style={{ marginLeft: 25, marginBottom: 15 }}
                  >
                    {unique}
                  </div>
                ))}
              </div>
            </div>


            <Card className="admin-list-card">
              {/* Add function */}
              {addDeleteIndex === 0 && (
                <CardContent>
                  <Grid container>
                    <Grid item sm={12} className="grid-add-delete-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.SelectItem}{" "}
                          <span className="mandatory-sign-add-new">*</span>
                        </span>
                      </InputLabel>
                      <Select
                        native
                        className="add-new-delete-box"
                        value={fieldId}
                        onChange={(event) =>
                          handleAddNewDeleteSelectChange(event)
                        }
                        defaultValue={0}
                        IconComponent={ExpandMoreStyledIcon}
                      >
                        <option key={0} value={0} disabled>
                          {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                        </option>
                        {availableHierarchy?.map((hierarchy) => {
                          return (
                            <option value={hierarchy.id} key={hierarchy.id}>
                              {hierarchy.hierarchy}
                            </option>
                          );
                        })}
                      </Select>
                    </Grid>
                    {(Number(fieldId) === 1 ||
                      Number(fieldId) === 6 ||
                      Number(fieldId) === 9 ||
                      Number(fieldId) === 10 ||
                      Number(fieldId) === 11) && (
                        <Grid item sm={12} className="grid-add-delete-container">
                          <InputLabel className="filter-table-label">
                            {Number(fieldId) !== 9 && Number(fieldId) !== 11 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.ManufactureName}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                            {Number(fieldId) === 9 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.Compound}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                            {Number(fieldId) === 11 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.RemovalReason}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                          </InputLabel>
                          <TextField
                            className="add-new-delete-box enter-detail-input-box text-area"
                            variant="outlined"
                            multiline
                            value={textArea}
                            onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                            helperText=""
                            placeholder={
                              globalizationMapping.ADMIN.INSERTNAMESBYCOMAS
                            }
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid>
                      )}

                    {(Number(fieldId) === 2 ||
                      Number(fieldId) === 7 ||
                      Number(fieldId) === 13 ||
                      Number(fieldId) === 15) && (
                        <Grid item sm={12} className="grid-add-delete-container">
                          <InputLabel className="filter-table-label">
                            {(Number(fieldId) === 2 || Number(fieldId) === 7) && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.ManufactureName}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                          </InputLabel>
                          {(Number(fieldId) === 2 || Number(fieldId) === 7) && (
                            <Select
                              native
                              className="add-new-delete-box"
                              value={dropdownManufacturerValue}
                              onChange={(event) => {
                                setDropdownManufacturerValue(parseInt(event.target.value, 10));
                                setDropdownTypeValue(0);
                                setDropdownSizeOrAxleConfigValue(0);
                              }}
                              IconComponent={ExpandMoreStyledIcon}
                            >
                              <option value={0} disabled>
                                {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                              </option>
                              {dropdownManufacturer?.map((manufacture) => {
                                return (
                                  <option value={manufacture.manufacturerId} key={manufacture.manufacturerId}>
                                    {manufacture.manufacturerName}
                                  </option>
                                );
                              })}
                            </Select>
                          )}

                          <InputLabel className="filter-table-label inside-fields">
                            {Number(fieldId) === 2 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.TypeName}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                            {(Number(fieldId) === 7 || Number(fieldId) === 13) && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.SizeName}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                            {Number(fieldId) === 15 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.ValveTypeName}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                          </InputLabel>
                          <TextField
                            className="add-new-delete-box enter-detail-input-box text-area"
                            variant="outlined"
                            multiline
                            value={textArea}
                            onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                            helperText=""
                            placeholder={
                              globalizationMapping.ADMIN.INSERTNAMESBYCOMAS
                            }
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid>
                      )}
                    {(Number(fieldId) === 3 ||
                      Number(fieldId) === 8 ||
                      Number(fieldId) === 14 ||
                      Number(fieldId) === 16) && (
                        <Grid item sm={12} className="grid-add-delete-container">
                          <InputLabel className="filter-table-label">
                            <span className="table-toolbar-label-text">
                              {globalizationMapping.ADMIN.ManufactureName}{" "}
                              <span className="mandatory-sign-add-new">*</span>
                            </span>
                          </InputLabel>
                          <Select
                            native
                            className="add-new-delete-box"
                            value={dropdownManufacturerValue}
                            onChange={(event) => {
                              setDropdownManufacturerValue(parseInt(event.target.value, 10));
                              setDropdownTypeValue(0);
                              setDropdownSizeOrAxleConfigValue(0);
                            }}
                            IconComponent={ExpandMoreStyledIcon}
                          >
                            <option value={0} disabled>
                              {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                            </option>
                            {dropdownManufacturer?.map((manufacture) => {
                              return (
                                <option value={manufacture.manufacturerId} key={manufacture.manufacturerId}>
                                  {manufacture.manufacturerName}
                                </option>
                              );
                            })}
                          </Select>
                          {Number(fieldId) === 3 && (
                            <>
                              <InputLabel className="filter-table-label inside-fields">
                                <span className="table-toolbar-label-text">
                                  {globalizationMapping.ADMIN.TypeName}{" "}
                                  <span className="mandatory-sign-add-new">*</span>{" "}
                                  :
                                </span>
                              </InputLabel>
                              <Select
                                native
                                className="add-new-delete-box"
                                value={dropdownTypeValue}
                                onChange={(event) =>
                                  setDropdownTypeValue(parseInt(event.target.value, 10))
                                }
                                disabled={dropdownManufacturerValue === 0}
                                IconComponent={ExpandMoreStyledIcon}
                              >
                                <option value={0} disabled>
                                  {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                                </option>
                                {dropdownType?.map((type) => {
                                  return (
                                    parseInt(dropdownManufacturerValue, 10) ===
                                    type.manufacturerId && (
                                      <option value={type.typeId}>
                                        {type.typeName}
                                      </option>
                                    )
                                  );
                                })}
                              </Select>
                            </>
                          )}
                          <InputLabel className="filter-table-label inside-fields">
                            {Number(fieldId) === 3 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.ConfigurationName}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                            {Number(fieldId) === 8 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.Size}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                          </InputLabel>
                          {Number(fieldId) === 3 && (
                            <Select
                              native
                              className="add-new-delete-box"
                              value={dropdownSizeOrAxleConfigValue}
                              onChange={(event) =>
                                setDropdownSizeOrAxleConfigValue(parseInt(event.target.value, 10))
                              }
                              IconComponent={ExpandMoreStyledIcon}
                            >
                              <option value={0} disabled>
                                {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                              </option>
                              {dropdownSizeOrAxleConfig?.map((config) => {
                                return (
                                  <option value={config.configurationId} key={config.configurationId}>
                                    {config.configurationText}
                                  </option>
                                );
                              })}
                            </Select>
                          )}
                          {Number(fieldId) === 8 && (
                            <Select
                              native
                              className="add-new-delete-box"
                              value={dropdownSizeOrAxleConfigValue}
                              onChange={(event) => {
                                setDropdownSizeOrAxleConfigValue(parseInt(event.target.value, 10));
                                setDropdownTypeValue(0);
                              }}
                              disabled={dropdownManufacturerValue === 0}
                              IconComponent={ExpandMoreStyledIcon}
                            >
                              <option value={0} disabled>
                                {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                              </option>
                              {dropdownSizeOrAxleConfig?.map((size) => {
                                return (
                                  parseInt(dropdownManufacturerValue, 10) === size.manufacturerId && (
                                    <option value={size.sizeId}>
                                      {size.sizeName}
                                    </option>
                                  )
                                );
                              })}
                            </Select>
                          )}

                          <InputLabel className="filter-table-label">
                            {Number(fieldId) === 16 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.Size}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                          </InputLabel>
                          {Number(fieldId) === 16 && (
                            <Select
                              native
                              className="add-new-delete-box"
                              value={dropdownSizeOrAxleConfigValue}
                              onChange={(event) => {
                                setDropdownSizeOrAxleConfigValue(parseInt(event.target.value, 10));
                                setDropdownTypeValue(0);
                              }}
                              IconComponent={ExpandMoreStyledIcon}
                              disabled={dropdownManufacturerValue === 0}
                            >
                              <option value={0} disabled>
                                {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                              </option>
                              {dropdownSizeOrAxleConfig?.map((size) => {
                                return (
                                  parseInt(dropdownManufacturerValue, 10) ===
                                  size.manufacturerId && (
                                    <option value={size.sizeId} key={size.sizeId}>
                                      {size.sizeName}
                                    </option>
                                  )
                                );
                              })}
                            </Select>
                          )}
                          {Number(fieldId) === 16 && (
                            <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.SelectType}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            </InputLabel>
                          )}

                          {Number(fieldId) === 16 && (
                            <Select
                              native
                              className="add-new-delete-box"
                              value={dropdownTypeValue}
                              onChange={(event) =>
                                setDropdownTypeValue(parseInt(event.target.value, 10))
                              }
                              IconComponent={ExpandMoreStyledIcon}
                              disabled={dropdownSizeOrAxleConfigValue === 0}
                            >
                              <option value={0} disabled>
                                {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                              </option>
                              {dropdownType?.map((type) => {
                                return (
                                  parseInt(dropdownManufacturerValue, 10) === type.manufacturerId &&
                                  parseInt(dropdownSizeOrAxleConfigValue, 10) === type.sizeId &&
                                  (<option value={type.typeId}>
                                    {type.typeName}
                                  </option>)
                                );
                              })}
                            </Select>
                          )}

                          <InputLabel className="filter-table-label inside-fields">
                            {Number(fieldId) === 16 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.ThreadDepthValue}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                          </InputLabel>

                          <InputLabel className="filter-table-label inside-fields">
                            {Number(fieldId) === 3 && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.ModelName}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                            {(Number(fieldId) === 8 || Number(fieldId) === 14) && (
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.TypeName}{" "}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            )}
                          </InputLabel>
                          {Number(fieldId) === 16 && (
                            <Input
                              className="add-new-delete-box enter-detail-input-box text-area1"
                              variant="outlined"
                              value={textArea}
                              onChange={(e) => {
                                regexHandleFor(e);
                              }}
                              helperText=""
                              placeholder={
                                globalizationMapping.ADMIN.INSERTTreadDepth
                              }
                            />
                          )}
                          {Number(fieldId) !== 16 && (
                            <TextField
                              className="add-new-delete-box enter-detail-input-box text-area"
                              variant="outlined"
                              multiline
                              value={textArea}
                              onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                              helperText=""
                              placeholder={
                                globalizationMapping.ADMIN.INSERTNAMESBYCOMAS
                              }
                              inputProps={{ maxLength: 50 }}
                            />
                          )}
                        </Grid>
                      )}
                    {Number(fieldId) === 5 && (
                      <Grid item sm={12} className="grid-add-delete-container">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">
                            {globalizationMapping.ADMIN.ConfigName}{" "}
                            <span className="mandatory-sign-add-new">*</span>
                          </span>
                        </InputLabel>
                        <TextField
                          className="add-new-delete-box enter-detail-input-box"
                          variant="outlined"
                          value={textAreaConfigName}
                          onChange={(e) => setTextAreaConfigName(e.target.value)}
                          helperText=""
                          placeholder={"22"}
                          inputProps={{ maxLength: 50 }}
                        />
                        <InputLabel className="filter-table-label inside-fields">
                          <span className="table-toolbar-label-text">
                            {globalizationMapping.ADMIN.ConfigurationText}{" "}
                            <span className="mandatory-sign-add-new">*</span>
                          </span>
                        </InputLabel>
                        <TextField
                          className="add-new-delete-box enter-detail-input-box"
                          variant="outlined"
                          value={textAreaConfigText}
                          onChange={(e) => setTextAreaConfigText(e.target.value)}
                          helperText=""
                          placeholder={"2 Axle(s) with 1 Tire(s) each side"}
                          inputProps={{ maxLength: 50 }}
                        />
                        <InputLabel className="filter-table-label inside-fields">
                          <span className="table-toolbar-label-text">
                            {globalizationMapping.ADMIN.ConfigPosition}{" "}
                            <span className="mandatory-sign-add-new">*</span>
                          </span>
                        </InputLabel>
                        <TextField
                          className="add-new-delete-box enter-detail-input-box"
                          variant="outlined"
                          value={textArea}
                          onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                          helperText=""
                          placeholder={"(No. of Tire)(Position: L or R) per axle  e.g. 1L,1R,1L,1R"}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <hr className="hr-divider-action" />

                  <div className="action-buttons">
                    <Button
                      className={styling.cancel}
                      variant="contained"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      {globalizationMapping.ADMIN.Cancel}
                    </Button>

                    <Button
                      className={styling.next}
                      variant="contained"
                      color="secondary"
                      onClick={() => handleAdd()}
                    >
                      {globalizationMapping.ADMIN.Add}
                    </Button>
                  </div>
                </CardContent>
              )}



              {/* Delete Function */}
              {addDeleteIndex === 1 && (
                <CardContent>
                  <Grid item sm={12} className="grid-add-delete-container">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">
                        {globalizationMapping.ADMIN.SelectItem}{" "}
                        <span className="mandatory-sign-add-new">*</span>
                      </span>
                    </InputLabel>
                    <Select
                      native
                      className="add-new-delete-box"
                      value={fieldId}
                      onChange={(event) => handleDeleteChange(event)}
                      IconComponent={ExpandMoreStyledIcon}
                    >
                      <option key={0} value={0} disabled>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>
                      {availableHierarchy.map((hierarchy) => {
                        return (
                          <option value={hierarchy.id} key={hierarchy.id}>
                            {hierarchy.hierarchy}
                          </option>
                        );
                      })}
                    </Select>
                  </Grid>
                  {(Number(fieldId) === 1 ||
                    Number(fieldId) === 6 ||
                    Number(fieldId) === 10 ||
                    Number(fieldId) === 16) && (
                      <Grid item sm={12} className="grid-add-delete-container">
                        <InputLabel className="filter-table-label inside-fields">
                          <span className="table-toolbar-label-text">
                            {globalizationMapping.ADMIN.SelectManufacturer}
                            <span className="mandatory-sign-add-new">*</span>
                          </span>
                        </InputLabel>
                        <Select
                          native
                          className="add-new-delete-box"
                          value={dropdownManufacturerValue}
                          onChange={(event) => {
                            setDropdownManufacturerValue(parseInt(event.target.value, 10));
                            setDropdownTypeValue(0);
                            setDropdownSizeOrAxleConfigValue(0);
                            setTreadDepthValue(0);
                          }}
                          IconComponent={ExpandMoreStyledIcon}
                        >
                          <option value={0} disabled>
                            {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                          </option>
                          {dropdownManufacturer?.map((manuf) => {
                            return (
                              <option value={manuf.manufacturerId} key={manuf.manufacturerId}>
                                {manuf.manufacturerName}
                              </option>
                            );
                          })}
                        </Select>

                        <InputLabel className="filter-table-label inside-fields">
                          {Number(fieldId) === 16 && (
                            <span className="table-toolbar-label-text">
                              {globalizationMapping.ADMIN.Size}{" "}
                              <span className="mandatory-sign-add-new">*</span>
                            </span>
                          )}
                        </InputLabel>

                        {Number(fieldId) === 16 && (
                          <Select
                            native
                            className="add-new-delete-box"
                            value={dropdownSizeOrAxleConfigValue}
                            onChange={(event) => {
                              setDropdownSizeOrAxleConfigValue(parseInt(event.target.value, 10));
                              setDropdownTypeValue(0);
                              setTreadDepthValue(0);
                            }}
                            IconComponent={ExpandMoreStyledIcon}
                            disabled={dropdownManufacturerValue === 0}
                          >
                            <option value={0} disabled>
                              {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                            </option>
                            {dropdownSizeOrAxleConfig?.map((size) => {
                              return (
                                parseInt(dropdownManufacturerValue, 10) ===
                                size.manufacturerId && (
                                  <option value={size.sizeId}>
                                    {size.sizeName}
                                  </option>
                                )
                              );
                            })}
                          </Select>
                        )}
                        {Number(fieldId) === 16 && (
                          <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                            <span className="table-toolbar-label-text">
                              {globalizationMapping.ADMIN.SelectType}
                              <span className="mandatory-sign-add-new">*</span>
                            </span>
                          </InputLabel>
                        )}

                        {Number(fieldId) === 16 && (
                          <Select
                            native
                            className="add-new-delete-box"
                            value={dropdownTypeValue}
                            onChange={(event) => {
                              setDropdownTypeValue(parseInt(event.target.value, 10));
                              getByDefaultTreadValue(parseInt(event.target.value, 10));
                              setTreadDepthValue(0);
                            }}
                            IconComponent={ExpandMoreStyledIcon}
                            disabled={dropdownSizeOrAxleConfigValue === 0}
                          >
                            <option value={0} disabled>
                              {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                            </option>
                            {dropdownType?.map((type) => {
                              return (
                                parseInt(dropdownManufacturerValue, 10) ===
                                type.manufacturerId &&
                                parseInt(dropdownSizeOrAxleConfigValue, 10) === type.sizeId && (
                                  <option value={type.typeId}>
                                    {type.typeName}
                                  </option>
                                )
                              );
                            })}
                          </Select>
                        )}

                        <InputLabel className="filter-table-label inside-fields">
                          {Number(fieldId) === 16 && (
                            <span className="table-toolbar-label-text">
                              {globalizationMapping.ADMIN.ThreadDepthValue}{" "}
                            </span>
                          )}
                        </InputLabel>
                        {Number(fieldId) === 16 && (
                          <Input
                            className="add-new-delete-box enter-detail-input-box text-area1 css-disable"
                            variant="outlined"
                            value={treadDepthValue}
                            helperText=""
                            disabled
                          />
                        )}
                        {Number(fieldId) === 16 && treadDepthValue === 0 && (
                          <FormHelperText className={classes.FormHelperText}>
                            {globalizationMapping.ADMIN.ErrorMessageTreadDepth}
                          </FormHelperText>
                        )}
                        <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                          <span className="table-toolbar-label-text">
                            {globalizationMapping.ADMIN.CommentsAdd}
                          </span>
                        </InputLabel>
                        <TextField
                          className="add-new-delete-box enter-detail-input-box"
                          variant="outlined"
                          value={textArea}
                          onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                          helperText=""
                          placeholder={globalizationMapping.PLACEHOLDER.Comments}
                          inputProps={{ maxLength: 200 }}
                        />
                      </Grid>
                    )}
                  {Number(fieldId) === 9 && (
                    <Grid item sm={12} className="grid-add-delete-container">
                      <InputLabel className="filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.SelectCompound}
                          <span className="mandatory-sign-add-new">*</span>
                        </span>
                      </InputLabel>
                      <Select
                        native
                        className="add-new-delete-box"
                        value={dropdownManufacturerValue}
                        onChange={(event) => {
                          setDropdownManufacturerValue(parseInt(event.target.value, 10));
                          setDropdownTypeValue(0);
                          setDropdownSizeOrAxleConfigValue(0);
                        }}
                        IconComponent={ExpandMoreStyledIcon}
                      >
                        <option value={0} disabled>
                          {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                        </option>
                        {dropdownManufacturer?.map((manuf) => {
                          return (
                            <option value={manuf.compoundId} key={manuf.compoundId}>
                              {manuf.compoundName}
                            </option>
                          );
                        })}
                      </Select>
                      <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.Comments}:
                        </span>
                      </InputLabel>
                      <TextField
                        className="add-new-delete-box enter-detail-input-box"
                        variant="outlined"
                        value={textArea}
                        onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                        helperText=""
                        placeholder={globalizationMapping.PLACEHOLDER.Comments}
                        inputProps={{ maxLength: 200 }}
                      />
                    </Grid>
                  )}
                  {(Number(fieldId) === 2 ||
                    Number(fieldId) === 7 ||
                    Number(fieldId) === 8 ||
                    Number(fieldId) === 13 ||
                    Number(fieldId) === 14 ||
                    Number(fieldId) === 15) && (
                      <Grid item sm={12} className="grid-add-delete-container">
                        {(Number(fieldId) === 2 ||
                          Number(fieldId) === 7 ||
                          Number(fieldId) === 8 ||
                          Number(fieldId) === 14) && (
                            <>
                              <InputLabel className="filter-table-label inside-fields">
                                <span className="table-toolbar-label-text">
                                  {globalizationMapping.ADMIN.SelectManufacturer}
                                  <span className="mandatory-sign-add-new">*</span>
                                </span>
                              </InputLabel>
                              <Select
                                native
                                className="add-new-delete-box"
                                value={dropdownManufacturerValue}
                                onChange={(event) => {
                                  setDropdownManufacturerValue(parseInt(event.target.value, 10));
                                  setDropdownTypeValue(0);
                                  setDropdownSizeOrAxleConfigValue(0);
                                }}
                                IconComponent={ExpandMoreStyledIcon}
                              >
                                <option value={0} disabled>
                                  {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                                </option>
                                {dropdownManufacturer?.map((manuf) => {
                                  return (
                                    <option value={manuf.manufacturerId} key={manuf.manufacturerId}>
                                      {manuf.manufacturerName}
                                    </option>
                                  );
                                })}
                              </Select>
                            </>
                          )}
                        {(Number(fieldId) === 7 || Number(fieldId) === 8) && (
                          <>
                            <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.SelectSize}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            </InputLabel>
                            <Select
                              native
                              className="add-new-delete-box"
                              value={dropdownSizeOrAxleConfigValue}
                              onChange={(event) => {
                                setDropdownSizeOrAxleConfigValue(parseInt(event.target.value, 10));
                                setDropdownTypeValue(0);
                              }}
                              IconComponent={ExpandMoreStyledIcon}
                              disabled={dropdownManufacturerValue === 0}
                            >
                              <option value={0} disabled>
                                {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                              </option>
                              {dropdownSizeOrAxleConfig?.map((type) => {
                                return (
                                  parseInt(dropdownManufacturerValue, 10) ===
                                  type.manufacturerId && (
                                    <option value={type.sizeId}>
                                      {type.sizeName}
                                    </option>
                                  )
                                );
                              })}
                            </Select>
                          </>
                        )}
                        {Number(fieldId) === 13 && (
                          <>
                            <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.SelectSize}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            </InputLabel>
                            <Select
                              native
                              className="add-new-delete-box"
                              value={dropdownSizeOrAxleConfigValue}
                              onChange={(event) =>
                                setDropdownSizeOrAxleConfigValue(parseInt(event.target.value, 10))
                              }
                              IconComponent={ExpandMoreStyledIcon}
                            >
                              <option value={0} disabled>
                                {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                              </option>
                              {dropdownSizeOrAxleConfig?.map((type) => {
                                return (
                                  <option value={type.sizeId} key={type.sizeId}>
                                    {type.sizeName}
                                  </option>
                                );
                              })}
                            </Select>
                          </>
                        )}
                        {Number(fieldId) === 15 && (
                          <>
                            <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                              <span className="table-toolbar-label-text">
                                {globalizationMapping.ADMIN.SelectValve}
                                <span className="mandatory-sign-add-new">*</span>
                              </span>
                            </InputLabel>
                            <Select
                              native
                              className="add-new-delete-box"
                              value={dropdownSizeOrAxleConfigValue}
                              onChange={(event) =>
                                setDropdownSizeOrAxleConfigValue(parseInt(event.target.value, 10))
                              }
                              IconComponent={ExpandMoreStyledIcon}
                            >
                              <option value={0} disabled>
                                {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                              </option>
                              {dropdownManufacturer?.map((type) => {
                                return (
                                  <option value={type.valveId} key={type.valveId}>
                                    {type.valveName}
                                  </option>
                                );
                              })}
                            </Select>
                          </>
                        )}
                        {(Number(fieldId) === 2 ||
                          Number(fieldId) === 8 ||
                          Number(fieldId) === 14) && (
                            <>
                              <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                                <span className="table-toolbar-label-text">
                                  {globalizationMapping.ADMIN.SelectType}
                                  <span className="mandatory-sign-add-new">*</span>
                                </span>
                              </InputLabel>
                              {Number(fieldId) === 2 && (
                                <Select
                                  native
                                  className="add-new-delete-box"
                                  value={dropdownTypeValue}
                                  onChange={(event) =>
                                    setDropdownTypeValue(parseInt(event.target.value, 10))
                                  }
                                  IconComponent={ExpandMoreStyledIcon}
                                  disabled={dropdownManufacturerValue === 0}
                                >
                                  <option value={0} disabled>
                                    {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                                  </option>
                                  {dropdownType?.map((type) => {
                                    return (
                                      parseInt(dropdownManufacturerValue, 10) ===
                                      type.manufacturerId && (
                                        <option value={type.typeId} key={type.typeId}>
                                          {type.typeName}
                                        </option>
                                      )
                                    );
                                  })}
                                </Select>
                              )}
                              {(Number(fieldId) === 8) && (
                                <Select
                                  native
                                  className="add-new-delete-box"
                                  value={dropdownTypeValue}
                                  onChange={(event) =>
                                    setDropdownTypeValue(parseInt(event.target.value, 10))
                                  }
                                  IconComponent={ExpandMoreStyledIcon}
                                  disabled={dropdownSizeOrAxleConfigValue === 0}
                                >
                                  <option value={0} disabled>
                                    {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                                  </option>
                                  {dropdownType?.map((type) => {
                                    return (
                                      parseInt(dropdownManufacturerValue, 10) ===
                                      type.manufacturerId &&
                                      parseInt(dropdownSizeOrAxleConfigValue, 10) ===
                                      type.sizeId && (
                                        <option value={type.typeId}>
                                          {type.typeName}
                                        </option>
                                      )
                                    );
                                  })}
                                </Select>
                              )}
                              {(Number(fieldId) === 14) && (
                                <Select
                                  native
                                  className="add-new-delete-box"
                                  value={dropdownTypeValue}
                                  onChange={(event) =>
                                    setDropdownTypeValue(parseInt(event.target.value, 10))
                                  }
                                  IconComponent={ExpandMoreStyledIcon}
                                  disabled={dropdownManufacturerValue === 0}
                                >
                                  <option value={0} disabled>
                                    {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                                  </option>
                                  {dropdownType?.map((type) => {
                                    return (
                                      parseInt(dropdownManufacturerValue, 10) ===
                                      type.manufacturerId && (
                                        <option value={type.typeId}>
                                          {type.typeName}
                                        </option>
                                      )
                                    );
                                  })}
                                </Select>
                              )}
                            </>
                          )}
                        <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                          <span className="table-toolbar-label-text">
                            {globalizationMapping.ADMIN.Comments}:
                          </span>
                        </InputLabel>
                        <TextField
                          className="add-new-delete-box enter-detail-input-box"
                          variant="outlined"
                          value={textArea}
                          onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                          helperText=""
                          placeholder={globalizationMapping.PLACEHOLDER.Comments}
                          inputProps={{ maxLength: 200 }}
                        />
                      </Grid>
                    )}
                  {Number(fieldId) === 3 && (
                    <Grid item sm={12} className="grid-add-delete-container">
                      <InputLabel className="filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.SelectManufacturer}
                          <span className="mandatory-sign-add-new">*</span>
                        </span>
                      </InputLabel>
                      <Select
                        native
                        className="add-new-delete-box"
                        value={dropdownManufacturerValue}
                        onChange={(event) => {
                          setDropdownManufacturerValue(parseInt(event.target.value, 10));
                          setDropdownTypeValue(0);
                          setDropdownSizeOrAxleConfigValue(0);
                        }}
                        IconComponent={ExpandMoreStyledIcon}
                      >
                        <option value={0} disabled>
                          {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                        </option>
                        {dropdownManufacturer?.map((manuf) => {
                          return (
                            <option value={manuf.manufacturerId} key={manuf.manufacturerId}>
                              {manuf.manufacturerName}
                            </option>
                          );
                        })}
                      </Select>
                      <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.SelectType}
                          <span className="mandatory-sign-add-new">*</span>
                        </span>
                      </InputLabel>
                      <Select
                        native
                        className="add-new-delete-box"
                        value={dropdownTypeValue}
                        onChange={(event) => {
                          setDropdownTypeValue(parseInt(event.target.value, 10));
                          setDropdownSizeOrAxleConfigValue(0);
                        }}
                        IconComponent={ExpandMoreStyledIcon}
                        disabled={dropdownManufacturerValue === 0}
                      >
                        <option value={0} disabled>
                          {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                        </option>
                        {dropdownType?.map((type) => {
                          return (
                            parseInt(dropdownManufacturerValue, 10) ===
                            type.manufacturerId && (
                              <option value={type.typeId}>
                                {type.typeName}
                              </option>
                            )
                          );
                        })}
                      </Select>
                      <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.SelectModel}
                          <span className="mandatory-sign-add-new">*</span>
                        </span>
                      </InputLabel>
                      <Select
                        native
                        className="add-new-delete-box"
                        value={dropdownSizeOrAxleConfigValue}
                        onChange={(event) =>
                          setDropdownSizeOrAxleConfigValue(parseInt(event.target.value, 10))
                        }
                        IconComponent={ExpandMoreStyledIcon}
                        disabled={dropdownTypeValue === 0}
                      >
                        <option value={0} disabled>
                          {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                        </option>
                        {dropdownSizeOrAxleConfig?.map((type) => {
                          return (
                            parseInt(dropdownManufacturerValue, 10) ===
                            type.vehicleManufacturerId &&
                            parseInt(dropdownTypeValue, 10) === type.vehicleTypeId && (
                              <option value={type.modelId}>
                                {type.modelName}
                              </option>
                            )
                          );
                        })}
                      </Select>
                      <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.Comments}:
                        </span>
                      </InputLabel>
                      <TextField
                        className="add-new-delete-box enter-detail-input-box"
                        variant="outlined"
                        value={textArea}
                        onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                        helperText=""
                        placeholder={globalizationMapping.PLACEHOLDER.Comments}
                        inputProps={{ maxLength: 200 }}
                      />
                    </Grid>
                  )}
                  {Number(fieldId) === 5 && (
                    <Grid item sm={12} className="grid-add-delete-container">
                      <InputLabel className="filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.SelectAxle}
                          <span className="mandatory-sign-add-new">*</span>
                        </span>
                      </InputLabel>
                      <Select
                        native
                        className="add-new-delete-box"
                        value={dropdownSizeOrAxleConfigValue}
                        onChange={(event) =>
                          setDropdownSizeOrAxleConfigValue(parseInt(event.target.value, 10))
                        }
                        IconComponent={ExpandMoreStyledIcon}
                      >
                        <option value={0} disabled>
                          {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                        </option>
                        {dropdownSizeOrAxleConfig?.map((manuf) => {
                          return (
                            <option value={manuf.configurationId} key={manuf.configurationId}>
                              {manuf.configurationText}
                            </option>
                          );
                        })}
                      </Select>
                      <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.Comments}:
                        </span>
                      </InputLabel>
                      <TextField
                        className="add-new-delete-box enter-detail-input-box"
                        variant="outlined"
                        value={textArea}
                        onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                        helperText=""
                        placeholder={globalizationMapping.PLACEHOLDER.Comments}
                        inputProps={{ maxLength: 200 }}
                      />
                    </Grid>
                  )}
                  {Number(fieldId) === 11 && (
                    <Grid item sm={12} className="grid-add-delete-container">
                      <InputLabel className="filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.SelectReason}
                          <span className="mandatory-sign-add-new">*</span>
                        </span>
                      </InputLabel>
                      <Select
                        native
                        className="add-new-delete-box"
                        value={dropdownManufacturerValue}
                        onChange={(event) => {
                          setDropdownManufacturerValue(parseInt(event.target.value, 10));
                          setDropdownTypeValue(0);
                          setDropdownSizeOrAxleConfigValue(0);
                        }}
                        IconComponent={ExpandMoreStyledIcon}
                      >
                        <option value={0} disabled>
                          {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                        </option>
                        {dropdownManufacturer?.map((manuf) => {
                          return (
                            <option value={manuf.reasonId} key={manuf.reasonId}>
                              {manuf.reasonName}
                            </option>
                          );
                        })}
                      </Select>
                      <InputLabel className="grid-add-delete-input-container filter-table-label inside-fields">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.ADMIN.Comments}:
                        </span>
                      </InputLabel>
                      <TextField
                        className="add-new-delete-box enter-detail-input-box"
                        variant="outlined"
                        value={textArea}
                        onChange={(e) => setTextArea(commonUtils.removeLeadingSpaces(e.target.value))}
                        helperText=""
                        placeholder={globalizationMapping.PLACEHOLDER.Comments}
                        inputProps={{ maxLength: 200 }}
                      />
                    </Grid>
                  )}
                  <hr className="hr-divider-action" />

                  <div className="action-buttons">
                    <Button
                      className={styling.cancel}
                      variant="contained"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      {globalizationMapping.ADMIN.Cancel}
                    </Button>
                    <button
                      // className={styling.next}
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteEntity()}
                      disabled={tireTreadDefaultId === 0}
                      className={
                        tireTreadDefaultId === 0
                          ? "header-change-modal-confirm-btn header-change-modal-confirm-btn-disabled"
                          : "header-change-modal-confirm-btn"
                      }
                    >
                      {globalizationMapping.ADMIN.Delete}
                    </button>
                  </div>
                </CardContent>
              )}
            </Card>
          </div>
        </div>
      )}
      {!showSpinner && currentRoute.path === "updateaccountdetails" && (
        <div className="admin-container-height">
          <div className="admin-list-container" >
            {showNotification && (
              <FlashMessage
                status={true}
                message={notification.notifmessage}
                description={notification.notifdesc}
              />
            )}
            <Card className="admin-list-card">
              <CardContent>
                <div className="change-modal-select-container" id="single-select">
                  <InputLabel className="filter-table-label">
                    <span className="table-toolbar-label-text">
                      {globalizationMapping.ADMIN.SelectParent}:
                    </span>
                  </InputLabel>
                  <Autocomplete
                    size="small"
                    id="combo-box-demo"
                    options={parentAccountList}
                    value={parentAccount}
                    getOptionLabel={(option) => option.accountName}
                    fullWidth={true}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" inputProps={{...params.inputProps, maxLength: 50 }}/>
                    )}
                    className={styling.root}
                    onChange={(event, newValue) => {
                      handleAccountChange(event, newValue);
                    }}
                  />
                  <br />
                  <Select
                    native
                    placeholder={globalizationMapping.INSPECT.UPDATE}
                    defaultValue={globalizationMapping.INSPECT.UPDATE}
                    className="admin-updateAccount-details"
                    IconComponent={ExpandMoreStyledIcon}
                    onChange={(event) => handleOnchangeHandle(event)}
                  >
                    <option value="Update" disabled>
                      {globalizationMapping.INSPECT.UPDATE}
                    </option>
                    <option value="VFP">
                      {globalizationMapping.ADMIN.VehicleFootPrint}
                    </option>
                    <option value="VTS">
                      {globalizationMapping.ADMIN.VehicleTireSize}
                    </option>
                    {parentAccount && parentAccount.parentAccountId !== "All" && (
                      <option value="PW">
                        {globalizationMapping.ADMIN.PercentageWorn}
                      </option>
                    )}
                    {parentAccount && parentAccount.parentAccountId !== "All" && (
                      <option value="AT">
                        {globalizationMapping.ADMIN.AverageTread}
                      </option>
                    )}
                  </Select>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      )}

      {!showSpinner && currentRoute.path === "updatebroadcastmsg" && (
        <div className="admin-container-height">
          <div className="admin-list-container" >
            {showNotification && (
              <FlashMessage
                status={true}
                message={notification.notifmessage}
                description={notification.notifdesc}
              />
            )}
            <div className="manage-button-admin-nav-sub-container">
              <div className="admin-nav-sub-container">
                {addNewDeleteBroadCast.map((unique, index) => (
                  <div
                    key={unique}
                    sm={2}
                    lg={2}
                    md={2}
                    xl={2}
                    onClick={() => {
                      addNewDeleteBroadCatClicked(index, unique);
                    }}
                    className={
                      addDeleteBroadcastIndex === index
                        ? "active-admin-nav-sub-card-text"
                        : "admin-nav-sub-card-text"
                    }
                    style={{ marginLeft: 25, marginBottom: 15 }}
                  >
                    {unique}
                  </div>
                ))}
              </div>
            </div>
            <Card className="admin-list-card">
              {addDeleteBroadcastIndex === 0 && (
                <CardContent>
                  <div
                    className="change-modal-select-container"
                    id="single-select"
                  >
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">
                        {globalizationMapping.ADMIN.broadCastMsgTitle}
                        <span className="required-mark">*</span>
                      </span>
                    </InputLabel>
                    <TextField
                      className="add-new-delete-box enter-detail-input-box text-area"
                      variant="outlined"
                      value={textTitle}
                      onChange={handleBroadcastTitle}
                      helperText=""
                      placeholder={globalizationMapping.ADMIN.broadCastMsgTitle}
                      inputProps={{ maxLength: 50 }}
                    />

                    {(textTitle === "" && isTitleEdited) ? <FormHelperText>{validationTexts.MISSINGFIELD}</FormHelperText> : ''}
                    <InputLabel className="filter-table-label padding-css">
                      <span className="table-toolbar-label-text">
                        {globalizationMapping.ADMIN.broadCastDescription}
                        <span className="required-mark">*</span>
                      </span>
                    </InputLabel>
                    <ReactQuill
                      value={textDescription}
                      onChange={handleBroadcastDescription}
                      modules={modules}
                      formats={formats}
                      placeholder={globalizationMapping.ADMIN.broadCastDescription}
                      style={{ height: "300px" }} />
                    {(textDescription === "" && isDescriptionEdited) ? <FormHelperText>{validationTexts.MISSINGFIELD}</FormHelperText> : ''}
                  </div>
                  <div className="action-buttons">
                    <Button
                      className={styling.cancel}
                      variant="contained"
                      color="secondary"
                      onClick={handleBroadCastCancel}
                    >
                      {globalizationMapping.ADMIN.Cancel}
                    </Button>
                    <button
                      // className={styling.next}
                      variant="contained"
                      color="secondary"
                      onClick={() => handleBroadCastMessage()}
                      className="header-change-modal-confirm-btn"
                      disabled={textTitle.trim().length === 0 || textDescription.trim().length === 0}
                    >
                      {globalizationMapping.ADMIN.Add}
                    </button>
                  </div>
                </CardContent>
              )}
              {addDeleteBroadcastIndex === 1 && (
                <div className="container">
                  <Card className="admin-list-card1">
                    <div className={classes.root}>
                      <Paper className={classes.paper}>
                        <TableContainer className={classes.container} style={{ maxHeight: 700 }}>
                          <Table
                            stickyHeader
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="a dense table"
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className="table-head-row"
                                  align="left"
                                >
                                  <TableSortLabel>
                                    {globalizationMapping.ADMIN.Description}
                                    Title
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell
                                  className="table-head-row"
                                  align="left"
                                >
                                  <TableSortLabel>
                                    {globalizationMapping.ADMIN.Description}
                                  </TableSortLabel>
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody >
                              {broadCastData?.map((dataMsg, _index) => (
                                <TableRow key={dataMsg.broadcastMessageId}>
                                  <TableCell className="table-content-text">
                                    {dataMsg.title}
                                  </TableCell>
                                  <TableCell className="table-content-text table-content-rich-text-editor ql-editor">
                                    <div style={{ float: "left" }} dangerouslySetInnerHTML={{ __html: dataMsg.description }} />
                                    <div>
                                      <Button
                                        aria-label="edit"
                                        style={{ float: "right" }}
                                        onClick={() =>
                                          deleteMessage(
                                            dataMsg.broadcastMessageId
                                          )
                                        }
                                      >
                                        <DeleteIcon style={{color:commonUtils.primaryBlue}}/>
                                      </Button>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            {broadCastData.length === 0 && (
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    className="no-match-found"
                                    colSpan={8}
                                    align="center"
                                  >
                                    <div className="no-data-container">
                                      <NoDataFound noDataType="table"></NoDataFound>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                  </Card>
                </div>
              )}
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};
export default AdminList;
