import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';

class ManageRimListApis {
    cs;
    constructor(){
         this.cs = new CommonService(); 
    }
    GetRimsByAccount = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.GetRimsByAccount}${parentAccountId}`,'get',{})
    }
    getLocationListByAccount = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getLocationListByAccount}${parentAccountId}`, "get", {});
      };
    transferVehivle = (vehicleId,locationId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.transferVehicle}${vehicleId}/${locationId}`,"put",null);
    }
    deleteRim = (vehicleId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.deleteRim}${vehicleId}`,'delete',{});
    };
}

export default ManageRimListApis;
