import Globalization from "../../../globalization/index";
import { InspectionOrderConfiguration } from "../../../constants/InspectionOrder";

class FleetDataAnalysisDataSetter {
  constructor() {
    this.data = {};
    this.fleetDataAnalysisData = [];
    this.fleetDataAnalysisVehicleData = [];
    this.headCells = [];
    this.fleetDataAnalysisMappedData = [];
    this.fleetDataMapping = [];
    this.globalizationMapping = new Globalization();
  }

  vehicleIdListSetter(vehicleData) {
    let vehicleIdList = [];
    vehicleIdList.push("All");
    vehicleData.forEach((item) => {
      vehicleIdList.push(item.VehicleId);
    });
    return vehicleIdList;
  }

  filterByVehicleId(vehicleId, vehicleData) {
    if (vehicleId === "All") {
      return vehicleData;
    } else {
      let updatedVehicleData = [];
      vehicleData.forEach((vehicleDataItem) => {
        if (vehicleDataItem.VehicleId === vehicleId) {
          updatedVehicleData.push(vehicleDataItem);
        }
      });
      return updatedVehicleData;
    }
  }

  // multiple selection via ID
  multipleVehicleIdListSetter(vehicleData) {
    let vehicleIdList = [];
    vehicleData.forEach((item) => {
      vehicleIdList.push({
        vehicleId: item.VehicleId,
        label: item.VehicleId,
        value: item.VehicleId
      });
    });
    return vehicleIdList;
  }

  filterByMultipleVehicleId(selectedVehicleIds, vehicleData) {
    if (selectedVehicleIds.length === vehicleData.length) {
      return vehicleData;
    } else {
      let updatedVehicleData = [];
      vehicleData.forEach((vehicleDataItem) => {
        selectedVehicleIds.forEach((vehicleId) => {
          if (vehicleDataItem.VehicleId === vehicleId.value) {
            updatedVehicleData.push(vehicleDataItem);
          }
        });
      });
      return updatedVehicleData;
    }
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ParentAccountName.toLowerCase().includes(searchText) ||
        item.LocationName.toLowerCase().includes(searchText) ||
        item.InspectionName.toLowerCase().includes(searchText) ||
        item.InspectionOrderId.toString().includes(searchText) ||
        item.VehicleId.toLowerCase().includes(searchText) ||
        item.AssetNumber.toLowerCase().includes(searchText) ||
        item.ModelName.toLowerCase().includes(searchText) ||
        item.Manufacturer.toLowerCase().includes(searchText) ||
        item.LastMiles.toString().toLowerCase().includes(searchText) ||
        item.CurrentHours.toString().includes(searchText) ||
        item.CurrentMiles.toString().toLowerCase().includes(searchText) ||
        item.VehicleComments.toLowerCase().includes(searchText)
      );
    });
  }

  // filters rows data based on the vehicle data update
  setRowsDataOnUpdate = (filteredVehicleData, originalRowsDataStore) => {
    let updatedRowsData = [];
    filteredVehicleData.forEach((vehicleDataItem) => {
      originalRowsDataStore.forEach((rowsDataItem, indexKey) => {
        if (vehicleDataItem.Key === indexKey) {
          updatedRowsData.push(rowsDataItem);
        }
      });
    });
    return updatedRowsData;
  };
  formatData(registeredDate) {
    let date, formattedDate;
    if (registeredDate) {
      let dateString = registeredDate.split("T");
      date = dateString[0];
      // date = dateString[2] + '/' + dateString[0] +  '/' + dateString[1]
      formattedDate = date;
    } else {
      date = null;
      formattedDate = date;
    }

    return formattedDate;
  }

  dateReturn(date) {
    let dateString = date.split("T");
    let updatedDate = new Date(dateString[0]);
    let dd = updatedDate.getDate();
    let mm = updatedDate.getMonth() + 1;
    let dd1 = ("0" + dd).slice(-2);
    let mm1 = ("0" + mm).slice(-2);

    let yy = updatedDate.getFullYear();
    if (sessionStorage.userLanguage === "en-uk") {
      let newDate = dd1 + "-" + mm1 + "-" + yy;
      return newDate;
    } else {
      let newDate = mm1 + "-" + dd1 + "-" + yy;
      return newDate;
    }
  }
  setData(data) {
    // TABLE ROWS DATA
    let reportData = data.model.reportData;

    reportData = reportData.filter((elem) => {
      if (elem.fleetList.length !== 0) {
        return elem;
      } else {
        return;
      }
    });

    reportData.forEach((elem) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      let tempData = [];
      elem.fleetList.forEach((item, index) => {
        tempData.push({
          Key: index,
          WheelPosition: item.wheelPosition ? item.wheelPosition : "",
          ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
          TypeName: item.typeName ? item.typeName : "",
          SerialNumber: item.serialNumber ? item.serialNumber : "",
          BrandNumber: item.brandNumber ? item.brandNumber : "",
          RimSerialNumber: item.rimSerialNumber ? item.rimSerialNumber : "",
          SizeName: item.sizeName ? item.sizeName : "",
          CompoundName: item.compoundName ? item.compoundName : "",
          RatingName: item.ratingName ? item.ratingName : "",
          InstalledDate: item.installedDate ? item.installedDate : "",
          TotalHours: item.totalHours ? item.totalHours : 0,
          TotalKm: item.totalKm ? item.totalKm : 0,
          TreadDepth: item.treadDepth ? item.treadDepth : "",
          PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
          Pressure: item.pressure ? item.pressure : "",
          InspectDate: item.inspectDate ? this.dateReturn(item.inspectDate) : "",
          InspectionComments: item.inspectionComments ? item.inspectionComments : ""
        });
      });
      this.fleetDataAnalysisData.push(tempData);
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    reportData.forEach((elem, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.fleetDataAnalysisVehicleData.push({
        Key: index,
        ParentAccountName: elem.vehicleData.parentAccountName ? elem.vehicleData.parentAccountName : "",
        LocationName: elem.vehicleData.locationName ? elem.vehicleData.locationName : "",
        InspectionName: elem.vehicleData.updatedBy ? elem.vehicleData.updatedBy : "",
        InspectionOrderId: elem.vehicleData.inspectionOrderId ? elem.vehicleData.inspectionOrderId : "",
        VehicleId: elem.vehicleData.vehicleNumber ? elem.vehicleData.vehicleNumber : "",
        AssetNumber: elem.vehicleData.assetNumber ? elem.vehicleData.assetNumber : "",
        ModelName: elem.vehicleData.modelName ? elem.vehicleData.modelName : "",
        Manufacturer: elem.vehicleData.manufacturer ? elem.vehicleData.manufacturer : "",
        CurrentHours: elem.vehicleData.currentHours ? elem.vehicleData.currentHours : "",
        LastMiles: "",
        CurrentMiles: elem.vehicleData.currentMiles ? elem.vehicleData.currentMiles : "",
        RecommendedPressure: elem.vehicleData.recommendedPressure ? elem.vehicleData.recommendedPressure : "",
        UpdatedDate: elem.vehicleData.updatedDate ? this.dateReturn(elem.vehicleData.updatedDate) : "",
        VehicleComments: elem.vehicleData.severityComments ? elem.vehicleData.severityComments : ""
      });
    });

    let TableData = reportData;

    let fleetSummaryList = TableData.filter((data) => {
      return data.fleetList;
    });
    let fleetListArr = [];
    // maps fleet survey data to the config file
    fleetSummaryList.forEach((item) => {
      let fleetListTemp = [];
      let order = item.vehicleData.inspectionOrderId;
      let axleConfig = item.vehicleData.axleConfig;
      let tireOrder = InspectionOrderConfiguration.TirePositionConfig[order][axleConfig];
 
      if (tireOrder !== undefined) {
        tireOrder.forEach((pos) => {
          item.fleetList.forEach((tire) => {
            if (pos === tire.wheelPosition) {
              fleetListTemp.push(tire);
            }
          });
        });
      }
      fleetListArr.push(fleetListTemp);
    });

    fleetListArr.forEach((elem) => {
      let tempFleetData = [];
      elem.forEach((item, index) => {
        tempFleetData.push({
          Key: index,
          WheelPosition: item.wheelPosition ? item.wheelPosition : "",
          ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
          TypeName: item.typeName ? item.typeName : "",
          SerialNumber: item.serialNumber ? item.serialNumber : "",
          BrandNumber: item.brandNumber ? item.brandNumber : "",
          RimSerialNumber: item.rimSerialNumber ? item.rimSerialNumber : "",
          SizeName: item.sizeName ? item.sizeName : "",
          CompoundName: item.compoundName ? item.compoundName : "",
          RatingName: item.ratingName ? item.ratingName : "",
          InstalledDate: item.installedDate ? item.installedDate : "",
          TotalHours: item.totalHours ? item.totalHours : 0,
          TotalKm: item.totalKm ? item.totalKm : 0,
          TreadDepth: item.treadDepth ? item.treadDepth : "",
          PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
          Pressure: item.pressure ? item.pressure : "",
          InspectDate: item.inspectDate ? this.dateReturn(item.inspectDate) : "",
          InspectionComments: item.inspectionComments ? item.inspectionComments : ""
        });
      });
      this.fleetDataMapping.push(tempFleetData);
    });

    // maps filter options to the config file data
    let rowsDataFilterOptions = [];
    this.fleetDataAnalysisData.forEach((fleetListItem, indexKey) => {
      if (fleetListItem.length > 0) {
        rowsDataFilterOptions.push(this.fleetDataAnalysisVehicleData[indexKey]);
      }
    });

    return {
      rowsData: this.fleetDataMapping,
      vehicleData: this.fleetDataAnalysisVehicleData,
      fleetDataAnalysisMappedData: fleetListArr,
      rowsDataFilterOptions: rowsDataFilterOptions
    };
  }
}

export default FleetDataAnalysisDataSetter;
