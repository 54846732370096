import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";

class SearchInstallTireListSetter {
  constructor() {
    this.data = {};
    this.searchInstallTireListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }
  // need to be change
  search(originalTableData, searchText) {
    return originalTableData.filter((item, _index) => {
      return (
        item.TireId.toString().toLowerCase().includes(searchText) ||
        item.SerialNo.toString().toLowerCase().includes(searchText) ||
        item.Brand.toLowerCase().includes(searchText) ||
        item.Manufacturer.toLowerCase().includes(searchText) ||
        item.Type.toString().includes(searchText) ||
        item.Size.toLowerCase().includes(searchText) ||
        item.Hours.toString().includes(searchText) ||
        item.Miles.toString().includes(searchText) ||
        item.MaxTread.toString().includes(searchText) ||
        item.PercentageWorn.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, _index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data, locationId) {
    // TABLE ROWS DATA
    const modelList = data.model;
    modelList.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      // vehicleList.forEach((vehicle,{} vehicleIndex) => {
      if ((item.locationId.toString().toLowerCase().trim() === locationId.toString().toLowerCase().trim() || locationId === "") && parseInt(item.dispositionId) === 8) {
        this.searchInstallTireListData.push({
          Key: index,
          SerialNo: item.tireSerialNo ? item.tireSerialNo : 0,
          Brand: item.brandNo ? item.brandNo : "",
          Manufacturer: item.manufacturerName ? item.manufacturerName : "",
          Type: item.typeName ? item.typeName : "",
          Size: item.sizeName ? item.sizeName : "",
          Hours: item.currentHours ? item.currentHours : "",
          Miles: item.currentMiles ? item.currentMiles : 0,
          MaxTread: item.currentTreadDepth ? item.currentTreadDepth : 0,
          PercentageWorn: item.percentageWorn ? item.percentageWorn : "",
          TireId: item.tireId ? item.tireId : "",
          Pressure: item.currentPressure ? item.currentPressure : "",
          MountedRimId: item.mountedRimId ? item.mountedRimId : "",
        });
      }
      // });
    });
    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.SearchInstallTire;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.searchInstallTireListData,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.searchInstallTireListData),
      selectedColShow: selectedColShow
    };
  }
}

export default SearchInstallTireListSetter;
