import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "../../../services/CustomBarGraphStyle.js";
import { Grid } from "@material-ui/core";
import CommonUtils from "../../../../../services/utils";
import "./custom-graph.css";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../globalization/index";


const TireBelowInflationGraph = ({
  byTireInflationGraphData,
  locationsData,
  setLocationsData,
  graphLocationIdSetter,
}) => {
  const [chartInfoData, setChartInfoData] = useState({});
  const commonUtils = new CommonUtils();

  const borderColor = ["#00AF32", "#f0de00", "#EDBD00", "#f46336", "#ff0100",];
  const options = commonUtils.barOptions;
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["Inflation-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["Inflation-y"];
  options.title.text = globalizationHeaders["Inflation-title"];
  options.title.fontSize = 16;
  options.title.fontStyle = "bold";
  options.title.fontColor = "#000000";
  options.scales.xAxes[0].barPercentage = 0.6;
  options.scales.xAxes[0].stacked = true;


  const chart = () => {
    if (
      byTireInflationGraphData !== undefined &&
      byTireInflationGraphData.chartlabels.length > 0
    ) {
      let tireInflationGraphData = byTireInflationGraphData.chartlabels;

      const colorCodes = ["#00AF32", "#f0de00", "#EDBD00", "#f46336", "#ff0100",];
      const dataset = [];
      for (let i = 0; i < tireInflationGraphData.length; i++) {
        const dataGroup = [];
        dataGroup.length = tireInflationGraphData.length;
        dataGroup.splice(i, 0, byTireInflationGraphData.chartData[0].data[i]);

        dataset.push({
          label: tireInflationGraphData[i],
          backgroundColor: colorCodes[i],
          fill: true,
          hoverBorderColor: borderColor[i],
          hoverBorderWidth: "3",
          data: dataGroup,
        });
      }
      setChartInfoData({
        labels: tireInflationGraphData,
        datasets: dataset,
      });
    } else {
      setChartInfoData({});
    }
  };

  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byTireInflationGraphData]);

  const locationsHandler = (selectedLocationIndex) => {
    const locationsDataObject = locationsData;
    locationsDataObject.forEach((item, index) => {
      if (index === selectedLocationIndex) {
        item.selected = !item.selected;
      }
    });
    setLocationsData(locationsDataObject);
    graphLocationIdSetter(locationsDataObject);
  };

  return (
    <div className="percentage-worn-graph-container">
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
      {Object.keys(chartInfoData).length !== 0 && (
        <div>
          <Grid item={true} md={12} sm={12}>
            {Object.keys(chartInfoData).length > 0 && (
              <Bar data={chartInfoData} height={295} options={options} />
            )}
          </Grid>
          <span>{`${globalizationMapping.HEADER.LOCATION}: `}</span>
          {locationsData &&
            locationsData.map((item, index) => {
              return (
                <span
                  key={item.label}
                  className={
                    item.selected ? "locations-text" : "locations-text-strike"
                  }
                  onClick={() => locationsHandler(index)}
                >
                  {item.label}
                </span>
              );
            })}
          <p>*Note: {globalizationMapping.HEADER.NOTEMESSAGE}</p>
        </div>
      )}
    </div>
  );
};

export default TireBelowInflationGraph;
