import React, { useEffect, useState, useContext } from "react";
import "./ScrapSummary.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import ScrapTiresNavigation from "../../../scrap-tires-navigation/components/ScrapTiresNavigation";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import ScrapTiresSummaryDataSetter from "../../../../../services/ScrapSummaryUtils";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import ScrapSummaryTable from "../table/ScrapSummaryTable";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import ScrapSummaryGraph from "../graph/ScrapSummaryGraph";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ScrapSummaryFilter from "../filter/scrapSummaryFilter";


const ScrapSummary = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const scrapTiresSummaryDataSetter = new ScrapTiresSummaryDataSetter();
  const commonUtils = new CommonUtils();
  const [scrapSummaryTableData, setScrapSummaryTableData] = useState();
  const [originalScrapSummaryTableData, setOriginalScrapSummaryTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(scrapSummaryTableData);
  const [noData, setNoData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [tireScrapType, setTireScrapType] = useState("AllTire");
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [inServiceDateRange, setInServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [outServiceDateRange, setOutServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const globalizationMapping = new Globalization();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [toggleHM, setToggleHM] = useState("Hours");
  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "VehicleTypeName", value: "VehicleTypeName" },
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      VehicleTypeName: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const ScrapSummaryResponseData = (tireScrapTypeValue, excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    let inServiceDateRangeType = "AllTime";
    let outServiceDateRangeType = "AllTime";

    if (inServiceDateRange.fromDate !== "" && inServiceDateRange.toDate !== "") inServiceDateRangeType = "DateRange";

    if (outServiceDateRange.fromDate !== "" && outServiceDateRange.toDate !== "") outServiceDateRangeType = "DateRange";

    let requestObject = {
      reportOption: "",
      reportName: "Scrap_Tire_Summary",
      dateRangeType: dateRangeType,
      InServiceDateRangeType: inServiceDateRangeType,
      OutServiceDateRangeType: outServiceDateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      OutServiceFromDate: outServiceDateRange.fromDate,
      OutServiceToDate: outServiceDateRange.toDate,
      InServiceFromDate: inServiceDateRange.fromDate,
      InServiceToDate: inServiceDateRange.toDate,
      accountIds: accountIds,
      locationIds: locationIds,
      TireScrapType: tireScrapTypeValue,
      usedTires: excludeUsedTiresValue.toString()
    };
    setShowSpinner(true);

    tiresService.getTiresReport(requestObject).then((data) => {
      let scrapSummaryTableInfo = scrapTiresSummaryDataSetter.setData(data);
      let filterOptions = {
        categories: [
          { name: globalizationMapping.REPORTTABLE.MANUFACTURER, value: "ManufacturerName"},
          { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
          { name: globalizationMapping.REPORTTABLE.VEHICLETYPENAME, value: "VehicleTypeName"}
        ],
        categoryData: {
          ManufacturerName: [],
          SizeName: [],
          VehicleTypeName: [],
          TypeName: [],
          outServiceDateToDate: '',
          outServiceDateFromDate: '',
          inServiceDateFromDate: '',
          inServiceDateToDate: '',
          dateRangeFromDate: '',
          dateRangeToDate: '',
        }
      };

      let filterOptionsInfo = scrapTiresSummaryDataSetter.setFilterOptionsData(filterOptions, scrapSummaryTableInfo.rowsData);
      setFilterOptions(filterOptionsInfo);
      setScrapSummaryTableData(scrapSummaryTableInfo);
      setTableBeforeFilter(scrapSummaryTableInfo);
      setOriginalScrapSummaryTableData(scrapSummaryTableInfo);
      scrapSummaryTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      let updatedHeadCells = scrapSummaryTableInfo.headCells.filter(
        (item) => item.value !== "AverageMiles" && item.value !== "AverageWearRateByMiles" && item.value !== "CostPerMile" && item.value !== "TotalAverageMiles"
      );
      setDisplayColOptions(updatedHeadCells);
      setDisplayColumns(updatedHeadCells);
      setShowColumns(scrapSummaryTableInfo.selectedColShow);
      setPrintData({
        reportType: "Scrap Tires : ",
        reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapSummary,
        rowsData: scrapSummaryTableInfo.rowsData,
        headCells: scrapSummaryTableInfo.headCells,
        avgValues: scrapSummaryTableInfo.avgValues,
        selectedColShow: scrapSummaryTableInfo.selectedColShow,
        filterApplied: filterAppliedValue
      });
      setShowSpinner(false);
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = scrapSummaryTableInfo.rowsData;
        let filteredData = scrapTiresSummaryDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setScrapSummaryTableData({
          ...scrapSummaryTableInfo,
          rowsData: filteredData,
          avgValues: scrapTiresSummaryDataSetter.avgValues(filteredData)
        });
        setOriginalScrapSummaryTableData({
          ...scrapSummaryTableInfo,
          rowsData: filteredData,
          avgValues: scrapTiresSummaryDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: scrapTiresSummaryDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        (((Object.values(inServiceDateRange).every(x => x === "")) && (Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(outServiceDateRange).every(x => x === ""))) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
    });
  };

  useEffect(() => {
    ScrapSummaryResponseData(tireScrapType, excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);



  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };
  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };
  const onClickFilter = () => {
    setShowFilter(true);
  };
  const actionChanged = (action) => {
    if (action === "Miles") {
      handleMilesClick();
      setToggleHM(action);
    } else {
      handleHoursClick();
      setToggleHM(action);
    }
  };
  const handleMilesClick = () => {
    let arrOfCol = ["AverageHours", "AverageWearRate", "CostPerHour", "TotalAverageHours"];
    const displayColSection = originalScrapSummaryTableData?.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      return flag;
    });
    setDisplayColumns(displayColSection);
    setDisplayColOptions(displayColSection);

    let currentHeadCells = originalScrapSummaryTableData?.headCells;
    let showColumnsUpdated = scrapTiresSummaryDataSetter.setDisplayColumnData(currentHeadCells, displayColSection);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };
  const handleHoursClick = () => {

    let arrOfCol = ["AverageMiles", "AverageWearRateByMiles", "CostPerMile", "TotalAverageMiles"];
    const displayColSection = originalScrapSummaryTableData?.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      return flag;
    });

    setDisplayColumns(displayColSection);
    setDisplayColOptions(displayColSection);

    let currentHeadCells = originalScrapSummaryTableData?.headCells;
    let showColumnsUpdated = scrapTiresSummaryDataSetter.setDisplayColumnData(currentHeadCells, displayColSection);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };
  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    // searchText === "" ? setFilterApplied(false) : setFilterApplied(true)
    let tableDataStore, filteredData;
    tableDataStore = originalScrapSummaryTableData?.rowsData;
    filteredData = scrapTiresSummaryDataSetter.search(tableDataStore, searchText);

    setScrapSummaryTableData({
      ...scrapSummaryTableData,
      rowsData: filteredData,
      avgValues: scrapTiresSummaryDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      avgValues: scrapTiresSummaryDataSetter.avgValues(filteredData),
      rowsData: filteredData,
      headCells: originalScrapSummaryTableData?.headCells,
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalScrapSummaryTableData?.headCells;
    let showColumnsUpdated = scrapTiresSummaryDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {

    let toSetCurrentFilter = 
      { ...appliedFilter,
        outServiceDateToDate: outServiceDateRange.toDate,
        outServiceDateFromDate:  outServiceDateRange.fromDate,
        inServiceDateFromDate: inServiceDateRange.fromDate,
        inServiceDateToDate: inServiceDateRange.toDate,
        dateRangeFromDate: dateRangeFilter.fromDate,
        dateRangeToDate: dateRangeFilter.toDate,
     };

    setCurrentFilter(toSetCurrentFilter);
    setSearchValue("");

    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    ScrapSummaryResponseData(tireScrapType, excludeUsedTires, true, appliedFilter);

    let originalTableDataStore = originalScrapSummaryTableData?.rowsData;
    let filteredData = scrapTiresSummaryDataSetter.filterTable(appliedFilter, originalTableDataStore);

    setScrapSummaryTableData({
      ...scrapSummaryTableData,
      rowsData: filteredData,
      avgValues: scrapTiresSummaryDataSetter.avgValues(filteredData)
    });
    setPrintData({ ...printData, rowsData: filteredData });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter({
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      VehicleTypeName: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      dateRangeFromDate: '',
      dateRangeToDate: '',
    })
    setFilterApplied(false);
    setScrapSummaryTableData(tableBeforeFilter);
    setOriginalScrapSummaryTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
  };


  const handleUpdateTireScrapType = (event) => {
    setTireScrapType(event.target.value);
    ScrapSummaryResponseData(event.target.value, excludeUsedTires, filterApplied, filterFieldsApplied);
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    setSearchValue("");
    ScrapSummaryResponseData(tireScrapType, event.target.checked, filterApplied, filterFieldsApplied);
  };

  return (
    <div className="container">
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="scrap-summary-container">
          <Card className="scrap-summary-card">
            <CardContent className="graph-card">
              <ScrapSummaryGraph scrapSummaryGraphData={scrapSummaryTableData?.rowsData} label={toggleHM} />
            </CardContent>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text"> {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native 
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down" 
                      name="tire scrap type" 
                      variant="outlined" 
                      defaultValue={"Hours"} 
                      onChange={(e) => actionChanged(e.target.value)}>
                      <option value="Hours">{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                      <option value="Miles">{toggleMile}</option>
                    </Select>
                  </div>
                </div>

                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div className="tire-scrap-type">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.GRAPH.TireScrapType}</span>
                    </InputLabel>
                    <Select
                      native 
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down" 
                      value={tireScrapType} 
                      name="tire scrap type" 
                      variant="outlined" 
                      onChange={handleUpdateTireScrapType}>
                      <option value="AllTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption1} </option>
                      <option value="Repair">{globalizationMapping.REPORTCOMMONWORDS.TypeOption2} </option>
                      <option value="Retread">{globalizationMapping.REPORTCOMMONWORDS.TypeOption3} </option>
                      <option value="NewTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption4} </option>
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="data-summary-table">
                <ScrapSummaryTable tableData={scrapSummaryTableData} showColumns={showColumns} filterApplied={filterApplied} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />

      <ScrapSummaryFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        inServiceDateRange={inServiceDateRange}
        setInServiceDateRange={setInServiceDateRange}
        outServiceDateRange={outServiceDateRange}
        setOutServiceDateRange={setOutServiceDateRange}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default ScrapSummary;
