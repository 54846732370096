import { getAccountData } from "./AccountInfoHandler";

export const initialAccountState = getAccountData();

export const AccountInfoUpdate = (state, action) => {
  if (action.type === "UPDATE") {
    return action.accountInfo;
  } else {
    return state;
  }
};
