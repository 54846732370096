import React, { useState, useEffect } from "react";
import "./ViewVehicleNavigation.css";
import Breadcrumbs from "../../../../common-components/breadcrumbs/breadcrumbs";
import { List, ListItem } from "@material-ui/core";
import Globalization from "../../../../globalization/index";
import { ViewVehiclePaths } from "../../../../routing/ViewVehiclePaths";
const ViewVehicleNavigation = ({ changedRoute }) => {
  const globalizationMapping = new Globalization();
  const viewVehicleGlobalTexts = globalizationMapping.VIEWVEHICLEREPORTS;
  const viewVehicleNav = [viewVehicleGlobalTexts.IDENTIFICATION, viewVehicleGlobalTexts.DESCRIPTION, viewVehicleGlobalTexts.OPERATIONSPECIFICATION];
  const viewVehiclePathNav = ["identification", "description", "operationSpecification"];

  const getRouteName = (currentPathName) => {
    let pathSelected = ViewVehiclePaths.find((route) => route.path === currentPathName);
    return pathSelected;
  };

  const getRoutePath = (manageTireSelected, path) => {
    let pathSelected = ViewVehiclePaths.find((route) => route.path === path);
    return pathSelected;
  };

  let currentRouteSelected = getRouteName("identification");
  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line no-unused-vars
  const globalHeaderTexts = globalizationMapping.HEADER;

  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME
    },
    {
      path: "",
      name: globalHeaderTexts.VIEWVEHICLE
    }
  ];

  const viewVehicleNavigation = (manageTireSelected, path) => {
    let routePath = getRoutePath(manageTireSelected, path);
    setSelectedRoute(routePath);
    changedRoute(routePath);
  };

  useEffect(() => {
    // history.push(selectedRoute.path)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  return (
    <div>
      <div className="manage-tires-main-container">
        <div className="view-vehicle-upper-deck">
          <div className="background-img-container-view-vehicle">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">{globalHeaderTexts.VIEWVEHICLE}</div>
                </div>
              </div>
              <div className="manage-button-manage-vehicle-veiw-nav-container">
                <div className="manage-tires-nav-container">
                  {viewVehicleNav.map((item, index) => {
                    return (
                      <div key={item} className="manage-tires-nav-card-container" onClick={() => viewVehicleNavigation(item, viewVehiclePathNav[index])}>
                        <List className={selectedRoute.name === item ? "active-manage-tires-nav-card" : "manage-tires-nav-card"}>
                          <ListItem button={selectedRoute.name === item} autoFocus={selectedRoute.name === item}>
                            <span className={selectedRoute.name === item ? "active-manage-tires-nav-card-text" : "manage-tires-nav-card-text"}>{item}</span>
                          </ListItem>
                        </List>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewVehicleNavigation;
