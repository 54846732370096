import React from "react";
import { Doughnut } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization";
const ByWearConditionGraph = ({ byWearConditionGraphData }) => {
  const globalizationMapping = new Globalization();

  let graphDataSum = {},
    graphDataAvg = {},
    graphDataCount = {};

  // setting the graph data sum and average objects
  byWearConditionGraphData.forEach((item) => {
    graphDataSum[item.WearCondition] = 0;
    graphDataAvg[item.WearCondition] = 0;
    graphDataCount[item.WearCondition] = 0;
  });

  // setting the graph data labels
  let graphDataLabels = Object.keys(graphDataCount);

  // calculates the sum of the graph data
  byWearConditionGraphData.forEach((item) => {
    graphDataSum[item.WearCondition] += item.TireCount;
    graphDataCount[item.WearCondition]++;
  });

  // setting the graph data values
  let graphDataValues = Object.values(graphDataSum);

  let totalValue = 0;
  graphDataValues.forEach((value) => {
    totalValue += value;
  });

  graphDataValues = graphDataValues.map((value) => {
    return ((value / totalValue) * 100).toFixed(1);
  });

  const commonUtils = new CommonUtils();
  const graphData = {
    labels: graphDataLabels,
    datasets: [
      {
        backgroundColor: commonUtils.colorArr,
        hoverBackgroundColor: commonUtils.borderColor,
        data: graphDataValues
      }
    ]
  };

  const options = commonUtils.pieOptions;
  options.title.text = globalizationMapping.REPORTCOMMONWORDS["ByWearCondition"];

  return (
    <div className="wear-condition-graph-container">
      {graphData.labels.length > 0 && (
        <Grid item={true} md={12} sm={12}>
          <Doughnut height={90} data={graphData} options={options}></Doughnut>
        </Grid>
      )}
      {graphData.labels.length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ByWearConditionGraph;
