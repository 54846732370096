import React, { useEffect, useState, useContext } from "react";
import "../../../../../common-styles/TableStyle.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import "./FleetSurveyTable.css";
import VehicleDiagram from "../vehicle-diagram/VehicleDiagram";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../services/utils";
import Globalization from "../../../../../globalization/index";
import { StateContext } from "../../../../../services/StateProvider";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: 60,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
    fontSize: 14,
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const globalizationMapping = new Globalization();
const commonUtils = new CommonUtils();

function removeDateNaN(row) {
  let item = JSON.parse(JSON.stringify(row));
  return item && item !== "NaN/NaN/NaN" ? item : "";
}

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {

  let firstVal = a[orderBy];
  let secondVal = b[orderBy];

  // Function to check if a value is a valid date
  const isValidDate = (value) => !isNaN(new Date(value).getTime());

  if (isValidDate(firstVal) && isValidDate(secondVal)) {
    // Compare date objects
    if (firstVal < secondVal) {
      return 1;
    } else if (firstVal > secondVal) {
      return -1;
    }
    return 0;
  }

  if (typeof firstVal === 'string' && typeof secondVal === 'string') {
    firstVal = firstVal.toLowerCase().trim();
    secondVal = secondVal.toLowerCase().trim();
  }
  if (typeof firstVal === 'number' && typeof secondVal === 'number') {
    firstVal = Number(firstVal);
    secondVal = Number(secondVal);
  }

  if (!isValidDate(firstVal) && isValidDate(secondVal)) {
    return 1; // Second value is valid, so it should come before the invalid one.
  }
  if (isValidDate(firstVal) && !isValidDate(secondVal)) {
    return -1; // First value is valid, so it should come before the invalid one.
  }

  // Compare other values (strings and numbers)
  if (firstVal < secondVal) {
    return 1;
  } else if (firstVal > secondVal) {
    return -1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => {
    el.InstalledDate = new Date(commonUtils.tableDateFormatter(el.InstalledDate));
    el.InspectDate = new Date(commonUtils.tableDateFormatter(el.InspectDate));
    return [el, index];
  });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => {
    el[0].InstalledDate = GetFormattedDate(el[0].InstalledDate);
    el[0].InspectDate = GetFormattedDate(el[0].InspectDate);
    return el[0];
  });
}

const GetFormattedDate = (inputDate) => {
  let todayTime = inputDate;
  let month = todayTime.getMonth() + 1;
  let day = todayTime.getDate();
  let year = todayTime.getFullYear();
  let newDate = month + "/" + day + "/" + year;
  newDate = commonUtils.tableDateFormatter(newDate);
  return newDate;
};
const FleetSurveyTable = ({ tableData, showColumns }) => {
  const globalAccountState = useContext(StateContext);
  const [fleetSurveyTableData, setFleetSurveyTableData] = useState(tableData);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  useEffect(() => {
    setFleetSurveyTableData(tableData);
    setPageVal(1);
  }, [tableData, showColumns]);

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const itemsPerPage = 5;
  const [pageVal, setPageVal] = React.useState(1);
  const noOfPages = Math.ceil(
    Object.keys(fleetSurveyTableData.rowsData).length / itemsPerPage
  );
  const handleChange = (event, newPage) => {
    setPageVal(newPage);
  };

  let count = -1;
  const classNameSet = (keyIndex, value) => {
    count = count + 1;
    switch (count) {
      case 0:
        return "fixed-columns first-fleet-col table-head-row";
      case 1:
        return "fixed-columns second-fleet-col table-head-row";
      case 2:
        return "fixed-columns third-fleet-col table-head-row";
      case 3:
        return "fixed-columns fourth-fleet-col table-head-row";
      default:
        return "table-head-row";
    }
  };
  let countBody = -1;
  const classNameSetBody = (c) => {
    countBody = c + 1;
    switch (countBody) {
      case -1:
        return "table-row";
      case 0:
        return "fixed-column-body first-fleet-col table-content-fleet-text";
      case 1:
        return "fixed-column-body second-fleet-col table-content-fleet-text";
      case 2:
        return "fixed-column-body third-fleet-col table-content-fleet-text";
      case 3:
        return "fixed-column-body fourth-fleet-col table-content-fleet-text";
      default:
        return "table-content-fleet-text";
    }
  };

  const firstRecord = Number((pageVal - 1) * itemsPerPage + 1);
  const lastRecord =
    Number(pageVal * itemsPerPage) <
      Object.keys(fleetSurveyTableData.rowsData).length
      ? Number(pageVal * itemsPerPage)
      : Object.keys(fleetSurveyTableData.rowsData).length;

  const classNamePercentageWorn = (percentageWorn) => {
    if (parseInt(percentageWorn) > 75) {
      return "table-content-fleet-text percent-worn-highest";
    } else if (
      parseInt(percentageWorn) > 50 &&
      parseInt(percentageWorn) <= 75
    ) {
      return "table-content-fleet-text percent-worn-high";
    } else if (
      parseInt(percentageWorn) > 25 &&
      parseInt(percentageWorn) <= 50
    ) {
      return "table-content-fleet-text percent-worn-mid";
    } else if (parseInt(percentageWorn) <= 25) {
      return "table-content-fleet-text percent-worn-low";
    } else {
      return "table-content-fleet-text percent-worn-lowest";
    }
  };

  return (
    <div className="container">
      {fleetSurveyTableData.rowsData !== null &&
        fleetSurveyTableData.rowsData !== undefined &&
        fleetSurveyTableData.rowsData.length > 0 &&
        Object.keys(fleetSurveyTableData.rowsData)
          .slice((pageVal - 1) * itemsPerPage, pageVal * itemsPerPage)
          .map((item) => {
            return (
              <div className={classes.root} key={fleetSurveyTableData.vehicleData[item].Key + 1}>
                {fleetSurveyTableData.rowsData[item].length > 0 && (
                  <React.Fragment>
                    <div
                      className="vehicle-container"
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <Grid container className="grid-vehicle-container">
                        <Grid item xs={6} sm={3}>
                          <div className="heading-column-data">
                            {globalizationMapping.REPORTCOMMONWORDS.RecordNo}
                          </div>
                          <div className="heading-column-data-info">
                            {(fleetSurveyTableData.vehicleData[item].Key + 1) <= 9 ? "0" + (fleetSurveyTableData.vehicleData[item].Key + 1) : (fleetSurveyTableData.vehicleData[item].Key + 1)}
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className="heading-column-data">
                            {globalizationMapping.REPORTTABLE.ACCOUNTNAME}
                          </div>
                          <div
                            className="heading-column-data-info"
                            style={{ fontWeight: 300 }}
                          >
                            {
                              fleetSurveyTableData.vehicleData[item]
                                .ParentAccountName
                            }
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className="heading-column-data">
                            {globalizationMapping.REPORTTABLE.Location}
                          </div>
                          <div className="heading-column-data-info">
                            {fleetSurveyTableData.vehicleData[item].LocationName}
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <div className="heading-column-data">
                            {globalizationMapping.REPORTTABLE.INSPID}
                          </div>
                          <div className="heading-column-data-info">
                            {
                              fleetSurveyTableData.vehicleData[item]
                                .InspectionName
                            }
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        spacing={3}
                        container
                        className="grid-data-container"
                      >
                        <Grid
                          item
                          xs={12}
                          md={
                            fleetSurveyTableData.vehicleData[item].AxleConfigName
                              .length < 5
                              ? 3
                              : 6
                          }
                          className="diagram-container"
                        >
                          <VehicleDiagram
                            data={
                              fleetSurveyTableData.vehicleData[item]
                                .AxleConfigName
                            }
                            tireListData={
                              fleetSurveyTableData?.fleetVehicleMappedData &&
                              fleetSurveyTableData.fleetVehicleMappedData[item]
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          md={
                            fleetSurveyTableData.vehicleData[item].AxleConfigName
                              .length < 5
                              ? 9
                              : 6
                          }
                          className="vehicle-details-container"
                        >
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            className="vehicle-basic-details padding-2em"
                          >
                            <Grid
                              item
                              xs={12}
                              className="vehicle-header-info"
                            >
                              {
                                globalizationMapping.REPORTCOMMONWORDS
                                  .VehicleDetails
                              }{" "}
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">
                                {globalizationMapping.REPORTTABLE.VehicleName}
                              </div>
                              <div>
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .VehicleId
                                }
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">
                                {globalizationMapping.REPORTTABLE.ASSETNUMBER}
                              </div>
                              <div>
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .AssetNumber
                                }
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">
                                {
                                  globalizationMapping.REPORTCOMMONWORDS
                                    .MakeModel
                                }
                              </div>
                              <div>
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .Manufacturer
                                }
                                /
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .ModelName
                                }
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">
                                {globalizationMapping.REPORTTABLE.TYPE}
                              </div>
                              <div>
                                {fleetSurveyTableData.vehicleData[item].TypeName}
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              {globalAccountState[0].accountInfo.userSetting
                                .userMeasurementSystemValue === "imperial" && (
                                  <div className="heading-column-data">
                                    {" "}
                                    {
                                      globalizationMapping["REPORTTABLE"][
                                      "HOURSMILESIMP"
                                      ]
                                    }
                                  </div>
                                )}
                              {globalAccountState[0].accountInfo.userSetting
                                .userMeasurementSystemValue === "metric" && (
                                  <div className="heading-column-data">
                                    {
                                      globalizationMapping["REPORTTABLE"][
                                      "HOURSMILESMET"
                                      ]
                                    }
                                  </div>
                                )}
                              <div>
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .CurrentHours
                                }
                                /
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .CurrentMiles
                                }
                              </div>
                            </Grid>

                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">
                                {" "}
                                {
                                  globalizationMapping["REPORTTABLE"][
                                  "RcmPressure"
                                  ]
                                }
                              </div>

                              <div>
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .RecommendedPressure
                                }
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            className="vehicle-operation-details padding-1em"
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              className="vehicle-header-info"
                            >
                              {
                                globalizationMapping.TIREFORECAST
                                  .VehicleOperationParameters
                              }
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <div className="heading-column-data">
                                {
                                  globalizationMapping.TIREFORECAST
                                    .LoadingRequirements
                                }
                              </div>
                              <div>
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .LoadingRequirement
                                }
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <div className="heading-column-data">
                                {globalizationMapping.TIREFORECAST.SpeedHeat}
                              </div>
                              <div>
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .SpeedName
                                }
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <div className="heading-column-data">
                                {globalizationMapping.TIREFORECAST.Cutting}
                              </div>
                              <div>
                                {
                                  fleetSurveyTableData.vehicleData[item]
                                    .CuttingName
                                }
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <Paper className={classes.paper}>
                      <TableContainer className={classes.container}>
                        <Table
                          stickyHeader
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          aria-label="a dense table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              {fleetSurveyTableData.headCells.map((headCell) =>
                                showColumns[headCell.value] &&
                                  headCell.value !== "InspectionComments" ? (
                                  <TableCell
                                    className={classNameSet(
                                      headCell.keyIndex,
                                      headCell.value
                                    )}
                                    item={headCell.keyIndex}
                                    align="left"
                                    sortDirection={
                                      orderBy === headCell.value ? order : false
                                    }
                                    key={headCell.keyIndex}
                                  >
                                    <TableSortLabel
                                      active={orderBy === headCell.value}
                                      direction={
                                        orderBy === headCell.value
                                          ? order
                                          : "asc"
                                      }
                                      onClick={handleRequestSort(
                                        headCell.value
                                      )}
                                    >
                                      {headCell.label !== "Comments" &&
                                        headCell.label}
                                      {orderBy === headCell.value ? (
                                        <span
                                          className={classes.visuallyHidden}
                                        >
                                          {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                  </TableCell>
                                ) : null
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {stableSort(
                              fleetSurveyTableData.rowsData[item],
                              getComparator(order, orderBy)
                            ).map((dataItem) => {
                              return (
                                <React.Fragment key={dataItem.Key}>
                                  <TableRow
                                    className={classNameSetBody(-2)}
                                    tabIndex={-1}
                                    key={dataItem.Key}
                                  >
                                    {showColumns.WheelPosition && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.WheelPosition}
                                      </TableCell>
                                    )}
                                    {showColumns.ManufacturerName && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.ManufacturerName}
                                      </TableCell>
                                    )}
                                    {showColumns.TypeName && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.TypeName}
                                      </TableCell>
                                    )} 
                                    {showColumns.VehicleNumber && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.VehicleNumber}
                                      </TableCell>
                                    )}
                                     {showColumns.VehicleNumber && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.VehicleNumber}
                                      </TableCell>
                                    )}
                                    {showColumns.SerialNumber && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.SerialNumber}
                                      </TableCell>
                                    )}
                                    {showColumns.BrandNumber && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.BrandNumber}
                                      </TableCell>
                                    )}
                                    {showColumns.RimSerialNumber && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.RimSerialNumber}
                                      </TableCell>
                                    )}
                                    {showColumns.SizeName && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.SizeName}
                                      </TableCell>
                                    )}
                                    {showColumns.CompoundName && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.CompoundName}
                                      </TableCell>
                                    )}
                                    {showColumns.Rating && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.Rating}
                                      </TableCell>
                                    )}
                                    {showColumns.InstalledDate && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {removeDateNaN(dataItem.InstalledDate)}
                                      </TableCell>
                                    )}
                                    {showColumns.TotalHours && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.TotalHours}
                                      </TableCell>
                                    )}
                                    {showColumns.TotalKm && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.TotalKm}
                                      </TableCell>
                                    )}
                                    {showColumns.TreadDepth && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.TreadDepth}
                                      </TableCell>
                                    )}
                                    {showColumns.PercentageWorn && (
                                      <TableCell
                                        className={classNamePercentageWorn(
                                          dataItem.PercentageWorn
                                        )}
                                      >
                                        {dataItem.PercentageWorn}
                                      </TableCell>
                                    )}
                                    {showColumns.Pressure && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {dataItem.Pressure}
                                      </TableCell>
                                    )}
                                    {showColumns.InspectDate && (
                                      <TableCell
                                        className={classNameSetBody(countBody)}
                                      >
                                        {removeDateNaN(dataItem.InspectDate)}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                  {showColumns.InspectionComments && (
                                    <TableRow>
                                      <TableCell className={classNameSet(0)}>
                                        {
                                          globalizationMapping.REPORTTABLE
                                            .COMMENTS
                                        }{" "}
                                      </TableCell>
                                      {showColumns.InspectionComments && (
                                        <TableCell
                                          colSpan={
                                            fleetSurveyTableData.headCells
                                              .length - 2
                                          }
                                          className={classNameSetBody(
                                            countBody
                                          )}
                                        >
                                          {dataItem.InspectionComments}
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </React.Fragment>
                )}
              </div>
            );
          })}
      {fleetSurveyTableData.rowsData.length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="table"></NoDataFound>
        </div>
      )}
      {fleetSurveyTableData.rowsData !== null &&
        fleetSurveyTableData.rowsData !== undefined &&
        fleetSurveyTableData.rowsData.length > 0 && (
          <React.Fragment>
            <div className="record-data">
              Showing {firstRecord} to {lastRecord} of{" "}
              {Object.keys(fleetSurveyTableData.rowsData).length} records
            </div>
            <Pagination
              count={noOfPages}
              page={pageVal}
              onChange={handleChange}
              defaultPage={1}
              shape="rounded"
              //size="small"
              className="pagination-vehicle-report"
            />
          </React.Fragment>
        )}
    </div>
  );
};

export default FleetSurveyTable;
