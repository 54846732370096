import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
// eslint-disable-next-line no-unused-vars


const BySizeGraph = ({ bySizeGraphData, showColumns }) => {
  const commonUtils = new CommonUtils();
  const [chartInfoData, setChartInfoData] = useState({});
  const [chartInfoDataMile, setChartInfoDataMile] = useState({});
  const options = commonUtils.barOptions;
  const optionsMile = JSON.parse(JSON.stringify(commonUtils.barOptions));
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["size-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["size-y"];
  options.title.text = globalizationHeaders["size-title"];
  optionsMile.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["size-x"];
  optionsMile.scales.yAxes[0].scaleLabel.labelString =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["size-y-km"]
      : globalizationHeaders["size-y-miles"];
  optionsMile.title.text =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["size-title-km"]
      : globalizationHeaders["size-title-miles"];


  const chart = () => {
    const colorCodes = commonUtils.getRandomColorHex();

    let size = {};

    size = {
      labels: bySizeGraphData.graphDataHours.labels,
      datasets: [
        {
          backgroundColor: colorCodes,
          fill: true,
          data: bySizeGraphData.graphDataHours.datas,
        },
      ],
    };
    setChartInfoData(size);

    size = {
      labels: bySizeGraphData.graphDataMiles.labels,
      datasets: [
        {
          backgroundColor: colorCodes,
          fill: true,
          data: bySizeGraphData.graphDataMiles.datas,
        },
      ],
    };

    setChartInfoDataMile(size);
  };
  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bySizeGraphData]);

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && showColumns.AverageHours && (
          <Bar data={chartInfoData} height={120} options={options} />
        )}
        {Object.keys(chartInfoDataMile).length > 0 &&
          !showColumns.AverageHours && (
            <Bar data={chartInfoDataMile} height={120} options={optionsMile} />
          )}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default BySizeGraph;
