import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";
class ManageParentAccountListSetter {
  constructor() {
    this.data = {};
    this.manageParentAccountListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item, _index) => {
      return (
        item.AccountName.toLowerCase().includes(searchText) ||
        item.Country.toLowerCase().includes(searchText) ||
        item.State.toLowerCase().includes(searchText) ||
        item.City.toLowerCase().includes(searchText) ||
        item.EmailAddress.toLowerCase().includes(searchText) ||
        item.LocationsCount.toString().includes(searchText) ||
        item.UpdatedDate.toLowerCase().includes(searchText) ||
        item.LastInspectionDate.toLowerCase().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, _index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        if(data.length>0){
          filterModifiedCategoryData[category.value].push({
            category: category.value,
            value: data ,
          });
        }
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data) {
    // TABLE ROWS DATA
    const accountList = data.model;
    /** NOTE : the keys must match the headCells value to be able to SORT */
    accountList.forEach((account, vehicleIndex) => {
      this.manageParentAccountListData.push({
        Key: vehicleIndex,
        AccountName: account.accountName ? "" + account.accountName : "",
        Country: account.country ? "" + account.country : "",
        State: account.state ? "" + account.state : "",
        City: account.city ? "" + account.city : "",
        EmailAddress: account.emailAddress ? "" + account.emailAddress : "",
        LocationsCount: account.locationsCount ? account.locationsCount : "",
        UpdatedDate: account.updatedDate
          ? "" + this.commonUtils.formatISODate(account.updatedDate)
          : "",
        LastInspectionDate: account.latestInspectedDate
          ? "" + this.commonUtils.formatISODate(account.latestInspectedDate)
          : "",
        AccountID: account.parentAccountId ? account.parentAccountId : 0,
      });
    });
    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.ManageParentAccount;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.manageParentAccountListData,
      headCells: this.headCells,
      selectedColShow: selectedColShow,
    };
  }
}

export default ManageParentAccountListSetter;
