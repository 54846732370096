import React, { useEffect, useState } from "react";
import "../../../../../common-styles/TableStyle.css";
import "./Table.css";
import { Button } from "semantic-ui-react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Modal,
  TextField,
  FormHelperText,
  InputLabel,
  Popper,
  Fade
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../globalization/";
import CommonUtils from "../../../../../services/utils";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import infoLogo from "../../../../../assets/Tooltip.svg"

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  popper: {
    padding: 10,
    border: '1px solid #1C72D0',
    borderRadius: '5px',
    background: '#B1D2FF',
    fontSize: '12px',
    marginTop: 10
  },
  table: {
    minWidth: 600
  },
  container: {
    maxHeight: 600
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  stickyHeader: {
    zIndex: "2!important"
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "14px !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important"
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
  }
}));

// USED FOR SORTING
const commonUtils = new CommonUtils();

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (orderBy === "Date") {
    let da = new Date(commonUtils.tableDateFormatter(a[orderBy]));
    let db = new Date(commonUtils.tableDateFormatter(b[orderBy]));
    if (da > db) {
      return -1;
    } else if (da < db) {
      return 1;
    } else {
      return 0;
    }
  } else if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    let firstVal = a[orderBy].toLowerCase().trim()
    let secondVal = b[orderBy].toLowerCase().trim()
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;

  } else {
   let firstVal = a[orderBy]
   let secondVal = b[orderBy]
   if (secondVal < firstVal) {
     return -1;
   }
   if (secondVal > firstVal) {
     return 1;
   }
   return 0;
 }
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const VehicleHistoryListTable = ({ tableData, showColumns, vehicleId, ManageVehicleListResponseData }) => {
  const [vehicleHistoryListTableData, setVehicleHistoryListTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ManufacturerName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const globalizationMapping = new Globalization();
  const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
  const [modalOpen, setModalOpen] = useState(false);
  const [latestRecordData, setLatestRecordData] = useState([]);
  const [updateMiles, setUpdateMiles] = useState("");
  const [updateHour, setUpdateHour] = useState("");
  const [comments, setComments] = useState("");
  const userPref = JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue"));
  const styling = useStyles();
  const [errorsDistance, setErrorsDistance] = useState(false);
  const [errorsHours, setErrorsHours] = useState(false);
  const [checkDisabled, setCheckDisabled] = useState(false)
  const [popperState, setPopperState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const headerApi = new HeaderApi();


  useEffect(() => {
    setVehicleHistoryListTableData(tableData);
    let arr = commonUtils.rowPerPageSelector(vehicleHistoryListTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  useEffect(() => {
    const maxObj = vehicleHistoryListTableData.rowsData.sort(
      (a, b) => a.EventId - b.EventId
    );
    if (maxObj && maxObj.length > 0 && maxObj[maxObj.length - 1].Event.toLowerCase() === "updatehours") {
      setCheckDisabled(false)
    } else {
      setCheckDisabled(true)
    }
  })

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const editRecord = () => {
    let maxObj = vehicleHistoryListTableData.rowsData.sort((a, b) => {
      return a.EventId - b.EventId;
    });
    if (maxObj.length >= 1) {
      setModalOpen(true);
      setLatestRecordData(maxObj[maxObj.length - 1]);
    }
  };
  const modalClose = () => {
    setModalOpen(false);
  };
  function onChangeListener(from, value) {
    if (from === "hours") {
      setUpdateHour(value);
      setErrorsHours(false);
    } else if (from === "miles") {
      setUpdateMiles(value);
      setErrorsDistance(false);
    } else if (from === "comment") {
      setComments(value);
    } else {
      return;
    }
  }
  const latestRecordSubmit = () => {
    let payload = {
      vehicleId: Number(vehicleId),
      updatedHours: Number(updateHour),
      updatedMiles: Number(updateMiles),
      vehicleEventId: Number(latestRecordData.EventId),
      comments: comments
    };
    headerApi.getLatestVehicleHistoryData(payload).then((data) => {
      setModalOpen(false);
      ManageVehicleListResponseData();
    });
  };
  const showError = (from) => {
    if (from === "miles") {
      return (
        (updateMiles == "" && updateMiles == null) || errorsDistance
      );
    } else {
      return (
        (updateHour == "" && updateHour == null) || errorsHours
      );
    }
  };

  const handlePopperOpen = target => {
    setAnchorEl(anchorEl ? null : target);
    setPopperState(true);
  };
  const handlePopperClose = () => {
    setAnchorEl(null);
    setPopperState(false);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <InputLabel className="filter-table-label">
            <span>
              <button
                className={checkDisabled ? "header-edit-latest-record-change-modal-disabled" : "header-change-modal-confirm-btn"}
                style={{ width: "10em", float: "right", marginRight: "15px" }}
                onClick={editRecord}
                disabled={checkDisabled}
              >
                {globalizationMapping.VEHICLEHISTORY.EDITLATESTRECORD}
              </button>
            </span>
          </InputLabel>
          <Modal open={modalOpen} disableScrollLock={true} onBackdropClick={modalClose}>
            <div className="header-change-modal-container center-modal">
              <button aria-hidden="true" onClick={modalClose} className="bootbox-close-button close" type="button">
                <img src={require("../../../../../assets/close-icon.svg")} className="modal-close-icon" alt="close-icon" />
              </button>
              <div style={{ marginLeft: "1.3em" }} className="header-change-modal-title">
                {/* Update Hours and Miles */}
                {globalizationMapping.VEHICLEHISTORY.EDITLABEL}
              </div>
              <div className="bootbox-body">
                {/* justifyContent: 'space-between', */}
                <div style={{ display: "flex", padding: "0em 1.7em" }}>
                  <div style={{ padding: "1em 0.7em" }}>
                    <div>
                      <div className="sub-heading1-UHM">{globalizationMapping.REPORTCOMMONWORDS.LastHour}</div>
                      <div className="sub-heading1-UHM" style={{ color: "#000" }}>
                        {latestRecordData.Hours}
                      </div>
                    </div>
                    <div style={{ marginTop: "2em" }}>
                      <div className="sub-heading2-UHM">
                        {globalizationMapping.VEHICLEHISTORY.ENTERHOURS}
                        <span className="required-mark">*</span>
                      </div>
                      <TextField
                        className="datepicker-popup"
                        variant="outlined"
                        value={updateHour}
                        onChange={(e) => onChangeListener("hours", e.target.value)}
                        placeholder="Enter Hour"
                        helperText=""
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        onKeyPress={(event) => {
                          if (event?.key === '-' || event?.key === '+') {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                      {showError("hours") && (
                        <FormHelperText>
                          {globalizationMapping.INSPECT.ERRMSGFOREDITMODELHOURS}
                        </FormHelperText>
                      )}
                    </div>
                  </div>

                  <div style={{ padding: "1em 6.7em" }}>
                    <div>
                      <div className="sub-heading1-UHM">{userPref.toLowerCase() !== "metric" ? "Last Miles" : "Last KM"}</div>
                      <div className="sub-heading1-UHM" style={{ color: "#000" }}>
                        {latestRecordData.Kilometers}
                      </div>
                    </div>
                    <div style={{ marginTop: "2em" }}>
                      <div className="sub-heading2-UHM">
                        {userPref.toLowerCase() !== "metric" ? "Enter Miles" : "Enter KM"}
                        <span className="required-mark">*</span>
                      </div>
                      <TextField
                        className="datepicker-popup"
                        variant="outlined"
                        value={updateMiles}
                        onChange={(e) => onChangeListener("miles", e.target.value)}
                        placeholder={userPref.toLowerCase() !== "metric" ? "Enter Miles" : "Enter KM"}
                        helperText=""
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        onKeyPress={(event) => {
                          if (event?.key === '-' || event?.key === '+') {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                      {showError("miles") && (
                        <FormHelperText>
                          {globalizationMapping.INSPECT.ERRMSGFOREDITMODELMILES}
                        </FormHelperText>)
                      }
                    </div>
                  </div>
                  <div style={{ padding: "1em 0.7em" }}>
                    <div>
                      <div className="sub-heading2-UHM">
                        {globalizationMapping.REPORTTABLE.COMMENTS}
                        {/* <span className="required-mark">*</span> */}
                      </div>
                      <textarea
                        className="textArea"
                        rows="4"
                        cols="50"
                        name={comments}
                        onChange={(e) => onChangeListener("comment", e.target.value)}
                        placeholder="Enter comments"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="footer-msg">
                <div className="action-buttons">
                  <Button className={styling.cancel} onClick={modalClose}>
                    {globalizationMapping.FORMS.CANCEL}
                  </Button>
                  <Button
                    className="header-change-modal-confirm-btn1"
                    onClick={() => {
                      if (updateMiles !== "" && updateHour !== "") {
                        latestRecordSubmit();
                      }
                      updateMiles === "" && setErrorsDistance(true);
                      updateHour === "" && setErrorsHours(true);
                    }}
                  >
                    {globalizationMapping.FORMS.SUBMIT}
                  </Button>
                </div>
              </footer>
            </div>
          </Modal>
          <InputLabel className="filter-table-label">
            <span className="table-toolbar-label-text-view-vehicle">{tireFormCaptions.VEHICLEHISTORY}</span>
            <img
              style={{ marginTop: '5px' }}
              onMouseEnter={e => handlePopperOpen(e.currentTarget)}
              onMouseLeave={handlePopperClose}
              alt="info"
              src={infoLogo}
            />
            <Popper
              open={popperState}
              placement="top"
              anchorEl={anchorEl}
              transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.popper}>
                    <p className="tirecosts-popper-title">{globalizationMapping.QUICKINSPECT.VEHICLEHISTORY}</p>
                    <p className="tirecosts-popper-desc" >
                      {globalizationMapping.INSPECT.NOTEMSGFORVEHICLEHISTORY}
                    </p>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </InputLabel>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {vehicleHistoryListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        // align={headCell.numeric === true ? "right" : "left"}
                        align="left"
                        sortDirection={orderBy === headCell.value ? order : false}
                      >
                        <TableSortLabel active={orderBy === headCell.value} direction={orderBy === headCell.value ? order : "asc"} onClick={handleRequestSort(headCell.value)}>
                          {headCell.label}
                          {orderBy === headCell.value ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
                <tr className="table-bottom-line">
                  <td colspan={vehicleHistoryListTableData.headCells.length + 1}></td>
                </tr>
              </TableHead>
              {vehicleHistoryListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(vehicleHistoryListTableData.rowsData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"}  tabIndex={-1} key={row.Key}>
                          {showColumns.Date && (
                            <TableCell align="left" className="table-content-text">
                              {row.Date}
                            </TableCell>
                          )}
                          {showColumns.Event && (
                            <TableCell align="left" className="table-content-text">
                              {row.Event}
                            </TableCell>
                          )}
                          {showColumns.Hours && (
                            <TableCell align="left" className="table-content-text">
                              {row.Hours}
                            </TableCell>
                          )}
                          {showColumns.Distance && (
                            <TableCell align="left" className="table-content-text">
                              {row.Kilometers}
                            </TableCell>
                          )}
                          {showColumns.Comments && (
                            <TableCell align="left" className="table-content-text">
                              {row.Comments}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {vehicleHistoryListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={8} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={vehicleHistoryListTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div >
  );
};
export default VehicleHistoryListTable;
