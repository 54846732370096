import React, { useEffect, useState, useContext } from "react";
import OtherTiresNavigation from "../../../other-tires-navigation/OtherTiresNavigation";
import "./TiresSentToInspectTireReport.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel } from "@material-ui/core";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import TiresSentToInspectTireReportDataSetter from "../../../../../services/TireSentToInspectTireReportUtils";
import MultiSelect from "react-multi-select-component";
import TiresSentToInspectTireReportTable from "../table/TiresSentToInspectTireReportTable";
import { StateContext } from "../../../../../../../services/StateProvider";
import CommonUtils from "../../../../../../../services/utils";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import Modal from "@material-ui/core/Modal";
import AlertPopup from "../../../../../../../common-components/alert-pop-up/alert-popup";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import HeaderApi from "../../../../../../../common-components/header/services/header-apis";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import TiresSentToInspectTireReportFilter from "../filter/TiresSentToInspectTireReportFilter";

const TiresSentToInspectTireReport = () => {
  // GLOBAL ACCOUNT STATE management
  const tiresService = new TiresService();
  const tiresSentToInspectTireReportDataSetter = new TiresSentToInspectTireReportDataSetter();
  const commonUtils = new CommonUtils();
  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [tiresSentToInspectTableData, setTiresSentToInspectTableData] = useState([]);
  const [tiresRAWData, setTiresRAWData] = useState([]);
  const [originalTiresSentToInspectTableData, setOriginalTiresSentToInspectTableData] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState([]);
  const [displayColumns, setDisplayColumns] = useState([]);
  const [displayUsers, setDisplayUsers] = useState([]);
  const [selectedMultiple, setMultipleOptions] = useState([]);

  const [noData, setNoData] = useState(false);
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [printData, setPrintData] = useState(tiresSentToInspectTableData);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [tryingToSearch, setSearchFlag] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const headerApi = new HeaderApi();

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" }

    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: []
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  let yy = new Date().getFullYear();
  let mm = new Date().getMonth() + 1;
  mm = mm < 10 ? "0" + mm : "" + mm;
  let dd = new Date().getDate();
  dd = dd < 10 ? "0" + dd : "" + dd;
  let endDate = yy + "-" + mm + "-" + dd;
  let fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 3);

  const singleDigitDateChecker = (monthOrDay) => {
    if (monthOrDay === "month") {
      if (fromDate.getMonth() + 1 < 10) {
        return "0"
      } else {
        return ""
      }
    } else {
      if (fromDate.getDate() < 10) {
        return "0"
      } else {
        return ""
      }
    }
  }

  useEffect(() => {
    let firstDate =
      fromDate.getFullYear() + "-" + ((singleDigitDateChecker('month')) + (fromDate.getMonth() + 1)) + "-" + (singleDigitDateChecker('day')) + fromDate.getDate();
    setStartDate(firstDate);

    setToDate(endDate);

    TiresSentToInspectResponseDataX(filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const TiresSentToInspectResponseDataX = (_filterAppliedValue, filterFieldsAppliedValue, fromStartDate, toEndDate, selectedMultiple) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    setShowSpinner(true);
    let firstDate = fromStartDate
      ? fromStartDate
      : fromDate.getFullYear() + "-" + ((singleDigitDateChecker('month')) + (fromDate.getMonth() + 1)) + "-" + (singleDigitDateChecker('day')) + fromDate.getDate();
    setStartDate(firstDate);
    setToDate(toEndDate ? toEndDate : endDate);

    headerApi.getUserProfile().then((loggedUser) => {
      let selectedUserList = [];
      if (selectedMultiple?.length > 0) {
        selectedMultiple.forEach((user) => {
          selectedUserList.push(user);
        });
      } else {
        selectedUserList.push(loggedUser.model.userId);
      }
      tiresService.getUserIdList().then((data) => {
        let userIdList = data.data;
        let requestObject = {
          startDate: fromStartDate
            ? fromStartDate
            : fromDate.getFullYear() +
            "-" +
            ((singleDigitDateChecker('month')) + (fromDate.getMonth() + 1)) +
            "-" +
            (singleDigitDateChecker('day')) +
            fromDate.getDate(),
          endDate: toEndDate ? toEndDate : endDate,
          locationId: locationIds,
          parentAccountId: accountIds[0],
          userIdList: selectedMultiple?.length > 0 ? selectedMultiple : selectedUserList
        };
        setShowSpinner(true);
        tiresService.getUserTiresReport(requestObject).then((data) => {
          let tiresSentInspectTireTableInfo = tiresSentToInspectTireReportDataSetter.setData(data, userIdList);
          let filterOptions = {
            categories: [
              {
                name: globalizationMapping.REPORTTABLE.MANUFACTURER,
                value: "ManufacturerName"
              },
              {
                name: globalizationMapping.REPORTTABLE.SIZE,
                value: "SizeName"
              },
              {
                name: globalizationMapping.REPORTTABLE.TYPE,
                value: "TypeName"
              },
              {
                name: globalizationMapping.REPORTTABLE.COMPOUND,
                value: "CompoundName"
              }
            ],
            categoryData: {
              ManufacturerName: [],
              SizeName: [],
              TypeName: [],
              CompoundName: []
            }
          };
          let filterOptionsInfo = tiresSentToInspectTireReportDataSetter.setFilterOptionsData(filterOptions, tiresSentInspectTireTableInfo.rowsData);
          setFilterOptions(filterOptionsInfo);
          setTableBeforeFilter(tiresSentInspectTireTableInfo);

          let updatedHeadCells = tiresSentToInspectTireReportDataSetter.updatedHeadCells();
          let updateSelectedUserColShow = tiresSentToInspectTireReportDataSetter.updatedUserHeadCells(selectedUserList);

          tiresSentInspectTireTableInfo.rowsData?.length === 0 ? setNoData(true) : setNoData(false);

          setTiresRAWData(JSON.parse(JSON.stringify(tiresSentInspectTireTableInfo)));
          setTiresSentToInspectTableData(tiresSentInspectTireTableInfo);
          setOriginalTiresSentToInspectTableData(tiresSentInspectTireTableInfo);
          setDisplayUsers(tiresSentInspectTireTableInfo.updateUsers);
          setMultipleOptions(updateSelectedUserColShow);

          setShowColumns(tiresSentInspectTireTableInfo.selectedColShow);

          setDisplayColOptions(tiresSentInspectTireTableInfo.headCells);
          setDisplayColumns(updatedHeadCells);
          setPrintData({
            reportType: "Other Tires : ",
            reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToInspectTireReport,
            rowsData: tiresSentInspectTireTableInfo.rowsData,
            headCells: tiresSentInspectTireTableInfo.headCells,
            avgValues: tiresSentInspectTireTableInfo.avgValues,
            selectedColShow: tiresSentInspectTireTableInfo.selectedColShow,
            updateUsers: tiresSentInspectTireTableInfo.updateUsers,
            filterApplied: filterApplied
          });
          if (filterApplied) {
            let originalTableDataStore = tiresSentInspectTireTableInfo.rowsData;
            let filteredData = tiresSentToInspectTireReportDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
            setTiresSentToInspectTableData({
              ...tiresSentInspectTireTableInfo,
              rowsData: filteredData,
              avgValues: tiresSentToInspectTireReportDataSetter.avgValues(filteredData)
            });
            setOriginalTiresSentToInspectTableData({
              ...tiresSentInspectTireTableInfo,
              rowsData: filteredData,
              avgValues: tiresSentToInspectTireReportDataSetter.avgValues(filteredData)
            });
            setPrintData({
              ...printData,
              rowsData: filteredData,
              avgValues: tiresSentToInspectTireReportDataSetter.avgValues(filteredData),
              filterFieldsApplied: filterFieldsAppliedValue,
              filterApplied: true
            });
            filteredData.length === 0 ? setNoData(true) : setNoData(false);
            Object.values(filterFieldsAppliedValue).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);

            confirmApplyDateFilter(filterFieldsApplied);
          }
          setShowSpinner(false);
        });
      });
    });
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });
  const globalizationMapping = new Globalization();

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };
  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };
  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };
  const onClickFilter = () => {
    setShowFilter(true);
  };
  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalTiresSentToInspectTableData?.headCells;
    let showColumnsUpdated = tiresSentToInspectTireReportDataSetter.setDisplayColumnData(currentHeadCells, selected);

    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setFilterApplied(false);
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setFilterApplied(true);
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns?.length === 0 ? setNoData(true) : setNoData(false);
  };
  // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}
    setSearchValue("");
    setFilterFieldsApplied(appliedFilter);
    let originalTableDataStore = originalTiresSentToInspectTableData?.rowsData;
    let filteredData = tiresSentToInspectTireReportDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setTiresSentToInspectTableData({
      ...tiresSentToInspectTableData,
      rowsData: filteredData,
      avgValues: tiresSentToInspectTireReportDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: tiresSentToInspectTireReportDataSetter.avgValues(filteredData)
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);

    confirmApplyDateFilter(appliedFilter);
  };
  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    setFilterApplied(true);
    setOnlyFilterApplied(true);
    setFilterFieldsApplied(appliedFilter);
    let filteredData = [];
    if (appliedFilter.SizeName && appliedFilter.SizeName.length) {
      tiresRAWData.rowsData.forEach((obj) => {
        if (appliedFilter.ManufacturerName.includes(obj.ManufacturerName)) filteredData.push(obj);
      });
    } else {
      filteredData = tiresRAWData.rowsData;
    }
    if (appliedFilter.SizeName && appliedFilter.SizeName.length) {
      let filteredDataTemp = [];
      filteredData.forEach((obj) => {
        if (appliedFilter.SizeName.includes(obj.SizeName)) filteredDataTemp.push(obj);
      });
      filteredData = filteredDataTemp;
    }

    if (appliedFilter.TypeName && appliedFilter.TypeName.length) {
      let filteredDataTemp = [];
      filteredData.forEach((obj) => {
        if (appliedFilter.TypeName.includes(obj.TypeName)) filteredDataTemp.push(obj);
      });
      filteredData = filteredDataTemp;
    }
    setTiresSentToInspectTableData({
      ...tiresSentToInspectTableData,
      rowsData: filteredData
    });
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: []
    });
    setFilterApplied(false);
    setTiresSentToInspectTableData(tableBeforeFilter);
    setOriginalTiresSentToInspectTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApllied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApllied).forEach((index) => {
      tempFilterFieldsApllied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApllied);
    setOnlyFilterApplied(false);
  };

  //   USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    let tableDataStore, filteredData;
    tableDataStore = originalTiresSentToInspectTableData?.rowsData;
    filteredData = tiresSentToInspectTireReportDataSetter.search(tableDataStore, searchText);
    setTiresSentToInspectTableData({
      ...tiresSentToInspectTableData,
      rowsData: filteredData,
      avgValues: tiresSentToInspectTireReportDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      reportType: "Other Tires : ",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToInspectTireReport,
      rowsData: filteredData,
      headCells: originalTiresSentToInspectTableData?.headCells,
      avgValues: tiresSentToInspectTireReportDataSetter.avgValues(filteredData),
      selectedColShow: originalTiresSentToInspectTableData?.selectedColShow,
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };
  const filterName = (data) => {
    const UserNameValue = data.map((userRow) => userRow.value);
    let tableDataStore, filteredData;
    tableDataStore = originalTiresSentToInspectTableData?.rowsData;
    filteredData = tableDataStore.filter((row) => UserNameValue.includes(row.InspectedBy));
    setPrintData({
      ...printData,
      reportType: "Other Tires : ",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToInspectTireReport,
      rowsData: filteredData,
      headCells: originalTiresSentToInspectTableData?.headCells,
      avgValues: tiresSentToInspectTireReportDataSetter.avgValues(filteredData),
      selectedColShow: originalTiresSentToInspectTableData?.selectedColShow,
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    if (filterApplied) confirmApplyDateFilter(filterFieldsApplied);
  };
  const handleFromStartDate = (event) => {
    setSearchFlag(false);
    setStartDate(event.startDate);
  };

  const handleToStartDate = (event) => {
    setSearchFlag(false);
    setToDate(event.toDate);
  };

  const onClickUpdate = () => {
    setSearchFlag(true);

    if (startDate !== "" && toDate !== "" && new Date(toDate).getTime() - new Date(startDate).getTime() >= 0) {
      let selectedMultiple1 = selectedMultiple;
      let userList = [];
      selectedMultiple1.forEach((user) => {
        userList.push(user.value);
      });
      TiresSentToInspectResponseDataX(false, false, startDate, toDate, userList);
    }
  };
  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };
  return (
    <div>
      <div className="container">
        <OtherTiresNavigation />
        {showSpinner && <Spinner />}
        {!showSpinner && (
          <div className="tire-register-container">
            <Card className="tire-register-card">
              <CardContent>
                <div className="table-toolbar">
                  <div className="table-filters">
                    <div className="display-columns-container" id="dashboard-display-column">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SelectUser}</span>
                      </InputLabel>
                      <MultiSelect
                        options={displayUsers}
                        value={selectedMultiple}
                        onChange={(data) => {
                          setMultipleOptions(data);
                          filterName(data);
                        }}
                        overrideStrings={{
                          selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                          allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                          selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                          search: globalizationMapping.REPORTCOMMONWORDS["Search"]
                        }}
                      />
                    </div>
                    <div className="display-columns-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.FromDate}</span>
                      </InputLabel>
                      <TextField
                        id="date"
                        type="date"
                        style={{ width: "100%" }}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={startDate}
                        onChange={(event) => handleFromStartDate({ startDate: event.target.value })}
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>
                    <div className="display-columns-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ToDate}</span>
                      </InputLabel>
                      <TextField
                        id="date"
                        type="date"
                        style={{ width: "100%" }}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        // onChange={handleStartDate(data)}
                        onChange={(event) => handleToStartDate({ toDate: event.target.value })}
                        value={toDate}
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>
                    <div className="display-columns-container">
                      <button className="update-button" onClick={onClickUpdate}>
                        <span className="Update-text-style">{globalizationMapping.INSPECT.UPDATE}</span>
                      </button>
                    </div>
                  </div>

                  <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
                </div>
                <div className="table-toolbar">
                  <div className="search-table-container" style={{ marginLeft: "0.5em" }}>
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>

                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                </div>

                <div className="tire-register-table">
                  <TiresSentToInspectTireReportTable tableData={tiresSentToInspectTableData} showColumns={showColumns} filterApplied={filterApplied} />
                </div>
              </CardContent>
            </Card>
          </div>
        )}

        <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />

        <TiresSentToInspectTireReportFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
      />
      </div>
      <Modal
        open={tryingToSearch && (startDate === "" || toDate === "" || new Date(toDate).getTime() - new Date(startDate).getTime() < 0)}
        onClose={() => setSearchFlag(false)}
        className="header-change-modal-style"
        disableScrollLock={true}
      >
        <AlertPopup
          onClose={() => setSearchFlag(false)}
          description="Incorrect Data"
          message="You haven't provided the dates properly. Please provide the correct dates."
          screen="TireInspectionReport"
        />
      </Modal>
    </div>
  );
};

export default TiresSentToInspectTireReport;
