import React, { useEffect, useState } from "react";
import "./ManageLocationListTable.css";
import "../../../../../common-styles/TableStyle.css";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Divider,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from '@material-ui/icons/MoreVertOutlined';
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import DeleteLocationModal from "../modals/delete-location/DeleteLocation";
import Globalization from "../../../../../globalization";
import CommonUtils from "../../../../../services/utils";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    maxHeight: 600,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
  },
}));

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ManageLocationListTable = ({
  tableData,
  _filterApplied,
  showColumns,
  multipleRowsSelected,
  deleteLocationDone,
  forceActionPopupClose,
  forceActionPopupCloseToFalse,
}) => {
  const [manageLocationListTableData, setManageLocationListTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const globalizationMapping = new Globalization();
  const [orderBy, setOrderBy] = useState("LocationName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowsSelected, setRowsSelected] = useState(0);
  const [editClicked, setEditClicked] = useState([]);
  const [selectedAccIds, setSelectedAccIds] = useState([]);
  const [selectedAccDetails, setSelectedAccDetails] = useState([]);
  const [accountIdToDelete, setAccountIdToDelete] = useState(null);
  const [accountNameToDelete, setAccountNameToDelete] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const commonUtils = new CommonUtils();
  const history = useHistory();
  
  useEffect(() => {
    setManageLocationListTableData(tableData);
    let row = [];
    row.length = manageLocationListTableData.rowsData.length;
    for (let i = 0; i < manageLocationListTableData.rowsData.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
    let arr = commonUtils.rowPerPageSelector(manageLocationListTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  useEffect(()=> {
    forceCloseAction();
    forceActionPopupCloseToFalse();
  },[forceActionPopupClose]);

  const handleRequestSort = (property) => (_event) => {
    forceCloseAction();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleChangePage = (_event, newPage) => {
    forceCloseAction();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    forceCloseAction();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSelectAllClick = () => {
    forceCloseAction();
    setSelectedAccDetails([]);
    if (rowsSelected !== manageLocationListTableData.rowsData.length) {
      let accIDs = manageLocationListTableData.rowsData.map((val, _index) => {
        return val.LocationId;
      });
      setSelectedAccIds(accIDs);
      setRowsSelected(manageLocationListTableData.rowsData.length);
      multipleRowsSelected(manageLocationListTableData.rowsData);
      setSelectedAccDetails(manageLocationListTableData.rowsData);
    } else {
      setSelectedAccIds([]);
      setRowsSelected(0);
      multipleRowsSelected([]);
    }
  };

  const onRowSelect = (_index, locationId) => {
    forceCloseAction();
    let ind = selectedAccIds.indexOf(locationId);
    let selectedCount = selectedAccDetails.length;
    let selectedIdsList = [...selectedAccIds];
    let selectedDetailsList = [...selectedAccDetails];
    if (ind > -1) {
      selectedIdsList.splice(ind, 1);
      selectedDetailsList.splice(ind, 1);
      selectedCount--;
    } else {
      selectedCount++;
      selectedIdsList.push(locationId);
      let Account = manageLocationListTableData.rowsData.find(
        (element) => element.LocationId === locationId
      );
      selectedDetailsList.push(Account);
    }
    multipleRowsSelected(selectedDetailsList);
    setRowsSelected(selectedCount);
    setSelectedAccIds(selectedIdsList);
    setSelectedAccDetails(selectedDetailsList);
  };

  const findBottom = (index) => {
    return (index > 7 && index < 10) ||
      (index > 12 && index < 15) ||
      (index > 22 && index < 25) ||
      index === manageLocationListTableData.rowsData.length
      ? true
      : false;
  };

  const editRow = (index) => {
    let row = [...editClicked];
    row.length = manageLocationListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      if (row[i] === true && index !== i) {
        row[i] = false;
      }
    }
    if (editClicked[index]) {
      row[index] = !row[index];
      setEditClicked(row);
    } else {
      row[index] = true;
      setEditClicked(row);
    }
  };

  const forceCloseAction = () => {
    let row = [...editClicked];
    row.length = manageLocationListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
  }

  const handleDeleteClick = (index, locationId, name) => {
    editRow(index);
    setAccountIdToDelete(locationId);
    setAccountNameToDelete(name);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = (deleteData) => {
    setDeleteModalOpen(false);
    setAccountIdToDelete(null);
    setAccountNameToDelete(null);
    let notification = { state: deleteData };
    deleteLocationDone(notification);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}
            style={{
              minHeight:
                manageLocationListTableData.rowsData.length < 4
                  ? "450px"
                  : "800px",
            }}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.stickyHeader}
                    padding="checkbox"
                  >
                    <Checkbox
                      indeterminate={
                        rowsSelected > 0 &&
                        rowsSelected <
                        manageLocationListTableData.rowsData.length
                      }
                      checked={
                        manageLocationListTableData.rowsData.length > 0 &&
                        rowsSelected ===
                        manageLocationListTableData.rowsData.length
                      }
                      style={{
                        color: "#0055A4",
                        zIndex: 2,
                      }}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  </TableCell>
                  {manageLocationListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] && headCell.keyIndex !== 8 ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort(headCell.value)}
                        >
                          {headCell.label}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        {" "}
                        {globalizationMapping.MANAGETIRE.ACTIONS}{" "}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <tr className="table-bottom-line">
                <td colSpan={manageLocationListTableData.headCells.length + 1}></td>
              </tr>
              {manageLocationListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    manageLocationListTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          tabIndex={-1}
                          key={row.Key}
                        >
                          <TableCell
                            style={{ position: "relative" }}
                            className="table-content-text"
                            padding="checkbox"
                          >
                            <Checkbox
                              checked={
                                selectedAccIds.indexOf(row.LocationId) > -1
                              }
                              style={{
                                color: "#0055A4",
                              }}
                              onChange={() =>
                                onRowSelect(index, row.LocationId)
                              }
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                            />
                          </TableCell>
                          {showColumns.LocationName && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.LocationName}
                            </TableCell>
                          )}
                          {showColumns.AccountName && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.AccountName}
                            </TableCell>
                          )}
                          {showColumns.Country && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Country}
                            </TableCell>
                          )}
                          {showColumns.State && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.State}
                            </TableCell>
                          )}
                          {showColumns.City && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.City}
                            </TableCell>
                          )}
                          {showColumns.ContactPerson && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.ContactPerson}
                            </TableCell>
                          )}
                          {showColumns.EmailAddress && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.EmailAddress}
                            </TableCell>
                          )}
                          {showColumns.PhoneNumber && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.PhoneNumber}
                            </TableCell>
                          )}

                          <TableCell
                            align="left"
                            className="table-content-text"
                            style={{ position: "relative" }}
                          >
                            <IconButton
                              aria-label="edit"
                              onClick={() => editRow(index)}
                            >
                              <MoreIcon style={{color:commonUtils.primaryBlue}} fontSize='small' ></MoreIcon>
                            </IconButton>
                            {editClicked[index] === true && (
                              <div className="action-dropdown-content"
                                style={{
                                  marginTop: findBottom(index)
                                    ? "-200px"
                                    : "-30px",
                                }}>
                                <div
                                  className="action-option"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/view-location",
                                      state: {
                                        locationId: row.LocationId,
                                        accountName: row.AccountName,
                                      },
                                    })
                                  }
                                >
                                  {globalizationMapping.HOMEDASHBOARD.VIEW}
                                </div>
                                <Divider />
                                <div
                                  className="action-option"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/editlocation",
                                      state: {
                                        accountId: row.LocationId,
                                      },
                                    })
                                  }
                                >
                                  {globalizationMapping.PROFILE.Edit}
                                </div>
                                <Divider />
                                <div
                                  className="action-option"
                                  onClick={() => {
                                    handleDeleteClick(
                                      index,
                                      row.LocationId,
                                      row.LocationName
                                    );
                                  }}
                                >
                                  {
                                    globalizationMapping.MANAGE_PARENT_ACCOUNT
                                      .OPTION3
                                  }
                                </div>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {manageLocationListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={8}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isDeleteModalOpen && (
                <DeleteLocationModal
                  closeDeleteModal={handleDeleteModalClose}
                  accountId={accountIdToDelete}
                  accountName={accountNameToDelete}
                  isSelectionMultiple={false}
                />
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={manageLocationListTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default ManageLocationListTable;
