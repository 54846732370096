import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import NoDataFound from '../../../../common-components/no-data-found/NoDataFound';
import Globalization from '../../../../globalization';
import CommonUtils from '../../../../services/utils';
import { HistoryGraphModal } from '../graph-modal/history-table-graph-modal';

const useStyles = makeStyles({
    graphsection: {
        height: '270px',
        width: '98%',
        background: '#FAFAFA',
        borderRadius: '7px',
        border: '1px solid #E8E8E8',
        opacity: 1,
        boxShadow: 'none'
    },
    graphGrid: {
        // marginLeft: '10px',
        flexBasis: '0%',
        flexWrap: 'nowrap'
    }
});

const ViewTireGraphs = ({ tireGraphData }) => {

    const styles = useStyles();
    const globalizationMapping = new Globalization();
    const graphCaptions = globalizationMapping.GRAPH;
    const hotColdLabel = globalizationMapping.INSPECT;

    const [historyGraphModalOpen, setHistoryGraphModalOpen] = useState(false);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const commonUtilsObjMTD = new CommonUtils();
    const commonUtilsObjHTD = new CommonUtils();
    const commonUtilsObjCH = new CommonUtils();
    const commonUtilsObjPL = new CommonUtils();
    const commonUtilsObjTD = new CommonUtils();
    const commonLineGraphOptionsMTD = commonUtilsObjMTD.viewTireLineOptions;
    const commonLineGraphOptionsHTD = commonUtilsObjHTD.viewTireLineOptions;
    const commonLineGraphOptionsCH = commonUtilsObjCH.viewTireLineOptions;
    const commonLineGraphOptionsPL = commonUtilsObjPL.viewTireLineOptions;
    const commonLineGraphOptionsTD = commonUtilsObjTD.viewTireLineOptions;
    commonLineGraphOptionsMTD.title.text = graphCaptions.milesTreadTitle;
    commonLineGraphOptionsHTD.title.text = graphCaptions.hoursTreadTitle;
    commonLineGraphOptionsCH.title.text = graphCaptions.costHourTitle;
    commonLineGraphOptionsPL.title.text = graphCaptions.pressureTitle;
    commonLineGraphOptionsTD.title.text = graphCaptions.treadTitle;


    const [mptdData, setMptdData] = useState({});
    const [hptdData, setHptdData] = useState({});
    const [cphData, setCphData] = useState({});
    const [pressureData, setPressureData] = useState({});
    const [tdData, setTdData] = useState({});
    const [graphIndex, setGraphIndex] = useState({});

    const handleElementOpen = (i) => {
        setGraphIndex(i);
        setHistoryGraphModalOpen(true);
    };

    const handleElementClose = () => {
        setHistoryGraphModalOpen(false);
    };

    const setData = () => {

        if (tireGraphData) {
            if (tireGraphData.milesPerTreadDepthList && tireGraphData.milesPerTreadDepthList.length > 0) {
                let graphData = tireGraphData.milesPerTreadDepthList.slice(tireGraphData.milesPerTreadDepthList.length - 5, tireGraphData.milesPerTreadDepthList.length)
                let dateLabelArr = [];
                let dataSetArr = [{ data: [], borderColor: '#505D6F', pointBackgroundColor: '#505D6F', fill: false, label: "", pointRadius: 4.5, }];
                graphData.forEach(gdata => {
                    let date = new Date(gdata.timeSpan);
                    date = date.getDate() + " " + (monthNames[date.getMonth()]);
                    dateLabelArr.push(date);

                    dataSetArr[0].data.push(gdata.tireMetric !== null ? gdata.tireMetric : 0);
                });
                setMptdData({
                    labels: dateLabelArr,
                    datasets: dataSetArr
                });
            }
            if (tireGraphData.hoursPerTreadDepthList && tireGraphData.hoursPerTreadDepthList.length > 0) {
                let graphData = tireGraphData.hoursPerTreadDepthList.slice(tireGraphData.hoursPerTreadDepthList.length - 5, tireGraphData.hoursPerTreadDepthList.length)
                let dateLabelArr = [];
                let dataSetArr = [{ data: [], borderColor: '#505D6F', pointBackgroundColor: '#505D6F', fill: false, label: "", pointRadius: 4.5, }];
                graphData.forEach(gdata => {
                    let date = new Date(gdata.timeSpan);
                    date = date.getDate() + " " + (monthNames[date.getMonth()]);
                    dateLabelArr.push(date);

                    dataSetArr[0].data.push(gdata.tireMetric !== null ? gdata.tireMetric : 0);
                });
                setHptdData({
                    labels: dateLabelArr,
                    datasets: dataSetArr
                });
            }
            if (tireGraphData.costPerHourList && tireGraphData.costPerHourList.length > 0) {
                let graphData = tireGraphData.costPerHourList.slice(tireGraphData.costPerHourList.length - 5, tireGraphData.costPerHourList.length);
                let dateLabelArr = [];
                let dataSetArr = [{ data: [], borderColor: '#505D6F', pointBackgroundColor: '#505D6F', fill: false, label: "", pointRadius: 4.5, }];
                graphData.forEach(gdata => {
                    let date = new Date(gdata.timeSpan);
                    date = date.getDate() + " " + (monthNames[date.getMonth()]);
                    dateLabelArr.push(date);

                    dataSetArr[0].data.push(gdata.tireMetric !== null ? gdata.tireMetric : 0);
                });
                setCphData({
                    labels: dateLabelArr,
                    datasets: dataSetArr
                });
            }
            if (tireGraphData.pressureList && tireGraphData.pressureList.length > 0) {
                let graphData = tireGraphData.pressureList.slice(tireGraphData.pressureList.length - 5, tireGraphData.pressureList.length)
                let dateLabelArr = [];
                let dataSetArr = [{ data: [], borderColor: '#505D6F', backgroundColor: [], fill: false, label: "", pointRadius: 5 }];
                graphData.forEach(gdata => {
                    let date = new Date(gdata.timeSpan);
                    date = date.getDate() + " " + (monthNames[date.getMonth()]);
                    dateLabelArr.push(date);

                    dataSetArr[0].data.push(gdata.tireMetric !== null ? gdata.tireMetric : 0);
                    dataSetArr[0].backgroundColor.push(gdata.info === 'H' ? '#ff0000' : '#00cdff');
                });
                setPressureData({
                    labels: dateLabelArr,
                    datasets: dataSetArr
                });
            }
            if (tireGraphData.treadDepthList && tireGraphData.treadDepthList.length > 0) {
                let graphData = tireGraphData.treadDepthList.slice(tireGraphData.treadDepthList.length - 5, tireGraphData.treadDepthList.length)
                let dateLabelArr = [];
                let dataSetArr = [{ data: [], borderColor: '#505D6F', pointBackgroundColor: '#505D6F', fill: false, label: "", pointRadius: 4.5, }];
                graphData.forEach(gdata => {
                    let date = new Date(gdata.timeSpan);
                    date = date.getDate() + " " + (monthNames[date.getMonth()]);
                    dateLabelArr.push(date);

                    dataSetArr[0].data.push(gdata.tireMetric !== null ? gdata.tireMetric : 0);
                });
                setTdData({
                    labels: dateLabelArr,
                    datasets: dataSetArr
                });
            }
        }
    };

    useEffect(() => {
        setData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tireGraphData]);


    return (
        <React.Fragment>
            {(tireGraphData.milesPerTreadDepthList.length === 0 &&
                tireGraphData.hoursPerTreadDepthList.length === 0 &&
                tireGraphData.costPerHourList.length === 0 &&
                tireGraphData.pressureList.length === 0 &&
                tireGraphData.treadDepthList.length === 0)
                ? <div className="no-data-container">
                    <NoDataFound noDataType="graph"></NoDataFound>
                </div>
                : <Grid container className={styles.graphGrid} spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={styles.graphsection}
                            onClick={() => handleElementOpen(0)}>
                            {tireGraphData &&
                                <Line
                                    data={mptdData} options={commonLineGraphOptionsMTD}
                                />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={styles.graphsection}
                            onClick={() => handleElementOpen(1)}>
                            {tireGraphData &&
                                <Line
                                    data={hptdData} options={commonLineGraphOptionsHTD}
                                />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={styles.graphsection}
                            onClick={() => handleElementOpen(2)}>
                            {tireGraphData &&
                                <Line
                                    data={cphData} options={commonLineGraphOptionsCH}
                                />}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={styles.graphsection}
                            onClick={() => handleElementOpen(3)}>
                            {tireGraphData && <>
                                <Line
                                    data={pressureData} options={commonLineGraphOptionsPL}
                                />
                                <div style={{ display: "flex", justifyContent: "space-around", marginTop: 10, fontSize: 12 }}>
                                    <span style={{ display: 'flex' }}><div style={{ background: "#ff0000", width: 14, height: 14, marginRight: 3, borderRadius: '100%' }}></div>{hotColdLabel.HOT}</span>
                                    <span style={{ display: 'flex' }}><div style={{ background: "#00cdff", width: 14, height: 14, marginRight: 3, borderRadius: '100%' }}></div>{hotColdLabel.COLD}</span>
                                </div> </>}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={styles.graphsection}
                            onClick={() => handleElementOpen(4)}>
                            {tireGraphData &&
                                <Line
                                    data={tdData} options={commonLineGraphOptionsTD}
                                />}
                        </Paper>
                    </Grid>
                </Grid>}
            {(historyGraphModalOpen && tireGraphData) &&
                <HistoryGraphModal
                    setData={setData}
                    onModalClose={handleElementClose}
                    currentGraph={graphIndex}
                    mptdGraphData={mptdData}
                    hptdGraphData={hptdData}
                    cphGraphData={cphData}
                    pressureGraphData={pressureData}
                    tdGraphData={tdData}
                />}
        </React.Fragment>
    );
}

export default ViewTireGraphs;
