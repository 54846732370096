import React, { useContext, useEffect, useState } from "react";
import "./create-tire.css";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import Globalization from "../../../globalization/index";
import { Button, makeStyles, Step, StepConnector, withStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Identification from "./step-identification";
import Description from "./step-description";
import TreadDepth from "./step-tread-depth";
import TireCosts from "./step-tire-costs";
import HeaderApi from "../../../common-components/header/services/header-apis";
import { StateContext } from "../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../services/AccountInfoHandler";
import Spinner from "../../../common-components/spinner/spinner";
import rightArrow from "../../../assets/Arrow.svg";
import FlashMessage from "../../../common-components/pop-up-notification/notification-snackbar";
import CancelConfirmationPopup from "../../cancel-confirmatipn-popup/cancel-popup";
import ManageRimListApis from "../../manage-rim/services/ManageRimListApis";
import CommonUtils from "../../../services/utils";

const CreateTire = () => {
  const history = useHistory();
  const locationObj = useLocation();
  const commonUtils = new CommonUtils();
  const fileUploadFormData = new FormData();
  const [showNotification, setShowNotification] = useState(false);
  const [flashMessageState, setFlashMessageState] = useState(locationObj);
  const [prevPath, setPrevPath] = useState(locationObj.state && locationObj.state.fromPath ? locationObj.state.fromPath : "/manage-tire");
  const [historyState, setHistoryState] = useState();
  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const tireFormCaptions = globalizationMapping.FORMS;
  const validationTexts = tireFormCaptions.VALIDATIONTEXTS;
  const notificationTexts = tireFormCaptions.NOTIFICATIONTEXTS;
  const globalCreateTireTexts = globalizationMapping.TIRES.CREATEFORM;
  const [isClonedClicked, setClonedClicked] = useState(false);
  const [idFormErrors, setIdFormErrors] = useState({});
  const [trackingMethod, setTrackingMethod] = useState(1);

  const breadcrumbsName = () => {
    if (locationObj.state && locationObj.state.tireId !== undefined) {
      if (!isClonedClicked) {
        return globalizationMapping.TIRES.EDITTIRE;
      } else {
        return globalHeaderTexts.CREATETIRE;
      }
    } else {
      return globalHeaderTexts.CREATETIRE;
    }
  }

  const myBreadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME
    },
    {
      path: "",
      name: breadcrumbsName(),
    }
  ];

  const useStyles = makeStyles({
    width: "100%",
    next: {
      "&:hover": {
        opacity: 1
      },
      background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 500,
      color: "#FFFFFF",
      boxShadow: "none"
    },
    previous: {
      "&:hover": {
        backgroundColor: "#EAF3FB",
        borderRadius: 18,
        border: "1px solid #EAF3FB",
        opacity: 1,
        boxShadow: "none"
      },
      background: "transparent",
      border: "1px solid #EAF3FB",
      borderRadius: 18,
      color: "#0055A4",
      opacity: 0.8,
      letterSpacing: 0,
      font: "Barlow",
      fontWeight: 600,
      zIndex: 0,
      boxShadow: "none",
      marginRight: "10px"
    },
    cancel: {
      "&:hover": {
        backgroundColor: "#EAF3FB",
        borderRadius: 18,
        border: "none",
        opacity: 1,
        boxShadow: "none"
      },
      background: "transparent",
      border: "none",
      borderRadius: 18,
      color: "#0055A4",
      opacity: 0.8,
      letterSpacing: 0,
      fontWeight: 600,
      zIndex: 0,
      boxShadow: "none",
      marginRight: 5
    },
    steplabels: {
      cursor: "pointer"
    },
    rootStepper: {
      "& .MuiStepIcon-root": {
        color: "#CFDBEB",
        borderRadius: "50%",
        height: "35px",
        width: "35px",
        marginTop: "9px"
      },
      "& .MuiStepIcon-active": {
        color: "#FEDD00 !important",
        borderColor: "#0D54B5",
        border: "2px solid",
        borderRadius: "50%",
        height: "35px",
        width: "35px"
      },
      "& .MuiStepIcon-completed": {
        color: "#1BB121 !important"
      },
      "& .MuiStepIcon-text": {
        fill: "#0D0D0D",
        opacity: "0.4"
      },
      " & .MuiStepLabel-label": {
        marginTop: "12px",
        textAlign: "inherit",
        marginLeft: "5%",
        fontSize: "1.20em"
      },
      "& .MuiStepLabel-root": {
        flexDirection: "row",
        marginLeft: "25%"
      },
      "& .MuiStepper-horizontal": {
        borderRadius: "4px",
        display: "flex",
        backgroundColor: "#E1EAF5 !important",
        border: "1px solid #D0D0D0 !important",
        opacity: "1"
      },
      "& .MuiStepIcon-active .MuiStepIcon-text": {
        fill: "#0D54B5 !important",
        opacity: "1 !important"
      }
    },
    rootStepper2: {
      "& .MuiStepIcon-root": {
        color: "#1BB121",
        borderRadius: "50%",
        border: "2px solid",
        borderColor: "#0D54B5",
        height: "35px",
        width: "35px",
        marginTop: "9px"
      },
      "& .MuiStepIcon-active": {
        color: "#FEDD00 !important",
        borderColor: "#0D54B5",
        border: "2px solid",
        borderRadius: "50%",
        height: "35px",
        width: "35px"
      },
      "& .MuiStepIcon-completed": {
        color: "#1BB121 !important",
        border: "none !important"
      },
      "& .MuiStepIcon-text": {
        fill: "#FFFFFF",
        opacity: "1"
      },
      " & .MuiStepLabel-label": {
        marginTop: "12px",
        textAlign: "inherit"
      },
      "& .MuiStepLabel-root": {
        flexDirection: "row",
        marginLeft: "25%"
      },
      "& .MuiStepper-horizontal": {
        borderRadius: "4px",
        display: "flex",
        backgroundColor: "#E1EAF5 !important",
        border: "1px solid #D0D0D0 !important",
        opacity: "1"
      },
      "& .MuiStepIcon-active .MuiStepIcon-text": {
        fill: "#0D54B5 !important",
        opacity: "1 !important"
      },
      "& .MuiStepLabel-label": {
        marginLeft: "5%"
      }
    }
  });

  //Stepper
  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22
    },
    active: {
      "& $line": {
        content: `url(${rightArrow})`,
        textAlign: "center",
        width: "100%",
        height: "30px",
        border: "none",
        marginTop: "-10px",
        display: "inline-block"
      }
    },
    completed: {
      "& $line": {
        content: `url(${rightArrow})`,
        textAlign: "center",
        width: "100%",
        height: "30px",
        border: "none",
        marginTop: "-10px",
        display: "inline-block"
      }
    },
    line: {
      content: `url(${rightArrow})`,
      textAlign: "center",
      width: "100%",
      height: "30px",
      border: "none",
      marginTop: "-10px",
      display: "inline-block"
    }
  })(StepConnector);

  const [globalAccountState] = useContext(StateContext);
  const [tireDetails, setTireDetails] = useState({
    brandNo: "",
    casingValue: 0,
    comments: "",
    compoundId: 0,
    costAdjustment: 0,
    currentHours: 0,
    currentMiles: 0,
    currentTreadDepth: 0,
    dispositionId: 8,
    evaluationNo: null,
    fillCost: 0,
    fillTypeId: 0,
    imagesLocation: "",
    imgsrc: [],
    indCodeId: 0,
    insideTread: 0,
    isMountToRim: false,
    loadRatingId: 0,
    locationId: accountInfoIdsFormatter(globalAccountState).locationIds[0],
    lotNo: null,
    manufacturerId: 0,
    middleTread: null,
    mileageType: "1",
    mountStatus: "Not Mounted",
    mountedRimSerialNo: "",
    netCost: 0,
    originalTread: 0,
    outsideTread: 0,
    parentAccountId: accountInfoIdsFormatter(globalAccountState).accountIds.toString(),
    percentageWorn: 0,
    plyId: 0,
    poNo: null,
    purchaseCost: 0,
    purchasedTread: 0,
    registeredDate: new Date().toISOString(),
    removeAt: 0,
    repairCost: 0,
    repairCount: 0,
    retreadCost: 0,
    retreadCount: 0,
    sizeId: 0,
    soldAmount: 0,
    speedRatingId: 0,
    starRatingId: 0,
    tireStatusId: 7,
    tireSerialNo: "",
    typeId: 0,
    vehicleSelected: "",
    warrantyAdjustment: 0,
    wheelPosition: ""
  });

  const [sizesByManuf, setSizesByManuf] = useState([]);
  const [typesBySize, setTypesBySize] = useState([]);
  const [vacantRims, setVacantRims] = useState([]);
  const [currSize, setCurrSize] = useState({});
  const [otherManuf, setOtherManuf] = useState({});
  const [otherSize, setOtherSize] = useState({});
  const [otherType, setOtherType] = useState({});
  const [tireMasterDetails, setTireMasterDetails] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [tireImages, setTireImages] = useState([]);
  const [storedImages, setStoredImages] = useState([]);

  //API Calls
  const headerApiObj = new HeaderApi();
  const manageRimApiObj = new ManageRimListApis();
  const [accLocationDetails, setAccLocationDetails] = useState({});
  const [userPreferences, setUserPreferences] = useState({});
  const [masterData, setMasterData] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);
  const [showClonedNotification, setClonedNotification] = useState(false);
  const [showCreateClonedNotif, setCreateClonedNotif] = useState(false);
  const [showEditClonedNotif, setEditClonedNotif] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [temptireserial, setTempTireSerial] = useState("");
  const [tempCloneSerial, setTempCloneSerial] = useState("");
  const [isTireSerialEdited, setIsTireSerialEdited] = useState(false);
  const [isRegisterDateEdited, setIsRegisterDateEdited] = useState(false);
  const [isCurrentHoursEdited, setIsCurrentHoursEdited] = useState(false);
  const [isCurrentMilesEdited, setIsCurrentMilesEdited] = useState(false);
  const [isManufacturerEdited, setIsManufacturerEdited] = useState(false);
  const [isNewManufacturerNameEdited, setIsNewManufacturerNameEdited] = useState(false);
  const [isTypeEdited, setIsTypeEdited] = useState(false);
  const [isNewTypeNameEdited, setIsNewTypeNameEdited] = useState(false);
  const [isSizeEdited, setIsSizeEdited] = useState(false);
  const [isNewSizeNameEdited, setIsNewSizeNameEdited] = useState(false);
  const [isOriginalTreadEdited, setIsOriginalTreadEdited] = useState(false);
  const [isRemoveAtEdited, setIsRemoveAtEdited] = useState(false);
  const [isOutsideTreadEdited, setIsOutsideTreadEdited] = useState(false);
  const [isInsideTreadEdited, setIsInsideTreadEdited] = useState(false);
  const [isMiddleTreadEdited, setIsMiddleTreadEdited] = useState(false);

  const initNotification = () => {
    if (locationObj.state && locationObj.state.updatedSection) {
      if (locationObj.state.updatedSection === "successQuickInspect") {
        if (locationObj.state.updateStatus) {
          setShowNotification(true);
        }
      } else {
        setTimeout(() => {
          setShowNotification(false);
          setFlashMessageState("");
        }, 5000);
      }
      setFlashMessageState(locationObj);
    }
  };

  const resetTextFieldIsEditState = () => {
    setIsTireSerialEdited(false);
    setIsRegisterDateEdited(false);
    setIsCurrentHoursEdited(false);
    setIsCurrentMilesEdited(false);
    setIsNewManufacturerNameEdited(false);
    setIsNewSizeNameEdited(false);
    setIsNewTypeNameEdited(false);
    setIsOriginalTreadEdited(false);
    setIsRemoveAtEdited(false);
    setIsOutsideTreadEdited(false);
    setIsInsideTreadEdited(false);
    setIsMiddleTreadEdited(false);
    setIsManufacturerEdited(false);
    setIsTypeEdited(false);
    setIsSizeEdited(false);

  }
  const getTireByID = (masterdata) => {
    headerApiObj.getTireById(locationObj.state.tireId).then((tireData) => {
      if (tireData.model) {
        setTempTireSerial(tireData.model.tireSerialNo);
        let arrImgs = [];
        if (tireData.model.imagesLocation !== "" && tireData.model.imagesLocation) {
          const arr = tireData.model.imagesLocation.split(",");
          arrImgs = arr.filter((img) => img !== "");
        }
        setStoredImages(arrImgs);

        const mydate = new Date(tireData.model.registeredDate);
        const newdatestr = `${mydate.getMonth() + 1}/${mydate.getDate()}/${mydate.getFullYear()}`;

        const tireDetailsObj = { ...tireData.model };
        const manufindex = masterdata.model.tireManufacturers.findIndex((mnf) => mnf.manufacturerId === tireData.model.manufacturerId);

        if (manufindex !== -1) {
          const tsbm = [];
          masterdata.model.tireSizes.forEach((size) => {
            if (size.tireManufacturerId === tireData.model.manufacturerId) {
              tsbm.push(size);
            }
            if (size.tireSizeName.trim().toLowerCase() === "other" && masterdata.model.tireManufacturers[manufindex].manufacturerName.trim().toLowerCase() !== "other") {
              tsbm.push(size);
            }
          });
          setSizesByManuf(tsbm);

          const selectedsizeindex = masterdata.model.tireSizes.findIndex((size) => size.tireSizeId === tireData.model.sizeId);

          if (selectedsizeindex !== -1) {
            if (masterdata.model.tireSizes[selectedsizeindex].tireSizeName.includes("R")) {
              setTireRadial(true);
            }
            setCurrSize(masterdata.model.tireSizes[selectedsizeindex]);

            const ttbs = [];
            masterdata.model.tireTypes.forEach((type) => {
              if (type.tireSizeId === tireData.model.sizeId && type.tireManufacturerId === tireData.model.manufacturerId) {
                ttbs.push(type);
              }
              if (type.typeName.trim().toLowerCase() === "other" && masterdata.model.tireSizes[selectedsizeindex].tireSizeName.trim().toLowerCase() !== "other") {
                ttbs.push(type);
              }
            });
            setTypesBySize(ttbs);

            const selectedtypeindex = masterdata.model.tireTypes.findIndex((type) => type.typeId === tireData.model.typeId);

            if (selectedtypeindex === -1) {
              tireDetailsObj.typeId = 0;
            }
          } else {
            tireDetailsObj.sizeId = 0;
            tireDetailsObj.typeId = 0;
          }
        } else {
          tireDetailsObj.manufacturerId = 0;
          tireDetailsObj.sizeId = 0;
          tireDetailsObj.typeId = 0;
        }
        setTrackingMethod(tireData.model.mileageType);

        if (locationObj.state.toBeCloned) {
          setClonedClicked(true);
          setTireDetails({
            ...tireDetailsObj,
            tireSerialNo: "",
            imgsrc: [],
            registeredDate: newdatestr,
            brandNo: "",
            dispositionId: 8,
            isMountToRim: false,
            mountedRimId: undefined,
            mountedRimSerialNo: "",
            tireGraphData: undefined,
            tireHistory: [],
            tireId: undefined,
            vehicleNumber: "",
            vehicleId: null,
            wheelPosition: ""
          });
          setClonedNotification(true);
        } else {
          setTireDetails({
            ...tireDetailsObj,
            imgsrc: [],
            registeredDate: newdatestr
          });
        }
        initPath(tireData.model.tireId, tireData.model.dispositionId);
      }
    });
  };
  useEffect(() => {
    setShowSpinner(true);
    const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    const { accountIds, locationIds } = accountInfoObject;
    const userPreference = globalAccountState.accountInfo.userSetting;
    setUserPreferences(userPreference);

    manageRimApiObj.GetRimsByAccount(accountIds[0]).then(response => {
      if (response.model) {
        const filteredRimList = response.model.filter((rim) => rim.locationId === locationIds[0]);
        setVacantRims(filteredRimList);
      }
    });
    let locationSelected;
    if (locationObj.state && locationObj.state.fromPath === "/search-install-tires") {
      locationSelected = locationObj.state.locationId;
    } else {
      locationSelected = locationIds[0];
    }
    headerApiObj.getLocationById(locationSelected).then(locationData => {
      if (locationData.model) {
        if (locationObj.state && locationObj.state.tireId === undefined) {
          const locationJson = { ...tireDetails };
          locationJson.locationId = locationData.model.locationId;
          locationJson.parentAccountId = locationData.model.parentAccountId;
          locationJson.removeAt = locationData.model.defaultRemovalTreadDepth;
          setTireDetails(locationJson);
        }
        setAccLocationDetails(locationData.model);
      }
    });

    headerApiObj.getMasterDataWeb().then(masterdata => {
      if (masterdata.model !== undefined) {
        setMasterData(masterdata.model);
      }
      //initialization for edit tire
      if (locationObj.state) {
        if (locationObj.state.tireId !== undefined) {
          getTireByID(masterdata);
        } else {
          if (locationObj.state.fromPath === "/search-install-tires") {
            setTireDetails({
              brandNo: "",
              casingValue: 0,
              comments: "",
              compoundId: 0,
              costAdjustment: 0,
              currentHours: 0,
              currentMiles: 0,
              currentTreadDepth: 0,
              dispositionId: 8,
              evaluationNo: null,
              fillCost: 0,
              fillTypeId: 0,
              imagesLocation: "",
              imgsrc: [],
              indCodeId: 0,
              insideTread: 0,
              isMountToRim: false,
              loadRatingId: 0,
              locationId: locationObj.state.locationId,
              lotNo: null,
              manufacturerId: 0,
              middleTread: null,
              mileageType: "1",
              mountStatus: "Not Mounted",
              mountedRimSerialNo: "",
              netCost: 0,
              originalTread: 0,
              outsideTread: 0,
              parentAccountId: accountInfoIdsFormatter(globalAccountState).accountIds.toString(),
              percentageWorn: 0,
              plyId: 0,
              poNo: null,
              purchaseCost: 0,
              purchasedTread: 0,
              registeredDate: new Date().toISOString(),
              removeAt: 0,
              repairCost: 0,
              repairCount: 0,
              retreadCost: 0,
              retreadCount: 0,
              sizeId: 0,
              soldAmount: 0,
              speedRatingId: 0,
              starRatingId: 0,
              tireStatusId: 7,
              tireSerialNo: "",
              typeId: 0,
              vehicleSelected: "",
              warrantyAdjustment: 0,
              wheelPosition: ""
            });
            setTempTireSerial("");
            setStoredImages([]);
            setTrackingMethod(1);
            setClonedClicked(false);

            initPath(locationObj.state.tireId, 0);
          }
        }
      } else {
        setTireDetails({
          brandNo: "",
          casingValue: 0,
          comments: "",
          compoundId: 0,
          costAdjustment: 0,
          currentHours: 0,
          currentMiles: 0,
          currentTreadDepth: 0,
          dispositionId: 8,
          evaluationNo: null,
          fillCost: 0,
          fillTypeId: 0,
          imagesLocation: "",
          imgsrc: [],
          indCodeId: 0,
          isMountToRim: false,
          loadRatingId: 0,
          locationId: accountInfoIdsFormatter(globalAccountState).locationIds[0],
          lotNo: null,
          manufacturerId: 0,
          middleTread: null,
          mileageType: "1",
          mountStatus: "Not Mounted",
          mountedRimSerialNo: "",
          netCost: 0,
          originalTread: 0,
          removeAt: 0,
          purchasedTread: 0,
          outsideTread: 0,
          insideTread: 0,
          parentAccountId: accountInfoIdsFormatter(globalAccountState).accountIds.toString(),
          percentageWorn: 0,
          plyId: 0,
          poNo: null,
          purchaseCost: 0,
          registeredDate: new Date().toISOString(),
          repairCost: 0,
          repairCount: 0,
          retreadCost: 0,
          retreadCount: 0,
          sizeId: 0,
          soldAmount: 0,
          speedRatingId: 0,
          starRatingId: 0,
          tireStatusId: 7,
          tireSerialNo: "",
          typeId: 0,
          vehicleSelected: "",
          warrantyAdjustment: 0,
          wheelPosition: ""
        });
        setTempTireSerial("");
        setStoredImages([]);
        setTrackingMethod(1);
        setClonedClicked(false);

        initPath(locationObj.state.tireId, 0);
      }
    });
    setTimeout(() => {
      setShowSpinner(false);
    }, 3500);
    initNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, locationObj]);

  const initPath = (id, disp) => {
    if (locationObj.state && locationObj.state.fromPath) {
      setPrevPath(locationObj.state.fromPath);
      if (locationObj.state.fromPath === "/manage-tire") {
        setHistoryState({ dispositionId: disp });
      } else if (locationObj.state.fromPath === "/view-tire") {
        setHistoryState({ tireId: id });
      } else {
        if (locationObj.state.fromPath === "/search-install-tires") {
          setHistoryState({
            id: locationObj.state.id,
            position: locationObj.state.position,
            locationId: locationObj.state.locationId
          });
        }
      }
    }
  };

  // cloned Notifications
  const [tireClonedEditNotif, setTireClonedEditNotif] = useState({
    notifmessage: notificationTexts.EDITEDANDCLONED,
    notifdesc: notificationTexts.UPDATESUCCESS.replace("#1#1", temptireserial)
  });

  const [tireClonedCreateNotif, setTireClonedCreateNotif] = useState({
    notifmessage: notificationTexts.CREATEDANDCLONED,
    notifdesc: notificationTexts.CREATIONSUCCESS.replace("#1#1", temptireserial)
  });

  const tireSerialNoNotification = tempCloneSerial ? tempCloneSerial : temptireserial

  useEffect(() => {
    setTireClonedEditNotif({
      notifmessage: notificationTexts.EDITEDANDCLONED,
      notifdesc: notificationTexts.UPDATESUCCESS.replace("#1#1", tireSerialNoNotification)
    });

    setTireClonedCreateNotif({
      notifmessage: notificationTexts.CREATEDANDCLONED,
      notifdesc: notificationTexts.CREATIONSUCCESS.replace("#1#1", tireSerialNoNotification)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temptireserial, tempCloneSerial]);

  // Step handling
  const styling = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const steps = getsteps();

  function getsteps() {
    return [
      globalCreateTireTexts.IDENTIFICATION,
      globalCreateTireTexts.DESCRIPTION,
      userPreferences.userMeasurementSystemValue === "imperial" ? globalCreateTireTexts.TREADDEPTH32 : globalCreateTireTexts.TREADDEPTHMM,
      globalCreateTireTexts.TIRECOSTS
    ];
  }

  const getTotalSteps = () => steps.length;

  const handleNextStep = () => {
    resetTextFieldIsEditState();
    switch (currentStep) {
      case 0:
        if (!validateIdForm()) {
          return;
        }
        break;
      case 1:
        if (!validateDescForm()) {
          return;
        }
        break;
      case 2:
        if (!validateTdForm()) {
          return;
        }
        break;
      case 3:
        validateTotalForm();
        break;
      default:
        break;
    }

    if (currentStep !== getTotalSteps() - 1) {
      setCurrentStep((currentStep) => currentStep + 1);
      window.scrollTo(0, 0);
    } else {
      submitTireCreationForm();
    }
  };

  const handlePreviousStep = () => {
    resetTextFieldIsEditState();
    if (currentStep !== 0) {
      setCurrentStep((currentStep) => currentStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const handleStep = (step) => {
    resetTextFieldIsEditState();
    if (step <= currentStep) {
      setCurrentStep(step);
    } else {
      switch (currentStep) {
        case 0:
          if (step === 1) {
            if (!validateIdForm()) {
              return;
            }
          } else if (step === 2) {
            if (!validateIdForm() || !validateDescForm()) {
              return;
            }
          } else {
            if (!validateIdForm() || !validateDescForm() || !validateTdForm()) {
              return;
            }
          }
          break;
        case 1:
          if (step === 2) {
            if (!validateDescForm()) {
              return;
            }
          } else {
            if (step === 3) {
              if (!validateDescForm() || !validateTdForm()) {
                return;
              }
            }
          }
          break;
        case 2:
          if (!validateTdForm()) {
            return;
          }
          break;
        default:
          break;
      }
      setCurrentStep(step);
    }
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  // Handling State Change
  const handleFieldChange = (field, val) => {
    switch (field) {
      case "tireserial":
        setTireDetails({ ...tireDetails, tireSerialNo: val });
        setTempTireSerial(val);
        setIsTireSerialEdited(true);
        break;
      case "brandNo":
        setTireDetails({ ...tireDetails, brandNo: val });
        break;
      case "regDate":
        if (val !== null) {

          let date = val;
          date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          setTireDetails({ ...tireDetails, registeredDate: date });
        } else {
          setTireDetails({ ...tireDetails, registeredDate: "" });
        }
        setIsRegisterDateEdited(true);
        break;
      case "evalno":
        setTireDetails({ ...tireDetails, evaluationNo: val });
        break;
      case "lotnum":
        setTireDetails({ ...tireDetails, lotNo: val });
        break;
      case "purodr":
        setTireDetails({ ...tireDetails, poNo: val });
        break;
      case "dispositionId":
        setTireDetails({ ...tireDetails, dispositionId: Number(val) });
        break;
      case "statusId":
        setTireDetails({ ...tireDetails, tireStatusId: val });
        break;
      case "currentHours":
        setTireDetails({ ...tireDetails, currentHours: val });
        setIsCurrentHoursEdited(true);
        break;
      case "currentMiles":
        setTireDetails({ ...tireDetails, currentMiles: val });
        setIsCurrentMilesEdited(true);
        break;
      case "Both":
      case "Hours":
      case "Miles":
        setTireDetails({ ...tireDetails, mileageType: val });
        setTrackingMethod(val);
        break;
      case "rimcheckbox":
          vacantRims.forEach((element) => {
            if(element.rimId === tireDetails.mountedRimId) {
              element.isMountToTire = val;
            }
        });
        
        setTireDetails({
          ...tireDetails,
          isMountToRim: val,
          mountedRimId: "",
          mountedRimSerialNo: ""
        });

        break;
      case "rimdetails":
        {
          vacantRims.forEach((element) => {
            if(element.rimId === tireDetails.mountedRimId) {
              if (val !== tireDetails.mountedRimId) {
                element.isMountToTire = false;
              }
            }
        });

          const myrim = vacantRims.find((rim) => rim.rimId === val);
          setTireDetails({
            ...tireDetails,
            mountedRimId: myrim.rimId,
            mountedRimSerialNo: myrim.rimSerialNo
          });
        }
        break;
      case "manufacturer":
        {
          setTireDetails({
            ...tireDetails,
            manufacturerId: val,
            sizeId: 0,
            typeId: 0
          });
          setCurrSize("");
          setOtherManuf({});
          setTireMasterDetails([]);
          setOtherSizeSelected(false);
          setOtherTypeSelected(false);
          setTireRadial(false);
          setIsManufacturerEdited(true);
          const tsbm = [];

          const index = masterData.tireManufacturers.findIndex((mnf) => mnf.manufacturerId === val);

          if (masterData.tireManufacturers[index].manufacturerName.trim().toLowerCase() === "other") {
            setOtherManuSelected(true);
          } else {
            setOtherManuSelected(false);
          }
          masterData.tireSizes.forEach((size) => {
            if (size.tireSizeName.trim().toLowerCase() === "other" && masterData.tireManufacturers[index].manufacturerName.trim().toLowerCase() !== "other") {
              tsbm.push(size);
            }
            if (size.tireManufacturerId === val) {
              tsbm.push(size);
            }
          });

          setSizesByManuf(tsbm);
        }

        break;
      case "sizeid":
        {
          if (val === null) {
            setTireDetails({ ...tireDetails, sizeId: 0, typeId: 0 });
            setCurrSize("");
            setOtherSizeSelected(false);
            return;
          }

          setTireDetails({ ...tireDetails, sizeId: val.tireSizeId, typeId: 0 });
          setOtherSize({});
          setOtherTypeSelected(false);
          setOtherType({});
          setCurrSize(val);
          setIsSizeEdited(true);

          if (val.tireSizeName.trim().toLowerCase() === "other") {
            setOtherSizeSelected(true);
          } else {
            setOtherSizeSelected(false);
          }
          if (val.tireSizeName.trim().toLowerCase() !== "other" && val.tireSizeName.includes("R")) {
            setTireRadial(true);
          } else {
            setTireRadial(false);
          }

          const ttbs = [];

          // eslint-disable-next-line array-callback-return
          masterData.tireTypes.map((type) => {
            if (type.typeName.trim().toLowerCase() === "other" && val.tireSizeName.trim().toLowerCase() !== "other") {
              ttbs.push(type);
            }
            if (type.tireSizeId === val.tireSizeId && type.tireManufacturerId === val.tireManufacturerId) {
              ttbs.push(type);
            }
          });
          setTypesBySize(ttbs);
        }
        break;
      case "typeid":
        /* Adding a code for by default Tread depth */
        setIsTypeEdited(true);
        const typeNameIndex = masterData.tireTypes.findIndex((type) => type.typeId === val);
        const tireSizeIdIndex = masterData.tireSizes.findIndex((type) => type.tireSizeId === tireDetails.sizeId);
        const manufactureIdIndex = masterData.tireManufacturers.findIndex((type) => type.manufacturerId === tireDetails.manufacturerId);
        const treadDepthPayload = {
          ManufacturerName: masterData.tireManufacturers[manufactureIdIndex].manufacturerName,
          Size: masterData.tireSizes[tireSizeIdIndex].tireSizeName,
          Type: masterData.tireTypes[typeNameIndex].typeName
        };
        // setShowSpinner(true)
        setTireDetails({ ...tireDetails, typeId: val });
        const otherIndx = masterData.tireTypes.findIndex((type) => type.typeId === val);

        setOtherType({});

        if (masterData.tireTypes[otherIndx].typeName.trim().toLowerCase() === "other") {
          setOtherTypeSelected(true);
        } else {
          setOtherTypeSelected(false);
        }
        headerApiObj.getTreadDepthDefaultValue(treadDepthPayload).then((treadDepth) => {
          if (treadDepth.data === null) {
            setTireDetails({
              ...tireDetails,
              originalTread: 0,
              purchasedTread: 0,
              outsideTread: 0,
              insideTread: 0,
              middleTread: 0,
              typeId: val
            });
          } else {
            treadDepth.data.treadDepth = Math.round(treadDepth.data.treadDepth * 100) / 100;
            setTireDetails({
              ...tireDetails,
              originalTread: treadDepth.data.treadDepth,
              purchasedTread: treadDepth.data.treadDepth,
              outsideTread: treadDepth.data.treadDepth,
              insideTread: treadDepth.data.treadDepth,
              middleTread: treadDepth.data.treadDepth,
              typeId: val
            });
          }
        });

        break;
      case "star":
        setTireDetails({ ...tireDetails, starRatingId: val, plyId: 0 });
        break;
      case "ply":
        setTireDetails({ ...tireDetails, plyId: val, starRatingId: 0 });
        break;
      case "indcode":
        setTireDetails({ ...tireDetails, indCodeId: val });
        break;
      case "compound":
        setTireDetails({ ...tireDetails, compoundId: val });
        break;
      case "loadrtg":
        setTireDetails({ ...tireDetails, loadRatingId: val });
        break;
      case "speed":
        setTireDetails({ ...tireDetails, speedRatingId: val });
        break;
      case "images":
        if (storedImages.length + tireDetails.imgsrc.length < 3) {
          if (val.files[0]) {
            const reader = new FileReader();
            reader.readAsDataURL(val.files[0]);
            reader.onloadend = () => {
              const imageSet = [...tireDetails.imgsrc];
              imageSet.push(reader.result);
              setTireDetails({ ...tireDetails, imgsrc: imageSet });

              const newImageSet = [...tireImages];
              newImageSet.push(val.files[0]);
              setTireImages(newImageSet);

              val.value = null;
            };
          }
        } else {
          setImgHelper(validationTexts.IMGLIMIT);
        }
        break;
      case "orgTread":
        if (tireDetails.tireStatusId === 7) {
          setTireDetails({
            ...tireDetails,
            originalTread: val,
            purchasedTread: val
          });
        } else {
          setTireDetails({ ...tireDetails, originalTread: val });
        }
        setIsOriginalTreadEdited(true);
        break;
      case "removeat":
        setTireDetails({ ...tireDetails, removeAt: val });
        setIsRemoveAtEdited(true);
        break;
      case "purtrd":
        if (tireDetails.tireStatusId === 7) {
          setTireDetails({
            ...tireDetails,
            originalTread: val,
            purchasedTread: val
          });
        } else {
          setTireDetails({ ...tireDetails, purchasedTread: val });
        }
        break;
      case "outsidetrd":
        setTireDetails({ ...tireDetails, outsideTread: val });
        setIsOutsideTreadEdited(true);
        break;
      case "insidetrd":
        setTireDetails({ ...tireDetails, insideTread: val });
        setIsInsideTreadEdited(true);
        break;
      case "mdltrd":
        setTireDetails({ ...tireDetails, middleTread: val });
        setIsMiddleTreadEdited(true);
        break;
      case "filltype":
        setTireDetails({ ...tireDetails, fillTypeId: val });
        break;
      case "purCost":
        setTireDetails({ ...tireDetails, purchaseCost: val });
        break;
      case "casingval":
        setTireDetails({ ...tireDetails, casingValue: val });
        break;
      case "fillcost":
        setTireDetails({ ...tireDetails, fillCost: val });
        break;
      case "warntyadj":
        setTireDetails({ ...tireDetails, warrantyAdjustment: val });
        break;
      case "costadj":
        setTireDetails({ ...tireDetails, costAdjustment: val });
        break;
      case "soldamt":
        setTireDetails({ ...tireDetails, soldAmount: val });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const outside = isNaN(parseInt(tireDetails.outsideTread, 10)) || tireDetails.outsideTread === null ? 0 : parseInt(tireDetails.outsideTread, 10);
    const inside = isNaN(parseInt(tireDetails.insideTread, 10)) || tireDetails.insideTread === null ? 0 : parseInt(tireDetails.insideTread, 10);
    const middle = isNaN(parseInt(tireDetails.middleTread, 10)) || tireDetails.middleTread === null ? 0 : parseInt(tireDetails.middleTread, 10);

    if (accLocationDetails.noOfTreadDepthReadings === 3) {
      let result;
      let worn;
      if (accLocationDetails.treadDepthMethodId === 1) {
        result = (outside + inside + middle) / 3;
        if (!Number.isInteger(result)) {
          result = result.toFixed(2);
        }
      } else if (accLocationDetails.treadDepthMethodId === 2) {
        result = Math.max(outside, inside, middle);
      } else {
        result = Math.min(outside, inside, middle);
      }
      worn = Math.round(((parseInt(tireDetails.originalTread, 10) - result) / (parseInt(tireDetails.originalTread, 10) - parseInt(tireDetails.removeAt, 10))) * 100);
      setTireDetails({
        ...tireDetails,
        currentTreadDepth: result,
        percentageWorn: worn
      });
    } else if (accLocationDetails.noOfTreadDepthReadings === 2) {
      let result;
      let worn;
      if (accLocationDetails.treadDepthMethodId === 1) {
        result = (outside + inside) / 2;
        if (!Number.isInteger(result)) {
          result = result.toFixed(2);
        }
      } else if (accLocationDetails.treadDepthMethodId === 2) {
        result = Math.max(outside, inside);
      } else {
        result = Math.min(outside, inside);
      }
      worn = Math.round(((parseInt(tireDetails.originalTread, 10) - result) / (parseInt(tireDetails.originalTread, 10) - parseInt(tireDetails.removeAt, 10))) * 100);
      setTireDetails({
        ...tireDetails,
        currentTreadDepth: result,
        percentageWorn: worn
      });
    } else {
      let worn;
      worn = Math.round(((parseInt(tireDetails.originalTread, 10) - outside) / (parseInt(tireDetails.originalTread, 10) - parseInt(tireDetails.removeAt, 10))) * 100);
      setTireDetails({
        ...tireDetails,
        currentTreadDepth: outside,
        percentageWorn: worn
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tireDetails.outsideTread, tireDetails.insideTread, tireDetails.middleTread, tireDetails.removeAt, tireDetails.originalTread, accLocationDetails]);

  const handleTireMasterDataChange = (field, val) => {
    let date = new Date();
    date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    let newData = {};
    switch (field) {
      case "otherManuf":
        newData = {
          requestDate: date,
          fieldId: 6,
          description: val,
          status: "New"
        };
        setOtherManuf(newData);
        setIsNewManufacturerNameEdited(true);
        break;
      case "otherSize":
        newData = {
          requestDate: date,
          fieldId: 7,
          description: val,
          status: "New"
        };
        setOtherSize(newData);
        setIsNewSizeNameEdited(true);
        break;
      case "otherType":
        newData = {
          requestDate: date,
          fieldId: 8,
          description: val,
          status: "New"
        };
        setOtherType(newData);
        setIsNewTypeNameEdited(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setTireMasterDetails([otherManuf, otherSize, otherType]);
  }, [otherManuf, otherSize, otherType]);

  //tireImages
  const deleteStoredImage = (index) => {
    const imgsArr = [...storedImages];
    imgsArr.splice(index, 1);
    setStoredImages(imgsArr);
    setImgHelper("");
  };

  const deleteImage = (index) => {
    const imgsArr = [...tireDetails.imgsrc];
    const imgfiles = [...tireImages];
    imgsArr.splice(index, 1);
    imgfiles.splice(index, 1);
    setTireImages(imgfiles);
    setTireDetails({ ...tireDetails, imgsrc: imgsArr });
    setImgHelper("");
  };
  //calculate net Costs
  useEffect(() => {
    let Cost = 0;
    // eslint-disable-next-line array-callback-return
    Object.keys(tireDetails).forEach((key) => {
      if (String(tireDetails[key]) !== "") {
        if (key === "purchaseCost" || key === "fillCost" || key === "repairCost" || key === "retreadCost") {
          Cost = Cost + (tireDetails[key] !== null ? parseInt(tireDetails[key], 10) : 0);
        }
        if (key === "casingValue" || key === "soldAmount" || key === "costAdjustment" || key === "warrantyAdjustment") {
          Cost = Cost - (tireDetails[key] !== null ? parseInt(tireDetails[key], 10) : 0);
        }
      }
    });

    if (Number.isInteger(Cost)) {
      setTotalCost(Cost);
    } else {
      setTotalCost(Cost.toFixed(2));
    }
  }, [tireDetails]);

  //Validations and Errors

  const validateIdForm = () => {
    let isformvalid = true;
    const FormErrors = {};

    Object.keys(tireDetails).forEach((key) => {
      if (key === "tireSerialNo" && String(tireDetails[key]) === "") {
        FormErrors[key] = validationTexts.MISSINGFIELD;
        isformvalid = false;
      }
      if (key === "registeredDate" && (isNaN(parseInt(tireDetails[key], 10)) || String(tireDetails[key]) === "")) {
        FormErrors[key] = `This field is required with format ${commonUtils.getDateFormat()}`;
        isformvalid = false;
      }
      if (key === "currentHours" && (trackingMethod === 3 || trackingMethod === 1) && (parseInt(tireDetails[key], 10) < 0 || String(tireDetails[key]) === "")) {
        FormErrors[key] = validationTexts.CURRENTHOURSMILES;
        isformvalid = false;
      }
      if (key === "currentMiles" && (trackingMethod === 3 || trackingMethod === 2)) {
        if (parseInt(tireDetails[key], 10) < 0 || String(tireDetails[key]) === "") {
          FormErrors[key] = validationTexts.CURRENTHOURSMILES;
          isformvalid = false;
        }
      }
      if (key === "mountedRimId" && tireDetails.isMountToRim && String(tireDetails[key]) === "") {
        FormErrors[key] = validationTexts.MISSINGFIELD;
        isformvalid = false;
      }

      return true;
    });

    setIdFormErrors(FormErrors);
    return isformvalid;
  };

  //DescForm
  const [descFormErrors, setDescFormErrors] = useState({});
  const [isOtherManuSelected, setOtherManuSelected] = useState(false);
  const [isOtherSizeSelected, setOtherSizeSelected] = useState(false);
  const [isOtherTypeSelected, setOtherTypeSelected] = useState(false);
  const [isTireRadial, setTireRadial] = useState(false);
  const [imgHelper, setImgHelper] = useState("");

  const validateDescForm = () => {
    let isDescFormValid = true;
    const FormErrors = {};

    // eslint-disable-next-line array-callback-return
    Object.keys(tireDetails).forEach((key) => {
      if (key === "manufacturerId" || key === "sizeId" || key === "typeId") {
        if (key !== "sizeId" && tireDetails[key] === 0) {
          isDescFormValid = false;
          FormErrors[key] = validationTexts.MISSINGFIELD;
        }
        if (key === "sizeId") {
          if (tireDetails[key] === 0 || (Object.keys(currSize).length === 0 && currSize.constructor === Object)) {
            isDescFormValid = false;
            FormErrors[key] = validationTexts.MISSINGFIELD;
          }
        }
      }
    });

    let imglocstr = "";
    const imglocarr = [...storedImages];
    imglocarr.forEach((imgloc, i) => {
      if (i === 0) {
        imglocstr = imglocstr + imgloc;
      } else {
        imglocstr = `${imglocstr},${imgloc}`;
      }
    });
    setTireDetails({ ...tireDetails, imagesLocation: imglocstr });

    setDescFormErrors(FormErrors);
    return isDescFormValid;
  };

  //tread-depth-form
  const [tdFormErrors, setTdFormErrors] = useState({});

  const validateTdForm = () => {
    let isValid = true;
    const FormErrors = {};

    // eslint-disable-next-line array-callback-return
    Object.keys(tireDetails).forEach((key) => {
      if (key === "originalTread" || key === "removeAt" || key === "outsideTread") {
        if (String(tireDetails[key]) === "") {
          isValid = false;
          FormErrors[key] = validationTexts.MISSINGFIELD;
        }
        if (key === "removeAt" && tireDetails[key] < 0) {
          isValid = false;
          FormErrors[key] = validationTexts.MINREMOVEAT;
        }
        if (key === "originalTread" && (tireDetails[key] <= 0 || tireDetails[key] <= parseInt(tireDetails.removeAt, 10))) {
          isValid = false;
          FormErrors[key] = validationTexts.MINORGTREAD;
        }
        if (key === "outsideTread") {
          if (parseInt(tireDetails[key], 10) > parseInt(tireDetails.originalTread, 10)) {
            isValid = false;
            FormErrors[key] = validationTexts.MAXTREAD;
          }
          if (parseInt(tireDetails[key], 10) < parseInt(tireDetails.removeAt, 10)) {
            isValid = false;
            FormErrors[key] = validationTexts.MINTREAD;
          }
        }
      }
      if ((accLocationDetails.noOfTreadDepthReadings === 2 || accLocationDetails.noOfTreadDepthReadings === 3) && key === "insideTread") {
        if (String(tireDetails[key]) === "" || tireDetails[key] === null) {
          isValid = false;
          FormErrors[key] = validationTexts.MISSINGFIELD;
        }
        if (parseInt(tireDetails[key], 10) > parseInt(tireDetails.originalTread, 10)) {
          isValid = false;
          FormErrors[key] = validationTexts.MAXTREAD;
        }
        if (parseInt(tireDetails[key], 10) < parseInt(tireDetails.removeAt, 10)) {
          isValid = false;
          FormErrors[key] = validationTexts.MINTREAD;
        }
      }
      if (accLocationDetails.noOfTreadDepthReadings === 3 && key === "middleTread") {
        if (String(tireDetails[key]) === "" || tireDetails[key] === null) {
          isValid = false;
          FormErrors[key] = validationTexts.MISSINGFIELD;
        }
        if (parseInt(tireDetails[key], 10) > parseInt(tireDetails.originalTread, 10)) {
          isValid = false;
          FormErrors[key] = validationTexts.MAXTREAD;
        }
        if (parseInt(tireDetails[key], 10) < parseInt(tireDetails.removeAt, 10)) {
          isValid = false;
          FormErrors[key] = validationTexts.MINTREAD;
        }
      }
    });
    setTdFormErrors(FormErrors);
    return isValid;
  };

  const validateTotalForm = () => {
    let masterTireArr = tireMasterDetails;
    // eslint-disable-next-line array-callback-return
    tireMasterDetails.forEach((obj, key) => {
      if (obj.description === "" || !obj.description) masterTireArr.splice(key, 1);
    });
    setTireMasterDetails(masterTireArr);

    setTireDetails({
      ...tireDetails,
      originalTread: parseInt(tireDetails.originalTread, 10),
      purchasedTread: parseInt(tireDetails.purchasedTread, 10),
      removeAt: parseInt(tireDetails.removeAt, 10),
      outsideTread: parseInt(tireDetails.outsideTread, 10),
      insideTread: parseInt(tireDetails.insideTread, 10),
      middleTread: parseInt(tireDetails.middleTread, 10),
      casingValue: parseInt(tireDetails.casingValue, 10),
      purchaseCost: parseInt(tireDetails.purchaseCost, 10),
      fillCost: parseInt(tireDetails.fillCost, 10),
      warrantyAdjustment: parseInt(tireDetails.warrantyAdjustment, 10),
      costAdjustment: parseInt(tireDetails.costAdjustment, 10),
      soldAmount: parseInt(tireDetails.soldAmount, 10)
    });
  };

  const [create, setCreate] = useState(0);
  window.localStorage.setItem("Count", create)

  function submitTireCreationForm() {
    setCreate(create + 1)
    setShowSpinner(true);
    validateTotalForm();
    resetTextFieldIsEditState();
    let tireidSuccess;
    if (validateIdForm() && validateDescForm() && validateTdForm()) {
      let updatedsection = "";
      let successStatus = false;
      let successmessage = "";
      let successdesc = "";
      let failuremsg = "";
      if (tireDetails.tireId) {
        updatedsection = "editoldtire";
        successmessage = notificationTexts.TIREEDITED;
        successdesc = notificationTexts.UPDATESUCCESS.replace("#1#1", tireDetails.tireSerialNo);
        failuremsg = notificationTexts.TIRENOTEDITED;
      } else {
        updatedsection = "createnewtire";
        successmessage = notificationTexts.TIRECREATED;
        successdesc = notificationTexts.CREATIONSUCCESS.replace("#1#1", tireDetails.tireSerialNo);
        failuremsg = notificationTexts.TIRENOTCREATED;
      }

      let isMasterDataEmpty = true;
      tireMasterDetails.forEach((tireMasterDetail) => {
        if (Object.keys(tireMasterDetail).length !== 0) {
          isMasterDataEmpty = false;
        }
      });

      //main API calls
      if (tireDetails.tireId) {
        //for tire alteration
        if (!isMasterDataEmpty) {
          headerApiObj.createMasterData(tireMasterDetails).then((masterResponse) => {
            if (!masterResponse.didError) {
              if (tireImages.length !== 0) {
                const timestamp = new Date().getTime();
                fileUploadFormData.append("tireId", tireDetails.tireId);
                Object.values(tireImages).forEach((img, index) => {
                  fileUploadFormData.append("imgsrc", img, `${index}_${timestamp}.png`);
                  return true;
                });

                headerApiObj.createTireImages(fileUploadFormData).then((response) => {
                  if (!response.didError) {
                    let imgstr = tireDetails.imagesLocation !== "" ? `${tireDetails.imagesLocation},${response.message}` : response.message;
                    setTireDetails({
                      ...tireDetails,
                      imagesLocation: imgstr
                    });
                    headerApiObj.editTire({ ...tireDetails, imagesLocation: imgstr }).then((editRes) => {
                      if (editRes.didError === false || !editRes.isError) {
                        successStatus = true;
                      } else {
                        successStatus = false;
                      }
                    });

                    history.push({
                      pathname: prevPath,
                      state: {
                        tireId: tireDetails.tireId,
                        dispositionId: tireDetails.dispositionId,
                        updatedSection: updatedsection,
                        updateStatus: successStatus,
                        successMessage: successmessage,
                        successMessageDesc: successdesc,
                        failureMessage: failuremsg,
                        locationId: locationObj.state.locationId ? locationObj.state.locationId : ""
                      }
                    });

                    setShowSpinner(false);
                  } else {
                    setShowSpinner(false);
                  }
                });
              } else {
                headerApiObj.editTire(tireDetails).then((response) => {
                  if (response.didError === false || !response.isError) {
                    successStatus = true;
                  } else {
                    successStatus = false;
                  }
                  setShowSpinner(false);
                  history.push({
                    pathname: prevPath,
                    state: {
                      tireId: tireDetails.tireId,
                      dispositionId: tireDetails.dispositionId,
                      updatedSection: updatedsection,
                      updateStatus: successStatus,
                      successMessage: successmessage,
                      successMessageDesc: successdesc,
                      failureMessage: failuremsg,
                      locationId: locationObj.state.locationId ? locationObj.state.locationId : ""
                    }
                  });
                });
              }
            }
          });
        } else {
          if (tireImages.length !== 0) {
            const timestamp = new Date().getTime();
            fileUploadFormData.append("tireId", tireDetails.tireId);
            Object.values(tireImages).forEach((img, index) => {
              fileUploadFormData.append("imgsrc", img, `${index}_${timestamp}.png`);
              return true;
            });

            headerApiObj.createTireImages(fileUploadFormData).then((response) => {
              if (response.didError === false) {
                const imgstr = tireDetails.imagesLocation !== "" ? `${tireDetails.imagesLocation},${response.message}` : response.message;
                setTireDetails({ ...tireDetails, imagesLocation: imgstr });
                headerApiObj.editTire({ ...tireDetails, imagesLocation: imgstr }).then((response) => {
                  if (response.didError === false || !response.isError) {
                    successStatus = true;
                  } else {
                    successStatus = false;
                  }
                  history.push({
                    pathname: prevPath,
                    state: {
                      tireId: tireDetails.tireId,
                      dispositionId: tireDetails.dispositionId,
                      updatedSection: updatedsection,
                      updateStatus: successStatus,
                      successMessage: successmessage,
                      successMessageDesc: successdesc,
                      failureMessage: failuremsg,
                      locationId: locationObj.state.locationId ? locationObj.state.locationId : ""
                    }
                  });
                  setShowSpinner(false);
                });
              } else {
                setShowSpinner(false);
              }
            });
          } else {
            headerApiObj.editTire(tireDetails).then((response) => {
              if (response.didError === false || !response.isError) {
                successStatus = true;
              } else {
                successStatus = false;
              }
              history.push({
                pathname: prevPath,
                state: {
                  fromPath: "/edit-tire",
                  tireId: tireDetails.tireId,
                  dispositionId: tireDetails.dispositionId,
                  updatedSection: updatedsection,
                  updateStatus: successStatus,
                  successMessage: successmessage,
                  successMessageDesc: successdesc,
                  failureMessage: failuremsg,
                  locationId: locationObj.state.locationId ? locationObj.state.locationId : ""
                }
              });
            });

            setShowSpinner(false);
          }
        }
      } else {
        //for tire creation
        headerApiObj.createTire(tireDetails).then((response) => {
          if ((response.didError !== undefined && !response.didError) || !response.isError) {
            successStatus = true;
            tireidSuccess = response.insertedId;

            if (!isMasterDataEmpty) {
              headerApiObj.createMasterData(tireMasterDetails).then((masterResponse) => {
                if (!masterResponse.didError) {
                  if (tireImages.length !== 0) {
                    const timestamp = new Date().getTime();

                    fileUploadFormData.append("tireId", response.insertedId);
                    Object.values(tireImages).forEach((img, index) => {
                      fileUploadFormData.append("imgsrc", img, `${index}_${timestamp}.png`);
                      return true;
                    });

                    headerApiObj.createTireImages(fileUploadFormData).then((apiRes) => {
                      if (!apiRes.didError) {
                        setTireImages([]);
                      }
                    });
                  }

                  setShowSpinner(false);
                  history.push({
                    pathname: prevPath,
                    state: {
                      fromPath: "/createtire",
                      id: locationObj.state.id,
                      position: locationObj.state.position,
                      dispositionId: 8,
                      updatedSection: updatedsection,
                      updateStatus: successStatus,
                      successMessage: successmessage,
                      successMessageDesc: successdesc,
                      failureMessage: failuremsg,
                      tireId: tireidSuccess,
                      locationId: locationObj.state.locationId ? locationObj.state.locationId : ""
                    }
                  });
                  isMasterDataEmpty = true;
                }
              });
            } else {
              if (tireImages.length !== 0) {
                let timestamp = new Date().getTime();
                Object.values(tireImages).forEach((img, index) => {
                  fileUploadFormData.append("imgsrc", img, `${index}_${timestamp}.png`);
                  return true;
                });
                fileUploadFormData.append("tireId", response.insertedId);
                headerApiObj.createTireImages(fileUploadFormData).then((imageResponse) => {
                  if (!imageResponse.didError) {
                    setTireImages([]);

                    history.push({
                      pathname: prevPath,
                      state: {
                        fromPath: "/createtire",
                        id: locationObj.state.id,
                        position: locationObj.state.position,
                        dispositionId: 8,
                        updatedSection: updatedsection,
                        updateStatus: successStatus,
                        successMessage: successmessage,
                        successMessageDesc: successdesc,
                        failureMessage: failuremsg,
                        tireId: tireidSuccess,
                        locationId: locationObj.state.locationId ? locationObj.state.locationId : ""
                      }
                    });
                  }
                });
              }
            }

            if (isMasterDataEmpty && tireImages.length === 0) {
              setShowSpinner(false);
              history.push({
                pathname: prevPath,
                state: {
                  fromPath: "/createtire",
                  id: locationObj.state.id,
                  position: locationObj.state.position,
                  dispositionId: 8,
                  updatedSection: updatedsection,
                  updateStatus: successStatus,
                  successMessage: successmessage,
                  successMessageDesc: successdesc,
                  failureMessage: failuremsg,
                  tireId: tireidSuccess,
                  locationId: locationObj.state.locationId ? locationObj.state.locationId : ""
                }
              });
            }
            //paste redirection here(maybe)
          } else {
            if (response.message === "A tire with the entered serial number already exists in this account") {
              handleStep(0);
              setIdFormErrors({
                ...idFormErrors,
                tireSerialNo: validationTexts.TIREEXISTS
              });
            }
            setShowSpinner(false);
          }
        });
      }
    }
  }

  const handleCloneClick = () => {
    setTempCloneSerial(temptireserial)
    validateTotalForm();
    setShowSpinner(true);
    setCreateClonedNotif(false);
    setEditClonedNotif(false);
    resetTextFieldIsEditState();

    if (validateIdForm() && validateDescForm() && validateTdForm()) {
      let successStatus = false;

      let isMasterDataEmpty = true;
      for (const element of tireMasterDetails) {
        if (Object.keys(element).length !== 0) {
          isMasterDataEmpty = false;
          break;
        }
      }

      if (tireDetails.tireId) {
        //for tire alteration and cloning
        if (!isMasterDataEmpty) {
          headerApiObj.createMasterData(tireMasterDetails).then((masterResponse) => {
            if (!masterResponse.didError) {
              if (tireImages.length !== 0) {
                const timestamp = new Date().getTime();
                fileUploadFormData.append("tireId", tireDetails.tireId);
                Object.values(tireImages).forEach((img, index) => {
                  fileUploadFormData.append("imgsrc", img, `${index}_${timestamp}.png`);
                  return true;
                });

                headerApiObj.createTireImages(fileUploadFormData).then((response) => {
                  if (!response.didError) {
                    const imgstr = `${tireDetails.imagesLocation},${response.message}`;
                    headerApiObj.editTire({ ...tireDetails, imagesLocation: imgstr }).then((editResponse) => {
                      if (editResponse.didError === false || !editResponse.isError) {
                        successStatus = true;
                      } else {
                        successStatus = false;
                      }
                    });

                    setClonedClicked(true);
                    setEditClonedNotif(successStatus);
                    setTireDetails({
                      ...tireDetails,
                      imagesLocation: imgstr,
                      isMountToRim: false,
                      mountedRimId: undefined,
                      mountedRimSerialNo: "",
                      tireSerialNo: "",
                      brandNo: "",
                      dispositionId: 8,
                      tireGraphData: undefined,
                      tireHistory: [],
                      tireId: undefined,
                      vehicleNumber: "",
                      vehicleId: null,
                      wheelPosition: ""
                    });
                    handleStep(0);
                    setShowSpinner(false);
                  }
                });
              } else {
                headerApiObj
                  .editTire({
                    ...tireDetails
                  })
                  .then((editResponse) => {
                    if (editResponse.didError === false || !editResponse.isError) {
                      successStatus = true;
                    } else {
                      successStatus = false;
                    }

                    setClonedClicked(true);
                    setTireDetails({
                      ...tireDetails,
                      brandNo: "",
                      tireSerialNo: "",
                      dispositionId: 8,
                      isMountToRim: false,
                      mountedRimId: undefined,
                      mountedRimSerialNo: "",
                      tireGraphData: undefined,
                      tireHistory: [],
                      tireId: undefined,
                      vehicleNumber: "",
                      vehicleId: null,
                      wheelPosition: ""
                    });
                    setEditClonedNotif(successStatus);
                    handleStep(0);
                    setShowSpinner(false);
                  });
              }
            }
          });
        } else {
          if (tireImages.length !== 0) {
            const timestamp = new Date().getTime();
            fileUploadFormData.append("tireId", tireDetails.tireId);
            Object.values(tireImages).forEach((img, index) => {
              fileUploadFormData.append("imgsrc", img, `${index}_${timestamp}.png`);
              return true;
            });

            headerApiObj.createTireImages(fileUploadFormData).then((response) => {
              if (response.didError === false) {
                const imgstr = `${tireDetails.imagesLocation},${response.message}`;
                setTireDetails({ ...tireDetails, imagesLocation: imgstr });
                headerApiObj.editTire({ ...tireDetails, imagesLocation: imgstr }).then((editResponse) => {
                  if (editResponse.didError === false || !editResponse.isError) {
                    successStatus = true;
                    setClonedClicked(true);
                    setEditClonedNotif(successStatus);
                    handleStep(0);
                    setTireDetails({
                      ...tireDetails,
                      tireSerialNo: "",
                      brandNo: "",
                      dispositionId: 8,
                      imagesLocation: imgstr,
                      isMountToRim: false,
                      mountedRimId: undefined,
                      mountedRimSerialNo: "",
                      tireGraphData: undefined,
                      tireHistory: [],
                      tireId: undefined,
                      vehicleNumber: "",
                      vehicleId: null,
                      wheelPosition: ""
                    });
                  } else {
                    successStatus = false;
                  }
                });
              }
            });
            setShowSpinner(false);
          } else {
            headerApiObj
              .editTire({
                ...tireDetails
              })
              .then((response) => {
                if (response.didError === false || !response.isError) {
                  successStatus = true;
                  setClonedClicked(true);
                  setEditClonedNotif(true);
                  handleStep(0);
                  setTireDetails({
                    ...tireDetails,
                    tireSerialNo: "",
                    brandNo: "",
                    dispositionId: 8,
                    isMountToRim: false,
                    mountedRimId: undefined,
                    mountedRimSerialNo: "",
                    tireGraphData: undefined,
                    tireHistory: [],
                    tireId: undefined,
                    vehicleNumber: "",
                    vehicleId: null,
                    wheelPosition: ""
                  });
                } else {
                  successStatus = false;
                }
                setShowSpinner(false);
              });
          }
        }
      } else {
        //for tire creation and cloning
        headerApiObj.createTire(tireDetails).then((response) => {
          if ((response.didError !== undefined && !response.didError) || !response.isError) {
            successStatus = true;
            if (!isMasterDataEmpty) {
              headerApiObj.createMasterData(tireMasterDetails).then((masterResponse) => {
                if (!masterResponse.didError) {
                  if (tireImages.length !== 0) {
                    const timestamp = new Date().getTime();

                    fileUploadFormData.append("tireId", response.insertedId);
                    Object.values(tireImages).forEach((img, index) => {
                      fileUploadFormData.append("imgsrc", img, `${index}_${timestamp}.png`);
                      return true;
                    });

                    headerApiObj.createTireImages(fileUploadFormData).then((_response) => {
                      //after successful api
                    });
                  }

                  setCreateClonedNotif(successStatus);

                  handleStep(0);
                  setTireDetails({
                    ...tireDetails,
                    tireSerialNo: "",
                    brandNo: ""
                  });

                  setShowSpinner(false);
                }
              });
            } else {
              if (tireImages.length !== 0) {
                let timestamp = new Date().getTime();
                Object.values(tireImages).forEach((img, index) => {
                  fileUploadFormData.append("imgsrc", img, `${index}_${timestamp}.png`);
                  return true;
                });
                fileUploadFormData.append("tireId", response.insertedId);

                headerApiObj.createTireImages(fileUploadFormData).then((_imageResponse) => {
                  setCreateClonedNotif(successStatus);
                  handleStep(0);
                  setTireDetails({
                    ...tireDetails,
                    tireSerialNo: "",
                    brandNo: ""
                  });
                  setShowSpinner(false);
                  setTimeout(() => {
                    setCreateClonedNotif(false);
                  }, 5000);
                });
              }
            }

            if (isMasterDataEmpty && tireImages.length === 0) {
              setCreateClonedNotif(successStatus);
              handleStep(0);
              setTireDetails({ ...tireDetails, tireSerialNo: "", brandNo: "" });
              setShowSpinner(false);
              setTimeout(() => {
                setCreateClonedNotif(false);
              }, 5000);
            }
          } else {
            if (response.message === "A tire with the entered serial number already exists in this account") {
              handleStep(0);
              setIdFormErrors({
                ...idFormErrors,
                tireSerialNo: validationTexts.TIREEXISTS
              });
            }
            setShowSpinner(false);
          }
        });
      }
      // set to top screen
      window.scrollTo(0, 0);
    }
  };

  //rendering children
  function getStepComponent(steps) {
    switch (steps) {
      case 0:
        return (
          <Identification
            fieldNames={tireFormCaptions.TIRESVEHICLES.TIREFORM}
            masterDataset={masterData}
            userPreferenceData={userPreferences}
            stateVar={tireDetails}
            onChangeListener={(field, val) => handleFieldChange(field, val)}
            trackBy={trackingMethod}
            onTrackByChange={(val) => setTrackingMethod(val)}
            formHelperTexts={idFormErrors}
            rimList={vacantRims}
            isTireSerialEdited={isTireSerialEdited}
            isRegisterDateEdited={isRegisterDateEdited}
            isCurrentHoursEdited = {isCurrentHoursEdited}
            isCurrentMilesEdited = {isCurrentMilesEdited}
          />
        );
      case 1:
        return (
          <Description
            fieldNames={tireFormCaptions.TIRESVEHICLES.TIREFORM}
            masterDataSet={masterData}
            stateVar={tireDetails}
            onChangeListener={(field, val) => handleFieldChange(field, val)}
            tireSizesByManuf={sizesByManuf}
            selectedSize={currSize}
            tireTypesBySize={typesBySize}
            istireRadial={isTireRadial}
            settireRadial={(val) => setTireRadial(val)}
            isManufNew={isOtherManuSelected}
            newManuf={otherManuf}
            isSizeNew={isOtherSizeSelected}
            newSize={otherSize}
            isTypeNew={isOtherTypeSelected}
            newType={otherType}
            onExtraDataChange={(field, val) => handleTireMasterDataChange(field, val)}
            dbImages={storedImages}
            deleteDbImage={deleteStoredImage}
            removeUploadedPhoto={deleteImage}
            formHelperTexts={descFormErrors}
            imageHelper={imgHelper}
            isNewManufacturerNameEdited={isNewManufacturerNameEdited}
            isNewTypeNameEdited={isNewTypeNameEdited}
            isNewSizeNameEdited={isNewSizeNameEdited}
            isManufacturerEdited={isManufacturerEdited}
            isTypeEdited={isTypeEdited}
            isSizeEdited={isSizeEdited}
          />
        );
      case 2:
        return (
          <TreadDepth
            fieldNames={tireFormCaptions.TIRESVEHICLES.TIREFORM}
            accLocationInfo={accLocationDetails}
            userPreferenceData={userPreferences}
            stateVar={tireDetails}
            onChangeListener={(field, val) => handleFieldChange(field, val)}
            formHelperTexts={tdFormErrors}
            isOriginalTreadEdited={isOriginalTreadEdited}
            isRemoveAtEdited={isRemoveAtEdited}
            isOutsideTreadEdited={isOutsideTreadEdited}
            isInsideTreadEdited={isInsideTreadEdited }
            isMiddleTreadEdited={isMiddleTreadEdited}
          />
        );
      case 3:
        return (
          <TireCosts
            fieldNames={tireFormCaptions.TIRESVEHICLES.TIREFORM}
            masterDataSet={masterData}
            accLocationInfo={accLocationDetails}
            stateVar={tireDetails}
            netCostCalculated={totalCost}
            onChangeListener={(field, val) => handleFieldChange(field, val)}
          />
        );
      default:
        return (<span>Invalid step</span>);
    }
  }
  const isForCloning = (from) => {
    switch (from) {
      case "title":
        if (locationObj.state && locationObj.state.tireId !== undefined) {
          return !isClonedClicked ? globalizationMapping.TIRES.EDITTIRE : globalHeaderTexts.CREATETIRE;
        } else {
          return globalHeaderTexts.CREATETIRE;
        }
      case "className":
        if (locationObj.state && locationObj.state.tireId !== undefined) {
          return !isClonedClicked ? styling.rootStepper2 : styling.rootStepper;
        } else {
          return styling.rootStepper;
        }

      default:
        return "";
    }
  };
  return (
    <React.Fragment>
      <div className="standalone-new-header-container">
        <div className="create-tire-main-page-container">
          <div className="my-profile-upper-deck">
            <div className="background-map-image-create-tire">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={myBreadcrumbs} />
                  <div className="scrap-tires-title">{isForCloning("title")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCreateClonedNotif && <FlashMessage status={true} message={tireClonedCreateNotif.notifmessage} description={tireClonedCreateNotif.notifdesc} />}

      {showEditClonedNotif && <FlashMessage status={true} message={tireClonedEditNotif.notifmessage} description={tireClonedEditNotif.notifdesc} />}

      {showClonedNotification && (
        <FlashMessage
          status={true}
          onClose={() => setClonedNotification(false)}
          message={notificationTexts.TIRECLONED}
          description={notificationTexts.TIRECLONEDENTERDETAILS.replace("#1#1", locationObj.state.serialTobeCloned)}
        />
      )}

      {showNotification && flashMessageState.state && (
        <FlashMessage status={flashMessageState.state.updateStatus} message={flashMessageState.state.successMessage} description={flashMessageState.state.successMessageDesc} />
      )}

      {showSpinner ? (
        <Spinner />
      ) : (
        <div className="manage-vehicle-create-container create-vehicle-form">
          <div className="create-tire-inner-container">
            <div className="step-indicator-container">
              <div className={isForCloning("className")}>
                <Stepper alternativeLabel activeStep={currentStep} connector={<ColorlibConnector />}>
                  {steps.map((label, index) => (
                    <Step key={index} className={styling.steplabels} onClick={() => handleStep(index)}>
                      <StepLabel>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>

              <div className="helper-message">
                {tireFormCaptions.MANDATORYFIELDS} <span className="required-mark">*</span>
              </div>

              <div className="step-form-container">{getStepComponent(currentStep)}</div>

              <hr className="hr-divider-action" />

              <div className="action-buttons">
                <Button className={styling.cancel} variant="contained" color="secondary" onClick={handleCancel}>
                  {tireFormCaptions.CANCEL}
                </Button>
                {currentStep > 0 && (
                  <Button className={styling.previous} variant="contained" color="secondary" onClick={() => handlePreviousStep()}>
                    {tireFormCaptions.PREVIOUS}
                  </Button>
                )}
                {currentStep === steps.length - 1 ? (
                  <React.Fragment>
                    {tireDetails.dispositionId !== 7 && 
                      <Button className={styling.previous} variant="contained" color="primary" onClick={() => handleCloneClick()}>
                        {tireFormCaptions.SAVEANDCLONE}
                      </Button>
                    }
                    <Button className={styling.next} variant="contained" color="primary" onClick={() => handleNextStep()}>
                      {tireFormCaptions.SUBMIT}
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button className={styling.next} variant="contained" color="primary" onClick={() => handleNextStep()}>
                    {tireFormCaptions.NEXT}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isCancelModalOpen && <CancelConfirmationPopup closeConfirmationModal={() => setCancelModalOpen(false)} pathToRedirect={prevPath} stateToPass={historyState} />}
    </React.Fragment>
  );
};

export default CreateTire;
