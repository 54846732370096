import React, { useEffect, useContext, useState } from "react";
import "./ViewVehicle.css";
import "../../../../../common-styles/TableFunctionOptions.css";
import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  Button,
  makeStyles,
  MenuItem,
  Menu,
} from "@material-ui/core";
import ViewVehicleListApis from "../../../services/ViewVehicleListApis";
import { StateContext } from "../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import Spinner from "../../../../../common-components/spinner/spinner";
import VehicleHistoryListTable from "../table/VehicleHistory";
import VehicleHistorySetter from "../../../services/VehicleHistoryListUtils";
import InstalledTiresListTable from "../table/InstalledTiresTable";
import InstalledTiresSetter from "../../../services/InstalledTiresListUtils";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ViewVehicleNavigation from "../../../view-vehicle-navigation/components/ViewVehicleNavigation";
import DeleteTireModal from "../../../../manage-vehicle/components/modals/delete-vehicle/DeleteVehicle";
import TransferVehicleModal from "../../../../manage-vehicle/components/modals/transfer-vehicle/TransferVehicle";
import Globalization from "../../../../../globalization/";
import FlashMessage from "../../../../../common-components/pop-up-notification/notification-snackbar";
import CommonUtils from "../../../../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      marginBottom: "60px",
      marginLeft: "1.5em",
    },
  },
  inspect: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  edit: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "1px solid #EAF3FB",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "1px solid #EAF3FB",
    borderRadius: 18,
    color: "#0055A4",
    fontSize: "14px !important",
    fontFamily: "Barlow  !important",
    opacity: 1,
    letterSpacing: 0,
    fontWeight: 500,
    zIndex: 0,
    boxShadow: "none",
  },
  action: {
    background: "transparent",
    color: "#0055A4",
    opacity: 1,
    zIndex: 0,
  },
}));

const ViewVehicle = () => {
  const commonUtils = new CommonUtils();
  const viewVehicleListService = new ViewVehicleListApis();
  const vehicleHistorySetter = new VehicleHistorySetter();
  const installedTiresSetter = new InstalledTiresSetter();
  const globalizationMapping = new Globalization();
  const { VehicleId } = useParams();
  const [globalAccountState] = useContext(StateContext);
  const locationObj = useLocation();
  const styles = useStyles();

  const [flashMessageState, setFlashMessageState] = useState(locationObj);
  const [showNotification, setShowNotification] = useState(false);
  const [transferDone, setTransferDone] = useState(commonUtils.generateRandomNumber());
  const [vehicleHistoryListTableData, setVehicleHistoryListTableData] = useState();
  const [installedTiresListTableData, setInstalledTiresListTableData] = useState();
  const [showColumnsVehicleHistory, setShowColumnsVehicleHistory] = useState();
  const [showColumnsInstalledTires, setShowColumnsInstalledTires] = useState();
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);
  const [currentRoute, setCurrentRoute] = useState({ path: "identification" });
  const [storedImages, setStoredImages] = useState([]);
  const [recommendedPressure, setRecommendedPressure] = useState("");
  const [tireDetails, setTireDetails] = useState({});
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [locationDetails, setLocationDetails] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);
  const [installedTires, setInstalledTires] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [pressureUnitLabel, setPressureUnitLabel] = useState("");
  const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;


  useEffect(() => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;

    let pressureUnitLabel = globalAccountState.accountInfo.userSetting.userPressureUnit;
    setPressureUnitLabel(pressureUnitLabel);
    if (pressureUnitLabel.toLowerCase() === "psi") {
      setPressureUnitLabel("PSI");
    } else if (pressureUnitLabel.toLowerCase() === "kpa") {
      setPressureUnitLabel("KPA");
    } else {
      setPressureUnitLabel("Bar");
    }

    viewVehicleListService
      .getLocationListByAccount(accountIds[0])
      .then((data) => {
        if (data.model !== undefined) setLocationList(data.model);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  useEffect(() => {
    ManageVehicleListResponseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (locationObj.state !== undefined) {
      switch (locationObj.state.updatedSection) {
        case "editvehicle":
        case "createnewvehicle":
        case "tranfervehicle":
          if (locationObj.state.updateStatus) {
            setShowNotification(true);
          }
          break;
        default:
          setTimeout(() => {
            setShowNotification(false);
          }, 5000);
          break;
      }
      setFlashMessageState(locationObj);
    }
  }, [globalAccountState, transferDone]);

  const ManageVehicleListResponseData = () => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;

    setShowSpinner(true);

    viewVehicleListService.getVehicleDetails(VehicleId).then((data) => {
      if (data.model) {
        setVehicleDetails(data.model);
        let arrImgs = [];
        if (
          data.model.imagesLocation !== null &&
          data.model.imagesLocation !== ""
        )
          arrImgs = data.model.imagesLocation.split(",");
        setStoredImages(arrImgs);

        let pressure = "";
        if (
          data.model.recommendedPressure !== null &&
          data.model.recommendedPressure !== ""
        )
          pressure = data.model.recommendedPressure.split(",");

        setRecommendedPressure(pressure);

        let axles = [];
        if (data.model.axleConfig) {
          for (const element of data.model.axleConfig) {
            let x = [];
            for (let j = 0; j < Number(element) / 2; j++) {
              x.push("a");
            }
            axles.push(x);
          }
        }
        setTireDetails({ ...data.model, axles });
      }
      let vehicleHistoryListTableInfo = vehicleHistorySetter.setData(data);
      setVehicleHistoryListTableData(vehicleHistoryListTableInfo);
      setShowColumnsVehicleHistory(vehicleHistoryListTableInfo.selectedColShow);
      viewVehicleListService
        .manageVehicleGetDetailsByAccount(accountIds[0])
        .then((accountData) => {
          setShowSpinner(false);
          if (accountData.model) {
            setLocationDetails(accountData.model);
            let location = accountData.model.locationList.find(
              (location) => location.locationId === data.model.locationId
            );
            setSelectedLocation(location);
          }
        });
    });

    viewVehicleListService
      .getInspectionRecordForVehicle(VehicleId)
      .then((data) => {
        if (data.model) {
          let installedTiresList = data.model.installedTires;
          setInstalledTires(installedTiresList);
        }
        let installesTiresListTableInfo = installedTiresSetter.setData(data);
        setInstalledTiresListTableData(installesTiresListTableInfo);
        setShowColumnsInstalledTires(
          installesTiresListTableInfo.selectedColShow
        );
      });
  };

  const changedRoute = (selectedRoute) => {
    setCurrentRoute(selectedRoute);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTransferClick = () => {
    setTransferModalOpen(true);
    handleClose();
  };

  const handleTransferModalClose = (transferData) => {
    setTransferModalOpen(false);
    let notification = { state: transferData };

    if (notification.state.updatedSection === "tranfervehicle") {
      if (notification.state.updateStatus) {
        setShowNotification(true);
      }
    } else {
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }

    if (notification.state.updateStatus) {
      setTransferDone(commonUtils.generateRandomNumber());
      setFlashMessageState(notification);
      setTimeout(() => {
        setShowNotification(false);
        setFlashMessageState({});
      }, 3000);
    }
  };
  const onDeleteActionClick = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };


  const handleTrackingMethod = (id) => {
    if (id === 1) {
      return "Hours"
    }
    else if (id === 2) {
      return "Distance"
    } else {
      return "Both"
    }
  }

  return (
    <div className="standalone-new-header-container">
      <ViewVehicleNavigation changedRoute={changedRoute} />
      {showNotification && flashMessageState.state && (
        <FlashMessage
          status={flashMessageState.state.updateStatus}
          message={flashMessageState.state.successMessage}
          description={flashMessageState.state.successMessageDesc}
        />
      )}
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="view-vehicle-installed-tires-veiw view-vehicle-form">
          <Card className="quick-inspect-list-card quick-inspect-list-card-vehicle">
            <CardContent>
              {/* Identification Details */}
              {currentRoute.path === "identification" && (
                <div className="location-details-grid-view-vehicle">
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {tireFormCaptions.ACCOUNTLOCATION}
                      </p>
                      <InputLabel>
                        {locationDetails.accountName +
                          "-" +
                          selectedLocation.locationName}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {tireFormCaptions.VEHICLEID}
                      </p>
                      <InputLabel>{vehicleDetails.vehicleNumber}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {tireFormCaptions.ASSETNUMBER}
                      </p>
                      <InputLabel>{vehicleDetails.assetNumber}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">{tireFormCaptions.REGDATE}</p>
                      <InputLabel>{commonUtils.formatISODate(vehicleDetails.createdDate)}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">{tireFormCaptions.TRACKBY}</p>
                      <InputLabel>{handleTrackingMethod(vehicleDetails.trackingMethod)}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {tireFormCaptions.CURRHOURS}
                      </p>
                      <InputLabel>{vehicleDetails.currentHours}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric" ? tireFormCaptions.CURRKM : tireFormCaptions.CURRMILES}
                      </p>
                      <InputLabel>{vehicleDetails.currentMiles}</InputLabel>
                    </Grid>
                  </Grid>
                </div>
              )}

              {currentRoute.path === "description" && (
                <div className="location-details-grid-view-vehicle">
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {tireFormCaptions.MANUFACTURER}
                      </p>
                      <InputLabel>{vehicleDetails.manufacturer}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">{tireFormCaptions.TYPE}</p>
                      <InputLabel>{vehicleDetails.typeName}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">{tireFormCaptions.MODEL}</p>
                      <InputLabel>{vehicleDetails.modelName}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">{tireFormCaptions.SIZE}</p>
                      <InputLabel>{vehicleDetails.tireSize}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {tireFormCaptions.OPERATION}
                      </p>
                      <InputLabel>{vehicleDetails.areaOfOperation}</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {tireFormCaptions.MODIFICATION}
                      </p>
                      <InputLabel>{vehicleDetails.modifications}</InputLabel>
                    </Grid>
                    <Grid item sm={12}>
                      {storedImages.length !== 0 && (
                        <p className="field-label">
                          {tireFormCaptions.UPLOADCAPTION}
                        </p>
                      )}
                      <Grid container spacing={1} className="">
                        {storedImages.length !== 0 &&
                          storedImages.map((source, key) => (
                            <div
                              className={`vehicle-img ${key > 0 ? "mar-left" : ""}`}
                              key={key}
                            >
                              <img
                                alt={"stored-img-".concat((key + 1).toString())}
                                src={source}
                                width="160px"
                                height="160px"
                              />
                            </div>
                          ))}
                      </Grid>
                    </Grid>
                    <p className="field-label">
                      {tireFormCaptions.VEHICLEDIAGRAM}
                    </p>
                    <Grid
                      className="axle-position axcel-top-mar"
                      container
                      spacing={1}
                    >
                      {tireDetails.axles &&
                        tireDetails.axles.map((a, i) => {
                          return (
                            <div
                              key={i}
                              // style={{display: 'inline-block', textAlign: 'center', marginRight: 22}}
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginRight: "5px",
                                flexWrap: "nowrap",
                                marginTop: "30px",
                              }}
                            >
                              {i === 0 && (
                                <span
                                  style={{
                                    marginRight: "10px",
                                    color: "#003584",
                                    fontWeight: "600",
                                  }}
                                >
                                  R
                                </span>
                              )}
                              {a.map((b, j) => {
                                return (
                                  <>
                                    <img
                                      className={`tire-create ${a.length > 1 && i === 0 && j === 1
                                        ? "double-tire"
                                        : ""
                                        }`}
                                      alt="Tire"
                                      src={require("../../../../../assets/Tire_a.svg")}
                                    />
                                    <br />
                                  </>
                                );
                              })}

                              <div
                                className={`axle ${i === 0 ? "axle-alignmen" : ""
                                  }`}
                              >
                                <span
                                  className={`axleNumber-create ${i === 0
                                    ? "axleNumber-create-first-view"
                                    : ""
                                    }`}
                                >
                                  {i + 1}
                                </span>
                                <img
                                  alt="Axle"
                                  src={require("../../../../../assets/axle_a.svg")}
                                />
                              </div>
                              {i === 0 && (
                                <span
                                  style={{
                                    marginRight: "10px",
                                    color: "#003584",
                                    fontWeight: "600",
                                  }}
                                >
                                  L
                                </span>
                              )}
                              {a.map((b, j) => {
                                return (
                                  <>
                                    <img
                                      className={`tire-create ${a.length > 1 && i === 0 && j === 1
                                        ? "double-tire"
                                        : ""
                                        }`}
                                      alt="Tire"
                                      src={require("../../../../../assets/Tire_a.svg")}
                                    />
                                    <br />
                                  </>
                                );
                              })}
                            </div>
                          );
                        })}
                    </Grid>
                    {recommendedPressure &&
                      recommendedPressure.map((a, i) => {
                        return (
                          <Grid key={i} item xs={4}>
                            <p className="field-label">
                              {tireFormCaptions.RECOMMENDEDPRESSURE}&nbsp;
                              {i + 1}
                            </p>
                            <InputLabel>
                              {a}&nbsp;{pressureUnitLabel}
                            </InputLabel>
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              )}

              {/* Operation Specification Details */}
              {currentRoute.path === "operationSpecification" && (
                <div className="location-details-grid-view-vehicle">
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {tireFormCaptions.SITESEVERITYINDEX}
                      </p>
                      <InputLabel>
                        {vehicleDetails.siteSeverityIndex}
                      </InputLabel>
                    </Grid>
                    {/* <Grid item xs={4}>
                      <p className="field-label">
                        {tireFormCaptions.REMOVALTREAD}
                      </p>
                      <InputLabel>{vehicleDetails.removalTread}</InputLabel>
                    </Grid> */}
                    <Grid item xs={4}>
                      <p className="field-label">{tireFormCaptions.COMMENTS}</p>
                      <InputLabel>{vehicleDetails.severityComments}</InputLabel>
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className={styles.root}>
                <Button
                  className={styles.inspect}
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  onClick={() => history.push("/quick-inspect/" + VehicleId)}
                >
                  {tireFormCaptions.INSPECT}
                </Button>
                <Button
                  className={styles.edit}
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  onClick={() =>
                    history.push({
                      pathname: "/create-vehicle/" + VehicleId,
                      state: {
                        fromView: true,
                      },
                    })
                  }
                >
                  {tireFormCaptions.EDIT}
                </Button>
                <Button
                  className={styles.edit}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  {tireFormCaptions.ACTIONS}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  disableScrollLock={true}
                >
                  <MenuItem
                    className={styles.action}
                    onClick={handleTransferClick}
                  >
                    {tireFormCaptions.TRANSFER}
                  </MenuItem>
                  <MenuItem
                    className={styles.action}
                    onClick={() =>
                      history.push({
                        pathname: "/create-vehicle/" + VehicleId,
                        state: {
                          option: "Clone",
                          fromView: true,
                        },
                      })
                    }
                  >
                    {tireFormCaptions.CLONE}
                  </MenuItem>
                  <MenuItem
                    className={styles.action}
                    onClick={onDeleteActionClick}
                  >
                    {tireFormCaptions.DELETE}
                  </MenuItem>
                </Menu>
              </div>
              <div className="data-summary-table">
                {installedTiresListTableData && showColumnsInstalledTires && (
                  <InstalledTiresListTable
                    tableData={installedTiresListTableData}
                    showColumns={showColumnsInstalledTires}
                    currentRoute={currentRoute}
                    listOfTires={installedTires}
                  />
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {!showSpinner && (
        <div className="view-vehicle-vehicle-history view-vehicle-form">
          <Card className="quick-inspect-list-card quick-inspect-list-card-vehicle">
            <CardContent>
              <div className="data-summary-table">
                {vehicleHistoryListTableData && showColumnsVehicleHistory && (
                  <VehicleHistoryListTable
                    tableData={vehicleHistoryListTableData}
                    showColumns={showColumnsVehicleHistory}
                    vehicleId={VehicleId}
                    ManageVehicleListResponseData={
                      ManageVehicleListResponseData
                    }
                  />
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {isTransferModalOpen && (
        <TransferVehicleModal
          closeTransferModal={handleTransferModalClose}
          locationList={locationList}
          vehicleId={VehicleId}
          vehicleNumber={vehicleDetails.vehicleNumber}
          isSelectionMultiple={false}
          fromView={true}
          currentTireLocation={selectedLocation.locationName}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteTireModal
          closeDeleteModal={handleDeleteModalClose}
          vehicleId={VehicleId}
          vehicleNumber={vehicleDetails.vehicleNumber}
          isSelectionMultiple={false}
          fromView={true}
        />
      )}
    </div>
  );
};
export default ViewVehicle;
