import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";
class ViewParentAccountListSetter {
  constructor() {
    this.data = {};
    this.viewParentAccountListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item, index) => {
      return (
        item.AccountName.toString().includes(searchText) ||
        item.Country.toLowerCase().includes(searchText) ||
        item.State.toLowerCase().includes(searchText) ||
        item.City.toString().includes(searchText) ||
        item.EmailAddress.toLowerCase().includes(searchText) ||
        item.LocationsCount.toString().includes(searchText) ||
        item.UpdatedDate.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data) {
    // TABLE ROWS DATA
    const accountList = data;

    /** NOTE : the keys must match the headCells value to be able to SORT */
    accountList.forEach((account, vehicleIndex) => {
      this.viewParentAccountListData.push({
        Key: vehicleIndex,
        LocationName: account.locationName ? account.locationName : "",
        Country: account.country ? account.country : "",
        State: account.state ? account.state : "",
        City: account.city ? account.city : "",
        ContactPerson: account.contactPerson ? account.contactPerson : "",
        ContactNo: account.phoneNumber ? account.phoneNumber : "",
        EmailAddress: account.emailAddress ? account.emailAddress : "",
        LocationId: account.locationId ? account.locationId : 0,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.ViewParentAccount;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });
    let selectedColShow = this.initDisplayColumnData(this.headCells);
    this.data = {
      rowsData: this.viewParentAccountListData,
      headCells: this.headCells,
      selectedColShow: selectedColShow,
    };
    return this.data;
  }
}

export default ViewParentAccountListSetter;
