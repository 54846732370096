import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';

export default class QuickInspectListApis {
    cs;
    constructor(){
         this.cs = new CommonService(); 
    }
    getVehicleDetails = (VehicleId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getVehicleDetails}${VehicleId}`,'get',{})
       }
    // getInspectionSummaryForVehicle = (VehicleId) => {
    //     return this.cs.httpConnection(`${SERVER.SERVICES.getInspectionSummaryForVehicle}${VehicleId}`,'get',{})
    // }
    manageVehicleGetDetailsByAccount = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.manageVehicleGetDetailsByAccount}${parentAccountId}`,'get',{})
    }
    getInspectionRecordForVehicle= (VehicleId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getInspectionRecordForVehicle}${VehicleId}`,'get',{})
    }
    getLocationListByAccount = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getLocationListByAccount}${parentAccountId}`, "get", {});
      };
}