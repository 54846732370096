import React, { useContext, useEffect, useState } from "react";
import Globalization from "../../../../../globalization/index";
import { Grid, TextField, makeStyles, Button, InputLabel, List, ListItem, } from "@material-ui/core";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import { StateContext } from "../../../../../services/StateProvider";
import QuickInspectListApis from "../../../services/QuickInspectListApis";
import legendicon from "../../../../../assets/legend.svg";
import "./Table.css";
import "./VehicleInspection.css";
import CommonUtils from "../../../../../services/utils";
import { useHistory } from "react-router-dom";
import TireConfigUtilsViewOnly from "../../../services/TireConfigUtilsViewOnly";
import HeaderApi from "../../../../../common-components/header/services/header-apis";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "transparent",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "inherit",
    },
    "& .MuiSwitch-sizeSmall .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(2.2em)",
    },
    "& input.MuiOutlinedInput-input": {
      padding: "0!important",
    },
    "& textarea.MuiInputBase-input": {
      color: "black"
    }
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    "max-height": "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
  },
  input: {
    display: "none",
  },
  save: {
    "&:hover": {
      opacity: 1,
    },
    borderRadius: 18,
    opacity: .8,
    fontWeight: 500,
  },
  update: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: .8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5
  },
  bottomUpdate: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    opacity: 1,
    background: "#b9b9b900",
    color: "#2355AF",
    fontWeight: 500,
    border: " 1px solid #ccc",
    borderRadius: "18px",
    height: "3em",
    width: "8em",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: .8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5
  },
  update_hour: {
    background: "#fff599 0% 0% no-repeat padding-box",
    borderRadius: 10,
    padding: "10px",
  },
  update_hour_label: {
    color: "#393939",
    fontSize: "1.25em",
    fontFamily: "Barlow,light",
  },
  editVehicle: {
    "&:hover": {
      opacity: 1,
    },

    backgroundColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: 18,
    font: 'Barlow',
    opacity: .8,
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.26)",
    boxShadow: "none"
  }
}));

const VehicleInspectionViewOnly = props => {

  const globalizationMapping = new Globalization();
  const [locationDetails, setLocationDetails] = useState({});
  const [tireDetails, setTireDetails] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [globalAccountState] = useContext(StateContext);
  const [vehicleSelected, setVehicleSelected] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [positionArray, setPositionArray] = useState([]);
  const [flag, setFlag] = useState(0);
  const [noOfReadings, setNoOfReadings] = useState(null);
  const [tdMethod, setTDMethod] = useState(1);
  const [inspectionOrder, setInspectionOrder] = useState(null);
  const quickInspectListService = new QuickInspectListApis();
  const classes = useStyles();
  const commonUtils = new CommonUtils();
  const headerApi = new HeaderApi();
  const history = useHistory();
  const globalization = new Globalization();
  const globalText = globalization.INSPECT;
  const modalTexts = globalization.FORMS.TIRESVEHICLES.TIREFORM;
  const [pressureUnit, setPressureUnit] = useState(globalAccountState.accountInfo.userSetting.userPressureUnit);
  const [imageInspectionSummary] = useState(props.imageInspectionSummary);
  const [showUpdateMsg] = useState(props.showUpdateMsg);
  const userPreference = JSON.parse(localStorage.getItem('accountInfouserMeasurementSystemValue'))

  useEffect(() => {

    let loading = 0;
    const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    const accountIds = accountInfoObject.accountIds;
    quickInspectListService.manageVehicleGetDetailsByAccount(accountIds[0]).then(accountData => {
      if (accountData.model) {
        setLocationDetails(accountData.model);
        // eslint-disable-next-line array-callback-return
        accountData.model.locationList.map(location => {
          // eslint-disable-next-line array-callback-return
          location.vehicleList.map(vehicle => {
            if (vehicle.vehicleId === Number(props.vehicleId)) {
              setSelectedLocation(location);
              vehicle.lastRead = commonUtils.formatISODate(
                vehicle.lastRead
              );
              setVehicleSelected(previousState => {
                return { ...previousState, ...vehicle };
              });
              ++loading;
              setFlag(loading);
            }
          });
        });
        setShowSpinner(false);
      }
    });

    quickInspectListService.getInspectionRecordForVehicle(props.vehicleId).then(imgObj => {
      if (imgObj !== undefined) {
        setVehicleSelected(previousState => {
          return { ...previousState, tireSize: imgObj.model.tireSize, tireSizeId: imgObj.model.tireSizeId };
        });
        const axles = [];
        const axlesPos = [];
        for (const element of imgObj.model.installedTires) {
          const str = element.wheelPosition;
          axlesPos.push(str);
        }

        setPositionArray(axlesPos);
        if (
          imgObj.model &&
          imgObj.model.axleConfig &&
          imgObj.model.axleConfig.length > 0
        ) {
          for (const element of imgObj.model.axleConfig) {
            const x = [];
            for (let j = 0; j < Number(element) / 2; j++) {
              x.push("a");
            }
            axles.push(x);
          }

          if (imgObj.model && imgObj.model.comments === null) {
            imgObj.model.comments = "";
          }
          if (!sessionStorage.getItem("updateHoursSessionCheck")) {
            sessionStorage.setItem("updateHoursSessionCheck", "NewSession");
          }

          setTireDetails({ ...imgObj.model, axles });
          if (imgObj.model.locationId || (imgObj.model.installedTires.length > 0 && imgObj.model.installedTires[0].locationId)) {
            // eslint-disable-next-line no-loop-func
            headerApi.getLocationById(imgObj.model.locationId).then(res => {
              setNoOfReadings(res.model.noOfTreadDepthReadings);
              setTDMethod(res.model.treadDepthMethodId);
              setInspectionOrder(res.model.inspectionOrderId);
            });
          } else {
            setNoOfReadings(0);
          }
          ++loading;
          setFlag(loading);
        }
      }
    });
    setPressureUnit(globalAccountState.accountInfo.userSetting.userPressureUnit);
    if (flag === 2) {
      setShowSpinner(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, props]);

  const checkingReqDetails = () => (tireDetails && tireDetails.axleConfig && noOfReadings && positionArray &&
    vehicleSelected && inspectionOrder && vehicleSelected.vehicleNumber && pressureUnit);



  return (
    <div>
      <div className="container">
        {!showSpinner && (
          <div className={classes.root}>
            <div>
              <Grid container spacing={1}>
                <Grid
                  container
                  style={{
                    background:
                      "0px 0px no-repeat padding-box padding-box rgb(250, 249, 242)",
                  }}
                >
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalText.SELECTPARENTACCOUNT}
                    </p>
                    <InputLabel>
                      {`${locationDetails.accountName}-${selectedLocation.locationName}`}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{globalText.VEHICLEID}</p>
                    <InputLabel>{vehicleSelected.vehicleNumber}</InputLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{globalText.LASTINSPECTIONDATE}</p>
                    <InputLabel>{vehicleSelected.lastRead}</InputLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{globalText.CURRENTDIS}</p>
                    <InputLabel>{vehicleSelected.currentMiles + " "}{userPreference.toLowerCase() === "metric" ?
                      globalizationMapping.MANAGEVEHICLE.KM : globalizationMapping.MANAGEVEHICLE.MILES}</InputLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{globalText.CURRENTHOURS}</p>
                    <InputLabel>{vehicleSelected.currentHours}</InputLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{modalTexts.SIZE}</p>
                    <InputLabel>{vehicleSelected.tireSize}</InputLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{modalTexts.MANUFACTURER}</p>
                    <InputLabel>{vehicleSelected.manufacturerName}</InputLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{modalTexts.MODEL}</p>
                    <InputLabel>{vehicleSelected.modelName}</InputLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{modalTexts.TYPE}</p>
                    <InputLabel>{vehicleSelected.typeName}</InputLabel>
                  </Grid>
                </Grid>
                {checkingReqDetails() && (
                  <Grid
                    className="axle-position"
                    container
                    spacing={1}
                    style={{
                      alignItems: "center",
                      display: "inline-flex",
                      width: "100%",
                      margin: "14px 2px",
                    }}
                  >
                    <TireConfigUtilsViewOnly
                      vehicleNumber={vehicleSelected.vehicleNumber}
                      showUpdateMsg={showUpdateMsg}
                      userPreference={userPreference}
                      tireDetails={tireDetails}
                      positionArray={positionArray}
                      history={history}
                      tdMethod={tdMethod}
                      inspectionOrder={inspectionOrder}
                      noOfReadings={noOfReadings}
                      userPressureUnit={pressureUnit}
                      vehicleInspectionDate={vehicleSelected.lastRead}
                    />
                  </Grid>
                )}

                <img
                  alt="legendicon"
                  style={{
                    width: "100%",
                    height: "2.3em",
                    justifyItems: "center",
                  }}
                  src={legendicon}
                />
              </Grid>
              <Grid container style={{ display: 'block' }}>
                {imageInspectionSummary.length !== 0 && (
                  <>
                    <p className="field-label-upload">{globalization.VEHICLEINSPECTION.UPLOADIMAGES}</p>

                    <List
                      className={'width-images-quick-inspect-tire'}
                      style={{
                        margin: "0",
                        display: "inline-flex",
                        overflow: "scroll",
                      }}
                    >
                      {imageInspectionSummary.map((source, key) =>
                        source.imagesLocation && source.imagesLocation !== "" && source.imagesLocation.split(",").map(src =>
                          <ListItem
                            sm={2}
                            style={{ "max-width": "min-content", display: 'block' }}
                            className="uploaded-image"
                            key={source.tireSerialNo}
                          >
                            <img alt={"chosen-image-".concat((key + 1).toString())}
                              src={src} width="160" height="160" />
                            <span>{globalization.VEHICLEINSPECTION.SERIALNUMBER} {source.tireSerialNo}</span>
                            <br />
                            <span>{globalization.VEHICLEINSPECTION.WHEELPOSITION} {source.wheelPosition}</span>
                          </ListItem>
                        )
                      )}
                    </List>
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                <p className="field-label">{globalization.REPORTCOMMONWORDS.VehicleComments}</p>
                <TextField id="contact-person" className="edit-comment-input-box"
                  placeholder="none for the moment" multiline
                  rows={4} disabled value={tireDetails.comments}
                  inputProps={{ maxLength: 200 }}
                  style={{width:1200}}
                />
              </Grid>
              <hr className="inspect_vehicle-hr-divider" />
              <div className="action-buttons">
                <Button className={classes.cancel} variant="contained" disableElevation={true}
                  onClick={() => { history.push("/manage-vehicle"); }} >
                  Back to Manage Vehicle
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleInspectionViewOnly;
