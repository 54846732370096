import React, { useEffect, useState } from "react";
import "./ViewParentAccountListLabels.css";
import {
  Grid,
} from "@material-ui/core";
import Globalization from "../../../../../globalization/index";


const ViewParentAccountListLabelsViewOnly = (details) => {

  const [viewDetails, setViewDetails] = useState(details.details)
  const globalizationMapping = new Globalization();
  const globaldHeaderTexts = globalizationMapping.HEADER;
  const formCaptions = globalizationMapping.FORMS.ACCOUNTS.PARENTFORM;

  useEffect(() => {
    setViewDetails(details.details)
  }, [details])

  return (
    <div className="view-parent-list-container">
      <div className="view-parent-container">
        <div className="view-parent-inner-container">
          <div className="view-parent-account-account-details-grid">
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <p className="field-label">
                  {formCaptions.PARENTACCOUNT}
                </p>
                <label className="save-table-details">
                  {viewDetails.accountName}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {formCaptions.ADDRESSLINE1}
                </p>
                <label className="save-table-details">
                  {viewDetails.accountAddressLine1}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">{formCaptions.ADDRESSLINE2}</p>
                <label className="save-table-details">
                  {viewDetails.accountAddressLine2}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {formCaptions.COUNTRY}
                </p>
                <label className="save-table-details">
                  {viewDetails.country}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">{globaldHeaderTexts.STATE}</p>
                <label className="save-table-details">
                  {viewDetails.state}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {globaldHeaderTexts.CITY}
                </p>
                <label className="save-table-details">
                  {viewDetails.city}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {globaldHeaderTexts.ZIPPOSTAL}
                </p>
                <label className="save-table-details">
                  {viewDetails.zipCode}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">{globaldHeaderTexts.PHONE}</p>
                <label className="save-table-details">
                  {viewDetails.phoneNumber}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {globaldHeaderTexts.EMAIL}
                </p>
                <label className="save-table-details">
                  {viewDetails.emailAddress}
                </label>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewParentAccountListLabelsViewOnly;
