import { FormHelperText, Grid, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import "../create-vehicle/create-vehicle.css";
import CalendarMonthIcon from '@material-ui/icons/Today';
import CommonUtils from '../../services/utils';
import ExpandMoreStyledIcon from '../../common-components/expandMoreStyledIcon/expandMoreStyledIcon';
import HeaderApi from "../../common-components/header/services/header-apis";
import {  useHistory } from "react-router-dom";
const Identification = props => {
    const commonUtils = new CommonUtils();
    const headerApiObj = new HeaderApi();
    const history = useHistory();
    const [rimAvailablePos, setRimAvailablePos] = useState([]);

    useEffect(() => {
        headerApiObj.getAvailablePosForRim(props.rimModal.vehicleId).then(res => {
            setRimAvailablePos(res.model)
        });
    },
     // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.rimModal.vehicleId]);



    const handleTrackingMethodChange = val => {
    props.onTrackByChange(val);
    };




    const disableInDispositionStatus = () => {
        if (props.RimId && props.currentDispositionSate === 2) { // edit mode and disposition is installed
                return true;
        } else if (!props.RimId && props.currentDispositionSate === 1) { // create mode and disposition must be inventory
            return true;
        } else if (history?.location?.state?.option === 'Clone') {
            return true;
        } else return false;
    }

    const showDropdownOptionsUnderDisposition = () => {// edit mode and disposition is from inventory set to installed
            return (props.RimId && props.currentDispositionSate === 1 && props.rimModal.dispositionId === 2);
    }
    
    const handleDateChange = (val) => {
        props.onChangeListener('registrationDate', val);
    };

    return (
        <Grid className="step-form-inner-container" container spacing={2} >
                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.SERIAL} <span className="required-mark">*</span></p>
                    <TextField
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.rimModal.rimSerialNo}
                        onChange={e => props.onChangeListener('rimSerialNo', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.SERIAL}`} 
                        inputProps={{ maxLength: 50 }}
                    />
                    {(props.rimModal.rimSerialNo === "" && props.isRimSerialNumberEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                    {(props.isRimSerialNumberEdited ? "" : <FormHelperText>{props.formHelperTexts.rimSerialNo}</FormHelperText>)}
                </Grid>
                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.BRAND}</p>
                    <TextField
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.rimModal.brandNo}
                        onChange={e => props.onChangeListener('brandNo', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.BRAND}`} 
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.REGDATE} <span className="required-mark">*</span></p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                        <KeyboardDatePicker
                            className="enter-detail-input-box"
                            margin="normal"
                            value={props.rimModal.registrationDate}
                            onChange={handleDateChange}
                            format={commonUtils.getDateFormat()}
                            placeholder={commonUtils.getDateFormat()}
                            keyboardIcon={<CalendarMonthIcon/>}
                            KeyboardButtonProps={{
                                'aria-label': 'change date'
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <FormHelperText>{props.formHelperTexts.registrationDate}</FormHelperText>
                </Grid>
                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.PURCHASEORDER}</p>
                    <TextField
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.rimModal.ponumber}
                        onChange={e => props.onChangeListener('ponumber', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.PURCHASEORDER}`} 
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>

                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.BARCODE}</p>
                    <TextField
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.rimModal.barCode}
                        onChange={e => props.onChangeListener('barCode', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.BARCODE}`} 
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.LOTNUMBER}</p>
                    <TextField
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.rimModal.lotNo}
                        onChange={e => props.onChangeListener('lotNo', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.LOTNUMBER}`} 
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.DISPOSITION}</p>
                    <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className={disableInDispositionStatus() ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                        variant="outlined"
                        value={props.rimModal.dispositionId}
                        onChange={e => props.onChangeListener('dispositionId', e.target.value)}
                        disabled={disableInDispositionStatus()}
                    >
 
                        {props.masterDataset?.rimDispositions && props.masterDataset.rimDispositions.map(disposition =>
                            <option key={disposition.dispositionId} value={disposition.dispositionId}>{disposition.dispositionName}</option>
                        )}
                    </Select>
                    <FormHelperText>{props.formHelperTexts.dispositionId}</FormHelperText>
                </Grid>
                {/* { showDropdownOptionsUnderDisposition() ? */}
                 <>
                <Grid item sm={12} >
                    <p className="field-label">Vehicle Available in this location <span className="required-mark">*</span></p>
                    <Select
                        required
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className={showDropdownOptionsUnderDisposition() ? "enter-detail-input-box" : "enter-detail-input-box disabled-input"}
                        variant="outlined"
                        value={props.rimModal.vehicleId ? props.rimModal.vehicleId : ""}
                        onChange={e => props.onChangeListener('vehicleId', e.target.value)}
                        disabled={!showDropdownOptionsUnderDisposition()}
                    >
                        <option key={0} value={0}>Select Vehicle</option>
                        {props.vehicleListDetails.map(vehicle =>
                            <option key={vehicle.vehicleId} value={vehicle.vehicleId}>{vehicle.vehicleNumber}</option>
                        )}
                    </Select>
                    <FormHelperText>{props.formHelperTexts.vehicleId}</FormHelperText>
                </Grid> 
                <Grid item sm={12} >
                    <p className="field-label">Rim Position Available on Vehicle <span className="required-mark">*</span></p>
                    <Select
                        required
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className={(showDropdownOptionsUnderDisposition() && props.rimModal.vehicleId && rimAvailablePos) ? "enter-detail-input-box" : "enter-detail-input-box disabled-input"}
                        variant="outlined"
                        value={props.rimModal.wheelPosition ? props.rimModal.wheelPosition : ""}
                        onChange={e => props.onChangeListener('wheelPosition', e.target.value)}
                        disabled={!(showDropdownOptionsUnderDisposition() && props.rimModal.vehicleId && rimAvailablePos)}
                    >
                        <option key={0} value={0}>Select Rim Position</option>
                        {rimAvailablePos ? rimAvailablePos.map(rim =>
                            <option key={rim} value={rim}>{rim}</option> 
                        ) : null }
                    </Select>
                    <FormHelperText>{props.formHelperTexts.wheelPosition}</FormHelperText>
                </Grid>
                </> 
                {/* : null} */}
                {disableInDispositionStatus() ? 
                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.VEHICALIDPOS}</p>
                    <TextField
                        className="enter-detail-input-box disabled-input"
                        variant="outlined"
                        value={props.vehicleIDPos}
                        disabled={true}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.VEHICALIDPOS}`} 
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid> : null}
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.STATUS}</p>
                    <Select
                        required
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.rimModal.rimStatusId}
                        onChange={e => props.onChangeListener('rimStatusId', e.target.value)}
                        helperText="">
                        <option value={""} hidden>{props.fieldNames.SELECT}</option>
                        <option value={1} className="list-color-change">{props.fieldNames.USED}</option>
                        <option value={2} className="list-color-change">{props.fieldNames.CONSIGNED}</option>
                    </Select>
                </Grid>

                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.TRACKINGMETHOD} <span className="required-mark">*</span></p>
                    <Select
                        required
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.trackBy}
                        onChange={e => handleTrackingMethodChange(e.target.value)}
                        helperText="">
                        <option value={1} className="list-color-change">{props.fieldNames.HOURS}</option>
                        <option value={2} className="list-color-change">{"Distance"}</option>
                        <option value={3} className="list-color-change">Both</option>
                    </Select>
                </Grid>
                    <Grid item sm={12}>
                        <p className="field-label">{props.fieldNames.CURRENT + " " + props.fieldNames.HOURS} {props.trackBy !== 2 ? <span className="required-mark">*</span> : null}</p>
                        <TextField
                            className={props.trackBy === 2 ? "enter-detail-input-box disabled-input": "enter-detail-input-box"}
                            variant="outlined"
                            value={props.rimModal.currentHours === "" ? 0 : props.rimModal.currentHours}
                            onChange={e => props.onChangeListener('currentHours', commonUtils.minmaxFloat(e.target.value, 0, 999999))}
                            type="number" 
                            inputProps={{ maxLength: 50 }}
                            disabled={props.trackBy === 2}
                        />
                        {(props.rimModal.currentHours !== "" && (props.rimModal.currentHours < 0 || props.rimModal.currentHours === '-0') && props.isCurrentHoursEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.rimModal.currentHours === "" && props.isCurrentHoursEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.isCurrentHoursEdited ? "" : <FormHelperText>{props.formHelperTexts.currentHours}</FormHelperText>)}
                    </Grid>

                    <Grid item sm={12}>
                        <p className="field-label">{props.fieldNames.INSPINTERVAL} for Hours {props.trackBy !== 2 ? <span className="required-mark">*</span> : null}</p>
                        <TextField
                            className={props.trackBy === 2 ? "enter-detail-input-box disabled-input": "enter-detail-input-box"}
                            variant="outlined"
                            value={props.rimModal.inspectionIntervalHours === "" ? 0 : props.rimModal.inspectionIntervalHours}
                            onChange={e => props.onChangeListener('inspectionIntervalHours', commonUtils.minmaxFloat(e.target.value, 0, 999999))}
                            type="number" 
                            inputProps={{ maxLength: 50 }}
                            disabled={props.trackBy === 2}
                        />
                        {(props.rimModal.inspectionIntervalHours !== "" && (props.rimModal.inspectionIntervalHours < 0 || props.rimModal.inspectionIntervalHours === '-0') && props.isInspectionIntervalHoursEdited) ? <FormHelperText>{`Value should not be < 0`}</FormHelperText> : ''}
                        {(props.isInspectionIntervalHoursEdited ? "" : <FormHelperText>{props.formHelperTexts.inspectionIntervalHours}</FormHelperText>)}
                    </Grid>

                    <Grid item sm={12}>
                        <p className="field-label">{props.fieldNames.HOURSLEFT} {props.trackBy !== 2 ? <span className="required-mark">*</span> : null}</p>
                        <TextField
                            className={props.trackBy === 2 ? "enter-detail-input-box disabled-input": "enter-detail-input-box"}
                            variant="outlined"
                            value={props.rimModal.hoursLeft === "" ? 0 : props.rimModal.hoursLeft}
                            onChange={e => props.onChangeListener('hoursLeft', commonUtils.minmaxFloat(e.target.value, 0, 999999))}
                            type="number" 
                            inputProps={{ maxLength: 50 }}
                            disabled={props.trackBy === 2}
                        />
                        {(props.rimModal.hoursLeft !== "" && (props.rimModal.hoursLeft < 0 || props.rimModal.hoursLeft === '-0') && props.isInspectionIntervalHoursEdited) ? <FormHelperText>{`Value should not be < 0`}</FormHelperText> : ''}
                        {(props.isHoursLeftEdited ? "" : <FormHelperText>{props.formHelperTexts.hoursLeft}</FormHelperText>)}
                    </Grid>

                    <Grid item sm={12}>
                        <p className="field-label">{props.userPreferenceData.userMeasurementSystemValue === "imperial" ? props.fieldNames.CURRMILES : props.fieldNames.CURRKM} {props.trackBy !== 1 ? <span className="required-mark">*</span> : null}</p>
                        <TextField
                            className={props.trackBy ===1 ? "enter-detail-input-box disabled-input": "enter-detail-input-box"}
                            variant="outlined"
                            value={props.rimModal.currentMiles === ""  ? 0 : props.rimModal.currentMiles}
                            onChange={e => props.onChangeListener('currentMiles', commonUtils.minmaxFloat(e.target.value, 0, 999999))}
                            type="number" 
                            inputProps={{ maxLength: 50 }}
                            disabled={props.trackBy === 1}
                        />
                        {(props.rimModal.currentMiles !== "" && (props.rimModal.currentMiles < 0 || props.rimModal.currentMiles === '-0') && props.isCurrentMilesEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.rimModal.currentMiles === "" && props.isCurrentMilesEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.isCurrentMilesEdited ? "" : <FormHelperText>{props.formHelperTexts.currentMiles}</FormHelperText>)}
                    </Grid>

                    <Grid item sm={12}>
                        <p className="field-label">{props.fieldNames.INSPINTERVAL} for Distance {props.trackBy !== 1 ? <span className="required-mark">*</span> : null}</p>
                        <TextField
                            className={props.trackBy ===1 ? "enter-detail-input-box disabled-input": "enter-detail-input-box"}
                            variant="outlined"
                            value={props.rimModal.inspectionIntervalMiles === ""  ? 0 : props.rimModal.inspectionIntervalMiles}
                            onChange={e => props.onChangeListener('inspectionIntervalMiles', commonUtils.minmaxFloat(e.target.value, 0, 999999))}
                            type="number" inputProps={{ maxLength: 50 }}
                            disabled={props.trackBy === 1}
                        />
                        {(props.rimModal.inspectionIntervalMiles !== "" && (props.rimModal.inspectionIntervalMiles < 0 || props.rimModal.inspectionIntervalMiles === '-0') && props.isInspectionIntervalHoursEdited) ? <FormHelperText>{`Value should not be < 0`}</FormHelperText> : ''}
                        {(props.isInspectionIntervalMilesEdited ? "" : <FormHelperText>{props.formHelperTexts.inspectionIntervalMiles}</FormHelperText>)}
                    </Grid>

                    <Grid item sm={12}>
                        <p className="field-label">{props.userPreferenceData.userMeasurementSystemValue === "imperial" ? props.fieldNames.MILESLEFT : props.fieldNames.KMLEFT} {props.trackBy !== 1 ? <span className="required-mark">*</span> : null}</p>
                        <TextField
                            className={props.trackBy ===1 ? "enter-detail-input-box disabled-input": "enter-detail-input-box"}
                            variant="outlined"
                            value={props.rimModal.milesLeft === ""  ? 0 : props.rimModal.milesLeft}
                            onChange={e => props.onChangeListener('milesLeft', commonUtils.minmaxFloat(e.target.value, 0, 999999))}
                            type="number" 
                            inputProps={{ maxLength: 50 }}
                            disabled={props.trackBy === 1}
                        />
                        {(props.rimModal.milesLeft !== "" && (props.rimModal.milesLeft < 0 || props.rimModal.milesLeft === '-0') && props.isInspectionIntervalHoursEdited) ? <FormHelperText>{`Value should not be < 0`}</FormHelperText> : ''}
                        {(props.isMilesLeftEdited ? "" : <FormHelperText>{props.formHelperTexts.milesLeft}</FormHelperText>)}
                    </Grid>
            </Grid>
    );
};

export default Identification;
