import { Button, Grid, Modal, Tooltip, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Globalization from '../../../globalization';
import { Warning } from '@material-ui/icons';
import UpdateHoursMiles from '../../quick-inspect-vehicle/components/list-vehicles/pop-up-error-tread/UpdateHoursMiles';


const useStyles = makeStyles({
    innerContainer: {
        display: 'inline-grid'
    },
    update_hour: {
        background: "#fff599 0% 0% no-repeat padding-box",
        borderRadius: 10,
        padding: "3px"
    },
    update_hour_label: {
        marginTop: "3px",
        color: "#393939",
        fontFamily: "Barlow,light",
        fontStyle: "italic",
        "&:hover": {
            cursor: "pointer",
            fontWeight: "bolder"
        }
    },
    editVehicle: {
        "&:hover": {
            opacity: 1
        },
        background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: "Barlow",
        opacity: 0.8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none"
    }
});

const AllTireRemovalDetailsGrid = ({ vehicleDetails, tireDetails, locationDetails, selectedLocation, vehicleSelected, allowSubmit }) => {

    const classes = useStyles();
    const globalizationMapping = new Globalization();
    const globalization = new Globalization();
    const globalText = globalization.INSPECT;
    const titleTexts = globalizationMapping.INSPECTNREMOVETIRE;
    const notificationTexts = globalization.FORMS.NOTIFICATIONTEXTS;

    const [inspDate, setInspDate] = useState("");
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        let inspDate = new Date(vehicleDetails.updatedDate);
        inspDate = `${inspDate.getDate()}/${inspDate.getMonth() + 1}/${inspDate.getFullYear()}`;
        setInspDate(inspDate);
    }, [vehicleDetails.updatedDate]);


    return (
        <React.Fragment>

            <Grid container spacing={1} className={classes.update_hour}>
                <Grid item xs={11} className="update-hour-inner-container">
                    <Tooltip title={globalText.UPDATE} arrow>
                        <label className={classes.update_hour_label} >
                            <Warning style={{ verticalAlign: "middle", marginBottom: 5, marginRight: 8, color: 'orange' }}></Warning>
                            {allowSubmit ? notificationTexts.UPDATEHPOURSHEAD : globalText.UPDATE_HOUR_LABEL}
                        </label>
                    </Tooltip>
                </Grid>
                <Grid item xs={1} style={{ paddingLeft: '2%' }}>
                    <Button className={classes.editVehicle} onClick={() => setOpenModal(true)}> {globalizationMapping.INSPECT.UPDATE}</Button>
                </Grid>

                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    className="header-change-modal-style"
                    disableScrollLock={true}
                    onBackdropClick={() => setOpenModal(false)}
                >
                    <div>
                        <UpdateHoursMiles
                            tireDetails={tireDetails}
                            vehicleDetails={vehicleDetails}
                            screen="RemoveAllTire"
                            setTryNavigating={() => setOpenModal(false)}
                            onClose={() => setOpenModal(false)}
                        />
                    </div>
                </Modal>
            </Grid>


            <Grid container spacing={2}>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.SELECTEDPARENTLOCATION}</span>
                    <span className="item-detail-content"> {`${locationDetails.accountName}-${selectedLocation.locationName}`}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.VEHICLEID}</span>
                    <span className="item-detail-content">{vehicleDetails.vehicleNumber}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.TIRESERIAL}</span>
                    <span className="item-detail-content">{tireDetails.installedTires.map((tire) => tire.tireSerialNo).join(', ')} </span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.BRANDNO}</span>
                    <span className="item-detail-content"> {vehicleDetails.manufacturer}</span>
                </Grid>

                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.WHEELPOS}</span>
                    <span className="item-detail-content">{tireDetails.installedTires.map((tire) => tire.wheelPosition).join(', ')}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.LASTINSPECTION}</span>
                    <span className="item-detail-content">{inspDate}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.CURRENTDISTANCE}</span>
                    <span className="item-detail-content">{vehicleDetails.currentMiles}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.CURRENTHOURS}</span>
                    <span className="item-detail-content">{vehicleDetails.currentHours}</span>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default AllTireRemovalDetailsGrid;
