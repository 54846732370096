import Globalization from '../globalization/index'

const globalizationMapping = new Globalization();

export const ManageTiresPaths = [
    {
        name: globalizationMapping.MANAGETIREREPORTS.ManageTireInstalled,
        path: 'manage-tire', 
        dispositionId: 7 
      },
      {
        name: globalizationMapping.MANAGETIREREPORTS.ManageTireInventory,
        path: 'manageTireInventory',
        dispositionId: 8
      },
      {
        name: globalizationMapping.MANAGETIREREPORTS.ManageTireOnHold,
        path: 'manageTireonHold',
        dispositionId: 4
      },
      {
        name: globalizationMapping.MANAGETIREREPORTS.ManageTireScrap,
        path: 'manageTireScrap',
        dispositionId: 1
      },
      {
        name: globalizationMapping.MANAGETIREREPORTS.ManageTireRepair,
        path: 'manageTireRepair',
        dispositionId: 2
      },
      {
        name: globalizationMapping.MANAGETIREREPORTS.ManageTireRetread,
        path: 'manageTireRetread',
        dispositionId: 3
      },
      {
        name: globalizationMapping.MANAGETIREREPORTS.ManageTireAll,
        path: 'manageTireAll',
        dispositionId: null
      },
]