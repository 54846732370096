import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";
import { InspectionOrderConfiguration } from "../../../constants/InspectionOrder";

class TireInflationDataSetter {
  constructor() {
    this.data = {};
    this.fleetSurveyData = [];
    this.fleetSurveyVehicleData = [];
    this.headCells = [];
    this.fleetVehicleMappedData = [];
    this.fleetSurveyMapping = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  tableHeaderOrderSetter(headCells) {
    let tableHeaders = headCells;
    // maintains the column order to be displayed (write them in the order you want to display table header)
    let headerOrder = [
      { columnNo: 1, value: "WheelPosition" },
      { columnNo: 2, value: "SerialNo" },
      { columnNo: 3, value: "ManufacturerName" },
      { columnNo: 4, value: "SizeName" },
      { columnNo: 5, value: "TypeName" },
      { columnNo: 6, value: "Compound" },
      { columnNo: 7, value: "RatingName" },
      { columnNo: 8, value: "TreadDepth" },
      { columnNo: 9, value: "PercentageWorn" },
      { columnNo: 10, value: "Eval" },
      { columnNo: 11, value: "TotalHours" },
      { columnNo: 12, value: "TotalMiles" },
      { columnNo: 13, value: "RecomPressure" },
      { columnNo: 14, value: "Pressure" },
      { columnNo: 15, value: "PercentRecomPressure" },
      { columnNo: 16, value: "InspectDate" }
    ];

    let resultHeaderCell = [];
    headerOrder.forEach((headerOrder) => {
      let found = false;
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === headerOrder.value) {
          headerItem.keyIndex = headerOrder.columnNo - 1;
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });

    return resultHeaderCell;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "TotalMiles") selectedColShow[headCell.value] = false;
      else selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item, index) => {
      return (
        item.ParentAccountName.toLowerCase().includes(searchText) ||
        item.LocationName.toLowerCase().includes(searchText) ||
        item.InspectionName.toLowerCase().includes(searchText) ||
        item.InspectionOrderId.toString().includes(searchText) ||
        item.VehicleId.toLowerCase().includes(searchText) ||
        item.AssetNumber.toLowerCase().includes(searchText) ||
        item.ModelName.toLowerCase().includes(searchText) ||
        item.Manufacturer.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.CurrentHours.toString().includes(searchText) ||
        item.CurrentMiles.toString().includes(searchText) ||
        item.LoadingRequirement.toLowerCase().includes(searchText) ||
        item.SpeedName.toLowerCase().includes(searchText) ||
        item.CuttingName.toLowerCase().includes(searchText)
      );
    });
  }

  // filters rows data based on the vehicle data update
  setRowsDataOnUpdate = (filteredVehicleData, originalRowsDataStore) => {
    let updatedRowsData = [];
    filteredVehicleData.forEach((vehicleDataItem) => {
      originalRowsDataStore.forEach((rowsDataItem, indexKey) => {
        if (vehicleDataItem.Key === indexKey) {
          updatedRowsData.push(rowsDataItem);
        }
      });
    });
    return updatedRowsData;
  };

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey].toString())) return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data) {
    // TABLE ROWS DATA
    let reportData = data.model.reportData;
    const columnDefinitions = data.model.columnDefinitions;
    reportData = reportData.filter((elem) => {
      if (elem.tireList.length !== 0) {
        return elem;
      }
    });

    reportData.forEach((elem) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      let tempData = [];
      elem.tireList.forEach((item, index) => {
        tempData.push({
          Key: index,
          WheelPosition: item.wheelPosition ? item.wheelPosition : "",
          SerialNo: item.serialNo ? item.serialNo : "",
          ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
          SizeName: item.sizeName ? item.sizeName : "",
          TypeName: item.typeName ? item.typeName : "",
          Compound: item.compound ? item.compound : "",
          RatingName: item.ratingName ? item.ratingName : "",
          TreadDepth: item.treadDepth ? item.treadDepth : 0,
          PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
          Eval: item.eval ? item.eval : "",
          TotalHours: item.totalHours ? item.totalHours : 0,
          TotalMiles: item.totalMiles ? item.totalMiles : 0,
          RecomPressure: item.recomPressure ? item.recomPressure : 0,
          Pressure: item.pressure ? item.pressure : 0,
          PercentRecomPressure: item.percentRecomPressure ? item.percentRecomPressure : 0,
          InspectDate: item.inspectDate ? item.inspectDate : ""
        });
      });
      this.fleetSurveyData.push(tempData);
    });

    // TABLE HEADER DATA
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    reportData.forEach((elem, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.fleetSurveyVehicleData.push({
        Key: index,
        ParentAccountName: elem.vehicleData.parentAccountName ? elem.vehicleData.parentAccountName : "NA",
        LocationName: elem.vehicleData.locationName ? elem.vehicleData.locationName : "NA",
        InspectionName: elem.vehicleData.updatedBy ? elem.vehicleData.updatedBy : "NA",
        InspectionOrderId: elem.vehicleData.inspectionOrderId ? elem.vehicleData.inspectionOrderId : "NA",
        VehicleId: elem.vehicleData.vehicleNumber ? elem.vehicleData.vehicleNumber : "NA",
        AssetNumber: elem.vehicleData.assetNumber ? elem.vehicleData.assetNumber : "",
        ModelName: elem.vehicleData.modelName ? elem.vehicleData.modelName : "NA",
        Manufacturer: elem.vehicleData.manufacturer ? elem.vehicleData.manufacturer : "",
        TypeName: elem.vehicleData.typeName ? elem.vehicleData.typeName : "NA",
        CurrentHours: elem.vehicleData.currentHours ? elem.vehicleData.currentHours : 0,
        CurrentMiles: elem.vehicleData.currentMiles ? elem.vehicleData.currentMiles : 0,
        LoadingRequirement: elem.vehicleData.averageLoadingReq ? elem.vehicleData.averageLoadingReq : "NA",
        SpeedName: elem.vehicleData.speed ? elem.vehicleData.speed : "NA",
        CuttingName: elem.vehicleData.cutting ? elem.vehicleData.cutting : "NA",
        AxleConfigName: elem.vehicleData.axleConfig ? elem.vehicleData.axleConfig : "NA"
      });
    });

    let TableData = reportData;

    let fleetSummaryList = TableData.filter((data) => {
      return data.tireList;
    });
    let fleetListArr = [];
    // maps fleet survey data to the config file
    fleetSummaryList.forEach((item) => {
      let fleetListTemp = [];
      let order = item.vehicleData.inspectionOrderId;
      let axleConfig = item.vehicleData.axleConfig;
      let tireOrder = InspectionOrderConfiguration.TirePositionConfig[order][axleConfig];
 
      if (tireOrder !== undefined) {
        tireOrder.forEach((pos) => {
          item.tireList.forEach((tire) => {
            if (pos === tire.wheelPosition) {
              fleetListTemp.push(tire);
            }
          });
        });
      }
      fleetListArr.push(fleetListTemp);
    });

    fleetListArr.forEach((elem) => {
      let tempDataFleet = [];
      elem.forEach((item, index) => {

       const pressureIdentifier = () => {
          if (item.pressure) {
            if (columnDefinitions[12].text.toUpperCase().indexOf("BAR") === -1) {
              return this.pressureFormat(item.pressure);
            } else {
              return item.pressure;
            }
          } else {
            return 0;
          }
        }

        tempDataFleet.push({
          Key: index,
          WheelPosition: item.wheelPosition ? item.wheelPosition : "",
          SerialNo: item.serialNo ? item.serialNo : "",
          ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
          SizeName: item.sizeName ? item.sizeName : "",
          TypeName: item.typeName ? item.typeName : "",
          Compound: item.compound ? item.compound : "",
          RatingName: item.ratingName ? item.ratingName : "",
          TreadDepth: item.treadDepth ? item.treadDepth : 0,
          PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
          Eval: item.eval ? item.eval : "",
          TotalHours: item.totalHours ? item.totalHours : 0,
          TotalMiles: item.totalMiles ? item.totalMiles : 0,
          RecomPressure: item.recomPressure ? item.recomPressure : 0,
          Pressure: pressureIdentifier(),
          PercentRecomPressure: item.percentRecomPressure ? item.percentRecomPressure : 0,
          InspectDate: item.inspectDate ? this.commonUtils.tableDateFormatter(item.inspectDate) : ""
        });
      });
      this.fleetSurveyMapping.push(tempDataFleet);
    });

    // maps filter options to the config file data
    let rowsDataFilterOptions = [];
    this.fleetSurveyData.forEach((fleetListItem, indexKey) => {
      if (fleetListItem.length > 0) {
        rowsDataFilterOptions.push(this.fleetSurveyVehicleData[indexKey]);
      }
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);
    let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells);
    return {
      rowsData: this.fleetSurveyMapping,
      headCells: orderedHeadCells,
      selectedColShow: selectedColShow,
      vehicleData: this.fleetSurveyVehicleData,
      fleetVehicleMappedData: fleetListArr,
      rowsDataFilterOptions: rowsDataFilterOptions
    };
  }
  pressureFormat = (itemPressure) => {
    if (itemPressure.indexOf(".") !== -1) {
      let temp = Math.round(Number(itemPressure.substring(0, itemPressure.indexOf("("))));
      return temp + itemPressure.substring(itemPressure.indexOf(".") + 2, itemPressure.length);
    } else return itemPressure;
  };
}

export default TireInflationDataSetter;
