import React, { useEffect, useState } from "react";
import "./TireHistoryTable.css";
import "../../../../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../../services/utils";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    height: 625
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const commonUtils = new CommonUtils();

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => {
    el.Date = new Date(commonUtils.tableDateFormatter(el.Date));
    return [el, index];
  });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => {
    el[0].Date = GetFormattedDate(el[0].Date);
    return el[0];
  });
}

const GetFormattedDate = (inputDate) => {
  let todayTime = inputDate;
  let month = todayTime.getMonth() + 1;
  let day = todayTime.getDate();
  let year = todayTime.getFullYear();
  let newDate = month + "/" + day + "/" + year;
  newDate = commonUtils.tableDateFormatter(newDate);
  return newDate;
};

const TireHistoryTable = ({ tableData, showColumns, filterApplied }) => {
  //
  const [tireHistoryTableData, setTireHistoryTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("RemovalReason");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const classNameGen = (row) => {
    return row?.newLine ? "table-content-text newLine" : "table-content-text";
  };

  useEffect(() => {
    setTireHistoryTableData(tableData);
    setPage(0);
    let arr = commonUtils.rowPerPageSelector(tireHistoryTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
  }, [tableData, showColumns, tireHistoryTableData.rowsData.length]);

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table 
              stickyHeader 
              className={classes.table} 
              aria-labelledby="tableTitle" 
              aria-label="a dense table"
              size="small"
              >
              <TableHead>
                <TableRow>
                  {tireHistoryTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className="table-head-row"
                        key={headCell.keyIndex}
                        // align={headCell.numeric===true?"right":"left"}
                        align="left"
                        sortDirection={orderBy === headCell.value ? order : false}
                      >
                        <TableSortLabel active={orderBy === headCell.value} direction={orderBy === headCell.value ? order : "asc"} onClick={handleRequestSort(headCell.value)}>
                          {headCell.label}
                          {orderBy === headCell.value ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {tireHistoryTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(tireHistoryTableData.rowsData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={index}>
                          {showColumns.BrandNumber && <TableCell className={classNameGen(row)}>{row.BrandNumber}</TableCell>}
                          {showColumns.SerialNo && <TableCell className={classNameGen(row)}>{row.SerialNo}</TableCell>}
                          {showColumns.ManufacturerName && <TableCell className={classNameGen(row)}>{row.ManufacturerName}</TableCell>}
                          {showColumns.SizeName && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.SizeName}
                            </TableCell>
                          )}
                          {showColumns.TypeName && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.TypeName}
                            </TableCell>
                          )}
                           {showColumns.CompoundName && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.CompoundName}
                            </TableCell>
                          )}
                          {showColumns.RatingName && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.RatingName}
                            </TableCell>
                          )}
                          {showColumns.Date && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.Date}
                            </TableCell>
                          )}
                          {showColumns.Disposition && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.Disposition}
                            </TableCell>
                          )}
                          {showColumns.Event && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.Event}
                            </TableCell>
                          )}
                          {showColumns.VehId && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.VehId}
                            </TableCell>
                          )}
                          {showColumns.AssetNumber && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.AssetNumber}
                            </TableCell>
                          )}
                          {showColumns.TotalHours && <TableCell className={classNameGen(row)}>{row.TotalHours}</TableCell>}
                          {showColumns.TotalMiles && <TableCell className={classNameGen(row)}>{row.TotalMiles}</TableCell>}
                          {showColumns.TreadDepth && <TableCell className={classNameGen(row)}>{row.TreadDepth}</TableCell>}
                          {showColumns.Pressure && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.Pressure}
                            </TableCell>
                          )}
                          {showColumns.InspId && (
                            <TableCell align="left" className={classNameGen(row)}>
                              {row.InspId}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {tireHistoryTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={12} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={tireHistoryTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default TireHistoryTable;
