import React, { useEffect, useContext, useState } from "react";
import VehicleReportsNavigation from "../../vehicle-reports-navigation/VehicleReportsNavigation";
import Spinner from "../../../../../common-components/spinner/spinner";
import "../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import MultiSelect from "react-multi-select-component";
import { StateContext } from "../../../../../services/StateProvider";
import SearchIcon from "../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import TiresService from "../../../../tires-reports/services/TiresApis";
import FleetSurveyTable from "../table/FleetSurveyTable";
import FleetSurveyDataSetter from "../../../services/FleetSurveyUtils";
import CommonUtils from "../../../../../services/utils";
import Globalization from "../../../../../globalization";
import { useComponentVisible } from "../../../../../services/CustomHooks";
import FilterPrintEmailExportSetButtons from "../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons";
import FleetSurveyFilter from "../filter/FleetSurveyFilter";

const FleetSurvey = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [fleetSurveyTableData, setFleetSurveyTableData] = useState();
  const [originalFleetSurveyTableData, setOriginalFleetSurveyTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(fleetSurveyTableData);
  const [printDataNotPDF, setPrintDataNotPDF] = useState(fleetSurveyTableData);
  const [noData, setNoData] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({ fromDate: "", toDate: "" });
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const [keys, setKeys] = useState([]);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Vehicle Id", value: "VehicleId" },
      { name: "Model", value: "ModelName" },
      { name: "Type", value: "TypeName" }
    ],
    categoryData: {
      VehicleId: [],
      ModelName: [],
      TypeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const commonUtils = new CommonUtils();
  const fleetSurveyDataSetter = new FleetSurveyDataSetter();
  const tiresService = new TiresService();

  const FleetSurveyResponseData = (excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      locationIds: locationIds,
      reportName: "Fleet_Survey",
      reportOption: "",
      toDate: dateRangeFilter.toDate,
      usedTires: excludeUsedTiresValue.toString()
    };

    setShowSpinner(true);

    tiresService.getTiresReport(requestObject).then((data) => {
      let fleetSurveyTableInfo = fleetSurveyDataSetter.setData(data);
      setKeys(fleetSurveyTableInfo.headCells.map((header) => data.model.columnDefinitions.find((item) => item.fieldName === header.value).text));

      let filterOptions = {
        categories: [
          { name: globalizationMapping.REPORTTABLE.VEHICLEID, value: "VehicleId" },
          { name: globalizationMapping.REPORTTABLE.VEHICLEMODEL, value: "ModelName" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" }
        ],
        categoryData: {
          VehicleId: [],
          ModelName: [],
          TypeName: [],
          dateRangeFromDate: '',
          dateRangeToDate: '',
        }
      };
      let filterOptionsInfo = fleetSurveyDataSetter.setFilterOptionsData(filterOptions, fleetSurveyTableInfo.rowsDataFilterOptions);
      fleetSurveyTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setOriginalFleetSurveyTableData(fleetSurveyTableInfo);
      setFleetSurveyTableData(fleetSurveyTableInfo);
      setTableBeforeFilter(fleetSurveyTableInfo);
      setFilterOptions(filterOptionsInfo);
      setDisplayColOptions(fleetSurveyTableInfo.headCells);
      setShowColumns(fleetSurveyTableInfo.selectedColShow);

      setPrintData({
        reportType: "Vehicle Reports",
        reportName: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
        rowsData: fleetSurveyTableInfo.rowsData,
        headCells: fleetSurveyTableInfo.headCells,
        vehicleData: fleetSurveyTableInfo.vehicleData,
        selectedColShow: fleetSurveyTableInfo.selectedColShow
      });
      if (displayColumns.length === 0) setDisplayColumns(fleetSurveyTableInfo.headCells);
      else {
        changeDisplayColumns(displayColumns);
      }
      let exportData = JSON.parse(JSON.stringify(fleetSurveyTableInfo));
      for (let index = 0; index < exportData.rowsData.length; index++) {
        let vehicleId = exportData.vehicleData[index].VehicleId;
        exportData.rowsData[index].forEach((tireRow) => {
          tireRow.VehicleId = vehicleId;
        });
      }
      exportData.headCells.unshift({
        key: "VehicleId",
        keyIndex: exportData.headCells.length,
        label: "Vehicle Id",
        numeric: true,
        value: "VehicleId"
      });
      exportData.selectedColShow.VehicleId = true;

      setPrintDataNotPDF({
        reportType: "Vehicle Reports",
        reportName: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
        rowsData: exportData.rowsData,
        vehicleData: exportData.vehicleData,
        headCells: exportData.headCells,
        selectedColShow: exportData.selectedColShow
      });
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalVehicleDataStore = fleetSurveyTableInfo.vehicleData;
        let originalRowsDataStore = fleetSurveyTableInfo.rowsData;
        let originalFleetVehicleMappedDataStore = fleetSurveyTableInfo.fleetVehicleMappedData;
        let filteredVehicleData = fleetSurveyDataSetter.filterTable(filterFieldsAppliedValue, originalVehicleDataStore);
        let filteredRowsData = fleetSurveyDataSetter.setRowsDataOnUpdate(filteredVehicleData, originalRowsDataStore);
        let filteredVehicleMappedData = fleetSurveyDataSetter.setAxleDataOnUpdate(filteredVehicleData, originalFleetVehicleMappedDataStore);
        setFleetSurveyTableData({ ...fleetSurveyTableInfo, rowsData: filteredRowsData, vehicleData: filteredVehicleData, fleetVehicleMappedData: filteredVehicleMappedData });
        setOriginalFleetSurveyTableData({
          ...fleetSurveyTableInfo,
          rowsData: filteredRowsData,
          vehicleData: filteredVehicleData,
          fleetVehicleMappedData: filteredVehicleMappedData
        });
        setPrintData({
          reportType: "Vehicle Reports",
          reportName: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
          rowsData: filteredRowsData,
          vehicleData: filteredVehicleData,
          headCells: fleetSurveyTableInfo.headCells,
          selectedColShow: fleetSurveyTableInfo.selectedColShow,
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        setPrintDataNotPDF({
          reportType: "Vehicle Reports",
          reportName: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
          rowsData: JSON.parse(JSON.stringify(filteredRowsData)),
          vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
          headCells: JSON.parse(JSON.stringify(fleetSurveyTableInfo.headCells)),
          selectedColShow: JSON.parse(JSON.stringify(fleetSurveyTableInfo.selectedColShow)),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setFilterApplied(false) : setFilterApplied(true)
      }
      setShowSpinner(false);
    });
  };

  useEffect(() => {
    FleetSurveyResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });
  const globalizationMapping = new Globalization();

  const onClickExport = (type) => {
    if (type === "pdf") {
      changeDisplayColumns(displayColumns);
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      VehicleId: [],
      ModelName: [],
      TypeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });

    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setFilterApplied(false);
    setFleetSurveyTableData(tableBeforeFilter);
    setOriginalFleetSurveyTableData(tableBeforeFilter);
    setPrintData({ ...printData, ...tableBeforeFilter, filterApplied: false, filterFieldsApplied: {} });
    setPrintDataNotPDF({ ...printDataNotPDF, ...tableBeforeFilter, filterApplied: false, filterFieldsApplied: {} });
    setNoData(false);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    let originalVehicleDataStore, originalRowsDataStore, originalFleetVehicleMappedDataStore, filteredVehicleData, filteredRowsData, filteredVehicleMappedData;
    originalVehicleDataStore = originalFleetSurveyTableData?.vehicleData;
    originalRowsDataStore = tableBeforeFilter?.rowsData;
    originalFleetVehicleMappedDataStore = tableBeforeFilter?.fleetVehicleMappedData;

    filteredVehicleData = fleetSurveyDataSetter.search(originalVehicleDataStore, searchText);

    filteredVehicleMappedData = fleetSurveyDataSetter.setAxleDataOnUpdate(filteredVehicleData, originalFleetVehicleMappedDataStore);

    filteredRowsData = fleetSurveyDataSetter.setRowsDataOnUpdate(filteredVehicleData, originalRowsDataStore);

    setFleetSurveyTableData({ ...fleetSurveyTableData, rowsData: filteredRowsData, vehicleData: filteredVehicleData, fleetVehicleMappedData: filteredVehicleMappedData });
    setPrintData({
      ...printData,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
      headCells: originalFleetSurveyTableData?.headCells,
      selectedColShow: originalFleetSurveyTableData?.selectedColShow
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
      rowsData: JSON.parse(JSON.stringify(filteredRowsData)),
      vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
      headCells: JSON.parse(JSON.stringify(originalFleetSurveyTableData?.headCells)),
      selectedColShow: JSON.parse(JSON.stringify(originalFleetSurveyTableData?.selectedColShow))
    });
    filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

  setCurrentFilter(toSetCurrentFilter);
  if (appliedFilter === filterOptions.categoryData) {
    setFilterApplied(false);
  } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    let originalVehicleDataStore = originalFleetSurveyTableData?.vehicleData;
    let originalRowsDataStore = originalFleetSurveyTableData?.rowsData;
    let originalFleetVehicleMappedDataStore = originalFleetSurveyTableData?.fleetVehicleMappedData;
    let filteredVehicleData = fleetSurveyDataSetter.filterTable(appliedFilter, originalVehicleDataStore);
    let filteredRowsData = fleetSurveyDataSetter.setRowsDataOnUpdate(filteredVehicleData, originalRowsDataStore);
    let filteredVehicleMappedData = fleetSurveyDataSetter.setAxleDataOnUpdate(filteredVehicleData, originalFleetVehicleMappedDataStore);
    setFleetSurveyTableData({ ...fleetSurveyTableData, rowsData: filteredRowsData, vehicleData: filteredVehicleData, fleetVehicleMappedData: filteredVehicleMappedData });
    setPrintData({
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
      headCells: originalFleetSurveyTableData?.headCells,
      selectedColShow: originalFleetSurveyTableData?.selectedColShow
    });
    setPrintDataNotPDF({
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
      rowsData: JSON.parse(JSON.stringify(filteredRowsData)),
      vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
      headCells: JSON.parse(JSON.stringify(originalFleetSurveyTableData?.headCells)),
      selectedColShow: JSON.parse(JSON.stringify(originalFleetSurveyTableData?.selectedColShow))
    });
    filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
    FleetSurveyResponseData(excludeUsedTires, true, appliedFilter);
  };


  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalFleetSurveyTableData?.headCells;
    let showColumnsUpdated = fleetSurveyDataSetter.setDisplayColumnData(currentHeadCells, selected);

    setShowColumns(showColumnsUpdated);
    setPrintData({ ...printData, selectedColShow: showColumnsUpdated });
    setPrintDataNotPDF({ ...printDataNotPDF, selectedColShow: showColumnsUpdated });
  };
  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    FleetSurveyResponseData(event.target.checked, filterApplied, filterFieldsApplied);
  };

  return (
    <div className="container fleet-root-class">
      <VehicleReportsNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-compound-container">
          <Card className="by-compound-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                  onClickFilter={onClickFilter}
                  onClickEmail={onClickEmail}
                  onClickPrint={onClickPrint}
                  onClickExport={onClickExport}
                  toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)}
                  printData={printData}
                  ref={ref}
                  onlyFilterApplied={filterApplied}
                  noData={noData}
                  isComponentVisible={isComponentVisible}
                  isVehicleReport={true}
                />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div className="exclude-used-tires">
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="data-summary-table">
                <FleetSurveyTable tableData={fleetSurveyTableData} showColumns={showColumns} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} keys={keys} tableData={printData} noGraph={true} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} keys={keys} tableData={printData} noGraph={true} />
      <EmailExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} fleetSurvey={true} />

       <FleetSurveyFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default FleetSurvey;
