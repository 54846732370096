import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
// eslint-disable-next-line no-unused-vars

const ScrapAnalysisGraph = ({ scrapAnalysisGraphData, _valueLostBy }) => {
  const [chartInfoData, setChartInfoData] = useState({});
  const commonUtils = new CommonUtils();
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["scrap-analysis-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["scrap-analysis-y"];
  options.title.text = globalizationHeaders["scrap-analysis-title"];


  const chart = () => {

    if (scrapAnalysisGraphData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();
      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      scrapAnalysisGraphData.forEach((item) => {
        graphDataSum[item.RemovalReason] = 0;
        graphDataAvg[item.RemovalReason] = 0;
        graphDataCount[item.RemovalReason] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      scrapAnalysisGraphData.forEach((item) => {
        graphDataSum[item.RemovalReason] += item.MoneyLost;
        graphDataCount[item.RemovalReason]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((RemovalReason) => {
        graphDataAvg[RemovalReason] = Math.round(
          graphDataSum[RemovalReason] / graphDataCount[RemovalReason]
        );
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoData({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };
  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrapAnalysisGraphData]);

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && (
          <Bar data={chartInfoData} height={120} options={options} />
        )}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ScrapAnalysisGraph;
