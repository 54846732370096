import React, { useEffect, useState } from "react";
import "../../../../../../common-styles/TableStyle.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import "./TireRotationTable.css";
import NoDataFound from "../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../services/utils";
import Globalization from "../../../../../../globalization";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
const globalizationMapping = new Globalization();
const commonUtils = new CommonUtils();

function hourMileLabels() {
  if (localStorage.getItem("accountInfo")) {
    if (
      JSON.parse(
        localStorage.getItem("accountInfouserMeasurementSystemValue")
      ) === "metric"
    ) {
      return globalizationMapping.REPORTCOMMONWORDS.HoursDistanceKM;
    } else {
      return globalizationMapping.REPORTCOMMONWORDS.HoursDistanceMiles;
    }
  } else {
    return "";
  }
}
const hourMileLabel = hourMileLabels();

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => {
    el.ProjectedRotationDate = new Date(
      commonUtils.tableDateFormatter(el.ProjectedRotationDate)
    );
    return [el, index];
  });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => {
    el[0].ProjectedRotationDate = GetFormattedDate(el[0].ProjectedRotationDate);
    return el[0];
  });
}

const GetFormattedDate = (inputDate) => {
  let todayTime = inputDate;
  let month = todayTime.getMonth() + 1;
  let day = todayTime.getDate();
  let year = todayTime.getFullYear();
  let newDate = month + "/" + day + "/" + year;
  newDate = commonUtils.tableDateFormatter(newDate);
  return newDate;
};

const TireRotationTable = ({ tableData, filterApplied, showColumns }) => {

  const [fleetSurveyTableData, setFleetSurveyTableData] = useState(tableData);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    setFleetSurveyTableData(tableData);
    setPage(0);
    setPageVal(1);
  }, [tableData, showColumns]);

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const itemsPerPage = 5;
  const [pageVal, setPageVal] = React.useState(1);
  const noOfPages = Math.ceil(
    Object.keys(fleetSurveyTableData.rowsData).length / itemsPerPage
  );
  const handleChange = (event, newPage) => {
    setPageVal(newPage);
  };

  let count = -1;
  const classNameSet = (keyIndex, value, c) => {
    count = c;
    switch (count) {
      case -1:
        return "table-row";
      case 0:
        return "fixed-columns first-rotation-col table-head-row";
      case 1:
        return "fixed-columns second-rotation-col table-head-row";
      case 2:
        return "fixed-columns third-rotation-col table-head-row";
      case 3:
        return "fixed-columns fourth-rotation-col table-head-row";
      default:
        return "table-head-row";
    }
  };
  let countBody = -1;
  const classNameSetBody = (c) => {
    countBody = c;
    switch (countBody) {
      case -1:
        return "table-row";
      case 0:
        return "fixed-column-body first-rotation-col table-content-fleet-text";
      case 1:
        return "fixed-column-body second-rotation-col table-content-fleet-text";
      case 2:
        return "fixed-column-body third-rotation-col table-content-fleet-text";
      case 3:
        return "fixed-column-body fourth-rotation-col table-content-fleet-text";
      default:
        return "table-content-fleet-text";
    }
  };

  const firstRecord = Number((pageVal - 1) * itemsPerPage + 1);
  const lastRecord =
    Number(pageVal * itemsPerPage) <
    Object.keys(fleetSurveyTableData.rowsData).length
      ? Number(pageVal * itemsPerPage)
      : Object.keys(fleetSurveyTableData.rowsData).length;

  return (
    <div className="container">
      {fleetSurveyTableData.rowsData !== null &&
        fleetSurveyTableData.rowsData !== undefined &&
        fleetSurveyTableData.rowsData !== "" &&
        Object.keys(fleetSurveyTableData.rowsData)
          .slice((pageVal - 1) * itemsPerPage, pageVal * itemsPerPage)
          .map((key, index) => {
            return (
              <div className={classes.root} key={index}>
                {fleetSurveyTableData.rowsData[key].length > 0 && (
                  <React.Fragment>
                    <div className="vehicle-container">
                      <Grid
                        className="removed-tire-record-detail"
                        container
                        spacing={3}
                      >
                        <Grid item xs={12} md className="record-no">
                          <div className="parent-account-container">
                            <div className="account-info-text">
                              {globalizationMapping.REPORTCOMMONWORDS.RecordNo}
                            </div>
                            <div className="account-header-text-by-tire font-wt-500">
                              {(fleetSurveyTableData.vehicleData[key].Key + 1) <= 9 ? "0" + (fleetSurveyTableData.vehicleData[key].Key + 1) : (fleetSurveyTableData.vehicleData[key].Key + 1)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md>
                          <div className="parent-account-container">
                            <div className="account-info-text">
                              {globalizationMapping.REPORTTABLE.ACCOUNTNAME}
                            </div>
                            <div className="account-header-text-by-tire font-wt-500">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .ParentAccountName
                              }
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md>
                          <div className="parent-account-container">
                            <div className="account-info-text">
                              {globalizationMapping.HEADER.LOCATION}
                            </div>
                            <div className="account-header-text-by-tire font-wt-500">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .LocationName
                              }
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md>
                          <div className="parent-account-container">
                            <div className="account-info-text">
                              {globalizationMapping.REPORTTABLE.INSPID}
                            </div>
                            <div className="account-header-text-by-tire font-wt-500">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .InspectionName
                              }
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="removed-tire-detail-container">
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <div className="heading-container heading-text">
                            {
                              globalizationMapping.REPORTCOMMONWORDS
                                .VehicleDetails
                            }
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">
                              {globalizationMapping.REPORTTABLE.VehicleName}
                            </div>
                            <div className="tire-header-text">
                              {fleetSurveyTableData.vehicleData[key].VehicleId}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">
                              {globalizationMapping.REPORTTABLE.ASSETNUMBER}
                            </div>
                            <div className="tire-header-text">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .AssetNumber
                              }
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">
                              {globalizationMapping.REPORTCOMMONWORDS.MakeModel}
                            </div>
                            <div className="tire-header-text">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .Manufacturer
                              }
                              /{fleetSurveyTableData.vehicleData[key].ModelName}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">
                              {globalizationMapping.REPORTTABLE.TYPE}
                            </div>
                            <div className="tire-header-text">
                              {fleetSurveyTableData.vehicleData[key].TypeName}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">
                              {hourMileLabel}
                            </div>
                            <div className="tire-header-text">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .CurrentHours
                              }
                              /
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .CurrentMiles
                              }
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <Paper className={classes.paper}>
                      <TableContainer className={classes.container}>
                        <Table
                          stickyHeader
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          aria-label="a dense table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow className={classNameSet(-1, -1, -1)}>
                              {fleetSurveyTableData.headCells.map((headCell) =>
                                showColumns[headCell.value] ? (
                                  <TableCell
                                    className={classNameSet(
                                      headCell.keyIndex,
                                      headCell.value,
                                      ++count
                                    )}
                                    key={headCell.keyIndex}
                                    align="left"
                                    sortDirection={
                                      orderBy === headCell.value ? order : false
                                    }
                                  >
                                    <TableSortLabel
                                      active={orderBy === headCell.value}
                                      direction={
                                        orderBy === headCell.value
                                          ? order
                                          : "asc"
                                      }
                                      onClick={handleRequestSort(
                                        headCell.value
                                      )}
                                    >
                                      {headCell.label}
                                      {orderBy === headCell.value ? (
                                        <span
                                          className={classes.visuallyHidden}
                                        >
                                          {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                  </TableCell>
                                ) : null
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {stableSort(
                              fleetSurveyTableData.rowsData[key],
                              getComparator(order, orderBy)
                            )
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((dataItem, index) => {
                                return (
                                  <TableRow
                                    className={classNameSetBody(-1)}
                                    tabIndex={-1}
                                    key={dataItem.Key}
                                  >
                                    {showColumns.WheelPosition && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.WheelPosition}
                                      </TableCell>
                                    )}
                                    {showColumns.BrandNumber && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.BrandNumber}
                                      </TableCell>
                                    )}
                                    {showColumns.SerialNo && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.SerialNo}
                                      </TableCell>
                                    )}
                                    {showColumns.ManufacturerName && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.ManufacturerName}
                                      </TableCell>
                                    )}
                                    {showColumns.SizeName && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.SizeName}
                                      </TableCell>
                                    )}
                                    {showColumns.TypeName && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TypeName}
                                      </TableCell>
                                    )}
                                    {showColumns.Compound && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.Compound}
                                      </TableCell>
                                    )}
                                    {showColumns.RatingName && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.RatingName}
                                      </TableCell>
                                    )}
                                    {showColumns.TreadDepth && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TreadDepth}
                                      </TableCell>
                                    )}
                                    {showColumns.PercentageWorn && 
                                        (<Tooltip title={commonUtils.wornPercentageInfo(dataItem.PercentageWorn)} placement="top-end">
                                          <TableCell className={commonUtils.wornPercentageClass(dataItem.PercentageWorn)}>{dataItem.PercentageWorn}%</TableCell>
                                        </Tooltip>
                                    )}
                                    {showColumns.Eval && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.Eval}
                                      </TableCell>
                                    )}
                                    {showColumns.TotalHours && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TotalHours}
                                      </TableCell>
                                    )}
                                    {showColumns.TotalMiles && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TotalMiles}
                                      </TableCell>
                                    )}
                                    {showColumns.TotalCost && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TotalCost}
                                      </TableCell>
                                    )}
                                    {showColumns.HoursPerTreadDepth && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.HoursPerTreadDepth}
                                      </TableCell>
                                    )}
                                    {showColumns.MilesPerTreadDepth && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.MilesPerTreadDepth}
                                      </TableCell>
                                    )}
                                    {showColumns.ProjectedHours && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.ProjectedHours}
                                      </TableCell>
                                    )}
                                    {showColumns.ProjectedMiles && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.ProjectedMiles}
                                      </TableCell>
                                    )}
                                    {showColumns.ProjectedCost && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.ProjectedCost}
                                      </TableCell>
                                    )}
                                    {showColumns.ProjectedCostPerMile && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.ProjectedCostPerMile}
                                      </TableCell>
                                    )}
                                    {showColumns.HoursLeft && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.HoursLeft}
                                      </TableCell>
                                    )}
                                    {showColumns.MilesLeft && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.MilesLeft}
                                      </TableCell>
                                    )}
                                    {showColumns.ProjectedRotationDate && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.ProjectedRotationDate}
                                      </TableCell>
                                    )}
                                    {showColumns.ProjectedRotationDateByMile && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.ProjectedRotationDateByMile}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </React.Fragment>
                )}
              </div>
            );
          })}
      {fleetSurveyTableData.rowsData.length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="table"></NoDataFound>
        </div>
      )}
      {fleetSurveyTableData.rowsData !== null &&
        fleetSurveyTableData.rowsData !== undefined &&
        fleetSurveyTableData.rowsData.length > 0 && (
          <React.Fragment>
            <div className="record-data">
              Showing {firstRecord} to {lastRecord} of{" "}
              {Object.keys(fleetSurveyTableData.rowsData).length} records
            </div>
            <Pagination
              count={noOfPages}
              page={pageVal}
              onChange={handleChange}
              defaultPage={1}
              shape="rounded"
              //size="small"
              className="pagination-vehicle-report"
            />
          </React.Fragment>
        )}
    </div>
  );
};

export default TireRotationTable;
