import React, { useState } from "react";
import "./ViewRimNavigation.css";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import { List, ListItem } from "@material-ui/core";
import Globalization from "../../../globalization/index";
import { ViewRimPaths } from "../../../routing/ViewRimPaths";
const ViewRimNavigation = ({ changedRoute }) => {
  const globalizationMapping = new Globalization();
  const viewRimGlobalTexts = globalizationMapping.VIEWRIMREPORTS;
  const viewRimNav = [
    viewRimGlobalTexts.IDENTIFICATION,
    viewRimGlobalTexts.DESCRIPTION,
    viewRimGlobalTexts.COST,
  ];
  const viewRimPathNav = [
    "identification",
    "description",
    "cost",
  ];

  const getRouteName = (currentPathName) => {
    let pathSelected = ViewRimPaths.find(
      (route) => route.path === currentPathName
    );
    return pathSelected;
  };

  const getRoutePath = (path) => {
    let pathSelected = ViewRimPaths.find((route) => route.path === path);
    return pathSelected;
  };

  let currentRouteSelected = getRouteName("identification");
  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);
  const globaldHeaderTexts = globalizationMapping.HEADER;

  const breadcrumbs = [
    {
      path: "home",
      name: globaldHeaderTexts.HOME,
    },
    {
      path: "",
      name: globaldHeaderTexts.VIEWRIM,
    },
  ];

  const viewRimNavigation = (path) => {
    let routePath = getRoutePath(path);
    setSelectedRoute(routePath);
    changedRoute(routePath);
  };
  return (
    <div>
      <div className="manage-tires-main-container">
        <div className="view-vehicle-upper-deck">
          <div className="background-img-container-view-rim">
            <div className="background-map-image background-map-image-view-rim">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {globaldHeaderTexts.VIEWRIM}
                  </div>
                </div>
              </div>
              <div className="manage-button-manage-vehicle-veiw-nav-container">
                <div className="manage-tires-nav-container">
                  {viewRimNav.map((item, index) => {
                    return (
                      <div
                        key={item}
                        className="manage-tires-nav-card-container"
                        onClick={() =>
                          viewRimNavigation(viewRimPathNav[index])
                        }
                      >
                        <List
                          className={
                            selectedRoute.name === item
                              ? "active-manage-tires-nav-card"
                              : "manage-tires-nav-card"
                          }
                        >
                          <ListItem
                            button={selectedRoute.name === item}
                            autoFocus={selectedRoute.name === item}
                          >
                            <span
                              className={
                                selectedRoute.name === item
                                  ? "active-manage-tires-nav-card-text"
                                  : "manage-tires-nav-card-text"
                              }
                            >
                              {item}
                            </span>
                          </ListItem>
                        </List>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRimNavigation;
