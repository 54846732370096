import React from 'react';
import '../common-modal/CommonPopup.css'
import CommonModal from '../common-modal/CommonPopup'
import Globalization from '../../../globalization';


export default function PrintExportModal(props) {
 const globalization = new Globalization()
 const handleClose = () => {
    props.setModalHandler(false)
  };
  return (
    <div>
      <CommonModal
        title={globalization.REPORTCOMMONWORDS.ConfigureAndPrint}
        subtitle={globalization.REPORTCOMMONWORDS.PrintSubTitle}
        onClose={handleClose}
        fieldTitle={globalization.REPORTCOMMONWORDS.PrintChooseField}
        openModal={props.modalHandler.handlePrint}
        printOrPdf={"print"}
        keys={props.keys}
        tableData={props.tableData}
        tableData2={props.tableData2}
        noGraph={props.noGraph}
        notable={props.notable}
      />
    </div>
  );
}
