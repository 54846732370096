import enUs from './en-us';
import enUk from './en-uk';
import es from './es';
import de from './de';
import fr from './fr';
import it from './it';
import pl from './pl';
import pt from './pt';
import ru from './ru';
import zh from './zh';
import ser from './ser';

export default function Globalization(){
    let reportDashboardMapping;
    switch(sessionStorage.getItem('userLanguage')){
        case 'en-us' : reportDashboardMapping = enUs; break;
        case 'en-uk' : reportDashboardMapping = enUk; break;
        case 'es' : reportDashboardMapping = es; break;
        case 'de' : reportDashboardMapping = de; break;
        case 'it' : reportDashboardMapping = it; break;
        case 'fr' : reportDashboardMapping = fr; break;
        case 'pl' : reportDashboardMapping = pl; break;
        case 'pt' : reportDashboardMapping = pt; break;
        case 'ru' : reportDashboardMapping = ru; break;
        case 'zh' : reportDashboardMapping = zh; break;
        case 'ser' : reportDashboardMapping = ser; break;
        default: reportDashboardMapping = enUs; break;
    }

    return reportDashboardMapping;
}