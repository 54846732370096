import { MakeFleetDataAnalysisTemplate, getFilename } from "./FleetDataPdfMake";

export const FleetDataTemplatePdfGenerator = (
  fleetDataAnalysisTableData,
  exportType
) => {
  switch (exportType) {
    case "create":
      return MakeFleetDataAnalysisTemplate(fleetDataAnalysisTableData);
    case "print":
      MakeFleetDataAnalysisTemplate(fleetDataAnalysisTableData).print();
      break;
    case "download":
      MakeFleetDataAnalysisTemplate(fleetDataAnalysisTableData).download(
        getFilename("Fleet Data Analysis")
      );
      break;
    case "view":
      MakeFleetDataAnalysisTemplate(fleetDataAnalysisTableData).open();
      break;
    default:
      break;
  }
};
