/* eslint-disable no-unused-vars */
import "./remove-all-tire.css";
import { Box, Button, Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import Spinner from "../../../common-components/spinner/spinner";
import Globalization from "../../../globalization";
import { StateContext } from "../../../services/StateProvider";
import AllTireRemovalForm from "./remove-all-tire-form";
import AllTireRemovalDetailsGrid from "./remove-all-tire-details";
import VehicleDiagram from "./vehicle-diagram/VehicleDiagram";
import RemoveTireApis from "../services/remove-tire-apis";
import HeaderApi from "../../../common-components/header/services/header-apis";
import AlertPopup from "../../../common-components/alert-pop-up/alert-popup";
import Modal from "@material-ui/core/Modal";
import legendIcon from "../../../assets/legend.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import QuickInspectListApis from "../../quick-inspect-vehicle/services/QuickInspectListApis";
import { InspectionOrderConfiguration } from "../../../constants/InspectionOrder";
const useStyles = makeStyles({
    width: "100%",
    submitButton: {
        "&:hover": {
            opacity: 1
        },
        background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: "Barlow",
        opacity: 0.8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none"
    },
    disabledButton: {
        background: "transparent linear-gradient(90deg, #bebfc046 0%, #bebfc046 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: "Barlow",
        opacity: 0.8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none"
      },
    cancel: {
        "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none"
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: 1,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none"
    },
    expandIcon: {
        color: "#0055A4",
        cursor: "pointer",
        top: "calc(50% - 12px)",
        position: "absolute",
        pointerEvents: "none",
        right: "7px"
    }
});

const RemoveAllTire = () => {
    const locationObj = useLocation();
    const history = useHistory();
    const styling = useStyles();
    const [globalAccountState] = useContext(StateContext);
    const globalizationMapping = new Globalization();
    const tireFormCaptions = globalizationMapping.FORMS;
    const validationTexts = globalizationMapping.FORMS.VALIDATIONTEXTS;
    const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
    let vehicleNotUpdated;

    const removeTireApi = new RemoveTireApis();
    const headerApiObj = new HeaderApi();
    const quickInspectListService = new QuickInspectListApis();

    const myBreadcrumbs = [
        {
            path: "home",
            name: globalizationMapping.HEADER.HOME
        },
        {
            path: "",
            name: globalizationMapping.INSPECT.QUICKINSPECT
        },
        {
            path: "",
            name: globalizationMapping.INSPECTNREMOVETIRE.REMOVE
        }
    ];


    const [masterData, setMasterData] = useState({});

    const [casingConditions, setCasingConditions] = useState([]);
    const [wearConditions, setWearConditions] = useState([]);
    const [removalMasterData, setRemovalMasterData] = useState([]);
    const [allowSubmit, setAllowSubmit] = useState(true);
    const [submitFlag, setSubmitFlag] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);
    const [vehicleDetails, setVehicleDetails] = useState(locationObj.state.vehicleDetails);
    const [tireDetails, setTireDetails] = useState({ ...locationObj.state.tireDetails });
    const [unitPreferences, setUnitPreferences] = useState({});
    const [tireRemovalDetails, setTireRemovalDetails] = useState(locationObj.state.tireDetails.installedTires);
    const [isRemovalReasonNewData, setIsRemovalReasonNew] = useState([]);
    const [handleCheckbox, setHandleCheckbox] = useState([])
    const [formHelperTexts, setFormHelperTexts] = useState([]);
    const [otherRemovalReasonId, setOtherRemovalReasonId] = useState(0)
    const [isFormValidStatus, setIsFormValidStatus] = useState(false)

    const noOfReadings = locationObj.state.noOfReadings;
    const inspectionOrderForCards = locationObj.state.inspectionOrder;
    const noOfTreadDepthMethodId = locationObj.state.noOfTreadDepthMethodId;
    const locationDetails = locationObj.state.locationDetails;
    const selectedLocation = locationObj.state.selectedLocation;
    const vehicleSelected = locationObj.state.vehicleSelected;

    const sortFieldEntries = (property) => {
        return function (a, b) {
            if (a[property] > b[property]) return 1;
            else if (a[property] < b[property]) return -1;

            return 0;
        };
    };

    function sortTiresByWheelPosition(tireArray, wheelOrder) {
        // Create an object to map wheel positions to their order
        const wheelPositionOrder = {}
        wheelOrder.forEach((position, index) => {
            wheelPositionOrder[position] = index;
        });
        // Sort the tireArray based on the custom sorting order
        tireArray.installedTires.sort((a, b) => wheelPositionOrder[a.wheelPosition] - wheelPositionOrder[b.wheelPosition]);
        return tireArray;
    }

    //function for handling current tread depth mismatch 

    const handleCurrentTreadDepth = (tireRemovalDetail) => {
        const outside =
            isNaN(parseInt(tireRemovalDetail.outsideTread, 10)) ||
                tireRemovalDetail.outsideTread === null
                ? 0
                : parseInt(tireRemovalDetail.outsideTread, 10);
        const inside =
            isNaN(parseInt(tireRemovalDetail.insideTread, 10)) ||
                tireRemovalDetail.insideTread === null
                ? 0
                : parseInt(tireRemovalDetail.insideTread, 10);
        const middle =
            isNaN(parseInt(tireRemovalDetail.middleTread, 10)) ||
                tireRemovalDetail.middleTread === null
                ? 0
                : parseInt(tireRemovalDetail.middleTread, 10);

        if (noOfReadings === 3) {
            let result;
            if (noOfTreadDepthMethodId === 1) {
                result = (outside + inside + middle) / 3;
                if (!Number.isInteger(result)) {
                    result = result.toFixed(2);
                }
            } else if (noOfTreadDepthMethodId === 2) {
                result = Math.max(outside, inside, middle);
            } else {
                result = Math.min(outside, inside, middle);
            }
            return result
        } else if (noOfReadings === 2) {
            let result;
            if (noOfTreadDepthMethodId === 1) {
                result = (outside + inside) / 2;
                if (!Number.isInteger(result)) {
                    result = result.toFixed(2);
                }
            } else if (noOfTreadDepthMethodId === 2) {
                result = Math.max(outside, inside);
            } else {
                result = Math.min(outside, inside);
            }
            return result
        } else {
            return outside
        }
    }

    useEffect(() => {
        setUnitPreferences({
            pressure: globalAccountState.accountInfo.userSetting.userPressureUnit,
            treadDepth: globalAccountState.accountInfo.userSetting.userMeasurementSystemValue
        });

        headerApiObj.getMasterDataWeb().then((masterData) => {
            if (masterData.model) {
                setMasterData(masterData.model);
                const otherRemovalReasonData = masterData.model.tireRemovalReasons.filter((ele) => ele.reasonName.toLowerCase().trim() === 'other')
                setOtherRemovalReasonId(otherRemovalReasonData[0].reasonId)

                let casingListArr = masterData.model.casingCondition;
                let wearListArr = masterData.model.wearConditions;
                casingListArr.sort(sortFieldEntries("casingConditionName"));
                wearListArr.sort(sortFieldEntries("wearConditionName"));
                setCasingConditions(casingListArr);
                setWearConditions(wearListArr);
            }
        });

        // Define the custom wheel order as an array
        const customWheelOrder = InspectionOrderConfiguration.TirePositionConfig[inspectionOrderForCards][vehicleDetails.axleConfig]

        //API call for vehicle details
        setShowSpinner(true)
        quickInspectListService.getVehicleDetails(locationObj.state.vehicleDetails.vehicleId).then(data => {
            setVehicleDetails(data.model);
            const today =
                new Date().getFullYear() +
                "-" +
                String(new Date().getMonth() + 1).padStart(2, '0') +
                "-" +
                String(new Date().getDate()).padStart(2, '0');
            vehicleNotUpdated = +new Date(today) !== +new Date(data.model.lastUpdatedDate.split("T")[0]);
            setAllowSubmit(!vehicleNotUpdated);
            setShowSpinner(false)
        })
        setSubmitFlag(false);
        setShowSpinner(true)
        quickInspectListService.getInstalledItemsOnVehiclePerPos(locationObj.state.vehicleDetails.vehicleId).then((data) => {
            let sortedTires = sortTiresByWheelPosition(data.model, customWheelOrder)
            setTireDetails(sortedTires)
            setTireRemovalDetails(sortedTires.installedTires)
            setShowSpinner(false);
        })
        const checkBoxArray = Array.from({ length: tireDetails.installedTires.length }, () => false)
        setHandleCheckbox(checkBoxArray)
        const updatedData = tireRemovalDetails.map((obj) => ({ ...obj, rimDispositionId: 0 }))
        setTireRemovalDetails(updatedData)

    }, []);

    const handleCancel = () => {
        history.push(locationObj.state && locationObj.state.fromPath ? locationObj.state.fromPath : "/manage-vehicle")
    };

    const handleSubmit = (fromWhere) => {
        setSubmitFlag(true);
        const updatedNewData = tireRemovalDetails.map((obj) => ({ ...obj, currentTreadDepth: handleCurrentTreadDepth(obj) }))
        let updatedTireRemovalDetails =
            updatedNewData.filter((_, index) => handleCheckbox[index])
                .map((obj) => ({ ...obj, inspectionDate: new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString(), action: "Remove" }))
        if (validateForm() && (allowSubmit || fromWhere === "Popup")) {
            setShowSpinner(true);
            let successStatus = false;

            updatedTireRemovalDetails.map((tire) => {
                tire.rimId = tire.mountedRimId;
            }) // add rimId to the backend

            removeTireApi
                .removeFromVehicle(updatedTireRemovalDetails)
                .then((removalResponse) => {
                    if (removalResponse.didError === false) {
                        successStatus = true;

                    } else {
                        successStatus = false;
                    }
                    setShowSpinner(false);
                    history.push({
                        pathname: `/quick-inspect/${vehicleDetails.vehicleId}`,
                        state: {
                            tireId: tireDetails.tireId,
                            dispositionId: tireRemovalDetails.dispositionId,
                            updatedSection: "removeinstalledtire",
                            updateStatus: successStatus,
                            successMessage: notificationTexts.TIREREMOVED,
                            successMessageDesc: notificationTexts.TIREREMOVEDDESC.replace(
                                "#1#1",
                                updatedTireRemovalDetails.map((tire) => tire.tireSerialNo).toString()
                            ).replace("#2#2",''),
                        },
                    });
                });
        }
    };

    function updateTireRemovalDetails(field, value, index) {
        setTireRemovalDetails((tireRemovalDetails) => {
            const updatedDetails = [...tireRemovalDetails];
            if (!isNaN(value)) {
                updatedDetails[index] = { ...updatedDetails[index], [field]: parseInt(value, 10) };
            } else {
                updatedDetails[index] = { ...updatedDetails[index], [field]: null };
            }
            return updatedDetails;
        });
    }

    const checkBoxHandler = (event, index) => {
        if (index !== undefined) {
            // Toggle specific index checkbox
            const updateCheckbox = [...handleCheckbox];
            updateCheckbox[index] = !updateCheckbox[index];
            setHandleCheckbox(updateCheckbox);
        } else {
            // Toggle all checkboxes when "Select All" is clicked
            const updateCheckbox = handleCheckbox.map(() => event.target.checked);
            setHandleCheckbox(updateCheckbox);
        }
    };

    const handleFieldChange = (field, value, index) => {
        switch (field) {
            case "outtread":
                updateTireRemovalDetails("outsideTread", value, index);
                break;
            case "intread":
                updateTireRemovalDetails("insideTread", value, index);
                break;
            case "midtread":
                updateTireRemovalDetails("middleTread", value, index);
                break;
            case "pressuretype":
                setTireRemovalDetails((tireRemovalDetails) => {
                    const updatedDetails = [...tireRemovalDetails];
                    const pressureType = value ? "H" : "C";
                    updatedDetails[index] = { ...updatedDetails[index], pressureType };
                    return updatedDetails;
                });
                break;
            case "pressure":
                updateTireRemovalDetails("currentPressure", value, index);
                break;
            case "casing":
                updateTireRemovalDetails("casingConditionId", value, index);
                break;
            case "wear":
                updateTireRemovalDetails("wearConditionId", value, index);
                break;
            case "comments":
                setTireRemovalDetails((tireRemovalDetails) => {
                    const updatedDetails = [...tireRemovalDetails];
                    updatedDetails[index] = { ...updatedDetails[index], [field]: value };
                    return updatedDetails;
                });
                break;
            case "disposition":
                updateTireRemovalDetails("dispositionId", value, index);
                break;
            case "rimdisposition":
                updateTireRemovalDetails("rimDispositionId", value, index);
                break;
            default:
                break;
        }
    };

    const handleRemovalChange = (field, value, index) => {
        if (field === "removalreason") {
            const idx = masterData.tireRemovalReasons.findIndex((reason) => reason.reasonId === value);
            const updatedDetails = [...isRemovalReasonNewData];

            if (masterData.tireRemovalReasons[idx].reasonName.trim().toLowerCase() === "other") {
                updatedDetails[index] = { ...updatedDetails[index], [index]: true };
                setIsRemovalReasonNew(updatedDetails);
            } else {
                updatedDetails[index] = { ...updatedDetails[index], [index]: false };
                setIsRemovalReasonNew(updatedDetails);
            }
            updateTireRemovalDetails("removalReasonId", value, index);
        } else {
            if (field === "otherreason") {
                let date = new Date();
                date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

                const newRemovalDetail = {
                    requestDate: date,
                    fieldId: 11,
                    description: value,
                    status: "New",
                };

                // Update the removalMasterData array at the specified index
                const updatedRemovalMasterData = [...removalMasterData];
                updatedRemovalMasterData[index] = newRemovalDetail;
                // Set the updated removalMasterData
                setRemovalMasterData(updatedRemovalMasterData);
            }
        }

    };

    //validation
    const validateForm = () => {
        let isFormValid = true;
        let formErrors = [...formHelperTexts];
        tireRemovalDetails.forEach((tire, id) => {
            let tireErrors = {};
            if (handleCheckbox[id] === true) {
                Object.keys(tire).forEach((key) => {
                    
                    if (tire.mountedRimId && tire.rimDispositionId === 2 && tire.tireId && tire.dispositionId === 7) {  // both has no change
                        if (key === "dispositionId" && tire[key] === 7 || key ==="rimDispositionId" && tire[key] === 2) {
                            isFormValid = false;
                            tireErrors[key] = "select item to remove";
                        } 
                    } else if (tire.tireId && tire.dispositionId !== 7) { // tire has change
                        if (key === "outsideTread" || key === "currentPressure") {
                            if (tire[key] < 0 || tire[key] === null || isNaN(tire[key])) {
                                isFormValid = false;
                                tireErrors[key] = (tire[key] === null || isNaN(tire[key])) ? validationTexts.MISSINGFIELD : validationTexts.INVALIDFIELD;
                            }
                        } else if (key === "removalReasonId") {
                            if (tire[key] === 0 || tire[key] === undefined || tire[key] === null) {
                                isFormValid = false;
                                tireErrors[key] = "This field is Required";
                            }
                            if (tire[key] === otherRemovalReasonId && removalMasterData.length === 0) {
                                isFormValid = false;
                                tireErrors["otherreason"] = "This field is required";
                            }
                        } else if (key === "insideTread" || key === "middleTread") {
                            if (noOfReadings === 3) {
                                if (tire[key] < 0 || tire[key] === null || isNaN(tire[key])) {
                                    isFormValid = false;
                                    tireErrors[key] = (tire[key] === null || isNaN(tire[key])) ? validationTexts.MISSINGFIELD : validationTexts.INVALIDFIELD;
                                }
                            }
                            if ((noOfReadings === 2 || noOfReadings === 3) && key === "insideTread") {
                                if (tire[key] < 0 || tire[key] === null || isNaN(tire[key])) {
                                    isFormValid = false;
                                    tireErrors[key] = (tire[key] === null || isNaN(tire[key])) ? validationTexts.MISSINGFIELD : validationTexts.INVALIDFIELD;
                                }
                            }
                        }
                    } else if (tire.mountedRimId && tire.rimDispositionId === 2) { // rim has no change (tire will not be changed) 
                        if (key === "rimDispositionId" && tire[key] === 2) {
                            isFormValid = false;
                            tireErrors[key] = validationTexts.MISSINGFIELD;
                        }
                    } else {
                        isFormValid = true;
                    }
                });
            } else {
                tireErrors = {};
            }
            formErrors[id] = tireErrors;
        });

        setFormHelperTexts(formErrors);
        setIsFormValidStatus(isFormValid)
        return isFormValid;
    };

    return (
        <React.Fragment>
            <div className="standalone-new-header-container">
                <div className="remove-tire-main-page-container">
                    <div className="my-profile-upper-deck">
                        <div className="background-map-image">
                            <div className="dashboard-header">
                                <div>
                                    <Breadcrumbs breadcrumbs={myBreadcrumbs} />
                                    <div className="scrap-tires-title">{globalizationMapping.INSPECTNREMOVETIRE.REMOVE}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showSpinner ? (
                <Spinner />
            ) : (
                <div className="manage-vehicle-create-container create-vehicle-form">
                    <div className="remove-tire-container">
                        <div className="remove-tire-inner-container">
                            <div className="vehicle-detail-container">
                                <div className="vehicle-details-grid">
                                    <AllTireRemovalDetailsGrid
                                        vehicleDetails={vehicleDetails}
                                        tireDetails={tireDetails}
                                        locationDetails={locationDetails}
                                        selectedLocation={selectedLocation}
                                        vehicleSelected={vehicleSelected}
                                        allowSubmit={allowSubmit}
                                    />
                                </div>
                            </div>
                            <div className="helper-message">
                                {tireFormCaptions.MANDATORYFIELDS} <span className="required-mark">*</span>
                            </div>

                            <div className="all-tire-form-container">
                                <VehicleDiagram data={tireDetails.axleConfig} tireListData={tireDetails.installedTires} from="remove-all-tire-main" />
                            </div>

                            <img
                                alt="legendIcon"
                                style={{
                                    width: "100%",
                                    height: "2.3em",
                                    justifyItems: "center",
                                    marginTop: "2%"
                                }}
                                src={legendIcon}
                            />
                            <div>
                                <Box mx={5} mt={2} style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={handleCheckbox.every((value) => value)}
                                                onChange={(e) => checkBoxHandler(e)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Select All"
                                    />
                                </Box>
                                
                                {tireDetails.installedTires.map((tire, index) => (
                                    <AllTireRemovalForm
                                        unitPreferences={unitPreferences}
                                        // key={tire.wheelPosition}
                                        key={index}
                                        index={index}
                                        tireDetails={tire}
                                        onChangeListener={(field, val, index) => handleFieldChange(field, val, index)}
                                        tireRemovalData={tireRemovalDetails}
                                        tireDetailsData={tireDetails}
                                        casingConditionList={casingConditions}
                                        wearConditionList={wearConditions}
                                        masterDataSet={masterData}
                                        onRemovalReasonChange={(field, value, index) => handleRemovalChange(field, value, index)}
                                        removalMasterData={removalMasterData}
                                        isRemovalReasonNewData={isRemovalReasonNewData}
                                        noOfReadings={noOfReadings}
                                        handleCheckbox={handleCheckbox}
                                        checkBoxHandler={checkBoxHandler}
                                        formHelperTexts={formHelperTexts}
                                    />
                                ))}
                            </div>

                            <hr className="hr-divider-action" />

                            <div className="action-buttons">
                                <Button className={styling.cancel} variant="contained" color="secondary" onClick={handleCancel}>
                                {tireFormCaptions.CANCEL}
                                </Button>
                                {handleCheckbox.includes(true) ? 
                                <Button className={styling.submitButton} style={{color:'white'}} variant="contained" color="primary" onClick={() => handleSubmit("Submit")}>
                                    {tireFormCaptions.SUBMIT}
                                </Button> :
                                <Button className={styling.disabledButton} variant="contained" color="primary"
                                    disabled>
                                        {tireFormCaptions.SUBMIT}
                                </Button> 
                                }
                               
                            </div>

                            <Modal
                                open={!allowSubmit && submitFlag && isFormValidStatus}
                                onClose={() => setSubmitFlag(false)}
                                className="header-change-modal-style"
                                disableScrollLock={true}
                            >
                                <AlertPopup
                                    description="Alert"
                                    message="You haven't updated the hours and Miles of the Vehicle. Do you want to proceed?"
                                    screen="RemoveAllTire"
                                    allowSubmit={setAllowSubmit}
                                    onSave={handleSubmit}
                                    onClose={() => setSubmitFlag(false)}
                                />
                            </Modal>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default RemoveAllTire;
