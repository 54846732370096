import React from "react";
import Moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../../common-modal/CommonPopup.css";
import Base64Img from "./FleetImageUtils";
import Globalization from "../../../../globalization";
import CommonUtils from "../../../../services/utils";

const globalizationMapping = new Globalization();
const commonUtils = new CommonUtils();
let userPreference = JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue"));
let distanceUnit = "";
if (userPreference) {
  distanceUnit = userPreference.toLowerCase() === "metric" ? globalizationMapping["REPORTTABLE"]["HOURSMILESMET"] : globalizationMapping["REPORTTABLE"]["HOURSMILESIMP"];
}
pdfMake.vfs = pdfFonts.pdfMake.vfs;
class FleetSurveyPDF extends React.Component {

  tableColumnWidths = [];
  globalization = new Globalization();

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  /** cell making */
  makeCell = (content, headerOrData, options = {}, rowIndex = -1) => {

    let count = this.getCount();

    const makeCellFillColor = () => {
      if (headerOrData === "data") {
        if (rowIndex % 2) {
          return "#FAFAFB";
        } else {
          return "#FFFFFF";
        }
      } else if (headerOrData === "total") {
        return "#E6ECF6";
      } else if (headerOrData === "low") {
        return "#ccf2db";
      } else if (headerOrData === "avg") {
        return "#ffffaf";
      } else if (headerOrData === "high") {
        return "#ffe0a9";
      } else if (headerOrData === "vhigh") {
        return "#ffc4c6";
      } else {
        return "#1250B0";
      }
    };

    const makeCellFontSize = () => {
      if (headerOrData === "data") {
        if (count >= 12) {
          return 6;
        } else {
          return 9;
        }
      } else {
        if (count >= 12) {
          return 6;
        } else {
          return this.fontSize;
        }
      }
    };

    return Object.assign(
      {
        text: content,
        border: [false, false, false, false],
        borderColor:
          headerOrData === "data" || headerOrData === "low" || headerOrData === "avg" || headerOrData === "high" || headerOrData === "vhigh"
            ? ["#E0E0E0", "#E0E0E0", "#E0E0E0", "#E0E0E0"]
            : ["#456CAD", "#456CAD", "#456CAD", "#456CAD"],
        fontSize: makeCellFontSize(),
        
        fillColor: makeCellFillColor(),
        color: headerOrData === "data" || headerOrData === "total" ? "#2E2E2E" : "white"
      },
      options
    );
  };
  //-- Format the table cells for presentation.
  thl = (content, headerOrData, options = {}, rowIndex = -1) => {
    return this.makeCell(content, headerOrData, Object.assign({ bold: true, alignment: "left", fontSize: 12, padding: 10 }, options, rowIndex));
  };
  tdl = (content, headerOrData, options = {}, rowIndex = -1) => {
    return this.makeCell(content, headerOrData, Object.assign({ bold: false, alignment: "left", fontSize: 9 }, options, rowIndex));
  };
  fontSize = 9;

  getCount = () => {
    let count = 0;
    Object.values(this.props.tableData.selectedColShow).forEach((val) => {
      if (val === true) count++;
      return count;
    });
    return count;
  };

  async PDFMake() {
    return this.makeTableData();
  }

  generateRemovedTirePDF() {
    let docDefinition = {
      content: []
    };

    let tireTable;
    for (let index = 0; index < this.props.tableData.vehicleData.length; index++) {
      let eachData = this.props.tableData.vehicleData[index];
      tireTable = {
        width: "auto",
        table: {
          headerRows: 1,
          widths: this.tableColumnWidths,
          body: this.tableBody(index)
        }
      };

      let column1 = { columnGap: 0, columns: [] };
      let recordNum = index;
      let recordDetails = {
        table: {
          widths: ["*"],
          margin: [20, 0, 20, 10],
          body: [
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                color: "#074BA7",
                fillColor: "#E5F0FF",
                alignment: "left",
                columns: [
                  {
                    text: this.globalization.REPORTTABLE.DATE + ": \u200B\t",
                    width: "8%"
                  },
                  {
                    text: "  " + Moment(new Date()).format("DD/MM/YYYY"),
                    width: "15%",
                    bold: true
                  },
                  {
                    text: this.globalization.REPORTTABLE.ACCOUNTNAME + ": \u200B\t",
                    width: "10%"
                  },
                  {
                    text: eachData.ParentAccountName,
                    width: "30%",
                    bold: true
                  },
                  {
                    text: this.globalization.HEADER.LOCATION + ": \u200B\t",
                    width: "10%"
                  },
                  {
                    text: eachData.LocationName,
                    width: "30%",
                    bold: true
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                border: [false, false, false, false],
                color: "#074BA7",
                fillColor: "#E5F0FF",
                alignment: "left",
                columns: [
                  {
                    text: "Record No. ",
                    width: "8%"
                  },
                  {
                    text: ++recordNum,
                    width: "15%",
                    bold: true
                  },
                  {
                    text: "Inspected By : ",
                    width: "10%"
                  },
                  {
                    text: eachData.InspectionName,
                    bold: true,
                    width: "30%"
                  },
                  {
                    text: "Inspection ID : ",
                    width: "10%"
                  },
                  {
                    text: eachData.InspectionOrderId,
                    bold: true,
                    width: "30%"
                  }
                ]
              }
            ]
          ]
        }
      };
      if (index > 0) {
        recordDetails.table.body[0][0].pageBreak = "before";
      }

      let tireDetails = {
        width: "50%",
        alignment: "center",
        table: {
          // margin:[100,100,100,100],
          body: [
            [
              {
                
                fontSize: 9,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Vehicle Details",
                    bold: true
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 9,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Vehicle Id:",
                    width: 70
                  },
                  {
                    text: eachData.VehicleId,
                    width: 100
                  },
                  {
                    text: "Asset no:",
                    width: 50
                  },
                  {
                    text: eachData.AssetNumber,
                    width: 70
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 9,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Make/Model:",
                    width: 70
                  },
                  {
                    text: eachData.Manufacturer + "/" + eachData.ModelName,
                    width: 100
                  },
                  {
                    text: "Type : ",
                    width: 30
                  },
                  {
                    text: eachData.TypeName,
                    width: 70
                  },
                  {
                    text: distanceUnit + " : ",
                    width: 60
                  },
                  {
                    text: eachData.CurrentHours + "/" + eachData.CurrentMiles,
                    width: 100
                  }
                ]
              }
            ],

            [
              {
                
                fontSize: 9,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Operation Parameters",
                    bold: true
                    // width: "auto"
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 9,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Cutting : ",
                    width: 70
                  },
                  {
                    text: eachData.CuttingName,
                    width: 70
                  },
                  {
                    text: "Speed/Heat : ",
                    width: 70
                  },
                  {
                    text: eachData.SpeedName,
                    width: 150
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 9,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Loading Req: ",
                    width: 70
                  },
                  {
                    text: eachData.LoadingRequirement,
                    width: 70
                  },
                  {
                    text: "Recomm. Pressure: ",
                    width: 90
                  },
                  {
                    text: eachData.RecommendedPressure,
                    width: 130
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 12,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "            ",
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  "",
                  ""
                ]
              }
            ]
          ]
        }
      };

      let imageString = eachData.VehJsonData;
      let str;
      if (imageString !== null) {
        let mapObj = {
          GREEN: Base64Img.GREEN,
          RED: Base64Img.RED,
          YELLOW: Base64Img.YELLOW,
          ORANGE: Base64Img.ORANGE,
          EMPTY: Base64Img.EMPTY,
          LEGEND: Base64Img.LEGEND,
          AXLE1: Base64Img.AXLE1,
          AXLE2: Base64Img.AXLE2,
          AXLE3: Base64Img.AXLE3,
          AXLE4: Base64Img.AXLE4,
          AXLE5: Base64Img.AXLE5,
          AXLE6: Base64Img.AXLE6,
          AXLE7: Base64Img.AXLE7,
          AXLE8: Base64Img.AXLE8,
          AXLE9: Base64Img.AXLE9
        };
        str = imageString.replace(/GREEN|RED|YELLOW|ORANGE|EMPTY|LEGEND|AXLE1|AXLE2|AXLE3|AXLE4|AXLE5|AXLE6|AXLE7|AXLE8|AXLE9/gi, function (matched) {
          return mapObj[matched];
        });
      }

      if (str !== undefined) {
        let obj;
        const strToJS = new Function("return " + str);
        obj = strToJS();
        if (obj) {
          obj.width = "50%";
          obj.alignment = "center";
        }
        column1.columns.push(obj);
      }
      column1.columns.push(tireDetails);

      let column4 = { columnGap: 10, columns: [] };
      docDefinition.content.push(recordDetails);
      docDefinition.content.push(column1);
      docDefinition.content.push(tireTable);
      docDefinition.content.push(column4);
    }
    docDefinition.content.push({
      table: {
        headerRows: 1,
        body: [
          [" ", " ", " "],
          [" ", " ", " "],
          [" ", " ", " "],
          [" ", " ", " "]
        ]
      },
      layout: "noBorders"
    });
    docDefinition.content.push({
      table: {
        headerRows: 1,
        body: [
          [
            {
              text: "Total Records : " + this.props.tableData.rowsData.length,
              fontSize: 8
            }
          ]
        ]
      }
    });

    let applyFilterTable = false;
    if (this.props.tableData.hasOwnProperty("filterFieldsApplied")) {
      let checkHeadersList = Object.keys(this.props.tableData.filterFieldsApplied);
      for (const element of checkHeadersList) {
        if (this.props.tableData.filterFieldsApplied[element].length !== 0) {
          applyFilterTable = true;
          break;
        }
      }
    }

    if (applyFilterTable) {
      docDefinition.content.push({
        table: {
          headerRows: 1,
          body: [[" ", " ", " "]]
        },
        layout: "noBorders"
      });
      let filterHeaderList = Object.keys(this.props.tableData.filterFieldsApplied);
      docDefinition.content.push({
        table: {
          headerRows: 1,
          body: [
            [
              {
                text: "Report Filters and Options",
                fontSize: "12",
                border: [false, false, false, false],
                bold: true
              },
              { text: "", border: [false, false, false, false] }
            ],
            [
              {
                text: " ",
                fontSize: "8",
                border: [false, false, false, true],
                bold: true
              },
              { text: "", border: [false, false, false, true] }
            ]
          ]
        },
        layout: {
          hLineColor(i, _node) {
            return i === 2 ? "#1B57B2" : "#E0E0E0";
          }
        }
      });
      filterHeaderList.forEach((filterHeader) => {
        if (this.props.tableData.filterFieldsApplied[filterHeader].length !== 0) {
          let filterArray = [
            {
              text: filterHeader + ":",
              fontSize: "9",
              bold: true,
              border: [false, false, false, true]
            }
          ];
          let filterHeaderValues = "";
          this.props.tableData.filterFieldsApplied[filterHeader].forEach((filterHeaderValue, index) => {
            if (index === 0) filterHeaderValues = filterHeaderValue;
            else filterHeaderValues += ", " + filterHeaderValue;
          });
          filterArray.push({
            text: filterHeaderValues,
            fontSize: "9",
            border: [false, false, false, true]
          });
          docDefinition.content[docDefinition.content.length - 1].table.body.push(filterArray);
        }
      });
    }
    return docDefinition.content;
  }


  tableBody = (tireNum) => {
    const body = [];
    const TableRow = [];

    let count = 0;
    const setHeader = (headerText) => {
      switch (headerText) {

        case "INSTD Date":
          headerText = `Installed 
          Date`;
          break;

        case "Insp Date":
          headerText = `Inspection
          Date`;
          break;

        default:
          break;
      }
      return headerText;

    }
    Object.values(this.props.tableData.selectedColShow).forEach((val) => {
      if (val === true) count++;
      return count;
    });
    const percent = 100 / count + "%"; //comment header not included
    this.props.tableData.headCells.forEach((headCell, index) => {
      body.splice(0, body.length);
      if (this.props.tableData.selectedColShow[headCell.value] === true) {



        let headCellName = commonUtils.pdfHeaderNameShorter(headCell.key);
        let headerText = setHeader(headCellName);

        if (headerText !== "InspectionComments") {

          TableRow.push(
            this.thl(headerText, "header", {
              border: [false, false, false, true],
              fontSize: 7,
              fillColor: "white",
              color: "#2E2E2E",
            }, -1)
          );
        }

        if (count >= 15) {
          if (headCell.key === "WheelPosition") {
            this.tableColumnWidths.push({
              width: "3%",
              _minWidth: "3%",
              _maxWidth: "3%",
              _calcWidth: "3%"
            });
          } else if (headCell.key === "Rating" || headCell.key === "PercentageWorn") {
            this.tableColumnWidths.push({
              width: "4%",
              _minWidth: "4%",
              _maxWidth: "4%",
              _calcWidth: "4%"
            });
          } else if (headCell.key === "Pressure") {
            this.tableColumnWidths.push({
              width: "5%",
              _minWidth: "5%",
              _maxWidth: "5%",
              _calcWidth: "5%"
            });
          } else if (headCell.key === "TotalHours" || headCell.key === "TotalKm" || headCell.key === "CompoundName") {
            this.tableColumnWidths.push({
              width: "5.5%",
              _minWidth: "5.5%",
              _maxWidth: "5.5%",
              _calcWidth: "5.5%"
            });
          } else if (headCell.key === "Manufacturer" || headCell.key === "TypeName"
            || headCell.key === "SerialNumber" || headCell.key === "BrandNumber" || headCell.key === "RimSerialNumber") {
            this.tableColumnWidths.push({
              width: "9%",
              _minWidth: "9%",
              _maxWidth: "9%",
              _calcWidth: "9%"
            });
          }
          else {
            this.tableColumnWidths.push({
              width: percent,
              _minWidth: percent,
              _maxWidth: percent,
              _calcWidth: percent
            });
          }
        } else {
          this.tableColumnWidths.push({
            width: percent,
            _minWidth: percent,
            _maxWidth: percent,
            _calcWidth: percent
          });
        }
        if (this.tableColumnWidths.length > count) this.tableColumnWidths.length = count;
      }
    });
    //   this.props.tableData.rowsData.forEach((row, index) => {
    body.splice(0, body.length);
    body.push();
    body.push(TableRow);
    this.props.tableData.rowsData[tireNum].forEach((item, index) => {
      const tableRow = [],
        lastRow = [];
      let last = this.props.tableData.rowsData.length - 1 > index ? 0 : 1;
      if (last === 1 && !this.props.tableData.avgValues) {
        last = 0;
      }

      // eslint-disable-next-line array-callback-return
      Object.keys(item).forEach((key, _id) => {
        if (key !== "Key" && this.props.tableData.selectedColShow[key] === true) {
          const wornTextInfo = () => {
            if (key === "PercentageWorn") {
              if (item[key] <= 25) {
                return "low";
              } else if (item[key] > 25 && item[key] <= 50) {
                return "avg";
              } else if (item[key] > 50 && item[key] <= 75) {
                return "high";
              } else {
                return "vhigh";
              }
            } else {
              return "data";
            }
          };
          const percentageAdder = (item) => {
            if (key === "PercentageWorn" || key === 'PercentRecomPressure') {
              return item + '%';
            } else {
              return item;
            }
          }
          if (key !== "InspectionComments") {
            let wornText = wornTextInfo();
            tableRow.push(
              this.tdl(percentageAdder(item[key]), wornText, {
                fontWeight: 400,
                color: "#333333",
                fontSize: 6,
                border: last === 1 ? [false, false, false, false] : [false, false, false, true]
              }, index)
            );
          } else if (key === "InspectionComments" && this.props.tableData.selectedColShow.InspectionComments) {
            let headCellLength = Object.keys(this.props.tableData.selectedColShow).length ? Object.keys(this.props.tableData.selectedColShow).length : this.props.tableData.headCells.length;
            lastRow.push(
              this.thl("", "header", {
                border: [false, false, false, true],
                borderColor: ["#E0E0E0", "#E0E0E0", "#E0E0E0", "#E0E0E0"],
                fontSize: 7,
                fillColor: "#F7FAFF",
                color: "#2E2E2E",
                bold: true
              }, -1),
              this.tdl(`${this.globalization.FLEETSURVEY.COMMENTS}: ${item[key]}`, "data", {
                fontWeight: 400,
                color: "#333333",
                colSpan: count === headCellLength ? headCellLength - 2 : count - 2, // -2 is reserve part for the comment column
                fontSize: 6,
                border: last === 1 ? [false, false, false, false] : [false, false, false, true],
                fillColor: "#F7FAFF"
              }, index)
            );
          } else {
            return;
          }
        }
      });
      // eslint-disable-next-line array-callback-return

      body.push(tableRow);
      if (this.props.tableData.selectedColShow.InspectionComments) {
        body.push(lastRow);
      }
    });
    // });
    return body;
  };

  /** Doc definition */
  async createDocumentDefinition() {
    const baseDocDefinition = {
      pageSize: "A4",
      pageMargins: [20, 65, 20, 55],
      pageOrientation: "landscape",
      content: []
    };
    const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
    docDefinition.header = await this.addPdfMakeHeader();
    docDefinition.footer = this.addPdfMakeFooter();
    const temp = this.generateRemovedTirePDF();
    docDefinition.content.push(temp);
    const defaultFont = {defaultStyle: {
      font: 'Barlow'
    }}
    docDefinition.defaultStyle = defaultFont;
    return docDefinition;
  }

  /** header body and footer */
  async addPdfMakeHeader() {

    return (_currentPage, _pageCount) => {
      return {
        margin: [20, 20, 20, 0],
        stack: [
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    border: [false, false, false, false],
                    
                    color: "white",
                    fillColor: "#1250B0",
                    alignment: "right",
                    columns: [
                      {
                        svg: commonUtils.EmTrackSVGLogo,
                        fit: [50, 80],
                        width: "5%",
                        margin: [0, 2, 0, 2],
                        alignment: "left"
                      },
                      {
                        text: " " + commonUtils.pdfReportName(this.props.tableData.reportName),
                        bold: true,
                        alignment: "center",
                        fontSize: 14,
                        margin: [0, 10, 0, 2]
                      },
                      {
                        svg: commonUtils.GoodyearSVGLogo,
                        alignment: "right",
                        width: "10%",
                        fit: [90, 150]
                      },
                    ]
                  }
                ]
              ]
            }
          }
        ]
      };
    };
  }

  addPdfMakeFooter = () => {
    let footerText = "©" + new Date().getFullYear() + " The Goodyear Tire & Rubber Company ";
    return (currentPage, pageCount) => {
      // you can apply any logic and return any valid pdfmake element
      return {
        margin: [20, 0, 20, 0],
        border: [false, false, false, false],
        table: {
          widths: ["*"],
          body: [
            [
              {
                border: [false, false, false, false],
                fontSize: 9,
                color: "white",
                fillColor: "#1250B0",
                alignment: "left",
                columns: [
                  {
                    text: footerText,
                    alignment: "left",
                    width: "40%",
                    margin: [0, 13, 0, 0],
                    bold: true
                  },
                  {
                    text: "PAGE " + currentPage.toString() + "/" + pageCount.toString(),
                    alignment: "right",
                    margin: [0, 13, 0, 0],
                    fontWeight: 400
                  }
                ]
              }
            ]
          ]
        }
      };
    };
  };

  async makeTableData() {
    // -- Create a base document template for the reports.

    const docDefinition = await this.createDocumentDefinition();

  //   pdfMake.fonts = {
  //     Barlow: {
  //       normal: 'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap',
  //       bold: 'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap',
  //       // italics: 'https://example.com/fonts/fontFile3.ttf',
  //       // bolditalics: 'https://example.com/fonts/fontFile4.ttf'
  //     },
   
  //     // download default Roboto font from cdnjs.com
  //     Roboto: {
  //       normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
  //       bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
  //       italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
  //       bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  //     },
   
  //     // example of usage fonts in collection
  //     PingFangSC: {
  //       normal: ['https://example.com/fonts/pingfang.ttc', 'PingFangSC-Regular'],
  //       bold: ['https://example.com/fonts/pingfang.ttc', 'PingFangSC-Semibold'],
  //     }
  //  }
    return pdfMake.createPdf(docDefinition );
  }
  render() {
    return <div></div>;
  }
}

export default FleetSurveyPDF;
