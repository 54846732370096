import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';

export default class SurveyTemplateService {
    cs;
    constructor(){
         this.cs = new CommonService(); 
    }
    getSurveyTemplateMasterConfig = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.surveyTemplateMasterConfig,'get',req)
    }
}