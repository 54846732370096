import React, { useEffect, useState } from "react";
import "../../../../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../../services/utils";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    height: 625
  },
  bgYellow: {
    backgroundColor: "#fff9ce"
  },
  bgPurple: {
    backgroundColor: "#1250B0 !important"
  },
  whiteText: {
    color: "#FFF !important",
    fontWeight: " 400 !important"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const TireNeedsForecastTable = ({ tableData, showColumns, _filterApplied, timeArray }) => {
  const [tireNeedsForecastTableData, setTireNeedsForecastTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const commonUtils = new CommonUtils();

  useEffect(() => {
    setTireNeedsForecastTableData(tableData);
    setPage(0);
    let arr = commonUtils.rowPerPageSelector(tireNeedsForecastTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
  }, [tableData, showColumns]);

  let lastPage = Math.floor(tireNeedsForecastTableData.rowsData.length / rowsPerPage);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {tireNeedsForecastTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className="table-head-row"
                        key={headCell.keyIndex}
                        align="left">
                        {headCell.value === 'maxcost' ? 'Max Cost' : headCell.label}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {tireNeedsForecastTableData.rowsData.length > 0 && (
                <TableBody>
                  {
                    tireNeedsForecastTableData.rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      if (row.VehicleNumber === "Total Tires" || row.VehicleNumber === "Total Cost") {
                        return (
                          <TableRow className={"table-row " + classes.bgYellow} tabIndex={-1} key={row.VehicleNumber}>
                            {showColumns.Manufacturer && row.VehicleNumber === "Total Tires" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.Manufacturer}{" "}
                              </TableCell>
                            )}
                            {showColumns.VehicleNumber && row.VehicleNumber === "Total Tires" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.VehicleNumber}{" "}
                              </TableCell>
                            )}
                            {showColumns.Size && row.VehicleNumber === "Total Tires" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.Size}{" "}
                              </TableCell>
                            )}
                            {showColumns.Type && row.VehicleNumber === "Total Tires" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.Type}{" "}
                              </TableCell>
                            )}
                            {showColumns.maxcost && row.VehicleNumber === "Total Tires" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.maxcost}
                              </TableCell>
                            )}
                            {showColumns.Manufacturer && row.VehicleNumber === "Total Cost" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.Manufacturer}{" "}
                              </TableCell>
                            )}
                            {showColumns.VehicleNumber && row.VehicleNumber === "Total Cost" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.VehicleNumber}{" "}
                              </TableCell>
                            )}
                            {showColumns.Size && row.VehicleNumber === "Total Cost" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.Size}{" "}
                              </TableCell>
                            )}
                            {showColumns.Type && row.VehicleNumber === "Total Cost" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.Type}{" "}
                              </TableCell>
                            )}
                            {showColumns.maxcost && row.VehicleNumber === "Total Cost" && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.maxcost}
                              </TableCell>
                            )}
                            {timeArray.map((field) => {
                              if (showColumns[field] && row.VehicleNumber === "Total Tires") {
                                return (
                                  <TableCell key={row[field]} className="total-avg-text" style={{ borderBottom: "none" }}>
                                    {row[field]}
                                  </TableCell>
                                );
                              } else return (
                                <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                  {" "}
                                </TableCell>
                              );
                            })}
                            {showColumns.TotalTires && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.TotalTires}
                              </TableCell>
                            )}
                            {showColumns.TotalCost && (
                              <TableCell className="total-avg-text" style={{ borderBottom: "none" }}>
                                {row.TotalCost}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      } else {
                        return (
                          <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={row.Key}>
                            {showColumns.Manufacturer && <TableCell className="table-content-text">{row.Manufacturer}</TableCell>}
                            {showColumns.VehicleNumber && <TableCell className="table-content-text">{row.VehicleNumber}</TableCell>}
                            {showColumns.Size && <TableCell className="table-content-text">{row.Size}</TableCell>}
                            {showColumns.Type && <TableCell className="table-content-text">{row.Type}</TableCell>}
                            {showColumns.maxcost && <TableCell className="table-content-text">{row.maxcost}</TableCell>}
                            {timeArray.map((field) => {
                              if (showColumns[field]) return <TableCell className="table-content-text">{row[field]}</TableCell>;
                              else return null;
                            })}
                            {showColumns.TotalTires && <TableCell className="table-content-text">{row.TotalTires}</TableCell>}
                            {showColumns.TotalCost && <TableCell className="table-content-text">{row.TotalCost}</TableCell>}
                          </TableRow>
                        );
                      }
                    })
                  }

                  {((page === lastPage - 1 && tireNeedsForecastTableData.rowsData.length % rowsPerPage === 0) ||
                    (page === lastPage && tireNeedsForecastTableData.rowsData.length % rowsPerPage !== 0) ||
                    rowsPerPage === tireNeedsForecastTableData.rowsData.length) && (
                      <>
                        <TableRow className={"total-avg-row " + classes.bgPurple}>
                          {showColumns.Manufacturer && (
                            <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}>
                              Grand Total
                            </TableCell>
                          )}
                          {showColumns.VehicleNumber && (
                            <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}>
                              Tires
                            </TableCell>
                          )}
                          {showColumns.Size && <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}></TableCell>}
                          {showColumns.Type && <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}></TableCell>}
                          {showColumns.maxcost && <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}></TableCell>}
                          {timeArray.map((field, index) => {
                            if (showColumns[field])
                              return (
                                <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}>
                                  {tireNeedsForecastTableData.avgValues[field]}
                                </TableCell>
                              );
                            else return null;
                          })}
                          {showColumns.TotalTires && (
                            <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}>
                              {tireNeedsForecastTableData.avgValues["TotalTires"]}
                            </TableCell>
                          )}
                          {showColumns.TotalCost && <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}></TableCell>}
                        </TableRow>
                        <TableRow className={"total-avg-row " + classes.bgPurple}>
                          {showColumns.Manufacturer && <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}></TableCell>}
                          {showColumns.VehicleNumber && (
                            <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}>
                              {tireNeedsForecastTableData.totalCost["VehicleNumber"]}
                            </TableCell>
                          )}
                          {showColumns.Size && <TableCell className={"total-avg-text " + classes.whiteText} style={{ borderBottom: "none", color: "#fff" }}></TableCell>}
                          {showColumns.Type && <TableCell className={"total-avg-text " + classes.whiteText}></TableCell>}
                          {showColumns.maxcost && <TableCell className={"total-avg-text " + classes.whiteText}></TableCell>}
                          {timeArray.map((field, index) => {
                            if (showColumns[field]) return <TableCell className={"total-avg-text " + classes.whiteText}>{tireNeedsForecastTableData.totalCost[field]}</TableCell>;
                            else return null;
                          })}
                          {showColumns.TotalTires && <TableCell className={"total-avg-text " + classes.whiteText}></TableCell>}
                          {showColumns.TotalCost && <TableCell className={"total-avg-text " + classes.whiteText}>{tireNeedsForecastTableData.totalCost["TotalCost"]}</TableCell>}
                        </TableRow>
                      </>
                    )}
                </TableBody>
              )}
              {tireNeedsForecastTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={tireNeedsForecastTableData.headCells.length} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={tireNeedsForecastTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div >
  );
};

export default TireNeedsForecastTable;
