import { Fade, Grid, InputAdornment, makeStyles, OutlinedInput, Paper, Popper, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import infoLogo from "../../../assets/Tooltip.svg";
import "./create-tire.css";
import ExpandMoreStyledIcon from '../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon';
import CommonUtils from "../../../services/utils";

const useStyles = makeStyles({
    adorn: {
        "& .MuiTypography-colorTextSecondary": {
            color: '#000000',
            fontWeight: '800'
        }
    },
    popper: {
        padding: 10,
        border: '1px solid #1C72D0',
        borderRadius: '5px',
        background: '#B1D2FF',
        fontSize: '12px'
    },
});

const TireCosts = props => {
    const commonUtils = new CommonUtils();
    const styles = useStyles();
    const tireFillTypes = props.masterDataSet.tireFillTypes;
    const [currency, setCurrency] = useState("");

    useEffect(() => {
        setCurrency(props.accLocationInfo.currency);
    }, [props.accLocationInfo]);

    const [popperState, setPopperState] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopperOpen = target => {
        setAnchorEl(anchorEl ? null : target);
        setPopperState(true);
    };

    const handlePopperClose = () => {
        setAnchorEl(null);
        setPopperState(false);
    };

    const netCostValue = () => {
        let netCost = props.stateVar.netCost = props.netCostCalculated;
        return netCost;
    }

    return (
        <React.Fragment>
            <Grid className="step-form-inner-container" container spacing={2} >
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.PURCHASECOST}</p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.purchaseCost}
                        onChange={e => {props.onChangeListener('purCost', commonUtils.minmaxFloat(e.target.value, 0, 999999))}}
                        endAdornment={<InputAdornment className={styles.adorn} position="end">{currency}</InputAdornment>}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.CASINGVALUE}</p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.casingValue}
                        onChange={e => {props.onChangeListener('casingval', commonUtils.minmaxFloat(e.target.value, 0, 999999))}}
                        endAdornment={<InputAdornment className={styles.adorn} position="end">{currency}</InputAdornment>}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.FILLTYPE}</p>
                    <Select
                        required
                        native
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.fillTypeId ? props.stateVar.fillTypeId : 0}
                        IconComponent={ExpandMoreStyledIcon}
                        onChange={e => props.onChangeListener('filltype', parseInt(e.target.value, 10))}
                        displayEmpty>
                        <option key={0} value={""}> {props.fieldNames.DROPDOWNPLACEHOLDER} </option>
                        {tireFillTypes.map((filltype, key) =>
                            <option key={key + 1} value={filltype.fillTypeId} className="list-color-change">{filltype.fillTypeName}</option>
                        )}
                    </Select>
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.FILLCOST}</p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.fillCost}
                        onChange={e => {props.onChangeListener('fillcost', commonUtils.minmaxFloat(e.target.value, 0, 999999))}}
                        endAdornment={<InputAdornment className={styles.adorn} position="end">{currency}</InputAdornment>}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.REPAIRCOUNT}</p>
                    <TextField
                        className="enter-detail-input-box disabled-input"
                        variant="outlined"
                        value={props.stateVar.repairCount}
                        onChange={e => props.onChangeListener('rprcount', commonUtils.removeLeadingSpaces(e.target.value))}
                        disabled
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.REPAIRCOST}</p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box disabled-input"
                        variant="outlined"
                        value={props.stateVar.repairCost}
                        endAdornment={<InputAdornment className={styles.adorn} position="end">{currency}</InputAdornment>}
                        disabled
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.RETREADCOUNT}</p>
                    <TextField
                        className="enter-detail-input-box disabled-input"
                        variant="outlined"
                        value={props.stateVar.retreadCount}
                        disabled
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.RETREADCOST}</p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box disabled-input"
                        variant="outlined"
                        value={props.stateVar.retreadCost}
                        endAdornment={<InputAdornment className={styles.adorn} position="end">{currency}</InputAdornment>}
                        disabled
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.WARRANTYADJ}</p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.warrantyAdjustment}
                        onChange={e => {props.onChangeListener('warntyadj', commonUtils.minmaxFloat(e.target.value, 0, 999999))}}
                        endAdornment={<InputAdornment className={styles.adorn} position="end">{currency}</InputAdornment>}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.COSTADJ}</p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.costAdjustment}
                        onChange={e => {props.onChangeListener('costadj', commonUtils.minmaxFloat(e.target.value, 0, 999999))}}
                        endAdornment={<InputAdornment className={styles.adorn} position="end">{currency}</InputAdornment>}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.SOLDAMT}</p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.soldAmount}
                        onChange={e => {props.onChangeListener('soldamt', commonUtils.minmaxFloat(e.target.value, 0, 999999))}}
                        endAdornment={<InputAdornment className={styles.adorn} position="end">{currency}</InputAdornment>}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">
                        {props.fieldNames.NETCOST}
                        <img
                            style={{ marginInlineStart: '5px' }}
                            // onClick={handleInfoClick}
                            onMouseEnter={e => handlePopperOpen(e.currentTarget)}
                            onMouseLeave={handlePopperClose}
                            alt="info"
                            src={infoLogo}
                        // ref={anchorRef}
                        />
                        <Popper
                            open={popperState}
                            placement="top"
                            anchorEl={anchorEl}
                            transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper className={styles.popper}>
                                        <p className="tirecosts-popper-title">{props.fieldNames.FORMULA}</p>
                                        <p className="tirecosts-popper-desc" >
                                            ({props.fieldNames.PURCHASECOST} + {props.fieldNames.REPAIRCOST} + {props.fieldNames.FILLCOST} + {props.fieldNames.RETREADCOST})
                                        </p>
                                        <p className="tirecosts-popper-desc" >-
                                            ({props.fieldNames.CASINGVALUE} + {props.fieldNames.COSTADJ} + {props.fieldNames.SOLDAMT} + {props.fieldNames.WARRANTYADJ})
                                        </p>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box disabled-input"
                        variant="outlined"
                        endAdornment={<InputAdornment className={styles.adorn} position="end">{currency}</InputAdornment>}
                        value={netCostValue()}
                        disabled
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default TireCosts;

