import React, { useEffect, useState, useContext } from "react";
import "./MonthlyTireLife.css";
import { Card, CardContent, InputLabel, TextField, Grid, Button } from "@material-ui/core";
import ScrapTiresNavigation from "../../../scrap-tires-navigation/components/ScrapTiresNavigation";
import MonthlyTireLifeDataSetter from "../../../../../services/MonthlyTireLifeUtils";
import MonthlyTireLifeGraph from "../graph/MonthlyTireLifeGraph";
import MonthlyTireLifeTable from "../table/MonthlyTireLifeTable";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import MonthlyTireLifeFilter from "../filter/MonthlyTireLifeFilter";

const MonthlyTireLife = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const globalizationMapping = new Globalization();
  const tiresService = new TiresService();
  const monthlyTireLifeDataSetter = new MonthlyTireLifeDataSetter();
  const commonUtils = new CommonUtils();
  const [showSpinner, setShowSpinner] = useState(true);
  const [monthlyTireLifeData, setMonthlyTireLifeData] = useState();
  const [originalMonthlyTireLifeData, setOriginalMonthlyTireLifeData] = useState();

  const [filterApplied, setFilterApplied] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(monthlyTireLifeData);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [noData, setNoData] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [targetValue, setTargetValue] = useState(0);
  const [stretchTargetValue, setStretchTargetValue] = useState(0);
  const [monthSelect, setMonthSelect] = useState();
  const [selectedMonthYear, setSelectedMonthYear] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  });

  const [filterOptions, setFilterOptions] = useState({
    categories: [
      {
        name: globalizationMapping.REPORTTABLE.MANUFACTURER,
        value: "tireManufacturers"
      },
      { name: globalizationMapping.REPORTTABLE.SIZE, value: "tireSizes" },
      { name: globalizationMapping.REPORTTABLE.TYPE, value: "tireTypes" }
    ],
    categoryData: {
      tireManufacturers: [],
      tireSizes: [],
      tireTypes: []
    }
  });
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const excludeUsedTires = false;

  useEffect(() => {
    MonthlyTireLifeResponseData(excludeUsedTires);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const MonthlyTireLifeResponseData = (excludeUsedTiresValue, filterApplied = false, appliedFilter = {}) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {};
    if (filterApplied)
      requestObject = {
        reportOption: "",
        reportName: "Monthly_Tire_Life_Report",
        accountIds: accountIds,
        locationIds: locationIds,
        year: selectedMonthYear.year,
        month: selectedMonthYear.month,
        tireManufacturerNames: appliedFilter.tireManufacturers ? appliedFilter.tireManufacturers.join(",") : "",
        tireSizeName: appliedFilter.tireSizes ? appliedFilter.tireSizes.join(",") : "",
        tireTypeName: appliedFilter.tireTypes ? appliedFilter.tireTypes.join(",") : "",
        usedTires: excludeUsedTiresValue.toString()
      };
    else 
      requestObject = {
        reportOption: "",
        reportName: "Monthly_Tire_Life_Report",
        accountIds: accountIds,
        locationIds: locationIds,
        year: selectedMonthYear.year,
        month: selectedMonthYear.month,
        tireManufacturerNames: "",
        tireSizeName: "",
        tireTypeName: "",
        usedTires: excludeUsedTiresValue.toString()
      };
    
    let filterOptions = {
      categories: [
        {
          name: globalizationMapping.REPORTTABLE.MANUFACTURER,
          value: "tireManufacturers"
        },
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "tireSizes" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "tireTypes" }
      ],
      categoryData: {
        tireManufacturers: [],
        tireSizes: [],
        tireTypes: []
      }
    };

    setShowSpinner(true);

    tiresService.getTiresReport(requestObject).then((data) => {
      let byTireSizeInfo = monthlyTireLifeDataSetter.setData(data);
      setOriginalMonthlyTireLifeData(byTireSizeInfo);
      let filterOptionsInfo = monthlyTireLifeDataSetter.setFilterOptionsData(filterOptions, data.model.reportData[0]);
      setMonthlyTireLifeData(byTireSizeInfo);
      byTireSizeInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      if (!filterApplied) setFilterOptions(filterOptionsInfo);
      setPrintData({
        reportType: "",
        reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.MonthlyTireLife,
        rowsData: byTireSizeInfo.rowsData,
        headCells: byTireSizeInfo.headCells,
        selectedColShow: byTireSizeInfo.selectedColShow
      });
      setShowSpinner(false);
      if (filterApplied) {
        setPrintData({
          ...printData,
          rowsData: byTireSizeInfo.rowsData,
          filterApplied: true,
          filterFieldsApplied: appliedFilter
        });
      }
    });
  };

  const handleUpdateReport = () => {
    MonthlyTireLifeResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
  };
  const handleDateChange = (value) => {
    setMonthSelect(value);
    let date = new Date(value);
    setSelectedMonthYear({
      month: date.getMonth() + 1,
      year: date.getFullYear()
    });
  };

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES

  // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    setCurrentFilter(appliedFilter)
    setFilterApplied(true);
    setFilterFieldsApplied(appliedFilter);

    let originalTableDataStore = originalMonthlyTireLifeData?.rowsData;
    let filteredData = monthlyTireLifeDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setMonthlyTireLifeData({
      ...monthlyTireLifeData,
      rowsData: filteredData,
      avgValues: monthlyTireLifeDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: monthlyTireLifeDataSetter.avgValues(filteredData)
    });
    setOnlyFilterApplied(true);
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    Object.values(appliedFilter).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);
    setFilterFieldsApplied(appliedFilter);
    MonthlyTireLifeResponseData(excludeUsedTires, true, appliedFilter);

  };


  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter({
      tireManufacturers: [],
      tireSizes: [],
      tireTypes: []})
    setShowFilter(false);
    setFilterApplied(false);
    setOnlyFilterApplied(false);
    MonthlyTireLifeResponseData(excludeUsedTires);

    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
  };

  return (
    <div>
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-tire-size-container">
          <Card className="by-tire-size-card">
            <CardContent className="graph-card">
              <MonthlyTireLifeGraph monthlyTireLifeGraphData={monthlyTireLifeData} targetValue={targetValue} stretchValue={stretchTargetValue} />
            </CardContent>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters-monthly-tire-life">
                  <Grid container>
                    <Grid item xs={3}>
                      <div className="tire-forecast-dropdown">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.MONTHLYTIRELIFE.YearandMonth}</span>
                        </InputLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            views={["year", "month"]}
                            value={monthSelect}
                            style={{
                              border: "1px solid #c4c4c4",
                              borderRadius: " 5px"
                            }}
                            onChange={(event) => {
                              handleDateChange(event);
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="tire-forecast-dropdown">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.MONTHLYTIRELIFE.TargetValue}</span>
                        </InputLabel>
                        <TextField variant="outlined" value={targetValue} onChange={(event) => setTargetValue(event.target.value)} type="number" inputProps={{ maxLength: 50 }}/>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="tire-forecast-dropdown">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.MONTHLYTIRELIFE.STargetValue}</span>
                        </InputLabel>
                        <TextField variant="outlined" value={stretchTargetValue} onChange={(event) => setStretchTargetValue(event.target.value)} type="number" inputProps={{ maxLength: 50 }}/>
                      </div>
                    </Grid>

                    <Grid item xs={3}>
                      <div className="tire-forecast-update">
                        <Button variant="contained" color="primary" onClick={handleUpdateReport} disabled={noData === true ? true : false}>
                          {globalizationMapping.INSPECT.UPDATE}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <div className="by-tire-size-table">
                <MonthlyTireLifeTable tableData={monthlyTireLifeData} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />

      <MonthlyTireLifeFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
      ></MonthlyTireLifeFilter>
    </div>
  );
};

export default MonthlyTireLife;
