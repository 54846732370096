import { Backdrop, Button, makeStyles, Modal } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import Globalization from '../../../globalization';
import "./edit-location.css";

const useStyles = makeStyles({
    proceed: {
        "&:hover": {
            opacity: 1,
        },
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: 'Barlow',
        opacity: .8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none"
    },
    cancel: {
        "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none",
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: .8,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none",
    },
});

const CancelModal = (props) => {
    const globalizationMapping = new Globalization();
    const history = useHistory();
    const styles = useStyles();
    const handleDeleteClick = () => {
        history.push({
            pathname: '/home',
        });
    }

    return (
        <div className="container">
                <Modal
                    open={true}
                    onClose={props.closeDeleteModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 300,
                    }}
                    disableScrollLock={true}
                >
                    <div className="delete-vehicle-modal-container center-modal">
                        <div className="delete-vehicle-title-container">
                            <div className="delete-vehicle-title">
                                {globalizationMapping.FORMS.CANCELTITLE}
                            </div>
                            <div
                                className="close-icon-container"
                                onClick={props.closeDeleteModal}>
                                <img
                                    src={require("../../../assets/close-icon.svg")}
                                    className="modal-close-icon"
                                    alt="close-icon"
                                />
                            </div>
                        </div>
                        <div className="delete-modal-description">
                            {globalizationMapping.FORMS.CANCELREQUESTDESC1}
                        </div>
                        <div className="delete-modal-description" style={{ marginTop: '-25px' }}><p>
                            {globalizationMapping.FORMS.CANCELREQUESTDESC2}
                        </p></div>
                        <div className="modal-action-buttons">
                            <Button
                                className={styles.cancel}
                                variant="contained"
                                disableElevation={true}
                                onClick={
                                    props.closeDeleteModal
                                }
                            >
                                {globalizationMapping.FORMS.NO}
                            </Button>
                            <Button
                                className={styles.proceed}
                                variant="contained"
                                disableElevation={true}
                                color='primary'
                                onClick={
                                    () => { handleDeleteClick() }
                                }
                            >
                                {globalizationMapping.FORMS.YES}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
    );
};

export default CancelModal