import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ViewParentAccountListLabels.css";
import {
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import Globalization from "../../../../../globalization/index";
const useStyles = makeStyles({
  save: {
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    opacity: 1,
    fontWeight: 500, width: "7em"
  }
});


const ViewParentAccountListLabels = (details) => {
  const history = useHistory();
  const [viewDetails, setViewDetails] = useState(details.details)
  const styles = useStyles();
  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const formCaptions = globalizationMapping.FORMS.ACCOUNTS.PARENTFORM;

  useEffect(() => {
    setViewDetails(details.details)
  }, [details])


  const handleEdit = () => {
    history.push({
      pathname: '/editparentaccount',
      state: {
        accountId: details.details.parentAccountId
      }
    })
  };

  return (
    <div className="view-parent-list-container">
      <div className="view-parent-container">
        <div className="view-parent-inner-container">
          <div className="view-parent-account-account-details-grid">
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <p className="field-label">
                  {formCaptions.PARENTACCOUNT}
                </p>
                <label className="save-table-details">
                  {viewDetails.accountName}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {formCaptions.ADDRESSLINE1}
                </p>
                <label className="save-table-details">
                  {viewDetails.accountAddressLine1}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">{formCaptions.ADDRESSLINE2}</p>
                <label className="save-table-details">
                  {viewDetails.accountAddressLine2}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {formCaptions.COUNTRY}
                </p>
                <label className="save-table-details">
                  {viewDetails.country}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">{globalHeaderTexts.STATE}</p>
                <label className="save-table-details">
                  {viewDetails.state}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {globalHeaderTexts.CITY}
                </p>
                <label className="save-table-details">
                  {viewDetails.city}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {globalHeaderTexts.ZIPPOSTAL}
                </p>
                <label className="save-table-details">
                  {viewDetails.zipCode}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">{globalHeaderTexts.PHONE}</p>
                <label className="save-table-details">
                  {viewDetails.phoneNumber}
                </label>
              </Grid>

              <Grid item xs={4}>
                <p className="field-label">
                  {globalHeaderTexts.EMAIL}
                </p>
                <label className="save-table-details">
                  {viewDetails.emailAddress}
                </label>
              </Grid>
            </Grid>
          </div>
          <div className="view-parent-account-action-buttons">

            <Button
              className={styles.save}
              variant="contained"
              color="primary"
              disableElevation={true}
              onClick={() => handleEdit()}
            >
              {globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM.EDIT}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewParentAccountListLabels;
