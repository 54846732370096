import React, { useEffect, useState, useContext } from "react";
import "./TireNeedsForecast.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import TiresInServiceNavigation from "../../../tires-in-service-navigation/TiresInServiceNavigation";
import { Card, CardContent, TextField, InputLabel, Select, Grid, Checkbox, FormControlLabel, Button } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import TireNeedsForecastDataSetter from "../../../../../services/TireNeedsForecastUtils";
import TireNeedsForecastSecondDataSetter from "../../../../../services/TireNeedsForecastSecondTableUtils";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import TireNeedsForecastTable from "../table/TireNeedsForecastTable";
import TireNeedsForecastSecondTable from "../table/TireNeedsForecastSecondTable";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import { makeStyles } from "@material-ui/core/styles";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import CommonUtils from "../../../../../../../services/utils";
import TireNeedsForecastFilter from "../filter/TireNeedsForecastFilter";
import FilterPrintEmailExportSetButtons from "../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons";

const TireNeedsForecast = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const commonUtils = new CommonUtils();
  const tiresService = new TiresService();
  const tireNeedsForecastDataSetter = new TireNeedsForecastDataSetter();
  const tireNeedsForecastSecondDataSetter = new TireNeedsForecastSecondDataSetter();
  const [tireNeedsForecastTableData, setTireNeedsForecastTableData] = useState();
  const [tireNeedsForecastSecondTableData, setTireNeedsForecastSecondTableData] = useState();
  const [originalTireNeedsForecastTableData, setOriginalTireNeedsForecastTableData] = useState();
  const [originalTireNeedsForecastSecondTableData, setOriginalTireNeedsForecastSecondTableData] = useState();
  const [showColumns, setShowColumns] = useState();
  const [showColumnsSecond, setShowColumnsSecond] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(tireNeedsForecastTableData);
  const [printDataSecond, setPrintDataSecond] = useState();
  const [noData, setNoData] = useState(false);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [reportBy, setReportBy] = useState("Year");
  const [isMonthRolling, setIsMonthRolling] = useState(false);
  const [fromYear, setFromYear] = useState(String(new Date().getFullYear() - 1));
  const [toYear, setToYear] = useState(String(new Date().getFullYear()));
  const [predictTireLifeBy, setPredictTireLifeBy] = useState("TreadWearRate");
  const [removalHours, setRemovalHours] = useState(14000);
  const [tireTypes, setTireTypes] = useState("installed");
  const [fromScrappedDate, setFromScrappedDate] = useState();
  const [toScrappedDate, setToScrappedDate] = useState();
  const [avgHours, setAvgHours] = useState(14);
  const [avgDays, setAvgDays] = useState(7);
  const [timeArray, setTimeArray] = useState([]);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [excludeTotalCost, setExcludeTotalCost] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const useStyles = makeStyles((theme) => ({
    text: {
      width: "80em"
    },
    root: {
      width: "100%",
      height: "auto",
      marginTop: "5%",
      "& label.MuiInputLabel-formControl": {
        color: "#993737",
        fontSize: "1.3em !important",
        fontWeight: "bold",
        top: "0em !important"
      },
      "& div.MuiInputBase-formControl": {
        "& textarea.MuiOutlinedInput-inputMultiline": {
          color: "black"
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          "& legend": {
            width: "8.5em"
          }
        }
      }
    }
  }));
  const classes = useStyles();

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "Manufacturer" },
      { name: "Vehicle ID", value: "VehicleNumber" },
      { name: "Size", value: "Size" },
      { name: "Type", value: "Type" }
    ],
    categoryData: {
      Manufacturer: [],
      VehicleNumber: [],
      Size: [],
      Type: []
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  useEffect(() => {
    TireNeedsForecastResponseData(filterApplied, filterFieldsApplied);
  }, [globalAccountState]);


  const handleExcludeTotalCostCommon = (data) => {
    const filteredHeadCells = data.headCells.filter(cell => cell.key !== 'TotalCost' && cell.key !== 'maxcost');
    const updatedRowsData = data.rowsData.filter(row => row.VehicleNumber !== 'Total Cost').map(({ TotalCost, maxcost, ...rest }) => rest)
    const updatedSelectedColShow = Object.fromEntries(Object.entries(data.selectedColShow).map(([key, value]) => [key, key === 'maxcost' || key === 'TotalCost' ? false : value]));
    // Store modified data in a variable
    const modifiedTableData = {
      ...data,
      headCells: filteredHeadCells,
      rowsData: updatedRowsData,
      totalCost: Object.fromEntries(Object.keys(data.totalCost).map(key => [key, ''])),
      total: data.total.filter((_, index) => index !== 1),
      selectedColShow: updatedSelectedColShow
    };
    setShowColumns(modifiedTableData.selectedColShow)

    // Update state with modified data
    setTireNeedsForecastTableData(modifiedTableData);

    // Use modified data in setPrintData
    setPrintData({
      reportType: "Tires in Services : ",
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireNeedsForecast,
      rowsData: modifiedTableData.rowsData,
      headCells: modifiedTableData.headCells,
      selectedColShow: modifiedTableData.selectedColShow,
      avgValues: modifiedTableData.avgValues,
      totalCost: modifiedTableData.totalCost,
    });
  }

  // for month
  const TireNeedsForecastResponseData = (filterAppliedValue, filterFieldsAppliedValue) => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    let today = new Date().toLocaleDateString();

    let currentMonth = today.split("/")[0];
    let currentYear = today.split("/")[2];

    let fromDate = fromYear + "-01-01";

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: "DateRange",
      fromDate: fromDate,
      locationIds: locationIds,
      reportName: "Tire_Needs_Forecast",
      reportOption: "",
      toDate: toYear + "-12-31",
      AverageHoursOfUsePerDay: avgHours,
      AverageWorkDaysPerWeek: avgDays,
      predictTireLifeBy: predictTireLifeBy,
      fixedRemovalHours: removalHours,
      usedTires: excludeUsedTires,
      isMonthRolling: isMonthRolling,
      tireDisposition: tireTypes,
      reportBy: reportBy,
      outServiceFromDate: fromScrappedDate && tireTypes === "scrapped" ? fromScrappedDate : null,
      outServiceToDate: toScrappedDate && tireTypes === "scrapped" ? toScrappedDate : null
    };

    let requestObject2 = {
      accountIds: accountIds,
      dateRangeType: "DateRange",
      fromDate: fromYear + "-01-01",
      locationIds: locationIds,
      reportName: "Tire_Needs_Forecast_HoursPerTread",
      toDate: toYear + "-12-31",
      usedTires: excludeUsedTires,
      outServiceFromDate: fromScrappedDate && tireTypes === "scrapped" ? fromScrappedDate : null,
      outServiceToDate: toScrappedDate && tireTypes === "scrapped" ? toScrappedDate : null,
      tireDisposition: tireTypes,
      AverageWorkDaysPerWeek: avgHours,
      AverageHoursOfUsePerDay: avgDays,
      drivingFixedRemovalHours: removalHours,
      predictTireLifeBy: predictTireLifeBy
    };

    let filterOptions = {
      categories: [
        {
          name: globalizationMapping.REPORTTABLE.MANUFACTURER,
          value: "Manufacturer"
        },
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "Size" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "Type" },
        {
          name: globalizationMapping.REPORTTABLE.VEHICLEID,
          value: "VehicleNumber"
        }
      ],
      categoryData: {
        Manufacturer: [],
        VehicleNumber: [],
        Size: [],
        Type: []
      }
    };
    let timeArrayTemp = [];
    const monthMapper = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec"
    };

    if (isMonthRolling) {
      for (let j = currentMonth; j <= parseInt(currentMonth) + 12; j++) {
        let monthValue = j;
        let yearValue = currentYear;
        //
        if (j > 12) {
          monthValue = parseInt(j) - 12;
          yearValue = parseInt(currentYear) + 1;
        }
        timeArrayTemp.push(monthMapper[monthValue] + " " + yearValue.toString());
      }

    } else if (reportBy === "Year") {
      for (let i = fromYear; i <= toYear; i++) {
        timeArrayTemp.push("Year " + i.toString());
      }
    } else if (reportBy === "Month") {
      for (let i = fromYear; i <= toYear; i++) {
        for (let j = 1; j <= 12; j++) {
          timeArrayTemp.push(monthMapper[j] + " " + i.toString());
        }
      }
    } else if (reportBy === "HalfYear") {
      for (let i = fromYear; i <= toYear; i++) {
        for (let j = 1; j <= 2; j++) {
          if (i === fromYear && j === 1 && currentMonth > 6) continue;
          timeArrayTemp.push("Half " + j + " " + i.toString());
        }
      }
    } else {
      // report by quarter
      for (let i = fromYear; i <= toYear; i++) {
        for (let j = 1; j <= 4; j++) {
          if (i === fromYear && ((j === 1 && currentMonth > 3) || (j === 2 && currentMonth > 6) || (j === 3 && currentMonth > 9))) continue;
          timeArrayTemp.push("Q" + j + " " + i.toString());
        }
      }
    }
    setTimeArray(timeArrayTemp);
    setShowSpinner(true);

    tiresService.getTiresReport(requestObject).then((data) => {
      let tiresInServiceTableInfo = tireNeedsForecastDataSetter.setData(data, timeArrayTemp);
      let filterOptionsInfo = tireNeedsForecastDataSetter.setFilterOptionsData(filterOptions, tiresInServiceTableInfo.rowsData);

      tiresInServiceTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setTireNeedsForecastTableData(tiresInServiceTableInfo);
      setOriginalTireNeedsForecastTableData(tiresInServiceTableInfo);
      setFilterOptions(filterOptionsInfo);
      setShowColumns(tiresInServiceTableInfo.selectedColShow);


      if (excludeTotalCost) {
        handleExcludeTotalCostCommon(tiresInServiceTableInfo)
      } else {
        // Handle the case when excludeTotalCost is false
        setPrintData({
          reportType: "Tires in Services : ",
          reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireNeedsForecast,
          rowsData: tiresInServiceTableInfo.rowsData,
          headCells: tiresInServiceTableInfo.headCells,
          selectedColShow: tiresInServiceTableInfo.selectedColShow,
          avgValues: tiresInServiceTableInfo.avgValues,
          totalCost: tiresInServiceTableInfo.totalCost,
        });
      }

      tiresService.getTiresReport(requestObject2).then((data2) => {
        if (data2.didError) {
          setTireNeedsForecastSecondTableData([]);
          setOriginalTireNeedsForecastSecondTableData([]);
          setShowColumnsSecond([]);
          setPrintDataSecond({
            reportType: "Tires in Services : ",
            reportName: "Tire Needs Forecast Second",
            rowsData: [],
            headCells: [],
            selectedColShow: []
          });
        } else {
          let tireNeedsSecondDataInfo = tireNeedsForecastSecondDataSetter.setData(data2);
          setTireNeedsForecastSecondTableData(tireNeedsSecondDataInfo);
          setOriginalTireNeedsForecastSecondTableData(tireNeedsSecondDataInfo);
          setShowColumnsSecond(tireNeedsSecondDataInfo.selectedColShow);
          setPrintDataSecond({
            reportType: "Tires in Services : ",
            reportName: "Tire Needs Forecast Second",
            rowsData: tireNeedsSecondDataInfo.rowsData,
            headCells: tireNeedsSecondDataInfo.headCells,
            selectedColShow: tireNeedsSecondDataInfo.selectedColShow

          });

          if (filterAppliedValue) {
            setFilterApplied(true);
            let originalTableDataStore = tiresInServiceTableInfo.rowsData;
            let originalTableDataStoreSecond = originalTireNeedsForecastSecondTableData?.rowsData;
            let filteredDataSecond = tireNeedsForecastSecondDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStoreSecond);

            setTireNeedsForecastSecondTableData({
              ...tireNeedsForecastSecondTableData,
              rowsData: filteredDataSecond
            });
            setPrintDataSecond({
              ...printDataSecond,
              rowsData: filteredDataSecond,
              filterApplied: filterApplied
            });
            if (filterFieldsAppliedValue.hasOwnProperty("Manufacturer")) {
              if (filterFieldsAppliedValue.Manufacturer.length !== 0) {
                let newMenu = [];
                filterFieldsAppliedValue.Manufacturer.forEach((menu) => {
                  newMenu.push(menu);
                  newMenu.push(menu + " - Total");
                  newMenu.push(menu + " - Cost");
                });
                filterFieldsAppliedValue.Manufacturer = newMenu;
              }
            }
            let filteredData = tireNeedsForecastDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore, timeArrayTemp);
            let totals = tireNeedsForecastDataSetter.totalForPdf(filteredData, timeArrayTemp);
            setTireNeedsForecastTableData({
              ...tireNeedsForecastTableData,
              rowsData: filteredData,
              headCells: tiresInServiceTableInfo.headCells,
              avgValues: totals[0],
              totalCost: totals[1]
            });

            if (excludeTotalCost) {
              handleExcludeTotalCostCommon({
                ...tireNeedsForecastTableData, rowsData: filteredData,
                headCells: tiresInServiceTableInfo.headCells,
                avgValues: totals[0],
                totalCost: totals[1],
                selectedColShow: tiresInServiceTableInfo.selectedColShow
              })
            } else {
              // Handle the case when excludeTotalCost is false
              setPrintData({
                ...printData,
                reportType: "Tires in Services : ",
                reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireNeedsForecast,
                rowsData: filteredData,
                avgValues: totals[0],
                totalCost: totals[1],
                filterFieldsApplied: filterFieldsAppliedValue,
                filterApplied: true,
                headCells: tiresInServiceTableInfo.headCells,
                selectedColShow: tiresInServiceTableInfo.selectedColShow
              });
            }

            filteredData.length === 0 ? setNoData(true) : setNoData(false);
            ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
          }
        }
        setShowSpinner(false);
      });
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });
  const globalizationMapping = new Globalization();
  const translationKeys = globalizationMapping.TIRENEEDSFORECAST;
  const onClickExport = (type) => {
    if (type === "pdf") {
      setModalHandler({ handlePdf: true });
    }
  };

  const onClickPrint = () => {
    setModalHandler({ handlePrint: true });
  };

  const onClickEmail = () => {
    setModalHandler({ handleEmail: true });
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };



  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      Manufacturer: [],
      VehicleNumber: [],
      Size: [],
      Type: []
    });
    setFilterApplied(false);
    setTireNeedsForecastTableData(originalTireNeedsForecastTableData);
    setNoData(false);
    setOnlyFilterApplied(false);
    setPrintData({
      reportType: "Tires in Services : ",
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireNeedsForecast,
      rowsData: originalTireNeedsForecastTableData?.rowsData,
      headCells: originalTireNeedsForecastTableData?.headCells,
      avgValues: originalTireNeedsForecastTableData?.avgValues,
      totalCost: originalTireNeedsForecastTableData?.totalCost,
      selectedColShow: originalTireNeedsForecastTableData?.selectedColShow,
      filterApplied: false,
      filterFieldsApplied: {}
    });
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {

    setCurrentFilter(appliedFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    let originalTableDataStore = originalTireNeedsForecastTableData?.rowsData;
    let filteredData = tireNeedsForecastDataSetter.filterTable(appliedFilter, originalTableDataStore, timeArray);
    setTireNeedsForecastTableData({
      ...tireNeedsForecastTableData,
      rowsData: filteredData
    });
    setPrintData({ ...printData, rowsData: filteredData });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);


    if (Object.values(appliedFilter).every(x => x.length === 0)) {
      resetTableFilter()
    }
    else {
      setFilterApplied(true);
      setFilterFieldsApplied(appliedFilter);
      TireNeedsForecastResponseData(true, appliedFilter);
    }
  };

  const handleUpdateReportBy = (event) => {
    setReportBy(event.target.value);
  };
  const handleUpdateIsMonthRolling = (event) => {
    setIsMonthRolling(event.target.checked);
  };
  const handleUpdateFromYear = (value) => {
    setFromYear(value);
  };
  const handleUpdateToYear = (value) => {
    setToYear(value);
  };
  const handleUpdatePredictTireLifeBy = (event) => {
    setPredictTireLifeBy(event.target.value);
  };
  const handleUpdateTireTypes = (event) => {
    setTireTypes(event.target.value);
  };
  const handleUpdateFromScrappedDate = (event) => {
    setFromScrappedDate(event.target.value);
  };
  const handleUpdateToScrappedDate = (event) => {
    setToScrappedDate(event.target.value);
  };
  const handleUpdateAvgHours = (value) => {
    if (value === "" || Number(value) === 0 || Number(value) < 0) setAvgHours("");
    else if (value <= 24) setAvgHours(value);
    else return;
  };
  const handleUpdateAvgDays = (value) => {
    if (value === "" || Number(value) === 0 || Number(value) < 0) setAvgDays("");
    else if (value <= 7) setAvgDays(value);
    else return;
  };
  const handleUpdateRemovalHours = (value) => {
    setRemovalHours(value);
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
  };

  const handleExcludeTotalCost = (event) => {
    setExcludeTotalCost(event.target.checked);
  }

  const handleUpdateReport = (event) => {
    if (avgDays && avgHours) TireNeedsForecastResponseData(filterApplied, filterFieldsApplied);
  };

  const toggleExportDropdown = () => {
    setIsComponentVisible(!isComponentVisible);
  }
  const date = new Date();
  return (
    <div className="container">
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="tire-installation-container">
          <Card className="tire-installation-card">
            <CardContent>
              <div className="table-toolbar" style={{justifyContent:'end'}}>
              <FilterPrintEmailExportSetButtons
                  onClickFilter={onClickFilter}
                  onClickEmail={onClickEmail}
                  onClickPrint={onClickPrint}
                  onClickExport={onClickExport}
                  toggleExportDropdown={toggleExportDropdown}
                  printData={printData}
                  ref={ref}
                  onlyFilterApplied={onlyFilterApplied}
                  noData={noData}
                  isComponentVisible={isComponentVisible}
                />
              </div>

              


              <Grid container>
                <Grid item xs={3}>
                  <div className="drop-down-forecast filter-container">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{translationKeys.ReportBy}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down"
                      value={reportBy}
                      variant="outlined"
                      onChange={handleUpdateReportBy}>
                      <option value="Month">{translationKeys.Month}</option>
                      <option value="Quarter">{translationKeys.Quarter}</option>
                      <option value="HalfYear">{translationKeys.HalfYear}</option>
                      <option value="Year">{translationKeys.Year}</option>
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="filter-container">
                    <FormControlLabel
                      className="filter-container"
                      control={<Checkbox checked={isMonthRolling} onChange={handleUpdateIsMonthRolling} name="checkedB" color="primary" />}
                      label={translationKeys.Rolling12Month}
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="filter-container">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{translationKeys.FromYear}</span>
                    </InputLabel>
                    <TextField
                      value={fromYear}
                      name="install number"
                      variant="outlined"
                      onChange={(e)=>(handleUpdateFromYear(commonUtils.minmaxInt(e.target.value, Number(date.getFullYear()) - 99, Number(date.getFullYear()))))}
                      type="number"
                      inputProps={{ maxLength: 50 }}
                    ></TextField>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="filter-container">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{translationKeys.ToYear}</span>
                    </InputLabel>
                    <TextField
                      value={toYear}
                      name="install number"
                      variant="outlined"
                      onChange={(e)=>(handleUpdateToYear(commonUtils.minmaxInt(e.target.value, Number(date.getFullYear()), Number(date.getFullYear()) + 99)))}
                      type="number"
                      inputProps={{ maxLength: 50 }}
                    ></TextField>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="drop-down-forecast filter-container">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{translationKeys.PredictBy}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down"
                      value={predictTireLifeBy}
                      name="install number"
                      variant="outlined"
                      onChange={handleUpdatePredictTireLifeBy}>
                      <option value="TreadWearRate">{translationKeys.TreadWearRate}</option>
                      <option value="removalHours">{translationKeys.FixedRemovalHours}</option>
                    </Select>
                  </div>
                </Grid>
                {predictTireLifeBy === "TreadWearRate" && (
                  <Grid item xs={3}>
                    <div className="drop-down-forecast filter-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{translationKeys.WearRate}</span>
                      </InputLabel>
                      <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="select-drop-down"
                        value={tireTypes}
                        name="install number"
                        variant="outlined"
                        onChange={handleUpdateTireTypes}>
                        <option value="installed">{translationKeys.InstalledTiresOnly}</option>
                        <option value="installedAndSpare">{translationKeys.InstalledAndSpare}</option>
                        <option value="scrapped">{translationKeys.ScrapTiresOnly}</option>
                        <option value="all">{translationKeys.AllTires}</option>
                      </Select>
                    </div>
                  </Grid>
                )}
                {predictTireLifeBy === "removalHours" && (
                  <Grid item xs={3}>
                    <div className="drop-down-forecast filter-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{translationKeys.InstalledTiresOnly}</span>
                      </InputLabel>
                      <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="select-drop-down"
                        value={tireTypes} name="install number" variant="outlined" onChange={handleUpdateTireTypes}>
                        <option value="all">{translationKeys.AllInstalledAndSpare}</option>
                        <option value="installed">{translationKeys.InstalledTiresOnly}</option>
                      </Select>
                    </div>
                  </Grid>
                )}
                {predictTireLifeBy === "removalHours" && (
                  <Grid item xs={3}>
                    <div className="filter-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{translationKeys.RemovalHours}</span>
                      </InputLabel>
                      <TextField
                        value={removalHours}
                        name="install number"
                        variant="outlined"
                        onChange={(e)=> (handleUpdateRemovalHours(commonUtils.minmaxInt(e.target.value, 0, 999999)))}
                        type="number"
                        inputProps={{ maxLength: 50 }}
                      ></TextField>
                    </div>
                  </Grid>
                )}
                {predictTireLifeBy === "TreadWearRate" && tireTypes === "scrapped" && (
                  <Grid item xs={3}>
                    <div className="filter-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{translationKeys.FromScrapDate}</span>
                      </InputLabel>
                      <TextField
                        value={fromScrappedDate}
                        type="date"
                        name="install number"
                        variant="outlined"
                        onChange={handleUpdateFromScrappedDate}
                        inputProps={{ maxLength: 50 }}
                      ></TextField>
                    </div>
                  </Grid>
                )}
                {predictTireLifeBy === "TreadWearRate" && tireTypes === "scrapped" && (
                  <Grid item xs={3}>
                    <div className="filter-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{translationKeys.ToScrapDate}</span>
                      </InputLabel>
                      <TextField
                        value={toScrappedDate}
                        type="date"
                        name="install number"
                        variant="outlined"
                        onChange={handleUpdateToScrappedDate}
                        inputProps={{ maxLength: 50 }}
                      ></TextField>
                    </div>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <div className="filter-container">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{translationKeys.AvgHoursPerDay}</span>
                    </InputLabel>
                    <TextField
                      value={avgHours}
                      type="number"
                      name="install number"
                      variant="outlined"
                      onChange={(e) => (handleUpdateAvgHours(commonUtils.minmaxInt(e.target.value, 0, 999999)))}
                      inputProps={{ maxLength: 50 }}
                    ></TextField>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="filter-container">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{translationKeys.AvgDaysPerWeek}</span>
                    </InputLabel>
                    <TextField
                      value={avgDays}
                      type="number"
                      name="install number"
                      variant="outlined"
                      onChange={(e)=> (handleUpdateAvgDays(commonUtils.minmaxInt(e.target.value, 0, 999999)))}
                      inputProps={{ maxLength: 50 }}
                    ></TextField>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="exclude-used-tires">
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="exclude-used-tires">
                    <FormControlLabel
                      control={<Checkbox checked={excludeTotalCost} onChange={handleExcludeTotalCost} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeTotalCostAndMaxCost}
                    />
                  </div>
                </Grid>

                <Grid item xs={3}>
                  <div className="filter-container">
                    <Button variant="contained" color="primary" onClick={handleUpdateReport}>
                      {globalizationMapping.INSPECT.UPDATE}
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <TireNeedsForecastTable tableData={tireNeedsForecastTableData} showColumns={showColumns} filterApplied={filterApplied} timeArray={timeArray} />
              <TireNeedsForecastSecondTable tableData={tireNeedsForecastSecondTableData} showColumns={showColumnsSecond} />  
            </CardContent>
          </Card>
          <TextField
            className={classes.text + " " + classes.root}
            id="outlined-multiline-static"
            disabled
            label={translationKeys.Disclaimer}
            multiline
            rows={4}
            defaultValue={translationKeys.DisclaimerMsg}
            variant="outlined"
            InputProps={{
              readOnly: true,
              style: {
                fontSize: "0.85em"
              }
            }}
            inputProps={{ maxLength: 200 }}
          />
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} tableData2={printDataSecond} noGraph={true} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} tableData2={printDataSecond} noGraph={true} />
      <EmailModalComponent
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        tableData2={printDataSecond}
        tireNeedsForecast={true}
        noGraph={true}
      />

      <TireNeedsForecastFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
      />
    </div>
  );
};

export default TireNeedsForecast;
