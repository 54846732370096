import React, { useEffect, useState, useContext } from "react";
import OtherTiresNavigation from "../../../other-tires-navigation/OtherTiresNavigation";
import "./TireRegister.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import TireRegisterDataSetter from "../../../../../services/TireRegisterUtils";
import TireRegisterTable from "../table/TireRegisterTable";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import TireRegisterFilter from "../filter/tireRegisterFilter.js";


const TireRegister = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const tireRegisterDataSetter = new TireRegisterDataSetter();
  const commonUtils = new CommonUtils();
  const [tireRegisterTableData, setTireRegisterTableData] = useState();
  const [originalTireRegisterTableData, setOriginalTireRegisterTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(tireRegisterTableData);
  const [noData, setNoData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [installDateRange, setInstallDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [registerDateRange, setRegisterDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [tireScrapType, setTireScrapType] = useState("AllTire");
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Eval #", value: "Eval" },
      { name: "Compound", value: "CompoundName" },
      { name: "Disposition", value: "Disposition" }
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      Eval: [],
      CompoundName: [],
      Disposition: [],
      installDateToDate:'',
      installDateFromDate:'',
      registerDateToDate:'',
      registerDateFromDate:'',
      dateRangeToDate: '',
      dateRangeFromDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER


  const TireRegisterResponseData = (tireScrapTypeVal, excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      installDateRangeType: dateRangeType,
      installFromDate : installDateRange.fromDate,
      installToDate : installDateRange.toDate,
      registerFromDate : registerDateRange.fromDate,
      registerToDate : registerDateRange.toDate,
      locationIds: locationIds,
      reportName: "Tire_Register",
      reportOption: "",
      tireScrapType: tireScrapTypeVal,
      usedTires: excludeUsedTiresValue.toString()
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let tireRegisterTableInfo = tireRegisterDataSetter.setData(data);
      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.MANUFACTURER,
            value: "ManufacturerName"
          },
          { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
          { name: globalizationMapping.REPORTTABLE.EVALNO, value: "Eval" },
          {
            name: globalizationMapping.REPORTTABLE.COMPOUND1,
            value: "CompoundName"
          },
          {
            name: globalizationMapping.REPORTTABLE.DISPOSITION,
            value: "Disposition"
          }
        ],
        categoryData: {
          ManufacturerName: [],
          SizeName: [],
          TypeName: [],
          Eval: [],
          CompoundName: [],
          Disposition: [],
          installDateToDate:'',
          installDateFromDate:'',
          registerDateToDate:'',
          registerDateFromDate:'',
          dateRangeToDate: '',
          dateRangeFromDate: '',
        }
      };
      let filterOptionsInfo = tireRegisterDataSetter.setFilterOptionsData(filterOptions, tireRegisterTableInfo.rowsData);

      setFilterOptions(filterOptionsInfo);
      setTireRegisterTableData(tireRegisterTableInfo);
      setOriginalTireRegisterTableData(tireRegisterTableInfo);
      setTableBeforeFilter(tireRegisterTableInfo);
      let updatedHeadCells = tireRegisterDataSetter.updatedHeadCells();
      tireRegisterTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setDisplayColOptions(tireRegisterTableInfo.headCells);
      setDisplayColumns(updatedHeadCells);
      setShowColumns(tireRegisterTableInfo.selectedColShow);
      setPrintData({
        reportType: "Other Tires : ",
        reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TireRegisterReport,
        rowsData: tireRegisterTableInfo.rowsData,
        headCells: tireRegisterTableInfo.headCells,
        avgValues: tireRegisterTableInfo.avgValues,
        selectedColShow: tireRegisterTableInfo.selectedColShow,
        filterApplied: filterApplied
      });
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = tireRegisterTableInfo.rowsData;
        let filteredData = tireRegisterDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setTireRegisterTableData({
          ...tireRegisterTableInfo,
          rowsData: filteredData,
          avgValues: tireRegisterDataSetter.avgValues(filteredData)
        });
        setOriginalTireRegisterTableData({
          ...tireRegisterTableInfo,
          rowsData: filteredData,
          avgValues: tireRegisterDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: tireRegisterDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
      setShowSpinner(false);
    });
    //setTireRegisterTableData(originalTireRegisterTableData)
  };

  useEffect(() => {
    TireRegisterResponseData(tireScrapType, excludeUsedTires, filterApplied, filterFieldsApplied);
  }, [globalAccountState, tireScrapType, excludeUsedTires, filterApplied, filterFieldsApplied]);

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });
  const globalizationMapping = new Globalization();

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    // searchText === "" ? setFilterApplied(false) : setFilterApplied(true)
    let tableDataStore, filteredData;
    tableDataStore = originalTireRegisterTableData?.rowsData;
    filteredData = tireRegisterDataSetter.search(tableDataStore, searchText);
    setTireRegisterTableData({
      ...tireRegisterTableData,
      rowsData: filteredData,
      avgValues: tireRegisterDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      reportType: "Other Tires : ",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TireRegisterReport,
      rowsData: filteredData,
      headCells: originalTireRegisterTableData?.headCells,
      avgValues: tireRegisterDataSetter.avgValues(filteredData),
      selectedColShow: originalTireRegisterTableData?.selectedColShow,
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalTireRegisterTableData?.headCells;
    let showColumnsUpdated = tireRegisterDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {

    let toSetCurrentFilter = 
    { ...appliedFilter,
      installDateToDate: installDateRange.toDate,
      installDateFromDate: installDateRange.fromDate,
      registerDateToDate: registerDateRange.toDate,
      registerDateFromDate:  registerDateRange.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
      dateRangeFromDate: dateRangeFilter.fromDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    setSearchValue("");

    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    TireRegisterResponseData(tireScrapType, excludeUsedTires, true, appliedFilter);

    // to observe code: start
    let originalTableDataStore = originalTireRegisterTableData?.rowsData;
    let filteredData = tireRegisterDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setTireRegisterTableData({
      ...tireRegisterTableData,
      rowsData: filteredData,
      avgValues: tireRegisterDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: tireRegisterDataSetter.avgValues(filteredData)
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);
    // to observe code: end
  };


  // RESET FILTER
  const resetTableFilter = () => {


    setCurrentFilter({
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      Eval: [],
      CompoundName: [],
      Disposition: [],
      registerDateToDate:'',
      registerDateFromDate:'',
      installDateFromDate:'',
      installDateToDate:'',
      dateRangeFromDate: '',
      dateRangeToDate: '',
    })

    setFilterApplied(false);
    setTireRegisterTableData(tableBeforeFilter);
    setOriginalTireRegisterTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
  };


  const handleUpdateTireScrapType = (event) => {
    setTireScrapType(event.target.value);
    TireRegisterResponseData(event.target.value, excludeUsedTires, filterApplied, filterFieldsApplied);
  };

  const handleExcludeUsedTires = (event) => {
    setSearchValue("");
    setExcludeUsedTires(event.target.checked);
    TireRegisterResponseData(tireScrapType, event.target.checked, filterApplied, filterFieldsApplied);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  return (
    <div>
      <div className="container">
        <OtherTiresNavigation />
        {showSpinner && <Spinner />}
        {!showSpinner && (
          <div className="tire-register-container">
            <Card className="tire-register-card">
              <CardContent>
                <div className="table-toolbar">
                  <div className="table-filters">
                    <div className="search-table-container">
                      <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                        </InputLabel>
                        <TextField
                          className="search-table-bar"
                          id="input-with-icon-textfield"
                          variant="outlined"
                          placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                          value={searchValue}
                          onChange={searchTableData}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                              </InputAdornment>
                            )
                          }}
                          inputProps={{ maxLength: 50 }}
                        />
                      </form>
                    </div>
                    <div className="display-columns-container" id="dashboard-display-column">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text"> {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                      </InputLabel>
                      <MultiSelect
                        options={displayColOptions}
                        value={displayColumns}
                        onChange={changeDisplayColumns}
                        overrideStrings={{
                          selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                          allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                          selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                          search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                        }}
                      />
                    </div>
                  </div>
                  <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
                </div>
                <Grid container>
                  <Grid item xs={3}>
                    <div className="tire-scrap-type">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.GRAPH.TireScrapType}</span>
                      </InputLabel>
                      <Select 
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="select-drop-down" 
                        value={tireScrapType} 
                        name="tire scrap type" 
                        variant="outlined" 
                        onChange={handleUpdateTireScrapType}>
                        <option value="AllTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption1} </option>
                        <option value="Repair">{globalizationMapping.REPORTCOMMONWORDS.TypeOption2} </option>
                        <option value="Retread">{globalizationMapping.REPORTCOMMONWORDS.TypeOption3} </option>
                        <option value="NewTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption4} </option>
                      </Select>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      className="
                                    exclude-used-tires"
                    >
                      <FormControlLabel
                        control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                        label={globalizationMapping.GRAPH.ExcludeUsedTires}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="tire-register-table">
                  <TireRegisterTable tableData={tireRegisterTableData} showColumns={showColumns} filterApplied={filterApplied} />
                </div>
              </CardContent>
            </Card>
          </div>
        )}
        <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />

        <TireRegisterFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        installDateRange={installDateRange}
        setInstallDateRange={setInstallDateRange}
        registerDateRange={registerDateRange}
        setRegisterDateRange={setRegisterDateRange}
        setDateRangeType={setDateRangeType}
        />          


      </div>
    </div>
  );
};

export default TireRegister;
