/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
    Switch,
    withStyles,
    makeStyles,
    Grid,
    FormHelperText,
    Select,
    TextField,
    ButtonGroup,
    Button,
    FormControl,
    OutlinedInput,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    Badge,
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded";
import deleteicon from "../../../assets/delete-icon.svg";
import CalendarMonthIcon from '@material-ui/icons/Today';
import Globalization from "../../../globalization";
import CommonUtils from "../../../services/utils";
import ExpandMoreStyledIcon from "../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";

const styles = makeStyles({
    buttons: {
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        font: "Barlow",
        opacity: 1,
        fontWeight: 500,
        color: "#FFFFFF",
        width: "100px",
        textAlign: "center",
    },
    adorn: {
        "& .MuiTypography-colorTextSecondary": {
            color: "#000000",
            fontWeight: "800",
        },
    },
    input: {
        display: "none",
    },
    deleteBadge: {
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            top: "10px",
            right: "17px",
            background: "transparent",
        },
    },
});

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 60,
        height: 24,
        padding: 0,
        margin: 5,
        display: "flex",
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        "&$checked": {
            transform: "translateX(36px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: "#D14449",
                borderColor: "#FFFFFF",
            },
        },
    },
    thumb: {
        width: 20,
        height: 20,
        boxShadow: "none",
    },
    track: {
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: 15,
        opacity: 1,
        backgroundColor: "#58CCE8",
    },
    checked: {},
}))(Switch);

const TireRemovalForm = (props) => {
    const classes = styles();
    let treadReadings = props.locationData.noOfTreadDepthReadings;
    useEffect(() => {
        treadReadings = props.locationData.noOfTreadDepthReadings;
    }, [props.locationData]);

    const globalizationMapping = new Globalization();
    const formCaptions = globalizationMapping.INSPECTNREMOVETIRE;
    const formUnitCaptions = globalizationMapping.FORMS;
    const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
    const commonUtils = new CommonUtils();

    const handleDateChange = (value) => {
        props.onChangeListener("inspdate", value);
    };

    const trdMaker = () => {
        if (props.locationData.treadDepthMethodId === 3) {
            return <p className="field-label">{tireFormCaptions.MINTRD}</p>;
        } else if (props.locationData.treadDepthMethodId === 2) {
            return <p className="field-label">{tireFormCaptions.MAXTRD}</p>;
        } else {
            return <p className="field-label">{tireFormCaptions.AVGTRD}</p>;
        }
    };

    const unitSelection = () => {
        if (props.unitPreferences.pressure === "PSI") {
            return "PSI";
        } else {
            return props.unitPreferences.pressure === "KPA" ? "KPA" : "Bar";
        }
    };
    const outTreadCal = () => {
        const originalT = parseInt(props.tireData.originalTread, 10);
        const outsideT = parseInt(props.tireRemovalData.outsideTread, 10);
        const removeAt = parseInt(props.tireData.removeAt, 10);
        if (isNaN(Math.round(((originalT - outsideT) / (originalT - removeAt)) * 100))) {
            return 0;
        } else {
            return Math.round(((originalT - outsideT) / (originalT - removeAt)) * 100);
        }
    };

    const inTreadCal = () => {
        const originalT = parseInt(props.tireData.originalTread, 10);
        const insideT = parseInt(props.tireRemovalData.insideTread, 10);
        const removeAt = parseInt(props.tireData.removeAt, 10);
        if (isNaN(Math.round(((originalT - insideT) / (originalT - removeAt)) * 100))) {
            return 0;
        } else {
            return Math.round(((originalT - insideT) / (originalT - removeAt)) * 100);
        }
    };

    const middleTreadCal = () => {
        const originalT = parseInt(props.tireData.originalTread, 10);
        const middleT = parseInt(props.tireRemovalData.middleTread, 10);
        const removeAt = parseInt(props.tireData.removeAt, 10);
        if (isNaN(Math.round(((originalT - middleT) / (originalT - removeAt)) * 100))) {
            return 0;
        } else {
            return Math.round(((originalT - middleT) / (originalT - removeAt)) * 100);
        }
    };

    return (
        <React.Fragment>
            <Grid container className="step-form-inner-container" spacing={2}>
                <Grid item sm={12}>
                    <p className="field-label">
                        {formCaptions.DATE} <span className="required-mark">*</span>
                    </p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className="enter-detail-input-box disabled-input"
                            // variant="inline"
                            value={props.tireRemovalData.inspectionDate}
                            onChange={handleDateChange}
                            format={commonUtils.getDateFormat()}
                            placeholder={commonUtils.getDateFormat()}
                            keyboardIcon={<CalendarMonthIcon />}
                            helperText=""
                            disabled
                        />
                    </MuiPickersUtilsProvider>
                    <FormHelperText>{props.formHelpers.inspectionDate}</FormHelperText>
                </Grid>
                {props.masterDataSet &&
                    props.masterDataSet.casingCondition &&
                    props.masterDataSet.casingCondition.length > 0 && (
                        <Grid item sm={12}>
                            <p className="field-label">
                                {formCaptions.REMOVALREASON}{" "}
                                <span className="required-mark">*</span>
                            </p>
                            <Select
                                native
                                className="enter-detail-input-box"
                                variant="outlined"
                                value={props.tireRemovalData.removalReasonId}
                                IconComponent={ExpandMoreStyledIcon}
                                onChange={(e) =>
                                    props.onRemovalReasonChange("removalreason", parseInt(e.target.value, 10))
                                }
                            >
                                <option key={0} value={0} disabled>
                                    {tireFormCaptions.DROPDOWNPLACEHOLDER}
                                </option>
                                {props.masterDataSet.tireRemovalReasons.map((reason) => (
                                    <option key={reason.reasonId} value={reason.reasonId}>
                                        {reason.reasonName}
                                    </option>
                                ))}
                            </Select>
                            <FormHelperText>
                                {props.formHelpers.removalReasonId}
                            </FormHelperText>
                        </Grid>
                    )}
                {props.isRemovalReasonNew && (
                    <Grid item sm={12}>
                        <TextField
                            className="enter-detail-input-box"
                            variant="outlined"
                            value={
                                props.removalMasterData[0] !== undefined
                                    ? props.removalMasterData[0].description
                                    : ""
                            }
                            onChange={(e) => props.onRemovalReasonChange("otherreason", commonUtils.removeLeadingSpaces(e.target.value))}
                            placeholder={`${tireFormCaptions.ENTER} ${formCaptions.REMOVALREASON}`}
                            inputProps={{ maxLength: 200 }}
                        />
                        <FormHelperText>{props.formHelpers.otherreason}</FormHelperText>
                    </Grid>
                )}
                {props.masterDataSet &&
                    props.masterDataSet.tireDispositions &&
                    props.masterDataSet.tireDispositions.length > 0 && (
                        <Grid item sm={12}>
                            <p className="field-label">
                                {tireFormCaptions.DISPO}&nbsp;
                                <span className="required-mark">*</span>
                            </p>
                            <Select
                                native
                                className="enter-detail-input-box"
                                variant="outlined"
                                value={props.tireRemovalData.dispositionId}
                                IconComponent={ExpandMoreStyledIcon}
                                onChange={(e) =>
                                    props.onChangeListener(
                                        "disposition",
                                        parseInt(e.target.value, 10)
                                    )
                                }
                            >
                                {props.masterDataSet.tireDispositions.map((disposition) => {
                                    if (
                                        disposition.dispositionName.trim().toLowerCase() !==
                                        "installed"
                                    ) {
                                        return (
                                            <option
                                                key={disposition.dispositionId}
                                                value={disposition.dispositionId}
                                            >
                                                {disposition.dispositionName}
                                            </option>
                                        );
                                    }
                                })}
                            </Select>
                            <FormHelperText>{props.formHelpers.dispositionId}</FormHelperText>
                        </Grid>
                    )}
                {props.tireData.mountedRimId !== null &&
                    props.tireData.mountedRimId !== undefined && (
                        <Grid item sm={12}>
                            <p className="field-label">
                                {formCaptions.RIM}&nbsp;<span className="required-mark">*</span>
                            </p>
                            <Select
                                native
                                className="enter-detail-input-box"
                                variant="outlined"
                                value={props.tireRemovalData.rimDispositionId}
                                IconComponent={ExpandMoreStyledIcon}
                                onChange={(e) =>
                                    props.onChangeListener(
                                        "rimdisposition",
                                        parseInt(e.target.value, 10)
                                    )
                                }
                            >
                                <option key={0} value={3}>
                                    {formCaptions.RIMDISPOSITIONOPTION1}
                                </option>
                                <option key={1} value={2}>
                                    {formCaptions.RIMDISPOSITIONOPTION2}
                                </option>
                                <option key={2} value={1}>
                                    {formCaptions.RIMDISPOSITIONOPTION3}
                                </option>
                            </Select>
                        </Grid>
                    )}
                <Grid item sm={12}>
                    <p style={{ display: "inline" }}>
                        <span className="field-label additional1">
                            {formCaptions.OUTTRD}&nbsp;
                            <span className="required-mark">*</span>
                        </span>
                        <span className="field-label additional2">
                            {outTreadCal()}
                            {formCaptions.PERCENTAGEWORN}
                        </span>
                    </p>
                    <ButtonGroup size="large" aria-label="medium outlined button group">
                        <Button
                            className={classes.buttons}
                            onClick={() =>
                                props.onChangeListener(
                                    "outtread",
                                    props.tireRemovalData.outsideTread - 1
                                )
                            }
                        >
                            -
                        </Button>
                        <FormControl
                            variant="outlined"
                            className="enter-detail-input-box buttoned-input"
                        >
                            <OutlinedInput
                                value={!isNaN(parseInt(props.tireRemovalData.outsideTread, 10)) ? props.tireRemovalData.outsideTread : ""}
                                type="number"
                                endAdornment={
                                    <InputAdornment className={classes.adorn} position="end">
                                        {props.unitPreferences.treadDepth === "imperial"
                                            ? formUnitCaptions["32"]
                                            : formUnitCaptions.MM}
                                    </InputAdornment>
                                }
                                onChange={(e) =>
                                    props.onChangeListener("outtread", commonUtils.minmaxInt(e.target.value, 0, 999999))
                                }
                            />
                        </FormControl>
                        <Button
                            className={classes.buttons}
                            onClick={() =>
                                props.onChangeListener(
                                    "outtread",
                                    props.tireRemovalData.outsideTread + 1
                                )
                            }
                        >
                            +
                        </Button>
                    </ButtonGroup>
                    <FormHelperText>{props.formHelpers.outsideTread}</FormHelperText>
                </Grid>
                {(treadReadings === 2 || treadReadings === 3) && (
                    <Grid item sm={12}>
                        <div style={{ display: "inline" }}>
                            <span className="field-label additional1">
                                {formCaptions.INTRD}&nbsp;
                                <span className="required-mark">*</span>
                            </span>
                            <span className="field-label additional2">
                                {inTreadCal()}
                                {formCaptions.PERCENTAGEWORN}
                            </span>
                        </div>
                        <ButtonGroup size="large" aria-label="medium outlined button group">
                            <Button
                                className={classes.buttons}
                                onClick={() =>
                                    props.onChangeListener(
                                        "intread",
                                        props.tireRemovalData.insideTread - 1
                                    )
                                }
                            >
                                -
                            </Button>
                            <FormControl
                                variant="outlined"
                                className="enter-detail-input-box buttoned-input"
                            >
                                <OutlinedInput
                                    value={!isNaN(parseInt(props.tireRemovalData.insideTread, 10)) ? props.tireRemovalData.insideTread : ""}
                                    type="number"
                                    endAdornment={
                                        <InputAdornment className={classes.adorn} position="end">
                                            {props.unitPreferences.treadDepth === "imperial"
                                                ? formUnitCaptions["32"]
                                                : formUnitCaptions.MM}
                                        </InputAdornment>
                                    }
                                    onChange={(e) => props.onChangeListener("intread", commonUtils.minmaxInt(e.target.value, 0, 999999))}
                                />
                            </FormControl>
                            <Button
                                className={classes.buttons}
                                onClick={() =>
                                    props.onChangeListener(
                                        "intread",
                                        props.tireRemovalData.insideTread + 1
                                    )
                                }
                            >
                                +
                            </Button>
                        </ButtonGroup>
                        <FormHelperText>{props.formHelpers.insideTread}</FormHelperText>
                    </Grid>
                )}
                {treadReadings === 3 && (
                    <Grid item sm={12}>
                        <div style={{ display: "inline" }}>
                            <span className="field-label additional1">
                                {formCaptions.MIDTRD}&nbsp;
                                <span className="required-mark">*</span>
                            </span>
                            <span className="field-label additional2">
                                {middleTreadCal()}
                                {formCaptions.PERCENTAGEWORN}
                            </span>
                        </div>
                        <ButtonGroup size="large" aria-label="medium outlined button group">
                            <Button
                                className={classes.buttons}
                                onClick={() =>
                                    props.onChangeListener(
                                        "midtread",
                                        props.tireRemovalData.middleTread - 1
                                    )
                                }
                            >
                                -
                            </Button>
                            <FormControl
                                variant="outlined"
                                className="enter-detail-input-box buttoned-input"
                            >
                                <OutlinedInput
                                    value={!isNaN(parseInt(props.tireRemovalData.middleTread, 10)) ? props.tireRemovalData.middleTread : ""}
                                    type="number"
                                    endAdornment={
                                        <InputAdornment className={classes.adorn} position="end">
                                            {props.unitPreferences.treadDepth === "imperial"
                                                ? formUnitCaptions["32"]
                                                : formUnitCaptions.MM}
                                        </InputAdornment>
                                    }
                                    onChange={(e) => props.onChangeListener("midtread", commonUtils.minmaxInt(e.target.value, 0, 999999))}
                                />
                            </FormControl>
                            <Button
                                className={classes.buttons}
                                onClick={() =>
                                    props.onChangeListener(
                                        "midtread",
                                        props.tireRemovalData.middleTread + 1
                                    )
                                }
                            >
                                +
                            </Button>
                        </ButtonGroup>
                        <FormHelperText>{props.formHelpers.middleTread}</FormHelperText>
                    </Grid>
                )}
                <Grid item sm={12}>
                    {trdMaker()}
                    <TextField
                        className="enter-detail-input-box disabled-input"
                        variant="outlined"
                        value={props.tireRemovalData.currentTreadDepth}
                        disabled
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">
                        {formCaptions.AIRPRESSURETYPE}&nbsp;&nbsp;
                        <span className="required-mark">*</span>
                    </p>
                    <FormControlLabel
                        style={{
                            float: "left",
                            marginBlockStart: "15px",
                            fontWeight: "600",
                        }}
                        control={
                            <AntSwitch
                                checked={
                                    props.tireRemovalData.pressureType === "H" ? true : false
                                }
                                onChange={(e) =>
                                    props.onChangeListener("pressuretype", e.target.checked)
                                }
                            />
                        }
                        label={props.tireRemovalData.pressureType === "H" ? "Hot" : "Cold"}
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">
                        {formCaptions.AIRPRESSURE}&nbsp;&nbsp;
                        <span className="required-mark">*</span>
                    </p>
                    <ButtonGroup size="large" aria-label="medium outlined button group">
                        <Button
                            className={classes.buttons}
                            onClick={() =>
                                props.onChangeListener(
                                    "pressure",
                                    props.unitPreferences.pressure === "bar" ||
                                        props.unitPreferences.pressure === "Bar"
                                        ? Math.round(
                                            (Number(props.tireRemovalData.currentPressure) - 1) * 10
                                        ) / 10
                                        : props.tireRemovalData.currentPressure - 1
                                )
                            }
                        >
                            -
                        </Button>
                        <FormControl
                            variant="outlined"
                            className="enter-detail-input-box buttoned-input"
                        >
                            <OutlinedInput
                                value={!isNaN(parseInt(props.tireRemovalData.currentPressure, 10)) ? props.tireRemovalData.currentPressure : ""}
                                type="number"
                                endAdornment={
                                    <InputAdornment className={classes.adorn} position="end">
                                        {unitSelection()}
                                    </InputAdornment>
                                }
                                onChange={(e) =>
                                    props.onChangeListener(
                                        "pressure",
                                        props.unitPreferences.pressure === "bar" ||
                                            props.unitPreferences.pressure === "Bar"
                                            ? String(Math.round(Number(e.target.value) * 10) / 10)
                                            : commonUtils.minmaxInt(e.target.value, 0, 999999)
                                    )
                                }
                            />
                        </FormControl>
                        <Button
                            className={classes.buttons}
                            onClick={() =>
                                props.onChangeListener(
                                    "pressure",
                                    props.unitPreferences.pressure === "bar" ||
                                        props.unitPreferences.pressure === "Bar"
                                        ? Math.round(
                                            (Number(props.tireRemovalData.currentPressure) + 1) * 10
                                        ) / 10
                                        : props.tireRemovalData.currentPressure + 1
                                )
                            }
                        >
                            +
                        </Button>
                    </ButtonGroup>
                    <FormHelperText>{props.formHelpers.currentPressure}</FormHelperText>
                </Grid>
                {props.casingConditionList && props.casingConditionList.length > 0 && (
                    <Grid item sm={12}>
                        <p className="field-label">{formCaptions.CASINGCONDITION}</p>
                        <Select
                            native
                            className="enter-detail-input-box"
                            variant="outlined"
                            value={
                                props.tireRemovalData.casingConditionId === null
                                    ? 0
                                    : props.tireRemovalData.casingConditionId
                            }
                            IconComponent={ExpandMoreStyledIcon}
                            onChange={(e) =>
                                props.onChangeListener("casing", parseInt(e.target.value, 10))
                            }
                        >
                            <option key={0} value={0} disabled>
                                {tireFormCaptions.DROPDOWNPLACEHOLDER}
                            </option>
                            {props.casingConditionList.map((condition) => (
                                <option
                                    key={condition.casingConditionId}
                                    value={condition.casingConditionId}
                                >
                                    {condition.casingConditionName}
                                </option>
                            ))}
                        </Select>
                    </Grid>
                )}
                {props.wearConditionList && props.wearConditionList.length > 0 && (
                    <Grid item sm={12}>
                        <p className="field-label">{formCaptions.WEARCONDITION}</p>
                        <Select
                            native
                            className="enter-detail-input-box"
                            variant="outlined"
                            value={
                                props.tireRemovalData.wearConditionId === null
                                    ? 0
                                    : props.tireRemovalData.wearConditionId
                            }
                            IconComponent={ExpandMoreStyledIcon}
                            onChange={(e) =>
                                props.onChangeListener("wear", parseInt(e.target.value, 10))
                            }
                        >
                            <option key={0} value={0} disabled>
                                {tireFormCaptions.DROPDOWNPLACEHOLDER}
                            </option>
                            {props.wearConditionList.map((condition) => (
                                <option
                                    key={condition.wearConditionId}
                                    value={condition.wearConditionId}
                                >
                                    {condition.wearConditionName}
                                </option>
                            ))}
                        </Select>
                    </Grid>
                )}
                <Grid item sm={12}>
                    <p className="field-label">{formCaptions.COMMENTS}</p>
                    <TextField
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.tireRemovalData.comments}
                        onChange={(e) => {
                            props.onChangeListener("comments", commonUtils.removeLeadingSpaces(e.target.value));
                        }}
                        multiline
                        rows={2}
                        type="text"
                        placeholder={globalizationMapping.INSPECT.TIREINSPECTCOMMENTS}
                        inputProps={{ maxLength: 200 }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Grid className="upload-box-description" container spacing={1}>
                        <Grid item sm={6}>
                            <InputLabel className="upload-label">
                                {tireFormCaptions.UPLOADCAPTION}
                            </InputLabel>
                        </Grid>
                        <Grid item sm={6}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="image-input-hidden"
                                onChange={(e) => {
                                    props.onChangeListener("images", e.target);
                                }}
                                type="file"
                            />
                            <label htmlFor="image-input-hidden">
                                <Button
                                    className="upload-Button"
                                    variant="outlined"
                                    size="large"
                                    aria-label="upload picture"
                                    component="span"
                                    startIcon={<PhotoCameraRoundedIcon />}
                                >
                                    {tireFormCaptions.UPLOAD}
                                </Button>
                            </label>
                        </Grid>
                    </Grid>
                    <FormHelperText>{props.formHelpers.imgHelper}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    {props.tireimages.length !== 0 && (
                        <p className="field-label-upload">
                            {tireFormCaptions.IMAGESCAPTION}
                        </p>
                    )}
                    <Grid container spacing={1} className="uploaded-images">
                        {props.tireimages.length !== 0 &&
                            props.tireimages.map((source, key) => (
                                <Badge
                                    className={classes.deleteBadge}
                                    key={key}
                                    badgeContent={
                                        <img
                                            alt="delete"
                                            src={deleteicon}
                                            onClick={() => props.removeUploadedPhoto(key)}
                                        />
                                    }
                                    color="primary"
                                    variant="standard"
                                >
                                    <Grid item sm={4} className="uploaded-image">
                                        <img
                                            alt={"chosen-img-".concat((key + 1).toString())}
                                            src={source}
                                            width="160px"
                                            height="160px"
                                        />
                                    </Grid>
                                </Badge>
                            ))}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment >
    );
};

export default TireRemovalForm;
