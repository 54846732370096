import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";
const columnsToHide = {
  All: ["RimId"],
};
class ManageRimListSetter {
  constructor() {
    this.data = {};
    this.manageTireListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells, dispositionId = "All") {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] =
        columnsToHide[dispositionId.toString()] &&
        columnsToHide[dispositionId.toString()].indexOf(headCell.value) >= 0
          ? false
          : true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }
  search(originalTableData, searchText) {
    return originalTableData.filter((item, _index) => {
      return (
        item.RimSerialNumber.toLowerCase().includes(searchText) ||
        item.BrandNumber.toLowerCase().includes(searchText) ||
        item.VehicleStatus.toLowerCase().includes(searchText) ||
        item.Hours.toString().includes(searchText) ||
        item.DispositionName.toLowerCase().includes(searchText) ||
        item.TireSerialNumber.toLowerCase().includes(searchText) ||
        item.Km.toString().includes(searchText)
      );
    });
  }
  globalizationMapping = new Globalization();

  setFilterOptionsData(rowsData) {
    let filterOptions = {
      categories: [
        {
          name: this.globalizationMapping.MANAGERIM.RIMSERIALNUMBER,
          value: "RimSerialNumber",
        },
        {
          name: this.globalizationMapping.MANAGERIM.DISPOSITION,
          value: "DispositionName",
        },
        {
          name: this.globalizationMapping.MANAGERIM.TIRESERIALNUMBER,
          value: "TireSerialNumber",
        },
      ],
      categoryData: {
        RimSerialNumber: [],
        DispositionName: [],
        TireSerialNumber: [],
      },
    };
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, _index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          ) &&
          rowData[category.value] !== 0
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (
          appliedFilter[categoryKey].includes(rowData[categoryKey].toString())
        )
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data, globalAccountState) {
    // TABLE ROWS DATA
    const rimList = data.model;
    /** NOTE : the keys must match the headCells value to be able to SORT */
    rimList.forEach((rim, rimIndex) => {
      let wheelPosition;
      if (rim.wheelPosition) {
        wheelPosition = " (" + rim.wheelPosition + ")";
      }
      this.manageTireListData.push({
        Key: rimIndex,
        RimSerialNumber: rim.rimSerialNo ? rim.rimSerialNo : "",
        BrandNumber: rim.brandNumber ? rim.brandNumber : "",
        VehicleStatus: rim.vehicleNumber
          ? rim.vehicleNumber + wheelPosition
          : "",
        Hours: rim.currentHours ? rim.currentHours : "",
        DispositionName: rim.dispositionName ? rim.dispositionName : "",
        TireSerialNumber: rim.tireSerialNo ? rim.tireSerialNo : "",
        Km: rim.currentMiles ? rim.currentMiles : 0,
        RimId: rim.rimId ? rim.rimId : "",
      });
    });
    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.ManageRim;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;
      if (labelName === this.globalizationMapping["MANAGERIM"]["KM"]) {
        labelName =
          globalAccountState.accountInfo.userSetting
            .userMeasurementSystemValue === "imperial"
            ? this.globalizationMapping["MANAGERIM"]["MILES"]
            : this.globalizationMapping["MANAGERIM"]["KM"];
      }
      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.manageTireListData,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.manageVehicleListData),
      selectedColShow: selectedColShow,
    };
  }

  setFilteredData(rimList, dispositionId) {
    // TABLE ROWS DATA
    // const locationList = data.model.locationList;

    // locationList.forEach((location, index) =>
    /** NOTE : the keys must match the headCells value to be able to SORT */
    const data = [];
    rimList.forEach((rim, rimIndex) => {
      if (rim.dispositionId === dispositionId || dispositionId === null) {

        const VehicleStatusSetter = () => {
          if (rim.vehicleNumber) {
            return rim.vehicleNumber;
          } else {
            if ("" + rim.wheelPosition) {
              return "/";
            } else {
              if ("" + rim.wheelPosition) {
                return rim.wheelPosition;
              } else {
                return "";
              }
            }
          }
        }

        data.push({
          Key: rimIndex,
          RimSerialNumber: rim.rimSerialNo ? rim.rimSerialNo : "",
          BrandNumber: rim.brandNumber ? rim.brandNumber : "",
          VehicleStatus: VehicleStatusSetter(),
          Hours: rim.currentHours ? rim.currentHours : "",
          Disposition: rim.dispositionName ? rim.dispositionName : "",
          TireSerialNumber: rim.tireSerialNo ? rim.tireSerialNo : "",
          Km: rim.currentMiles ? rim.currentMiles : 0,
          RimId: rim.rimId ? rim.rimId : "",
        });
      }
    });
    // });
    // TABLE HEADER DATA
    this.headCells = [];
    const columnDefinitions = ColumnDefinitions.ManageRim;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(
      this.headCells,
      dispositionId || "All"
    );
    return {
      rowsData: data,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.manageVehicleListData),
      selectedColShow: selectedColShow,
    };
  }

  setDataByLocation(data, locationIds) {
    const rimList = data.model;
    let rimData = [];
    rimList.forEach((rim, rimIndex) => {
      if (locationIds.indexOf(rim.locationId) > -1) {
        let wheelPosition;
        if (rim.wheelPosition) {
          wheelPosition = " (" + rim.wheelPosition + ")";
        }
        rimData.push({
          Key: rimIndex,
          RimSerialNumber: rim.rimSerialNo ? rim.rimSerialNo : "",
          BrandNumber: rim.brandNumber ? rim.brandNumber : "",
          VehicleStatus: rim.vehicleNumber
            ? rim.vehicleNumber + wheelPosition
            : "",
          Hours: rim.currentHours ? rim.currentHours : "",
          DispositionName: rim.dispositionName ? rim.dispositionName : "",
          TireSerialNumber: rim.tireSerialNo ? rim.tireSerialNo : "",
          Km: rim.currentMiles ? rim.currentMiles : 0,
          RimId: rim.rimId ? rim.rimId : "",
        });
      }
    });
    return rimData;
  }
}
export default ManageRimListSetter;
