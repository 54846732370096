import { Backdrop, Button, makeStyles, Modal } from "@material-ui/core";
import React from "react";
import Globalization from "../../../../globalization";
import TireHistoryApis from "../../services/tire-history-update-apis";
const useStyles = makeStyles({
  proceed: {
    "&:hover": {
      opacity: 1
    },
    background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none"
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none"
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 1,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none"
  }
});

const DeleteEventModal = (props) => {
  const styles = useStyles();
  const globalizationMapping = new Globalization();
  const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
  const titleTexts = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
  const tireHistoryApis = new TireHistoryApis();

  const handleDeleteClick = () => {
    let successStatus = false;
    tireHistoryApis.deleteEvent(props.eventData.EventId).then((response) => {
      if (response.didError === false) {
        successStatus = true;
      } else {
        successStatus = false;
      }
      closeModal({
        updatedSection: "delteevent",
        updateStatus: successStatus,
        successMessage: notificationTexts.EVENTDELETED,
        successMessageDesc: notificationTexts.DELETEEVENTSUCCESS.replace("#1#1", props.eventData.Event)
      });
    });
  };
  const closeModal = (state) => {
    props.closeEventDeleteModal(state);
  };

  return (
    <React.Fragment>
      <div className="container">
        <Modal
          open={true}
          onClose={!props.isModalOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300
          }}
          disableScrollLock={true}
        >
          <div className={`delete-vehicle-modal-container center-modal-vehicle ${props.isSelectionMultiple && props.rimIds.length > 50 ? "delete-multiple-selected" : ""}`}>
            <div className="delete-vehicle-title-container">
              <div className="delete-vehicle-title">{titleTexts.DELETEEVENT}</div>
              <div className="close-icon-container" onClick={props.closeEventDeleteModal}>
                <img src={require("../../../../assets/close-icon.svg")} className="modal-close-icon" alt="close-icon" />
              </div>
            </div>
            <div className="delete-modal-description-vehicle">
              <p className="vehicle-ids">{titleTexts.DELETETIREEVENT}</p>
            </div>
            <div className="delete-modal-description-vehicle" style={{ marginTop: "-25px" }}>
              <p>{titleTexts.WISHPROCEED}</p>
            </div>
            <div className="modal-action-buttons">
              <Button className={styles.cancel} variant="contained" disableElevation={true} onClick={props.closeEventDeleteModal}>
                {globalizationMapping.FORMS.CANCEL}
              </Button>
              <Button
                className={styles.proceed}
                variant="contained"
                disableElevation={true}
                color="primary"
                onClick={() => {
                  handleDeleteClick();
                }}
              >
                {globalizationMapping.FORMS.PROCEED}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default DeleteEventModal;
