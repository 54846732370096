import React, { useState, forwardRef} from "react";
import { Grid, Tab, Tabs, AppBar,Box, Typography } from '@material-ui/core';
import "./home-dashboard.css";
import Globalization from "../../../../globalization";
import ImageAllOk from "../../../../assets/all-ok-image.svg";
import CommonUtils from "../../../../services/utils";

const globalizationMapping = new Globalization();
const commonUtils = new CommonUtils();

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'} variant={'body2'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const BroadcastMessages = forwardRef(({broadCastData, modalClose}, ref) => {

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    return (
        <div>
      


      <div className="whats-new-container center-modal">
                      <header className="header-msg">
                        <div>
                          <span >{globalizationMapping.HIGHLIGHTS.WHATSNEW}</span>{" "}
                            <AppBar position="static" style={{paddingTop:10, backgroundColor: "#083475"}}>
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" style={{color:"black", backgroundColor: "#083475"}} >
                                    {broadCastData?.map((dataMsg, index) => (
                                        <Tab key={index} label={commonUtils.formatISODate(dataMsg.updatedAt)} {...a11yProps(index)} />
                                    ))}
                                </Tabs>
                            </AppBar>
                        </div>
                      </header>

                      <Grid container spacing={10} style={{height:'78%'}}>
                        <Grid item container justify="flex-start" sm={2} alignItems="stretch" direction="row">
                          <Grid item sm={12}>
                            <img src={ImageAllOk} alt="tick-all-ok" height="100%" width="220%" />
                          </Grid>
                        </Grid>
                        <Grid item sm={1}></Grid>
                        <Grid item sm={9} style={{maxHeight:800, overflowY:'auto'}}>

                          {broadCastData?.map((dataMsg, index) => (
                            <TabPanel value={value} index={index} key={index}>
                            <div key={dataMsg.title} className="chatBubble" style={{height:580,   overflowY:"scroll"}}>
                              <div className="message">
                                <div className="broad-body">
                                  <div className="header-change-modal-title-container1">
                                    <div className="header-change-modal-title1">{dataMsg.title}</div>
                                    <p>{commonUtils.formatISODate(dataMsg.updatedAt)}</p>
                                  </div>
                                  <div className="change-modal-select-label1 ql-editor" dangerouslySetInnerHTML={{ __html: dataMsg.description }} />
                                </div>
                              </div>
                            </div>
                            </TabPanel>
                          ))}
                        </Grid>
                      </Grid>

                      <footer className="footer-msg" style={{marginTop:'6%'}}>
                        <div className="modal-button-group1">
                          <button className="header-change-modal-confirm-btn1" onClick={() => modalClose()}>
                            {globalizationMapping.HIGHLIGHTS.CLOSE}
                          </button>
                        </div>
                      </footer>
                    </div>
    </div>
    );
});

export default BroadcastMessages;