import React, { useState, useEffect } from "react";
import "./ManageTiresNavigation.css";
import Breadcrumbs from "../../../../common-components/breadcrumbs/breadcrumbs";
import { List, ListItem } from "@material-ui/core";
import Globalization from "../../../../globalization/index";
import { useHistory, useLocation } from "react-router-dom";
import { ManageTiresPaths } from "../../../../routing/ManageTiresPaths";
const ManageTiresNavigation = ({ changedRoute, modalActionTracker, globalAccountState }) => {
  const globalizationMapping = new Globalization();
  const manageTiresGlobalTexts = globalizationMapping.MANAGETIREREPORTS;
  const manageTiresNav = [
    manageTiresGlobalTexts.ManageTireAll,
    manageTiresGlobalTexts.ManageTireInstalled,
    manageTiresGlobalTexts.ManageTireInventory,
    manageTiresGlobalTexts.ManageTireOnHold,
    manageTiresGlobalTexts.ManageTireScrap,
    manageTiresGlobalTexts.ManageTireRepair,
    manageTiresGlobalTexts.ManageTireRetread
  ];
  const manageTiresPathNav = ["manageTireAll", "manage-tire", "manageTireInventory", "manageTireonHold", "manageTireScrap", "manageTireRepair", "manageTireRetread"];

  const getRouteName = (currentPathName) => {
    let pathSelected = ManageTiresPaths.find((route) => route.path === currentPathName);
    return pathSelected;
  };

  const getRoutePath = (_manageTireSelected, path) => {
    let pathSelected = ManageTiresPaths.find((route) => route.path === path);
    return pathSelected;
  };

  const history = useHistory();
  let currentPathName = history.location.pathname.split("/")[1];
  const locationObj = useLocation();
  let currentRouteSelected = getRouteName(currentPathName);
  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    let tempPathName = "";
    if (locationObj && locationObj.state) {
      switch (locationObj.state.dispositionId) {
        case 8:
          tempPathName = "manageTireInventory";
          break;
        case 1:
          tempPathName = "manageTireScrap";
          break;
        case 7:
          tempPathName = "manage-tire";
          break;
        case 4:
          tempPathName = "manageTireonHold";
          break;
        case 2:
          tempPathName = "manageTireRepair";
          break;
        case 3:
          tempPathName = "manageTireRetread";
          break;
        default:
          tempPathName = "manageTireAll";
          break;
      }
    } else {
      tempPathName = "manageTireAll";
    }
    let RouteSelected = getRouteName(tempPathName);
    setSelectedRoute(RouteSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalActionTracker, globalAccountState]);

  const globaldHeaderTexts = globalizationMapping.HEADER;

  const breadcrumbs = [
    {
      path: "home",
      name: globaldHeaderTexts.HOME
    },
    {
      path: "",
      name: globaldHeaderTexts.MANAGETIRES
    }
  ];

  const manageTiresNavigation = (manageTireSelected, path) => {
    let routePath = getRoutePath(manageTireSelected, path);
    setSelectedRoute(routePath);
    changedRoute(routePath);
  };

  useEffect(() => {
    // history.push(selectedRoute.path)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  return (
    <div>
      <div className="manage-tires-main-container">
        <div className="manage-tires-upper-deck">
          <div className="background-img-container-manage-tire">
            <div className="background-map-image background-map-image-manage-tire">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="manage-tires-title">{globaldHeaderTexts.MANAGETIRES}</div>
                </div>
              </div>
              <div className="manage-button-manage-tires-nav-container">
                <div className="manage-tires-nav-container">
                  {manageTiresNav.map((item, index) => {
                    return (
                      <div key={item} className="manage-tires-nav-card-container" onClick={() => manageTiresNavigation(item, manageTiresPathNav[index])}>
                        <List className={selectedRoute.name === item ? "active-manage-tires-nav-card" : "manage-tires-nav-card"}>
                          <ListItem button={selectedRoute.name === item} autoFocus={selectedRoute.name === item}>
                            <span className={selectedRoute.name === item ? "active-manage-tires-nav-card-text" : "manage-tires-nav-card-text"}>{item}</span>
                          </ListItem>
                        </List>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageTiresNavigation;
