import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';

export default class SearchInstallTireListApis {
    cs;
    constructor() {
        this.cs = new CommonService();
    }
    searchInstallTireGetDetailsByAccount = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getTireByAccount}${parentAccountId}`, 'get', {})
    }

    searchInstallTireToInstallTire = (tireDetails) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.putInstallTire}`, 'put', tireDetails)
    }

}