import React, { useState, useEffect } from "react";
import "./AdminRequestNavigation.css";
import Breadcrumbs from "../../../../common-components/breadcrumbs/breadcrumbs";
import { List, ListItem } from "@material-ui/core";
import Globalization from "../../../../globalization/index";
import { useHistory } from "react-router-dom";
import { AdminPaths } from "../../../../routing/AdminPaths";
const AdminNavigation = ({ changedRoute }) => {
  const globalizationMapping = new Globalization();
  const adminGlobalTexts = globalizationMapping.ADMIN;
  const adminNav = [
    adminGlobalTexts.MASTERDATAREQUEST,
    adminGlobalTexts.ADDNEW,
    adminGlobalTexts.UPDATEACCOUNTDETAILS,
    adminGlobalTexts.UPDATEBROADCASTMSG,
  ];
  const adminPathNav = [
    "admin",
    "add-delete",
    "updateaccountdetails",
    "updatebroadcastmsg",
  ];

  const getRouteName = (currentPathName) => {
    return AdminPaths.find((route) => route.path === currentPathName);
  };

  const getRoutePath = (_adminSelected, path) => {
    return AdminPaths.find((route) => route.path === path);
  };

  const history = useHistory();
  let currentPathName = history.location.pathname.split("/")[1];
  let currentRouteSelected = getRouteName(currentPathName);
  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line no-unused-vars

  const globaldHeaderTexts = globalizationMapping.HEADER;

  const breadcrumbs = [
    {
      path: "home",
      name: globaldHeaderTexts.HOME,
    },
    {
      path: "",
      name: globaldHeaderTexts.ADMIN,
    },
  ];

  const adminNavigation = (adminSelected, path) => {
    let routePath = getRoutePath(adminSelected, path);
    setSelectedRoute(routePath);
    changedRoute(routePath);
  };

  useEffect(() => {}, [selectedRoute]);

  return (
    <div>
      <div className="admin-main-container">
        <div className="admin-upper-deck">
          <div className="background-img-container-admin-view">
            <div className="background-map-image-admin-view">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="admin-title">{globaldHeaderTexts.ADMIN}</div>
                </div>
              </div>
              <div className="manage-button-admin-nav-container">
                <div className="admin-nav-container">
                  {adminNav.map((item, index) => {
                    return (
                      <div
                        key={item}
                        className="admin-nav-card-container"
                        onClick={() =>
                          adminNavigation(item, adminPathNav[index])
                        }
                      >
                        <List
                          className={
                            selectedRoute.name === item
                              ? "active-admin-nav-card"
                              : "admin-nav-card"
                          }
                        >
                          <ListItem
                            button={selectedRoute.name === item}
                            autoFocus={selectedRoute.name === item}
                          >
                            <span
                              className={
                                selectedRoute.name === item
                                  ? "active-admin-nav-card-text"
                                  : "admin-nav-card-text"
                              }
                            >
                              {item === "Add/Delete" ? `${item} Dropdown Selection` : item}
                            </span>
                          </ListItem>
                        </List>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavigation;
