import React, { useEffect, useContext, useState } from "react";
import "./QuickInspect.css";
import moment from "moment";
import "../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent } from "@material-ui/core";
import QuickInspectListApis from "../../../services/QuickInspectListApis";
import { StateContext } from "../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import Spinner from "../../../../../common-components/spinner/spinner";
import VehicleHistoryListTable from "../table/VehicleHistory";
import VehicleHistorySetter from "../../../services/QuickInspectListUtils";
import InspectionHistoryListTable from "../table/InspectionHistoryTable";
import InspectionHistorySetter from "../../../services/InspectionHistoryUtils";
import VehicleListSetter from "../../../services/VehicleListUtils";
import VehicleIdListTable from "../table/VehicleIdListTable";
import VehicleInspection from "../table/VehicleInspection";
import Breadcrumbs from "../../../../../common-components/breadcrumbs/breadcrumbs";
import { useLocation, useParams } from "react-router-dom";
import FlashMessage from "../../../../../common-components/pop-up-notification/notification-snackbar";
import Globalization from "../../../../../globalization";

const QuickInspect = () => {
  const locationObj = useLocation();
  const [globalAccountState] = useContext(StateContext);
  const [vehicleHistoryListTableData, setVehicleHistoryListTableData] = useState();
  const [inspectionHistoryListTableData, setInspectionHistoryListTableData] = useState();
  const [vehicleDetails, setVehicleDetails] = useState();
  const [vehicleIdListTableData, setVehicleIdListTableData] = useState();
  const [showColumns, setShowColumns] = useState();
  const [showColumnsVehicleHistory, setShowColumnsVehicleHistory] = useState();
  const [showColumnsInspectionHistory, setShowColumnsInspectionHistory] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const [flashMessageState, setFlashMessageState] = useState(locationObj);

  const quickInspectListService = new QuickInspectListApis();
  const vehicleHistorySetter = new VehicleHistorySetter();
  const inspectionHistorySetter = new InspectionHistorySetter();
  const vehicleIdSetter = new VehicleListSetter();

  const [showSpinner, setShowSpinner] = useState(true);
  const [serviceCallCompleted, setServiceCallCompleted] = useState(0);
  const { VehicleId } = useParams();
  const [imageInspectionSummary, setImageInspectionSummary] = useState();
  const [showIsUpdate, setIsUpdate] = useState(true);
  const [showUpdateMsg, setUpdateMsg] = useState(true);
  const [positionVehicleIdDiv, setPositionVehicleIdDiv] = useState("fixed");
  const globalizationMapping = new Globalization();

  const breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME,
    },
    {
      path: "",
      name: globalizationMapping.INSPECT.QUICKINSPECT,
    },
  ];

  useEffect(() => {
    manageVehicleListResponseData();
    window.addEventListener("scroll", listenToScroll);
    initNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, VehicleId]);


  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    if (scrolled > 0.43) {
      setPositionVehicleIdDiv("absolute");
    } else {
      setPositionVehicleIdDiv("fixed");
    }
  };


  const recallAPI = () => {
    quickInspectListService.getVehicleDetails(VehicleId).then(data => {
      const manageVehicleListTableInfo = vehicleHistorySetter.setData(data);
      setVehicleHistoryListTableData(manageVehicleListTableInfo);
      let count = 0;
      setVehicleDetails(data.model);
      if (
        data &&
        data.model &&
        data.model.lastUpdatedDate &&
        data.model.lastUpdatedDate !== "0001-01-01T00:00:00"
      ) {
        const date = moment(new Date(data.model.lastUpdatedDate)).format(
          "DD-MM-YYYY"
        );
        const today = moment(new Date()).format("DD-MM-YYYY");
        if (date === today) {
          count = 1;
        }
      } else {
        if (data.model.lastUpdatedDate === "0001-01-01T00:00:00") {
          const visitedVehicleList = JSON.parse(
            sessionStorage.getItem("visitedVehicles")
          );
          if (
            visitedVehicleList &&
            Array.isArray(visitedVehicleList) &&
            visitedVehicleList.length
          ) {
            const i = visitedVehicleList.findIndex(ele => {
              if (ele.toString() === VehicleId.toString()) {
                return ele;
              }
            });
            if (i !== -1) {
              count++;
            }
          }
        }
      }
      if (count > 0) {
        setUpdateMsg(false);
        setIsUpdate(true);
      } else {
        setIsUpdate(true);
        setUpdateMsg(true);
      }
      setShowColumnsVehicleHistory(manageVehicleListTableInfo.selectedColShow);
    });
  };


  const manageVehicleListResponseData = () => {
    let loading = 0;

    const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    const accountIds = accountInfoObject.accountIds;

    setShowSpinner(true);
    quickInspectListService
      .manageVehicleGetDetailsByAccount(accountIds[0])
      .then(data => {
        const vehicleListTableInfo = vehicleIdSetter.setData(
          data,
          globalAccountState.accountInfo.location
        );
        setVehicleIdListTableData(vehicleListTableInfo);
        setShowColumns(vehicleListTableInfo.selectedColShow);
        loading++;
        setServiceCallCompleted(loading);
        if (serviceCallCompleted === 3 || loading === 3) {
          setShowSpinner(false);
        }
      });

    quickInspectListService
      .getInspectionSummaryForVehicle(VehicleId)
      .then(data => {
        const inspectionHistoryTableInfo =
          inspectionHistorySetter.setData(data);
        setInspectionHistoryListTableData(inspectionHistoryTableInfo);
        setShowColumnsInspectionHistory(
          inspectionHistoryTableInfo.selectedColShow
        );
        if (data && data.model) {
          setImageInspectionSummary(data.model);
        } else {
          setImageInspectionSummary(null);
        }
        loading++;
        setServiceCallCompleted(loading);
        if (serviceCallCompleted === 3 || loading === 3) {
          setShowSpinner(false);
        }
      });

    quickInspectListService.getVehicleDetails(VehicleId).then(data => {
      const manageVehicleListTableInfo = vehicleHistorySetter.setData(data);
      setVehicleHistoryListTableData(manageVehicleListTableInfo);
      let count = 0;
      setVehicleDetails(data.model);
      if (
        data &&
        data.model &&
        data.model.lastUpdatedDate &&
        data.model.lastUpdatedDate !== "0001-01-01T00:00:00"
      ) {
        const date = moment(new Date(data.model.lastUpdatedDate)).format(
          "DD-MM-YYYY"
        );
        const today = moment(new Date()).format("DD-MM-YYYY");
        if (date === today) {
          count = 1;
        }
      } else {
        if (data.model.lastUpdatedDate === "0001-01-01T00:00:00") {
          const visitedVehicleList = JSON.parse(
            sessionStorage.getItem("visitedVehicles")
          );
          if (
            visitedVehicleList &&
            Array.isArray(visitedVehicleList) &&
            visitedVehicleList.length
          ) {
            const i = visitedVehicleList.findIndex(ele => {
              if (ele.toString() === VehicleId.toString()) {
                return ele;
              }
            });
            if (i !== -1) {
              count++;
            }
          }
        }
      }
      if (count > 0) {
        setUpdateMsg(false);
        setIsUpdate(true);
      } else {
        setIsUpdate(true);
        setUpdateMsg(true);
      }
      setShowColumnsVehicleHistory(manageVehicleListTableInfo.selectedColShow);
      loading++;
      setServiceCallCompleted(loading);
      if (serviceCallCompleted === 3 || loading === 3) {
        setShowSpinner(false);
      }
    });
  };

  const successNotificationMessage = (body, fromWhere) => {
    setFlashMessageState({ state: null });
    setFlashMessageState(body);
    setShowNotification(true);
    if (fromWhere === "Submit") {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const initNotification = () => {
    if (locationObj.state && locationObj.state.updatedSection) {
      switch (locationObj.state.updatedSection) {
        case "editoldtire":
        case "inspecttire":
        case "removeinstalledtire":
          if (locationObj.state.updateStatus) {
            setShowNotification(true);
          }
          break;
        default:
          setTimeout(() => {
            setShowNotification(false);
          }, 5000);
          break;
      }
      setFlashMessageState(locationObj);
    }
  };

  return (
    <div>
      <div className="standalone-new-header-container">
        <div className="quick-inspect-main-container">
          <div className="my-profile-upper-deck">
            <div className="background-img-container">
              <div className="background-map-image">
                <div className="dashboard-header">
                  <div>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />{" "}
                    <div className="scrap-tires-title">
                      {" "}
                      {globalizationMapping.INSPECT.QUICKINSPECT}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
        {showNotification && flashMessageState.state && (
          <FlashMessage
            status={flashMessageState.state.updateStatus}
            message={flashMessageState.state.successMessage}
            description={flashMessageState.state.successMessageDesc}
            red={flashMessageState.state.red}
          />
        )}
        {showSpinner && <Spinner />}
        {!showSpinner && (
          <div style={{ display: "table" }}>
            <div
              className="quick-inspect-list-container-inspection"
              style={{ position: positionVehicleIdDiv }}
            >
              <Card className="quick-inspect-list-card">
                <CardContent>
                  <div className="data-summary-table">
                    {" "}
                    {vehicleIdListTableData && showColumns && (
                      <VehicleIdListTable
                        tableData={vehicleIdListTableData}
                        showColumns={showColumns}
                        vehicleId={VehicleId}
                      />
                    )}{" "}
                  </div>{" "}
                </CardContent>{" "}
              </Card>{" "}
            </div>{" "}
            <div className="quick-inspect-list-container-vehicle-inspection">
              <Card className="quick-inspect-list-card">
                <CardContent>
                  <div className="data-summary-table">
                    <VehicleInspection
                      vehicleId={VehicleId}
                      vehicleDetails={vehicleDetails}
                      imageInspectionSummary={imageInspectionSummary}
                      successNotificationMessage={successNotificationMessage}
                      showIsUpdate={showIsUpdate}
                      showUpdateMsg={showUpdateMsg}
                      setInspectionHistoryListTableData={setInspectionHistoryListTableData}
                      setShowColumnsInspectionHistory={setShowColumnsInspectionHistory}
                      setImageInspectionSummary={setImageInspectionSummary}
                    />{" "}
                    {/* )}  */}{" "}
                  </div>{" "}
                </CardContent>{" "}
              </Card>{" "}
            </div>{" "}
          </div>
        )}{" "}
        {!showSpinner && (
          <div className="quick-inspect-list-container-vehicle">
            <Card className="quick-inspect-list-card">
              <CardContent>
                <div className="data-summary-table">
                  {" "}
                  {inspectionHistoryListTableData &&
                    showColumnsInspectionHistory && (
                      <InspectionHistoryListTable
                        tableData={inspectionHistoryListTableData}
                        showColumns={showColumnsInspectionHistory}
                      />
                    )}{" "}
                </div>{" "}
              </CardContent>{" "}
            </Card>{" "}
          </div>
        )}{" "}
        {!showSpinner && (
          <div className="quick-inspect-list-container-vehicle">
            <Card className="quick-inspect-list-card">
              <CardContent>
                <div className="data-summary-table">
                  {" "}
                  {vehicleHistoryListTableData && showColumnsVehicleHistory && (
                    <VehicleHistoryListTable
                      tableData={vehicleHistoryListTableData}
                      showColumns={showColumnsVehicleHistory}
                      vehicleId={VehicleId}
                      recallAPI={recallAPI}
                      userPref={JSON.parse(
                        localStorage.getItem(
                          "accountInfouserMeasurementSystemValue"
                        )
                      )}
                    />
                  )}{" "}
                </div>{" "}
              </CardContent>{" "}
            </Card>{" "}
          </div>
        )}{" "}
      </div>
    </div>
  );
};
export default QuickInspect;
