import React, { useEffect, useState } from "react";
import "./ManageParentAccountListTable.css";
import "../../../../../common-styles/TableStyle.css";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Divider,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from '@material-ui/icons/MoreVertOutlined';
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../globalization";
import DeleteAccountModal from "../modal/delete-account/DeleteAccount";
import CommonUtils from "../../../../../services/utils";
// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    height: 910,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
  },
}));

const commonUtils = new CommonUtils();

function removeDateNaN(row) {
  let item = JSON.parse(JSON.stringify(row));
  return item && item !== "NaN/NaN/NaN" ? item : "";
}

function descendingComparator(a, b, orderBy) {
  let firstVal = a[orderBy];
  let secondVal = b[orderBy];

  // Function to check if a value is a valid date
  const isValidDate = (value) => !isNaN(new Date(value).getTime());

  if (isValidDate(firstVal) && isValidDate(secondVal)) {
    // Compare date objects
    if (firstVal < secondVal) {
      return 1;
    } else if (firstVal > secondVal) {
      return -1;
    }
    return 0;
  }

  if (typeof firstVal === 'string' && typeof secondVal === 'string') {
    firstVal = firstVal.toLowerCase().trim();
    secondVal = secondVal.toLowerCase().trim();
  }
  if (typeof firstVal === 'number' && typeof secondVal === 'number') {
    firstVal = Number(firstVal);
    secondVal = Number(secondVal);
  }

  if (!isValidDate(firstVal) && isValidDate(secondVal)) {
    return 1; // Second value is valid, so it should come before the invalid one.
  }
  if (isValidDate(firstVal) && !isValidDate(secondVal)) {
    return -1; // First value is valid, so it should come before the invalid one.
  }

  // Compare other values (strings and numbers)
  if (firstVal < secondVal) {
    return 1;
  } else if (firstVal > secondVal) {
    return -1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => {
    el.UpdatedDate = new Date(commonUtils.tableDateFormatter(el.UpdatedDate));
    el.LastInspectionDate = new Date(
      commonUtils.tableDateFormatter(el.LastInspectionDate)
    );
    return [el, index];
  });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => {
    el[0].UpdatedDate = GetFormattedDate(el[0].UpdatedDate);
    el[0].LastInspectionDate = GetFormattedDate(el[0].LastInspectionDate);
    return el[0];
  });
}

const GetFormattedDate = (inputDate) => {
  let todayTime = inputDate;
  let month = todayTime.getMonth() + 1;
  let day = todayTime.getDate();
  let year = todayTime.getFullYear();
  let newDate = month + "/" + day + "/" + year;
  newDate = commonUtils.tableDateFormatter(newDate);
  return newDate;
};

const ManageParentAccountListTable = ({
  tableData,
  _filterApplied,
  showColumns,
  multipleRowsSelected,
  forceActionPopupClose,
  forceActionPopupCloseToFalse,
}) => {
  const [
    manageParentAccountListTableData,
    setManageParentAccountListTableData,
  ] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("AccountName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const globalizationMapping = new Globalization();
  const [rowsSelected, setRowsSelected] = useState(0);
  const [editClicked, setEditClicked] = useState([]);
  const [selectedAccIds, setSelectedAccIds] = useState([]);
  const [selectedAccDetails, setSelectedAccDetails] = useState([]);
  const [accountIdToDelete, setAccountIdToDelete] = useState(null);
  const [accountNameToDelete, setAccountNameToDelete] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);


  const history = useHistory();
  const globalization = new Globalization();

  useEffect(() => {
    setManageParentAccountListTableData(tableData);
    let row = [];
    row.length = manageParentAccountListTableData.rowsData.length;
    for (let i = 0; i < manageParentAccountListTableData.rowsData.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
    let arr = commonUtils.rowPerPageSelector(manageParentAccountListTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  useEffect(()=> {
    forceCloseAction();
    forceActionPopupCloseToFalse();
  },[forceActionPopupClose]);

  const handleRequestSort = (property) => (_event) => {
    forceCloseAction();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleChangePage = (_event, newPage) => {
    forceCloseAction();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    forceCloseAction();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const onSelectAllClick = () => {
    forceCloseAction();
    setSelectedAccDetails([]);
    if (rowsSelected !== manageParentAccountListTableData.rowsData.length) {
      let accIDs = manageParentAccountListTableData.rowsData.map(
        (val, _index) => {
          return val.AccountID;
        }
      );
      setSelectedAccIds(accIDs);
      setRowsSelected(manageParentAccountListTableData.rowsData.length);
      multipleRowsSelected(manageParentAccountListTableData.rowsData);
    } else {
      setSelectedAccIds([]);
      setRowsSelected(0);
      multipleRowsSelected([]);
    }
  };

  const onRowSelect = (_index, accountID) => {
    forceCloseAction();
    let ind = selectedAccIds.indexOf(accountID);
    let selectedCount = selectedAccDetails.length;
    if (ind > -1) {
      selectedAccIds.splice(ind, 1);
      selectedAccDetails.splice(ind, 1);
      selectedCount--;
    } else {
      selectedCount++;
      let selectedIds = selectedAccIds;
      selectedIds.push(accountID);
      setSelectedAccIds(selectedIds);
      let totalSelectedAcc = selectedAccDetails;
      let Account = manageParentAccountListTableData.rowsData.find(
        (element) => element.AccountID === accountID
      );
      totalSelectedAcc.push(Account);
      setSelectedAccDetails(totalSelectedAcc);
    }
    multipleRowsSelected(selectedAccDetails);
    setRowsSelected(selectedCount);
  };

  const editRow = (index) => {
    let row = [...editClicked];
    row.length = manageParentAccountListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      if (row[i] === true && index !== i) {
        row[i] = false;
      }
    }
    if (editClicked[index]) {
      row[index] = !row[index];
      setEditClicked(row);
    } else {
      row[index] = true;
      setEditClicked(row);
    }
  };

  const forceCloseAction = () => {
    let row = [...editClicked];
    row.length = manageParentAccountListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
  }

  const handleDeleteClick = (index, vehicleId, number) => {
    editRow(index);
    setAccountIdToDelete(vehicleId);
    setAccountNameToDelete(number);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setAccountIdToDelete(null);
    setAccountNameToDelete(null);
  };
  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.stickyHeader}
                    padding="checkbox"
                  >
                    <Checkbox
                      indeterminate={
                        rowsSelected > 0 &&
                        rowsSelected <
                          manageParentAccountListTableData.rowsData.length
                      }
                      checked={
                        manageParentAccountListTableData.rowsData.length > 0 &&
                        rowsSelected ===
                          manageParentAccountListTableData.rowsData.length
                      }
                      style={{
                        color: "#0055A4",
                        zIndex: 2,
                      }}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  </TableCell>
                  {manageParentAccountListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] && headCell.keyIndex !== 8 ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align={headCell.numeric === true ? "right" : "left"}
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort(headCell.value)}
                        >
                          {headCell.label}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        {" "}
                        {globalization.INSPECT.ACTION}{" "}
                      </TableCell>
                    ) 
                  )}
                </TableRow>
              </TableHead>
                <tbody>
                  <tr className="table-bottom-line">
                    <td
                      colSpan={manageParentAccountListTableData.headCells.length + 1}
                    ></td>
                  </tr>
                </tbody>
              {manageParentAccountListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    manageParentAccountListTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          tabIndex={-1}
                          key={row.Key}
                        >
                          <TableCell
                            style={{ position: "relative" }}
                            className="table-content-text"
                            padding="checkbox"
                          >
                            <Checkbox
                              checked={
                                rowsSelected ===
                                  manageParentAccountListTableData.rowsData
                                    .length ||
                                selectedAccIds.indexOf(row.AccountID) > -1
                              }
                              style={{
                                color: "#0055A4",
                              }}
                              onChange={() => onRowSelect(index, row.AccountID)}
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                            />
                          </TableCell>
                          {showColumns.AccountName && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.AccountName}
                            </TableCell>
                          )}
                          {showColumns.Country && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Country}
                            </TableCell>
                          )}
                          {showColumns.State && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.State}
                            </TableCell>
                          )}
                          {showColumns.City && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.City}
                            </TableCell>
                          )}
                          {showColumns.EmailAddress && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.EmailAddress}
                            </TableCell>
                          )}
                          {showColumns.LocationsCount && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.LocationsCount}
                            </TableCell>
                          )}
                          {showColumns.UpdatedDate && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {removeDateNaN(row.UpdatedDate)}
                            </TableCell>
                          )}
                          {showColumns.LastInspectionDate && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {removeDateNaN(row.LastInspectionDate)}
                            </TableCell>
                          )}
                          <TableCell
                            align="left"
                            className="table-content-text"
                            style={{ position: "relative" }}
                          >
                            <IconButton
                              aria-label="edit"
                              onClick={() => editRow(index)}
                            >
                              <MoreIcon style={{color:commonUtils.primaryBlue}} fontSize='small'></MoreIcon>
                            </IconButton>
                            {editClicked[index] === true && (
                              <div className="action-dropdown-content">
                                <div
                                  className="action-option"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/view-parent-account",
                                      state: {
                                        accountId: row.AccountID,
                                      },
                                    })
                                  }
                                >
                                  {globalizationMapping.HOMEDASHBOARD.VIEW}
                                </div>
                                <Divider />
                                <div
                                  className="action-option"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/editparentaccount",
                                      state: {
                                        accountId: row.AccountID,
                                      },
                                    })
                                  }
                                >
                                  {globalizationMapping.PROFILE.Edit}
                                </div>
                                <Divider />
                                <div
                                  className="action-option"
                                  onClick={() => {
                                    handleDeleteClick(
                                      index,
                                      row.AccountID,
                                      row.AccountName
                                    );
                                  }}
                                >
                                  {
                                    globalizationMapping.MANAGE_PARENT_ACCOUNT
                                      .OPTION3
                                  }
                                </div>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {manageParentAccountListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={9}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {isDeleteModalOpen && (
                <DeleteAccountModal
                  closeDeleteModal={handleDeleteModalClose}
                  accountId={accountIdToDelete}
                  accountName={accountNameToDelete}
                  isSelectionMultiple={false}
                />
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={manageParentAccountListTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default ManageParentAccountListTable;
