import React, { useEffect, useState } from "react";
import "../../../../../../common-styles/TableStyle.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import "./TireInflationTable.css";
import NoDataFound from "../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../services/utils";
import Globalization from "../../../../../../globalization";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const commonUtils = new CommonUtils();

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => {
    el.InspectDate = new Date(commonUtils.tableDateFormatter(el.InspectDate));
    return [el, index];
  });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => {
    el[0].InspectDate = GetFormattedDate(el[0].InspectDate);
    return el[0];
  });
}

const GetFormattedDate = (inputDate) => {
  let todayTime = inputDate;
  let month = todayTime.getMonth() + 1;
  let day = todayTime.getDate();
  let year = todayTime.getFullYear();
  let newDate = month + "/" + day + "/" + year;
  newDate = commonUtils.tableDateFormatter(newDate);
  return newDate;
};

const TireInflationTable = ({ tableData, showColumns }) => {

  const globalizationMapping = new Globalization();
  const [fleetSurveyTableData, setFleetSurveyTableData] = useState(tableData);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [pageVal, setPageVal] = useState(1);

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.REPORTTABLE.HOURSMILESMET
      : globalizationMapping.REPORTTABLE.HOURSMILESIMP;

  useEffect(() => {
    setFleetSurveyTableData(tableData);
    setPage(0);
    setPageVal(1);
  }, [tableData, showColumns]);

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const rowsPerPage = 10;
  const itemsPerPage = 5;

  const noOfPages = Math.ceil(
    Object.keys(fleetSurveyTableData.rowsData).length / itemsPerPage
  );
  const handleChange = (event, newPage) => {
    setPageVal(newPage);
  };

  let count = -1;
  const classNameSet = (keyIndex, value, c) => {
    count = c;
    switch (c) {
      case -1:
        return "table-row";
      case 0:
        return "fixed-columns first-fleet-col table-head-row";
      case 1:
        return "fixed-columns second-fleet-col table-head-row";
      case 2:
        return "fixed-columns third-fleet-col table-head-row";
      case 3:
        return "fixed-columns fourth-fleet-col table-head-row";
      default:
        return "table-head-row";
    }
  };
  let countBody = -1;
  const classNameSetBody = (c) => {
    countBody = c;
    switch (countBody) {
      case -1:
        return "table-row";
      case 0:
        return "fixed-column-body first-fleet-col table-content-fleet-text";
      case 1:
        return "fixed-column-body second-fleet-col table-content-fleet-text";
      case 2:
        return "fixed-column-body third-fleet-col table-content-fleet-text";
      case 3:
        return "fixed-column-body fourth-fleet-col table-content-fleet-text";
      default:
        return "table-content-fleet-text";
    }
  };

  const firstRecord = Number((pageVal - 1) * itemsPerPage + 1);
  const lastRecord =
    Number(pageVal * itemsPerPage) <
    Object.keys(fleetSurveyTableData.rowsData).length
      ? Number(pageVal * itemsPerPage)
      : Object.keys(fleetSurveyTableData.rowsData).length;




  const tirePressureClass = (TyrePressure, RcmdPressure) => {
    let tirePressure = parseInt(TyrePressure, 10);
  
    const percentValue = (percentage, recommendedPressure) => {
      return (percentage / 100) * recommendedPressure;
    }
  
    if (tirePressure <= percentValue(20,RcmdPressure)) {  // if pressure is less 80% of recommended
      return "table-content-fleet-text  pressure-range-danger";
    } else if (tirePressure >= percentValue(21,RcmdPressure) && tirePressure < percentValue(35,RcmdPressure)) {
      return "table-content-fleet-text  pressure-range-heavy-warning";
    } else if (tirePressure >= percentValue(35,RcmdPressure) && tirePressure < percentValue(50,RcmdPressure)) {
      return "table-content-fleet-text  pressure-range-warning";
    } else if (tirePressure >= percentValue(50,RcmdPressure) && tirePressure < percentValue(70,RcmdPressure)) {
      return "table-content-fleet-text pressure-range-mild-warning";
    } else if (tirePressure >= percentValue(70,RcmdPressure) && tirePressure < percentValue(85,RcmdPressure)) {
      return "table-content-fleet-text pressure-range-light-warning";
    } else {
      return "table-content-fleet-text pressure-range-safe";
    }
  }

  const tirePressureTooltip = (TyrePressure, RcmdPressure) => {
    let tirePressure = parseInt(TyrePressure, 10);
  
    const percentValue = (percentage, recommendedPressure) => {
      return (percentage / 100) * recommendedPressure;
    }
  
    if (tirePressure <= percentValue(20,RcmdPressure)) {  // if pressure is less 80% of recommended
      return "Pressure less than 20%: Danger";
    } else if (tirePressure >= percentValue(21,RcmdPressure) && tirePressure < percentValue(35,RcmdPressure)) {
      return "Pressure less than 35%: Very Low";
    } else if (tirePressure >= percentValue(35,RcmdPressure) && tirePressure < percentValue(50,RcmdPressure)) {
      return "Pressure less than 35%: Low";
    } else if (tirePressure >= percentValue(50,RcmdPressure) && tirePressure < percentValue(70,RcmdPressure)) {
      return "Pressure less than 70%: Warning";
    } else if (tirePressure >= percentValue(70,RcmdPressure) && tirePressure < percentValue(85,RcmdPressure)) {
      return "Pressure less than 85%: Warning";
    } else {
      return "Pressure more than 85%: Safe";
    }
  }

  return (
    <div className="container">
      {fleetSurveyTableData.rowsData !== null &&
        fleetSurveyTableData.rowsData !== undefined &&
        fleetSurveyTableData.rowsData !== "" &&
        Object.keys(fleetSurveyTableData.rowsData)
          .slice((pageVal - 1) * itemsPerPage, pageVal * itemsPerPage)
          .map((key, index) => {
            return (
              <div className={classes.root} key={fleetSurveyTableData.vehicleData[key].Key}>
                {fleetSurveyTableData.rowsData[key].length > 0 && (
                  <React.Fragment>
                    <div className="vehicle-container">
                      <Grid
                        className="removed-tire-record-detail"
                        container
                        spacing={3}
                      >
                        <Grid item xs={12} md className="record-no">
                          <div className="parent-account-container">
                            <div className="account-info-text">
                              {globalizationMapping.REPORTCOMMONWORDS.RecordNo}
                            </div>
                            <div className="account-header-text-by-tire font-wt-500">
                              {(fleetSurveyTableData.vehicleData[key].Key + 1) <= 9 ? "0" + (fleetSurveyTableData.vehicleData[key].Key + 1) : (fleetSurveyTableData.vehicleData[key].Key + 1)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md>
                          <div className="parent-account-container">
                            <div className="account-info-text">
                              {globalizationMapping.REPORTTABLE.ACCOUNTNAME}
                            </div>
                            <div className="account-header-text-by-tire font-wt-500">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .ParentAccountName
                              }
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md>
                          <div className="parent-account-container">
                            <div className="account-info-text">
                              {globalizationMapping.REPORTTABLE.Location}
                            </div>
                            <div className="account-header-text-by-tire font-wt-500">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .LocationName
                              }
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md>
                          <div className="parent-account-container">
                            <div className="account-info-text">
                              {globalizationMapping.REPORTTABLE.INSPID}
                            </div>
                            <div className="account-header-text-by-tire font-wt-500">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .InspectionName
                              }
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="removed-tire-detail-container">
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <div className="heading-container heading-text">
                            {
                              globalizationMapping.REPORTCOMMONWORDS
                                .VehicleDetails
                            }
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">
                              {globalizationMapping.REPORTTABLE.VehicleName}
                            </div>
                            <div className="tire-header-text">
                              {fleetSurveyTableData.vehicleData[key].VehicleId}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">
                              {globalizationMapping.REPORTTABLE.ASSETNUMBER}
                            </div>
                            <div className="tire-header-text">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .AssetNumber
                              }
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">
                              {globalizationMapping.REPORTCOMMONWORDS.MakeModel}
                            </div>
                            <div className="tire-header-text">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .Manufacturer
                              }
                              /{fleetSurveyTableData.vehicleData[key].ModelName}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">
                              {globalizationMapping.REPORTTABLE.TYPE}
                            </div>
                            <div className="tire-header-text">
                              {fleetSurveyTableData.vehicleData[key].TypeName}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="parent-account-container">
                            <div className="tire-info-text">{toggleMile}</div>
                            <div className="tire-header-text">
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .CurrentHours
                              }
                              /
                              {
                                fleetSurveyTableData.vehicleData[key]
                                  .CurrentMiles
                              }
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <Paper className={classes.paper}>
                      <TableContainer className={classes.container}>
                        <Table
                          stickyHeader
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          aria-label="a dense table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow className={classNameSet(-1, -1, -1)}>
                              {fleetSurveyTableData.headCells.map((headCell) =>
                                showColumns[headCell.value] ? (
                                  <TableCell
                                    className={classNameSet(
                                      headCell.keyIndex,
                                      headCell.value,
                                      ++count
                                    )}
                                    key={headCell.keyIndex}
                                    align="left"
                                    sortDirection={
                                      orderBy === headCell.value ? order : false
                                    }
                                  >
                                    <TableSortLabel
                                      active={orderBy === headCell.value}
                                      direction={
                                        orderBy === headCell.value
                                          ? order
                                          : "asc"
                                      }
                                      onClick={handleRequestSort(
                                        headCell.value
                                      )}
                                    >
                                      {headCell.label}
                                      {orderBy === headCell.value ? (
                                        <span
                                          className={classes.visuallyHidden}
                                        >
                                          {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                  </TableCell>
                                ) : null
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {stableSort(
                              fleetSurveyTableData.rowsData[key],
                              getComparator(order, orderBy)
                            )
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((dataItem, index) => {
                                return (
                                  <TableRow
                                    className={classNameSetBody(-1)}
                                    tabIndex={-1}
                                    key={dataItem.Key}
                                  >
                                    {showColumns.WheelPosition && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.WheelPosition}
                                      </TableCell>
                                    )}
                                    {showColumns.SerialNo && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.SerialNo}
                                      </TableCell>
                                    )}
                                    {showColumns.ManufacturerName && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.ManufacturerName}
                                      </TableCell>
                                    )}
                                    {showColumns.SizeName && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.SizeName}
                                      </TableCell>
                                    )}
                                    {showColumns.TypeName && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TypeName}
                                      </TableCell>
                                    )}
                                    {showColumns.Compound && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.Compound}
                                      </TableCell>
                                    )}
                                    {showColumns.RatingName && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.RatingName}
                                      </TableCell>
                                    )}
                                    {showColumns.TreadDepth && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TreadDepth}
                                      </TableCell>
                                    )}
                                    {showColumns.PercentageWorn && 
                                        (<Tooltip title={commonUtils.wornPercentageInfo(dataItem.PercentageWorn)} placement="top-end">
                                          <TableCell className={commonUtils.wornPercentageClass(dataItem.PercentageWorn)}>{dataItem.PercentageWorn}%</TableCell>
                                        </Tooltip>
                                    )}
                                    {showColumns.Eval && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.Eval}
                                      </TableCell>
                                    )}
                                    {showColumns.TotalHours && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TotalHours}
                                      </TableCell>
                                    )}
                                    {showColumns.TotalMiles && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TotalMiles}
                                      </TableCell>
                                    )}
                                    {showColumns.TotalKm && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.TotalKm}
                                      </TableCell>
                                    )}
                                    {showColumns.RecomPressure && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.RecomPressure}
                                      </TableCell>
                                    )}
                                    {showColumns.Pressure && (
                                      <Tooltip title={tirePressureTooltip(dataItem.Pressure,dataItem.RecomPressure)} placement="top-end">
                                        <TableCell className={tirePressureClass(dataItem.Pressure,dataItem.RecomPressure)}>
                                          {dataItem.Pressure}
                                        </TableCell>
                                      </Tooltip>
                                    )}
                                    {showColumns.PercentRecomPressure && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.PercentRecomPressure}%
                                      </TableCell>
                                    )}
                                    {showColumns.InspectDate && (
                                      <TableCell
                                        className={classNameSetBody(
                                          ++countBody
                                        )}
                                      >
                                        {dataItem.InspectDate}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </React.Fragment>
                )}
              </div>
            );
          })}
      {fleetSurveyTableData.rowsData.length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="table"></NoDataFound>
        </div>
      )}
      {fleetSurveyTableData.rowsData !== null &&
        fleetSurveyTableData.rowsData !== undefined &&
        fleetSurveyTableData.rowsData.length > 0 && (
          <React.Fragment>
            <div className="record-data">
              Showing {firstRecord} to {lastRecord} of{" "}
              {Object.keys(fleetSurveyTableData.rowsData).length} records
            </div>
            <Pagination
              count={noOfPages}
              page={pageVal}
              onChange={handleChange}
              defaultPage={1}
              shape="rounded"
              className="pagination-vehicle-report"
            />
          </React.Fragment>
        )}
    </div>
  );
};

export default TireInflationTable;
