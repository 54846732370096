import React from "react";
import Moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../../common-modal/CommonPopup.css";
import { getAccountNames, getLocationNames } from "../../../../services/AccountInfoHandler";
import Globalization from "../../../../globalization";
import CommonUtils from "../../../../services/utils";

const commonUtils = new CommonUtils();
pdfMake.vfs = pdfFonts.pdfMake.vfs;
class TiresReportPDFMake extends React.Component {
  tableColumnWidths = [];
  globalization = new Globalization();

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  async PDFMake() {
    return this.makeTableData();
  }
  /** cell making */
  makeCell = (content, headerOrData, options = {}, rowIndex = -1) => {
    let count = this.getCount();

    const makeCellFontSize = () => {
      if (headerOrData === "data") {
        if (count >= 12) {
          if (count > 15) {
            return 6;
          } else {
            return 7;
          }
        } else {
          return 9;
        }
      } else {
        if (count >= 12) {
          if (count > 15) {
            return 6;
          } else {
            return 7;
          }
        } else {
          return this.fontSize;
        }
      }
    };

    const makeCellFillColor = () => {
      if (headerOrData === "data") {
        if (rowIndex % 2) {
          return "#FAFAFB";
        } else {
          return "#FFFFFF";
        }
      } else {
        if (headerOrData === "total") {
          return "#E6ECF6";
        } else {
          return "#1250B0";
        }
      }
    };

    return Object.assign(
      {
        text: content,
        border: [false, false, false, false],
        borderColor: headerOrData === "data" ? ["#E0E0E0", "#E0E0E0", "#E0E0E0", "#E0E0E0"] : ["#456CAD", "#456CAD", "#456CAD", "#456CAD"],
        fontSize: makeCellFontSize(),
        
        //alignment: "left",
        fillColor: makeCellFillColor(),
        color: headerOrData === "data" || headerOrData === "total" ? "#2E2E2E" : "white"
      },
      options
    );
  };
  //-- Format the table cells for presentation.
  thl = (content, headerOrData, options = {}, rowIndex = -1) => {
    return this.makeCell(content, headerOrData, Object.assign({ bold: true, alignment: "left" }, options, rowIndex));
  };
  tdl = (content, headerOrData, options = {}, rowIndex = -1) => {
    return this.makeCell(content, headerOrData, Object.assign({ bold: false, alignment: "left" }, options, rowIndex));
  };
  fontSize = 10;

  getCount = () => {
    let count = 0;
    Object.keys(this.props.tableData.selectedColShow).forEach((val) => {
      if (this.props.tableData.selectedColShow[val] === true && val !== "Actions") count++;
      return count;
    });
    return count;
  };
  /** Doc definition */
  async createDocumentDefinition(_reportDate, ...contentParts) {
    let count = this.getCount();
    const baseDocDefinition = {
      pageSize: "A4",
      pageMargins: [20, 90, 20, 55],
      pageOrientation: count >= 8 ? "landscape" : "portrait",
      content: []
    };
    const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
    docDefinition.header = await this.addPdfMakeHeader();
    docDefinition.footer = this.addPdfMakeFooter();
    if (this.props.value === "Graph" || this.props.value === "All") {
      docDefinition.content.push(this.exportGraphToPDF(baseDocDefinition.pageOrientation));
      if (this.props.tableData.hasOwnProperty("filterForGraphApplied") && this.props.tableData.filterForGraphApplied) {
        docDefinition.content.push(...contentParts);
      }
    }
    if (this.props.value === "Table" || this.props.value === "All") {
      docDefinition.content.push(...contentParts);
    }

    const defaultFont = {defaultStyle: {
      font: 'Barlow'
    }}
    docDefinition.defaultStyle = defaultFont;

    return docDefinition;
  }

  /** header body and footer */
  async addPdfMakeHeader() {

    return (_currentPage, _pageCount) => {
      return {
        margin: [20, 20, 20, 0],
        stack: [
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    border: [false, false, false, false],
                    
                    color: "white",
                    fillColor: "#1250B0",
                    alignment: "right",
                    columns: [
                      {
                        svg: commonUtils.EmTrackSVGLogo,
                        fit: [50, 80],
                        width: "5%",
                        margin: [0, 2, 0, 2],
                        alignment: "left"
                      },
                      {
                        text: " " + commonUtils.pdfReportName(this.props.tableData.reportName),
                        bold: true,
                        alignment: "center",
                        fontSize: 14,
                        margin: [0, 10, 0, 2]
                      },
                      {
                        svg: commonUtils.GoodyearSVGLogo,
                        alignment: "right",
                        width: "10%",
                        fit: [90, 150]
                      },
                    ]
                  }
                ]
              ]
            }
          },
          {
            table: {
              widths: ["*"],
              margin: [20, 0, 20, 0],
              body: [
                [
                  {
                    
                    fontSize: this.fontSize,
                    border: [false, false, false, false],
                    color: "#074BA7",
                    fillColor: "#E5F0FF",
                    alignment: "left",
                    columns: [
                      {
                        text: this.globalization.REPORTTABLE.DATE + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: Moment(new Date()).format("DD/MM/YYYY"),
                        bold: true
                      },
                      {
                        text: this.globalization.MANAGEPARENTACCOUNT.ACCOUNT + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: getAccountNames().toString(),
                        bold: true
                      },
                      {
                        text: this.globalization.HEADER.LOCATION + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: getLocationNames().toString(),
                        bold: true,
                        width: "auto"
                      }
                    ]
                  }
                ]
              ]
            }
          }
        ]
      };
    };
  }

  tableBody = () => {
    const body = [];
    const TableRow = [];
    const TotalRows = [];
    let count = 0;
    Object.keys(this.props.tableData.selectedColShow).forEach((val) => {
      if (this.props.tableData.selectedColShow[val] === true && val !== "Actions") {
        count++;
      }
      return count;
    });
    let percent = 100 / count + "%";
    this.props.tableData.headCells.forEach((headCell, _index) => {
      if (this.props.tableData.selectedColShow[headCell.value] === true && headCell.key !== "Actions") {

        let headCellName = commonUtils.pdfHeaderNameShorter( headCell.key);
        let headerText = headCellName;

        TableRow.push(
          this.thl(headerText, "header", {
            border: [false, false, false, true],
            fillColor: "white",
            color: "#2E2E2E",
            bold: true
          }, -1)
        );

        this.tableColumnWidths.push(percent);
      }
    });

    body.push(TableRow);
    let valIndex = 0;
    this.props.tableData.rowsData.forEach((row, index) => {
      const tableRow = [];
      valIndex = index;
      let last = this.props.tableData.rowsData.length - 1 > index ? 0 : 1;
      if (last === 1 && !this.props.tableData.avgValues) {
        last = 0;
      }
      // eslint-disable-next-line array-callback-return
      this.props.tableData.headCells.forEach((headCell, indexHeadCell) => {
        let key = headCell.value;

        if (key !== "Key" && key !== "Actions" && this.props.tableData.selectedColShow[key] === true) {

          const percentageWornColor = () => {
            if (row[key] < 25) {
              return '#ccf2db';
            } else if (row[key] < 50) {
              return '#ffffaf';
            } else if (row[key] < 75) {
              return '#ffe0a9';
            } else {
              return '#ffc7c7';
            }
          };

          const percentageAdder = (item) => {
            if (key === "PercentageWorn" || key === 'PercentRecomPressure'
            || key === "PercentHourTarget" || key === "PercentWornTarget"
            || key === "AverageWorn") {
              return item + '%';
            } else {
              return item;
            }
          }

          tableRow.push(
            this.tdl(percentageAdder(row[key]), "data", {
              fontWeight: 400,
              color: "#333333",
              fillColor: (key === "AverageWorn" || key === "PercentageWorn") ? percentageWornColor() : "white",
              border: last === 1 ? [false, false, false, false] : [false, false, false, true]
            }, index)
          );
        }
      });
      body.push(tableRow);
    });
    if (this.props.tableData.avgValues) {
      // eslint-disable-next-line array-callback-return
      this.props.tableData.headCells.forEach((headCell, _index) => {
        let key = headCell.value;
        // Object.keys(this.props.tableData.avgValues).map((key, id) => {
        if (this.props.tableData.avgValues[key] === "Total / Average" && this.props.tableData.selectedColShow[key] === true) {
          let str = this.props.tableData.avgValues[key];
          str = str.replace("/", " /\n");
          TotalRows.push(
            this.tdl(str, "total", {
              bold: false,
              fontSize:6,
              border: [false, true, false, false]
            }, valIndex + 1)
          );
        } else {
          if (this.props.tableData.selectedColShow[key] === true) {
            let strData = "" + this.props.tableData.avgValues[key];
            strData = strData.replace("/", " /\n");
            TotalRows.push(
              this.tdl(strData, "total", {
                bold: false,
                fontSize:6,
                border: [false, true, false, false]
              }, valIndex + 1)
            );
          }
        }
      });
      body.push(TotalRows);
    }

    return body;
  };
  addPdfMakeFooter = () => {
    let footerText = "©" + new Date().getFullYear() + " The Goodyear Tire & Rubber Company ";
    return (currentPage, pageCount) => {
      // you can apply any logic and return any valid pdfmake element
      return {
        margin: [20, 0, 20, 0],
        border: [false, false, false, false],
        table: {
          widths: ["*"],
          body: [
            [
              {
                border: [false, false, false, false],
                fontSize: 9,
                color: "white",
                fillColor: "#1250B0",
                alignment: "left",
                columns: [
                  {
                    text: footerText,
                    alignment: "left",
                    width: "40%",
                    margin: [0, 13, 0, 0],
                    bold: true
                  },
                  {
                    text: "PAGE " + currentPage.toString() + "/" + pageCount.toString(),
                    alignment: "right",
                    margin: [0, 13, 0, 0],
                    fontWeight: 400
                  }
                ]
              }
            ]
          ]
        }
      };
    };
  };
  calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
    let ratio = srcWidth / srcHeight;
    let width = maxWidth;
    let height = maxHeight;
    if (srcWidth <= maxWidth && srcHeight <= maxHeight) {
      // source less that maximum size print as it is
      width = srcWidth;
      height = srcHeight;
    } else if (srcWidth >= srcHeight) {
      //width is larger
      height = maxWidth / ratio;
      if (height > maxHeight) {
        //calculated height greater than maxheight reallocate by adjusting height
        height = maxHeight;
        width = maxWidth * ratio;
        if (width > maxWidth) {
          width = maxWidth;
        }
      }
    } else {
      width = maxHeight * ratio;
      if (width > maxWidth) {
        //calculated width greater than maxWidth reallocate by adjusting width
        height = maxWidth / ratio;
        width = maxWidth;
      }
    }
    return { width: width, height: height };
  };
  /** Graph creation */
  exportGraphToPDF = (orientation) => {
    let labelHeader = "";
    let canvasElm = document.getElementsByClassName("chartjs-render-monitor");
    let docDefinition = {
      content: []
    };
    if (canvasElm && canvasElm.length > 0) {
      let canvasLength = canvasElm.length;
      // writing canvas images to pdf
      for (let index = 0; index < canvasLength; index++) {
        let img = new Image();

        img.src = canvasElm[index].toDataURL();
        let imgData = canvasElm[index].toDataURL();
        let resolution = this.calculateAspectRatioFit(canvasElm[0].clientWidth, canvasElm[0].clientHeight, 550, 800);
        let width, height;
        if ((resolution.width > 555 && orientation === "portrait") || (resolution.width < 812 && orientation === "landscape")) {
          width = orientation === "portrait" ? 555 : 812;
        } else {
          width = resolution.width;
        }

        if (this.props.tableData.reportName === "Tire Inflation") {
          if (this.props.tableData.graphType === "Pie") {
            // pie chart tire inflation
            docDefinition.content.push({
              image: imgData,
              width: 670,
              height: height,
              alignment: "right",
              margin: [0, 150, 0, 0]
            });
          } else {
            docDefinition.content.push({
              image: imgData,
              width: width,
              height: height,
              alignment: "centre",
              margin: [0, 150, 0, 0]
            });
          }
        } else {
          //  bar chart
          docDefinition.content.push({
            image: imgData,
            width: width,
            height: height,
            alignment: "center",
            margin: this.props.value === "Graph" ? [0, 150, 0, 0] : [0, 0, 0, 0]
          });
        }

        docDefinition.content.push({
          text: labelHeader,
          style: {
            fontSize: this.fontSize
          }
        });
      }
    }
    return docDefinition.content;
  };


  // -- The main report table, with the table body.
  tableDataReport = {};

  async makeTableData() {
    // -- Create a base document template for the reports.
    const bodyArr = this.tableBody();

    this.tableDataReport = {
      stack: [
        {
          width: parseInt(this.tableColumnWidths.length, 10) > 6 ? this.tableColumnWidths : "*",
          table: {
            headerRows: 1,
            widths: parseInt(this.tableColumnWidths.length, 10) > 6 ? this.tableColumnWidths : "*",
            body: bodyArr
          }
        },
        {
          table: {
            headerRows: 1,
            body: [
              [" ", " ", " "],
              [" ", " ", " "],
              [" ", " ", " "],
              [" ", " ", " "]
            ]
          },
          layout: "noBorders"
        },
        {
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: "Total Records : " + this.props.tableData.rowsData.length,
                  fontSize: 8
                }
              ]
            ]
          }
        }
      ]
    };
    let applyFilterTable = false;
    if (this.props.tableData.hasOwnProperty("filterFieldsApplied")) {
      let checkHeadersList = Object.keys(this.props.tableData.filterFieldsApplied);
      for (const element of checkHeadersList) {
        if (this.props.tableData.filterFieldsApplied[element].length !== 0) {
          applyFilterTable = true;
          break;
        }
      }
    }

    if (applyFilterTable) {
      this.tableDataReport.stack.push({
        table: {
          headerRows: 1,
          body: [[" ", " ", " "]]
        },
        layout: "noBorders"
      });
      let filterHeaderList = Object.keys(this.props.tableData.filterFieldsApplied);
      this.tableDataReport.stack.push({
        table: {
          headerRows: 1,
          body: [
            [
              {
                text: "Report Filters and Options",
                fontSize: "12",
                border: [false, false, false, false],
                bold: true
              },
              { text: "", border: [false, false, false, false] }
            ],
            [
              {
                text: " ",
                fontSize: "8",
                border: [false, false, false, true],
                bold: true
              },
              { text: "", border: [false, false, false, true] }
            ]
          ]
        },
        layout: {
          hLineColor(i, _node) {
            return i === 2 ? "#1B57B2" : "#E0E0E0";
          }
        }
      });
      filterHeaderList.forEach((filterHeader) => {
        if (this.props.tableData.filterFieldsApplied[filterHeader].length !== 0) {
          let filterArray = [
            {
              text: filterHeader + ":",
              fontSize: "9",
              bold: true,
              border: [false, false, false, true]
            }
          ];
          let filterHeaderValues = "";
          this.props.tableData.filterFieldsApplied[filterHeader].forEach((filterHeaderValue, index) => {
            if (index === 0) filterHeaderValues = filterHeaderValue;
            else filterHeaderValues += ", " + filterHeaderValue;
          });
          filterArray.push({
            text: filterHeaderValues,
            fontSize: "9",
            border: [false, false, false, true]
          });
          this.tableDataReport.stack[4].table.body.push(filterArray);
        }
      });
    }
    const docDefinition = await this.createDocumentDefinition(new Date(), this.tableDataReport);
    return pdfMake.createPdf(docDefinition);
  }
  render() {
    return <div></div>;
  }
}

export default TiresReportPDFMake;
