import React, { useState,useEffect } from 'react'
import './ByTiresNavigation.css'
import Breadcrumbs from '../../../../../common-components/breadcrumbs/breadcrumbs'
import { Card,CardContent } from '@material-ui/core'
import Globalization from '../../../../../globalization/index'
import { useHistory } from 'react-router-dom'
import { ReportsRoutePaths } from '../../../../../routing/ReportsRoutePaths'

const ByTiresNavigation = () => {

    const globalizationMapping = new Globalization();
    const otherTireGlobalTexts = globalizationMapping.VEHICLEREPORTS.BYTIRES
    const otherTireNav = [ otherTireGlobalTexts.TireForecast,otherTireGlobalTexts.TireInflation,otherTireGlobalTexts.TireRotation,otherTireGlobalTexts.TireFlipping]
    const otherTirePathNav = ['tireForecast','tireInflationReport','tireRotation','tireFlipping']
    
    const getRouteName = (currentPathName) => {
        let pathSelected = ReportsRoutePaths.find(route => route.path === currentPathName)
        return pathSelected
    }

    const getRoutePath = (path) => {
        let pathSelected = ReportsRoutePaths.find(route => route.path === path)
        return pathSelected
    }

    let breadcrumbs = []
    const history = useHistory()

    
    let currentPathName = history.location.pathname.split('/')[1]
    let currentRouteSelected = getRouteName(currentPathName)
    
    const [selectedRoute,setSelectedRoute] = useState(currentRouteSelected)
    // eslint-disable-next-line no-unused-vars
    
    breadcrumbs = [
        {
            path: "home",
            name: globalizationMapping.HEADER.HOME
        },
        {
            path: "reportsDashboard",
            name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.REPORTSDASHBOARD
        },
        {
            path: "",
            name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.VEHICLEREPORTS+ " - " + otherTireGlobalTexts.ByTires + ": "  + selectedRoute.name,
        }
    ]
      
    const otherTireNavigation = (path) => {
        let routePath = getRoutePath(path)
        setSelectedRoute(routePath)
    }
    
    useEffect(() => {
            history.push(selectedRoute.path)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedRoute])

    return (
        <div>
         <div className="other-tire-main-container">
            <div className="other-tire-upper-deck">
                <div className="background-img-container">
                    <div className="background-map-image">
                        <div className="dashboard-header">
                            <div>
                                <Breadcrumbs breadcrumbs={breadcrumbs} />
                                <div className="other-tire-title">
                                    {otherTireGlobalTexts.ByTires}
                                </div>
                            </div>
                        </div>
                        <div className="other-tire-nav-container">
                            {otherTireNav.map((item, index) => {
                                return (
                                   <div key={item} 
                                        className="other-tire-nav-card-container"
                                        onClick={() => otherTireNavigation(otherTirePathNav[index])}>
                                    <Card className={selectedRoute.name===item ? 
                                            "active-other-tire-nav-card" :"other-tire-nav-card"}>
                                        <CardContent>
                                            <span  className={selectedRoute.name===item ? 
                                                    "active-other-tire-nav-card-text" :"other-tire-nav-card-text"}>
                                                {item}
                                            </span>
                                        </CardContent>
                                    </Card>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    )
}

export default ByTiresNavigation