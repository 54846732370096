import React, { useEffect, useState } from "react";
import "../../../../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    // height: 625,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const MonthlyTireLifeTable = ({ tableData }) => {
  const [monthlyTireLifeTableData, setMonthlyTireLifeTableData] = useState(tableData);
  const classes = useStyles();
  const order = "asc";
  const orderBy = "ManufacturerName";

  useEffect(() => {
    setMonthlyTireLifeTableData(tableData);
  }, [tableData]);

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table 
              stickyHeader 
              className={classes.table} 
              aria-labelledby="tableTitle" 
              aria-label="a dense table"
              size="small"
              >
              <TableHead>
                <TableRow>
                  {monthlyTireLifeTableData.headCells.map((headCell) => (
                    <TableCell
                      className="table-head-row"
                      key={headCell.keyIndex}
                      align="left"
                      sortDirection={orderBy === headCell.value ? order : false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {monthlyTireLifeTableData.rowsData.length > 0 && (
                <TableBody>
                  {monthlyTireLifeTableData.rowsData.map((row, index) => {
                    return (
                      <TableRow
                        className={index % 2 === 0 ? "table-row" : "table-row-alternate"}
                        tabIndex={-1}
                        key={index}
                      >
                        {monthlyTireLifeTableData.headCells.map((headCell, index) => (
                          <TableCell className="table-content-text" key={index}>{row[headCell.value]}</TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
              {monthlyTireLifeTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={monthlyTireLifeTableData.headCells.length} align="center">
                      <NoDataFound noDataType="table"></NoDataFound>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default MonthlyTireLifeTable;
