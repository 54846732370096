import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';

export default class RimsService {
    cs;
    constructor(){
         this.cs = new CommonService(); 
    }
    getTiresReport = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getReport,'post',req)
    }
    getTireSerial = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getTireSerialNo,'post',req)
    }

    getTiresGraph = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getGraph,'post',req)
    }

    getLocationById = (locId) => {
        return this.cs.httpConnection(SERVER.SERVICES.getLocationById + locId,'get',{})
    }
}