import React, { useContext, useEffect, useState } from "react";
import Globalization from "../../../../../globalization/index";
import { Grid, makeStyles, Button, InputLabel, List, ListItem, Tooltip, TextField, Modal } from "@material-ui/core";
import Moment from "moment";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import { StateContext } from "../../../../../services/StateProvider";
import QuickInspectListApis from "../../../services/QuickInspectListApis";
import legendIcon from "../../../../../assets/legend.svg";
import "./Table.css";
import "./VehicleInspection.css";
import CommonUtils from "../../../../../services/utils";
import { useHistory } from "react-router-dom";
import TireConfigUtils from "../../../services/TireConfigUtils";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import AlertPopup from "../../../../../common-components/alert-pop-up/alert-popup";
import UpdateHoursMiles from "../pop-up-error-tread/UpdateHoursMiles";
import Warning from "@material-ui/icons/Warning";
import Info from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiBadge-colorPrimary": {
      backgroundColor: "transparent"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "inherit"
    },
    "& .MuiSwitch-sizeSmall .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(2.2em)"
    },
    "& input.MuiOutlinedInput-input": {
      padding: "0!important"
    },
    "& textarea.MuiInputBase-input": {
      color: "black"
    }
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    "max-height": "100%"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  stickyHeader: {
    zIndex: "2!important"
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important"
  },
  input: {
    display: "none"
  },
  save: {
    "&:hover": {
      opacity: 1
    },
    background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    opacity: 0.8,
    fontWeight: 500
  },
  update: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none"
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5
  },
  bottomUpdate: {
    "&:hover": {
      backgroundColor: "transparent"
    },
    opacity: 1,
    background: "#b9b9b900",
    color: "#2355AF",
    fontWeight: 500,
    border: " 1px solid #ccc",
    borderRadius: "18px",
    height: "3em",
    width: "8em"
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none"
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5
  },
  update_hour: {
    background: "#fff599 0% 0% no-repeat padding-box",
    borderRadius: 10,
    padding: "3px"
  },
  update_hour_label: {
    marginTop: "3px",
    color: "#393939",
    fontFamily: "Barlow,light",
    fontStyle: "italic",
    "&:hover": {
      cursor: "pointer",
      fontWeight: "bolder"
    }
  },
  editVehicle: {
    "&:hover": {
      opacity: 1
    },
    background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none"
  },
  disabledButton: {
    background: "transparent linear-gradient(90deg, #bebfc046 0%, #bebfc046 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none"
  }
}));

const VehicleInspection = (props) => {
  const globalizationMapping = new Globalization();
  const [locationDetails, setLocationDetails] = useState({});
  const [tireDetails, setTireDetails] = useState({});
  const [installedTireDetailsPerPos, setInstalledTireDetailsPerPos] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [popupDescription, setPopupDescription] = useState("");
  const [popupMsg, setPopupMsg] = useState("");
  const [popupPurpose, setPopupPurpose] = useState("");
  const [globalAccountState] = useContext(StateContext);
  const [vehicleSelected, setVehicleSelected] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [positionArray, setPositionArray] = useState([]);
  const [updateTireDetail, setUpdatedTireDetails] = useState();
  const [changedTire, setChangedTire] = useState();
  const [flag, setFlag] = useState(0);
  const [noOfReadings, setNoOfReadings] = useState(null);
  const [tdMethod, setTDMethod] = useState(1);
  const [inspectionOrder, setInspectionOrder] = useState(null);
  const quickInspectListService = new QuickInspectListApis();
  const classes = useStyles();
  const commonUtils = new CommonUtils();
  const headerApi = new HeaderApi();
  const history = useHistory();
  const globalization = new Globalization();
  const globalText = globalization.INSPECT;
  const notificationTexts = globalization.FORMS.NOTIFICATIONTEXTS;
  const modalTexts = globalization.FORMS.TIRESVEHICLES.TIREFORM;
  const [pressureUnit, setPressureUnit] = useState(globalAccountState.accountInfo.userSetting.userPressureUnit);
  const imageInspectionSummary = props.imageInspectionSummary;
  const showIsUpdate = props.showIsUpdate;
  const showUpdateMsg = props.showUpdateMsg;
  const [navigationAllowed, setNavigationAllowed] = useState(true);
  const [tryingToNavigate, setTryNavigating] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [navigationLink, setNavigationLink] = useState({});
  const userPreference = JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue"));

  useEffect(() => {
    setVehicleDetails(props.vehicleDetails);
    let loading = 0;
    const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    const accountIds = accountInfoObject.accountIds;
    quickInspectListService.manageVehicleGetDetailsByAccount(accountIds[0]).then((accountData) => {
      if (accountData.model) {
        setLocationDetails(accountData.model);
        // eslint-disable-next-line array-callback-return
        accountData.model.locationList.map((location) => {
          // eslint-disable-next-line array-callback-return
          location.vehicleList.map((vehicle) => {
            if (vehicle.vehicleId === Number(props.vehicleId)) {
              setSelectedLocation(location);
              vehicle.lastRead = commonUtils.formatISODate(vehicle.lastRead);
              setVehicleSelected((previousState) => {
                return { ...previousState, ...vehicle };
              });
              ++loading;
              setFlag(loading);
            }
          });
        });
        setShowSpinner(false);
      }
    });

    quickInspectListService.getInspectionRecordForVehicle(props.vehicleId).then((imgObj) => {
      // this API is based on position of the tires (movable cause) in quick inspect
      if (imgObj !== undefined) {
        setVehicleSelected((previousState) => {
          return {
            ...previousState,
            tireSize: imgObj.model.tireSize,
            tireSizeId: imgObj.model.tireSizeId,
            multipleTireSizeNames: imgObj.model.multipleTireSizeNames,
            hasMultipleTireSizes: imgObj.model.hasMultipleTireSizes
          };
        });
        const axles = [];
        const axlesPos = [];
        for (const element of imgObj.model.installedTires) {
          const str = element.wheelPosition;
          axlesPos.push(str);
        }

        setPositionArray(axlesPos);
        if (imgObj.model && imgObj.model.axleConfig && imgObj.model.axleConfig.length > 0) {
          for (const element of imgObj.model.axleConfig) {
            const x = [];
            for (let j = 0; j < Number(element) / 2; j++) {
              x.push("a");
            }
            axles.push(x);
          }

          if (imgObj.model && imgObj.model.comments === null) {
            imgObj.model.comments = "";
          }
          if (!sessionStorage.getItem("updateHoursSessionCheck")) {
            sessionStorage.setItem("updateHoursSessionCheck", "NewSession");
          }

          quickInspectListService.getPSAlertByVehicleId(props.vehicleId).then((res) => {
            if (res.model) {
              for (const element of imgObj.model.installedTires) {
                for (const resElement of res.model) {
                  if (element.wheelPosition === resElement.wheelPosition) {
                    element.pSAlert = resElement;
                  } else {
                    element.pSAlert = null;
                  }
                }
              }
              // eslint-disable-next-line array-callback-return
            }
          });
          // API used for remove Tire feature
          quickInspectListService.getInstalledItemsOnVehiclePerPos(props.vehicleId).then((imgObj) => {          
            setInstalledTireDetailsPerPos({ ...imgObj.model});
          });
          // old API
          console.log(imgObj.model,'xxx')
          setTireDetails({ ...imgObj.model, axles });
          if (imgObj.model.locationId || (imgObj.model.installedTires.length > 0 && imgObj.model.installedTires[0].locationId)) {
            // eslint-disable-next-line no-loop-func
            headerApi.getLocationById(imgObj.model.locationId).then((res) => {
              setNoOfReadings(res.model.noOfTreadDepthReadings);
              setTDMethod(res.model.treadDepthMethodId);
              if (res.model.inspectionOrderId < 3 || res.model.inspectionOrderId > 7) {
                setInspectionOrder(3);
              } else {
                setInspectionOrder(res.model.inspectionOrderId);
              }
            });
          } else {
            setNoOfReadings(0);
          }
          ++loading;
          setFlag(loading);
        }
      }
    });
    setPressureUnit(globalAccountState.accountInfo.userSetting.userPressureUnit);
    if (flag === 2) {
      setShowSpinner(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, props]);

  const checkingReqDetails = () =>
    tireDetails && tireDetails.axleConfig && noOfReadings && positionArray && vehicleSelected && inspectionOrder && vehicleSelected.vehicleNumber && pressureUnit;

  function handleUpdate() {
    history.push({
      pathname: "/update-hours-miles",
      state: {
        vehicleId: tireDetails.vehicleId
      }
    });
  }

  const updatedTireDetails = (currentTireDetails, currentChangedTire) => {
    setUpdatedTireDetails(currentTireDetails);
    setChangedTire(currentChangedTire);
  };
  const setPopupItems = (purpose) => {
    if (purpose.match("beforeSubmit")) {
      setPopupPurpose(purpose);
      if (showUpdateMsg) {
        setPopupDescription(globalization.QUICKINSPECT.ConfirmChanges);
        setPopupMsg(globalization.QUICKINSPECT.ConfirmationMsg);
      } else {
        onClickSaveStatus();
        return;
      }
    }
    if (purpose.match("beforeRemove")) {
      setPopupPurpose(purpose);
      if (showUpdateMsg) {
        setPopupDescription(globalization.QUICKINSPECT.ConfirmChanges);
        setPopupMsg(globalization.QUICKINSPECT.ConfirmationMsg);
      } else {
        sendDataToRemoveTires();
        return;
      }
    }
    if (purpose.match("alert")) {
      setPopupPurpose(purpose);
      setPopupDescription(globalization.QUICKINSPECT.ConfirmChanges);
      setPopupMsg(globalization.QUICKINSPECT.WarningMsg);
    }
    setTimeout(() => {
      setTryNavigating(true);
      setNavigationAllowed(false);
    }, 500);
  };
  const onClickSaveStatus = () => {
    setShowSpinner(true);
    console.log(updateTireDetail,'updateTireDetail on save')
    const configTireDetail = updateTireDetail;
    const newConfigTireDetail = changedTire;

    let index;
    if (configTireDetail && configTireDetail.installedTires) {
      index = configTireDetail.installedTires.findIndex((val) => val.wheelPosition.indexOf("Out") !== -1);
    }
    if (index !== undefined && index === -1) {
      const req = {
        inspectionList: newConfigTireDetail,
        tireList: configTireDetail.installedTires
      };
      headerApi.saveVehicleFootPrintDetails(req).then((_data) => {
        const location = [];
        globalAccountState.accountInfo.location.map((val) => location.push(val.value));
        const homeObj = {
          accountIds: [globalAccountState.accountInfo.account.accountId],
          locationIds: location,
          vehicleId: props.vehicleId,
          actionDate: Moment(new Date()).format("YYYY-MM-DD"),
          actionDescription: "INSPECT.QUICKINSPECTED " + vehicleSelected.vehicleNumber
        };
        headerApi.createHomeActivity(homeObj).then((_res) => { });
        props.successNotificationMessage(
          {
            state: {
              updatedSection: "successQuickInspect",
              updateStatus: true,
              successMessage: notificationTexts.QUICKINSPECTSUCCESSMESSAGE,
              successMessageDesc: `${notificationTexts.QUICKINSPECTSUCCESSMESSAGEDESC} ${vehicleSelected.vehicleNumber} is successful`
            }
          },
          "Submit"
        );
        setShowSpinner(false);
      });
    } else if (index !== undefined && index !== -1) {
      props.successNotificationMessage(
        {
          state: {
            updatedSection: "successQuickInspect",
            updateStatus: true,
            red: true,
            successMessage: notificationTexts.QUICKINSPECTOUTTUREERRORMESSAGE,
            successMessageDesc: notificationTexts.QUICKINSPECTOUTTUREERRORMESSAGEDESC
          }
        },
        "Submit"
      );
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
    }
  };

  const onPopupSave = () => {
    setShowSpinner(true);
    if (popupPurpose.match("beforeSubmit")) {
      onClickSaveStatus();
    }
    if (popupPurpose.match("beforeRemove")) {
      onClickSaveStatus();
      sendDataToRemoveTires();
    }
    if (popupPurpose.match("alert")) {
      const configTireDetail = updateTireDetail;
      const newConfigTireDetail = changedTire;

      let index;
      if (configTireDetail && configTireDetail.installedTires) {
        index = configTireDetail.installedTires.findIndex((val) => val.wheelPosition.indexOf("Out") !== -1);
      }
      if (index !== undefined && index === -1) {
        const req = {
          inspectionList: newConfigTireDetail,
          tireList: configTireDetail.installedTires
        };
        headerApi.saveVehicleFootPrintDetails(req).then((_data) => {
          const location = [];
          globalAccountState.accountInfo.location.map((val) => location.push(val.value));
          const homeObj = {
            accountIds: [globalAccountState.accountInfo.account.accountId],
            locationIds: location,
            vehicleId: props.vehicleId,
            actionDate: Moment(new Date()).format("YYYY-MM-DD"),
            actionDescription: "INSPECT.QUICKINSPECTED " + vehicleSelected.vehicleNumber
          };
          headerApi.createHomeActivity(homeObj).then((_res) => { });
          setShowSpinner(false);
          const navigationObject = JSON.parse(JSON.stringify(navigationLink));
          navigationObject.state = {
            ...navigationObject.state,
            updatedSection: "successQuickInspect",
            updateStatus: true,
            successMessage: notificationTexts.QUICKINSPECTSUCCESSMESSAGE,
            successMessageDesc: `${notificationTexts.QUICKINSPECTSUCCESSMESSAGEDESC} ${vehicleSelected.vehicleNumber} is successful`
          };
          history.push(navigationObject);
        });
      } else if (index !== undefined && index !== -1) {
        setShowSpinner(false);
        const navigationObject = JSON.parse(JSON.stringify(navigationLink));
        navigationObject.state = {
          ...navigationObject.state,
          updatedSection: "successQuickInspect",
          updateStatus: true,
          successMessage: notificationTexts.QUICKINSPECTSUCCESSMESSAGE,
          successMessageDesc: `${notificationTexts.QUICKINSPECTSUCCESSMESSAGEDESC} ${vehicleSelected.vehicleNumber} is successful`
        };
        history.push(navigationObject);
      } else {
        setShowSpinner(false);
        history.push(navigationLink);
      }
    }
  };

  const newOnPopupSave = () => {
    setShowSpinner(true);
  };

  const sendDataToRemoveTires = () => {
    history.push({
      pathname: "/remove-all-tire",
      state: {
        fromQuickInspect: true,
        vehicleDetails: vehicleDetails,
        tireDetails: installedTireDetailsPerPos, // due to render complication, we are using this for update
        locationDetails: locationDetails,
        selectedLocation: selectedLocation,
        vehicleSelected: vehicleSelected,
        inspectionOrder: inspectionOrder,
        noOfReadings: noOfReadings,
        noOfTreadDepthMethodId: tdMethod,
        fromPath: "/quick-inspect/" + vehicleSelected.vehicleId
      }
    })
  }

  return (
    <div>
      <div className="container">
        {!showSpinner && (
          <div className={classes.root}>

            {showIsUpdate && (
              <Grid container spacing={1} className={classes.update_hour}>
                <Grid item xs={11} className="update-hour-inner-container">
                  <Tooltip title={globalText.UPDATE} arrow>
                    <label className={classes.update_hour_label} onClick={() => handleUpdate()}>
                      <Warning style={{ verticalAlign: "middle", marginBottom: 5, marginRight: 8, color: 'orange' }}></Warning>
                      {!showUpdateMsg ? notificationTexts.UPDATEHPOURSHEAD : globalText.UPDATE_HOUR_LABEL}
                    </label>
                  </Tooltip>
                </Grid>
                <Grid item xs={1} style={{ paddingLeft: '2%' }}>
                  <Button className={classes.editVehicle} onClick={() => handleUpdate()}> {globalizationMapping.INSPECT.UPDATE}</Button>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={1}>
              <Grid
                container
                style={{
                  background: "0px 0px no-repeat padding-box padding-box rgb(250, 249, 242)",
                  padding: "10px"
                }}
              >
                <Grid item xs={4}>
                  <p className="field-label">{globalText.SELECTPARENTACCOUNT}</p>
                  <InputLabel>{`${locationDetails.accountName}-${selectedLocation.locationName}`}</InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label">{globalText.VEHICLEID}</p>
                  <InputLabel>{vehicleSelected.vehicleNumber}</InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label">{globalText.LASTINSPECTIONDATE}</p>
                  <InputLabel>{vehicleSelected.lastRead}</InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label">{globalText.CURRENTDIS}</p>
                  <InputLabel>
                    {vehicleSelected.currentMiles + " "}
                    {userPreference.toLowerCase() === "metric" ? globalizationMapping.MANAGEVEHICLE.KM : globalizationMapping.MANAGEVEHICLE.MILES}
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label">{globalText.CURRENTHOURS}</p>
                  <InputLabel>{vehicleSelected.currentHours}</InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label">{modalTexts.SIZE}</p>
                  <InputLabel>{vehicleSelected.hasMultipleTireSizes ? vehicleSelected.multipleTireSizeNames : vehicleSelected.tireSize}</InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label">{modalTexts.MANUFACTURER}</p>
                  <InputLabel>{vehicleSelected.manufacturerName}</InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label">{modalTexts.MODEL}</p>
                  <InputLabel>{vehicleSelected.modelName}</InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <p className="field-label">{modalTexts.TYPE}</p>
                  <InputLabel>{vehicleSelected.typeName}</InputLabel>
                </Grid>
                <Grid item xs={11}>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "92.5%",
                      width: "80%",
                      paddingTop: "10px"
                    }}
                  >
                    <Button
                      className={tireDetails?.installedTires?.length === 0 ? classes.disabledButton: classes.editVehicle}
                      disabled={tireDetails?.installedTires?.length === 0}
                      onClick={() => setPopupItems("beforeRemove")}
                    >
                      {globalizationMapping.INSPECTNREMOVETIRE.REMOVE}
                    </Button>

                    <Button
                      className={classes.editVehicle}
                      style={{
                        marginLeft: "1%"
                      }}
                      onClick={() =>
                        history.push({
                          pathname: "/create-vehicle/" + vehicleSelected.vehicleId,
                          state: {
                            fromQuickInspect: true
                          }
                        })
                      }
                    >
                      {globalization.HEADER.EDITVEHICLE}
                    </Button>
                  </div>
                </Grid>
              </Grid>
              {checkingReqDetails() && (
                <Grid
                  className="axle-position"
                  container
                  spacing={1}
                  style={{
                    alignItems: "center",
                    display: "inline-flex",
                    width: "100%",
                    margin: "14px 2px"
                  }}
                >
                  <Grid item sx={2} style={{fontStyle:'italic', fontSize:13}}> 
                    <Info style={{ verticalAlign: "middle", marginBottom: 5, marginRight: 8, color: 'orange' }}></Info>
                  {`Existing Rim cannot be dragged-out.`}
                  </Grid>
                  <Grid item container sx={10} justifyContent="center"> 
                    <TireConfigUtils
                      vehicleNumber={vehicleSelected.vehicleNumber}
                      setNavigationFlag={() => setNavigationAllowed(false)}
                      tryNavigatingFlag={tryingToNavigate}
                      showUpdateMsg={showUpdateMsg}
                      tryingNavigation={(navigationLinkCurrent) => {
                        setPopupItems("alert");
                        setNavigationLink(navigationLinkCurrent);
                      }}
                      userPreference={userPreference}
                      allowNavigation={navigationAllowed}
                      tireDetails={tireDetails}
                      positionArray={positionArray}
                      updatedTireDetails={updatedTireDetails}
                      history={history}
                      tdMethod={tdMethod}
                      inspectionOrder={inspectionOrder}
                      noOfReadings={noOfReadings}
                      userPressureUnit={pressureUnit}
                      vehicleInspectionDate={vehicleSelected.lastRead}
                    />
                  </Grid>
                  
                </Grid>
              )}

              <img
                alt="legendIcon"
                style={{
                  width: "100%",
                  height: "2.3em",
                  justifyItems: "center"
                }}
                src={legendIcon}
              />
            </Grid>
            <Grid container style={{ display: "block" }}>
              {imageInspectionSummary.length !== 0 && (
                <>
                  <p className="field-label-upload">{globalization.VEHICLEINSPECTION.UPLOADIMAGES}</p>

                  <List
                    className={"width-images-quick-inspect-tire"}
                    style={{
                      margin: "0",
                      display: "inline-flex",
                      overflow: "scroll"
                    }}
                  >
                    {imageInspectionSummary.map(
                      (source, key) =>
                        source.imagesLocation &&
                        source.imagesLocation !== "" &&
                        source.imagesLocation.split(",").map((src) => (
                          <ListItem key={src} sm={2} style={{ "max-width": "min-content", display: "block" }} className="uploaded-image">
                            <img alt={"chosen-image-".concat((key + 1).toString())} src={src} width="160" height="160" />
                            <span>
                              {globalization.VEHICLEINSPECTION.SERIALNUMBER} {source.tireSerialNo}
                            </span>
                            <br />
                            <span>
                              {globalization.VEHICLEINSPECTION.WHEELPOSITION} {source.wheelPosition}
                            </span>
                          </ListItem>
                        ))
                    )}
                  </List>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <p className="field-label" style={{ marginTop: 30, marginBottom: 3 }}>
                {globalization.REPORTCOMMONWORDS.VehicleComments}
              </p>
              <TextField id="contact-person" className="edit-comment-input-box"
                  placeholder="none for the moment" multiline
                  rows={4} disabled value={tireDetails.comments}
                  inputProps={{ maxLength: 200 }}
                  style={{width:1200}}
                />
            </Grid>
            <hr className="inspect_vehicle-hr-divider" />
            <div className="action-buttons" style={{marginRight:0}}>
              <Button
                className={classes.cancel}
                variant="contained"
                disableElevation={true}
                onClick={() => {
                  history.push("/manage-vehicle");
                }}
              >
                {globalization.FORMS.CANCEL}
              </Button>
              <Button className={classes.save} variant="contained" color="primary" disableElevation={true} onClick={() => setPopupItems("beforeSubmit")}>
                {globalization.FORMS.SUBMIT}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Modal open={!navigationAllowed && tryingToNavigate} onClose={() => setTryNavigating(false)} className="header-change-modal-style" disableScrollLock={true}>
        <AlertPopup
          message={popupMsg}
          description={popupDescription}
          screen="QuickInspect"
          setNavigationFlag={() => setNavigationAllowed(true)}
          setTryNavigating={() => setTryNavigating(false)}
          onSave={onPopupSave}
          onClose={() => setTryNavigating(false)}
        />
      </Modal>
      <Modal
        open={isFirstTime && showUpdateMsg}
        onClose={() => setIsFirstTime(false)}
        className="header-change-modal-style"
        disableScrollLock={true}
        onBackdropClick={() => setIsFirstTime(false)}
      >
        <div>
          <UpdateHoursMiles
            tireDetails={tireDetails}
            vehicleDetails={vehicleDetails}
            screen="QuickInspect"
            setTryNavigating={() => setIsFirstTime(false)}
            onSave={newOnPopupSave}
            onClose={() => setIsFirstTime(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default VehicleInspection;
