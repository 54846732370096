import React, { useEffect } from 'react'
import "./remove-all-tire.css";
import {
    Switch,
    withStyles,
    makeStyles,
    Grid,
    ButtonGroup,
    Button,
    FormControl,
    OutlinedInput,
    InputAdornment,
    Accordion,
    Box,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormControlLabel,
    Select,
    TextField,
    Checkbox,
    FormHelperText,
    Divider
} from "@material-ui/core";
import Globalization from '../../../globalization';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VehicleDiagram from './vehicle-diagram/VehicleDiagram';
import ExpandMoreStyledIcon from '../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon';
import CommonUtils from "../../../services/utils";


const styles = makeStyles({
    buttons: {
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        font: "Barlow",
        opacity: .8,
        fontWeight: 500,
        color: "#FFFFFF",
        width: "100px",
        textAlign: "center",
        height: "35px",
    },
    disabledButtons: {
        background: "#D3D3D3",
        font: "Barlow",
        opacity: .3,
        fontWeight: 500,
        color: "#e1e1e1",
        width: "100px",
        textAlign: "center",
        height: "35px",
    },
    fieldLabel:{
        fontWeight: 500,
        textAlign: 'left',
        color: '#333333',
        fontFamily: "Barlow",
        fontSize: 14,
        marginTop: 14,
        marginBottom: 10,
        opacity: 1,
    },
    fieldLabelWithFloatLeft:{
        fontWeight: 500,
        textAlign: 'left',
        color: '#333333',
        fontFamily: "Barlow",
        fontSize: 14,
        marginTop: 14,
        marginBottom: 10,
        opacity: 1,
        float: 'left',
    },
    fieldLabelWithFloatRight:{
        fontWeight: 500,
        textAlign: 'left',
        color: '#333333',
        fontFamily: "Barlow",
        fontSize: 14,
        marginTop: 14,
        marginBottom: 10,
        opacity: 1,
        float: 'right',
    },
    disabledFieldLabel:{
        fontWeight: 500,
        textAlign: 'left',
        color: '#333333',
        fontFamily: "Barlow",
        fontSize: 14,
        marginTop: 14,
        marginBottom: 10,
        opacity: .3,
    },
    disabledFieldLabelWithFloatLeft:{
        fontWeight: 500,
        textAlign: 'left',
        color: '#333333',
        fontFamily: "Barlow",
        fontSize: 14,
        marginTop: 14,
        marginBottom: 10,
        opacity: .3,
        float: 'left',
    },
    disabledFieldLabelWithFloatRight:{
        fontWeight: 500,
        textAlign: 'left',
        color: '#333333',
        fontFamily: "Barlow",
        fontSize: 14,
        marginTop: 14,
        marginBottom: 10,
        opacity: .3,
        float: 'right',
    },
    adorn: {
        "& .MuiTypography-colorTextSecondary": {
            color: "#000000",
            fontWeight: "500",
        },
    },
    disabledAdorn: {
        "& .MuiTypography-colorTextSecondary": {
            color: "#333333",
            fontWeight: "500",
            opacity: 0.5,
        },
    },
    input: {
        display: "none",
    },
    deleteBadge: {
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            top: "10px",
            right: "17px",
            background: "transparent",
        },
    },
    customMargin: {
        margin: '0px 6px',
        width: '18%'
    },
    customBorder: {
    borderStyle: 'dashed',
    borderColor: 'rgba(7, 70, 158, 0.53)',
    borderWidth: 'thin',
    padding: 5,
    }
});

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 115,
        height: 37,
        padding: 0,
        margin: 5,
        display: "flex",
    },
    switchBase: {
        padding: 4,
        color: theme.palette.common.white,
        "&$checked": {
            transform: "translateX(75px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: "#D14449",
                borderColor: "#FFFFFF",
            },
        },
    },
    thumb: {
        width: 30,
        height: 30,
        boxShadow: "none",
    },
    track: {
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: 30,
        opacity: 1,
        backgroundColor: "#58CCE8",
    },
    checked: {},
}))(Switch);

const AllTireRemovalForm = (props) => {
    let { tireDetails, onChangeListener, tireRemovalData, index, unitPreferences, tireDetailsData, casingConditionList, wearConditionList, masterDataSet, onRemovalReasonChange,
        removalMasterData, isRemovalReasonNewData, noOfReadings, handleCheckbox, checkBoxHandler, formHelperTexts } = props
    const classes = styles();
    const globalizationMapping = new Globalization();
    const commonUtils = new CommonUtils();
    const formCaptions = globalizationMapping.INSPECTNREMOVETIRE;
    const formUnitCaptions = globalizationMapping.FORMS;
    const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;

    let treadReadings = noOfReadings
    useEffect(() => {
        treadReadings = noOfReadings
    }, [props.noOfReadings]);
    const unitSelection = () => {
        if (unitPreferences.pressure === "PSI") {
            return "PSI";
        } else {
            return unitPreferences.pressure === "KPA" ? "KPA" : "Bar";
        }
    };


    const outTreadCal = () => {
        const originalT = parseInt(props.tireRemovalData[index].originalTread, 10);
        const outsideT = parseInt(props.tireRemovalData[index].outsideTread, 10);
        const removeAt = parseInt(props.tireRemovalData[index].removeAt, 10);
        if (isNaN(Math.round(((originalT - outsideT) / (originalT - removeAt)) * 100))) {
            return 0;
        } else {
            return Math.round(((originalT - outsideT) / (originalT - removeAt)) * 100);
        }
    };

    const inTreadCal = () => {
        const originalT = parseInt(props.tireRemovalData[index].originalTread, 10);
        const insideT = parseInt(props.tireRemovalData[index].insideTread, 10);
        const removeAt = parseInt(props.tireRemovalData[index].removeAt, 10);
        if (isNaN(Math.round(((originalT - insideT) / (originalT - removeAt)) * 100))) {
            return 0;
        } else {
            return Math.round(((originalT - insideT) / (originalT - removeAt)) * 100);
        }
    };

    const middleTreadCal = () => {
        const originalT = parseInt(props.tireRemovalData[index].originalTread, 10);
        const middleT = parseInt(props.tireRemovalData[index].middleTread, 10);
        const removeAt = parseInt(props.tireRemovalData[index].removeAt, 10);
        if (isNaN(Math.round(((originalT - middleT) / (originalT - removeAt)) * 100))) {
            return 0;
        } else {
            return Math.round(((originalT - middleT) / (originalT - removeAt)) * 100);
        }
    };

    return (
        <Box mx={5} mt={2} style={{ display: 'flex' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={handleCheckbox[index]}
                            onChange={(e) => checkBoxHandler(e.target, index)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                />
                <Accordion elevation={3} expanded={handleCheckbox[index] === true ? true : false} style={{width:'100%'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        disabled={handleCheckbox[index] === false ? true : false}
                    >
                        {[
                            { label: 'Pos', value: tireDetails.wheelPosition },
                            { label: 'Tire Serial #', value: tireDetails.tireSerialNo },
                            { label: 'Brand #', value: tireDetails.brandNo },
                            { label: 'Make', value: tireDetails.manufacturerName },
                            { label: 'Type', value: tireDetails.typeName },
                            { label: 'Size', value: tireDetails.sizeName },
                            { label: 'Hours', value: tireDetails.currentHours },
                        ].map((item, index) => (
                            <Typography key={index} variant='subtitle2' className={classes.customMargin}>
                                {item.label}: {item.value}
                            </Typography>
                        ))}
                    </AccordionSummary>
                    <hr className='black-line' />
                    <AccordionDetails>
                        <Grid container item spacing={2} xs={12}>
                            <Grid item sm={3} className="image-grid-container"  >
                                <div className='vehicle-diagram-div'>
                                    <VehicleDiagram data={tireDetailsData.axleConfig} tireListData={tireDetailsData.installedTires} index={index} from="remove-all-tire-form" />
                                </div>
                            </Grid>
                            <Grid item xs={3}> 
                                    {masterDataSet &&
                                    masterDataSet.tireDispositions &&
                                    props.masterDataSet.tireDispositions.length > 0 && (
                                        <Grid item sm={12}>
                                            <p className={tireDetails.tireId ? classes.fieldLabel : classes.disabledFieldLabel}>
                                                Tire&nbsp;{tireFormCaptions.DISPO}&nbsp;
                                            </p>
                                            <Select
                                                native
                                                className={tireDetails.tireId ? "enter-detail-input-box" : "enter-detail-input-box disabled-input"}
                                                variant="outlined"
                                                value={tireRemovalData[index].dispositionId}
                                                IconComponent={ExpandMoreStyledIcon}
                                                onChange={(e) =>
                                                    props.onChangeListener(
                                                        "disposition",
                                                        parseInt(e.target.value, 10),
                                                        index
                                                    )
                                                }
                                                disabled={!tireDetails.tireId}
                                            >
                                                {tireDetails.tireId ? 
                                                <>
                                                    {masterDataSet.tireDispositions.map((disposition) => {
                                                            return (
                                                                <option
                                                                    key={disposition.dispositionId}
                                                                    value={disposition.dispositionId}
                                                                >
                                                                    {disposition.dispositionName}
                                                                </option>
                                                            );                                                  
                                                    })}
                                                </> : 
                                                <option key={0} value={0} disabled>
                                                 {'Not Installed'}
                                                </option>
                                                }
                                                
                                            </Select>
                                            <FormHelperText>{formHelperTexts[index]?.dispositionId}</FormHelperText>
                                        </Grid>
                                    )}
                                    <Divider style={{marginTop:30}}/>
                                    {masterDataSet &&
                                    masterDataSet.rimDispositions &&
                                    masterDataSet.rimDispositions.length > 0 && (
                                    <Grid item sm={12} style={{marginBottom:20}}>
                                        <p className={tireDetails.mountedRimId ? classes.fieldLabel : classes.disabledFieldLabel}>
                                            {formCaptions.RIM} Disposition
                                        </p>
                                        <Select
                                            native
                                            className={tireDetails.mountedRimId ? "enter-detail-input-box" : "enter-detail-input-box disabled-input"}
                                            variant="outlined"
                                            value={tireRemovalData[index].rimDispositionId}
                                            IconComponent={ExpandMoreStyledIcon}
                                            onChange={(e) =>
                                                props.onChangeListener(
                                                    "rimdisposition",
                                                    parseInt(e.target.value, 10),
                                                    index
                                                )
                                            }
                                            disabled={!tireDetails.mountedRimId}
                                        >
                                            { tireDetails.mountedRimId ? 
                                            <>
                                                {masterDataSet.rimDispositions.map((disposition) => (
                                                    <option key={disposition.dispositionId} value={disposition.dispositionId}>
                                                        {disposition.dispositionName}
                                                    </option>
                                                ))}
                                            </>
                                             :
                                             <option key={0} value={0} disabled>
                                                {'Not Installed'}
                                            </option>
                                            }
                                            
                                        </Select>
                                        <FormHelperText>{formHelperTexts[index]?.rimDispositionId}</FormHelperText>
                                    </Grid>
                                    )}
                            </Grid>
                            

                            <Grid item xs={3}>
                                {masterDataSet &&
                                    masterDataSet.casingCondition &&
                                    masterDataSet.casingCondition.length > 0 && (
                                        <Grid item sm={12}>
                                            <p className={(!tireDetails.tireId)|| (tireRemovalData[index].dispositionId === 7) ? classes.disabledFieldLabel : classes.fieldLabel}>
                                                {formCaptions.REMOVALREASON}{" "}
                                                <span className="required-mark">*</span>
                                            </p>
                                            <Select
                                                native
                                                className={(!tireDetails.tireId)|| (tireRemovalData[index].dispositionId === 7) ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                                                variant="outlined"
                                                value={tireRemovalData[index].removalReasonId ? tireRemovalData[index].removalReasonId : 0}
                                                IconComponent={ExpandMoreStyledIcon}
                                                onChange={(e) =>
                                                    onRemovalReasonChange("removalreason", parseInt(e.target.value, 10), index)
                                                }
                                                disabled={!tireDetails.tireId || tireRemovalData[index].dispositionId === 7}
                                            >
                                                <option key={0} value={0} disabled>
                                                    {tireFormCaptions.DROPDOWNPLACEHOLDER}
                                                </option>
                                                {masterDataSet.tireRemovalReasons.map((reason) => (
                                                    <option key={reason.reasonId} value={reason.reasonId}>
                                                        {reason.reasonName}
                                                    </option>
                                                ))}
                                            </Select>
                                            <FormHelperText>{formHelperTexts[index]?.removalReasonId}</FormHelperText>
                                        </Grid>
                                    )}
                                {isRemovalReasonNewData[index] && isRemovalReasonNewData[index][index] === true && (
                                    <Grid item sm={12}>
                                        <TextField
                                            className={(!tireDetails.tireId)|| (tireRemovalData[index].dispositionId === 7) ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                                            variant="outlined"
                                            value={
                                                removalMasterData[index] !== undefined
                                                    ? removalMasterData[index].description
                                                    : ""
                                            }
                                            onChange={(e) => onRemovalReasonChange("otherreason", commonUtils.removeLeadingSpaces(e.target.value), index)}
                                            placeholder={`${tireFormCaptions.ENTER} ${formCaptions.REMOVALREASON}`}
                                            inputProps={{ maxLength: 200 }}
                                            disabled={!tireDetails.tireId || tireRemovalData[index].dispositionId === 7}
                                        />
                                        <FormHelperText>{formHelperTexts[index]?.otherreason}</FormHelperText>
                                    </Grid>
                                )}

                                {casingConditionList && casingConditionList.length > 0 && (
                                    <Grid item sm={12}>
                                        <p className={(!tireDetails.tireId)|| (tireRemovalData[index].dispositionId === 7) ? classes.disabledFieldLabel : classes.fieldLabel}>{formCaptions.CASINGCONDITION}</p>
                                        <Select
                                            native
                                            className={(!tireDetails.tireId)|| (tireRemovalData[index].dispositionId === 7) ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                                            variant="outlined"
                                            value={
                                                tireRemovalData[index].casingConditionId === null || undefined
                                                    ? 0
                                                    : tireRemovalData[index].casingConditionId
                                            }
                                            IconComponent={ExpandMoreStyledIcon}
                                            onChange={(e) =>
                                                onChangeListener("casing", parseInt(e.target.value, 10), index)
                                            }
                                            disabled={!tireDetails.tireId || tireRemovalData[index].dispositionId === 7}
                                        >
                                            <option key={0} value={0} disabled>
                                                {tireFormCaptions.DROPDOWNPLACEHOLDER}
                                            </option>
                                            {casingConditionList.map((condition) => (
                                                <option
                                                    key={condition.casingConditionId}
                                                    value={condition.casingConditionId}
                                                >
                                                    {condition.casingConditionName}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                )}
                                {wearConditionList && wearConditionList.length > 0 && (
                                    <Grid item sm={12}>
                                        <p className={(!tireDetails.tireId)|| (tireRemovalData[index].dispositionId === 7) ? classes.disabledFieldLabel : classes.fieldLabel}>{formCaptions.WEARCONDITION}</p>
                                        <Select
                                            native
                                            className={(!tireDetails.tireId)|| (tireRemovalData[index].dispositionId === 7) ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                                            variant="outlined"
                                            value={
                                                tireRemovalData[index].wearConditionId === null
                                                    ? 0
                                                    : tireRemovalData[index].wearConditionId
                                            }
                                            IconComponent={ExpandMoreStyledIcon}
                                            onChange={(e) =>
                                                onChangeListener("wear", parseInt(e.target.value, 10), index)
                                            }
                                            disabled={!tireDetails.tireId || tireRemovalData[index].dispositionId === 7}
                                        >
                                            <option key={0} value={0} disabled>
                                                {tireFormCaptions.DROPDOWNPLACEHOLDER}
                                            </option>
                                            {wearConditionList.map((condition) => (
                                                <option
                                                    key={condition.wearConditionId}
                                                    value={condition.wearConditionId}
                                                >
                                                    {condition.wearConditionName}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                )}

                                <Grid item sm={12}>
                                    <p className={classes.fieldLabel}>{formCaptions.COMMENTS}</p>
                                    <TextField
                                        className="enter-detail-input-box"
                                        variant="outlined"
                                        value={tireRemovalData[index].comments}
                                        onChange={(e) => {
                                            onChangeListener("comments", commonUtils.removeLeadingSpaces(e.target.value), index);
                                        }}
                                        rows={1}
                                        type="text"
                                        placeholder={globalizationMapping.INSPECT.TIREINSPECTCOMMENTS}
                                        inputProps={{ maxLength: 200 }}
                                    />
                                </Grid>
                            </Grid>


                            <Grid item xs={3}>
                                <div className={classes.customBorder}>
                                    <span className={tireDetails.tireId ? classes.fieldLabel : classes.disabledFieldLabel} style={{fontWeight:600}}>Edit Tire Details</span>

                               
                                <Grid item sm={12}>
                                    <p style={{ display: "inline" }}>
                                        <span className={tireDetails.tireId ? classes.fieldLabelWithFloatLeft : classes.disabledFieldLabelWithFloatLeft} >
                                            {formCaptions.OUTTRD}&nbsp;
                                            <span className="required-mark">*</span>
                                        </span>
                                        <span className={tireDetails.tireId ? classes.fieldLabelWithFloatRight : classes.disabledFieldLabelWithFloatRight}>
                                            {tireDetails.tireId ? outTreadCal() : 0}
                                            {formCaptions.PERCENTAGEWORN}
                                        </span>
                                    </p>
                                    <ButtonGroup size="medium" aria-label="medium outlined button group">
                                        <Button
                                            className={tireDetails.tireId ? classes.buttons : classes.disabledButtons}
                                            tabIndex={-1}
                                            onClick={() =>
                                                onChangeListener(
                                                    "outtread",
                                                    tireRemovalData[index].outsideTread - 1,
                                                    index
                                                )
                                            }
                                            disabled={!tireDetails.tireId}
                                        >
                                            -
                                        </Button>
                                        <FormControl
                                            variant="outlined"
                                            className={tireDetails.tireId ? "enter-detail-input-box buttoned-input" : "enter-detail-input-box buttoned-input disabled-input"}
                                        >
                                            <OutlinedInput
                                                value={!isNaN(tireRemovalData[index].outsideTread) ? parseInt(tireRemovalData[index].outsideTread, 10) : ""}
                                                type="number"
                                                endAdornment={
                                                    <InputAdornment className={tireDetails.tireId ? classes.adorn : classes.disabledAdorn} position="end">
                                                        {unitPreferences.treadDepth === "imperial"
                                                            ? formUnitCaptions["32"]
                                                            : formUnitCaptions.MM}
                                                    </InputAdornment>
                                                }
                                                onChange={(e) => {
                                                    onChangeListener("outtread", commonUtils.minmaxInt(e.target.value, 0, 999999), index)
                                                }}
                                                disabled={!tireDetails.tireId}
                                            />
                                        </FormControl>
                                        <Button
                                            className={tireDetails.tireId ? classes.buttons : classes.disabledButtons}
                                            tabIndex={-1}
                                            onClick={() =>
                                                onChangeListener(
                                                    "outtread",
                                                    tireRemovalData[index].outsideTread + 1,
                                                    index
                                                )
                                            }
                                            disabled={!tireDetails.tireId}
                                        >
                                            +
                                        </Button>
                                    </ButtonGroup>
                                    <FormHelperText>{formHelperTexts[index]?.outsideTread}</FormHelperText>
                                </Grid>
                                {(treadReadings === 2 || treadReadings === 3) && (
                                    <Grid item sm={12}>
                                        <div style={{ display: "inline" }}>
                                            <span className={tireDetails.tireId ? classes.fieldLabelWithFloatLeft : classes.disabledFieldLabelWithFloatLeft} >
                                                {formCaptions.INTRD}&nbsp;
                                                <span className="required-mark">*</span>
                                            </span>
                                            <span className={tireDetails.tireId ? classes.fieldLabelWithFloatRight : classes.disabledFieldLabelWithFloatRight}>
                                                {inTreadCal()}
                                                {formCaptions.PERCENTAGEWORN}
                                            </span>
                                        </div>
                                        <ButtonGroup size="medium" aria-label="medium outlined button group">
                                            <Button
                                                className={tireDetails.tireId ? classes.buttons : classes.disabledButtons}
                                                tabIndex={-1}
                                                onClick={() =>
                                                    onChangeListener(
                                                        "intread",
                                                        tireRemovalData[index].insideTread - 1,
                                                        index
                                                    )
                                                }
                                                disabled={!tireDetails.tireId}
                                            >
                                                -
                                            </Button>
                                            <FormControl
                                                variant="outlined"
                                                className={tireDetails.tireId ? "enter-detail-input-box buttoned-input" : "enter-detail-input-box buttoned-input disabled-input"}
                                            >
                                                <OutlinedInput
                                                    value={!isNaN(tireRemovalData[index].insideTread) ? parseInt(tireRemovalData[index].insideTread, 10) : ""}
                                                    type="number"
                                                    endAdornment={
                                                        <InputAdornment className={tireDetails.tireId ? classes.adorn : classes.disabledAdorn} position="end">
                                                            {unitPreferences.treadDepth === "imperial"
                                                                ? formUnitCaptions["32"]
                                                                : formUnitCaptions.MM}
                                                        </InputAdornment>
                                                    }
                                                    onChange={(e) => {
                                                        onChangeListener("intread", commonUtils.minmaxInt(e.target.value, 0, 999999), index)
                                                    }}
                                                    disabled={!tireDetails.tireId}
                                                />
                                            </FormControl>
                                            <Button
                                                className={tireDetails.tireId ? classes.buttons : classes.disabledButtons}
                                                tabIndex={-1}
                                                onClick={() =>
                                                    onChangeListener(
                                                        "intread",
                                                        tireRemovalData[index].insideTread + 1,
                                                        index
                                                    )
                                                }
                                                disabled={!tireDetails.tireId}
                                            >
                                                +
                                            </Button>
                                        </ButtonGroup>
                                        <FormHelperText>{formHelperTexts[index]?.insideTread}</FormHelperText>
                                    </Grid>
                                )}
                                {treadReadings === 3 && (
                                    <Grid item sm={12}>
                                        <div style={{ display: "inline" }}>
                                            <span className={tireDetails.tireId ? classes.fieldLabelWithFloatLeft : classes.disabledFieldLabelWithFloatLeft} >
                                                {formCaptions.MIDTRD}&nbsp;
                                                <span className="required-mark">*</span>
                                            </span>
                                            <span className={tireDetails.tireId ? classes.fieldLabelWithFloatRight : classes.disabledFieldLabelWithFloatRight}>
                                                {middleTreadCal()}
                                                {formCaptions.PERCENTAGEWORN}
                                            </span>
                                        </div>
                                        <ButtonGroup size="medium" aria-label="medium outlined button group">
                                            <Button
                                                className={tireDetails.tireId ? classes.buttons : classes.disabledButtons}
                                                tabIndex={-1}
                                                onClick={() =>
                                                    onChangeListener(
                                                        "midtread",
                                                        tireRemovalData[index].middleTread - 1,
                                                        index
                                                    )
                                                }
                                                disabled={!tireDetails.tireId}
                                            >
                                                -
                                            </Button>
                                            <FormControl
                                                variant="outlined"
                                                className={tireDetails.tireId ? "enter-detail-input-box buttoned-input" : "enter-detail-input-box buttoned-input disabled-input"}
                                            >
                                                <OutlinedInput
                                                    value={!isNaN(parseInt(tireRemovalData[index].middleTread, 10)) ? tireRemovalData[index].middleTread : ""}
                                                    type="number"
                                                    endAdornment={
                                                        <InputAdornment className={tireDetails.tireId ? classes.adorn : classes.disabledAdorn} position="end">
                                                            {unitPreferences.treadDepth === "imperial"
                                                                ? formUnitCaptions["32"]
                                                                : formUnitCaptions.MM}
                                                        </InputAdornment>
                                                    }
                                                    onChange={(e) => {
                                                        onChangeListener("midtread", commonUtils.minmaxInt(e.target.value, 0, 999999), index)
                                                    }}
                                                    disabled={!tireDetails.tireId}
                                                />
                                            </FormControl>
                                            <Button
                                                className={tireDetails.tireId ? classes.buttons : classes.disabledButtons}
                                                tabIndex={-1}
                                                onClick={() =>
                                                    onChangeListener(
                                                        "midtread",
                                                        tireRemovalData[index].middleTread + 1,
                                                        index
                                                    )
                                                }
                                                disabled={!tireDetails.tireId}
                                            >
                                                +
                                            </Button>
                                        </ButtonGroup>
                                        <FormHelperText>{formHelperTexts[index]?.middleTread}</FormHelperText>
                                    </Grid>
                                )}
                                <Grid item sm={12}>
                                    <p className={tireDetails.tireId ? classes.fieldLabel : classes.disabledFieldLabel}>
                                        {formCaptions.AIRPRESSURE}&nbsp;&nbsp;
                                        <span className="required-mark">*</span>
                                    </p>
                                    <ButtonGroup size="medium" aria-label="medium outlined button group">
                                        <Button
                                            className={tireDetails.tireId ? classes.buttons : classes.disabledButtons}
                                            tabIndex={-1}
                                            onClick={() =>
                                                onChangeListener(
                                                    "pressure",
                                                    unitPreferences.pressure === "bar" ||
                                                        unitPreferences.pressure === "Bar"
                                                        ? Math.round(
                                                            (Number(tireRemovalData[index].currentPressure) - 1) * 10
                                                        ) / 10
                                                        : tireRemovalData[index].currentPressure - 1,
                                                    index
                                                )
                                            }
                                            disabled={!tireDetails.tireId}
                                        >
                                            -
                                        </Button>
                                        <FormControl
                                            variant="outlined"
                                            className={tireDetails.tireId ? "enter-detail-input-box buttoned-input" : "enter-detail-input-box buttoned-input disabled-input"}
                                        >
                                            <OutlinedInput
                                                value={!isNaN(parseInt(tireRemovalData[index].currentPressure, 10)) ? tireRemovalData[index].currentPressure : ""}
                                                type="number"
                                                endAdornment={
                                                    <InputAdornment className={tireDetails.tireId ? classes.adorn : classes.disabledAdorn} position="end">
                                                        {unitSelection()}
                                                    </InputAdornment>
                                                }
                                                onChange={(e) =>
                                                    onChangeListener(
                                                        "pressure",
                                                        unitPreferences.pressure === "bar" ||
                                                            unitPreferences.pressure === "Bar"
                                                            ? String(Math.round(Number(e.target.value) * 10) / 10)
                                                            : commonUtils.minmaxInt(e.target.value, 0, 999999),
                                                        index
                                                    )
                                                }
                                                disabled={!tireDetails.tireId}
                                            />
                                        </FormControl>
                                        <Button
                                            className={tireDetails.tireId ? classes.buttons : classes.disabledButtons}
                                            tabIndex={-1}
                                            onClick={() =>
                                                onChangeListener(
                                                    "pressure",
                                                    unitPreferences.pressure === "bar" ||
                                                        unitPreferences.pressure === "Bar"
                                                        ? Math.round(
                                                            (Number(tireRemovalData[index].currentPressure) + 1) * 10
                                                        ) / 10
                                                        : tireRemovalData[index].currentPressure + 1,
                                                    index
                                                )
                                            }
                                            disabled={!tireDetails.tireId}
                                        >
                                            +
                                        </Button>
                                    </ButtonGroup>
                                    <FormHelperText>{formHelperTexts[index]?.currentPressure}</FormHelperText>
                                </Grid>
                                <Grid item sm={12}>
                                    <p className={tireDetails.tireId ?  classes.fieldLabel : classes.disabledFieldLabel}>
                                        {formCaptions.AIRPRESSURETYPE}&nbsp;&nbsp;
                                    </p>
                                    <FormControlLabel
                                        control={
                                            <AntSwitch
                                                checked={
                                                    tireRemovalData[index].pressureType === "H" ? true : false
                                                }
                                                onChange={(e) =>
                                                    onChangeListener("pressuretype", e.target.checked, index)
                                                }
                                            />
                                        }
                                        label={tireDetails.tireId? (tireRemovalData[index].pressureType === "H" ? "Hot" : "Cold") : null}
                                        labelPlacement="end"
                                        disabled={!tireDetails.tireId}
                                    />
                                </Grid>
                                </div>
                            </Grid>

                            

                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
    )
}

export default AllTireRemovalForm