import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";
class ManageTireListSetter {
  constructor() {
    this.data = {};
    this.adminListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "RequestId")
        selectedColShow[headCell.value] = false;
      else selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }
  search(originalTableData, searchText) {
    return originalTableData.filter((item, _index) => {
      return (
        item.Date.toLowerCase().includes(searchText) ||
        item.FieldType.toLowerCase().includes(searchText) ||
        item.Description.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.Status.toString().toLowerCase().includes(searchText) ||
        item.RequestedBy.toLowerCase().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, _index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          ) &&
          rowData[category.value] !== 0
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (
          appliedFilter[categoryKey].includes(rowData[categoryKey].toString())
        )
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data) {
    // TABLE ROWS DATA
    const requestList = data.model;
    /** NOTE : the keys must match the headCells value to be able to SORT */
    requestList.forEach((request, index) => {
      this.adminListData.push({
        Key: request.fieldId ? request.fieldId : index,
        Date: request.requestDate
          ? this.commonUtils.formatISODate(request.requestDate)
          : "",
        FieldType: request.fieldName ? request.fieldName : "",
        Description: request.description ? request.description : "",
        Status: request.status ? request.status : "",
        RequestedBy: request.requestor ? request.requestor : "",
        RequestId: request.requestId ? request.requestId : "",
      });
    });
    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.Admin;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);
    this.data = {
      rowsData: this.adminListData,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.manageVehicleListData),
      selectedColShow: selectedColShow,
    };
    return this.data;
  }

  setFilteredData(requestList, fieldtype) {
    const data = [];

    requestList.forEach((request, _index) => {
      if (fieldtype === request.FieldType) {
        data.push(request);
      }
    });
    // TABLE HEADER DATA
    this.headCells = [];
    const columnDefinitions = ColumnDefinitions.Admin;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);
    return {
      reportType: "",
      reportName: this.globalizationMapping.ADMIN.MASTERDATAREQUEST,
      rowsData: data,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.manageVehicleListData),
      selectedColShow: selectedColShow,
    };
  }
}
export default ManageTireListSetter;
