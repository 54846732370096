import Globalization from "../../../globalization/index";

class ByTiresInServiceTireTypeDataSetter {
  constructor() {
    this.data = {};
    this.byTypeData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (
        headCell.value === "AverageMilesPerMM" ||
        headCell.value === "AverageMiles"
      )
        selectedColShow[headCell.value] = false;
      else selectedColShow[headCell.value] = true;
    });
    //
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.TireCount.toString().includes(searchText) ||
        item?.AverageHoursPerMM?.toString().includes(searchText) ||
        item?.AverageMilesPerMM?.toString().includes(searchText) ||
        item?.AverageHours?.toString().includes(searchText) ||
        item?.AverageMiles?.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        //
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }
  avgValues = (byTypeData) => {
    let TireCountSum = 0,
      HoursSum = 0,
      HoursPerMMSum = 0,
      MilesSum = 0,
      MilesPerMMSum = 0;
    byTypeData.forEach((item, index) => {
      HoursSum += item.AverageHours;
      MilesSum += item.AverageMiles;
      TireCountSum += item.TireCount;
      HoursPerMMSum += item.AverageHoursPerMM;
      MilesPerMMSum += item.AverageMilesPerMM;
    });
    let TireCount = TireCountSum;
    let HoursAvg = Math.round(HoursSum / byTypeData.length);
    let MilessAvg = Math.round(MilesSum / byTypeData.length);
    let HoursPerMMAvg = Math.round(HoursPerMMSum / byTypeData.length);
    let MilesPerMMAvg = Math.round(MilesPerMMSum / byTypeData.length);
    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      CompoundName: "",
      AverageHoursPerMM: HoursPerMMAvg,
      AverageMilesPerMM: MilesPerMMAvg,
      TireCount: TireCount,
      AverageHours: HoursAvg,
      AverageMiles: MilessAvg,
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data?.model.reportData;
    if (reportData !== null || reportData.length !== 0 || reportData !== undefined) {
      reportData.forEach((item, index) => {
        /** NOTE : the keys must match the headCells value to be able to SORT */
        this.byTypeData.push({
          Key: index,
          ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
          SizeName: item.sizeName ? item.sizeName : "",
          TypeName: item.typeName ? item.typeName.trim() : "",
          CompoundName: item.compoundName ? item.compoundName : "",
          AverageHoursPerMM: item.hoursPerMM
            ? Math.round(item.hoursPerMM * 100) / 100
            : 0,
          AverageMilesPerMM: item.milesPerMM
            ? Math.round(item.milesPerMM * 100) / 100
            : 0,
          TireCount: item.tireCount ? item.tireCount : 0,
          AverageHours: item.averageHours
            ? Math.round(item.averageHours * 100) / 100
            : 0,
          AverageMiles: item.averageMiles
            ? Math.round(item.averageMiles * 100) / 100
            : 0,
        });
      });
    }

    // TABLE HEADER DATA
    const columnDefinitions = data?.model.columnDefinitions;
    if (
      columnDefinitions !== null ||
      columnDefinitions.length !== 0 ||
      columnDefinitions !== undefined
    ) {
      columnDefinitions.forEach((item, index) => {
        let colDefnString = item.text.split(".");
        let masterKey = colDefnString[0];
        let key = colDefnString[1];
        let labelName = this.globalizationMapping[masterKey][key];
        let labelId = item.fieldName;

        this.headCells.push({
          key: labelId,
          keyIndex: index,
          value: labelId,
          label: labelName,
          numeric:
            item.dataType === "number" ||
            item.dataType === "currency" ||
            item.dataType === "percentagedecimal"
              ? true
              : false,
        });
      });
    }
    let selectedColShow = this.initDisplayColumnData(this.headCells);
    return {
      rowsData: this.byTypeData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.byTypeData),
      selectedColShow: selectedColShow,
    };
  }
}

export default ByTiresInServiceTireTypeDataSetter;
