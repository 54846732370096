import React from "react";
import Moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "../../common-modal/CommonPopup.css";
import { getAccountNames, getLocationNames } from "../../../../services/AccountInfoHandler";
import Globalization from "../../../../globalization";
import CommonUtils from "../../../../services/utils";
const globalizationMapping = new Globalization();
const commonUtils = new CommonUtils();
let userPreference = JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue"));

let distanceUnit = "";
if (userPreference) {
  distanceUnit = userPreference.toLowerCase() === "metric" ? globalizationMapping["REPORTTABLE"]["HOURSMILESMET"] : globalizationMapping["REPORTTABLE"]["HOURSMILESIMP"];
}
pdfMake.vfs = pdfFonts.pdfMake.vfs;
class TireInflationPDF extends React.Component {

  tableColumnWidths = [];
  globalization = new Globalization();

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  /** cell making */
  makeCell = (content, headerOrData, options = {}, rowIndex = -1) => {
    let count = this.getCount();

    const makeCellFontSize = () => {
      if (headerOrData === "data") {
        if (count >= 11) {
          return 6;
        } else {
          return 7;
        }
      } else {
        if (count >= 11) {
          return 6;
        } else {
          return this.fontSize;
        }
      }
    };

    const makeCellFillColor = () => {
      if (headerOrData === "data") {
        if (rowIndex % 2) {
          return "#FAFAFB";
        } else {
          return "#FFFFFF";
        }
      } else {
        if (headerOrData === "total") {
          return "#E6ECF6";
        } else {
          return "#1250B0";
        }
      }
    };

    return Object.assign(
      {
        text: content,
        border: [false, false, false, false],
        borderColor: headerOrData === "data" ? ["#E0E0E0", "#E0E0E0", "#E0E0E0", "#E0E0E0"] : ["#456CAD", "#456CAD", "#456CAD", "#456CAD"],
        fontSize: makeCellFontSize(),
        
        fillColor: makeCellFillColor(),
        color: headerOrData === "data" || headerOrData === "total" ? "#2E2E2E" : "white"
      },
      options
    );
  };
  //-- Format the table cells for presentation.
  thl = (content, headerOrData, options = {}, rowIndex = -1) => {
    let count = this.getCount();
    return this.makeCell(content, headerOrData, Object.assign({ bold: true, alignment: "left", fontSize: count >= 11 ? 5 : 6 }, options, rowIndex));
  };
  tdl = (content, headerOrData, options = {}, rowIndex = -1) => {
    let count = this.getCount();
    return this.makeCell(content, headerOrData, Object.assign({ bold: false, alignment: "left", fontSize: count >= 11 ? 5 : 6 }, options, rowIndex));
  };

  getCount = () => {
    let count = 0;
    Object.values(this.props.tableData.selectedColShow).forEach((val) => {
      if (val === true) count++;
      return count;
    });
    return count;
  };

  fontSize = this.getCount() >= 11 ? 5 : 6;

  async PDFMake() {
    return this.makeTableData();
  }

  async generateRemovedTirePDF() {
    let docDefinition = {
      content: []
    };

    let tireTable;
    for (let index = 0; index < this.props.tableData.vehicleData.length; index++) {
      let eachData = this.props.tableData.vehicleData[index];
      tireTable = {
        table: {
          headerRows: 1,
          widths: this.tableColumnWidths,
          body: this.tableBody(index)
        }
      };

      let recordNum = index;
      let recordDetails = {
        table: {
          widths: ["*"],
          margin: [20, 0, 20, 0],
          body: [
            [
              {
                
                fontSize: 9,
                border: [false, false, false, false],
                color: "#000",
                fillColor: "#E5F0FF",
                alignment: "left",
                columns: [
                  {
                    text: "Record No. \u200B\t",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: ++recordNum
                  },
                  {
                    text: this.globalization.REPORTTABLE.ACCOUNTNAME + ": \u200B\t",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: eachData.ParentAccountName
                  },
                  {
                    text: this.globalization.HEADER.LOCATION + ": \u200B\t",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: eachData.LocationName
                  },
                  {
                    text: "Inspection Name: \u200B\t",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: eachData.InspectionName
                  },
                  {
                    text: "Inspection ID/ Record ID: \u200B\t",
                    bold: true,
                    width: "auto"
                  },
                  {
                    text: eachData.InspectionOrderId
                  }
                ]
              }
            ]
          ]
        }
      };
      if (index > 0) {
        recordDetails.table.body[0][0].pageBreak = "before";
      }

      let column1 = { columns: [] };

      let tireDetails = {
        table: {
          widths: ["*"],
          margin: [40, 0, 20, 0],
          body: [
            [
              {
                
                fontSize: 12,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "            ",
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  ""
                ]
              }
            ],
            [
              {
                
                fontSize: 10,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Vehicle Details",
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  ""
                ]
              }
            ],
            [
              {
                
                fontSize: 8,
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "Vehicle Id: " + eachData.VehicleId,
                    width: 140
                  },
                  {
                    text: "Asset no: " + eachData.AssetNumber,
                    width: 140
                  },
                  {
                    text: "Make/Model: " + eachData.ModelName + "/" + eachData.Manufacturer,
                    width: 170
                  },
                  {
                    text: "Type: " + eachData.TypeName,
                    width: 130
                  },
                  {
                    text: distanceUnit + " : " + eachData.CurrentHours + "/" + eachData.CurrentMiles,
                    width: 150
                  }
                ]
              }
            ],
            [
              {
                
                fontSize: 12,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "            ",
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  ""
                ]
              }
            ],
            [
              {
                
                fontSize: 12,
                color: "#074BA7",
                border: [false, false, false, false],
                alignment: "left",
                columns: [
                  {
                    text: "            ",
                    bold: true,
                    width: "auto"
                  },
                  "",
                  "",
                  ""
                ]
              }
            ]
          ]
        }
      };

      let column2 = { columnGap: 10, columns: [] };
      let column3 = { columns: [] };
      docDefinition.content.push(recordDetails);
      docDefinition.content.push(column1);
      docDefinition.content.push(tireDetails);
      docDefinition.content.push(column2);
      docDefinition.content.push(tireTable);
      docDefinition.content.push(column3);
    }
    docDefinition.content.push({
      table: {
        headerRows: 1,
        body: [
          [" ", " ", " "],
          [" ", " ", " "],
          [" ", " ", " "],
          [" ", " ", " "]
        ]
      },
      layout: "noBorders"
    });
    docDefinition.content.push({
      table: {
        headerRows: 1,
        body: [
          [
            {
              text: "Total Records : " + this.props.tableData.rowsData.length,
              fontSize: 8
            }
          ]
        ]
      }
    });

    let applyFilterTable = false;
    if (this.props.tableData.hasOwnProperty("filterFieldsApplied")) {
      let checkHeadersList = Object.keys(this.props.tableData.filterFieldsApplied);
      for (const element of checkHeadersList) {
        if (this.props.tableData.filterFieldsApplied[element].length !== 0) {
          applyFilterTable = true;
          break;
        }
      }
    }

    if (applyFilterTable) {
      docDefinition.content.push({
        table: {
          headerRows: 1,
          body: [[" ", " ", " "]]
        },
        layout: "noBorders"
      });
      let filterHeaderList = Object.keys(this.props.tableData.filterFieldsApplied);
      docDefinition.content.push({
        table: {
          headerRows: 1,
          body: [
            [
              {
                text: "Report Filters and Options",
                fontSize: "12",
                border: [false, false, false, false],
                bold: true
              },
              { text: "", border: [false, false, false, false] }
            ],
            [
              {
                text: " ",
                fontSize: "8",
                border: [false, false, false, true],
                bold: true
              },
              { text: "", border: [false, false, false, true] }
            ]
          ]
        },
        layout: {
          hLineColor: function (i, _node) {
            return i === 2 ? "#1B57B2" : "#E0E0E0";
          }
        }
      });
      filterHeaderList.forEach((filterHeader) => {
        if (this.props.tableData.filterFieldsApplied[filterHeader].length !== 0) {
          let filterArray = [
            {
              text: filterHeader + ":",
              fontSize: "9",
              bold: true,
              border: [false, false, false, true]
            }
          ];
          let filterHeaderValues = "";
          this.props.tableData.filterFieldsApplied[filterHeader].forEach((filterHeaderValue, index) => {
            if (index === 0) filterHeaderValues = filterHeaderValue;
            else filterHeaderValues += ", " + filterHeaderValue;
          });
          filterArray.push({
            text: filterHeaderValues,
            fontSize: "9",
            border: [false, false, false, true]
          });
          docDefinition.content[docDefinition.content.length - 1].table.body.push(filterArray);
        }
      });
    }
    return docDefinition.content;
  }


  tableBody = (tireNum) => {
    const body = [];
    const TableRow = [];

    let count = 0;
    Object.values(this.props.tableData.selectedColShow).forEach((val) => {
      if (val === true) count++;
      return count;
    });
    let percent = 107 / count + "%";
    this.props.tableData.headCells.forEach((headCell, _index) => {
      body.splice(0, body.length);
      if (this.props.tableData.selectedColShow[headCell.value] === true) {

        let headCellName = commonUtils.pdfHeaderNameShorter( headCell.key);
        let headerText = headCellName;


        TableRow.push(
          this.thl(headerText,  "header", {
            border: [false, false, false, true],
            fontSize: 7,
            fillColor: "white",
            color: "#2E2E2E",
            bold: true
          }, -1)
        );
        if (this.tableColumnWidths.length !== this.props.tableData.headCells.length) 
          switch (headCell.label) {
            case "Serial #":
            case "Manufacturer":
            case "Position":
            case "Eval#":
              this.tableColumnWidths.push({
                width: percent,
                _minWidth: percent,
                _maxWidth: percent,
                _calcWidth: percent
              });
              break;
            default:
              this.tableColumnWidths.push(percent);
              break;
          }
      }
    });

    body.splice(0, body.length);
    body.push(TableRow);
    this.props.tableData.rowsData[tireNum].forEach((item, index) => {
      const tableRow = [];
      let last = this.props.tableData.rowsData.length - 1 > index ? 0 : 1;
      if (last === 1 && !this.props.tableData.avgValues) {
        last = 0;
      }
      // eslint-disable-next-line array-callback-return


      Object.keys(item).forEach((key, _id) => {
        if (key !== "Key" && this.props.tableData.selectedColShow[key] === true) {

            let tirePressure = parseInt(item.Pressure, 10);
            const percentValue = (percentage, recommendedPressure) => {
              return (percentage / 100) * recommendedPressure;
            }

            const pressureFillColor = () => {
              if (tirePressure <= percentValue(20, item.RecomPressure)) {
                return '#ff9488';
              } else if (tirePressure >= percentValue(21,item.RecomPressure) && tirePressure < percentValue(35,item.RecomPressure)) {
                return '#ffc7c7';
              } else if (tirePressure >= percentValue(35,item.RecomPressure) && tirePressure < percentValue(50,item.RecomPressure)) {
                return '#ffe0a9';
              } else if (tirePressure >= percentValue(50,item.RecomPressure) && tirePressure < percentValue(70,item.RecomPressure)) {
                return '#ffe0a9';
              } else if (tirePressure >= percentValue(70,item.RecomPressure) && tirePressure < percentValue(85,item.RecomPressure)) {
                return '#ffffaf';
              } else {
                return '#ccf2db'
              }
            }

            const percentageWornColor = () => {
              if (item.PercentageWorn < 25) {
                return '#ccf2db';
              } else if (item.PercentageWorn < 50) {
                return '#ffffaf';
              } else if (item.PercentageWorn < 75) {
                return '#ffe0a9';
              } else {
                return '#ffc7c7';
              }
            }; 

            const fillColorIdentifier = () => {
              if (key === "Pressure") {
                return pressureFillColor();
              } else if (key === "PercentageWorn") {
                return percentageWornColor();
              } else {
                return "white";
              }
            }

            const percentageAdder = (item) => {
              if (key === "PercentageWorn" || key === 'PercentRecomPressure') {
                return item + '%';
              } else {
                return item;
              }
            }
            
            tableRow.push(
              this.tdl(percentageAdder(item[key]), "data", {
                fontWeight: 400,
                color: "#333333",
                fillColor:fillColorIdentifier(),
                fontSize: this.fontSize,
                border: last === 1 ? [false, false, false, false] : [false, false, false, true]
              }, index)
            );
        }
      });

      body.push(tableRow);
    });
    // });
    return body;
  };

  /** Doc definition */
  async createDocumentDefinition() {
    const baseDocDefinition = {
      pageSize: "A4",
      pageMargins: [15, 90, 15, 55],
      pageOrientation: "landscape",
      content: []
    };
    const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
    docDefinition.header = await this.addPdfMakeHeader();
    docDefinition.footer = this.addPdfMakeFooter();
    docDefinition.content.push(await this.generateRemovedTirePDF());
    const defaultFont = {defaultStyle: {
      font: 'Barlow'
    }}
    docDefinition.defaultStyle = defaultFont;
    return docDefinition;
  }

  /** header body and footer */
  async addPdfMakeHeader() {

    return (_currentPage, _pageCount) => {
      return {
        margin: [20, 20, 20, 0],
        stack: [
          {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    border: [false, false, false, false],
                    
                    color: "white",
                    fillColor: "#1250B0",
                    alignment: "right",
                    columns: [
                      {
                        svg: commonUtils.EmTrackSVGLogo,
                        fit: [50, 80],
                        width: "5%",
                        margin: [0, 2, 0, 2],
                        alignment: "left"
                      },
                      {
                        text: " " + commonUtils.pdfReportName(this.props.tableData.reportName),
                        bold: true,
                        alignment: "center",
                        fontSize: 14,
                        margin: [0, 10, 0, 2]
                      },
                      {
                        svg: commonUtils.GoodyearSVGLogo,
                        alignment: "right",
                        width: "10%",
                        fit: [90, 150]
                      },
                    ]
                  }
                ]
              ]
            }
          },
          {
            table: {
              widths: ["*"],
              margin: [20, 0, 20, 0],
              body: [
                [
                  {
                    
                    fontSize: 10,
                    border: [false, false, false, false],
                    color: "#074BA7",
                    fillColor: "#E5F0FF",
                    alignment: "left",
                    columns: [
                      {
                        text: this.globalization.REPORTTABLE.DATE + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: "  " + Moment(new Date()).format("DD/MM/YYYY"),
                        bold: true
                      },
                      {
                        text: this.globalization.MANAGEPARENTACCOUNT.ACCOUNT + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: " " + getAccountNames().toString(),
                        bold: true
                      },
                      {
                        text: this.globalization.HEADER.LOCATION + ": \u200B\t",
                        width: "auto"
                      },
                      {
                        text: " " + getLocationNames().toString(),
                        bold: true,
                        width: "auto"
                      }
                    ]
                  }
                ]
              ]
            }
          }
        ]
      };
    };
  }

  addPdfMakeFooter = () => {
    let footerText = "©" + new Date().getFullYear() + " The Goodyear Tire & Rubber Company ";
    return (currentPage, pageCount) => {
      // you can apply any logic and return any valid pdfmake element
      return {
        margin: [20, 0, 20, 0],
        border: [false, false, false, false],
        table: {
          widths: ["*"],
          body: [
            [
              {
                border: [false, false, false, false],
                fontSize: 9,
                color: "white",
                fillColor: "#1250B0",
                alignment: "left",
                columns: [
                  {
                    text: footerText,
                    alignment: "left",
                    width: "40%",
                    margin: [0, 13, 0, 0],
                    bold: true
                  },
                  {
                    text: "PAGE " + currentPage.toString() + "/" + pageCount.toString(),
                    alignment: "right",
                    margin: [0, 13, 0, 0],
                    fontWeight: 400
                  }
                ]
              }
            ]
          ]
        }
      };
    };
  };


  async makeTableData() {
    // -- Create a base document template for the reports.
    const docDefinition = await this.createDocumentDefinition();
    return pdfMake.createPdf(docDefinition);
  }
  render() {
    return <div></div>;
  }
}

export default TireInflationPDF;
