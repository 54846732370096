import Globalization from "../../../globalization/index";

class ProjectedCostByTireTypeDataSetter {
  constructor() {
    this.projectedCostByTireTypeData = [];
    this.globalizationMapping = new Globalization();
    this.data = {};
    this.headCells = [];
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    //
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.TireCount.toString().includes(searchText) ||
        item.CostPerHour.toString().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    //
    //

    let categoryKeys = Object.keys(appliedFilter);

    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }

  avgValues = (projectedCostByTireTypeData) => {
    let TotalTires = 0,
      HoursSum = 0;
    projectedCostByTireTypeData.forEach((item) => {
      TotalTires += item.TireCount;
      HoursSum += parseFloat(item.CostPerHour);
    });

    let TotalTiresSum = TotalTires;
    let HoursAvg = parseFloat(HoursSum).toFixed(2);

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      TireCount: TotalTiresSum,
      CostPerHour: HoursAvg,
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.projectedCostByTireTypeData.push({
        Key: index,
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        TireCount: item.tireCount ? item.tireCount : 0,
        CompoundName: item.compoundName ? item.compoundName : "",
        CostPerHour: item.costPerHour
          ? parseFloat((item.costPerHour * 100) / 100).toFixed(2)
          : 0.0,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.projectedCostByTireTypeData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.projectedCostByTireTypeData),
      selectedColShow: selectedColShow,
    };
  }
}

export default ProjectedCostByTireTypeDataSetter;
