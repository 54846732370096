import { FormHelperText, Grid, Select, TextField, makeStyles, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import "./create-vehicle.css";
import updateicon from "../../assets/update.svg";
import CommonUtils from "../../services/utils";
import Globalization from '../../globalization';
import ExpandMoreStyledIcon from '../../common-components/expandMoreStyledIcon/expandMoreStyledIcon';
import CalendarMonthIcon from '@material-ui/icons/Today';

const useStyles = makeStyles(_theme => ({
    bottomUpdate: {
        "&:hover": {
            backgroundColor: "transparent"
        },
        opacity: 1,
        background: "#b9b9b900",
        color: "#2355AF",
        fontWeight: 500,
        border: " 1px solid #ccc",
        borderRadius: "18px",
        height: "3em",
        width: "8em"
    }
}));
const Identification = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const commonUtils = new CommonUtils();
    const globalizationMapping = new Globalization();
    const [trackingMethod, setTrackingMethod] = useState(1);
    const [installedTiresAvailable, setInstalledTiresAvailable] = useState(false);

    useEffect(() => {
        setTrackingMethod(props.trackBy ? props.trackBy : 1);
        if (props.installedTireData && props.installedTireData.installedTires && props.installedTireData.installedTires.length > 0) {
            setInstalledTiresAvailable(true);
        }
    }, [props.installedTireData]);
    const handleTrackingMethodChange = (val) => {
        setTrackingMethod(val);
        if (val === 1) {
            props.onChangeListener('Hours', val);

        } else if (val === 2) {
            props.onChangeListener('Miles', val);

        } else {
            props.onChangeListener('Both', val);

        }
        props.onTrackByChange(val);
    };


    const handleDateChange = (val) => {
        props.onChangeListener('regDate', val);
    };

    function handleUpdate() {
        history.push({
            pathname: "/meter-change",
            state: {
                vehicleId: props.vehicleId,
                from: 'createVehicle'
            }
        });
    }
    return (
        <Grid className="step-form-inner-container" container spacing={2} >
                <Grid item sm={12} >
                    <p className="field-label-vehicle">{props.fieldNames.VEHICLEID} <span className="required-mark">*</span></p>
                    <TextField
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.vehicleNumber}
                        onChange={(e) => props.onChangeListener('vehicleNumber', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.VEHICLEID}`} 
                        inputProps={{ maxLength: 50 }}
                    />
                    {(props.stateVar.vehicleNumber === "" && props.isVehicleNumberEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(props.isVehicleNumberEdited ? "" : <FormHelperText>{props.formHelperTexts.vehicleNumber}</FormHelperText>)} 
                </Grid>
                <Grid item sm={12} >
                    <p className="field-label-vehicle">{props.fieldNames.ASSETNUMBER}</p>
                    <TextField
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.assetNumber}
                        onChange={(e) => props.onChangeListener('assetNumber', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.ASSETNUMBER}`} 
                        inputProps={{ maxLength: 50 }}
                    />
                    <FormHelperText>{props.formHelperTexts.assetNumber}</FormHelperText>
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label-vehicle">{props.fieldNames.REGDATE} <span className="required-mark">*</span></p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                        <KeyboardDatePicker
                            className="enter-detail-input-box"
                            margin="normal"
                            value={props.stateVar.registeredDate}
                            onChange={handleDateChange}
                            format={commonUtils.getDateFormat()}
                            placeholder={commonUtils.getDateFormat()}
                            KeyboardButtonProps={{
                                'aria-label': 'change date'
                            }}
                            keyboardIcon={<CalendarMonthIcon/>}
                        />
                    </MuiPickersUtilsProvider>
                    <FormHelperText>{props.formHelperTexts.registeredDate}</FormHelperText>
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label-vehicle">{props.fieldNames.TRACKBY}</p>

                    <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={trackingMethod}
                        onChange={(e) => handleTrackingMethodChange(e.target.value)}
                    >
                        <option value={3}>{globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM.BOTH}</option>
                        <option value={2}>{globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM.DISTANCE}</option>
                        <option value={1}>{globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM.HOURS}</option>

                    </Select>
                </Grid>
                {(Number(props.trackBy) === 1 || Number(props.trackBy) === 3) &&
                    <Grid item sm={12}>
                        <p className="field-label-vehicle">{props.fieldNames.CURRHOURS}<span className="required-mark">*</span></p>
                        <TextField
                            className="enter-detail-input-box"
                            variant="outlined"
                            value={props.stateVar.currentHours}
                            onChange={(e) => props.onChangeListener('currentHours', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                            type="number"
                            inputProps={{ maxLength: 50 }}
                        />
                        {(props.stateVar.currentHours !== "" && (props.stateVar.currentHours < 0 || props.stateVar.currentHours === '-0' ) && props.isCurrentHoursEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.stateVar.currentHours === "" && props.isCurrentHoursEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.isCurrentHoursEdited ? "" : <FormHelperText>{props.formHelperTexts.currentHours}</FormHelperText>)} 
                    </Grid>}
                {(Number(props.trackBy) === 2 || Number(props.trackBy) === 3) &&
                    <Grid item sm={12}>
                        <p className="field-label-vehicle">
                            {props.userPreferenceData.userMeasurementSystemValue === "imperial" ? props.fieldNames.CURRMILES : props.fieldNames.CURRKM}
                            <span className="required-mark">*</span>
                        </p>
                        <TextField
                            
                            className="enter-detail-input-box"
                            variant="outlined"
                            value={props.stateVar.currentMiles}
                            onChange={(e) => props.onChangeListener('currentMiles', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                            type="number"
                            inputProps={{ maxLength: 50 }}
                        />
                        {(props.stateVar.currentMiles !== "" && (props.stateVar.currentMiles < 0 || props.stateVar.currentMiles === '-0') && props.isCurrentMilesEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.stateVar.currentMiles === "" && props.isCurrentMilesEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.isCurrentMilesEdited ? "" : <FormHelperText>{props.formHelperTexts.currentMiles}</FormHelperText>)} 
                    </Grid>}
                {installedTiresAvailable && <Grid item sm={12}>
                    <p className="field-label-vehicle">{props.fieldNames.UPDATEHOURS}</p>
                    <Button
                        className={classes.bottomUpdate}
                        variant="contained"
                        color="primary"
                        disableElevation={true}
                        onClick={() => handleUpdate()}
                        startIcon={
                            <img
                                alt="updateicon"
                                style={{
                                    width: "100%",
                                    height: "2.3em",
                                    justifyItems: "center"
                                }}
                                src={updateicon}
                            />
                        }
                    >
                        {globalizationMapping.INSPECT.UPDATE}
                    </Button>
                </Grid>}
            </Grid>
    );
};

export default Identification;
