import { Backdrop, Button, makeStyles, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Globalization from '../../../../../globalization';
import ManageTireListApis from '../../../services/ManageTireListApis';
import './delete-tire-modal.css';

const useStyles = makeStyles({
    proceed: {
        "&:hover": {
            opacity: 1,
        },
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: 'Barlow',
        opacity: .8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none"
        },
    cancel: {
          "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none",
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: .8,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none",
        marginRight:5
    },
});

const DeleteTireModal = (props) => {

    const styles = useStyles();
    const locationObj = useLocation();
    const history = useHistory();

    const globalizationMapping = new Globalization();
    const titleTexts = globalizationMapping.FORMS;
    const modalTexts = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
    const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;

    const manageTireApiServices = new ManageTireListApis();

    const [tiresToDelete, setTiresToDelete] = useState([]);

    useEffect(() => {
        if (props.isSelectionMultiple) {

            let arr = props.tireIds.filter(tire => {
                return (tire.Disposition !== "Installed" && tire.Disposition !== "OnHold");
            });
            setTiresToDelete(arr);
        }
    }, []);

    const handleModalClose = (stateData) => {
        props.closeDeleteModal(stateData);
    }

    const showSelectedTires = () => {
        if (props.isSelectionMultiple) {
            if (tiresToDelete.length > 3) {
                let tireStr1 = "";
                let tireStr2 = "";
                for (let i = 0; i <= 2; i++) {
                    tireStr1 = tireStr1 + tiresToDelete[i].SerialNo + ", ";
                }
                tireStr2 = "..." + (tiresToDelete.length - 3) + " more";
                return (tireStr1 + tireStr2);
            } else {
                return (Object.values(tiresToDelete).map(value => (value.SerialNo)));
            }
        }
    };

    const handleDeleteClick = () => {
        let successStatus = false;
        if (props.isSelectionMultiple) {

            if (tiresToDelete.length !== 0) {

                tiresToDelete.forEach(tire => {
                    manageTireApiServices
                        .deleteTire(tire.Key)
                        .then(response => {
                            if (response.didError === false) {
                                successStatus = true;
                            } else {
                                successStatus = false;
                            }
                        });
                });
                handleModalClose({
                    dispositionId: 0,
                    updatedSection: 'deletetire',
                    updateStatus: true,
                    successMessage: notificationTexts.TIRESDELETED,
                    successMessageDesc: notificationTexts.TIRESDELETIONSUCCESS
                });
            }
        } else {
            manageTireApiServices
                .deleteTire(props.tireId)
                .then(response => {
                    if (response.didError === false) {
                        successStatus = true;
                    } else {
                        successStatus = false;
                    }
                    if (locationObj.pathname === '/view-tire') {
                        history.push({
                            pathname: '/manage-tire',
                            state: {
                                dispositionId: 0,
                                updatedSection: 'deletetire',
                                updateStatus: successStatus,
                                successMessage: notificationTexts.TIREDELETED,
                                successMessageDesc: notificationTexts.TIREDELETIONSUCCESS.replace("#1#1", props.tireSerial)
                            }
                        });
                    } else {
                        handleModalClose({
                            dispositionId: 0,
                            updatedSection: 'deletetire',
                            updateStatus: successStatus,
                            successMessage: notificationTexts.TIREDELETED,
                            successMessageDesc: notificationTexts.TIREDELETIONSUCCESS.replace("#1#1", props.tireSerial)
                        });
                    }
                });
        }
    }


    const deleteModalDescription = () => {

        if (!props.isSelectionMultiple) {
            return modalTexts.DELETETIREDESC.replace("#1#1", props.tireSerial); 
        } else {
            if (tiresToDelete.length !== 0) {
                return modalTexts.DELETETIREDESC.replace("#1#1", showSelectedTires).replaceAll(",", ", ");
            } else {
                return modalTexts.DELETEFORIBIDDEN
            }
        }
    }

    return (
        <React.Fragment>
            <div className="container">
                <Modal
                    open={true}
                    onClose={handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 300,
                    }}
                    disableScrollLock= {true}
                >
                    <div className="delete-tire-modal-container center-modal">
                        <div className="delete-tire-title-container">
                            <div className="delete-tire-title">
                                {modalTexts.DELETETIRE}
                            </div>
                            <div
                                className="close-icon-container"
                                onClick={handleModalClose}>
                                <img
                                    src={require("../../../../../assets/close-icon.svg")}
                                    className="modal-close-icon"
                                    alt="close-icon"
                                />
                            </div>
                        </div>
                        <div className="delete-modal-description">
                            {deleteModalDescription()}
                            <p>{(tiresToDelete.length !== 0 || props.tireSerial) && titleTexts.CONFIRMPROCEED}</p>
                        </div>
                        {((props.isSelectionMultiple && tiresToDelete.length !== 0) || !props.isSelectionMultiple)
                            &&
                            <div className="modal-action-buttons">
                                <Button
                                    className={styles.cancel}
                                    variant="contained"
                                    disableElevation={true}
                                    onClick={handleModalClose}
                                >
                                    {titleTexts.CANCEL}
                                </Button>
                                <Button
                                    className={styles.proceed}
                                    variant="contained"
                                    disableElevation={true}
                                    onClick={
                                        () => { handleDeleteClick() }
                                    }
                                >
                                    {titleTexts.DELETE}
                                </Button>
                            </div>}
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default DeleteTireModal;