import Globalization from "../../../globalization/index";

class MonthlyTireLifeDataSetter {
  constructor() {
    this.monthlyTireLifeData = [];
    this.globalizationMapping = new Globalization();
    this.data = {};
    this.headCells = [];
  }

  setGraphData(data){
      let graphData = data.model.graphData
      return graphData
  }
  setDisplayColumns(graphData) {
      let displayColumns = []
      graphData.chartlabels.forEach((item, index) => {
          displayColumns.push({
              key: item,
              keyIndex: index,
              value: item,
              label: item,
              count:  graphData.chartData[0].data[index]
          })
      })
      return displayColumns
  }
  setDisplayColumnData(byTireSizeGraphData, selectedCols) {
    let bS = { chartlabels: [], chartData: [{ data: [] }] };
    for (let graphIndex = 0; graphIndex < byTireSizeGraphData.chartlabels.length; graphIndex++) {
      for (const element of selectedCols) {
        if (element.value === byTireSizeGraphData.chartlabels[graphIndex]) {
          bS.chartlabels[graphIndex] = element.value;
          bS.chartData[0].data[graphIndex] = element.count;
          break;
        }
      }
    }
    bS.chartlabels = bS.chartlabels.filter((item) => item !== undefined);
    bS.chartData[0].data = bS.chartData[0].data.filter((item) => item !== undefined);
    return bS;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    //
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.TireCount.toString().includes(searchText) ||
        item.AverageHours.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    filterOptions.categories.forEach((category, index) => {
      rowsData[category.value].forEach((rowData) => {
        if (!filterInitCategoryData[category.value].includes(rowData)) {
          filterInitCategoryData[category.value].push(rowData.toString());
        }
      });
    });

    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  avgValues = (monthlyTireLifeData) => {
    let TotalTires = 0,
      HoursSum = 0,
      MilesSum = 0;
    monthlyTireLifeData.forEach((item) => {
      TotalTires += item.TireCount;
      HoursSum += item.TotalAverageHours;
      MilesSum += item.TotalAverageMiles;
    });

    let TotalTiresSum = TotalTires;
    let HoursAvg = Math.round(HoursSum / TotalTires);
    let MilesAvg = Math.round(MilesSum / TotalTires);

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      TireCount: TotalTiresSum,
      AverageHours: HoursAvg,
      AverageMiles: MilesAvg
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    let keysArr = ["actualHours", "ytd", "totalTire"];
    const columnDefinitions = data.model.columnDefinitions;
    const reportData = data.model.reportData[0].monthlyTireLifeReportModel;
    keysArr.forEach((key) => {
      let temp = {};
      columnDefinitions.some((item, index) => {
        let colDefnString = item.text.split(".");
        let masterKey = colDefnString[0];
        let str = colDefnString[1];
        let labelName = this.globalizationMapping[masterKey][str];
        if (key.toLowerCase() === str.toLowerCase()) {
          temp["header"] = labelName;
          return true;
        }
      });
      // temp['header'] = key
      reportData.forEach((item, index) => {
        temp[item.month] = item[key] ? Math.round(item[key]) : 0;
      });
      this.monthlyTireLifeData.push(temp);
    });

    // TABLE HEADER DATA
    let j = 0;
    this.headCells.push({
      key: "header",
      keyIndex: j++,
      value: "header",
      label: "",
      numeric: false
    });
    for (const iterator of reportData) {
      this.headCells.push({
        key: iterator.month,
        keyIndex: j++,
        value: iterator.month,
        label: this.globalizationMapping["MONTHLYTIRELIFE"][iterator.month],
        numeric: true
      });
    }

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.monthlyTireLifeData,
      headCells: this.headCells,
      avgValues: {},
      selectedColShow: selectedColShow
    };
  }
}

export default MonthlyTireLifeDataSetter;
