import React, { useEffect, useContext, useState } from "react";
import ByTiresNavigation from "../../by-tires-navigation/ByTiresNavigation";
import Spinner from "../../../../../../common-components/spinner/spinner";
import "../../../../../../common-styles/TableFunctionOptions.css";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Grid,
  Select,
} from "@material-ui/core";
import ExpandMoreStyledIcon from "../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import MultiSelect from "react-multi-select-component";
import { StateContext } from "../../../../../../services/StateProvider";
import SearchIcon from "../../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import { accountInfoIdsFormatter } from "../../../../../../services/AccountInfoHandler";
import TiresService from "../../../../../tires-reports/services/TiresApis";
import TireInflationTable from "../table/TireInflationTable";
import TireInflationDataSetter from "../../../../services/TireInflationUtils";
import CommonUtils from "../../../../../../services/utils";
import Globalization from "../../../../../../globalization";
import { useComponentVisible } from "../../../../../../services/CustomHooks";
import FilterPrintEmailExportSetButtons from '../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import TireInflationReportFilter from '../filter/TireInflationReportFilter'

const TireInflationReport = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const globalizationMapping = new Globalization();

  const [showSpinner, setShowSpinner] = useState(true);
  const [fleetSurveyTableData, setFleetSurveyTableData] = useState();
  const [originalFleetSurveyTableData, setOriginalFleetSurveyTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(fleetSurveyTableData);
  const [printDataNotPDF, setPrintDataNotPDF] = useState(fleetSurveyTableData);
  const [noData, setNoData] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: "",
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [dateRangeType, setDateRangeType] =
    useState("AllTime");
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [toggleHM, setToggleHM] = useState("hours");
  const toggleMile =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Vehicle Id", value: "VehicleId" },
      { name: "Model", value: "ModelName" },
      { name: "Type", value: "TypeName" },
    ],
    categoryData: {
      VehicleId: [],
      ModelName: [],
      TypeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    },
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const commonUtils = new CommonUtils();
  const fleetSurveyDataSetter = new TireInflationDataSetter();
  const tiresService = new TiresService();

  const TireInflationReportResponseData = (
    excludeUsedTiresValue,
    filterAppliedValue,
    filterFieldsAppliedValue
  ) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      locationIds: locationIds,
      reportName: "Tire_Inflation",
      reportOption: "",
      toDate: dateRangeFilter.toDate,
      usedTires: excludeUsedTiresValue.toString(),
    };

    setShowSpinner(true);

    tiresService.getTiresReport(requestObject).then((data) => {
      let fleetSurveyTableInfo = fleetSurveyDataSetter.setData(data);
      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.VEHICLEID,
            value: "VehicleId",
          },
          {
            name: globalizationMapping.REPORTTABLE.VEHICLEMODEL,
            value: "ModelName",
          },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        ],
        categoryData: {
          VehicleId: [],
          ModelName: [],
          TypeName: [],
          dateRangeFromDate: '',
          dateRangeToDate: '',
        },
      };

      let filterOptionsInfo = fleetSurveyDataSetter.setFilterOptionsData(
        filterOptions,
        fleetSurveyTableInfo.rowsDataFilterOptions
      );
      setFleetSurveyTableData(fleetSurveyTableInfo);
      fleetSurveyTableInfo.rowsData.length === 0
        ? setNoData(true)
        : setNoData(false);
      setOriginalFleetSurveyTableData(fleetSurveyTableInfo);
      setTableBeforeFilter(fleetSurveyTableInfo);
      setFilterOptions(filterOptionsInfo);
      setDisplayColOptions(fleetSurveyTableInfo.headCells);
      setDisplayColumns(fleetSurveyTableInfo.headCells);
      setShowColumns(fleetSurveyTableInfo.selectedColShow);
      setShowSpinner(false);
      setPrintData({
        reportType: "Vehicle Reports",
        reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
        rowsData: fleetSurveyTableInfo.rowsData,
        headCells: fleetSurveyTableInfo.headCells,
        vehicleData: fleetSurveyTableInfo.vehicleData,
        selectedColShow: fleetSurveyTableInfo.selectedColShow,
      });
      let exportData = JSON.parse(JSON.stringify(fleetSurveyTableInfo));
      for (let index = 0; index < exportData.rowsData.length; index++) {
        let vehicleId = exportData.vehicleData[index].VehicleId;
        exportData.rowsData[index].forEach((tireRow) => {
          tireRow.VehicleId = vehicleId;
        });
      }
      exportData.headCells.unshift({
        key: "VehicleId",
        keyIndex: exportData.headCells.length,
        label: "Vehicle Id",
        numeric: true,
        value: "VehicleId",
      });
      exportData.selectedColShow.VehicleId = true;

      setPrintDataNotPDF({
        reportType: "Vehicle Reports",
        reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
        rowsData: exportData.rowsData,
        vehicleData: exportData.vehicleData,
        headCells: exportData.headCells,
        selectedColShow: exportData.selectedColShow,
      });

      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalVehicleDataStore = fleetSurveyTableInfo.vehicleData;
        let originalRowsDataStore = fleetSurveyTableInfo.rowsData;
        let filteredVehicleData = fleetSurveyDataSetter.filterTable(
          filterFieldsAppliedValue,
          originalVehicleDataStore
        );
        let filteredRowsData = fleetSurveyDataSetter.setRowsDataOnUpdate(
          filteredVehicleData,
          originalRowsDataStore
        );
        setFleetSurveyTableData({
          ...fleetSurveyTableInfo,
          rowsData: filteredRowsData,
          vehicleData: filteredVehicleData,
        });
        setOriginalFleetSurveyTableData({
          ...fleetSurveyTableInfo,
          rowsData: filteredRowsData,
          vehicleData: filteredVehicleData,
        });
        setPrintData({
          reportType: "Vehicle Reports",
          reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
          rowsData: filteredRowsData,
          vehicleData: filteredVehicleData,
          headCells: fleetSurveyTableInfo.headCells,
          selectedColShow: fleetSurveyTableInfo.selectedColShow,
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true,
        });
        setPrintDataNotPDF({
          reportType: "Vehicle Reports",
          reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
          rowsData: JSON.parse(JSON.stringify(filteredRowsData)),
          vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
          headCells: JSON.parse(JSON.stringify(fleetSurveyTableInfo.headCells)),
          selectedColShow: JSON.parse(
            JSON.stringify(fleetSurveyTableInfo.selectedColShow)
          ),
          filterFieldsApplied: JSON.parse(
            JSON.stringify(filterFieldsAppliedValue)
          ),
          filterApplied: true,
        });
        setOnlyFilterApplied(true);
        filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
      }
    });
  };

  useEffect(() => {
    TireInflationReportResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false,
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]
        );
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

  setCurrentFilter(toSetCurrentFilter);
  if (appliedFilter === filterOptions.categoryData) {
    setFilterApplied(false);
  } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    let originalVehicleDataStore = originalFleetSurveyTableData?.vehicleData;
    let originalRowsDataStore = originalFleetSurveyTableData?.rowsData;
    let filteredVehicleData = fleetSurveyDataSetter.filterTable(
      appliedFilter,
      originalVehicleDataStore
    );
    let filteredRowsData = fleetSurveyDataSetter.setRowsDataOnUpdate(
      filteredVehicleData,
      originalRowsDataStore
    );
    setFleetSurveyTableData({
      ...fleetSurveyTableData,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
    });
    setPrintData({
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
      headCells: originalFleetSurveyTableData?.headCells,
      selectedColShow: originalFleetSurveyTableData?.selectedColShow,
    });
    setPrintDataNotPDF({
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
      rowsData: JSON.parse(JSON.stringify(filteredRowsData)),
      vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
      headCells: JSON.parse(
        JSON.stringify(originalFleetSurveyTableData?.headCells)
      ),
      selectedColShow: JSON.parse(
        JSON.stringify(originalFleetSurveyTableData?.selectedColShow)
      ),
    });
    filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
    setToggleHM("hours");
    TireInflationReportResponseData(excludeUsedTires, true, appliedFilter);
  };


  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      VehicleId: [],
      ModelName: [],
      TypeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });

    setFilterApplied(false);
    setFleetSurveyTableData(tableBeforeFilter);
    setOriginalFleetSurveyTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {},
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {},
    });

    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
    setNoData(false);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    let originalVehicleDataStore,
      originalRowsDataStore,
      filteredVehicleData,
      filteredRowsData;
    originalVehicleDataStore = originalFleetSurveyTableData?.vehicleData;
    originalRowsDataStore = tableBeforeFilter?.rowsData;

    filteredVehicleData = fleetSurveyDataSetter.search(
      originalVehicleDataStore,
      searchText
    );

    filteredRowsData = fleetSurveyDataSetter.setRowsDataOnUpdate(
      filteredVehicleData,
      originalRowsDataStore
    );

    setFleetSurveyTableData({
      ...fleetSurveyTableData,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
    });
    setPrintData({
      ...printData,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
      headCells: originalFleetSurveyTableData?.headCells,
      selectedColShow: originalFleetSurveyTableData?.selectedColShow,
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
      rowsData: JSON.parse(JSON.stringify(filteredRowsData)),
      vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
      headCells: JSON.parse(
        JSON.stringify(originalFleetSurveyTableData?.headCells)
      ),
      selectedColShow: JSON.parse(
        JSON.stringify(originalFleetSurveyTableData?.selectedColShow)
      ),
    });
    filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalFleetSurveyTableData?.headCells;
    let showColumnsUpdated = fleetSurveyDataSetter.setDisplayColumnData(
      currentHeadCells,
      selected
    );

    setShowColumns(showColumnsUpdated);
    setPrintData({ ...printData, selectedColShow: showColumnsUpdated });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      selectedColShow: showColumnsUpdated,
    });
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    setToggleHM("hours");
    TireInflationReportResponseData(
      event.target.checked,
      filterApplied,
      filterFieldsApplied
    );
  };
  const actionChanged = (value) => {
    let avoidArr = [];
    setToggleHM(value);
    if (value === "miles") {
      avoidArr = ["TotalHours"];
    } else {
      avoidArr = ["TotalMiles"];
    } 

    let fleetTableInfo = fleetSurveyTableData;

    fleetTableInfo.headCells.map((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      fleetTableInfo.selectedColShow[item.value] = flag;
    });
    setFleetSurveyTableData(fleetTableInfo);
    fleetTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
    let updatedHeadCells = fleetTableInfo.headCells.filter((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });
    setDisplayColOptions(updatedHeadCells);
    setDisplayColumns(updatedHeadCells);
    setShowColumns(fleetTableInfo.selectedColShow);

    setPrintData({
      ...printData,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
      rowsData: fleetTableInfo.rowsData,
      vehicleData: fleetTableInfo.vehicleData,
      headCells: fleetTableInfo.headCells,
      selectedColShow: fleetTableInfo.selectedColShow,
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
      rowsData: JSON.parse(JSON.stringify(fleetTableInfo.rowsData)),
      vehicleData: JSON.parse(JSON.stringify(fleetTableInfo.vehicleData)),
      headCells: JSON.parse(JSON.stringify(fleetTableInfo.headCells)),
      selectedColShow: JSON.parse(
        JSON.stringify(fleetTableInfo.selectedColShow)
      ),
    });

    setShowSpinner(false);
  };

  return (
    <div className="container fleet-root-class">
      <ByTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-compound-container">
          <Card className="by-compound-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form
                      className="search-table-container"
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.REPORTCOMMONWORDS.SearchTable}
                        </span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={
                          globalizationMapping.REPORTCOMMONWORDS.Search
                        }
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="keyword-search-icon-img"
                                alt=""
                                src={SearchIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div
                    className="display-columns-container"
                    id="dashboard-display-column"
                  >
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">
                        {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}
                      </span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems:
                          globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected:
                          globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll:
                          globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                      }}
                    />
                  </div>
                  <div className="tire-scrap-type">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">
                        {
                          globalizationMapping.REPORTCOMMONWORDS
                            .ChangeHoursAndMiles
                        }
                      </span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down"
                      name="tire scrap type"
                      variant="outlined"
                      value={toggleHM}
                      onChange={(e) => actionChanged(e.target.value)}
                    >
                      <option value="hours">
                        {globalizationMapping.MANAGEVEHICLE.HOURS}{" "}
                      </option>
                      <option value="miles">{toggleMile} </option>
                    </Select>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                    isVehicleReport={true}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div className="exclude-used-tires">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={excludeUsedTires}
                          onChange={handleExcludeUsedTires}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="data-summary-table">
                <TireInflationTable
                  tableData={fleetSurveyTableData}
                  showColumns={showColumns}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <EmailExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
        tireInflation={true}
      />

      <TireInflationReportFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default TireInflationReport;
