import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";

class AllTireReportDataSetter {
  constructor() {
    this.data = {};
    this.AllTireReportData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }
  tableHeaderOrderSetter(headCells) {
    let tableHeaders = headCells;
    // maintains the column order to be displayed (write them in the order you want to display table header)
    let headerOrder = [
      { columnNo: 1, value: "NID" },
      { columnNo: 2, value: "parentAccount" },
      { columnNo: 3, value: "Location" },
      { columnNo: 4, value: "BrandNo" },
      { columnNo: 5, value: "TireSerialNo" },
      { columnNo: 6, value: "Manufacturer" },
      { columnNo: 7, value: "Size" },
      { columnNo: 8, value: "Type" },
      { columnNo: 9, value: "Compound"},
      { columnNo: 10, value: "OTD" },
      { columnNo: 11, value: "OTDInside" },
      { columnNo: 12, value: "OTDOutside" },
      { columnNo: 13, value: "EvalNo" },
      { columnNo: 14, value: "PurchaseTD" },
      { columnNo: 15, value: "PurchaseCost" },
      { columnNo: 16, value: "CostAdjustment" },
      { columnNo: 17, value: "Status" },
      { columnNo: 18, value: "LastActivityDate" },
      { columnNo: 19, value: "CurrentTD" },
      { columnNo: 20, value: "CurrentTDInside" },
      { columnNo: 21, value: "CurrentTDOutside" },
      { columnNo: 22, value: "CurrentHours" },
      { columnNo: 23, value: "CurrentDistance" },
      { columnNo: 24, value: "CurrentUnit" },
      { columnNo: 25, value: "CurrentPosition" },
      { columnNo: 26, value: "CurrentDisposition" },
      { columnNo: 27, value: "HrsPerDistance" },
      { columnNo: 28, value: "FirstInstallDate" },
      { columnNo: 29, value: "FirstInstallUnit" },
      { columnNo: 30, value: "FirstInstallPosition" },
      { columnNo: 31, value: "FirstInstallTD" },
      { columnNo: 32, value: "FirstInstallTDInside" },
      { columnNo: 33, value: "FirstInstallTDOutside" },
      { columnNo: 34, value: "Hours" },
      { columnNo: 35, value: "Distance" },
      { columnNo: 36, value: "RepairCost" },
      { columnNo: 37, value: "NumberOfRepairs" },
      { columnNo: 38, value: "ScrapDate" },
      { columnNo: 39, value: "ScrapReason" },
      { columnNo: 40, value: "DollarsLost" },
      { columnNo: 41, value: "ScrapUnit" },
      { columnNo: 42, value: "ScrapPosition" },
      { columnNo: 43, value: "ScrapHours" },
      { columnNo: 44, value: "ScrapTD" },
    ];

    let resultHeaderCell = [];
    headerOrder.forEach((headerOrder) => {
      let found = false;
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === headerOrder.value) {
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });

    return resultHeaderCell;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
        updatedHeadCells.push(headCell);
    });
    return updatedHeadCells;
  }

  formatData(item) {
    let dateString, removedDate, installedDate;
    if (item.removedDate) {
      dateString = item.removedDate.split("/");
      removedDate = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      removedDate = null;
    }
    if (item.installedDate && item.installedDate !== "") {
      dateString = item.installedDate.split("/");
      installedDate = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      installedDate = null;
    }

    let formattedData = {
      removedDate: removedDate,
      installedDate: installedDate,
    };
    return formattedData;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {selectedColShow[headCell.value] = true;});
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      searchText = searchText.toLowerCase();

        let NID = !!item.NID;
        let ParentAccount = !!item.ParentAccount;
        let Location = !!item.Location;
        let BrandNo = !!item.BrandNo;
        let TireSerialNo = !!item.TireSerialNo;
        let Manufacturer = !!item.Manufacturer;
        let Size = !!item.Size;
        let Type = !!item.Type;
        let Compound = !!item.Compound;
        let OTD = !!item.OTD;
        let OTDInside = !!item.OTDInside;
        let OTDOutside = !!item.OTDOutside;
        let EvalNo = !!item.EvalNo;
        let PurchaseTD = !!item.PurchaseTD;
        let PurchaseCost = !!item.PurchaseCost;
        let CostAdjustment = !!item.CostAdjustment;
        let Status = !!item.Status;
        let LastActivityDate = !!item.LastActivityDate;
        let CurrentTD = !!item.CurrentTD;
        let CurrentTDInside = !!item.CurrentTDInside;
        let CurrentTDOutside = !!item.CurrentTDOutside;
        let CurrentHours = !!item.CurrentHours;
        let CurrentDistance = !!item.CurrentDistance;
        let CurrentUnit = !!item.CurrentUnit;
        let CurrentPosition = !!item.CurrentPosition;
        let CurrentDisposition = !!item.CurrentDisposition;
        let HrsPerDistance = !!item.HrsPerDistance;
        let FirstInstallDate = !!item.FirstInstallDate;
        let FirstInstallUnit = !!item.FirstInstallUnit;
        let FirstInstallPosition = !!item.FirstInstallPosition;
        let FirstInstallTD = !!item.FirstInstallTD;
        let FirstInstallTDInside = !!item.FirstInstallTDInside;
        let FirstInstallTDOutside = !!item.FirstInstallTDOutside;
        let Hours = !!item.Hours;
        let Distance = !!item.Distance;
        let RepairCost = !!item.RepairCost;
        let NumberOfRepairs = !!item.NumberOfRepairs;
        let ScrapDate = !!item.ScrapDate;
        let ScrapReason = !!item.ScrapReason;
        let DollarsLost = !!item.DollarsLost;
        let ScrapUnit = !!item.ScrapUnit;
        let ScrapPosition = !!item.ScrapPosition;
        let ScrapHours = !!item.ScrapHours;
        let ScrapTD = !!item.ScrapTD;


      return (
        (NID? item.NID.toString().toLowerCase().includes(searchText): false) ||
        (ParentAccount? item.ParentAccount.toString().toLowerCase().includes(searchText): false) ||
        (Location? item.Location.toString().toLowerCase().includes(searchText): false) ||
        (BrandNo? item.BrandNo.toString().toLowerCase().includes(searchText): false) ||
        (TireSerialNo? item.TireSerialNo.toString().toLowerCase().includes(searchText): false) ||
        (Manufacturer? item.Manufacturer.toString().toLowerCase().includes(searchText): false) ||
        (Size? item.Size.toString().toLowerCase().includes(searchText): false) ||
        (Type? item.Type.toString().toLowerCase().includes(searchText): false) ||
        (Compound? item.Compound.toString().toLowerCase().includes(searchText): false) ||
        (OTD? item.OTD.toString().toLowerCase().includes(searchText): false) ||
        (OTDInside? item.OTDInside.toString().toLowerCase().includes(searchText): false) ||
        (OTDOutside? item.OTDOutside.toString().toLowerCase().includes(searchText): false) ||
        (EvalNo? item.EvalNo.toString().toLowerCase().includes(searchText): false) ||
        (PurchaseTD? item.PurchaseTD.toString().toLowerCase().includes(searchText): false) ||
        (PurchaseCost? item.PurchaseCost.toString().toLowerCase().includes(searchText): false) ||
        (CostAdjustment? item.CostAdjustment.toString().toLowerCase().includes(searchText): false) ||
        (Status? item.Status.toString().toLowerCase().includes(searchText): false) ||
        (LastActivityDate? item.LastActivityDate.toString().toLowerCase().includes(searchText): false) ||
        (CurrentTD? item.CurrentTD.toString().toLowerCase().includes(searchText): false) ||
        (CurrentTDInside? item.CurrentTDInside.toString().toLowerCase().includes(searchText): false) ||
        (CurrentTDOutside? item.CurrentTDOutside.toString().toLowerCase().includes(searchText): false) ||
        (CurrentHours? item.CurrentHours.toString().toLowerCase().includes(searchText): false) ||
        (CurrentDistance? item.CurrentDistance.toString().toLowerCase().includes(searchText): false) ||
        (CurrentUnit? item.CurrentUnit.toString().toLowerCase().includes(searchText): false) ||
        (CurrentPosition? item.CurrentPosition.toString().toLowerCase().includes(searchText): false) ||
        (CurrentDisposition? item.CurrentDisposition.toString().toLowerCase().includes(searchText): false) ||
        (HrsPerDistance? item.HrsPerDistance.toString().toLowerCase().includes(searchText): false) ||
        (FirstInstallDate? item.FirstInstallDate.toString().toLowerCase().includes(searchText): false) ||
        (FirstInstallUnit? item.FirstInstallUnit.toString().toLowerCase().includes(searchText): false) ||
        (FirstInstallPosition? item.FirstInstallPosition.toString().toLowerCase().includes(searchText): false) ||
        (FirstInstallTD? item.FirstInstallTD.toString().toLowerCase().includes(searchText): false) ||
        (FirstInstallTDInside? item.FirstInstallTDInside.toString().toLowerCase().includes(searchText): false) ||
        (FirstInstallTDOutside? item.FirstInstallTDOutside.toString().toLowerCase().includes(searchText): false) ||
        (Hours? item.Hours.toString().toLowerCase().includes(searchText): false) ||
        (Distance? item.Distance.toString().toLowerCase().includes(searchText): false) ||
        (RepairCost? item.RepairCost.toString().toLowerCase().includes(searchText): false) ||
        (NumberOfRepairs? item.NumberOfRepairs.toString().toLowerCase().includes(searchText): false) ||
        (ScrapDate? item.ScrapDate.toString().toLowerCase().includes(searchText): false) ||
        (ScrapReason? item.ScrapReason.toString().toLowerCase().includes(searchText): false) ||
        (DollarsLost? item.DollarsLost.toString().toLowerCase().includes(searchText): false) ||
        (ScrapUnit? item.ScrapUnit.toString().toLowerCase().includes(searchText): false) ||
        (ScrapPosition? item.ScrapPosition.toString().toLowerCase().includes(searchText): false) ||
        (ScrapHours? item.ScrapHours.toString().toLowerCase().includes(searchText): false) ||
        (ScrapTD? item.ScrapTD.toString().toLowerCase().includes(searchText): false)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          ) &&
          rowData[category.value]
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;

    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {

    let categoryKeys = Object.keys(appliedFilter);

    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */

      this.AllTireReportData.push({
        Key: index,
        NID: item.nid ? item.nid : 0,
        ParentAccount: item.parentAccount ? item.parentAccount : "",
        Location: item.location ? item.location : "",
        BrandNo: item.brandNo ? item.brandNo : "",
        TireSerialNo: item.tireSerialNo ? item.tireSerialNo : "",
        Manufacturer: item.manufacturer ? item.manufacturer : "",
        Size: item.size ? item.size : "",
        Type: item.type ? item.type : "",
        Compound: item.compound ? item.compound : "",
        OTD: item.otd ? item.otd : 0,
        OTDInside: item.otdInside ? item.otdInside : 0,
        OTDOutside: item.otdOutside ? item.otdOutside : 0,
        EvalNo: item.evalNo ? item.evalNo : "",
        PurchaseTD: item.purchaseTD ? item.purchaseTD : 0,
        PurchaseCost: item.purchaseCost ? item.purchaseCost : 0,
        CostAdjustment: item.costAdjustment ? item.costAdjustment : 0,
        Status: item.status ? item.status : "",
        LastActivityDate: item.lastActivityDate ? this.commonUtils.formatISODate(item.lastActivityDate) : "",
        CurrentTD: item.currentTD ? item.currentTD : 0,
        CurrentTDInside: item.currentTDInside ? item.currentTDInside : 0,
        CurrentTDOutside: item.currentTDOutside ? item.currentTDOutside : 0,
        CurrentHours: item.currentHours ? item.currentHours : 0,
        CurrentDistance: item.currentDistance ? item.currentDistance : 0,
        CurrentUnit: item.currentUnit ? item.currentUnit : 0,
        CurrentPosition: item.currentPosition ? item.currentPosition : "",
        CurrentDisposition: item.currentDisposition ? item.currentDisposition : "",
        HrsPerDistance: item.hrsPerDistance ? item.hrsPerDistance : 0,
        FirstInstallDate: item.firstInstallDate ? this.commonUtils.formatISODate(item.firstInstallDate) : "",
        FirstInstallUnit: item.firstInstallUnit ? item.firstInstallUnit : "",
        FirstInstallPosition: item.firstInstallPosition ? item.firstInstallPosition : "",
        FirstInstallTD: item.firstInstallTD ? item.firstInstallTD : 0,
        FirstInstallTDInside: item.firstInstallTDInside ? item.firstInstallTDInside : 0,
        FirstInstallTDOutside: item.firstInstallTDOutside ? item.firstInstallTDOutside : 0,
        Hours: item.hours ? item.hours : 0,
        Distance: item.distance ? item.distance : 0,
        RepairCost: item.repairCost ? item.repairCost : 0,
        NumberOfRepairs: item.numberOfRepairs ? item.numberOfRepairs : 0,
        ScrapDate: item.scrapDate ? this.commonUtils.formatISODate(item.scrapDate) : "",
        ScrapReason: item.scrapReason ? item.scrapReason : "",
        DollarsLost: item.dollarsLost ? item.dollarsLost : 0,
        ScrapUnit: item.scrapUnit ? item.scrapUnit : "",
        ScrapPosition: item.scrapPosition ? item.scrapPosition : "",
        ScrapHours: item.scrapHours ? item.scrapHours : "",
        ScrapTD: item.scrapTD ? item.scrapTD : "",

      });
    });
    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || 
        item.dataType === "percentagedecimal" ? true : false
      });
    });
    
    let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells);
    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: JSON.parse(JSON.stringify(this.AllTireReportData)),
      headCells: orderedHeadCells,
      selectedColShow: selectedColShow,
    };
  }
}

export default AllTireReportDataSetter;
