import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
// eslint-disable-next-line no-unused-vars

const TireForecastByTireTypeGraph = ({ tireForecastByTireTypeGraphData }) => {
  const commonUtils = new CommonUtils();
  const [chartInfoData, setChartInfoData] = useState({});
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString =
    globalizationHeaders["tire-forecast-type-x"];
  options.scales.yAxes[0].scaleLabel.labelString =
    globalizationHeaders["tire-forecast-type-y"];
  options.title.text = globalizationHeaders["tire-forecast-type-title"];
  options.legend.display = true;
  options.plugins.datalabels.rotation = 90;

  const chart = () => {
    setChartInfoData({});
    if (tireForecastByTireTypeGraphData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();

      let graphDataProjectedSum = {},
        graphDataActualSum = {},
        graphDataProjectedCount = {},
        graphDataActualCount = {},
        graphDataActualAvg = {},
        graphDataProjectedAvg = {};

      // setting the graph data sum and average objects
      tireForecastByTireTypeGraphData.forEach((item) => {
        graphDataProjectedSum[item.TypeName] = 0;
        graphDataActualSum[item.TypeName] = 0;
        graphDataProjectedCount[item.TypeName] = 0;
        graphDataActualCount[item.TypeName] = 0;
        graphDataActualAvg[item.TypeName] = 0;
        graphDataProjectedAvg[item.TypeName] = 0;
      });

      // setting the graph data labels
      let graphDataProjectedLabels = "Projected Hours";
      let graphDataActualLabels = "Actual Hours";
      let graphDataLabels = Object.keys(graphDataActualCount);

      // calculates the sum of the graph data
      tireForecastByTireTypeGraphData.forEach((item) => {
        graphDataProjectedSum[item.TypeName] += item.ProjectedHours;
        graphDataProjectedCount[item.TypeName]++;

        graphDataActualSum[item.TypeName] += item.CurrentHours;
        graphDataActualCount[item.TypeName]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((type) => {
        graphDataProjectedAvg[type] = Math.round(
          graphDataProjectedSum[type] / graphDataProjectedCount[type]
        );
      });
      graphDataLabels.forEach((type) => {
        graphDataActualAvg[type] = Math.round(
          graphDataActualSum[type] / graphDataActualCount[type]
        );
      });

      // setting the graph data values
      let graphDataActualValues = Object.values(graphDataActualAvg);
      let graphDataProjectedValues = Object.values(graphDataProjectedAvg);


      setChartInfoData({
        labels: graphDataLabels,
        datasets: [
          {
            label: graphDataActualLabels,
            backgroundColor: colorCodes[0],
            fill: true,
            hoverBorderColor: borderColor[0],
            hoverBorderWidth: "3",
            data: graphDataActualValues,
          },
          {
            label: graphDataProjectedLabels,
            backgroundColor: colorCodes[1],
            fill: true,
            hoverBorderColor: borderColor[1],
            hoverBorderWidth: "3",
            data: graphDataProjectedValues,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };
  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tireForecastByTireTypeGraphData]);

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && (
          <Bar data={chartInfoData} height={120} options={options} />
        )}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default TireForecastByTireTypeGraph;
