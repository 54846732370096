import React from 'react'
import './FleetDataTable.css'
import { Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound"
import Globalization from '../../../../../globalization';


const FleetDataTable = ({ fleetDataAnalysisTableData }) => {
    const globalizationMapping = new Globalization()
    const itemsPerPage = 5;
    const [pageVal, setPageVal] = React.useState(1);
    const noOfPages = Math.ceil(Object.keys(fleetDataAnalysisTableData.rowsData).length / itemsPerPage)
    const handleChange = (event, newPage) => {
        setPageVal(newPage);
    };

    const firstRecord = Number((pageVal - 1) * itemsPerPage + 1)
    const lastRecord = Number(pageVal * itemsPerPage) < Object.keys(fleetDataAnalysisTableData.rowsData).length ? Number(pageVal * itemsPerPage) : Object.keys(fleetDataAnalysisTableData.rowsData).length
    const inspectionDateToday = new Date().toLocaleDateString();
    return (

        <div className="fleet-data-analysis-container">
            {fleetDataAnalysisTableData.rowsData !== null && fleetDataAnalysisTableData.rowsData !== undefined && fleetDataAnalysisTableData.rowsData.length > 0 &&
                (Object.keys(fleetDataAnalysisTableData.rowsData)).slice(((pageVal - 1) * (itemsPerPage)), ((pageVal) * (itemsPerPage))).map((key, index) => {
                    return (
                        <div key={index} className="vehicle-container" style={{ paddingTop: 0, paddingBottom: 0, marginBottom: 60 }}>
                            {fleetDataAnalysisTableData.rowsData[key].length > 0 &&
                                <div>
                                    <Grid container className="grid-vehicle-container">
                                        <Grid item xs={6} sm={3}>
                                            <div className="heading-column-data">
                                                {globalizationMapping.REPORTCOMMONWORDS.RecordNo}
                                            </div>
                                            <div className="grid-vehicle-container-heading-column-data">0{fleetDataAnalysisTableData.vehicleData[key].Key + 1}</div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="heading-column-data">{globalizationMapping.REPORTTABLE.ACCOUNTNAME}</div>
                                            <div className="grid-vehicle-container-heading-column-data">{fleetDataAnalysisTableData.vehicleData[key].ParentAccountName}</div>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <div className="heading-column-data">{globalizationMapping.REPORTTABLE.Location}</div>
                                            <div className="grid-vehicle-container-heading-column-data">{fleetDataAnalysisTableData.vehicleData[key].LocationName}</div>
                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                            <div className="heading-column-data">{globalizationMapping.REPORTTABLE.INSPID}</div>
                                            <div className="grid-vehicle-container-heading-column-data">{fleetDataAnalysisTableData.vehicleData[key].InspectionName}</div>
                                        </Grid>
                                    </Grid>

                                    {/* DESKTOP VIEW */}
                                    <div className="desktop-view-fleet-data" >
                                        <Grid spacing={3} container className="fleet-data-vehicle-template">
                                            <Grid container spacing={3} xs={12} sm={12} className="vehicle-details-container">
                                                <Grid container xs={12} sm={12} className="vehicle-basic-details outer-spacing">
                                                    <Grid container xs={12} className="vehicle-header-info"></Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header">{globalizationMapping.REPORTTABLE.VehicleName}</div>
                                                        <div className="heading-column-data-info" >{fleetDataAnalysisTableData.vehicleData[key].VehicleId}</div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header">{globalizationMapping.REPORTTABLE.ASSETNUMBER}</div>
                                                        <div className="heading-column-data-info" >{fleetDataAnalysisTableData.vehicleData[key].AssetNumber}</div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header" >{globalizationMapping.REPORTCOMMONWORDS.MakeModel}</div>
                                                        <div className="heading-column-data-info" >{fleetDataAnalysisTableData.vehicleData[key].Manufacturer}/{fleetDataAnalysisTableData.vehicleData[key].ModelName}</div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header">{globalizationMapping.REPORTCOMMONWORDS.LastHour}</div>
                                                        <div className="heading-column-data-info" >{fleetDataAnalysisTableData.vehicleData[key].CurrentHours}</div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header">{globalizationMapping.INSPECT.CURRENTHOURS}</div>
                                                        <div className="heading-column-data-info" ></div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header">{globalizationMapping.REPORTCOMMONWORDS.LastMiles}</div>
                                                        <div className="heading-column-data-info" >{fleetDataAnalysisTableData.vehicleData[key].CurrentMiles}</div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header">{globalizationMapping.REPORTTABLE.RcmdPressure}</div>
                                                        <div className="heading-column-data-info" >{fleetDataAnalysisTableData.vehicleData[key].RecommendedPressure}</div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header">{globalizationMapping.MANAGEPARENTACCOUNT.LASTINSPECTIONDATE}</div>
                                                        <div className="heading-column-data-info" >{fleetDataAnalysisTableData.vehicleData[key].UpdatedDate}</div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header">{globalizationMapping.INSPECT.INSPECTIONDATE}</div>
                                                        <div className="heading-column-data-info" >{inspectionDateToday}</div>
                                                    </Grid>
                                                    <Grid item xs={6} sm={2}>
                                                        <div className="heading-column-data-header">{globalizationMapping.FORMS.RIM.RIMFORM.CURRMILES}</div>
                                                        <div className="heading-column-data-info" ></div>
                                                    </Grid>
                                                </Grid>
                                                <Grid container xs={12} sm={12} className="vehicle-basic-details inner-spacing">

                                                    <Grid item xs={12} sm={12}>
                                                        <div className="heading-column-data-header">{globalizationMapping.REPORTCOMMONWORDS.VehicleComments}</div>
                                                        <div className="heading-column-data-info" >{fleetDataAnalysisTableData.vehicleData[key].VehicleComments}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container sm={12} justify="flex-end" className="vehicle-basic-details fleet-data-survey-template" style={{ fontSize: 15 }}>
                                                <Grid item sm={1} xs={2}>
                                                    <div className="small-heading-column-data" style={{ paddingLeft: 0 }}>{globalizationMapping.REPORTCOMMONWORDS.CurrentNS}</div>
                                                </Grid>
                                                <Grid item sm={1} xs={2}>
                                                    <div className="small-heading-column-data end-align" style={{ paddingRight: 10 }}>Hot/Cold</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        {fleetDataAnalysisTableData.rowsData[key].map((rowDataItem, index) => {
                                            
                                            return (
                                                <div key={index}>
                                                    <Grid spacing={3} container className="fleet-data-analysis-template" style={{ paddingBottom: 20 }}>

                                                        <Grid container sm={12} className="vehicle-basic-details fleet-data-survey-template">
                                                            <Grid item sm={10} xs={8}>
                                                                <div className="xl-heading-column-data">{rowDataItem.WheelPosition}</div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container sm={12} className="vehicle-basic-details fleet-data-survey-template">

                                                            <Grid item xs={6} sm={3}>
                                                                <div className="small-heading-column-data underline-style left-spaceing">{globalizationMapping.FLEETSURVEY.SIZE}  : <span className="fleet-data-text">{rowDataItem.SizeName}</span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={3}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.FLEETSURVEY.MANUFACTURER} : <span className="fleet-data-text">{rowDataItem.ManufacturerName}</span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={2}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTTABLE.TYPE} : <span className="fleet-data-text">{rowDataItem.TypeName}</span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={2}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTTABLE.RATING} : <span className="fleet-data-text">{rowDataItem.RatingName}</span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={1}>
                                                                <div className="small-heading-column-data underline-style">(O) <span className="fleet-data-text"></span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={1}>
                                                                <div className="small-heading-column-data underline-style"><span className="fleet-data-text"></span> (H)</div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container sm={12} className="vehicle-basic-details fleet-data-survey-template">

                                                            <Grid item xs={6} sm={3}>
                                                                <div className="small-heading-column-data underline-style left-spaceing">{globalizationMapping.FLEETSURVEY.BRANDNUMBER}  : <span className="fleet-data-text">{rowDataItem.BrandNumber}</span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={3}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.FLEETSURVEY.SERIALNUMBER} : <span className="fleet-data-text">  {rowDataItem.SerialNumber}</span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={2}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTTABLE.TOTALHOURS} : <span className="fleet-data-text">{rowDataItem.TotalHours}</span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={2}>
                                                                <div className="small-heading-column-data underline-style"> <span className="fleet-data-text"></span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={1}>
                                                                <div className="small-heading-column-data underline-style">(C) <span className="fleet-data-text"></span></div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={1}>
                                                                <div className="small-heading-column-data underline-style"><span className="fleet-data-text"></span> (C)</div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container sm={12} className="vehicle-basic-details fleet-data-survey-template">
                                                            <Grid item sm={10}>
                                                                <div className="small-heading-column-data underline-style left-spacing">{globalizationMapping.REPORTTABLE.COMMENTS}  : <span className="fleet-data-text">{rowDataItem.InspectionComments}</span></div>
                                                            </Grid>
                                                            <Grid item sm={2}>
                                                            <div className="small-heading-column-data underline-style">(I) <span className="fleet-data-text"></span></div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>


                                    {/* MOBILE VIEW */}
                                    <div className="mobile-view-fleet-data">

                                        <Grid spacing={3} container className="">

                                            <Grid container spacing={3} sm={12} className="vehicle-details-container">

                                                <Grid container spacing={3} xs={12} sm={12} className="vehicle-basic-details outer-spacing">
                                                    <Grid container xs={12} className="vehicle-header-info"></Grid>
                                                    <Grid item xs={6} >
                                                        <div className="heading-column-data">{globalizationMapping.REPORTTABLE.VehicleName} </div>
                                                        <div>{fleetDataAnalysisTableData.vehicleData[key].VehicleId}</div>
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <div className="heading-column-data">{globalizationMapping.REPORTTABLE.MakeModel} </div>
                                                        <div>{fleetDataAnalysisTableData.vehicleData[key].ModelName}</div>
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <div className="heading-column-data">{globalizationMapping.INSPECTNREMOVETIRE.CURRENTHOURS} </div>
                                                        <div>{fleetDataAnalysisTableData.vehicleData[key].CurrentHours}</div>
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <div className="heading-column-data">{globalizationMapping.REPORTCOMMONWORDS.LastMiles}</div>
                                                        <div>{fleetDataAnalysisTableData.vehicleData[key].LastMiles}</div>
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <div className="heading-column-data">{globalizationMapping.FORMS.RIM.RIMFORM.CURRENTHOURS}</div>
                                                        <div>{fleetDataAnalysisTableData.vehicleData[key].CurrentMiles}</div>
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <div className="heading-column-data">{globalizationMapping.REPORTTABLE.RcmdPressure}</div>
                                                        <div>{fleetDataAnalysisTableData.vehicleData[key].RecommendedPressure}</div>
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <div className="heading-column-data">{globalizationMapping.INSPECT.LASTINSPECTIONDATE}</div>
                                                        <div>{fleetDataAnalysisTableData.vehicleData[key].UpdatedDate}</div>
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <div className="heading-column-data">{globalizationMapping.REPORTCOMMONWORDS.VehicleComments} </div>
                                                        <div>{fleetDataAnalysisTableData.vehicleData[key].VehicleComments}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {fleetDataAnalysisTableData.rowsData[key].map((rowDataItem, index) => {
                                            return (
                                                <div key={index}>
                                                    <Grid spacing={3} container className="fleet-data-analysis-template">

                                                        <Grid container sm={12} className="vehicle-basic-details">
                                                            <Grid item sm={10} xs={8}>
                                                                <div className="xl-heading-column-data">{rowDataItem.WheelPosition}</div>
                                                            </Grid>
                                                            <Grid item sm={1} xs={2}>
                                                                <div className="small-heading-column-data">{globalizationMapping.REPORTCOMMONWORDS.CurrentNS}</div>
                                                            </Grid>
                                                            <Grid item sm={1} xs={2}>
                                                                <div className="small-heading-column-data end-align">{globalizationMapping.REPORTCOMMONWORDS.HotCold}</div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container sm={12} className="vehicle-basic-details">
                                                            <Grid item xs={8}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTTABLE.TYPE} <span className="fleet-data-text">{rowDataItem.TypeName}</span></div>
                                                            </Grid>

                                                            <Grid item xs={2}>
                                                                <div className="small-heading-column-data underline-style">(O) <span className="fleet-data-text"></span></div>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <div className="small-heading-column-data underline-style"><span className="fleet-data-text"></span> (H)</div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container xs={12} className="vehicle-basic-details">
                                                            <Grid item xs={8}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTCOMMONWORDS.LAstNS}<span className="fleet-data-text"></span></div>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <div className="small-heading-column-data underline-style">(C) <span className="fleet-data-text"></span></div>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <div className="small-heading-column-data underline-style"><span className="fleet-data-text"></span> (C)</div>
                                                            </Grid>
                                                        </Grid>


                                                        <Grid container xs={12} className="vehicle-basic-details">
                                                            <Grid item xs={4} >
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.FLEETSURVEY.BRANDNUMBER}  <span className="fleet-data-text">{rowDataItem.BrandNumber}</span></div>
                                                            </Grid>
                                                            <Grid item xs={8} >
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.FLEETSURVEY.SERIALNUMBER}  <span className="fleet-data-text">{rowDataItem.SerialNumber}</span></div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container sm={12} className="vehicle-basic-details">
                                                            <Grid item xs={4}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.FLEETSURVEY.SIZE}  <span className="fleet-data-text">{rowDataItem.SizeName}</span></div>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.FLEETSURVEY.MANUFACTURER}  <span className="fleet-data-text">{rowDataItem.ManufacturerName}</span></div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container xs={12} className="vehicle-basic-details">
                                                            <Grid item xs={6}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTTABLE.TOTALHOURS}  <span className="fleet-data-text">{rowDataItem.TotalHours}</span></div>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTCOMMONWORDS.LastPSIRec} <span className="fleet-data-text"></span></div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container xs={12} className="vehicle-basic-details">
                                                            <Grid item xs={12}>
                                                                <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTTABLE.COMMENTS}  <span className="fleet-data-text">{rowDataItem.InspectionComments}</span></div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>

                                </div>
                            }
                        </div>
                    )
                })}

            {fleetDataAnalysisTableData.rowsData.length === 0 &&
                <div className="no-data-container">
                    <NoDataFound noDataType="table"></NoDataFound>
               </div>
            }
            {fleetDataAnalysisTableData.rowsData !== null && fleetDataAnalysisTableData.rowsData !== undefined && fleetDataAnalysisTableData.rowsData.length > 0 &&
                <React.Fragment>
                    <div className="record-data">Showing {firstRecord} to {lastRecord} of {Object.keys(fleetDataAnalysisTableData.rowsData).length} records</div>
                    <Pagination
                        count={noOfPages}
                        page={pageVal}
                        onChange={handleChange}
                        defaultPage={1}
                        shape="rounded"
                        className="pagination-vehicle-report"
                    />
                </React.Fragment>
            }

        </div>
    )

}

export default FleetDataTable