/* eslint-disable no-use-before-define */
/*>>>>>>>>> to be deleted soon for now it is on hold for the said on hold feature */
import React, { useContext } from 'react';
import { Checkbox, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import './FilterTextFields.css'
import { filterContext } from '../../../../scenes/manage-vehicle/components/list-vehicles/main-container/ManageVehicleCotext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterTextField = ({ filterOptions, filterOptionsSelected, refArray }) => {

    const holdFilters = useContext(filterContext)

    const holdFilterObj = {
        "categories": [
            {
                "name": "Location",
                "value": "Location"
            },
            {
                "name": "Make",
                "value": "MakeName"
            },
            {
                "name": "Model",
                "value": "ModelName"
            },
            {
                "name": "Type",
                "value": "TypeName"
            }
        ],
        "categoryData": {
            "Location": holdFilters.holdFilters?.Location?.map((ele) => {
                return {
                    category: 'Location', value: ele
                }
            }) || [],
            "MakeName": holdFilters.holdFilters?.MakeName?.map((ele) => {
                return {
                    category: 'Make', value: ele
                }
            }) || [],
            "ModelName": holdFilters.holdFilters.ModelName?.map((ele) => {
                return {
                    category: 'Model', value: ele
                }
            }) || [],
            "TypeName": holdFilters.holdFilters?.TypeName?.map((ele) => {
                return {
                    category: 'Type', value: ele
                }
            }) || []
        }
    }

    const defaultProps = {
        getOptionLabel: (option) => option.value,
    };
    return (
        <Grid container>
            {filterOptions !== '' && filterOptions !== null && filterOptions.categories.map((category, index) => {
                return (
                    <Grid key={category.name} item sm={6} xs={12}>
                        <div>
                            <div className="filter-options-container padding-one">
                                <div className="checkbox-header">
                                    <div className="checkbox-title">
                                        {category.name}
                                    </div>
                                    <Autocomplete
                                        size="small"
                                        ref={refArray.current[index]}
                                        multiple
                                        id={category.name}
                                        {...defaultProps}
                                        limitTags={1}
                                        defaultValue={holdFilterObj?.categoryData[category.value]}
                                        autoComplete={true}
                                        options={filterOptions.categoryData[category.value]}
                                        style={{ width: "100%" }}
                                        disableCloseOnSelect={true}
                                        getOptionLabel={(option) => (option.value)}
                                        getOptionSelected={(option, value) => option.value === value.value}
                                        onChange={(event, selectedItems) => { filterOptionsSelected(event, selectedItems, category.value) }}
                                        renderOption={(option, { selected }) => {
                                            let isFound;
                                            if (Object.keys(holdFilters?.holdFilters).length !== 0) {
                                                isFound = holdFilters?.holdFilters[category.value]?.some(element => element === option.value)
                                            }
                                            if (isFound) selected = true;
                                            return (<React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.value}
                                            </React.Fragment>)
                                        }}
                                        renderInput={(params) => {
                                            return (
                                                <TextField {...params} variant="outlined" inputProps={{...params.inputProps, maxLength: 50 }}/>
                                            )
                                        }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>
                )
            })
            }
        </Grid>

    );
}

export default FilterTextField
