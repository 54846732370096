import { TextField, FormHelperText } from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect, useState } from "react";
import CalendarMonthIcon from '@material-ui/icons/Today';
import "../../../../../common-components/header/components/change-modal/change-modal.css";
import moment from "moment";
import CommonUtils from "../../../../../services/utils";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import Globalization from "../../../../../globalization";

const UpdateHoursMiles = (props) => {
    const userRef = JSON.parse(
        localStorage.getItem("accountInfouserMeasurementSystemValue")
    );
    const [surveyDate, setSurveyDate] = useState(new Date());
    const [updateHour, setUpdateHour] = useState("");
    const [updateMiles, setUpdateMiles] = useState("");
    const [lastDate, setLastDate] = useState("");
    const [lastRecordedHours, setLastRecordedHours] = useState(0);
    const [lastRecordedMiles, setLastRecordedMiles] = useState(0);
    const [errorsDistance, setErrorsDistance] = useState(false);
    const [errorsHours, setErrorsHours] = useState(false);
    const commonUtils = new CommonUtils();
    const headerApi = new HeaderApi();
    const globalizationMapping = new Globalization();

    useEffect(() => {
        if (props.vehicleDetails.trackingMethod === 1) {
            setUpdateMiles(props.vehicleDetails.currentMiles);
        } else {
            if (props.vehicleDetails.trackingMethod === 2) {
                setUpdateHour(props.vehicleDetails.currentHours);
            }
        }
        if (sessionStorage.userLanguage === "en-uk") {
            setLastDate(
                moment(props.vehicleDetails.updatedDate).format('DD/MM/YYYY')
            );
        } else {
            setLastDate(
                moment(props.vehicleDetails.updatedDate).format('MM/DD/YYYY')
            );
        }
        setLastRecordedHours(props.vehicleDetails.currentHours);
        setLastRecordedMiles(props.vehicleDetails.currentMiles);
        // setTimeout(() => {
        //   setShowSpinner(false);
        // }, 3500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tireDetails, props.vehicleDetails]);

    const saveChanges = () => {
        const reqObj = {
            inspectionDate: surveyDate,
            currentHours: updateHour,
            currentMiles: updateMiles,
            action: "UpdateHoursEditTire",
            vehicleId: props.tireDetails.vehicleId,
            locationId: props.tireDetails.locationId,
            parentAccountId: props.vehicleDetails.parentAccountId,
        };
        headerApi.updateHoursForVehicle(reqObj).then((data) => {
            if (data.message) {
                let arr = [];
                let visitedVehicles = sessionStorage.getItem.visitedVehicles;
                if (visitedVehicles?.length) {
                    arr = sessionStorage.getItem("visitedVehicles");
                }
                arr.push(props.vehicleDetails.vehicleId);
                sessionStorage.setItem("visitedVehicles", JSON.stringify(arr));
                window.location.reload();
            }
        });
    };
    const onDateChange = (value) => {
        setSurveyDate(value);
    };
    function onChangeListener(from, value) {
        if (from === "hours") {
            setUpdateHour(value);
            setErrorsHours(false);
        } else {
            if (from === "miles") {
                setUpdateMiles(value);
                setErrorsDistance(false);
            }
        }
    }
    const showError = (from) => {
        if (from === "miles") {
            return (
                (updateMiles !== "" &&
                    updateMiles !== null &&
                    parseFloat(updateMiles) < parseFloat(lastRecordedMiles)) ||
                errorsDistance
            );
        } else {
            return (
                (updateHour !== "" &&
                    updateHour !== null &&
                    parseFloat(updateHour) < parseFloat(lastRecordedHours)) ||
                errorsHours
            );
        }
    };

    const isHoursInputInvalid = (inputHrs, surveyDate) => {

        //need to format first to YYYY-MM-DD and remove time
        let updatedDateNoTime = new Date(props.vehicleDetails.updatedDate).toISOString().split('T')[0];
        let surveyDateNoTime = new Date(surveyDate).toISOString().split('T')[0];
        let updateHrs = Number(inputHrs) - lastRecordedHours;

        // Calculate the difference in milliseconds
        let diff = new Date(surveyDateNoTime) - new Date(updatedDateNoTime);

        // Convert to hour
        let actualHrs = diff / 1000 / 60 / 60;

          // Check if inputHrs is greater than actualHrs
          return updateHrs > Number(actualHrs);
    }

    return (
        <div className="full-container">
            <div
                className={
                    props.vehicleDetails.trackingMethod < 3
                        ? "header-change-modal-title-single center-modal"
                        : "header-change-modal-title-double center-modal"
                }
            >
                <button
                    aria-hidden="true"
                    onClick={props.onClose}
                    className="bootbox-close-button close"
                    type="button"
                >
                    <img
                        src={require("../../../../../assets/close-icon.svg")}
                        className="modal-close-icon"
                        alt="close-icon"
                    />
                </button>
                <div
                    style={{ marginLeft: "1.3em" }}
                    className="header-change-modal-title"
                >
                    {/* Update Hours and Miles */}
                    {globalizationMapping.HEADER.UPDATEHOURSMILES}
                </div>
                <div className="bootbox-body">
                    {props.vehicleDetails.trackingMethod === 3 && (
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0em 0.7em", }}>
                            <div style={{ padding: "1em 0.7em" }}>
                                <div>
                                    <div className="sub-heading1-UHM">
                                        {globalizationMapping.INSPECT.LASTRECOREDEDDATE}
                                    </div>
                                    <div className="sub-heading1-UHM" style={{ color: "#666" }}>
                                        {lastDate}
                                    </div>
                                </div>
                                <div style={{ marginTop: "2em" }}>
                                    <div className="sub-heading2-UHM">
                                        {globalizationMapping.INSPECT.SURVEYDATE}
                                        <span className="required-mark">*</span>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker style={{ border: "1px solid #aaa", }}
                                            keyboardIcon={<CalendarMonthIcon />}
                                            value={surveyDate} onChange={onDateChange}
                                            format={commonUtils.getDateFormat()} helperText=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div style={{ padding: "1em 0.7em" }}>
                                <div>
                                    <div className="sub-heading1-UHM">
                                        {globalizationMapping.INSPECT.LASTRECORDEDHOURS}
                                    </div>
                                    <div className="sub-heading1-UHM" style={{ color: "#000" }}>
                                        {lastRecordedHours}
                                    </div>
                                </div>
                                <div style={{ marginTop: "2em" }}>
                                    <div className="sub-heading2-UHM">
                                        {globalizationMapping.INSPECT.UPDATEHOURS}
                                        <span className="required-mark">*</span>
                                    </div>
                                    <TextField
                                        className="datepicker-popup"
                                        variant="outlined"
                                        value={updateHour}
                                        onChange={(e) => onChangeListener("hours", commonUtils.minmaxInt(e.target.value, 0, 999999))}
                                        disabled={props.vehicleDetails.trackingMethod === 2}
                                        placeholder="Enter Hour"
                                        helperText=""
                                        type="number"
                                        inputProps={{ maxLength: 50 }}
                                    />

                                    {showError("hours") && (
                                        <FormHelperText>
                                            {globalizationMapping.INSPECT.ERRMSGHOURS}
                                        </FormHelperText>
                                    )}
                                    {isHoursInputInvalid(updateHour,surveyDate) && (
                                        <FormHelperText>
                                            Input Hours must less than 24 hours per day for the period 
                                        </FormHelperText>)}
                                </div>
                            </div>
                            <div style={{ padding: "1em 0.7em" }}>
                                <div>
                                    <div className="sub-heading1-UHM">
                                        {userRef.toLowerCase() === "metric"
                                            ? globalizationMapping.INSPECT.LASTRECORDEDKILOMETERS
                                            : globalizationMapping.INSPECT.LASTRECORDEDMILES}
                                    </div>
                                    <div className="sub-heading1-UHM" style={{ color: "#000" }}>
                                        {lastRecordedMiles}
                                    </div>
                                </div>
                                <div style={{ marginTop: "2em" }}>
                                    <div className="sub-heading2-UHM">
                                        {userRef.toLowerCase() !== "metric"
                                            ? globalizationMapping.INSPECT.UPDATEMILES
                                            : globalizationMapping.INSPECT.UPDATEKILOMETERS}
                                        <span className="required-mark">*</span>
                                    </div>
                                    <TextField
                                        className="datepicker-popup"
                                        variant="outlined"
                                        value={updateMiles}
                                        onChange={(e) => onChangeListener("miles", commonUtils.minmaxInt(e.target.value, 0, 999999))}
                                        disabled={props.vehicleDetails.trackingMethod === 1}
                                        placeholder="Enter Miles"
                                        helperText=""
                                        type="number"
                                        inputProps={{ maxLength: 50 }}
                                    />
                                    {showError("miles") && (
                                        <FormHelperText>
                                            {globalizationMapping.INSPECT.ERRMSGMILES}
                                        </FormHelperText>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {props.vehicleDetails.trackingMethod === 2 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0em 1.3em",
                            }}
                        >
                            <div style={{ padding: "1em 0.7em" }}>
                                <div>
                                    <div className="sub-heading1-UHM">
                                        {/* Last Recorded Date */}
                                        {globalizationMapping.INSPECT.LASTRECOREDEDDATE}
                                    </div>
                                    <div className="sub-heading1-UHM" style={{ color: "#666" }}>
                                        {lastDate}
                                    </div>
                                </div>
                                <div style={{ marginTop: "2em" }}>
                                    <div className="sub-heading2-UHM">
                                        {globalizationMapping.INSPECT.SURVEYDATE}
                                        <span className="required-mark">*</span>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            style={{
                                                border: "1px solid #aaa",
                                            }}
                                            keyboardIcon={<CalendarMonthIcon />}
                                            value={surveyDate}
                                            onChange={onDateChange}
                                            format={commonUtils.getDateFormat()}
                                            helperText=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>

                            <div style={{ padding: "1em 0.7em" }}>
                                <div>
                                    <div className="sub-heading1-UHM">
                                        {userRef.toLowerCase() === "metric"
                                            ? globalizationMapping.INSPECT.LASTRECORDEDKILOMETERS
                                            : globalizationMapping.INSPECT.LASTRECORDEDMILES}
                                    </div>
                                    <div className="sub-heading1-UHM" style={{ color: "#000" }}>
                                        {lastRecordedMiles}
                                    </div>
                                </div>
                                <div style={{ marginTop: "2em" }}>
                                    <div className="sub-heading2-UHM">
                                        {userRef.toLowerCase() !== "metric"
                                            ? globalizationMapping.INSPECT.UPDATEMILES
                                            : globalizationMapping.INSPECT.UPDATEKILOMETERS}
                                        <span className="required-mark">*</span>
                                    </div>
                                    <TextField
                                        className="datepicker-popup"
                                        variant="outlined"
                                        value={updateMiles}
                                        onChange={(e) => onChangeListener("miles", commonUtils.minmaxInt(e.target.value, 0, 999999))}
                                        disabled={props.vehicleDetails.trackingMethod === 1}
                                        placeholder={
                                            userRef.toLowerCase() !== "metric"
                                                ? "Enter Miles"
                                                : "Enter KMs"
                                        }
                                        helperText=""
                                        type="number"
                                        inputProps={{ maxLength: 50 }}
                                    />
                                    {showError("miles") && (
                                        <FormHelperText>
                                            {userRef.toLowerCase() !== "metric"
                                                ? globalizationMapping.INSPECT.ERRMSGMILES
                                                : globalizationMapping.INSPECT.ERRMSGKM}
                                        </FormHelperText>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {props.vehicleDetails.trackingMethod === 1 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0em 1.3em",
                            }}
                        >
                            <div style={{ padding: "1em 0.7em" }}>
                                <div>
                                    <div className="sub-heading1-UHM">
                                        {globalizationMapping.INSPECT.LASTRECOREDEDDATE}
                                    </div>
                                    <div className="sub-heading1-UHM" style={{ color: "#666" }}>
                                        {lastDate}
                                    </div>
                                </div>
                                <div style={{ marginTop: "2em" }}>
                                    <div className="sub-heading2-UHM">
                                        {globalizationMapping.INSPECT.SURVEYDATE}
                                        <span className="required-mark">*</span>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            style={{
                                                border: "1px solid #aaa",
                                            }}
                                            keyboardIcon={<CalendarMonthIcon />}
                                            value={surveyDate}
                                            onChange={onDateChange}
                                            format={commonUtils.getDateFormat()}
                                            helperText=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div style={{ padding: "1em 0.7em" }}>
                                <div>
                                    <div className="sub-heading1-UHM">
                                        {globalizationMapping.INSPECT.LASTRECORDEDHOURS}
                                    </div>
                                    <div className="sub-heading1-UHM" style={{ color: "#000" }}>
                                        {lastRecordedHours}
                                    </div>
                                </div>
                                <div style={{ marginTop: "2em" }}>
                                    <div className="sub-heading2-UHM">
                                        {globalizationMapping.INSPECT.UPDATEHOURS}
                                        <span className="required-mark">*</span>
                                    </div>
                                    <TextField
                                        className="datepicker-popup"
                                        variant="outlined"
                                        value={updateHour}
                                        onChange={(e) => onChangeListener("hours", commonUtils.minmaxInt(e.target.value, 0, 999999))}
                                        disabled={props.vehicleDetails.trackingMethod === 2}
                                        placeholder="Enter Hour"
                                        helperText=""
                                        type="number"
                                        inputProps={{ maxLength: 50 }}
                                    />

                                    {showError("hours") && (
                                        <FormHelperText>
                                            {globalizationMapping.INSPECT.ERRMSGHOURS}
                                        </FormHelperText>
                                    )}
                                    {isHoursInputInvalid(updateHour, surveyDate) && (
                                        <FormHelperText>
                                            Input Hours must less than 24 hours per day for the period
                                        </FormHelperText>)}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="modal-button-group">
                    <div>
                        <button
                            className="header-change-modal-cancel-btn"
                            style={{ padding: 0, marginRight: 30 }}
                            onClick={props.onClose}
                        >
                            {globalizationMapping.FORMS.CANCEL}
                        </button>
                    </div>
                    <div>
                        <button
                            className={(showError("hours") || showError("miles") || (updateHour === "")) ? "header-edit-latest-record-change-modal-disabled" : "header-change-modal-confirm-btn"}
                            onClick={saveChanges}
                            disabled = {(showError("hours") || showError("miles")|| (updateHour === ""))}
                        >
                            {globalizationMapping.INSPECT.UPDATE}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UpdateHoursMiles;
