import { Backdrop, Button, makeStyles, Modal } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router";
import HeaderApi from "../../../common-components/header/services/header-apis";
import Globalization from "../../../globalization";
import "./confirm-popup.css";

const useStyles = makeStyles({
    proceed: {
        "&:hover": {
            opacity: 1,
        },
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: "Barlow",
        opacity: 0.8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none",
    },
    cancel: {
        "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none",
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: 0.8,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none",
    },
});

const MeterUpdateHourPopUp = (props) => {
    const history = useHistory();
    const styles = useStyles();
    const headerApi = new HeaderApi();
    const globalizationMapping = new Globalization();
    const titleTexts = globalizationMapping.FORMS;
    const globaldHeaderTexts = globalizationMapping.HEADER;
    const modalTexts = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
    const globalTexts = globalizationMapping.INSPECT;
    const locationObj = useLocation();

    const warningMsg = () => {
        if (props.checked && locationObj.state && locationObj.state.from !== "createVehicle") {
            return (<p>{globalizationMapping.POPUPWORDS.WARNINGMSG1}</p>);
        } else {
            return (locationObj.state && locationObj.state.from === "createVehicle" ?
                (<p>{globalizationMapping.POPUPWORDS.WARNINGMSG2}</p>) : (<p>{globalizationMapping.POPUPWORDS.WARNINGMSG3}</p>));
        }
    };

    const handleProceed = () => {
        headerApi.updateHoursForVehicle(props.reqObj).then((data) => {
            if (data.message) {
                if (locationObj.state && locationObj.state.from === "createVehicle") {
                    const updatedsection = "updateHours";
                    const successmessage = globalTexts.METERCHANGESUSS;
                    const successdesc = globalTexts.METERSUCCESSMSG;
                    const failuremsg = "Meter Change";
                    const successStatus = true;
                    history.push({
                        pathname: "/create-vehicle/" + props.reqObj.vehicleId,
                        state: {
                            updatedSection: updatedsection,
                            updateStatus: successStatus,
                            successMessage: successmessage,
                            successMessageDesc: successdesc,
                            failureMessage: failuremsg,
                        },
                    });
                } else {
                    history.push({
                        pathname: "/quick-inspect/" + props.reqObj.vehicleId,
                    });
                }
            }
        });
    };

    return (
        <React.Fragment>
            <div className="container">
                <Modal
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 300,
                    }}
                    disableScrollLock={true}
                >
                    <div className="delete-tire-modal-container center-modal">
                        <div className="delete-tire-title-container">
                            <div className="delete-tire-title">
                                {props.reqObj.action === "UpdateHoursEditTire" ||
                                    props.reqObj.action === "UpdateHours"
                                    ? globaldHeaderTexts.UPDATEHOURS
                                    : modalTexts.UPDATEHOURS}
                            </div>
                            <div className="close-icon-container" onClick={props.closeWindow}>
                                <img
                                    src={require("../../../assets/close-icon.svg")}
                                    className="modal-close-icon"
                                    alt="close-icon"
                                />
                            </div>
                        </div>
                        <div className="delete-modal-description">
                            {warningMsg()}
                        </div>
                        <div className="modal-action-buttons">
                            <Button
                                className={styles.cancel}
                                variant="contained"
                                disableElevation={true}
                                onClick={props.closeWindow}
                            >
                                {titleTexts.CANCEL}
                            </Button>
                            <Button
                                className={styles.proceed}
                                variant="contained"
                                disableElevation={true}
                                onClick={() => {
                                    handleProceed();
                                }}
                            >
                                {titleTexts.PROCEED}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default MeterUpdateHourPopUp;
