import { Badge, Button, FormHelperText, Grid, InputLabel, makeStyles, Select, TextField, FormGroup, FormControlLabel, ButtonGroup, FormControl, InputAdornment, OutlinedInput, Checkbox } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./create-vehicle.css";
import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded";
import deleteIcon from "../../assets/delete-icon.svg";
import ExpandMoreStyledIcon from "../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import CommonUtils from "../../services/utils";

const Styles = makeStyles({
  root: {
    "& input.MuiAutocomplete-inputFocused": {
      color: "#000000 !important",
      fontWeight: "bold"
    }
  },
  next: {
    "&:hover": {
      opacity: 1
    },
    background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
    textAlign: "center"
  },
  input: {
    display: "none"
  },
  FormHelperText: {
    align: "center"
  },
  deleteBadge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "10px",
      right: "17px",
      background: "transparent"
    }
  }
});

const Description = (props) => {
  const commonUtils = new CommonUtils();
  const [installedTiresAvailable, setInstalledTiresAvailable] = useState(false);
  const [hasMultipleAxleClicked, setHasMultipleAxleClicked] = useState(props.stateVar.hasMultipleTireSizes);

  useEffect(() => {
    if (props.installedTireData && props.installedTireData.installedTires && props.installedTireData.installedTires.length > 0) {
      setInstalledTiresAvailable(true);
    }
  }, [props.installedTireData]);
  const classes = Styles();
  const manufacturersList = props.masterDataSet.vehicleManufacturers.sort(function (a, b) {
    return compareStrings(a.manufacturerName, b.manufacturerName);
  });
  const tireData = props.masterDataSet.tireSizes.find((element) => element.tireSizeId === props.stateVar.tireSizeId);
  let tireSizeData = [...props.masterDataSet.tireSizes];
  if (tireData) {
    const duplicateTireData = tireSizeData.filter(
      (element) => element.tireSizeId !== props.stateVar.tireSizeId && element["tireSizeName"].trim() === tireData["tireSizeName"].trim()
    );

    tireSizeData = tireSizeData.filter(function (obj) {
      return !this.has(obj.tireSizeId);
    }, new Set(duplicateTireData.map((obj) => obj.tireSizeId)));
  }
  const tirSizeList = tireSizeData.filter((elem, index, self) => self.findIndex((t) => t["tireSizeName"].trim() === elem["tireSizeName"].trim()) === index);
  const axleConfig = props.masterDataSet.axleConfigs;
  const vehicles = props.masterDataSet.vehicleModels.sort(function (a, b) {
    return compareStrings(a.modelName, b.modelName);
  });

  const handleIncrement = (i) => {
    const allAxles = props.stateVar.recommendedPressure.split(",");
    allAxles[i] = Number(allAxles[i]) + 1;
    props.onChangeListener("recommenedPressure", allAxles.join(","));
  };
  const handleDecrement = (i) => {
    const allAxles = props.stateVar.recommendedPressure.split(",");
    if (Number(allAxles[i]) !== 0) {
      allAxles[i] = Number(allAxles[i]) - 1;
    }
    props.onChangeListener("recommenedPressure", allAxles.join(","));
  };
  const changePressure = (event, i) => {
    const allAxles = props.stateVar.recommendedPressure.split(",");
    if (!isNaN(event.target.value)) {
      const isValue = isNaN(event.target.value) ? String(event.target.value) : Number(event.target.value);
      allAxles[i] = String(event.target.value).includes(".") ? String(event.target.value) : isValue;
    } // else
    //     allAxles[i] = "";
    props.onChangeListener("recommenedPressure", allAxles.join(","));
  };
  function compareStrings(a, b) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a < b) {
      return -1;
    } else {
      return a > b ? 1 : 0;
    }
  }

  const handleModel = (e) => {
    const selectedmodel = vehicles.find((model) => model.modelId === parseInt(e.target.value, 10));
    if (selectedmodel.modelName.trim().toLowerCase() === "other") {
      props.onChangeListener("modelid", e.target.value);
    } else {
      const selectedConfiguration = axleConfig.find((a) => a.configurationId === selectedmodel.configurationId);
      if (selectedConfiguration && selectedConfiguration.configurationName) {
        const configAxle = [];
        const axles = [];
        for (const element of selectedConfiguration.configurationName) {
          configAxle.push(0);
          const x = [];
          for (let j = 0; j < Number(element) / 2; j++) {
            x.push([""]);
          }
          axles.push(x);
        }
        props.onChangeListener("modelid", e.target.value, {
          recommendedPressure: configAxle.join(","),
          multipleTireSizeIds: configAxle.join(","),
          axleConfig: axles
        });
      } else {
        props.onChangeListener("modelid", e.target.value);
      }
    }
  };

  const [selectedAxelSize, setSelectedAxelSize] = useState(props.stateVar.axleConfigId);
  const handleAxleConfig = (e) => {
    setSelectedAxelSize(e.target.value);
    const configAxle = [];
    const selectedAxle = axleConfig.find((a) => a.configurationId === parseInt(e.target.value, 10));
    const axles = [];
    if (selectedAxle.hasOwnProperty("configurationName")) {

      Object.assign([], selectedAxle.configurationName).forEach((configName) => {
        configAxle.push(0);
        const x = [];
        for (let j = 0; j < Number(configName) / 2; j++) {
          x.push([""]);
        }
        axles.push(x);
      });
    }
    props.onChangeListener("axleconfigid", Number(e.target.value), {
      recommendedPressure: configAxle.join(","),
      axleConfig: axles
    });
  };


  const hasMultipleAxleHandler = (event) => {
    setHasMultipleAxleClicked(event.target.checked);
    props.onChangeListener("hasMultipleTireSizes", event.target.checked);
  }

  const tireSizeChangeHandler = (event, i) => {
    const allSizes = props.stateVar?.multipleTireSizeIds.split(",");
    allSizes[i] = event.target.value;
    props.onChangeListener("multipleTireSizeIds", allSizes.join(","));
  }


  const removeTypeDuplicates = (arr) => {
    return arr.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.typeName === value.typeName
      )))
  }
  const removeModelDuplicates = (arr) => {
    return arr.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.modelName === value.modelName
      )))
  }
  let nonDuplicateManufacturerTypeName = removeTypeDuplicates(props.vehicleTypesByManuf);
  let nonDuplicateModelName = removeModelDuplicates(props.vehicleModelsByType);

  return (
    <React.Fragment>
      <Grid className="step-form-inner-container" container spacing={2}>
        <Grid item sm={12}>
          <p className="field-label-vehicle">
            {props.fieldNames.MANUFACTURER} <span className="required-mark">*</span>
          </p>
          {installedTiresAvailable && !props.isTireCloned ? (
            <>
              <React.Fragment>
                <TextField className="enter-detail-input-box disabled-input" variant="outlined" value={props.installedTireData.vehicleManufacturer} disabled={true} />
              </React.Fragment>
              <span style={{ fontSize: 12, fontStyle: 'italic', color: '#0055A4' }}>{`Disabled due to existing installed tires`}</span>
            </>
          ) : (
            <React.Fragment>
              <Select
                required
                native
                IconComponent={ExpandMoreStyledIcon}
                className="enter-detail-input-box"
                variant="outlined"
                value={props.stateVar.manufacturerId}
                onChange={(e) => {
                  props.onChangeListener("manufacturer", e.target.value);
                  setHasMultipleAxleClicked(false);
                }}
              >
                <option key={0} value={""} hidden>
                  {" "}
                  {` ${props.fieldNames.ENTER} ${props.fieldNames.MANUFACTURER}`}{" "}
                </option>
                {manufacturersList &&
                  manufacturersList.map((mfcr, key) => (
                    <option
                      key={key + 1}
                      value={mfcr.manufacturerId}
                      className="list-color-change"
                    >
                      {mfcr.manufacturerName}
                    </option>
                  ))}
              </Select>
              {(props.stateVar.manufacturerId === 0 && props.isManufacturerEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
              {(props.isManufacturerEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.manufacturerId}</FormHelperText>)}
            </React.Fragment>
          )}
        </Grid>
        <Grid item sm={12}>
          <p className="field-label-vehicle">
            {props.fieldNames.TYPE} <span className="required-mark">*</span>
          </p>
          {installedTiresAvailable && !props.isTireCloned ? (
            <>
              <React.Fragment>
                <TextField className="enter-detail-input-box disabled-input" variant="outlined" value={props.installedTireData.vehicleType} disabled={true} />
              </React.Fragment>
              <span style={{ fontSize: 12, fontStyle: 'italic', color: '#0055A4' }}>{`Disabled due to existing installed tires`}</span>
            </>
          ) : (
            <React.Fragment>
              <Select
                required
                native
                IconComponent={ExpandMoreStyledIcon}
                className={props.stateVar.manufacturerId === 0 ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                variant="outlined"
                value={props.stateVar.typeId}
                onChange={(e) => {
                  props.onChangeListener("typeid", e.target.value);
                }}
                disabled={props.stateVar.manufacturerId === 0}
              >
                <option key={0} value={""} hidden>
                  {" "}
                  {` ${props.fieldNames.ENTER} ${props.fieldNames.TYPE}`}{" "}
                </option>
                {nonDuplicateManufacturerTypeName.map((type, key) => (
                  <option key={key + 1} value={type.typeId} className="list-color-change">
                    {type.typeName}
                  </option>
                ))}
              </Select>
              {(props.stateVar.typeId === 0 && props.isTypeEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
              {(props.isTypeEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.typeId}</FormHelperText>)}
            </React.Fragment>
          )}
        </Grid>
        <Grid item sm={12}>
          <p className="field-label-vehicle">
            {props.fieldNames.MODEL} <span className="required-mark">*</span>
          </p>
          {installedTiresAvailable && !props.isTireCloned ? (
            <>
              <React.Fragment>
                <TextField className="enter-detail-input-box disabled-input" variant="outlined" value={props.installedTireData.vehicleModel} disabled={true} />
              </React.Fragment>
              <span style={{ fontSize: 12, fontStyle: 'italic', color: '#0055A4' }}>{`Disabled due to existing installed tires`}</span>
            </>
          ) : (
            <React.Fragment>
              <Select
                required
                native
                IconComponent={ExpandMoreStyledIcon}
                className={(props.stateVar.typeId === 0) ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                variant="outlined"
                value={props.stateVar.modelId}
                onChange={(e) => {
                  handleModel(e);
                  setHasMultipleAxleClicked(false);
                }}
                disabled={(props.stateVar.typeId === 0)}
              >
                <option key={0} value={""} hidden>
                  {" "}
                  {` ${props.fieldNames.ENTER} ${props.fieldNames.MODEL}`}{" "}
                </option>
                {nonDuplicateModelName.map((model, key) => (
                  <option key={key + 1} value={model.modelId} className="list-color-change">
                    {model.modelName}
                  </option>
                ))}
              </Select>
              {(props.stateVar.modelId === 0 && props.isModelEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
              {(props.isModelEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.modelId}</FormHelperText>)}
            </React.Fragment>
          )}
        </Grid>
        {props.isModelNew && (
          <Grid item sm={12}>
            <p className="field-label-vehicle">
              {props.fieldNames.AXLECONFIG} <span className="required-mark">*</span>
            </p>
            <Select
              native
              IconComponent={ExpandMoreStyledIcon}
              className={installedTiresAvailable && props.isModelNew ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
              variant="outlined"
              value={props.stateVar.axleConfigId}
              onChange={(e) => {
                props.onExtraDataChange("axleconfigid", e.target.value);
                handleAxleConfig(e);
              }}
              disabled={installedTiresAvailable && props.isModelNew}
            >
              <option key={0} value={0} disabled>
                {" "}
                {` ${props.fieldNames.ENTER} ${props.fieldNames.AXLECONFIG}`}{" "}
              </option>
              {axleConfig.map((axle, key) => (
                <option key={key + 1} value={axle.configurationId}>
                  {axle.configurationName}
                </option>
              ))}
            </Select>
            <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.axleConfigId}</FormHelperText>
          </Grid>
        )}
        {props.isModelNew === false && props.stateVar.modelId > 0 && props.stateVar.axleConfigId > 0 && props.stateVar.typeId > 0 &&

          <Grid item sm={12}>

            <FormGroup>

              <FormControlLabel
                control={<Checkbox
                  checked={props.stateVar.hasMultipleTireSizes && hasMultipleAxleClicked === true}
                  style={{ color: "#0055A4" }} />}
                label="Has Multiple Axle"
                onChange={hasMultipleAxleHandler}
                disabled={installedTiresAvailable && !props.isTireCloned}
              />
            </FormGroup>
            {installedTiresAvailable && !props.isTireCloned ?
              <span style={{ fontSize: 12, fontStyle: 'italic', color: '#0055A4' }}>{`Disabled due to existing installed tires`}</span>
              : null}
          </Grid>}
        {props.stateVar.hasMultipleTireSizes && hasMultipleAxleClicked && props.stateVar.axleConfig.length > 1 &&
          props.stateVar.multipleTireSizeIds.split(",").map((element, i) =>
            <Grid item sm={12} key={i}>

              <p className="field-label-vehicle">
                {props.fieldNames.SIZE} for Axle {i + 1}<span className="required-mark">*</span>
              </p>
              <Select
                required
                native
                IconComponent={ExpandMoreStyledIcon}
                className="enter-detail-input-box"
                variant="outlined"
                value={element}
                onChange={(e) => { tireSizeChangeHandler(e, i) }}
              >
                <option key={0} value={""} hidden>
                  {" "}
                  {` ${props.fieldNames.ENTER} ${props.fieldNames.SIZE}`}{" "}
                </option>
                {tirSizeList &&
                  tirSizeList.map((size, key) => (
                    <option key={key + 1} value={size.tireSizeId} className="list-color-change">
                      {size.tireSizeName}
                    </option>
                  ))}
              </Select>
              {(element === 0 && Number(props.isOtherSizeEdited[i]) !== 0) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
              {(Number(props.isOtherSizeEdited[i]) !== 0 ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts?.multipleTireSizeIds !== undefined ? props.formHelperTexts?.multipleTireSizeIds[i] : ""}</FormHelperText>)}
            </Grid>
          )}
        {hasMultipleAxleClicked === false && <Grid item sm={12}>
          <p className="field-label-vehicle">
            {props.fieldNames.SIZE} <span className="required-mark">*</span>
          </p>
          <Select
            required
            native
            IconComponent={ExpandMoreStyledIcon}
            className={(props.stateVar.modelId === 0) ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
            variant="outlined"
            value={props.stateVar.tireSizeId}
            onChange={(e) => props.onChangeListener("tiresizeid", e.target.value)}
            disabled={(props.stateVar.modelId === 0)}
          >
            <option key={0} value={""} hidden>
              {" "}
              {` ${props.fieldNames.ENTER} ${props.fieldNames.SIZE}`}{" "}
            </option>
            {tirSizeList &&
              tirSizeList.map((size, key) => (
                <option key={key + 1} value={size.tireSizeId} className="list-color-change">
                  {size.tireSizeName}
                </option>
              ))}
          </Select>
          {(props.stateVar.tireSizeId === 0 && props.isSizeEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
          {(props.isSizeEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.tireSizeId}</FormHelperText>)}
        </Grid>}
        <Grid item sm={12}>
          <p className="field-label-vehicle">{props.fieldNames.OPERATION} </p>
          <TextField
            className="enter-detail-input-box"
            variant="outlined"
            value={props.stateVar.areaOfOperation}
            onChange={(e) => props.onChangeListener("areaofoperation", commonUtils.removeLeadingSpaces(e.target.value))}
            placeholder={props.fieldNames.OPERATION}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item sm={12}>
          <p className="field-label-vehicle">{props.fieldNames.MODIFICATION}</p>
          <TextField
            className="enter-detail-input-box"
            variant="outlined"
            value={props.stateVar.modifications}
            onChange={(e) => props.onChangeListener("modifications", commonUtils.removeLeadingSpaces(e.target.value))}
            placeholder={props.fieldNames.MODIFICATION}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid item sm={12}>
          <div className="vehicle-upload-box-description">
            <InputLabel className="upload-label">{props.fieldNames.UPLOADCAPTION}</InputLabel>
            <input accept="image/*" className={classes.input} id="icon-button-file" onChange={(e) => props.onChangeListener("images", e.target)} type="file" />
            <label htmlFor="icon-button-file">
              <Button className="upload-Button" variant="outlined" size="large" aria-label="upload picture" component="span" startIcon={<PhotoCameraRoundedIcon />}>
                {props.fieldNames.UPLOAD}
              </Button>
            </label>
          </div>
          <FormHelperText className={classes.FormHelperText}>{props.imageHelper}</FormHelperText>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          {(props.dbImages.length !== 0 || props.stateVar.imgsrc.length !== 0) && <p className="field-label-upload">{props.fieldNames.IMAGESCAPTION}</p>}
          <Grid container spacing={1} className="uploaded-images">
            {props.dbImages.length !== 0 &&
              props.dbImages.map((source, key) => (
                <Badge
                  className={classes.deleteBadge}
                  key={key}
                  badgeContent={<img alt="delete" src={deleteIcon} onClick={() => props.deleteDbImage(key)} />}
                  color="primary"
                  variant="standard"
                >
                  <Grid item sm={4} className="uploaded-image">
                    <img alt={"stored-img-".concat((key + 1).toString())} src={source} width="160px" height="160px" />
                  </Grid>
                </Badge>
              ))}
            {props.stateVar.imgsrc.length !== 0 &&
              props.stateVar.imgsrc.map((source, key) => (
                <Badge
                  className={classes.deleteBadge}
                  key={key}
                  badgeContent={<img alt="delete" src={deleteIcon} onClick={() => props.removeUploadedPhoto(key)} />}
                  color="primary"
                  variant="standard"
                >
                  <Grid item sm={4} className="uploaded-image">
                    <img alt={"chosen-img-".concat((key + 1).toString())} src={source} width="160px" height="160px" />
                  </Grid>
                </Badge>
              ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid className="axle-position" container spacing={1}>
        {props.stateVar.axleConfig.length > 0 &&
          props.stateVar.recommendedPressure &&
          props.stateVar.recommendedPressure.split(",").map((_a, i) => {
            return (
              <div
                key={i}
                className={`axel-container-create-vehicle
                    ${props.stateVar.recommendedPressure.split(",").length === 2 && i === 0 ? "axel-container-create-vehicle-align-0-1" : ""}
                    ${props.stateVar.recommendedPressure.split(",").length === 2 && i !== 0 ? "axel-container-create-vehicle-align-1-1" : ""}
                    ${props.stateVar.recommendedPressure.split(",").length === 3 && i === 0 ? "axel-container-create-vehicle-align-0-2" : ""} 
                    ${props.stateVar.recommendedPressure.split(",").length === 3 && i !== 0 ? "axel-container-create-vehicle-align-1-2" : ""}
                    ${props.stateVar.recommendedPressure.split(",").length === 4 && i === 0 ? "axel-container-create-vehicle-align-0-3" : ""} 
                    ${props.stateVar.recommendedPressure.split(",").length === 4 && i !== 0 ? "axel-container-create-vehicle-align-1-2" : ""}   
                    ${props.stateVar.recommendedPressure.split(",").length === 5 && i === 0 ? "axel-container-create-vehicle-align-0-4" : ""} 
                    ${props.stateVar.recommendedPressure.split(",").length === 5 && i !== 0 ? "axel-container-create-vehicle-align-1-4" : ""}
                `}
              >
                {i === 0 && (
                  <span
                    style={{
                      color: "#353A94",
                      marginRight: "10px",
                      fontWeight: "600"
                    }}
                  >
                    R
                  </span>
                )}
                {props.stateVar.axleConfig[i].map((_b, j) => {
                  return (
                    <>
                      <img
                        className={`tire-create 
                            ${(selectedAxelSize === 11 ||
                            selectedAxelSize === 12 ||
                            selectedAxelSize === 13 ||
                            selectedAxelSize === 21 ||
                            props.stateVar.axleConfig[i].length === 2) &&
                            i === 0 &&
                            j === 1
                            ? "double-tire"
                            : ""
                          }`}
                        alt="Tire"
                        src={require("../../assets/Tire_a.svg")}
                      />
                      <br />
                    </>
                  );
                })}

                <div className={`axle ${i === 0 ? "axle-alignmen" : ""}`}>
                  <span className={`axleNumber-create ${i === 0 ? "axleNumber-create-first" : ""}`}>{i + 1}</span>
                  <img alt="Axle" className={`${i === 0 ? "left-10" : ""}`} src={require("../../assets/axle_a.svg")} />
                </div>
                {i === 0 && (
                  <span
                    style={{
                      color: "#353A94",
                      marginRight: "10px",
                      fontWeight: "600"
                    }}
                  >
                    L
                  </span>
                )}
                {props.stateVar.axleConfig[i].map((_a, j) => {
                  return (
                    <>
                      <img
                        className={`tire-create ${(selectedAxelSize === 11 || selectedAxelSize === 12 || selectedAxelSize === 13 || selectedAxelSize === 21 || props.stateVar.axleConfig[i].length === 2) &&
                          i === 0 &&
                          j === 1
                          ? "double-tire"
                          : ""
                          }`}
                        alt="Tire"
                        src={require("../../assets/Tire_a.svg")}
                      />
                      <br />
                    </>
                  );
                })}
              </div>
            );
          })}
      </Grid>

      <Grid className="step-form-inner-container" container spacing={1}>
        {props.stateVar.recommendedPressure &&
          props.stateVar.recommendedPressure.split(",").map((a, i) => {
            return (
              <Grid key={i} item sm={12}>
                <p className="field-label-vehicle">
                  {props.fieldNames.RECOMMENDEDPRESSURE}&nbsp;{i + 1}
                </p>
                <ButtonGroup size="large" aria-label="medium outlined button group">
                  <Button className={classes.next} onClick={() => handleDecrement(i)}>
                    -
                  </Button>
                  <FormControl variant="outlined" className="enter-detail-input-box" onKeyPress={(event) => {
                    if (event?.key === '-') {
                      event.preventDefault();
                    }
                  }}
                    onChange={(e) => changePressure(e, i)}>
                    <OutlinedInput
                      value={a}
                      endAdornment={
                        <InputAdornment position="end">
                          {props.preferenceUnits.pressure === "PSI" || props.preferenceUnits.pressure === "KPA" ? props.preferenceUnits.pressure : "Bar"}
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Button className={classes.next} onClick={() => handleIncrement(i)}>
                    +
                  </Button>
                </ButtonGroup>
              </Grid>
            );
          })}
      </Grid>
    </React.Fragment>
  );
};

export default Description;
