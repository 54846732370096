import { FormHelperText, Grid, Select, TextField, Checkbox } from '@material-ui/core';
import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CalendarMonthIcon from '@material-ui/icons/Today';
import "./create-tire.css";
import CommonUtils from "../../../services/utils";
import ExpandMoreStyledIcon from '../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon';


const Identification = props => {
    const commonUtils = new CommonUtils();
    const [registeredDate, setRegisteredDate] = useState(props.stateVar.registeredDate);
    const onDateChange = value => {
        setRegisteredDate(value);
        props.formHelperTexts.registeredDate = "";
        props.onChangeListener('regDate', value);
    };

    const handleTrackingMethodChange = val => {
        if (val === 1) {
            props.onChangeListener('Hours', val);
        } else if (val === 2) {
            props.onChangeListener('Miles', val);
        } else {
            props.onChangeListener('Both', val);
        }
        props.onTrackByChange(val);
    };


    const dispositionBaseOnTireId = () => {

        if (props.stateVar.tireId === undefined) { // the tire was in create mode
            return  (<Select
                        native
                        className="enter-detail-input-box disabled-input"
                        variant="outlined"
                        value={8} // disposition Inventory
                        IconComponent={ExpandMoreStyledIcon}
                        disabled={true} >
                        {props.masterDataset.tireDispositions.map(disposition =>
                            <option key={disposition.dispositionId} value={disposition.dispositionId}>{disposition.dispositionName}</option>
                        )}
                    </Select>)
        } else if (props.stateVar.tireId !== 0 && props.stateVar.dispositionId !== 7) {
            return (
                <Select
                    native
                    className={(props.stateVar.tireId !== null && props.stateVar.dispositionId === 7) ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                    variant="outlined"
                    value={props.stateVar.dispositionId}
                    onChange={e => props.onChangeListener('dispositionId', e.target.value)}
                    IconComponent={ExpandMoreStyledIcon}
                    disabled={props.stateVar.tireId !== 0 && props.stateVar.dispositionId === 7} >
                    {props.masterDataset.tireDispositions.map(disposition => {
                                if(disposition.dispositionName !== "Installed" && disposition.dispositionId !== 7) {
                                    return <option key={disposition.dispositionId} value={disposition.dispositionId}>{disposition.dispositionName}</option>
                                }
                            }
                    )}
                </Select>
            )
        } else {
            return (
                <Select
                    native
                    className="enter-detail-input-box disabled-input"
                    variant="outlined"
                    value={7} // disposition Installed
                    IconComponent={ExpandMoreStyledIcon}
                    disabled={true} >
                    {props.masterDataset.tireDispositions.map(disposition =>
                        <option key={disposition.dispositionId} value={disposition.dispositionId}>{disposition.dispositionName}</option>
                    )}
                </Select>
            )
        }
    }
    return (
        <React.Fragment>
            <Grid className="step-form-inner-container" container spacing={2} >
                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.TIRESERIALNO} <span className="required-mark">*</span></p>
                    <TextField
                        autoFocus={props.formHelperTexts.tireSerialNo !== undefined}
                        className="enter-detail-input-box" 
                        variant="outlined"
                        value={props.stateVar.tireSerialNo ? props.stateVar.tireSerialNo : ''}
                        onChange={e => props.onChangeListener('tireserial', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.TIRESERIALNO}`} 
                        inputProps={{ maxLength: 50 }}
                    />
                    {(props.stateVar.tireSerialNo === "" && props.isTireSerialEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(props.isTireSerialEdited ? "" : <FormHelperText>{props.formHelperTexts.tireSerialNo}</FormHelperText>)}
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.BRANDNO}</p>
                    <TextField
                        className="enter-detail-input-box" 
                        variant="outlined"
                        value={props.stateVar.brandNo ? props.stateVar.brandNo : ''} 
                        onChange={e => props.onChangeListener('brandNo', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.BRANDNO}`}
                        type="" 
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.REGDATE}<span className="required-mark">*</span></p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                        <KeyboardDatePicker
                            className="enter-detail-input-box"
                            value={registeredDate}
                            helperText=""
                            onChange={onDateChange}
                            format={commonUtils.getDateFormat()}
                            placeholder={commonUtils.getDateFormat()}
                            keyboardIcon={<CalendarMonthIcon />} />
                    </MuiPickersUtilsProvider>
                    {((registeredDate === "" || registeredDate === null || registeredDate === 'NaN-NaN-NaN') && props.isRegisterDateEdited) ? <FormHelperText>{`This field is required with format ${commonUtils.getDateFormat()}`}</FormHelperText> : ''}
                    {(props.isRegisterDateEdited ? "" : <FormHelperText>{props.formHelperTexts.registeredDate}</FormHelperText>)}
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.EVALNO}</p>
                    <TextField
                        className="enter-detail-input-box" 
                        variant="outlined"
                        value={props.stateVar.evaluationNo ? props.stateVar.evaluationNo : ''} 
                        onChange={e => props.onChangeListener('evalno', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.EVALNO}`}
                        helperText="" type="" 
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.LOTNO}</p>
                    <TextField
                        className="enter-detail-input-box" 
                        variant="outlined"
                        value={props.stateVar.lotNo} 
                        onChange={e => props.onChangeListener('lotnum', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.LOTNO}`}
                        helperText="" type="" 
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.PONO}</p>
                    <TextField
                        className="enter-detail-input-box" 
                        variant="outlined"
                        value={props.stateVar.poNo ? props.stateVar.poNo : ''} 
                        onChange={e => props.onChangeListener('purodr', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.PONO}`}
                        helperText="" type="" 
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                {(props.masterDataset?.tireDispositions) &&
                    <Grid item sm={12}>
                        <p className="field-label">{props.fieldNames.DISPO} <span className="required-mark">*</span></p>
                        {dispositionBaseOnTireId()}
                    </Grid>}
                {(props.masterDataset?.tireStatus) &&
                    <Grid item sm={12}>
                        <p className="field-label">{props.fieldNames.STATUS}</p>
                        <Select
                            native className="enter-detail-input-box"
                            variant="outlined" value={props.stateVar.tireStatusId ? props.stateVar.tireStatusId : 0}
                            onChange={e => props.onChangeListener('statusId', parseInt(e.target.value, 10))}
                            helperText="" displayEmpty
                            IconComponent={ExpandMoreStyledIcon}
                        >
                            <option key={0} value={0} >{props.fieldNames.DROPDOWNPLACEHOLDER}</option>
                            {props.masterDataset.tireStatus.map((status, _key) =>
                                <option key={status.statusId} value={status.statusId}>{status.statusName}</option>
                            )}
                        </Select>
                    </Grid>}
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.TRACKBY} </p>
                    <Select
                        native className="enter-detail-input-box" variant="outlined"
                        value={props.trackBy}
                        onChange={e => handleTrackingMethodChange(parseInt(e.target.value, 10))}
                        IconComponent={ExpandMoreStyledIcon}
                        helperText="">
                        <option value={1}>{props.fieldNames.HOURS}</option>
                        <option value={2}>{props.fieldNames.DISTANCE}</option>
                        <option value={3}>{props.fieldNames.BOTH}</option>
                    </Select>
                </Grid>
                {(parseInt(props.trackBy, 10) === 1 || parseInt(props.trackBy, 10) === 3) &&
                    <Grid item sm={12}>
                        <p className="field-label">{props.fieldNames.CURRHOURS} <span className="required-mark">*</span></p>
                        <TextField
                            className="enter-detail-input-box" 
                            variant="outlined"
                            value={props.stateVar.currentHours ? props.stateVar.currentHours : ''} 
                            onChange={e => props.onChangeListener('currentHours', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                            type="number" 
                            inputProps={{ maxLength: 50 }}/>
                        {(props.stateVar.currentHours !== "" && (props.stateVar.currentHours < 0 || props.stateVar.currentHours === '-0') && props.isCurrentHoursEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.stateVar.currentHours === "" && props.isCurrentHoursEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.isCurrentHoursEdited ? "" : <FormHelperText>{props.formHelperTexts.currentHours}</FormHelperText>)}
                    </Grid>}
                {(parseInt(props.trackBy, 10) === 2 || parseInt(props.trackBy, 10) === 3) &&
                    <Grid item sm={12}>
                        <p className="field-label">
                            {props.userPreferenceData.userMeasurementSystemValue === "imperial" ? props.fieldNames.CURRMILES : props.fieldNames.CURRKM}
                            <span className="required-mark">*</span>
                        </p>
                        <TextField
                            className="enter-detail-input-box" 
                            variant="outlined"
                            value={props.stateVar.currentMiles ? props.stateVar.currentMiles : ''} 
                            onChange={e => props.onChangeListener('currentMiles', commonUtils.minmaxInt(e.target.value, 0, 999999))} 
                            type="number" 
                            inputProps={{ maxLength: 50 }}/>
                        {(props.stateVar.currentMiles !== "" && (props.stateVar.currentMiles < 0 || props.stateVar.currentMiles === '-0') && props.isCurrentMilesEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.stateVar.currentMiles === "" && props.isCurrentMilesEdited) ? <FormHelperText>{`This field is required and should not be < 0`}</FormHelperText> : ''}
                        {(props.isCurrentMilesEdited ? "" : <FormHelperText>{props.formHelperTexts.currentMiles}</FormHelperText>)}
                    </Grid>}
            </Grid>
        </React.Fragment>
    );
};

export default Identification;
