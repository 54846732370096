import Globalization from "../../../globalization/index";

class DailyJobSummaryDataSetter {
  constructor() {
    this.data = {};
    this.dailyJobSummaryTableData0 = [];
    this.dailyJobSummaryTableData1 = [];
    this.dailyJobSummaryTableData2 = [];
    this.dailyJobSummaryTableData3 = [];
    this.dailyJobSummaryData = [];
    this.headCellsTable0 = [];
    this.headCellsTable1 = [];
    this.headCellsTable2 = [];
    this.headCellsTable3 = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.year = "";
    this.avgHoursRadial = "";
    this.avgHoursBias = "";
    this.avgWornRadial = "";
    this.avgWornBias = "";
  }

  tableHeaderOrderSetter(headCells, index) {
    let tableHeaders = headCells;
    let headerOrder = [];
    // maintains the column order to be displayed (write them in the order you want to display table header)
    switch (index) {
      case 0:
      case 1: {
        headerOrder = [
          { columnNo: 1, value: "ReportingPeriods" },
          { columnNo: 2, value: "TireCount" },
          { columnNo: 3, value: "AverageHours" },
          { columnNo: 4, value: "PercentHourTarget" },
          { columnNo: 5, value: "AverageWorn" },
          { columnNo: 6, value: "PercentWornTarget" }
        ];
        break;
      }
      case 2: {
        headerOrder = [
          { columnNo: 1, value: "Manufacturer" },
          { columnNo: 2, value: "Last24Hours" },
          { columnNo: 3, value: "MonthToDate" },
          { columnNo: 4, value: "LastMonth" },
          { columnNo: 5, value: "YearToDate" },
          { columnNo: 6, value: "PrevYearToDate" }
        ];
        break;
      }
      case 3: {
        headerOrder = [
          { columnNo: 1, value: "TireSize" },
          { columnNo: 2, value: "Last24Hours" },
          { columnNo: 3, value: "MonthToDate" },
          { columnNo: 4, value: "LastMonth" },
          { columnNo: 5, value: "YearToDate" },
          { columnNo: 6, value: "PrevYearToDate" }
        ];
        break;
      }
    }

    let resultHeaderCell = [];
    headerOrder.forEach((headerOrder) => {
      let found = false;
      //
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === headerOrder.value) {
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });

    return resultHeaderCell;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      if (headCell.value !== "BrandNumber") {
        updatedHeadCells.push(headCell);
      }
    });
    return updatedHeadCells;
  }

  formatData(item) {
    let dateString, registeredDate, installedDate;
    if (item.registeredDate) {
      dateString = item.registeredDate.split("/");
      registeredDate = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      registeredDate = null;
    }
    if (item.installedDate) {
      dateString = item.installedDate.split("/");
      installedDate = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      installedDate = null;
    }

    let formattedData = {
      registeredDate: registeredDate,
      installedDate: installedDate
    };
    return formattedData;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "BrandNumber") {
        selectedColShow[headCell.value] = false;
      } else {
        selectedColShow[headCell.value] = true;
      }
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    //
    //

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }

  calculateTotalAvg(rowsData) {
    let totalLast24Hours = 0;
    let totalMonthToDate = 0;
    let totalLastMonth = 0;
    let totalYearToDate = 0;
    let totalPrevYearToDate = 0;

    rowsData.forEach((rowData) => {
      totalLast24Hours += rowData.Last24Hours;
      totalMonthToDate += rowData.MonthToDate;
      totalLastMonth += rowData.LastMonth;
      totalYearToDate += rowData.YearToDate;
      totalPrevYearToDate += rowData.PrevYearToDate;
    });

    let totals = [totalLast24Hours, totalMonthToDate, totalLastMonth, totalYearToDate, totalPrevYearToDate];

    return totals;
  }

  avgValues = (dailyJobSummaryData, index) => {
    const avgValues = {};
    if (index === 2 || index === 3) {
      //
      let totals = this.calculateTotalAvg(dailyJobSummaryData);
      if (index === 2) avgValues.Manufacturer = "Total / Average";
      else avgValues.TireSize = "Total / Average";

      avgValues.Last24Hours = totals[0];
      avgValues.MonthToDate = totals[1];
      avgValues.LastMonth = totals[2];
      avgValues.YearToDate = totals[3];
      avgValues.PrevYearToDate = totals[4];
    } else if (index === 0) {
      avgValues.ReportingPeriods = "Company Targets: " + this.year;
      avgValues.TireCount = "";
      avgValues.AverageHours = this.avgHoursRadial;
      avgValues.PercentHourTarget = "";
      avgValues.AverageWorn = this.avgWornRadial + " %";
      avgValues.PercentWornTarget = "";
    } else {
      avgValues.ReportingPeriods = "Company Targets: " + this.year;
      avgValues.TireCount = "";
      avgValues.AverageHours = this.avgHoursBias;
      avgValues.PercentHourTarget = "";
      avgValues.AverageWorn = this.avgWornBias + " %";
      avgValues.PercentWornTarget = "";
    }

    return avgValues;
  };

  setData(data, reportEndDate, avgHoursRadial, avgWornRadial, avgHoursBias, avgWornBias) {
    const monthMapper = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December"
    };
    this.avgHoursRadial = avgHoursRadial;
    this.avgWornRadial = avgWornRadial;
    this.avgHoursBias = avgHoursBias;
    this.avgWornBias = avgWornBias;

    let monthNumber = parseInt(reportEndDate.split("-")[1]);
    let lastMonthNUmber = monthNumber - 1;

    if (monthNumber === 1) lastMonthNUmber = 12;

    let month = monthMapper[monthNumber];
    let lastMonth = monthMapper[lastMonthNUmber];
    let year = reportEndDate.split("-")[0];
    this.year = year;
    let lastYear = reportEndDate.split("-")[0] - 1;

    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      let tableData = item.reportData;
      switch (index) {
        case 0: {
          tableData.forEach((tableItem, i) => {
            this.dailyJobSummaryTableData0.push({
              Key: i,
              ReportingPeriods: tableItem.reportingPeriods ? tableItem.reportingPeriods : "",
              TireCount: tableItem.tireCount ? tableItem.tireCount : 0,
              AverageHours: tableItem.averageHours ? tableItem.averageHours : 0,
              PercentHourTarget: tableItem.percentHourTarget ? tableItem.percentHourTarget : 0,
              AverageWorn: tableItem.averageWorn ? tableItem.averageWorn : 0,
              PercentWornTarget: tableItem.percentWornTarget ? tableItem.percentWornTarget : 0
            });
          });
          const columnDefinitions = item.columnDefinitions;
          columnDefinitions.forEach((columnItem, j) => {
            let colDefnString = columnItem.text.split(".");
            let masterKey = colDefnString[0];
            let key = colDefnString[1];
            let labelName = this.globalizationMapping[masterKey][key];

            let labelId = columnItem.fieldName;

            this.headCellsTable0.push({
              key: labelId,
              keyIndex: j,
              value: labelId,
              label: labelName,
              numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
            });
          });
          this.headCells.push(this.headCellsTable0);
          let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells[index], index);
          let selectedColShow = this.initDisplayColumnData(this.headCells[index]);
          this.dailyJobSummaryData.push({
            rowsData: this.dailyJobSummaryTableData0,
            headCells: orderedHeadCells,
            avgValues: this.avgValues(this.dailyJobSummaryData0, index),
            selectedColShow: selectedColShow
          });
          break;
        }
        case 1: {
          tableData.forEach((tableItem, i) => {
            this.dailyJobSummaryTableData1.push({
              Key: i,
              ReportingPeriods: tableItem.reportingPeriods ? tableItem.reportingPeriods : "",
              TireCount: tableItem.tireCount ? tableItem.tireCount : 0,
              AverageHours: tableItem.averageHours ? tableItem.averageHours : 0,
              PercentHourTarget: tableItem.percentHourTarget ? tableItem.percentHourTarget : 0,
              AverageWorn: tableItem.averageWorn ? tableItem.averageWorn : 0,
              PercentWornTarget: tableItem.percentWornTarget ? tableItem.percentWornTarget : 0
            });
          });
          const columnDefinitions = item.columnDefinitions;
          columnDefinitions.forEach((columnItem, j) => {
            let colDefnString = columnItem.text.split(".");
            let masterKey = colDefnString[0];
            let key = colDefnString[1];
            let labelName = this.globalizationMapping[masterKey][key];

            let labelId = columnItem.fieldName;

            this.headCellsTable1.push({
              key: labelId,
              keyIndex: j,
              value: labelId,
              label: labelName,
              numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
            });
          });
          this.headCells.push(this.headCellsTable1);
          let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells[index], index);
          let selectedColShow = this.initDisplayColumnData(this.headCells[index]);
          this.dailyJobSummaryData.push({
            rowsData: this.dailyJobSummaryTableData1,
            headCells: orderedHeadCells,
            avgValues: this.avgValues(this.dailyJobSummaryData1, index),
            selectedColShow: selectedColShow
          });
          break;
        }
        case 2: {
          tableData.forEach((tableItem, i) => {
            this.dailyJobSummaryTableData2.push({
              Key: i,
              Manufacturer: tableItem.manufacturer ? tableItem.manufacturer : "",
              Last24Hours: tableItem.last24Hours ? tableItem.last24Hours : 0,
              MonthToDate: tableItem.monthToDate ? tableItem.monthToDate : 0,
              LastMonth: tableItem.lastMonth ? tableItem.lastMonth : 0,
              YearToDate: tableItem.yearToDate ? tableItem.yearToDate : 0,
              PrevYearToDate: tableItem.prevYearToDate ? tableItem.prevYearToDate : 0
            });
          });
          const columnDefinitions = item.columnDefinitions;
          columnDefinitions.forEach((columnItem, j) => {
            let colDefnString = columnItem.text.split(".");
            let masterKey = colDefnString[0];
            let key = colDefnString[1];
            let labelName = this.globalizationMapping[masterKey][key];

            let labelId = columnItem.fieldName;

            this.headCellsTable2.push({
              key: labelId,
              keyIndex: j,
              value: labelId,
              label: labelName,
              numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
            });

            if (j === 1) this.headCellsTable2[j].label += " & " + reportEndDate;
            else if (j === 2) this.headCellsTable2[j].label += " & " + month;
            else if (j === 3) this.headCellsTable2[j].label += " & " + lastMonth;
            else if (j === 4) this.headCellsTable2[j].label += " & " + year;
            else if (j === 5) this.headCellsTable2[j].label += " & " + lastYear;
          });
          this.headCells.push(this.headCellsTable2);
          let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells[index], index);
          let selectedColShow = this.initDisplayColumnData(this.headCells[index]);
          this.dailyJobSummaryData.push({
            rowsData: this.dailyJobSummaryTableData2,
            headCells: orderedHeadCells,
            avgValues: this.avgValues(this.dailyJobSummaryTableData2, index),
            selectedColShow: selectedColShow
          });
          break;
        }
        case 3: {
          tableData.forEach((tableItem, i) => {
            this.dailyJobSummaryTableData3.push({
              Key: i,
              TireSize: tableItem.tireSize ? tableItem.tireSize : "",
              Last24Hours: tableItem.last24Hours ? tableItem.last24Hours : 0,
              MonthToDate: tableItem.monthToDate ? tableItem.monthToDate : 0,
              LastMonth: tableItem.lastMonth ? tableItem.lastMonth : 0,
              YearToDate: tableItem.yearToDate ? tableItem.yearToDate : 0,
              PrevYearToDate: tableItem.prevYearToDate ? tableItem.prevYearToDate : 0
            });
          });
          const columnDefinitions = item.columnDefinitions;
          columnDefinitions.forEach((columnItem, j) => {
            let colDefnString = columnItem.text.split(".");
            let masterKey = colDefnString[0];
            let key = colDefnString[1];
            let labelName = this.globalizationMapping[masterKey][key];

            let labelId = columnItem.fieldName;

            this.headCellsTable3.push({
              key: labelId,
              keyIndex: j,
              value: labelId,
              label: labelName,
              numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
            });

            if (j === 1) this.headCellsTable3[j].label += " & " + reportEndDate;
            else if (j === 2) this.headCellsTable3[j].label += " & " + month;
            else if (j === 3) this.headCellsTable3[j].label += " & " + lastMonth;
            else if (j === 4) this.headCellsTable3[j].label += " & " + year;
            else if (j === 5) this.headCellsTable3[j].label += " & " + lastYear;
          });
          this.headCells.push(this.headCellsTable3);
          let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells[index], index);
          let selectedColShow = this.initDisplayColumnData(this.headCells[index]);
          this.dailyJobSummaryData.push({
            rowsData: this.dailyJobSummaryTableData3,
            headCells: orderedHeadCells,
            avgValues: this.avgValues(this.dailyJobSummaryTableData3, index),
            selectedColShow: selectedColShow
          });
          break;
        }
        default:
          break;
      }

      /** NOTE : the keys must match the headCells value to be able to SORT */
    });

    return this.dailyJobSummaryData;
    // TABLE HEADER DATA
  }

  search(originalTableData, searchText, index) {
    switch (index) {
      case 0:
      case 1: {
        return originalTableData.filter((item) => {
          return (
            item.ReportingPeriods.toLowerCase().includes(searchText) ||
            item.TireCount.toString().includes(searchText) ||
            item.AverageHours.toString().includes(searchText) ||
            item.PercentHourTarget.toString().includes(searchText) ||
            item.AverageWorn.toString().includes(searchText) ||
            item.PercentWornTarget.toString().includes(searchText)
          );
        });
      }
      case 2: {
        return originalTableData.filter((item) => {
          return (
            item.Manufacturer.toLowerCase().includes(searchText) ||
            item.Last24Hours.toString().includes(searchText) ||
            item.MonthToDate.toString().includes(searchText) ||
            item.LastMonth.toString().includes(searchText) ||
            item.YearToDate.toString().includes(searchText) ||
            item.PrevYearToDate.toString().includes(searchText)
          );
        });
      }
      case 3: {
        return originalTableData.filter((item) => {
          return (
            item.TireSize.toLowerCase().includes(searchText) ||
            item.Last24Hours.toString().includes(searchText) ||
            item.MonthToDate.toString().includes(searchText) ||
            item.LastMonth.toString().includes(searchText) ||
            item.YearToDate.toString().includes(searchText) ||
            item.PrevYearToDate.toString().includes(searchText)
          );
        });
      }
    }
  }
}

export default DailyJobSummaryDataSetter;
