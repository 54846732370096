import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";
import { InspectionOrderConfiguration } from "../../../constants/InspectionOrder";

class TireForecastDataSetter {
  constructor() {
    this.data = {};
    this.tireForecastData = [];
    this.tireForecastVehicleData = [];
    this.headCells = [];
    this.tireVehicleMappedData = [];
    this.tireForecastMapping = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  tableHeaderOrderSetter(headCells) {
    let tableHeaders = headCells;
    // maintains the column order to be displayed (write them in the order you want to display table header)
    let headerOrder = [
      { columnNo: 1, value: "WheelPosition" },
      { columnNo: 2, value: "VehicleNumber" },
      { columnNo: 3, value: "BrandNumber" },
      { columnNo: 4, value: "SerialNo" },
      { columnNo: 5, value: "ManufacturerName" },
      { columnNo: 6, value: "SizeName" },
      { columnNo: 7, value: "TypeName" },
      { columnNo: 8, value: "Compound" },
      { columnNo: 9, value: "RatingName" },
      { columnNo: 10, value: "TreadDepth" },
      { columnNo: 11, value: "PercentageWorn" },
      { columnNo: 12, value: "TotalHours" },
      { columnNo: 13, value: "TotalMiles" },
      { columnNo: 14, value: "TotalCost" },
      { columnNo: 15, value: "HoursPerTreadDepth" },
      { columnNo: 16, value: "MilesPerTreadDepth" },
      { columnNo: 17, value: "ProjectedHours" },
      { columnNo: 18, value: "ProjectedMiles" },
      { columnNo: 19, value: "ProjectedCost" },
      { columnNo: 20, value: "ProjectedCostByMiles" },
      { columnNo: 21, value: "HoursLeft" },
      { columnNo: 22, value: "MilesLeft" },
      { columnNo: 23, value: "ProjectedRemovalDate" },
      { columnNo: 24, value: "ProjectedRemovalDateByMiles" }
    ];

    let resultHeaderCell = [];
    headerOrder.forEach((headerOrder) => {
      let found = false;
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === headerOrder.value) {
          headerItem.keyIndex = headerOrder.columnNo - 1;
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });

    return resultHeaderCell;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      if (
        headCell.value !== "TotalMiles" &&
        headCell.value !== "MilesLeft" &&
        headCell.value !== "ProjectedMiles" &&
        headCell.value !== "ProjectedCostByMiles" &&
        headCell.value !== "ProjectedRemovalDateByMiles" &&
        headCell.value !== "MilesPerTreadDepth"
      )
        selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item, index) => {
      return (
        item.ParentAccountName.toLowerCase().includes(searchText) ||
        item.LocationName.toLowerCase().includes(searchText) ||
        item.InspectionName.toLowerCase().includes(searchText) ||
        item.InspectionOrderId.toString().includes(searchText) ||
        item.VehicleId.toLowerCase().includes(searchText) ||
        item.AssetNumber.toLowerCase().includes(searchText) ||
        item.ModelName.toLowerCase().includes(searchText) ||
        item.Manufacturer.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.CurrentHours.toString().includes(searchText) ||
        item.LoadingRequirement.toLowerCase().includes(searchText) ||
        item.SpeedName.toLowerCase().includes(searchText) ||
        item.CuttingName.toLowerCase().includes(searchText)
      );
    });
  }

  // filters rows data based on the vehicle data update
  setRowsDataOnUpdate = (filteredVehicleData, originalRowsDataStore) => {
    let updatedRowsData = [];
    filteredVehicleData.forEach((vehicleDataItem) => {
      originalRowsDataStore.forEach((rowsDataItem, indexKey) => {
        if (vehicleDataItem.Key === indexKey) {
          updatedRowsData.push(rowsDataItem);
        }
      });
    });
    return updatedRowsData;
  };

  setAxleDataOnUpdate = (filteredVehicleData, originalFleetVehicleMappedDataStore) => {
    let updatedAxleData = [];
    filteredVehicleData.forEach((vehicleDataItem) => {
      originalFleetVehicleMappedDataStore.forEach((rowsDataItem, indexKey) => {
        if (vehicleDataItem.Key === indexKey) {
          updatedAxleData.push(rowsDataItem);
        }
      });
    });
    return updatedAxleData;
  };

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) 
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data) {
    // TABLE ROWS DATA
    let reportData = data.model.reportData;
    reportData = reportData.filter((elem) => {
      if (elem.tireList.length !== 0) {
        return elem;
      }
    });

    reportData.forEach((elem) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      let tempData = [];
      elem.tireList.forEach((item, index) => {
        tempData.push({
          Key: index,
          WheelPosition: item.wheelPosition ? item.wheelPosition : "",
          BrandNumber: item.brandNumber ? item.brandNumber : "",
          SerialNo: item.serialNo ? item.serialNo : "",
          ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
          SizeName: item.sizeName ? item.sizeName : "",
          TypeName: item.typeName ? item.typeName : "",
          Compound: item.compound ? item.compound : "",
          RatingName: item.ratingName ? item.ratingName : "",
          TreadDepth: item.treadDepth ? item.treadDepth : 0,
          PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
          TotalHours: item.totalHours ? item.totalHours : 0,
          TotalMiles: item.totalMiles ? item.totalMiles : 0,
          TotalCost: item.totalCost ? item.totalCost : 0,
          HoursPerTreadDepth: item.hoursPerTreadDepth ? item.hoursPerTreadDepth : 0,
          MilesPerTreadDepth: item.milesPerTreadDepth ? item.milesPerTreadDepth : 0,
          ProjectedHours: item.projectedHours ? item.projectedHours : 0,
          ProjectedCost: item.projectedCost ? item.projectedCost : 0,
          HoursLeft: item.hoursLeft ? item.hoursLeft : 0,
          ProjectedRemovalDate: item.projectedRemovalDate ? item.projectedRemovalDate : "",
          MilesLeft: item.milesLeft ? item.milesLeft : 0,
          ProjectedMiles: item.projectedMiles ? item.projectedMiles : 0,
          ProjectedCostByMiles: item.projectedCostByMiles ? item.projectedCostByMiles : 0,
          ProjectedRemovalDateByMiles: item.projectedRemovalDateByMiles ? item.projectedRemovalDateByMiles : 0,
          VehicleNumber: elem.vehicleData.vehicleNumber ? elem.vehicleData.vehicleNumber : ""
        });
      });
      this.tireForecastData.push(tempData);
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    reportData.forEach((elem, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.tireForecastVehicleData.push({
        Key: index,
        ParentAccountName: elem.vehicleData.parentAccountName ? elem.vehicleData.parentAccountName : "NA",
        LocationName: elem.vehicleData.locationName ? elem.vehicleData.locationName : "NA",
        InspectionName: elem.vehicleData.updatedBy ? elem.vehicleData.updatedBy : "NA",
        InspectionOrderId: elem.vehicleData.inspectionOrderId ? elem.vehicleData.inspectionOrderId : "NA",
        VehicleId: elem.vehicleData.vehicleNumber ? elem.vehicleData.vehicleNumber : "NA",
        AssetNumber: elem.vehicleData.assetNumber ? elem.vehicleData.assetNumber : "",
        ModelName: elem.vehicleData.modelName ? elem.vehicleData.modelName : "NA",
        Manufacturer: elem.vehicleData.manufacturer ? elem.vehicleData.manufacturer : "",
        TypeName: elem.vehicleData.typeName ? elem.vehicleData.typeName : "NA",
        CurrentHours: elem.vehicleData.currentHours ? elem.vehicleData.currentHours : "NA",
        CurrentMiles: elem.vehicleData.currentMiles ? elem.vehicleData.currentMiles : 0,
        LoadingRequirement: elem.vehicleData.averageLoadingReq ? elem.vehicleData.averageLoadingReq : "NA",
        SpeedName: elem.vehicleData.speed ? elem.vehicleData.speed : "NA",
        CuttingName: elem.vehicleData.cutting ? elem.vehicleData.cutting : "NA",
        AxleConfigName: elem.vehicleData.axleConfig ? elem.vehicleData.axleConfig : "NA",
        VehJsonData: elem.vehicleData.vehjsonFootprint ? elem.vehicleData.vehjsonFootprint : "NA"
      });
    });

    let TableData = reportData;

    let tireSummaryList = TableData.filter((data) => {
      return data.tireList;
    });
    let tireListArr = [];
    // maps tire survey data to the config file
    tireSummaryList.forEach((item) => {
      let tireListTemp = [];
      let order = item.vehicleData.inspectionOrderId;
      let axleConfig = item.vehicleData.axleConfig;
      let tireOrder = InspectionOrderConfiguration.TirePositionConfig[order][axleConfig];
 
      if (tireOrder !== undefined) {
        tireOrder.forEach((pos) => {
          item.tireList.forEach((tire) => {
            if (pos === tire.wheelPosition) {
              tireListTemp.push(tire);
            }
          });
        });
      }
      tireListArr.push(tireListTemp);
    });

    tireListArr.forEach((elem) => {
      let tempTireForecast = [];
      elem.forEach((item, index) => {
        tempTireForecast.push({
          Key: index,
          WheelPosition: item.wheelPosition ? item.wheelPosition : "",
          BrandNumber: item.brandNumber ? item.brandNumber : "",
          SerialNo: item.serialNo ? item.serialNo : "",
          ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
          SizeName: item.sizeName ? item.sizeName : "",
          TypeName: item.typeName ? item.typeName : "",
          Compound: item.compound ? item.compound : "",
          RatingName: item.ratingName ? item.ratingName : "",
          TreadDepth: item.treadDepth ? item.treadDepth : 0,
          PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
          TotalHours: item.totalHours ? item.totalHours : 0,
          TotalMiles: item.totalMiles ? item.totalMiles : 0,
          TotalCost: item.totalCost ? item.totalCost : 0,
          HoursPerTreadDepth: item.hoursPerTreadDepth ? item.hoursPerTreadDepth : 0,
          MilesPerTreadDepth: item.milesPerTreadDepth ? item.milesPerTreadDepth : 0,
          ProjectedHours: item.projectedHours ? item.projectedHours : 0,
          ProjectedCost: item.projectedCost ? item.projectedCost : 0,
          HoursLeft: item.hoursLeft ? item.hoursLeft : 0,
          ProjectedRemovalDate: item.projectedRemovalDate ? this.commonUtils.tableDateFormatter(item.projectedRemovalDate) : "",
          MilesLeft: item.milesLeft ? item.milesLeft : 0,
          ProjectedMiles: item.projectedMiles ? item.projectedMiles : 0,
          ProjectedCostByMiles: item.projectedCostByMiles ? item.projectedCostByMiles : 0,
          ProjectedRemovalDateByMiles: item.projectedRemovalDateByMiles ? this.commonUtils.tableDateFormatter(item.projectedRemovalDateByMiles) : "",
          VehicleNumber: item.vehicleNumber ? item.vehicleNumber : ""
        });
      });
      this.tireForecastMapping.push(tempTireForecast);
    });

    // maps filter options to the config file data
    let rowsDataFilterOptions = [];
    this.tireForecastData.forEach((tireListItem, indexKey) => {
      if (tireListItem.length > 0) {
        rowsDataFilterOptions.push(this.tireForecastVehicleData[indexKey]);
      }
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);
    let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells);
    return {
      rowsData: this.tireForecastMapping,
      headCells: orderedHeadCells,
      selectedColShow: selectedColShow,
      vehicleData: this.tireForecastVehicleData,
      tireVehicleMappedData: tireListArr,
      rowsDataFilterOptions: rowsDataFilterOptions
    };
  }
}

export default TireForecastDataSetter;
