import React, { useState, useEffect } from "react";
import "../../../../../../common-styles/SurveyTemplateContainer.css";
import { SurveyTemplateConfiguration } from "../../../../services/TemplateConfigData";
import OneAxleNavigation from "../1-Axle-Navigation/OneAxleNavigation";
import { Card, CardContent } from "@material-ui/core";
import SurveyTemplateService from "../../../../services/SurveyTemplateApis";
import Spinner from "../../../../../../common-components/spinner/spinner";
import SurveyTemplate from "../../../template/SurveyTemplate";

const OneAxleFourTires = () => {
  const configName =
    SurveyTemplateConfiguration.ConfigurationNames.OneAxleFourTires;

  const surveyTemplateService = new SurveyTemplateService();
  const [showSpinner, setShowSpinner] = useState(true);
  const [axleConfigurationDetails, setAxleConfigurationDetails] = useState();

  useEffect(() => {
    let requestObject = {};
    setShowSpinner(true);
    surveyTemplateService
      .getSurveyTemplateMasterConfig(requestObject)
      .then((data) => {
        let currentAxleConfig;
        data.model.axleConfigs.forEach((config) => {
          if (config.configurationName === configName) {
            currentAxleConfig = config;
          }
        });
        setAxleConfigurationDetails(currentAxleConfig);
        setShowSpinner(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <OneAxleNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="survey-template-container">
          <Card>
            <CardContent>
              <SurveyTemplate
                axleConfigurationDetails={axleConfigurationDetails}
              />
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};

export default OneAxleFourTires;
