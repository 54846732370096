import React, { useEffect, useState } from "react";
import "../../../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import "./TireForecastTable.css";
import VehicleDiagram from "../vehicle-diagram/VehicleDiagram";
import NoDataFound from "../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../services/utils";
import Globalization from "../../../../../../globalization";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const globalizationMapping = new Globalization();

const commonUtils = new CommonUtils();

const removeDateNaN = (row, projectedProperty, checkProperty) => {
  let item = JSON.parse(JSON.stringify(row[projectedProperty]));

  if (row[checkProperty] === 0) {
    item = 'N/A';
  }

  return item && item !== "NaN/NaN/NaN" ? item : "";
}


// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    let firstVal = a[orderBy].toLowerCase().trim()
    let secondVal = b[orderBy].toLowerCase().trim()
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;

  } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => {
    el.ProjectedRemovalDate = new Date(commonUtils.tableDateFormatter(el.ProjectedRemovalDate));
    return [el, index];
  });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => {
    el[0].ProjectedRemovalDate = GetFormattedDate(el[0].ProjectedRemovalDate);
    return el[0];
  });
}

const DateFormat = (newDate) => {
  if (newDate === "NaN/NaN/NaN") {
    return "N/A"
  }
  else if (newDate === "" || newDate === "01/01/0001") {
    return "";
  } else if (sessionStorage.userLanguage === "en-uk") {
    const dateArray = newDate.split("/");
    return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
  } else {
    return newDate;
  }
}
const GetFormattedDate = (inputDate) => {
  let todayTime = inputDate;
  let month = todayTime.getMonth() + 1;
  let day = todayTime.getDate();
  let year = todayTime.getFullYear();
  let newDate = month + "/" + day + "/" + year;
  newDate = DateFormat(newDate)
  return newDate;
};

const TireForecastTable = ({ tableData, filterApplied, showColumns, toggleHourMiles }) => {
  const [tireForecastTableData, setTireForecastTableData] = useState(tableData);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [pageVal, setPageVal] = useState(1);

  useEffect(() => {
    setTireForecastTableData(tableData);
    setPage(0);
    setPageVal(1);
  }, [tableData, showColumns]);

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const rowsPerPage = 10;
  const itemsPerPage = 5;
  const noOfPages = Math.ceil(Object.keys(tireForecastTableData.rowsData).length / itemsPerPage);

  const handleChange = (event, newPage) => {
    setPageVal(newPage);
  };

  let count = -1;
  const classNameSet = (keyIndex, value, c) => {
    count = c;
    switch (c) {
      case -1:
        return "table-row";
      case 0:
        return "fixed-columns first-tire-col table-head-row";
      case 1:
        return "fixed-columns second-tire-col table-head-row";
      case 2:
        return "fixed-columns third-tire-col table-head-row";
      case 3:
        return "fixed-columns fourth-tire-col table-head-row";
      default:
        return "table-head-row";
    }
  };
  let countBody = -1;
  const classNameSetBody = (c) => {
    countBody = c;
    switch (countBody) {
      case -1:
        return "table-row";
      case 0:
        return "fixed-column-body first-tire-col table-content-fleet-text";
      case 1:
        return "fixed-column-body second-tire-col table-content-fleet-text";
      case 2:
        return "fixed-column-body third-tire-col table-content-fleet-text";
      case 3:
        return "fixed-column-body fourth-tire-col table-content-fleet-text";
      default:
        return "table-content-fleet-text";
    }
  };

  const firstRecord = Number((pageVal - 1) * itemsPerPage + 1);
  const lastRecord =
    Number(pageVal * itemsPerPage) < Object.keys(tireForecastTableData.rowsData).length ? Number(pageVal * itemsPerPage) : Object.keys(tireForecastTableData.rowsData).length;

  return (
    <div className="container">
      {tireForecastTableData.rowsData !== null &&
        tireForecastTableData.rowsData !== undefined &&
        tireForecastTableData.rowsData !== "" &&
        Object.keys(tireForecastTableData.rowsData)
          .slice((pageVal - 1) * itemsPerPage, pageVal * itemsPerPage)
          .map((key, index) => {
            return (
              <div className={classes.root} key={index}>
                {tireForecastTableData.rowsData[key].length > 0 && (
                  <React.Fragment>
                    <div className="vehicle-container">
                      <Grid spacing={3} container className="forecast-vehicle-container">
                        <Grid item xs={6} sm={3}>
                          <div className="heading-column-data">{globalizationMapping.REPORTCOMMONWORDS.RecordNo}</div>
                          <div className="font-wt-500">0{tireForecastTableData.vehicleData[key].Key + 1}</div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className="heading-column-data">{globalizationMapping.REPORTTABLE.ACCOUNTNAME}</div>
                          <div className="font-wt-500">{tireForecastTableData.vehicleData[key].ParentAccountName}</div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className="heading-column-data">{globalizationMapping.REPORTTABLE.Location}</div>
                          <div className="font-wt-500">{tireForecastTableData.vehicleData[key].LocationName}</div>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <div className="heading-column-data">{globalizationMapping.REPORTTABLE.INSPID}</div>
                          <div className="font-wt-500">{tireForecastTableData.vehicleData[key].InspectionName}</div>
                        </Grid>
                      </Grid>
                      <Grid spacing={3} container className="grid-data-container">
                        <Grid item xs={12} md={tireForecastTableData.vehicleData[key].AxleConfigName.length < 5 ? 3 : 6} className="diagram-container">
                          <VehicleDiagram
                            data={tireForecastTableData.vehicleData[key].AxleConfigName}
                            key={key + "-" + tireForecastTableData.vehicleData[key].Key + 1}
                            tireListData={tireForecastTableData.tireVehicleMappedData && tireForecastTableData.tireVehicleMappedData[key]}
                          />
                        </Grid>
                        <Grid container item xs={12} md={tireForecastTableData.vehicleData[key].AxleConfigName.length < 5 ? 9 : 6} className="vehicle-details-container">
                          <Grid container item xs={12} sm={12} className="vehicle-basic-details padding-2em">
                            <Grid item xs={12} className="vehicle-header-info">
                              {globalizationMapping.REPORTCOMMONWORDS.VehicleDetails}{" "}
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">{globalizationMapping.REPORTTABLE.VehicleName}</div>
                              <div>{tireForecastTableData.vehicleData[key].VehicleId}</div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">{globalizationMapping.REPORTTABLE.ASSETNUMBER}</div>
                              <div>{tireForecastTableData.vehicleData[key].AssetNumber}</div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">{globalizationMapping.REPORTCOMMONWORDS.MakeModel}</div>
                              <div>
                                {tireForecastTableData.vehicleData[key].Manufacturer}/{tireForecastTableData.vehicleData[key].ModelName}
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">{globalizationMapping.REPORTTABLE.TYPE}</div>
                              <div>{tireForecastTableData.vehicleData[key].TypeName}</div>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <div className="heading-column-data">
                                {globalizationMapping.MANAGEVEHICLE.HOURS}/
                                {JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
                                  ? globalizationMapping.MANAGEVEHICLE.KM
                                  : globalizationMapping.MANAGEVEHICLE.MILES}
                              </div>
                              <div>
                                {tireForecastTableData.vehicleData[key].CurrentHours}/{Math.round(tireForecastTableData.vehicleData[key].CurrentMiles)}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} sm={12} className="vehicle-operation-details padding-1em">
                            <Grid container item xs={12} className="vehicle-header-info">
                              {globalizationMapping.TIREFORECAST.VehicleOperationParameters}
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <div className="heading-column-data">{globalizationMapping.TIREFORECAST.LoadingRequirements}</div>
                              <div>{tireForecastTableData.vehicleData[key].LoadingRequirement}</div>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <div className="heading-column-data">{globalizationMapping.TIREFORECAST.SpeedHeat}</div>
                              <div>{tireForecastTableData.vehicleData[key].SpeedName}</div>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                              <div className="heading-column-data">{globalizationMapping.TIREFORECAST.Cutting}</div>
                              <div>{tireForecastTableData.vehicleData[key].CuttingName}</div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <Paper className={classes.paper}>
                      <TableContainer className={classes.container}>
                        <Table
                          stickyHeader
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          aria-label="a dense table"
                          size="small"
                        >
                          <TableHead>
                            <TableRow className={classNameSet(-1, -1, -1)}>
                              {tireForecastTableData.headCells.map((headCell) =>
                                showColumns[headCell.value] ? (
                                  <TableCell
                                    className={classNameSet(headCell.keyIndex, headCell.value, ++count)}
                                    key={headCell.keyIndex}
                                    align="left"
                                    sortDirection={orderBy === headCell.value ? order : false}
                                  >
                                    <TableSortLabel
                                      active={orderBy === headCell.value}
                                      direction={orderBy === headCell.value ? order : "asc"}
                                      onClick={handleRequestSort(headCell.value)}
                                    >
                                      {headCell.label}
                                      {orderBy === headCell.value ? (
                                        <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                                      ) : null}
                                    </TableSortLabel>
                                  </TableCell>
                                ) : null
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {stableSort(tireForecastTableData.rowsData[key], getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((dataItem, index) => {
                                return (
                                  <TableRow className={classNameSetBody(-1)} tabIndex={-1} key={dataItem.Key}>
                                    {showColumns.WheelPosition && <TableCell className={classNameSetBody(++countBody)}>{dataItem.WheelPosition}</TableCell>}
                                    {showColumns.VehicleNumber && <TableCell className={classNameSetBody(++countBody)}>{dataItem.VehicleNumber}</TableCell>}
                                    {showColumns.BrandNumber && <TableCell className={classNameSetBody(++countBody)}>{dataItem.BrandNumber}</TableCell>}
                                    {showColumns.SerialNo && <TableCell className={classNameSetBody(++countBody)}>{dataItem.SerialNo}</TableCell>}
                                    {showColumns.ManufacturerName && <TableCell className={classNameSetBody(++countBody)}>{dataItem.ManufacturerName}</TableCell>}
                                    {showColumns.SizeName && <TableCell className={classNameSetBody(++countBody)}>{dataItem.SizeName}</TableCell>}
                                    {showColumns.TypeName && <TableCell className={classNameSetBody(++countBody)}>{dataItem.TypeName}</TableCell>}
                                    {showColumns.Compound && <TableCell className={classNameSetBody(++countBody)}>{dataItem.Compound}</TableCell>}
                                    {showColumns.RatingName && <TableCell className={classNameSetBody(++countBody)}>{dataItem.RatingName}</TableCell>}
                                    {showColumns.TreadDepth && <TableCell className={classNameSetBody(++countBody)}>{dataItem.TreadDepth}</TableCell>}
                                    {showColumns.PercentageWorn &&
                                      (<Tooltip title={commonUtils.wornPercentageInfo(dataItem.PercentageWorn)} placement="top-end">
                                        <TableCell className={classNameSetBody(++countBody) + " " + commonUtils.wornPercentageClass(dataItem.PercentageWorn)}>{dataItem.PercentageWorn}%</TableCell>
                                      </Tooltip>
                                      )}
                                    {showColumns.TotalHours && <TableCell className={classNameSetBody(++countBody)}>{dataItem.TotalHours}</TableCell>}
                                    {showColumns.TotalMiles && <TableCell className={classNameSetBody(++countBody)}>{dataItem.TotalMiles}</TableCell>}
                                    {showColumns.TotalCost && <TableCell className={classNameSetBody(++countBody)}>{dataItem.TotalCost}</TableCell>}
                                    {showColumns.HoursPerTreadDepth && <TableCell className={classNameSetBody(++countBody)}>{dataItem.HoursPerTreadDepth}</TableCell>}
                                    {showColumns.MilesPerTreadDepth && <TableCell className={classNameSetBody(++countBody)}>{dataItem.MilesPerTreadDepth}</TableCell>}
                                    {showColumns.ProjectedHours && <TableCell className={classNameSetBody(++countBody)}>{dataItem.ProjectedHours}</TableCell>}
                                    {showColumns.ProjectedMiles && <TableCell className={classNameSetBody(++countBody)}>{dataItem.ProjectedMiles}</TableCell>}
                                    {showColumns.ProjectedCost && <TableCell className={classNameSetBody(++countBody)}>{dataItem.ProjectedCost}</TableCell>}
                                    {showColumns.ProjectedCostByMiles && <TableCell className={classNameSetBody(++countBody)}>{dataItem.ProjectedCostByMiles}</TableCell>}
                                    {showColumns.HoursLeft && <TableCell className={classNameSetBody(++countBody)}>{dataItem.HoursLeft}</TableCell>}
                                    {showColumns.MilesLeft && <TableCell className={classNameSetBody(++countBody)}>{dataItem.MilesLeft}</TableCell>}
                                    {showColumns.ProjectedRemovalDate && (
                                      <TableCell className={classNameSetBody(++countBody)}>{removeDateNaN(dataItem, 'ProjectedRemovalDate', 'ProjectedHours')}</TableCell>
                                    )}
                                    {showColumns.ProjectedRemovalDateByMiles && (
                                      <TableCell className={classNameSetBody(++countBody)}>{removeDateNaN(dataItem, 'ProjectedRemovalDateByMiles', 'ProjectedMiles')}</TableCell>
                                    )}

                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </React.Fragment>
                )}
              </div>
            );
          })}
      {tireForecastTableData.rowsData.length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="table"></NoDataFound>
        </div>
      )}
      {tireForecastTableData.rowsData !== null && tireForecastTableData.rowsData !== undefined && tireForecastTableData.rowsData.length > 0 && (
        <React.Fragment>
          <div className="record-data">
            Showing {firstRecord} to {lastRecord} of {Object.keys(tireForecastTableData.rowsData).length} records
          </div>
          <Pagination
            count={noOfPages}
            page={pageVal}
            onChange={handleChange}
            defaultPage={1}
            shape="rounded"
            //size="small"
            className="pagination-vehicle-report"
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default TireForecastTable;
