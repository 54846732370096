import { Backdrop, Button, makeStyles, Modal } from '@material-ui/core';
import React, { useEffect } from 'react';
import Globalization from '../../../../../globalization';

const useStyles = makeStyles({
    proceed: {
        "&:hover": {
            opacity: 1
        },
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: 'Barlow',
        opacity: .8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none"
    },
    cancel: {
        "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none"
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: .8,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none"
    }
});

const ResetHoursMilesModal = props => {

    const styles = useStyles();

    const globalizationMapping = new Globalization();
    const titleTexts = globalizationMapping.FORMS;
    const modalTexts = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleYesClick = () => {
        props.onResetHoursMiles();
        props.closeResetModal();
    };

    return (
        <div className="container">
            <Modal
                open={props.open}
                onClose={props.closeResetModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 300
                }}
                disableScrollLock={true}
            >
                <div className="delete-tire-modal-container center-modal">
                    <div className="delete-tire-title-container">
                        <div className="delete-tire-title">
                            {modalTexts.RESETHOURSMILES}
                        </div>
                        <div
                            className="close-icon-container"
                            onClick={props.closeResetModal}>
                            <img
                                src={require("../../../../../assets/close-icon.svg")}
                                className="modal-close-icon"
                                alt="close-icon"
                            />
                        </div>
                    </div>
                    <div className="delete-modal-description">
                        {modalTexts.RESETHOURSMILESDESC}
                    </div>
                    <div className="modal-action-buttons">
                        <Button
                            className={styles.cancel}
                            variant="contained"
                            disableElevation={true}
                            onClick={
                                props.closeResetModal
                            }
                        >
                            {titleTexts.NO}
                        </Button>
                        <Button
                            className={styles.proceed}
                            variant="contained"
                            disableElevation={true}
                            onClick={
                                () => { handleYesClick(); }
                            }
                        >
                            {titleTexts.YES}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ResetHoursMilesModal;
