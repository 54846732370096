import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
// eslint-disable-next-line no-unused-vars


const ByTypeGraph = ({ byTypeGraphData, label }) => {
  const [chartInfoDataForMile, setChartInfoDataForMile] = useState({});
  const [chartInfoData, setChartInfoData] = useState({});
  const commonUtils = new CommonUtils();
  const globalizationMapping = new Globalization();
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const optionsForMile = JSON.parse(JSON.stringify(commonUtils.barOptions));
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["type-x"];
  optionsForMile.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["type-x"];

  options.scales.yAxes[0].scaleLabel.labelString =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["type-y"]
      : globalizationHeaders["type-y-32"];
  options.title.text =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["type-title"]
      : globalizationHeaders["type-title-32"];

  optionsForMile.scales.yAxes[0].scaleLabel.labelString =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["tire-type-km-title"]
      : globalizationHeaders['AVERAGEMILES/32"'];
  optionsForMile.title.text =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["tire-type-km-title-graph"]
      : globalizationHeaders["type-title-miles-32"];


  const chart = () => {
    if (byTypeGraphData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();

      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byTypeGraphData.forEach((item) => {
        graphDataSum[item.TypeName] = 0;
        graphDataAvg[item.TypeName] = 0;
        graphDataCount[item.TypeName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byTypeGraphData.forEach((item) => {
        graphDataSum[item.TypeName] += item.AverageHoursPerMM;
        graphDataCount[item.TypeName]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((typeName) => {
        graphDataAvg[typeName] = Math.round(
          graphDataSum[typeName] / graphDataCount[typeName]
        );
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoData({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };
  const chartMiles = () => {
    if (byTypeGraphData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();

      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byTypeGraphData.forEach((item) => {
        graphDataSum[item.TypeName] = 0;
        graphDataAvg[item.TypeName] = 0;
        graphDataCount[item.TypeName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byTypeGraphData.forEach((item) => {
        graphDataSum[item.TypeName] += item.AverageMilesPerMM;
        graphDataCount[item.TypeName]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((typeName) => {
        graphDataAvg[typeName] = Math.round(
          graphDataSum[typeName] / graphDataCount[typeName]
        );
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoDataForMile({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoDataForMile({});
    }
  };
  useEffect(() => {
    chart();
    chartMiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byTypeGraphData]);
  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && label === "hours" && (
          <Bar data={chartInfoData} height={120} options={options} />
        )}
        {Object.keys(chartInfoDataForMile).length > 0 && label === "miles" && (
          <Bar
            data={chartInfoDataForMile}
            height={120}
            options={optionsForMile}
          />
        )}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ByTypeGraph;
