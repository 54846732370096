const initialAccountState = {
  account: { accountName: "", accountId: "0000" },
  location: [
    { value: 0, label: "" }
    // { value: 1023, label: "Kokkola" }
  ],
  isUpdated: false,
  userSetting: {
    userId: "",
    userLanguage: "en-us",
    userMeasurementSystemValue: "metric",
    userPressureUnit: "PSI",
    updatedBy: "",
    logoLocation: null,
    updatedDate: "",
    lastAccessedAccountId: "0000",
    lastAccessedLocationId: "0000",
    lastAccessedAccountName: "",
    lastAccessedLocationName: ""
  },
  userProfile: {
    countryCode: "",
    emailAddress: null,
    firstName: "",
    lastName: "",
    middleName: "",
    phoneNumber: "",
    updatedDate: "",
    userId: "",
    userRole: null
  }
};

let accountData = {};

export const setAccountData = (data) => {
  accountData = data;
  localStorage.setItem("accountInfo", JSON.stringify(data));
  localStorage.setItem("accountInfouserMeasurementSystemValue", JSON.stringify(data.userSetting.userMeasurementSystemValue));
};

export const getAccountData = () => {
  if (localStorage.getItem("accountInfo")) {
    accountData = JSON.parse(localStorage.getItem("accountInfo"));
  } else {
    // WHEN APP LOADS INITIALLY
    const initAccountData = initialAccountState;
    setAccountData(initAccountData);
    accountData = JSON.parse(localStorage.getItem("accountInfo"));
  }
  return accountData;
};

export const accountInfoIdsFormatter = (accountDataLocal) => {
  const accountInfoIdsObject = { accountIds: [], locationIds: [] };
  // formats the ids as needed by request object
  accountInfoIdsObject.accountIds.push(accountDataLocal.accountInfo.account.accountId);
  accountDataLocal.accountInfo.location.forEach((item) => {
    accountInfoIdsObject.locationIds.push(item.value);
  });
  return accountInfoIdsObject;
};

export const getAccountNames = () => {
  const accountDataLocal = JSON.parse(localStorage.getItem("accountInfo"));
  const accountName = [];
  accountName.push(accountDataLocal.account.accountName);
  return accountName;
};
export const getLocationNames = () => {
  const accountDataLocal = JSON.parse(localStorage.getItem("accountInfo"));
  const locationName = [];
  for (const item of accountDataLocal.location) {
    locationName.push(item.label);
  }
  return locationName;
};

export const setDashboardGraphLocationData = (dashboardGraphLocationData) => {
  localStorage.setItem("dashboardGraphLocationData", JSON.stringify(dashboardGraphLocationData));
};

export const getDashboardGraphLocationData = () => {
  return JSON.parse(localStorage.getItem("dashboardGraphLocationData"));
};
