import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import "../common-modal/CommonPopup.css";
import CommonUtils from "../../../services/utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExportVehicleXls = (props) => {
  const commonUtils = new CommonUtils();
  const [rowsData, setRowsData] = useState(props.exportXlsx.rowsData);
  const [headCells, setHeadCells] = useState(props.exportXlsx.headCells);
  useEffect(() => {
    const headCellArr = [];
    let rowData = [];
    props.exportXlsx.headCells.forEach((headCell, _index) => {
      if (props.exportXlsx.selectedColShow[headCell.value] === true) {
        headCellArr.push(headCell);
      }
    });
    setHeadCells(headCellArr);
    props.exportXlsx.rowsData.forEach((row, _index) => {
      row.forEach((elem) => {
        let rowJson = {};
        Object.keys(elem).forEach((key, _id) => {
          if (key !== "Key" && props.exportXlsx.selectedColShow[key] === true) {
            rowJson[key] = elem[key];
          }
          return true;
        });
        rowData.push(rowJson);
      });
    });
    setRowsData(rowData);
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        { rowsData: rowsData, headCells: headCells },
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setRowsData(data.rowsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  let tempArray = [];
  rowsData.forEach((element) => {
    tempArray = tempArray.concat(element);
  });

  return (
    <ExcelFile
      filename={`${props.exportXlsx.reportName}`}
      element={<div className="export-xlsx-button">XLS</div>}
    >
      <ExcelSheet data={tempArray} headers={headCells} name="Exported Xlsx">
        {headCells.map((key) => (
          <ExcelColumn label={key.label} value={key.key} key={key.keyIndex} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportVehicleXls;
