import React, {useEffect, useContext, useState}  from 'react';
import './FleetData.css'
import VehicleReportsNavigation from '../../vehicle-reports-navigation/VehicleReportsNavigation';
import Spinner from "../../../../../common-components/spinner/spinner";
import FleetService from "../../../services/VehicleApis";
import {StateContext} from "../../../../../services/StateProvider";
import {accountInfoIdsFormatter} from "../../../../../services/AccountInfoHandler";
import "../../../../../common-styles/TableFunctionOptions.css";
import {Card,CardContent,TextField,InputAdornment,InputLabel,
    FormControlLabel,Checkbox,Grid} from '@material-ui/core';
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import FleetDataAnalysisDataSetter from '../../../services/FleetDataAnalysisUtils'
import FleetDataTable from '../fleet-data-table/FleetDataTable'
import {FleetDataTemplatePdfGenerator} from '../../../../../common-components/fleet-data-pdf/FleetDataPdf'
import Globalization from '../../../../../globalization';
import {useComponentVisible} from "../../../../../services/CustomHooks"
import PrintEmailExportSetButtons from "../../../../../common-components/filterPrintEmailExport-SetButtons/PrintEmailExportSetButton"

const FleetData = () => {

    // GLOBAL ACCOUNT STATE management
   const [globalAccountState] = useContext(StateContext)
   const [showSpinner, setShowSpinner] = useState(true)
   const [multipleVehicleIdList, setMultipleVehicleIdList] = useState()
   const [multipleVehicleIdSelected, setMultipleVehicleIdSelected] = useState()
   const [fleetDataAnalysisTableData, setFleetDataAnalysisTableData] = useState()
   const [originalFleetDataAnalysisTableData, setOriginalFleetDataAnalysisTableData] = useState()
   const [noData, setNoData] = useState(false);
   const [excludeUsedTires,setExcludeUsedTires] = useState(false);
   const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

   const fleetService = new FleetService()
   const fleetDataAnalysisDataSetter = new FleetDataAnalysisDataSetter()

    const FleetDataResponseData = (excludeUsedTiresValue) =>{
        let accountInfoObject = accountInfoIdsFormatter(globalAccountState)
        let accountIds = accountInfoObject.accountIds
        let locationIds = accountInfoObject.locationIds

        let requestObject = {
            accountIds: accountIds,
            dateRangeType: "AllTime",
            fromDate: "",
            locationIds: locationIds,
            reportName: "Fleet_Survey",
            reportOption: "",
            toDate: "",
            usedTires:excludeUsedTiresValue.toString()
        }

        setShowSpinner(true)

        fleetService.getFleetData(requestObject).then(data => {
            let fleetDataTableInfo = fleetDataAnalysisDataSetter.setData(data)
            setFleetDataAnalysisTableData(fleetDataTableInfo)
            fleetDataTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false)
            setOriginalFleetDataAnalysisTableData(fleetDataTableInfo)
            let vehicleDataInfo = fleetDataTableInfo.vehicleData

            // for multiple id selection
            setMultipleVehicleIdList(fleetDataAnalysisDataSetter.multipleVehicleIdListSetter(vehicleDataInfo))
            setMultipleVehicleIdSelected(fleetDataAnalysisDataSetter.multipleVehicleIdListSetter(vehicleDataInfo))
            setShowSpinner(false)
            })
    }

    useEffect(() => {

      FleetDataResponseData(excludeUsedTires)
    },[globalAccountState])

    // Handle modal pop ups for export
    const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
    });

    const onClickExport = type => {
        FleetDataTemplatePdfGenerator(fleetDataAnalysisTableData,'download')
    };

    const onClickPrint = () => {
        FleetDataTemplatePdfGenerator(fleetDataAnalysisTableData,'print')
    };

    const onClickEmail = () => {
    setModalHandler({ handleEmail: true });
    };


    // USED FOR SEARCHING THE TABLES
    const searchTableData = event => {
       let searchText = event.target.value.toLowerCase()
       let originalVehicleDataStore, originalRowsDataStore, filteredVehicleData,filteredRowsData;
       originalVehicleDataStore = originalFleetDataAnalysisTableData?.vehicleData;
       originalRowsDataStore = originalFleetDataAnalysisTableData?.rowsData;

       filteredVehicleData = fleetDataAnalysisDataSetter.search(
         originalVehicleDataStore,
         searchText
       );

       filteredRowsData = fleetDataAnalysisDataSetter.setRowsDataOnUpdate(
        filteredVehicleData,
        originalRowsDataStore
      );
      filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false)
      setFleetDataAnalysisTableData({...fleetDataAnalysisTableData,rowsData : filteredRowsData,vehicleData : filteredVehicleData});
    };

    const formSubmitHandler = (e) => {
        e.preventDefault(); //should prevent submit, and continue below?
        return false;
    }
    const globalizationMapping = new Globalization();

    // FILTER ON MULTIPLE VEHICLE ID
    const selectMultipleVehicleUpdate = (selectedId) => {
      let originalVehicleDataStore = originalFleetDataAnalysisTableData?.vehicleData;
      let originalRowsDataStore = originalFleetDataAnalysisTableData?.rowsData;
      setMultipleVehicleIdSelected(selectedId);
      let filteredVehicleData = fleetDataAnalysisDataSetter.filterByMultipleVehicleId(selectedId,originalVehicleDataStore);
      let filteredRowsData = fleetDataAnalysisDataSetter.setRowsDataOnUpdate(filteredVehicleData, originalRowsDataStore);
      setFleetDataAnalysisTableData({...fleetDataAnalysisTableData,rowsData : filteredRowsData, vehicleData : filteredVehicleData});
    }

    const handleExcludeUsedTires = (event) =>
  {
      setExcludeUsedTires(event.target.checked); 
      FleetDataResponseData(event.target.checked); 
  }

    return(
    <div>
        <div className="fleet-data-container">
            <VehicleReportsNavigation/>
            {showSpinner && <Spinner />}
       {!showSpinner && (
       <div className="by-compound-container">
         <Card className="by-compound-card">
           <CardContent>
            <div className="table-toolbar">
                <div className="table-filters">
                    <div className="search-table-container">
                    <form
                        className="search-table-container"
                        noValidate
                        autoComplete="off"
                        onSubmit={formSubmitHandler}
                    >
                        <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                        </InputLabel>
                        <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        onChange={searchTableData}
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <img
                                className="keyword-search-icon-img"
                                alt=""
                                src={SearchIcon}
                                />
                            </InputAdornment>
                            )
                        }}
                        inputProps={{ maxLength: 50 }}
                        />
                    </form>
                    </div>
                    <div
                   className="select-drop-down-container"
                   id="tire-scrap-type-column"
                            >
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.VEHICLEREPORTS.BYFLEET.SelectVehicleId}</span>
                    </InputLabel>
                    <MultiSelect
                     options={multipleVehicleIdList}
                      value={multipleVehicleIdSelected}
                      onChange={selectMultipleVehicleUpdate}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: 'All Vehicle ID',
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                    </div>
                </div>
                <PrintEmailExportSetButtons
                      onClickEmail={onClickEmail}
                      onClickPrint={onClickPrint}
                      onClickExport={onClickExport}
                      toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)}
                      ref={ref}
                      noData={noData}
                      isComponentVisible={isComponentVisible}
                    />
                </div>
                <Grid container>
                  <Grid item xs={3}>
                    <div className="exclude-used-tires">
                      <FormControlLabel
                          control={
                            <Checkbox
                                checked={excludeUsedTires}
                                onChange={handleExcludeUsedTires}
                                name="checkedB"
                                color="primary"/>}
                          label={globalizationMapping.GRAPH.ExcludeUsedTires}
                          />
                      </div>
                    </Grid>
                </Grid> 
               
                <div className="fleet-data-table">
                <FleetDataTable
                        fleetDataAnalysisTableData={fleetDataAnalysisTableData}
                />
                </div> 
            </CardContent>
            </Card>
        </div>
        )}
        <EmailExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={fleetDataAnalysisTableData}
        noGraph={true}
        fleetData={true}
        />
        </div>
    </div>
    );
}

export default FleetData;
