import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  TextField,
  InputLabel,
  Grid,
  Select,
} from "@material-ui/core";
import "./FilterTabs.css";
import FilterTextField from "../filter-textfields/FilterTextFields";
import FilterDatePickers from "../filter-datepicker/FilterDatePickers";
import Globalization from "../../../../globalization";
import ExpandMoreStyledIcon from "../../../expandMoreStyledIcon/expandMoreStyledIcon";
import CommonUtils from '../../../../services/utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  gridContainer: {},
  gridItem: {
    margin: "0.5em 0 1em 1em",
  },
}));

const FilterTabs = ({
  filterOptions,
  selectedFilterOptions,
  noFieldFilter,
  noDateFilter,
  tireNeedsForecast,
  isTireForecastFilter,
  filterOptionsSelected,
  onFilterUpdate,
  confirmApplyDateFilter,
  setShowFilter,
  resetTableFilter,
  isEntryDate,
  setInServiceDateFilter,
  setOutServiceDateFilter,
  dateRangeFilter,
  setDateRangeFilter,
  inServiceDateRange,
  setInServiceDateRange,
  outServiceDateRange,
  setOutServiceDateRange,
  steeringWheelsForecastType,
  steeringWheelsFixedRemovalHours,
  drivingWheelsForecastType,
  drivingWheelsFixedRemovalHours,
  handleSteeringWheelsForecastType,
  handleSteeringWheelsFixedRemovalHours,
  handleDrivingWheelsForecastType,
  handleDrivingWheelsFixedRemovalHours,
  setSelectedFilterOptions,
  handleRemoveAt,
  removeAt
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonUtils = new CommonUtils();

  const globalizationMapping = new Globalization();
  const filterSectionWords = globalizationMapping.FILTERSECTION;
  const [value, setValue] = React.useState(0);
  let requestOptions = "AllTime";
  const [dateFilter, setDateFilter] = useState(requestOptions);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inServiceStartDate, setInServiceStartDate] = useState("");
  const [inServiceEndDate, setInServiceEndDate] = useState("");
  const [outServiceStartDate, setOutServiceStartDate] = useState("");
  const [outServiceEndDate, setOutServiceEndDate] = useState("");
  let useReferenceArray = useRef([]);
  useReferenceArray.current = Array.from({ length: filterOptions.categories.length }).map(
    (ref, index) => useReferenceArray.current[index] = React.createRef()
  )

  const handleChange = (_event, optionDate) => {
    switch (optionDate) {
      case 0:
        requestOptions = "AllTime";
        break;
      case 1:
        requestOptions = "YTD";
        break;
      case 2:
        requestOptions = "MTD";
        break;
      case 3:
        requestOptions = "DateRange";
        break;
      default:
        requestOptions = "AllTime";
    }
    setValue(optionDate);
    setDateFilter(requestOptions);
    onFilterUpdate(requestOptions);
  };

  const closeFilter = () => {
    setShowFilter(false);
  };
  const applyDateFilter = () => {
    if (noDateFilter) {
      confirmApplyDateFilter(selectedFilterOptions);
    } else {
      confirmApplyDateFilter(selectedFilterOptions, dateFilter);
    }
    closeFilter();
  };
  const resetFilter = () => {
    useReferenceArray.current.forEach(ele => ele.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click())
    resetTableFilter();
    closeFilter();
    setSelectedFilterOptions({})
  };

  const resetDateFilter = () => {
    if (!noDateFilter) {
      resetFilter();
      setValue(0);
      setDateFilter("AllTime");
      onFilterUpdate("AllTime");
      setDateRangeFilter({ fromDate: "", toDate: "" });
      setStartDate("");
      setEndDate("");
    } else {
      resetFilter();
    }
    if (setInServiceDateFilter) resetInServiceDateFilter();
    if (setOutServiceDateFilter) resetOutServiceDateFilter();
  };

  const resetInServiceDateFilter = () => {
    setInServiceDateRange({ fromDate: "", toDate: "" });
    setInServiceStartDate("");
    setInServiceEndDate("");
  };

  const resetOutServiceDateFilter = () => {
    setOutServiceDateRange({ fromDate: "", toDate: "" });
    setOutServiceStartDate("");
    setOutServiceEndDate("");
  };

  return (
    <div className={classes.root}>
      <div className="tab-header">
        <div className="tab-title">
          {!isEntryDate &&
            !isTireForecastFilter &&
            !tireNeedsForecast &&
            !noDateFilter &&
            filterSectionWords.DateFilters}
          {isEntryDate && filterSectionWords.EntryDate}
          {isTireForecastFilter && (
            <span className="table-toolbar-label-text">
              {filterSectionWords.ProjectedRemovalDateFilter}
            </span>
          )}
        </div>

        <div className="filter-header-options">
          <div className="filter-reset" onClick={resetDateFilter}>
            {filterSectionWords.Reset}
          </div>
          <div className="filter-apply" onClick={applyDateFilter}>
            {!isEntryDate && !isTireForecastFilter && filterSectionWords.Apply}
            {isEntryDate && filterSectionWords.Apply}
            {isTireForecastFilter && filterSectionWords.Apply}
          </div>
        </div>
      </div>

      {!isEntryDate && !noDateFilter && !tireNeedsForecast && (
        <div>
          <div className="date-tab-container">
            <AppBar position="static" color="default">
              <Tabs
                id="filter-tabs"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="All Time" {...a11yProps(0)} />
                <Tab label="YTD" {...a11yProps(1)} />
                <Tab label="MTD" {...a11yProps(2)} />
                <Tab label="Date Range" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
          </div>
          <TabPanel value={value} index={0} dir={theme.direction}></TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}></TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}></TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <FilterDatePickers
              dateRangeFilter={dateRangeFilter}
              setDateRangeFilter={setDateRangeFilter}
              isInService={false}
              isOutService={false}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </TabPanel>
        </div>
      )}

      {isEntryDate && (
        <div className="date-tab-container">
          <FilterDatePickers
            dateRangeFilter={dateRangeFilter}
            setDateRangeFilter={setDateRangeFilter}
            isInService={false}
            isOutService={false}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
      )}
      {setInServiceDateFilter && (
        <div className="in-service-filter">
          <div className="tab-header">
            <div className="tab-title">{filterSectionWords.InService}</div>
          </div>
          <div className="date-tab-container">
            <FilterDatePickers
              inServiceDateRange={inServiceDateRange}
              setInServiceDateRange={setInServiceDateRange}
              isInService={true}
              isOutService={false}
              startDate={inServiceStartDate}
              setStartDate={setInServiceStartDate}
              endDate={inServiceEndDate}
              setEndDate={setInServiceEndDate}
            />
          </div>
        </div>
      )}
      {setOutServiceDateFilter && (
        <div className="in-service-filter">
          <div className="tab-header">
            <div className="tab-title">{filterSectionWords.OutService}</div>
          </div>
          <div className="date-tab-container">
            <FilterDatePickers
              outServiceDateRange={outServiceDateRange}
              setOutServiceDateRange={setOutServiceDateRange}
              isInService={false}
              isOutService={true}
              startDate={outServiceStartDate}
              setStartDate={setOutServiceStartDate}
              endDate={outServiceEndDate}
              setEndDate={setOutServiceEndDate}
            />
          </div>
        </div>
      )}
      {isTireForecastFilter && (
        <>
          <Grid className={classes.gridContainer}>
            <Grid item sm={6} xs={12} className={classes.gridItem}>
              <InputLabel className="filter-table-label">
                <span className="table-toolbar-label-text">
                  {filterSectionWords.SteeringWheelsForecast}
                </span>
              </InputLabel>
              <Select
                native
                variant="outlined"
                value={steeringWheelsForecastType}
                onChange={handleSteeringWheelsForecastType}
                IconComponent={ExpandMoreStyledIcon}
              >
                <option value="treadWearRate">
                  {filterSectionWords.TreadWearRate}
                </option>
                <option value="fixedRemovalHours">
                  {filterSectionWords.FixedRemovalHours}
                </option>
              </Select>
            </Grid>
            {steeringWheelsForecastType === "fixedRemovalHours" && (
              <Grid item sm={6} xs={12} className={classes.gridItem}>
                <InputLabel className="filter-table-label">
                  <span className="table-toolbar-label-text">
                    {filterSectionWords.FixedRemovalHours}
                  </span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  value={steeringWheelsFixedRemovalHours}
                  onChange={(e) => handleSteeringWheelsFixedRemovalHours(commonUtils.minmaxInt(e.target.value, 0, 999999))}
                  type="number"
                />
              </Grid>
            )}
            <Grid item sm={6} xs={12} className={classes.gridItem}>
              <InputLabel className="filter-table-label">
                <span className="table-toolbar-label-text">
                  {filterSectionWords.DrivingWheelsForecast}
                </span>
              </InputLabel>
              <Select
                native
                variant="outlined"
                value={drivingWheelsForecastType}
                onChange={handleDrivingWheelsForecastType}
                IconComponent={ExpandMoreStyledIcon}
              >
                <option value="treadWearRate">
                  {filterSectionWords.TreadWearRate}
                </option>
                <option value="fixedRemovalHours">
                  {filterSectionWords.FixedRemovalHours}
                </option>
              </Select>
            </Grid>
            {drivingWheelsForecastType === "fixedRemovalHours" && (
              <Grid item sm={6} xs={12} className={classes.gridItem}>
                <InputLabel className="filter-table-label">
                  <span className="table-toolbar-label-text">
                    {filterSectionWords.FixedRemovalHours}
                  </span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  value={drivingWheelsFixedRemovalHours}
                  onChange={(e) => handleDrivingWheelsFixedRemovalHours(commonUtils.minmaxInt(e.target.value, 0, 999999))}
                  type="number"
                />
              </Grid>
            )}
            
              <Grid item sm={6} xs={12} className={classes.gridItem}>
                <InputLabel className="filter-table-label">
                  <span className="table-toolbar-label-text">
                    Remove At
                  </span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  value={removeAt}
                  onChange={(e)=> handleRemoveAt(commonUtils.minmaxInt(e.target.value, 0, 999999))}
                  type="number"
                />
              </Grid>
            
          </Grid>
        </>
      )}

      {!noFieldFilter && (
        <>
          <div className="tab-header">
            <div className="tab-title">{filterSectionWords.Filters}</div>
          </div>
          <div className="date-tab-container">
            <FilterTextField
              filterOptions={filterOptions}
              selectedFilterOptions={selectedFilterOptions}
              filterOptionsSelected={filterOptionsSelected}
              refArray={useReferenceArray}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FilterTabs;
