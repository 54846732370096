import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
// eslint-disable-next-line no-unused-vars


const ByCompoundGraph = ({ byCompoundGraphData }) => {
  const [chartInfoData, setChartInfoData] = useState({});
  const [chartInfoDataPerMile, setChartInfoDataPerMile] = useState({});
  const commonUtils = new CommonUtils();
  const globalizationMapping = new Globalization();
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const optionsForMile = JSON.parse(JSON.stringify(commonUtils.barOptions));
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["compound-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["compound-y"];
  options.title.text = globalizationHeaders["compound-title"];
  options.plugins.datalabels.formatter = (value) => {
    return value;
  };
  optionsForMile.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["compound-x"];
  optionsForMile.scales.yAxes[0].scaleLabel.labelString =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["compound-y-perKM"]
      : globalizationHeaders["compound-y-perMile"];
  optionsForMile.title.text =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["compound-title-perKM"]
      : globalizationHeaders["compound-title-perMile"];
  optionsForMile.plugins.datalabels.formatter = (value) => {
    return value;
  };

  const chart = () => {

    if (byCompoundGraphData.rowsData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();
      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byCompoundGraphData.rowsData.forEach((item) => {
        graphDataSum[item.CompoundName] = 0;
        graphDataAvg[item.CompoundName] = 0;
        graphDataCount[item.CompoundName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byCompoundGraphData.rowsData.forEach((item) => {
        graphDataSum[item.CompoundName] += parseFloat(
          item.CostPerHour * item.TireCount
        );
        graphDataCount[item.CompoundName] += item.TireCount;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((CompoundName) => {
        graphDataAvg[CompoundName] = (
          graphDataSum[CompoundName] / graphDataCount[CompoundName]
        ).toFixed(2);
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoData({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };

  const chartPerMile = () => {

    if (byCompoundGraphData.rowsData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();
      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byCompoundGraphData.rowsData.forEach((item) => {
        graphDataSum[item.CompoundName] = 0;
        graphDataAvg[item.CompoundName] = 0;
        graphDataCount[item.CompoundName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byCompoundGraphData.rowsData.forEach((item) => {
        graphDataSum[item.CompoundName] += parseFloat(
          item.CostPerMile * item.TireCount
        );
        graphDataCount[item.CompoundName] += item.TireCount;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((CompoundName) => {
        graphDataAvg[CompoundName] = (
          graphDataSum[CompoundName] / graphDataCount[CompoundName]
        ).toFixed(2);
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoDataPerMile({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };
  useEffect(() => {
    chart();
    chartPerMile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byCompoundGraphData]);

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 &&
          byCompoundGraphData.selectedColShow.CostPerHour && (
            <Bar data={chartInfoData} height={120} options={options} />
          )}
        {Object.keys(chartInfoDataPerMile).length > 0 &&
          !byCompoundGraphData.selectedColShow.CostPerHour && (
            <Bar
              data={chartInfoDataPerMile}
              height={120}
              options={optionsForMile}
            />
          )}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ByCompoundGraph;
