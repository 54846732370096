import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./ViewLocation.css";
import {
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";
import Globalization from "../../globalization/index";
import Spinner from "../../common-components/spinner/spinner";
import Breadcrumbs from "../../common-components/breadcrumbs/breadcrumbs";
import { accountInfoIdsFormatter } from "../../services/AccountInfoHandler";
import { StateContext } from "../../services/StateProvider";
import HeaderApi from "../../common-components/header/services/header-apis";

const useStyles = makeStyles({
  save: {
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    opacity: 1,
    fontWeight: 500, width: "7em"
  }
});


const ViewLocation = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [accountName, setAccountName] = useState('');
  const [locationDetails, setLocationDetails] = useState({});
  const [masterData, setMasterData] = useState({});
  const [globalAccountState] = useContext(StateContext);
  const locationObj = useLocation();
  const history = useHistory();
  const styles = useStyles();
  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const formCaptions = globalizationMapping.FORMS.ACCOUNTS.PARENTFORM;
  const headerApiObj = new HeaderApi();
  const formLabels = globalizationMapping.VIEW_LOCATION;
  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME
    },
    {
      path: "",
      name: globalHeaderTexts.VIEWLOCATION
    }
  ]
  const convertIdBaseOnMasterData = (id, identifier, masterDataArrayName, toReturn ) => {
    let masterDataArray = masterData[masterDataArrayName];
    if (masterDataArray) {
      let masterDataObject = masterDataArray.find((item) => {
        return item[identifier] === id;
      });
      return masterDataObject ? masterDataObject[toReturn] : "";
    }
  }
  useEffect(() => {
    setShowSpinner(true);
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let locationIds = accountInfoObject.locationIds;

    headerApiObj.getCountryList().then((data) => {
      if (locationObj.pathname === "/view-location") {
        if (locationObj?.state.locationId) {
          locationIds[0] = locationObj.state.locationId;
          setAccountName(locationObj.state.accountName);
        }
        headerApiObj.getLocationById(locationIds[0]).then((accountData) => {
          if (accountData.model) {
            let arr = [accountData.model]
            arr.forEach((item) => {
              let country = data.model.filter(i => {
                return i.countryId === parseInt(item.country)
              })
              if (country[0])
                item.country = country[0].countryName;
              else
                item.country = "-"
            })
            setLocationDetails(arr[0]);
            headerApiObj.getMasterDataWeb().then((data) => {
              if (data.model !== undefined) {
                setMasterData(data.model);
              }
            });
          }
        });
      }
    });
    setTimeout(() => { setShowSpinner(false)},4000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = () => {
    history.push({
      pathname: '/editlocation',
      state: {
        accountId: locationDetails.locationId
      }
    })
  };

  return (
    <div className="standalone-new-header-container">
      {/* <ScrapTiresNavigation/> */}
      <div className="view-location-main-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image background-map-image-view-location">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {globalHeaderTexts.VIEWLOCATION}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="view-parent-account-list-container">
          <div className="view-location-container">
            <div className="view-parent-container">
              <div className="view-parent-inner-container">
                <div className="account-detail-grid">
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {formCaptions.PARENTACCOUNT}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.locationName} - {accountName}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formCaptions.ADDRESSLINE1}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.addressLine1}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formCaptions.ADDRESSLINE2}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.addressLine2}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formCaptions.COUNTRY}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.country}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {globalHeaderTexts.STATE}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.state}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {globalHeaderTexts.CITY}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.city}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {globalHeaderTexts.ZIPPOSTAL}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.zipCode}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {globalHeaderTexts.PHONE}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.phoneNumber}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {globalHeaderTexts.EMAIL}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.emailAddress}
                      </label>
                    </Grid>
                  </Grid>
                </div>
                <hr className="hr-divider" />
                {/* section2 */}
                <div className="account-detail-grid">
                  <Grid item xs={6}>
                    <p className="field-title">
                      {formLabels.LOCATIONPREF}
                    </p>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.NOOFTREAD}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.noOfTreadDepthReadings}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.TREADDEPTH}
                      </p>
                      <label className="save-table-details">
                        {convertIdBaseOnMasterData( locationDetails.treadDepthMethodId,'treadDepthMethodId', 'tireDepthMethods', 'treadDepthMethodName')}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.DEFAULTREMOVAL}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.defaultRemovalTreadDepth}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.INSPECTIONORDER}

                      </p>
                      <label className="save-table-details">
                        {convertIdBaseOnMasterData( locationDetails.inspectionOrderId,'inspectionOrderId', 'inspectionOrders', 'inspectionOrderName')}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.RECOMMENDED}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.recommendedPressure}
                      </label>
                    </Grid>
                  </Grid>
                </div>
                <hr className="hr-divider" />
                {/* Section 3 */}
                <div className="account-detail-grid">
                  <Grid item xs={6}>
                    <p className="field-title">
                      {formLabels.OPERATIONSPEC}
                    </p>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.MARKETSEGMENT}
                      </p>
                      <label className="save-table-details">
                        {convertIdBaseOnMasterData(locationDetails.marketSegmentId,'marketSegmentId', 'marketSegments', 'marketSegmentName')}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.APPLICATIONTYPE}
                      </p>
                      <label className="save-table-details">
                        {convertIdBaseOnMasterData(locationDetails.applicationTypeId,'applicationTypeId', 'applicationTypes', 'applicationTypeName')}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.MATERIAL}
                      </p>
                      <label className="save-table-details">
                        {convertIdBaseOnMasterData(locationDetails.material,'material', 'materials', 'materialName')}
                      </label>
                    </Grid>
                  </Grid>
                </div>
                <hr className="hr-divider" />
                {/* Section 4 */}
                <div className="account-detail-grid">
                  <Grid item xs={6}>
                    <p className="field-title">
                      {formLabels.VEHICLEOPERATIONSPEC}
                    </p>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.SITESEVERITYINDEX}
                      </p>
                      <label className="save-table-details">
                        {convertIdBaseOnMasterData(locationDetails.siteSeverityIndex,'siteSeverityIndex', 'siteSeverityIndexes', 'siteSeverityIndexName')}
                      </label>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="field-label">
                        {formLabels.COMMENTS}
                      </p>
                      <label className="save-table-details">
                        {locationDetails.severityComments}
                      </label>
                    </Grid>
                  </Grid>
                </div>
                <div className="view-location-action-buttons">
                  <Button
                    className={styles.save}
                    variant="contained"
                    color="primary"
                    disableElevation={true}
                    onClick={() => handleEdit()}
                  >
                    {formLabels.EDIT}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewLocation;