import React, { useEffect, useState } from "react";
import { 
    Drawer,
    Divider,
    Button,
    makeStyles,
    Grid,
    Checkbox,
    TextField,
    Select } from "@material-ui/core";
import TiresSentToRepairFilterDatePicker from "./filter-datepicker/TiresSentToRepairFilterDatePicker";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CommonUtils from "../../../../../../../services/utils";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import Globalization from "../../../../../../../globalization";


const commonUtils = new CommonUtils();
const globalizationMapping = new Globalization();
const filterSectionWords = globalizationMapping.FILTERSECTION;
const useStyles = makeStyles({
    filter: {
      "&:hover": {
        opacity: 1,
      },
      background:
        "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 500,
      color: "#FFFFFF",
      boxShadow: "none",
    },
    filterDisabled: {
      background:commonUtils.disabledColor,
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 600,
      color: "#FFFFFF",
      boxShadow: "none",
    },
    root: {
      "& input.MuiAutocomplete-inputFocused": {
        color: "#000000 !important",
        Height: "45px",
      },
    },
    clear: {
      "&:hover": {
        backgroundColor: "#EAF3FB",
        borderRadius: 18,
        border: "none",
        opacity: 1,
        boxShadow: "none",
      },
      background: "transparent",
      border: "none",
      borderRadius: 18,
      color: "#0055A4",
      opacity: 0.8,
      letterSpacing: 0,
      fontWeight: 600,
      zIndex: 0,
      boxShadow: "none",
      marginRight: 5,
    },
    FormHelperText: {
      marginTop: 25,
    },
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;


const TiresSentToRepairFilter = ({
        setShowFilter,
        showFilter,
        filterOptions,
        ApplyFilter,
        resetTableFilter,
        currentFilter,
        dateRangeFilter,
        setDateRangeFilter,
        setDateRangeType
}) => {
    const classes = useStyles();
    const [resetValue, setResetValue] = useState(false);
    const [dateType, setDateType] = useState("All Time");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


    const filterOptionsTemplate = (filterOptions) => {
      let selectedFilterOptions = {};
      filterOptions.categories.forEach((category) => {
        selectedFilterOptions[category.value] = [];
      });
      return selectedFilterOptions;
    };

    let selectedFilterOptionsInit = filterOptionsTemplate(filterOptions);
    let selectedFilterOptionsWIthDate = {
      ...selectedFilterOptionsInit,
      dateRangeFromDate: dateRangeFilter.fromDate ? dateRangeFilter.fromDate : startDate,
      dateRangeToDate: dateRangeFilter.toDate ? dateRangeFilter.toDate : endDate,
    }
    const [selectedFilterOptions, setSelectedFilterOptions] = useState(selectedFilterOptionsWIthDate);
  
    


    useEffect(() => {
      isCurrentFilterEmpty();
      isSelectedFilterEmpty();
      disableApplyFilter();

    });


    const dateTypeChange = (value) => {
      setDateType(value);
      setDateRangeType(value);
    }


    // START>> Developer Template to consider the filter is same or not
    const isFilterTheSame = () => {

      // selected filter option are within the component state
      selectedFilterOptions.ManufacturerName.sort();
      selectedFilterOptions.SizeName.sort();
      selectedFilterOptions.TypeName.sort();
      selectedFilterOptions.Compound.sort();
      selectedFilterOptions.Eval.sort();



      let updatedSelectedFilterOptions = 
      {...selectedFilterOptions,
        dateRangeFromDate: startDate,
        dateRangeToDate: endDate,
      };

      // current filter option are from the redux store
      currentFilter.ManufacturerName.sort();
      currentFilter.SizeName.sort();
      currentFilter.TypeName.sort();
      currentFilter.Compound.sort(); 
      currentFilter.Eval.sort();

      let isTheSame = JSON.stringify(currentFilter) === JSON.stringify(updatedSelectedFilterOptions);
      return isTheSame;
    }

    const isDatePickerNotBothDated = () => {
      let isNotBothDated = false;
      if (((startDate.length > 0 && endDate.length === 0) || (startDate.length === 0 && endDate.length > 0))) {
        isNotBothDated = true;
      }
      return isNotBothDated;
    }

    const disableApplyFilter = () => {
      return isFilterTheSame() || isDatePickerNotBothDated() || isDateRangeNotCorrect();
    };

    const isDateRangeNotCorrect = () => {
      return new Date(startDate) > new Date(endDate)
    }

    const isSelectedFilterEmpty = () => {
      return selectedFilterOptions.ManufacturerName.length === 0 &&
        selectedFilterOptions.SizeName.length === 0 && selectedFilterOptions.TypeName.length === 0 &&  selectedFilterOptions.Compound.length === 0 &&
        selectedFilterOptions.Eval.length === 0 && startDate.length === 0 && endDate.length === 0;
    }

    const isCurrentFilterEmpty = () => {
      
        return currentFilter.ManufacturerName.length === 0 &&
        currentFilter.SizeName.length === 0 && currentFilter.TypeName.length === 0  && currentFilter.Compound.length === 0 &&
        currentFilter.Eval.length === 0 &&
        currentFilter.dateRangeFromDate.length === 0 && currentFilter.dateRangeToDate.length === 0;
      }

    // END>> Developer Template to consider the filter is same or not





    const filterOptionsSelected = (_event, selectedItems, categoryName) => {
        let updatedOptions = [];
        // initiates the selections for the selected category
        selectedItems.forEach((selectedItem) => {
          setSelectedFilterOptions({ [selectedItem.category]: [] });
        });
        // adds selected items
        if (selectedItems.length > 0) {
          selectedItems.forEach((selectedItem) => {
            updatedOptions.push(selectedItem.value);
            setSelectedFilterOptions({
              ...selectedFilterOptions,
              [selectedItem.category]: updatedOptions,
            });
          });
        } else if (selectedItems.length === 0) {
          setSelectedFilterOptions({
            ...selectedFilterOptions,
            [categoryName]: [],
          });
        } else {
          return;
        }
      };



      const applyFilterClicked = () => {
        ApplyFilter(selectedFilterOptions);
        setShowFilter(false);
      }

      const applyResetFilterClicked = () => {
        setSelectedFilterOptions(selectedFilterOptionsInit);

        // clear dumb component states
        setStartDate("");
        setEndDate("");
        // clear smart component states
        setDateRangeFilter({fromDate: "", toDate: ""});

        setResetValue(!resetValue);
        resetTableFilter();
        setShowFilter(false);
      }

      const applyClearFilterClicked = () => {
        setSelectedFilterOptions(selectedFilterOptionsInit);
        setResetValue(!resetValue);

        // clear dumb component states
        setStartDate("");
        setEndDate("");
        // clear smart component states
        setDateRangeFilter({fromDate: "", toDate: ""});
      }

      const closeFilter = () => {
        if(isFilterTheSame()) {
          setShowFilter(false);
        }
      };

    return (
      <Drawer
                className="filter-drawer"
                anchor="right"
                open={showFilter}
                onClose={closeFilter}
                ModalProps={{keepMounted: true}}
            >
        <div className="filter-container">
            
                <div className="filter-header" style={{marginBottom:20, marginTop:10}}>
                    <div className="filter-title">Filter</div>
                    <p style={{fontStyle:'italic',fontSize:11, marginLeft:'20%'}}>This filter always refer to ORIGINAL table</p>
                </div>
                <div className="date-tab-container">
                  <div className="display-columns-container" id="dashboard-display-column"></div>
                  <Grid container>
                    <Grid item sm={3} xs={3} >
                      <div className="checkbox-title">
                      Type of Date
                      </div>
                        <Select
                          native 
                          IconComponent={ExpandMoreStyledIcon}
                          className="select-drop-down" 
                          name="tire scrap type" 
                          variant="outlined" 
                          value={dateType}
                          onChange={(e) => dateTypeChange(e.target.value)}>
                          <option value="AllTime">All Time</option>
                          <option value="YTD">Year To Date</option>
                          <option value="MTD">Month To Date</option>
                          <option value="DateRange">Date Range</option>
                        </Select>
                      
                    </Grid>
                  </Grid>
                     <div className="in-service-filter">
                        <div className="tab-header">
                          <div className="tab-title" style={{color: dateType === "DateRange" ? 'black' : commonUtils.disabledColor }}><b>Date Range</b></div>
                          {(startDate.length > 0 && endDate.length === 0) || (startDate.length === 0 && endDate.length > 0) ?
                        <p style={{fontStyle:'italic',fontSize:11,width:'20%'}}><span className="required-mark">*</span>Please fill both date</p>
                        : <p></p>}
                        {(new Date(startDate) > new Date(endDate)) ?
                        <p style={{fontStyle:'italic',fontSize:11,width:'50%'}}><span className="required-mark">*</span>Start Date must be earlier that End Date</p>
                        : <p></p>}
                        </div>
                        <div className="date-tab-container">
                          <TiresSentToRepairFilterDatePicker
                            dateRangeFilter={dateRangeFilter}
                            setDateRangeFilter={setDateRangeFilter}
                            isInService={false}
                            isOutService={false}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            isDisabled={dateType !== "DateRange"}
                          />
                        </div>
 
                   </div> 
                  <Grid container style={{marginTop:30}}>
                    {filterOptions !== '' && filterOptions !== null && filterOptions.categories.map((category, index) => {
                        return (
                            <Grid key={category.name} item sm={6} xs={12}>
                                <div>
                                    <div className="filter-options-container padding-one">
                                        <div className="checkbox-header">
                                            <div className="checkbox-title">
                                                {category.name}
                                            </div>
                                            <Autocomplete
                                                disableClearable
                                                key={resetValue}
                                                multiple
                                                size="small"
                                                id={category.name}
                                                limitTags={3}
                                                autoComplete={true}
                                                options={filterOptions.categoryData[category.value]}
                                                style={{ width: "100%" }}
                                                disableCloseOnSelect={true}
                                                getOptionLabel={(option) => (option.value)}
                                                getOptionSelected={(option, value) => option.value === value.value}
                                                onChange={(event, selectedItems) => { filterOptionsSelected(event, selectedItems, category.value) }}
                                                renderOption={(option, { selected }) => {
                                                    return (<React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.value}
                                                    </React.Fragment>)
                                                }}
                                                renderInput={(params) => {
                                                  return (<TextField {...params} variant="outlined" inputProps={{...params.inputProps, maxLength: 50 }}/>)}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        )
                    })
                    }
                </Grid>
                </div>
                


                <Divider style={{marginBottom:20}}/>
               
                <div className="action-buttons action-buttons-create-location" style={{marginRight:'1.5em'}}>
                      <Button
                            className={classes.clear}
                            variant="contained"
                            disableElevation={true}
                            onClick={()=> applyClearFilterClicked()}
                            // disabled={isSelectedFilterEmpty()}
                            style={{marginRight:30}}
                        >
                            Clear
                        </Button>
                      <Button
                            className={classes.clear}
                            variant="contained"
                            disableElevation={true}
                            onClick={()=> applyResetFilterClicked()}
                            // disabled={isCurrentFilterEmpty()}
                        >
                            {globalizationMapping.FILTERSECTION.Reset}
                        </Button>
                      <Button
                            className={classes.filter}
                            variant="contained"
                            disableElevation={true}
                            onClick={() => applyFilterClicked()}
                            // disabled={disableApplyFilter()}
                        >
                            {globalizationMapping.FILTERSECTION.Apply}
                        </Button>  
                </div>
                {isFilterTheSame() ? null : 
                  <div>
                     <p style={{fontStyle:'italic',fontSize:12}}> <WarningIcon style={{verticalAlign:"middle", color: 'orange', marginLeft:20, fontSize:15}}/>Changed in Filter Detected. Click "Apply" to make changes.</p>
                  </div>}
                



        

        </div>
      </Drawer>
    );
};

export default TiresSentToRepairFilter;