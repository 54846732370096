import React, { useState, useEffect } from "react";
import "./TiresInServiceNavigation.css";
import Breadcrumbs from "../../../../../common-components/breadcrumbs/breadcrumbs";
import { IconButton, List, ListItem } from "@material-ui/core";
import Globalization from "../../../../../globalization/index";
import { useHistory } from "react-router-dom";
import { ReportsRoutePaths } from "../../../../../routing/ReportsRoutePaths";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const TiresInServiceNavigation = () => {
  const globalizationMapping = new Globalization();
  const tiresInServiceGlobalTexts = globalizationMapping.TIREREPORTS.TIRESINSERVICE;
  const tiresInServiceNav = [
    tiresInServiceGlobalTexts.InServiceTireCostSummary,
    tiresInServiceGlobalTexts.TireInstallation,
    tiresInServiceGlobalTexts.TireHistory,
    tiresInServiceGlobalTexts.ByPercentageWorn,
    tiresInServiceGlobalTexts.ByWearCondition,
    tiresInServiceGlobalTexts.ByManufacturer,
    tiresInServiceGlobalTexts.BySize,
    tiresInServiceGlobalTexts.ByTiresInServiceTireType,
    tiresInServiceGlobalTexts.ByVehicleModel,
    tiresInServiceGlobalTexts.ByVehicleID,
    tiresInServiceGlobalTexts.ProjectedCostByTireSize,
    tiresInServiceGlobalTexts.ProjectedCostByTireType,
    tiresInServiceGlobalTexts.ByVehicleWheelPosition,
    tiresInServiceGlobalTexts.TireForecastByTireType,
    tiresInServiceGlobalTexts.TireInflation,
    tiresInServiceGlobalTexts.DailyJobSummary,
    tiresInServiceGlobalTexts.TireNeedsForecast
  ];
  const tiresInServicePathNav = [
    "inServiceTireCostSummary",
    "tireInstallation",
    "tireHistory",
    "byPercentageWorn",
    "byWearCondition",
    "byManufacturer",
    "bySize",
    "byTiresInServicesTireType",
    "byVehicleModel",
    "byVehicleId",
    "projectedCostByTireSize",
    "projectedCostByTireType",
    "byVehicleWheelPosition",
    "tireForecastByTireType",
    "tireInflation",
    "dailyJobSummary",
    "tireNeedsForecast"
  ];

  const getRouteName = (currentPathName) => {
    let pathSelected = ReportsRoutePaths.find((route) => route.path === currentPathName);
    return pathSelected;
  };

  const getRoutePath = (tiresInServiceSelected, path) => {
    let pathSelected = ReportsRoutePaths.find((route) => route.path === path);
    return pathSelected;
  };

  let breadcrumbs = [];
  const history = useHistory();

  let currentPathName = history.location.pathname.split("/")[1];
  let currentRouteSelected = getRouteName(currentPathName);
  //

  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line no-unused-vars
  const [selectedTireInService, setSelectedTireInService] = useState(currentRouteSelected.name);

  breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME
    },
    {
      path: "reportsDashboard",
      name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.REPORTSDASHBOARD
    },
    {
      path: "",
      name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.TIRESREPORTS + " - "
        + tiresInServiceGlobalTexts.TiresInService + ": " + selectedRoute.name
      // name: "Tire Reports - " + selectedRoute.name,
    }
  ];

  const tiresInServiceNavigation = (tiresInServiceSelected, path) => {
    let routePath = getRoutePath(tiresInServiceSelected, path);
    //setCurrentItem(tiresInServiceSelected)
    setSelectedTireInService(tiresInServiceSelected);
    setSelectedRoute(routePath);
  };
  const prev = () => {
    tiresInServiceNav.forEach((item, index) => {
      if (selectedTireInService === item && index !== 0) {
        setSelectedTireInService(tiresInServiceNav[index - 1]);
        tiresInServiceNavigation(tiresInServiceNav[index - 1], tiresInServicePathNav[index - 1]);
      }
    });
  };
  const next = () => {
    tiresInServiceNav.forEach((item, index) => {
      if (selectedTireInService === item && index !== tiresInServiceNav.length - 1) {
        setSelectedTireInService(tiresInServiceNav[index + 1]);
        tiresInServiceNavigation(tiresInServiceNav[index + 1], tiresInServicePathNav[index + 1]);
      }
    });
  };
  useEffect(() => {
    history.push(selectedRoute.path);
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  return (
    <div>
      <div className="tires-service-main-container">
        <div className="tires-service-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="tires-service-title">{tiresInServiceGlobalTexts.TiresInService}</div>
                </div>
              </div>
              <div className=" button-tires-service-nav-container">
                <IconButton onClick={() => prev()}>
                   <ArrowBackIosRoundedIcon style={{color:'whitesmoke'}}></ArrowBackIosRoundedIcon>
                </IconButton>
                <div className="tires-service-nav-container">
                  {tiresInServiceNav.map((item, index) => {
                    return (
                      <div key={item} className="tires-service-nav-card-container" onClick={() => tiresInServiceNavigation(item, tiresInServicePathNav[index])}>
                        <List className={selectedRoute.name === item ? "active-tires-service-nav-card" : "tires-service-nav-card"}>
                          <ListItem button={selectedRoute.name === item} autoFocus={selectedRoute.name === item}>
                            <span className={selectedRoute.name === item ? "active-tires-service-nav-card-text" : "tires-service-nav-card-text"}>{item}</span>
                          </ListItem>
                        </List>
                      </div>
                    );
                  })}
                </div>
                <IconButton onClick={() => next()}>
                   <ArrowForwardIosRoundedIcon style={{color:'whitesmoke'}}></ArrowForwardIosRoundedIcon>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TiresInServiceNavigation;
