import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import "./ResponsiveTabView.css";
import { tabViewList } from "../../../services/tab-view-data.js";
import CommonUtils from "../../../../../services/utils";

const ResponsiveTabView = (props) => {
  const commonUtils = new CommonUtils();
  const selectedTabViewList =
    tabViewList[props.viewedTab.viewedTabIndex][props.viewedTab.viewedHeader];

  // NAVIGATE fn used for navigating reports using react router
  const navigate = (link, path) => {
    props.setSelectedTab({
      selectedTabIndex: props.viewedTab.viewedTabIndex,
      selectedHeader: props.reportHeadersKeys[props.viewedTab.viewedHeader],
    });
    props.setSelectedRoute({ link, path });
    props.onHoverExit(props.viewedTab.viewedTabIndex);
  };

  return (
    <div>
      <div className="responsive-tab-view">
        {props.viewedTab.viewedTabIndex !== 0 && (
          <Card
            className="container overlay"
            onMouseEnter={() =>
              props.onHoverEnter(props.viewedTab.viewedTabIndex)
            }
            onMouseLeave={() =>
              props.onHoverExit(props.viewedTab.viewedTabIndex)
            }
          >
            <CardContent>
              {selectedTabViewList[0].mainTitle !== "surveyTemplate" && (
                <Grid
                  container
                  spacing={2}
                  className="responsive-tab-grid-container"
                >
                  {selectedTabViewList.map((viewItem, index) => {
                    return (
                      <Grid key={index} item xl={2} md={2} xs={12}>
                        <div className="title-text">{viewItem.title}</div>
                        {viewItem.links.map((link, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => navigate(link, viewItem.path[i])}
                              className={
                                viewItem.path[i] ===
                                commonUtils.getCurrentUrl(window.location.href)
                                  ? "current-link-text"
                                  : "link-text"
                              }
                            >
                              {viewItem.numVal && viewItem.numVal[i]}. {link}
                            </div>
                          );
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
              )}

              {selectedTabViewList[0].mainTitle === "surveyTemplate" && (
                <div>
                  {selectedTabViewList.map((viewItem, index) => {
                    return (
                      <div key={index}>
                        <div className="survey-template-title-text">
                          {viewItem.title}
                        </div>
                        <Grid
                          className="responsive-tab-grid-container"
                          container
                        >
                          {viewItem.links.map((subItem, index) => {
                            return (
                              <Grid key={index} item xl={1} md={3} xs={12} >
                                <div className="sub-header-title-text">
                                  {subItem.subHeader}
                                </div>
                                {subItem.subLinks.map((subLink, j) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        subItem.path[j] ===
                                        commonUtils.getCurrentUrl(
                                          window.location.href
                                        )
                                          ? "current-link-text"
                                          : "link-text"
                                      }
                                      onClick={() =>
                                        navigate(subLink, subItem.path[j])
                                      }
                                    >
                                      {subItem.numVal && subItem.numVal[j]}.{" "}
                                      {subLink}
                                    </div>
                                  );
                                })}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </div>
                    );
                  })}
                </div>
              )}
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};

export default ResponsiveTabView;
