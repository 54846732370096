import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';


export default class ViewParentAccountListApis {
    cs;
    constructor() {
        this.cs = new CommonService();
    }
    viewParentAccountGetParentAccountDetails = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getParentAccountDetails}${parentAccountId}`, 'get', {})
    }
    viewParentAccountGetManageAccountList = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getManageLocationList}${parentAccountId}`, 'get', {})
    }
}