import React from 'react'
import './TireInflationFilter.css'
import Globalization from '../../../../../../../globalization';
import MultiSelect from "react-multi-select-component";
import CommonUtils from "../../../../../../../services/utils";
import {
    InputLabel,
    Grid,
    Button,
    Drawer, 
    Divider,
    makeStyles,
  } from "@material-ui/core";

const TireInflationFilter = ({showFilter, masterData, manufList, vehManufList,setSelectedManuf,selectedManuf,sizeList,setSizeList,sizeSelected,setSizeSelected,typeList,setTypeList,TypeSelected,setTypeSelected, 
  selectedVehManuf,setSelectedVehManuf,vtypeList,setvTypeList,vTypeSelected,setvTypeSelected,handleApplyFilter, resetFilter}) => {

    
const commonUtils = new CommonUtils();
const useStyles = makeStyles({
    filter: {
      "&:hover": {
        opacity: 1,
      },
      background:
        "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 500,
      color: "#FFFFFF",
      boxShadow: "none",
    },
    filterDisabled: {
      background:commonUtils.disabledColor,
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 600,
      color: "#FFFFFF",
      boxShadow: "none",
    },
    root: {
      "& input.MuiAutocomplete-inputFocused": {
        color: "#000000 !important",
        Height: "45px",
      },
    },
    clear: {
      "&:hover": {
        backgroundColor: "#EAF3FB",
        borderRadius: 18,
        border: "none",
        opacity: 1,
        boxShadow: "none",
      },
      background: "transparent",
      border: "none",
      borderRadius: 18,
      color: "#0055A4",
      opacity: 0.8,
      letterSpacing: 0,
      fontWeight: 600,
      zIndex: 0,
      boxShadow: "none",
      marginRight: 5,
    },
    FormHelperText: {
      marginTop: 25,
    },
  });

  const classes = useStyles();
    const globalizationMapping = new Globalization();
    const filterSectionWords = globalizationMapping.FILTERSECTION;
  
  
    const handleFilterChange = (event, from) => {
        switch (from) {
          case 'manuf':
            setSelectedManuf(event);
            let sizesArr = [];
            masterData.tireSizes.map(size => {
              let t = event.some(item => item.value === size.tireManufacturerId)
              if (t) sizesArr.push({ label: size.tireSizeName, value: size.tireSizeId })
            })
            setSizeList(sizesArr)
            setSizeSelected([])
            break;
          case 'size':
            setSizeSelected(event);
            let typesArr = [];
            masterData.tireTypes.map(size => {
              let isManf = selectedManuf.some(item => { return item.value === size.tireManufacturerId })
              let isSize = event.some(item => { return item.value === size.tireSizeId })
    
              if (isManf && isSize) typesArr.push({ label: size.typeName, value: size.typeId })
            })
            setTypeList(typesArr)
            setTypeSelected([])
            break;
          case 'type':
            setTypeSelected(event)
            break;
          case 'vmanuf':
            setSelectedVehManuf(event);
            let typeArr = [];
            masterData.vehicleTypes.map(size => {
              let t = event.some(item => item.value === size.manufacturerId)
              if (t) typeArr.push({ label: size.typeName, value: size.typeId })
            })
            setvTypeList(typeArr)
            setvTypeSelected([])
            break;
          case 'vtype':
            setvTypeSelected(event)
            break;
          default:
            break;
        }
      }


      const disableApplyFilter = () => {
        return (selectedManuf.length === 0 || sizeSelected.length === 0 || TypeSelected.length === 0 || selectedVehManuf.length === 0 || vTypeSelected.length === 0)
           
      }
    return (
        <React.Fragment>
            <Drawer className="filter-drawer"
                anchor="right"
                open={showFilter}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <div className="filter-container">
                    <div className="filter-header">
                        <div className="filter-title">
                            {filterSectionWords.Filters}
                        </div>
                    </div>
                <Grid xs={12} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom:15 }}>
                <Grid item xs={6} >
                  <div className="select-container">
                    <InputLabel id="chartType" style={{marginBottom:5}}>{globalizationMapping.REPORTTABLE.MANUFACTURER}</InputLabel>
                    <MultiSelect
                      options={manufList}
                      value={selectedManuf}
                      onChange={(event) => {
                        handleFilterChange(event, 'manuf');
                      }}
                      hasSelectAll={false}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"],
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} >
                  <div className="select-container">
                    <InputLabel id="chartType" style={{color: (selectedManuf.length === 0 ? commonUtils.disabledColor :'black'), marginBottom:5}}>{globalizationMapping.REPORTTABLE.SIZE}</InputLabel>
                    <MultiSelect
                      options={sizeList}
                      value={sizeSelected}
                      onChange={(event) => {
                        handleFilterChange(event, 'size');
                      }}
                      hasSelectAll={false}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"],
                      }}
                      disabled={selectedManuf.length === 0}
                    />
                  </div>
                </Grid>

                
              </Grid>
              <Grid xs={12} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
              <Grid item xs={6} >
                  <div className="select-container">
                    <InputLabel id="chartType" style={{color: (sizeSelected.length === 0 ? commonUtils.disabledColor :'black'), marginBottom:5}}>{globalizationMapping.REPORTTABLE.TYPE}</InputLabel>
                    <MultiSelect
                      options={typeList}
                      value={TypeSelected}
                      onChange={(event) => {
                        handleFilterChange(event, 'type');
                      }}
                      hasSelectAll={false}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"],
                      }}
                      disabled={sizeSelected.length === 0}
                    />
                  </div>
                </Grid>
              

              
              </Grid>
              <Grid xs={12} style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
              <Grid item xs={6} >
                  <div className="select-container">
                    <InputLabel id="chartType" style={{marginBottom:5}}>{globalizationMapping.ADMIN.VehicleManufNotification}</InputLabel>
                    <MultiSelect
                      options={vehManufList}
                      value={selectedVehManuf}
                      onChange={(event) => {
                        handleFilterChange(event, 'vmanuf');
                      }}
                      hasSelectAll={false}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"],
                      }}
                    />
                  </div>
                </Grid>
              <Grid item xs={6} >
                  <div className="select-container">
                    <InputLabel id="chartType" style={{color: (selectedVehManuf.length === 0 ? commonUtils.disabledColor :'black'), marginBottom:5}}>{globalizationMapping.REPORTTABLE.VEHICLETYPENAME}</InputLabel>
                    <MultiSelect
                      options={vtypeList}
                      value={vTypeSelected}
                      onChange={(event) => {
                        handleFilterChange(event, 'vtype');
                      }}
                      hasSelectAll={false}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"],
                      }}
                      disabled={selectedVehManuf.length === 0}
                    />
                  </div>
                </Grid>
                </Grid>
                  <Divider style={{marginTop:15, marginBottom:20}}></Divider>
                  <div className="action-buttons action-buttons-create-location" style={{marginRight:'1.5em'}}>
                      <Button
                            className={classes.clear}
                            variant="contained"
                            disableElevation={true}
                            onClick={resetFilter}
                            disabled={selectedManuf.length === 0}
                        >
                            {globalizationMapping.FILTERSECTION.Reset}
                        </Button>
                      <Button
                            className={(disableApplyFilter()) ? classes.filterDisabled : classes.filter}
                            variant="contained"
                            disableElevation={true}
                            onClick={handleApplyFilter}
                            disabled={selectedManuf.length === 0}
                        >
                            {globalizationMapping.FILTERSECTION.Apply}
                        </Button>  
                </div>
                </div>
            </Drawer>
        </React.Fragment>
    )
}

export default TireInflationFilter