import React from 'react';
import { useLocation } from "react-router-dom";
import { AppBar, Toolbar, Typography, Hidden, Container } from '@material-ui/core';
import './footer.css'
import FooterLogo from '../../assets/footer-logo.svg';

export default function FooterNavigation() {
  const location = useLocation();
  const pathName = location.pathname;
// ©2020 The Goodyear Tire and Rubber Company
  return (
    <>
      {pathName === '/login' ? null : 
        <div className="app-footer-container">
        <AppBar position="static" className="footerClass">
          <Container maxWidth="lg" className="footer-container">
            <Toolbar>
              <Typography className='footerCopyright'>
               {"©"+ new Date().getFullYear()+" The Goodyear Tire & Rubber Company "}
              </Typography>
              <Hidden smDown>
                <Typography className='footerCopyright'>
                  |
              </Typography>
              </Hidden>
              <Typography className='footerLink'>
                Terms & Conditions and Privacy Policy
              </Typography>
              <Typography><img src={FooterLogo} alt="Footer Logo" /></Typography>
            </Toolbar>
          </Container>
        </AppBar>
  </div>}
    </>
  
  );
}