import React, { useEffect, useContext, useState } from "react";
import "./ByCompound.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent,TextField, InputAdornment, InputLabel, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import ScrapTiresNavigation from "../../../scrap-tires-navigation/components/ScrapTiresNavigation";
import TiresService from "../../../../../services/TiresApis.js";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import ByCompoundTable from "../table/ByCompoundTable";
import ByCompoundDataSetter from "../../../../../services/ByCompoundUtils";
import CommonUtils from "../../../../../../../services/utils";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import ByCompoundGraph from "../graph/ByCompoundGraph";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ByCompoundFilter from "../filter/ByCompoundFilter.js";


const ByCompound = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [byCompoundTableData, setByCompoundTableData] = useState();
  const [originalByCompoundTableData, setOriginalByCompoundTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(byCompoundTableData);
  const [searchValue, setSearchValue] = useState("");
  const [tireScrapType, setTireScrapType] = useState("AllTire");
  const [noData, setNoData] = useState(false);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [inServiceDateRange, setInServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [outServiceDateRange, setOutServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  // filter options driver
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const globalizationMapping = new Globalization();

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" }
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const commonUtils = new CommonUtils();
  const byCompoundDataSetter = new ByCompoundDataSetter();
  const tiresService = new TiresService();

  useEffect(() => {
    ByCompoundResponseData(tireScrapType, excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const actionChanged = (value) => {
    let avoidArr = [];
    if (value === "miles") {
      avoidArr = ["CostPerHour", "AverageHours"];
    } else if (value === "hours") {
      avoidArr = ["CostPerMile", "AverageMiles"];
    } else {
      return;
    }

    let inServiceTireCostSummaryTableInfo = originalByCompoundTableData;
    inServiceTireCostSummaryTableInfo.headCells.map((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      inServiceTireCostSummaryTableInfo.selectedColShow[item.value] = flag;
    });
    setByCompoundTableData(inServiceTireCostSummaryTableInfo);
    setOriginalByCompoundTableData(inServiceTireCostSummaryTableInfo);
    setTableBeforeFilter(inServiceTireCostSummaryTableInfo);
    inServiceTireCostSummaryTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
    let updatedHeadCells = inServiceTireCostSummaryTableInfo.headCells.filter((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });
    setDisplayColOptions(updatedHeadCells);
    setDisplayColumns(updatedHeadCells);
    setShowColumns(inServiceTireCostSummaryTableInfo.selectedColShow);
    setPrintData({
      reportType: "",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByTireType,
      rowsData: inServiceTireCostSummaryTableInfo.rowsData,
      headCells: inServiceTireCostSummaryTableInfo.headCells,
      avgValues: byCompoundDataSetter.avgValues(inServiceTireCostSummaryTableInfo.rowsData),
      selectedColShow: inServiceTireCostSummaryTableInfo.selectedColShow
    });
    setShowSpinner(false);
  };

  const ByCompoundResponseData = (tireScrapTypeValue, excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {

    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let inServiceDateRangeType = "AllTime";
    let outServiceDaterangeType = "AllTime";

    if (inServiceDateRange.fromDate !== "" && inServiceDateRange.toDate !== "") inServiceDateRangeType = "DateRange";

    if (outServiceDateRange.fromDate !== "" && outServiceDateRange.toDate !== "") outServiceDaterangeType = "DateRange";

    let requestObject = {
      reportOption: "",
      reportName: "Scrap_Tire_By_Compound",
      dateRangeType: dateRangeType,
      InServiceDateRangeType: inServiceDateRangeType,
      OutServiceDateRangeType: outServiceDaterangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      OutServiceFromDate: outServiceDateRange.fromDate,
      OutServiceToDate: outServiceDateRange.toDate,
      InServiceFromDate: inServiceDateRange.fromDate,
      InServiceToDate: inServiceDateRange.toDate,
      accountIds: accountIds,
      locationIds: locationIds,
      TireScrapType: tireScrapTypeValue,
      usedTires: excludeUsedTiresValue.toString()
    };
    let filterOptions = {
      categories: [
        {
          name: globalizationMapping.REPORTTABLE.MANUFACTURER,
          value: "ManufacturerName"
        },
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        {
          name: globalizationMapping.REPORTTABLE.COMPOUND1,
          value: "CompoundName"
        }
      ],
      categoryData: {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        CompoundName: [],
        outServiceDateToDate: '',
        outServiceDateFromDate: '',
        inServiceDateFromDate: '',
        inServiceDateToDate: '',
        dateRangeFromDate: '',
        dateRangeToDate: '',
      }
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let byCompoundTableInfo = byCompoundDataSetter.setData(data);
      let filterOptionsInfo = byCompoundDataSetter.setFilterOptionsData(filterOptions, byCompoundTableInfo.rowsData);
      setByCompoundTableData(byCompoundTableInfo);
      setOriginalByCompoundTableData(byCompoundTableInfo);
      setTableBeforeFilter(byCompoundTableInfo);
      byCompoundTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setFilterOptions(filterOptionsInfo);
      setDisplayColOptions(byCompoundTableInfo.headCells);
      setDisplayColumns(byCompoundTableInfo.headCells);
      setShowColumns(byCompoundTableInfo.selectedColShow);
      setPrintData({
        reportType: "Scrap Tires : ",
        reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByCompound,
        rowsData: byCompoundTableInfo.rowsData,
        headCells: byCompoundTableInfo.headCells,
        avgValues: byCompoundTableInfo.avgValues,
        selectedColShow: byCompoundTableInfo.selectedColShow
      });
      setShowSpinner(false);
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = byCompoundTableInfo.rowsData;
        let filteredData = byCompoundDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setByCompoundTableData({
          ...byCompoundTableInfo,
          rowsData: filteredData,
          avgValues: byCompoundDataSetter.avgValues(filteredData)
        });
        setOriginalByCompoundTableData({
          ...byCompoundTableInfo,
          rowsData: filteredData,
          avgValues: byCompoundDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: byCompoundDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        (((Object.values(inServiceDateRange).every(x => x === "")) && (Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(outServiceDateRange).every(x => x === ""))) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    setModalHandler({ handleEmail: true });
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      dateRangeFromDate: '',
      dateRangeToDate: '',
    })

    setSearchValue("");
    setFilterApplied(false);
    setByCompoundTableData(tableBeforeFilter);
    setOriginalByCompoundTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
  };
  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();

    let originalTableDataStore, filteredData;
    originalTableDataStore = originalByCompoundTableData?.rowsData;

    filteredData = byCompoundDataSetter.search(originalTableDataStore, searchText);

    let updatedTableData = {
      rowsData: filteredData,
      headCells: originalByCompoundTableData?.headCells,
      avgValues: byCompoundDataSetter.avgValues(filteredData),
      selectedColShow: originalByCompoundTableData?.selectedColShow
    };
    setByCompoundTableData(updatedTableData);
    setPrintData({
      ...printData,
      reportType: "Scrap Tires : ",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByCompound,
      rowsData: filteredData,
      headCells: originalByCompoundTableData?.headCells,
      avgValues: byCompoundDataSetter.avgValues(filteredData),
      selectedColShow: originalByCompoundTableData?.selectedColShow
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue
    return false;
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      outServiceDateToDate: outServiceDateRange.toDate,
      outServiceDateFromDate:  outServiceDateRange.fromDate,
      inServiceDateFromDate: inServiceDateRange.fromDate,
      inServiceDateToDate: inServiceDateRange.toDate,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}


    setSearchValue("");
    setFilterApplied(true);
    setFilterFieldsApplied(appliedFilter);
    ByCompoundResponseData(tireScrapType, excludeUsedTires, true, appliedFilter);
    let originalTableDataStore = originalByCompoundTableData?.rowsData;
    let filteredData = byCompoundDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setByCompoundTableData({
      ...byCompoundTableData,
      rowsData: filteredData,
      avgValues: byCompoundDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byCompoundDataSetter.avgValues(filteredData)
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);
  };


  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalByCompoundTableData?.headCells;
    let showColumnsUpdated = byCompoundDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    setPrintData({ ...printData, selectedColShow: showColumnsUpdated });
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  const handleUpdateTireScrapType = (event) => {
    setTireScrapType(event.target.value);
    ByCompoundResponseData(event.target.value, excludeUsedTires, filterApplied, filterFieldsApplied);
  };

  const handleExcludeUsedTires = (event) => {
    setSearchValue("");
    setExcludeUsedTires(event.target.checked);
    ByCompoundResponseData(tireScrapType, event.target.checked, filterApplied, filterFieldsApplied);
  };

  return (
    <div className="container">
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-compound-container">
          <Card className="by-compound-card">
            <CardContent className="graph-card">
              <ByCompoundGraph byCompoundGraphData={byCompoundTableData} />
            </CardContent>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div
                    className="tire-scrap-type"
                    style={{
                      marginLeft: "0.5em",
                      marginRight: "1em",
                      minWidth: "11em"
                    }}
                  >
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select 
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down" 
                      name="tire scrap type" 
                      variant="outlined" 
                      defaultValue={"hours"} 
                      onChange={(e) => actionChanged(e.target.value)}>
                      <option value="hours">{globalizationMapping.MANAGEVEHICLE.HOURS} </option>
                      <option value="miles">{toggleMile} </option>
                    </Select>
                  </div>
                  <div
                    className="tire-scrap-type"
                    style={{
                      marginLeft: "0.5em",
                      marginRight: "1em",
                      minWidth: "11em"
                    }}
                  >
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.GRAPH.TireScrapType}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down" 
                      value={tireScrapType} 
                      name="tire scrap type" 
                      variant="outlined" 
                      onChange={handleUpdateTireScrapType}>
                      <option value="AllTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption1} </option>
                      <option value="Repair">{globalizationMapping.REPORTCOMMONWORDS.TypeOption2} </option>
                      <option value="Retread">{globalizationMapping.REPORTCOMMONWORDS.TypeOption3} </option>
                      <option value="NewTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption4} </option>
                    </Select>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div className="exclude-used-tires">
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>

              <div className="data-summary-table">
                <ByCompoundTable tableData={byCompoundTableData} showColumns={showColumns} filterApplied={filterApplied} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />
      <EmailExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />

      <ByCompoundFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        inServiceDateRange={inServiceDateRange}
        setInServiceDateRange={setInServiceDateRange}
        outServiceDateRange={outServiceDateRange}
        setOutServiceDateRange={setOutServiceDateRange}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default ByCompound;
