import React from 'react';
import './spinner.css'

export default function Spinner() {

  return (
    <div className="spinner-main-container">
      <div className="spinner-container">
        <img src={require("../../assets/logo_pulse.svg")} alt="loader" />
        <span className='circle'></span>
      </div>
    </div>
  );
}