import axios from "axios";
import React from "react";
import { SERVER } from "./api-urls.js";
import { AuthConsumer } from "../services/AuthProvider";
import AuthService from "./AuthService.js";

export default class CommonService {
  token;
  requestOptions;

  constructor() {
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));
    this.token = "Bearer " + oidcStorage.access_token;
    this.requestOptions = {
      headers: {
        Authorization: this.token,
        "Content-Type": "application/json"
      }
    };

    switch (process.env.REACT_APP_ENV) {
      case "DEV":
        this.baseUrl = SERVER.BASE_URL_TEST;
        this.redirectionUrl = SERVER.REDIRECT_URL_TEST;
        break;

      case "PROD":
        this.baseUrl = SERVER.BASE_URL_PROD;
        this.redirectionUrl = SERVER.REDIRECT_URL_PROD;
        break;

      case "STAGE":
        this.baseUrl = SERVER.BASE_URL_STAGE;
        this.redirectionUrl = SERVER.REDIRECT_URL_STAGE;
        break;

      case "LOCAL":
      default:
        this.baseUrl = SERVER.BASE_URL_TEST;
        this.redirectionUrl = SERVER.REDIRECT_URL_LOCAL;
        break;
    }
  }

  httpConnection = async (url, method, body) => {
    switch (method) {
      case "get":
        const response = await this.httpConnectionGet(url, this.requestOptions);
        const data = response.data ? response.data : "";
        if (data.httpStatusCode === 401) {
          return this.errorHandling(response);
        } else if (data !== "") {
          return data;
        } else {
          return this.errorHandling(response);
        }

      case "post":
        if (url === "Tire/CreateImages/") {
          this.requestOptions["Content-Type"] = "multipart/form-data";
        }
        const responsePost = await this.httpConnectionPost(url, body);
        const dataPost = responsePost.data ? responsePost.data : "";
        if (dataPost !== "") {
          return dataPost;
        } else {
          return this.errorHandling(responsePost);
        }

      case "put":
        const responsePut = await this.httpConnectionPut(url, body);
        const dataPut = responsePut.data ? responsePut.data : "";
        if (dataPut !== "") {
          return dataPut;
        } else {
          return this.errorHandling(responsePut);
        }
      case "delete":
        const responseDelete = await this.httpConnectionDelete(url, body);
        const dataDelete = responseDelete.data ? responseDelete.data : "";
        if (dataDelete !== "") {
          return dataDelete;
        } else {
          return this.errorHandling(responseDelete);
        }
      default:
        return "NOT USING CORRECT METHOD";
    }
  };

  httpConnectionGet = async (url, _body) => {
    try {
      return await axios.get(`${this.baseUrl}${url}`, this.requestOptions);
    } catch (error) {
      return error.response;
    }
  };

  httpConnectionPost = async (url, body) => {
    try {
      return await axios.post(`${this.baseUrl}${url}`, body, this.requestOptions);
    } catch (error) {
      return error.response;
    }
  };
  httpConnectionPut = async (url, body) => {
    try {
      return await axios.put(`${this.baseUrl}${url}`, body, this.requestOptions);
    } catch (error) {
      return error.response;
    }
  };
  httpConnectionDelete = async (url, _body) => {
    try {
      return await axios.delete(`${this.baseUrl}${url}`, this.requestOptions);
    } catch (error) {
      return error.response;
    }
  };
  errorHandling = (response) => {
    if (response.status === 401) {
      const logoutMsgShown = sessionStorage.getItem("LogoutMsgShown");
      if (logoutMsgShown === "false") {
        sessionStorage.setItem("LogoutMsgShown", "true");
        const authService = new AuthService();
        alert("Due to inactivity you will need to log out and then log in again");
        authService.logout();
      }
      return <AuthConsumer>removeUser()</AuthConsumer>;
    }
    return response;
  };
}
