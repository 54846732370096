import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Globalization from "../../../../globalization";
import Breadcrumbs from "../../../../common-components/breadcrumbs/breadcrumbs";
import {
  Button,
  Grid,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import Spinner from "../../../../common-components/spinner/spinner";
import rightArrow from "../../../../assets/Arrow.svg";
import RepairDetails from "./step-tire-form";
import "./repair-to-inventory.css";
import RepairCosts from "./step-costs";
import HeaderApi from "../../../../common-components/header/services/header-apis";
import ChangeDispositionApi from "../../services/change-disposition-apis";
import RemoveTireApis from "../../../remove-tire/services/remove-tire-apis";
import ResetHoursMilesModal from "./modal/reset-hours-miles";
import { StateContext } from "../../../../services/StateProvider";
import CancelConfirmationPopup from "../../../cancel-confirmatipn-popup/cancel-popup";

const useStyles = makeStyles({
  width: "100%",
  next: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  previous: {
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 1,
    },
    background: "transparent",
    border: "1px solid #707070",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    font: "Barlow",
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: "10px",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5,
  },
  rootStepper: {
    "& .MuiStepIcon-root": {
      color: "#CFDBEB",
      borderRadius: "50%",
      height: "35px",
      width: "35px",
      marginTop: "9px",
    },
    "& .MuiStepIcon-active": {
      color: "#FEDD00 !important",
      borderColor: "#0D54B5",
      border: "2px solid",
      borderRadius: "50%",
      height: "35px",
      width: "35px",
    },
    "& .MuiStepIcon-completed": {
      color: "#1BB121 !important",
    },
    "& .MuiStepIcon-text": {
      fill: "#0D0D0D",
      opacity: "0.4",
    },
    " & .MuiStepLabel-label": {
      marginTop: "12px",
      textAlign: "inherit",
      marginLeft: "5%",
      fontSize: "1.20em",
    },
    "& .MuiStepLabel-root": {
      flexDirection: "row",
      marginLeft: "40%",
    },
    "& .MuiStepper-horizontal": {
      borderRadius: "4px",
      display: "flex",
      backgroundColor: "#E1EAF5 !important",
      border: "1px solid #D0D0D0 !important",
      opacity: "1",
    },
    "& .MuiStepIcon-active .MuiStepIcon-text": {
      fill: "#0D54B5 !important",
      opacity: "1 !important",
    },
  },
});

//Stepper
const ColorLibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      content: `url(${rightArrow})`,
      textAlign: "center",
      width: "100%",
      height: "30px",
      border: "none",
      marginTop: "-10px",
      display: "inline-block",
    },
  },
  completed: {
    "& $line": {
      content: `url(${rightArrow})`,
      textAlign: "center",
      width: "100%",
      height: "30px",
      border: "none",
      marginTop: "-10px",
      display: "inline-block",
    },
  },
  line: {
    content: `url(${rightArrow})`,
    textAlign: "center",
    width: "100%",
    height: "30px",
    border: "none",
    marginTop: "-10px",
    display: "inline-block",
  },
})(StepConnector);

const RepairToInventory = () => {
  const styling = useStyles();
  const locationObj = useLocation();
  const history = useHistory();
  const [prevPath, setPrevPath] = useState(locationObj.state?.fromPath? locationObj.state.fromPath: "/home");
  const [historyState, setHistoryState] = useState();
  const [showResetModal, setShowResetModal] = useState(false);
  const [tireDetails, setTireDetails] = useState({});
  const [tireInspDetails, setTireInspDetails] = useState({
    action: "ReturnRP",
    comments: "No comments",
    currentHours: 9796,
    currentMiles: 0,
    currentPressure: 0,
    dispositionId: 8,
    insideTread: 22,
    inspectionDate: new Date().toISOString(),
    locationId: 2652,
    middleTread: null,
    outsideTread: 2,
    parentAccountId: 2683,
    pressureType: "H",
    removalReasonId: null,
    tireId: 114147,
    vehicleId: 11202,
    wearConditionId: 0,
    casingConditionId:0
  });

  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const formCaptions = globalizationMapping.FORMS;
  const tireFormCaptions = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
  const validationTexts = globalizationMapping.FORMS.VALIDATIONTEXTS;
  const myBreadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME,
    },
    {
      path: "",
      name:
        locationObj.state.changeFrom === "repair"
          ? tireFormCaptions.REPAIRTOINVENTORY
          : tireFormCaptions.RETREADTOINVENTORY,
    },
  ];

  const [globalAccountState] = useContext(StateContext);

  const [showSpinner, setShowSpinner] = useState(true);
  const [masterData, setMasterData] = useState({});
  const [accLocationString, setAccLocationString] = useState("");
  const [locationDetails, setLocationDetails] = useState({});
  const headerApiObj = new HeaderApi();
  const changeDispApiObj = new ChangeDispositionApi();
  const removeTireApiObj = new RemoveTireApis();

  const [isRemovalReasonNew, setRemovalReasonNew] = useState(false);
  const [removalMasterData, setRemovalMasterData] = useState([]);
  const [oldNetCost, setOldNetCost] = useState(0);
  const [oldRepairCost, setOldRepairCost] = useState(0);
  const [repairCost, setRepairCost] = useState(0);
  const [costArr, setCostArr] = useState([0]);
  const [commentArr, setCommentArr] = useState([""]);
  const [repairHistoryObj, setRepairHistoryObj] = useState({});
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);

  const [isFirstLoad, setFirstLoad] = useState(true);

  const initPath = (id, disp) => {
    if (locationObj.state && locationObj.state.fromPath) {
      setPrevPath(locationObj.state.fromPath);
      if (locationObj.state.fromPath === "/manage-tire") {
        setHistoryState({ dispositionId: disp });
      } else {
        if (locationObj.state.fromPath === "/view-tire") {
          setHistoryState({ tireId: id });
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const account = globalAccountState.accountInfo.account.accountName;
    const location = globalAccountState.accountInfo.location[0].label;
    setAccLocationString(`${account} - ${location}`);
    if (locationObj.state && locationObj.state.tireObj) {
      const tireId = locationObj.state.tireObj.tireId;

      setTireDetails({ ...locationObj.state.tireObj });
      setRepairHistoryObj({ tireId, comments: [], repairCost: [] });

      setOldRepairCost(
        locationObj.state.changeFrom === "repair"
          ? locationObj.state.tireObj.repairCost
          : locationObj.state.tireObj.retreadCost
      );
      setOldNetCost(
        locationObj.state.tireObj.netCost
          ? locationObj.state.tireObj.netCost
          : 0
      );

      changeDispApiObj
        .getInspectionRecordForTire(tireId)
        .then((tireInspRcd) => {
          if (tireInspRcd.model) {
            headerApiObj
              .getLocationById(tireInspRcd.model.locationId)
              .then((locationData) => {
                if (locationData.model) {
                  setLocationDetails(locationData.model);
                }
              });

            setTireInspDetails({
              ...tireInspDetails,
              action:
                locationObj.state.changeFrom === "repair"
                  ? "ReturnRP"
                  : "ReturnRT",
              comments: "",
              currentHours: tireInspRcd.model.currentHours,
              currentMiles: tireInspRcd.model.currentMiles,
              currentPressure: tireInspRcd.model.currentPressure,
              insideTread: tireInspRcd.model.insideTread
                ? tireInspRcd.model.insideTread
                : 0,
              locationId: tireInspRcd.model.locationId,
              middleTread: tireInspRcd.model.middleTread
                ? tireInspRcd.model.middleTread
                : 0,
              outsideTread: tireInspRcd.model.outsideTread,
              parentAccountId: tireInspRcd.model.parentAccountId,
              pressureType:
                tireInspRcd.model.pressureType !== ""
                  ? tireInspRcd.model.pressureType
                  : "C",
              tireId: tireInspRcd.model.tireId,
              vehicleId: tireInspRcd.model.vehicleId,
            });
            initPath(tireInspRcd.model.tireId, tireInspRcd.model.dispositionId);
          }
        });

      headerApiObj.getMasterDataWeb().then((data) => {
        if (data.model) {
          setMasterData(data.model);
        }
      });
      setFirstLoad(false);
    } else {
      history.push("/home");
    }
    setShowSpinner(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  //Step Handling
  const [currentStep, setCurrentStep] = useState(0);
  const steps = getsteps();

  function getsteps() {
    return [
      tireFormCaptions.REPAIRTOINVENTORYSTEPDETAILS,
      tireFormCaptions.REPAIRTOINVENTORYSTEPCOSTS,
    ];
  }

  const getTotalSteps = () => steps.length;

  const handleNextStep = () => {
    if (currentStep !== getTotalSteps() - 1) {
      if (validateFirstForm()) {
        setCurrentStep((currStep) => currStep + 1);
        window.scrollTo(0, 0);
      }
    } else {
      handleSubmit();
    }
  };

  const handlePreviousStep = () => {
    if (currentStep !== 0) {
      setCurrentStep((currStep) => currStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const handleStep = (step) => {
    if (step > currentStep) {
      if (validateFirstForm()) {
        setCurrentStep(step);
      }
    } else {
      setCurrentStep(step);
    }
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
    //handle Cancel
  };

  const handleFieldChange = (field, value) => {
    switch (field) {
      case "inspdate":
        if (value !== null) {
          let date = value;
          date = `${date.getFullYear()}-${date.getMonth() + 1
            }-${date.getDate()}`;
          setTireInspDetails({ ...tireInspDetails, inspectionDate: date });
        } else {
          setTireInspDetails({ ...tireInspDetails, inspectionDate: "" });
        }
        break;
      case "removalreason":
        setTireInspDetails({ ...tireInspDetails, removalReasonId: value });
        break;
      case "outtread":
        if (!isNaN(value)) {
          setTireInspDetails({ ...tireInspDetails, outsideTread: value });
          setTireDetails({ ...tireDetails, outsideTread: value });
        } else {
          setTireInspDetails({ ...tireInspDetails, outsideTread: null });
          setTireDetails({ ...tireDetails, outsideTread: null });
        }
        break;
      case "intread":
        if (!isNaN(value)) {
          setTireInspDetails({ ...tireInspDetails, insideTread: value });
          setTireDetails({ ...tireDetails, insideTread: value });
        } else {
          setTireInspDetails({ ...tireInspDetails, insideTread: null });
          setTireDetails({ ...tireDetails, insideTread: null });
        }
        break;
      case "midtread":
        if (!isNaN(value)) {
          setTireInspDetails({ ...tireInspDetails, middleTread: value });
          setTireDetails({ ...tireDetails, middleTread: value });
        } else {
          setTireInspDetails({ ...tireInspDetails, middleTread: null });
          setTireDetails({ ...tireDetails, middleTread: null });
        }
        break;
      case "pressuretype":
        if (value) {
          setTireInspDetails({ ...tireInspDetails, pressureType: "H" });
        } else {
          setTireInspDetails({ ...tireInspDetails, pressureType: "C" });
        }
        break;
      case "pressure":
        if (!isNaN(value)) {
          setTireInspDetails({ ...tireInspDetails, currentPressure: value });
        } else {
          setTireInspDetails({ ...tireInspDetails, currentPressure: null });
        }
        break;
      case "casing":
        setTireInspDetails({ ...tireInspDetails, casingConditionId: value });
        break;
      case "wear":
        setTireInspDetails({ ...tireInspDetails, wearConditionId: value });
        break;
      case "casingval":
        setTireDetails({ ...tireDetails, casingValue: value });
        break;
      case "cost":
        setRepairCost(value);
        break;
      case "comments":
        setTireInspDetails({ ...tireInspDetails, comments: value });
        break;
      default:
        break;
    }
  };

  //masterData
  const handleRemovalChange = (field, value) => {
    if (field === "removalreason") {
      setRemovalMasterData([]);
      const idx = masterData.tireRemovalReasons.findIndex(
        (reason) => reason.reasonId === value
      );

      if (
        masterData.tireRemovalReasons[idx].reasonName.trim().toLowerCase() ===
        "other"
      ) {
        setRemovalReasonNew(true);
      } else {
        setRemovalReasonNew(false);
      }
      setTireInspDetails({ ...tireInspDetails, removalReasonId: value });
    } else {
      if (field === "otherreason") {
        let date = new Date();
        date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        setRemovalMasterData([
          {
            requestDate: date,
            fieldId: 11,
            description: value,
            status: "New",
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (!isFirstLoad) {
      const rprCost = !isNaN(repairCost) ? repairCost : 0;
      const netCostCalc = rprCost + oldNetCost - tireDetails.casingValue;
      if (locationObj.state.changeFrom === "repair") {
        setTireDetails({
          ...tireDetails,
          repairCost: oldRepairCost + rprCost,
          netCost: netCostCalc,
          actualNetCost: netCostCalc,
        });
      } else {
        setTireDetails({
          ...tireDetails,
          retreadCost: oldRepairCost + rprCost,
          netCost: netCostCalc,
          actualNetCost: netCostCalc,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repairCost, tireDetails.casingValue]);

  useEffect(() => {
    if (!isFirstLoad) {
      const outside =
        isNaN(parseInt(tireDetails.outsideTread, 10)) ||
          tireDetails.outsideTread === null
          ? 0
          : parseInt(tireDetails.outsideTread, 10);
      const inside =
        isNaN(parseInt(tireDetails.insideTread, 10)) ||
          tireDetails.insideTread === null
          ? 0
          : parseInt(tireDetails.insideTread, 10);
      const middle =
        isNaN(parseInt(tireDetails.middleTread, 10)) ||
          tireDetails.middleTread === null
          ? 0
          : parseInt(tireDetails.middleTread, 10);

      if (locationDetails.noOfTreadDepthReadings === 3) {
        let result;
        if (locationDetails.treadDepthMethodId === 3) {
          result = Math.min(outside, inside, middle);
        } else if (locationDetails.treadDepthMethodId === 2) {
          result = Math.max(outside, inside, middle);
        } else {
          result = (outside + inside + middle) / 3;
          if (!Number.isInteger(result)) {
            result = result.toFixed(2);
          }
        }
        setTireDetails({
          ...tireDetails,
          currentTreadDepth: result,
        });
      } else if (locationDetails.noOfTreadDepthReadings === 2) {
        let result;
        if (locationDetails.treadDepthMethodId === 3) {
          result = Math.min(outside, inside);
        } else if (locationDetails.treadDepthMethodId === 2) {
          result = Math.max(outside, inside);
        } else {
          result = (outside + inside) / 2;
          if (!Number.isInteger(result)) {
            result = result.toFixed(2);
          }
        }
        setTireDetails({
          ...tireDetails,
          currentTreadDepth: result,
        });
      } else {
        setTireDetails({
          ...tireDetails,
          currentTreadDepth: outside,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tireDetails.outsideTread,
    tireDetails.insideTread,
    tireDetails.middleTread,
    locationDetails,
  ]);

  //validation
  const [formHelperTexts, setFormHelperTexts] = useState({});

  const validateFirstForm = () => {
    let isFormvalid = true;
    const formErrors = {};

    Object.keys(tireInspDetails).forEach((key) => {
      if (
        key === "inspectionDate" &&
        (isNaN(parseInt(tireInspDetails[key], 10)) ||
          tireInspDetails[key] === "")
      ) {
        isFormvalid = false;
        formErrors[key] = validationTexts.INVALIDFIELD;
      }
    });

    if (isRemovalReasonNew && removalMasterData.length === 0) {
      isFormvalid = false;
      formErrors["otherreason"] = validationTexts.MISSINGFIELD;
    }

    setFormHelperTexts(formErrors);
    return isFormvalid;
  };

  const onCostCommentChange = (field, i, val) => {
    if (field === "cost") {
      const cost = [...costArr];
      if (!isNaN(val)) {
        cost[i] = val;
      } else {
        cost[i] = 0;
      }
      setCostArr(cost);
      setRepairHistoryObj({ ...repairHistoryObj, repairCost: cost });
    } else {
      const comment = [...commentArr];
      comment[i] = val;
      setCommentArr(comment);
      setRepairHistoryObj({ ...repairHistoryObj, comments: comment });
    }
    setFormHelperTexts({});
  };

  //step2- feild handling
  const addCostField = () => {
    if (costArr.length < 10) {
      if (
        costArr[costArr.length - 1] > 0 ||
        commentArr[commentArr.length - 1] !== ""
      ) {
        const comment = "";
        const cost = 0;
        const cstarr = [...costArr];
        const commtarr = [...commentArr];
        cstarr.push(cost);
        commtarr.push(comment);
        setCostArr(cstarr);
        setCommentArr(commtarr);
      } else {
        setFormHelperTexts({
          ...formHelperTexts,
          costHelper: "Please fill up the the exisiting ones first",
        });
      }
    }
  };

  const removeCostField = (i) => {
    const cstarr = [...costArr];
    const commtarr = [...commentArr];
    cstarr.splice(i, 1);
    commtarr.splice(i, 1);
    setCostArr(cstarr);
    setCommentArr(commtarr);
    setFormHelperTexts({ ...formHelperTexts, costHelper: "" });
  };

  const handleSubmit = () => {
    setShowSpinner(true);
    let tireDetailsPayload;
    let repairretreadcost =
      locationObj.state.changeFrom === "repair"
        ? tireDetails.repairCost
        : tireDetails.retreadCost;
    for (const element of costArr) {
      if (!isNaN(element)) {
        repairretreadcost += element;
      }
    }
    if (locationObj.state.changeFrom === "repair") {
      tireDetailsPayload = { ...tireDetails, repairCost: repairretreadcost };
    } else {
      tireDetailsPayload = { ...tireDetails, retreadCost: repairretreadcost };
    }

    let successStatus = false;

    headerApiObj.editTire(tireDetailsPayload).then((response) => {
      if (response.didError === false) {
        successStatus = true;
        let updatedTireInspDetails = {
          ...tireInspDetails,
          inspectionDate: new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString(),
        }
        removeTireApiObj
          .removeInstalledTire(updatedTireInspDetails)
          .then((removedData) => {
            if (
              removedData.didError === false &&
              costArr.length > 0 &&
              commentArr.length > 0 &&
              repairHistoryObj.repairCost[0] !== 0 &&
              repairHistoryObj.repairCost[0] !== 0
            ) {
              changeDispApiObj
                .addRepairCostTireHistory(repairHistoryObj)
                .then((_response) => { });
            }
          });

        if (removalMasterData.length > 0) {
          headerApiObj
            .createMasterData(removalMasterData)
            .then((_masterResponse) => { });
        }
      } else {
        successStatus = false;
      }
      history.push({
        pathname: "/manage-tire",
        state: {
          tireId: tireDetails.tireId,
          dispositionId: 8,
          updatedSection: "changetiredisposition",
          updateStatus: successStatus,
          successMessage: "Tire Disposition updated Successfully",
          successMessageDesc: `Tire with serial no: ${tireDetails.tireSerialNo} disposition was Updated`,
        },
      });
    });
  };

  const resetHoursMilesSubmit = () => {
    setTireDetails({ ...tireDetails, currentHours: 0, currentMiles: 0 });
    handleSubmit();
  };

  //getting stepper component
  function getStepComponent(cStep) {
    switch (cStep) {
      case 0:
        return (
          <RepairDetails
            masterDataSet={masterData}
            tireData={tireDetails}
            tireRemovalData={tireInspDetails}
            repairCost={repairCost}
            onChangeListener={(field, val) => handleFieldChange(field, val)}
            onRemovalReasonChange={(field, value) =>handleRemovalChange(field, value)}
            isRemovalReasonNew={isRemovalReasonNew}
            locationData={locationDetails}
            removalMasterData={removalMasterData}
            formHelpers={formHelperTexts}
          />
        );
      case 1:
        return (
          <RepairCosts
            locationData={locationDetails}
            fieldNames={formCaptions.TIRESVEHICLES.TIREFORM}
            costArr={costArr}
            commentArr={commentArr}
            addField={addCostField}
            deleteField={(event) => removeCostField(event)}
            onChangeListener={(f, i, v) => onCostCommentChange(f, i, v)}
            formHelpers={formHelperTexts}
          />
        );
      default:
        return (<span>Invalid step</span>);
    }
  }

  return (
    <React.Fragment>
      <div className="standalone-new-header-container">
        <div className="return-inv-main-page-container">
          <div className="my-profile-upper-deck">
            <div className="background-map-image-return-to-inv">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={myBreadcrumbs} />
                  <div className="scrap-tires-title">
                    {locationObj.state.changeFrom === "repair"
                      ? tireFormCaptions.REPAIRTOINVENTORY
                      : tireFormCaptions.RETREADTOINVENTORY}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSpinner ? (
        <Spinner />
      ) : (
        <div className="manage-vehicle-create-container create-vehicle-form">
          <div className="create-tire-inner-container">
            <div className="step-indicator-container">
              <div className={styling.rootStepper}>
                <Stepper
                  alternativeLabel
                  activeStep={currentStep}
                  connector={<ColorLibConnector />}
                >
                  {steps.map((label, index) => (
                    <Step key={label} onClick={() => handleStep(index)}>
                      <StepLabel>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>

              <div className="header-inner-container">
                <Grid className="inner-container" container>
                  <Grid item>
                    <p className="item-detail-label">
                      {tireFormCaptions.ACCOUNTLOCATION}
                    </p>
                    <p id="recordedHour" className="field-label">
                      {accLocationString}
                    </p>
                  </Grid>
                  <Grid item>
                    <p className="item-detail-label">
                      {tireFormCaptions.TIRESERIALNO}
                    </p>
                    <p id="recordedHour" className="field-label">
                      {tireDetails.tireSerialNo}
                    </p>
                  </Grid>
                </Grid>
              </div>

              <div className="helper-message">
                {formCaptions.MANDATORYFIELDS}{" "}
                <span className="required-mark">*</span>
              </div>

              <div className="step-form-container">
                {getStepComponent(currentStep)}
              </div>

              <hr className="hr-divider-action" />

              <div className="action-buttons">
                <Button
                  className={styling.cancel}
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {formCaptions.CANCEL}
                </Button>
                {currentStep > 0 && (
                  <Button
                    className={styling.previous}
                    variant="contained"
                    color="secondary"
                    onClick={() => handlePreviousStep()}
                  >
                    {formCaptions.PREVIOUS}
                  </Button>
                )}
                {currentStep === steps.length - 1 ? (
                  <Button
                      className={styling.next}
                      variant="contained"
                      color="primary"
                      onClick={() => handleNextStep()}
                    >
                      {formCaptions.SAVE}
                    </Button>
                ) : (
                  <Button
                    className={styling.next}
                    variant="contained"
                    color="primary"
                    onClick={() => handleNextStep()}
                  >
                    {formCaptions.NEXT}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ResetHoursMilesModal
        open={showResetModal}
        closeResetModal={() => {
          setShowResetModal(false);
          handleSubmit();
          // setResetAsked(true)
        }}
        // setResetAsked={() => setResetAsked(true)}
        onResetHoursMiles={() => resetHoursMilesSubmit()}
      />

      {isCancelModalOpen && (
        <CancelConfirmationPopup
          closeConfirmationModal={() => setCancelModalOpen(false)}
          pathToRedirect={prevPath}
          stateToPass={historyState}
        />
      )}
    </React.Fragment>
  );
};

export default RepairToInventory;
