import Globalization from "../../../globalization/index";

class ScrapHoursByTireTypeDataSetter {
  constructor() {
    this.ScrapHoursByTireTypeGraphData = [];
    this.globalizationMapping = new Globalization();
  }

  setGraphData(data) {
    let graphData = data.model.graphData;
    return graphData;
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    //
    rowsData.tireSizeLabel.forEach((row, index) => {
      filterOptions.categories.forEach((category) => {
        if (!filterInitCategoryData[category.value].includes(row)) {
          filterInitCategoryData[category.value].push(row.toString());
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = {
      chartlabels: [],
      chartData: [
        {
          data: [],
          label: "CostPerHour"
        },
        {
          data: [],
          label: ""
        }
      ],
      count: [],
      manufacturerLabel: [],
      tireSizeLabel: []
    };

    tableData.tireSizeLabel.map((rowData, index) => {
      let isValidItem = categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) {
          return true;
        }
        return appliedFilter[categoryKey].includes(rowData);
      });

      if (isValidItem) {
        filteredTable.chartData[0].data.push(tableData.chartData[0].data[index]);
        filteredTable.chartlabels.push(tableData.chartlabels[index]);
        filteredTable.count.push(tableData.count[index]);
        filteredTable.manufacturerLabel.push(tableData.manufacturerLabel[index]);
        filteredTable.tireSizeLabel.push(tableData.tireSizeLabel[index]);
      }

      return index;
    });

    return filteredTable;
  }
  setDisplayColumns(graphData) {
    let displayColumns = [];
    graphData.chartlabels.forEach((item, index) => {
      displayColumns.push({
        key: item,
        keyIndex: index,
        value: item,
        label: item,
        count: graphData.chartData[0].data[index]
      });
    });
    return displayColumns;
  }
  setDisplayColumnData(ScrapHoursByTireTypeGraphData, selectedCols) {
    let bS = { chartlabels: [], chartData: [{ data: [] }] };

    for (let graphIndex = 0; graphIndex < ScrapHoursByTireTypeGraphData.chartlabels.length; graphIndex++) {
      for (const element of selectedCols) {
        if (element.value === ScrapHoursByTireTypeGraphData.chartlabels[graphIndex]) {
          bS.chartlabels[graphIndex] = element.value;
          bS.chartData[0].data[graphIndex] = element.count;
          break;
        }
      }
    }
    bS.chartlabels = bS.chartlabels.filter((item) => item !== undefined);
    bS.chartData[0].data = bS.chartData[0].data.filter((item) => item !== undefined);
    return bS;
  }
}

export default ScrapHoursByTireTypeDataSetter;
