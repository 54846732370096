import React, { useEffect, useContext, useState } from "react";
import "./ViewParentAccountList.css";
import "../../../../../common-styles/TableFunctionOptions.css";
import {
  Card,
  CardContent,
} from "@material-ui/core";
import ViewParentAccountListApis from "../../../services/ViewParentAccountListApis";
import { StateContext } from "../../../../../services/StateProvider";
import Spinner from "../../../../../common-components/spinner/spinner";
import ViewParentAccountListTable from "../table/ViewParentAccountListTable";
import ViewParentAccountListLabels from "../label-container/ViewParentAccountListLabels";
import ViewParentAccountListSetter from "../../../services/ViewParentAccountListUtils";
import Breadcrumbs from "../../../../../common-components/breadcrumbs/breadcrumbs";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import Globalization from "../../../../../globalization";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import { useLocation } from "react-router";

const ViewParentAccountList = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const [details, setDetails] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);
  const [
    viewParentAccountListTableData,
    setViewParentAccountListTableData,
  ] = useState();

  const [showColumns, setShowColumns] = useState();
  const locationObj = useLocation();
  const globalization = new Globalization();
  const globalHeaderTexts = globalization.HEADER;
  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME
    },
    {
      path: "",
      name: globalHeaderTexts.VIEWPARENTACCOUNT
    }
  ]
  const viewParentAccountListDataSetter = new ViewParentAccountListSetter();
  const viewParentAccountListService = new ViewParentAccountListApis();
  const headerApiObj = new HeaderApi();

  useEffect(() => {
    ViewParentAccountListResponseData();
  }, [globalAccountState]);

  const ViewParentAccountListResponseData = () => {

    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;


    setShowSpinner(true);
    headerApiObj.getCountryList().then(countries => {
      if (locationObj.pathname === "/view-parent-account") {
        if (locationObj.state && locationObj.state.accountId)
          accountIds[0] = locationObj.state.accountId
      }
      // viewParentAccountListService.viewParentAccountGetManageAccountList()
      viewParentAccountListService.viewParentAccountGetParentAccountDetails(accountIds[0])
        .then((data) => {
          let arr = [data.model]
          arr.forEach((item) => {
            let country = countries.model.filter(i => {
              return i.countryId === parseInt(item.country)
            })
            if (country[0])
              item.country = country[0].countryName;
            else
              item.country = "-"
          })
          setDetails(data.model)
        });
      viewParentAccountListService.viewParentAccountGetManageAccountList(accountIds[0])
        .then((data) => {
          data.model.forEach((item) => {
            let country = countries.model.filter(i => {
              return i.countryId === parseInt(item.country)
            })
            if (country[0])
              item.country = country[0].countryName;
            else
              item.country = ""
          })
          let locationList = data.model.filter(i => {
            return i.parentAccountId === accountIds[0]
          })

          let viewParentAccountListTableInfo = viewParentAccountListDataSetter.setData(
            locationList
          );
          setViewParentAccountListTableData(viewParentAccountListTableInfo);

          setShowColumns({
            "LocationName": true,
            "Country": true,
            "State": true,
            "City": true,
            "ContactPerson": true,
            "ContactNo": true,
            "EmailAddress": true,
            "LocationId": false
          });

          setShowSpinner(false);
        });

      setShowSpinner(false);

    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div className="standalone-new-header-container">
      <div className="view-parent-acct-list-main-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {globalHeaderTexts.VIEWPARENTACCOUNT}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="view-parent-account-list-container">
          <ViewParentAccountListLabels details={details} />
          <Card className="view-parent-account-list-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <label className="filter-table-label">
                      <span className="view-parent-account-location-list-heading">
                        {globalHeaderTexts.LOCATION}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              {viewParentAccountListTableData && showColumns &&
                <div className="data-summary-table">
                  <ViewParentAccountListTable
                    tableData={viewParentAccountListTableData}
                    showColumns={showColumns}
                  />
                </div>
              }
            </CardContent>
          </Card>
        </div>
      )}


    </div>
  );
};

export default ViewParentAccountList;
