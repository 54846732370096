import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';


export default class ManageTireListApis {
    cs;
    constructor() {
        this.cs = new CommonService();
    }
    manageTiresByAccount = (getLocationById) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.manageTiresByAccount}${getLocationById}`, 'get', {});
    }
    getLocationListByAccount = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getLocationListByAccount}${parentAccountId}`, "get", {});
      };
    transferTireToLocation = (tireId,locationId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.transferTire}${tireId}/${locationId}`,"put",null);
    };
    transferTireToParent = (payload) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.transferTireWithParent}`,"put",payload);

    };
    deleteTire = (tireId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.deleteTire}${tireId}`,'delete',null);
    };
}