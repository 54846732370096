import React, { useEffect, useState } from "react";
import "./custom-table.css";
import "../../../../../common-styles/TableStyle.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip
} from "@material-ui/core";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../services/utils";
// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },

  container: {
    height: 380,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const commonUtils = new CommonUtils();


const TireWithLowTreadDepth = (props) => {
  const [tableData, setTableData] = useState({
    tireWithLowTreadDepthData: [],
    headCells: [],
  });
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("vehicleName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  function removeDateNaN(row) {
    const item = JSON.parse(JSON.stringify(row));
    return item && item !== "NaN/NaN/NaN" ? item : "";
  }

  // USED FOR SORTING
  function descendingComparator(a, b, orderByName) {
    if (b[orderByName] < a[orderByName]) {
      return -1;
    }
    if (b[orderByName] > a[orderByName]) {
      return 1;
    }
    return 0;
  }
  function getComparator(sOrder, orderByName) {
    return sOrder === "desc"
      ? (a, b) => descendingComparator(a, b, orderByName)
      : (a, b) => -descendingComparator(a, b, orderByName);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => {
      el.DateTimeInstalled = new Date(
        commonUtils.tableDateFormatter(el.DateTimeInstalled)
      );
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      const sOrder = comparator(a[0], b[0]);
      if (sOrder !== 0) {
        return sOrder;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => {
      el[0].DateTimeInstalled = getFormattedDate(el[0].DateTimeInstalled);
      return el[0];
    });
  }

  const getFormattedDate = (inputDate) => {
    const todayTime = inputDate;
    const month = todayTime.getMonth() + 1;
    const day = todayTime.getDate();
    const year = todayTime.getFullYear();
    let newDate = `${month}/${day}/${year}`;
    newDate = commonUtils.tableDateFormatter(newDate);
    return newDate;
  };

  useEffect(() => {
    setTableData({
      tireWithLowTreadDepthData: props.tableData.rowsData,
      headCells: props.tableData.headCells,
    });
    let arr = commonUtils.rowPerPageSelector(tableData.tireWithLowTreadDepthData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      tableData.tireWithLowTreadDepthData.length - page * rowsPerPage
    );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="a dense table"
            size="small"
          >
            <TableHead>
              <TableRow>
                {tableData.headCells.map((headCell) =>
                  props.showColumns[headCell.value] ? (
                    <TableCell
                      className="table-head-row"
                      key={headCell.keyIndex}
                      align="left"
                      sortDirection={orderBy === headCell.value ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.value}
                        direction={orderBy === headCell.value ? order : "asc"}
                        onClick={handleRequestSort(headCell.value)}
                      >
                        {headCell.label}
                        {orderBy === headCell.value ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ) : null
                )}
              </TableRow>
            </TableHead>
            {tableData.tireWithLowTreadDepthData.length > 0 && (
              <TableBody>
                {stableSort(
                  tableData.tireWithLowTreadDepthData,
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        className={
                          index % 2 === 0 ? "table-row" : "table-row-alternate"
                        }
                        tabIndex={-1}
                        key={row.Key}
                      >
                        {props.showColumns.VehicleName && (
                          <TableCell className="table-content-text">
                            {row.VehicleName}
                          </TableCell>
                        )}
                        {props.showColumns.WheelPosition && (
                          <TableCell className="table-content-text">
                            {row.WheelPosition}
                          </TableCell>
                        )}
                        {props.showColumns.SerialNo && (
                          <TableCell className="table-content-text">
                            {row.SerialNo}
                          </TableCell>
                        )}
                        {props.showColumns.PercentageWorn && (
                          <Tooltip title={commonUtils.wornPercentageInfo(row.PercentageWorn)} placement="top-end">
                            <TableCell className={commonUtils.wornPercentageClass(row.PercentageWorn)}>{row.PercentageWorn}%</TableCell>
                          </Tooltip>
                        )}
                        {props.showColumns.TreadDepth && (
                          <TableCell className="table-content-text">
                            {row.TreadDepth}
                          </TableCell>
                        )}
                        {props.showColumns.BrandNo && (
                          <TableCell className="table-content-text">
                            {row.BrandNo}
                          </TableCell>
                        )}
                        {props.showColumns.Location && (
                          <TableCell className="table-content-text">
                            {row.Location}
                          </TableCell>
                        )}
                        {props.showColumns.DateTimeInstalled && (
                          <TableCell className="table-content-text">
                            {removeDateNaN(row.DateTimeInstalled)}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={tableData.headCells.length} />
                  </TableRow>
                )}
              </TableBody>
            )}
            {tableData.tireWithLowTreadDepthData.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell
                    className="no-match-found"
                    colSpan={tableData.headCells.length}
                    align="center"
                  >
                    <div className="no-data-container">
                      <NoDataFound noDataType="table"></NoDataFound>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={arrRowsPerPageOptions}
          component="div"
          count={tableData.tireWithLowTreadDepthData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
        />
      </Paper>
    </div>
  );
};
export default TireWithLowTreadDepth;
