import Globalization from "../../../globalization/index";

class ScrapTiresAnalysisDataSetter {
  constructor() {
    this.data = {};
    this.scrapAnalysisData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      if (
        headCell.value !== "AverageMiles" &&
        headCell.value !== "AverageCostPerMile" &&
        headCell.value !== "AverageWearRatePerMile"
      )
        selectedColShow[headCell.value] = true;
    });
    //
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }
  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    //
    //

    let categoryKeys = Object.keys(appliedFilter);

    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }
  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.scrapAnalysisData.push({
        Key: index,
        RemovalReason: item.removalReason,
        ManufacturerName: item.manufacturerName,
        TypeName: item.typeName,
        SizeName: item.sizeName,
        CompoundName: item.compoundName ? item.compoundName: " " ,
        TireCount: Math.round(item.tireCount),
        AverageHours: Math.round(item.averageHours * 100) / 100,
        AverageMiles: Math.round(item.averageMiles * 100) / 100,
        AverageCostPerHour: ((item.averageCostPerHour * 100) / 100).toFixed(2),
        AverageCostPerMile: ((item.averageCostPerMile * 100) / 100).toFixed(2),
        AverageWorn: Math.round(item.averageWorn * 100) / 100,
        AverageWearRate: Math.round(item.averageWearRate * 100) / 100,
        AverageWearRatePerMile:
          Math.round(item.averageWearRatePerMile * 100) / 100,
        MoneyLost: Math.round(item.moneyLost * 100) / 100,
        PercentageTotal: Math.round(item.percentageTotal * 100) / 100,
        TotalAverageHours:
          Math.round(item.averageHours * 100 * item.tireCount) / 100,
        TotalAverageMiles:
          Math.round(item.averageMiles * 100 * item.tireCount) / 100,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.scrapAnalysisData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.scrapAnalysisData),
      selectedColShow: selectedColShow,
    };
  }
  avgValues = (scrapAnalysisData) => {
    let TireCountSum = 0,
      AverageHoursSum = 0,
      AverageMilesSum = 0,
      AverageCostPerHourSum = 0,
      AverageCostPerMileSum = 0,
      AvgWornSum = 0,
      AverageWearRateSum = 0,
      AverageWearRateMileSum = 0,
      MoneyLostSum = 0,
      PercentageTotalSum = 0;

    scrapAnalysisData.forEach((item, index) => {
      TireCountSum += item.TireCount;
      AverageHoursSum += item.TotalAverageHours;
      AverageMilesSum += item.TotalAverageMiles;

      AverageCostPerHourSum += parseFloat(
        item.AverageCostPerHour * item.TireCount
      );
      AverageCostPerMileSum += parseFloat(
        item.AverageCostPerMile * item.TireCount
      );
      AvgWornSum += item.AverageWorn * item.TireCount;
      AverageWearRateSum += item.AverageWearRate * item.TireCount;
      AverageWearRateMileSum += item.AverageWearRatePerMile * item.TireCount;

      MoneyLostSum += item.MoneyLost;
      PercentageTotalSum += item.PercentageTotal * item.TireCount;
    });
    let TireCount = TireCountSum;
    let AverageHoursAvg = Math.round(AverageHoursSum / TireCountSum);
    let AverageCostPerHourAvg = parseFloat(
      AverageCostPerHourSum / TireCountSum
    ).toFixed(2);
    let AvgWornAvg = Math.round(AvgWornSum / TireCountSum);
    let AverageWearRateAvg = Math.round(AverageWearRateSum / TireCountSum);
    let MoneyLostAvg = Math.round(MoneyLostSum / TireCountSum);
    let PercentageTotalAvg = Math.round(PercentageTotalSum / TireCountSum);
    let AverageMilesAvg = Math.round(AverageMilesSum / TireCountSum);
    let AverageCostPerMileAvg = parseFloat(
      AverageCostPerMileSum / TireCountSum
    ).toFixed(2);
    let AverageWearRatePerMileAvg = Math.round(
      AverageWearRateMileSum / TireCountSum
    );

    const avgValues = {
      RemovalReason: "Total / Average",
      ManufacturerName: "",
      TypeName: "",
      SizeName: "",
      CompoundName: "",
      TireCount: TireCount,
      AverageHours: AverageHoursAvg,
      AverageMiles: AverageMilesAvg,
      AverageCostPerHour: AverageCostPerHourAvg,
      AverageCostPerMile: AverageCostPerMileAvg,
      AverageWorn: AvgWornAvg,
      AverageWearRate: AverageWearRateAvg,
      AverageWearRatePerMile: AverageWearRatePerMileAvg,
      MoneyLost: MoneyLostAvg,
      PercentageTotal: PercentageTotalAvg,
    };
    return avgValues;
  };
  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.RemovalReason.toLowerCase().includes(searchText) ||
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.TireCount.toString().includes(searchText) ||
        (item.AverageHours !== undefined && item.AverageHours.toString().includes(searchText)) ||
        (item.AverageMiles !== undefined && item.AverageMiles.toString().includes(searchText)) ||
        (item.AverageCostPerHour !== undefined && item.AverageCostPerHour.toString().includes(searchText)) ||
        (item.AverageCostPerMile !== undefined && item.AverageCostPerMile.toString().includes(searchText)) ||
        item.AverageWorn.toString().includes(searchText) ||
        item.AverageWearRate.toString().includes(searchText) ||
        item.AverageWearRatePerMile.toString().includes(searchText) ||
        item.MoneyLost.toString().includes(searchText) ||
        item.PercentageTotal.toString().includes(searchText)
      );
    });
  }
}

export default ScrapTiresAnalysisDataSetter;
