import Moment from "moment";
import { getAccountNames } from "../../services/AccountInfoHandler";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Globalization from "../../globalization";
import CommonUtils from "../../services/utils";

const globalizationMapping = new Globalization();
const commonUtils = new CommonUtils();


pdfMake.vfs = pdfFonts.pdfMake.vfs;

/** header */
const addPdfMakeHeader = (axleConfigurationDetails, locationNameSelected) => {

  return (_currentPage, _pageCount) => {
    return {
      margin: [10, 20, 10, 0],
      stack: [
        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  border: [false, false, false, false],
                  color: "white",
                  fillColor: "#1250B0",
                  alignment: "right",
                  columns: [
                    {
                      svg: commonUtils.EmTrackSVGLogo,
                      fit: [50, 80],
                      width: "25%",
                      margin: [0, 2, 0, 2],
                      alignment: "left"
                    },
                    {
                      text: "Survey Templates: " + axleConfigurationDetails.configurationText,
                      alignment: "center",
                      margin: [10, 15, 0, 15],
                      fontSize: 14,
                      width: "50%",
                    },
                    {
                      svg: commonUtils.GoodyearSVGLogo,
                      alignment: "right",
                      width: "25%",
                      fit: [90, 150]
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ["*"],
            margin: [20, 0, 20, 0],
            body: [
              [
                {
                  fontSize: 10,
                  border: [false, false, false, false],
                  color: "#074BA7",
                  fillColor: "#E5F0FF",
                  alignment: "left",
                  columns: [
                    {
                      text: "Date : ",
                      width: "auto",
                    },
                    {
                      text: "  " + Moment(new Date()).format("DD/MM/YYYY"),
                      bold: true,
                    },
                    {
                      text: "Account : ",
                      width: "auto",
                    },
                    {
                      text: " " + getAccountNames().toString(),
                      bold: true,
                    },
                    {
                      text: "Location :  ",
                      width: "auto",
                    },
                    {
                      text: " " + locationNameSelected,
                      bold: true,
                      width: "auto",
                    },
                  ],
                },
              ],
            ],
          },
        },
      ],
    };
  };
};

/** footer */
const addPdfmakefooter = () => {
  let footerText =
    "©" + new Date().getFullYear() + " The Goodyear Tire & Rubber Company ";
  return (currentPage, pageCount) => {
    // you can apply any logic and return any valid pdfmake element
    return {
      margin: [10, 0, 10, 0],
      border: [false, false, false, false],
      table: {
        widths: ["*"],
        body: [
          [
            {
              border: [false, false, false, false],
              fontSize: 9,
              color: "white",
              fillColor: "#1250B0",
              alignment: "left",
              columns: [
                {
                  text: footerText,
                  alignment: "left",
                  width: "40%",
                  margin: [0, 13, 0, 0],
                  bold: true
                },
                {
                  text: "PAGE " + currentPage.toString() + "/" + pageCount.toString(),
                  alignment: "right",
                  margin: [0, 13, 0, 0],
                  fontWeight: 400
                }
              ],
            },
          ],
        ],
      },
    };
  };
};

/** makes the survey template body */
const surveyTemplateForm = (axleConfigurationDetails) => {
  let axleConfigRows =
    axleConfigurationDetails.configurationPosition.split(",");

  let dynamicSurveyTemplateContent = [];
  let staticHeaderTemplate, dynamicBodyTemplate;

  // generates the body content dynamically for survey templates
  axleConfigRows.forEach((axleRowConfig, index) => {
    staticHeaderTemplate = [
      {
        margin: [0, 7],
        columns: [
          {
            width: "30%",
            text:
              globalizationMapping.INSPECT.SURVEYDATE +
              " \t\t\t\t\t\t\t\t\t\t\t ",
            style: "contentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "50%",
            text:
              globalizationMapping.REPORTTABLE.ACCOUNTNAME +
              " \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
            style: "contentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "20%",
            text:
              globalizationMapping.POPUPWORDS.InspID + " \t\t\t\t\t\t\t\t\t",
            style: "contentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
        ],
      },
      {
        margin: [0, 7],
        columns: [
          {
            width: "30%",
            table: {
              widths: "90%",
              body: [[" "]],
            },
          },
          {
            width: "35%",
            text:
              globalizationMapping.REPORTCOMMONWORDS.MakeModel +
              " \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
            style: "contentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "35%",
            text:
              globalizationMapping.REPORTCOMMONWORDS.HourMeter +
              " \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
            style: "contentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
        ],
      },
      {
        margin: [0, 3],
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 5,
            x2: 580,
            y2: 5,
            lineWidth: 1,
            lineColor: "lightgray",
          },
        ],
      },
    ];

    if (index === 0) {
      dynamicSurveyTemplateContent.push(staticHeaderTemplate);
    }

    dynamicBodyTemplate = [
      {
        columns: [
          {
            width: "7.5%",
            text: axleRowConfig,
            style: "captionStyle",
          },
          {
            width: "77.5%",
            text: "",
          },
          {
            width: "7.5%",
            text: globalizationMapping.REPORTCOMMONWORDS.CurrentNS,
            style: "innerContentSubHeader",
          },
          {
            width: "7.5%",
            text: globalizationMapping.REPORTCOMMONWORDS.HotCold,
            style: "innerContentSubHeader",
            alignment: "right",
          },
        ],
        unbreakable: true,
      },
      {
        margin: [0, 3],
        columns: [
          {
            width: "5%",
            text: "",
          },
          {
            width: "15%",
            text: globalizationMapping.REPORTTABLE.SIZE + " \t\t\t\t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "20%",
            text:
              globalizationMapping.REPORTCOMMONWORDS.TireManuf +
              " \t\t\t\t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "15%",
            text: globalizationMapping.REPORTTABLE.TYPE + " \t\t\t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "15%",
            text: "PR \t\t\t\t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "15%",
            text: "Comp \t\t\t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "7.5%",
            text: "(O) \t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "7.5%",
            text: "\t\t  (H)",
            style: "innerContentStyle",
            alignment: "right",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
        ],
        unbreakable: true,
      },
      {
        margin: [0, 3],
        columns: [
          {
            width: "5%",
            text: "",
          },
          {
            width: "15%",
            text:
              globalizationMapping.REPORTCOMMONWORDS.CustomerNo + " \t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "35%",
            text:
              globalizationMapping.REPORTTABLE.SerialNo +
              " \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "15%",
            text:
              globalizationMapping.REPORTCOMMONWORDS.TireHours + " \t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "15%",
            text:
              globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM.INDCODE +
              "\t\t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "7.5%",
            text: "(C) \t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "7.5%",
            text: "\t\t  (C)",
            style: "innerContentStyle",
            alignment: "right",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
        ],
        unbreakable: true,
      },
      {
        margin: [0, 3],
        columns: [
          {
            width: "5%",
            text: "",
          },
          {
            width: "80%",
            text:
              globalizationMapping.REPORTTABLE.COMMENTS +
              " \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
          {
            width: "7.5%",
            text: "(I)   \t\t\t",
            style: "innerContentStyle",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
        ],
        unbreakable: true,
      },
      {
        margin: [0, 3],
        columns: [
          {
            width: "5%",
            text: "",
          },
          {
            width: "80%",
            text: "-\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
            decoration: "underline",
            decorationColor: "#e3e3e3",
          },
        ],
        unbreakable: true,
      },
      {
        margin: [0, 5],
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 5,
            x2: 580,
            y2: 5,
            lineWidth: 1,
            lineColor: "lightgray",
          },
        ],
        unbreakable: true,
      },
    ];

    dynamicSurveyTemplateContent.push(dynamicBodyTemplate);
  });

  // generates dynamic page breaks
  // let dynamicPageBreakerFunction =
  // {
  //     pageBreakBefore : function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
  //         return currentNode.pageNumbers.length > 1 && currentNode.unbreakable;
  //       }

  //     pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
  //         // check if surveyTemplateBody part is completely on the last page, add pagebreak if not
  //         if (currentNode.id === 'surveyTemplateBody' && (currentNode.pageNumbers[0] !== currentNode.pages)) {
  //           return true;
  //         }
  //           return false;

  // }

  // generates the body content styles for survey templates
  let surveyTemplateStyles = {
    captionStyle: {
      fontSize: 15,
      bold: true,
      color: "#252525",
    },
    contentStyle: {
      fontSize: 9,
      bold: true,
      color: "#252525",
    },
    innerContentStyle: {
      fontSize: 9,
      bold: false,
      color: "#252525",
    },
    innerContentSubHeader: {
      fontSize: 8,
      bold: true,
      color: "#252525",
    },
  };

  //  returns the content and style to be added in the doc definition of pdf
  return {
    styles: surveyTemplateStyles,
    content: dynamicSurveyTemplateContent,
    // dynamicPageBreakerFunction : dynamicPageBreakerFunction
  };
};

/** Doc definition */
const createDocumentDefinition = (
  _reportDate,
  axleConfigurationDetails,
  locationNameSelected
) => {
  const baseDocDefinition = {
    pageSize: "A4",
    pageMargins: [10, 90, 10, 55],
    pageOrientation: "portrait",
  };

  const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
  docDefinition.header = addPdfMakeHeader(
    axleConfigurationDetails,
    locationNameSelected
  );
  docDefinition.footer = addPdfmakefooter();

  let surveyTemplateFormDefinition = surveyTemplateForm(
    axleConfigurationDetails
  );
  docDefinition.styles = surveyTemplateFormDefinition.styles;
  docDefinition.content = surveyTemplateFormDefinition.content;
  // docDefinition.pageBreakBefore = surveyTemplateFormDefinition.dynamicPageBreakerFunction.pageBreakBefore
  const defaultFont = {defaultStyle: {
    font: 'Barlow'
  }}
  docDefinition.defaultStyle = defaultFont;
  

  return docDefinition;
};

// customizes filename to be used on download of pdf
export const getFilename = (axleConfigurationDetails) => {
  return (
    axleConfigurationDetails.configurationText
      .trim()
      .replace(new RegExp(" ", "g"), "_") + ".pdf"
  );
};

// creates the survey template pdf
export const MakeSurveyTemplate = (
  axleConfigurationDetails,
  locationNameSelected
) => {
  // -- Create a base document template for the templates.
  const docDefinition = createDocumentDefinition(
    new Date(),
    axleConfigurationDetails,
    locationNameSelected
  );
  return pdfMake.createPdf(docDefinition);
};
