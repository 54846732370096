import {
  Button,
  FormHelperText,
  Grid,
  makeStyles,
  OutlinedInput,
} from "@material-ui/core";
import React from "react";
import deleteIcon from "../../../../assets/delete-icon.svg";
import CommonUtils from '../../../../services/utils';
import Globalization from "../../../../globalization";

const useStyles = makeStyles({
  adorn: {
    "& .MuiTypography-colorTextSecondary": {
      color: "#000000",
      fontSize: "medium",
      fontWeight: "800",
    },
  },
  popper: {
    padding: 10,
    border: "1px solid #1C72D0",
    borderRadius: "5px",
    background: "#B1D2FF",
    fontSize: "12px",
  },
  addTextField: {
    color: "#0059AC",
    margin: "20px 0 10px 0",
    backgroundColor: "#E6EFFE",
    border: "1px solid #77ACFF",
    opacity: "0.44",
  },
});

const RepairCosts = (props) => {
  const commonUtils = new CommonUtils();
  const styles = useStyles();
  const globalizationMapping = new Globalization();


  return (
    <React.Fragment>
      {props.costArr.length > 0 &&
        props.costArr.map((val, key) => {
          return (
            <Grid className="step-costs-inner-container" container spacing={1} key={val}>
              <Grid item sm={4}>
                <p className="field-label">
                  {globalizationMapping.VIEWRIMREPORTS.COST}
                </p>
                <OutlinedInput
                  type="number"
                  className="enter-detail-input-box"
                  variant="outlined"
                  value={val}
                  autoFocus
                  placeholder={props.fieldNames.COST}
                  onChange={(e) => props.onChangeListener("cost", key, commonUtils.minmaxInt(e.target.value, 0, 999999))}
                />
              </Grid>
              <Grid item sm={7}>
                <p className="field-label">
                  {globalizationMapping.REPORTTABLE.COMMENTS}
                </p>
                <OutlinedInput
                  className="enter-detail-input-box"
                  variant="outlined"
                  value={props.commentArr[key]}
                  placeholder={props.fieldNames.COMMENTS}
                  onChange={(e) =>
                    props.onChangeListener("comment", key, e.target.value)
                  }
                />
              </Grid>
              <Grid item sm={1}>
                {key === props.costArr.length - 1 && (
                  <img
                    style={{ marginTop: "41px" }}
                    src={deleteIcon}
                    onClick={() => props.deleteField(key)}
                    color="primary"
                    alt="delete-icon"
                  />
                )}
              </Grid>
            </Grid>
          );
        })}
      <FormHelperText>{props.formHelpers.costHelper}</FormHelperText>
      <Button
        className={styles.addTextField}
        size="small"
        onClick={props.addField}
      >
        {props.fieldNames.ADDCOST}
      </Button>
    </React.Fragment>
  );
};

export default RepairCosts;
