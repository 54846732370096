import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';

export default class TiresService {
    cs;
    constructor() {
        this.cs = new CommonService();
    }
    getTiresReport = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getReport, 'post', req)
    }
    getTiresWheelPositionReport = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getWheelPosition, 'post', req)
    }
    getNewVsScrapReport = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getNewVsScrapReport, 'post', req)
    }
    getUserTiresReport = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getUserReport, 'post', req)
    }
    getAchievedHoursReport = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getAchievedHoursReport, 'post', req)
    }
    getTireSerial = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getTireSerialNo, 'post', req)
    }

    getTiresGraph = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getGraph, 'post', req)
    }

    getDistinctTireSize = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getDistinctTireSize, 'post', req)
    }

    getDistinctTireSizeForNewVsScrap = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getDistinctTireSizeNS, 'post', req)
    }
    isBrandNumberUniqueInTire = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.isBrandNumberUniqueInTire, 'post', req)
    }
    getLocationById = (locId) => {
        return this.cs.httpConnection(SERVER.SERVICES.getLocationById + locId, 'get', {})
    }
    getUserIdList = () => {
        return this.cs.httpConnection(SERVER.SERVICES.getUserIdList, 'get', {})
    }
    filterAccounts = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.FilterAccount, 'post', req)
    }
    getTiresByAccountList = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.getTiresByAccountList, 'post', req)
    }
}