import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import "../common-modal/CommonPopup.css";
import CommonUtils from "../../../services/utils";
import Globalization from "../../../globalization";


const ExportCsv = (props) => {
  const globalizationMapping = new Globalization();
  const commonUtils = new CommonUtils();
  const [rowsData, setRowsData] = useState(props.exportCsv?.rowsData);
  const [headCells, setHeadCells] = useState(props.exportCsv?.headCells);


  useEffect(() => {
    const headCellArr = [];
    let rowData = [];
    JSON.parse(JSON.stringify(props.exportCsv.headCells)).forEach(
      (headCell, _index) => {
        if (props.exportCsv.selectedColShow && props.exportCsv.selectedColShow[headCell.value] === true) {
          if (headCell.value === null) {
            headCell.label = "";
          }
          if (
            headCell.label.includes(",") ||
            headCell.label.includes('"') ||
            headCell.label.includes("'") ||
            headCell.label.includes("\\")
          ) {
            headCell.label = headCell.label.replaceAll('"', '""');
          }

          headCellArr.push(headCell);
        }
      }
    );
    setHeadCells(headCellArr);

    props.exportCsv.rowsData.forEach((row, _index) => {
      const rowJson = {};
      Object.keys(row).forEach((key, _id) => {
        rowJson[key] = row[key];
        return true;
      });
      rowData.push(rowJson);
    });
    setRowsData(rowData);
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        { rowsData: rowsData, headCells: headCells },
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setRowsData(data.rowsData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);


  const classExportCsv = () => {
      if(!props.exportCsv.isReady) {
        return "header-change-modal-confirm-btn header-change-modal-confirm-btn-disabled"
      } else {
        return "header-change-modal-confirm-btn"
      }
  }

  return (
    <>
      {props.multireport ? (
        <CSVLink
          className="export-csv-link"
          filename={`${props.exportCsv.reportName}${".csv"}`}
          data={rowsData}
          headers={headCells}
          enclosingCharacter={`'`}
          target="_blank"
        >
          <button
            style={{ width: "fit-content" }}
            disabled={!props.exportCsv.isReady}
            className={classExportCsv()}
          >
            {globalizationMapping.MULTIREPORTS.DownloadCSV}
          </button>
        </CSVLink>
      ) : (
        <CSVLink
          className="export-csv-link"
          filename={`${props.exportCsv.reportName}${".csv"}`}
          data={rowsData}
          headers={headCells}
          enclosingCharacter={`'`}
          target="_blank"
        >
          CSV
        </CSVLink>
      )}
    </>
  );
};
export default ExportCsv;
