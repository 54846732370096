/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "../../../../../common-styles/TableStyle.css";
import "./ManageTireListTable.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from '@material-ui/icons/MoreVertOutlined';
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import { useHistory } from "react-router-dom";
import TransferTireModal from "../../modals/transfer-tire/transfer-tire-modal";
import DeleteTireModal from "../../modals/delete-tire/delete-tire-modal";
import ChangeTireDispositionModal from "../../../../change-tire-disposition/components/modal/change-disp-modal";
import Globalization from "../../../../../globalization";
import CommonUtils from "../../../../../services/utils";
// import onTireOptionsClick from "../../../services/ManageTireRouting";
// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    height: 1000,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
    // whiteSpace: "nowrap !important"
  },
}));

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}



function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const commonUtils = new CommonUtils();

const ManageTireListTableViewOnly = ({
  tableData,
  _filterApplied,
  showColumns,
  multipleRowsSelected,
  locationList,
  masterData,
  currentRoute,
  initModalNotifs,
  forceActionPopupClose,
  forceActionPopupCloseToFalse,
}) => {
  const history = useHistory();
  const [manageTireListTableData, setManageTireListTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("SerialNo");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [editClicked, setEditClicked] = useState([]);
  const [selectedTireIds, setSelectedTireIds] = useState([]);
  const [selectedTiresDetails, setSelectedTiresDetails] = useState([]);
  const [tireIdForAction, setTireIdIdForAction] = useState(null);
  const [tireSerialForAction, setTireSerialForAction] = useState(null);
  const [selectedTireDisposition, setSelectedTireDisposition] = useState("");
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDispModalOpen, setDispModalOpen] = useState(false);
  const globalizationMapping = new Globalization();

  useEffect(() => {
    setManageTireListTableData(tableData);
    setPage(0);
    let row = [];
    row.length = manageTireListTableData.rowsData.length;
    for (let i = 0; i < manageTireListTableData.rowsData.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
    let arr = commonUtils.rowPerPageSelector(tableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
  }, [tableData, showColumns]);


  useEffect(()=> {
    forceCloseAction();
    forceActionPopupCloseToFalse();
  },[forceActionPopupClose])



  const handleRequestSort = (property) => (_event) => {
    forceCloseAction();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleChangePage = (_event, newPage) => {
    forceCloseAction();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    forceCloseAction();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSelectAllClick = () => {
    forceCloseAction();
    setSelectedTiresDetails([]);
    if (rowsSelected !== manageTireListTableData.rowsData.length) {
      let tireIds = manageTireListTableData.rowsData.map((tire, _ind) => {
        return tire.Key;
      });
      setSelectedTireIds(tireIds);
      setSelectedTiresDetails(manageTireListTableData.rowsData);
      setRowsSelected(manageTireListTableData.rowsData.length);
      multipleRowsSelected(manageTireListTableData.rowsData);
    } else {
      setSelectedTireIds([]);
      setRowsSelected(0);
      multipleRowsSelected([]);
    }
  };

  const onRowSelect = (_index, tireId) => {
    let selectedIdsList = [...selectedTireIds];
    let selectedDetailsList = [...selectedTiresDetails];
    let ind = selectedTireIds.indexOf(tireId);
    let selectedCount = selectedTiresDetails.length;
    if (ind > -1) {
      selectedIdsList.splice(ind, 1);
      selectedDetailsList.splice(ind, 1);
      selectedCount--;
    } else {
      selectedCount++;
      let newSelectedTire = manageTireListTableData.rowsData.find(
        (tire) => tire.Key === tireId
      );
      selectedIdsList.push(tireId);
      selectedDetailsList.push(newSelectedTire);
    }

    setSelectedTireIds(selectedIdsList);
    setSelectedTiresDetails(selectedDetailsList);
    multipleRowsSelected(selectedDetailsList);
    setRowsSelected(selectedCount);
  };

  const editRow = (index) => {
    let row = [...editClicked];
    row.length = manageTireListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      if (row[i] === true && index !== i) {
        row[i] = false;
      }
    }
    if (editClicked[index]) {
      row[index] = !row[index];
      setEditClicked(row);
    } else {
      row[index] = true;
      setEditClicked(row);
    }
  };

  const forceCloseAction = () => {
    let row = [...editClicked];
    row.length = manageTireListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
  }


  const classNameSet = (_keyIndex, value) => {
    if (value === "Actions") {
      return "tire-table-head-row sticky-col last-col";
    }
    return "tire-table-head-row";
  };

  const handleTransferModalClose = (transferNotificationData) => {
    setTransferModalOpen(false);
    setTireIdIdForAction(null);
    setTireSerialForAction(null);
    initModalNotifs({ state: transferNotificationData });
  };

  const handleDeleteModalClose = (deleteNotificationData) => {
    setDeleteModalOpen(false);
    setTireIdIdForAction(null);
    setTireSerialForAction(null);
    initModalNotifs({ state: deleteNotificationData });
  };

  const handleDispModalClose = (dispNotificationData) => {
    setDispModalOpen(false);
    setTireIdIdForAction(null);
    setTireSerialForAction(null);
    setSelectedTireDisposition("");
    initModalNotifs({ state: dispNotificationData });
  };

  const findBottom = (index) => {
    return (index > 7 && index < 10) ||
      (index > 12 && index < 15) ||
      (index > 22 && index < 25) ||
      index === manageTireListTableData.rowsData.length
      ? true
      : false;
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.stickyHeader}
                    padding="checkbox"
                  >
                    <Checkbox
                      disabled
                      indeterminate={
                        rowsSelected > 0 &&
                        rowsSelected < manageTireListTableData.rowsData.length
                      }
                      checked={
                        manageTireListTableData.rowsData.length > 0 &&
                        rowsSelected === manageTireListTableData.rowsData.length
                      }
                      style={{
                        zIndex: 2,
                      }}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  </TableCell>
                  {manageTireListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className={classNameSet(
                          headCell.keyIndex,
                          headCell.value
                        )}
                        // className="tire-table-head-row"
                        key={headCell.keyIndex}
                        // align={headCell.numeric === true ? "right" : "left"}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        {headCell.value === "Actions" ? (
                          <>{headCell.label}</>
                        ) : (
                          <TableSortLabel
                            active={orderBy === headCell.value}
                            direction={
                              orderBy === headCell.value ? order : "asc"
                            }
                            onClick={handleRequestSort(headCell.value)}
                          >
                            {headCell.label}
                            {orderBy === headCell.value ? (
                              <span className={classes.visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              <tbody>
                <tr className="table-bottom-line">
                  <td colSpan={manageTireListTableData.headCells.length}></td>
                </tr>
              </tbody>
              {manageTireListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    manageTireListTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          tabIndex={-1}
                          key={row.Key}
                        >
                          <TableCell
                            style={{ position: "relative" }}
                            className="table-content-text"
                            padding="checkbox"
                          >
                            <Checkbox
                              disabled
                              checked={
                                rowsSelected ===
                                  manageTireListTableData.rowsData.length ||
                                selectedTireIds.indexOf(row.Key) > -1
                              }
                              onChange={() => onRowSelect(index, row.Key)}
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                            />
                          </TableCell>
                          {showColumns.SerialNo && (
                            <TableCell
                              align="left"
                              style={{ position: "relative" }}
                              className="table-content-text"
                            >
                              {row.SerialNo.replace(/(\w)(\w*)/g,
                                function (g0, g1, g2) { return g1.toUpperCase() + g2.toUpperCase(); })}
                            </TableCell>
                          )}
                          {showColumns.Brand && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Brand}
                            </TableCell>
                          )}
                          {showColumns.RimSerialNo && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.RimSerialNo}
                            </TableCell>
                          )}
                          <TableCell
                            align="left"
                            style={{ position: "relative" }}
                            className="table-content-text"
                            >
                              {showColumns.VehicleNumber && row.VehicleNumber ? row.VehicleNumber : " "}
                          </TableCell>
                          {showColumns.WheelPosition && (
                            <TableCell
                              align="left"
                              style={{ position: "relative" }}
                              className="table-content-text"
                            >
                              {row.WheelPosition}
                            </TableCell>
                          )}
                          {showColumns.Location && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Location}
                            </TableCell>
                          )}
                          {showColumns.Disposition && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Disposition}
                            </TableCell>
                          )}
                          {showColumns.Status && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Status}
                            </TableCell>
                          )}
                          {showColumns.Make && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Make}
                            </TableCell>
                          )}
                          {showColumns.Type && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Type}
                            </TableCell>
                          )}
                          {showColumns.Size && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Size}
                            </TableCell>
                          )}
                          {showColumns.CompoundName && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.CompoundName}
                            </TableCell>
                          )}
                          {showColumns.RatingName && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.RatingName}
                            </TableCell>
                          )}
                          {showColumns.CurrentHours && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.CurrentHours}
                            </TableCell>
                          )}
                          {showColumns.Miles && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Miles}
                            </TableCell>
                          )}
                          {showColumns.Kilometers && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Kilometers}
                            </TableCell>
                          )}
                          {showColumns.TreadDepth32 && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.TreadDepth32}
                            </TableCell>
                          )}
                          {showColumns.TreadDepthmm && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.TreadDepthmm}
                            </TableCell>
                          )}
                          {showColumns.PercentageWorn && 
                            <Tooltip title={commonUtils.wornPercentageInfo(row.PercentageWorn)} placement="top-end">
                              <TableCell className={commonUtils.wornPercentageClass(row.PercentageWorn)}>{row.PercentageWorn}%</TableCell>
                            </Tooltip>}
                          {showColumns.LastRead && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.LastRead}
                            </TableCell>
                          )}
                          <TableCell
                            align="left"
                            className="sticky-col last-col table-content-text last-row fixed-column-body fourth-fleet-col table-content-fleet-text"
                            style={{ position: "relative" }}
                          >
                            <IconButton
                              aria-label="edit"
                              onClick={() => editRow(index)}
                            >
                              <MoreIcon style={{color:commonUtils.primaryBlue}} fontSize='small'></MoreIcon>
                            </IconButton>
                            {editClicked[index] === true && (
                              <div
                                className="action-tire-dropdown-content"
                                style={{
                                  marginTop: findBottom(index)
                                    ? "-80px"
                                    : "-30px",
                                }}
                              >
                                <div
                                  className="action-tire-option"
                                  onClick={() => {
                                    history.push({
                                      pathname: "/view-tire",
                                      state: {
                                        tireId: row.Key,
                                        fromPath: 'manage-tire'
                                      },
                                    });
                                  }}
                                >
                                  {globalizationMapping.HOMEDASHBOARD.VIEW}
                                </div>                              
                           </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              <tbody>
                <tr className="table-bottom-line">
                  <td colSpan={manageTireListTableData.headCells.length + 1}></td>
                </tr>
              </tbody>
              {manageTireListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={12}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {isTransferModalOpen && (
            <TransferTireModal
              closeTransferModal={handleTransferModalClose}
              locationList={locationList}
              tireId={tireIdForAction}
              tireSerial={tireSerialForAction}
              isSelectionMultiple={false}
            />
          )}

          {isDeleteModalOpen && (
            <DeleteTireModal
              closeDeleteModal={handleDeleteModalClose}
              tireId={tireIdForAction}
              tireSerial={tireSerialForAction}
              isSelectionMultiple={false}
            />
          )}

          {isDispModalOpen && (
            <ChangeTireDispositionModal
              closeDispModal={handleDispModalClose}
              tireId={tireIdForAction}
              tireSerial={tireSerialForAction}
              tireDisposition={selectedTireDisposition}
              dispositionList={masterData.tireDispositions}
            />
          )}

          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={tableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default ManageTireListTableViewOnly;
