import React from 'react';
import { FormHelperText, Grid, InputAdornment, OutlinedInput } from '@material-ui/core';
import './create-tire.css';
import Globalization from "../../../globalization/index";
import CommonUtils from '../../../services/utils';

const TreadDepth = props => {
    const globalizationMapping = new Globalization();
    const commonUtils = new CommonUtils();
    const tireFormCaptions = globalizationMapping.FORMS;
    const validationTexts = tireFormCaptions.VALIDATIONTEXTS;


    const treadDepthPercentage = (tread) => {
        let percentage = Math.round(((parseInt(props.stateVar.originalTread, 10) - parseInt(tread, 10)) / 
        (parseInt(props.stateVar.originalTread, 10) - parseInt(props.stateVar.removeAt, 10))) * 100);

        if (isNaN(percentage)) {
         return 0;
        } else {
            return percentage;
        }
    }

    const originalTreadHelperText = () => {
        if (parseInt(props.stateVar.originalTread) < parseInt(props.stateVar.removeAt)) {
            return <FormHelperText>{validationTexts.MINORGTREAD}</FormHelperText>;
        } else if (props.stateVar.originalTread === "") {
            return <FormHelperText>{validationTexts.MISSINGFIELD}</FormHelperText>;
        } else {
            return;
        }
    }

    const treadDepthHelperText = (tread) => {
        if (tread === "") {
            return <FormHelperText>{validationTexts.MISSINGFIELD}</FormHelperText>;
        } else if ( parseInt(tread, 10) > parseInt(props.stateVar.originalTread, 10)) {
            return <FormHelperText>{validationTexts.MAXTREAD}</FormHelperText>;
        } else if ( parseInt(tread, 10) < parseInt(props.stateVar.removeAt, 10)) {
            return <FormHelperText>{validationTexts.MINTREAD}</FormHelperText>;
        }
    }

    const originalTreadDepthLimiter = (value) => {
        if (parseInt(value) < 0) {
            return 0;
        } else if (parseInt(value) > parseInt(props.stateVar.originalTread, 10)) {
            return parseInt(props.stateVar.originalTread, 10);
        } else {
            return parseInt(value);
        }
    }

    return (
        <Grid className="step-form-inner-container" container spacing={2} >
                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.ORGTREAD} <span className="required-mark">*</span></p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.originalTread}
                        onChange={e => props.onChangeListener('orgTread', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.ORGTREAD}`}
                    />
                        {(props.stateVar.originalTread !== "" && (parseInt(props.stateVar.originalTread) === parseInt(props.stateVar.removeAt)) && props.isOriginalTreadEdited) ? <FormHelperText>{`Value must be greater than its set pull point (Original Tread > Remove at)`}</FormHelperText> : ''}
                        {(props.stateVar.originalTread !== "" && (parseInt(props.stateVar.originalTread) < parseInt(props.stateVar.removeAt)) && props.isOriginalTreadEdited) ? <FormHelperText>{`Value must be greater than its set pull point (Original Tread > Remove at)`}</FormHelperText> : ''}
                        {(props.stateVar.originalTread === "" && props.isOriginalTreadEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                        {(props.isOriginalTreadEdited ? "" : originalTreadHelperText())} 
                </Grid>
                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.PURCHASEDTREAD}</p>
                    <OutlinedInput
                        type="number"
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={(props.stateVar.tireStatusId === 7 ? props.stateVar.originalTread : props.stateVar.purchasedTread)}
                        onChange={e => props.onChangeListener('purtrd', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                        placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.PURCHASEDTREAD}`}
                    />
                </Grid>
                {(props.accLocationInfo.noOfTreadDepthReadings === 1
                    || props.accLocationInfo.noOfTreadDepthReadings === 2
                    || props.accLocationInfo.noOfTreadDepthReadings === 3) &&
                    <Grid item sm={12} >
                        <p className="field-label">{props.fieldNames.OUTTRD} <span className="required-mark">*</span></p>
                        <OutlinedInput
                            type="number"
                            className={props.stateVar.originalTread < props.stateVar.removeAt ?"enter-detail-input-box disabled-input" :"enter-detail-input-box"}
                            variant="outlined"
                            value={props.stateVar.outsideTread}
                            onChange={e => props.onChangeListener('outsidetrd', originalTreadDepthLimiter(e.target.value))}
                            placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.OUTTRD}`}
                            endAdornment={<InputAdornment
                                className=""
                                position="end">
                                {treadDepthPercentage(props.stateVar.outsideTread)}
                                {props.fieldNames.PERCENTAGEWORN}
                            </InputAdornment>}
                            disabled={props.stateVar.originalTread < props.stateVar.removeAt}
                        />
                        {(props.stateVar.outsideTread !== "" && (parseInt(props.stateVar.outsideTread, 10) > parseInt(props.stateVar.originalTread, 10)) && props.isOutsideTreadEdited) ? <FormHelperText>{`Value cannot be greater than its Initial value (Tread < Original Tread)`}</FormHelperText> : ''}
                        {(props.stateVar.outsideTread !== "" && (parseInt(props.stateVar.outsideTread, 10) < parseInt(props.stateVar.removeAt, 10)) && props.isOutsideTreadEdited) ? <FormHelperText>{`Value cannot be less than its set pull point (Tread > Remove at)`}</FormHelperText> : ''}
                        {(props.stateVar.outsideTread === "" && props.isOutsideTreadEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                        {(props.isOutsideTreadEdited ? "" : treadDepthHelperText(props.stateVar.outsideTread))} 
                    </Grid>}
                {(props.accLocationInfo.noOfTreadDepthReadings === 3) &&
                    <Grid item sm={12} >
                        <p className="field-label">{props.fieldNames.MIDTRD} <span className="required-mark">*</span></p>
                        <OutlinedInput
                            type="number"
                            className={props.stateVar.originalTread < props.stateVar.removeAt ?"enter-detail-input-box disabled-input" :"enter-detail-input-box"}
                            variant="outlined"
                            value={props.stateVar.middleTread}
                            onChange={e => props.onChangeListener('mdltrd', originalTreadDepthLimiter(e.target.value))}
                            placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.MIDTRD}`}
                            endAdornment={<InputAdornment
                                className=""
                                position="end">
                                {treadDepthPercentage(props.stateVar.middleTread)}
                                {props.fieldNames.PERCENTAGEWORN}
                            </InputAdornment>}
                            disabled={props.stateVar.originalTread < props.stateVar.removeAt}
                        />
                        {(props.stateVar.middleTread !== "" && (parseInt(props.stateVar.middleTread, 10) > parseInt(props.stateVar.originalTread, 10)) && props.isMiddleTreadEdited) ? <FormHelperText>{`Value cannot be greater than its Initial value (Tread < Original Tread)`}</FormHelperText> : ''}
                        {(props.stateVar.middleTread !== "" && (parseInt(props.stateVar.middleTread, 10) < parseInt(props.stateVar.removeAt, 10)) && props.isMiddleTreadEdited) ? <FormHelperText>{`Value cannot be less than its set pull point (Tread > Remove at)`}</FormHelperText> : ''}
                        {(props.stateVar.middleTread === "" && props.isMiddleTreadEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                        {(props.isMiddleTreadEdited ? "" : treadDepthHelperText(props.stateVar.middleTread))} 
                    </Grid>}
                {(props.accLocationInfo.noOfTreadDepthReadings === 2
                    || props.accLocationInfo.noOfTreadDepthReadings === 3) &&
                    <Grid item sm={12} >
                        <p className="field-label">{props.fieldNames.INTRD} <span className="required-mark">*</span></p>
                        <OutlinedInput
                            type="number"
                            className={props.stateVar.originalTread < props.stateVar.removeAt ?"enter-detail-input-box disabled-input" :"enter-detail-input-box"}
                            variant="outlined"
                            value={props.stateVar.insideTread}
                            onChange={e => props.onChangeListener('insidetrd', originalTreadDepthLimiter(e.target.value))}
                            placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.INTRD}`}
                            endAdornment={<InputAdornment
                                className=""
                                position="end">
                                {treadDepthPercentage(props.stateVar.insideTread)}
                                {props.fieldNames.PERCENTAGEWORN}
                            </InputAdornment>}
                            disabled={props.stateVar.originalTread < props.stateVar.removeAt}
                        />
                        {(props.stateVar.insideTread !== "" && (parseInt(props.stateVar.insideTread, 10) > parseInt(props.stateVar.originalTread, 10)) && props.isInsideTreadEdited) ? <FormHelperText>{`Value cannot be greater than its Initial value (Tread < Original Tread)`}</FormHelperText> : ''}
                        {(props.stateVar.insideTread !== "" && (parseInt(props.stateVar.insideTread, 10) < parseInt(props.stateVar.removeAt, 10)) && props.isInsideTreadEdited) ? <FormHelperText>{`Value cannot be less than its set pull point (Tread > Remove at)`}</FormHelperText> : ''}
                        {(props.stateVar.insideTread === "" && props.isInsideTreadEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                        {(props.isInsideTreadEdited ? "" : treadDepthHelperText(props.stateVar.insideTread))} 
                    </Grid>}
                {props.accLocationInfo.treadDepthMethodId === 0 &&
                    <Grid item sm={12} >
                        <p className="field-label">{props.fieldNames.ACTTRD}</p>
                        <OutlinedInput
                            type="number"
                            className="enter-detail-input-box disabled-input"
                            variant="outlined"
                            value={props.stateVar.outsideTread}
                            disabled
                        />
                    </Grid>}
                {props.accLocationInfo.treadDepthMethodId === 1 &&
                    <Grid item sm={12} >
                        <p className="field-label">{props.fieldNames.AVGTRD}</p>
                        <OutlinedInput
                            type="number"
                            className="enter-detail-input-box disabled-input"
                            variant="outlined"
                            value={props.stateVar.currentTreadDepth}
                            disabled
                        />
                    </Grid>}
                {props.accLocationInfo.treadDepthMethodId === 2 &&
                    <Grid item sm={12} >
                        <p className="field-label">{props.fieldNames.MAXTRD}</p>
                        <OutlinedInput
                            type="number"
                            className="enter-detail-input-box disabled-input"
                            variant="outlined"
                            value={props.stateVar.currentTreadDepth}
                            disabled
                        />
                    </Grid>}
                {props.accLocationInfo.treadDepthMethodId === 3 &&
                    <Grid item sm={12} >
                        <p className="field-label">{props.fieldNames.MINTRD}</p>
                        <OutlinedInput
                            type="number"
                            className="enter-detail-input-box disabled-input"
                            variant="outlined"
                            value={props.stateVar.currentTreadDepth}
                            disabled
                        />
                    </Grid>
                }
            </Grid>
    );
};

export default TreadDepth;
