import React, { useEffect, useState, useContext } from "react";
import "./TireHistory.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import TiresInServiceNavigation from "../../../tires-in-service-navigation/TiresInServiceNavigation";
import { Card, CardContent, TextField, InputAdornment, InputLabel, FormControlLabel, Checkbox, Grid, Select } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import TireHistoryDataSetter from "../../../../../services/TireHistoryUtils";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import TireHistoryTable from "../table/TireHistoryTable";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import TireHistoryFilter from '../filter/TireHistoryFilter'


const TireHistory = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const tireHistoryDataSetter = new TireHistoryDataSetter();
  const commonUtils = new CommonUtils();
  const [tireHistoryTableData, setTireHistoryTableData] = useState();
  const [originalTireHistoryTableData, setOriginalTireHistoryTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [noData, setNoData] = useState(false);
  const [tireSerialColOptions, setTireSerialColOptions] = useState();
  const [tireSerial, setTireSerialColumns] = useState();
  const [toggleHM, setToggleHM] = useState("Hours");
  const [showSpinner, setShowSpinner] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [onFirstLoad, setFirstLoad] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" }

    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const [printData, setPrintData] = useState(tireHistoryTableData);
  const [printDataNotPDF, setPrintDataNotPDF] = useState(tireHistoryTableData);
  const globalizationMapping = new Globalization();

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;

  useEffect(() => {
    TireHistoryResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
  }, [globalAccountState]);

  const TireHistoryResponseData = (excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    let updatedHeadCells;
    let tireSerialObject = {
      accountIds: accountIds.toString(),
      locationIds: locationIds.toString(),
      reportName: "tireHistory"
    };
    let filterOptions = {
      categories: [
        { name: globalizationMapping.REPORTTABLE.MANUFACTURER, value: "ManufacturerName"},
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        { name: globalizationMapping.REPORTTABLE.COMPOUND, value: "CompoundName" }
      ],
      categoryData: {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        CompoundName: [],
        dateRangeFromDate: '',
        dateRangeToDate: '',
      }
    };

    setShowSpinner(true);
    setFirstLoad(1);
    tiresService.getTireSerial(tireSerialObject).then((data) => {
      let tireList = tireHistoryDataSetter.setTireData(data);
      setTireSerialColOptions(tireList);
      setTireSerialColumns(tireList);
      let requestObject = {
        accountIds: accountIds,
        dateRangeType: dateRangeType,
        fromDate: dateRangeFilter.fromDate,
        locationIds: locationIds,
        reportName: "Tire_History",
        reportOption: "",
        toDate: dateRangeFilter.toDate,
        tireIds: tireHistoryDataSetter.getTireIds(tireList), // to be deleted soon
        usedTires: excludeUsedTiresValue.toString()
      };
      tiresService.getTiresReport(requestObject).then((data) => {

        let tireHistoryTableInfo = tireHistoryDataSetter.setData(data);

        updatedHeadCells = tireHistoryDataSetter.updatedHeadCells();
        let filterOptionsInfo = tireHistoryDataSetter.setFilterOptionsData(filterOptions, tireHistoryTableInfo.rowsData);
        setSearchValue("");
        tireHistoryTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

        setOriginalTireHistoryTableData(tireHistoryTableInfo);
        setTableBeforeFilter(tireHistoryTableInfo);
        setFilterOptions(filterOptionsInfo);
        setDisplayColOptions(tireHistoryTableInfo.headCells);
        setDisplayColumns(updatedHeadCells);
        setShowColumns(tireHistoryTableInfo.selectedColShow);
        setPrintData({
          reportType: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TiresInService,
          reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
          rowsData: tireHistoryTableInfo.rowsData,
          headCells: tireHistoryTableInfo.headCells,
          avgValues: tireHistoryTableInfo.avgValues,
          selectedColShow: tireHistoryTableInfo.selectedColShow,
          filterApplied: filterApplied
        });
        let exportData = JSON.parse(JSON.stringify(tireHistoryTableInfo));
        exportData.rowsData.forEach((tireRow) => {

          let [vehId, wheelPosition] = tireRow.VehId.replace(")", "").split("(");
          tireRow.VehId = vehId;
          tireRow.WheelPosition = wheelPosition;
        });
        let index = exportData.headCells.findIndex((element) => element.key === "VehId");
        exportData.headCells[index].label = "Vehicle Id";
        exportData.headCells.splice(index + 1, 0, {
          key: "WheelPosition",
          keyIndex: exportData.headCells.length,
          label: "Wheel Position",
          numeric: false,
          value: "WheelPosition"
        });
        exportData.selectedColShow.WheelPosition = true;


        setPrintDataNotPDF({
          reportType: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TiresInService,
          reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
          rowsData: exportData.rowsData,
          headCells: exportData.headCells,
          selectedColShow: exportData.selectedColShow
        });

        if (filterAppliedValue) {
          setFilterApplied(true);
          let originalTableDataStore = tireHistoryTableInfo.rowsData;
          let filteredData = tireHistoryDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
          setSearchValue("");

          let data = JSON.parse(JSON.stringify(filteredData));

          data.sort((a, b) => {
            if (a.BrandNumber > b.BrandNumber) {
              return 1;
            } else if (a.BrandNumber < b.BrandNumber) {
              return -1;
            } else {
              return 0;
            }
          });
          data.sort((a, b) => {
            if (a.SerialNo > b.SerialNo) {
              return 1;
            } else if (a.SerialNo < b.SerialNo) {
              return -1;
            } else {
              return 0;
            }
          });

          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (index === 0) element["newLine"] = false;
            else if (index < data.length - 2) {
              if (element.SerialNo !== data[index + 1].SerialNo) element["newLine"] = true;
              else element["newLine"] = false;
            } else {
              if (element.SerialNo !== data[index - 1].SerialNo) element["newLine"] = true;
              else element["newLine"] = false;
            }
          }

          let body = [];
          data.forEach((row, index) => {
            const tableRow = {};

            Object.keys(row).forEach((key, id) => {
              if (key !== "Key") {

                let content = row[key];
                if (index !== 0) {
                  content =
                    !data[index - 1].newLine && (key === "BrandNumber" || key === "TypeName" || key === "SerialNo" || key === "ManufacturerName" || key === "SizeName")
                      ? ""
                      : row[key];
                }
                content = content === "()" ? "" : content;
                tableRow[key] = content;
              }
            });
            tableRow["newLine"] = row.newLine;

            body.push(tableRow);
          });
          setTireHistoryTableData({
            ...tireHistoryTableInfo,
            rowsData: body,
            avgValues: tireHistoryDataSetter.avgValues
          });
          setOriginalTireHistoryTableData({
            ...tireHistoryTableInfo,
            rowsData: filteredData,
            avgValues: tireHistoryDataSetter.avgValues
          });
          setPrintData({
            ...printData,
            rowsData: filteredData,
            avgValues: tireHistoryDataSetter.avgValues,
            filterFieldsApplied: filterFieldsAppliedValue,
            filterApplied: true
          });
          setPrintDataNotPDF({
            ...printDataNotPDF,
            rowsData: filteredData,
            filterFieldsApplied: JSON.parse(JSON.stringify(filterFieldsAppliedValue)),
            filterApplied: true
          });
          ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
          filteredData.length === 0 ? setNoData(true) : setNoData(false);
        } else {
          let data = JSON.parse(JSON.stringify(tireHistoryTableInfo));

          data.rowsData.sort((a, b) => {
             if (a.BrandNumber > b.BrandNumber) {
                return 1;
             } else if (a.BrandNumber < b.BrandNumber) {
                return -1;
             } else {
              return 0;
             }
          });
          data.rowsData.sort((a, b) => {
            if (a.SerialNo > b.SerialNo) {
              return 1;
           } else if (a.SerialNo < b.SerialNo) {
              return -1;
           } else {
            return 0;
           }
          });

          for (let index = 0; index < data.rowsData.length; index++) {
            const element = data.rowsData[index];
            if (index === 0) element["newLine"] = false;
            else if (index < data.rowsData.length - 2) {
              if (element.SerialNo !== data.rowsData[index + 1].SerialNo) element["newLine"] = true;
              else element["newLine"] = false;
            } else {
              if (element.SerialNo !== data.rowsData[index - 1].SerialNo) element["newLine"] = true;
              else element["newLine"] = false;
            }
          }

          let body = [];
          data.rowsData.forEach((row, index) => {
            const tableRow = {};

            Object.keys(row).forEach((key, id) => {
              if (key !== "Key" && data.selectedColShow[key] === true) {
                let content = row[key];
                if (index !== 0) {
                  content =
                    !data.rowsData[index - 1].newLine && (key === "BrandNumber" || key === "TypeName" || key === "SerialNo" || key === "ManufacturerName" || key === "SizeName")
                      ? ""
                      : row[key];
                }
                content = content === "()" ? "" : content;
                tableRow[key] = content;
              }
            });
            tableRow["newLine"] = row.newLine;

            body.push(tableRow);
          });
          data.rowsData = body;
          setTireHistoryTableData(data);
        }
        setShowSpinner(false);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");

      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);

        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        let data = JSON.parse(JSON.stringify(printData));

        for (let index = 0; index < data.rowsData.length; index++) {
          const element = data.rowsData[index];
          if (index === 0) element["newLine"] = false;
          else if (index < data.rowsData.length - 2) {
            if (element.RealSerialNo !== data.rowsData[index + 1].RealSerialNo) element["newLine"] = true;
            else element["newLine"] = false;
          } else {
            if (element.RealSerialNo !== data.rowsData[index - 1].RealSerialNo) element["newLine"] = true;
            else element["newLine"] = false;
          }
        }
        setPrintData(data);

        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };
  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };
  const onClickFilter = () => {
    setShowFilter(true);
  };

  const actionChanged = (action) => {
    setToggleHM(action);
    let avoid = [];
    if (action === "Miles") {
      avoid = ["TotalHours", "BrandNumber"];
    } else {
      avoid = ["TotalMiles", "BrandNumber"];
    }
    let tireHistoryTableInfo = originalTireHistoryTableData;
    // selectedColShow
    tireHistoryTableInfo.headCells.forEach((headCell) => {
      if (avoid.some((item) => item === headCell.value)) {
        tireHistoryTableInfo.selectedColShow[headCell.value] = false;
      } else {
        tireHistoryTableInfo.selectedColShow[headCell.value] = true;
      }
    });

    setSearchValue("");
    tireHistoryTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

    setOriginalTireHistoryTableData(tireHistoryTableInfo);
    setTableBeforeFilter(tireHistoryTableInfo);

    setShowColumns(tireHistoryTableInfo.selectedColShow);
    let exportData = JSON.parse(JSON.stringify(tireHistoryTableInfo));
    exportData.rowsData.forEach((tireRow) => {
      let [vehId, wheelPosition] = tireRow.VehId.replace(")", "").split("(");
      tireRow.VehId = vehId;
      tireRow.WheelPosition = wheelPosition;
    });
    let index = exportData.headCells.findIndex((element) => element.key === "VehId");
    exportData.headCells[index].label = "Vehicle Id";
    exportData.headCells.splice(index + 1, 0, {
      key: "WheelPosition",
      keyIndex: exportData.headCells.length,
      label: "Wheel Position",
      numeric: false,
      value: "WheelPosition"
    });
    exportData.selectedColShow.WheelPosition = true;
    //

    let data = JSON.parse(JSON.stringify(tireHistoryTableInfo));

    data.rowsData.sort((a, b) => {
      if (a.BrandNumber > b.BrandNumber) {
        return 1;
      } else if (a.BrandNumber < b.BrandNumber) {
         return -1;
      } else {
       return 0;
      }
    });
    data.rowsData.sort((a, b) => {
      if (a.SerialNo > b.SerialNo) {
        return 1;
      } else if (a.SerialNo < b.SerialNo) {
         return -1;
      } else {
       return 0;
      }
    });
    for (let index = 0; index < data.rowsData.length; index++) {
      const element = data.rowsData[index];
      if (index === 0) element["newLine"] = false;
      else if (index < data.rowsData.length - 2) {
        if (element.SerialNo !== data.rowsData[index + 1].SerialNo) element["newLine"] = true;
        else element["newLine"] = false;
      } else {
        if (element.SerialNo !== data.rowsData[index - 1].SerialNo) element["newLine"] = true;
        else element["newLine"] = false;
      }
    }

    let body = [];
    data.rowsData.forEach((row, index) => {
      const tableRow = {};
      Object.keys(row).forEach((key, id) => {
        if (key !== "Key" && data.selectedColShow[key] === true) {
          let content = row[key];
          if (index !== 0) {
            content =
              !data.rowsData[index - 1].newLine && (key === "BrandNumber" || key === "TypeName" || key === "SerialNo" || key === "ManufacturerName" || key === "SizeName")
                ? ""
                : row[key];
          }
          content = content === "()" ? "" : content;
          tableRow[key] = content;
        }
      });
      tableRow["newLine"] = row.newLine;

      body.push(tableRow);
    });
    data.rowsData = body;
    setTireHistoryTableData(data);

    setPrintData({
      reportType: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TiresInService,
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
      rowsData: tireHistoryTableInfo.rowsData,
      headCells: tireHistoryTableInfo.headCells,
      avgValues: tireHistoryTableInfo.avgValues,
      selectedColShow: tireHistoryTableInfo.selectedColShow,
      filterApplied: filterApplied
    });
    setPrintDataNotPDF({
      reportType: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TiresInService,
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
      rowsData: exportData.rowsData,
      headCells: exportData.headCells,
      selectedColShow: exportData.selectedColShow
    });
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    let originalTableDataStore, filteredData;
    originalTableDataStore = originalTireHistoryTableData?.rowsData;

    filteredData = tireHistoryDataSetter.search(originalTableDataStore, searchText);
    let data = JSON.parse(JSON.stringify(filteredData));

    data.sort((a, b) => {
      if (a.BrandNumber > b.BrandNumber) {
        return 1;
      } else if (a.BrandNumber < b.BrandNumber) {
         return -1;
      } else {
       return 0;
      }
    });
    data.sort((a, b) => {
      if (a.SerialNo > b.SerialNo) {
        return 1;
      } else if (a.SerialNo < b.SerialNo) {
         return -1;
      } else {
       return 0;
      }  
    });

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (index === 0) {
        element["newLine"] = false;
      } else if (index < data.length - 2) {
        if (element.SerialNo !== data[index + 1].SerialNo) element["newLine"] = true;
        else element["newLine"] = false;
      } else {
        if (element.SerialNo !== data[index - 1].SerialNo) element["newLine"] = true;
        else element["newLine"] = false;
      }
    }

    let body = [];
    data.forEach((row, index) => {
      const tableRow = {};

      Object.keys(row).forEach((key, id) => {
        if (key !== "Key") {
          let content = row[key];
          if (index !== 0) {
            content =
              !data[index - 1].newLine && (key === "BrandNumber" || key === "TypeName" || key === "SerialNo" || key === "ManufacturerName" || key === "SizeName") ? "" : row[key];
          }
          content = content === "()" ? "" : content;
          tableRow[key] = content;
        }
      });
      tableRow["newLine"] = row.newLine;

      body.push(tableRow);
    });

    let updatedTableData = {
      rowsData: body,
      headCells: originalTireHistoryTableData?.headCells,
      avgValues: originalTireHistoryTableData?.avgValues,
      selectedColShow: originalTireHistoryTableData?.selectedColShow
    };
    setTireHistoryTableData(updatedTableData);
    setPrintData({
      ...printData,
      reportType: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TiresInService,
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
      rowsData: filteredData,
      headCells: originalTireHistoryTableData?.headCells,
      selectedColShow: originalTireHistoryTableData?.selectedColShow,
      filterApplied: filterApplied
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      reportType: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TiresInService,
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
      rowsData: JSON.parse(JSON.stringify(filteredData)),
      headCells: JSON.parse(JSON.stringify(originalTireHistoryTableData?.headCells)),
      selectedColShow: JSON.parse(JSON.stringify(originalTireHistoryTableData?.selectedColShow)),
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    //should prevent submit, and continue below?
    return false;
  };

  const changeTireSerial = (selected) => {
    let selectedColumns = selected;
    setTireSerialColumns(selectedColumns);
  };
  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalTireHistoryTableData?.headCells;
    let showColumnsUpdated = tireHistoryDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
      setPrintDataNotPDF({
        ...printDataNotPDF,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
      setPrintDataNotPDF({
        ...printDataNotPDF,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };
  const onBlurChangeTireSerial = () => {

    if (document.querySelector('#tire-serial-list div[aria-expanded="false"]') && onFirstLoad !== 1) {
      setShowLoading(true);
      let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
      let accountIds = accountInfoObject.accountIds;
      let locationIds = accountInfoObject.locationIds;
      let updatedHeadCells;
      let requestObject = {
        accountIds: accountIds,
        dateRangeType: dateRangeType,
        fromDate: dateRangeFilter.fromDate,
        locationIds: locationIds,
        reportName: "Tire_History",
        reportOption: "",
        toDate: dateRangeFilter.toDate,
        tireIds: tireHistoryDataSetter.getTireIds(tireSerial),
        usedTires: excludeUsedTires.toString()
      };
      tiresService.getTiresReport(requestObject).then((resData) => {
        let tireHistoryTableInfo = tireHistoryDataSetter.setData(resData);
        updatedHeadCells = tireHistoryDataSetter.updatedHeadCells();
        let filterOptionsInfo = tireHistoryDataSetter.setFilterOptionsData(filterOptions, tireHistoryTableInfo.rowsData);
        setSearchValue("");
        tireHistoryTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

        setOriginalTireHistoryTableData(tireHistoryTableInfo);
        setTableBeforeFilter(tireHistoryTableInfo);
        setFilterOptions(filterOptionsInfo);
        setDisplayColOptions(tireHistoryTableInfo.headCells);
        setDisplayColumns(updatedHeadCells);
        setShowColumns(tireHistoryTableInfo.selectedColShow);
        setPrintData({
          reportType: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TiresInService,
          reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
          rowsData: tireHistoryTableInfo.rowsData,
          headCells: tireHistoryTableInfo.headCells,
          avgValues: tireHistoryTableInfo.avgValues,
          selectedColShow: tireHistoryTableInfo.selectedColShow,
          filterApplied: filterApplied
        });
        let exportData = JSON.parse(JSON.stringify(tireHistoryTableInfo));
        exportData.rowsData.forEach((tireRow) => {
          let [vehId, wheelPosition] = tireRow.VehId.replace(")", "").split("(");
          tireRow.VehId = vehId;
          tireRow.WheelPosition = wheelPosition;
        });
        let index = exportData.headCells.findIndex((element) => element.key === "VehId");
        exportData.headCells[index].label = "Vehicle Id";
        exportData.headCells.splice(index + 1, 0, {
          key: "WheelPosition",
          keyIndex: exportData.headCells.length,
          label: "Wheel Position",
          numeric: false,
          value: "WheelPosition"
        });
        exportData.selectedColShow.WheelPosition = true;

        setPrintDataNotPDF({
          reportType: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TiresInService,
          reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
          rowsData: exportData.rowsData,
          headCells: exportData.headCells,
          selectedColShow: exportData.selectedColShow
        });
        if (filterApplied) {
          setFilterApplied(true);
          let originalTableDataStore = tireHistoryTableInfo.rowsData;
          let filteredData = tireHistoryDataSetter.filterTable(filterFieldsApplied, originalTableDataStore);
          setSearchValue("");

          let resData = JSON.parse(JSON.stringify(filteredData));

          resData.sort((a, b) => {
            if (a.BrandNumber > b.BrandNumber) {
              return 1;
            } else if (a.BrandNumber < b.BrandNumber) {
               return -1;
            } else {
             return 0;
            }  
          });
          resData.sort((a, b) => {
            if (a.SerialNo > b.SerialNo) {
              return 1;
            } else if (a.SerialNo < b.SerialNo) {
               return -1;
            } else {
             return 0;
            }  
          });

          for (let index = 0; index < resData.length; index++) {
            const element = resData[index];
            if (index === 0) element["newLine"] = false;
            else if (index < resData.length - 2) {
              if (element.SerialNo !== resData[index + 1].SerialNo) element["newLine"] = true;
              else element["newLine"] = false;
            } else {
              if (element.SerialNo !== resData[index - 1].SerialNo) element["newLine"] = true;
              else element["newLine"] = false;
            }
          }

          let body = [];
          resData.forEach((row, index) => {
            const tableRow = {};

            Object.keys(row).forEach((key, id) => {
              
              if (key !== "Key") {
                
                let content = row[key];
                if (index !== 0) {
                  content =
                    !resData[index - 1].newLine && (key === "BrandNumber" || key === "TypeName" || key === "SerialNo" || key === "ManufacturerName" || key === "SizeName")
                      ? ""
                      : row[key];
                }
                content = content === "()" ? "" : content;
                tableRow[key] = content;
              }
            });
            tableRow["newLine"] = row.newLine;
            body.push(tableRow);
          });
          setTireHistoryTableData({
            ...tireHistoryTableInfo,
            rowsData: body,
            avgValues: tireHistoryDataSetter.avgValues
          });
          setOriginalTireHistoryTableData({
            ...tireHistoryTableInfo,
            rowsData: filteredData,
            avgValues: tireHistoryDataSetter.avgValues
          });
          setPrintData({
            ...printData,
            rowsData: filteredData,
            avgValues: tireHistoryDataSetter.avgValues,
            filterFieldsApplied: filterFieldsApplied,
            filterApplied: true
          });
          setPrintDataNotPDF({
            ...printDataNotPDF,
            rowsData: filteredData,
            filterFieldsApplied: JSON.parse(JSON.stringify(filterFieldsApplied)),
            filterApplied: true
          });
          setOnlyFilterApplied(true);
          filteredData.length === 0 ? setNoData(true) : setNoData(false);
        } else {
          let data = JSON.parse(JSON.stringify(tireHistoryTableInfo));

          data.rowsData.sort((a, b) => {
            if (a.BrandNumber > b.BrandNumber) {
              return 1;
            } else if (a.BrandNumber < b.BrandNumber) {
               return -1;
            } else {
             return 0;
            }  
          });
          data.rowsData.sort((a, b) => {
            if (a.SerialNo > b.SerialNo) {
              return 1;
            } else if (a.SerialNo < b.SerialNo) {
               return -1;
            } else {
             return 0;
            }  
          });
          for (let index = 0; index < data.rowsData.length; index++) {
            const element = data.rowsData[index];
            if (index === 0) element["newLine"] = false;
            else if (index < data.rowsData.length - 2) {
              if (element.SerialNo !== data.rowsData[index + 1].SerialNo) element["newLine"] = true;
              else element["newLine"] = false;
            } else {
              if (element.SerialNo !== data.rowsData[index - 1].SerialNo) element["newLine"] = true;
              else element["newLine"] = false;
            }
          }
          let body = [];
          data.rowsData.forEach((row, index) => {
            const tableRow = {};
            Object.keys(row).forEach((key, id) => {
              if (key !== "Key" && data.selectedColShow[key] === true) {

                let content = row[key];
                if (index !== 0) {
                  content =
                    !data.rowsData[index - 1].newLine && (key === "BrandNumber" || key === "TypeName" || key === "SerialNo" || key === "ManufacturerName" || key === "SizeName")
                      ? ""
                      : row[key];
                }
                content = content === "()" ? "" : content;
                tableRow[key] = content;
              }
            });
            tableRow["newLine"] = row.newLine;

            body.push(tableRow);
          });
          data.rowsData = body;
          setTireHistoryTableData(data);
        }
        setShowSpinner(false);
        setShowLoading(false);
        setToggleHM("Hours");
      });
    }
    setFirstLoad(2);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });
    setFilterApplied(false);
    setTireHistoryTableData(tableBeforeFilter);
    setOriginalTireHistoryTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setSearchValue("");
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
    setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };
   setCurrentFilter(toSetCurrentFilter);
   if (appliedFilter === filterOptions.categoryData) {
     setFilterApplied(false);
   } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    let originalTableDataStore = originalTireHistoryTableData?.rowsData;
    let filteredData = tireHistoryDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setSearchValue("");
    setTireHistoryTableData({
      ...tireHistoryTableData,
      rowsData: filteredData,
      avgValues: tireHistoryDataSetter.avgValues
    });
    setPrintData({
      ...printData,
      rowsData: filteredData
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      rowsData: filteredData
    });
    setOnlyFilterApplied(true);
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    TireHistoryResponseData(excludeUsedTires, true, appliedFilter);
  };


  const handleExcludeUsedTires = (event) => {
    setSearchValue("");
    setToggleHM("Hours");
    setExcludeUsedTires(event.target.checked);
    TireHistoryResponseData(event.target.checked, filterApplied, filterFieldsApplied);
  };

  return (
    <div className="container">
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="tire-history-container">
          {showLoading && <Spinner />}
          <Card className="tire-history-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="tires-select-container" id="tire-serial-list">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.TiresSelected}</span>
                    </InputLabel>
                    {tireSerialColOptions && tireSerial && (
                      <MultiSelect
                        options={tireSerialColOptions}
                        value={tireSerial}
                        onChange={changeTireSerial}
                        onMenuToggle={onBlurChangeTireSerial}
                        overrideStrings={{
                          selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                          allItemsAreSelected: "All Tires",
                          selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                          search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                        }}
                      />
                    )}
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div className="exclude-used-tires">
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      value={toggleHM}
                      onChange={(e) => actionChanged(e.target.value)}
                    >
                      <option value={"Hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                      <option value={"Miles"}>{toggleMile}</option>
                    </Select>
                  </div>
                </Grid>
              </Grid>
              <div className="tire-history-table">
                <TireHistoryTable tableData={tireHistoryTableData} showColumns={showColumns} filterApplied={filterApplied} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />

      <TireHistoryFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default TireHistory;
