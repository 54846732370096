import React, { useState, useEffect } from "react";
import Header from "../common-components/header/components/header/header";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";

import MyProfile from "../scenes/my-profile/components/profile-details/profile-details";
import ManageVehicleList from "../scenes/manage-vehicle/components/list-vehicles/main-container/ManageVehicleList";
import ManageParentAccountList from "../scenes/manage-parent-account/components/list-account/main-container/ManageParentAccountList";
import ManageLocationList from "../scenes/manage-location/components/list-location/main-container/ManageLocationList";
import ReportsNav from "../common-components/reports-nav/components/reports-navbar/reports-nav";
import HomeDashboard from "../scenes/home/components/home-dashboard/home-dashboard";
import { Logout } from "../auth/logout";
import ParentAccForm from "../scenes/edit-parent-account/components/edit-parent-details";
import EditLocation from "../scenes/edit-location/components/edit-location";
import CreateTire from "../scenes/create-tire/components/create-tire";
import Help from "../scenes/help/components/help";
import UserPreferences from "../scenes/user-preference/components/user-preferences";
import ManageTireList from "../scenes/manage-tire/components/list-tires/main-container/ManageTireList";
import UpdateHoursMiles from "../scenes/update-hours-miles/components/update-hours-miles";
import SearchInstallTire from "../scenes/search-install-tire/components/main-container/search-install-tire";
import CreateVehicle from "../scenes/create-vehicle/create-vehicle";
import CreateLocation from "../scenes/create-location/components/create-location";
import QuickInspect from "../scenes/quick-inspect-vehicle/components/list-vehicles/main-container/QuickInspect";
import InspectTire from "../scenes/inspect-tire/inspect-tire";
import AdminList from "../scenes/admin/components/request-list/main-container/AdminRequestList";
import MultiReportList from "../scenes/multi-admin-list/multi-admin-list";
import RemoveTire from "../scenes/remove-tire/components/remove-tire";
import RemoveAllTire from "../scenes/remove-all-tire/components/remove-all-tire";
import ViewVehicle from "../scenes/view-vehicle/components/list-vehicles/main-container/ViewVehicle";
import ViewParentAccountList from "../scenes/view-parent-account/components/list-account/main-container/ViewParentAccountList";
import ViewLocation from "../scenes/view-location/ViewLocation";
import CreateRim from "../scenes/create-rim/create-rim";
import ManageRimList from "../scenes/manage-rim/components/list-rim/main-container/ManageRimList";
import ViewRim from "../scenes/view-rim/components/list-rim/main-container/ViewRim";

import ViewTire from "../scenes/view-tire/components/main-container/ViewTire";
import RepairToInventory from "../scenes/change-tire-disposition/components/repair-retread-to-inventory/repair-to-inventory";
import MeterChange from "../scenes/meter-change/components/meter-change";
import LogIn from "../scenes/login/components/LogIn";

import HeaderViewOnly from "../common-components/header/components/header/headerViewOnly";
import QuickInspectViewOnly from "../scenes/quick-inspect-vehicle/components/list-vehicles/main-container/QuickInspectViewOnly";
import ManageTireListViewOnly from "../scenes/manage-tire/components/list-tires/main-container/ManageTireListViewOnly";
import ManageVehicleListViewOnly from "../scenes/manage-vehicle/components/list-vehicles/main-container/ManageVehicleListViewOnly";
import ManageLocationListViewOnly from "../scenes/manage-location/components/list-location/main-container/ManageLocationListViewOnly";
import ManageRimListViewOnly from "../scenes/manage-rim/components/list-rim/main-container/ManageRimListViewOnly";
import ManageParentAccountListViewOnly from "../scenes/manage-parent-account/components/list-account/main-container/ManageParentAccountListViewOnly";
import ViewVehicleViewOnly from "../scenes/view-vehicle/components/list-vehicles/main-container/ViewVehicleViewOnly";
import ViewTireViewOnly from "../scenes/view-tire/components/main-container/ViewTireViewOnly";
import ViewLocationViewOnly from "../scenes/view-location/ViewLocationViewOnly";
import ViewRimViewOnly from "../scenes/view-rim/components/list-rim/main-container/ViewRimViewOnly";
import ViewParentAccountListViewOnly from "../scenes/view-parent-account/components/list-account/main-container/ViewParentAccountListViewOnly";
const HeaderRouting = () => {
  const [path, setPath] = useState("home");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    //Detect the location/url change
    const pathName = location.pathname;
    if (pathName === "/") {
      history.push("/home"); // initial load 
      // history.push("/login"); 
    }
    if (path.toString().trim().toLowerCase() !== pathName.split("/")[1].toString().trim().toLowerCase()) {
      setPath(pathName.split("/")[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  // path selected in this function will help in routing in between old and new application
  const onClickHeader = (selectedPath) => {
    setPath(selectedPath);

    switch (selectedPath) {
      case "logout":
        localStorage.clear();
        sessionStorage.clear();
        history.push("/logout");
        window.location.reload();
        break;

      case "home":
        history.push("/home");
        break;

      case "createparentacc":
        history.push("/createparentaccount");
        break;

      case "admin":
        history.push("/admin");
        break;

      case "multireportsdata":
        history.push("/multireportsdata");
        break;

      case "help":
        history.push("/help");
        break;

      case "editparentaccount":
        history.push("/editparentaccount");
        break;

      case "viewparentaccount":
        history.push("/view-parent-account");
        break;

      case "viewlocation":
        history.push("/view-location");
        break;

      case "editlocation":
        history.push("/editlocation");
        break;

      case "userpreference":
        history.push("/userpreference");
        break;

      case "myprofile":
        history.push("/myprofile");
        break;

      case "update-hours-miles":
        history.push("/update-hours-miles");
        break;

      case "meter-change":
        history.push("/meter-change");
        break;

      case "search-install-tires":
        history.push("/search-install-tires");
        break;

      case "createtire":
        history.push({
          pathname: "/createtire",
          state: {
            fromPath: "/manage-tire"
          }
        });
        break;

      case "manageparentaccount":
        history.push("/manage-parent-account");
        break;

      case "managetire":
        history.push({ pathname: "/manage-tire" });
        break;

      case "managelocation":
        history.push("/manage-location");
        break;

      case "managevehicle":
        history.push("/manage-vehicle");
        break;

      case "createvehicle":
        history.push("/create-vehicle");
        break;

      case "createlocation":
        history.push("/create-location");
        break;

      case "quickinspect":
        history.push("/quick-inspect");
        break;

      case "viewvehicle":
        history.push("/view-vehicle");
        break;

      case "createrim":
        history.push("/createrim");
        break;

      case "managerim":
        history.push("/manage-rim");
        break;

      case "returntoinventory":
        history.push("/return-to-inventory");
        break;

      case "viewrim":
        history.push("/view-rim");
        break;

      case "reports":
        history.push("/reportsDashboard");
        break;

      default:
        //history.push("/home")
        break;
    }
  };

  return (
    <div>
      {path === "login" ? null: 
      <>
        {sessionStorage.getItem("isOnlyView") === "false" ?
          <Header path={path} onClickHeader={onClickHeader} /> : null}
        {sessionStorage.getItem("isOnlyView") === "true" ?
        <HeaderViewOnly path={path} onClickHeader={onClickHeader} /> : null}
      </>
      }
      
      <Switch>
        <Route path="/myprofile" component={MyProfile} exact />
        {sessionStorage.getItem("isOnlyView") === "true" ?
          <Route path="/manage-vehicle" component={ManageVehicleListViewOnly} exact />
          :
          <Route path="/manage-vehicle" component={ManageVehicleList} exact />}
        <Route path="/editparentaccount" component={ParentAccForm} exact />
        <Route path="/editlocation" component={EditLocation} exact />
        <Route path="/home" component={HomeDashboard} exact />
        {
          sessionStorage.getItem("isOnlyView") === "true" ?
            <Route path="/manage-tire" component={ManageTireListViewOnly} exact />
            :
            <Route path="/manage-tire" component={ManageTireList} exact />}
        <Route path="/createtire" component={CreateTire} exact />
        <Route path="/create-vehicle/:VehicleId?" component={CreateVehicle} exact />
        <Route path="/create-location" component={CreateLocation} exact />
        {
          sessionStorage.getItem("isOnlyView") === "true" ?
            <Route path="/quick-inspect/:VehicleId" component={QuickInspectViewOnly} exact />
            :
            <Route path="/quick-inspect/:VehicleId" component={QuickInspect} exact />
        }

        {
          sessionStorage.getItem("isOnlyView") === "true" ?
            <Route path="/view-vehicle/:VehicleId" component={ViewVehicleViewOnly} exact />
            :
            <Route path="/view-vehicle/:VehicleId" component={ViewVehicle} exact />
        }

        <Route path="/createparentaccount" component={ParentAccForm} exact />
        <Route path="/create-location" component={CreateLocation} exact />
        <Route path="/help" component={Help} exact />
        <Route path="/userpreference" component={UserPreferences} exact />
        <Route path="/update-hours-miles" component={UpdateHoursMiles} exact />
        <Route path="/meter-change" component={MeterChange} exact />
        <Route path="/search-install-tires" component={SearchInstallTire} exact />
        {
          sessionStorage.getItem("isOnlyView") === "true" ?
            <Route path="/manage-parent-account" component={ManageParentAccountListViewOnly} exact />
            :
            <Route path="/manage-parent-account" component={ManageParentAccountList} exact />}
        {
          sessionStorage.getItem("isOnlyView") === "true" ?
            <Route path="/view-parent-account" component={ViewParentAccountListViewOnly} exact />
            :
            <Route path="/view-parent-account" component={ViewParentAccountList} exact />}
        {
          sessionStorage.getItem("isOnlyView") === "true" ?
            <Route path="/view-location" component={ViewLocationViewOnly} exact />
            :
            <Route path="/view-location" component={ViewLocation} exact />}
        {
          sessionStorage.getItem("isOnlyView") === "true" ?
            <Route path="/manage-location" component={ManageLocationListViewOnly} exact />
            :
            <Route path="/manage-location" component={ManageLocationList} exact />}
        <Route path="/return-to-inventory" component={RepairToInventory} exact />
        <Route path="/inspect-tire" component={InspectTire} exact />
        <Route path="/remove-tire" component={RemoveTire} exact />
        <Route path="/remove-all-tire" component={RemoveAllTire} exact />
        {sessionStorage.getItem("isOnlyView") === "true" ?
          <Route path="/view-tire" component={ViewTireViewOnly} exact /> :
          <Route path="/view-tire" component={ViewTire} exact />}
        <Route path="/admin" component={AdminList} />
        <Route path="/multireportsdata" component={MultiReportList} exact />
        <Route path="/createrim/:RimId?" component={CreateRim} />
        {sessionStorage.getItem("isOnlyView") === "true" ? (
          <Route path="/manage-rim" component={ManageRimListViewOnly} />
        ) : (
          <Route path="/manage-rim" component={ManageRimList} />
        )}
        {sessionStorage.getItem("isOnlyView") === "true" ? (
          <Route path="/view-rim/:RimId" component={ViewRimViewOnly} exact />
        ) : (
          <Route path="/view-rim/:RimId" component={ViewRim} exact />
        )}
        <Route path="/login" component={LogIn} exact />
        <Route path="/logout" component={Logout} exact />
        <Route path="/" component={ReportsNav} />
        <Route path="/reportsDashboard" component={ReportsNav} />
      </Switch>
    </div>
  );
};

export default HeaderRouting;
