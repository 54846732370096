import Globalization from "../../../globalization/index";

class InServiceTireCostSummaryDataSetter {
  constructor() {
    this.data = {};
    this.inServiceTireCostData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }

  tableHeaderOrderSetter(headCells) {
    let tableHeaders = headCells;
    // maintains the column order to be displayed (write them in the order you want to display table header)
    let headerOrder = [
      { columnNo: 1, value: "ManufacturerName" },
      { columnNo: 2, value: "SizeName" },
      { columnNo: 3, value: "TireCount" },
      { columnNo: 4, value: "TreadDepth" },
      { columnNo: 5, value: "AverageWorn" },
      { columnNo: 6, value: "NewCost" },
      { columnNo: 7, value: "NoOfRetread" },
      { columnNo: 8, value: "RetreadCost" },
      { columnNo: 9, value: "NoOfRepair" },
      { columnNo: 10, value: "RepairCost" },
      { columnNo: 11, value: "TotalCost" },
      { columnNo: 12, value: "AverageCost" },
      { columnNo: 13, value: "AverageHours" },
      { columnNo: 14, value: "AverageMiles" },
      { columnNo: 15, value: "AverageProjHours" },
      { columnNo: 16, value: "AverageProjMiles" },
      { columnNo: 17, value: "CostPerHourProj" },
      { columnNo: 18, value: "CostPerMileProj" },
      { columnNo: 19, value: "CasingValue" },
      { columnNo: 20, value: "CostAdjustment" },
      { columnNo: 21, value: "WarrantyAdjustment" },
    ];

    let resultHeaderCell = [];
    headerOrder.forEach((headerOrder) => {
      let found = false;
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === headerOrder.value) {
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });
    return resultHeaderCell;
  }

  // shows the headCells to be displayed first time on first time load
  // updatedHeadCells(){
  //     let updatedHeadCells = []
  //     this.headCells.forEach(headCell => {
  //         updatedHeadCells.push(headCell)
  //     })
  //     return updatedHeadCells
  // }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (
        headCell.value !== "AverageMiles" &&
        headCell.value !== "AverageProjMiles" &&
        headCell.value !== "CostPerMileProj"
      )
        selectedColShow[headCell.value] = true;
      else selectedColShow[headCell.value] = false;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          if (
            headCell.value !== "AverageMiles" &&
            headCell.value !== "AverageProjMiles" &&
            headCell.value !== "CostPerMileProj"
          )
            selectedColShow[headCell.value] = true;
          else selectedColShow[headCell.value] = false;
        }
      });
    });
    //
    return selectedColShow;
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  avgValues = (inServiceTireCostSummaryData) => {
    let TireCountSum = 0,
      AvgWornSum = 0,
      NewCostSum = 0,
      NoOfRetreadSum = 0,
      RetreadCostSum = 0,
      NoOfRepairSum = 0,
      RepairCostSum = 0,
      TotalCostSum = 0,
      AverageCostSum = 0,
      AverageHoursSum = 0,
      AverageProjHoursSum = 0,
      CostPerHourProjSum = 0,
      AverageMilesSum = 0,
      AverageProjMilesSum = 0,
      CostPerMileProjSum = 0,
      CasingValueSum = 0,
      CostAdjustmentSum = 0,
      WarrantyAdjustmentSum = 0;

    inServiceTireCostSummaryData.forEach((item) => {
      TireCountSum += item.TireCount;
      AvgWornSum += item.AverageWorn;
      NewCostSum += item.NewCost;
      NoOfRetreadSum += item.NoOfRetread;
      RetreadCostSum += item.RetreadCost;
      NoOfRepairSum += item.NoOfRepair;
      RepairCostSum += item.RepairCost;
      TotalCostSum += item.TotalCost;
      AverageCostSum += item.AverageCost;
      AverageHoursSum += item.AverageHours;
      AverageProjHoursSum += item.AverageProjHours;
      CostPerHourProjSum += item.CostPerHourProj;
      AverageMilesSum += item.AverageMiles;
      AverageProjMilesSum += item.AverageProjMiles;
      CostPerMileProjSum += item.CostPerMileProj;
      CasingValueSum += item.CasingValue;
      CostAdjustmentSum += item.CostAdjustment;
      WarrantyAdjustmentSum += item.WarrantyAdjustment;
    });

    let AvgWornTotalAvg = Math.round(
      AvgWornSum / inServiceTireCostSummaryData.length
    );
    let AverageCostAvg = Math.round(
      AverageCostSum / inServiceTireCostSummaryData.length
    );
    let AverageHoursAvg = Math.round(
      AverageHoursSum / inServiceTireCostSummaryData.length
    );
    let AverageProjHoursAvg = Math.round(
      AverageProjHoursSum / inServiceTireCostSummaryData.length
    );
    let AverageMilesAvg = Math.round(
      AverageMilesSum / inServiceTireCostSummaryData.length
    );
    let AverageProjMilesAvg = Math.round(
      AverageProjMilesSum / inServiceTireCostSummaryData.length
    );

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TireCount: TireCountSum,
      TreadDepth: "",
      AverageWorn: AvgWornTotalAvg,
      NewCost: parseFloat(NewCostSum.toFixed(2)),
      NoOfRetread: NoOfRetreadSum,
      RetreadCost: parseFloat(RetreadCostSum.toFixed(2)),
      NoOfRepair: NoOfRepairSum,
      RepairCost: parseFloat(RepairCostSum.toFixed(2)),
      TotalCost: parseFloat(TotalCostSum.toFixed(2)),
      AverageCost: AverageCostAvg,
      AverageMiles: AverageMilesAvg,
      AverageProjMiles: AverageProjMilesAvg,
      CostPerMileProj: parseFloat(CostPerMileProjSum.toFixed(2)),
      AverageHours: AverageHoursAvg,
      AverageProjHours: AverageProjHoursAvg,
      CostPerHourProj: parseFloat(CostPerHourProjSum.toFixed(2)),
      CasingValue: CasingValueSum,
      CostAdjustment: parseFloat(CostAdjustmentSum.toFixed(2)),
      WarrantyAdjustment: parseFloat(WarrantyAdjustmentSum.toFixed(2)),
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.inServiceTireCostData.push({
        Key: index,
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TireCount: item.tireCount ? item.tireCount : 0,
        TreadDepth: item.treadDepth ? item.treadDepth : "",
        AverageWorn: item.averageWorn
          ? Math.round(item.averageWorn * 100) / 100
          : 0,
        NewCost: item.newCost ? item.newCost : 0,
        NoOfRetread: item.noOfRetread ? item.noOfRetread : 0,
        RetreadCost: item.retreadCost ? item.retreadCost : 0,
        NoOfRepair: item.noOfRepair ? item.noOfRepair : 0,
        RepairCost: item.repairCost ? item.repairCost : 0,
        TotalCost: item.totalCost ? item.totalCost : 0,
        AverageCost: item.averageCost
          ? Math.round(item.averageCost * 100) / 100
          : 0,
        AverageHours: item.averageHours
          ? Math.round(item.averageHours * 100) / 100
          : 0,
        AverageProjHours: item.averageProjHours
          ? Math.round(item.averageProjHours * 100) / 100
          : 0,
        CostPerHourProj: item.costPerHourProj
          ? Math.round(item.costPerHourProj * 100) / 100
          : 0,
        AverageMiles: item.averageMiles
          ? Math.round(item.averageMiles * 100) / 100
          : 0,
        AverageProjMiles: item.averageProjectedMiles
          ? Math.round(item.averageProjectedMiles * 100) / 100
          : 0,
        CostPerMileProj: item.projectedCostPerMile
          ? Math.round(item.projectedCostPerMile * 100) / 100
          : 0,
        CasingValue: item.casingValue ? item.casingValue : 0,
        CostAdjustment: item.costAdjustment ? item.costAdjustment : 0,
        WarrantyAdjustment: item.warrantyAdjustment
          ? item.warrantyAdjustment
          : 0,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "numeric" ||
          item.dataType === "number" ||
          item.dataType === "decimal" ||
          item.dataType === "currency"
            ? true
            : false,
      });
    });

    let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells);
    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.inServiceTireCostData,
      headCells: orderedHeadCells,
      avgValues: this.avgValues(this.inServiceTireCostData),
      selectedColShow: selectedColShow,
    };
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ManufacturerName.toString().toLowerCase().includes(searchText) ||
        item.SizeName.toString().toLowerCase().includes(searchText) ||
        item.TireCount.toString().toLowerCase().includes(searchText) ||
        item.TreadDepth.toString().toLowerCase().includes(searchText) ||
        item.AverageWorn.toString().toLowerCase().includes(searchText) ||
        item.NewCost.toString().toLowerCase().includes(searchText) ||
        item.NoOfRetread.toString().toLowerCase().includes(searchText) ||
        item.RetreadCost.toString().toLowerCase().includes(searchText) ||
        item.NoOfRepair.toString().toLowerCase().includes(searchText) ||
        item.RepairCost.toString().toLowerCase().includes(searchText) ||
        item.TotalCost.toString().toLowerCase().includes(searchText) ||
        item.AverageCost.toString().toLowerCase().includes(searchText) ||
        item?.AverageHours?.toString().toLowerCase().includes(searchText) ||
        item?.AverageProjHours?.toString().toLowerCase().includes(searchText) ||
        item?.CostPerHourProj?.toString().toLowerCase().includes(searchText) ||
        item?.AverageMiles?.toString().toLowerCase().includes(searchText) ||
        item?.AverageProjMiles?.toString().toLowerCase().includes(searchText) ||
        item?.CostPerMileProj?.toString().toLowerCase().includes(searchText) ||
        item.CasingValue.toString().toLowerCase().includes(searchText) ||
        item.CostAdjustment.toString().toLowerCase().includes(searchText) ||
        item.WarrantyAdjustment.toString().toLowerCase().includes(searchText)
      );
    });
  }
}

export default InServiceTireCostSummaryDataSetter;
