import React, { useEffect, useState, useContext } from "react";
import "./ByVehicleWheelPosition.css";
import { Card, CardContent, InputLabel } from "@material-ui/core";
import MultiSelect from "react-multi-select-component";
import TiresInServiceNavigation from "../../../tires-in-service-navigation/TiresInServiceNavigation";
import ByVehicleModelDataSetter from "../../../../../services/ByVehicleWheelPositionUtils";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import ByVehicleModelTable from "../table/ByVehicleWheelPositionTable";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ByVehicleWheelPositionFilter from '../filter/ByVehicleWheelPositionFilter';

const ByVehicleModel = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const byVehicleModelDataSetter = new ByVehicleModelDataSetter();
  const commonUtils = new CommonUtils();
  const [byVehicleModelTableData, setByVehicleModelTableData] = useState();
  const [originalByVehicleModelTableData, setOriginalByVehicleModelTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(byVehicleModelTableData);
  const [noData, setNoData] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Wheel Position", value: "ActualWheelPosition" },
      { name: "Tire Manufacturer", value: "ManufacturerName" },
      { name: "Vehicle Manufacture", value: "VehicleManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Tire Type", value: "TypeName" },
      { name: "Vehicle Type", value: "VehicleTypeName" },
      { name: "Vehicle Id", value: "VehicleId" }, 
    ],
    categoryData: {
      ActualWheelPosition: [],
      ManufacturerName: [],
      VehicleManufacturerName: [],         
      SizeName: [],
      TypeName: [],
      VehicleTypeName: [],
      VehicleId: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const excludeUsedTires = false;


  const ByVehicleModelResponseData = (filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      ParentAccountIds: accountIds,
      LocationIds: locationIds
    };
    setShowSpinner(true);
    tiresService.getTiresWheelPositionReport(requestObject).then((data) => {
      let byVehicleModelTableInfo = byVehicleModelDataSetter.setData(data);
      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.WheelPosition,
            value: "ActualWheelPosition"
          },
          {
            name: globalizationMapping.REPORTTABLE.MANUFACTURER,
            value: "ManufacturerName"
          },
          {
            name: globalizationMapping.ADMIN.VehicleManufacturerT,
            value: "VehicleManufacturerName"
          },
          { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
          {
            name: globalizationMapping.REPORTTABLE.VEHICLETYPENAME,
            value: "VehicleTypeName"
          },
          {
            name: globalizationMapping.REPORTTABLE.VEHICLEID,
            value: "VehicleId"
          },
        ],
        categoryData: {
          ActualWheelPosition: [],
          ManufacturerName: [],
          VehicleManufacturerName: [],         
          SizeName: [],
          TypeName: [],
          VehicleTypeName: [],
          VehicleId: [],
          dateRangeFromDate: '',
          dateRangeToDate: '',
        }
      };
      let filterOptionsInfo = byVehicleModelDataSetter.setFilterOptionsData(filterOptions, byVehicleModelTableInfo.rowsData);
      setFilterOptions(filterOptionsInfo);
      setByVehicleModelTableData(byVehicleModelTableInfo);
      setOriginalByVehicleModelTableData(byVehicleModelTableInfo);
      setTableBeforeFilter(byVehicleModelTableInfo);
      byVehicleModelTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setDisplayColOptions(byVehicleModelTableInfo.headCells);
      setDisplayColumns(byVehicleModelTableInfo.headCells);
      setShowColumns(byVehicleModelTableInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByVehicleWheelPosition,
        rowsData: byVehicleModelTableInfo.rowsData,
        headCells: byVehicleModelTableInfo.headCells,
        avgValues: byVehicleModelTableInfo.avgValues,
        selectedColShow: byVehicleModelTableInfo.selectedColShow,
        filterApplied: filterApplied
      });
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = byVehicleModelTableInfo.rowsData;
        let filteredData = byVehicleModelDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore, dateRangeFilter);
        setByVehicleModelTableData({
          ...byVehicleModelTableInfo,
          rowsData: filteredData,
          avgValues: byVehicleModelDataSetter.avgValues(filteredData)
        });
        setOriginalByVehicleModelTableData({
          ...byVehicleModelTableInfo,
          rowsData: filteredData,
          avgValues: byVehicleModelDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: byVehicleModelDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
      setShowSpinner(false);
    });
  };

  useEffect(() => {
    // FORMATTING THE GLOBAL ACCOUNT STATES
    ByVehicleModelResponseData(excludeUsedTires, filterOptions.categoryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, excludeUsedTires]);

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalByVehicleModelTableData?.headCells;
    let showColumnsUpdated = byVehicleModelDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  const globalizationMapping = new Globalization();



  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    let originalTableDataStore = originalByVehicleModelTableData?.rowsData;
    let filteredData = byVehicleModelDataSetter.filterTable(appliedFilter, originalTableDataStore, dateRangeFilter);
    setByVehicleModelTableData({
      ...byVehicleModelTableData,
      rowsData: filteredData,
      avgValues: byVehicleModelDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byVehicleModelDataSetter.avgValues(filteredData)
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);

    setFilterFieldsApplied(appliedFilter);
    ByVehicleModelResponseData(true, appliedFilter);
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ActualWheelPosition: [],
      ManufacturerName: [],
      VehicleManufacturerName: [],         
      SizeName: [],
      TypeName: [],
      VehicleTypeName: [],
      VehicleId: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    });
    setFilterApplied(false);
    setByVehicleModelTableData(tableBeforeFilter);
    setOriginalByVehicleModelTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
    setDateRangeFilter({ fromDate: "", toDate: "" });
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  return (
    <div>
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-vehicle-model-container">
          <Card className="by-vehicle-model-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text"> {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <div className="data-summary-table">
                <ByVehicleModelTable tableData={byVehicleModelTableData} showColumns={showColumns} filterApplied={filterApplied} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />

      <ByVehicleWheelPositionFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
      />
    </div>
  );
};

export default ByVehicleModel;
