import React,{useEffect} from 'react';
import RedTire from '../../../../../../assets/RedTire.png'
import YellowTire from '../../../../../../assets/YellowTire.png'
import OrangeTire from '../../../../../../assets/OrangeTire.png'
import EmptyTire from '../../../../../../assets/tire-empty-new.png'
import GreenTire from '../../../../../../assets/GreenTire.png'
import Legends from '../../../../../../assets/legend.svg'
import Axle1 from '../../../../../../assets/axle-1.svg'
import Axle2 from '../../../../../../assets/axle-2.svg'
import Axle3 from '../../../../../../assets/axle-3.svg'
import Axle4 from '../../../../../../assets/axle-4.svg'
import Axle5 from '../../../../../../assets/axle-5.svg'
import Axle6 from '../../../../../../assets/axle-6.svg'
import Axle7 from '../../../../../../assets/axle-7.svg'
import Axle8 from '../../../../../../assets/axle-8.svg'
import Axle9 from '../../../../../../assets/axle-9.svg'
import './VehicleDiagram.css'


const VehicleDiagram = ({tableData,data,tireListData}) => {
    useEffect(() => {
        initiateTireCreation()
     },[])
     const imageUrl = [Axle1,Axle2,Axle3,Axle4,Axle5,Axle6,Axle7,Axle8,Axle9]
     let tireConfig = data
     let tireList = tireListData
     let configArray = tireConfig.split("");
     let maximumTires = (Math.max.apply(null, configArray)) / 2;
     let tireCnfPos = [];
     let tireInput = [];

    const initiateTireCreation = () => {
        if (tireList.length !== 0) {
            generateTirePositions();
        }
        if (tireList.length !== 0) {
            formatTireList();
       }
    }
    const generateTirePositions = () => {
        for (let i = 0; i < configArray.length; i++) {
          let eachAxleTires = {};
          eachAxleTires['L'] = createTires(configArray[i], false, i);
          eachAxleTires['R'] = createTires(configArray[i], true, i);
          eachAxleTires['config'] = configArray[i];
          tireCnfPos.push(eachAxleTires);
        }
        return tireCnfPos;
      }

      const createTires = (eachConfig, rightTire, currentIndex) => {
        const oneSideTire = eachConfig / 2;
        const currentPrePos = currentIndex + 1;
        let items = [];
        let tirePositionNo = 1;
        let tirePositionStr = rightTire ? "R" : "L";
        let tirePosition = "";
        for (let i = 0; i < maximumTires; i++) {
          let hastire = false;
          if (rightTire) {
            hastire = i < oneSideTire;
          } else {
            hastire = i >= (maximumTires - oneSideTire);
          }
          if (hastire) {
            if (oneSideTire > 1) {
              tirePosition = currentPrePos + changePositionKeys(tirePositionStr, tirePositionNo);
            } else {
              tirePosition = currentPrePos + tirePositionStr;
            }
            tirePositionNo++;
          }
          items.push({
            'hastire': hastire,
            'tirePos': tirePosition
          });
        }
        return items;
      }

      const changePositionKeys = (positionString, positionNo) => {
        let positionKey = '';
        if (isFinite(positionNo)) {
          if (positionString === 'L') {
            positionKey = (positionNo % 2) ? 'LO' : 'LI';
          } else {
            positionKey = (positionNo % 2) ? 'RI' : 'RO';
          }
        }
        return positionKey;
      }
     const formatTireList = () => {
        tireList.forEach(obj => {
         tireInput[obj.wheelPosition] = obj;
        });
        
        return tireInput
    }
    let generateTirePositionData = generateTirePositions();
    let generateTireListData = formatTireList();
      
     return(
        <div>
            <table className="tire-table">
            <tbody>
            <tr className="display-inline">
            <td className="display-block">
                {(generateTirePositionData).map((item, index) => {  
                 if(item['R'] && item['R'][1] !== undefined){
                    if(item['R'][1].hastire === true){
                      let percentWorn = generateTireListData[item['R'][1].tirePos] && generateTireListData[item['R'][1].tirePos].percentageWorn
                      if(item['R'][1].tirePos){
                        switch (true) {
                          case (percentWorn >75):
                            return <img src={RedTire} alt="Tire" key={index}/>
                          case (percentWorn >50 && percentWorn <= 75):
                            return <img src={OrangeTire} alt="Tire" key={index}/>
                          case (percentWorn >25 && percentWorn <= 50):
                            return <img src={YellowTire} alt="Tire" key={index}/>
                          case (percentWorn <= 25):
                            return <img src={GreenTire} alt="Tire" key={index}/>
                          default:
                            return <img src={EmptyTire} className="empty-tire" alt="Tire" key={index}/>
                        }
                      }
                      else{
                        return <img src={EmptyTire} className="empty-tire" alt="Tire" key={index}/>
                      }
                      
                    }
                    else{
                      return <img className="no-tire"></img>
                    }
                 }
                })} 
                </td>
                <td className="display-block">
                {(generateTirePositionData).map((item, index) => {
                 if(item['R'] && item['R'][0] !== undefined){
                    if(item['R'][0].hastire === true){
                      let percentWorn = generateTireListData[item['R'][0].tirePos] && generateTireListData[item['R'][0].tirePos].percentageWorn
                      if(item['R'][0].tirePos){
                        switch (true) {
                          case (percentWorn >75):
                            return <img src={RedTire} alt="Tire" key={index}/>
                          case (percentWorn >50 && percentWorn <= 75):
                            return <img src={OrangeTire} alt="Tire" key={index}/>
                          case (percentWorn >25 && percentWorn <= 50):
                            return <img src={YellowTire} alt="Tire" key={index}/>
                          case (percentWorn <= 25):
                            return <img src={GreenTire} alt="Tire" key={index}/>
                          default:
                            return <img src={EmptyTire} className="empty-tire" alt="Tire" key={index}/>
                        }
                      }
                      else{
                        return <img src={EmptyTire} className="empty-tire" alt="Tire" key={index}/>
                      }
                      
                    }
                    else{
                      return <img className="no-tire" key={index}></img>
                    }
                 }
                })}
                </td>
                 </tr>
             <tr>
             {generateTirePositionData !== null && Object.keys(generateTirePositionData).map((key, index) =>{
               return <td className={`display-inline tire-axle-number axle-${key}`} key={index}><img src={imageUrl[key]} alt="Tire"/></td>
             })}
             </tr>
            
            <tr className="display-inline">
                <td className="display-block">
                {(generateTirePositionData).map((item, index) => {  
                 if(item['L'] && item['L'][1] !== undefined){
                    if(item['L'][1].hastire === true){
                      let percentWorn = generateTireListData[item['L'][1].tirePos] && generateTireListData[item['L'][1].tirePos].percentageWorn
                      if(item['L'][1].tirePos){
                        switch (true) {
                          case (percentWorn >75):
                            return <img src={RedTire} alt="Tire" key={index}/>
                          case (percentWorn >50 && percentWorn <= 75):
                            return <img src={OrangeTire} alt="Tire" key={index}/>
                          case (percentWorn >25 && percentWorn <= 50):
                            return <img src={YellowTire} alt="Tire" key={index}/>
                          case (percentWorn <= 25):
                            return <img src={GreenTire} alt="Tire" key={index}/>
                          default:
                            return <img src={EmptyTire} className="empty-tire" alt="Tire" key={index}/>
                        }
                      }
                      else{
                        return <img src={EmptyTire} className="empty-tire" alt="Tire" key={index}/>
                      }
                      
                    }
                    else{
                      return <img className="no-tire" key={index}></img>
                    }
                 }
                })}
                </td>
                <td className="display-block">
                {(generateTirePositionData).map((item, index) => {
                 if(item['L'] && item['L'][0] !== undefined){
                    if(item['L'][0].hastire === true){
                      let percentWorn = generateTireListData[item['L'][0].tirePos] && generateTireListData[item['L'][0].tirePos].percentageWorn
                      if(item['L'][0].tirePos){
                        switch (true) {
                          case (percentWorn >75):
                            return <img src={RedTire} alt="Tire" key={index}/>
                          case (percentWorn >50 && percentWorn <= 75):
                            return <img src={OrangeTire} alt="Tire" key={index}/>
                          case (percentWorn >25 && percentWorn <= 50):
                            return <img src={YellowTire} alt="Tire" key={index}/>
                          case (percentWorn <= 25):
                            return <img src={GreenTire} alt="Tire" key={index}/>
                          default:
                            return <img src={EmptyTire} className="empty-tire" alt="Tire" key={index}/>
                        }
                      }
                      else{
                        return <img src={EmptyTire} className="empty-tire" alt="Tire" key={index}/>
                      }      
                    }
                    else{
                      return <img className="no-tire" key={index}></img>
                    }
                 }
                })}
                </td>
            </tr>
            <tr><td><img src={Legends} alt="Legend"/></td></tr>
            </tbody>
            </table>
        </div>
    )
}

export default VehicleDiagram;