import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
// eslint-disable-next-line no-unused-vars


const ByTireSizeGraph = ({ byTireSizeGraphData }) => {
  const [chartInfoData, setChartInfoData] = useState({});
  const [chartInfoDataForMile, setChartInfoDataForMile] = useState({});
  const commonUtils = new CommonUtils();
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const optionsForMile = JSON.parse(JSON.stringify(commonUtils.barOptions));
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["tire-size-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["tire-size-y"];
  options.title.text = globalizationHeaders["tire-size-title"];
  const tempYAxis =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? "tire-size-y-perKM"
      : "tire-size-y-perMile";

  const tempTitle =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? "tire-size-title-perKM"
      : "tire-size-title-perMile";
  optionsForMile.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["tire-size-x"];
  optionsForMile.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders[tempYAxis];
  optionsForMile.title.text = globalizationHeaders[tempTitle];

  const chart = () => {

    if (byTireSizeGraphData.rowsData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();
      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byTireSizeGraphData.rowsData.forEach((item) => {
        graphDataSum[item.SizeName] = 0;
        graphDataAvg[item.SizeName] = 0;
        graphDataCount[item.SizeName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byTireSizeGraphData.rowsData.forEach((item) => {
        graphDataSum[item.SizeName] += item.AverageHours;
        graphDataCount[item.SizeName]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((SizeName) => {
        graphDataAvg[SizeName] = Math.round(
          graphDataSum[SizeName] / graphDataCount[SizeName]
        );
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoData({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };

  const chartForMile = () => {

    if (byTireSizeGraphData.rowsData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();
      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      byTireSizeGraphData.rowsData.forEach((item) => {
        graphDataSum[item.SizeName] = 0;
        graphDataAvg[item.SizeName] = 0;
        graphDataCount[item.SizeName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      byTireSizeGraphData.rowsData.forEach((item) => {
        graphDataSum[item.SizeName] += item.AverageMiles;
        graphDataCount[item.SizeName]++;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((SizeName) => {
        graphDataAvg[SizeName] = Math.round(
          graphDataSum[SizeName] / graphDataCount[SizeName]
        );
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoDataForMile({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoDataForMile({});
    }
  };
  useEffect(() => {
    chart();
    chartForMile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byTireSizeGraphData]);

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 &&
          byTireSizeGraphData.selectedColShow.AverageHours && (
            <Bar data={chartInfoData} height={120} options={options} />
          )}

        {Object.keys(chartInfoDataForMile).length > 0 &&
          !byTireSizeGraphData.selectedColShow.AverageHours && (
            <Bar
              data={chartInfoDataForMile}
              height={120}
              options={optionsForMile}
            />
          )}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ByTireSizeGraph;
