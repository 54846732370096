import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, InputLabel, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import ScrapTiresNavigation from "../../../../scrap-tires/scrap-tires-navigation/components/ScrapTiresNavigation";
import ScrapCostByTireTypeDataSetter from "../../../../../services/ScrapCostByTireTypeUtils";
import ScrapCostByTireTypeGraph from "../graph/ScrapCostByTireTypeGraph";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import ScrapCostByTireTypeFilter from "../filter/ScrapCostByTireTypeFilter.js"
import PrintIcon from "@material-ui/icons/Print";
import FilterListIcon from '@material-ui/icons/FilterList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
const ScrapCostByTireType = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const scrapCostByTireTypeDataSetter = new ScrapCostByTireTypeDataSetter();
  const commonUtils = new CommonUtils();
  const [showSpinner, setShowSpinner] = useState(true);
  const [scrapCostByTireTypeGraphData, setScrapCostByTireTypeGraphData] = useState();
  const [printData, setPrintData] = useState();
  const [originalScrapCostByTireTypeTableData, setOriginalScrapCostByTireTypeTableData] = useState();
  const [displayColOptions, setDisplayColOptions] = useState();
  const [mfgOptions, setMfgOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [mfgColumns, setMfgColumns] = useState();
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [inServiceDateRange, setInServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [outServiceDateRange, setOutServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [filterOptions, setFilterOptions] = useState({
    categories: [{ name: "Size", value: "Size" }],
    categoryData: {
      Size: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
    }
  });
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  const ScrapCostByTireTypeResponseData = (excludeUsedTiresValue, appliedFilter) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let inServiceDateRangeType = "AllTime";
    let outServiceDateRangeType = "AllTime";

    if (inServiceDateRange.fromDate !== "" && inServiceDateRange.toDate !== "") inServiceDateRangeType = "DateRange";

    if (outServiceDateRange.fromDate !== "" && outServiceDateRange.toDate !== "") outServiceDateRangeType = "DateRange";
    let tempFilterOptions = {
      categories: [{ name: globalizationMapping.REPORTTABLE.SIZE, value: "Size" }],
      categoryData: {
        Size: [],
        outServiceDateToDate: '',
        outServiceDateFromDate: '',
        inServiceDateFromDate: '',
        inServiceDateToDate: '',
      }
    };

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: "AllTime",
      fromDate: "",
      graphType: "Bar",
      locationIds: locationIds,
      reportName: "GetScrapCostTireType",
      toDate: "",
      usedTires: excludeUsedTiresValue.toString(),
      InServiceDateRangeType: inServiceDateRangeType,
      InServiceFromDate: inServiceDateRange.fromDate,
      InServiceToDate: inServiceDateRange.toDate,
      OutServiceDateRangeType: outServiceDateRangeType,
      OutServiceFromDate: outServiceDateRange.fromDate,
      OutServiceToDate: outServiceDateRange.toDate
    };

    setShowSpinner(true);
    tiresService.getTiresGraph(requestObject).then((data) => {
      let scrapCostByTireTypeGraphInfo = scrapCostByTireTypeDataSetter.setGraphData(data);
      let sizes = scrapCostByTireTypeDataSetter.setFilterOptionsData(tempFilterOptions, scrapCostByTireTypeGraphInfo);
      setFilterOptions(sizes);
      setScrapCostByTireTypeGraphData(scrapCostByTireTypeGraphInfo);
      setOriginalScrapCostByTireTypeTableData(JSON.parse(JSON.stringify(scrapCostByTireTypeGraphInfo)));
      // static preset printData to allow only graph printing
      setPrintData({
        reportType: "Scrap Tires: ",
        reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapCostByTireType,
        rowsData: [],
        headCells: [],
        avgValues: [],
        selectedColShow: [],
        filterApplied: false
      });
      setDisplayColOptions(scrapCostByTireTypeDataSetter.setDisplayColumns(scrapCostByTireTypeGraphInfo));
      setDisplayColumns(scrapCostByTireTypeDataSetter.setDisplayColumns(scrapCostByTireTypeGraphInfo));
      setMfgOptions(scrapCostByTireTypeDataSetter.setMfgColumns(scrapCostByTireTypeGraphInfo));
      setMfgColumns(scrapCostByTireTypeDataSetter.setMfgColumns(scrapCostByTireTypeGraphInfo));
      setShowSpinner(false);
      if (appliedFilter && appliedFilter.Size && appliedFilter.Size.length) {
        let originalTableGraphStore = scrapCostByTireTypeGraphInfo;
        let filteredData = scrapCostByTireTypeDataSetter.filterTable(appliedFilter, originalTableGraphStore);
        setScrapCostByTireTypeGraphData(filteredData);
        setOriginalScrapCostByTireTypeTableData(JSON.parse(JSON.stringify(scrapCostByTireTypeGraphInfo)));
        // static preset printData to allow only graph printing
        setPrintData({
          reportType: "Scrap Tires: ",
          reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapCostByTireType,
          rowsData: [],
          headCells: [],
          avgValues: [],
          selectedColShow: [],
          filterApplied: false
        });
        setDisplayColOptions(scrapCostByTireTypeDataSetter.setDisplayColumns(filteredData));
        setDisplayColumns(scrapCostByTireTypeDataSetter.setDisplayColumns(filteredData));
        setMfgOptions(scrapCostByTireTypeDataSetter.setMfgColumns(filteredData));
        setMfgColumns(scrapCostByTireTypeDataSetter.setMfgColumns(filteredData));
        // setOnlyFilterApplied(true)
      }
    });
  };

  useEffect(() => {
    // FORMATTING THE GOBAL ACCOUNT STATES
    ScrapCostByTireTypeResponseData(excludeUsedTires);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    // when all columns selected

    let showColumnsUpdated = scrapCostByTireTypeDataSetter.setDisplayColumnData(originalScrapCostByTireTypeTableData, selected);
    setScrapCostByTireTypeGraphData(showColumnsUpdated);
  };

  const changeMfgColumns = (selected) => {
    let selectedColumns = selected;
    setMfgColumns(selectedColumns);
    // when all columns selected
    const originalDataTemp = JSON.parse(JSON.stringify(originalScrapCostByTireTypeTableData));
    let showColumnsUpdated = scrapCostByTireTypeDataSetter.setMfgColumnData(originalDataTemp, selected);
    setScrapCostByTireTypeGraphData(showColumnsUpdated);
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    ScrapCostByTireTypeResponseData(event.target.checked);
  };
  const globalizationMapping = new Globalization();

  const onClickFilter = () => {
    setShowFilter(true);
  };

  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      outServiceDateToDate: outServiceDateRange.toDate,
      outServiceDateFromDate:  outServiceDateRange.fromDate,
      inServiceDateFromDate: inServiceDateRange.fromDate,
      inServiceDateToDate: inServiceDateRange.toDate,
   };

  setCurrentFilter(toSetCurrentFilter);

    ScrapCostByTireTypeResponseData(excludeUsedTires, appliedFilter);
    ((Object.values(inServiceDateRange).every(x => x === "")) && (Object.values(outServiceDateRange).every(x => x === ""))) && (Object.values(appliedFilter).every(x => x.length === 0)) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
  };


  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      Size: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
    });
    let appliedFilter = {
      Size: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
    }
    ScrapCostByTireTypeResponseData(excludeUsedTires, appliedFilter);
    setFilterOptions({
      categories: [{ name: "Size", value: "Size" }],
      categoryData: {
        Size: [],
        outServiceDateToDate: '',
        outServiceDateFromDate: '',
        inServiceDateFromDate: '',
        inServiceDateToDate: '',
      }
    });
    setPrintData({
      reportType: "Scrap Tires: ",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapCostByTireType,
      rowsData: [],
      headCells: [],
      avgValues: [],
      selectedColShow: [],
      filterApplied: false
    });
    setOnlyFilterApplied(false)
  };

  return (
    <div>
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="scrap-cost-by-tire-size">
          <Card className="scrap-cost-by-tire-size-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text"> Manufacturer </span>
                    </InputLabel>
                    <MultiSelect
                      options={mfgOptions}
                      value={mfgColumns}
                      onChange={changeMfgColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: "All Manufacturer",
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text"> Display Tire Type</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllTireType,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                </div>

                <div className="table-function-title">
                  <button
                    onClick={onClickFilter}
                    className="table-function-item"
                    disabled={!scrapCostByTireTypeGraphData.chartlabels.length}>
                      {!scrapCostByTireTypeGraphData.chartlabels.length ? 
                      <span>
                        <FilterListIcon fontSize="small" style={{verticalAlign:"middle", marginBottom:5, color:commonUtils.disabledColor}}></FilterListIcon>
                      </span> : 
                      <span>
                          <FilterListIcon fontSize="small" style={{verticalAlign:"middle", marginBottom:5, color:onlyFilterApplied ? 'red': commonUtils.primaryBlue}}></FilterListIcon>
                        </span>
                      }
                  </button>
                  <button
                    onClick={onClickPrint}
                    className="table-function-item"
                    disabled={!scrapCostByTireTypeGraphData.chartlabels.length}>
                        <span style={{color: !scrapCostByTireTypeGraphData.chartlabels.length ? commonUtils.disabledColor : commonUtils.primaryBlue}}>
                          <PrintIcon disabled={!scrapCostByTireTypeGraphData.chartlabels.length} fontSize="small" style={{verticalAlign:"middle", marginBottom:5,marginLeft:5}}></PrintIcon>
                        </span>
                  </button>
                  <button
                    className={
                      !scrapCostByTireTypeGraphData.chartlabels.length === true
                        ? "table-function-item disabled-export-button"
                        : "table-function-item export-button"
                    }
                    disabled={!scrapCostByTireTypeGraphData.chartlabels.length}
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                    ref={ref}
                  >
                    <div className="dropdown-icon-container" ref={ref}>
                      <div className="export-down-arrow">
                        <span style={{color: !scrapCostByTireTypeGraphData.chartlabels.length ? commonUtils.disabledColor : commonUtils.primaryBlue}}>
                          <FileCopyIcon disabled={!scrapCostByTireTypeGraphData.chartlabels.length} fontSize="small" style={{verticalAlign:"middle", marginBottom:8}}></FileCopyIcon>
                        </span>
                      </div>
                      {isComponentVisible && (
                        <div className="export-dropdown-content">
                          <div
                            onClick={() => onClickExport("pdf")}
                            className="export-option"
                          >
                            {globalizationMapping.MANAGE_PARENT_ACCOUNT.PDF}
                          </div>
                        </div>
                      )}
                    </div>
                  </button>
                </div>
              </div>
              <Grid container>
                <Grid item xs={4}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <ScrapCostByTireTypeGraph scrapCostByTireTypeGraphData={scrapCostByTireTypeGraphData} />
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        notable={true}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        notable={true}
      />
      <EmailModalComponent
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        notable={true}
      />

      <ScrapCostByTireTypeFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        inServiceDateRange={inServiceDateRange}
        setInServiceDateRange={setInServiceDateRange}
        outServiceDateRange={outServiceDateRange}
        setOutServiceDateRange={setOutServiceDateRange}
      />
    </div>
  );
};

export default ScrapCostByTireType;
