import React from 'react';
import './alert-popup.css';
import Globalization from "../../globalization";

const AlertPopup = (props) => {
  const globalizationMapping = new Globalization();

  const saveChanges = () => {
    props.onSave();
    props.setNavigationFlag();
    props.setTryNavigating();
  }

  return (
    <div className="container">
      <div className="header-change-modal-container center-modal">
        <button onClick={props.onClose} className="bootbox-close-button close" type="button"><img
          src={require("../../assets/close-icon.svg")}
          className="modal-close-icon"
          alt="close-icon"
        /></button>
        <div style={{ marginLeft: '1em' }} className="header-change-modal-title">
          {props.description}
        </div>
        <div style={{ marginLeft: '1em', marginTop: '1em' }} className="bootbox-body header-change-modal-description">
          {props.message}
        </div>
        <div className="modal-button-group">
          {(props.screen === "TireInspectionReport") && (<div>
            <button
              className="header-change-modal-confirm-btn"
              onClick={props.onClose}
            >
              {globalizationMapping.FORMS.OK}
            </button>
          </div>)}
          {(props.screen === "InspectTire" || props.screen === "RemoveTire" || props.screen === "RemoveAllTire") && (<div>
            <button
              className="header-change-modal-cancel-btn"
              onClick={props.onClose}
            >
              {globalizationMapping.FORMS.NO}
            </button>
            <button
              className="header-change-modal-confirm-btn"
              onClick={() => {
                props.allowSubmit(true);
                props.onSave('Popup');
              }}
            >
              {globalizationMapping.FORMS.YES}
            </button>
          </div>)}
          {(props.screen === "QuickInspect") && (<div>
            <button
              className="header-change-modal-cancel-btn"
              onClick={props.onClose}
            >
              {globalizationMapping.FORMS.CANCEL}
            </button>
          </div>)}
          {(props.screen === "QuickInspect") && (<div>
            <button
              className="header-change-modal-confirm-btn"
              onClick={saveChanges}
            >
              {globalizationMapping.FORMS.SAVE}
            </button>
          </div>)}
        </div>
      </div>
    </div>
  );
}

export default AlertPopup;