import Globalization from '../globalization/index'

const globalizationMapping = new Globalization();

export const ViewVehiclePaths = [
    {
        name: globalizationMapping.VIEWVEHICLEREPORTS.IDENTIFICATION,
        path: 'identification', 
        routeSelected: 1 
      },
      {
        name: globalizationMapping.VIEWVEHICLEREPORTS.DESCRIPTION,
        path: 'description',
        routeSelected: 2
      },
      {
        name: globalizationMapping.VIEWVEHICLEREPORTS.OPERATIONSPECIFICATION,
        path: 'operationSpecification',
        routeSelected: 3
      }
]