import React, { useEffect, useState } from "react";
import "./ManageRimListTable.css";
import "../../../../../common-styles/TableStyle.css";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Divider,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from '@material-ui/icons/MoreVertOutlined';
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import DeleteRimModal from "../../modals/DeleteRim";
import Globalization from "../../../../../globalization";
import CommonUtils from "../../../../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    maxHeight: 890,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
  },
}));
function descendingComparator(a, b, orderBy) {
  let firstVal = a[orderBy]
  let secondVal = b[orderBy]
  if (isNaN(a[orderBy]) && isNaN(b[orderBy])) {
    firstVal = a[orderBy]?.toLowerCase().trim()
    secondVal = b[orderBy]?.toLowerCase().trim()
  }

  if (secondVal < firstVal) {
    return -1;
  }
  if (secondVal > firstVal) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const ManageRimListTable = ({
  tableData,
  showColumns,
  multipleRowsSelected,
  _locationList,
  deleteRimDone,
  forceActionPopupClose,
  forceActionPopupCloseToFalse,
}) => {
  const globalizationMapping = new Globalization();
  const [manageRimListTableData, setManageRimListTableData] =
    useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ManufacturerName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rowsSelected, setRowsSelected] = useState(0);
  const [editClicked, setEditClicked] = useState([]);
  const history = useHistory();
  const [rimIdToDelete, setRimIdToDelete] = useState(null);
  const [rimNumberToDelete, setRimNumberToDelete] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedAccIds, setSelectedAccIds] = useState([]);
  const [selectedAccDetails, setSelectedAccDetails] = useState([]);
  const commonUtils = new CommonUtils();

  useEffect(() => {
    setManageRimListTableData(tableData);
    let row = [];
    row.length = manageRimListTableData.rowsData.length;
    for (let i = 0; i < manageRimListTableData.rowsData.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
    let arr = commonUtils.rowPerPageSelector(manageRimListTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  useEffect(() => {
    forceCloseAction();
    forceActionPopupCloseToFalse();
  }, [forceActionPopupClose]);


  const findBottom = (index) => {
    return (index > 7 && index < 10) ||
      (index > 12 && index < 15) ||
      (index > 22 && index < 25) ||
      index === manageRimListTableData.rowsData.length
      ? true
      : false;
  };
  const handleRequestSort = (property) => (_event) => {
    forceCloseAction();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (_event, newPage) => {
    forceCloseAction();
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    forceCloseAction();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSelectAllClick = () => {
    forceCloseAction();
    let rimData = manageRimListTableData.rowsData;
    setSelectedAccDetails([]);
    if (rowsSelected !== rimData.length) {
      let rimData = manageRimListTableData.rowsData;
      let rimIDs = rimData.map((val, _index) => {
        return val.RimId;
      });
      setSelectedAccIds(rimIDs);
      setRowsSelected(rimData.length);
      multipleRowsSelected(rimData);
      setSelectedAccDetails(rimData);
    } else {
      setSelectedAccIds([]);
      setRowsSelected(0);
      multipleRowsSelected([]);
    }
  };
  const onRowSelect = (_index, rimId) => {
    forceCloseAction();
    let ind = selectedAccIds.indexOf(rimId);
    let selectedCount = selectedAccDetails.length;
    let selectedIdsList = [...selectedAccIds];
    let selectedDetailsList = [...selectedAccDetails];
    if (ind > -1) {
      selectedIdsList.splice(ind, 1);
      selectedDetailsList.splice(ind, 1);
      selectedCount--;
    } else {
      selectedCount++;
      selectedIdsList.push(rimId);
      let Account = manageRimListTableData.rowsData.find(
        (element) => element.RimId === rimId
      );
      selectedDetailsList.push(Account);
    }
    multipleRowsSelected(selectedDetailsList);
    setRowsSelected(selectedCount);
    setSelectedAccIds(selectedIdsList);
    setSelectedAccDetails(selectedDetailsList);
  };

  const editRow = (index) => {
    let row = [...editClicked];
    row.length = manageRimListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      if (row[i] === true && index !== i) {
        row[i] = false;
      }
    }
    if (editClicked[index]) {
      row[index] = !row[index];
      setEditClicked(row);
    } else {
      row[index] = true;
      setEditClicked(row);
    }
  };

  const forceCloseAction = () => {
    let row = [...editClicked];
    row.length = manageRimListTableData.rowsData.length;
    for (let i = 0; i < row.length; i++) {
      row[i] = false;
    }
    setEditClicked(row);
  }

  const handleDeleteClick = (index, vehicleId, number) => {
    editRow(index);
    setRimIdToDelete(vehicleId);
    setRimNumberToDelete(number);
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = (deleteData) => {
    setDeleteModalOpen(false);
    setRimIdToDelete(null);
    setRimNumberToDelete(null);
    let notification = { state: deleteData };
    deleteRimDone(notification);
  };
  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}
            style={{
              minHeight:
                manageRimListTableData.rowsData.length < 4
                  ? "450px"
                  : "800px",
            }}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.stickyHeader}
                    padding="checkbox"
                  >
                    <Checkbox
                      indeterminate={
                        rowsSelected > 0 &&
                        rowsSelected < manageRimListTableData.rowsData.length
                      }
                      checked={
                        manageRimListTableData.rowsData.length > 0 &&
                        rowsSelected === manageRimListTableData.rowsData.length
                      }
                      style={{
                        color: "#0055A4",
                        zIndex: 2,
                      }}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  </TableCell>
                  {manageRimListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align={headCell.numeric === true ? "right" : "left"}
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        {headCell.value === "Actions" ? (
                          <>{headCell.label}</>
                        ) : (
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort(headCell.value)}
                        >
                          {headCell.label === "Km" ? "Kilometers" : headCell.label}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                        )}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              <tbody>
                <tr className="table-bottom-line">
                  <td colSpan={manageRimListTableData.headCells.length}></td>
                </tr>
              </tbody>
              {manageRimListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    manageRimListTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          tabIndex={-1}
                          key={row.Key}
                        >
                          <Tooltip title={row.DispositionName !== "Inventory" ? 'item not in Inventory' : 'item in inventory'} placement="top">
                            <TableCell
                              style={{ position: "relative" }}
                              className="table-content-text"
                              padding="checkbox"
                            >
                              <Checkbox
                                // disabled={row.DispositionName !== "Inventory"} -> requested by tirefountain
                                checked={selectedAccIds.indexOf(row.RimId) > -1}
                                style={{ color: "#0055A4" }}
                                onChange={() => onRowSelect(index, row.RimId)}
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                              />
                            </TableCell>
                          </Tooltip>
                          {showColumns.RimSerialNumber && (
                            <Tooltip title={`View Rim ${row.RimSerialNumber}`} placement="top">
                              <TableCell
                                align="left"
                                className="table-content-text"
                                style={{ position: "relative" }}
                                id="vehicle-number"
                                onClick={() =>
                                  history.push("/view-rim/" + row.RimId)
                                }
                              >
                                {row.RimSerialNumber}
                              </TableCell>
                            </Tooltip>
                          )}
                          {showColumns.TireSerialNumber && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.TireSerialNumber}
                            </TableCell>
                          )}
                          {showColumns.BrandNumber && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.BrandNumber}
                            </TableCell>
                          )}
                          {showColumns.Hours && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Hours}
                            </TableCell>
                          )}
                          {showColumns.DispositionName && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.DispositionName}
                            </TableCell>
                          )}
                          {showColumns.VehicleStatus && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.VehicleStatus}
                            </TableCell>
                          )}
                          {showColumns.Km && (
                            <TableCell
                              align="left"
                              className="table-content-text"
                              style={{ position: "relative" }}
                            >
                              {row.Km}
                            </TableCell>
                          )}
                          <TableCell
                            align="left"
                            className="table-content-text"
                            style={{ position: "relative" }}
                          >
                            <IconButton
                              aria-label="edit"
                              onClick={() => editRow(index)}
                            >
                              <MoreIcon style={{color:commonUtils.primaryBlue}} fontSize='small'></MoreIcon>
                            </IconButton>
                            {editClicked[index] === true && (
                              <div className="action-dropdown-content"
                                style={{
                                  marginTop: findBottom(index)
                                    ? "-200px"
                                    : "-30px",
                                }}>
                                <div
                                  className="action-option"
                                  onClick={() =>
                                    history.push("/view-rim/" + row.RimId)
                                  }
                                >
                                  {globalizationMapping.HOMEDASHBOARD.VIEW}
                                </div>
                                <Divider />
                                <div
                                  className="action-option"
                                  onClick={() =>
                                    history.push("/createrim/" + row.RimId)
                                  }
                                >
                                  {globalizationMapping.PROFILE.Edit}
                                </div>
                                <Divider />
                                {row.DispositionName !== "Installed" ? 
                                  <React.Fragment>
                                    <div
                                      className="action-option"
                                      onClick={() =>
                                        history.push({
                                          pathname: "/createrim/" + row.RimId,
                                          state: {
                                            option: "Clone",
                                          },
                                        })
                                      }
                                    >
                                      {
                                        globalizationMapping.FORMS.TIRESVEHICLES
                                          .TIREFORM.CLONE
                                      }
                                    </div>
                                    <Divider />
                                    <div
                                      className="action-option"
                                      onClick={() => {
                                        handleDeleteClick(
                                          index,
                                          row.RimId,
                                          row.RimSerialNumber
                                        );
                                      }}
                                    >
                                      {
                                        globalizationMapping
                                          .MANAGE_PARENT_ACCOUNT.OPTION3
                                      }
                                    </div>
                                    <Divider />{" "}
                                    </React.Fragment>
                                  : null
                                }
                                
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
              {manageRimListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={8}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              <tbody>
                <tr className="table-bottom-line">
                  <td colSpan={manageRimListTableData.headCells.length}></td>
                </tr>
              </tbody>
              {isDeleteModalOpen && (
                <DeleteRimModal
                  closeDeleteModal={handleDeleteModalClose}
                  rimId={rimIdToDelete}
                  rimNumber={rimNumberToDelete}
                  isSelectionMultiple={false}
                  isModalOpen={isDeleteModalOpen}
                />
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={manageRimListTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default ManageRimListTable;
