import React, { useEffect, useState } from "react";
import "./RimReportTable.css";
import "../../../../../../../common-styles/TableStyle.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../../services/utils";
import Globalization from "../../../../../../../globalization";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 600,
  },
  container: {
    height: 800,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const commonUtils = new CommonUtils();

const RimReportTable = ({ tableData, showColumns, _filterApplied }) => {
  const [rimReportTableData, setRimReportTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ManufacturerName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const globalizationMapping = new Globalization();

  function removeDateNaN(row) {
    const item = JSON.parse(JSON.stringify(row));
    return item && item !== "NaN/NaN/NaN" ? item : "";
  }

  // USED FOR SORTING
  function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
       let firstVal = a[orderBy].toLowerCase().trim()
       let secondVal = b[orderBy].toLowerCase().trim()
       if (secondVal < firstVal) {
         return -1;
       }
       if (secondVal > firstVal) {
         return 1;
       }
       return 0;
  
     } else {
      let firstVal = a[orderBy]
      let secondVal = b[orderBy]
      if (secondVal < firstVal) {
        return -1;
      }
      if (secondVal > firstVal) {
        return 1;
      }
      return 0;
    }
  }

  
  function getComparator(sOrder, orderByName) {
    return sOrder === "desc"
      ? (a, b) => descendingComparator(a, b, orderByName)
      : (a, b) => -descendingComparator(a, b, orderByName);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => {
      el.EntryDate = new Date(commonUtils.tableDateFormatter(el.EntryDate));
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      const sOrder = comparator(a[0], b[0]);
      if (sOrder !== 0) {
        return sOrder;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => {
      el[0].EntryDate = getFormattedDate(el[0].EntryDate);
      return el[0];
    });
  }

  const getFormattedDate = (inputDate) => {
    const todayTime = inputDate;
    const month = todayTime.getMonth() + 1;
    const day = todayTime.getDate();
    const year = todayTime.getFullYear();
    let newDate = `${month}/${day}/${year}`;
    newDate = commonUtils.tableDateFormatter(newDate);
    return newDate;
  };

  useEffect(() => {
    setRimReportTableData(tableData);
    setPage(0);
    let arr = commonUtils.rowPerPageSelector(rimReportTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  const lastPage = Math.floor(rimReportTableData.rowsData.length / rowsPerPage);

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {rimReportTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className="table-head-row"
                        key={headCell.keyIndex}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort(headCell.value)}
                        >
                          {headCell.label}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {rimReportTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    rimReportTableData.rowsData,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? "table-row"
                              : "table-row-alternate"
                          }
                          tabIndex={-1}
                          key={row.Key}
                        >
                          {showColumns.BrandNumber && (
                            <TableCell className="table-content-text">
                              {row.BrandNumber}
                            </TableCell>
                          )}
                          {showColumns.RimSerialNo && (
                            <TableCell className="table-content-text">
                              {row.RimSerialNo}
                            </TableCell>
                          )}
                          {showColumns.ManufacturerName && (
                            <TableCell className="table-content-text">
                              {row.ManufacturerName}
                            </TableCell>
                          )}
                          {showColumns.SizeName && (
                            <TableCell className="table-content-text">
                              {row.SizeName}
                            </TableCell>
                          )}
                          {showColumns.TypeName && (
                            <TableCell className="table-content-text">
                              {row.TypeName}
                            </TableCell>
                          )}
                          {showColumns.TotalHours && (
                            <TableCell className="table-content-text">
                              {row.TotalHours}
                            </TableCell>
                          )}
                          {showColumns.TotalMiles && (
                            <TableCell className="table-content-text">
                              {row.TotalMiles}
                            </TableCell>
                          )}
                          {showColumns.NewCost && (
                            <TableCell className="table-content-text">
                              {row.NewCost}
                            </TableCell>
                          )}
                          {showColumns.Disposition && (
                            <TableCell className="table-content-text">
                              {row.Disposition}
                            </TableCell>
                          )}
                          {showColumns.TorqueValue && (
                            <TableCell className="table-content-text">
                              {row.TorqueValue}
                            </TableCell>
                          )}
                          {showColumns.EntryDate && (
                            <TableCell className="table-content-text">
                              {removeDateNaN(row.EntryDate)}
                            </TableCell>
                          )}
                          {showColumns.PoNumber && (
                            <TableCell className="table-content-text">
                              {row.PoNumber}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {((page === lastPage - 1 &&
                    rimReportTableData.rowsData.length % rowsPerPage === 0) ||
                    (page === lastPage &&
                      rimReportTableData.rowsData.length % rowsPerPage !== 0) ||
                    rowsPerPage === rimReportTableData.rowsData.length) && (
                    <TableRow className="total-avg-row">
                      {showColumns.BrandNumber && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.RimSerialNo && (
                        <TableCell className="total-avg-text">
                          {globalizationMapping.REPORTCOMMONWORDS.TotalAverage}
                        </TableCell>
                      )}
                      {showColumns.ManufacturerName && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.SizeName && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.TypeName && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.Compound && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.TotalHours && (
                        <TableCell align="left" className="total-avg-text">
                          {rimReportTableData.avgValues.TotalHours}
                        </TableCell>
                      )}
                      {showColumns.TotalMiles && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.NewCost && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.Disposition && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.TorqueValue && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.EntryDate && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                      {showColumns.PoNumber && (
                        <TableCell className="total-avg-text"></TableCell>
                      )}
                    </TableRow>
                  )}
                </TableBody>
              )}
              {rimReportTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={rimReportTableData.headCells.length}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={rimReportTableData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default RimReportTable;
