import {
  Backdrop,
  Button,
  InputLabel,
  makeStyles,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Globalization from "../../../../../globalization";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import { StateContext } from "../../../../../services/StateProvider";
import ManageTireListApis from "../../../services/ManageTireListApis";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./transfer-tire-modal.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HeaderApi from "../../../../../common-components/header/services/header-apis";

const useStyles = makeStyles({
  proceed: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5,
  },
  expandIcon: {
    color: "#0055A4",
    cursor: "pointer",
    top: "calc(50% - 12px)",
    position: "absolute",
    pointerEvents: "none",
    right: "7px",
  },
});

const TransferTireModal = (props) => {
  const styles = useStyles();

  const globalizationMapping = new Globalization();
  const titleTexts = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
  const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
  const [globalAccountState] = useContext(StateContext);
  const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
  const locationIds = accountInfoObject.locationIds;
  const [tiresToTransfer, setTiresToTransfer] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const manageTireApiServices = new ManageTireListApis();
  const [transferType, setTransferType] = useState(titleTexts.WithinAccount);
  const [parentAccountList, setParentAccountList] = useState([
    {
      accountName: titleTexts.DROPDOWNPLACEHOLDER,
      createdBy: titleTexts.DROPDOWNPLACEHOLDER,
      parentAccountId: titleTexts.DROPDOWNPLACEHOLDER,
    },
  ]);

  const [parentAccount, setParentAccount] = useState({
    accountName: titleTexts.DROPDOWNPLACEHOLDER,
    createdBy: titleTexts.DROPDOWNPLACEHOLDER,
    parentAccountId: titleTexts.DROPDOWNPLACEHOLDER,
  });
  const [multiSelectLocationList, setMultiSelectLocationList] = React.useState(
    []
  );
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = React.useState([]);
  const headerApi = new HeaderApi();

  useEffect(() => {
    getParentAccountList();
    getLocationListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.isSelectionMultiple) {
      const arr = props.tireIds.filter((tire) => {
        return tire.Disposition !== "Installed";
      });
      setTiresToTransfer(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = (stateData) => {
    props.closeTransferModal(stateData);
  };

  const getDisposition = (disp) => {
    switch (disp) {
      case "Inventory":
        return 8;
      case "Scrap":
        return 1;
      case "Installed":
        return 7;
      case "OnHold":
        return 4;
      default:
        return 0;
    }
  };

  const showSelectedTires = () => {
    if (props.isSelectionMultiple) {
      if (tiresToTransfer.length > 3) {
        let tireStr1 = "";
        let tireStr2 = "";
        for (let i = 0; i <= 2; i++) {
          tireStr1 = tireStr1 + tiresToTransfer[i].SerialNo + ", ";
        }
        tireStr2 = "..." + (tiresToTransfer.length - 3) + " more";
        return tireStr1 + tireStr2;
      } else {
        return Object.values(tiresToTransfer).map((value) => value.SerialNo);
      }
    }
  };
  const compareStrings = (a, b) => {
    // Assuming you want case-insensitive comparison
    a = a.trim();
    b = b.trim();
    a = a.toLowerCase();
    b = b.toLowerCase();

    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  };
  const getParentAccountList = () => {
    headerApi.getParentAccountList().then((data) => {
      setParentAccountList([]);
      let model = data.model;
      model.sort(function (a, b) {
        return compareStrings(a.accountName, b.accountName);
      });
      let parentAccountListObject = [
        {
          accountName: titleTexts.DROPDOWNPLACEHOLDER,
          createdBy: titleTexts.DROPDOWNPLACEHOLDER,
          parentAccountId: titleTexts.DROPDOWNPLACEHOLDER,
        },
      ];
      for (let item of model) {
        parentAccountListObject.push({
          accountName: item.accountName,
          createdBy: item.createdBy,
          parentAccountId: item.parentAccountId,
        });
      }
      setParentAccountList(parentAccountListObject);
    });
  };

  const getLocationListData = () => {
    let locationListObject = [];
    headerApi.getLocationList().then((data) => {
      for (let item of data.model) {
        locationListObject.push({
          locationId: item.locationId,
          locationName: item.locationName,
          parentAccountId: item.parentAccountId,
          createdBy: item.createdBy,
        });
      }
      setLocationList(locationListObject);
    });
  };
  const onChangeValue = (event) => {
    setTransferType(event.target.value);
  };

  const handleAccountChange = (_event, selectedOption) => {
    if (selectedOption !== null && selectedOption.parentAccountId !== null) {
      setParentAccount(selectedOption);
      getLocationListByAccount(selectedOption.parentAccountId);
    }
  };
  const getLocationListByAccount = (parentAccountId) => {
    if (parentAccountId) {
      let updatedMultiSelectLocationObject =
        updateLocationList(parentAccountId);
      setMultiSelectLocationList(updatedMultiSelectLocationObject);
      setLocation([]);
    } else {
      setMultiSelectLocationList([]);
      setLocation([]);
    }
  };

  // update location based on parent account
  const updateLocationList = (selectedParentAccountId) => {
    setLocation([]);
    let updatedMultiSelectLocationObject = [];
    for (let item of locationList) {
      if (item.parentAccountId === selectedParentAccountId) {
        updatedMultiSelectLocationObject.push({
          value: item.locationId,
          label: item.locationName,
        });
      }
    }
    return updatedMultiSelectLocationObject;
  };

  const handleTransferClick = () => {
    let successStatus = false;
    if (transferType === titleTexts.ToAnotherAccount) {
      if (props.isSelectionMultiple) {
        if (tiresToTransfer.length !== 0) {
          let tireIdArray = [];
          tiresToTransfer.forEach((tire) => {
            tireIdArray.push(tire.Key);
          });
          let payload = {
            tireListId: tireIdArray,
            targetParentAccountId: parentAccount.parentAccountId,
            targetLocationId: Number(location),
          };
          manageTireApiServices
            .transferTireToParent(payload)
            .then((response) => {
              if (response.didError === false) {
                successStatus = true;
              } else {
                successStatus = false;
              }

              handleModalClose({
                updatedSection: "tranfertire",
                updateStatus: true,
                successMessage: notificationTexts.TIRESTRANSFERED,
                successMessageDesc: notificationTexts.TIRESTRANSFERSUCCESS,
              });
            });
        }
      } else {
        //tire is single
        let payload = {
          tireListId: [props.tireId],
          targetParentAccountId: parentAccount.parentAccountId,
          targetLocationId: Number(location),
        };

        manageTireApiServices.transferTireToParent(payload).then((response) => {
          if (response.didError === false) {
            successStatus = true;
          } else {
            successStatus = false;
          }
          handleModalClose({
            dispositionId: getDisposition(props.tireDisposition),
            updatedSection: "tranfertire",
            updateStatus: successStatus,
            successMessage: notificationTexts.TIRETRANSFERED,
            successMessageDesc: notificationTexts.TRANSFERSUCCESS.replace(
              "#1#1",
              props.tireSerial
            ),
          });
        });
      }
    } else if (transferType === titleTexts.WithinAccount) {
      if (selectedLocation === 0 || locationIds[0] === 0) {
        return;
      } else {
        if (props.isSelectionMultiple) {
          if (tiresToTransfer.length !== 0) {
            tiresToTransfer.forEach((tire) => {
              manageTireApiServices
                .transferTireToLocation(tire.Key, selectedLocation)
                .then((response) => {
                  if (response.didError === false) {
                    successStatus = true;
                  } else {
                    successStatus = false;
                  }
                });
            });

            handleModalClose({
              updatedSection: "tranfertire",
              updateStatus: true,
              successMessage: notificationTexts.TIRESTRANSFERED,
              successMessageDesc: notificationTexts.TIRESTRANSFERSUCCESS,
            });
          }
        } else {
          //tire is single
          manageTireApiServices
            .transferTireToLocation(props.tireId, selectedLocation)
            .then((response) => {
              if (response.didError === false) {
                successStatus = true;
              } else {
                successStatus = false;
              }
              handleModalClose({
                dispositionId: getDisposition(props.tireDisposition),
                updatedSection: "tranfertire",
                updateStatus: successStatus,
                successMessage: notificationTexts.TIRETRANSFERED,
                successMessageDesc: notificationTexts.TRANSFERSUCCESS.replace(
                  "#1#1",
                  props.tireSerial
                ),
              });
            });
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        {/* {showSpinner
                ?<Spinner />: */}
        <Modal
          open={true}
          onClose={props.closeTransferModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300,
          }}
          disableScrollLock={true}
        >
          <div
            className={`tire-transfer-modal-container center-modal ${
              props.isSelectionMultiple && tiresToTransfer > 50
                ? "transfer-multiple-selected"
                : ""
            }`}
          >
            <div className="transfer-modal-title-container">
              <div className="transfer-modal-title">
                {titleTexts.TRANSFERTIRE}
              </div>
              <div className="close-icon-container" onClick={handleModalClose}>
                <img
                  src={require("../../../../../assets/close-icon.svg")}
                  className="modal-close-icon"
                  alt="close-icon"
                />
              </div>
            </div>
            {/* Adding a code for radio button  */}
            <div
              className="transfer-modal-description"
              onChange={onChangeValue}
            >
              <>
                {" "}
                <input
                  type="radio"
                  value={titleTexts.WithinAccount}
                  name="transferType"
                  checked={transferType === titleTexts.WithinAccount}
                />{" "}
                {titleTexts.WithinAccount}
              </>
              <>
                {" "}
                <input
                  type="radio"
                  value={titleTexts.ToAnotherAccount}
                  name="transferType"
                  checked={transferType === titleTexts.ToAnotherAccount}
                />{" "}
                {titleTexts.ToAnotherAccount}
              </>
            </div>
            {transferType === titleTexts.WithinAccount && (
              <>
                <div className="transfer-modal-description">
                  {!props.isSelectionMultiple ? (
                    titleTexts.TRANSFERTIREDESC.replace(
                      "#1#1",
                      props.tireSerial
                    )
                  ) : tiresToTransfer.length !== 0 ? (
                    titleTexts.TRANSFERTIREDESC.replace(
                      "#1#1",
                      showSelectedTires
                    ).replaceAll(",", ", ")
                  ) : (
                    <p>{titleTexts.TRANSFERFORIBIDDEN}</p>
                  )}
                </div>

                {((props.isSelectionMultiple && tiresToTransfer.length !== 0) ||
                  !props.isSelectionMultiple) && (
                  <>
                    <div className="modal-select-container">
                      <InputLabel>
                        {globalizationMapping.HEADER.LOCATION}&nbsp;
                        <span className="required-mark">*</span>
                      </InputLabel>
                      <Select
                        native
                        className="location-select-input"
                        variant="outlined"
                        value={selectedLocation}
                        onChange={(e) =>
                          setSelectedLocation(parseInt(e.target.value))
                        }
                        IconComponent={() => (
                          <ExpandMoreIcon
                            className={styles.expandIcon}
                          ></ExpandMoreIcon>
                        )}
                      >
                        <option key={0} value={0} disabled>
                          &nbsp;{titleTexts.DROPDOWNPLACEHOLDER}
                        </option>
                        {/* eslint-disable-next-line array-callback-return */}
                        {props.locationList.map((location, key) => {
                          if (
                            !props.isSelectionMultiple &&
                            location.locationName.trim().toLowerCase() ===
                              props.currentTireLocation.trim().toLowerCase()
                          ) {
                            return (
                              <option
                                key={key}
                                value={location.locationId}
                                disabled
                              >
                                &nbsp;{location.locationName}
                              </option>
                            );
                          } else
                            return (
                              <option key={key} value={location.locationId}>
                                &nbsp;{location.locationName}
                              </option>
                            );
                        })}
                      </Select>
                    </div>
                  </>
                )}
              </>
            )}
            {transferType === titleTexts.ToAnotherAccount && (
              <>
                <div className="transfer-modal-description">
                  <p className="selected-vehicles">
                    {!props.isSelectionMultiple ? (
                      titleTexts.TRANSFERTIREWITHDESC.replace(
                        "#1#1",
                        props.tireSerial
                      )
                    ) : tiresToTransfer.length !== 0 ? (
                      titleTexts.TRANSFERTIREWITHDESC.replace(
                        "#1#1",
                        showSelectedTires
                      ).replaceAll(",", ", ")
                    ) : (
                      <p>{titleTexts.TRANSFERFORIBIDDEN}</p>
                    )}
                  </p>
                </div>

                <div className="modal-select-container">
                  {((props.isSelectionMultiple &&
                    tiresToTransfer.length !== 0) ||
                    !props.isSelectionMultiple) && (
                    <>
                      <InputLabel
                        className="location-cl"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        {globalizationMapping.CHANGEMODEL.SELECTPARENTACCOUNT}
                      </InputLabel>
                      <Autocomplete
                        size="small"
                        className="location-select-input"
                        options={parentAccountList}
                        value={parentAccount}
                        getOptionLabel={(option) => option.accountName}
                        fullWidth={true}
                        renderInput={(params) => {return (
                          <TextField
                            {...params}
                            className="location-select-input"
                            variant="outlined"
                            inputProps={{...params.inputProps, maxLength: 50 }}
                          />
                        )}}
                        onChange={(event, newValue) => {
                          handleAccountChange(event, newValue);
                        }}
                        
                      />

                      {/* Multi Select */}

                      <div style={{ marginTop: "1rem" }}>
                        <InputLabel className="location-cl">
                          {globalizationMapping.CHANGEMODEL.LOCATION}
                        </InputLabel>
                        <Select
                          style={{ padding: "1.5%" }}
                          native
                          className="location-select-input"
                          variant="outlined"
                          options={multiSelectLocationList}
                          value={location}
                          onChange={(event) => {
                            setLocation(event.target.value);
                          }}
                        >
                          <option key={0} value={0}>
                            &nbsp;{titleTexts.DROPDOWNPLACEHOLDER}
                          </option>
                          {/* eslint-disable-next-line array-callback-return */}
                          {multiSelectLocationList &&
                            multiSelectLocationList.map((location, key) => {
                              return (
                                <option key={key} value={location.value}>
                                  &nbsp;{location.label}
                                </option>
                              );
                            })}
                        </Select>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div className="modal-action-buttons">
              <Button
                className={styles.cancel}
                variant="contained"
                disableElevation={true}
                onClick={handleModalClose}
              >
                {globalizationMapping.FORMS.CANCEL}
              </Button>
              <Button
                className={styles.proceed}
                variant="contained"
                disableElevation={true}
                onClick={() => {
                  handleTransferClick();
                }}
              >
                {globalizationMapping.FORMS.TRANSFER}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default TransferTireModal;
