import { MakeSurveyTemplate, getFilename } from "./SurveyTemplatePdfMake";

export const SurveyTemplatePdfGenerator = (
  axleConfigurationDetails,
  locationNameSelected,
  exportType
) => {
  switch (exportType) {
    case "create":
      return MakeSurveyTemplate(axleConfigurationDetails, locationNameSelected);
    case "print":
      MakeSurveyTemplate(
        axleConfigurationDetails,
        locationNameSelected
      ).print();
      break;
    case "download":
      MakeSurveyTemplate(
        axleConfigurationDetails,
        locationNameSelected
      ).download(getFilename(axleConfigurationDetails));
      break;
    case "view":
      MakeSurveyTemplate(axleConfigurationDetails, locationNameSelected).open();
      break;
    default:
      break;
  }
};
