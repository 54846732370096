import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, InputLabel, TextField, InputAdornment, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresInServiceNavigation from "../../../tires-in-service-navigation/TiresInServiceNavigation";
import ByPercentageWornDataSetter from "../../../../../services/ByPercentageWornUtils";
import ByPercentageWornGraph from "../graph/ByPercentageWornGraph";
import ByPercentageWornTable from "../table/ByPercentageWornTable";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ByPercentageWornFilter from "../filter/ByPercentageWornFilter.js";

const ByPercentageWorn = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const byPercentageWornDataSetter = new ByPercentageWornDataSetter();
  const commonUtils = new CommonUtils();

  const [showSpinner, setShowSpinner] = useState(true);
  const [byPercentageWornData, setByPercentageWornData] = useState();
  const [originalByPercentageWornData, setOriginalByPercentageWornData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(byPercentageWornData);
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [noData, setNoData] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const globalizationMapping = new Globalization();
  const [toggleHM, setToggleHM] = useState("Hours");

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" }

    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  useEffect(() => {
    ByPercentageWornResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const ByPercentageWornResponseData = (excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    // FORMATTING THE GOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      locationIds: locationIds,
      reportName: "Tire_In_Service_By_Percentage_Worn",
      reportOption: "",
      usedTires: excludeUsedTiresValue.toString()
    };
    let filterOptions = {
      categories: [
        {
          name: globalizationMapping.REPORTTABLE.MANUFACTURER,
          value: "ManufacturerName"
        },
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        { name: globalizationMapping.REPORTTABLE.COMPOUND, value: "CompoundName" }

      ],
      categoryData: {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        CompoundName: [],
        dateRangeFromDate: '',
        dateRangeToDate: '',
      }
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let byPercentageWornInfo = byPercentageWornDataSetter.setData(data);
      let filterOptionsInfo = byPercentageWornDataSetter.setFilterOptionsData(filterOptions, byPercentageWornInfo.rowsData);
      setByPercentageWornData(byPercentageWornInfo);
      setOriginalByPercentageWornData(byPercentageWornInfo);
      setTableBeforeFilter(byPercentageWornInfo);
      byPercentageWornInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setFilterOptions(filterOptionsInfo);
      setDisplayColOptions(byPercentageWornInfo.headCells);
      setDisplayColumns(byPercentageWornInfo.headCells);
      setShowColumns(byPercentageWornInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: "Tires In Service By Percentage Worn",
        rowsData: byPercentageWornInfo.rowsData,
        headCells: byPercentageWornInfo.headCells,
        avgValues: byPercentageWornDataSetter.avgValues(byPercentageWornInfo.rowsData),
        selectedColShow: byPercentageWornInfo.selectedColShow
      });
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = byPercentageWornInfo.rowsData;
        let filteredData = byPercentageWornDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setByPercentageWornData({
          ...byPercentageWornInfo,
          rowsData: filteredData,
          avgValues: byPercentageWornDataSetter.avgValues(filteredData)
        });
        setOriginalByPercentageWornData({
          ...byPercentageWornInfo,
          rowsData: filteredData,
          avgValues: byPercentageWornDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: byPercentageWornDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });

        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
      }
      setShowSpinner(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    let tableDataStore, filteredData;

    if (showColumns?.AverageHours) {
      tableDataStore = originalByPercentageWornData?.rowsData.map(({ AverageMiles, TotalAverageMiles, ...rest }) => {
        return rest;
      });
    } else {
      tableDataStore = originalByPercentageWornData?.rowsData.map(({ AverageHours, TotalAverageHours, ...rest }) => {
        return rest;
      });
    }

    filteredData = byPercentageWornDataSetter.search(tableDataStore, searchText);
    setByPercentageWornData({
      ...byPercentageWornData,
      rowsData: filteredData,
      avgValues: byPercentageWornDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byPercentageWornDataSetter.avgValues(filteredData),
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalByPercentageWornData?.headCells;
    let showColumnsUpdated = byPercentageWornDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    let originalTableDataStore = originalByPercentageWornData?.rowsData;
    let filteredData = byPercentageWornDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setByPercentageWornData({
      ...byPercentageWornData,
      rowsData: filteredData,
      avgValues: byPercentageWornDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byPercentageWornDataSetter.avgValues(filteredData)
    });
    setOnlyFilterApplied(true);
    filteredData.length === 0 ? setNoData(true) : setNoData(false);

    setToggleHM("Hours");
    actionChanged("Hours");
    setFilterFieldsApplied(appliedFilter);
    ByPercentageWornResponseData(excludeUsedTires, true, appliedFilter);
  };


  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });
    setFilterApplied(false);
    setByPercentageWornData(tableBeforeFilter);
    setOriginalByPercentageWornData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    let tempFilterFieldsApllied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApllied).forEach((index) => {
      tempFilterFieldsApllied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApllied);
    setOnlyFilterApplied(false);
    setNoData(false);
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    setToggleHM("Hours");
    actionChanged("Hours");
    ByPercentageWornResponseData(event.target.checked, filterApplied, filterFieldsApplied);
  };
  const actionChanged = (action) => {
    let avoidArr = [];
    setToggleHM(action);
    if (action === "Miles") {
      avoidArr = ["AverageHours"];
    } else {
      avoidArr = ["AverageMiles"];
    }
    let byTireTypeInfo = byPercentageWornData;

    byTireTypeInfo.headCells.map((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      byTireTypeInfo.selectedColShow[item.value] = flag;
    });

    setByPercentageWornData(byTireTypeInfo);

    byTireTypeInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);

    let updatedHeadCells = byTireTypeInfo.headCells.filter((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });

    setDisplayColOptions(updatedHeadCells);
    setDisplayColumns(updatedHeadCells);
    setShowColumns(byTireTypeInfo.selectedColShow);
    setPrintData({
      reportType: "",
      reportName: "Tires In Service By Percentage Worn",
      rowsData: byTireTypeInfo.rowsData,
      headCells: byTireTypeInfo.headCells,
      avgValues: byPercentageWornDataSetter.avgValues(byTireTypeInfo.rowsData),
      selectedColShow: byTireTypeInfo.selectedColShow
    });
    setShowSpinner(false);
  };

  return (
    <div>
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-tire-type-container">
          <Card className="by-tire-type-card">
            <CardContent className="graph-card">
              <ByPercentageWornGraph byPercentageWornGraphData={byPercentageWornData?.rowsData} />
            </CardContent>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="display-columns-container">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      value={toggleHM}
                      onChange={(e) => actionChanged(e.target.value)}>
                      <option value={"Hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                      <option value={"Miles"}>{toggleMile}</option>
                    </Select>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="by-tire-type-table">
                <ByPercentageWornTable tableData={byPercentageWornData} showColumns={showColumns} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />

      <ByPercentageWornFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default ByPercentageWorn;
