import React, { useEffect, useState } from "react";
import "./TireDetails.css";
import { Grid } from '@material-ui/core';
import Globalization from '../../../../../../../globalization';
import Spinner from "../../../../../../../common-components/spinner/spinner";

const TireDetails = ({ eachTireData }) => {
    const [eachTireDataDetails, setEachTireDataDetails] = useState(eachTireData);
    const [showSpinner, setShowSpinner] = useState(true);
    const globalizationMapping = new Globalization()

    useEffect(() => {
        setEachTireDataDetails(eachTireData);
        setShowSpinner(false);
    }, [eachTireData]);
    return (
        <div className="removed-tire-detail-container">
            {showSpinner && <Spinner />}
            {!showSpinner && (
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <div className="heading-container heading-text">
                            {globalizationMapping.REPORTCOMMONWORDS.TireDetails}
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.SEARCHINSTALLTIRE.TIRESERIALNO}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.SerialNo}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTTABLE.MANUFACTURER}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.ManufacturerName}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.FLEETSURVEY.SIZE}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.SizeName}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTTABLE.TYPE}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.TypeName}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTTABLE.COMPOUND}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.CompoundName}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTTABLE.RATING}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.RatingName}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.MANAGERIM.HOURS}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.currentHours}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTCOMMONWORDS.DISTANCE}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.currentMiles}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTCOMMONWORDS.percWorn}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.PercentageWorn}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTCOMMONWORDS.TreadOrg}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.TreadDepth}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTTABLE.COSTPERHOUR}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.CostPerHour}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTTABLE.VehicleName}

                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.VehId}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTTABLE.ASSETNUMBER}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.AssetNumber}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">
                                {globalizationMapping.REPORTCOMMONWORDS.MakeModel}
                            </div>
                            <div className="tire-header-text">
                                {eachTireDataDetails.VehicleSpec}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">{globalizationMapping.REPORTTABLE.WheelPosition}
                            </div>
                            <div className="tire-header-text">{eachTireDataDetails.WheelPosition}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">{globalizationMapping.REPORTCOMMONWORDS.RemovalDate}
                            </div>
                            <div className="tire-header-text">{eachTireDataDetails.RemovalDate}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">{globalizationMapping.REPORTTABLE.REMOVALREASON}
                            </div>
                            <div className="tire-header-text">{eachTireDataDetails.RemovalReason}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">{globalizationMapping.REPORTTABLE.MONEYLOST}
                            </div>
                            <div className="tire-header-text">{eachTireDataDetails.MoneyLost}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <div className="parent-account-container">
                            <div className="tire-info-text">{globalizationMapping.REPORTTABLE.COMMENTS}
                            </div>
                            <div className="tire-header-text wrap-container">{eachTireDataDetails.Comments}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )
            }
        </div >
    );
};

export default TireDetails;