import { SERVER } from "../../../services/api-urls";
import CommonService from "../../../services/common-api-service";


export default class InspectTireApis {
    cs;
    constructor() {
        this.cs = new CommonService();
    }
    sendTireInspectionData = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.sendTireInspectionData}`,'put',req);
    };
    createTireInspectionImages = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.createTireInspectionImages}`,'post',req);
    };
    sendInspectionData = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.sendInspectionData}`,'put',req);
    };
}

