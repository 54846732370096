import React, { useEffect, useState, useContext } from "react";
import OtherTiresNavigation from "../../../other-tires-navigation/OtherTiresNavigation";
import "./NewVSScrap.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputLabel } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import NewVSScrapSetter from "../../../../../services/NewVSScrapUtils";
import NewVSScrapTable from "../table/NewVSScrapTable";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import PrintEmailExportSetButtons from "../../../../../../../common-components/filterPrintEmailExport-SetButtons/PrintEmailExportSetButton";

const NewVSScrap = () => {
  // GLOBAL ACCOUNT STATE management
  const tiresService = new TiresService();
  const tireRegisterDataSetter = new NewVSScrapSetter();
  const commonUtils = new CommonUtils();
  const globalizationMapping = new Globalization();

  const [globalAccountState] = useContext(StateContext);
  const [tireRegisterTableData, setTireRegisterTableData] = useState();
  const [showColumns, setShowColumns] = useState();
  const [tireSizesOpt, setTireSizesOpt] = useState();
  const [tireSizes, setTireSizes] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [printData, setPrintData] = useState(tireRegisterTableData);
  const [noData, setNoData] = useState(false);
  const [startDate, setStartDate] = useState();
  const [toDate, setToDate] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // filter options driver

  const GetTireSizes = () => {
    setShowSpinner(true);

    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let req = {
      ParentAccountIds: accountInfoObject.accountIds[0]
    };

    const tireSizeChecker = (a, b) => {
      if (a.tireSizeName < b.tireSizeName) {
        return 1;
      } else {
        if (a.tireSizeName === b.tireSizeName) {
          return 0;
        } else {
          return -1;
        }
      }
    };

    tiresService.getDistinctTireSizeForNewVsScrap(req).then((data) => {
      let size = [];
      data.data.sort((a, b) => {
        return tireSizeChecker(a, b);
      });

      data.data.forEach((obj, index) => {
        size.push({
          key: obj.tireSizeName,
          keyIndex: index,
          value: obj.tireSizeName,
          label: obj.tireSizeName
        });
      });

      let first = [];
      if (size.length) first = [size[0]];
      setTireSizesOpt(size);
      setTireSizes(first);
      let yy = new Date().getFullYear();

      let mm = new Date().getMonth() + 1;
      mm = mm < 10 ? "0" + mm : "" + mm;
      let dd = new Date().getDate();
      dd = dd < 10 ? "0" + dd : "" + dd;
      let endDate = yy + "-" + mm + "-" + dd;
      let fromDate = new Date();
      fromDate.setMonth(fromDate.getMonth() - 3);
      let firstdate =
        fromDate.getFullYear() + "-" + ((fromDate.getMonth() + 1 < 10 ? "0" : "") + (fromDate.getMonth() + 1)) + "-" + (fromDate.getDate() < 10 ? "0" : "") + fromDate.getDate();

      setStartDate(firstdate);
      setToDate(endDate);
      TireRegisterResponseData(firstdate, endDate, first);
    });
  };
  const TireRegisterResponseData = (startFromDate, endToDate, tireSize) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;

    let tiresizeArr = [];
    tireSize.forEach((obj) => {
      if (obj) tiresizeArr.push(obj.value);
    });
    let requestObject = {
      parentAccount: accountIds[0],
      tireSize: tiresizeArr,
      fromDate: startFromDate,
      todate: endToDate
    };

    setShowSpinner(true);
    tiresService.getNewVsScrapReport(requestObject).then((data) => {
      let tireRegisterTableInfo = tireRegisterDataSetter.setData(data);
      tireRegisterTableInfo.rowsData.length === 0
      ? setNoData(true)
      : setNoData(false);
      setTireRegisterTableData(tireRegisterTableInfo);
      setShowColumns(tireRegisterTableInfo.selectedColShow);
      setPrintData({
        reportType: globalizationMapping.TIREREPORTS.OTHERTIRES.OtherTires + ": ",
        reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.NewVSScrap,
        rowsData: tireRegisterTableInfo.rowsData,
        headCells: tireRegisterTableInfo.headCells,
        selectedColShow: tireRegisterTableInfo.selectedColShow
      });
      setShowSpinner(false);
    });
  };

  useEffect(() => {
    GetTireSizes();
  }, [globalAccountState]);

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeTireSizes = (selected) => {
    setTireSizes(selected);
  };

  const handleFromStartDate = (event) => {
    setStartDate(event.startDate);
  };
  const handleToStartDate = (event) => {
    setToDate(event.toDate);
  };
  const onClickUpdate = () => {
    if (startDate !== "" && toDate !== "" && new Date(toDate).getTime() - new Date(startDate).getTime() >= 0) {
      TireRegisterResponseData(startDate, toDate, tireSizes);
    }
  };

  return (
    <div>
      <div className="container">
        <OtherTiresNavigation />
        {showSpinner && <Spinner />}
        {!showSpinner && (
          <div className="tire-register-container">
            <Card className="tire-register-card">
              <CardContent>
                <div className="table-toolbar">
                  <div className="table-filters">
                    <div className="display-columns-container" id="dashboard-display-column">
                      <div className="tire-scrap-type">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayTireSize}</span>
                        </InputLabel>
                        <MultiSelect
                          options={tireSizesOpt}
                          value={tireSizes}
                          onChange={changeTireSizes}
                          overrideStrings={{
                            selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                            allItemsAreSelected: "All Tire Sizes",
                            selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                            search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                          }}
                        />
                      </div>
                    </div>

                    <div className="display-columns-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.FromDate}</span>
                      </InputLabel>
                      <TextField
                        id="date"
                        type="date"
                        style={{ width: "100%" }}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={startDate}
                        onChange={(event) => handleFromStartDate({ startDate: event.target.value })}
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>
                    <div className="display-columns-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ToDate}</span>
                      </InputLabel>
                      <TextField
                        id="date"
                        type="date"
                        style={{ width: "100%" }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleToStartDate({ toDate: event.target.value })}
                        value={toDate}
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>
                    <div className="display-columns-container">
                      <button className="update-button" onClick={onClickUpdate}>
                        <span className="Update-text-style">{globalizationMapping.INSPECT.UPDATE}</span>
                      </button>
                    </div>
                  </div>
                  <PrintEmailExportSetButtons
                      onClickEmail={onClickEmail}
                      onClickPrint={onClickPrint}
                      onClickExport={onClickExport}
                      toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)}
                      ref={ref}
                      noData={noData}
                      isComponentVisible={isComponentVisible}
                    />
                </div>

                <div className="tire-register-table">
                  <NewVSScrapTable tableData={tireRegisterTableData} showColumns={showColumns} />
                </div>
              </CardContent>
            </Card>
          </div>
        )}
        <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
      </div>
    </div>
  );
};

export default NewVSScrap;
