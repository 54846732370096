/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import Breadcrumbs from "../../common-components/breadcrumbs/breadcrumbs";
import Globalization from "../../globalization";
import "./multi-admin-list.css";
import MultiSelect from "react-multi-select-component";
import HeaderApi from "../../common-components/header/services/header-apis";
import Spinner from "../../common-components/spinner/spinner";
import { Grid, Select, FormHelperText, Divider } from "@material-ui/core";
import { StateContext } from "../../services/StateProvider";
import TiresService from "../tires-reports/services/TiresApis";
import ByCompoundDataSetter from "../tires-reports/services/ByCompoundUtils";
import ManageTireListSetter from "../manage-tire/services/ManageTireListUtils";
import ExportCsv from "../../common-components/modal-components/export-types/ExportCsv";
import ExpandMoreStyledIcon from "../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";

const MultiReportList = () => {
  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const headerApi = new HeaderApi();
  const tiresService = new TiresService();

  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME
    },
    {
      path: "",
      name: globalizationMapping.MULTIREPORTS.TITLE
    }
  ];

  const [parentAccountList, setParentAccountList] = useState([]);
  const [originalParentAccountList, setOriginalParentAccountList] = useState([]);
  const [globalAccountState] = useContext(StateContext);
  const [parentAccount, setParentAccount] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [reportSelected, setReportSelected] = useState(-1);
  const [report, setReport] = useState(-1);
  const [location, setLocation] = useState([]);
  const [multiSelectLocationList, setMultiSelectLocationList] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [errors, setErrors] = useState({
    accounts: "",
    locations: ""
  });
  const [countrySelected, setCountrySelected] = useState(-1);
  const [marketSegmentId, setMarketSegmentId] = useState(-1);
  const [applicationTypeId, setApplicationTypeId] = useState(-1);
  const [material, setMaterial] = useState(-1);
  const [countryList, setCountryList] = useState([]);
  const [appTypesByMarketSegment, setAppTypesByMarketSegment] = useState([]);
  const [materialByMarketSegment, setMaterialByMarketSegment] = useState([]);
  const [manufSelected, setManufSelected] = useState([]);
  const [sizeSelected, setSizeSelected] = useState([]);
  const [typeSelected, setTypeSelected] = useState([]);
  const [manufList, setManufList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [masterData, setMasterData] = useState(null);
  const [xlsData, setXlsData] = useState({
    isReady: false,
    reportType: "",
    reportName: "",
    rowsData: [],
    headCells: [],
    avgValues: [],
    selectedColShow: []
  });

  const reportList = require("../../constants/ReportsList");

  const compareStrings = (a, b) => {
    if (a && b) {
      // Assuming you want case-insensitive comparison
      a = a.trim();
      b = b.trim();
      a = a.toLowerCase();
      b = b.toLowerCase();

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const [reqFilter, setReqFilter] = useState({
    countryIds: [],
    marketSegmentIds: [],
    ApplicationTypeIds: [],
    MaterialIds: []
  });
  const getParentAccountListData = () => {
    headerApi.getCountryList().then((data) => {
      let model = data.model;
      model.sort(function (a, b) {
        return compareStrings(a.countryName, b.countryName);
      });
      setCountryList(model);
    });

    headerApi.getMasterDataWeb().then((data) => {
      if (data.model !== undefined) {
        setMasterData(data.model);
        let list = [];
        data.model.tireManufacturers.sort(function (a, b) {
          return compareStrings(a.manufacturerName, b.manufacturerName);
        });
        for (let item of data.model.tireManufacturers) {
          if (item.activeFlag)
            list.push({
              label: item.manufacturerName.trim(),
              value: item.manufacturerId
            });
        }
        setManufList(list);
      }
    });

    headerApi.getParentAccountList().then((data) => {
      let parentAccountListObject = [];
      let model = data.model;
      model.sort(function (a, b) {
        return compareStrings(a.accountName, b.accountName);
      });
      for (let item of model) {
        parentAccountListObject.push({
          label: item.accountName,
          createdBy: item.createdBy,
          value: item.parentAccountId
        });
      }

      setParentAccountList(parentAccountListObject);
      setOriginalParentAccountList(parentAccountListObject);
    });
  };

  const getLocationListData = () => {
    const locationListObject = [];
    headerApi.getLocationList().then((data) => {
      for (let item of data.model) {
        locationListObject.push({
          locationId: item.locationId,
          locationName: item.locationName,
          parentAccountId: item.parentAccountId,
          createdBy: item.createdBy
        });
      }
      setLocationList(locationListObject);
      setSpinner(false);
    });
  };

  useEffect(() => {
    getParentAccountListData();
    getLocationListData();
  }, [globalAccountState]);

  // update location based on parent account
  const updateLocationList = (accountList) => {
    setLocation([]);
    let updatedMultiSelectLocationObject = [];
    accountList.map((item) => {
      let selectedParentAccountId = item.value;
      for (let item of locationList) {
        if (item.parentAccountId === selectedParentAccountId) {
          updatedMultiSelectLocationObject.push({
            value: item.locationId,
            label: item.locationName
          });
        }
      }
    });
    return updatedMultiSelectLocationObject;
  };
  const onReset = () => {
    setParentAccount([]);
    setLocation([]);
    setReportSelected(-1);
    setReport(-1);
    setCountrySelected(-1);
    setMarketSegmentId(-1);
    setApplicationTypeId(-1);
    setMaterial(-1);
    setParentAccountList(originalParentAccountList);
    setMultiSelectLocationList([]);

    setErrors({
      accounts: "",
      locations: ""
    });
    setXlsData({
      isReady: false,
      reportType: "",
      reportName: "",
      rowsData: [],
      headCells: [],
      avgValues: [],
      selectedColShow: []
    });
  };
  const handleAccountChange = (event) => {
    setParentAccount(event);
    let updatedMultiSelectLocationObject = updateLocationList(event);
    setMultiSelectLocationList(updatedMultiSelectLocationObject);
    setLocation([]);
    setXlsData({
      isReady: false,
      reportType: "",
      reportName: "",
      rowsData: [],
      headCells: [],
      avgValues: [],
      selectedColShow: []
    });
  };
  const handleReportChange = (event, _selected) => {
    if (event.target.value !== -1) {
      let index = reportList.data.findIndex((item) => item.value === event.target.value);
      setReportSelected(event.target.value);
      setReport(reportList.data[index]);
    }
  };
  const changeLocationDetails = (key, value) => {
    // value is a string
    let req = reqFilter;
    setParentAccount([]);

    switch (key) {
      case "country":
        setCountrySelected(value);
        if (value === -1 || value === '-1') req.countryIds = [];
        else req.countryIds = [value];
        break;
      case "marketsegment":
        let appTypes = [];
        let materialBySegment = [];
        // eslint-disable-next-line array-callback-return
        masterData.applicationTypes.map((appType) => {
          if (parseInt(appType.marketSegmentId) === parseInt(value)) appTypes.push(appType);
        });
        // eslint-disable-next-line array-callback-return
        masterData.materials.map((material) => {
          if (parseInt(material.marketSegmentId) === parseInt(value)) materialBySegment.push(material);
        });
        setAppTypesByMarketSegment(appTypes);
        setMaterialByMarketSegment(materialBySegment);
        setMarketSegmentId(value);
        req.marketSegmentIds = [value];
        req.ApplicationTypeIds = [];
        req.MaterialIds = [];
        setApplicationTypeId(-1);
        setMaterial(-1);
        if (value === -1 || value === '-1') req.marketSegmentIds = [];
        break;
      case "applicationtype":
        setApplicationTypeId(value);
        setMaterial(-1);
        req.ApplicationTypeIds = [value];
        req.MaterialIds = [];
        if (value === -1 || value === '-1') req.ApplicationTypeIds = [];
        break;
      case "material":
        setMaterial(value);
        req.MaterialIds = [value];
        if (value === -1 || value === '-1') req.MaterialIds = [];
        break;
      default:
        break;
    }
    setReqFilter(req);
    tiresService.filterAccounts(req).then((data) => {
      let parentAccountListObject = [];
      let model = data.data;
      model.sort(function (a, b) {
        return compareStrings(a.accountName, b.accountName);
      });
      for (let item of model) {
        parentAccountListObject.push({
          label: item.accountName,
          createdBy: item.createdBy,
          value: item.parentAccountId
        });
      }
      if (!parentAccountListObject.length) setErrors({ ...errors, accounts: "No account found" });
      else setErrors({ ...errors, accounts: "" });
      setParentAccountList(parentAccountListObject);

    });

  };
  const byCompoundDataSetter = new ByCompoundDataSetter();
  const manageTireListDataSetter = new ManageTireListSetter();

  const additionFilter = (from, value) => {
    switch (from) {
      case "manuf":
        setManufSelected(value);
        let sList = [];
        value.map((parentAccountItem) => {
          masterData.tireSizes.map((item) => {
            if (parentAccountItem.value === item.tireManufacturerId)
              sList.push({
                label: item.tireSizeName.trim(),
                value: item.tireSizeId,
                tireManufacturerId: item.tireManufacturerId
              });
          });
        });
        sList.sort((n, p) => {
          if (n.label < p.label) {
            return -1;
          } else if (n.label > p.label) {
            return 1;
          } else {
            return 0;
          }
        });

        setSizeSelected([]);
        const unique = [...new Map(sList.map((size) => [size.label, size])).values()];
        setSizeList(unique);
        break;
      case "size":
        setSizeSelected(value);
        let tList = [];
        value.map((sizeItem) => {
          masterData.tireTypes.map((item) => {
            if (sizeItem.tireManufacturerId === item.tireManufacturerId && sizeItem.value === item.tireSizeId)
              tList.push({
                label: item.typeName.trim(),
                value: item.typeId
              });
          });
        });
        tList.sort((n, p) => {
          if (n.label < p.label) {
            return -1;
          } else if (n.label > p.label) {
            return 1;
          } else {
            return 0;
          }
        });

        setTypeSelected([]);
        setTypeList(tList);
        break;
      case "type":
        setTypeSelected(value);
        break;
      default:
        break;
    }
  };

  const apiCall = () => {
    setSpinner(true);
    let accountIds = [];
    parentAccount.forEach((item) => accountIds.push(item.value));
    let locationIds = [];
    location.forEach((item) => locationIds.push(item.value));
    let manufIds = [];
    manufSelected.forEach((item) => manufIds.push(item.value));
    let typeIds = [];
    typeSelected.forEach((item) => typeIds.push(item.value));
    let sizeIds = [];
    sizeSelected.forEach((item) => sizeIds.push(item.value));

    let xls = [];
    switch (report.value) {
      case "Scrap_Tire_By_Compound":
        let requestObject1 = byCompoundDataSetter.multiAccountCall(report.value, accountIds, locationIds);
        tiresService.getTiresReport(requestObject1).then((data) => {
          let byCompoundTableInfo = byCompoundDataSetter.setData(data);
          Object.keys(byCompoundTableInfo.selectedColShow).forEach((item) => (byCompoundTableInfo.selectedColShow[item] = true));
          xls = {
            isReady: true,
            reportType: "",
            reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByCompound,
            rowsData: byCompoundTableInfo.rowsData,
            headCells: byCompoundTableInfo.headCells,
            avgValues: byCompoundTableInfo.avgValues,
            selectedColShow: byCompoundTableInfo.selectedColShow
          };
          setParentAccount([]);
          setLocation([]);
          setReportSelected(-1);
          setReport(-1);
          setXlsData(xls);
          setSpinner(false);
        });
        break;
      case "All_Tire_Summary_Report":
        let unitPreference = globalAccountState.accountInfo.userSetting.userMeasurementSystemValue;
        accountIds = originalParentAccountList.length === accountIds.length ? [] : accountIds;
        locationIds = locationList.length === locationIds.length ? [] : locationIds;
        let requestObject2 = {
          accountId: accountIds,
          locationId: locationIds,
          TireManufacturerId: manufIds,
          TireSizeId: sizeIds,
          TireTypeId: typeIds
        };
        tiresService.getTiresByAccountList(requestObject2).then((data) => {
          let manageTireListTableInfo = manageTireListDataSetter.setData(data, unitPreference);

          let manageTireInitialTableInfo = manageTireListDataSetter.setFilteredData(data.model, null, unitPreference);
          let manageTireRowsDataByLocation = manageTireListDataSetter.getFilteredDataByLocation(data.model, locationIds, null);
          let tireDataToShow = { ...manageTireInitialTableInfo };
          tireDataToShow.rowsData = [...manageTireRowsDataByLocation];

          Object.keys(manageTireListTableInfo.selectedColShow).forEach((item) => (manageTireListTableInfo.selectedColShow[item] = true));

          setParentAccount([]);
          setLocation([]);
          setReportSelected(-1);
          setReport(-1);
          setManufSelected([]);
          setSizeSelected([]);
          setTypeSelected([]);
          setCountrySelected(-1);
          setMarketSegmentId(-1);
          setApplicationTypeId(-1);
          setXlsData({
            isReady: true,
            reportType: "",
            reportName: globalizationMapping.MANAGETIREREPORTS.ManageTireAllTyre,
            rowsData: tireDataToShow.rowsData,
            headCells: manageTireInitialTableInfo.headCells,
            selectedColShow: manageTireInitialTableInfo.selectedColShow
          });
          setSpinner(false);
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="standalone-new-header-container">
      <div className="edit-parent-account-main-page-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title"> {globalizationMapping.MULTIREPORTS.TITLE}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {spinner ? (
        <Spinner />
      ) : (
        <div className="manage-vehicle-list-container">
          <div className="edit-parent-container" id="height-css">
            <div className="change-modal-select-container">
              <div className="create-location-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={3} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label">
                      {globalizationMapping.CHANGEMODEL.SELECTCOUNTRY}
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={countrySelected}
                      variant="outlined"
                      type="number"
                      onChange={(e) => {
                        changeLocationDetails("country", e.target.value);
                      }}
                      inputProps={{
                        name: "country",
                        id: "segment-select-drop-down"
                      }}
                      defaultValue={-1}
                    >
                      <option value={-1}>
                        {globalizationMapping.HEADER.ALL}
                      </option>
                      {countryList &&
                        countryList.length > 0 &&
                        countryList.map((country, key) => (
                          <option key={key + 1} value={country.countryId}>
                            {country.countryName}
                          </option>
                        ))}
                    </Select>

                  </Grid>

                  <Grid item xs={3} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label">
                      {globalHeaderTexts.MARKETSEGMENT}
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={marketSegmentId}
                      variant="outlined"
                      type="number"
                      onChange={(e) => {
                        changeLocationDetails("marketsegment", e.target.value);
                      }}
                      inputProps={{
                        name: "marketsegment",
                        id: "segment-select-drop-down"
                      }}
                      defaultValue={-1}
                    >
                      <option value={-1}>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>
                      {masterData &&
                        masterData.marketSegments !== undefined &&
                        masterData.marketSegments.map((segment) => (
                          <option key={segment.marketSegmentId} value={segment.marketSegmentId}>
                            {segment.marketSegmentName}
                          </option>
                        ))}
                    </Select>
                  </Grid>

                  <Grid item xs={3} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label">
                      {globalHeaderTexts.APPLICATIONTYPE}
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={applicationTypeId}
                      variant="outlined"
                      type="number"
                      disabled={marketSegmentId === -1}
                      onChange={(e) => {
                        changeLocationDetails("applicationtype", e.target.value);
                      }}
                      inputProps={{
                        name: "apptype",
                        id: "apptype-select-drop-down"
                      }}
                      defaultValue={-1}
                    >
                      <option value={-1}>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>
                      {appTypesByMarketSegment !== undefined &&
                        appTypesByMarketSegment.map((type) => (
                          <option key={type.applicationTypeId} value={type.applicationTypeId}>
                            {type.applicationTypeName}
                          </option>
                        ))}
                    </Select>
                  </Grid>

                  <Grid item xs={3} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label">
                      {globalHeaderTexts.MATERIAL}
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={material}
                      variant="outlined"
                      type="number"
                      disabled={applicationTypeId === -1}
                      onChange={(e) => {
                        changeLocationDetails("material", e.target.value);
                      }}
                      inputProps={{
                        name: "material",
                        id: "material-select-drop-down"
                      }}
                      defaultValue={-1}
                    >
                      <option value={-1}>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>
                      {materialByMarketSegment !== undefined &&
                        materialByMarketSegment.map((mat) => (
                          <option key={mat.material} value={mat.material}>
                            {mat.materialName}
                          </option>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </div>
              <div className="create-location-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={4} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label">
                      {globalizationMapping.ADMIN.SelectParent} <span className="required-mark">*</span>
                    </p>
                    <MultiSelect
                      options={parentAccountList}
                      value={parentAccount}
                      onChange={(event) => {
                        handleAccountChange(event);
                      }}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"]
                      }}
                    />
                    <FormHelperText>{errors.accounts}</FormHelperText>
                  </Grid>

                  <Grid item xs={4} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label">
                      {globalizationMapping.CHANGEMODEL.LOCATION} <span className="required-mark">*</span>
                    </p>
                    <MultiSelect
                      options={multiSelectLocationList}
                      value={location}
                      onChange={setLocation}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"]
                      }}
                    />
                    <FormHelperText>{errors.locations}</FormHelperText>
                  </Grid>

                  <Grid item xs={4} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label">
                      {globalizationMapping.MULTIREPORTS.REPORTS} <span className="required-mark">*</span>
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={reportSelected}
                      variant="outlined"
                      // type=""
                      onChange={(event) => handleReportChange(event)}
                      inputProps={{
                        name: "report",
                        id: "material-select-drop-down"
                      }}
                      style={{ height: "58px", width: "100%" }}
                    >
                      <option value={-1}>{globalizationMapping.REPORTCOMMONWORDS.SELECT}</option>
                      {reportList.data !== undefined &&
                        reportList.data.map((reportObj) => (
                          <option key={reportObj.reportName} value={reportObj.value}>
                            {reportObj.reportName}
                          </option>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label"> {globalizationMapping.ADMIN.TireManufacturerT} </p>
                    <MultiSelect
                      options={manufList}
                      value={manufSelected}
                      onChange={(e) => additionFilter("manuf", e)}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"]
                      }}
                    />
                    <FormHelperText>{errors.locations}</FormHelperText>
                  </Grid>
                  <Grid item xs={4} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label">{globalizationMapping.ADMIN.TireSizeT} </p>
                    <MultiSelect
                      options={sizeList}
                      value={sizeSelected}
                      onChange={(e) => additionFilter("size", e)}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"]
                      }}
                    />
                    <FormHelperText>{errors.locations}</FormHelperText>
                  </Grid>
                  <Grid item xs={4} className="multi-select-dropdown change-modal-select-container" id="header-change-modal-multi-select">
                    <p className="field-label">{globalizationMapping.ADMIN.TireTypeT}  </p>
                    <MultiSelect
                      options={typeList}
                      value={typeSelected}
                      onChange={(e) => additionFilter("type", e)}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.SELECTEDALL,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS["Search"]
                      }}
                    />
                    <FormHelperText>{errors.locations}</FormHelperText>
                  </Grid>
                </Grid>
              </div>
              <div
                className=""
                style={{
                  margin: "3% 0%",
                  justifyContent: "end",
                  display: "flex"
                }}
              >
                <button
                  disabled={location.length <= 0 || !report.value}
                  className={location.length <= 0 || !report.value ? "header-change-modal-confirm-btn header-change-modal-confirm-btn-disabled" : "header-change-modal-confirm-btn"}
                  onClick={() => apiCall()}
                >
                  {globalizationMapping.MULTIREPORTS.Generate}
                </button>
                <button className={"header-change-modal-confirm-btn"} onClick={onReset}>
                  {globalizationMapping.MULTIREPORTS.Reset}
                </button>
                <Divider orientation="vertical" flexItem style={{ marginLeft: 15, marginRight: 15 }} />
                <ExportCsv multireport={true} exportCsv={xlsData} />
              </div>
              <div
                className=""
                style={{
                  margin: "0px 0%",
                  justifyContent: "center",
                  display: "flex"
                }}
              >
                {xlsData.isReady && <p className="field-label">{globalizationMapping.MULTIREPORTS.DownloadMsg}</p>}
                {parentAccount.length > 10 && <p className="field-label"> </p>}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MultiReportList;
