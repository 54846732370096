import { FormHelperText, Grid, makeStyles, Select, TextField, InputAdornment, OutlinedInput } from "@material-ui/core";
import React from "react";
import "../create-vehicle/create-vehicle.css";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import CalendarMonthIcon from '@material-ui/icons/Today';
import CommonUtils from "../../services/utils";
import ExpandMoreStyledIcon from "../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";

const Styles = makeStyles({
  root: {
    "& input.MuiAutocomplete-inputFocused": {
      color: "#000000 !important",
      fontWeight: "bold"
    }
  },
  input: {
    display: "none"
  },
  FormHelperText: {
    align: "center"
  },
  deleteBadge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "10px",
      right: "17px",
      background: "transparent"
    }
  },
  dorn: {
    "& .MuiTypography-colorTextSecondary": {
      color: "#000000",
      fontWeight: "800"
    }
  }
});
const Description = (props) => {
  const classes = Styles();
  const manufacturersList = props.masterDataset.rimManufacturers.sort((a, b) => {
    return compareStrings(a.manufacturerName, b.manufacturerName);
  });
  const rimSizes = props.masterDataset.rimSizes;
  const rimTypes = props.masterDataset.rimTypes;
  const valveData = props.masterDataset.rimValves.sort((a, b) => {
    return compareStrings(a.valveName, b.valveName);
  });
  const handleDateChange = (val) => {
    props.onChangeListener("nextCheck", val);
  };
  const commonUtils = new CommonUtils();

  function compareStrings(a, b) {
    if (a && b) {
      a = a.toLowerCase();
      b = b.toLowerCase();
      if (a < b) {
        return -1;
      } else {
        return a > b ? 1 : 0;
      }
    }
    return 0;
  }


  const removeDuplicates = (arr) => {
    return arr.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.typeName === value.typeName
      )))
  }
  let nonDuplicateRimTypeName = removeDuplicates(rimTypes);


  return (
    <Grid className="step-form-inner-container" container spacing={2}>
      <Grid item sm={12}>
        <p className="field-label">
          {props.fieldNames.MANUFACTURER} <span className="required-mark">*</span>
        </p>
        <Select
          required
          native
          IconComponent={ExpandMoreStyledIcon}
          autoFocus={props.formHelperTexts.manufacturerId !== undefined}
          className="enter-detail-input-box"
          variant="outlined"
          value={props.rimModal.manufacturerId}
          onChange={(e) => {
            props.onChangeListener("manufacturerId", e.target.value);
          }}
        >
          <option key={0} value={""} hidden>
            {" "}
            {` ${props.fieldNames.SELECT} ${props.fieldNames.MANUFACTURER}`}{" "}
          </option>
          {manufacturersList.map((mfcr) => (
            <option key={mfcr.manufacturerId} value={mfcr.manufacturerId} className="list-color-change">
              {mfcr.manufacturerName}
            </option>
          ))}
        </Select>
        {(props.rimModal.manufacturerId === 0 && props.isManufacturerEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
        {(props.isManufacturerEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.manufacturerId}</FormHelperText>)}
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">
          {props.fieldNames.SIZE} <span className="required-mark">*</span>
        </p>
        <Select
          required
          native
          IconComponent={ExpandMoreStyledIcon}
          className="enter-detail-input-box"
          variant="outlined"
          value={props.rimModal.sizeId}
          onChange={(e) => {
            props.onChangeListener("sizeId", e.target.value);
          }}
        >
          <option key={0} value={""} hidden>
            {" "}
            {` ${props.fieldNames.SELECT} ${props.fieldNames.SIZE}`}{" "}
          </option>
          {rimSizes.map((type) => (
            <option key={type.sizeId} value={type.sizeId} className="list-color-change">
              {type.sizeName}
            </option>
          ))}
        </Select>
        {(props.rimModal.sizeId === 0 && props.isSizeEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
        {(props.isSizeEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.sizeId}</FormHelperText>)}
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">
          {props.fieldNames.TYPE} <span className="required-mark">*</span>
        </p>
        <Select
          required
          native
          IconComponent={ExpandMoreStyledIcon}
          className="enter-detail-input-box"
          variant="outlined"
          value={props.rimModal.typeId}
          onChange={(e) => props.onChangeListener("typeId", e.target.value)}
        >
          <option key={0} value={""} hidden>
            {" "}
            {` ${props.fieldNames.SELECT} ${props.fieldNames.TYPE}`}{" "}
          </option>
          {nonDuplicateRimTypeName.map((type) => (
            <option key={type.typeId} value={type.typeId} className="list-color-change">
              {type.typeName}
            </option>
          ))}
        </Select>
        {(props.rimModal.typeId === 0 && props.isTypeEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
        {(props.isTypeEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.typeId}</FormHelperText>)}
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{props.fieldNames.EVLNUM}</p>
        <TextField
          className="enter-detail-input-box"
          variant="outlined"
          value={props.rimModal.evalNo}
          onChange={(e) => props.onChangeListener("evalNo", commonUtils.removeLeadingSpaces(e.target.value))}
          placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.EVLNUM}`}
          inputProps={{ maxLength: 50 }}
        />
        <FormHelperText>{props.formHelperTexts.evalNo}</FormHelperText>
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{props.fieldNames.VALTYPE}</p>
        <Select
          required
          native
          IconComponent={ExpandMoreStyledIcon}
          className="enter-detail-input-box"
          variant="outlined"
          value={props.rimModal.valveTypeId}
          onChange={(e) => {
            props.onChangeListener("valveTypeId", e.target.value);
          }}
        >
          {" "}
          <option key={0} value={""} hidden>
            {" "}
            {` ${props.fieldNames.SELECT} ${props.fieldNames.VALTYPE}`}{" "}
          </option>
          {valveData.map((mfcr) => (
            <option key={mfcr.valveId} value={mfcr.valveId} className="list-color-change">
              {mfcr.valveName}
            </option>
          ))}
        </Select>
        <FormHelperText>{props.formHelperTexts.valveTypeId}</FormHelperText>
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{props.fieldNames.MAXINFRAT}</p>
        <OutlinedInput
          type="number"
          className="enter-detail-input-box"
          variant="outlined"
          value={props.rimModal.maxInflationRating}
          onChange={(e) => props.onChangeListener("maxInflationRating", commonUtils.minmaxFloat(e.target.value, 0, 999999))}
          endAdornment={
            <InputAdornment className={classes.adorn} position="end">
              {props.userPreferenceData.userPressureUnit}
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{props.fieldNames.TORSPEC}</p>
        <TextField
          className="enter-detail-input-box"
          variant="outlined"
          value={props.rimModal.torqueSpec}
          onChange={(e) => props.onChangeListener("torqueSpec", commonUtils.removeLeadingSpaces(e.target.value))}
          placeholder={` ${props.fieldNames.ENTER} ${props.fieldNames.TORSPEC}`}
          inputProps={{ maxLength: 50 }}
        />
        <FormHelperText>{props.formHelperTexts.torqueSpec}</FormHelperText>
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{props.fieldNames.CHECKINV}</p>
        <OutlinedInput
          type="number"
          className="enter-detail-input-box"
          variant="outlined"
          value={props.rimModal.checkInterval}
          onChange={(e) => props.onChangeListener("checkInterval", commonUtils.minmaxInt(e.target.value, 0, 999999))}
          endAdornment={
            <InputAdornment className={classes.adorn} position="end">
              {props.fieldNames.DAYS}
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{props.fieldNames.RIMINSDATE}</p>
        <TextField
          className="enter-detail-input-box disabled-input"
          variant="outlined"
          value={props.rimModal.rimInstallDate ? commonUtils.formatISODate(props.rimModal.rimInstallDate) : "Install date not available"}
          disabled
        />
        <FormHelperText>{props.formHelperTexts.rimInstallDate}</FormHelperText>
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{props.fieldNames.LASTCHECKED}</p>
        <TextField
          className="enter-detail-input-box disabled-input"
          variant="outlined"
          value={props.rimModal.lastChecked ? commonUtils.formatISODate(props.rimModal.lastChecked) : "Last checked date not available"}
          disabled
        />
        <FormHelperText>{props.formHelperTexts.lastChecked}</FormHelperText>
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{props.fieldNames.INSPECTOR}</p>
        <TextField
          className="enter-detail-input-box disabled-input"
          variant="outlined"
          value={props.rimModal.inspector ? props.rimModal.inspector : "Inspector Name not available"}
          disabled
        />
        <FormHelperText>{props.formHelperTexts.inspector}</FormHelperText>
      </Grid>
      <Grid item sm={12}>
        <p className="field-label">{props.fieldNames.NEXTCHECK}</p>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disablePast={true}
            className="enter-detail-input-box"
            margin="normal"
            value={props.rimModal.nextCheck}
            onChange={handleDateChange}
            format={commonUtils.getDateFormat()}
            placeholder={commonUtils.getDateFormat()}
            keyboardIcon={<CalendarMonthIcon/>}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            minDateMessage="Date should not be before current date"
          />
        </MuiPickersUtilsProvider>
        <FormHelperText>{props.formHelperTexts.registrationDate}</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default Description;
