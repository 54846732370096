import { createContext } from "react";


export const filterContext = createContext({ holdFilters: null, setHoldFilters: () => { } })

export const filterAppliedBtn = createContext({ filtersAppliedValue: null, setFiltersAppliedValue: () => { } })







