import React, { useEffect, useState, useContext } from "react";
import OtherTiresNavigation from "../../../other-tires-navigation/OtherTiresNavigation";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import TiresService from "../../../../../services/TiresApis.js";
import TiresSentToRetreadDataSetter from "../../../../../services/TiresSentToRetreadUtils";
import TiresSentToRetreadTable from "../table/TiresSentToRetreadTable";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import TiresSentToRetreadFilter from "../filter/TiresSentToRetreadFilter.js";


const TiresSentToRetread = () => {
  const globalizationMapping = new Globalization();

  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const tiresSentToRetreadDataSetter = new TiresSentToRetreadDataSetter();
  const commonUtils = new CommonUtils();
  const [tiresSentToRetreadTableData, setTiresSentToRetreadTableData] = useState();
  const [originalTiresSentToRetreadTableData, setOriginalTiresSentToRetreadTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(tiresSentToRetreadTableData);
  const [noData, setNoData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [usePurchasedTread, setUsePurchasedTread] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [toggleHM, setToggleHM] = useState("hours");
  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "Compound" }
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      Compound: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const measurement = "Imperial - 32nd/in";

  const TiresSentToRetreadResponseData = (measurementValue, excludeUsedTiresValue, usePurchasedTreadValue, filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      locationIds: locationIds,
      reportName: "Tires_Sent_To_Retread",
      reportOption: "",
      toDate: dateRangeFilter.toDate,
      measurement: measurementValue,
      usedTires: excludeUsedTiresValue.toString(),
      usePurchasedTread: usePurchasedTreadValue.toString()
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let tiresSentToRetreadTableInfo = tiresSentToRetreadDataSetter.setData(data);
      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.MANUFACTURER,
            value: "ManufacturerName"
          },
          { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
          {
            name: globalizationMapping.REPORTTABLE.COMPOUND1,
            value: "Compound"
          }
        ],
        categoryData: {
          ManufacturerName: [],
          SizeName: [],
          TypeName: [],
          Compound: [],
          dateRangeFromDate: '',
          dateRangeToDate: '',
        }
      };
      let filterOptionsInfo = tiresSentToRetreadDataSetter.setFilterOptionsData(filterOptions, tiresSentToRetreadTableInfo.rowsData);
      setFilterOptions(filterOptionsInfo);
      tiresSentToRetreadTableInfo.headCells.forEach((element) => {
        if (element.value === "TreadDepth") {
          if (usePurchasedTreadValue) element.label = 'NS Cur/Pur (32")O, C, I/Pur';
          else element.label = "Tread Cur/Org (mm)";
        }
      });
      setTiresSentToRetreadTableData(tiresSentToRetreadTableInfo);
      setOriginalTiresSentToRetreadTableData(tiresSentToRetreadTableInfo);
      setTableBeforeFilter(tiresSentToRetreadTableInfo);
      let updatedHeadCells = tiresSentToRetreadDataSetter.updatedHeadCells();
      tiresSentToRetreadTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setDisplayColOptions(tiresSentToRetreadTableInfo.headCells);
      setDisplayColumns(updatedHeadCells);
      setShowColumns(tiresSentToRetreadTableInfo.selectedColShow);

      setPrintData({
        reportType: "Other Tires : ",
        reportName: "Tires_Sent_To_Retread",
        rowsData: tiresSentToRetreadTableInfo.rowsData,
        headCells: tiresSentToRetreadTableInfo.headCells,
        avgValues: tiresSentToRetreadTableInfo.avgValues,
        selectedColShow: tiresSentToRetreadTableInfo.selectedColShow,
        filterApplied: filterApplied
      });
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = tiresSentToRetreadTableInfo.rowsData;
        let filteredData = tiresSentToRetreadDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setTiresSentToRetreadTableData({
          ...tiresSentToRetreadTableInfo,
          rowsData: filteredData,
          avgValues: tiresSentToRetreadDataSetter.avgValues(filteredData)
        });
        setOriginalTiresSentToRetreadTableData({
          ...tiresSentToRetreadTableInfo,
          rowsData: filteredData,
          avgValues: tiresSentToRetreadDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: tiresSentToRetreadDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
      setShowSpinner(false);
    });
    // setTiresSentToRetreadTableData(originalTiresSentToRetreadTableData)
  };

  useEffect(() => {
    TiresSentToRetreadResponseData(measurement, excludeUsedTires, usePurchasedTread, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    // searchText === "" ? setFilterApplied(false) : setFilterApplied(true)
    let tableDataStore, filteredData;
    tableDataStore = originalTiresSentToRetreadTableData?.rowsData;
    filteredData = tiresSentToRetreadDataSetter.search(tableDataStore, searchText);
    setTiresSentToRetreadTableData({
      ...tiresSentToRetreadTableData,
      rowsData: filteredData,
      avgValues: tiresSentToRetreadDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      reportType: "Other Tires : ",
      reportName: "Tires_Sent_To_Retread",
      rowsData: filteredData,
      headCells: originalTiresSentToRetreadTableData?.headCells,
      avgValues: tiresSentToRetreadDataSetter.avgValues(filteredData),
      selectedColShow: originalTiresSentToRetreadTableData?.selectedColShow,
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalTiresSentToRetreadTableData?.headCells;
    let showColumnsUpdated = tiresSentToRetreadDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    setToggleHM("hours");
    actionChanged("hours");
    setSearchValue("");

    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}


    setFilterFieldsApplied(appliedFilter);

    let originalTableDataStore = originalTiresSentToRetreadTableData?.rowsData;
    let filteredData = tiresSentToRetreadDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setTiresSentToRetreadTableData({
      ...tiresSentToRetreadTableData,
      rowsData: filteredData,
      avgValues: tiresSentToRetreadDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: tiresSentToRetreadDataSetter.avgValues(filteredData)
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);
    TiresSentToRetreadResponseData(measurement, excludeUsedTires, usePurchasedTread, true, appliedFilter);
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      Compound: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    });

    setFilterApplied(false);
    setTiresSentToRetreadTableData(tableBeforeFilter);
    setOriginalTiresSentToRetreadTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApllied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApllied).forEach((index) => {
      tempFilterFieldsApllied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApllied);
    setOnlyFilterApplied(false);
  };


  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    setSearchValue("");
    setToggleHM("hours");
    actionChanged("hours");
    TiresSentToRetreadResponseData(measurement, event.target.checked, usePurchasedTread, filterApplied, filterFieldsApplied);
  };

  const handleUsedPurchasedTread = (event) => {
    setUsePurchasedTread(event.target.checked);
    setToggleHM("hours");
    actionChanged("hours");
    TiresSentToRetreadResponseData(measurement, excludeUsedTires, event.target.checked, filterApplied, filterFieldsApplied);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    return false;
  };
  const actionChanged = (value) => {
    let avoidArr = [];
    setToggleHM(value);
    if (value === "miles") {
      avoidArr = ["HoursAtRemoval"];
    } else if (value === "hours") {
      avoidArr = ["MilesAtRemoval"];
    } else {
      return;
    }

    let tireSentInfo = tiresSentToRetreadTableData;

    tireSentInfo.headCells.map((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      tireSentInfo.selectedColShow[item.value] = flag;
    });
    setTiresSentToRetreadTableData(tireSentInfo);
    tireSentInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
    let updatedHeadCells = tireSentInfo.headCells.filter((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });
    setDisplayColOptions(updatedHeadCells);
    setDisplayColumns(updatedHeadCells);
    setShowColumns(tireSentInfo.selectedColShow);
    setPrintData({
      reportType: "Other Tires : ",
      reportName: "Tires_Sent_To_Retread",
      rowsData: tireSentInfo.rowsData,
      headCells: tireSentInfo.headCells,
      avgValues: tiresSentToRetreadDataSetter.avgValues(tireSentInfo.rowsData),
      selectedColShow: tireSentInfo.selectedColShow
    });
    setShowSpinner(false);
  };
  return (
    <div>
      <div className="container">
        <OtherTiresNavigation />
        {showSpinner && <Spinner />}
        {!showSpinner && (
          <div className="tire-register-container">
            <Card className="tire-register-card">
              <CardContent>
                <div className="table-toolbar">
                  <div className="table-filters">
                    <div className="search-table-container">
                      <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                        </InputLabel>
                        <TextField
                          className="search-table-bar"
                          id="input-with-icon-textfield"
                          variant="outlined"
                          placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                          value={searchValue}
                          onChange={searchTableData}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                              </InputAdornment>
                            )
                          }}
                          inputProps={{ maxLength: 50 }}
                        />
                      </form>
                    </div>
                    <div className="display-columns-container" id="dashboard-display-column">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text"> {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                      </InputLabel>
                      <MultiSelect
                        options={displayColOptions}
                        value={displayColumns}
                        onChange={changeDisplayColumns}
                        overrideStrings={{
                          selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                          allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                          selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                          search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                        }}
                      />
                    </div>
                    <div className="tire-scrap-type">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                      </InputLabel>
                      <Select 
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="select-drop-down"
                        name="tire scrap type"
                        variant="outlined"
                        value={toggleHM} 
                        onChange={(e) => actionChanged(e.target.value)}>
                        <option value="hours">{globalizationMapping.MANAGEVEHICLE.HOURS} </option>
                        <option value="miles">{toggleMile} </option>
                      </Select>
                    </div>
                  </div>
                  <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
                </div>
                <Grid container>
                  <Grid item xs={3}>
                    <div className="exclude-used-tires">
                      <FormControlLabel
                        control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} color="primary" />}
                        label={globalizationMapping.GRAPH.ExcludeUsedTires}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="exclude-used-tires">
                      <FormControlLabel
                        control={<Checkbox checked={usePurchasedTread} onChange={handleUsedPurchasedTread} color="primary" />}
                        label={globalizationMapping.REPORTCOMMONWORDS.UsePurchasedTread}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="tire-register-table">
                  <TiresSentToRetreadTable tableData={tiresSentToRetreadTableData} showColumns={showColumns} filterApplied={filterApplied} />
                </div>
              </CardContent>
            </Card>
          </div>
        )}
        <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />

      <TiresSentToRetreadFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
      </div>
    </div>
  );
};

export default TiresSentToRetread;
