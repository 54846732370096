import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';


export default class ChangeDispositionApi {
    cs;
    constructor() {
        this.cs = new CommonService();
    }
    getVacantTirePositions = (locationId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getVacantWheelPositions}${locationId}`, 'get', {});
    };
    getInspectionRecordForTire = (tireId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getInspectionRecordForTire}${tireId}`,'get',{});
    }
    addRepairCostTireHistory = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.addRepairCostTireHistory}`,'post',req);
    };
}