import React, { useEffect, useState } from "react";
import "./DailyJobSummaryTable.css";
import "../../../../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import CommonUtils from "../../../../../../../services/utils";
// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    height: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const commonUtils = new CommonUtils();

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const DailyJobSummaryTable = ({ tableData, showColumns, filterApplied, tableNumber, reportEndDate, avgHrsRadial, avgHrsBiasPly, avgWornRadial, avgWornBiasPly }) => {

  const [dailyJobSummaryTableData, setDailyJobSummaryTableData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ManufacturerName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  useEffect(() => {
    setDailyJobSummaryTableData(tableData);
    setPage(0);
    let arr = commonUtils.rowPerPageSelector(dailyJobSummaryTableData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  let lastPage = Math.floor(dailyJobSummaryTableData.rowsData.length / rowsPerPage);

  const monthMapper = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  };
  let monthNumber = parseInt(reportEndDate.split("-")[1]);
  let lastMonthNUmber = monthNumber - 1;

  if (monthNumber === 1) lastMonthNUmber = 12;

  let month = monthMapper[monthNumber];
  let lastMonth = monthMapper[lastMonthNUmber];
  let year = reportEndDate.split("-")[0];

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table 
              stickyHeader 
              className={classes.table} 
              aria-labelledby="tableTitle" 
              aria-label="a dense table"
              size="small"
              >
              <TableHead>
                <TableRow>
                  {dailyJobSummaryTableData.headCells.map((headCell, index) =>
                    showColumns[headCell.value] ? (
                      <TableCell className="table-head-row" key={headCell.keyIndex} align="left" sortDirection={orderBy === headCell.value ? order : false}>
                        <TableSortLabel active={orderBy === headCell.value} direction={orderBy === headCell.value ? order : "asc"} onClick={handleRequestSort(headCell.value)}>
                          {Number(tableNumber) === 0 && index === 0 && "Radial Tires Scrapped "} {Number(tableNumber) === 0 && index === 0 && <br />}
                          {Number(tableNumber) === 0 && index === 0 && <div className="daily-job-reporting-periods"> {headCell.label + ": " + year} </div>}
                          {Number(tableNumber) === 1 && index === 0 && "Bias Ply Tires Scrapped "}
                          {Number(tableNumber) === 1 && index === 0 && <br />}
                          {Number(tableNumber) === 1 && index === 0 && <div className="daily-job-reporting-periods"> {headCell.label + ": " + year} </div>}
                          {Number(tableNumber) === 2 && index === 0 && "Tire Installation Summary "} {Number(tableNumber) === 2 && index === 0 && <br />}
                          {Number(tableNumber) === 2 && index === 0 && <div className="daily-job-reporting-periods"> {headCell.label + ": " + year} </div>}
                          {Number(tableNumber) === 3 && index === 0 && "Tire Repair Scrapped "}
                          {Number(tableNumber) === 3 && index === 0 && <br />}
                          {Number(tableNumber) === 3 && index === 0 && <div className="daily-job-reporting-periods"> {headCell.label + ": " + year} </div>}
                          {index !== 0 && Number(tableNumber) === 0 && headCell.label}
                          {index !== 0 && Number(tableNumber) === 1 && headCell.label}
                          {index !== 0 && Number(tableNumber) === 2 && headCell.label.split("&")[0]} {index !== 0 && Number(tableNumber) === 2 && <br />}{" "}
                          {index !== 0 && Number(tableNumber) === 2 && headCell.label.split("&")[1]}
                          {index !== 0 && Number(tableNumber) === 3 && headCell.label.split("&")[0]} {index !== 0 && Number(tableNumber) === 3 && <br />}{" "}
                          {index !== 0 && Number(tableNumber) === 3 && headCell.label.split("&")[1]}
                          {orderBy === headCell.value ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {dailyJobSummaryTableData.rowsData.length > 0 && tableNumber === 0 && (
                <TableBody>
                  {stableSort(dailyJobSummaryTableData.rowsData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={row.Key}>
                          {showColumns.ReportingPeriods && (
                            <TableCell className="table-content-text">
                              {index === 0 && row.ReportingPeriods + " : " + year}
                              {index === 1 && row.ReportingPeriods + " : " + lastMonth}
                              {index === 2 && row.ReportingPeriods + " : " + month}
                              {index === 3 && row.ReportingPeriods + " : " + reportEndDate}
                            </TableCell>
                          )}
                          {showColumns.TireCount && <TableCell className="table-content-text">{row.TireCount}</TableCell>}
                          {showColumns.AverageHours && <TableCell className="table-content-text">{row.AverageHours}</TableCell>}
                          {showColumns.PercentHourTarget && <TableCell className="table-content-text">{row.PercentHourTarget}%</TableCell>}
                          {showColumns.AverageWorn && 
                            <Tooltip title={commonUtils.wornPercentageInfo(row.PercentageWorn)} placement="top-end">
                              <TableCell className={commonUtils.wornPercentageClass(row.AverageWorn)}>{row.AverageWorn}%</TableCell>
                            </Tooltip>}
                          {showColumns.PercentWornTarget && <TableCell className="table-content-text">{row.PercentWornTarget}%</TableCell>}
                        </TableRow>
                      );
                    })}

                  <TableRow className="total-avg-row">
                    {showColumns.ReportingPeriods && <TableCell className="total-avg-text">Company Targets:{" " + year}</TableCell>}
                    {showColumns.TireCount && <TableCell className="total-avg-text"></TableCell>}
                    {showColumns.AverageHours && <TableCell className="total-avg-text">{avgHrsRadial}</TableCell>}
                    {showColumns.PercentHourTarget && <TableCell className="total-avg-text"></TableCell>}
                    {showColumns.AverageWorn && <TableCell className="total-avg-text">{avgWornRadial + " %"}</TableCell>}
                    {showColumns.PercentWornTarget && <TableCell className="total-avg-text"></TableCell>}
                  </TableRow>
                </TableBody>
              )}
              {dailyJobSummaryTableData.rowsData.length > 0 && tableNumber === 1 && (
                <TableBody>
                  {stableSort(dailyJobSummaryTableData.rowsData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={row.Key}>
                          {showColumns.ReportingPeriods && (
                            <TableCell className="table-content-text">
                              {index === 0 && row.ReportingPeriods + " : " + year}
                              {index === 1 && row.ReportingPeriods + " : " + lastMonth}
                              {index === 2 && row.ReportingPeriods + " : " + month}
                              {index === 3 && row.ReportingPeriods + " : " + reportEndDate}
                            </TableCell>
                          )}
                          {showColumns.TireCount && <TableCell className="table-content-text">{row.TireCount}</TableCell>}
                          {showColumns.AverageHours && <TableCell className="table-content-text">{row.AverageHours}</TableCell>}
                          {showColumns.PercentHourTarget && <TableCell className="table-content-text">{row.PercentHourTarget}%</TableCell>}
                          {showColumns.AverageWorn && 
                            <Tooltip title={commonUtils.wornPercentageInfo(row.PercentageWorn)} placement="top-end">
                              <TableCell className={commonUtils.wornPercentageClass(row.AverageWorn)}>{row.AverageWorn}%</TableCell>
                            </Tooltip>}
                          {showColumns.PercentWornTarget && <TableCell className="table-content-text">{row.PercentWornTarget}%</TableCell>}
                        </TableRow>
                      );
                    })}
                  <TableRow className="total-avg-row">
                    {showColumns.ReportingPeriods && <TableCell className="total-avg-text">Company Targets:{" " + year}</TableCell>}
                    {showColumns.TireCount && <TableCell className="total-avg-text"></TableCell>}
                    {showColumns.AverageHours && <TableCell className="total-avg-text">{avgHrsBiasPly}</TableCell>}
                    {showColumns.PercentHourTarget && <TableCell className="total-avg-text"></TableCell>}
                    {showColumns.AverageWorn && <TableCell className="total-avg-text">{avgWornBiasPly + " %"}</TableCell>}
                    {showColumns.PercentWornTarget && <TableCell className="total-avg-text"></TableCell>}
                  </TableRow>
                </TableBody>
              )}
              {dailyJobSummaryTableData.rowsData.length > 0 && tableNumber === 2 && (
                <TableBody>
                  {stableSort(dailyJobSummaryTableData.rowsData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={row.Key}>
                          {showColumns.Manufacturer && <TableCell className="table-content-text">{row.Manufacturer}</TableCell>}
                          {showColumns.Last24Hours && <TableCell className="table-content-text">{row.Last24Hours}</TableCell>}
                          {showColumns.MonthToDate && <TableCell className="table-content-text">{row.MonthToDate}</TableCell>}
                          {showColumns.LastMonth && <TableCell className="table-content-text">{row.LastMonth}</TableCell>}
                          {showColumns.YearToDate && <TableCell className="table-content-text">{row.YearToDate}</TableCell>}
                          {showColumns.PrevYearToDate && <TableCell className="table-content-text">{row.PrevYearToDate}</TableCell>}
                        </TableRow>
                      );
                    })}
                  {(page === lastPage || rowsPerPage === dailyJobSummaryTableData.rowsData.length) && (
                    <TableRow className="total-avg-row">
                      {showColumns.Manufacturer && <TableCell className="total-avg-text">Total</TableCell>}
                      {showColumns.Last24Hours && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.Last24Hours}</TableCell>}
                      {showColumns.MonthToDate && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.MonthToDate}</TableCell>}
                      {showColumns.LastMonth && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.LastMonth}</TableCell>}
                      {showColumns.YearToDate && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.YearToDate}</TableCell>}
                      {showColumns.PrevYearToDate && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.PrevYearToDate}</TableCell>}
                    </TableRow>
                  )}
                </TableBody>
              )}
              {dailyJobSummaryTableData.rowsData.length > 0 && tableNumber === 3 && (
                <TableBody>
                  {stableSort(dailyJobSummaryTableData.rowsData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={row.Key}>
                          {showColumns.TireSize && <TableCell className="table-content-text">{row.TireSize}</TableCell>}
                          {showColumns.Last24Hours && <TableCell className="table-content-text">{row.Last24Hours}</TableCell>}
                          {showColumns.MonthToDate && <TableCell className="table-content-text">{row.MonthToDate}</TableCell>}
                          {showColumns.LastMonth && <TableCell className="table-content-text">{row.LastMonth}</TableCell>}
                          {showColumns.YearToDate && <TableCell className="table-content-text">{row.YearToDate}</TableCell>}
                          {showColumns.PrevYearToDate && <TableCell className="table-content-text">{row.PrevYearToDate}</TableCell>}
                        </TableRow>
                      );
                    })}
                  {(page === lastPage || rowsPerPage === dailyJobSummaryTableData.rowsData.length) && (
                    <TableRow className="total-avg-row">
                      {showColumns.TireSize && <TableCell className="total-avg-text">Total</TableCell>}
                      {showColumns.Last24Hours && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.Last24Hours}</TableCell>}
                      {showColumns.MonthToDate && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.MonthToDate}</TableCell>}
                      {showColumns.LastMonth && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.LastMonth}</TableCell>}
                      {showColumns.YearToDate && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.YearToDate}</TableCell>}
                      {showColumns.PrevYearToDate && <TableCell className="total-avg-text">{dailyJobSummaryTableData.avgValues.PrevYearToDate}</TableCell>}
                    </TableRow>
                  )}
                </TableBody>
              )}
              {dailyJobSummaryTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={dailyJobSummaryTableData.headCells.length} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {tableNumber !== 0 && tableNumber !== 1 && (
            <TablePagination
              rowsPerPageOptions={arrRowsPerPageOptions}
              component="div"
              count={dailyJobSummaryTableData.rowsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
            />
          )}
        </Paper>
      </div>
    </div>
  );
};

export default DailyJobSummaryTable;
