import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";

class TireBelowInflationDataSetter {
  constructor() {
    this.data = {};
    this.tireBelowInflationData = [];
    this.headCells = [];
    this.byTireBelowInflation = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  formatData(item) {
    let date;
    if (item.dateTimeInstalled) {
      const datetimeString = item.dateTimeInstalled.split("T");
      const dateString = datetimeString[0].split("-");
      date = `${dateString[0]}/${dateString[1]}/${dateString[2]}`;
    } else {
      date = null;
    }

    const tyrePressureString = item.tyrePressure.split(".");
    const tyrePressure = tyrePressureString[0];

    return {
      dateTimeInstalled: date,
      tyrePressure,
    };
  }

  tableHeaderOrderSetter(headCells) {
    let tableHeaders = headCells;
    // maintains the column order to be displayed
    const headerOrder = [
      { columnNo: 1, value: "VehicleName" },
      { columnNo: 2, value: "WheelPosition" },
      { columnNo: 3, value: "SerialNo" },
      { columnNo: 4, value: "TyrePressure" },
      { columnNo: 5, value: "RcmdPressure" },
      { columnNo: 6, value: "BrandNo" },
      { columnNo: 7, value: "Location" },
      { columnNo: 8, value: "DateTimeInstalled" },
    ];

    const resultHeaderCell = [];
    headerOrder.forEach((header) => {
      let found = false;
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === header.value) {
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });

    return resultHeaderCell;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    const updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      if (headCell.value !== "BrandNo") {
        updatedHeadCells.push(headCell);
      }
      return true;
    });
    return updatedHeadCells;
  }

  initDisplayColumnData(headCells) {
    const selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "BrandNo") {
        selectedColShow[headCell.value] = false;
      } else {
        selectedColShow[headCell.value] = true;
      }
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    const selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    for (let index = 0; index < reportData.length; index++) {
      // formatting incoming data
      const formattedData = this.formatData(reportData[index]);
      // the below object maintains the display of rows data
      this.tireBelowInflationData.push({
        Key: index,
        VehicleName: reportData[index].vehicleName
          ? reportData[index].vehicleName
          : "",
        WheelPosition: reportData[index].wheelPosition
          ? reportData[index].wheelPosition
          : "",
        SerialNo: reportData[index].serialNo ? reportData[index].serialNo : "",
        TyrePressure: formattedData.tyrePressure
          ? formattedData.tyrePressure
          : "",
        RcmdPressure: reportData[index].rcmdPressure
          ? Math.round(reportData[index].rcmdPressure)
          : 0,
        BrandNo: reportData[index].brandNo ? reportData[index].brandNo : "",
        Location: reportData[index].location ? reportData[index].location : "",
        DateTimeInstalled:
          reportData[index].dateTimeInstalled !== "" &&
          reportData[index].dateTimeInstalled !== null
            ? this.commonUtils.formatISODate(
                reportData[index].dateTimeInstalled
              )
            : "",
      });
    }
    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    for (let index = 0; index < columnDefinitions.length; index++) {
      const colDefnString = columnDefinitions[index].text.split(".");
      const [masterKey, key] = colDefnString;
      const labelName = this.globalizationMapping[masterKey][key];
      const labelId = columnDefinitions[index].fieldName;
      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          columnDefinitions[index].dataType === "number" ||
          columnDefinitions[index].dataType === "currency" ||
          columnDefinitions[index].dataType === "percentagedecimal"
            ? true
            : false,
      });
    }
    const orderedHeaderCells = this.tableHeaderOrderSetter(this.headCells);
    const selectedColShow = this.initDisplayColumnData(this.headCells);
    this.data = {
      tireBelowInflationData: this.tireBelowInflationData,
      headCells: orderedHeaderCells,
      selectedColShow,
    };
    return this.data;
  }

  setGraphData(data) {
    return data.model && data.model.graphData;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      const SerialNo = item.SerialNo ? true : false;
      const BrandNo = item.BrandNo ? true : false;
      const VehicleName = item.VehicleName ? true : false;
      const WheelPosition = item.WheelPosition ? true : false;
      const Location = item.Location ? true : false;
      const TyrePressure = item.TyrePressure ? true : false;
      const RcmdPressure = item.RcmdPressure ? true : false;
      const DateTimeInstalled = item.DateTimeInstalled ? true : false;
      return (
        (VehicleName ? item.VehicleName.toLowerCase().includes(searchText) : false ) ||
        (WheelPosition ? item.WheelPosition.toLowerCase().includes(searchText) : false ) ||
        (Location ? item.Location.toLowerCase().includes(searchText) : false ) ||
        (TyrePressure? item.TyrePressure.toLowerCase().includes(searchText) : false ) ||
        (RcmdPressure? item.RcmdPressure.toString().toLowerCase().includes(searchText) : false ) ||
        (DateTimeInstalled ? item.DateTimeInstalled.toLowerCase().includes(searchText) : false ) ||
        (SerialNo ? item.SerialNo.toLowerCase().includes(searchText) : false) ||
        (BrandNo ? item.BrandNo.toLowerCase().includes(searchText) : false)
      );
    });
  }
}

export default TireBelowInflationDataSetter;
