import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";

class RemovedTireDataSetter {
  constructor() {
    this.data = {};
    this.removedTireData = [];
    this.headCells = [];
    this.tireList = [];
    this.tireId = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  setTireData(data) {
    const reportData = data.model;
    reportData.forEach((item, index) => {
      this.tireList.push({
        key: item.tireId,
        keyIndex: index,
        value: item.tireId,
        label: item.tireSerialNo,
      });
    });
    return this.tireList;
  }
  getTireIds(tireSerial) {
    const tireSerialData = tireSerial;
    tireSerialData.forEach((item, index) => {
      this.tireId.push(item.value);
    });
    return this.tireId;
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      this.removedTireData.push({
        Key: index,
        ParentAccount: item.parentAccount ? item.parentAccount : "",
        Location: item.location ? item.location : "",
        InspectedBy: item.inspectedBy ? item.inspectedBy : "",
        InspectionId: "" ? item.inspectedBy : "",
        SerialNo: item.tireSerialNo ? item.tireSerialNo : "",
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        RatingName: item.ratingName ? item.ratingName : "",
        currentHours: item.currentHours ? item.currentHours : 0,
        currentMiles: item.currentMiles ? item.currentMiles : 0,
        PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
        TreadDepth: item.treadDepth ? item.treadDepth : 0,
        CostPerHour: item.costPerHour
          ? Math.round(item.costPerHour * 100) / 100
          : 0,
        VehId: item.vehicleNumber ? item.vehicleNumber : "",
        AssetNumber: item.assetNumber ? item.assetNumber : "",
        VehicleSpec: item.vehicleSpec ? item.vehicleSpec : "",
        WheelPosition: item.wheelPosition ? item.wheelPosition : "",
        RemovalDate: item.removalDate
          ? this.commonUtils.tableDateFormatter(item.removalDate)
          : "",
        RemovalReason: item.removalReason ? item.removalReason : "",
        Comments: item.comments ? item.comments : "",
        RemoveTireImages: item.removeTireImages,
        MoneyLost: item.moneyLost,
        TireGraphData: item.tireGraphData,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "numeric" ? true : false,
      });
    });

    return {
      rowsData: this.removedTireData,
      headCells: this.headCells,
    };
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ParentAccount.toString().toLowerCase().includes(searchText) ||
        item.Location.toString().toLowerCase().includes(searchText) ||
        item.InspectedBy.toString().toLowerCase().includes(searchText) ||
        item.InspectionId.toString().toLowerCase().includes(searchText) ||
        item.SerialNo.toString().toLowerCase().includes(searchText) ||
        item.ManufacturerName.toString().toLowerCase().includes(searchText) ||
        item.SizeName.toString().toLowerCase().includes(searchText) ||
        item.TypeName.toString().toLowerCase().includes(searchText) ||
        item.CompoundName.toString().toLowerCase().includes(searchText) ||
        item.RatingName.toString().includes(searchText) ||
        item.currentHours.toString().includes(searchText) ||
        item.currentMiles.toString().includes(searchText) ||
        item.AssetNumber.toString().toLowerCase().includes(searchText) ||
        item.PercentageWorn.toString().includes(searchText) ||
        item.TreadDepth.toString().toLowerCase().includes(searchText) ||
        item.CostPerHour.toString().includes(searchText) ||
        item.VehId.toString().toLowerCase().includes(searchText) ||
        item.VehicleSpec.toString().toLowerCase().includes(searchText) ||
        item.WheelPosition.toString().toLowerCase().includes(searchText) ||
        item.MoneyLost.toString().toLowerCase().includes(searchText) ||
        item.RemovalDate.toString().includes(searchText) ||
        item.RemovalReason.toString().toLowerCase().includes(searchText)
      );
    });
  }
}

export default RemovedTireDataSetter;
