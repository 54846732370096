import React, { useEffect, useState, useContext } from "react";
import { Card, CardContent, InputLabel, TextField, Select, InputAdornment, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import OtherTiresNavigation from "../../../other-tires-navigation/OtherTiresNavigation";
import TiresByDispositionDataSetter from "../../../../../services/TiresByDispositionUtils";
import TiresByDispositionGraph from "../graph/TiresByDispositionGraph";
import TiresByDispositionTable from "../table/TiresByDispositionTable";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import TiresByDispositionFilter from "../filter/TiresByDispositionFilter.js";


const TiresByDisposition = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const tiresByDispositionDataSetter = new TiresByDispositionDataSetter();
  const commonUtils = new CommonUtils();

  const [showSpinner, setShowSpinner] = useState(true);
  const [tiresByDispositionData, setTiresByDispositionData] = useState();
  const [originalTiresByDispositionData, setOriginalTiresByDispositionData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(tiresByDispositionData);
  const [dateRangeType, setDateRangeType] = useState("AllTime");

  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [noData, setNoData] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [toggleHM, setToggleHM] = useState("Hours");

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Disposition", value: "Disposition" }
    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      Disposition: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const globalizationMapping = new Globalization();

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  useEffect(() => {
    TiresByDispositionResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const TiresByDispositionResponseData = (excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    // FORMATTING THE GOBAL ACCOUNT STATES
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      locationIds: locationIds,
      reportName: "Tires_By_Disposition",
      reportOption: "",
      toDate: dateRangeFilter.toDate,
      usedTires: excludeUsedTiresValue.toString()
    };
    let filterOptions = {
      categories: [
        {
          name: globalizationMapping.REPORTTABLE.MANUFACTURER,
          value: "ManufacturerName"
        },
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        {
          name: globalizationMapping.REPORTTABLE.DISPOSITION,
          value: "Disposition"
        }
      ],
      categoryData: {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        Disposition: [],
        dateRangeFromDate: '',
        dateRangeToDate: '',
      }
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let tiresByDispositionInfo = tiresByDispositionDataSetter.setData(data);
      let filterOptionsInfo = tiresByDispositionDataSetter.setFilterOptionsData(filterOptions, tiresByDispositionInfo.rowsData);
      setTiresByDispositionData(tiresByDispositionInfo);
      setOriginalTiresByDispositionData(tiresByDispositionInfo);
      setTableBeforeFilter(tiresByDispositionInfo);
      tiresByDispositionInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setFilterOptions(filterOptionsInfo);
      let updatedHeadCells = tiresByDispositionInfo.headCells.filter((item) => item.value !== "AverageMiles");
      setDisplayColOptions(updatedHeadCells);
      setDisplayColumns(updatedHeadCells);
      setShowColumns(tiresByDispositionInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TireByDisposition,
        rowsData: tiresByDispositionInfo.rowsData,
        headCells: tiresByDispositionInfo.headCells,
        avgValues: tiresByDispositionDataSetter.avgValues(tiresByDispositionInfo.rowsData),
        selectedColShow: tiresByDispositionInfo.selectedColShow
      });
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = tiresByDispositionInfo.rowsData;
        let filteredData = tiresByDispositionDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setTiresByDispositionData({
          ...tiresByDispositionInfo,
          rowsData: filteredData,
          avgValues: tiresByDispositionDataSetter.avgValues(filteredData)
        });
        setOriginalTiresByDispositionData({
          ...tiresByDispositionInfo,
          rowsData: filteredData,
          avgValues: tiresByDispositionDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: tiresByDispositionDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
      }
      setShowSpinner(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    // searchText === "" ? setFilterApplied(false) : setFilterApplied(true)
    let tableDataStore, filteredData;
    tableDataStore = originalTiresByDispositionData?.rowsData;
    filteredData = tiresByDispositionDataSetter.search(tableDataStore, searchText);
    setTiresByDispositionData({
      ...tiresByDispositionData,
      rowsData: filteredData,
      avgValues: tiresByDispositionDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: tiresByDispositionDataSetter.avgValues(filteredData),
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalTiresByDispositionData?.headCells;
    let showColumnsUpdated = tiresByDispositionDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    setToggleHM("Hours");
    actionChanged("Hours");
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}


    setFilterFieldsApplied(appliedFilter);
    let originalTableDataStore = originalTiresByDispositionData?.rowsData;
    let filteredData = tiresByDispositionDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setTiresByDispositionData({
      ...tiresByDispositionData,
      rowsData: filteredData,
      avgValues: tiresByDispositionDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: tiresByDispositionDataSetter.avgValues(filteredData)
    });
    setOnlyFilterApplied(true);
    filteredData.length === 0 ? setNoData(true) : setNoData(false);


    setFilterFieldsApplied(appliedFilter);
    TiresByDispositionResponseData(excludeUsedTires, true, appliedFilter);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      Disposition: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    });
    setFilterApplied(false);
    setTiresByDispositionData(tableBeforeFilter);
    setOriginalTiresByDispositionData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    let tempFilterFieldsApllied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApllied).forEach((index) => {
      tempFilterFieldsApllied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApllied);
    setOnlyFilterApplied(false);
    setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };
  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    setToggleHM("Hours");
    actionChanged("Hours");
    TiresByDispositionResponseData(event.target.checked, filterApplied, filterFieldsApplied);
  };
  const actionChanged = (action) => {
    setToggleHM(action);
    if (action === "Miles") {
      handleMilesClick();
    } else {
      handleHoursClick();
    }
  };
  const handleMilesClick = () => {
    let avoidArr = [];
    avoidArr = ["AverageHours"];
    let dispositionData = tiresByDispositionData;
    dispositionData.headCells.map((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      dispositionData.selectedColShow[item.value] = flag;
    });
    setTiresByDispositionData(dispositionData);
    dispositionData.rowsData.length === 0 ? setNoData(true) : setNoData(false);

    let updatedHeadCells = dispositionData.headCells.filter((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });

    setDisplayColumns(updatedHeadCells);
    setDisplayColOptions(updatedHeadCells);
    setShowColumns(dispositionData.selectedColShow);
    setPrintData({
      reportType: "",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TireByDisposition,
      rowsData: dispositionData.rowsData,
      headCells: dispositionData.headCells,
      avgValues: tiresByDispositionDataSetter.avgValues(dispositionData.rowsData),
      selectedColShow: dispositionData.selectedColShow
    });
  };
  const handleHoursClick = () => {
    let avoidArr = [];
    avoidArr = ["AverageMiles"];
    let dispositionData = tiresByDispositionData;
    dispositionData.headCells.map((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      dispositionData.selectedColShow[item.value] = flag;
    });
    setTiresByDispositionData(dispositionData);
    dispositionData.rowsData.length === 0 ? setNoData(true) : setNoData(false);

    let updatedHeadCells = dispositionData.headCells.filter((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });

    setDisplayColumns(updatedHeadCells);
    setDisplayColOptions(updatedHeadCells);
    setShowColumns(dispositionData.selectedColShow);

    setPrintData({
      reportType: "",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.TireByDisposition,
      rowsData: dispositionData.rowsData,
      headCells: dispositionData.headCells,
      avgValues: tiresByDispositionDataSetter.avgValues(dispositionData.rowsData),
      selectedColShow: dispositionData.selectedColShow
    });
  };

  return (
    <div>
      <OtherTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-tire-type-container">
          <Card className="by-tire-type-card">
            <CardContent className="graph-card">
              <TiresByDispositionGraph tiresByDispositionGraphData={tiresByDispositionData?.rowsData} />
            </CardContent>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      value={toggleHM}
                      onChange={(e) => actionChanged(e.target.value)}
                      helperText=""
                    >
                      <option value={"Hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                      <option value={"Miles"}>{toggleMile}</option>
                    </Select>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="by-tire-type-table">
                <TiresByDispositionTable tableData={tiresByDispositionData} showColumns={showColumns} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />

      <TiresByDispositionFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default TiresByDisposition;
