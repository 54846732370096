import Globalization from '../../../globalization/index'

class TireInflationDataSetter {
    
        constructor() {
            this.tireInflationGraphData = [];
            this.globalizationMapping = new Globalization();
        }

        setGraphData(data){
            let graphData = data.model.graphData
            return graphData
        }


    }

export default TireInflationDataSetter;