import * as React from "react";
import { Route, Switch } from "react-router-dom";

import Callback from "./callback";
import { Logout } from "./logout";
import { LogoutCallback } from "./logoutCallback";
import { PrivateRoute } from "../services/PrivateRoute";
import { SilentRenew } from "./silentRenew";
import ReportsNav from "../common-components/reports-nav/components/reports-navbar/reports-nav"
import MainComponent from "../auth/MainComponent"
import { AuthConsumer } from "../services/AuthProvider";

const renderFn = (Component) => (props) => (
    <AuthConsumer>
        {({ isAuthenticated, signinRedirect }) => {
            if (!!Component && isAuthenticated()) {
                return <Component {...props} />;
            } else {
                signinRedirect();
                return <span>private route loading</span>;
            }
        }}
    </AuthConsumer>
);
export const Routes = (

    <Switch>
        <Route exact={true} path="/authentication/callback" component={Callback} />
        <Route exact={true} path="/logout" component={Logout} />
        <Route exact={true} path="/logout/callback" component={LogoutCallback} />
        <Route exact={true} path="/silentrenew" component={SilentRenew} />
        <PrivateRoute path="/$reportsDashboardReplica$" component={renderFn(ReportsNav)} exact />
        <PrivateRoute path="/" component={renderFn(MainComponent)} />
        <PrivateRoute path="/main-routing" component={renderFn(MainComponent)} exact />
    </Switch>
);