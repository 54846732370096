/* eslint-disable no-unused-vars */
import {
  Backdrop,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  Modal,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import HeaderApi from "../../../../common-components/header/services/header-apis";
import ChangeDispositionApi from "../../services/change-disposition-apis";
import Globalization from "../../../../globalization";
import "./change-disp-modal.css";
import ExpandMoreStyledIcon from "../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";

const useStyles = makeStyles({
  proceed: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
  },
  progress: {
    color: "#0055A4",
    marginLeft: "46%",
    marginRight: "46%",
  },
});

const ChangeTireDispositionModal = (props) => {
  const history = useHistory();
  const locationObj = useLocation();
  const styles = useStyles();
  const globalizationMapping = new Globalization();
  const headerApiObj = new HeaderApi();
  const changeDispApiObj = new ChangeDispositionApi();
  const titleTexts = globalizationMapping.FORMS;
  const modalTexts = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
  const validationTexts = globalizationMapping.FORMS.VALIDATIONTEXTS;
  const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;

  const [useCase, setUseCase] = useState(1);
  const [tireDetails, setTireDetails] = useState({});
  const [dispositionList, setDispositionList] = useState([]);
  const [vehicleList, setVehicleList] = useState();
  const [wheelListByVehicle, setWheelListByVehicle] = useState([]);
  const [toInstall, setToInstall] = useState(false);
  const [formHelperText, setFormHelperText] = useState("");

  useEffect(() => {
    headerApiObj.getTireById(props.tireId).then((tireData) => {
      if (tireData.model) {
        let dispListArr = [];
        if (props.tireDisposition === "Scrap") {
          setUseCase(2);
          dispListArr = props.dispositionList.filter(
            (disp) => disp.dispositionName === "Inventory"
          );
          setTireDetails({ ...tireData.model, dispositionId: 8 });

        } else if (props.tireDisposition === "Repair" || props.tireDisposition === "Retread" || props.tireDisposition === "OnHold") {
          dispListArr = [
            {dispositionId: 1, dispositionName: 'Scrap', dispositionGroupId: 3},
            {dispositionId: 2, dispositionName: 'Repair', dispositionGroupId: 1},
            {dispositionId: 3, dispositionName: 'Retread', dispositionGroupId: 1},
            {dispositionId: 4, dispositionName: 'OnHold', dispositionGroupId: 4},
            {dispositionId: 8, dispositionName: 'Inventory', dispositionGroupId: 1},
                        ]
          setTireDetails(tireData.model);

        } else {
          dispListArr = [...props.dispositionList];
          setTireDetails(tireData.model);
        }
        setDispositionList(dispListArr);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initVehicleList = () => {
      changeDispApiObj
        .getVacantTirePositions(tireDetails.locationId)
        .then((data) => {
          if (data.model.length !== 0) {
            setVehicleList(data.model);
          } else {
            setVehicleList([]);
          }
        });
  };

  const handleModalClose = (stateData) => {
    props.closeDispModal(stateData);
  };

  const getDisposition = (disp) => {
    switch (disp) {
      case "Inventory":
        return 8;
      case "Scrap":
        return 1;
      case "Installed":
        return 7;
      case "OnHold":
        return 4;
      default:
        return 0;
    }
  };
  const onDispositionSelectChange = (value) => {
    value = parseInt(value, 10);
    const disp = props.dispositionList.find(
      (dispo) => dispo.dispositionId === value
    );

    if (disp.dispositionName === "Installed") {
      if (props.tireDisposition !== "Installed") {
        setToInstall(true);
        setUseCase(1);
        initVehicleList();
      }
      setTireDetails({ ...tireDetails, dispositionId: value });
    } else if (
      (props.tireDisposition === "Repair" ||
        props.tireDisposition === "Retread") &&
      value === 8
    ) {
      setToInstall(false);
      setTireDetails({
        ...tireDetails,
        dispositionId: value,
        vehicleId: null,
        vehicleNumber: "",
        wheelPosition: "",
      });
      setUseCase(2);
    } else {
      setUseCase(1);
      setToInstall(false);
      setTireDetails({
        ...tireDetails,
        dispositionId: value,
        vehicleId: null,
        vehicleNumber: "",
        wheelPosition: "",
      });
    }
  };

  const onTireDetailsChange = (field, value) => {
    const vehicle =
      vehicleList && vehicleList.find((veh) => veh.vehicleId === value);
    if (field === "vehicle") {
      setWheelListByVehicle(vehicle.wheelPositionList);
      setTireDetails({
        ...tireDetails,
        vehicleId: value,
        vehicleNumber: vehicle.vehicleNumber,
        wheelPosition: "",
      });
    } else {
      setTireDetails({ ...tireDetails, wheelPosition: value });
    }
    setFormHelperText("");
  };

  const validateForm = () => {
    let isFormValid = true;
    let formHelper = "";
    Object.keys(tireDetails).forEach((key) => {
      if (toInstall) {
        if (key === "vehicleId" && tireDetails[key] === null) {
          isFormValid = false;
          formHelper = validationTexts.MANDATORYFIELDSLALERT;
        }
        if (key === "wheelPosition" && tireDetails[key] === "") {
          isFormValid = false;
          formHelper = validationTexts.MANDATORYFIELDSLALERT;
        }
      }
    });
    setFormHelperText(formHelper);
    return isFormValid;
  };

  const handleChangeDispClick = () => {
    let successStatus = false;
    if (validateForm()) {
      if (useCase === 1) {
        headerApiObj.editTire(tireDetails).then((response) => {
          if (response.didError === false) {
            successStatus = true;
          } else {
            successStatus = false;
          }
          handleModalClose({
            dispositionId: getDisposition(props.tireDisposition),
            updatedSection: "changetiredisposition",
            updateStatus: successStatus,
            successMessage: notificationTexts.DISPOSITIONCHANGEDSUCCESS,
            successMessageDesc:
              notificationTexts.DISPOSITIONCHANGEDDESC.replace(
                "#1#1",
                tireDetails.tireSerialNo
              ),
          });
        });
      }
      if (useCase === 2) {
        history.push({
          pathname: "/return-to-inventory",
          state: {
            tireObj: tireDetails,
            changeFrom:
              props.tireDisposition === "Repair" ? "repair" : "retread",
            fromPath: locationObj.pathname,
          },
        });
      }
    }
  };

  return (
    <div className="container">
        <Modal
          open={true}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 300 }}
          disableScrollLock={true}
        >
          <div className="delete-tire-modal-container center-modal">
            <div className="delete-tire-title-container">
              <div className="delete-tire-title">
                {titleTexts.CHANGEDISPOSITION}
              </div>
              <div className="close-icon-container" onClick={handleModalClose}>
                <img
                  src={require("../../../../assets/close-icon.svg")}
                  className="modal-close-icon"
                  alt="close-icon"
                />
              </div>
            </div>
            <div className="delete-modal-description">
              {modalTexts.CHANGEDISPOSITIONDESC.replace(
                "#1#1",
                props.tireSerial
              )}
            </div>

            <div className="modal-select-container">
              {tireDetails.dispositionId && (
                <>
                  <InputLabel>
                    {modalTexts.DISPO}&nbsp;
                    <span className="required-mark">*</span>
                  </InputLabel>
                  <Select
                    native
                    className="location-select-input disposition-popup-select"
                    variant="outlined"
                    value={tireDetails.dispositionId}
                    onChange={(e) => {
                      onDispositionSelectChange(e.target.value);
                    }}
                    IconComponent={ExpandMoreStyledIcon}
                  >
                    <option key={0} value="" disabled>
                      &nbsp;{modalTexts.DROPDOWNPLACEHOLDER}
                    </option>
                    {dispositionList.map((disp, key) => (disp.dispositionId !== 7 ?
                      <option key={disp.dispositionId} value={disp.dispositionId}>
                        {disp.dispositionName}
                      </option> : null
                    ))}
                  </Select>
                </>
              )}
              {toInstall &&
                vehicleList &&
                (vehicleList.length !== 0 ? (
                  <Grid container spacing={1}>
                    <Grid item sm={6}>
                      <InputLabel>
                        {modalTexts.VEHICLEID}&nbsp;
                        <span className="required-mark">*</span>
                      </InputLabel>
                      <Select
                        native
                        className="location-select-input"
                        variant="outlined"
                        value={tireDetails.vehicleId}
                        onChange={(e) => {
                          onTireDetailsChange(
                            "vehicle",
                            parseInt(e.target.value, 10)
                          );
                        }}
                        IconComponent={ExpandMoreStyledIcon}
                      >
                        <option key={0} value="" disabled>
                          &nbsp;{modalTexts.DROPDOWNPLACEHOLDER}
                        </option>
                        {vehicleList.map((vehicle, key) => (
                          <option key={vehicle.vehicleId} value={vehicle.vehicleId}>
                            &nbsp;{vehicle.vehicleNumber}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item sm={6}>
                      <InputLabel>
                        {modalTexts.WHEELPOS}&nbsp;
                        <span className="required-mark">*</span>
                      </InputLabel>
                      <Select
                        native
                        className="location-select-input"
                        variant="outlined"
                        value={tireDetails.wheelPosition}
                        onChange={(e) => {
                          onTireDetailsChange("wheel", e.target.value);
                        }}
                        IconComponent={ExpandMoreStyledIcon}
                      >
                        <option key={0} value="" disabled>
                          &nbsp;{modalTexts.DROPDOWNPLACEHOLDER}
                        </option>
                        {wheelListByVehicle.map((position) => (
                          <option key={position} value={position}>
                            &nbsp;{position}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                ) : (
                  <p>{modalTexts.NOVEHICLEAVAILABLE}</p>
                ))}
              {toInstall && !vehicleList && (
                <CircularProgress className={styles.progress} />
              )}
              <FormHelperText>{formHelperText}</FormHelperText>
            </div>

            {
              <div className="modal-action-buttons">
                <Button
                  className={styles.cancel}
                  variant="contained"
                  disableElevation={true}
                  onClick={handleModalClose}
                >
                  {titleTexts.CANCEL}
                </Button>
                <Button
                  className={styles.proceed}
                  variant="contained"
                  disableElevation={true}
                  onClick={() => {
                    handleChangeDispClick();
                  }}
                  disabled={!tireDetails.dispositionId}
                >
                  {titleTexts.PROCEED}
                </Button>
              </div>
            }
          </div>
        </Modal>
      </div>
  );
};

export default ChangeTireDispositionModal;
