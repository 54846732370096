import React, { useEffect, useContext, useState } from "react";
import "./ManageLocationList.css";
import "../../../../../common-styles/TableFunctionOptions.css";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
} from "@material-ui/core";
import ManageLocationListApis from "../../../services/ManageLocationListApis";
import { StateContext } from "../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import Spinner from "../../../../../common-components/spinner/spinner";
import ManageLocationListTable from "../table/ManageLocationListTable";
import ManageLocationListSetter from "../../../services/ManageLocationListUtils";
import CommonUtils from "../../../../../services/utils";
import SearchIcon from "../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import Breadcrumbs from "../../../../../common-components/breadcrumbs/breadcrumbs";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import Globalization from "../../../../../globalization";
import { useLocation } from "react-router-dom";
import FlashMessage from "../../../../../common-components/pop-up-notification/notification-snackbar";
import DeleteLocationModal from "../modals/delete-location/DeleteLocation";
import { useComponentVisible } from "../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ManageLocationListFilter from "../filter/ManageLocationListFilter";

const ManageLocationList = () => {
  // GLOBAL ACCOUNT STATE management
  const commonUtils = new CommonUtils();
  const manageLocationListDataSetter = new ManageLocationListSetter();
  const manageLocationListService = new ManageLocationListApis();
  const headerApiObj = new HeaderApi();
  const locationObj = useLocation();
  const globalizationMapping = new Globalization();

  const [manageLocationListTableDataByLocation, setManageLocationListTableDataByLocation] = useState();
  const [manageLocationListTableCurrentData, setManageLocationListTableCurrentData] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const [flashMessageState, setFlashMessageState] = useState(locationObj);
  const [transferDone, setTransferDone] = useState(commonUtils.generateRandomNumber());
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [manageLocationListTableData, setManageLocationListTableData] = useState();
  const [originalManageLocationListTableData, setOriginalManageLocationListTableData] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(manageLocationListTableData);
  const [searchValue, setSearchValue] = useState("");
  const [noData, setNoData] = useState(false);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [action, setAction] = useState([]);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const [filterOptions, setFilterOptions] = useState({
    categories: [
      {
        name: globalizationMapping.MANAGELOCATION.ACCOUNTNAME,
        value: "AccountName",
      },
      { name: globalizationMapping.MANAGELOCATION.COUNTRY, value: "Country" },
      { name: globalizationMapping.MANAGELOCATION.STATE, value: "State" },
      { name: globalizationMapping.MANAGELOCATION.CITY, value: "City" },
    ],
    categoryData: {
      AccountName: [],
      Country: [],
      State: [],
      City: [],
    },
  });
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false,
  });
  const [forceActionPopupClose, setForceActionPopupClose] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER


  const globalHeaderTexts = globalizationMapping.HEADER;
  const globalTexts = globalizationMapping.MANAGE_PARENT_ACCOUNT;
  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME,
    },
    {
      path: "",
      name: globalHeaderTexts.MANAGELOCATION
    }
  ]

  useEffect(() => {
    ManageLocationListResponseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, transferDone]);


  const multipleRowsSelected = (rows) => {
    setAction(rows)
  }

  const ManageLocationListResponseData = () => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let filterOptions = {
      categories: [
        {
          name: globalizationMapping.MANAGELOCATION.ACCOUNTNAME,
          value: "AccountName",
        },
        { name: globalizationMapping.MANAGELOCATION.COUNTRY, value: "Country" },
        { name: globalizationMapping.MANAGELOCATION.STATE, value: "State" },
        { name: globalizationMapping.MANAGELOCATION.CITY, value: "City" },
      ],
      categoryData: {
        AccountName: [],
        Country: [],
        State: [],
        City: [],
      },
    };

    setShowSpinner(true);
    headerApiObj.getCountryList().then((countries) => {
      manageLocationListService
        .manageLocationGetDetailsByAccount(accountIds[0])
        .then((data) => {
          data.model.forEach((item, _index) => {
            let country = countries.model.filter((i) => {
              return i.countryId === parseInt(item.country);
            });
            if (country[0]) item.country = country[0].countryName;
            else item.country = "";
          });
          let manageLocationListTableInfo = manageLocationListDataSetter.setData(data);
          let filterOptionsInfo = manageLocationListDataSetter.setFilterOptionsData( filterOptions, manageLocationListTableInfo.rowsData);
          let dataDataByLocation = manageLocationListDataSetter.setDataByLocation(data, accountIds[0]);
          let locationDataShown = { ...manageLocationListTableInfo };
          locationDataShown.rowsData = dataDataByLocation;
          setManageLocationListTableDataByLocation(locationDataShown);
          setManageLocationListTableData(locationDataShown);
          setManageLocationListTableCurrentData(locationDataShown.rowsData);
          setOriginalManageLocationListTableData(manageLocationListTableInfo);
          locationDataShown.rowsData.length === 0
            ? setNoData(true)
            : setNoData(false);
          setFilterOptions(filterOptionsInfo);
          setShowColumns(manageLocationListTableInfo.selectedColShow);
          setPrintData({
            reportType: "",
            reportName: globalizationMapping.MANAGELOCATION.LocationList,
            rowsData: locationDataShown.rowsData,
            headCells: manageLocationListTableInfo.headCells,
            selectedColShow: manageLocationListTableInfo.selectedColShow,
          });
          setShowSpinner(false);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };



  const onClickExport = (type) => {
    setForceActionPopupClose(true);
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]
        );
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    setForceActionPopupClose(true);
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);

      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    setForceActionPopupClose(true);
    setModalHandler({ handleEmail: true });
  };

  const onClickFilter = () => {
    setForceActionPopupClose(true);
    setShowFilter(true);
  };

  const forceActionPopupCloseToFalse = () => {
    setForceActionPopupClose(false);
  }

  // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    setCurrentFilter(appliedFilter);
    setSearchValue("");
    setFilterApplied(true);
    let originalTableDataStore = originalManageLocationListTableData?.rowsData;
    let filteredData = manageLocationListDataSetter.filterTable(
      appliedFilter,
      originalTableDataStore
    );
    setManageLocationListTableData({
      ...manageLocationListTableData,
      rowsData: filteredData,
    });
    setPrintData({ ...printData, rowsData: filteredData });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setManageLocationListTableCurrentData(filteredData);
    Object.values(appliedFilter).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter({
      AccountName: [],
      Country: [],
      State: [],
      City: [],
    },)
    setSearchValue("");
    setPrintData({
      ...printData,
      rowsData: manageLocationListTableDataByLocation?.rowsData,
    });
    setFilterApplied(false);
    setManageLocationListTableData(manageLocationListTableDataByLocation);
    setNoData(false);
    setOnlyFilterApplied(false);
    setManageLocationListTableCurrentData(
      manageLocationListTableDataByLocation?.rowsData
    );
  };
  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setForceActionPopupClose(true);
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    searchText === "" ? setFilterApplied(false) : setFilterApplied(true);
    let originalTableDataStore, filteredData;
    originalTableDataStore = manageLocationListTableCurrentData;

    filteredData = manageLocationListDataSetter.search(
      originalTableDataStore,
      searchText
    );

    let updatedTableData = {
      rowsData: filteredData,
      headCells: originalManageLocationListTableData?.headCells,
      selectedColShow: originalManageLocationListTableData?.selectedColShow,
    };
    setManageLocationListTableData(updatedTableData);
    setPrintData({
      reportType: "",
      reportName: globalizationMapping.MANAGELOCATION.LocationList,
      rowsData: filteredData,
      headCells: originalManageLocationListTableData?.headCells,
      selectedColShow: originalManageLocationListTableData?.selectedColShow,
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  const deleteLocationDone = (transferState) => {

    if (transferState.state.updatedSection === "deletelocation") {
      if (transferState.state.updateStatus) {
        setShowNotification(true);
      };
    } else {
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    };

    if (transferState.state.updateStatus) {
      setTransferDone(commonUtils.generateRandomNumber());
      setFlashMessageState(transferState);
      setTimeout(() => {
        setShowNotification(false);
        setFlashMessageState({});
      }, 3000);
    }
  };
  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };
  const actionChanged = (action) => {
    if (action === "Delete") {
      handleDeleteClick();
    }
  };
  const handleDeleteModalClose = (deleteData) => {
    setDeleteModalOpen(false);
    let notification = { state: deleteData };
    deleteLocationDone(notification);
    if (deleteData.state && deleteData.state.updateStatus) setAction([]);
  };

  const toggleExportDropdown = () => {
    setForceActionPopupClose(true);
    setIsComponentVisible(!isComponentVisible);
  }

  return (
    <div className="standalone-new-header-container">
      {showNotification && flashMessageState.state && (
        <FlashMessage
          status={flashMessageState.state.updateStatus}
          message={flashMessageState.state.successMessage}
          description={flashMessageState.state.successMessageDesc}
        />
      )}
      <div className="manage-location-main-container ">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {globalHeaderTexts.MANAGELOCATION}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="manage-location-list-container">
          <Card className="manage-location-list-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container-vehicle">
                    <form
                      className=""
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span
                          className={
                            action.length > 1
                              ? "enable-table-toolbar-label-text"
                              : "disabled-table-toolbar-label-text"
                          }
                        >
                          {action.length} {globalTexts.ROWSELECTED}
                        </span>
                      </InputLabel>
                      <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className={
                          action.length > 1
                            ? "enable-manage-vehicle-action-dropdown"
                            : "disabled-manage-vehicle-action-dropdown"
                        }
                        variant="outlined"
                        value={"Actions"}
                        onChange={(e) => actionChanged(e.target.value)}
                        helperText=""
                        disabled={action.length <= 1}
                      >
                        <option value={"Actions"} disabled>
                          {globalizationMapping.MANAGE_PARENT_ACCOUNT.OPTION1}
                        </option>
                        <option value={"Delete"}>
                          {globalizationMapping.MANAGE_PARENT_ACCOUNT.OPTION3}
                        </option>
                      </Select>
                    </form>
                  </div>

                  <div className="search-table-container">
                    <form
                      className="search-table-container"
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {globalTexts.SEARCHTABLE}
                        </span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={
                          globalizationMapping.REPORTCOMMONWORDS.Search
                        }
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="keyword-search-icon-img"
                                alt=""
                                src={SearchIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                  onClickFilter={onClickFilter}
                  onClickEmail={onClickEmail}
                  onClickPrint={onClickPrint}
                  onClickExport={onClickExport}
                  toggleExportDropdown={toggleExportDropdown}
                  printData={printData}
                  ref={ref}
                  onlyFilterApplied={onlyFilterApplied}
                  noData={noData}
                  isComponentVisible={isComponentVisible}
                />
              </div>

              <div className="data-summary-table">
                <ManageLocationListTable
                  tableData={manageLocationListTableData}
                  showColumns={showColumns}
                  filterApplied={filterApplied}
                  multipleRowsSelected={multipleRowsSelected}
                  deleteLocationDone={deleteLocationDone}
                  forceActionPopupClose={forceActionPopupClose}
                  forceActionPopupCloseToFalse={forceActionPopupCloseToFalse}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {isDeleteModalOpen && (
        <DeleteLocationModal
          closeDeleteModal={handleDeleteModalClose}
          accountIds={action}
          isSelectionMultiple={true}
          isModalOpen={isDeleteModalOpen}
        />
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <EmailExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <ManageLocationListFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
      ></ManageLocationListFilter>


    </div>
  );
};

export default ManageLocationList;
