import React, { useEffect, useContext, useState } from "react";
import ByTiresNavigation from "../../by-tires-navigation/ByTiresNavigation";
import Spinner from "../../../../../../common-components/spinner/spinner";
import "../../../../../../common-styles/TableFunctionOptions.css";
import "./TireFlipping.css";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Grid,
  Select,
} from "@material-ui/core";
import MultiSelect from "react-multi-select-component";
import { StateContext } from "../../../../../../services/StateProvider";
import SearchIcon from "../../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import { accountInfoIdsFormatter } from "../../../../../../services/AccountInfoHandler";
import TiresService from "../../../../../tires-reports/services/TiresApis";
import TireFlippingTable from "../table/TireFlippingTable";
import TireFlippingDataSetter from "../../../../services/TireFlippingUtils";
import CommonUtils from "../../../../../../services/utils";
import Globalization from "../../../../../../globalization";
import { useComponentVisible } from "../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import TireFlippingFilter from "../filter/TireFlippingFilter";


const TireFlipping = () => {
  // GLOBAL ACCOUNT STATE management
  const globalizationMapping = new Globalization();

  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [fleetSurveyTableData, setFleetSurveyTableData] = useState();
  const [originalFleetSurveyTableData, setOriginalFleetSurveyTableData] =
    useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [averageHoursOfUsePerDay, setAverageHoursOfUsePerDay] = useState(12);
  const [averageWorkDaysPerWeek, setAverageWorkDaysPerWeek] = useState(7);
  const [tireFlippingBasedOn, setTireFlippingBasedOn] = useState("AverageWear");
  const [wearRateValue, setWearRateValue] = useState(20);
  const [printData, setPrintData] = useState(fleetSurveyTableData);
  const [printDataNotPDF, setPrintDataNotPDF] = useState(fleetSurveyTableData);
  const [noData, setNoData] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: "",
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [dateRangeType, setDateRangeType] =
    useState("AllTime");
  const [searchValue, setSearchValue] = useState("");
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [toggleHourMiles, setToggleHourMiles] = useState("");
  const toggleMile =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Vehicle Id", value: "VehicleId" },
      { name: "Model", value: "ModelName" },
      { name: "Type", value: "TypeName" },
    ],
    categoryData: {
      VehicleId: [],
      ModelName: [],
      TypeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  
  const commonUtils = new CommonUtils();
  const fleetSurveyDataSetter = new TireFlippingDataSetter();
  const tiresService = new TiresService();

  const TireFlippingResponseData = (
    filterAppliedValue,
    filterFieldsAppliedValue
  ) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: "",
      locationIds: locationIds,
      reportName: "Tire_Flipping",
      reportOption: "",
      toDate: "",
      AverageHoursOfUsePerDay: averageHoursOfUsePerDay,
      AverageWorkDaysPerWeek: averageWorkDaysPerWeek,
      tireFlippingBasedOn: tireFlippingBasedOn,
      wearRateValue: wearRateValue,
      usedTires: excludeUsedTires,
    };

    setShowSpinner(true);

    tiresService.getTiresReport(requestObject).then((data) => {
      let fleetSurveyTableInfo = fleetSurveyDataSetter.setData(data);
      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.VEHICLEMODEL,
            value: "ModelName",
          },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
          {
            name: globalizationMapping.REPORTTABLE.VEHICLEID,
            value: "VehicleId",
          },
        ],
        categoryData: {
          VehicleId: [],
          ModelName: [],
          TypeName: [],
          dateRangeFromDate: '',
          dateRangeToDate: '',
        },
      };
      let filterOptionsInfo = fleetSurveyDataSetter.setFilterOptionsData(
        filterOptions,
        fleetSurveyTableInfo.rowsDataFilterOptions
      );
      setFleetSurveyTableData(fleetSurveyTableInfo);
      fleetSurveyTableInfo.rowsData.length === 0
        ? setNoData(true)
        : setNoData(false);
      setOriginalFleetSurveyTableData(fleetSurveyTableInfo);
      setTableBeforeFilter(fleetSurveyTableInfo);
      setFilterOptions(filterOptionsInfo);

      let arrOfCol = [
        "TotalMiles",
        "ProjectedMiles",
        "MilesLeft",
        "ProjectedCostPerMile",
        "ProjectedFlippingDateBYMILE",
        "MilesPerTreadDepth",
      ];
      const updatedHeadCells = fleetSurveyTableInfo.headCells.filter((item) => {
        let flag = true;
        arrOfCol.forEach((term) => {
          if (item.value === term) flag = false;
        });
        return flag;
      });
      setDisplayColOptions(updatedHeadCells);
      if (displayColumns === "") setDisplayColumns(updatedHeadCells);
      else {
        changeDisplayColumns(displayColumns);
      }

      setShowColumns(fleetSurveyTableInfo.selectedColShow);

      setShowSpinner(false);
      setPrintData({
        reportType: "Vehicle Reports",
        reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
        rowsData: fleetSurveyTableInfo.rowsData,
        headCells: fleetSurveyTableInfo.headCells,
        vehicleData: fleetSurveyTableInfo.vehicleData,
        selectedColShow: fleetSurveyTableInfo.selectedColShow,
      });
      let exportData = JSON.parse(JSON.stringify(fleetSurveyTableInfo));
      for (let index = 0; index < exportData.rowsData.length; index++) {
        let vehicleId = exportData.vehicleData[index].VehicleId;
        exportData.rowsData[index].forEach((tireRow) => {
          tireRow.VehicleId = vehicleId;
        });
      }
      exportData.headCells.unshift({
        key: "VehicleId",
        keyIndex: exportData.headCells.length,
        label: "Vehicle Id",
        numeric: true,
        value: "VehicleId",
      });
      exportData.selectedColShow.VehicleId = true;
      setPrintDataNotPDF({
        reportType: "Vehicle Reports",
        reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
        rowsData: exportData.rowsData,
        vehicleData: exportData.vehicleData,
        headCells: exportData.headCells,
        selectedColShow: exportData.selectedColShow,
      });

      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalVehicleDataStore = fleetSurveyTableInfo.vehicleData;
        let originalRowsDataStore = fleetSurveyTableInfo.rowsData;
        let filteredVehicleData = fleetSurveyDataSetter.filterTable(
          filterFieldsAppliedValue,
          originalVehicleDataStore
        );
        let filteredRowsData = fleetSurveyDataSetter.setRowsDataOnUpdate(
          filteredVehicleData,
          originalRowsDataStore
        );

        setFleetSurveyTableData({
          ...fleetSurveyTableInfo,
          rowsData: filteredRowsData,
          vehicleData: filteredVehicleData,
        });
        setOriginalFleetSurveyTableData({
          ...fleetSurveyTableInfo,
          rowsData: filteredRowsData,
          vehicleData: filteredVehicleData,
        });
        setPrintData({
          reportType: "Vehicle Reports",
          reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
          rowsData: filteredRowsData,
          vehicleData: filteredVehicleData,
          headCells: fleetSurveyTableInfo.headCells,
          selectedColShow: fleetSurveyTableInfo.selectedColShow,
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true,
        });
        setPrintDataNotPDF({
          reportType: "Vehicle Reports",
          reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
          rowsData: JSON.parse(JSON.stringify(filteredRowsData)),
          vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
          headCells: JSON.parse(JSON.stringify(fleetSurveyTableInfo.headCells)),
          selectedColShow: JSON.parse(
            JSON.stringify(fleetSurveyTableInfo.selectedColShow)
          ),
          filterFieldsApplied: JSON.parse(
            JSON.stringify(filterFieldsAppliedValue)
          ),
          filterApplied: true,
        });
        filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
        setOnlyFilterApplied(true);
      }
      actionChanged(toggleHourMiles);
    });
  };

  useEffect(() => {
    TireFlippingResponseData(
      filterApplied,
      filterFieldsApplied
    );
  }, [globalAccountState]);

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false,
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]
        );
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };


  const confirmApplyDateFilter = (appliedFilter) => {
    
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setFilterFieldsApplied(appliedFilter);
    let originalVehicleDataStore = originalFleetSurveyTableData?.vehicleData;
    let originalRowsDataStore = originalFleetSurveyTableData?.rowsData;
    let filteredVehicleData = fleetSurveyDataSetter.filterTable(
      appliedFilter,
      originalVehicleDataStore
    );
    let filteredRowsData = fleetSurveyDataSetter.setRowsDataOnUpdate(
      filteredVehicleData,
      originalRowsDataStore
    );
    setFleetSurveyTableData({
      ...fleetSurveyTableData,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
    });
    setPrintData({
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
      headCells: originalFleetSurveyTableData?.headCells,
      selectedColShow: originalFleetSurveyTableData?.selectedColShow,
    });
    setPrintDataNotPDF({
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
      rowsData: JSON.parse(JSON.stringify(filteredRowsData)),
      vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
      headCells: JSON.parse(
        JSON.stringify(originalFleetSurveyTableData?.headCells)
      ),
      selectedColShow: JSON.parse(
        JSON.stringify(originalFleetSurveyTableData?.selectedColShow)
      ),
    });
    filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);

    setOnlyFilterApplied(true);
    setFilterFieldsApplied(appliedFilter);
    TireFlippingResponseData(true, appliedFilter);
  };


  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      VehicleId: [],
      ModelName: [],
      TypeName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });

    setFilterApplied(false);
    setSearchValue("");
    setFleetSurveyTableData(tableBeforeFilter);
    setOriginalFleetSurveyTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {},
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {},
    });
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setNoData(false);
    setOnlyFilterApplied(false);
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    setSearchValue(event.target.value);
    let originalVehicleDataStore,
      originalRowsDataStore,
      filteredVehicleData,
      filteredRowsData;
    originalVehicleDataStore = originalFleetSurveyTableData?.vehicleData;
    originalRowsDataStore = tableBeforeFilter?.rowsData;

    filteredVehicleData = fleetSurveyDataSetter.search(
      originalVehicleDataStore,
      searchText
    );

    filteredRowsData = fleetSurveyDataSetter.setRowsDataOnUpdate(
      filteredVehicleData,
      originalRowsDataStore
    );

    setFleetSurveyTableData({
      ...fleetSurveyTableData,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
    });
    setPrintData({
      ...printData,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
      rowsData: filteredRowsData,
      vehicleData: filteredVehicleData,
      headCells: originalFleetSurveyTableData?.headCells,
      selectedColShow: originalFleetSurveyTableData?.selectedColShow,
    });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      reportType: "Vehicle Reports",
      reportName: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
      rowsData: JSON.parse(JSON.stringify(filteredRowsData)),
      vehicleData: JSON.parse(JSON.stringify(filteredVehicleData)),
      headCells: JSON.parse(
        JSON.stringify(originalFleetSurveyTableData?.headCells)
      ),
      selectedColShow: JSON.parse(
        JSON.stringify(originalFleetSurveyTableData?.selectedColShow)
      ),
    });
    filteredVehicleData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    let currentHeadCells = originalFleetSurveyTableData?.headCells;
    let showColumnsUpdated = fleetSurveyDataSetter.setDisplayColumnData(
      currentHeadCells,
      selected
    );

    setShowColumns(showColumnsUpdated);
    setPrintData({ ...printData, selectedColShow: showColumnsUpdated });
    setPrintDataNotPDF({
      ...printDataNotPDF,
      selectedColShow: showColumnsUpdated,
    });
  };

  const handleExcludeUsedTires = (event) => {
    setSearchValue("");
    setExcludeUsedTires(event.target.checked);
  };

  const handleUpdateAverageHoursOfUse = (event) => {
    if (
      event.target.value === "" ||
      Number(event.target.value) === 0 ||
      Number(event.target.value) < 0
    )
      setAverageHoursOfUsePerDay("");
    else if (toggleHourMiles === "Hours" && event.target.value <= 24)
      setAverageHoursOfUsePerDay(event.target.value);
    else if (toggleHourMiles === "" && event.target.value <= 24)
      setAverageHoursOfUsePerDay(event.target.value);
    else if (toggleHourMiles === "Miles")
      setAverageHoursOfUsePerDay(event.target.value);
    else return;
  };

  const handleUpdateAverageWeekDays = (event) => {
    if (
      event.target.value === "" ||
      Number(event.target.value) === 0 ||
      Number(event.target.value) < 0
    )
      setAverageWorkDaysPerWeek("");
    else if (event.target.value <= 7)
      setAverageWorkDaysPerWeek(event.target.value);
    else return;
  };

  const handleUpdateTireFlippingBasedOn = (event) => {
    setTireFlippingBasedOn(event.target.value);
    if (event.target.value === "WearRateDifference") setWearRateValue(5);
  };

  const handleUpdateWearRateValue = (event) => {
    setWearRateValue(event.target.value);
  };

  const handleUpdateReport = () => {
    if (averageHoursOfUsePerDay && averageWorkDaysPerWeek)
      TireFlippingResponseData(filterApplied, filterFieldsApplied);
  };

  function actionChanged(eValue) {
    setToggleHourMiles(eValue);
    if (eValue === "Miles") {
      handleMilesClick();
    } else if (eValue === "Hours") {
      setAverageHoursOfUsePerDay(
        averageHoursOfUsePerDay && averageHoursOfUsePerDay > 24
          ? 24
          : averageHoursOfUsePerDay
      );
      handleHoursClick();
    }
    else return;
  }
  const handleMilesClick = () => {
    let arrOfCol = [
      "TotalHours",
      "ProjectedHours",
      "HoursLeft",
      "ProjectedCost",
      "ProjectedFlippingDate",
      "HoursPerTreadDepth",
    ];

    const displayColSection = fleetSurveyTableData?.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      return flag;
    });

    setDisplayColumns(displayColSection);
    setDisplayColOptions(displayColSection);

    let currentHeadCells = originalFleetSurveyTableData?.headCells;
    let showColumnsUpdated = fleetSurveyDataSetter.setDisplayColumnData(
      currentHeadCells,
      displayColSection
    );
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false,
      });

      setPrintDataNotPDF({
        ...printDataNotPDF,
        selectedColShow: showColumnsUpdated,
        filterApplied: false,
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true,
      });
      setPrintDataNotPDF({
        ...printDataNotPDF,
        selectedColShow: showColumnsUpdated,
        filterApplied: true,
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };
  const handleHoursClick = () => {
    let arrOfCol = [
      "TotalMiles",
      "ProjectedMiles",
      "MilesLeft",
      "ProjectedCostPerMile",
      "ProjectedFlippingDateBYMILE",
      "MilesPerTreadDepth",
    ];
    const displayColSection = fleetSurveyTableData?.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      return flag;
    });
    setDisplayColumns(displayColSection);
    setDisplayColOptions(displayColSection);

    let currentHeadCells = originalFleetSurveyTableData?.headCells;
    let showColumnsUpdated = fleetSurveyDataSetter.setDisplayColumnData(
      currentHeadCells,
      displayColSection
    );
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false,
      });
      setPrintDataNotPDF({
        ...printDataNotPDF,
        selectedColShow: showColumnsUpdated,
        filterApplied: false,
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true,
      });
      setPrintDataNotPDF({
        ...printDataNotPDF,
        selectedColShow: showColumnsUpdated,
        filterApplied: true,
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };
  return (
    <div className="container fleet-root-class">
      <ByTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-compound-container">
          <Card className="by-compound-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters-tireflipping">
                  <Grid container>
                    <Grid item xs={2} className="flipping-input">
                      <div className="tire-flipping-dropdown">
                        <InputLabel className="filter-table-label">
                          {(!toggleHourMiles ||
                            toggleHourMiles === "Hours") && (
                            <span className="table-toolbar-label-text">
                              {
                                globalizationMapping.REPORTCOMMONWORDS
                                  .AvgHoursperDay
                              }
                            </span>
                          )}
                          {toggleHourMiles && toggleHourMiles === "Miles" && (
                            <span className="table-toolbar-label-text">
                              {
                                globalizationMapping.REPORTCOMMONWORDS
                                  .AvgMilesperDay
                              }
                            </span>
                          )}
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          value={averageHoursOfUsePerDay}
                          onChange={handleUpdateAverageHoursOfUse}
                          type="number"
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2} className="flipping-input">
                      <div className="tire-flipping-dropdown">
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">
                            {
                              globalizationMapping.REPORTCOMMONWORDS
                                .AvgDaysperWeek
                            }
                          </span>
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          value={averageWorkDaysPerWeek}
                          onChange={handleUpdateAverageWeekDays}
                          type="number"
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3} className="flipping-input">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {
                            globalizationMapping.VEHICLEREPORTS.TIREFLIPPING
                              .tireFlippingBasedOn
                          }
                        </span>
                      </InputLabel>
                      <Select
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="select-drop-down"
                        value={tireFlippingBasedOn}
                        name="tire flipping based on"
                        variant="outlined"
                        onChange={handleUpdateTireFlippingBasedOn}
                      >
                        <option value="AverageWear">
                          {
                            globalizationMapping.VEHICLEREPORTS.TIREFLIPPING
                              .averageWearToZero
                          }
                        </option>
                        <option value="WearRateDifference">
                          {
                            globalizationMapping.VEHICLEREPORTS.TIREFLIPPING
                              .wearRateDifference
                          }
                        </option>
                      </Select>
                    </Grid>
                    <Grid item xs={3}>
                      <div
                        className="display-columns-container"
                        id="dashboard-display-column"
                      >
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">
                            {
                              globalizationMapping.REPORTCOMMONWORDS
                                .ChangeHoursAndMiles
                            }
                          </span>
                        </InputLabel>
                        <Select
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className="manage-vehicle-action-dropdown"
                          variant="outlined"
                          value={toggleHourMiles}
                          onChange={(e) => actionChanged(e.target.value)}
                        >
                          <option value={"Hours"}>
                            {globalizationMapping.MANAGEVEHICLE.HOURS}
                          </option>
                          <option value={"Miles"}>{toggleMile}</option>
                        </Select>
                      </div>
                    </Grid>
                    {tireFlippingBasedOn === "WearRateDifference" && (
                      <Grid item xs={3}>
                        <div>
                          <InputLabel className="filter-table-label">
                            {tireFlippingBasedOn === "WearRateDifference" && (
                              <span className="table-toolbar-label-text">
                                {
                                  globalizationMapping.VEHICLEREPORTS
                                    .TIREFLIPPING.wearRateDifferenceExceed
                                }
                              </span>
                            )}
                          </InputLabel>
                          <TextField
                            variant="outlined"
                            value={wearRateValue}
                            onChange={handleUpdateWearRateValue}
                            type="number"
                            inputProps={{ maxLength: 50 }}
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                    isVehicleReport={true}
                  />
              </div>
              <div className="tire-flipping-filters">
                <Grid container>
                  <Grid item xs={2}>
                    <div className="search-table-container">
                      <form
                        noValidate
                        autoComplete="off"
                        onSubmit={formSubmitHandler}
                      >
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">
                            {globalizationMapping.REPORTCOMMONWORDS.SearchTable}
                          </span>
                        </InputLabel>
                        <TextField
                          id="input-with-icon-textfield"
                          variant="outlined"
                          placeholder={
                            globalizationMapping.REPORTCOMMONWORDS.Search
                          }
                          value={searchValue}
                          onChange={searchTableData}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  className="keyword-search-icon-img"
                                  alt=""
                                  src={SearchIcon}
                                />
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 50 }}
                        />
                      </form>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="display-columns-container-tireflipping"
                      id="dashboard-display-column"
                    >
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {
                            globalizationMapping.REPORTCOMMONWORDS
                              .DisplayColumns
                          }
                        </span>
                      </InputLabel>
                      <MultiSelect
                        options={displayColOptions}
                        value={displayColumns}
                        onChange={changeDisplayColumns}
                        overrideStrings={{
                          selectSomeItems:
                            globalizationMapping.REPORTCOMMONWORDS.SELECT,
                          allItemsAreSelected:
                            globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                          selectAll:
                            globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                          search: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={3}>
                    <div
                      className="
                          exclude-used-tires-tireflipping"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={excludeUsedTires}
                            onChange={handleExcludeUsedTires}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={globalizationMapping.GRAPH.ExcludeUsedTires}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="tire-flipping-update">
                      <button
                        className="update-button"
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateReport}
                        disabled={noData === true ? true : false}
                      >
                        {globalizationMapping.INSPECT.UPDATE}
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className="data-summary-table">
                <TireFlippingTable
                  tableData={fleetSurveyTableData}
                  showColumns={showColumns}
                  filterApplied={filterApplied}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <EmailExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
        tireFlipping={true}
      />

      <TireFlippingFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default TireFlipping;
