import React from "react";
import Globalization from "../../../globalization/index";
import { Modal, Backdrop, Fade, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@material-ui/core";
import "./CommonPopup.css";
import CloseIcon from "../../../assets/close-icon.svg";
import FleetSurveyPDF from "../export-options/fleet-survey-pdf/FleetSurveyPdf";
import TireForecastPDF from "../export-options/tire-forecast-pdf/TireForecastPdf";
import TireInflationPDF from "../export-options/tire-inflation-pdf/TireInflationPdf";
import DailyJobSummaryPDF from "../export-options/daily-job-summary-pdf/DailyJobSummaryPdf";
import TireNeedsForecastPDF from "../export-options/tire-needs-forecast-pdf/TireNeedsForecastPdf";
import TireRegisterPDF from "../export-options/tire-register-pdf/TireRegisterPDF";
import TiresReportPDFMake from "../export-options/Tires-Report-pdf/TiresReportPDFMake";
import RemovedTirePDF from "../export-options/removed-tire-pdf/RemovedTirePdf";
import Spinner from "../../spinner/spinner";
import NewVsScrapPDF from "../export-options/new-vs-scrap-pdf/NewVsScrapPDF";
import AchievedHoursReportPDF from "../export-options/achieved-hours-report-pdf/AchievedHoursReportPDF";
import ByVehicleWheelPositionReportPDF from "../export-options/byvehicle-wheelposition-report-pdf/ByVehicleWheelPositionReportPDF";

class CommonPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.whatToExportSelection(this.props),
      spinner: false
    };
  }

  globalization = new Globalization();

  whatToExportSelection = (props) => {
    if (props?.noGraph === true) {
      return "Table";
    } else if (props?.notable === true) {
      return "Graph";
    } else {
      return "All";
    }
  };
  handleRadioChange = (event) => {
    this.setState({ value: event.target.value, spinner: false });
  };
  getFilename = () => {
    return this.props.tableData.reportName.trim().replace(/ /g, "_") + ".pdf";
  };

  printOrExportPdf = async () => {
    this.setState({ spinner: true });
    const sendProps = {
      tableData: this.props.tableData,
      printOrPdf: this.props.printOrPdf,
      value: this.state.value
    };
    if (this.props.tableData2) {
      sendProps.tableData2 = this.props.tableData2;
    }
    let tableGraph = {};

    if (this.props.tableData.reportName === this.globalization.TIREREPORTS.OTHERTIRES.RemoveTireReport) {
      tableGraph = new RemovedTirePDF(sendProps);
    } else if (this.props.tableData.reportName === this.globalization.VEHICLEREPORTS.BYFLEET.FleetSurvey) {
      const propsWithNewLabels = JSON.parse(JSON.stringify(sendProps));
      propsWithNewLabels.tableData.headCells.forEach((headCell, index) => {
        const key = this.props.keys[index].split(".").key;
        if (this.globalization["FLEETSURVEY"][key]) {
          headCell.label = this.globalization["FLEETSURVEY"][key];
        } else {
          headCell.label = headCell.key;
        }
      });
      tableGraph = new FleetSurveyPDF(propsWithNewLabels);
    } else if (
      this.props.tableData.reportName === this.globalization.VEHICLEREPORTS.BYTIRES.TireInflation ||
      this.props.tableData.reportName === this.globalization.VEHICLEREPORTS.BYTIRES.TireRotation ||
      this.props.tableData.reportName === this.globalization.VEHICLEREPORTS.BYTIRES.TireFlipping
    ) {
      tableGraph = new TireInflationPDF(sendProps);
    } else if (this.props.tableData.reportName === this.globalization.VEHICLEREPORTS.BYTIRES.TireForecast) {
      const propsWithNewLabels = JSON.parse(JSON.stringify(sendProps));
      propsWithNewLabels.tableData.headCells.forEach((headCell, index) => {
        const key = this.props.keys[index].split(".").key;
        if (!this.globalization["TIREFORECAST"][key] === this.globalization["TIREFORECAST"]["TOTALMILES"]) {
          // remove the total miles since it was not on the table
          if (this.globalization["TIREFORECAST"][key]) {
            headCell.label = this.globalization["TIREFORECAST"][key];
          } else {
            headCell.label = headCell.key;
          }
        }
      });
      tableGraph = new TireForecastPDF(propsWithNewLabels);
    } else if (this.props.tableData.reportName === this.globalization.TIREREPORTS.TIRESINSERVICE.DailyJobSummary) {
      tableGraph = new DailyJobSummaryPDF(sendProps);
    } else if (this.props.tableData.reportName === this.globalization.TIREREPORTS.TIRESINSERVICE.TireNeedsForecast) {
      tableGraph = new TireNeedsForecastPDF(sendProps);
    } else if (
      this.props.tableData.reportName === this.globalization.TIREREPORTS.OTHERTIRES.TireRegisterReport ||
      this.props.tableData.reportName === this.globalization.TIREREPORTS.SCRAPTIRES.ByIndividualTire ||
      this.props.tableData.reportName === this.globalization.TIREREPORTS.TIRESINSERVICE.TireInstallation ||
      this.props.tableData.reportName === this.globalization.TIREREPORTS.TIRESINSERVICE.TireHistory
    ) {
      tableGraph = new TireRegisterPDF(sendProps);
    } else if (this.props.tableData.reportName === this.globalization.TIREREPORTS.OTHERTIRES.NewVSScrap) {

      tableGraph = new NewVsScrapPDF(sendProps);
    } else if (this.props.tableData.reportName === this.globalization.TIREREPORTS.OTHERTIRES.AchievedHoursReport) {

      tableGraph = new AchievedHoursReportPDF(sendProps);
    } else if (this.props.tableData.reportName === this.globalization.TIREREPORTS.TIRESINSERVICE.ByVehicleWheelPosition) {
      tableGraph = new ByVehicleWheelPositionReportPDF(sendProps);
    } else {
      tableGraph = new TiresReportPDFMake(sendProps);
    }
    let value = this.whatToExportSelection(this.props);
    switch (this.props.printOrPdf) {
      case "print":
        {
          const data = await tableGraph.PDFMake();
          const win = window.open("", "_blank");
          await data.print({}, win);
          this.setState({ value: value, spinner: false });
        }
        break;
      case "pdf":
        {
          const dataPdf = await tableGraph.PDFMake();
          await dataPdf.download(this.getFilename());
          this.setState({ value: value, spinner: false });
        }
        break;
      default:
        break;
    }
    this.handleClose();
  };
  handleClose = () => {
    let value = this.whatToExportSelection(this.props);
    this.setState({ value: value, spinner: false });
    this.props.onClose();
  };
  render() {
    return (
      this.props.openModal === true && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalParent"
          open={this.props.openModal}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          disableScrollLock={true}
        >
          <Fade in={this.props.openModal}>
            <div className="modal">
              <div className="change-modal-container center-popup-modal">
                <div className="change-modal-header">
                  {this.state.spinner === true && <Spinner />}
                  <div className="change-modal-title-container">
                    <div className="change-modal-title">{this.props.title}</div>
                    <div className="close-icon-container" onClick={this.props.onClose}>
                      <img src={CloseIcon} className="modal-close-icon" alt="close-icon" />
                    </div>
                  </div>
                  <div className="change-modal-description">{this.props.subtitle}</div>
                </div>
                <div className="change-modal-body-container">
                  <FormControl component="fieldset">
                    <FormLabel className="modal-subtitle">{this.props.fieldTitle}</FormLabel>
                    <RadioGroup row onChange={this.handleRadioChange}>
                      <FormControlLabel
                        className="modal-subtitle"
                        checked={this.state.value === "All"}
                        value="All"
                        control={<Radio color="primary" />}
                        label={this.globalization.POPUPWORDS.SelectAll}
                        disabled={this.props.noGraph || this.props.notable}
                      />
                      <FormControlLabel
                        className="modal-subtitle"
                        checked={this.state.value === "Graph"}
                        value="Graph"
                        control={<Radio color="primary" />}
                        label={this.globalization.POPUPWORDS.OnlyGraph}
                        disabled={this.props.noGraph}
                      />
                      <FormControlLabel
                        className="modal-subtitle"
                        checked={this.state.value === "Table"}
                        value="Table"
                        control={<Radio color="primary" />}
                        label={this.globalization.POPUPWORDS.OnlyTable}
                        disabled={this.props.notable}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="border-bottom"></div>
                <div className="modal-button-group">
                  <div>
                    <button className="change-modal-cancel-btn" onClick={this.handleClose}>
                      {this.globalization.USER_PREFERENCE.CANCELBUTTON}
                    </button>
                  </div>
                  <div>
                    <button className="change-modal-proceed-btn" onClick={this.printOrExportPdf}>
                      {this.globalization.FORMS.PROCEED}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )
    );
  }
}

export default CommonPopup;
