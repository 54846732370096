import React from 'react';
import "../common-modal/CommonPopup.css";
import './GraphModalComponent.css';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { Line } from 'react-chartjs-2';


class GraphModal extends React.Component {
  handleClose = () => {
    this.props.setModalHandlerGraph(false);
  };

  previousItem = () => {
    if (this.props.selectedChart > 0) {
      let chartNo = this.props.selectedChart - 1
      this.props.setSelectedChart(chartNo);
      this.props.modalData[chartNo].option.title.display = false;
    }
  };

  nextItem = () => {
    if (this.props.selectedChart < 4) {
      let chartNo = this.props.selectedChart + 1
      this.props.setSelectedChart(chartNo);
      this.props.modalData[chartNo].option.title.display = false;
    }
  };

  render() {
    return (
      this.props.modalHandlerGraph && this.props.modalHandlerGraph.handleGraph === true && (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalParent"
            open={this.props.modalHandlerGraph.handleGraph}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
            closeAfterTransition
            disableScrollLock={true}
          >
            <Fade in={this.props.modalHandlerGraph.handleGraph}>
              <div className="modal">
                <div className="change-modal-container center-modal">
                  <div className="change-modal-header">
                    <div className="change-modal-title-container graph-title">
                      <div
                        className="previous-icon-container"
                        onClick={this.previousItem}
                      >
                        <img
                          src={require("../../../assets/Previous.svg")}
                          className="modal-previous-icon"
                          alt="close-icon"
                        />
                      </div>
                      <div className="graph-modal-title">
                        {this.props.modalData[this.props.selectedChart].option.title.text}
                      </div>
                      <div
                        className="next-icon-container"
                        onClick={this.nextItem}
                      >
                        <img
                          src={require("../../../assets/next.svg")}
                          className="modal-next-icon"
                          alt="close-icon"
                        />
                      </div>
                      <div
                        className="close-icon-container graph-close"
                        onClick={this.handleClose}
                      >
                        <img
                          src={require("../../../assets/close-icon.svg")}
                          className="modal-close-icon"
                          alt="close-icon"
                        />
                      </div>
                    </div>
                    <div className="change-modal-body-container graph-modal-body">
                      {Object.keys(this.props.modalData[this.props.selectedChart].graphData).length > 0 && (
                        <Line data={this.props.modalData[this.props.selectedChart].graphData} options={this.props.modalData[this.props.selectedChart].option} height={200}
                          width={500} key={this.props.selectedChart} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      )
    )
  }
}

export default GraphModal