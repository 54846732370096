import { SERVER } from '../../../services/api-urls';
import CommonService from '../../../services/common-api-service';

export default class RemoveTireApis {
    cs;
    constructor() {
        this.cs = new CommonService();
    }

    removeInstalledTire = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.removeInstalledTire}`, 'put', req);
    };
    removeFromVehicle = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.removeFromVehicle}`, 'put', req);
    };

}
