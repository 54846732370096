import { Button, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../common-components/breadcrumbs/breadcrumbs";
import HeaderApi from "../../common-components/header/services/header-apis";
import Globalization from "../../globalization";
import { StateContext } from "../../services/StateProvider";
import "./inspect-tire.css";
import TireDetailsGrid from "./inspect-tire-details";
import TireInspectionForm from "./tire-inspection-form";
import Spinner from "../../common-components/spinner/spinner";
import InspectTireApis from "./services/inspect-tire-apis";
import { useHistory, useLocation } from "react-router";
import { accountInfoIdsFormatter } from "../../services/AccountInfoHandler";
import CancelConfirmationPopup from "../cancel-confirmatipn-popup/cancel-popup";
import FlashMessage from "../../common-components/pop-up-notification/notification-snackbar";
import Modal from "@material-ui/core/Modal";
import AlertPopup from "../../common-components/alert-pop-up/alert-popup";

const useStyles = makeStyles({
  width: "100%",
  next: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5,
  },
});

const InspectTire = () => {
  const history = useHistory();
  let vehicleNotUpdated;
  const [globalAccountState] = useContext(StateContext);
  const globalizationMapping = new Globalization();
  const titleTexts = globalizationMapping.INSPECTNREMOVETIRE;
  const tireFormCaptions = globalizationMapping.FORMS;
  const validationTexts = tireFormCaptions.VALIDATIONTEXTS;
  const notificationTexts = tireFormCaptions.NOTIFICATIONTEXTS;
  const styling = useStyles();
  const locationObj = useLocation();
  const [prevPath, setPrevPath] = useState(
    locationObj.state && locationObj.state.fromPath
      ? locationObj.state.fromPath
      : "/manage-tire"
  );
  const [historyState, setHistoryState] = useState();
  const [imageSession, setImageSession] = useState(
    sessionStorage.getItem("InspectTireImages")
  );
  const myBreadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME,
    },
    {
      path: "",
      name: titleTexts.INSPECTTIRETITLE,
    },
  ];

  const headerApiObj = new HeaderApi();
  const inspectTireApi = new InspectTireApis();

  const [masterData, setMasterData] = useState({});
  const [conditions, setConditions] = useState({
    wearCondition: "",
    casingCondition: "",
  });

  const [homeObj, setHomeObj] = useState({
    accountIds: [],
    actionDate: new Date().toISOString(),
    actionDescription: "",
    locationIds: [],
    vehicleId: "",
  });

  const [tireInspectionDetails, setTireInspectionDetails] = useState({
    action: "Inspect",
    brandNo: null,
    casingConditionId: null,
    comments: "",
    currentHours: 0,
    currentMiles: 0,
    currentPressure: 0,
    currentTreadDepth: 0,
    dispositionId: 0,
    insideTread: 0,
    inspectionDate: "",
    locationId: 0,
    middleTread: 0,
    outsideTread: 0,
    parentAccountId: 0,
    pressureType: "",
    rimDispositionId: 3,
    tireId: "",
    tireSerialNo: "",
    vehicleId: "",
    wearConditionId: null,
    wheelPosition: "",
  });

  const [tireImages, setTireImages] = useState([]);
  const [imgsrc, setImgSrc] = useState([]);

  const [casingConditions, setCasingConditions] = useState([]);
  const [wearConditions, setWearConditions] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState("Vehicle 001");
  const [accLocationDetails, setAccLocationDetails] = useState("");
  const [locationDetails, setLocationDetails] = useState({});
  const [unitPreferences, setUnitPreferences] = useState({});
  const [formHelperTexts, setFormHelperTexts] = useState({});
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [flashMessageState, setFlashMessageState] = useState(locationObj);
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [submitFlag, setSubmitFlag] = useState(false);

  const [isFirstLoad, setFirstLoad] = useState(false);
  const [storePreviousComments, setStorePreviousComments] = useState('')

  const [tireDetails, setTireDetails] = useState({
    brandNo: null,
    casingConditionId: null,
    comments: "",
    createdBy: "",
    currentHours: "",
    currentMiles: "",
    currentPressure: "",
    currentTreadDepth: "",
    dispositionId: "",
    imagesLocation: null,
    insideTread: "",
    inspectionDate: new Date().toISOString(),
    locationId: "",
    manufacturerId: "",
    manufacturerName: "",
    middleTread: 0,
    mileageType: "",
    mountedRimId: null,
    originalTread: "",
    outsideTread: "",
    parentAccountId: "",
    percentageWorn: "",
    pressureType: "",
    registeredDate: "2012-06-11T00:00:00",
    removalReasonId: null,
    removeAt: 0,
    sizeId: "",
    sizeName: "",
    tireId: "",
    tireSerialNo: "",
    typeId: "",
    typeName: "",
    vehicleId: "",
    wearConditionId: null,
    wearNonSkid: null,
    wheelPosition: "",
  });

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setShowSpinner(true);
    const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIdArr = accountInfoObject.accountIds;

    setUnitPreferences({
      pressure: globalAccountState.accountInfo.userSetting.userPressureUnit,
      treadDepth:
        globalAccountState.accountInfo.userSetting.userMeasurementSystemValue,
    });
    if (locationObj.state) {
      let tire = {};
      if (
        locationObj.state.tireDetails !== undefined &&
        locationObj.state.tireId === undefined
      ) {
        setTireDetails({ ...locationObj.state.tireDetails });
        tire = locationObj.state.tireDetails;
        if (tire.vehicleId !== locationObj.state.id) {
          tire = {
            ...tire,
            vehicleId: locationObj.state.id,
          }
        }
        vehicleNotUpdated = locationObj.state.vehicleNotUpdated;

        setTireInspectionDetails({
          ...tireInspectionDetails,
          brandNo: tire.brandNo,
          casingConditionId: tire.casingConditionId,
          comments: tire.comments,
          currentHours: tire.currentHours,
          currentMiles: tire.currentMiles,
          currentPressure: tire.currentPressure,
          currentTreadDepth: tire.currentTreadDepth,
          dispositionId: tire.dispositionId,
          inspectionDate:  new Date().toISOString(),
          locationId: tire.locationId,
          insideTread: tire.insideTread ? tire.insideTread : 0,
          middleTread: tire.middleTread ? tire.middleTread : 0,
          outsideTread: tire.outsideTread,
          parentAccountId: tire.parentAccountId,
          pressureType:
            tire.pressureType === null ||
              tire.pressureType === "H" ||
              tire.pressureType === ""
              ? "H"
              : "C",
          tireId: tire.tireId,
          tireSerialNo: tire.tireSerialNo,
          vehicleId: tire.vehicleId,
          wearConditionId: tire.wearConditionId,
          wheelPosition: tire.wheelPosition,
        });
        setHomeObj({
          ...homeObj,
          accountIds: accountIdArr,
          actionDescription: "INSPECT.INSPECTED " + tire.vehicleNumber,
          vehicleId: tire.vehicleId,
        });
        setVehicleNumber(tire.vehicleNumber);
        headerApiObj
          .getParentAccountDetails(tire.parentAccountId)
          .then((accData) => {
            if (accData.model) {
              headerApiObj
                .getLocationById(tire.locationId)
                .then((locationData) => {
                  if (locationData.model) {
                    setAccLocationDetails(
                      accData.model.accountName +
                      " - " +
                      locationData.model.locationName
                    );
                    setLocationDetails(locationData.model);
                  }
                });
            }
          });
      } else if (
        locationObj.state.tireId !== undefined &&
        locationObj.state.vehicleId !== undefined
      ) {
        headerApiObj
          .getInspectionRecordForVehicle(locationObj.state.vehicleId)
          .then((vehicleRcd) => {
            if (vehicleRcd.model) {
              setAllowSubmit(!vehicleNotUpdated);
              tire = vehicleRcd.model.installedTires.find(
                (installedTire) =>
                  installedTire.tireId === locationObj.state.tireId
              );

              setVehicleNumber(vehicleRcd.model.vehicleNumber);
              setTireDetails(tire);
              setTireInspectionDetails({
                ...tireInspectionDetails,
                brandNo: tire.brandNo,
                casingConditionId: tire.casingConditionId,
                comments: tire.comments,
                currentHours: tire.currentHours,
                currentMiles: tire.currentMiles,
                currentPressure: tire.currentPressure,
                currentTreadDepth: tire.currentTreadDepth,
                dispositionId: tire.dispositionId,
                inspectionDate: new Date().toISOString(),
                locationId: tire.locationId,
                insideTread: tire.insideTread ? tire.insideTread : 0,
                middleTread: tire.middleTread ? tire.middleTread : 0,
                outsideTread: tire.outsideTread,
                parentAccountId: tire.parentAccountId,
                pressureType:
                  tire.pressureType === null ||
                    tire.pressureType === "H" ||
                    tire.pressureType === ""
                    ? "H"
                    : "C",
                tireId: tire.tireId,
                tireSerialNo: tire.tireSerialNo,
                vehicleId: tire.vehicleId,
                wearConditionId: tire.wearConditionId,
                wheelPosition: tire.wheelPosition,
              });
              setHomeObj({
                ...homeObj,
                accountIds: accountIdArr,
                actionDescription:
                  "INSPECT.INSPECTED " + vehicleRcd.model.vehicleNumber,
                vehicleId: tire.vehicleId,
              });
              headerApiObj
                .getParentAccountDetails(tire.parentAccountId)
                .then((accData) => {
                  if (accData.model) {
                    headerApiObj
                      .getLocationById(tire.locationId)
                      .then((locationData) => {
                        if (locationData.model) {
                          setAccLocationDetails(
                            accData.model.accountName +
                            " - " +
                            locationData.model.locationName
                          );
                          setLocationDetails(locationData.model);
                        }
                      });
                  }
                });
              initPath(tire.tireId, tire.dispositionId);
            }
          });
      } else {
        return;
      }
    } else {
      history.push("/home");
    }

    headerApiObj.getMasterDataWeb().then((masterData) => {
      if (masterData.model) {
        setMasterData(masterData.model);

        let casingListArr = masterData.model.casingCondition;
        let wearListArr = masterData.model.wearConditions;
        casingListArr.sort(sortFieldEntries("casingConditionName"));
        wearListArr.sort(sortFieldEntries("wearConditionName"));
        setCasingConditions(casingListArr);
        setWearConditions(wearListArr);
      }
    });

    let tireIdForPreviousCommments = locationObj.state.tireId !== undefined ? locationObj.state.tireId : locationObj.state.tireDetails.tireId;
    headerApiObj.getTireById(tireIdForPreviousCommments).then((tireData) => {
      if (tireData.model) {
        setStorePreviousComments(tireData.model.tireHistory1.filter(x => x.comments != "")[0].comments)
      }
    });

    setFirstLoad(true);
    InitNotification();

    setTimeout(() => {
      setShowSpinner(false);
    }, 5000);
    setAllowSubmit(!vehicleNotUpdated);
    setSubmitFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const InitNotification = () => {
    if (locationObj.state && locationObj.state.updatedSection) {
      if (locationObj.state.updatedSection === "successQuickInspect") {
        if (locationObj.state.updateStatus) {
          setShowNotification(true);
        }
      } else {
        setTimeout(() => {
          setShowNotification(false);
          setFlashMessageState("");
        }, 5000);
      }
      setFlashMessageState(locationObj);
    }
  };

  const initPath = (id, disp) => {
    if (locationObj.state && locationObj.state.fromPath) {
      setPrevPath(locationObj.state.fromPath);
      if (locationObj.state.fromPath === "/manage-tire")
        setHistoryState({ dispositionId: disp });
      else if (locationObj.state.fromPath === "/view-tire")
        setHistoryState({ tireId: id });
      else return;
    }
  };

  useEffect(() => {
    if (
      masterData.casingCondition &&
      masterData.wearConditions &&
      tireDetails.casingConditionId &&
      tireDetails.wearConditionId
    ) {
      let casing = masterData.casingCondition.find((condition) => {
        return condition.casingConditionId === tireDetails.casingConditionId;
      });
      let wear = masterData.wearConditions.find((condition) => {
        return condition.wearConditionId === tireDetails.wearConditionId;
      });
      if (casing !== undefined && wear !== undefined) {
        setConditions({
          casingCondition: casing.casingConditionName,
          wearCondition: wear.wearConditionName,
        });
      } else if (casing === undefined && wear !== undefined) {
        setConditions({ ...conditions, wearCondition: wear.wearConditionName });
      } else if (casing !== undefined && wear === undefined) {
        setConditions({
          ...conditions,
          casingCondition: casing.casingConditionName,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tireDetails, masterData]);

  const sortFieldEntries = (property) => {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;

      return 0;
    };
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  // Remove Tire function - code for future reference.
  // const handleRemoveClick = () => {
  //   delete tireInspectionDetails.wheelPosition;
  //   history.push({
  //     pathname: "/remove-tire",
  //     state: {
  //       accountLocationString: accLocationDetails,
  //       locationDetailsObj: locationDetails,
  //       vehicleNo: vehicleNumber,
  //       tireData: tireDetails,
  //       vehicleNotUpdated: !allowSubmit,
  //       inspectionData: {
  //         ...tireInspectionDetails,
  //         action: "Remove",
  //         removalReasonId: 0,
  //       },
  //       homeObjData: homeObj,
  //       masterData: masterData,
  //       fromPath: prevPath,
  //     },
  //   });
  // };

  const handleFieldChange = (field, value) => {
    switch (field) {
      case "inspdate":
        if (value) {
          let date = value;
          date =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
          setTireInspectionDetails({
            ...tireInspectionDetails,
            inspectionDate: date,
          });
          setHomeObj({ ...homeObj, actionDate: date });
        } else
          setTireInspectionDetails({
            ...tireInspectionDetails,
            inspectionDate: "",
          });
        break;
      case "outtread":
        if (!isNaN(value))
          setTireInspectionDetails({
            ...tireInspectionDetails,
            outsideTread: value,
          });
        else
          setTireInspectionDetails({
            ...tireInspectionDetails,
            outsideTread: null,
          });
        break;
      case "intread":
        if (!isNaN(value))
          setTireInspectionDetails({
            ...tireInspectionDetails,
            insideTread: value,
          });
        else
          setTireInspectionDetails({
            ...tireInspectionDetails,
            insideTread: null,
          });
        break;
      case "midtread":
        if (!isNaN(value))
          setTireInspectionDetails({
            ...tireInspectionDetails,
            middleTread: value,
          });
        else
          setTireInspectionDetails({
            ...tireInspectionDetails,
            middleTread: null,
          });
        break;
      case "pressuretype":
        if (value)
          setTireInspectionDetails({
            ...tireInspectionDetails,
            pressureType: "H",
          });
        else
          setTireInspectionDetails({
            ...tireInspectionDetails,
            pressureType: "C",
          });
        break;
      case "pressure":
        if (!isNaN(value))
          setTireInspectionDetails({
            ...tireInspectionDetails,
            currentPressure: value,
          });
        else
          setTireInspectionDetails({
            ...tireInspectionDetails,
            currentPressure: null,
          });
        break;
      case "casing":
        setTireInspectionDetails({
          ...tireInspectionDetails,
          casingConditionId: value,
        });
        break;
      case "wear":
        setTireInspectionDetails({
          ...tireInspectionDetails,
          wearConditionId: value,
        });
        break;
      case "comments":
        setTireInspectionDetails({ ...tireInspectionDetails, comments: value });
        break;
      case "images":
        if (imgsrc.length < 5) {
          if (value.files[0]) {
            let reader = new FileReader();
            reader.readAsDataURL(value.files[0]);
            reader.onloadend = () => {
              let imageSet = [...imgsrc];
              imageSet.push(reader.result);
              setImgSrc([...imageSet]);
              let newImageSet = [...tireImages];
              newImageSet.push(value.files[0]);
              setTireImages(newImageSet);
              let detailsImage = JSON.parse(imageSession);

              if (detailsImage) {
                let tireSerialNo = detailsImage.tireSerialNo;
                if (detailsImage.vehicleId === tireDetails.vehicleId) {
                  let index = tireSerialNo.findIndex(
                    (val) => val.tireSerialNo === tireDetails.tireSerialNo
                  );
                  if (index !== -1) {
                    detailsImage.tireSerialNo[index].img = imageSet;
                  } else {
                    tireSerialNo.push({
                      tireSerialNo: tireDetails.tireSerialNo,
                      wheelPosition: tireDetails.wheelPosition,
                      img: imageSet,
                    });
                    detailsImage.tireSerialNo = tireSerialNo;
                  }
                  setImageSession(JSON.stringify(detailsImage));
                } else {
                  tireSerialNo = [];
                  let jsonSerialNo = {
                    tireSerialNo: tireDetails.tireSerialNo,
                    wheelPosition: tireDetails.wheelPosition,
                    img: imageSet,
                  };
                  tireSerialNo.push(jsonSerialNo);
                  detailsImage = {
                    vehicleId: tireDetails.vehicleId,
                    tireSerialNo: tireSerialNo,
                  };
                }
                setImageSession(JSON.stringify(detailsImage));
              } else {
                let tireSerialNo = [];
                tireSerialNo = [];
                let jsonSerialNo = {
                  tireSerialNo: tireDetails.tireSerialNo,
                  wheelPosition: tireDetails.wheelPosition,
                  img: imageSet,
                };
                tireSerialNo.push(jsonSerialNo);
                detailsImage = {
                  vehicleId: tireDetails.vehicleId,
                  tireSerialNo: tireSerialNo,
                };
                setImageSession(JSON.stringify(detailsImage));
              }

              value.value = null;
            };
          }
        } else
          setFormHelperTexts({
            ...formHelperTexts,
            imgHelper: validationTexts.IMGLIMIT5,
          });
        break;
      default:
        break;
    }
  };

  const deleteImage = (index) => {
    let imgsArr = [...imgsrc];
    let imgfiles = [...tireImages];
    imgsArr.splice(index, 1);
    imgfiles.splice(index, 1);
    setTireImages(imgfiles);
    setImgSrc(imgsArr);
    let detailsImage = JSON.parse(imageSession);
    if (detailsImage) {
      let tireSerialNo = detailsImage.tireSerialNo;
      if (detailsImage.vehicleId === tireDetails.vehicleId) {
        let index = tireSerialNo.findIndex(
          (val) => val.tireSerialNo === tireDetails.tireSerialNo
        );
        if (index !== -1) {
          detailsImage.tireSerialNo[index].img = imgsArr;
        }
      }
      setImageSession(JSON.stringify(detailsImage));
    }
    setFormHelperTexts({ ...formHelperTexts, imgHelper: "" });
  };

  useEffect(() => {
    if (isFirstLoad) {
      let outside =
        isNaN(parseInt(tireInspectionDetails.outsideTread)) ||
          tireInspectionDetails.outsideTread === null
          ? 0
          : parseInt(tireInspectionDetails.outsideTread);
      let inside =
        isNaN(parseInt(tireInspectionDetails.insideTread)) ||
          tireInspectionDetails.insideTread === null
          ? 0
          : parseInt(tireInspectionDetails.insideTread);
      let middle =
        isNaN(parseInt(tireInspectionDetails.middleTread)) ||
          tireInspectionDetails.middleTread === null
          ? 0
          : parseInt(tireInspectionDetails.middleTread);

      if (locationDetails.noOfTreadDepthReadings === 3) {
        let result;
        if (locationDetails.treadDepthMethodId === 1) {
          result = (outside + inside + middle) / 3;
          if (!Number.isInteger(result)) result = result.toFixed(2);
        } else if (locationDetails.treadDepthMethodId === 2) {
          result = Math.max(outside, inside, middle);
        } else {
          result = Math.min(outside, inside, middle);
        }
        setTireInspectionDetails({
          ...tireInspectionDetails,
          currentTreadDepth: result,
        });
      } else if (locationDetails.noOfTreadDepthReadings === 2) {
        let result;
        if (locationDetails.treadDepthMethodId === 1) {
          result = (outside + inside) / 2;
          if (!Number.isInteger(result)) result = result.toFixed(2);
        } else if (locationDetails.treadDepthMethodId === 2) {
          result = Math.max(outside, inside);
        } else {
          result = Math.min(outside, inside);
        }
        setTireInspectionDetails({
          ...tireInspectionDetails,
          currentTreadDepth: result,
        });
      } else {
        setTireInspectionDetails({
          ...tireInspectionDetails,
          currentTreadDepth: outside,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tireInspectionDetails.outsideTread,
    tireInspectionDetails.insideTread,
    tireInspectionDetails.middleTread,
    locationDetails,
  ]);

  const validateForm = () => {
    let isFormValid = true;
    let formErrors = {};

    Object.keys(tireInspectionDetails).forEach((key) => {
      if (key === "outsideTread") {
        if (tireInspectionDetails[key] < 0) {
          isFormValid = false;
          formErrors[key] = validationTexts.INVALIDFIELD;
        } else if (tireInspectionDetails[key] === null) {
          isFormValid = false;
          formErrors[key] = validationTexts.MISSINGFIELD;
        } else {
          return;
        }
      }
      if (key === "currentPressure") {
        if (tireInspectionDetails[key] < 0) {
          isFormValid = false;
          formErrors[key] = validationTexts.INVALIDFIELD;
        } else if (tireInspectionDetails[key] === null) {
          isFormValid = false;
          formErrors[key] = validationTexts.MISSINGFIELD;
        } else {
          return;
        }
      }
      if (
        key === "inspectionDate" &&
        (isNaN(parseInt(tireInspectionDetails[key])) ||
          String(tireInspectionDetails[key]) === "")
      ) {
        isFormValid = false;
        formErrors[key] = validationTexts.INVALIDFIELD;
      }
      if (key === "insideTread" || key === "middleTread") {
        if (locationDetails.noOfTreadDepthReadings === 3) {
          if (
            tireInspectionDetails[key] < 0 ||
            tireInspectionDetails[key] === undefined
          ) {
            isFormValid = false;
            formErrors[key] = validationTexts.INVALIDFIELD;
          } else if (tireInspectionDetails[key] === null) {
            isFormValid = false;
            formErrors[key] = validationTexts.MISSINGFIELD;
          } else {
            return;
          }
        }
        if (
          (locationDetails.noOfTreadDepthReadings === 2 ||
            locationDetails.noOfTreadDepthReadings === 3) &&
          key === "insideTread"
        ) {
          if (
            tireInspectionDetails[key] < 0 ||
            String(tireInspectionDetails[key]) === "" ||
            tireInspectionDetails[key] === undefined
          ) {
            isFormValid = false;
            formErrors[key] = validationTexts.INVALIDFIELD;
          }
        }
      }
    });

    setFormHelperTexts(formErrors);
    return isFormValid;
  };

  const handleSubmit = (fromWhere) => {
    const fileUploadFormData = new FormData();
    setSubmitFlag(true);
    if (validateForm() && (allowSubmit || fromWhere === "Popup")) {
      setShowSpinner(true);
      let successStatus = false;
      if (tireImages.length !== 0) {
        let updatedTireInspectionDetails = {
          ...tireInspectionDetails,
          inspectionDate: new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString(),
        }
        inspectTireApi
          .sendTireInspectionData(updatedTireInspectionDetails)
          .then((inspectionResponse) => {
            if (inspectionResponse.didError === false) {
              successStatus = true;

              let timeStamp = new Date().getTime();
              fileUploadFormData.append(
                "inspectionId",
                inspectionResponse.model.inspectionId
              );
              Object.values(tireImages).forEach((img, index) => {
                fileUploadFormData.append(
                  "imgsrc",
                  img,
                  index + "_" + timeStamp + ".png"
                );
              });

              inspectTireApi
                .createTireInspectionImages(fileUploadFormData)
                .then((imgResponse) => {
                  if (imgResponse.didError === false) {
                    inspectTireApi
                      .sendInspectionData({
                        ...tireInspectionDetails,
                        inspectionId: inspectionResponse.model.inspectionId,
                        imagesLocation: imgResponse.message,
                      })
                      .then((finalResponse) => {
                        if (finalResponse.didError === false) {
                          successStatus = true;
                          sessionStorage.setItem(
                            "InspectTireImages",
                            imageSession
                          );
                          headerApiObj
                            .createHomeActivity(homeObj)
                            .then((_response) => {
                              sessionStorage.setItem(
                                "InspectTireImages",
                                imageSession
                              );
                            });
                        } else {
                          successStatus = false;
                        }
                        setShowSpinner(false);
                        history.push({
                          pathname: prevPath,
                          state: {
                            fromPath: prevPath,
                            tireId: tireDetails.tireId,
                            dispositionId: 7,
                            updatedSection: "inspecttire",
                            updateStatus: successStatus,
                            successMessage: notificationTexts.TIREINSPECTED,
                            successMessageDesc:
                              notificationTexts.TIREINSPECTEDDESC.replace(
                                "#1#1",
                                tireDetails.tireSerialNo
                              ).replace("#2#2", vehicleNumber),
                          },
                        });
                      });
                  } else {
                    setShowSpinner(false);
                  }
                });
            }
          });
      } else {

        inspectTireApi
          .sendTireInspectionData(tireInspectionDetails)
          .then((response) => {
            if (response.didError === false) {
              successStatus = true;
              sessionStorage.setItem("InspectTireImages", imageSession);
              headerApiObj.createHomeActivity(homeObj).then((_response) => {
                sessionStorage.setItem("InspectTireImages", imageSession);
              });
            } else {
              successStatus = false;
            }
            setShowSpinner(false);
            history.push({
              pathname: prevPath,
              state: {
                fromPath: prevPath,
                tireId: tireDetails.tireId,
                dispositionId: 7,
                updatedSection: "inspecttire",
                updateStatus: successStatus,
                successMessage: notificationTexts.TIREINSPECTED,
                successMessageDesc: notificationTexts.TIREINSPECTEDDESC.replace(
                  "#1#1",
                  tireDetails.tireSerialNo
                ).replace("#2#2", vehicleNumber),
              },
            });
          });
      }
    }
  };

  return (
    <React.Fragment>
      <div className="standalone-new-header-container">
        <div className="inspect-tire-main-page-container">
          <div className="my-profile-upper-deck">
            <div className="background-map-image background-map-image-inspect-tire">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={myBreadcrumbs} />
                  <div className="scrap-tires-title">
                    {titleTexts.INSPECTTIRETITLE}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSpinner ? (
        <Spinner />
      ) : (
        <div className="manage-vehicle-create-container create-vehicle-form">
          <div className="inspect-tire-container">
            <div className="inspect-tire-inner-container">
              <div className="vehicle-detail-container">
                <div className="vehicle-details-grid">
                  <TireDetailsGrid
                    vehicleNo={vehicleNumber}
                    tireData={tireDetails}
                    conditions={conditions}
                    location={accLocationDetails}
                  // onRemoveTireClick={handleRemoveClick}
                  />
                </div>
              </div>

              <div className="helper-message">
                {tireFormCaptions.MANDATORYFIELDS}{" "}
                <span className="required-mark">*</span>
              </div>

              <div className="inspect-tire-form-container">
                {masterData && tireDetails && (
                  <TireInspectionForm
                    storePreviousComments={storePreviousComments}
                    masterDataSet={masterData}
                    casingConditionList={casingConditions}
                    wearConditionList={wearConditions}
                    tireData={tireDetails}
                    tireInspectionData={tireInspectionDetails}
                    onChangeListener={(field, val) =>
                      handleFieldChange(field, val)
                    }
                    locationData={locationDetails}
                    tireimages={imgsrc}
                    formHelpers={formHelperTexts}
                    deleteImg={(i) => deleteImage(i)}
                    unitPreferences={unitPreferences}
                  />
                )}
              </div>

              <hr className="hr-divider-action" />

              <div className="action-buttons">
                <Button
                  className={styling.cancel}
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {tireFormCaptions.CANCEL}
                </Button>
                <Button
                  className={styling.next}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit("Submit")}
                >
                  {tireFormCaptions.SUBMIT}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showNotification && flashMessageState.state && (
        <FlashMessage
          status={flashMessageState.state.updateStatus}
          message={flashMessageState.state.successMessage}
          description={flashMessageState.state.successMessageDesc}
        />
      )}
      {isCancelModalOpen && (
        <CancelConfirmationPopup
          closeConfirmationModal={() => setCancelModalOpen(false)}
          pathToRedirect={prevPath}
          stateToPass={historyState}
        />
      )}
      <Modal
        open={!allowSubmit && submitFlag}
        onClose={() => setSubmitFlag(false)}
        className="header-change-modal-style"
        disableScrollLock={true}
      >
        <AlertPopup
          description={globalizationMapping.INSPECT.ALERT}
          message={globalizationMapping.INSPECT.ALERTMSG}
          screen="InspectTire"
          allowSubmit={setAllowSubmit}
          onSave={handleSubmit}
          onClose={() => setSubmitFlag(false)}
        />
      </Modal>
    </React.Fragment>
  );
};

export default InspectTire;
