import {
  Backdrop,
  Button,
  InputLabel,
  makeStyles,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Globalization from "../../../../../globalization";
import ManageVehicleListApis from "../../../services/ManageVehicleListApis";
import "./TransferVehicle.css";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import ExpandMoreStyledIcon from "../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles({
  proceed: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5,
  },
});

const TransferVehicleModal = (props) => {
  const manageVehicleApiServices = new ManageVehicleListApis();
  const headerApi = new HeaderApi();
  const styles = useStyles();
  const globalizationMapping = new Globalization();
  const titleTexts = globalizationMapping.FORMS.TIRESVEHICLES.TIREFORM;
  const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [transferType, setTransferType] = useState(titleTexts.WithinAccount);
  const [accountType, setAccountType] = useState(titleTexts.WithTire);
  const [parentAccountList, setParentAccountList] = useState([
    {
      accountName: titleTexts.DROPDOWNPLACEHOLDER,
      createdBy: titleTexts.DROPDOWNPLACEHOLDER,
      parentAccountId: titleTexts.DROPDOWNPLACEHOLDER,
    },
  ]);
  const [parentAccount, setParentAccount] = useState({
    accountName: titleTexts.DROPDOWNPLACEHOLDER,
    createdBy: titleTexts.DROPDOWNPLACEHOLDER,
    parentAccountId: titleTexts.DROPDOWNPLACEHOLDER,
  });
  const [multiSelectLocationList, setMultiSelectLocationList] = useState(
    []
  );
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getParentAccountList();
    getLocationListData();
  }, []);
  const handleTransferClick = () => {
    let successStatus = false;

    if (props.isSelectionMultiple === false) {
      // Single Vehicle Selected //
      if (transferType === titleTexts.ToAnotherAccount && accountType === titleTexts.WithoutTire) {
        if (parentAccount.parentAccountId !== null) {
          let req = {
            vehicleId: Number(props.vehicleId),
            parentAccountId: parentAccount.parentAccountId,
            targetLocationId: Number(location),
            TransferWithTire: false,
          };
          headerApi.getVehicleTransfer(req).then((data) => {
            let message = data.message;
            let showMsg = message.toLowerCase().includes("successfully");
            if (showMsg === false) {
              setErrorMsg(data.message);
            } else {
              closeModal({
                updatedSection: "tranfervehicle",
                updateStatus: showMsg,
                successMessage: notificationTexts.VEHICLETRANSFERED,
                successMessageDesc:
                  notificationTexts.TRANSFERVEHICLESUCCESS.replace(
                    "#1#1",
                    props.vehicleNumber
                  ),
              });
            }
          });
        }
      } else if ( transferType === titleTexts.ToAnotherAccount && accountType === titleTexts.WithTire) {
        if (parentAccount.parentAccountId !== null) {
          let req = {
            vehicleId: Number(props.vehicleId),
            parentAccountId: parentAccount.parentAccountId,
            targetLocationId: Number(location),
            TransferWithTire: true,
          };
          headerApi.getVehicleTransferWithTire(req).then((data) => {
            let message = data.message;
            let showMsg = message.toLowerCase().includes("successfully");
            if (showMsg === false) {
              setErrorMsg(data.message);
            } else {
              closeModal({
                updatedSection: "tranfervehicle",
                updateStatus: showMsg,
                successMessage: notificationTexts.VEHICLETRANSFERED,
                successMessageDesc:
                  notificationTexts.TRANSFERVEHICLESUCCESS.replace(
                    "#1#1",
                    props.vehicleNumber
                  ),
              });
            }
          });
        }
      } else {
        // condition: transferType === titleTexts.WithinAccount
        manageVehicleApiServices.transferVehicle(props.vehicleId, selectedLocation).then((response) => {
            if (response.didError === false) {
              successStatus = true;
            } else {
              successStatus = false;
            }
            closeModal({
              updatedSection: "tranfervehicle",
              updateStatus: successStatus,
              successMessage: notificationTexts.VEHICLETRANSFERED,
              successMessageDesc:
                notificationTexts.TRANSFERVEHICLESUCCESS.replace(
                  "#1#1",
                  props.vehicleNumber
                ),
            });
          });
      }
    } else {
      //condition: props.isSelectionMultiple === false
      // Multiple Vehicle Selected //
          if (transferType === titleTexts.ToAnotherAccount && accountType === titleTexts.WithoutTire) {
            if (parentAccount.parentAccountId !== null) {
              props.vehicleIds.forEach((vehicle) => {
                let req = {
                  vehicleId: Number(vehicle.VehicleId),
                  parentAccountId: parentAccount.parentAccountId,
                  targetLocationId: Number(location),
                  TransferWithTire: false,
                };
                headerApi.getVehicleTransfer(req).then((data) => {
                  let message = data.message;
                  let showMsg = message.toLowerCase().includes("successfully");
                  if (showMsg === false) {
                    setErrorMsg(data.message);
                  } else {
                    closeModal({
                      updatedSection: "tranfervehicle",
                      updateStatus: showMsg,
                      successMessage: notificationTexts.MULTIPLEVEHICLETRANSFERED,
                      successMessageDesc: notificationTexts.MULTIPLETRANSFERVEHICLESUCCESS,
                    });
                  }
                });
              });
            } 
          } else if (transferType === titleTexts.ToAnotherAccount && accountType === titleTexts.WithTire) {
              if (parentAccount.parentAccountId !== null) {
                props.vehicleIds.forEach((vehicle) => {
                  let req = {
                    vehicleId: Number(vehicle.VehicleId),
                    parentAccountId: parentAccount.parentAccountId,
                    targetLocationId: Number(location),
                    TransferWithTire: true,
                  };
                  headerApi.getVehicleTransferWithTire(req).then((data) => {
                    let message = data.message;
                    let showMsg = message.toLowerCase().includes("successfully");
                    if (showMsg === false) {
                      setErrorMsg(data.message);
                    } else {
                      closeModal({
                        updatedSection: "tranfervehicle",
                        updateStatus: showMsg,
                        successMessage: notificationTexts.MULTIPLEVEHICLETRANSFERED,
                        successMessageDesc: notificationTexts.MULTIPLETRANSFERVEHICLESUCCESS,
                      });
                    }
                  });
                });
              }
          } else {
            // condition: transferType === titleTexts.WithinAccount
            props.vehicleIds.forEach((vehicle) => {
              manageVehicleApiServices.transferVehicle(vehicle.VehicleId, selectedLocation).then((response) => {
                  if (response.didError === false) {
                    successStatus = true;
                  } else {
                    successStatus = false;
                  }
                  closeModal({
                    updatedSection: "tranfervehicle",
                    updateStatus: successStatus,
                    successMessage: notificationTexts.MULTIPLEVEHICLETRANSFERED,
                    successMessageDesc: notificationTexts.MULTIPLETRANSFERVEHICLESUCCESS,
                  });
                });
            });
          } 
    }
  };
  const onChangeValue = (event) => {
    setTransferType(event.target.value);
    setErrorMsg("");
    if (event.target.value === titleTexts.ToAnotherAccount) {
      setAccountType(titleTexts.WithTire);
    }
  };
  const onChangeAccount = (event) => {
    setErrorMsg("");
    setAccountType(event.target.value);
  };

  const compareStrings = (a, b) => {
    // Assuming you want case-insensitive comparison
    a = a.trim();
    b = b.trim();
    a = a.toLowerCase();
    b = b.toLowerCase();

    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  };
  const getParentAccountList = () => {
    headerApi.getParentAccountList().then((data) => {
      setParentAccountList([]);
      let model = data.model;
      model.sort(function (a, b) {
        return compareStrings(a.accountName, b.accountName);
      });
      let parentAccountListObject = [
        {
          accountName: titleTexts.DROPDOWNPLACEHOLDER,
          createdBy: titleTexts.DROPDOWNPLACEHOLDER,
          parentAccountId: titleTexts.DROPDOWNPLACEHOLDER,
        },
      ];
      for (let item of model) {
        parentAccountListObject.push({
          accountName: item.accountName,
          createdBy: item.createdBy,
          parentAccountId: item.parentAccountId,
        });
      }

      setParentAccountList(parentAccountListObject);
    });
  };

  const getLocationListData = () => {
    let locationListObject = [];
    headerApi.getLocationList().then((data) => {
      for (let item of data.model) {
        locationListObject.push({
          locationId: item.locationId,
          locationName: item.locationName,
          parentAccountId: item.parentAccountId,
          createdBy: item.createdBy,
        });
      }
      setLocationList(locationListObject);
    });
  };
  const getLocationListByAccount = (parentAccountId) => {
    if (parentAccountId) {
      let updatedMultiSelectLocationObject =
        updateLocationList(parentAccountId);
      setMultiSelectLocationList(updatedMultiSelectLocationObject);
      setLocation([]);
    } else {
      setMultiSelectLocationList([]);
      setLocation([]);
    }
  };
  // update location based on parent account
  const updateLocationList = (selectedParentAccountId) => {
    setLocation([]);
    let updatedMultiSelectLocationObject = [];
    for (let item of locationList) {
      if (item.parentAccountId === selectedParentAccountId) {
        updatedMultiSelectLocationObject.push({
          value: item.locationId,
          label: item.locationName,
        });
      }
    }
    return updatedMultiSelectLocationObject;
  };

  const handleAccountChange = (_event, selectedOption) => {
    if (selectedOption !== null && selectedOption.parentAccountId !== null) {
      setParentAccount(selectedOption);
      getLocationListByAccount(selectedOption.parentAccountId);
    }
  };
  const closeModal = (state) => {
    props.closeTransferModal(state);
  };
  return (
    <React.Fragment>
      <div className="container">
        <Modal
          open={true}
          onClose={closeModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300,
          }}
          disableScrollLock={true}
        >
          <div
            className={`vehicle-transfer-modal-container center-modal-vehicle-transfer ${
              props.isSelectionMultiple && props.vehicleIds.length > 50
                ? "transfer-multiple-selected"
                : ""
            }`}
          >
            <div className="transfer-modal-title-container">
              <div className="transfer-modal-title">
                {titleTexts.TRANSFERVEHICLE}
              </div>
              <div className="close-icon-container" onClick={closeModal}>
                <img
                  src={require("../../../../../assets/close-icon.svg")}
                  className="modal-close-icon modal-close-icon-transfer"
                  alt="close-icon"
                />
              </div>
            </div>
            {/* Adding a code for radio button  */}
            <div
              className="transfer-modal-description"
              onChange={onChangeValue}
            >
              <>
                {" "}
                <input
                  type="radio"
                  value={titleTexts.WithinAccount}
                  name="transferType"
                  checked={transferType === titleTexts.WithinAccount}
                />{" "}
                {titleTexts.WithinAccount}
              </>
              <>
                {" "}
                <input
                  type="radio"
                  value={titleTexts.ToAnotherAccount}
                  name="transferType"
                  checked={transferType === titleTexts.ToAnotherAccount}
                />{" "}
                {titleTexts.ToAnotherAccount}
              </>
            </div>
            {/* End a code for radio button  */}
            {transferType === titleTexts.WithinAccount && (
              <>
                <div className="transfer-modal-description">
                  <p className="selected-vehicles">
                    {!props.isSelectionMultiple
                      ? titleTexts.TRANSFERVEHICLEDESC.replace(
                          "#1#1",
                          props.vehicleNumber
                        )
                      : titleTexts.TRANSFERVEHICLEDESC.replace(
                          "#1#1",
                          Object.values(props.vehicleIds).map(
                            (value) => value.VehicleNumber
                          )
                        ).replaceAll(",", ", ")}
                  </p>
                </div>

                <div className="vehicle-transfer-note">
                  <p>{titleTexts.TRANSFERNOTE}</p>
                </div>

                <div className="modal-select-container">
                  <InputLabel className="location-cl">
                    {globalizationMapping.HEADER.LOCATION}&nbsp;
                    <span className="required-mark">*</span>
                  </InputLabel>
                  <Select
                    native
                    IconComponent={ExpandMoreStyledIcon}
                    className="location-select-input"
                    variant="outlined"
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                  >
                    <option key={0} value={0} disabled>
                      &nbsp;{titleTexts.DROPDOWNPLACEHOLDER}
                    </option>
                    {props.locationList &&
                      props.locationList.map((location, key) => {
                        if (
                          !props.isSelectionMultiple &&
                          location.locationName.trim().toLowerCase() ===
                            props.currentTireLocation.trim().toLowerCase()
                        ) {
                          return (
                            <option
                              key={key}
                              value={location.locationId}
                              disabled
                            >
                              &nbsp;{location.locationName}
                            </option>
                          );
                        } else
                          return (
                            <option key={key} value={location.locationId}>
                              &nbsp;{location.locationName}
                            </option>
                          );
                      })}
                  </Select>
                </div>
              </>
            )}
            {transferType === titleTexts.ToAnotherAccount && (
              <>
                {/* Adding a code for radio button  */}
                {/* <div
                  className="transfer-modal-description"
                  onChange={onChangeAccount}
                >
                  <>
                    <input
                      type="radio"
                      value={titleTexts.WithoutTire}
                      name="accountType"
                      checked={accountType === titleTexts.WithoutTire}
                    />{" "}
                    {titleTexts.WithoutTire}
                  </>
                  <>
                    {" "}
                    <input
                      type="radio"
                      value={titleTexts.WithTire}
                      name="accountType"
                      checked={accountType === titleTexts.WithTire}
                      disabled
                    />
                    {titleTexts.WithTire}
                  </>
                </div> */}
                {/* End a code for radio button  */}

                {accountType === titleTexts.WithoutTire && (
                  <>
                    <div className="transfer-modal-description">
                      <p className="selected-vehicles">
                        {!props.isSelectionMultiple
                          ? titleTexts.TRANSFERWITHOUTTIREDESC.replace(
                              "#1#1",
                              props.vehicleNumber
                            )
                          : titleTexts.TRANSFERWITHOUTTIREDESC.replace(
                              "#1#1",
                              Object.values(props.vehicleIds).map(
                                (value) => value.VehicleNumber
                              )
                            ).replaceAll(",", ", ")}
                      </p>
                    </div>
                    <div className="modal-select-container">
                      <InputLabel
                        className="location-cl"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        {globalizationMapping.CHANGEMODEL.SELECTPARENTACCOUNT}
                      </InputLabel>
                      <Autocomplete
                        size="small"
                        className="location-select-input"
                        options={parentAccountList}
                        value={parentAccount}
                        getOptionLabel={(option) => option.accountName}
                        fullWidth={true}
                        renderInput={(params) => {return (
                          <TextField
                            {...params}
                            className="location-select-input"
                            variant="outlined"
                            inputProps={{...params.inputProps, maxLength: 100 }}
                          />
                        )}}
                        onChange={(event, newValue) => {
                          handleAccountChange(event, newValue);
                        }}
                        
                      />

                      {/* Multi Select */}

                      <div style={{ marginTop: "1rem" }}>
                        <InputLabel className="location-cl">
                          {globalizationMapping.CHANGEMODEL.LOCATION}
                        </InputLabel>
                        <Select
                          style={{ padding: "1.5%" }}
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className="location-select-input"
                          variant="outlined"
                          options={multiSelectLocationList}
                          value={location}
                          onChange={(event) => {
                            setLocation(event.target.value);
                          }}
                        >
                          <option key={0} value={0}>
                            &nbsp;{titleTexts.DROPDOWNPLACEHOLDER}
                          </option>
                          {multiSelectLocationList &&
                            multiSelectLocationList.map((location, key) => {
                              return (
                                <option key={key} value={location.value}>
                                  &nbsp;{location.label}
                                </option>
                              );
                            })}
                        </Select>
                      </div>
                      <div style={{ marginTop: "1rem" }}>
                        <InputLabel className="change-modal-select-label-alert">
                          {errorMsg}
                        </InputLabel>
                      </div>
                    </div>
                    
                  </>
                )}
                {accountType === titleTexts.WithTire && (
                  <>
                    <div className="transfer-modal-description">
                      <p className="selected-vehicles">
                        {!props.isSelectionMultiple
                          ? titleTexts.TRANSFERWITHOUTTIREDESC.replace(
                              "#1#1",
                              props.vehicleNumber
                            )
                          : titleTexts.TRANSFERWITHOUTTIREDESC.replace(
                              "#1#1",
                              Object.values(props.vehicleIds).map(
                                (value) => value.VehicleNumber
                              )
                            ).replaceAll(",", ", ")}
                      </p>
                    </div>
                    <div className="vehicle-transfer-note">
                      <p>{titleTexts.TRANSFERNOTE}</p>
                    </div>
                    <div className="modal-select-container">
                      <InputLabel
                        className="location-cl"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        {globalizationMapping.CHANGEMODEL.SELECTPARENTACCOUNT}
                      </InputLabel>
                      <Autocomplete
                        size="small"
                        className="location-select-input"
                        options={parentAccountList}
                        value={parentAccount}
                        getOptionLabel={(option) => option.accountName}
                        fullWidth={true}
                        renderInput={(params) => { return(
                          <TextField
                            {...params}
                            className="location-select-input"
                            variant="outlined"
                            inputProps={{...params.inputProps, maxLength: 100 }}
                          />
                        )}}
                        onChange={(event, newValue) => {
                          handleAccountChange(event, newValue);
                        }}
                        
                      />

                      {/* Multi Select */}

                      <div style={{ marginTop: "1rem" }}>
                        <InputLabel className="location-cl">
                          {globalizationMapping.CHANGEMODEL.LOCATION}
                        </InputLabel>
                        <Select
                          style={{ padding: "1.5%" }}
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className="location-select-input"
                          variant="outlined"
                          options={multiSelectLocationList}
                          value={location}
                          onChange={(event) => {
                            setLocation(event.target.value);
                          }}
                        >
                          <option key={0} value={0}>
                            &nbsp;{titleTexts.DROPDOWNPLACEHOLDER}
                          </option>
                          {multiSelectLocationList &&
                            multiSelectLocationList.map((location, key) => {
                              return (
                                <option key={location.value} value={location.value}>
                                  &nbsp;{location.label}
                                </option>
                              );
                            })}
                        </Select>
                      </div>
                      <div style={{ marginTop: "1rem" }}>
                        <InputLabel className="change-modal-select-label-alert">
                          {errorMsg}
                        </InputLabel>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            <div className="modal-action-buttons">
              <Button
                className={styles.cancel}
                variant="contained"
                disableElevation={true}
                onClick={closeModal}
              >
                {globalizationMapping.FORMS.CANCEL}
              </Button>
              <Button
                className={styles.proceed}
                variant="contained"
                disableElevation={true}
                color="primary"
                onClick={() => {
                  handleTransferClick();
                }}
              >
                {globalizationMapping.FORMS.TRANSFER}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default TransferVehicleModal;
