import React, { useEffect, useState } from "react";
import { 
    Drawer,
    Divider,
    Button,
    makeStyles } from "@material-ui/core";
import CommonUtils from "../../../../../../../services/utils";
import WarningIcon from '@material-ui/icons/Warning';
import Globalization from "../../../../../../../globalization";
import ScrapHoursByTireSizeFilterDatePicker from "./filter-datepicker/ScrapHoursByTireSizeFilterDatePicker";

const commonUtils = new CommonUtils();
const globalizationMapping = new Globalization();
const filterSectionWords = globalizationMapping.FILTERSECTION;
const useStyles = makeStyles({
    filter: {
      "&:hover": {
        opacity: 1,
      },
      background:
        "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 500,
      color: "#FFFFFF",
      boxShadow: "none",
    },
    filterDisabled: {
      background:commonUtils.disabledColor,
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 600,
      color: "#FFFFFF",
      boxShadow: "none",
    },
    root: {
      "& input.MuiAutocomplete-inputFocused": {
        color: "#000000 !important",
        Height: "45px",
      },
    },
    clear: {
      "&:hover": {
        backgroundColor: "#EAF3FB",
        borderRadius: 18,
        border: "none",
        opacity: 1,
        boxShadow: "none",
      },
      background: "transparent",
      border: "none",
      borderRadius: 18,
      color: "#0055A4",
      opacity: 0.8,
      letterSpacing: 0,
      fontWeight: 600,
      zIndex: 0,
      boxShadow: "none",
      marginRight: 5,
    },
    FormHelperText: {
      marginTop: 25,
    },
  });


const ScrapCostByTireSizeFilter = ({
        setShowFilter,
        showFilter,
        ApplyFilter,
        resetTableFilter,
        currentFilter,
        inServiceDateRange,
        setInServiceDateRange,
        outServiceDateRange,
        setOutServiceDateRange
}) => {
    const classes = useStyles();
    const [resetValue, setResetValue] = useState(false);
    const [inServiceStartDate, setInServiceStartDate] = useState("");
    const [inServiceEndDate, setInServiceEndDate] = useState("");
    const [outServiceStartDate, setOutServiceStartDate] = useState("");
    const [outServiceEndDate, setOutServiceEndDate] = useState("");


    let selectedFilterOptionsWIthDate = {
      outServiceDateToDate: outServiceDateRange.toDate ? outServiceDateRange.toDate : outServiceEndDate,
      outServiceDateFromDate: outServiceDateRange.fromDate ? outServiceDateRange.fromDate : outServiceStartDate,
      inServiceDateFromDate: inServiceDateRange.fromDate ? inServiceDateRange.fromDate : inServiceStartDate,
      inServiceDateToDate: inServiceDateRange.toDate ? inServiceDateRange.toDate : inServiceEndDate,
    }
    


    useEffect(() => {
      isCurrentFilterEmpty();
      isSelectedFilterEmpty();
      disableApplyFilter();

    });




    // START>> Developer Template to consider the filter is same or not
    const isFilterTheSame = () => {
      // selected filter option are within the component state
 
      let updatedSelectedFilterOptions = 
      {
        outServiceDateToDate: outServiceEndDate,
        outServiceDateFromDate: outServiceStartDate,
        inServiceDateFromDate: inServiceStartDate,
        inServiceDateToDate: inServiceEndDate,
      };

      // current filter option are from the redux store
    

      let isTheSame = JSON.stringify(currentFilter) === JSON.stringify(updatedSelectedFilterOptions);
      return isTheSame;
    }

    const isDatePickerNotBothDated = () => {
      let isNotBothDated = false;
      if (((inServiceStartDate.length > 0 && inServiceEndDate.length === 0) || (inServiceStartDate.length === 0 && inServiceEndDate.length > 0))) {
        isNotBothDated = true;
      }
      if (((outServiceStartDate.length > 0 && outServiceEndDate.length === 0) || (outServiceStartDate.length === 0 && outServiceEndDate.length > 0))) {
        isNotBothDated = true;
      }
      return isNotBothDated;
    }

    const disableApplyFilter = () => {
      return isFilterTheSame() || isDatePickerNotBothDated() || isDateRangeNotCorrect();
    };

    const isDateRangeNotCorrect = () => {
      return ((new Date(inServiceStartDate) > new Date(inServiceEndDate)) || (new Date(outServiceStartDate) > new Date(outServiceEndDate)))
    }

    const isSelectedFilterEmpty = () => {
      return inServiceStartDate.length === 0 && inServiceEndDate.length === 0 &&
        outServiceStartDate.length === 0 && outServiceEndDate.length === 0;
    }

    const isCurrentFilterEmpty = () => {
      
        return currentFilter.outServiceDateToDate.length === 0 && currentFilter.outServiceDateFromDate.length === 0 &&
        currentFilter.inServiceDateFromDate.length === 0 && currentFilter.inServiceDateToDate.length === 0;
      }

    // END>> Developer Template to consider the filter is same or not


      const applyFilterClicked = () => {
        ApplyFilter(selectedFilterOptionsWIthDate);
        setShowFilter(false);
      }

      const applyResetFilterClicked = () => {

        // clear dumb component states
        setInServiceStartDate("");
        setInServiceEndDate("");
        setOutServiceStartDate("");
        setOutServiceEndDate("");
        // clear smart component states
        setInServiceDateRange({fromDate: "", toDate: ""});
        setOutServiceDateRange({fromDate: "", toDate: ""});


        setResetValue(!resetValue);
        resetTableFilter();
        setShowFilter(false);
      }

      const applyClearFilterClicked = () => {

        setResetValue(!resetValue);

        // clear dumb component states
        setInServiceStartDate("");
        setInServiceEndDate("");
        setOutServiceStartDate("");
        setOutServiceEndDate("");
        // clear smart component states
        setInServiceDateRange({fromDate: "", toDate: ""});
        setOutServiceDateRange({fromDate: "", toDate: ""});

      }

      const closeFilter = () => {
        if(isFilterTheSame()) {
          setShowFilter(false);
        }
      };

    return (
      <Drawer
                className="filter-drawer"
                anchor="right"
                open={showFilter}
                onClose={closeFilter}
                ModalProps={{keepMounted: true}}
            >
        <div className="filter-container">
            
                <div className="filter-header" style={{marginBottom:20, marginTop:10}}>
                    <div className="filter-title">Filter</div>
                    <p style={{fontStyle:'italic',fontSize:11, marginLeft:'20%'}}>This filter always refer to ORIGINAL table</p>
                </div>
                <div className="date-tab-container">
                  <div className="display-columns-container" id="dashboard-display-column"></div>

                    <div className="in-service-filter" style={{marginTop:15}}>
                      <div className="tab-header">
                        <div className="tab-title" style={{color: 'black' }}><b>{filterSectionWords.InService}</b></div>
                        {(inServiceStartDate.length > 0 && inServiceEndDate.length === 0) || (inServiceStartDate.length === 0 && inServiceEndDate.length > 0) ?
                        <p style={{fontStyle:'italic',fontSize:11,width:'20%'}}><span className="required-mark">*</span>Please fill both date</p>
                        : <p></p>}
                       {(new Date(inServiceStartDate) > new Date(inServiceEndDate)) ?
                        <p style={{fontStyle:'italic',fontSize:11,width:'50%'}}><span className="required-mark">*</span>Start Date must be earlier that End Date</p>
                        : <p></p>}
                      </div>
                      <div className="date-tab-container">
                        <ScrapHoursByTireSizeFilterDatePicker
                          inServiceDateRange={inServiceDateRange}
                          setInServiceDateRange={setInServiceDateRange}
                          isInService={true}
                          isOutService={false}
                          startDate={inServiceStartDate}
                          setStartDate={setInServiceStartDate}
                          endDate={inServiceEndDate}
                          setEndDate={setInServiceEndDate}
                        />
                      </div>
                    </div>
                    <div className="in-service-filter">
                      <div className="tab-header">
                        <div className="tab-title" style={{color:'black' }}><b>{filterSectionWords.OutService}</b></div>
                        {(outServiceStartDate.length > 0 && outServiceEndDate.length === 0) || (outServiceStartDate.length === 0 && outServiceEndDate.length > 0) ?
                        <p style={{fontStyle:'italic',fontSize:11,width:'20%'}}><span className="required-mark">*</span>Please fill both date</p>
                        : <p></p>}
                        {(new Date(outServiceStartDate) > new Date(outServiceEndDate)) ?
                        <p style={{fontStyle:'italic',fontSize:11,width:'50%'}}><span className="required-mark">*</span>Start Date must be earlier that End Date</p>
                        : <p></p>}
                      </div>
                      <div className="date-tab-container">
                        <ScrapHoursByTireSizeFilterDatePicker
                          outServiceDateRange={outServiceDateRange}
                          setOutServiceDateRange={setOutServiceDateRange}
                          isInService={false}
                          isOutService={true}
                          startDate={outServiceStartDate}
                          setStartDate={setOutServiceStartDate}
                          endDate={outServiceEndDate}
                          setEndDate={setOutServiceEndDate}
                        />
                      </div>
                    </div>
                </div>
                


                <Divider style={{marginBottom:20}}/>
               
                <div className="action-buttons action-buttons-create-location" style={{marginRight:'1.5em'}}>
                      <Button
                            className={classes.clear}
                            variant="contained"
                            disableElevation={true}
                            onClick={()=> applyClearFilterClicked()}
                            // disabled={isSelectedFilterEmpty()}
                            style={{marginRight:30}}
                        >
                            Clear
                        </Button>
                      <Button
                            className={classes.clear}
                            variant="contained"
                            disableElevation={true}
                            onClick={()=> applyResetFilterClicked()}
                            // disabled={isCurrentFilterEmpty()}
                        >
                            {globalizationMapping.FILTERSECTION.Reset}
                        </Button>
                      <Button
                            className={classes.filter}
                            variant="contained"
                            disableElevation={true}
                            onClick={() => applyFilterClicked()}
                            // disabled={disableApplyFilter()}
                        >
                            {globalizationMapping.FILTERSECTION.Apply}
                        </Button>  
                </div>
                {isFilterTheSame() ? null : 
                  <div>
                     <p style={{fontStyle:'italic',fontSize:12}}> <WarningIcon style={{verticalAlign:"middle", color: 'orange', marginLeft:20, fontSize:15}}/>Changed in Filter Detected. Click "Apply" to make changes.</p>
                  </div>}
                



        

        </div>
      </Drawer>
    );
};

export default ScrapCostByTireSizeFilter;