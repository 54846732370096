import Globalization from '../globalization/index'

const globalizationMapping = new Globalization();

export const ReportsRoutePaths = [
  {
    name: globalizationMapping.reportDashboard,
    path: 'reportsDashboard'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapSummary,
    path: 'scrapSummary'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByRemovalReason,
    path: 'byRemovalReason'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByCompound,
    path: 'byCompound'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByIndividualTire,
    path: 'byIndividualTire'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapAnalysis,
    path: 'scrapAnalysis'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByTireType,
    path: 'byTireType'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByTireSize,
    path: 'byTireSize'
  },

  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.MonthlyTireLife,
    path: 'monthlyTireLife'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapCostByTireSize,
    path: 'scrapCostByTireSize'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapHoursByTireSize,
    path: 'scrapHoursByTireSize'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapCostByTireType,
    path: 'scrapCostByTireType'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapHoursByTireType,
    path: 'scrapHoursByTireType'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.InServiceTireCostSummary,
    path: 'inServiceTireCostSummary'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireInstallation,
    path: 'tireInstallation'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
    path: 'tireHistory'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByWearCondition,
    path: 'byWearCondition'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByManufacturer,
    path: 'byManufacturer'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.BySize,
    path: 'bySize'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByTiresInServiceTireType,
    path: 'byTiresInServicesTireType'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByVehicleID,
    path: 'byVehicleId'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ProjectedCostByTireSize,
    path: 'projectedCostByTireSize'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ProjectedCostByTireType,
    path: 'projectedCostByTireType'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByVehicleModel,
    path: 'byVehicleModel'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByVehicleWheelPosition,
    path: 'byVehicleWheelPosition'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireForecastByTireType,
    path: 'tireForecastByTireType'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireInflation,
    path: 'tireInflation'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByPercentageWorn,
    path: 'byPercentageWorn'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.DailyJobSummary,
    path: 'dailyJobSummary'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireNeedsForecast,
    path: 'tireNeedsForecast'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.RemoveTireReport,
    path: 'removedTire'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TireByDisposition,
    path: 'tiresByDisposition'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.NewVSScrap,
    path: 'newVSScrap'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.AchievedHoursReport,
    path: 'achievedHoursReport'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TireRegisterReport,
    path: 'tireRegister'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToRepair,
    path: 'tiresSentToRepair'
  }
  ,
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToRetread,
    path: 'tiresSentToRetread'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToInspectTireReport,
    path: 'tiresSentToInspectTireReport'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.AllTireReport,
    path: 'allTireReport'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
    path: 'tireInflationReport'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireRotation,
    path: 'tireRotation'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
    path: 'tireFlipping'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
    path: 'tireForecast'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetDataAnalysis,
    path: 'fleetData'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetDataAnalysis,
    path: 'fleetData'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
    path: 'fleetSurvey'
  },
  // {
  //   name:  globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
  //   path: 'tireInflation'
  // },
  
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsFourTires,
    path: 'twoAxlesFourTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsSixTires,
    path: 'twoAxlesSixTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsSixTiresTwo,
    path: 'twoAxlesSixTiresTwo'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsEightTires,
    path: 'twoAxlesEightTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.OneAxelTwoTires,
    path: 'oneAxleTwoTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.OneAxelFourTires,
    path: 'oneAxleFourTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.ThreeAxelsSixTires,
    path: 'threeAxlesSixTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.ThreeAxelsTenTires,
    path: 'threeAxlesTenTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FourAxelsEightTires,
    path: 'fourAxlesEightTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsTenTires,
    path: 'fiveAxlesTenTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsSixteenTires,
    path: 'fiveAxlesSixteenTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsEighteenTires,
    path: 'fiveAxlesEighteenTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsTwentyTires,
    path: 'fiveAxlesTwentyTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.SixAxelsTwelveTires,
    path: 'sixAxlesTwelveTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.SevenAxelsFourteenTires,
    path: 'sevenAxlesFourteenTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.SevenAxelsTwentySixTires,
    path: 'sevenAxlesTwentySixTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.EightAxelsSixteenTires,
    path: 'eightAxlesSixteenTires'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.NineAxelsEighteenTires,
    path: 'nineAxlesEighteenTires'
  },
  
  {
    name: globalizationMapping.RIMREPORTS.RimReports.RimReport,
    path: 'rimReport'
  },
  {
    name: globalizationMapping.RIMREPORTS.RimReports.RimsInService,
    path: 'rimsInService'
  },


  //   KEEP ADDING ROUTE PATHS
]