import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "../../../services/CustomBarGraphStyle.js";
import Grid from "@material-ui/core/Grid";
import "./custom-graph.css";
import "chartjs-plugin-annotation";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../globalization/index.js";



const TireWithLowTreadDepth = ({ tireWithLowTreadDepthGraphData, locationsData, setLocationsData, graphLocationIdSetter }) => {

  const [chartInfoData, setChartInfoData] = useState({});
  const options = {
    title: {
      display: false
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          maxBarThickness: 80,
          barPercentage: 1.5,
          categoryPercentage: 0.3,
          align: "center",
          stacked: true,
          ticks: {
            display: true,
            fontSize: 16,
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: "Percentage Worn",
            fontStyle: "bold",
            fontSize: 16,
            fontColor: "#333",
            align: "center"
          }
        }
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: "No. of Tires",
            fontStyle: "bold",
            fontSize: 16,
            fontColor: "#333"
          },
          ticks: {
            display: true,
            fontSize: 16,
            beginAtZero: true
            // suggestedMin: 0,
            // suggestedMax: 400,
            // stepSize:50 ,
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
        labels: {
          value: {
            color: "black",
            font: {
              weight: "bold",
              size: "10"
            }
          }
        },
        formatter: (value, index) => {
          return index.datasetIndex === 3 ? null : Math.round(value);
        }
      }
    },
    annotation: {
      annotations: [
        {
          type: "box",
          id: "low-box",
          drawTime: "beforeDatasetsDraw",
          xScaleID: "x-axis-0",
          yScaleID: "y-axis-0",
          borderColor: "#D1D1D1",
          borderWidth: 1,
          xMax: tireWithLowTreadDepthGraphData && tireWithLowTreadDepthGraphData.chartlabels[3],
          // yMin: 395,
          // yMax:400,
          yMin: 390,
          // yMax: yAxesticks[0],
          backgroundColor: "#D1D1D1"
        },
        {
          type: "box",
          id: "low-box-1",
          drawTime: "beforeDatasetsDraw",
          xScaleID: "x-axis-0",
          yScaleID: "y-axis-0",
          borderColor: "#FF2323",
          borderWidth: 1,
          // yMin: 395,
          // yMax:400,
          yMin: 390,
          //yMax: yAxesticks[0],
          xMin: tireWithLowTreadDepthGraphData && tireWithLowTreadDepthGraphData.chartlabels[3],
          backgroundColor: "#FF2323"
        },
        {
          drawTime: "afterDatasetsDraw",
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: 3.0,
          borderWidth: 1,
          borderDashOffset: 5,
          borderColor: "#ff0100",
          borderDash: [4, 4]
        }
      ]
    },
    cornerRadius: 10,
    responsive: true
  };

  const globalizationMapping = new Globalization();
  const graphTexts = globalizationMapping.GRAPH;

  const chart = () => {
    if (
      tireWithLowTreadDepthGraphData !== undefined &&
      tireWithLowTreadDepthGraphData !== null &&
      tireWithLowTreadDepthGraphData.chartlabels &&
      tireWithLowTreadDepthGraphData.chartlabels.length > 0
    ) {


      const colorCodes =  ["#00AF32", "#f0de00", "#EDBD00", "#ff0100", "#ff0100"]
      const borderColorCodes =  ["#00AF32", "#f0de00", "#EDBD00", "#ff0100", "#ff0100"]
      const dataset = [];
      for (let i = 0; i < tireWithLowTreadDepthGraphData.chartlabels.length; i++) {
        const dataGroup = [];
        dataGroup.length = tireWithLowTreadDepthGraphData.chartlabels.length;
        dataGroup.splice(i, 0, tireWithLowTreadDepthGraphData.chartData[0].data[i]);
        dataset.push({
          label: tireWithLowTreadDepthGraphData.chartlabels[i],
          backgroundColor: colorCodes[i],
          fill: true,
          hoverBorderColor: borderColorCodes[i],
          hoverBorderWidth: "3",
          data: dataGroup
        });
      }
      setChartInfoData({
        labels: tireWithLowTreadDepthGraphData.chartlabels,
        datasets: dataset
      });
    } else {
      setChartInfoData({});
    }
  };

  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tireWithLowTreadDepthGraphData]);

  const locationsHandler = (selectedLocationIndex) => {
    const locationsDataObject = locationsData;
    locationsDataObject.forEach((item, index) => {
      if (index === selectedLocationIndex) {
        item.selected = !item.selected;
      }
    });
    setLocationsData(locationsDataObject);
    graphLocationIdSetter(locationsDataObject);
  };
  return (
    <div className="percentage-worn-graph-container">
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
      {Object.keys(chartInfoData).length !== 0 && (
        <div>
          <div className="chart-title-info">{globalizationMapping.tiresWithLowTreadDepthCount}</div>
          <Grid item={true} md={12} sm={12}>
            <div className="container-normal">{graphTexts.NormalTreadDepth}</div>
            <div className="container-low">{graphTexts.LowTreadDepth}</div>
            {Object.keys(chartInfoData).length > 0 && <Bar data={chartInfoData} height={275} options={options} />}
          </Grid>
          <span>{`${globalizationMapping.HEADER.LOCATION}: `}</span>
          {locationsData &&
            locationsData.map((item, index) => (
              <span key={item.label} className={item.selected ? "locations-text" : "locations-text-strike"} onClick={() => locationsHandler(index)}>
                {item.label}
              </span>
            ))}
        </div>
      )}
    </div>
  );
};

export default TireWithLowTreadDepth;
