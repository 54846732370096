import React, { useEffect, useState, useContext } from 'react';
import OtherTiresNavigation from "../../other-tires-navigation/OtherTiresNavigation";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Divider} from "@material-ui/core";
import AllTireReportDataSetter from "../../../../services/AllTireReportUtils";
import { accountInfoIdsFormatter } from "../../../../../../services/AccountInfoHandler";
import AllTireReportTable from "../table/AllTIreReportTable";
import { StateContext } from "../../../../../../services/StateProvider";
import TiresService from "../../../../services/TiresApis.js";
import Spinner from "../../../../../../common-components/spinner/spinner";
import Globalization from "../../../../../../globalization";
import SearchIcon from "../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../services/utils";
import { useComponentVisible } from "../../../../../../services/CustomHooks";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExportCsv from "../../../../../../common-components/modal-components/export-types/ExportCsv";
import ExportXls from "../../../../../../common-components/modal-components/export-types/ExportXls";
import PdfExportModal from "../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import AllTireReportFilter from '../filter/AllTireReportFilter.js';
import FilterListIcon from '@material-ui/icons/FilterList';

const AllTireReport = () => {

    const allTireReportDataSetter = new AllTireReportDataSetter();
    const tiresService = new TiresService();
    const commonUtils = new CommonUtils();
    const globalizationMapping = new Globalization();
    const [globalAccountState] = useContext(StateContext);
    const [allTableReportTableData, setAllTableReportTableData] = useState();
    const [showColumns, setShowColumns] = useState();
    const [showSpinner, setShowSpinner] = useState(true);
    const [noData, setNoData] = useState(false);

    const [printData, setPrintData] = useState(allTableReportTableData);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [originalTableDataStore, setOriginalTableDataStore] = useState();
    const [modalHandler, setModalHandler] = useState({
      handlePdf: false,
      handleEmail: false,
      handlePrint: false,
    });
    const [showFilter, setShowFilter] = useState(false);
    const [filterOptions, setFilterOptions] = useState({
      categories: [
        { name: "Brand #", value: "BrandNo" },
        { name: "Location", value: "Location" },
        { name: "Status", value: "Status" },
        { name: "Manufacturer", value: "Manufacturer" },
        { name: "Size", value: "Size" },
        { name: "Type", value: "Type" },
        { name: "Compound", value: "Compound" },
        { name: "Eval #", value: "EvalNo" },
      ],
      categoryData: {
        BrandNo: [],
        Location: [],
        Status: [],
        Manufacturer: [],
        Size: [],
        Type: [],
        Compound: [],
        EvalNo: [],
      },
    });
    const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
    const [tableBeforeFilter, setTableBeforeFilter] = useState();
    const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
    const [filterFieldsApplied, setFilterFieldsApplied] = useState();

    useEffect(() => {
      AllTireReportResponseData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalAccountState]);


    const AllTireReportResponseData = () => {
      setShowSpinner(true);

      let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
      let accountIds = accountInfoObject.accountIds;
      let locationIds = accountInfoObject.locationIds;
  
      let requestObject = {
        reportName: "All_Tire_Report",
        accountIds: accountIds,
        locationIds: locationIds,
      }
      tiresService.getTiresReport(requestObject).then((data) => {
        let allTireTableInfo = allTireReportDataSetter.setData(data);
        setOriginalTableDataStore(allTireTableInfo)

        setAllTableReportTableData(allTireTableInfo);
        setShowColumns(allTireTableInfo.selectedColShow)

        let filterOptionsInfo = allTireReportDataSetter.setFilterOptionsData(filterOptions, allTireTableInfo.rowsData);
        setFilterOptions(filterOptionsInfo);

        setPrintData({
          ...printData,
          reportType: "All Tire Report : ",
          reportName: "All Tire Report",
          rowsData: allTireTableInfo?.rowsData,
          headCells: allTireTableInfo?.headCells,
          selectedColShow: allTireTableInfo?.selectedColShow,
        });
        setOriginalTableDataStore(allTireTableInfo)
        setTableBeforeFilter(allTireTableInfo);
        setShowSpinner(false);
      })
    };
    const formSubmitHandler = (e) => {
      e.preventDefault(); //should prevent submit, and continue
      return false;
    };

    const toggleExportDropdown = () => {setIsComponentVisible(!isComponentVisible)}
    const onClickFilter = () => {setShowFilter(true);};
    
      // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    setCurrentFilter(appliedFilter)

    let filteredData = allTireReportDataSetter.filterTable(
      appliedFilter,
      originalTableDataStore.rowsData
    );
    setAllTableReportTableData({
      ...allTableReportTableData,
      rowsData: filteredData,
    });
    setPrintData({ ...printData, rowsData: filteredData });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    Object.values(appliedFilter).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);
  };



  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter({
      BrandNo: [],
      Location: [],
      Status: [],
      Manufacturer: [],
      Size: [],
      Type: [],
      Compound: [],
      EvalNo: [],
    });
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setAllTableReportTableData(tableBeforeFilter);
    setOriginalTableDataStore(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {},
    });
    setOnlyFilterApplied(false);
    setNoData(false);
  };


    const searchTableData = (event) => {
      let searchText = event.target.value.toLowerCase();
      let originalTableData, filteredData;
      originalTableData = originalTableDataStore?.rowsData;
  
      filteredData = allTireReportDataSetter.search(originalTableData, searchText);
      let updatedTableData = {
        rowsData: filteredData,
        headCells: originalTableDataStore?.headCells,
        selectedColShow: originalTableDataStore?.selectedColShow,
      };
      setAllTableReportTableData(updatedTableData);

      setPrintData({
        ...printData,
        reportType: "All Tire Report : ",
        reportName: "All Tire Report",
        rowsData: updatedTableData.rowsData,
        headCells: originalTableDataStore?.headCells,
        selectedColShow: originalTableDataStore?.selectedColShow,
      });
      filteredData.length === 0 ? setNoData(true) : setNoData(false);
    };


    const onClickExport = (type) => {
      if (type === "pdf") {
        let checkSortApplicable = document.querySelector("th[aria-sort] span");
        if (checkSortApplicable) {
          checkSortApplicable = checkSortApplicable.textContent;
          let data = commonUtils.exportTableSort(
            printData,
            checkSortApplicable.split("sorted ")[0],
            checkSortApplicable.split("sorted ")[1]
          );
          setPrintData(data);
          setModalHandler({ handlePdf: true });
        } else {
          setModalHandler({ handlePdf: true });
        }
      }
    };

    return (
            <div className="container">
                <OtherTiresNavigation />
                {showSpinner && <Spinner />}
                {!showSpinner && (
                <div className="tire-register-container">
                    <Card className="tire-register-card">
                        <CardContent className="table-card">
                          <div className="table-toolbar">
                          <div className="table-filters">
                            <div className="search-table-container">
                              <form
                                className="search-table-container"
                                noValidate
                                autoComplete="off"
                                onSubmit={formSubmitHandler}
                              >
                                <InputLabel className="filter-table-label">
                                  <span className="table-toolbar-label-text">
                                    {globalizationMapping.REPORTCOMMONWORDS.SearchTable}
                                  </span>
                                </InputLabel>
                                <TextField
                                  className="search-table-bar"
                                  id="input-with-icon-textfield"
                                  variant="outlined"
                                  placeholder={
                                    globalizationMapping.REPORTCOMMONWORDS.Search
                                  }
                                  onChange={searchTableData}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img
                                          className="keyword-search-icon-img"
                                          alt=""
                                          src={SearchIcon}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{ maxLength: 50 }}
                                />
                              </form>
                            </div>
                          </div>

                          <div className="table-function-title" style={{marginLeft:'auto'}}>
                            <button
                              onClick={onClickFilter}
                              className="table-function-item">
                                  <span>
                                    <FilterListIcon fontSize="medium" style={{verticalAlign:"middle", marginBottom:5, color:onlyFilterApplied ? 'red': commonUtils.primaryBlue}}></FilterListIcon>
                                  </span>
                            </button>
                            <button
                                  className={
                                    noData === true
                                      ? "table-function-item disabled-export-button"
                                      : "table-function-item export-button"
                                  }
                                  disabled={noData}
                                  onClick={toggleExportDropdown}
                                  ref={ref}
                                >
                                  <div className="dropdown-icon-container" ref={ref}>
                                    <div className="export-down-arrow">
                                      <span style={{color: noData ? commonUtils.disabledColor : commonUtils.primaryBlue}}>
                                        <FileCopyIcon disabled={noData} fontSize="medium" style={{verticalAlign:"middle", marginBottom:8}}></FileCopyIcon>
                                      </span>
                                    </div>
                                    {isComponentVisible && (
                                      <div className="export-dropdown-content">
                                        <div
                                          onClick={() => onClickExport("csv")}
                                          className="export-option"
                                        >
                                           <ExportCsv exportCsv={printData} />
                                        </div>
                                        <Divider />
                                        <div
                                          onClick={() => onClickExport("xlsx")}
                                          className="export-option"
                                        >
                                          <ExportXls exportXlsx={printData} />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </button>
                            </div>
                          </div>
              
              
                          <AllTireReportTable
                            tableData={allTableReportTableData}
                            showColumns={showColumns}
                          />

                          
                        </CardContent>
                    </Card>
                </div>)} 

                <PdfExportModal
                  modalHandler={modalHandler}
                  setModalHandler={setModalHandler}
                  tableData={printData}
                  noGraph={true}
                />
                <AllTireReportFilter
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                  filterOptions={filterOptions}
                  ApplyFilter={confirmApplyFilter}
                  currentFilter={currentFilter}
                  resetTableFilter={resetTableFilter}
                ></AllTireReportFilter>
            </div>

    );
};

export default AllTireReport;
