import React, { useEffect, useState } from "react";
import "./GraphContainer.css";
import { Grid } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import moment from "moment";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import GraphModal from "../../../../../../../common-components/modal-components/graph-modal/GraphModalComponent";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";

const GraphContainer = ({ eachTireData }) => {
  const [eachTireGraphData, setEachTireGraphData] = useState(eachTireData);
  const [chartTreadDepthMilesInfoData, setTreadDepthMilesChartInfoData] = useState({});
  const [chartTreadDepthHoursInfoData, setTreadDepthHoursChartInfoData] = useState({});
  const [chartCostInfoData, setCostChartInfoData] = useState({});
  const [chartPressureInfoData, setChartPressureInfoData] = useState({});
  const [chartTreadDepthInfoData, setTreadDepthChartInfoData] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);
  const [modalHandlerGraph, setModalHandlerGraph] = React.useState({
    handleGraph: false
  });
  const [modalData, setModalData] = useState({});
  const [selectedChart, setSelectedChart] = useState();
  useEffect(() => {
    setEachTireGraphData(eachTireData);
    setShowSpinner(false);
    chart();
  }, [eachTireData]);

  const options1 = {
    title: {
      display: true,
      text: "Miles/Tread Depth"
    },
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ]
    },
    responsive: true,
    maintainAspectRatio: true
  };
  const options2 = {
    title: {
      display: true,
      text: "Hours/Tread Depth"
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ]
    },
    responsive: true,
    maintainAspectRatio: true
  };
  const options3 = {
    title: {
      display: true,
      text: "Cost/Hour"
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ]
    },
    responsive: true,
    maintainAspectRatio: true
  };
  const options4 = {
    title: {
      display: true,
      text: "Pressure"
    },
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ]
    },
    responsive: true,
    maintainAspectRatio: true
  };
  const options5 = {
    title: {
      display: true,
      text: "Tread Depth"
    },
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: true
          }
        }
      ]
    },
    responsive: true,
    maintainAspectRatio: true
  };

  const chart = () => {
    let treadDepthMilesdataset = [];
    let treadDepthMilesGraphData = [];
    let treadDepthMilesGraphLabel = [];
    let treadDepthHoursdataset = [];
    let treadDepthHoursGraphData = [];
    let treadDepthHoursGraphLabel = [];
    let costdataset = [];
    let costGraphData = [];
    let costGraphLabel = [];
    let pressuredataset = [];
    let pressureGraphData = [];
    let pressureGraphLabel = [];
    let treadDepthdataset = [];
    let treadDepthGraphData = [];
    let treadDepthGraphLabel = [];

    for (let graphItem of eachTireGraphData["milesPerTreadDepthList"]) {
      treadDepthMilesGraphData.push(graphItem.tireMetric);
      let DateObj = moment(new Date(graphItem.timeSpan));
      treadDepthMilesGraphLabel.push(DateObj.format("Do MMM"));
    }
    for (let graphItem of eachTireGraphData["hoursPerTreadDepthList"]) {
      treadDepthHoursGraphData.push(graphItem.tireMetric);
      let DateObj = moment(new Date(graphItem.timeSpan));
      treadDepthHoursGraphLabel.push(DateObj.format("Do MMM"));
    }
    for (let graphItem of eachTireGraphData["costPerHourList"]) {
      costGraphData.push(graphItem.tireMetric);
      let DateObj = moment(new Date(graphItem.timeSpan));
      costGraphLabel.push(DateObj.format("Do MMM"));
    }
    for (let graphItem of eachTireGraphData["pressureList"]) {
      pressureGraphData.push(graphItem.tireMetric);
      let DateObj = moment(new Date(graphItem.timeSpan));
      pressureGraphLabel.push(DateObj.format("Do MMM"));
    }
    for (let graphItem of eachTireGraphData["treadDepthList"]) {
      treadDepthGraphData.push(graphItem.tireMetric);
      let DateObj = moment(new Date(graphItem.timeSpan));
      treadDepthGraphLabel.push(DateObj.format("Do MMM"));
    }
    treadDepthMilesdataset.push({
      fill: false,
      borderColor: "rgb(255,255,0)",
      pointBackgroundColor: "rgba(151,187,205,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(151,187,205,0.8)",
      data: treadDepthMilesGraphData
    });
    treadDepthHoursdataset.push({
      fill: false,
      borderColor: "rgb(255,255,0)",
      pointBackgroundColor: "rgba(151,187,205,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(151,187,205,0.8)",
      data: treadDepthHoursGraphData
    });
    costdataset.push({
      fill: false,
      borderColor: "rgb(255,255,0)",
      pointBackgroundColor: "rgba(151,187,205,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(151,187,205,0.8)",
      data: costGraphData
    });
    pressuredataset.push({
      fill: false,
      borderColor: "rgb(255,255,0)",
      pointBackgroundColor: "rgba(151,187,205,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(151,187,205,0.8)",
      data: pressureGraphData
    });
    treadDepthdataset.push({
      fill: false,
      borderColor: "rgb(255,255,0)",
      pointBackgroundColor: "rgba(151,187,205,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(151,187,205,0.8)",
      data: treadDepthGraphData
    });

    setTreadDepthMilesChartInfoData({
      labels: treadDepthMilesGraphLabel,
      datasets: treadDepthMilesdataset
    });
    setTreadDepthHoursChartInfoData({
      labels: treadDepthHoursGraphLabel,
      datasets: treadDepthHoursdataset
    });
    setCostChartInfoData({
      labels: costGraphLabel,
      datasets: costdataset
    });
    setChartPressureInfoData({
      labels: pressureGraphLabel,
      datasets: pressuredataset
    });
    setTreadDepthChartInfoData({
      labels: treadDepthGraphLabel,
      datasets: treadDepthdataset
    });
  };

  const onClickRemovedTireGraph = (option) => {
    let graphModalData = [];
    graphModalData.push({
      graphData: chartTreadDepthMilesInfoData,
      option: options1
    });
    graphModalData.push({
      graphData: chartTreadDepthHoursInfoData,
      option: options2
    });
    graphModalData.push({ graphData: chartCostInfoData, option: options3 });
    graphModalData.push({ graphData: chartPressureInfoData, option: options4 });
    graphModalData.push({
      graphData: chartTreadDepthInfoData,
      option: options5
    });
    let selectedChartNum;

    switch (option.title.text) {
      case "Miles/Tread Depth":
        setSelectedChart(0);
        selectedChartNum = 0;
        break;

      case "Hours/Tread Depth":
        setSelectedChart(1);
        selectedChartNum = 1;
        break;

      case "Cost/Hour":
        setSelectedChart(2);
        selectedChartNum = 2;
        break;

      case "Pressure":
        setSelectedChart(3);
        selectedChartNum = 3;
        break;

      case "Tread Depth":
        setSelectedChart(4);
        selectedChartNum = 4;
        break;

      default:
        setSelectedChart(0);
        selectedChartNum = 5;
        break;
    }

    if (selectedChartNum !== undefined) {
      graphModalData[selectedChartNum].option.title.display = false;
    }
    setModalData(graphModalData);
    setModalHandlerGraph({ handleGraph: true });
  };
  return (
    <>
      <div className="removed-tire-graph-container">
        {showSpinner && <Spinner />}
        {!showSpinner && (
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading-graph">
              <div className="heading-container">Graph</div>
            </Grid>
            {eachTireData.hoursPerTreadDepthList.length === 0 && (
              <div className="no-graph-removed-tire">
                <div className="no-data-container">
                  <NoDataFound noDataType="graph"></NoDataFound>
                </div>
              </div>
            )}
            {eachTireData.hoursPerTreadDepthList.length !== 0 && (
              <>
                <Grid item xs={3}>
                  <div className="graph-removed-tire-container" onClick={() => onClickRemovedTireGraph(options1)}>
                    {Object.keys(chartTreadDepthMilesInfoData).length > 0 && <Line data={chartTreadDepthMilesInfoData} options={options1} height={400} width={400} />}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="graph-removed-tire-container" onClick={() => onClickRemovedTireGraph(options2)}>
                    {Object.keys(chartTreadDepthHoursInfoData).length > 0 && <Line data={chartTreadDepthHoursInfoData} options={options2} height={400} width={400} />}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="graph-removed-tire-container" onClick={() => onClickRemovedTireGraph(options3)}>
                    {Object.keys(chartCostInfoData).length > 0 && <Line data={chartCostInfoData} options={options3} height={400} width={400} />}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="graph-removed-tire-container" onClick={() => onClickRemovedTireGraph(options4)}>
                    {Object.keys(chartPressureInfoData).length > 0 && <Line data={chartPressureInfoData} options={options4} height={400} width={400} />}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="graph-removed-tire-container" onClick={() => onClickRemovedTireGraph(options5)}>
                    {Object.keys(chartTreadDepthInfoData).length > 0 && <Line data={chartTreadDepthInfoData} options={options5} height={400} width={400} />}
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </div>
      <GraphModal
        modalData={modalData}
        selectedChart={selectedChart}
        setSelectedChart={setSelectedChart}
        modalHandlerGraph={modalHandlerGraph}
        setModalHandlerGraph={setModalHandlerGraph}
      />
    </>
  );
};

export default GraphContainer;
