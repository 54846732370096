import React from "react";
import { makeStyles, Grid, TextField } from "@material-ui/core";
import "./ByVehicleIdFilterDatePicker.css";
import Globalization from "../../../../../../../../globalization";
import CommonUtils from "../../../../../../../../services/utils";

const commonUtils = new CommonUtils();

const useStyles = makeStyles((_theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
  },
}));

export default function ByVehicleIdFilterDatePicker({
  setDateRangeFilter,
  dateRangeFilter,
  inServiceDateRange,
  setInServiceDateRange,
  outServiceDateRange,
  setOutServiceDateRange,
  isInService,
  isOutService,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isDisabled
}) {
  const classes = useStyles();

  const globalizationMapping = new Globalization();
  const handleStartDate = (event) => {
    setStartDate(event.target.value);
    if (isInService)
      setInServiceDateRange({
        ...inServiceDateRange,
        fromDate: event.target.value,
      });
    else if (isOutService)
      setOutServiceDateRange({
        ...outServiceDateRange,
        fromDate: event.target.value,
      });
    else
      setDateRangeFilter({ ...dateRangeFilter, fromDate: event.target.value });
  };

  const handleEndDate = (event) => {
    setEndDate(event.target.value);
    if (isInService)
      setInServiceDateRange({
        ...inServiceDateRange,
        toDate: event.target.value,
      });
    else if (isOutService)
      setOutServiceDateRange({
        ...outServiceDateRange,
        toDate: event.target.value,
      });
    else setDateRangeFilter({ ...dateRangeFilter, toDate: event.target.value });
    //onHandleEndDate(endDate)
  };

  return (
    <form className={classes.container} noValidate>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <div className="from-container padding-one">
            <div className="checkbox-header">
              <div className="checkbox-title" style={{color: isDisabled ? commonUtils.disabledColor: 'black' }}>
                {globalizationMapping.REPORTCOMMONWORDS.FromDate}
              </div>
            </div>

            <TextField
              id="date"
              type="date"
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleStartDate}
              value={startDate}
              disabled={isDisabled}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className="to-container padding-one">
            <div className="checkbox-header">
              <div className="checkbox-title" style={{color: isDisabled ? commonUtils.disabledColor: 'black' }}>
                {globalizationMapping.REPORTCOMMONWORDS.ToDate}
              </div>
            </div>

            <TextField
              id="date"
              type="date"
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleEndDate}
              value={endDate}
              disabled={isDisabled}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
