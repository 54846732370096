import React, { useState, useEffect } from "react";
import "./RimReportsNavigation.css";
import Breadcrumbs from "../../../../common-components/breadcrumbs/breadcrumbs";
import { Card, CardContent } from "@material-ui/core";
import Globalization from "../../../../globalization/index";
import { useHistory } from "react-router-dom";
import { ReportsRoutePaths } from "../../../../routing/ReportsRoutePaths";

const RimReportsNavigation = () => {
  const globalizationMapping = new Globalization();
  const rimReportsGlobalText = globalizationMapping.RIMREPORTS.RimReports;
  const rimReportsNav = [
    rimReportsGlobalText.RimReport,
    rimReportsGlobalText.RimsInService,
  ];
  const rimReportsPathNav = ["rimReport", "rimsInService"];

  const getRouteName = (currentPath) => {
    return ReportsRoutePaths.find((route) => route.path === currentPath);
  };

  const getRoutePath = (_scrapTireSelected, path) => {
    return ReportsRoutePaths.find((route) => route.path === path);
  };

  let breadcrumbs = [];
  const history = useHistory();
  const currentPathName = history.location.pathname.split("/")[1];
  const currentRouteSelected = getRouteName(currentPathName);
  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);

  breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME,
    },
    {
      path: "reportsDashboard",
      name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.REPORTSDASHBOARD,
    },
    {
      path: "",
      name: `${globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.RIMREPORTS}: ${selectedRoute.name}`,
    },
  ];

  const rimReportNavigation = (rimReportSelected, path) => {
    const routePath = getRoutePath(rimReportSelected, path);
    setSelectedRoute(routePath);
  };

  useEffect(() => {
    history.push(selectedRoute.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  return (
    <div>
      <div className="other-tire-main-container">
        <div className="rim-reports-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="rim-reports-title">
                    {rimReportsGlobalText.RimReports}
                  </div>
                </div>
              </div>
              <div className="scrap-time-nav-container">
                {rimReportsNav.map((item, index) => {
                  return (
                    <div
                      key={item}
                      className="rim-reports-nav-card-container"
                      onClick={() =>
                        rimReportNavigation(item, rimReportsPathNav[index])
                      }
                    >
                      <Card
                        className={
                          selectedRoute.name === item
                            ? "active-rim-reports-nav-card"
                            : "rim-reports-nav-card"
                        }
                      >
                        <CardContent>
                          <span
                            className={
                              selectedRoute.name === item
                                ? "active-rim-reports-nav-card-text"
                                : "rim-reports-nav-card-text"
                            }
                          >
                            {item}
                          </span>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RimReportsNavigation;
