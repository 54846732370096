import { Backdrop, Button, makeStyles, Modal } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import Globalization from '../../globalization';
import './cancel-popup.css';

const useStyles = makeStyles({
    proceed: {
        "&:hover": {
            opacity: 1
        },
        background:
            "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
        borderRadius: 18,
        font: 'Barlow',
        opacity: .8,
        fontWeight: 500,
        color: "#FFFFFF",
        boxShadow: "none"
    },
    cancel: {
        "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none"
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: .8,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none"
    }
});

const CancelConfirmationPopup = props => {

    const history = useHistory();
    const styles = useStyles();

    const globalizationMapping = new Globalization();
    const titleTexts = globalizationMapping.FORMS;

    const onYesClick = () => {
        history.push({
            pathname: props.pathToRedirect,
            state: props.stateToPass
        });
    };

    return (
        <div className="container">
                <Modal
                    open={true}
                    onClose={props.closeConfirmationModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 300
                    }}
                    disableScrollLock={true}
                >
                    <div className="cancel-tire-modal-container center-modal">
                        <div className="cancel-modal-title-container">
                            <div className="cancel-modal-title">
                                {titleTexts.CANCELTITLE}
                            </div>
                            <div
                                className="cancel-modal-close-icon-container"
                                onClick={props.closeConfirmationModal}>
                                <img
                                    src={require("../../assets/close-icon.svg")}
                                    className="modal-close-icon"
                                    alt="close-icon"
                                />
                            </div>
                        </div>
                        <div className="delete-modal-description">
                            <p>{titleTexts.CANCELREQUESTDESC1}</p>
                            <p>{titleTexts.CANCELREQUESTDESC2}</p>
                        </div>
                        <div className="modal-action-buttons">
                            <Button
                                className={styles.cancel}
                                variant="contained"
                                disableElevation={true}
                                onClick={
                                    props.closeConfirmationModal
                                }
                            >
                                {titleTexts.NO}
                            </Button>
                            <Button
                                className={styles.proceed}
                                variant="contained"
                                disableElevation={true}
                                onClick={() => onYesClick()}
                            >
                                {titleTexts.YES}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
    );
};

export default CancelConfirmationPopup;
