import React, { useEffect, useState, useContext } from "react";
import "./ByVehicleModel.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel, FormControlLabel, Checkbox, Grid, Select } from "@material-ui/core";
import MultiSelect from "react-multi-select-component";
import TiresInServiceNavigation from "../../../../tires-in-service/tires-in-service-navigation/TiresInServiceNavigation";
import ByVehicleModelDataSetter from "../../../../../services/ByVehicleModelUtils";
import ByVehicleModelGraph from "../graph/ByVehicleModelGraph";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import ByVehicleModelTable from "../table/ByVehicleModelTable";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ByVehicleModelFilter from '../filter/ByVehicleModelFilter'

const ByVehicleModel = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const tiresService = new TiresService();
  const byVehicleModelDataSetter = new ByVehicleModelDataSetter();
  const commonUtils = new CommonUtils();
  const [byVehicleModelTableData, setByVehicleModelTableData] = useState();
  const [originalByVehicleModelTableData, setOriginalByVehicleModelTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(byVehicleModelTableData);
  const [noData, setNoData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const globalizationMapping = new Globalization();

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Compound", value: "CompoundName" }

    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

  const ByVehicleModelResponseData = (excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let requestObject = {
      accountIds: accountIds,
      dateRangeType: dateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      locationIds: locationIds,
      reportName: "Tire_In_Service_By_Vehicle_Model",
      reportOption: "",
      usedTires: excludeUsedTiresValue.toString()
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let byVehicleModelTableInfo = byVehicleModelDataSetter.setData(data);
      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.MANUFACTURER,
            value: "ManufacturerName"
          },
          { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
          { name: globalizationMapping.REPORTTABLE.COMPOUND, value: "CompoundName" }

        ],
        categoryData: {
          ManufacturerName: [],
          SizeName: [],
          TypeName: [],
          CompoundName: [],
          dateRangeFromDate: '',
          dateRangeToDate: '',
        }
      };
      let filterOptionsInfo = byVehicleModelDataSetter.setFilterOptionsData(filterOptions, byVehicleModelTableInfo.rowsData);
      setFilterOptions(filterOptionsInfo);
      setByVehicleModelTableData(byVehicleModelTableInfo);
      setOriginalByVehicleModelTableData(byVehicleModelTableInfo);
      setTableBeforeFilter(byVehicleModelTableInfo);
      byVehicleModelTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setDisplayColOptions(byVehicleModelTableInfo.headCells);
      setDisplayColumns(byVehicleModelTableInfo.headCells);
      setShowColumns(byVehicleModelTableInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByVehicleModel,
        rowsData: byVehicleModelTableInfo.rowsData,
        headCells: byVehicleModelTableInfo.headCells,
        avgValues: byVehicleModelTableInfo.avgValues,
        selectedColShow: byVehicleModelTableInfo.selectedColShow,
        filterApplied: filterApplied
      });
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = byVehicleModelTableInfo.rowsData;
        let filteredData = byVehicleModelDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setByVehicleModelTableData({
          ...byVehicleModelTableInfo,
          rowsData: filteredData,
          avgValues: byVehicleModelDataSetter.avgValues(filteredData)
        });
        setOriginalByVehicleModelTableData({
          ...byVehicleModelTableInfo,
          rowsData: filteredData,
          avgValues: byVehicleModelDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: byVehicleModelDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        ((Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
      setShowSpinner(false);
    });
  };
  const actionChanged = (value) => {
    let arrOfCol = [];
    if (value === "miles") arrOfCol = ["AverageHours"];
    else arrOfCol = ["AverageMiles"];

    const displayColSection = byVehicleModelTableData?.headCells.filter((item) => {
      let flag = true;
      arrOfCol.forEach((term) => {
        if (item.value === term) flag = false;
      });
      return flag;
    });

    setDisplayColumns(displayColSection);
    setDisplayColOptions(displayColSection);

    let currentHeadCells = originalByVehicleModelTableData?.headCells;
    let showColumnsUpdated = byVehicleModelDataSetter.setDisplayColumnData(currentHeadCells, displayColSection);
    setShowColumns(showColumnsUpdated);

    setByVehicleModelTableData({
      ...byVehicleModelTableData,
      selectedColShow: showColumnsUpdated
    });

    // when all columns selected
    if (displayColSection.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    displayColSection.length === 0 ? setNoData(true) : setNoData(false);
  };

  useEffect(() => {
    // FORMATTING THE GOBAL ACCOUNT STATES
    ByVehicleModelResponseData(excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalByVehicleModelTableData?.headCells;
    let showColumnsUpdated = byVehicleModelDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    let tableDataStore, filteredData;
    tableDataStore = originalByVehicleModelTableData?.rowsData;
    filteredData = byVehicleModelDataSetter.search(tableDataStore, searchText);
    setByVehicleModelTableData({
      ...byVehicleModelTableData,
      rowsData: filteredData,
      avgValues: byVehicleModelDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      reportType: "",
      reportName: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByVehicleModel,
      rowsData: filteredData,
      avgValues: byVehicleModelDataSetter.avgValues(filteredData),
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

    setCurrentFilter(toSetCurrentFilter);
    if (appliedFilter === filterOptions.categoryData) {
      setFilterApplied(false);
    } else {setFilterApplied(true)}

    setSearchValue("");
    setFilterApplied(true);
    setFilterFieldsApplied(appliedFilter);
    let originalTableDataStore = originalByVehicleModelTableData?.rowsData;
    let filteredData = byVehicleModelDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setByVehicleModelTableData({
      ...byVehicleModelTableData,
      rowsData: filteredData,
      avgValues: byVehicleModelDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byVehicleModelDataSetter.avgValues(filteredData)
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setOnlyFilterApplied(true);
    ByVehicleModelResponseData(excludeUsedTires, true, appliedFilter);
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      CompoundName: [],
      dateRangeFromDate: '',
      dateRangeToDate: '',
  });
    setFilterApplied(false);
    let temp = tableBeforeFilter;
    temp.selectedColShow = printData?.selectedColShow;
    setByVehicleModelTableData(tableBeforeFilter);
    setOriginalByVehicleModelTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      selectedColShow: printData?.selectedColShow,
      filterApplied: false,
      filterFieldsApplied: {}
    });

    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApllied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApllied).forEach((index) => {
      tempFilterFieldsApllied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApllied);
    setOnlyFilterApplied(false);
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  const handleExcludeUsedTires = (event) => {
    setSearchValue("");
    setExcludeUsedTires(event.target.checked);
    ByVehicleModelResponseData(event.target.checked, filterApplied, filterFieldsApplied);
  };

  return (
    <div>
      <TiresInServiceNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="by-vehicle-model-container">
          <Card className="by-vehicle-model-card">
            <CardContent>
              <ByVehicleModelGraph byVehicleModelGraphData={byVehicleModelTableData} />
            </CardContent>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text"> {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="manage-vehicle-action-dropdown"
                      variant="outlined"
                      onChange={(e) => actionChanged(e.target.value)}>
                      <option value={"hours"}>{globalizationMapping.MANAGEVEHICLE.HOURS}</option>
                      <option value={"miles"}> {toggleMile}</option>
                    </Select>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={3}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="data-summary-table">
                <ByVehicleModelTable tableData={byVehicleModelTableData} showColumns={showColumns} filterApplied={filterApplied} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />
      <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />

      <ByVehicleModelFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default ByVehicleModel;
