import React, { useEffect, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./search-install-tire.css";
import "../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Grid, Button, makeStyles } from "@material-ui/core";
import SearchInstallTireListApis from "../../service/SearchInstallTireListApis";
import { StateContext } from "../../../../services/StateProvider";
import { accountInfoIdsFormatter, getAccountNames, getLocationNames } from "../../../../services/AccountInfoHandler";
import Spinner from "../../../../common-components/spinner/spinner";
import SearchInstallTireListTable from "../table/SearchInstallTireListTable";
import SearchInstallTireListSetter from "../../service/SearchInstallTireListUtils";
import SearchIcon from "../../../../assets/icons8-search.svg";
import Breadcrumbs from "../../../../common-components/breadcrumbs/breadcrumbs";
import Globalization from "../../../../globalization";
import FlashMessage from "../../../../common-components/pop-up-notification/notification-snackbar";

const useStyles = makeStyles({
  width: "100%",
  create: {
    background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 1,
    margin: 3,
    height: "max-content",
    fontWeight: 500,
    color: "#FFFFFF"
  }
});

const SearchInstallTire = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(true);
  const [searchInstallTireListTableData, setSearchInstallTireListTableData] = useState();
  const [originalSearchInstallTireListTableData, setOriginalSearchInstallTireListTableData] = useState();
  const [showColumns, setShowColumns] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [fromRequest, setFromRequest] = useState();
  const [accountName, setAccountName] = useState("");
  const [locationName, setLocationName] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState("0");
  const [wheelPosition, setWheelPosition] = useState("R1");
  const [vehicleLocationId, setVehicleLocationId] = useState("");
  const [tireId, setTireId] = useState("");
  const styling = useStyles();
  const locationObj = useLocation();
  const globalizationMapping = new Globalization();
  const globalTexts = globalizationMapping.SEARCH_INSTALL_TIRE;
  const globalFormTexts = globalizationMapping.FORMS.ACCOUNTS.PARENTFORM;
  const globalHeaderTexts = globalizationMapping.HEADER;

  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME
    },
    {
      path: "",
      name: globalHeaderTexts.SEARCHINSTALLTIRE
    }
  ];

  const [showNotification, setShowNotification] = useState(false);
  const [flashMessageState, setFlashMessageState] = useState(locationObj.state ? locationObj.state : {});
  const searchInstallTireListDataSetter = new SearchInstallTireListSetter();
  const searchInstallTireListService = new SearchInstallTireListApis();

  useEffect(() => {
    setLocationName(getLocationNames());
    setAccountName(getAccountNames());
    if (locationObj.pathname === "/search-install-tires")
      if (locationObj.state && locationObj.state.id) {
        setFromRequest(locationObj.state);
        setVehicleNumber(locationObj.state.id);
        setWheelPosition(locationObj.state.position);
        setVehicleLocationId(locationObj.state.locationId);
        let locArray = [...globalAccountState.accountInfo.location];
        let indexSelectedLocationName = locArray.filter((val) => parseInt(val.value) === parseInt(locationObj.state.locationId));
        if (indexSelectedLocationName && indexSelectedLocationName.length > 0 && indexSelectedLocationName[0].label !== "") {
          setLocationName([indexSelectedLocationName[0].label]);
        }
        SearchInstallTireListResponseData();
        if (locationObj.state.fromPath === "/createtire") {
          if (locationObj.state && locationObj.state.updateStatus) {
            setShowNotification(true);
            setFlashMessageState(locationObj.state);
            setTireId(locationObj.state.tireId);
          }
          setTimeout(() => {
            setShowNotification(false);
            setFlashMessageState({});
          }, 5000);
        }
      }
    InitNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, locationObj]);

  const InitNotification = () => {
    if (locationObj.state && locationObj.state.updatedSection) {
      if (locationObj.state.updatedSection === "successQuickInspect") {
        if (locationObj.state.updateStatus) {
          setShowNotification(true);
        }
      } else {
        setTimeout(() => {
          setShowNotification(false);
          setFlashMessageState("");
        }, 5000);
      }
      setFlashMessageState(locationObj);
    }
  };

  const SearchInstallTireListResponseData = () => {
    const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    const accountIds = accountInfoObject.accountIds;

    setShowSpinner(true);
    searchInstallTireListService.searchInstallTireGetDetailsByAccount(accountIds).then((data) => {
      let vLId = locationObj.state.locationId && locationObj.state.locationId !== "" ? locationObj.state.locationId : "";
      let searchInstallTireListTableInfo = searchInstallTireListDataSetter.setData(data, vLId);
      setSearchInstallTireListTableData(searchInstallTireListTableInfo);
      setOriginalSearchInstallTireListTableData(searchInstallTireListTableInfo);
      setShowColumns(searchInstallTireListTableInfo.selectedColShow);

      setShowSpinner(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    let originalTableDataStore, filteredData;
    originalTableDataStore = originalSearchInstallTireListTableData?.rowsData;

    filteredData = searchInstallTireListDataSetter.search(originalTableDataStore, searchText);

    let updatedTableData = {
      rowsData: filteredData,
      headCells: originalSearchInstallTireListTableData?.headCells,
      selectedColShow: originalSearchInstallTireListTableData?.selectedColShow
    };
    setSearchInstallTireListTableData(updatedTableData);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  function handleCreate() {
    history.push({
      pathname: "/createtire",
      state: {
        fromPath: "/search-install-tires",
        id: vehicleNumber,
        position: wheelPosition,
        locationId: vehicleLocationId
      }
    });
  }
  const setTireIdNull = () => {
    setTireId("");
  };

  return (
    <div className="standalone-new-header-container">
      {/* <ScrapTiresNavigation/> */}
      <div className="search-install-tires-main-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">{globalHeaderTexts.SEARCHINSTALLTIRE}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showNotification && <FlashMessage status={flashMessageState.updateStatus} message={flashMessageState.successMessage} description={flashMessageState.successMessageDesc} />}

      {showNotification && flashMessageState.state && (
        <FlashMessage status={flashMessageState.state.updateStatus} message={flashMessageState.state.successMessage} description={flashMessageState.state.successMessageDesc} />
      )}

      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="search-install-tire-list-container">
          <Card className="search-install-tire-list-card">
            <div className="header-inner-container">
              <Grid className="inner-container" container>
                <Grid item>
                  <p className="field-label">{globalTexts.SELECTEDPARENT}</p>
                  <p id="recordedHour" className="field-label-p">
                    {accountName + " - " + locationName[0]}
                  </p>
                </Grid>
                <Grid item>
                  <p className="field-label">{globalTexts.VEHICLEID}</p>
                  <p id="recordedHour" className="field-label-p">
                    {vehicleNumber}
                  </p>
                </Grid>
                <Grid item>
                  <p className="field-label">{globalTexts.WHEELPOSITION}</p>
                  <p id="recordedHour" className="field-label-p">
                    {wheelPosition}
                  </p>
                </Grid>
              </Grid>
            </div>
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalTexts.SEARCHTABLE}</span>
                      </InputLabel>
                      <TextField
                        className="search-bar-input" //search-table-bar
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalFormTexts.SEARCHPLACEHOLDER}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              {/* <label className="filter-table-label">
                                <p className="search-install-tire-list-heading">
                                    {globalTexts.SELECTTYRES}
                                </p>
                            </label> */}
              <div className="data-summary-table">
                {!showSpinner && (
                  <SearchInstallTireListTable
                    tableData={searchInstallTireListTableData}
                    showColumns={showColumns}
                    senderDetails={fromRequest}
                    selectedTire={tireId}
                    setTireIdNull={setTireIdNull}
                    // selectedRow={selectedRow}
                  />
                )}
              </div>
            </CardContent>
            <Grid className="bottem-container" container>
              <Grid item className="bottem-inner-container">
                <p className="field-label-bottem">{globalTexts.CREATEMESSAGE}</p>
                <Button className={styling.create} variant="contained" color="secondary" onClick={() => handleCreate()}>
                  {globalTexts.CREATEBUTTON}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </div>
      )}
    </div>
  );
};

export default SearchInstallTire;
