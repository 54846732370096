import React, {forwardRef } from "react";
import "./filterPrintEmailExport-SetButtons.css";
import CommonUtils from "../../services/utils";
import Globalization from "../../globalization";
import PrintIcon from "@material-ui/icons/Print";
import FilterListIcon from '@material-ui/icons/FilterList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MailIcon from "../../assets/mail-icon.svg";
import MailDisabledIcon from "../../assets/mail_disabled.svg";

import {
  Divider,
} from "@material-ui/core";
import ExportCsv from "../../common-components/modal-components/export-types/ExportCsv";
import ExportXls from "../../common-components/modal-components/export-types/ExportXls";
import ExportVehicleCsv from "../../common-components/modal-components/export-types/ExportVehicleCsv";
import ExportVehicleXls from "../../common-components/modal-components/export-types/ExportVehicleXls";
const FilterPrintEmailExportSetButtons = forwardRef(({
    onClickFilter,
    onClickEmail,
    onClickPrint,
    onClickExport,
    toggleExportDropdown,
    printData,
    
    onlyFilterApplied,
    noData,
    isComponentVisible,    
    isVehicleReport
}, ref) => {

    const commonUtils = new CommonUtils();
    const globalizationMapping = new Globalization();


    return (
            <div className="table-function-title">
                  <button
                    onClick={onClickFilter}
                    className="table-function-item"
                    disabled={noData}>
                      {noData ? 
                      <span>
                        <FilterListIcon fontSize="small" style={{verticalAlign:"middle", marginBottom:5, color:commonUtils.disabledColor}}></FilterListIcon>
                      </span> : 
                      <span>
                          <FilterListIcon fontSize="small" style={{verticalAlign:"middle", marginBottom:5, color:onlyFilterApplied ? 'red': commonUtils.primaryBlue}}></FilterListIcon>
                        </span>
                      }
                  </button>
                  <button
                    onClick={onClickPrint}
                    className="table-function-item"
                    disabled={noData}>
                        <span style={{color: noData ? commonUtils.disabledColor : commonUtils.primaryBlue}}>
                          <PrintIcon disabled={noData} fontSize="small" style={{verticalAlign:"middle", marginBottom:5,marginLeft:5}}></PrintIcon>
                        </span>
                  </button>
                    {localStorage.getItem("isEmailBtnShow") === "true" ?
                        <button
                          onClick={onClickEmail}
                          className="table-function-item"
                          disabled={noData}>
                          <img
                            className="mail-icon-img"
                            alt=""
                            src={noData === true ? MailDisabledIcon : MailIcon}
                          />
                          <span
                            className={
                              noData === true
                                ? "table-toolbar-text disabled-text"
                                : "table-toolbar-text"
                            }
                          >
                            {globalizationMapping.MANAGEPARENTACCOUNT.EMAILADDRESS}
                          </span>
                        </button>
                    : null }
                  <button
                    className={
                      noData === true
                        ? "table-function-item disabled-export-button"
                        : "table-function-item export-button"
                    }
                    disabled={noData}
                    onClick={toggleExportDropdown}
                    ref={ref}
                  >
                    <div className="dropdown-icon-container" ref={ref}>
                      <div className="export-down-arrow">
                        <span style={{color: noData ? commonUtils.disabledColor : commonUtils.primaryBlue}}>
                          <FileCopyIcon disabled={noData} fontSize="small" style={{verticalAlign:"middle", marginBottom:8}}></FileCopyIcon>
                        </span>
                      </div>
                      {isComponentVisible && (
                        <div className="export-dropdown-content">
                          <div
                            onClick={() => onClickExport("pdf")}
                            className="export-option"
                          >
                            {globalizationMapping.MANAGE_PARENT_ACCOUNT.PDF}
                          </div>
                          <Divider />
                          <div
                            onClick={() => onClickExport("csv")}
                            className="export-option"
                          >
                            {isVehicleReport ?  <ExportVehicleCsv exportCsv={printData} /> : <ExportCsv exportCsv={printData} />}
                          </div>
                          <Divider />
                          <div
                            onClick={() => onClickExport("xlsx")}
                            className="export-option"
                          >
                            {isVehicleReport ?  <ExportVehicleXls exportXlsx={printData} /> : <ExportXls exportXlsx={printData} />}
                          </div>
                        </div>
                      )}
                    </div>
                  </button>
                </div>
)});

export default FilterPrintEmailExportSetButtons;