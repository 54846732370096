export const InspectionOrderConfiguration = {

    ConfigurationNames: {
        "OneAxleTwoTires": "2",
        "OneAxleFourTires": "4",
        "TwoAxlesFourTires": "22",
        "TwoAxlesSixTires": "24",
        "TwoAxlesSixTiresTwo": "42",
        "TwoAxlesEightTires": "44",
        "ThreeAxlesSixTires": "222",
        "ThreeAxlesTenTires": "244",
        "ThreeAxlesTwelveTires": "444",
        "FourAxlesEightTires": "2222",
        "FiveAxlesTenTires": "22222",
        "FiveAxlesSixteenTires": "22444",
        "FiveAxlesEighteenTires": "24444",
        "FiveAxlesTwentyTires": "44444",
        "SixAxlesTwelveTires": "222222",
        "SevenAxlesFourteenTires": "2222222",
        "SevenAxlesTwentySixTires": "2444444",
        "EightAxlesSixteenTires": "22222222",
        "NineAxlesEighteenTires": "222222222"
    },
    TirePositionConfig: {
        'default': {
            "2": ['1L', '1R'],
            "4": ['1LO', '1LI', '1RI', '1RO'],
            "22": ["1L", "1R", "2L", "2R"],
            "24": ['1L', '1R', '2LO', '2LI', '2RI', '2RO'],
            "42": ['1LO', '1LI', '1RI', '1RO', '2L', '2R'],
            "44": ['1LO', '1LI', '1RI', '1RO', '2LO', '2LI', '2RI', '2RO'],
            "222": ['1L', '1R', '2L', '2R', '3L', '3R'],
            "244": ['1L', '1R', '2LO', '2LI', '2RI', '2RO', '3LO', '3LI', '3RI', '3RO'],
            "2222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R'],
            "22222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R'],
            "22444": ['1L', '1R', '2L', '2R', '3LO', '3LI', '3RO', '3RI', '4LO', '4LI', '4RO', '4RI', '5LI', '5LO', '5RO', '5RI'],
            "24444": ['1L', '1R', '2LO', '2LI', '2RI', '2RO', '3LO', '3LI', '3RI', '3RO', '4LO', '4LI', '4RI', '4RO', '5LO', '5LI', '5RI', '5RO'],
            "44444": ['1LO', '1LI', '1RI', '1RO', '2LO', '2LI', '2RI', '2RO', '3LO', '3LI', '3RI', '3RO', '4LO', '4LI', '4RI', '4RO', '5LO', '5LI', '5RI', '5RO'],
            "222222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R', '6L', '6R'],
            "2222222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R', '6L', '6R', '7L', '7R'],
            "2444444": ['1L', '1R', '2LO', '2LI', '2RI', '2RO', '3LO', '3LI', '3RI', '3RO', '4LO', '4LI', '4RI', '4RO', '5LO', '5LI', '5RI', '5RO', '6LO', '6LI', '6RI', '6RO', '7LO', '7LI', '7RI', '7RO'],
            "22222222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R', '6L', '6R', '7L', '7R', '8L', '8R'],
            "222222222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R', '6L', '6R', '7L', '7R', '8L', '8R', '9L', '9R'],
            "444": ['1LO', '1LI', '1RO', '1RI', '2LO', '2LI', '2RO', '2RI', '3LO', '3LI', '3RO', '3RI'], // same to 3: need to edit soon
        },

        '5': {
            '22': ['1R', '2R', '2L', '1L'],
            '222': ['1R', '2R', '3R', '3L', '2L', '1L'],
            '2222': ['1R', '2R', '3R', '4R', '4L', '3L', '2L', '1L'],
            '22222': ['1R', '2R', '3R', '4R', '5R', '5L', '4L', '3L', '2L', '1L'],
            '24': ['1R', '2RI', '2RO', '2LI', '2LO', '1L'],
            '244': ['1R', '2RI', '2RO', '3RI', '3RO', '3LI', '3LO', '2LI', '2LO', '1L'],
            '42': ['1RI', '1RO', '2R', '2L', '1LI', '1LO'],
            '44444': ['1RI', '1RO', '2RI', '2RO', '3RI', '3RO', '4RI', '4RO', '4LI', '4LO', '3LI', '3LO', '2LI', '2LO', '1LI', '1LO'],

            "2": ['1R', '1L'],
            "4": ['1RI', '1RO', '1LI', '1LO'],
            "44": ['1RI', '1RO', '2RI', '2RO', '2LI', '2LO', '1LI', '1LO'],
            "22444": ['1R', '2R', '3RI', '3RO', '4RI', '4RO', '5RI', '5RO', '5LI', '5LO', '4LI', '4LO', '3LI', '3LO', '2L', '1L'],
            "24444": ['1R', '2RI', '2RO', '3RI', '3RO', '4RI', '4RO', '5RI', '5RO', '5LI', '5LO', '4LI', '4LO', '3LI', '3LO', '2LI', '2LO', '1L'],
            "222222": ['1R', '2R', '3R', '4R', '5R', '6R', '6L', '5L', '4L', '3L', '2L', '1L'],
            "2222222": ['1R', '2R', '3R', '4R', '5R', '6R', '7R', '7L', '6L', '5L', '4L', '3L', '2L', '1L'],
            "2444444": ['1R', '2RI', '2RO', '3RI', '3RO', '4RI', '4RO', '5RI', '5RO', '6RI', '6RO', '7RI', '7RO', '7LI', '7LO', '6LI', '6LO', '5LI', '5LO', '4LI', '4LO', '3LI', '3LO', '2LI', '2LO', '1L'],
            "22222222": ['1R', '2R', '3R', '4R', '5R', '6R', '7R', '8R', '8L', '7L', '6L', '5L', '4L', '3L', '2L', '1L'],
            "222222222": ['1R', '2R', '3R', '4R', '5R', '6R', '7R', '8R', '9R', '9L', '8L', '7L', '6L', '5L', '4L', '3L', '2L', '1L'],
            "444": ['1LO', '1LI', '1RO', '1RI', '2LO', '2LI', '2RO', '2RI', '3LO', '3LI', '3RO', '3RI'], // same to 3: need to edit soon
        },
        '6': {
            '22': ['1R', '2R', '2L', '1L'],
            '222': ['1R', '2R', '3R', '3L', '2L', '1L'],
            '2222': ['1R', '2R', '3R', '4R', '4L', '3L', '2L', '1L'],
            '22222': ['1R', '2R', '3R', '4R', '5R', '5L', '4L', '3L', '2L', '1L'],
            '24': ['1R', '2RO', '2RI', '2LO', '2LI', '1L'],
            '244': ['1R', '2RO', '2RI', '3RO', '3RI', '3LO', '3LI', '2LO', '2LI', '1L'],
            '42': ['1RO', '1RI', '2R', '2L', '1LO', '1LI'],
            '44444': ['1RO', '1RI', '2RO', '2RI', '3RO', '3RI', '4RO', '4RI', '4LO', '4LI', '3LO', '3LI', '2LO', '2LI', '1LO', '1LI'],

            "2": ['1R', '1L'],
            "4": ['1RO', '1RI', '1LO', '1LI'],
            "44": ['1RO', '1RI', '2RO', '2RI', '2LO', '2LI', '1LO', '1LI'],
            "22444": ['1R', '2R', '3RO', '3RI', '4RO', '4RI', '5RO', '5RI', '5LO', '5LI', '4LO', '4LI', '3LO', '3LI', '2L', '1L'],
            "24444": ['1R', '2RO', '2RI', '3RO', '3RI', '4RO', '4RI', '5RO', '5RI', '5LO', '5LI', '4LO', '4LI', '3LO', '3LI', '2LO', '2LI', '1L'],
            "222222": ['1R', '2R', '3R', '4R', '5R', '6R', '6L', '5L', '4L', '3L', '2L', '1L'],
            "2222222": ['1R', '2R', '3R', '4R', '5R', '6R', '7R', '7L', '6L', '5L', '4L', '3L', '2L', '1L'],
            "2444444": ['1R', '2RO', '2RI', '3RO', '3RI', '4RO', '4RI', '5RO', '5RI', '6RO', '6RI', '7RO', '7RI', '7LO', '7LI', '6LO', '6LI', '5LO', '5LI', '4LO', '4LI', '3LO', '3LI', '2LO', '2LI', '1L'],
            "22222222": ['1R', '2R', '3R', '4R', '5R', '6R', '7R', '8R', '8L', '7L', '6L', '5L', '4L', '3L', '2L', '1L'],
            "222222222": ['1R', '2R', '3R', '4R', '5R', '6R', '7R', '8R', '9R', '9L', '8L', '7L', '6L', '5L', '4L', '3L', '2L', '1L'],
            "444": ['1LO', '1LI', '1RO', '1RI', '2LO', '2LI', '2RO', '2RI', '3LO', '3LI', '3RO', '3RI'], // same to 3: need to edit soon
        },
        '3': {
            '22': ['1L', '2L', '2R', '1R'],
            '222': ['1L', '2L', '3L', '3R', '2R', '1R'],
            '2222': ['1L', '2L', '3L', '4L', '4R', '3R', '2R', '1R'],
            '22222': ['1L', '2L', '3L', '4L', '5L', '5R', '4R', '3R', '2R', '1R'],
            '24': ['1L', '2LI', '2LO', '2RI', '2RO', '1R'],
            '244': ['1L', '2LI', '2LO', '3LI', '3LO', '3RI', '3RO', '2RI', '2RO', '1R'],
            '42': ['1LI', '1LO', '2L', '2R', '1RI', '1RO'],
            '44444': ['1LI', '1LO', '2LI', '2LO', '3LI', '3LO', '4L1', '4LO', '4RI', '4RO', '3RI', '3RO', '2RI', '2RO', '1RI', '1RO'],

            "2": ['1L', '1R'],
            "4": ['1LI', '1LO', '1RI', '1RO'],
            "44": ['1LI', '1LO', '2LI', '2LO', '2RI', '2RO', '1RI', '1RO'],
            "22444": ['1L', '2L', '3LI', '3LO', '4LI', '4LO', '5LI', '5LO', '5RI', '5RO', '4RI', '4RO', '3RI', '3RO', '2R', '1R'],
            "24444": ['1L', '2LI', '2LO', '3LI', '3LO', '4LI', '4LO', '5LI', '5LO', '5RI', '5RO', '4RI', '4RO', '3RI', '3RO', '2RI', '2RO', '1R'],

            "222222": ['1L', '2L', '3L', '4L', '5L', '6L', '6R', '5R', '4R', '3R', '2R', '1R'],
            "2222222": ['1L', '2L', '3L', '4L', '5L', '6L', '7L', '7R', '6R', '5R', '4R', '3R', '2R', '1R'],
            "2444444": ['1L', '2LI', '2LO', '3LI', '3LO', '4LI', '4LO', '5LI', '5LO', '6LI', '6LO', '7LI', '7LO', '7RI', '7RO', '6RI', '6RO', '5RI', '5RO', '4RI', '4RO', '3RI', '3RO', '2RI', '2RO', '1R'],
            "22222222": ['1L', '2L', '3L', '4L', '5L', '6L', '7L', '8L', '8R', '7R', '6R', '5R', '4R', '3R', '2R', '1R'],
            "222222222": ['1L', '2L', '3L', '4L', '5L', '6L', '7L', '8L', '9L', '9R', '8R', '7R', '6R', '5R', '4R', '3R', '2R', '1R'],
            "444": ['1LO', '1LI', '1RO', '1RI', '2LO', '2LI', '2RO', '2RI', '3LO', '3LI', '3RO', '3RI'], // base
        },
        '4': {
            '22': ['1L', '2L', '2R', '1R'],
            '222': ['1L', '2L', '3L', '3R', '2R', '1R'],
            '2222': ['1L', '2L', '3L', '4L', '4R', '3R', '2R', '1R'],
            '22222': ['1L', '2L', '3L', '4L', '5L', '5R', '4R', '3R', '2R', '1R'],
            '24': ['1L', '2LO', '2LI', '2RO', '2RI', '1R'],
            '244': ['1L', '2LO', '2LI', '3LO', '3LI', '3RO', '3RI', '2RO', '2RI', '1R'],
            '42': ['1LO', '1LI', '2L', '2R', '1RO', '1RI'],
            '44444': ['1LO', '1LI', '2LO', '2LI', '3LO', '3LI', '4LO', '4L1', '4RO', '4RI', '3RO', '3RI', '2RO', '2RI', '1RO', '1RI'],

            "2": ['1L', '1R'],
            "4": ['1LO', '1LI', '1RO', '1RI'],
            "44": ['1LO', '1LI', '2LO', '2LI', '2RO', '2RI', '1RO', '1RI'],
            "22444": ['1L', '2L', '3LO', '3LI', '4LO', '4LI', '5LO', '5LI', '5RO', '5RI', '4RO', '4RI', '3RO', '3RI', '2R', '1R'],
            "24444": ['1L', '2LO', '2LI', '3LO', '3LI', '4LO', '4LI', '5LO', '5LI', '5RO', '5RI', '4RO', '4RI', '3RO', '3RI', '2RO', '2RI', '1R'],

            "222222": ['1L', '2L', '3L', '4L', '5L', '6L', '6R', '5R', '4R', '3R', '2R', '1R'],
            "2222222": ['1L', '2L', '3L', '4L', '5L', '6L', '7L', '7R', '6R', '5R', '4R', '3R', '2R', '1R'],
            "2444444": ['1L', '2LO', '2LI', '3LO', '3LI', '4LO', '4LI', '5LO', '5LI', '6LO', '6LI', '7LO', '7LI', '7RO', '7RI', '6RO', '6RI', '5RO', '5RI', '4RO', '4RI', '3RO', '3RI', '2RO', '2RI', '1R'],
            "22222222": ['1L', '2L', '3L', '4L', '5L', '6L', '7L', '8L', '8R', '7R', '6R', '5R', '4R', '3R', '2R', '1R'],
            "222222222": ['1L', '2L', '3L', '4L', '5L', '6L', '7L', '8L', '9L', '9R', '8R', '7R', '6R', '5R', '4R', '3R', '2R', '1R'],
            "444": ['1LO', '1LI', '2LO', '2LI', '3LO', '3LI', '3RI', '3RO', '2RO', '2RI', '1RO', '1RI'], // updated
        },
        '7': {
            '22': ['1L', '1R', '2L', '2R'],
            '222': ['1L', '1R', '2L', '2R', '3L', '3R'],
            '2222': ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R'],
            '22222': ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R'],
            '24': ['1L', '1R', '2LO', '2LI', '2RI', '2RO'],
            '244': ['1L', '1R', '2LO', '2LI', '2RI', '2RO', '3LO', '3LI', '3RI', '3RO'],
            '42': ['1LO', '1LI', '1RI', '1RO', '2L', '2R'],
            '44444': ['1LO', '1LI', '1RI', '1RO', '2LO', '2LI', '2RI', '2RO', '3LO', '3LI', '3RI', '3RO', '4LO', '4LI', '4RI', '4RO'],

            "2": ['1L', '1R'],
            "4": ['1LO', '1LI', '1RI', '1RO'],
            "44": ['1LO', '1LI', '1RI', '1RO', '2LO', '2LI', '2RI', '2RO'],
            "22444": ['1L', '1R', '2L', '2R', '3LO', '3LI', '3RO', '3RI', '4LO', '4LI', '4RO', '4RI', '5LI', '5LO', '5RO', '5RI'],
            "24444": ['1L', '1R', '2LO', '2LI', '2RI', '2RO', '3LO', '3LI', '3RI', '3RO', '4LO', '4LI', '4RI', '4RO', '5LO', '5LI', '5RI', '5RO'],
            "222222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R', '6L', '6R'],
            "2222222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R', '6L', '6R', '7L', '7R'],
            "2444444": ['1L', '1R', '2LO', '2LI', '2RI', '2RO', '3LO', '3LI', '3RI', '3RO', '4LO', '4LI', '4RI', '4RO', '5LO', '5LI', '5RI', '5RO', '6LO', '6LI', '6RI', '6RO', '7LO', '7LI', '7RI', '7RO'],
            "22222222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R', '6L', '6R', '7L', '7R', '8L', '8R'],
            "222222222": ['1L', '1R', '2L', '2R', '3L', '3R', '4L', '4R', '5L', '5R', '6L', '6R', '7L', '7R', '8L', '8R', '9L', '9R'],
            "444": ['1LO', '1LI', '1RO', '1RI', '2LO', '2LI', '2RO', '2RI', '3LO', '3LI', '3RO', '3RI'], // same to 3: need to edit soon
        }
    }
}
