import CommonService from '../../../services/common-api-service';
import { SERVER } from '../../../services/api-urls';

export default class ReportDashboardService {
     cs;
     constructor() {
          this.cs = new CommonService();
     }
     getReportDashboardData = (req) => {
          return this.cs.httpConnection(SERVER.SERVICES.getReportDashboardData, 'post', req);
     };
     getTireReportDashboardData = (req) => {
          return this.cs.httpConnection(SERVER.SERVICES.getTireReportDashboard, 'post', req);
     };
     getGraph = (req) => {
          return this.cs.httpConnection(SERVER.SERVICES.getGraph, 'post', req);
     };
}
