import React, { useEffect, useState, useContext } from "react";
import OtherTiresNavigation from "../../../other-tires-navigation/OtherTiresNavigation";
import "./AchievedHoursReport.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel } from "@material-ui/core";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import AchievedHoursReportDataSetter from "../../../../../services/AchievedHoursUtils";
import MultiSelect from "react-multi-select-component";
import AchievedHoursTable from "../table/AchievedHoursTable";
import { StateContext } from "../../../../../../../services/StateProvider";
import CommonUtils from "../../../../../../../services/utils";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import FilterTireReport from "../../../../../../../common-components/filter/components/main-container/FilterTireReport";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import TiresService from "../../../../../services/TiresApis.js";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'



const AchievedHoursReport = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const tiresService = new TiresService();
  const achievedHoursReportDataSetter = new AchievedHoursReportDataSetter();
  const commonUtils = new CommonUtils();
  const [showSpinner, setShowSpinner] = useState(true);

  const [achievedTireTableData, setAchievedTireTableData] = useState([]);
  const [tiresRAWData, setTiresRAWData] = useState([]);
  const [originalAchievedTireTableData, setOriginalAchievedTireTableData] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState([]);
  const [displayColumns, setDisplayColumns] = useState([]);
  const [noData, setNoData] = useState(false);
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [printData, setPrintData] = useState(achievedTireTableData);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Make", value: "Make" },
      { name: "Type", value: "Type" },
      { name: "Size", value: "Size" },
      { name: "Compound", value: "CompoundName" }
    ],
    categoryData: {
      Make: [],
      Type: [],
      Size: [],
      CompoundName: []
    }
  });
  let fromDate = new Date();
  useEffect(() => {
    let firstDate = fromDate.getFullYear() + "-01-01";
    setStartDate(firstDate);
    let endDate =
      fromDate.getFullYear() + "-" + ((fromDate.getMonth() + 1 < 10 ? "0" : "") + (fromDate.getMonth() + 1)) + "-" + (fromDate.getDate() < 10 ? "0" : "") + fromDate.getDate();
    setToDate(endDate);
    TiresSentToInspectResponseDataX(firstDate, filterApplied, filterFieldsApplied, endDate);
  }, [globalAccountState]);

  const TiresSentToInspectResponseDataX = (firstDate, _filterAppliedValue, filterFieldsAppliedValue, endDate) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    setShowSpinner(true);
    let req = {
      ParentAccount: accountIds[0],
      inspectionDate: firstDate,
      RemovalFromDate: firstDate,
      RemovalToDate: endDate
    };
    setShowSpinner(true);
    tiresService.getAchievedHoursReport(req).then((acievedData) => {
      let tiresAchievedTableInfo = achievedHoursReportDataSetter.setData(acievedData);
      let filterOptions = {
        categories: [
          {
            name: globalizationMapping.REPORTTABLE.MANUFACTURER,
            value: "Make"
          },
          { name: globalizationMapping.REPORTTABLE.SIZE, value: "Size" },
          { name: globalizationMapping.REPORTTABLE.TYPE, value: "Type" },
          {
            name: globalizationMapping.REPORTTABLE.COMPOUND,
            value: "CompoundName"
          }
        ],
        categoryData: {
          Make: [],
          Type: [],
          Size: [],
          CompoundName: []
        }
      };
      let filterOptionsInfo = achievedHoursReportDataSetter.setFilterOptionsData(filterOptions, tiresAchievedTableInfo.rowsData);
      setFilterOptions(filterOptionsInfo);
      setTableBeforeFilter(tiresAchievedTableInfo);

      let updatedHeadCells = achievedHoursReportDataSetter.updatedHeadCells();
      tiresAchievedTableInfo.rowsData?.length === 0 ? setNoData(true) : setNoData(false);

      setTiresRAWData(JSON.parse(JSON.stringify(tiresAchievedTableInfo)));
      setAchievedTireTableData(tiresAchievedTableInfo);
      setOriginalAchievedTireTableData(tiresAchievedTableInfo);

      setShowColumns(tiresAchievedTableInfo.selectedColShow);
      setFilterApplied(false);

      setDisplayColOptions(tiresAchievedTableInfo.headCells);
      setDisplayColumns(updatedHeadCells);
      setPrintData({
        reportType: "Other Tires : ",
        reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.AchievedHoursReport,
        rowsData: tiresAchievedTableInfo.rowsData,
        headCells: tiresAchievedTableInfo.headCells,
        avgValues: tiresAchievedTableInfo.avgValues,
        selectedColShow: tiresAchievedTableInfo.selectedColShow,
        updateUsers: tiresAchievedTableInfo.updateUsers,
        filterApplied: filterApplied
      });
      if (filterApplied) {
        setFilterApplied(true);
        let originalTableDataStore = tiresAchievedTableInfo.rowsData;
        let filteredData = achievedHoursReportDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setAchievedTireTableData({
          ...tiresAchievedTableInfo,
          rowsData: filteredData,
          avgValues: achievedHoursReportDataSetter.avgValues(filteredData)
        });
        setOriginalAchievedTireTableData({
          ...tiresAchievedTableInfo,
          rowsData: filteredData,
          avgValues: achievedHoursReportDataSetter.avgValues(filteredData)
        });
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: achievedHoursReportDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        confirmApplyDateFilter(filterFieldsApplied);
      }
      setShowSpinner(false);
    });
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });
  const globalizationMapping = new Globalization();

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };
  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };
  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };
  const onClickFilter = () => {
    setShowFilter(true);
  };
  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);
    let currentHeadCells = originalAchievedTireTableData?.headCells;
    let showColumnsUpdated = achievedHoursReportDataSetter.setDisplayColumnData(currentHeadCells, selected);

    setShowColumns(showColumnsUpdated);
    // when all columns selected
    if (selected.length === displayColOptions?.length) {
      setFilterApplied(false);
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: false
      });
    } else {
      setFilterApplied(true);
      setPrintData({
        ...printData,
        selectedColShow: showColumnsUpdated,
        filterApplied: true
      });
    }
    selectedColumns?.length === 0 ? setNoData(true) : setNoData(false);
  };
  // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    setSearchValue("");
    setFilterApplied(true);
    setFilterFieldsApplied(appliedFilter);
    let originalTableDataStore = originalAchievedTireTableData?.rowsData;
    let filteredData = achievedHoursReportDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setAchievedTireTableData({
      ...achievedTireTableData,
      rowsData: filteredData,
      avgValues: achievedHoursReportDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: achievedHoursReportDataSetter.avgValues(filteredData)
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    Object.values(appliedFilter).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);

    confirmApplyDateFilter(appliedFilter);
  };
  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    setFilterApplied(true);
    Object.values(appliedFilter).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);
    setFilterFieldsApplied(appliedFilter);
    let filteredData = [];
    if (appliedFilter.Type && appliedFilter.Type.length) {
      tiresRAWData.rowsData.forEach((obj) => {
        if (appliedFilter.Type.includes(obj.Type)) filteredData.push(obj);
      });
    } else {
      filteredData = tiresRAWData.rowsData;
    }
    if (appliedFilter.Make && appliedFilter.Make.length) {
      let filteredDataTemp = [];
      filteredData.forEach((obj) => {
        if (appliedFilter.Make.includes(obj.Make)) filteredDataTemp.push(obj);
      });
      filteredData = filteredDataTemp;
    }
    if (appliedFilter.Size && appliedFilter.Size.length) {
      let filteredDataTemp = [];
      filteredData.forEach((obj) => {
        if (appliedFilter.Size.includes(obj.Size)) filteredDataTemp.push(obj);
      });
      filteredData = filteredDataTemp;
    }
    if (appliedFilter.CompoundName && appliedFilter.CompoundName.length) {
      let filteredDataTemp = [];
      filteredData.forEach((obj) => {
        if (appliedFilter.CompoundName.includes(obj.CompoundName)) filteredDataTemp.push(obj);
      });
      filteredData = filteredDataTemp;
    }
    setAchievedTireTableData({
      ...achievedTireTableData,
      rowsData: filteredData,
      avgValues: achievedHoursReportDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      reportType: "Other Tires : ",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.AchievedHoursReport,
      rowsData: filteredData,
      headCells: originalAchievedTireTableData?.headCells,
      avgValues: achievedHoursReportDataSetter.avgValues(filteredData),
      selectedColShow: originalAchievedTireTableData?.selectedColShow,
      filterApplied: filterApplied
    });
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setFilterApplied(false);
    setShowFilter(false)
    setAchievedTireTableData(tableBeforeFilter);
    setOriginalAchievedTireTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    setNoData(false);
    setSearchValue("");
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
  };
  const updateFilterMain = (filterval) => {
  // empty function
  };
  //   USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    let tableDataStore, filteredData;
    tableDataStore = originalAchievedTireTableData?.rowsData;
    filteredData = achievedHoursReportDataSetter.search(tableDataStore, searchText);
    setAchievedTireTableData({
      ...achievedTireTableData,
      rowsData: filteredData,
      avgValues: achievedHoursReportDataSetter.avgValues(filteredData)
    });
    setPrintData({
      ...printData,
      reportType: "Other Tires : ",
      reportName: globalizationMapping.TIREREPORTS.OTHERTIRES.AchievedHoursReport,
      rowsData: filteredData,
      headCells: originalAchievedTireTableData?.headCells,
      avgValues: achievedHoursReportDataSetter.avgValues(filteredData),
      selectedColShow: originalAchievedTireTableData?.selectedColShow,
      filterApplied: filterApplied
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const handleFromStartDate = (event) => {
    setStartDate(event.startDate);
  };
  const handleFromToDate = (event) => {
    setToDate(event.endDate);
  };

  const onClickUpdate = () => {
    if (startDate !== "" || toDate !== "") {
      TiresSentToInspectResponseDataX(startDate, filterApplied, filterFieldsApplied, toDate);
    }
  };
  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };
  return (
    <div>
      <div className="container">
        <OtherTiresNavigation />
        {showSpinner && <Spinner />}
        {!showSpinner && (
          <div className="tire-register-container">
            <Card className="tire-register-card">
              <CardContent>
                <div className="table-toolbar">
                  <div className="table-filters">
                    <div className="display-columns-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.ACHIEVEDHOURSREPORT.FROMREMOVALDATE}</span>
                      </InputLabel>
                      <TextField
                        id="date"
                        type="date"
                        style={{ width: "100%" }}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={startDate}
                        onChange={(event) => handleFromStartDate({ startDate: event.target.value })}
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>

                    <div className="display-columns-container">
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.ACHIEVEDHOURSREPORT.TOREMOVALDATE}</span>
                      </InputLabel>
                      <TextField
                        id="date"
                        type="date"
                        style={{ width: "100%" }}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={toDate}
                        onChange={(event) => handleFromToDate({ endDate: event.target.value })}
                        inputProps={{ maxLength: 50 }}
                      />
                    </div>

                    <div className="display-columns-container">
                      <button className="update-button" onClick={onClickUpdate}>
                        <span className="Update-text-style">{globalizationMapping.INSPECT.UPDATE}</span>
                      </button>
                    </div>
                  </div>

                  <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
                </div>
                <div className="table-toolbar">
                  <div className="search-table-container" style={{ marginLeft: "0.5em" }}>
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>

                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                </div>

                <div className="tire-register-table">
                  <AchievedHoursTable tableData={achievedTireTableData} showColumns={showColumns} filterApplied={filterApplied} />
                </div>
              </CardContent>
            </Card>
          </div>
        )}

        <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <EmailModalComponent modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={true} />
        <FilterTireReport
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          filterOptions={filterOptions}
          confirmApplyFilter={confirmApplyFilter}
          confirmApplyDateFilter={confirmApplyDateFilter}
          resetTableFilter={resetTableFilter}
          onFilterUpdateMain={updateFilterMain}
        />
      </div>
    </div>
  );
};

export default AchievedHoursReport;
