import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Globalization from '../../../globalization';

const useStyles = makeStyles({
    innerContainer: {
        display: 'inline-grid'
    }
});

const TireRemovalDetailsGrid = ({ location, vehicleNo, tireData }) => {

    const classes = useStyles();
    const globalizationMapping = new Globalization();
    const titleTexts = globalizationMapping.INSPECTNREMOVETIRE;

    const [inspDate, setInspDate] = useState("");

    useEffect(() => {
        let inspdate = new Date(tireData.inspectionDate);
        inspdate = `${inspdate.getDate()}/${inspdate.getMonth() + 1}/${inspdate.getFullYear()}`;
        setInspDate(inspdate);
    }, [tireData.inspectionDate]);

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.SELECTEDPARENTLOCATION}</span>
                    <span className="item-detail-content">{location}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.VEHICLEID}</span>
                    <span className="item-detail-content">{vehicleNo}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.TIRESERIAL}</span>
                    <span className="item-detail-content">{tireData.tireSerialNo}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.BRANDNO}</span>
                    <span className="item-detail-content">{tireData.brandNo !== null ? tireData.brandNo : "-"}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.WHEELPOS}</span>
                    <span className="item-detail-content">{tireData.wheelPosition}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.LASTINSPECTION}</span>
                    <span className="item-detail-content">{inspDate}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.CURRENTDISTANCE}</span>
                    <span className="item-detail-content">{tireData.currentMiles}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.CURRENTHOURS}</span>
                    <span className="item-detail-content">{tireData.currentHours}</span>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default TireRemovalDetailsGrid;
