import React, { useEffect, useState } from "react";
import "./ImageContainer.css";
import {Grid} from '@material-ui/core';
import Spinner from "../../../../../../../common-components/spinner/spinner";
import ImageModal from "../../../../../../../common-components/modal-components/image-modal/ImageModalComponent";



const ImageContainer = ({eachTireData}) => {
  const [eachTireImageData, setEachTireImageData] = useState(eachTireData);
  const [showSpinner, setShowSpinner] = useState(true);
  const [modalData, setModalData] = useState({})
  const [modalHandlerImage, setModalHandlerImage] = React.useState({
    handleImage: false
  });
  const [selectedImage, setSelectedImage] = useState()
  useEffect(() => {
      setEachTireImageData(eachTireData);
      setShowSpinner(false);
    }, [eachTireData]);

  let imageArray= [];
  if(eachTireImageData){
    imageArray=eachTireImageData.split(',');
  }  
  const onClickTireImage = (index) => {
      setSelectedImage(index);
      setModalHandlerImage({ handleImage: true });
      let imageModalData={};
      imageModalData.imageData=imageArray;
      setModalData(imageModalData)
    }
return (
  eachTireImageData!==null?<><div className="removed-tire-image-container">
    {showSpinner && <Spinner />}
      {!showSpinner && (
        <Grid container spacing={5}>   
        {eachTireImageData && eachTireImageData.split(',')  && eachTireImageData.split(',').map((eachImage, index) =>
        <Grid item xs={3} key={index}>
            <div className="image-container" onClick={() => onClickTireImage(index)}>
            <img className="image-style" alt="" src={eachImage} /> 
            </div> 
        </Grid>  
        )}   
           
      </Grid>
      )}
    </div>
    <ImageModal
        modalData={modalData}
        modalHandlerImage={modalHandlerImage}
        setModalHandlerImage={setModalHandlerImage}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
    </>:null
    );
};

export default ImageContainer;