import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
import "./TiresByDispositionGraph.css";


const TiresByDispositionGraph = ({ tiresByDispositionGraphData }) => {
  const [chartInfoData, setChartInfoData] = useState({});
  const commonUtils = new CommonUtils();
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;

  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["disposition-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["disposition-y"];
  options.title.text = globalizationHeaders["disposition-title"];


  const chart = () => {
    if (tiresByDispositionGraphData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();

      let graphDataSum = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      tiresByDispositionGraphData.forEach((item) => {
        graphDataSum[item.Disposition] = 0;
        graphDataCount[item.Disposition] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      tiresByDispositionGraphData.forEach((item) => {
        graphDataSum[item.Disposition] += item.TireCount;
        graphDataCount[item.Disposition]++;
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataSum);

      setChartInfoData({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };
  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiresByDispositionGraphData]);

  return (
    <div className="tires-by-disposition-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && (
          <Bar data={chartInfoData} height={120} options={options} />
        )}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default TiresByDispositionGraph;
