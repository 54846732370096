import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization/index";
// eslint-disable-next-line no-unused-vars


const ScrapSummaryGraph = ({ scrapSummaryGraphData, label }) => {
  const [chartInfoData, setChartInfoData] = useState({});
  const [chartInfoDataForMile, setChartInfoDataForMile] = useState({});
  const commonUtils = new CommonUtils();
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const optionsForMile = JSON.parse(JSON.stringify(commonUtils.barOptions));
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["scrap-summary-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["scrap-summary-y"];
  options.title.text = globalizationHeaders["scrap-summary-title"];
  optionsForMile.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["scrap-summary-x"];
  optionsForMile.scales.yAxes[0].scaleLabel.labelString =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["scrap-summary-KM-y"]
      : globalizationHeaders["scrap-summary-mile-y"];
  optionsForMile.title.text =
    JSON.parse(
      localStorage.getItem("accountInfouserMeasurementSystemValue")
    ).toLowerCase() === "metric"
      ? globalizationHeaders["scrap-summary-KM-title"]
      : globalizationHeaders["scrap-summary-mile-title"];

  options.plugins.datalabels.formatter = (value) => {
    return value;
  };
  optionsForMile.plugins.datalabels.formatter = (value) => {
    return value;
  };

  const chart = () => {

    if (scrapSummaryGraphData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();
      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      scrapSummaryGraphData.forEach((item) => {
        graphDataSum[item.TypeName] = 0;
        graphDataAvg[item.TypeName] = 0;
        graphDataCount[item.TypeName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      scrapSummaryGraphData.forEach((item) => {
        graphDataSum[item.TypeName] += parseFloat(
          item.CostPerHour * item.TireCount
        );
        graphDataCount[item.TypeName] += item.TireCount;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((TypeName) => {
        graphDataAvg[TypeName] = parseFloat(
          (graphDataSum[TypeName] / graphDataCount[TypeName]).toFixed(2)
        );
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoData({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };
  const chartMiles = () => {

    if (scrapSummaryGraphData.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();
      let graphDataSum = {},
        graphDataAvg = {},
        graphDataCount = {};

      // setting the graph data sum and average objects
      scrapSummaryGraphData.forEach((item) => {
        graphDataSum[item.TypeName] = 0;
        graphDataAvg[item.TypeName] = 0;
        graphDataCount[item.TypeName] = 0;
      });

      // setting the graph data labels
      let graphDataLabels = Object.keys(graphDataCount);

      // calculates the sum of the graph data
      scrapSummaryGraphData.forEach((item) => {
        graphDataSum[item.TypeName] += parseFloat(
          item.CostPerMile * item.TireCount
        );
        graphDataCount[item.TypeName] += item.TireCount;
      });

      // calculates the average of the graph data
      graphDataLabels.forEach((TypeName) => {
        graphDataAvg[TypeName] = parseFloat(
          (graphDataSum[TypeName] / graphDataCount[TypeName]).toFixed(2)
        );
      });

      // setting the graph data values
      let graphDataValues = Object.values(graphDataAvg);

      setChartInfoDataForMile({
        labels: graphDataLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: graphDataValues,
          },
        ],
      });
    } else {
      setChartInfoDataForMile({});
    }
  };
  useEffect(() => {
    chart();
    chartMiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrapSummaryGraphData]);

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && label === "Hours" && (
          <Bar data={chartInfoData} height={120} options={options} />
        )}
        {Object.keys(chartInfoDataForMile).length > 0 && label === "Miles" && (
          <Bar
            data={chartInfoDataForMile}
            height={120}
            options={optionsForMile}
          />
        )}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ScrapSummaryGraph;
