import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Globalization from '../../globalization';

const useStyles = makeStyles({
    innerContainer: {
        display: 'inline-grid'
    },
    removeTire: {
        background: "#fff599 0% 0% no-repeat padding-box",
        borderRadius: 10,
        // padding: "1.26em",
        marginBottom: '1.4em'
    },
    removeTireLabel: {
        color: "#393939",
        fontSize: "1.25em",
        font: "normal normal normal 20px/24px Barlow",
        margin: "5px"
    },
    removeButton: {
        "&:hover": {
            backgroundColor: "#EAF3FB",
            borderRadius: 18,
            border: "none",
            opacity: 1,
            boxShadow: "none",
        },
        background: "transparent",
        border: "none",
        borderRadius: 18,
        color: "#0055A4",
        opacity: .8,
        letterSpacing: 0,
        fontWeight: 600,
        zIndex: 0,
        boxShadow: "none",
        marginRight: 5
    },
});

const TireDetailsGrid = ({ tireData, vehicleNo, location, conditions, onRemoveTireClick }) => {

    const classes = useStyles();
    const globalizationMapping = new Globalization();
    const titleTexts = globalizationMapping.INSPECTNREMOVETIRE;

    const [inspDate, setInspDate] = useState("");

    useEffect(() => {
        let inspDate = new Date(tireData.inspectionDate);
        if (sessionStorage.userLanguage === "en-uk") {
            inspDate = inspDate.getDate() + "/" + (inspDate.getMonth() + 1) + "/" + inspDate.getFullYear();
        } else {
            inspDate = + (inspDate.getMonth() + 1) + "/" + inspDate.getDate() + "/" + inspDate.getFullYear();
        }
        setInspDate(inspDate);
    }, [tireData.inspectionDate]);

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.SELECTEDPARENTLOCATION}</span>
                    <span className="item-detail-content">{location}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.VEHICLEID}</span>
                    <span className="item-detail-content">{vehicleNo}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.TIRESERIAL}</span>
                    <span className="item-detail-content">{tireData.tireSerialNo}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.BRANDNO}</span>
                    <span className="item-detail-content">{tireData.brandNo ? tireData.brandNo : "-"}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.WHEELPOS}</span>
                    <span className="item-detail-content">{tireData.wheelPosition}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.LASTINSPECTION}</span>
                    <span className="item-detail-content">{inspDate}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.CURRENTDISTANCE}</span>
                    <span className="item-detail-content">{tireData.currentMiles}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.CURRENTHOURS}</span>
                    <span className="item-detail-content">{tireData.currentHours}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.TREADDEPTH}</span>
                    <span className="item-detail-content">{tireData.currentTreadDepth}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.PRESSURE}</span>
                    <span className="item-detail-content">{tireData.currentPressure}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.CASINGCONDITION}</span>
                    <span className="item-detail-content">{conditions.casingCondition !== "" ? conditions.casingCondition : "-"}</span>
                </Grid>
                <Grid item xs={4} className={classes.innerContainer}>
                    <span className="item-detail-label">{titleTexts.WEARCONDITION}</span>
                    <span className="item-detail-content">{conditions.wearCondition !== "" ? conditions.wearCondition : "-"}</span>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default TireDetailsGrid;