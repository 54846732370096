import { Backdrop, Button, makeStyles, Modal } from "@material-ui/core";
import React from "react";
import Globalization from "../../../../../globalization";
import AdminListApis from "../../../services/AdminRequestListApis";

const useStyles = makeStyles({
  proceed: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      border: "none",
      opacity: 1,
      boxShadow: "none",
      borderRadius: 18,
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
  },
});
const AddLogoModal = (props) => {
  const styles = useStyles();
  const globalizationMapping = new Globalization();
  const adminListApis = new AdminListApis();
  const handleAddClick = () => {
    adminListApis.updateUserPreferences(props.data).then((_res) => {
      props.closeAddModal();
    });
  };
  return (
    <div className="container">
        <Modal
          open={true}
          onClose={props.closeAddModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300,
          }}
          disableScrollLock={true}
        >
          <div className="tire-transfer-modal-container center-modal">
            <div className="transfer-modal-title-container">
              <div className="transfer-modal-title">
                {globalizationMapping.LOGOMODEL.AddLogo}
              </div>
              <div
                className="close-icon-container"
                onClick={props.closeAddModal}
              >
                <img
                  src={require("../../../../../assets/close-icon.svg")}
                  className="modal-close-icon"
                  alt="close-icon"
                />
              </div>
            </div>
            <div className="transfer-modal-description">
              <img alt={"Logo"} src={props.data.logoLocation} />
            </div>
            <div className="modal-action-buttons">
              <Button
                className={styles.cancel}
                variant="contained"
                disableElevation={true}
                onClick={props.closeAddModal}
              >
                {globalizationMapping.FORMS.CANCEL}
              </Button>
              <Button
                className={styles.proceed}
                variant="contained"
                disableElevation={true}
                onClick={() => {
                  handleAddClick();
                }}
              >
                {globalizationMapping.FORMS.ADD}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
  );
};
export default AddLogoModal;
