import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";

class AchievedHoursReportDataSetter {
  constructor() {
    this.data = {};
    this.achievedHoursData = [];
    this.headCells = [];
    this.updateSelectedUsers = [];
    this.globalizationMapping = new Globalization();
  }

  formatData(registeredDate) {
    let date, formattedDate;
    if (registeredDate) {
      let dateString = registeredDate.split("T");
      date = dateString[0];
      // date = dateString[2] + '/' + dateString[0] +  '/' + dateString[1]
      formattedDate = date;
    } else {
      date = null;
      formattedDate = date;
    }

    return formattedDate;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      if (headCell.value !== "BrandNumber") {
        updatedHeadCells.push(headCell);
      }
    });
    return updatedHeadCells;
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });

    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  search(originalTableData, searchText) {

    return originalTableData.filter((item) => {
      searchText = searchText.toLowerCase().toString();
      let Make = !!item.Make;
      let Type = !!item.Type;
      let TireSerialNumber = !!item.TireSerialNumber;
      let AchievedHrsRear = !!item.AchievedHrsRear;
      let AchievedlHrsFront = !!item.AchievedlHrsFront;
      let CurrentEquipHours = !!item.CurrentEquipHours;
      let DateOfFirstFitment = !!item.DateOfFirstFitment;
      let EquipNo = !!item.EquipNo;
      let AssetNumber = !!item.AssetNumber;
      let HrPerMm = !!item.HrPerMm;
      let InspDate = !!item.InspDate;
      let Key = !!item.Key;
      let Otd = !!item.Otd;
      let Otd_A = !!item.Otd_A;
      let Otd_B = !!item.Otd_B;
      let PercentageWorn = !!item.PercentageWorn;
      let Pos = !!item.Pos;
      let PressureType = !!item.PressureType;
      let RepairLife = !!item.RepairLife;
      let TotalHours = !!item.TotalHours;
      let Size = !!item.Size;
      let CompoundName = !!item.CompoundName;
      let RatingName = !!item.RatingName;
      let AvgOtd = !!item.AvgOtd;
      let CurrentPressure = !!item.CurrentPressure;


      return (
        (Make ? item.Make.toLowerCase().includes(searchText) : false) ||
        (Type ? item.Type.toLowerCase().includes(searchText) : false) ||
        (TireSerialNumber ? item.TireSerialNumber.toLowerCase().includes(searchText) : false) ||
        (AchievedHrsRear ? item.AchievedHrsRear.toString().includes(searchText) : false)||
        (AchievedlHrsFront ? item.AchievedlHrsFront.toString().includes(searchText) : false) ||
        (CurrentEquipHours ? item.CurrentEquipHours.toString().includes(searchText) : false) ||
        (DateOfFirstFitment ? item.DateOfFirstFitment.toString().toLowerCase().includes(searchText) : false) ||
        (EquipNo ? item.EquipNo.toString().toLowerCase().includes(searchText) : false) ||
        (AssetNumber ? item.AssetNumber.toString().toLowerCase().includes(searchText) : false) ||
        (HrPerMm ? item.HrPerMm.toString().includes(searchText) : false) ||
        (InspDate ? item.InspDate.toString().toLowerCase().includes(searchText) : false) ||
        (Key ? item.Key.toString().includes(searchText) : false) ||
        (Otd ? item.Otd.toString().includes(searchText) : false) ||
        (Otd_A ? item.Otd_A.toString().includes(searchText) : false) ||
        (Otd_B ? item.Otd_B.toString().includes(searchText) : false) ||
        (PercentageWorn ? item.PercentageWorn.toString().includes(searchText) : false) ||
        (Pos ? item.Pos.toString().toLowerCase().includes(searchText) : false) ||
        (PressureType ? item.PressureType.toString().toLowerCase().includes(searchText) : false) ||
        (RepairLife ? item.RepairLife.toString().includes(searchText) : false) ||
        (TotalHours ? item.TotalHours.toString().includes(searchText) : false) ||
        (Size ? item.Size.toString().toLowerCase().includes(searchText) : false) ||
        (CompoundName ? item.CompoundName.toString().toLowerCase().includes(searchText) : false) ||
        (RatingName ? item.RatingName.toString().toLowerCase().includes(searchText) : false) ||
        (CurrentPressure ? item.CurrentPressure.toString().includes(searchText) : false) ||
        (AvgOtd ? item.AvgOtd.toString().includes(searchText) : false)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;

    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  avgValues = (achievedHoursData) => {
    let AchHoursFrontSum = 0,
      AchHoursFrontRearSum = 0,
      TotalAchHoursSum = 0,
      RepairLifeSum = 0,
      OtdSum = 0,
      OtdA = 0,
      OtdB = 0,
      percentageWorn = 0,
      hrsMm = 0,
      AvgOtdVal = 0,
      count1 = 0,
      count2 = 0,
      count3 = 0,
      count4 = 0;
    achievedHoursData.forEach((item, index) => {
      if (Number(item.AchievedlHrsFront) === 0) {
        count1++;
      }
      if (Number(item.AchievedHrsRear) === 0) {
        count2++;
      }
      if (Number(item.TotalHours) === 0) {
        count3++;
      }
      if (Number(item.RepairLife) === 0) {
        count4++;
      }
      AchHoursFrontSum += Number(item.AchievedlHrsFront);
      AchHoursFrontRearSum += Number(item.AchievedHrsRear);
      TotalAchHoursSum += Number(item.TotalHours);
      RepairLifeSum += Number(item.RepairLife);
      OtdSum += Number(item.Otd);
      OtdA += Number(item.Otd_A);
      OtdB += Number(item.Otd_B);
      AvgOtdVal += Number(item.AvgOtd);
      percentageWorn += Number(item.PercentageWorn);
      hrsMm += Number(item.HrPerMm);
    });

    let AchHoursFrontSumAvg = Math.round(Number(AchHoursFrontSum) / (achievedHoursData.length - count1));
    let AchHoursFrontRearSumAvg = Math.round(Number(AchHoursFrontRearSum) / (achievedHoursData.length - count2));
    let TotalAchHoursSumAvg = parseFloat(TotalAchHoursSum / (achievedHoursData.length - count3)).toFixed(2);
    let OtdSumAvg = Math.round(OtdSum / achievedHoursData.length);
    let OtdAAvg = Math.round(OtdA / achievedHoursData.length);
    let OtdBAvg = Math.round(OtdB / achievedHoursData.length);
    let AvgOtdAvg = Math.round(AvgOtdVal / achievedHoursData.length);
    let AvgRepairLifeSum = Math.round(RepairLifeSum / (achievedHoursData.length - count4));
    let percentageWornAvg = Math.round(percentageWorn / achievedHoursData.length);
    let hrsMmAvg = Math.round(hrsMm / achievedHoursData.length);

    const avgValues = {
      TireSerialNumber: "TOTAL AVERAGE",
      Make: "",
      Type: "",
      CompoundName: "",
      RatingName: "",
      DateOfFirstFitment: "",
      EquipNo: "",
      AssetNumber: "",
      Pos: "",
      InspDate: "",
      CurrentEquipHours: "",
      AchievedlHrsFront: AchHoursFrontSumAvg || 0,
      AchievedHrsRear: AchHoursFrontRearSumAvg || 0,
      TotalHours: TotalAchHoursSumAvg || 0,
      RepairLife: AvgRepairLifeSum || 0,
      Otd: OtdSumAvg || 0,
      Otd_A: OtdAAvg || 0,
      Otd_B: OtdBAvg || 0,
      AvgOtd: AvgOtdAvg || 0,
      PercentageWorn: percentageWornAvg || 0,
      HrPerMm: hrsMmAvg  || 0,
      PressureType: "",
      CurrentPressure: ""
    };
    return avgValues;
  };

  UptoDecimal(item) {
    return Math.round(item * 100) / 100;
  }

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.data;

    reportData.forEach((item) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.achievedHoursData.push({
        TireSerialNumber: item.tireSerial ? item.tireSerial : "",
        Make: item.make ? item.make : "",
        Type: item.type ? item.type : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        RatingName: item.ratingName ? item.ratingName : "",
        Size: item.tireSizeName ? item.tireSizeName : "",
        DateOfFirstFitment: this.formatData(item.dateOfFirstFitment) ? this.formatData(item.dateOfFirstFitment) : "",
        TotalHours: item.totalHours ? this.UptoDecimal(item.totalHours) : 0,
        EquipNo: item.equipNo ? item.equipNo : "",
        AssetNumber: item.assetNumber ? item.assetNumber : "",
        Pos: item.pos ? item.pos : "",
        InspDate: item.removalDate ? this.formatData(item.removalDate) : "",
        CurrentEquipHours: item.currentEquipHours ? this.UptoDecimal(item.currentEquipHours) : 0,
        AchievedlHrsFront: item.achievedlHrsFront ? this.UptoDecimal(item.achievedlHrsFront) : 0,
        AchievedHrsRear: item.achievedHrsRear ? this.UptoDecimal(item.achievedHrsRear) : 0,
        RepairLife: item.repairLife ? this.UptoDecimal(item.repairLife) : 0,
        Otd_A: item.otD_A ? this.UptoDecimal(item.otD_A) : 0,
        Otd_B: item.otD_B ? this.UptoDecimal(item.otD_B) : 0,
        Otd: item.otd ? this.UptoDecimal(item.otd) : 0,
        AvgOtd: item.avgOtd ? this.UptoDecimal(item.avgOtd) : 0,
        HrPerMm: item.hrPerMm ? this.UptoDecimal(item.hrPerMm) : 0,
        CurrentPressure: item.currentPressure ? this.UptoDecimal(item.currentPressure) : 0,
        PressureType: item.pressureType === "C" ? "Cold" : "Hot",
        MiddleTread: item.middleTread ? this.UptoDecimal(item.middleTread) : 0,
        RemoveAt: item.removeAt ? this.UptoDecimal(item.removeAt) : 0,
        PercentageWorn: item.percentageWorn ? this.UptoDecimal(item.percentageWorn) : 0
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.AchievedHoursReportText;
    //
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.achievedHoursData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.achievedHoursData),
      selectedColShow: selectedColShow
    };
  }
}

export default AchievedHoursReportDataSetter;
