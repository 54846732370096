export const IDENTITY_CONFIG = {
    authority: 'https://sso.goodyeartrucktires.com',
    client_id: 'EMTrackWebApp',
    redirect_uri: 'https://emtrack-react-staging.azurewebsites.net/authentication/callback',//'https://emtrack-react-test.azurewebsites.net/authentication/callback',//'http://localhost:3000/authentication/callback'
    //redirect_uri options: 
    //prod:    'https://emtrack-react.azurewebsites.net/authentication/callback',
    //test:    'https://emtrack-react-test.azurewebsites.net/authentication/callback',
    //staging: 'https://emtrack-react-staging.azurewebsites.net/authentication/callback
    //local:   'http://localhost:3000/authentication/callback'
    response_type: 'token id_token',
    scope: 'openid profile EMTireTrackAPI email',
    post_logout_redirect_uri: 'https://emtrack-react.azurewebsites.net/logout',
};

export const METADATA_OIDC = {
};
