import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";

class InspectionHistorySetter {
  constructor() {
    this.data = {};
    this.inspectionHistoryListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    const selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    const selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  setData(data) {
    const inspectionHistory = data.model;
    inspectionHistory.forEach((inspection, vehicleIndex) => {
      this.inspectionHistoryListData.push({
        Key: vehicleIndex,
        SerialNumber: inspection.tireSerialNo ? inspection.tireSerialNo : "",
        WheelPosition: inspection.wheelPosition ? inspection.wheelPosition : "",
        CurrentTread: inspection.currentTreadDepth
          ? inspection.currentTreadDepth
          : 0,
        CurrentPressure: inspection.currentPressure
          ? inspection.currentPressure
          : 0,
        Event: inspection.action ? inspection.action : "",
        TireId: inspection.tireId,
        VehicleId: inspection.vehicleId,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.InspectionHistory;
    columnDefinitions.forEach((item, index) => {
      const colDefnString = item.text.split(".");
      const [masterKey, key] = colDefnString;
      const labelName = this.globalizationMapping[masterKey][key];
      const labelId = item.fieldName;
      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    const selectedColShow = this.initDisplayColumnData(this.headCells);
    this.data = {
      rowsData: this.inspectionHistoryListData,
      headCells: this.headCells,
      selectedColShow,
    };
    return this.data;
  }
}
export default InspectionHistorySetter;
