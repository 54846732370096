import React, { useContext, useEffect, useState } from "react";
import "./create-vehicle.css";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import Breadcrumbs from "../../common-components/breadcrumbs/breadcrumbs";
import Globalization from "../../globalization";
import { Button, makeStyles, Step, withStyles, StepConnector } from "@material-ui/core";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Identification from "./step-identification";
import Description from "./step-description";
import OperationSpecifications from "./step-operation-specifications";
import HeaderApi from "../../common-components/header/services/header-apis";
import { StateContext } from "../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../services/AccountInfoHandler";
import Spinner from "../../common-components/spinner/spinner";
import rightArrow from "../../assets/Arrow.svg";
import FlashMessage from "../../common-components/pop-up-notification/notification-snackbar";


const CreateVehicle = () => {

  const history = useHistory();
  const globalizationMapping = new Globalization();
  let today = new Date();
  const locationObj = useLocation();
  const { VehicleId } = useParams();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const globalCreateVehicleTexts = globalizationMapping.CREATEVEHICLESTEPS;
  const tireFormCaptions = globalizationMapping.FORMS;
  const validationTexts = tireFormCaptions.VALIDATIONTEXTS;
  const notificationTexts = tireFormCaptions.NOTIFICATIONTEXTS;
  const [isClonedClicked, setClonedClicked] = useState(false);
  const [idFormErrors, setIdFormErrors] = useState({});
  const [trackingMethod, setTrackingMethod] = useState(1);
  const [mileageType, setMileageType] = useState(1);

  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = mm + "/" + dd + "/" + yyyy;

  const myBreadcrumbsName = () => {
    if (VehicleId !== undefined) {
      if ((locationObj.state && locationObj.state.option === "Clone") || isClonedClicked) {
        return globalHeaderTexts.CREATEVEHICLE;
      } else {
        return globalHeaderTexts.EDITVEHICLE;
      }
    } else {
      return globalHeaderTexts.CREATEVEHICLE;
    }
  }

  const myBreadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME
    },
    {
      path: "",
      name: myBreadcrumbsName()
    }
  ];

  const useStyles = makeStyles({
    width: "100%",
    next: {
      "&:hover": {
        opacity: 1
      },
      background: "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
      borderRadius: 18,
      font: "Barlow",
      opacity: 0.8,
      fontWeight: 500,
      color: "#FFFFFF",
      boxShadow: "none"
    },
    previous: {
      "&:hover": {
        backgroundColor: "#EAF3FB",
        borderRadius: 18,
        border: "1px solid #EAF3FB",
        opacity: 1,
        boxShadow: "none"
      },
      background: "transparent",
      border: "1px solid #EAF3FB",
      borderRadius: 18,
      color: "#0055A4",
      opacity: 0.8,
      letterSpacing: 0,
      font: "Barlow",
      fontWeight: 600,
      zIndex: 0,
      boxShadow: "none",
      marginRight: "10px"
    },
    cancel: {
      "&:hover": {
        backgroundColor: "#EAF3FB",
        borderRadius: 18,
        border: "none",
        opacity: 1,
        boxShadow: "none"
      },
      background: "transparent",
      border: "none",
      borderRadius: 18,
      color: "#0055A4",
      opacity: 0.8,
      letterSpacing: 0,
      fontWeight: 600,
      zIndex: 0,
      boxShadow: "none",
      marginRight: 5
    },
    rootStepper: {
      "& .MuiStepIcon-root": {
        color: "#CFDBEB",
        borderRadius: "50%",
        height: "35px",
        width: "35px",
        marginTop: "9px"
      },
      "& .MuiStepIcon-active": {
        color: "#FEDD00 !important",
        borderColor: "#0D54B5",
        border: "2px solid",
        borderRadius: "50%",
        height: "35px",
        width: "35px"
      },
      "& .MuiStepIcon-completed": {
        color: "#1BB121 !important"
      },
      "& .MuiStepIcon-text": {
        fill: "#0D0D0D",
        opacity: "0.4"
      },
      " & .MuiStepLabel-label": {
        marginTop: "12px",
        textAlign: "inherit",
        fontSize: "1.187rem"
      },
      "& .MuiStepLabel-root": {
        flexDirection: "row",
        marginLeft: "25%"
      },
      "& .MuiStepper-horizontal": {
        borderRadius: "4px",
        display: "flex",
        backgroundColor: "#E1EAF5 !important",
        border: "1px solid #D0D0D0 !important",
        opacity: "1"
      },
      "& .MuiStepIcon-active .MuiStepIcon-text": {
        fill: "#0D54B5 !important",
        opacity: "1 !important"
      },
      "& .MuiStepLabel-label": {
        marginLeft: "5%"
      }
    },
    rootStepper2: {
      "& .MuiStepIcon-root": {
        color: "#1BB121",
        borderRadius: "50%",
        border: "2px solid",
        borderColor: "#0D54B5",
        height: "35px",
        width: "35px",
        marginTop: "9px"
      },
      "& .MuiStepIcon-active": {
        color: "#FEDD00 !important",
        borderColor: "#0D54B5",
        border: "2px solid",
        borderRadius: "50%",
        height: "35px",
        width: "35px"
      },
      "& .MuiStepIcon-completed": {
        color: "#1BB121 !important",
        border: "none !important"
      },
      "& .MuiStepIcon-text": {
        fill: "#FFFFFF",
        opacity: "0.4"
      },
      " & .MuiStepLabel-label": {
        marginTop: "12px",
        textAlign: "inherit"
      },
      "& .MuiStepLabel-root": {
        flexDirection: "row",
        marginLeft: "25%"
      },
      "& .MuiStepper-horizontal": {
        borderRadius: "4px",
        display: "flex",
        backgroundColor: "#E1EAF5 !important",
        border: "1px solid #D0D0D0 !important",
        opacity: "1"
      },
      "& .MuiStepIcon-active .MuiStepIcon-text": {
        fill: "#0D54B5 !important",
        opacity: "1 !important"
      },
      "& .MuiStepLabel-label": {
        marginLeft: "5%",
        fontSize: "1.187rem"
      }
    }
  });

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22
    },
    active: {
      "& $line": {
        content: "url(" + rightArrow + ")",
        textAlign: "center",
        width: "100%",
        height: "30px",
        border: "none",
        marginTop: "-10px",
        display: "inline-block"
      }
    },
    completed: {
      "& $line": {
        content: "url(" + rightArrow + ")",
        textAlign: "center",
        width: "100%",
        height: "30px",
        border: "none",
        marginTop: "-10px",
        display: "inline-block"
      }
    },
    line: {
      content: "url(" + rightArrow + ")",
      textAlign: "center",
      width: "100%",
      height: "30px",
      border: "none",
      marginTop: "-10px",
      display: "inline-block"
    }
  })(StepConnector);

  const [globalAccountState] = useContext(StateContext);
  const [vehicleDetails, setVehicleDetails] = useState({
    areaOfOperation: "",
    averageLoadingReqId: 0,
    axleConfig: "",
    axleConfigId: 0,
    comments: "",
    currentHours: 0,
    currentMiles: 0,
    cuttingId: 0,
    event: "",
    hasMultipleTireSizes: false,
    imagesLocation: "",
    imgsrc: [],
    installedTireCount: 0,
    installedTires: [],
    locationId: accountInfoIdsFormatter(globalAccountState).locationIds[0],
    manufacturerId: 0,
    mileageType: 1,
    modelId: 0,
    modifications: "",
    multipleTireSizeIds: "",
    parentAccountId: accountInfoIdsFormatter(globalAccountState).accountIds.toString(),
    recommendedPressure: "",
    registeredDate: today,
    removalTread: 0,
    severityComments: "",
    siteSeverityIndex: 0,
    speedId: 0,
    tireSizeId: 0,
    typeId: 0,
    vehicleNumber: "",
    assetNumber: "",
    trackingMethod: 1
  });

  const [typesByManuf, setTypesByManuf] = useState([]);
  const [modelsBytype, setModelsByType] = useState([]);

  const [otherManuf, setOtherManuf] = useState({});
  const [otherType, setOtherType] = useState({});
  const [otherModel, setOtherModel] = useState({});
  const [otherSize, setOtherSize] = useState({});
  const [vehicleImages, setVehicleImages] = useState([]);
  const [imgHelper, setImgHelper] = useState("");
  const [vehicleMasterDetails, setVehicleMasterDetails] = useState([]);
  const [isOtherManuSelected, setOtherMenuSelected] = useState(false);
  const [isOtherTypeSelected, setOtherTypeSelected] = useState(false);
  const [isOtherModelSelected, setOtherModelSelected] = useState(false);
  const [isOtherSizeSelected, setOtherSizeSelected] = useState(false);
  //API Calls
  const headerApiObj = new HeaderApi();
  const [masterData, setMasterData] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);
  const fileUploadFormData = new FormData();
  const [showCreateClonedNotif, setCreateClonedNotif] = useState(false);
  const [showEditClonedNotif, setEditClonedNotif] = useState(false);

  const [tempVehicleNumber, setTempVehicleNumber] = useState("");
  const [tempClone, setTempClone] = useState("")
  const [storedImages, setStoredImages] = useState([]);
  const [userPreferences, setUserPreferences] = useState({});
  const [installedTireData, setInstalledTireData] = useState();
  const [preferenceUnits, setPreferenceUnits] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [flashMessageState, setFlashMessageState] = useState(locationObj);
  const [multipleOtherSizes, setMultipleOtherSizes] = useState({});
  const [otherSizeInput, setOtherSizeInput] = useState({})
  const [isVehicleNumberEdited, setIsVehicleNumberEdited] = useState(false);
  const [isCurrentHoursEdited, setIsCurrentHoursEdited] = useState(false);
  const [isCurrentMilesEdited, setIsCurrentMilesEdited] = useState(false);
  const [isManufacturerEdited, setIsManufacturerEdited] = useState(false);
  const [isNewManufacturerNameEdited, setIsNewManufacturerNameEdited] = useState(false);
  const [isTypeEdited, setIsTypeEdited] = useState(false);
  const [isNewTypeNameEdited, setIsNewTypeNameEdited] = useState(false);
  const [isModelEdited, setIsModelEdited] = useState(false);
  const [isNewModelNameEdited, setIsNewModelNameEdited] = useState(false);
  const [isSizeEdited, setIsSizeEdited] = useState(false);
  const [isNewSizeNameEdited, setIsNewSizeNameEdited] = useState(false);
  const [isOtherSizeEdited, setIsOtherSizeEdited] = useState([]);
  const [isOtherSizeInputEdited, setIsOtherSizeInputEdited] = useState({});
  const [isRemovalTreadEdited, setIsRemovalTreadEdited] = useState(false);

  useEffect(() => {
    setShowSpinner(true);
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let locationIds = accountInfoObject.locationIds;
    setPreferenceUnits({
      pressure: globalAccountState.accountInfo.userSetting.userPressureUnit,
      treadDepth: globalAccountState.accountInfo.userSetting.userMeasurementSystemValue
    });
    headerApiObj.getUserPreference().then((data) => {
      if (data.model !== undefined) setUserPreferences(data.model);
    });

    headerApiObj.getLocationById(locationIds[0]).then((locationData) => {
      if (locationData && locationData.model) {
        if (VehicleId === undefined) {
          let locationJson = { ...vehicleDetails };
          locationJson.locationId = locationData.model.locationId;
          locationJson.parentAccountId = locationData.model.parentAccountId;
          setVehicleDetails(locationJson);
        }
      }
    });

    headerApiObj.getMasterDataWeb().then((data) => {
      if (data.model !== undefined) setMasterData(data.model);
      //EditVehicle
      if (VehicleId) {
        if (locationObj.state === undefined || (locationObj.state && (locationObj.state.updatedSection === "updateHours" || locationObj.state.fromQuickInspect))) {
          headerApiObj.getInstalledTires(VehicleId).then((tireData) => {
            if (tireData && tireData.model) {
              setInstalledTireData(tireData.model);
            }
          });
        }
        headerApiObj.getVehicleDetails(VehicleId).then((VehicleData) => {
          if (VehicleData.model !== undefined) {
            setVehicleDetails(VehicleData.model);
            setTempVehicleNumber(VehicleData.model.vehicleNumber);
            setVehicleDetails(VehicleData.model);
            let arrImgs = [];
            if (VehicleData.model.imagesLocation !== null && VehicleData.model.imagesLocation !== "") arrImgs = VehicleData.model.imagesLocation.split(",");
            setStoredImages(arrImgs);

            let axles = [];
            if (VehicleData.model.axleConfig) {
              for (const element of VehicleData.model.axleConfig) {
                let x = [];
                for (let j = 0; j < Number(element) / 2; j++) {
                  x.push([""]);
                }
                axles.push(x);
              }
            }
            setVehicleDetails({
              ...vehicleDetails,
              ...VehicleData.model,
              axleConfig: axles
            });
            setTrackingMethod(VehicleData.model.trackingMethod);
            setMileageType(VehicleData.model.trackingMethod)

            // if (VehicleData.model.currentHours === 0 || VehicleData.model.currentHours === null)
            // else if (VehicleData.model.currentMiles === 0 || VehicleData.model.currentMiles === null)
            //     setTrackingMethod(1);
            // else
            //     setTrackingMethod(3);
            // eslint-disable-next-line array-callback-return

            let vehicleTypeByManufacturer = [];
            // eslint-disable-next-line array-callback-return
            data.model.vehicleTypes.map((type) => {
              if (type.manufacturerId === VehicleData.model.manufacturerId || type.typeName.trim().toLowerCase() === "other") {
                vehicleTypeByManufacturer.push(type);
              }
            });
            let sortedVehicleTypeByManufacturer = [...vehicleTypeByManufacturer].sort(function (a, b) {
              return compareStrings(a.typeName, b.typeName);
            })
            setTypesByManuf(sortedVehicleTypeByManufacturer);

            let vehicleModelByType = [];
            // eslint-disable-next-line array-callback-return
            data.model.vehicleModels.map((model) => {
              if (model.vehicleTypeId === VehicleData.model.typeId || model.modelName.trim().toLowerCase() === "other") {
                vehicleModelByType.push(model);
              }
            });

            let mappedVehicleModelByType = [...vehicleModelByType].sort(function (a, b) {
              return compareStrings(a.modelName, b.modelName);
            })
            setModelsByType(mappedVehicleModelByType)
              ;
            let selectedManufacturer = data.model.vehicleManufacturers.find((manufacturer) => manufacturer.manufacturerId === VehicleData.model.manufacturerId);
            if (selectedManufacturer === undefined || selectedManufacturer.manufacturerName.trim().toLowerCase() === "other") {
              setOtherMenuSelected(true);
            }
            let selectedType = data.model.vehicleTypes.find((model) => model.typeId === VehicleData.model.typeId);
            if (selectedManufacturer === undefined || selectedType === undefined || selectedType.typeName.trim().toLowerCase() === "other") {
              setOtherTypeSelected(true);
            }
            let selectedModel = data.model.vehicleModels.find((model) => model.modelId === VehicleData.model.modelId);
            if (selectedManufacturer === undefined || selectedType === undefined || selectedModel === undefined || selectedModel.modelName.trim().toLowerCase() === "other") {
              setOtherModelSelected(true);
            }
            let otherMfDetails, otherModelDetails, otherTyepDetails;
            if (selectedModel === undefined) {
              otherModelDetails = data.model.vehicleModels.find((model) => model.modelName.trim().toLowerCase() === "other");
              setVehicleDetails({
                ...vehicleDetails,
                ...VehicleData.model,
                modelId: otherModelDetails.modelId,
                axleConfig: axles
              });
            }
            if (selectedType === undefined) {
              otherMfDetails = data.model.vehicleManufacturers.find((manufacturer) => manufacturer.manufacturerName.trim().toLowerCase() === "other");
              otherModelDetails = data.model.vehicleModels.find((model) => model.modelName.trim().toLowerCase() === "other");
              otherTyepDetails = data.model.vehicleTypes.find((model) => model.typeName.trim().toLowerCase() === "other");
              setVehicleDetails({
                ...vehicleDetails,
                ...VehicleData.model,
                typeId: otherTyepDetails.typeId,
                modelId: otherModelDetails.modelId,
                axleConfig: axles
              });
            }
            if (selectedManufacturer === undefined) {
              otherMfDetails = data.model.vehicleManufacturers.find((manufacturer) => manufacturer.manufacturerName.trim().toLowerCase() === "other");
              otherModelDetails = data.model.vehicleModels.find((model) => model.modelName.trim().toLowerCase() === "other");
              otherTyepDetails = data.model.vehicleTypes.find((model) => model.typeName.trim().toLowerCase() === "other");
              setVehicleDetails({
                ...vehicleDetails,
                ...VehicleData.model,
                manufacturerId: otherMfDetails.manufacturerId,
                typeId: otherTyepDetails.typeId,
                modelId: otherModelDetails.modelId,
                axleConfig: axles
              });
            }
            let selecttireSize = data.model.tireSizes.find((manufacturer) => manufacturer.tireSizeId === VehicleData.model.tireSizeId);
            if ((VehicleData.model.hasMultipleTireSizes === false && selecttireSize === undefined) ||
              selecttireSize?.tireSizeName?.trim().toLowerCase() === "other") if (locationObj.state && locationObj.state.option === "Clone") {
                setClonedClicked(true);
                if (selectedManufacturer === undefined) {
                  otherMfDetails = data.model.vehicleManufacturers.find((manufacturer) => manufacturer.manufacturerName.trim().toLowerCase() === "other");
                  otherModelDetails = data.model.vehicleModels.find((model) => model.modelName.trim().toLowerCase() === "other");
                  otherTyepDetails = data.model.vehicleTypes.find((model) => model.typeName.trim().toLowerCase() === "other");
                  setVehicleDetails({
                    ...vehicleDetails,
                    ...VehicleData.model,
                    manufacturerId: otherMfDetails.manufacturerId,
                    typeId: otherTyepDetails.typeId,
                    modelId: otherModelDetails.modelId,
                    vehicleNumber: "",
                    assetNumber: "",
                    vehicleId: null,
                    currentHours: 0,
                    currentMiles: 0,
                    axleConfig: axles
                  });
                } else if (selectedType === undefined) {
                  otherModelDetails = data.model.vehicleModels.find((model) => model.modelName.trim().toLowerCase() === "other");
                  otherTyepDetails = data.model.vehicleTypes.find((model) => model.typeName.trim().toLowerCase() === "other");
                  setVehicleDetails({
                    ...vehicleDetails,
                    ...VehicleData.model,
                    typeId: otherTyepDetails.typeId,
                    modelId: otherModelDetails.modelId,
                    vehicleNumber: "",
                    assetNumber: "",
                    vehicleId: null,
                    currentHours: 0,
                    currentMiles: 0,
                    axleConfig: axles
                  });
                } else if (selectedModel === undefined) {
                  otherModelDetails = data.model.vehicleModels.find((model) => model.modelName.trim().toLowerCase() === "other");
                  setVehicleDetails({
                    ...vehicleDetails,
                    ...VehicleData.model,
                    modelId: otherModelDetails.modelId,
                    vehicleNumber: "",
                    assetNumber: "",
                    vehicleId: null,
                    currentHours: 0,
                    currentMiles: 0,
                    axleConfig: axles
                  });
                } else
                  setVehicleDetails({
                    ...vehicleDetails,
                    ...VehicleData.model,
                    vehicleNumber: "",
                    assetNumber: "",
                    vehicleId: null,
                    currentHours: 0,
                    currentMiles: 0,
                    axleConfig: axles
                  });
              }
          }
        });
      }
      else {
        setVehicleDetails({
          areaOfOperation: "",
          averageLoadingReqId: 0,
          axleConfig: "",
          axleConfigId: 0,
          comments: "",
          currentHours: 0,
          currentMiles: 0,
          cuttingId: 0,
          event: "",
          hasMultipleTireSizes: false,
          imagesLocation: "",
          imgsrc: [],
          installedTireCount: 0,
          installedTires: [],
          locationId: accountInfoIdsFormatter(globalAccountState).locationIds[0],
          manufacturerId: 0,
          mileageType: mileageType,
          modelId: 0,
          modifications: "",
          multipleTireSizeIds: "",
          parentAccountId: accountInfoIdsFormatter(globalAccountState).accountIds.toString(),
          recommendedPressure: "",
          registeredDate: today,
          removalTread: 0,
          severityComments: "",
          siteSeverityIndex: 0,
          speedId: 0,
          tireSizeId: 0,
          typeId: 0,
          vehicleNumber: "",
          assetNumber: "",
          trackingMethod: trackingMethod
        });
        setTempVehicleNumber("");
        setStoredImages([]);
        setClonedClicked(false);
      }
    });
    setTimeout(() => {
      setShowSpinner(false);
    }, 3500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (locationObj.state !== undefined) {
      if (locationObj.state.updatedSection === "updateHours") {
        if (locationObj.state.updateStatus) {
          setShowNotification(true);
        }
      } else {
        setTimeout(() => {
          setShowNotification(false);
        }, 5000);
      }
      setFlashMessageState(locationObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const [vehicleClonedEditNotif, setVehicleClonedEditNotif] = useState({
    notifmessage: "Vehicle Edited and Cloned",
    notifdesc: `Vehicle with ID: ${tempVehicleNumber} was edited successfully.`
  });

  const [vehicleClonedCreateNotif, setVehicleClonedCreateNotif] = useState({
    notifmessage: "Vehicle Created and Cloned",
    notifdesc: `Vehicle with ID: ${tempVehicleNumber} was created successfully.`
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setVehicleClonedEditNotif({
      notifmessage: "Vehicle Edited and Cloned",
      notifdesc: `Vehicle with ID: ${tempClone ? tempClone : tempVehicleNumber} was edited successfully.`
    });

    setVehicleClonedCreateNotif({
      notifmessage: "Vehicle Created and Cloned",
      notifdesc: `Vehicle with ID: ${tempClone ? tempClone : tempVehicleNumber} was created successfully.`
    });
  }, [tempVehicleNumber, tempClone]);


  const styling = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const steps = getsteps();

  function getsteps() {
    return [globalCreateVehicleTexts.IDENTIFICATION, globalCreateVehicleTexts.DESCRIPTION, globalCreateVehicleTexts.OPERATIONSPCIFICATIONS];
  }

  const resetTextFieldIsEditState = () => {
    setIsVehicleNumberEdited(false);
    setIsCurrentHoursEdited(false);
    setIsCurrentMilesEdited(false);
    setIsManufacturerEdited(false);
    setIsNewManufacturerNameEdited(false);
    setIsTypeEdited(false);
    setIsNewTypeNameEdited(false);
    setIsModelEdited(false);
    setIsNewModelNameEdited(false);
    setIsSizeEdited(false);
    setIsNewSizeNameEdited(false);
    setIsRemovalTreadEdited(false);
    setIsOtherSizeEdited({});
    setIsOtherSizeInputEdited({});
  }

  const getTotalSteps = () => {
    return steps.length;
  };

  const handleNextStep = () => {
    resetTextFieldIsEditState();
    switch (currentStep) {
      case 0:
        if (!validateIdForm()) return;
        break;
      case 1:
        if (!validateDescForm()) return;
        break;
      case 2:
        if (!validateSpecForm()) return;
        break;
      default:
        break;
    }

    if (currentStep !== getTotalSteps() - 1) {
      setCurrentStep((currentStep) => currentStep + 1);
      window.scrollTo(0, 0);
    } else submitVehicleCreationForm();
  };

  const handlePreviousStep = () => {
    resetTextFieldIsEditState();
    if (currentStep !== 0) {
      setCurrentStep((currentStep) => currentStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const handleStep = (step) => {
    resetTextFieldIsEditState();
    if (step <= currentStep) setCurrentStep(step);
    else {
      if (currentStep === 0) {
        if (step === 1) {
          if (!validateIdForm()) return;
        } else if (step === 2) {
          if (!validateIdForm() || !validateDescForm()) return;
        } else {
          if (!validateIdForm() || !validateDescForm() || !validateSpecForm()) return;
        }
      } else if (currentStep === 1) {
        if (step === 2) {
          if (!validateDescForm()) return;
        } else if (step === 3) {
          if (!validateDescForm() || !validateSpecForm()) return;
        } else {
          return;
        }
      } else {
        return;
      }
      setCurrentStep(step);
    }
  };

  const handleCancel = () => {
    // handleStep(0)
    let redirect;
    if (locationObj.state && locationObj.state.fromView) {
      redirect = "/view-vehicle/" + VehicleId;
    } else if (locationObj.state && locationObj.state.fromQuickInspect) {
      redirect = "/quick-inspect/" + VehicleId;
    } else redirect = "/manage-vehicle";
    history.push(redirect);
  };

  function redirect(updatedSection, successStatus, successMessage, successDesc, failureMsg) {
    let redirect;
    if (locationObj.state && locationObj.state.fromView) {
      redirect = "/view-vehicle/" + VehicleId;
    } else redirect = "/manage-vehicle";
    history.push({
      pathname: redirect,
      state: {
        updatedSection: updatedSection,
        updateStatus: successStatus,
        successMessage: successMessage,
        successMessageDesc: successDesc,
        failureMessage: failureMsg
      }
    });
  }
  function compareStrings(a, b) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a < b) {
      return -1;
    } else {
      return a > b ? 1 : 0;
    }

  }
  // Handling State Change
  const handleFieldChange = (field, val, extraVal) => {
    switch (field) {
      case "vehicleNumber":
        setVehicleDetails({ ...vehicleDetails, vehicleNumber: val });
        setTempVehicleNumber(val);
        setIsVehicleNumberEdited(true);
        break;
      case "assetNumber":
        setVehicleDetails({ ...vehicleDetails, assetNumber: val });
        break;
      case "regDate":
        if (val !== null) {
          let date = val;
          date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
          setVehicleDetails({ ...vehicleDetails, registeredDate: date });
        } else {
          setVehicleDetails({ ...vehicleDetails, registeredDate: "" });
        }
        break;
      case "currentHours":
        setVehicleDetails({ ...vehicleDetails, currentHours: val });
        setIsCurrentHoursEdited(true);
        break;
      case "currentMiles":
        setVehicleDetails({ ...vehicleDetails, currentMiles: val });
        setIsCurrentMilesEdited(true);
        break;
      case "Both":
      case "Hours":
      case "Miles":
        setVehicleDetails({ ...vehicleDetails, trackingMethod: val, mileageType: val });
        setTrackingMethod(val);
        setMileageType(val)

        break;
      case "manufacturer":
        {
          setVehicleDetails({
            ...vehicleDetails,
            manufacturerId: val,
            typeId: 0,
            modelId: 0,
            tireSizeId: 0,
            recommendedPressure: ""
          });
          setVehicleMasterDetails([]);
          setOtherManuf({});
          setOtherTypeSelected(false);
          setIsManufacturerEdited(true);
          let vehicleTypeByManufacturer = [];
          let item = masterData.vehicleManufacturers.find((mcfr) => mcfr.manufacturerId === parseInt(val));
          if (item.manufacturerName.trim().toLowerCase() === "other") {
            setOtherMenuSelected(true);
            setIsNewManufacturerNameEdited(true);
          }
          else setOtherMenuSelected(false);

          // eslint-disable-next-line array-callback-return
          masterData.vehicleTypes.map((type) => {
            if (type.typeName.trim().toLowerCase() === "other" && item.manufacturerName.trim().toLowerCase() !== "other") vehicleTypeByManufacturer.push(type);
            if (type.manufacturerId === parseInt(val)) {
              vehicleTypeByManufacturer.push(type);
            }
          });
          setTypesByManuf(
            [...vehicleTypeByManufacturer].sort(function (a, b) {
              return compareStrings(a.typeName, b.typeName);
            })
          );
        }
        break;
      case "typeid":
        setVehicleDetails({
          ...vehicleDetails,
          typeId: val,
          modelId: 0,
          tireSizeId: 0,
          recommendedPressure: ""
        });
        setOtherType({});
        setOtherModelSelected(false);
        setIsTypeEdited(true);
        let vehicleModelByType = [];

        let selectedType = masterData.vehicleTypes.find((type) => type.typeId === parseInt(val));
        if (selectedType.typeName.trim().toLowerCase() === "other") {
          setOtherTypeSelected(true);
          setIsNewTypeNameEdited(true);
        }
        else setOtherTypeSelected(false);
        // eslint-disable-next-line array-callback-return
        masterData.vehicleModels.map((model) => {
          if (model.modelName.trim().toLowerCase() === "other" && selectedType.typeName.trim().toLowerCase() !== "other") vehicleModelByType.push(model);
          if (model.vehicleTypeId === parseInt(val)) {
            vehicleModelByType.push(model);
          }
        });
        setModelsByType(
          [...vehicleModelByType].sort(function (a, b) {
            return compareStrings(a.modelName, b.modelName);
          })
        );
        break;
      case "modelid":
        setOtherModel({});
        let selectedModel = masterData.vehicleModels.find((model) => model.modelId === parseInt(val));
        if (selectedModel.modelName.trim().toLowerCase() === "other") {
          setOtherModelSelected(true);
          setIsNewModelNameEdited(true);
          setVehicleDetails({
            ...vehicleDetails,
            modelId: val,
            axleConfigId: 0,
            axleConfig: "",
            recommendedPressure: "",
            ...(extraVal ? extraVal : {})
          });
        }
        else {
          setOtherModelSelected(false);
          setVehicleDetails({
            ...vehicleDetails,
            modelId: val,
            axleConfigId: selectedModel.configurationId,
            ...(extraVal ? extraVal : {})
          });
        }
        setIsModelEdited(true);
        break;
      case "axleconfigid":
        setVehicleDetails({
          ...vehicleDetails,
          axleConfigId: val,
          ...(extraVal ? extraVal : {})
        });
        break;
      case "tiresizeid":
        setVehicleDetails({ ...vehicleDetails, tireSizeId: val });
        setOtherSize({});
        setIsSizeEdited(true);
        let selectedSize = masterData.tireSizes.find((size) => size.tireSizeId === parseInt(val));
        if (selectedSize.tireSizeName.trim().toLowerCase() === "other") {
          setOtherSizeSelected(true);
          setIsNewSizeNameEdited(true);
        }
        else setOtherSizeSelected(false);
        break;
      case "areaofoperation":
        setVehicleDetails({ ...vehicleDetails, areaOfOperation: val });
        break;
      case "modifications":
        setVehicleDetails({ ...vehicleDetails, modifications: val });
        break;
      case "images":
        if (storedImages.length + vehicleDetails.imgsrc.length < 3) {
          if (val.files[0]) {
            let reader = new FileReader();
            reader.readAsDataURL(val.files[0]);
            reader.onloadend = () => {
              let imageSet = [...vehicleDetails.imgsrc];
              imageSet.push(reader.result);
              setVehicleDetails({ ...vehicleDetails, imgsrc: imageSet });

              let newImageSet = [...vehicleImages];
              newImageSet.push(val.files[0]);
              setVehicleImages(newImageSet);
              val.value = null;
            };
          }
        } else setImgHelper(validationTexts.IMGLIMIT);
        break;
      case "siteseverityindex":
        setVehicleDetails({ ...vehicleDetails, siteSeverityIndex: val });
        break;
      case "removaltread":
        setVehicleDetails({ ...vehicleDetails, removalTread: val });
        setIsRemovalTreadEdited(true);
        break;
      case "severityComments":
        setVehicleDetails({ ...vehicleDetails, severityComments: val });
        break;
      case "recommenedPressure":
        setVehicleDetails({ ...vehicleDetails, recommendedPressure: val });
        break;
      case "multipleTireSizeIds":
        setVehicleDetails({ ...vehicleDetails, multipleTireSizeIds: val });
        const splitAllSizes = val.split(",");
        setIsOtherSizeEdited(splitAllSizes);
        break;
      case "hasMultipleTireSizes":
        setVehicleDetails({ ...vehicleDetails, hasMultipleTireSizes: val });
        break;
      case "otherSizeInput":
        setOtherSizeInput({ ...otherSizeInput, [extraVal]: val });
        setIsOtherSizeInputEdited({ ...isOtherSizeInputEdited, [extraVal]: true })
        break;
      default:
        break;
    }
  };

  const deleteStoredImage = (index) => {
    let imgsArr = [...storedImages];
    imgsArr.splice(index, 1);
    setStoredImages(imgsArr);
    setImgHelper("");
  };

  const deleteImage = (index) => {
    let imgsArr = [...vehicleDetails.imgsrc];
    let imgfiles = [...vehicleImages];
    imgsArr.splice(index, 1);
    imgfiles.splice(index, 1);
    setVehicleImages(imgfiles);
    setVehicleDetails({ ...vehicleDetails, imgsrc: imgsArr });
    setImgHelper("");
  };

  const handleVehicleMasterDataChange = (field, val, axelNumber = -1) => {
    let date = new Date();
    date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    let newData = {};
    switch (field) {
      case "otherManuf":
        newData = {
          requestDate: date,
          fieldId: 1,
          description: val,
          status: "New"
        };
        setOtherManuf(newData);
        break;

      case "otherType":
        newData = {
          requestDate: date,
          fieldId: 2,
          description: val,
          status: "New"
        };
        setOtherType(newData);
        break;

      case "otherModel":
        newData = {
          requestDate: date,
          fieldId: 3,
          description: val,
          status: "New"
        };
        setOtherModel(newData);
        break;
      case "otherSize":
        newData = {
          requestDate: date,
          fieldId: 4,
          description: val,
          status: "New"
        };
        setOtherSize(newData);
        break;
      case "multipleOtherSize":
        newData = Object.keys(multipleOtherSizes).length ? multipleOtherSizes : {};
        newData[axelNumber + ""] = {
          requestDate: date,
          fieldId: 4,
          description: val,
          status: "New"
        };
        setMultipleOtherSizes(newData);
        break;
      default:
        break;
    }
  };

  const validateIdForm = () => {
    let isFormValid = true;
    let FormErrors = {};

    Object.keys(vehicleDetails).forEach((key) => {
      if (key === "vehicleNumber" && String(vehicleDetails[key]) === "") {
        FormErrors[key] = validationTexts.MISSINGFIELD;
        isFormValid = false;
      }
      if (key === "registeredDate" && (isNaN(parseInt(vehicleDetails[key], 10)) || String(vehicleDetails[key]) === "")) {
        FormErrors[key] = validationTexts.INVALIDFIELD;
        isFormValid = false;
      }

      if (key === "currentHours" && (parseInt(trackingMethod) === 3 || trackingMethod === 1) && (String(vehicleDetails[key]) === "" || parseInt(vehicleDetails[key]) < 0)) {
        FormErrors[key] = validationTexts.CURRENTHOURSMILES;
        isFormValid = false;
      }
      if (
        key === "currentMiles" &&
        (parseInt(trackingMethod) === 3 || parseInt(trackingMethod) === 2) &&
        (String(vehicleDetails[key]) === "" || parseInt(vehicleDetails[key]) < 0)
      ) {
        FormErrors[key] = validationTexts.CURRENTHOURSMILES;
        isFormValid = false;
      }
      return true;
    });

    setIdFormErrors(FormErrors);
    return isFormValid;
  };

  const [descFormErrors, setDescFormErrors] = useState({});

  const validateDescForm = () => {
    let isDescFormValid = true;
    let FormErrors = {};

    // eslint-disable-next-line array-callback-return
    Object.keys(vehicleDetails).forEach((key) => {
      if (key === "manufacturerId" || key === "typeId" || key === "modelId" || key === "tireSizeId") {
        if (Number(vehicleDetails[key]) === 0 && vehicleDetails["hasMultipleTireSizes"] === false) {
          isDescFormValid = false;
          FormErrors[key] = validationTexts.MISSINGFIELD;
        }
        if (key === "tireSizeId" && vehicleDetails["hasMultipleTireSizes"] === false) {
          if (Number(vehicleDetails[key]) === 0) {
            isDescFormValid = false;
            FormErrors[key] = validationTexts.MISSINGFIELD;
          }
        }

        if (vehicleDetails["hasMultipleTireSizes"] === true) {
          let multipleOtherSizeIdSelected = [];
          let multipleOtherSizeEntered = [];
          const allSizes = vehicleDetails["multipleTireSizeIds"].split(",");
          allSizes.map((size) => {
            size === '0' ? multipleOtherSizeIdSelected.push(validationTexts.MISSINGFIELD) : multipleOtherSizeIdSelected.push("");
          });

          isDescFormValid = false;
          FormErrors['multipleTireSizeIds'] = multipleOtherSizeIdSelected;
          FormErrors['multipleOtherSizeArray'] = multipleOtherSizeEntered;
          if (multipleOtherSizeIdSelected.every(element => element === "") && multipleOtherSizeEntered.every(ele => ele === "")) isDescFormValid = true;
        }
      }
    });
    let imglocstr = "";
    let imglocarr = [...storedImages];
    for (let i = 0; i < imglocarr.length; i++) {
      if (i === 0) imglocstr = imglocstr + imglocarr[i];
      else imglocstr = imglocstr + "," + imglocarr[i];
    }
    setVehicleDetails({ ...vehicleDetails, imagesLocation: imglocstr });
    setDescFormErrors(FormErrors);
    let masterTireArr = vehicleMasterDetails;
    // eslint-disable-next-line array-callback-return
    vehicleMasterDetails.forEach((obj, key) => {
      if (obj.description === "" || !obj.description) masterTireArr.splice(key, 1);
    });
    setVehicleMasterDetails(masterTireArr);
    return isDescFormValid;
  };

  //OperationSpecificationForm
  const [specFormErrors, setSpecFormErrors] = useState({});
  const validateSpecForm = () => {
    let isSpecFormValid = true;
    let FormErrors = {};
    Object.keys(vehicleDetails).forEach((key) => {
      if (key === "siteSeverityIndex") {
        if (Number(vehicleDetails[key]) === 0) {
          isSpecFormValid = false;
          FormErrors[key] = validationTexts.MISSINGFIELD;
        }
      }
      // if (key === "removalTread") {
      //   if (String(vehicleDetails[key]) === "") {
      //     isSpecFormValid = false;
      //     FormErrors[key] = 'This field is required and should not be < 0';
      //   }
      // }
      return true;
    });
    setSpecFormErrors(FormErrors);
    return isSpecFormValid;
  };

  function submitVehicleCreationForm() {
    validateSpecForm();
    resetTextFieldIsEditState();
    if (validateIdForm() && validateDescForm()) {
      let isMasterDataEmpty = true;
      for (const element of vehicleMasterDetails) {
        if (Object.keys(element).length !== 0) {
          isMasterDataEmpty = false;
          break;
        }
      }
      setShowSpinner(true);
      if ((locationObj.state && locationObj.state.option === "Clone") || isClonedClicked) {
        delete vehicleDetails["vehicleId"];
        createNewVehicle(isMasterDataEmpty);
      } else if (VehicleId) {
        setCreate(create + 1)
        let updatedSection = "editvehicle";
        let successMessage = notificationTexts.VEHICLEEDITED;
        let successDesc = notificationTexts.VEHICLEUPDATESUCCESS.replace("#1#1", vehicleDetails.vehicleNumber);
        let failureMsg = notificationTexts.VEHICLENOTEDITED;
        let successStatus = false;

        if (Object.keys(multipleOtherSizes).length) {
          let multipleOtherSizeTemp = vehicleMasterDetails.length ? vehicleMasterDetails : [];
          multipleOtherSizeTemp.shift();
          multipleOtherSizeTemp.shift();
          let tempKeys = Object.keys(multipleOtherSizes);
          if (Object.keys(multipleOtherSizes).length) {
            for (let i = 0; i < Object.keys(multipleOtherSizes).length; i++) {
              let currentKeys = tempKeys[i];
              multipleOtherSizeTemp.push(multipleOtherSizes[currentKeys]);
            }
          }
        }

        if (!isMasterDataEmpty) {
          headerApiObj.createMasterData(vehicleMasterDetails).then((masterResponse) => {
            if (!masterResponse.didError) {
              if (vehicleImages.length !== 0) {
                let timestamp = new Date().getTime();
                fileUploadFormData.append("vehicleId", VehicleId);
                Object.values(vehicleImages).forEach((img, index) => {
                  fileUploadFormData.append("imgsrc", img, index + "_" + timestamp + ".png");
                  return true;
                });

                headerApiObj.createVehicleImages(fileUploadFormData).then((response) => {
                  if (!response.didError) {
                    let imgStr = vehicleDetails.imagesLocation + "," + response.message;
                    setVehicleDetails({
                      ...vehicleDetails,
                      imagesLocation: imgStr
                    });
                    headerApiObj
                      .updateVehicle({
                        ...vehicleDetails,
                        imagesLocation: imgStr,
                        axleConfig: "",
                        event: "Update"
                      })
                      .then((response) => {
                        if (response.didError === false || !response.isError) {
                          successStatus = true;
                        } else {
                          successStatus = false;
                        }
                      });

                    setShowSpinner(false);
                    redirect(updatedSection, successStatus, successMessage, successDesc, failureMsg);
                  } else {
                    setShowSpinner(false);
                  }
                });
              } else {
                headerApiObj
                  .updateVehicle({
                    ...vehicleDetails,
                    axleConfig: "",
                    event: "Update"
                  })
                  .then((response) => {
                    if (response.didError === false || !response.isError) {
                      successStatus = true;
                    } else {
                      successStatus = false;
                    }
                    setShowSpinner(false);
                    redirect(updatedSection, successStatus, successMessage, successDesc, failureMsg);
                  });
              }
            }
          });
        } else {
          if (vehicleImages.length !== 0) {
            let timestamp = new Date().getTime();
            fileUploadFormData.append("vehicleId", VehicleId);
            Object.values(vehicleImages).forEach((img, index) => {
              fileUploadFormData.append("imgsrc", img, index + "_" + timestamp + ".png");
              return true;
            });

            headerApiObj.createVehicleImages(fileUploadFormData).then((response) => {
              if (response.didError === false) {
                let imgStr = vehicleDetails.imagesLocation + "," + response.message;
                setVehicleDetails({
                  ...vehicleDetails,
                  imagesLocation: imgStr
                });
                headerApiObj
                  .updateVehicle({
                    ...vehicleDetails,
                    imagesLocation: imgStr,
                    axleConfig: "",
                    event: "Update"
                  })
                  .then((response) => {
                    if (response.didError === false || !response.isError) {
                      successStatus = true;
                    } else {
                      successStatus = false;
                    }
                    redirect(updatedSection, successStatus, successMessage, successDesc, failureMsg);
                  });

                setShowSpinner(false);
              } else {
                setShowSpinner(false);
              }
            });
          } else {
            headerApiObj
              .updateVehicle({
                ...vehicleDetails,
                axleConfig: "",
                event: "Update"
              })
              .then((response) => {
                if (response.didError === false || !response.isError) {
                  successStatus = true;
                } else {
                  successStatus = false;
                }
                redirect(updatedSection, successStatus, successMessage, successDesc, failureMsg);
              });

            setShowSpinner(false);
          }
        }
      } else {
        createNewVehicle(isMasterDataEmpty);
      }
    }
  }


  const [create, setCreate] = useState(0);
  window.localStorage.setItem("Count", create)

  function createNewVehicle(isMasterDataEmpty) {
    setCreate(create + 1)
    let updatedSection = "createnewvehicle";
    let successMessage = notificationTexts.VEHICLECREATED;
    let successDesc = notificationTexts.VEHICLECREATEDSUCCESS.replace("#1#1", vehicleDetails.vehicleNumber);
    let failureMsg = notificationTexts.VEHICLENOTCREATED;
    let successStatus = false;

    if (Object.keys(multipleOtherSizes).length) {
      let multipleOtherSizeTemp = vehicleMasterDetails.length ? vehicleMasterDetails : [];
      multipleOtherSizeTemp.shift();
      multipleOtherSizeTemp.shift();
      let tempKeys = Object.keys(multipleOtherSizes);
      if (Object.keys(multipleOtherSizes).length) {
        for (let i = 0; i < Object.keys(multipleOtherSizes).length; i++) {
          let currentKeys = tempKeys[i];
          multipleOtherSizeTemp.push(multipleOtherSizes[currentKeys]);
        }
      }
    }

    headerApiObj.createVehicle({ ...vehicleDetails, axleConfig: "" }).then((response) => {
      if ((response.didError !== undefined && !response.didError) || !response.isError) {
        successStatus = true;
        if (!isMasterDataEmpty) {
          headerApiObj.createMasterData(vehicleMasterDetails).then((masterResponse) => {
            if (!masterResponse.didError) {
              if (vehicleImages.length !== 0) {
                let timestamp = new Date().getTime();
                fileUploadFormData.append("vehicleId", response.insertedId);
                Object.values(vehicleImages).forEach((img, index) => {
                  fileUploadFormData.append("imgsrc", img, index + "_" + timestamp + ".png");
                  return true;
                });

                headerApiObj.createVehicleImages(fileUploadFormData).then((response) => {
                  if (!response.didError) {
                    setVehicleImages([]);
                  }
                });
              }
              setShowSpinner(false);
              redirect(updatedSection, successStatus, successMessage, successDesc, failureMsg);

              isMasterDataEmpty = true;
            }
          });
        } else {
          if (vehicleImages.length !== 0) {
            let timestamp = new Date().getTime();
            Object.values(vehicleImages).forEach((img, index) => {
              fileUploadFormData.append("imgsrc", img, index + "_" + timestamp + ".png");
              return true;
            });
            fileUploadFormData.append("vehicleId", response.insertedId);

            headerApiObj.createVehicleImages(fileUploadFormData).then((imageResponse) => {
              if (!imageResponse.didError) {
                setVehicleImages([]);
                redirect(updatedSection, successStatus, successMessage, successDesc, failureMsg);
              }
            });
          }
        }
        if (isMasterDataEmpty && vehicleImages.length === 0) {
          setShowSpinner(false);
          redirect(updatedSection, successStatus, successMessage, successDesc, failureMsg);
        }
      } else {
        if (response.message === "A vehicle with the entered vehicle number already exists in this account") {
          handleStep(0);
          setIdFormErrors({
            ...idFormErrors,
            vehicleNumber: validationTexts.VEHICLEEXISTS.replace("#1#1", vehicleDetails.vehicleNumber)
          });
        }
        setShowSpinner(false);
      }
    });
  }



  const handleCloneClick = () => {
    validateSpecForm();
    setShowSpinner(true);
    setCreateClonedNotif(false);
    setEditClonedNotif(false);
    resetTextFieldIsEditState();
    if (validateIdForm() && validateDescForm()) {
      let successStatus = false;

      let isMasterDataEmpty = true;
      for (const element of vehicleMasterDetails) {
        if (Object.keys(element).length !== 0) {
          isMasterDataEmpty = false;
          break;
        }
      }

      if (VehicleId && !(locationObj.state && locationObj.state.option === "Clone") && !isClonedClicked) {
        if (!isMasterDataEmpty) {
          headerApiObj.createMasterData(vehicleMasterDetails).then((masterResponse) => {
            if (!masterResponse.didError) {
              if (vehicleImages.length !== 0) {
                let timestamp = new Date().getTime();
                fileUploadFormData.append("vehicleId", VehicleId);
                Object.values(vehicleImages).forEach((img, index) => {
                  fileUploadFormData.append("imgsrc", img, index + "_" + timestamp + ".png");
                  return true;
                });

                headerApiObj.createVehicleImages(fileUploadFormData).then((response) => {
                  if (!response.didError) {
                    let imgStr = vehicleDetails.imagesLocation + "," + response.message;
                    headerApiObj
                      .updateVehicle({
                        ...vehicleDetails,
                        imagesLocation: imgStr,
                        axleConfig: "",
                        event: "Update"
                      })
                      .then((response) => {
                        if (response.didError === false || !response.isError) {
                          successStatus = true;
                        } else {
                          successStatus = false;
                        }
                      });
                    setClonedClicked(true);
                    setEditClonedNotif(successStatus);
                    setVehicleDetails({
                      ...vehicleDetails,
                      imagesLocation: imgStr,
                      vehicleId: null,
                      vehicleNumber: "",
                      assetNumber: "",
                      currentHours: 0,
                      currentMiles: 0
                    });
                    handleStep(0);
                    setShowSpinner(false);
                  }
                });
              } else {
                headerApiObj
                  .updateVehicle({
                    ...vehicleDetails,
                    axleConfig: "",
                    event: "Update"
                  })
                  .then((response) => {
                    if (response.didError === false || !response.isError) {
                      successStatus = true;
                    } else {
                      successStatus = false;
                    }

                    setClonedClicked(true);
                    setVehicleDetails({
                      ...vehicleDetails,
                      vehicleNumber: "",
                      assetNumber: "",
                      vehicleId: null,
                      currentHours: 0,
                      currentMiles: 0
                    });
                    setEditClonedNotif(successStatus);
                    handleStep(0);
                    setShowSpinner(false);
                  });
              }
            }
          });
        } else {
          if (vehicleImages.length !== 0) {
            let timestamp = new Date().getTime();
            fileUploadFormData.append("vehicleId", VehicleId);
            Object.values(vehicleImages).forEach((img, index) => {
              fileUploadFormData.append("imgsrc", img, index + "_" + timestamp + ".png");
              return true;
            });

            headerApiObj.createVehicleImages(fileUploadFormData).then((response) => {
              if (response.didError === false) {
                let imgStr = vehicleDetails.imagesLocation + "," + response.message;
                setVehicleDetails({
                  ...vehicleDetails,
                  imagesLocation: imgStr
                });
                headerApiObj
                  .updateVehicle({
                    ...vehicleDetails,
                    imagesLocation: imgStr,
                    axleConfig: "",
                    event: "Update"
                  })
                  .then((response) => {
                    if (response.didError === false || !response.isError) {
                      successStatus = true;
                      setClonedClicked(true);
                      setEditClonedNotif(successStatus);
                      handleStep(0);
                      setVehicleDetails({
                        ...vehicleDetails,
                        imagesLocation: imgStr,
                        vehicleNumber: "",
                        assetNumber: "",
                        vehicleId: null,
                        currentHours: 0,
                        currentMiles: 0
                      });
                    } else {
                      successStatus = false;
                    }
                  });
              }
            });
            setShowSpinner(false);
          } else {
            headerApiObj
              .updateVehicle({
                ...vehicleDetails,
                axleConfig: "",
                event: "Update"
              })
              .then((response) => {
                if (response.didError === false || !response.isError) {
                  successStatus = true;
                  setClonedClicked(true);
                  setEditClonedNotif(true);
                  handleStep(0);
                  setVehicleDetails({
                    ...vehicleDetails,
                    vehicleNumber: "",
                    assetNumber: "",
                    vehicleId: null,
                    currentHours: 0,
                    currentMiles: 0
                  });
                } else {
                  successStatus = false;
                }
                setShowSpinner(false);
              });
          }
        }
      } else {
        if (locationObj.state && (locationObj.state.option === "Clone" || isClonedClicked)) delete vehicleDetails.vehicleId;
        //Vehicle creation
        setTempClone(tempVehicleNumber)
        headerApiObj.createVehicle({ ...vehicleDetails, axleConfig: "" }).then((response) => {
          if ((response.didError !== undefined && !response.didError) || !response.isError) {
            successStatus = true;

            if (!isMasterDataEmpty) {
              headerApiObj.createMasterData(vehicleMasterDetails).then((masterResponse) => {
                if (!masterResponse.didError) {
                  if (vehicleImages.length !== 0) {
                    let timestamp = new Date().getTime();

                    fileUploadFormData.append("vehicleId", response.insertedId);
                    Object.values(vehicleImages).forEach((img, index) => {
                      fileUploadFormData.append("imgsrc", img, index + "_" + timestamp + ".png");
                      return true;
                    });

                    headerApiObj.createVehicleImages(fileUploadFormData).then((response) => {
                      if (!response.didError) {
                        //VehicleImages uploaded
                      }
                    });
                  }

                  setCreateClonedNotif(successStatus);
                  handleStep(0);
                  setVehicleDetails({
                    ...vehicleDetails,
                    vehicleNumber: "",
                    assetNumber: ""
                  });
                  setShowSpinner(false);
                }
              });
            } else {
              if (vehicleImages.length !== 0) {
                let timestamp = new Date().getTime();
                Object.values(vehicleImages).forEach((img, index) => {
                  fileUploadFormData.append("imgsrc", img, index + "_" + timestamp + ".png");
                  return true;
                });
                fileUploadFormData.append("vehicleId", response.insertedId);

                headerApiObj.createVehicleImages(fileUploadFormData).then((imageResponse) => {
                  if (!imageResponse.didError) {
                    // VehicleImages uploaded
                  }

                  setCreateClonedNotif(successStatus);
                  handleStep(0);
                  setVehicleDetails({
                    ...vehicleDetails,
                    vehicleNumber: "",
                    assetNumber: "",
                    currentHours: 0,
                    currentMiles: 0
                  });
                  setShowSpinner(false);
                  setTimeout(() => {
                    setCreateClonedNotif(false);
                  }, 5000);
                });
              }
            }

            if (isMasterDataEmpty && vehicleImages.length === 0) {
              setCreateClonedNotif(successStatus);
              handleStep(0);
              setVehicleDetails({
                ...vehicleDetails,
                vehicleNumber: "",
                assetNumber: "",
                currentHours: 0,
                currentMiles: 0
              });
              setShowSpinner(false);
              setTimeout(() => {
                setCreateClonedNotif(false);
              }, 5000);
            }
          } else {
            if (response.message === "A vehicle with the entered vehicle number already exists in this account") {
              handleStep(0);
              setIdFormErrors({
                ...idFormErrors,
                vehicleNumber: validationTexts.VEHICLEEXISTS.replace("#1#1", vehicleDetails.vehicleNumber)
              });
            }
            setShowSpinner(false);
          }
        });
      }
    }
  };

  //rendering children
  function getStepComponent(currentStep) {
    switch (currentStep) {
      case 0:
        return (
          <Identification
            fieldNames={tireFormCaptions.TIRESVEHICLES.TIREFORM}
            vehicleId={VehicleId}
            masterDataset={masterData}
            userPreferenceData={userPreferences}
            stateVar={vehicleDetails}
            onChangeListener={(field, val) => handleFieldChange(field, val)}
            trackBy={trackingMethod}
            onTrackByChange={(val) => setTrackingMethod(val)}
            formHelperTexts={idFormErrors}
            isTireCloned={isClonedClicked}
            installedTireData={installedTireData}
            isVehicleNumberEdited={isVehicleNumberEdited}
            isCurrentHoursEdited={isCurrentHoursEdited}
            isCurrentMilesEdited={isCurrentMilesEdited}
          />
        );
      case 1:
        return (
          <Description
            fieldNames={tireFormCaptions.TIRESVEHICLES.TIREFORM}
            masterDataSet={masterData}
            stateVar={vehicleDetails}
            onChangeListener={(field, val, extraVal) => handleFieldChange(field, val, extraVal)}
            vehicleTypesByManuf={typesByManuf}
            vehicleModelsByType={modelsBytype}
            isManufNew={isOtherManuSelected}
            newManuf={otherManuf}
            isTypeNew={isOtherTypeSelected}
            newType={otherType}
            isModelNew={isOtherModelSelected}
            newModel={otherModel}
            isSizeNew={isOtherSizeSelected}
            newSize={otherSize}
            onExtraDataChange={(field, val, axelNumber) => handleVehicleMasterDataChange(field, val, axelNumber)}
            imageHelper={imgHelper}
            removeUploadedPhoto={deleteImage}
            formHelperTexts={descFormErrors}
            dbImages={storedImages}
            deleteDbImage={deleteStoredImage}
            isTireCloned={isClonedClicked}
            installedTireData={installedTireData}
            preferenceUnits={preferenceUnits}
            multipleOtherSizesText={multipleOtherSizes}
            otherSizeInput={otherSizeInput}
            isManufacturerEdited={isManufacturerEdited}
            isNewManufacturerNameEdited={isNewManufacturerNameEdited}
            isTypeEdited={isTypeEdited}
            isNewTypeNameEdited={isNewTypeNameEdited}
            isModelEdited={isModelEdited}
            isNewModelNameEdited={isNewModelNameEdited}
            isSizeEdited={isSizeEdited}
            isNewSizeNameEdited={isNewSizeNameEdited}
            isOtherSizeEdited={isOtherSizeEdited}
            isOtherSizeInputEdited={isOtherSizeInputEdited}
          />
        );
      case 2:
        return (
          <OperationSpecifications
            fieldNames={tireFormCaptions.TIRESVEHICLES.TIREFORM}
            masterDataSet={masterData}
            stateVar={vehicleDetails}
            onChangeListener={(field, val) => handleFieldChange(field, val)}
            formHelperTexts={specFormErrors}
            isRemovalTreadEdited={isRemovalTreadEdited}
          />
        );
      default:
        return (<span>Invalid step</span>);
    }
  }


  return (
    <React.Fragment>
      <div className="standalone-new-header-container">
        <div className="create-tire-main-page-container">
          <div className="create-vehicle-upper-half">
            <div className="background-map-image-create-vehicle">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={myBreadcrumbs} />
                  <div className="scrap-tires-title">
                    {myBreadcrumbsName()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showNotification && flashMessageState.state && (
        <FlashMessage status={flashMessageState.state.updateStatus} message={flashMessageState.state.successMessage} description={flashMessageState.state.successMessageDesc} />
      )}
      {showCreateClonedNotif && <FlashMessage status={true} message={vehicleClonedCreateNotif.notifmessage} description={vehicleClonedCreateNotif.notifdesc} />}

      {showEditClonedNotif && <FlashMessage status={true} message={vehicleClonedEditNotif.notifmessage} description={vehicleClonedEditNotif.notifdesc} />}

      {showSpinner ? (
        <Spinner />
      ) : (
        <div className="manage-vehicle-create-container create-vehicle-form">
          <div className="create-vehicle-inner-container">
            <div className="step-indicator-container">
              <div className={VehicleId > 0 ? styling.rootStepper2 : styling.rootStepper}>
                <Stepper alternativeLabel activeStep={currentStep} connector={<ColorlibConnector />}>
                  {steps.map((label, index) => (
                    <Step key={label} onClick={() => handleStep(index)}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>

              <div className="helper-message">
                {tireFormCaptions.MANDATORYFIELDS}
                <span className="required-mark">*</span>
              </div>

              <div className="step-forms-container">{getStepComponent(currentStep)}</div>

              <hr className="hr-divider-action" />

              <div className="action-buttons">
                <Button className={styling.cancel} variant="contained" color="secondary" onClick={handleCancel}>
                  {tireFormCaptions.CANCEL}
                </Button>
                {currentStep > 0 && (
                  <Button className={styling.previous} variant="contained" color="secondary" onClick={() => handlePreviousStep()}>
                    {tireFormCaptions.PREVIOUS}
                  </Button>
                )}
                {currentStep === steps.length - 1 ? (
                  <React.Fragment>
                    <Button className={styling.previous} variant="contained" color="primary" onClick={() => handleCloneClick()}>
                      {tireFormCaptions.SAVEANDCLONE}
                    </Button>
                    <Button className={styling.next} variant="contained" color="primary" onClick={() => handleNextStep()}>
                      {tireFormCaptions.SUBMIT}
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button className={styling.next} variant="contained" color="primary" onClick={() => handleNextStep()}>
                    {tireFormCaptions.NEXT}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CreateVehicle;
