import Globalization from "../../../globalization/index";

class TiresSentToRetreadDataSetter {
  constructor() {
    this.data = {};
    this.tiresSentToRetreadData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }

  formatData(registeredDate) {
    let date, formattedDate;
    if (registeredDate) {
      let dateString = registeredDate.split("/");
      date = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
      formattedDate = date;
    } else {
      date = null;
      formattedDate = date;
    }

    return formattedDate;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      if (headCell.value !== "BrandNumber") {
        updatedHeadCells.push(headCell);
      }
    });
    return updatedHeadCells;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "BrandNumber" || headCell.value === "MilesAtRemoval") {
        selectedColShow[headCell.value] = false;
      } else {
        selectedColShow[headCell.value] = true;
      }
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.BrandNumber.toLowerCase().includes(searchText) ||
        item.SerialNo.toLowerCase().includes(searchText) ||
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.Compound.toLowerCase().includes(searchText) ||
        item.RatingName.includes(searchText) ||
        item.TreadDepth.toLowerCase().includes(searchText) ||
        item.HoursAtRemoval.toString().includes(searchText) ||
        item.VehId.toLowerCase().includes(searchText) ||
        item.NoOfRetread.toString().includes(searchText) ||
        item.DateSent.toLowerCase().includes(searchText) ||
        item.RemovalReason.toLowerCase().includes(searchText) ||
        item.Comments.toLowerCase().includes(searchText) ||
        item.MilesAtRemoval.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey])) return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  avgValues = (tiresSentToRetreadData) => {
    let TotalRetreads = 0;

    tiresSentToRetreadData.forEach((item, index) => {
      TotalRetreads += item.NoOfRetread;
    });

    const avgValues = {
      BrandNumber: "",
      SerialNo: "",
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      Compound: "",
      RatingName: "",
      TreadDepth: "",
      PercentageWorn: "",
      HoursAtRemoval: "",
      MilesAtRemoval: "",
      VehId: "",
      NoOfRetread: TotalRetreads,
      DateSent: "",
      RemovalReason: "",
      Comments: ""
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;

    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.tiresSentToRetreadData.push({
        Key: index,
        BrandNumber: item.brandNumber ? item.brandNumber : "",
        SerialNo: item.serialNo ? item.serialNo : "",
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        Compound: item.compound ? item.compound : "",
        RatingName: item.ratingName ? item.ratingName : "",
        TreadDepth: item.treadDepth ? item.treadDepth : "",
        PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
        HoursAtRemoval: item.hoursAtRemoval ? item.hoursAtRemoval : 0,
        MilesAtRemoval: item.milesAtRemoval ? item.milesAtRemoval : 0,
        VehId: item.vehId ? item.vehId : "",
        NoOfRetread: item.noOfRetread ? item.noOfRetread : 0,
        DateSent: item.dateSent ? item.dateSent : "",
        RemovalReason: item.removalReason ? item.removalReason : "",
        Comments: item.comments ? item.comments : ""
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.tiresSentToRetreadData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.tiresSentToRetreadData),
      selectedColShow: selectedColShow
    };
  }
}

export default TiresSentToRetreadDataSetter;
