import TotalTires from "./total-tires-icon.svg";
import ScrapTires from "./scrap-tires-icon.svg";
import RemovedTires from "./removed-tires-icon.svg";
import Vehicles from "./vehicle-icon.svg";

export default function IndexSVG() {
    return {
        totalTiresCount: TotalTires,
        scrapTireCount: ScrapTires,
        tiresInServiceCount: TotalTires,
        vehicleCount: Vehicles,
        tiresInInventoryCount: RemovedTires,
    };
}
