import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import "./dashboard.css";
import Breadcrumbs from "../../../../common-components/breadcrumbs/breadcrumbs";
import { 
  Card,
  CardContent, 
  InputLabel } from "@material-ui/core";
import GraphTable from "../graph-table-container/graph-table";
import ReportDashboardService from "../../services/report-dashboard-apis";
import IndexSVG from "../../../../assets/index";
import Globalization from "../../../../globalization/index";
import Spinner from "../../../../common-components/spinner/spinner";
import { StateContext } from "../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../services/AccountInfoHandler";
import TickAllOk from "../../../../assets/tick-all-ok.svg";
import ImageAllOk from "../../../../assets/all-ok-image.svg";
import Right_Arrow from "../../../../assets/Right_Arrow.svg";

const Dashboard = (_props) => {
  const [globalAccountState] = useContext(StateContext);
  const history = useHistory();
  const [dashboardNav, setDashboardNav] = useState([]);
  const [floatingCardData, setFloatingCardData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedNav, setSelectedNav] = useState({});
  const [selectedNavValue, setSelectedNavValue] = useState("");
  const globalizationMapping = new Globalization();
  const reportsDashboardHeader = globalizationMapping.reportDashboard;
  const globalTexts = globalizationMapping.HOMEDASHBOARD;

  useEffect(() => {
    setShowSpinner(true);

    const svg = new IndexSVG();
    const reportDashboardService = new ReportDashboardService();

    // UPDATING THE GLOBAL ACCOUNT STATES
    const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    const accountIds = accountInfoObject.accountIds;
    const locationIds = accountInfoObject.locationIds;

    const requestObject = {
      accountIds: accountIds.toString(),
      locationIds: locationIds.toString(),
    };

    let isMounted = true;

    reportDashboardService
      .getReportDashboardData(requestObject)
      .then((data) => { 
        const model = data.model;
        const floatingData = [
          {
            name: globalizationMapping["totalTiresCount"],
            imgPath: svg["totalTiresCount"],
            count: model.totalTiresCount,
          },
        ];
        const dashboardNavLocal = [];
        Object.keys(model).forEach(function (key) {
          if (
            key !== "rimCount" &&
            key !== "totalTiresCount" &&
            key !== "removedTireCount" &&
            key !== "tiresBelowInflationCount" &&
            key !== "tiresWithLowTreadDepthCount" &&
            key !== "tireConditionCount"
          ) {
            floatingData.push({
              name: globalizationMapping[key],
              imgPath: svg[key],
              count: model[key],
            });
          } else {
            if (
              key === "tiresBelowInflationCount" ||
              key === "tiresWithLowTreadDepthCount" ||
              key === "tireConditionCount"
            ) {
              dashboardNavLocal.push({
                name: globalizationMapping[key],
                count: model[key],
                value: key,
              });
            }
          }
        });

        if (isMounted) {
          setFloatingCardData(floatingData);
          setDashboardNav(dashboardNavLocal);
          setSelectedNav(dashboardNavLocal[0]);
          setSelectedNavValue(dashboardNavLocal[0].value);
          setShowSpinner(false);

        }
      });
      return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);
  const breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME,
    },
    {
      path: "",
      name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.REPORTSDASHBOARD,
    },
  ];

  const onClickDashboardNav = (selectedNavPath) => {
    setSelectedNav(selectedNavPath);
    setSelectedNavValue(selectedNavPath.value);
  };

  const dashboardVal = dashboardNav.length > 0 && (dashboardNav[0].count !== 0 || 
    dashboardNav[1].count !== 0 || dashboardNav[2].count !== 0) ? "report-dashboard-container" : "report-dashboard-container-allok";


  const handleFloatingCardClick = (item) => {
    switch (item) {
      case 0:
        history.push({ pathname: "/manage-tire", state: { item: "All" } });
        break;
      case 1:
        history.push({ pathname: "/manage-tire", state: { dispositionId: 1 } });
        break;
      case 2:
        history.push({ pathname: "/manage-tire", state: { dispositionId: 7 } });
        break;
      case 3:
        history.push({ pathname: "/manage-tire", state: { dispositionId: 8 } });
        break;
      case 4:
        history.push("/manage-vehicle");
        break;
      case 5:
        history.push({ pathname: "/manage-tire", state: { dispositionId: 4 } });
        break;
      default:
        break;
    }
  };
  
  return (
    <div className={`container ${dashboardVal}`}>
      {showSpinner ? <Spinner />
        : ( 
            <div className="dashboard-main-container">
              <div className="dashboard-upper-deck">
                <div className="background-img-container">
                  <div className="background-map-image">
                    <div className="dashboard-header">
                      <div>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <div className="dashboard-title">
                          {reportsDashboardHeader}
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>
                    {/* FLOATING CARDS WITH DATA */}
                    <div className="card-views-container">
                      {floatingCardData.map((item, index) => {
                        return (
                            <Card className="floating-card card-custom-style" key={item.name} onClick={() => handleFloatingCardClick(index)}>
                              <CardContent>
                                <div>
                                  <div className="inner-card-container">
                                    <div className="floating-card-image">
                                      <img src={item.imgPath} alt="dashboard-card-icon-1"/>
                                    </div>
                                    <div>
                                      <div className="floating-card-header">{item.name}</div>
                                      <div className="floating-card-value">{item.count}</div>
                                    </div>
                                   
                                  </div> 
                                  <div className="view-link-floating-card">
                                      <div className="floating-card-value">
                                        <InputLabel className="card-arrow">
                                          {globalTexts.VIEW} &nbsp;
                                          <img src={Right_Arrow} alt="Click to navigate" />
                                        </InputLabel>
                                      </div>
                                    </div>
                                </div>
                              </CardContent>
                            </Card>
                        );
                      })}
                    </div>

                    {/* DASHBOARD NAV */}
                    {dashboardNav.length > 0 &&
                      (dashboardNav[0].count !== 0 ||
                        dashboardNav[1].count !== 0 ||
                        dashboardNav[2].count !== 0) && (
                        <React.Fragment>
                          <div className="dashboard-nav-container">
                            {dashboardNav.map((item) => {
                              return (
                                <Card
                                  key={item.name}
                                  className={
                                    selectedNavValue === item.value
                                      ? "active-nav-card-custom-style nav-card-custom-style"
                                      : "nav-card-custom-style"
                                  }
                                  onClick={() => onClickDashboardNav(item)}
                                >
                                  <CardContent>
                                    <div>
                                      <div className="nav-inner-card-container">
                                        <div
                                          className={ 
                                            selectedNavValue === item.value
                                              ? "active-dashboard-nav-text-style dashboard-nav-title"
                                              : "dashboard-nav-text-style dashboard-nav-title"
                                          }
                                        >
                                          {item.name}
                                        </div>
                                        <span
                                          className={
                                            selectedNavValue === item.value
                                              ? "active-dashboard-nav-text-style dashboard-nav-delimiter"
                                              : "dashboard-nav-text-style dashboard-nav-delimiter"
                                          }
                                        >
                                          :{" "}
                                        </span>
                                        <div
                                          className={
                                            selectedNavValue === item.value
                                              ? "active-dashboard-nav-text-style dashboard-nav-count"
                                              : "dashboard-nav-text-style dashboard-nav-count"
                                          }
                                        >
                                          {item.count}
                                        </div>
                                      </div>
                                    </div>
                                  </CardContent>
                                </Card>
                              );
                            })}
                          </div>

                          <div>
                            <GraphTable selectedNav={selectedNav} />
                          </div>
                        </React.Fragment>
                      )}
                    {dashboardNav.length > 0 &&
                      dashboardNav[0].count === 0 &&
                      dashboardNav[1].count === 0 &&
                      dashboardNav[2].count === 0 && (
                        <div className="all-ok-container">
                          <div className="image-all-ok">
                            <img src={ImageAllOk} alt="tick-all-ok" />
                          </div>
                          <div className="text-container">
                            <div className="tick-all-ok">
                              <img src={TickAllOk} alt="tick-all-ok" />
                            </div>
                            <div className="all-ok-title-text">
                              You're all set
                            </div>
                            <div className="all-ok-sub-text">
                              All of your data are up to date
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
        )}
    </div>
  );
};

export default Dashboard;
