import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import {
  FormHelperText,
  Grid,
  TextField,
  Select,
  makeStyles,
  Button,
  InputAdornment,
  Input,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Globalization from "../../../globalization/index";
import HeaderApi from "../../../common-components/header/services/header-apis";
import { StateContext } from "../../../services/StateProvider";
import { useHistory, useLocation } from "react-router-dom";
import Spinner from "../../../common-components/spinner/spinner";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./create-location.css";
import CancelConfirmationPopup from "../../cancel-confirmatipn-popup/cancel-popup";
import FlashMessage from "../../../common-components/pop-up-notification/notification-snackbar";
import ExpandMoreStyledIcon from "../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import CommonUtils from "../../../services/utils";

const useStyles = makeStyles({
  save: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  root: {
    "& input.MuiAutocomplete-inputFocused": {
      color: "#000000 !important",
      Height: "45px",
    },
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5,
  },
  FormHelperText: {
    marginTop: 25,
  },
});

const CreateLocation = () => {
  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
  const [globalAccountState, globalAccountDispatch] = useContext(StateContext);
  const styles = useStyles();
  const history = useHistory();
  const locationObj = useLocation();

  const [locationDetails, setLocationDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    applicationTypeId: 0,
    averageLoadingReqId: 0,
    city: "",
    contactPerson: "",
    country: 0,
    currency: 0,
    cuttingId: 0,
    defaultRemovalTreadDepth: 0,
    emailAddress: "",
    fax: "",
    inspectionOrderId: 0,
    locationId: 0,
    locationName: "",
    marketSegmentId: 0,
    material: 0,
    noOfTreadDepthReadings: 0,
    noTires: 0,
    noVehicles: 0,
    parentAccountId: 0,
    phoneNumber: "",
    severityComments: "",
    siteSeverityIndex: 0,
    speedId: 0,
    state: "",
    treadDepthMethodId: 0,
    zipCode: "",
  });

  //API Calls
  const headerApiObj = new HeaderApi();
  const commonUtils = new CommonUtils();
  const [showSpinner, setShowSpinner] = useState(true);
  const [isParentNew, setParentNew] = useState(false);
  const [countryList, setCountryList] = useState([{}]);
  const [parentAccountList, setParentAccountList] = useState([{}]);
  const [masterData, setMasterData] = useState({});
  const [currentCountryIndex, setCurrentCountryIndex] = useState(-1);
  const [appTypesByMarketSegment, setAppTypesByMarketSegment] = useState([]);
  const [materialByMarketSegment, setMaterialByMarketSegment] = useState([]);
  const [errors, setErrors] = useState({});
  const [showNewNotification, setShowNewNotification] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [historyState, setHistoryState] = useState();
  const [currencyList, setCurrencyList] = useState([]);
  const [parentName, setParentName] = useState("");
  const [sameAs, setSameAs] = useState(false);
  const [currentParentInfo, setCurrentParentInfo] = useState({});
  const [createLocation, setCreateLocation] = useState(0);
  const [isLocationNameEdited, setILocationNameEdited] = useState(false);
  const [isAddressLine1Edited, setIsAddressLine1Edited] = useState(false);
  const [isCountryEdited, setIsCountryEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isZipCodeEdited, setIsZipCodeEdited] = useState(false);
  const [isPhoneNumberEdited, setIsPhoneNumberEdited] = useState(false);
  const [isEmailAddressEdited, setIsEmailAddressEdited] = useState(false);
  const [isContactPersonEdited, setIsContactPersonEdited] = useState(false);
  const [isNoOfTDEdited, setIsNoOfTDEdited] = useState(false);
  const [isTreadDepthMethodIdEdited, setIsTreadDepthMethodIdEdited] = useState(false);
  const [isInspectionOrderIdEdited, setIsInspectionOrderIdEdited] = useState(false);
  const [isMarketSegmentIdEdited, setIsMarketSegmentIdEdited] = useState(false);
  const [isApplicationTypeIdEdited, setIsApplicationTypeIdEdited] = useState(false);
  const [isMaterialEdited, setIsMaterialEdited] = useState(false);
  const [isSiteSeverityIndexEdited, setIsSiteSeverityIndexEdited] = useState(false);
  const [isParentAccountIdEdited, setIsParentAccountIdEdited] = useState(false);

  useEffect(() => {
    setShowSpinner(true);

    if (
      locationObj.state &&
      locationObj.state.previousPath === "/createparentaccount"
    ) {
      setParentNew(true);
      if (locationObj.state.showNewAccNotification) {
        setShowNewNotification(true);
      }
      setHistoryState({
        fromPath: locationObj.pathname,
        accountObj: locationObj.state.accountObj,
      });
      setTimeout(() => {
        setShowNewNotification(false);
      }, 5000);
    } else {
      setParentNew(false);
    }
    headerApiObj.getParentAccountList().then((data) => {
      setParentAccountList(data.model);
    });

    headerApiObj.getMasterDataWeb().then((data) => {
      if (data.model !== undefined) {
        setMasterData(data.model);
      }
    });

    headerApiObj.getCountryList().then((data) => {
      setCountryList(data.model);
      filterCurrency(data.model);
    });

    setTimeout(() => {
      setShowSpinner(false);
    }, 3500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, locationObj]);


  function filterCurrency(data) {
    const newArray = [];
    const uniqueObject = {};
    for (const i in data) {
      const objTitle = data[i]["currency"];
      uniqueObject[objTitle] = data[i];
    }
    for (const i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    setCurrencyList(newArray);
  }

  const handleSameAs = (e) => {
    setSameAs(e.target.checked);
    if (
      e.target.checked &&
      locationObj.state &&
      locationObj.state.previousPath === "/createparentaccount"
    ) {
      let accountInfoObj = historyState?.accountObj;
      setLocationDetails({
        ...locationDetails,
        addressLine1: accountInfoObj.accountAddressLine1,
        addressLine2: accountInfoObj.accountAddressLine2,
        phoneNumber: accountInfoObj.phoneNumber,
        city: accountInfoObj.city,
        country: accountInfoObj.country,
        emailAddress: accountInfoObj.emailAddress,
        state: accountInfoObj.state,
        zipCode: accountInfoObj.zipCode,
      });
    } else if (
      !(
        locationObj.state &&
        locationObj.state.previousPath === "/createparentaccount"
      ) &&
      e.target.checked
    ) {
      setLocationDetails({
        ...locationDetails,
        addressLine1: currentParentInfo.accountAddressLine1,
        addressLine2: currentParentInfo.accountAddressLine2,
        phoneNumber: currentParentInfo.phoneNumber,
        city: currentParentInfo.city,
        country: currentParentInfo.country,
        emailAddress: currentParentInfo.emailAddress,
        state: currentParentInfo.state,
        zipCode: currentParentInfo.zipCode,
      });
    } else {
      setLocationDetails({
        ...locationDetails,
        addressLine1: "",
        addressLine2: "",
        phoneNumber: "",
        city: "",
        country: "",
        emailAddress: "",
        state: "",
        zipCode: "",
      });
    }
  };

  const changeLocationDetails = (key, value) => {
    const date = new Date();
    switch (key) {
      case "parentAccount":
        const parentName = parentAccountList.find(
          (acc) => acc.parentAccountId === parseInt(value, 10)
        );
        setParentName(parentName.accountName);
        headerApiObj
          .getParentAccountDetails(parentName.parentAccountId)
          .then((data) => {
            setCurrentParentInfo(data.model);
            if (sameAs) {
              setLocationDetails({
                ...locationDetails,
                parentAccountId: value,
                updatedDate: date,
                addressLine1: data.model.accountAddressLine1,
                addressLine2: data.model.accountAddressLine2,
                phoneNumber: data.model.phoneNumber,
                city: data.model.city,
                country: data.model.country,
                emailAddress: data.model.emailAddress,
                state: data.model.state,
                zipCode: data.model.zipCode,
              });
            } else {
              setLocationDetails({
                ...locationDetails,
                parentAccountId: value,
                updatedDate: date,
              });
            }
          });
        setIsParentAccountIdEdited(true);
        break;
      case "locationName":
        setLocationDetails({
          ...locationDetails,
          locationName: value,
          updatedDate: date,
        });
        setILocationNameEdited(true);
        break;
      case "addLine1":
        setLocationDetails({
          ...locationDetails,
          addressLine1: value,
          updatedDate: date,
        });
        setIsAddressLine1Edited(true);
        break;
      case "addLine2":
        setLocationDetails({
          ...locationDetails,
          addressLine2: value,
          updatedDate: date,
        });
        break;
      case "country":
        {
          const currentCountryIndexLocal = countryList.findIndex(
            (country) => parseInt(country.countryId, 10) === parseInt(value, 10)
          );
          setCurrentCountryIndex(currentCountryIndexLocal);
          const firstStateOption = countryList[currentCountryIndexLocal].states.length > 0 ? countryList[currentCountryIndexLocal].states[0].stateName : '';

          if (countryList[currentCountryIndexLocal].states.length > 0) {
            setLocationDetails({
              ...locationDetails,
              country: value,
              currency: countryList[currentCountryIndexLocal].currency,
              state: firstStateOption,
              updatedDate: date,
            });
          } else {
            setLocationDetails({
              ...locationDetails,
              country: value,
              currency: countryList[currentCountryIndexLocal].currency,
              updatedDate: date,
            });
          }





        }
        setIsCountryEdited(true);
        break;
      case "currency":
        setLocationDetails({
          ...locationDetails,
          currency: value,
          updatedDate: date,
        });
        break;
      case "state":
        setLocationDetails({
          ...locationDetails,
          state: value,
          updatedDate: date,
        });
        break;
      case "city":
        setLocationDetails({
          ...locationDetails,
          city: value,
          updatedDate: date,
        });
        setIsCityEdited(true);
        break;
      case "zipCode":
        setLocationDetails({
          ...locationDetails,
          zipCode: value,
          updatedDate: date,
        });
        setIsZipCodeEdited(true);
        break;
      case "phone":
        setLocationDetails({
          ...locationDetails,
          phoneNumber: value,
          updatedDate: date,
        });
        setIsPhoneNumberEdited(true);
        break;
      case "fax":
        setLocationDetails({
          ...locationDetails,
          fax: value,
          updatedDate: date,
        });
        break;
      case "email":
        setLocationDetails({
          ...locationDetails,
          emailAddress: value,
          updatedDate: date,
        });
        setIsEmailAddressEdited(true);
        break;
      case "contactPerson":
        setLocationDetails({
          ...locationDetails,
          contactPerson: value,
          updatedDate: date,
        });
        setIsContactPersonEdited(true);
        break;
      case "marketsegment":
        const appTypes = [];
        const materialBySegment = [];
        // eslint-disable-next-line array-callback-return
        masterData.applicationTypes.map((appType) => {
          if (parseInt(appType.marketSegmentId, 10) === parseInt(value, 10)) {
            appTypes.push(appType);
          }
        });
        masterData.materials.forEach((material) => {
          if (parseInt(material.marketSegmentId, 10) === parseInt(value, 10)) {
            materialBySegment.push(material);
          }
        });
        setAppTypesByMarketSegment(appTypes);
        setMaterialByMarketSegment(materialBySegment);
        setLocationDetails({
          ...locationDetails,
          marketSegmentId: value,
          updatedDate: date,
        });
        setIsMarketSegmentIdEdited(true);
        break;
      case "applicationtype":
        setLocationDetails({
          ...locationDetails,
          applicationTypeId: value,
          updatedDate: date,
        });
        setIsApplicationTypeIdEdited(true);
        break;
      case "material":
        setLocationDetails({
          ...locationDetails,
          material: value,
          updatedDate: date,
        });
        setIsMaterialEdited(true);
        break;
      case "siteseverityindex":
        setLocationDetails({
          ...locationDetails,
          siteSeverityIndex: value,
          updatedDate: date,
        });
        setIsSiteSeverityIndexEdited(true);
        break;
      case "severitycomments":
        setLocationDetails({
          ...locationDetails,
          severityComments: value,
          updatedDate: date,
        });
        break;
      case "noOftreaddepthreadings":
        if (value === 2 || value === 3) {
          setLocationDetails({
            ...locationDetails,
            noOfTreadDepthReadings: value,
            updatedDate: date,
          });
        } else {
          setLocationDetails({
            ...locationDetails,
            noOfTreadDepthReadings: value,
            treadDepthMethodId: 0,
            updatedDate: date,
          });
        }
        setIsNoOfTDEdited(true);
        break;
      case "treadDepthMethod":
        setLocationDetails({
          ...locationDetails,
          treadDepthMethodId: value,
          updatedDate: date,
        });
        setIsTreadDepthMethodIdEdited(true);
        break;
      case "defaultremovaltreaddepth":
        setLocationDetails({
          ...locationDetails,
          defaultRemovalTreadDepth: value,
          updatedDate: date,
        });
        break;
      case "inspectionorderid":
        setLocationDetails({
          ...locationDetails,
          inspectionOrderId: value,
          updatedDate: date,
        });
        setIsInspectionOrderIdEdited(true);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let errorsLocal = {};
    let isValid = true;
    Object.keys(locationDetails).forEach((key) => {
      if (
        key !== "addressLine2" &&
        key !== "state" &&
        key !== "currency" &&
        key !== "fax" &&
        key !== "severityComments"
      ) {
        if (
          locationDetails[key] === 0 &&
          key === "parentAccountId" &&
          !isParentNew
        ) {
          errorsLocal[key] = "This field is required. Select Parent Account";
          isValid = false;
        } else if (
          (locationDetails[key] === "" && key === "locationName") ||
          (locationDetails[key] === "" && key === "addressLine1") ||
          (locationDetails[key] === 0 && key === "country") ||
          (locationDetails[key] === "" && key === "city") ||
          (locationDetails[key] === "" && key === "contactPerson") ||
          (locationDetails[key] === 0 && key === "noOfTreadDepthReadings") ||
          (locationDetails[key] === 0 && key === "inspectionOrderId") ||
          (locationDetails[key] === 0 && key === "marketSegmentId") ||
          (locationDetails[key] === 0 && key === "applicationTypeId") ||
          (locationDetails[key] === 0 && key === "material") ||
          (locationDetails[key] === "" && key === "zipCode") ||
          (locationDetails[key] === 0 && key === "siteSeverityIndex")
        ) {
          errorsLocal[key] = "This field is required";
          isValid = false;
        } else if (locationDetails[key] === 0 && key === "treadDepthMethodId") {
          if (locationDetails.noOfTreadDepthReadings === 2 || locationDetails.noOfTreadDepthReadings === 3) {
            errorsLocal[key] = "This field is required";
            isValid = false;
          }
        } else if (
          key === "emailAddress" &&
          !locationDetails[key].match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
        ) {
          //^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$
          errorsLocal[key] =
            "Email Address is invalid. Must have @ and .com or the like";
          isValid = false;
        }
         else if (
          key === "phoneNumber" &&
          !locationDetails[key].match(/\d{3}[ -]?\d{3}[ -]?\d{4}/)
        ) {
          errorsLocal[key] =
            "Phone number is invalid. Must be at least 10 digits";
          isValid = false;
        }
        // else {
        //   if (key === "city" && !locationDetails[key].match(/^[a-zA-Z',.\s-]{1,25}$/)) {
        //     errorsLocal[key] = "Alphabets only";
        //     isValid = false;
        //   }
        // }
      }
      return true;
    });
    setErrors(errorsLocal);
    return isValid;
  };


  localStorage.setItem("createLocation", createLocation)
  const onClickSaveStatus = (_event, _value) => {
    setCreateLocation(createLocation + 1)
    if (validateForm()) {
      setShowSpinner(true);
      let date = new Date();
      let successStatus = false;
      date = date.toISOString();
      setLocationDetails({ ...locationDetails, updatedDate: date });

      if (isParentNew) {
        headerApiObj
          .createParentAccount({
            accountViewModel: { ...locationObj.state.accountObj },
            locationViewModel: { ...locationDetails },
          })
          .then((response) => {
            if (response.didError === false) {
              const initialAccountState = globalAccountState.accountInfo;
              initialAccountState.isUpdated = true;
              globalAccountDispatch({
                accountInfo: initialAccountState,
                type: "UPDATE",
              });
              successStatus = true;
            } else {
              successStatus = false;
            }
            let tempdesc = notificationTexts.ACCNLOCATIONCREATIONDESC;
            tempdesc = tempdesc.replace(
              "#ACCNAME",
              locationObj.state.accountObj.accountName
            );
            tempdesc = tempdesc.replace(
              "#LOCATIONNAME",
              locationDetails.locationName
            );
            setShowSpinner(false);
            history.push({
              pathname: "/home",
              state: {
                updatedSection: "accountnlocation",
                updateStatus: successStatus,
                successMessage: notificationTexts.ACCNLOCATIONCREATED,
                successMessageDesc: tempdesc,
                failureMessage: notificationTexts.ACCCREATIONFAILED,
              },
            });
          });
      } else {
        headerApiObj.createLocation(locationDetails).then((response) => {
          if (response.didError === false) {
            successStatus = true;
          } else {
            successStatus = false;
            if (response.message === "Location already exists") {
              setErrors({
                ...errors,
                locationName: "Location name already exists",
              });
              window.scrollTo(0, 0);
            }
          }
          let tempdescloc = notificationTexts.NEWLOCATIONCREATEDDESC;
          tempdescloc = tempdescloc.replace(
            "#LOCATIONNAME",
            locationDetails.locationName
          );
          tempdescloc = tempdescloc.replace("#ACCNAME", parentName);
          setShowSpinner(false);
          if (successStatus) {
            history.push({
              pathname: "/home",
              state: {
                updatedSection: "createlocation",
                updateStatus: successStatus,
                successMessage: notificationTexts.NEWLOCATIONCREATED,
                successMessageDesc: tempdescloc,
                failureMessage: notificationTexts.LOCATIONCREATIONFAILED,
              },
            });
          }
        });
      }
    }
    setILocationNameEdited(false);
    setIsAddressLine1Edited(false);
    setIsCountryEdited(false);
    setIsCityEdited(false);
    setIsZipCodeEdited(false);
    setIsPhoneNumberEdited(false);
    setIsEmailAddressEdited(false);
    setIsContactPersonEdited(false);
    setIsNoOfTDEdited(false);
    setIsTreadDepthMethodIdEdited(false);
    setIsInspectionOrderIdEdited(false);
    setIsMarketSegmentIdEdited(false);
    setIsApplicationTypeIdEdited(false);
    setIsMaterialEdited(false);
    setIsSiteSeverityIndexEdited(false);
  };

  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME,
    },
    {
      path: "",
      name: globalHeaderTexts.CREATELOCATION,
    },
  ];

  const countryStateSelector = () => {
    return (countryList[currentCountryIndex].states.length > 0 ? (
      <Select
        native
        IconComponent={ExpandMoreStyledIcon}
        className="edit-detail-input-box"
        value={locationDetails.state}
        variant="outlined"
        type="number"
        onChange={(e) => {
          changeLocationDetails("state", e.target.value);
        }}
        inputProps={{
          name: "State",
          id: "state-select-drop-down",
        }}
      >
        <option value={0} disabled>
          {globalizationMapping.CHANGEMODEL.SELECTSTATE}
        </option>
        {countryList[currentCountryIndex].states.map(
          
          (stateDetail) => (
            
            <option
              key={stateDetail.stateId}
              value={stateDetail.stateName}
            >
              {stateDetail.stateName}
            </option>
          )
        )}
      </Select>
    ) : (
      <div>
        <TextField
          className="edit-detail-input-box"
          variant="outlined"
          value={locationDetails.state}
          placeholder={
            globalizationMapping.PLACEHOLDER.StateProvince
          }
          onChange={(e) => {
            changeLocationDetails("state", commonUtils.removeLeadingSpaces(e.target.value));
          }}
          inputProps={{ maxLength: 50 }}
        />
      </div>
    ))
  }


  return (
    <div className="standalone-header-container">
      <div className="create-location-main-page-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image background-map-image-create-location">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {globalHeaderTexts.CREATELOCATION}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showNewNotification && (
        <FlashMessage
          status={showNewNotification}
          message={notificationTexts.ACCOUNTEDITSUCCESSMSG}
          description={notificationTexts.ACCOUNTSAVEDCREATELOCATIONDESC.replace(
            "#ACCNAME",
            locationObj.state.accountObj.accountName
          )}
        />
      )}

      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="create-location-list-container">
          <div className="create-location-container">
            <div className="create-location-inner-container">
              <div className="create-sub-header">
                <span>{globalHeaderTexts.PARENTACCOUNTDETAILS}</span>
              </div>
              <div className="edit-details-message bottom-margin">
                <span>
                  {globalizationMapping.FORMS.ACCOUNTS.PARENTFORM.ACCFORMDESC}
                </span>
              </div>
              <div className="create-location-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.PARENTACC}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    {!isParentNew ? (
                      <>
                        <Select
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className="edit-detail-input-box"
                          value={locationDetails.parentAccountId}
                          variant="outlined"
                          onChange={(e) => {
                            changeLocationDetails(
                              "parentAccount",
                              e.target.value
                            );
                          }}
                          type="number"
                        >
                          <option key={0} value={0} disabled>
                            {globalizationMapping.ADMIN.SelectParent}
                          </option>
                          {parentAccountList.map((parentAccount, key) => (
                            <option
                              key={parentAccount.parentAccountId}
                              value={parentAccount.parentAccountId}
                            >
                              {parentAccount.accountName}
                            </option>
                          ))}
                        </Select>
                        {(locationDetails.parentAccountId === "" && isParentAccountIdEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                        {(isParentAccountIdEdited ? "" : <FormHelperText>{errors.parentAccountId}</FormHelperText>)}
                      </>
                    ) : (
                      <Input
                        id="parent-name-create-location"
                        className="edit-detail-input-box disabled-input-location-form disable-input-location parent-name-create-location"
                        variant="outlined"
                        value={locationObj.state.accountObj.accountName}
                        disabled
                        startAdornment={
                          <InputAdornment
                            position="end"
                            style={{
                              color: "#d2d4d6",
                              cursor: "pointer",
                              position: "absolute",
                              pointerEvents: "none",
                              right: "7px",
                            }}
                          >
                            <ExpandMoreIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              <hr className="hr-divider" />
              <div className="create-sub-header">
                <span>{globalHeaderTexts.LOCATIONDETAILS}</span>
              </div>
              <div className="create-location-details-grid location-mb">
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameAs}
                        onChange={handleSameAs}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    disabled={!(isParentNew || locationDetails.parentAccountId)}
                    label={globalizationMapping.HEADER.SameasParentLocation}
                  />
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.LOCATIONNAME}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="location"
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={
                        globalizationMapping.PLACEHOLDER.LocationName
                      }
                      value={locationDetails.locationName}
                      onChange={(e) => {
                        changeLocationDetails("locationName", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    {(locationDetails.locationName === "" && isLocationNameEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isLocationNameEdited ? "" : <FormHelperText>{errors.locationName}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.ADDRESSLINE1}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="add1"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={
                        globalizationMapping.PLACEHOLDER.ADDRESSLINE1
                      }
                      value={locationDetails.addressLine1}
                      onChange={(e) => {
                        changeLocationDetails("addLine1", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                    {(locationDetails.addressLine1 === "" && isAddressLine1Edited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isAddressLine1Edited ? "" : <FormHelperText>{errors.addressLine1}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.ADDRESSLINE2}
                    </p>
                    <TextField
                      id="add2"
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={
                        globalizationMapping.PLACEHOLDER.ADDRESSLINE2
                      }
                      value={locationDetails.addressLine2}
                      onChange={(e) => {
                        changeLocationDetails("addLine2", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.COUNTRY}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={locationDetails.country}
                      variant="outlined"
                      onChange={(e) => {
                        changeLocationDetails("country", e.target.value);
                      }}
                      // type="number"
                      inputProps={{
                        name: "Country",
                        id: "country-select-drop-down",
                      }}
                    >
                      <option value={0} disabled>
                        {globalizationMapping.CHANGEMODEL.SELECTCOUNTRY}
                      </option>
                      {countryList.map((country, key) => (
                        <option key={country.countryId} value={country.countryId}>
                          {country.countryName}
                        </option>
                      ))}
                    </Select>
                    {(locationDetails.country === "" && isCountryEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isCountryEdited ? "" : <FormHelperText>{errors.country}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">{globalHeaderTexts.CURRENCY}</p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      id="currency"
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.currency}
                      type="number"
                      onChange={(e) => {
                        changeLocationDetails("currency", e.target.value);
                      }}
                    >
                      <option value={0} disabled>
                        {globalizationMapping.CHANGEMODEL.SELECTCURRENCY}
                      </option>
                      {currencyList.map((countryDetail, key) => (
                        <option
                          key={countryDetail.countryId}
                          value={countryDetail.SELECTCURRENCY}
                        >
                          {countryDetail.currency}
                        </option>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">{globalHeaderTexts.STATE}</p>
                    {currentCountryIndex !== -1 ? (
                      countryStateSelector()
                    ) : (
                      <div>
                        <TextField
                          className="edit-detail-input-box"
                          variant="outlined"
                          placeholder={
                            globalizationMapping.PLACEHOLDER.StateProvince
                          }
                          value={locationDetails.state}
                          onChange={(e) => {
                            changeLocationDetails("state", commonUtils.removeLeadingSpaces(e.target.value));
                          }}
                          type=""
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.CITY}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="city"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={globalizationMapping.PLACEHOLDER.City}
                      value={locationDetails.city}
                      onChange={(e) => {
                        changeLocationDetails("city", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    {(locationDetails.city === "" && isCityEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isCityEdited ? "" : <FormHelperText>{errors.city}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.ZIPPOSTAL}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="zip-code"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={globalizationMapping.PLACEHOLDER.ZipCode}
                      value={locationDetails.zipCode}
                      onChange={(e) => {
                        changeLocationDetails("zipCode", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    {(locationDetails.zipCode === "" && isZipCodeEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isZipCodeEdited ? "" : <FormHelperText>{errors.zipCode}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.PHONE}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="phone"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={globalizationMapping.PLACEHOLDER.Phone}
                      value={locationDetails.phoneNumber}
                      onChange={(e) => {
                        changeLocationDetails("phone", e.target.value);
                      }}
                      inputProps={{ maxLength: 12 }}
                    />
                    {(locationDetails.phoneNumber !== "" && !locationDetails.phoneNumber?.match(/\d{3}[ -]?\d{3}[ -]?\d{4}/) && isPhoneNumberEdited) ? <FormHelperText>Phone number is invalid. Must be at least 10 digits</FormHelperText> : ''}
                    {(locationDetails.phoneNumber === "" && isPhoneNumberEdited) ? <FormHelperText>This field is required and must be at least 10 digits</FormHelperText> : ''}
                    {(isPhoneNumberEdited ? "" : <FormHelperText>{errors.phoneNumber}</FormHelperText>)}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">{globalHeaderTexts.FAX}</p>
                    <TextField
                      id="fax"
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={globalizationMapping.PLACEHOLDER.Fax}
                      value={locationDetails.fax}
                      onChange={(e) => {
                        changeLocationDetails("fax", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.EMAIL}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="email"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={globalizationMapping.PLACEHOLDER.EmailIds}
                      value={locationDetails.emailAddress}
                      onChange={(e) => {
                        changeLocationDetails("email", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    {(locationDetails.emailAddress !== "" && !locationDetails.emailAddress?.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/) && isEmailAddressEdited) ? <FormHelperText>Email Address is invalid. Must have @ and .com or the like</FormHelperText> : ''}
                    {(locationDetails.emailAddress === "" && isEmailAddressEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isEmailAddressEdited ? "" : <FormHelperText>{errors.emailAddress}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.CONTACT}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="contact-person"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      placeholder={
                        globalizationMapping.PLACEHOLDER.ContactPerson
                      }
                      value={locationDetails.contactPerson}
                      onChange={(e) => {
                        changeLocationDetails("contactPerson", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      inputProps={{ maxLength: 150 }}
                    />
                    {(locationDetails.contactPerson === "" && isContactPersonEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isContactPersonEdited ? "" : <FormHelperText>{errors.contactPerson}</FormHelperText>)}
                  </Grid>
                </Grid>{" "}
              </div>
              <br></br>


              <hr className="hr-divider" />
              <br></br>
              <div className="create-sub-header">
                <span>{globalHeaderTexts.LOCATIONPREFERENCE}</span>
              </div>
              <div className="create-location-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.TREADDEPTHREADINGS}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      variant="outlined"
                      type="number"
                      value={locationDetails.noOfTreadDepthReadings}
                      onChange={(e) => {
                        changeLocationDetails(
                          "noOftreaddepthreadings",
                          parseInt(e.target.value)
                        );
                      }}
                    >
                      <option value={0} disabled>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                    </Select>
                    {(locationDetails.noOfTreadDepthReadings === 0 && isNoOfTDEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isNoOfTDEdited ? "" : <FormHelperText>{errors.noOfTreadDepthReadings}</FormHelperText>)}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.TREADDEPTHMETHOD}{" "}
                      {(locationDetails.noOfTreadDepthReadings === 2 || locationDetails.noOfTreadDepthReadings === 3) ? <span className="required-mark">*</span> : null}
                    </p>
                    {masterData.tireDepthMethods &&
                      masterData.tireDepthMethods.length > 0 && (
                        <Select
                          native
                          IconComponent={ExpandMoreStyledIcon}
                          className={
                            (locationDetails.noOfTreadDepthReadings === 1 || locationDetails.noOfTreadDepthReadings === 0)
                              ? "edit-detail-input-box  disabled-input"
                              : "edit-detail-input-box"
                          }
                          value={locationDetails.treadDepthMethodId}
                          variant="outlined"
                          type="number"
                          disabled={
                            locationDetails.noOfTreadDepthReadings === 1 || locationDetails.noOfTreadDepthReadings === 0
                          }
                          onChange={(e) => {
                            changeLocationDetails(
                              "treadDepthMethod",
                              e.target.value
                            );
                          }}
                        >
                          <option value={0} disabled>
                            {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                          </option>
                          {masterData.tireDepthMethods !== undefined &&
                            masterData?.tireDepthMethods.map((methods, key) => (
                              <option
                                key={methods.treadDepthMethodId}
                                value={methods.treadDepthMethodId}
                              >
                                {methods.treadDepthMethodName}
                              </option>
                            ))}
                        </Select>
                      )}
                    {((locationDetails.treadDepthMethodId === 0 && (locationDetails.noOfTreadDepthReadings === 2 || locationDetails.noOfTreadDepthReadings === 3)) && isTreadDepthMethodIdEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isTreadDepthMethodIdEdited ? "" : <FormHelperText>{errors.treadDepthMethodId}</FormHelperText>)}
                  </Grid>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.INSPECTIONORDER}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={locationDetails.inspectionOrderId}
                      variant="outlined"
                      type="number"
                      onChange={(e) => {
                        changeLocationDetails(
                          "inspectionorderid",
                          e.target.value
                        );
                      }}
                    >
                      <option value={0} disabled>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>
                      {masterData?.inspectionOrders &&
                        masterData.inspectionOrders.map((inspection) => (
                          <option
                            key={inspection.inspectionOrderId}
                            value={inspection.inspectionOrderId}
                          >
                            {inspection.inspectionOrderName}
                          </option>
                        ))}
                    </Select>
                    {(locationDetails.inspectionOrderId === "" && isInspectionOrderIdEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isInspectionOrderIdEdited ? "" : <FormHelperText>{errors.inspectionOrderId}</FormHelperText>)}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      Default Tread Depth Removal
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      id="contact-person"
                      type="number"
                      required
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={locationDetails.defaultRemovalTreadDepth}
                      onChange={(e) => {
                        changeLocationDetails("defaultremovaltreaddepth", commonUtils.minmaxInt(e.target.value, 0, 99999));
                      }}
                      inputProps={{ maxLength: 150 }}
                    />
                  </Grid>
                </Grid>
              </div>
              <hr className="hr-divider" />
              <br></br>
              <div className="create-sub-header">
                <span>{globalHeaderTexts.OPERATIONSPEC}</span>
              </div>
              <div className="create-location-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.MARKETSEGMENT}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={locationDetails.marketSegmentId}
                      variant="outlined"
                      type="number"
                      onChange={(e) => {
                        changeLocationDetails("marketsegment", e.target.value);
                      }}
                      inputProps={{
                        name: "marketsegment",
                        id: "segment-select-drop-down",
                      }}
                    // displayEmpty
                    >
                      <option value={0} disabled>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>

                      {masterData.marketSegments !== undefined &&
                        masterData?.marketSegments.map((segment) => (
                          <option key={segment.marketSegmentId} value={segment.marketSegmentId}>
                            {segment.marketSegmentName}
                          </option>
                        ))}
                    </Select>
                    {(locationDetails.marketSegmentId === "" && isMarketSegmentIdEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isMarketSegmentIdEdited ? "" : <FormHelperText>{errors.marketSegmentId}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.APPLICATIONTYPE}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className={locationDetails.marketSegmentId === 0
                        ? "edit-detail-input-box  disabled-input"
                        : "edit-detail-input-box"
                      }
                      value={locationDetails.applicationTypeId}
                      variant="outlined"
                      type="number"
                      disabled={locationDetails.marketSegmentId === 0}
                      onChange={(e) => {
                        changeLocationDetails(
                          "applicationtype",
                          e.target.value
                        );
                      }}
                      inputProps={{
                        name: "apptype",
                        id: "apptype-select-drop-down",
                      }}
                    // displayEmpty
                    >
                      <option value={0} disabled>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>
                      {appTypesByMarketSegment !== undefined &&
                        appTypesByMarketSegment?.map((type) => (
                          <option key={type.applicationTypeId} value={type.applicationTypeId}>
                            {type.applicationTypeName}
                          </option>
                        ))}
                    </Select>
                    {(locationDetails.applicationTypeId === "" && isApplicationTypeIdEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isApplicationTypeIdEdited ? "" : <FormHelperText>{errors.applicationTypeId}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.MATERIAL}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className={locationDetails.applicationTypeId === 0
                        ? "edit-detail-input-box  disabled-input"
                        : "edit-detail-input-box"
                      }
                      value={locationDetails.material}
                      variant="outlined"
                      type="number"
                      disabled={locationDetails.applicationTypeId === 0}
                      onChange={(e) => {
                        changeLocationDetails("material", e.target.value);
                      }}
                      inputProps={{
                        name: "material",
                        id: "material-select-drop-down",
                      }}
                    // displayEmpty
                    >
                      <option value={0} disabled>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>
                      {materialByMarketSegment !== undefined &&
                        materialByMarketSegment?.map((mat) => (
                          <option key={mat.material} value={mat.material}>
                            {mat.materialName}
                          </option>
                        ))}
                    </Select>
                    {(locationDetails.material === "" && isMaterialEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isMaterialEdited ? "" : <FormHelperText>{errors.material}</FormHelperText>)}
                  </Grid>
                </Grid>
              </div>
              <hr className="hr-divider" />
              <br></br>
              <div className="create-sub-header">
                <span>{globalHeaderTexts.VEHICLEOPERATINGSPEC}</span>
              </div>
              <div className="create-location-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.SITESEVERITYINDEX}{" "}
                      <span className="required-mark"> *</span>
                    </p>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="edit-detail-input-box"
                      value={locationDetails.siteSeverityIndex}
                      variant="outlined"
                      type="number"
                      onChange={(e) => {
                        changeLocationDetails(
                          "siteseverityindex",
                          e.target.value
                        );
                      }}
                    >
                      <option value={0} disabled>
                        {globalizationMapping.REPORTCOMMONWORDS.SELECT}
                      </option>
                      {masterData.siteSeverityIndexes !== undefined &&
                        masterData?.siteSeverityIndexes.map((index) => (
                          <option key={index.siteSeverityIndex} value={index.siteSeverityIndex}>
                            {index.siteSeverityIndexName}
                          </option>
                        ))}
                    </Select>
                    {(locationDetails.siteSeverityIndex === "" && isSiteSeverityIndexEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isSiteSeverityIndexEdited ? "" : <FormHelperText>{errors.siteSeverityIndex}</FormHelperText>)}
                  </Grid>
                  <Grid item xs={8}>
                    <p className="field-label">
                      {globalHeaderTexts.COMMENTS}{" "}
                    </p>
                    <TextField
                      id="contact-person"
                      required
                      className="comment-input-box"
                      variant="outlined"
                      placeholder={globalizationMapping.PLACEHOLDER.CommentsMsg}
                      multiline
                      rows={3}
                      value={locationDetails.severityComments}
                      onChange={(e) => {
                        changeLocationDetails(
                          "severitycomments",
                          commonUtils.removeLeadingSpaces(e.target.value)
                        );
                      }}
                      inputProps={{ maxLength: 200 }}
                    />
                  </Grid>
                </Grid>
              </div>
              <hr className="hr-divider" style={{ marginTop: "70px" }} />
              <div className="action-buttons action-buttons-create-location">
                <Button
                  className={styles.cancel}
                  variant="contained"
                  disableElevation={true}
                  onClick={() => {
                    setCancelModalOpen(true);
                  }}
                >
                  {globalizationMapping.FORMS.CANCEL}
                </Button>
                <Button
                  className={styles.save}
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  onClick={onClickSaveStatus}
                >
                  {globalizationMapping.FORMS.SAVE}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isCancelModalOpen && (
        <CancelConfirmationPopup
          closeConfirmationModal={() => setCancelModalOpen(false)}
          pathToRedirect={"/createparentaccount"}
          stateToPass={historyState}
        />
      )}
    </div>
  );
};
export default CreateLocation;
