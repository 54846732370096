import React, { useEffect, useContext, useState } from "react";
import "./ByRemovalReason.css";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import { Card, CardContent, TextField, InputAdornment, InputLabel, Select, FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import ScrapTiresNavigation from "../../../scrap-tires-navigation/components/ScrapTiresNavigation";
import TiresService from "../../../../../services/TiresApis.js";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import ByRemovalReasonTable from "../table/ByRemovalReasonTable";
import CustomGraph from "../graph/ByRemovalReasonGraph";
import ByRemovalReasonDataSetter from "../../../../../services/ByRemovalReasonUtils";
import CommonUtils from "../../../../../../../services/utils";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import RemovalReasonFilter from "../filter/RemovalReasonFilter.js";

const ByRemovalReason = () => {
  // GLOBAL ACCOUNT STATE management
  const globalizationMapping = new Globalization();

  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [byRemovalReasonTableData, setByRemovalReasonTableData] = useState();
  const [originalByRemovalReasonTableData, setOriginalByRemovalReasonTableData] = useState();
  const [tableBeforeFilter, setTableBeforeFilter] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [displayColOptions, setDisplayColOptions] = useState();
  const [displayColumns, setDisplayColumns] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(byRemovalReasonTableData);
  const [dateRangeType, setDateRangeType] = useState("AllTime");
  const [tireScrapType, setTireScrapType] = useState("AllTire");
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: "",
    toDate: ""
  });
  const [inServiceDateRange, setInServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [outServiceDateRange, setOutServiceDateRange] = useState({
    fromDate: "",
    toDate: ""
  });
  const [noData, setNoData] = useState(false);
  const [excludeUsedTires, setExcludeUsedTires] = useState(false);
  const [filterFieldsApplied, setFilterFieldsApplied] = useState();
  const [toggleHM, setToggleHM] = useState("hours");

  // filter options driver
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Manufacturer", value: "ManufacturerName" },
      { name: "Size", value: "SizeName" },
      { name: "Type", value: "TypeName" },
      { name: "Removal Reason", value: "ReasonName" },
      { name: "Compound", value: "CompoundName" }

    ],
    categoryData: {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      ReasonName: [],
      CompoundName: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      dateRangeFromDate: '',
      dateRangeToDate: '',
    }
  });
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

    // Handle modal pop ups for export
    const [modalHandler, setModalHandler] = useState({
      handlePdf: false,
      handleEmail: false,
      handlePrint: false
    });

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const toggleMile =
    JSON.parse(localStorage.getItem("accountInfouserMeasurementSystemValue")).toLowerCase() === "metric"
      ? globalizationMapping.MANAGEVEHICLE.KM
      : globalizationMapping.MANAGEVEHICLE.MILES;
  const commonUtils = new CommonUtils();
  const byRemovalReasonDataSetter = new ByRemovalReasonDataSetter();
  const tiresService = new TiresService();

  useEffect(() => {
    ByRemovalReasonResponseData(tireScrapType, excludeUsedTires, filterApplied, filterFieldsApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const ByRemovalReasonResponseData = (tireScrapTypeValue, excludeUsedTiresValue, filterAppliedValue, filterFieldsAppliedValue) => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;

    let inServiceDateRangeType = "AllTime";
    let outServiceDateRangeType = "AllTime";

    if (inServiceDateRange.fromDate !== "" && inServiceDateRange.toDate !== "") inServiceDateRangeType = "DateRange";

    if (outServiceDateRange.fromDate !== "" && outServiceDateRange.toDate !== "") outServiceDateRangeType = "DateRange";

    let requestObject = {
      reportOption: "",
      reportName: "Scrap_Tire_By_Removal_Reason",
      dateRangeType: dateRangeType,
      InServiceDateRangeType: inServiceDateRangeType,
      OutServiceDateRangeType: outServiceDateRangeType,
      fromDate: dateRangeFilter.fromDate,
      toDate: dateRangeFilter.toDate,
      OutServiceFromDate: outServiceDateRange.fromDate,
      OutServiceToDate: outServiceDateRange.toDate,
      InServiceFromDate: inServiceDateRange.fromDate,
      InServiceToDate: inServiceDateRange.toDate,
      accountIds: accountIds,
      locationIds: locationIds,
      TireScrapType: tireScrapTypeValue,
      usedTires: excludeUsedTiresValue.toString()
    };
    let filterOptions = {
      categories: [
        { name: globalizationMapping.REPORTTABLE.MANUFACTURER, value: "ManufacturerName"},
        { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
        { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
        { name: globalizationMapping.REPORTTABLE.REMOVALREASON, value: "ReasonName" },
        { name: globalizationMapping.REPORTTABLE.COMPOUND, value: "CompoundName" }
      ],
      categoryData: {
        ManufacturerName: [],
        SizeName: [],
        TypeName: [],
        ReasonName: [],
        CompoundName: [],
        outServiceDateToDate: '',
        outServiceDateFromDate: '',
        inServiceDateFromDate: '',
        inServiceDateToDate: '',
        dateRangeFromDate: '',
        dateRangeToDate: '',
      }
    };

    setShowSpinner(true);
    tiresService.getTiresReport(requestObject).then((data) => {
      let byRemovalReasonTableInfo = byRemovalReasonDataSetter.setData(data);
      let filterOptionsInfo = byRemovalReasonDataSetter.setFilterOptionsData(filterOptions, byRemovalReasonTableInfo.rowsData);
      byRemovalReasonTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
      setByRemovalReasonTableData(byRemovalReasonTableInfo);
      setOriginalByRemovalReasonTableData(byRemovalReasonTableInfo);
      setTableBeforeFilter(byRemovalReasonTableInfo);
      setFilterOptions(filterOptionsInfo);
      setDisplayColOptions(byRemovalReasonTableInfo.headCells);
      setDisplayColumns(byRemovalReasonTableInfo.headCells);
      setShowColumns(byRemovalReasonTableInfo.selectedColShow);
      setPrintData({
        reportType: "Scrap Tires : ",
        reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByRemovalReason,
        rowsData: byRemovalReasonTableInfo.rowsData,
        headCells: byRemovalReasonTableInfo.headCells,
        avgValues: byRemovalReasonDataSetter.avgValues(byRemovalReasonTableInfo.rowsData),
        selectedColShow: byRemovalReasonTableInfo.selectedColShow
      });
      setShowSpinner(false);
      if (filterAppliedValue) {
        setFilterApplied(true);
        let originalTableDataStore = byRemovalReasonTableInfo.rowsData;
        let filteredData = byRemovalReasonDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
        setPrintData({
          ...printData,
          rowsData: filteredData,
          avgValues: byRemovalReasonDataSetter.avgValues(filteredData),
          filterFieldsApplied: filterFieldsAppliedValue,
          filterApplied: true
        });
        setByRemovalReasonTableData({
          ...byRemovalReasonTableInfo,
          rowsData: filteredData,
          avgValues: byRemovalReasonDataSetter.avgValues(filteredData)
        });
        setOriginalByRemovalReasonTableData({
          ...byRemovalReasonTableInfo,
          rowsData: filteredData,
          avgValues: byRemovalReasonDataSetter.avgValues(filteredData)
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        (((Object.values(inServiceDateRange).every(x => x === "")) && (Object.values(dateRangeFilter).every(x => x === "")) && (Object.values(outServiceDateRange).every(x => x === ""))) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
      }
    });
  };



  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");

    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(printData, checkSortApplicable.split("sorted ")[0], checkSortApplicable.split("sorted ")[1]);
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    setModalHandler({ handleEmail: true });
  };

  const onClickFilter = () => {
    setShowFilter(true);
  };

  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      ManufacturerName: [],
      SizeName: [],
      TypeName: [],
      ReasonName: [],
      CompoundName: [],
      outServiceDateToDate: '',
      outServiceDateFromDate: '',
      inServiceDateFromDate: '',
      inServiceDateToDate: '',
      dateRangeFromDate: '',
      dateRangeToDate: '',
    })

    setFilterApplied(false);
    setByRemovalReasonTableData(tableBeforeFilter);
    setOriginalByRemovalReasonTableData(tableBeforeFilter);
    setPrintData({
      ...printData,
      ...tableBeforeFilter,
      filterApplied: false,
      filterFieldsApplied: {}
    });
    let tempFilterFieldsApplied = { ...filterFieldsApplied };
    Object.keys(tempFilterFieldsApplied).forEach((index) => {
      tempFilterFieldsApplied[index] = [];
    });
    setFilterFieldsApplied(tempFilterFieldsApplied);
    setOnlyFilterApplied(false);
    setNoData(false);
  };

  //APPLY DATE FILTER
  const confirmApplyDateFilter = (appliedFilter) => {
    let toSetCurrentFilter = 
    { ...appliedFilter,
      outServiceDateToDate: outServiceDateRange.toDate,
      outServiceDateFromDate:  outServiceDateRange.fromDate,
      inServiceDateFromDate: inServiceDateRange.fromDate,
      inServiceDateToDate: inServiceDateRange.toDate,
      dateRangeFromDate: dateRangeFilter.fromDate,
      dateRangeToDate: dateRangeFilter.toDate,
   };

  setCurrentFilter(toSetCurrentFilter);
  if (appliedFilter === filterOptions.categoryData) {
    setFilterApplied(false);
  } else {setFilterApplied(true)}

    setToggleHM("hours");
    actionChanged("hours");
    setFilterFieldsApplied(appliedFilter);
    ByRemovalReasonResponseData(tireScrapType, excludeUsedTires, true, appliedFilter);

    let originalTableDataStore = originalByRemovalReasonTableData?.rowsData;
    let filteredData = byRemovalReasonDataSetter.filterTable(appliedFilter, originalTableDataStore);
    setPrintData({
      ...printData,
      rowsData: filteredData,
      avgValues: byRemovalReasonDataSetter.avgValues(filteredData)
    });
    setByRemovalReasonTableData({
      ...byRemovalReasonTableData,
      rowsData: filteredData,
      avgValues: byRemovalReasonDataSetter.avgValues(filteredData)
    });
    setOnlyFilterApplied(true);
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };


  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    let searchText = event.target.value.toLowerCase();
    let originalTableDataStore, filteredData;
    originalTableDataStore = originalByRemovalReasonTableData?.rowsData;

    filteredData = byRemovalReasonDataSetter.search(originalTableDataStore, searchText);
    let updatedTableData = {
      rowsData: filteredData,
      headCells: originalByRemovalReasonTableData?.headCells,
      avgValues: originalByRemovalReasonTableData?.avgValues,
      selectedColShow: originalByRemovalReasonTableData?.selectedColShow
    };
    setByRemovalReasonTableData(updatedTableData);
    setPrintData({
      ...printData,
      reportType: "Scrap Tires : ",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByRemovalReason,
      rowsData: filteredData,
      headCells: originalByRemovalReasonTableData?.headCells,
      avgValues: byRemovalReasonDataSetter.avgValues(filteredData),
      selectedColShow: originalByRemovalReasonTableData?.selectedColShow
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  // USED FOR CHANGING DISPLAY COLUMNS
  const changeDisplayColumns = (selected) => {
    let selectedColumns = selected;
    setDisplayColumns(selectedColumns);

    // when all columns selected
    // if (selected.length === displayColOptions?.length) {
    //   setFilterApplied(false);
    // } else {
    //   setFilterApplied(true);
    // }
    let currentHeadCells = originalByRemovalReasonTableData?.headCells;
    let showColumnsUpdated = byRemovalReasonDataSetter.setDisplayColumnData(currentHeadCells, selected);
    setShowColumns(showColumnsUpdated);
    setPrintData({ ...printData, selectedColShow: showColumnsUpdated });
    selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
  };

  const handleUpdateTireScrapType = (event) => {
    setTireScrapType(event.target.value);
    setToggleHM("hours");
    actionChanged("hours");
    ByRemovalReasonResponseData(event.target.value, excludeUsedTires, filterApplied, filterFieldsApplied);
  };

  const handleExcludeUsedTires = (event) => {
    setExcludeUsedTires(event.target.checked);
    setToggleHM("hours");
    actionChanged("hours");
    ByRemovalReasonResponseData(tireScrapType, event.target.checked, filterApplied, filterFieldsApplied);
  };
  const actionChanged = (value) => {
    let avoidArr = [];
    setToggleHM(value);
    if (value === "miles") {
      avoidArr = ["AverageHours", "CostPerHour"];
    } else if (value === "hours") {
      avoidArr = ["AverageMiles", "CostPerMile"];
    } else {
      return;
    }

    let removalReasonTableInfo = byRemovalReasonTableData;

    removalReasonTableInfo.headCells.map((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      removalReasonTableInfo.selectedColShow[item.value] = flag;
    });
    setByRemovalReasonTableData(removalReasonTableInfo);
    removalReasonTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
    let updatedHeadCells = removalReasonTableInfo.headCells.filter((item) => {
      let flag = true;
      avoidArr.forEach((term) => {
        if (item.value === term) flag = false;
      });
      let temp = {};
      temp[item.value] = flag;
      return temp;
    });
    setDisplayColOptions(updatedHeadCells);
    setDisplayColumns(updatedHeadCells);
    setShowColumns(removalReasonTableInfo.selectedColShow);
    setPrintData({
      reportType: "Scrap Tires : ",
      reportName: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByRemovalReason,
      rowsData: removalReasonTableInfo.rowsData,
      headCells: removalReasonTableInfo.headCells,
      avgValues: byRemovalReasonDataSetter.avgValues(removalReasonTableInfo.rowsData),
      selectedColShow: removalReasonTableInfo.selectedColShow
    });
    setShowSpinner(false);
  };

  return (
    <div className="container">
      <ScrapTiresNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="removal-reason-container">
          <Card className="removal-reason-card">
            <CardContent className="graph-card">
              <CustomGraph byRemovalReasonGraphData={printData?.rowsData} />
            </CardContent>
            <CardContent className="table-card">
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form className="search-table-container" noValidate autoComplete="off" onSubmit={formSubmitHandler}>
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.SearchTable}</span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.REPORTCOMMONWORDS.Search}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img className="keyword-search-icon-img" alt="" src={SearchIcon} />
                            </InputAdornment>
                          )
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                  <div className="display-columns-container" id="dashboard-display-column">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}</span>
                    </InputLabel>
                    <MultiSelect
                      options={displayColOptions}
                      value={displayColumns}
                      onChange={changeDisplayColumns}
                      overrideStrings={{
                        selectSomeItems: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        allItemsAreSelected: globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                        selectAll: globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                        search: globalizationMapping.REPORTCOMMONWORDS.SELECT
                      }}
                    />
                  </div>
                  <div className="tire-scrap-type">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.REPORTCOMMONWORDS.ChangeHoursAndMiles}</span>
                    </InputLabel>
                    <Select
                      native 
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down" 
                      name="tire scrap type" 
                      variant="outlined" 
                      value={toggleHM} 
                      onChange={(e) => actionChanged(e.target.value)}>
                      <option value="hours">{globalizationMapping.MANAGEVEHICLE.HOURS} </option>
                      <option value="miles">{toggleMile} </option>
                    </Select>
                  </div>
                </div>
                <FilterPrintEmailExportSetButtons
                    onClickFilter={onClickFilter}
                    onClickEmail={onClickEmail}
                    onClickPrint={onClickPrint}
                    onClickExport={onClickExport}
                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                    printData={printData}
                    ref={ref}
                    onlyFilterApplied={onlyFilterApplied}
                    noData={noData}
                    isComponentVisible={isComponentVisible}
                  />
              </div>
              <Grid container>
                <Grid item xs={4} md={3} lg={2} xl={1}>
                  <div className="tire-scrap-type">
                    <InputLabel className="filter-table-label">
                      <span className="table-toolbar-label-text">{globalizationMapping.GRAPH.TireScrapType}</span>
                    </InputLabel>
                    <Select
                      native
                      IconComponent={ExpandMoreStyledIcon}
                      className="select-drop-down"
                      value={tireScrapType}
                      name="tire scrap type"
                      variant="outlined"
                      onChange={handleUpdateTireScrapType}>
                      <option value="AllTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption1} </option>
                      <option value="Repair">{globalizationMapping.REPORTCOMMONWORDS.TypeOption2} </option>
                      <option value="Retread">{globalizationMapping.REPORTCOMMONWORDS.TypeOption3} </option>
                      <option value="NewTire">{globalizationMapping.REPORTCOMMONWORDS.TypeOption4} </option>
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={4} md={4} lg={3} xl={2}>
                  <div
                    className="
                          exclude-used-tires"
                  >
                    <FormControlLabel
                      control={<Checkbox checked={excludeUsedTires} onChange={handleExcludeUsedTires} name="checkedB" color="primary" />}
                      label={globalizationMapping.GRAPH.ExcludeUsedTires}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="data-summary-table">
                <ByRemovalReasonTable tableData={byRemovalReasonTableData} showColumns={showColumns} filterApplied={filterApplied} />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <PrintExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />
      <PdfExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} noGraph={false} />
      <EmailExportModal modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={printData} />

      <RemovalReasonFilter 
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyDateFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        dateRangeFilter={dateRangeFilter}
        setDateRangeFilter={setDateRangeFilter}
        inServiceDateRange={inServiceDateRange}
        setInServiceDateRange={setInServiceDateRange}
        outServiceDateRange={outServiceDateRange}
        setOutServiceDateRange={setOutServiceDateRange}
        setDateRangeType={setDateRangeType}
      />
    </div>
  );
};

export default ByRemovalReason;
