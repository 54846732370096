import { FormHelperText, Grid, makeStyles, InputAdornment, OutlinedInput, Popper, Fade, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import "../create-vehicle/create-vehicle.css";
import infoLogo from "../../assets/Tooltip.svg";
import CommonUtils from "../../services/utils";

const Styles = makeStyles({
    root: {
        "& input.MuiAutocomplete-inputFocused": {
            color: "#000000 !important",
            fontWeight: "bold"
        }
    },
    input: {
        display: 'none'
    },
    FormHelperText: {
        align: 'center'
    },
    deleteBadge: {
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            top: '10px',
            right: '17px',
            background: 'transparent'
        },
    },
    adorn: {
        "& .MuiTypography-colorTextSecondary": {
            color: '#000000',
            fontWeight: '800'
        }
    },
    popper: {
        padding: 10,
        border: '1px solid #1C72D0',
        borderRadius: '5px',
        background: '#B1D2FF',
        fontSize: '12px',
        width: '400px',
        height: '30px',
        marginLeft: '33%'
    },
    poppercost: {
        padding: 10,
        border: '1px solid #1C72D0',
        borderRadius: '5px',
        background: '#B1D2FF',
        fontSize: '12px',
        width: '400px',
        height: '30px',
        marginLeft: '22%'
    }
});

const Costs = props => {
    const commonUtils = new CommonUtils();
    const classes = Styles();
    const [currency, setCurrency] = useState("");

    useEffect(() => {
        setCurrency(props.accLocationInfo.currency);
    }, [props.accLocationInfo]);
    const [popperstate, setpopperstate] = useState(false);
    const [anchorEl, setanchorEl] = useState(null);

    const handlePopperOpen = target => {
        setanchorEl(anchorEl ? null : target);
        setpopperstate(true);
    };

    const handlePopperClose = () => {
        setanchorEl(null);
        setpopperstate(false);
    };
    const [popperstateCa, setpopperstateCa] = useState(false);
    const [anchorElCa, setanchorElCa] = useState(null);

    const handlePopperOpenCa = target => {
        setanchorElCa(anchorElCa ? null : target);
        setpopperstateCa(true);
    };

    const handlePopperCloseCa = () => {
        setanchorElCa(null);
        setpopperstateCa(false);
    };
    return (
        <React.Fragment>
            <Grid item sm={12}>
                <p className="field-label">{props.fieldNames.OFREPAIRS}</p>
                <OutlinedInput
                    type="number"
                    className="enter-detail-input-box"
                    variant="outlined"
                    value={props.rimModal.repairCount}
                    onChange={e => props.onChangeListener('repairCount', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                />
                <FormHelperText>{props.formHelperTexts.repairCount}</FormHelperText>
            </Grid>
            <Grid item sm={12}>
                <p className="field-label">{props.fieldNames.PURCHASECOST}</p>
                <OutlinedInput
                    type="number"
                    className="enter-detail-input-box"
                    variant="outlined"
                    value={props.rimModal.purchaseCost}
                    onChange={e => props.onChangeListener('purchaseCost', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                    endAdornment={<InputAdornment className={classes.adorn} position="end">{currency}</InputAdornment>}
                />
                <FormHelperText>{props.formHelperTexts.purchaseCost}</FormHelperText>
            </Grid>
            <Grid item sm={12}>
                <p className="field-label">{props.fieldNames.REPAIRCOST}</p>
                <OutlinedInput
                    type="number"
                    className="enter-detail-input-box"
                    variant="outlined"
                    value={props.rimModal.repairCost}
                    onChange={e => props.onChangeListener('repairCost', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                    endAdornment={<InputAdornment className={classes.adorn} position="end">{currency}</InputAdornment>}
                />
                <FormHelperText>{props.formHelperTexts.repairCost}</FormHelperText>
            </Grid>
            <Grid item sm={12}>
                <p className="field-label">{props.fieldNames.COSTADJ}
                    <img
                        style={{ marginInlineStart: '5px' }}
                        // onClick={handleInfoClick}
                        onMouseEnter={e => handlePopperOpenCa(e.currentTarget)}
                        onMouseLeave={handlePopperCloseCa}
                        alt="info"
                        src={infoLogo}
                    // ref={anchorRef}
                    />
                    <Popper
                        open={popperstateCa}
                        placement="top"
                        anchorEl={anchorElCa}
                        transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.poppercost}>
                                    <p style={{ fontWeight: '400', marginTop: '0px' }}>{props.fieldNames.FORMULA}</p>
                                    <p style={{ fontStyle: 'italic', marginTop: '-10px' }}>{props.fieldNames.PURCHASECOST} + {props.fieldNames.REPAIRCOST}</p>
                                </Paper>
                            </Fade>
                        )}
                    </Popper></p>
                <OutlinedInput
                    type="number"
                    className="enter-detail-input-box disabled-input"
                    variant="outlined"
                    value={props.rimModal.costAdjustment}
                    onChange={e => props.onChangeListener('costAdjustment', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                    endAdornment={<InputAdornment className={classes.adorn} position="end">{currency}</InputAdornment>}
                    disabled
                />
                <FormHelperText>{props.formHelperTexts.costAdjustment}</FormHelperText>
            </Grid>
            <Grid item sm={12}>
                <p className="field-label">{props.fieldNames.SOLDAMOUNT}</p>
                <OutlinedInput
                    type="number"
                    className="enter-detail-input-box"
                    variant="outlined"
                    value={props.rimModal.soldAmount}
                    onChange={e => props.onChangeListener('soldAmount', commonUtils.minmaxInt(e.target.value, 0, 999999))}
                    endAdornment={<InputAdornment className={classes.adorn} position="end">{currency}</InputAdornment>}
                />
                <FormHelperText>{props.formHelperTexts.soldAmount}</FormHelperText>
            </Grid>
            <Grid item sm={12}>
                <p className="field-label">{props.fieldNames.NETCOST}
                    <img
                        style={{ marginInlineStart: '5px' }}
                        // onClick={handleInfoClick}
                        onMouseEnter={e => handlePopperOpen(e.currentTarget)}
                        onMouseLeave={handlePopperClose}
                        alt="info"
                        src={infoLogo}
                    // ref={anchorRef}
                    />
                    <Popper
                        open={popperstate}
                        placement="top"
                        anchorEl={anchorEl}
                        transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.popper}>
                                    <p style={{ fontWeight: '400', marginTop: '0px' }}></p>
                                    <p style={{ fontStyle: 'italic', marginTop: '-10px' }}>{props.fieldNames.COSTADJ} - {props.fieldNames.SOLDAMOUNT}</p>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </p>
                <OutlinedInput
                    type="number"
                    className="enter-detail-input-box disabled-input"
                    variant="outlined"
                    value={props.rimModal.netCost}
                    onChange={e => props.onChangeListener('netCost', e.target.value)}
                    endAdornment={<InputAdornment className={classes.adorn} position="end">{currency}</InputAdornment>}
                    disabled
                />
                <FormHelperText>{props.formHelperTexts.netCost}</FormHelperText>
            </Grid>
        </React.Fragment>
    );
};

export default Costs;
