import Globalization from "../../../globalization/index";

class TiresSentToInspectTireReportDataSetter {
  constructor() {
    this.data = {};
    this.tiresSentToInspectData = [];
    this.headCells = [];
    this.updateSelectedUsers = [];
    this.globalizationMapping = new Globalization();
  }

  formatData(registeredDate) {
    let date, formattedDate;
    if (registeredDate) {
      let dateString = registeredDate.split("/");
      date = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
      formattedDate = date;
    } else {
      date = null;
      formattedDate = date;
    }

    return formattedDate;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      if (headCell.value !== "BrandNumber") {
        updatedHeadCells.push(headCell);
      }
    });
    return updatedHeadCells;
  }
  updatedUserHeadCells(userId) {
    let updatedUserHeadCells = [];
    for (const element of this.updateSelectedUsers) {
      for (const value of userId) {
        if (element.value === value) {
          updatedUserHeadCells.push(element);
        }
      }
    }
    return updatedUserHeadCells;
  }
  updateUsers(reportData, userIdList) {
    let updateUserArray = [...new Set(userIdList)];
    let userObj = updateUserArray.map((row, index) => {
      return {
        key: row,
        keyIndex: index,
        value: row,
        label: row,
        numeric: false,
      };
    });
    return userObj;
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.AccountName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.InspectedBy.toLowerCase().includes(searchText)
        // item.VehId.toLowerCase().includes(searchText) ||
        // item.NoOfRetread.toString().includes(searchText) ||
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  avgValues = (tiresSentToInspectData) => {
    let TotalCount = 0;

    tiresSentToInspectData.forEach((item, index) => {
      TotalCount += Number(item.InspectedTireCount);
    });
    const avgValues = {
      InspectedBy: "",
      AccountName: "",
      ManufacturerName: "Total Inspected Tires Count",
      TypeName: "",
      SizeName: "",
      CompoundName: "",
      Date: "",
      InspectedTireCount: TotalCount,
    };
    return avgValues;
  };

  setData(data, userIdList) {
    // TABLE ROWS DATA
    const reportData = data.model.tireInspectionData;

    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.tiresSentToInspectData.push({
        Key: index,
        InspectedBy: item.inspectedBy ? item.inspectedBy : "",
        AccountName: item.accountName ? item.accountName : "",
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        TypeName: item.typeName ? item.typeName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        Date: item.date ? item.date : "",
        InspectedTireCount: item.inspectedTireCount
          ? item.inspectedTireCount
          : 0,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinition;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);
    this.updateSelectedUsers = this.updateUsers(reportData, userIdList);

    return {
      rowsData: this.tiresSentToInspectData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.tiresSentToInspectData),
      selectedColShow: selectedColShow,
      updateUsers: this.updateSelectedUsers,
    };
  }
}

export default TiresSentToInspectTireReportDataSetter;
