import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";

class TireHistoryDataSetter {
  constructor() {
    this.data = {};
    this.tireHistoryData = [];
    this.headCells = [];
    this.tireList = [];
    this.tireId = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "TotalMiles") {
        selectedColShow[headCell.value] = false;
      } else {
        selectedColShow[headCell.value] = true;
      }
    });

    return selectedColShow;
  }
  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }
  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
        updatedHeadCells.push(headCell);
      return true;
    });
    return updatedHeadCells;
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions

    // set initial list to avoid overload of list
    if (filterOptions.categoryData.CompoundName.length === 0 && filterOptions.categoryData.ManufacturerName.length === 0 
      && filterOptions.categoryData.SizeName.length === 0 && filterOptions.categoryData.TypeName.length === 0) {
        
        filterOptions.categoryData = filterModifiedCategoryData;
      }
    
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }
  setTireData(data) {
    const reportData = data.model;
    reportData.forEach((item, index) => {
      this.tireList.push({
        key: item.tireId,
        keyIndex: index,
        value: item.tireId,
        label: item.tireSerialNo,
      });
    });
    return this.tireList;
  }
  getTireIds(tireSerial) {
    const tireSerialData = tireSerial;
    tireSerialData.forEach((item) => {
      this.tireId.push(item.key);
    });
    return this.tireId;
  }

  
  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      item.forEach((item, index) => {
        this.tireHistoryData.push({
          Key: index,
          BrandNumber: item.brandNumber,
          RealSerialNo: item.serialNo,
          SerialNo: item.serialNo,
          AssetNumber: item.assetNumber ? item.assetNumber : "",
          ManufacturerName: item.manufacturerName,
          SizeName: item.sizeName,
          TypeName: item.typeName,
          CompoundName: item.compoundName ? item.compoundName : "",
          RatingName: item.ratingName,
          Date: item.date ? this.commonUtils.tableDateFormatter(item.date) : "",
          Disposition: item.disposition,
          Event: item.event,
          VehId: item.vehId,
          TotalHours: item.totalHours,
          TotalMiles: item.totalMiles ? item.totalMiles : 0,
          TreadDepth: item.treadDepth,
          Pressure: item.pressure,
          InspId: item.inspId,
        });
      });
    });
    //

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal" ||
          item.dataType === "combinednumber"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);
    //
    this.data = {
      rowsData: this.tireHistoryData,
      headCells: this.headCells,
      selectedColShow: selectedColShow,
    };
    // ));
    return this.data;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      let SerialNo = item.SerialNo ? true : false;
      let BrandNumber = item.BrandNumber ? true : false;

      return (
        (BrandNumber
          ? item.BrandNumber.toLowerCase().includes(searchText)
          : false) ||
        (SerialNo ? item.SerialNo.toLowerCase().includes(searchText) : false) ||
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.Date.toString().includes(searchText) ||
        item.Disposition.toLowerCase().includes(searchText) ||
        item.Event.toLowerCase().includes(searchText) ||
        item.VehId.toLowerCase().includes(searchText) ||
        item.AssetNumber.toLowerCase().includes(searchText) ||
        item.TotalHours.toString().includes(searchText) ||
        item.TreadDepth.toString().includes(searchText) ||
        item.Pressure.toString().includes(searchText) ||
        item.InspId.toLowerCase().includes(searchText)
      );
    });
  }
}

export default TireHistoryDataSetter;
