import React from "react";
import GroupedButtons from "../components/list-vehicles/groupedButtons/groupedButton";
import Dragula from "react-dragula";
import Globalization from "../../../globalization";
import { InspectionOrderConfiguration } from "../../../constants/InspectionOrder";
import TireAGreen from "../../../assets/Tire_a_green.png";
import TireAYellow from "../../../assets/Tire_a_yellow.png";
import TireAOrange from "../../../assets/Tire_a_orange.png";
import TireARed from "../../../assets/Tire_a_red.png";

class TireConfigUtilsViewOnly extends React.Component {
  constructor(props) {
    super(props);
    const inspOrder = props.inspectionOrder;
    this.configArrangement = InspectionOrderConfiguration.TirePositionConfig[inspOrder + ""][props.tireDetails.axleConfig + ""];
    this.tireDetails = {};
    this.positionArray = [];
    this.elementDiv = [];
    this.vehicleNumber = "";
    this.positionVehicleIdDiv = "fixed";
    this.emptyTirePosition = [];
    this.filledTirePositions = [];
    this.state = {
      outsideTread: "",
      middleTread: "",
      insideTread: "",
      noOfReadings: 0,
      element: []
    };
    this.userPressureUnit = "PSI";
    if (this.props) {
      this.history = this.props.history;
    }
  }
  shouldComponentUpdate = (nextProps, _nextState) => nextProps.vehicleNumber !== this.vehicleNumber;

  UNSAFE_componentWillMount = () => {
    window.addEventListener("scroll", this.listenToScroll);
    this.userPressureUnit = this.props.userPressureUnit;
    this.tireConfigUI(this.props.vehicleNumber, this.props.tireDetails, this.props.positionArray);
    const options = {
      isContainer(_el) {
        return false;
      },
      moves(el, _source, _handle, _sibling) {
        // elements are always draggable by default
        return !(el.classList.contains("axleConfigBar") || el.classList.contains("empty-tire-space-quick-inspect"));
      },
      accepts(_el, target, _source, _sibling) {
        // elements can be dropped in any of the `containers` by default
        return !!target.classList.contains("empty-tire-space-quick-inspect");
      },
      invalid(el, _handle) {
        // don't prevent any drags from initiating by default
        return !!el.classList.contains("empty-tire-space-quick-inspect");
      },
      // something is wrong above
      direction: "horizontal", // Y axis is considered when determining where an element would be dropped
      copy: false, // elements are moved by default, not copied
      copySortSource: false, // elements in copy-source containers can be reordered
      revertOnSpill: true, // spilling will put the element back where it was dragged from, if this is true
      removeOnSpill: false, // spilling will `.remove` the element, if this is true
      mirrorContainer: document.body, // set the element that gets mirror elements appended
      ignoreInputTextSelection: true, // allows users to select input text, see details below
      slideFactorX: 0, // allows users to select the amount of movement on the X axis before it is considered a drag instead of a click
      slideFactorY: 0
    };
    this.dragula = new Dragula([], options);
    this.dragula.on("drop", this.handleDropDragula);
  };
  globalizationMapping = new Globalization();
  handleDropDragula = (_el, _target, _source, _sibling) => {
    this.dragula.cancel(true);
  };


  isAvailable = (index, side, extra) => {
    for (const element of this.positionArray) {
      if (index === parseInt(element[0], 10)) {
        if (side === element[1]) {
          if (extra !== -1) {
            if (extra === element[2]) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }
    return false;
  };

  wornImg = (tire) => {
    if (tire.percentageWorn < 25) {
      return TireAGreen;
    } else if (tire.percentageWorn < 50) {
      return TireAYellow;
    } else if (tire.percentageWorn < 75) {
      return TireAOrange;
    } else {
      return TireARed;
    }
  };

  wornPercentageClass = (wornPercentage) => {

    if (wornPercentage < 25) {
      return '#00af45';
    } else if (wornPercentage < 50) {
      return '#afaf00';
    } else if (wornPercentage < 75) {
      return '#dd8d00';
    } else {
      return '#ad0b0b';
    }
  };

  getCurrentInstalledTireDetails = (element) => {
    let details = {};
    this.tireDetails.installedTires.forEach((installedTire) => {
      if (installedTire.wheelPosition === element) {
        const cPtabValue = this.configArrangement.findIndex((pos) => pos === installedTire.wheelPosition);
        details = installedTire;
        let traversalNumb = 0;
        traversalNumb = cPtabValue * 4;
        details["cPtabValue"] = traversalNumb + 1;
        details["outsideTabValue"] = traversalNumb + 2;
        details["middleTabValue"] = traversalNumb + 3;
        details["insideTabValue"] = traversalNumb + 4;
      }
      details.vehicleNumber = this.vehicleNumber;
    });
    return details;
  };
  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    if (
      (parseInt(this.tireDetails.axleConfig.length, 10) > 3 && scrolled > 0.5) ||
      (parseInt(this.tireDetails.axleConfig.length, 10) > 2 && parseInt(this.tireDetails.axleConfig.length, 10) <= 3 && scrolled > 0.3) ||
      (parseInt(this.tireDetails.axleConfig.length, 10) >= 1 && parseInt(this.tireDetails.axleConfig.length, 10) <= 2 && scrolled > 0.25)
    ) {
      this.positionVehicleIdDiv = "absolute";
      this.populateLRTires(this.tireDetails);
      this.forceUpdate();
    } else {
      this.positionVehicleIdDiv = "fixed";
      this.populateLRTires(this.tireDetails);
      this.forceUpdate();
    }
  };
  populateLRTires = (tireDetails) => {
    this.elementDiv = [];
    this.setState({ element: [] });
    if (tireDetails.axleConfig) {
      const axleMapping = tireDetails.axleConfig;
      const noOfAxles = axleMapping.length;
      const tiresEachAxle = [];
      tiresEachAxle.length = noOfAxles;
      const tirePosition = [];

      for (let index = 0; index < tiresEachAxle.length; index++) {
        tiresEachAxle[index] = axleMapping[index];
        const i = index + 1;
        if (parseInt(axleMapping[index], 10) === 4) {
          tirePosition.push(i + "LO");
          tirePosition.push(i + "LI");
          tirePosition.push(i + "RI");
          tirePosition.push(i + "RO");
        } else {
          if (parseInt(axleMapping[index], 10) === 2) {
            tirePosition.push(i + "L");
            tirePosition.push(i + "R");
          }
        }
      }
      tirePosition.push(tirePosition.length + "Out");
      let prevInd = 1;
      for (let index = 0; index < tirePosition.length - 1; index++) {
        const ind = parseInt(tirePosition[index][0], 10);
        const side = tirePosition[index][1];
        const extra = tirePosition[index].length === 3 ? tirePosition[index][2] : -1;
        if (side === "R" && (extra === -1 || extra === "I")) {
          this.elementDiv.push(
            <div key={index + "parentDiv"} style={{ display: "inline-flex", cursor: "pointer" }}>
              <span></span>
              <div>
                <div key={index + "A"} className=" axleConfigBar tire-container">
                  <div className="axle-inspect">
                    <span className="axleNumber">{ind}</span>
                    <img className="axleImg" alt="Axle" src={require("../../../assets/axle_b_middle_bar_inspection.svg")} />
                  </div>
                </div>
              </div>
            </div>
          );
        }
        const current = this.getCurrentInstalledTireDetails(ind + side + (extra === -1 ? "" : extra));
        if (this.isAvailable(ind, side, extra)) {
          if (prevInd !== ind) {
            this.elementDiv.push(<br key={"br" + ind}></br>);
            prevInd = ind;
          }
          this.elementDiv.push(
            <div
              id={tirePosition[index]}
              style={{ display: "inline-block" }}
              key={tirePosition[index]}
              //ref={this.addDraggable}
              data-type={`${tirePosition[index]}.${index}`}
            >
              <div
                className="onClick-link-tire-serial-number"
              >
                <div>SN: {current.tireSerialNo === "-" ? "" : current.tireSerialNo}</div>
                <div>BN: {current.brandNo === "" ? "-" : current.brandNo}</div>
                <div>RID: {current.mountedRimId === "" ? "-" : current.mountedRimId}</div>
              </div>
              <div style={{ display: "inline-block", verticalAlign: "middle", cursor: "pointer" }}>
                <span
                  onClick={() => {
                      this.history.push({
                        pathname: "/view-tire",
                        state: {
                          tireId: current.tireId,
                          fromPath: "/quick-inspect/" + this.tireDetails.vehicleId
                        }
                      }); 
                  }}
                  className="onClick-link"
                >
                  {this.globalizationMapping.HOMEDASHBOARD.VIEW}
                </span>
              </div>
              <div key={index}>
                <div className="tire-container">
                  <div style={{ height: "100%", width: "0%" }}>
                    <img
                      className="tireImg img-background-tire"
                      alt="Tire"
                      src={this.wornImg(current)}
                    />
                    {current.mountedRimId && (
                      <div
                        className="centered"
                        style={{
                          left: "1%",
                          width: "100%",
                          top: "50%",
                          height: "95%"
                        }}
                      >
                        <img className="tireImg img-background-tire" src={require("../../../assets/Rim_Tire_svg.svg")} alt="Rim" />
                      </div>
                    )}
                    <div className="centered">
                      <p
                        className="field-tire"
                      >
                        {this.globalizationMapping.INSPECTNREMOVETIRE.PRESSURE} ({this.userPressureUnit})
                      </p>
                      <div className="field-tire" style={{ height: "20px" }}>
                        <GroupedButtons
                          disabled={true}
                          value={current.currentPressure ? current.currentPressure : 0}
                          tabValue={current.cPtabValue}
                          pressureUnit={this.userPressureUnit}
                        />
                      </div>
                      <div className={current.pressureType === "C" ? "field-tire switch-container switch-container-blue" : "field-tire switch-container switch-container-red"}
                        style={{
                          height: "23px",
                          margin: "auto"
                        }}>
                        <label
                          className="field-tire"
                          style={{
                            marginTop: "10px", marginBottom: "0px", padding: "5px",
                            fontSize: "12px",
                            fontWeight: "normal",
                            color: "#F2F2F2"
                          }}
                        >
                          {current.pressureType === "C" ? "Cold" : "Hot"}
                        </label>
                      </div>
                      <p
                        className="field-tire"
                        style={{
                          marginTop: "10px", marginBottom: "0px"

                        }}
                      >
                        {this.globalizationMapping.INSPECTNREMOVETIRE.TREADDEPTH}
                      </p>
                      {this.props.noOfReadings > 0 && (
                        <>
                          <p
                            className="field-tire"
                          >
                            {this.globalizationMapping.INSPECT.OUTSIDE}
                          </p>
                          <div className="field-tire" style={{ height: "20px" }}>
                            <GroupedButtons
                              disabled={true}
                              tabValue={current.outsideTabValue}
                              value={current.outsideTread ? current.outsideTread : 0}
                            />
                          </div>
                        </>
                      )}
                      {this.props.noOfReadings > 2 && (
                        <>
                          <p
                            className="field-tire"
                          >
                            {this.globalizationMapping.INSPECT.MIDDLE}
                          </p>
                          <div className="field-tire" style={{ height: "20px" }}>
                            <GroupedButtons
                              disabled={true}
                              tabValue={current.middleTabValue}
                              value={current.middleTread ? current.middleTread : 0}
                            />
                          </div>
                        </>
                      )}
                      {this.props.noOfReadings > 1 && (
                        <>
                          <p
                            className="field-tire"
                          >
                            {this.globalizationMapping.INSPECT.INSIDE}
                          </p>
                          <div className="field-tire" style={{ height: "20px" }}>
                            <GroupedButtons
                              disabled={true}
                              value={current.insideTread ? current.insideTread : 0}
                              tabValue={current.insideTabValue}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <span><b>{current.wheelPosition}</b> | <span style={{ color: this.wornPercentageClass(current.percentageWorn) }}> {current.percentageWorn ? current.percentageWorn : 0}%</span></span>
              <br />
              <span>
                {current.currentMiles} {this.props.userPreference.toLowerCase() === "metric" ? "KM" : this.globalizationMapping.MANAGEVEHICLE.MILES}
              </span>
              <br />
              <span>
                {current.currentHours} {this.globalizationMapping.MANAGEVEHICLE.HOURS}
              </span>
              <br />
              <br></br>
            </div >
          );
        } else {
          if (prevInd !== ind) {
            this.elementDiv.push(<br key={index}></br>);
            prevInd = ind;
          }
          this.elementDiv.push(
            <div
              id={tirePosition[index]}
              data-type={`${tirePosition[index]}.${index}`}
              style={{
                display: "inline-block",
                cursor: "pointer",
                marginTop: "15px",
                marginBottom: "65px"
              }}
              key={tirePosition[index]}
              className="tire-container empty-tire empty-tire-space-quick-inspect"
            >
              <div>
                <div key={index}>
                  <div
                    style={{
                      height: "100%",
                      width: "0%"
                    }}
                  >
                    <div className="centered">
                      {!this.emptyTirePosition.includes(tirePosition[index]) && (
                        <span style={{ fontWeight: "bold", color: "#ff0000" }}>{this.globalizationMapping.FORMS.SUBMIT}</span>
                      )}
                      {!this.emptyTirePosition.includes(tirePosition[index]) && <br />}
                      {!this.emptyTirePosition.includes(tirePosition[index]) && (
                        <span style={{ fontWeight: "bold", color: "#ff0000" }}>{this.globalizationMapping.REPORTCOMMONWORDS.Rotation}</span>
                      )}
                      {!this.emptyTirePosition.includes(tirePosition[index]) && <br />}
                      {!this.emptyTirePosition.includes(tirePosition[index]) && <span style={{ color: "#ff0000" }}>{this.globalizationMapping.REPORTCOMMONWORDS.ToInstall}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }

      this.setState({ element: this.elementDiv });

      this.forceUpdate();
    }
  };


  tireConfigUI = (vehicleNumber, tireDetails, positionArray) => {
    this.tireDetails = tireDetails;
    this.vehicleNumber = vehicleNumber;
    this.positionArray = positionArray;
    tireDetails.installedTires.forEach((tire) => {
      this.filledTirePositions.push(tire.wheelPosition);
    });
    const axleMapping = tireDetails.axleConfig;
    const noOfAxles = axleMapping.length;
    const tiresEachAxle = [];
    tiresEachAxle.length = noOfAxles;

    for (let index = 0; index < tiresEachAxle.length; index++) {
      tiresEachAxle[index] = axleMapping[index];
      const i = index + 1;
      if (parseInt(axleMapping[index], 10) === 4) {
        if (!this.filledTirePositions.includes(i + "LO")) {
          this.emptyTirePosition.push(i + "LO");
        }
        if (!this.filledTirePositions.includes(i + "LI")) {
          this.emptyTirePosition.push(i + "LI");
        }
        if (!this.filledTirePositions.includes(i + "RI")) {
          this.emptyTirePosition.push(i + "RI");
        }
        if (!this.filledTirePositions.includes(i + "RO")) {
          this.emptyTirePosition.push(i + "RO");
        }
      } else {
        if (parseInt(axleMapping[index], 10) === 2) {
          if (!this.filledTirePositions.includes(i + "L")) {
            this.emptyTirePosition.push(i + "L");
          }
          if (!this.filledTirePositions.includes(i + "R")) {
            this.emptyTirePosition.push(i + "R");
          }
        }
      }
    }
    this.populateLRTires(tireDetails);
  };
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          marginRight: "auto",
          marginLeft: "auto",
          flexWrap: "nowrap",
          marginTop: "30px"
        }}
      >
        {this.state.element}
      </div>
    );
  }
}
export default TireConfigUtilsViewOnly;
