import Globalization from "../../../globalization/index";

class ScrapTiresSummaryDataSetter {
  constructor() {
    this.data = {};
    this.scrapSummaryData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (
        headCell.value === "AverageMiles" ||
        headCell.value === "AverageWearRateByMiles" ||
        headCell.value === "CostPerMile" ||
        headCell.value === "TotalAverageMiles"
      ) {
        selectedColShow[headCell.value] = false;
      } else {
        selectedColShow[headCell.value] = true;
      }
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      if (item.CompoundName === undefined) {
        item.CompoundName = ""
      }
      return (
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.TireCount.toString().includes(searchText) ||
        item.AverageWorn.toString().includes(searchText) ||
        item.AverageWearRate.toString().includes(searchText) ||
        item.AverageHours.toString().includes(searchText) ||
        item.AverageMiles.toString().includes(searchText) ||
        item.CostPerHour.toString().includes(searchText) ||
        item.VehicleTypeName.toString().toLowerCase().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        //
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }
  avgValues = (scrapSummaryData) => {
    let WearRateSum = 0,
      WearRatePerMileSum = 0,
      HoursSum = 0,
      CostPerHourSum = 0,
      CostPerMileSum = 0,
      AverageWornSum = 0,
      TiresSum = 0,
      MilesSum = 0;
    scrapSummaryData.forEach((item, index) => {
      WearRateSum += item.AverageWearRate * item.TireCount;
      WearRatePerMileSum += item.AverageWearRateByMiles * item.TireCount;
      HoursSum += item.TotalAverageHours;
      CostPerHourSum += parseFloat(item.CostPerHour * item.TireCount);
      CostPerMileSum += parseFloat(item.CostPerMile * item.TireCount);
      AverageWornSum += item.AverageWorn * item.TireCount;
      TiresSum += item.TireCount;
      MilesSum += item.TotalAverageMiles;
    });

    let WearRateAvg = Math.round(WearRateSum / TiresSum);
    let WearRateAvgperMile = Math.round(WearRatePerMileSum / TiresSum);
    let MilesAvg = Math.round(MilesSum / TiresSum);
    let HoursAvg = Math.round(HoursSum / TiresSum);
    let CostPerHourAvg = parseFloat(CostPerHourSum / TiresSum).toFixed(2);
    let CostPerMileAvg = parseFloat(CostPerMileSum / TiresSum).toFixed(2);
    let AverageWornAvg = Math.round(AverageWornSum / TiresSum);
    let TireCount = TiresSum;

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      CompoundName: "",
      TireCount: TireCount,
      AverageWorn: AverageWornAvg,
      AverageWearRate: WearRateAvg,
      AverageWearRateByMiles: WearRateAvgperMile,
      AverageHours: HoursAvg,
      AverageMiles: MilesAvg,
      CostPerHour: CostPerHourAvg,
      CostPerMile: CostPerMileAvg,
      VehicleTypeName: "",
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;

    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.scrapSummaryData.push({
        Key: index,
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        TypeName: item.typeName ? item.typeName : "",
        VehicleTypeName: item.vehicleTypeName ? item.vehicleTypeName : "",
        TireCount: item.tireCount ? item.tireCount : 0,
        AverageWorn: item.averageWorn ? item.averageWorn : 0,
        AverageWearRate: item.averageWearRate
          ? ((item.averageWearRate * 100) / 100).toFixed(2)
          : 0,
        AverageWearRateByMiles: item.averageWearRatePerMile
          ? ((item.averageWearRatePerMile * 100) / 100).toFixed(2)
          : 0,
        AverageHours: item.averageHours ? item.averageHours : 0,
        AverageMiles: item.averageMiles ? item.averageMiles : 0,
        TotalAverageHours: item.averageHours
          ? item.averageHours * item.tireCount
          : 0,
        TotalAverageMiles: item.averageMiles
          ? item.averageMiles * item.tireCount
          : 0,
        CostPerHour: item.costPerHour
          ? ((item.costPerHour * 100) / 100).toFixed(2)
          : 0,
        CostPerMile: item.costPerMile
          ? ((item.costPerMile * 100) / 100).toFixed(2)
          : 0,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
            item.dataType === "currency" ||
            item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);
    return {
      rowsData: this.scrapSummaryData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.scrapSummaryData),
      selectedColShow: selectedColShow,
    };
  }
}

export default ScrapTiresSummaryDataSetter;
