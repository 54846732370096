import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';

export default class TireHistoryApis {
    cs;
    constructor(){
         this.cs = new CommonService(); 
    }
    updateTireHistoryEntry = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.updateTireHistory}`,'put',req);
    };
    getInspectionRecordByEvent = (eventId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getInspectionRecordByEvent}${eventId}`,'get',{});
    };
    updateTireInspectionRecord = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.updateTireInspectionRecord}`,'put',req);
    };
    deleteEvent = (eventId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.deleteEvent}${eventId}`,'delete',{});
    };
}