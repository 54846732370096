import Globalization from "../../../globalization/index";
import CommonUtils from "../../../services/utils";

class ByIndividualTireDataSetter {
  constructor() {
    this.data = {};
    this.byIndividualTireData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }
  tableHeaderOrderSetter(headCells) {
    let tableHeaders = headCells;
    // maintains the column order to be displayed (write them in the order you want to display table header)
    let headerOrder = [
      { columnNo: 1, value: "ManufacturerName" },
      { columnNo: 2, value: "SizeName" },
      { columnNo: 3, value: "TypeName" },
      { columnNo: 4, value: "TireSerialNo" },
      { columnNo: 5, value: "CompoundName" },
      { columnNo: 6, value: "RatingName" },
      { columnNo: 7, value: "EvaluationNo" },
      { columnNo: 8, value: "BrandNo" },
      { columnNo: 9, value: "VehicleNumber" },
      { columnNo: 10, value: "WheelPosition" },
      { columnNo: 11, value: "TreadDepth" },
      { columnNo: 12, value: "HoursPerTreadDepth" },
      { columnNo: 13, value: "MilesPerTreadDepth" },
      { columnNo: 14, value: "PercentageWorn" },
      { columnNo: 15, value: "InstalledDate" },
      { columnNo: 16, value: "RemovedDate" },
      { columnNo: 17, value: "RemovalReason" },
      { columnNo: 18, value: "RepairRetreadCount" },
      { columnNo: 19, value: "TotalCostBeforeAdjustment" },
      { columnNo: 20, value: "AdjustmentCost" },
      { columnNo: 21, value: "CurrentHours" },
      { columnNo: 22, value: "TotalMiles" },
      { columnNo: 23, value: "AdjustmentCostPerHour" },
      { columnNo: 24, value: "AdjustmentCostPerMile" },
      { columnNo: 25, value: "CostPerHour" },
      { columnNo: 26, value: "CostPerMile" },
      { columnNo: 27, value: "MoneyLost" },
    ];

    let resultHeaderCell = [];
    headerOrder.forEach((headerOrder) => {
      let found = false;
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === headerOrder.value) {
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });

    return resultHeaderCell;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      updatedHeadCells.push(headCell);
    });
    return updatedHeadCells;
  }

  formatData(item) {
    let dateString, removedDate, installedDate;
    if (item.removedDate) {
      dateString = item.removedDate.split("/");
      removedDate = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      removedDate = null;
    }
    if (item.installedDate && item.installedDate !== "") {
      dateString = item.installedDate.split("/");
      installedDate = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      installedDate = null;
    }

    let formattedData = {
      removedDate: removedDate,
      installedDate: installedDate,
    };
    return formattedData;
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "BrandNo" || headCell.value === "TotalMiles") {
        selectedColShow[headCell.value] = false;
      } else {
        selectedColShow[headCell.value] = true;
      }
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      searchText = searchText.toLowerCase();
      let ManufacturerName = !!item.ManufacturerName;
      let SizeName = !!item.SizeName;
      let TypeName = !!item.TypeName;
      let TireSerialNo = !!item.TireSerialNo;
      let BrandNo = !!item.BrandNo;
      let CompoundName = !!item.CompoundName;
      let RatingName = !!item.RatingName;
      let EvaluationNo = !!item.EvaluationNo;
      let VehicleNumber = !!item.VehicleNumber;
      let WheelPosition = !!item.WheelPosition;
      let TreadDepth = !!item.TreadDepth;
      let HoursPerTreadDepth = !!item.HoursPerTreadDepth;
      let PercentageWorn = !!item.PercentageWorn;
      let MilesPerTreadDepth = !!item.MilesPerTreadDepth;
      let InstalledDate = !!item.InstalledDate;
      let RemovedDate = !!item.RemovedDate;
      let RemovalReason = !!item.RemovalReason;
      let TotalCostBeforeAdjustment = !!item.TotalCostBeforeAdjustment;
      let AdjustmentCost = !!item.AdjustmentCost;
      let CurrentHours = !!item.CurrentHours;
      let TotalMiles = !!item.TotalMiles;

      let AdjustmentCostPerHour = !!item.AdjustmentCostPerHour;
      let CostPerHour = !!item.CostPerHour;
      let CostPerMile = !!item.CostPerMile;
      let MoneyLost = !!item.MoneyLost;
      let RepairRetreadCount = !!item.RepairRetreadCount;
      return (
        (ManufacturerName ? item.ManufacturerName.toString().toLowerCase().includes(searchText) : false) ||
        (SizeName ? item.SizeName.toLowerCase().includes(searchText) : false) ||
        (TypeName ? item.TypeName.toLowerCase().includes(searchText) : false) ||
        (BrandNo ? item.BrandNo.toString().includes(searchText) : false) ||
        (TireSerialNo ? item.TireSerialNo.toString().toLowerCase().includes(searchText) : false) ||
        (CompoundName ? item.CompoundName.toLowerCase().includes(searchText) : false) ||
        (RatingName ? item.RatingName.toLowerCase().includes(searchText) : false) ||
        (EvaluationNo ? item.EvaluationNo.toLowerCase().includes(searchText) : false) ||
        (VehicleNumber ? item.VehicleNumber.toLowerCase().includes(searchText) : false) ||
        (WheelPosition ? item.WheelPosition.toLowerCase().includes(searchText) : false) ||
        (TreadDepth ? item.TreadDepth.toLowerCase().includes(searchText) : false) ||
        (HoursPerTreadDepth ? item.HoursPerTreadDepth.toString().includes(searchText) : false) ||
        (MilesPerTreadDepth ? item.MilesPerTreadDepth.toString().includes(searchText) : false) ||
        (PercentageWorn ? item.PercentageWorn.toString().includes(searchText) : false) ||
        (InstalledDate ? item.InstalledDate.toLowerCase().includes(searchText) : false) ||
        (RemovedDate ? item.RemovedDate.toLowerCase().includes(searchText) : false) ||
        (RemovalReason ? item.RemovalReason.toLowerCase().includes(searchText) : false) ||
        (RepairRetreadCount ? item.RepairRetreadCount.toString().includes(searchText) : false) ||
        (TotalCostBeforeAdjustment ? item.TotalCostBeforeAdjustment.toString().includes(searchText) : false) ||
        (AdjustmentCost ? item.AdjustmentCost.toString().includes(searchText) : false) ||
        (CurrentHours ? item.CurrentHours.toString().includes(searchText) : false) ||
        (TotalMiles ? item.TotalMiles.toString().includes(searchText) : false) ||
        (AdjustmentCostPerHour ? item.AdjustmentCostPerHour.toString().includes(searchText) : false) ||
        (CostPerHour ? item.CostPerHour.toString().includes(searchText) : false) ||
        (CostPerMile ? item.CostPerMile.toString().includes(searchText) : false) ||
        (MoneyLost ? item.MoneyLost.toString().includes(searchText) : false)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          ) &&
          rowData[category.value]
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    //

    let categoryKeys = Object.keys(appliedFilter);

    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }
  avgValues = (byIndividualTireData) => {
    let PercentageWorn = 0,
      TotalCostBeforeAdjustment = 0,
      AdjustmentCost = 0,
      TotalHours = 0,
      TotalMiles = 0,
      AdjustmentCostPerHour = 0,
      AdjustmentCostPerMile = 0,
      CostPerHour = 0,
      CostPerMile = 0,
      MoneyLost = 0;
    byIndividualTireData.forEach((item, index) => {
      PercentageWorn += item.PercentageWorn;
      TotalCostBeforeAdjustment += item.TotalCostBeforeAdjustment;
      AdjustmentCost += item.AdjustmentCost;
      TotalHours += item.CurrentHours;
      TotalMiles += item.TotalMiles;
      AdjustmentCostPerHour += item.AdjustmentCostPerHour;
      AdjustmentCostPerMile += item.AdjustmentCostPerMile;
      CostPerHour += parseFloat(item.CostPerHour);
      CostPerMile += parseFloat(item.CostPerMile);
      MoneyLost += parseFloat(item.MoneyLost);
    });

    let PercentageWornAvg = Math.round(PercentageWorn.toFixed(2) / byIndividualTireData.length);

    let TotalCostBeforeAdjustmentTotAvg =
      TotalCostBeforeAdjustment + "/" +
      Math.round(TotalCostBeforeAdjustment / byIndividualTireData.length);

    let AdjustmentCostTotAvg =
      AdjustmentCost.toFixed(2) + "/" +
      Math.round(AdjustmentCost.toFixed(2) / byIndividualTireData.length);

    let TotalHoursTotAvg =
      TotalHours + "/" +
      Math.round(TotalHours.toFixed(2) / byIndividualTireData.length);

    let TotalMilesTotAvg =
      TotalMiles + "/" +
      Math.round(TotalMiles.toFixed(2) / byIndividualTireData.length);

    let AdjustmentCostPerHourTotAvg =
      AdjustmentCostPerHour.toFixed(2) + "/" +
      Math.round(AdjustmentCostPerHour.toFixed(2) / byIndividualTireData.length);

    let AdjustmentCostPerMileTotAvg =
      AdjustmentCostPerMile.toFixed(2) +
      "/" +
      Math.round(
        AdjustmentCostPerMile.toFixed(2) / byIndividualTireData.length
      );
    let CostPerHourTotAvg =
      CostPerHour.toFixed(2) + "/" +
      parseFloat(Math.round(TotalCostBeforeAdjustment / byIndividualTireData.length).toFixed(2)
        / Math.round(TotalHours.toFixed(2) / byIndividualTireData.length)).toFixed(2);

    let CostPerMileTotAvg =
      CostPerMile.toFixed(2) + "/" +
      parseFloat(Math.round(TotalCostBeforeAdjustment / byIndividualTireData.length).toFixed(2)
        / Math.round(TotalMiles.toFixed(2) / byIndividualTireData.length)).toFixed(2);


    let MoneyLostTotAvg =
      MoneyLost.toFixed(2) +
      "/" +
      parseFloat(MoneyLost.toFixed(2) / byIndividualTireData.length).toFixed(2);

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      TireSerialNo: "",
      CompoundName: "",
      RatingName: "",
      EvaluationNo: "",
      BrandNo: "",
      VehicleNumber: "",
      WheelPosition: "",
      TreadDepth: "",
      HoursPerTreadDepth: "",
      MilesPerTreadDepth: "",
      PercentageWorn: PercentageWornAvg,
      InstalledDate: "",
      RemovedDate: "",
      RemovalReason: "",
      RepairRetreadCount: "",
      TotalCostBeforeAdjustment: TotalCostBeforeAdjustmentTotAvg,
      AdjustmentCost: AdjustmentCostTotAvg,
      CurrentHours: TotalHoursTotAvg,
      TotalMiles: TotalMilesTotAvg,
      AdjustmentCostPerHour: AdjustmentCostPerHourTotAvg,
      AdjustmentCostPerMile: AdjustmentCostPerMileTotAvg,
      CostPerHour: CostPerHourTotAvg,
      CostPerMile: CostPerMileTotAvg,
      MoneyLost: MoneyLostTotAvg,
    };
    return avgValues;
  };
  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;

    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */

      this.byIndividualTireData.push({
        Key: index,
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        TireSerialNo: item.tireSerialNo ? item.tireSerialNo : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        RatingName: item.ratingName ? item.ratingName : "",
        EvaluationNo: item.evaluationNo ? item.evaluationNo : "",
        BrandNo: item.brandNo ? item.brandNo : "",
        VehicleNumber: item.vehicleNumber ? item.vehicleNumber : "",
        WheelPosition: item.wheelPosition ? item.wheelPosition : "",
        TreadDepth: item.treadDepth ? item.treadDepth : "",
        HoursPerTreadDepth: item.hoursPerTreadDepth ? item.hoursPerTreadDepth : 0,
        MilesPerTreadDepth: item.milesPerTreadDepth ? item.milesPerTreadDepth : 0,
        PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
        InstalledDate: item.installedDate ? this.commonUtils.tableDateFormatter(item.installedDate) : "",
        RemovedDate: item.removedDate ? this.commonUtils.tableDateFormatter(item.removedDate) : "",
        RemovalReason: item.removalReason ? item.removalReason : "",
        RepairRetreadCount: item.repairRetreadCount ? item.repairRetreadCount : "",
        TotalCostBeforeAdjustment: item.totalCostBeforeAdjustment ? item.totalCostBeforeAdjustment : 0,
        AdjustmentCost: item.adjustmentCost ? item.adjustmentCost : 0,
        CurrentHours: item.currentHours ? item.currentHours : 0,
        TotalMiles: item.totalMiles ? item.totalMiles : 0,
        AdjustmentCostPerHour: item.adjustmentCostPerHour ? item.adjustmentCostPerHour : 0,
        CostPerHour: parseFloat((item.costPerHour * 100) / 100).toFixed(2),
        MoneyLost: parseFloat((item.moneyLost * 100) / 100).toFixed(2),
        AdjustmentCostPerMile: item.adjustmentCostPerMile ? item.adjustmentCostPerMile : 0,
        CostPerMile: parseFloat((item.costPerMile * 100) / 100).toFixed(2),
      });
    });
    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
            item.dataType === "currency" ||
            item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells);
    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: JSON.parse(JSON.stringify(this.byIndividualTireData)),
      headCells: orderedHeadCells,
      avgValues: this.avgValues(this.byIndividualTireData),
      selectedColShow: selectedColShow,
    };
  }
}

export default ByIndividualTireDataSetter;
