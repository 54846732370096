import Globalization from "../../../globalization/index";

class TireForecastByTireTypeDataSetter {
  constructor() {
    this.tireForecastByTireTypeData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }

  // setGraphData(data){
  //     let graphData = data.model.graphData
  //     return graphData
  // }
  // setDisplayColumns(graphData) {
  //     let displayColumns = []
  //     graphData.chartlabels.forEach((item, index) => {
  //         displayColumns.push({
  //             key: item,
  //             keyIndex: index,
  //             value: item,
  //             label: item,
  //             count1:  graphData.chartData[0].data[index],
  //             count2:  graphData.chartData[1].data[index],
  //             legendLabel1: graphData.chartData[0].label,
  //             legendLabel2: graphData.chartData[1].label,
  //         })
  //     })
  //     return displayColumns
  // }
  // setDisplayColumnData(tireForecastByTireTypeGraphData, selectedCols) {
  //     // dataGroup.length = tireForecastByTireTypeGraphData.chartlabels.length
  //     // dataGroup.splice(i,0,tireForecastByTireTypeGraphData.chartData[0].data[i])
  //     let bS = {chartlabels:[],chartData:[{data:[],label:''},{data:[],label:''}]}
  //     //let BreakException= {};
  //     for(let graphIndex=0;graphIndex<tireForecastByTireTypeGraphData.chartlabels.length;graphIndex++){
  //         for(let index=0;index<selectedCols.length;index++){
  //             if(selectedCols[index].value === tireForecastByTireTypeGraphData.chartlabels[graphIndex]){
  //                 bS.chartlabels[graphIndex] = selectedCols[index].value;
  //                 bS.chartData[0].data[graphIndex] =  selectedCols[index].count1;
  //                 bS.chartData[1].data[graphIndex] =  selectedCols[index].count2;
  //                 bS.chartData[0].label = selectedCols[index].legendLabel1;
  //                 bS.chartData[1].label = selectedCols[index].legendLabel2;
  //                 break;
  //             }
  //             // else{
  //             //     bS.chartlabels[graphIndex] = ''
  //             //     bS.chartData[0].data[graphIndex] = 0
  //             // }
  //         }
  //     }
  //     bS.chartlabels = bS.chartlabels.filter(item => item !== undefined)
  //     bS.chartData[0].data = bS.chartData[0].data.filter(item => item !== undefined)
  //     bS.chartData[1].data = bS.chartData[1].data.filter(item => item !== undefined)
  //     //
  //     return bS;
  //   }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    //
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.ManufacturerName.toLowerCase().includes(searchText) ||
        item.SizeName.toLowerCase().includes(searchText) ||
        item.TypeName.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.TireCount.toString().includes(searchText) ||
        item.CurrentHours.toString().toLowerCase().includes(searchText) ||
        item.ProjectedHours.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    //
    //

    let categoryKeys = Object.keys(appliedFilter);

    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }

  avgValues = (tiresForecastByTireTypeData) => {
    let TotalTires = 0,
      ProjectedHoursSum = 0,
      CurrentHoursSum = 0;
    tiresForecastByTireTypeData.forEach((item) => {
      TotalTires += item.TireCount;
      ProjectedHoursSum += item.ProjectedHours;
      CurrentHoursSum += item.CurrentHours;
    });

    let TotalTiresSum = TotalTires;
    let ProjectedHoursAvg = Math.round(
      ProjectedHoursSum / tiresForecastByTireTypeData.length
    );
    let CurrentHoursAvg = Math.round(
      CurrentHoursSum / tiresForecastByTireTypeData.length
    );

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      CompoundName: "",
      TireCount: TotalTiresSum,
      CurrentHours: CurrentHoursAvg,
      ProjectedHours: ProjectedHoursAvg,
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.tireForecastByTireTypeData.push({
        Key: index,
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        TireCount: item.tireCount ? item.tireCount : 0,
        CurrentHours: item.currentHours
          ? Math.round(item.currentHours * 100) / 100
          : 0,
        ProjectedHours: item.projectedHours
          ? Math.round(item.projectedHours * 100) / 100
          : 0,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "decimal" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.tireForecastByTireTypeData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.tireForecastByTireTypeData),
      selectedColShow: selectedColShow,
    };
  }
}

export default TireForecastByTireTypeDataSetter;
