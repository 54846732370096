import React, { useEffect, useState, useContext } from "react";
import "../../../../../../../common-styles/TableFunctionOptions.css";
import RimReportsNavigation from "../../../../rim-reports-navigation/RimReportsNavigation";
import {
    Card,
    CardContent,
    TextField,
    InputAdornment,
    InputLabel,
} from "@material-ui/core";
import RimsService from "../../../../../services/RimApis.js";
import RimReportDataSetter from "../../../../../services/RimReportUtils";
import Spinner from "../../../../../../../common-components/spinner/spinner";
import RimReportTable from "../table/RimReportTable";
import EmailModalComponent from "../../../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import MultiSelect from "react-multi-select-component";
import SearchIcon from "../../../../../../../assets/icons8-search.svg";
import CommonUtils from "../../../../../../../services/utils";
import { StateContext } from "../../../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../../../services/AccountInfoHandler";
import Globalization from "../../../../../../../globalization";
import { useComponentVisible } from "../../../../../../../services/CustomHooks";
import FilterPrintEmailExportSetButtons from '../../../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import RimReportFilter from "../filter/RimReportFilter.js";

const RimReport = () => {
    // GLOBAL ACCOUNT STATE management
    const [globalAccountState] = useContext(StateContext);
    const rimsService = new RimsService();
    const commonUtils = new CommonUtils();
    const rimReportDataSetter = new RimReportDataSetter();

    const [rimReportTableData, setRimReportTableData] = useState();
    const [originalRimReportTableData, setOriginalRimReportTableData] = useState();
    const [tableBeforeFilter, setTableBeforeFilter] = useState();
    const [showColumns, setShowColumns] = useState();
    const [filterApplied, setFilterApplied] = useState(false);
    const [displayColOptions, setDisplayColOptions] = useState();
    const [displayColumns, setDisplayColumns] = useState();
    const [showSpinner, setShowSpinner] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [printData, setPrintData] = useState(rimReportTableData);
    const [dateRangeType, setDateRangeType] = useState("AllTime");
    const [noData, setNoData] = useState(false);
    const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [dateRangeFilter, setDateRangeFilter] = useState({ fromDate: "", toDate: "", });
    const [filterFieldsApplied, setFilterFieldsApplied] = useState();
    const { ref, isComponentVisible, setIsComponentVisible } =
        useComponentVisible(false);
    const globalizationMapping = new Globalization();

    // filter options driver
    const [filterOptions, setFilterOptions] = useState({
        categories: [
            { name: "Manufacturer", value: "ManufacturerName" },
            { name: "Size", value: "SizeName" },
            { name: "Type", value: "TypeName" },
        ],
        categoryData: {
            ManufacturerName: [],
            SizeName: [],
            TypeName: [],
            dateRangeFromDate: '',
            dateRangeToDate: '',
          }
        });
    const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER

    const installNumber = "AllInstalls";
    const tireScrapType = "AllTire";
    const excludeUsedTires = false;


    useEffect(() => {
        rimReportResponseData(
            installNumber,
            tireScrapType,
            excludeUsedTires,
            filterApplied,
            filterFieldsApplied
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalAccountState, excludeUsedTires, filterApplied, filterFieldsApplied]);

    const rimReportResponseData = (
        installNumberValue,
        tireScrapTypeValue,
        excludeUsedTiresValue,
        filterAppliedValue,
        filterFieldsAppliedValue
    ) => {
        // FORMATTING THE GLOBAL ACCOUNT STATES
        const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
        const accountIds = accountInfoObject.accountIds;
        const locationIds = accountInfoObject.locationIds;
        let inServiceDateRangeType = "AllTime";


        const requestObject = {
            accountIds,
            locationIds,
            reportOption: "",
            reportName: "Rim_Register",
            dateRangeType: dateRangeType,
            InServiceDateRangeType: inServiceDateRangeType,
            fromDate: dateRangeFilter.fromDate,
            toDate: dateRangeFilter.toDate,
            installNo: installNumberValue,
            tireScrapType: tireScrapTypeValue,
            usedTires: excludeUsedTiresValue.toString(),
        };

        const filterOptionsLocal = {
            categories: [
                { name: globalizationMapping.REPORTTABLE.MANUFACTURER, value: "ManufacturerName" },
                { name: globalizationMapping.REPORTTABLE.SIZE, value: "SizeName" },
                { name: globalizationMapping.REPORTTABLE.TYPE, value: "TypeName" },
            ],
            categoryData: {
                ManufacturerName: [],
                SizeName: [],
                TypeName: [],
                dateRangeFromDate: '',
                dateRangeToDate: '',
            },
        };

        setShowSpinner(true);
        rimsService.getTiresReport(requestObject).then((data) => {
            const tiresInServiceTableInfo = rimReportDataSetter.setData(data);
            const filterOptionsInfo = rimReportDataSetter.setFilterOptionsData(filterOptionsLocal, tiresInServiceTableInfo.rowsData);
            const updatedHeadCells = rimReportDataSetter.updatedHeadCells();
            tiresInServiceTableInfo.rowsData.length === 0 ? setNoData(true) : setNoData(false);
            setRimReportTableData(tiresInServiceTableInfo);
            setOriginalRimReportTableData(tiresInServiceTableInfo);
            setTableBeforeFilter(tiresInServiceTableInfo); // news
            setFilterOptions(filterOptionsInfo);
            setDisplayColOptions(tiresInServiceTableInfo.headCells);
            setDisplayColumns(updatedHeadCells);
            setShowColumns(tiresInServiceTableInfo.selectedColShow);
            setPrintData({
                reportType: "Rim Reports : ",
                reportName: globalizationMapping.RIMREPORTS.RimReports.RimReport,
                rowsData: tiresInServiceTableInfo.rowsData,
                headCells: tiresInServiceTableInfo.headCells,
                avgValues: tiresInServiceTableInfo.avgValues,
                selectedColShow: tiresInServiceTableInfo.selectedColShow,
                filterApplied,
            });
            if (filterAppliedValue) {
                setFilterApplied(true);
                setSearchValue("");
                const originalTableDataStore = tiresInServiceTableInfo.rowsData;
                const filteredData = rimReportDataSetter.filterTable(filterFieldsAppliedValue, originalTableDataStore);
                setRimReportTableData({
                    ...tiresInServiceTableInfo,
                    rowsData: filteredData,
                    avgValues: rimReportDataSetter.avgValues(filteredData),
                });
                setOriginalRimReportTableData({
                    ...tiresInServiceTableInfo,
                    rowsData: filteredData,
                    avgValues: rimReportDataSetter.avgValues(filteredData),
                });
                setPrintData({
                    ...printData,
                    rowsData: filteredData,
                    avgValues: rimReportDataSetter.avgValues(filteredData),
                    filterFieldsApplied: filterFieldsAppliedValue,
                    filterApplied: true,
                });
                filteredData.length === 0 ? setNoData(true) : setNoData(false);
                (((Object.values(dateRangeFilter).every(x => x === ''))) && (Object.values(filterFieldsAppliedValue).every(x => x.length === 0))) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true)
            }
            setShowSpinner(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    // Handle modal pop ups for export
    const [modalHandler, setModalHandler] = React.useState({
        handlePdf: false,
        handleEmail: false,
        handlePrint: false,
    });

    const onClickExport = (type) => {
        if (type === "pdf") {
            let checkSortApplicable = document.querySelector("th[aria-sort] span");
            if (checkSortApplicable) {
                checkSortApplicable = checkSortApplicable.textContent;
                const data = commonUtils.exportTableSort(printData,
                    checkSortApplicable.split("sorted ")[0],
                    checkSortApplicable.split("sorted ")[1]
                );
                setPrintData(data);
                setModalHandler({ handlePdf: true });
            } else {
                setModalHandler({ handlePdf: true });
            }
        }
    };

    const onClickPrint = () => {
        let checkSortApplicable = document.querySelector("th[aria-sort] span");

        if (checkSortApplicable) {
            checkSortApplicable = checkSortApplicable.textContent;
            const data = commonUtils.exportTableSort(
                printData,
                checkSortApplicable.split("sorted ")[0],
                checkSortApplicable.split("sorted ")[1]
            );
            setPrintData(data);
            setModalHandler({ handlePrint: true });
        } else {
            setModalHandler({ handlePrint: true });
        }
    };

    const onClickEmail = () => {
        let checkSortApplicable = document.querySelector("th[aria-sort] span");
        if (checkSortApplicable) {
            checkSortApplicable = checkSortApplicable.textContent;
            const data = commonUtils.exportTableSort(
                printData,
                checkSortApplicable.split("sorted ")[0],
                checkSortApplicable.split("sorted ")[1]
            );
            setPrintData(data);
            setModalHandler({ handleEmail: true });
        } else {
            setModalHandler({ handleEmail: true });
        }
    };

    const onClickFilter = () => {
        setShowFilter(true);
    };

    // USED FOR SEARCHING THE TABLES
    const searchTableData = (event) => {
        setSearchValue(event.target.value);
        const searchText = event.target.value.toLowerCase();
        const tableDataStore = originalRimReportTableData?.rowsData;
        const filteredData = rimReportDataSetter.search(tableDataStore, searchText);

        setRimReportTableData({
            ...rimReportTableData,
            rowsData: filteredData,
            avgValues: rimReportDataSetter.avgValues(filteredData),
        });
        setPrintData({
            ...printData,
            reportType: "Rim Reports : ",
            reportName: globalizationMapping.RIMREPORTS.RimReports.RimReport,
            rowsData: filteredData,
            avgValues: rimReportDataSetter.avgValues(filteredData),
            filterApplied,
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
    };

    // USED FOR CHANGING DISPLAY COLUMNS
    const changeDisplayColumns = (selected) => {
        const selectedColumns = selected;
        setDisplayColumns(selectedColumns);

        const currentHeadCells = originalRimReportTableData?.headCells;
        const showColumnsUpdated = rimReportDataSetter.setDisplayColumnData(
            currentHeadCells,
            selected
        );
        setShowColumns(showColumnsUpdated);
        if (selected.length === displayColOptions?.length) {
            setPrintData({
                ...printData,
                selectedColShow: showColumnsUpdated,
                filterApplied: false,
            });
        } else {
            setPrintData({
                ...printData,
                selectedColShow: showColumnsUpdated,
                filterApplied: true,
            });
        }
        selectedColumns.length === 0 ? setNoData(true) : setNoData(false);
    };


    // RESET FILTER
    const resetTableFilter = () => {
        setCurrentFilter( {
            ManufacturerName: [],
            SizeName: [],
            TypeName: [],
            dateRangeFromDate: '',
            dateRangeToDate: '',
        });

        setFilterApplied(false);
        setRimReportTableData(tableBeforeFilter);
        setOriginalRimReportTableData(tableBeforeFilter);
        setPrintData({
            ...printData,
            ...tableBeforeFilter,
            filterApplied: false,
            filterFieldsApplied: {},
        }); 
        setNoData(false);
        setSearchValue("");
        setOnlyFilterApplied(false);
    };

    //APPLY DATE FILTER
    const confirmApplyDateFilter = (appliedFilter) => {
        let toSetCurrentFilter = 
        { ...appliedFilter,
          dateRangeFromDate: dateRangeFilter.fromDate,
          dateRangeToDate: dateRangeFilter.toDate,
       };
    
      setCurrentFilter(toSetCurrentFilter);
      if (appliedFilter === filterOptions.categoryData) {
        setFilterApplied(false);
      } else {setFilterApplied(true)}
    

        setSearchValue("");
        const originalTableDataStore = originalRimReportTableData?.rowsData;
        const filteredData = rimReportDataSetter.filterTable(appliedFilter, originalTableDataStore);
        setRimReportTableData({
            ...rimReportTableData,
            rowsData: filteredData,
            avgValues: rimReportDataSetter.avgValues(filteredData),
        });
        setPrintData({
            ...printData,
            rowsData: filteredData,
            avgValues: rimReportDataSetter.avgValues(filteredData),
        });
        filteredData.length === 0 ? setNoData(true) : setNoData(false);
        setOnlyFilterApplied(true);
        setFilterFieldsApplied(appliedFilter);
        rimReportResponseData(installNumber, tireScrapType, excludeUsedTires, true, appliedFilter);
    };


    return (
        <div className="container">
            <RimReportsNavigation />
            {showSpinner && <Spinner />}
            {!showSpinner && (
                <div className="tire-installation-container">
                    <Card className="tire-installation-card">
                        <CardContent>
                            <div className="table-toolbar">
                                <div className="table-filters">
                                    <div className="search-table-container">
                                        <form
                                            className="search-table-container"
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <InputLabel className="filter-table-label">
                                                <span className="table-toolbar-label-text">
                                                    {globalizationMapping.REPORTCOMMONWORDS.SearchTable}
                                                </span>
                                            </InputLabel>
                                            <TextField
                                                className="search-table-bar"
                                                id="input-with-icon-textfield"
                                                variant="outlined"
                                                placeholder={
                                                    globalizationMapping.REPORTCOMMONWORDS.Search
                                                }
                                                value={searchValue}
                                                onChange={searchTableData}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <img
                                                                className="keyword-search-icon-img"
                                                                alt=""
                                                                src={SearchIcon}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{ maxLength: 50 }}
                                            />
                                        </form>
                                    </div>
                                    <div
                                        className="display-columns-container"
                                        id="dashboard-display-column"
                                    >
                                        <InputLabel className="filter-table-label">
                                            <span className="table-toolbar-label-text">
                                                {globalizationMapping.REPORTCOMMONWORDS.DisplayColumns}
                                            </span>
                                        </InputLabel>
                                        <MultiSelect
                                            options={displayColOptions}
                                            value={displayColumns}
                                            onChange={changeDisplayColumns}
                                            overrideStrings={{
                                                selectSomeItems:
                                                    globalizationMapping.REPORTCOMMONWORDS.SELECT,
                                                allItemsAreSelected:
                                                    globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                                                selectAll:
                                                    globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                                                search: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                                            }}
                                        />
                                    </div>
                                </div>
                                <FilterPrintEmailExportSetButtons
                                    onClickFilter={onClickFilter}
                                    onClickEmail={onClickEmail}
                                    onClickPrint={onClickPrint}
                                    onClickExport={onClickExport}
                                    toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)} // setIsComponentVisible is used if not in the HEADER
                                    printData={printData}
                                    ref={ref}
                                    onlyFilterApplied={onlyFilterApplied}
                                    noData={noData}
                                    isComponentVisible={isComponentVisible}
                                />
                            </div>
                            <RimReportTable
                                tableData={rimReportTableData}
                                showColumns={showColumns}
                                filterApplied={filterApplied}
                            />
                        </CardContent>
                    </Card>
                </div>
            )}
            <PrintExportModal
                modalHandler={modalHandler}
                setModalHandler={setModalHandler}
                tableData={printData}
                noGraph={true}
            />
            <PdfExportModal
                modalHandler={modalHandler}
                setModalHandler={setModalHandler}
                tableData={printData}
                noGraph={true}
            />
            <EmailModalComponent
                modalHandler={modalHandler}
                setModalHandler={setModalHandler}
                tableData={printData}
                noGraph={true}
            />
        <RimReportFilter 
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            filterOptions={filterOptions}
            ApplyFilter={confirmApplyDateFilter}
            currentFilter={currentFilter}
            resetTableFilter={resetTableFilter}
            dateRangeFilter={dateRangeFilter}
            setDateRangeFilter={setDateRangeFilter}
            setDateRangeType={setDateRangeType}
        />
        </div>
    );
};

export default RimReport;
