/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import "./ManageTireList.css";
import "../../../../../common-styles/TableFunctionOptions.css";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ManageTireListApis from "../../../services/ManageTireListApis";
import { StateContext } from "../../../../../services/StateProvider";
import Spinner from "../../../../../common-components/spinner/spinner";
import ManageTireListTable from "../table/ManageTireListTable";
import ManageTireListSetter from "../../../services/ManageTireListUtils";
import CommonUtils from "../../../../../services/utils";
import SearchIcon from "../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import ManageTiresNavigation from "../../../manage-tires-navigation/components/ManageTiresNavigation";
import TransferTireModal from "../../modals/transfer-tire/transfer-tire-modal";
import DeleteTireModal from "../../modals/delete-tire/delete-tire-modal";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import FlashMessage from "../../../../../common-components/pop-up-notification/notification-snackbar";
import { ManageTiresPaths } from "../../../../../routing/ManageTiresPaths";
import Globalization from "../../../../../globalization";
import { useComponentVisible } from "../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ManageTireListFilter from '../filter/ManageTireListFilter'

const ManageTireList = () => {
  const commonUtils = new CommonUtils();
  const manageTireListService = new ManageTireListApis();
  const headerApiObj = new HeaderApi();
  const locationObj = useLocation();
  const [globalAccountState] = useContext(StateContext);
  const manageTireListDataSetter = new ManageTireListSetter(globalAccountState.accountInfo.userSetting.userMeasurementSystemValue);

  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [manageTireListTableData, setManageTireListTableData] = useState();
  const [originalManageTireListTableData, setOriginalManageTireListTableData] = useState();
  const [manageTireTableDataByLocation, setManageTireTableDataByLocation] = useState();
  const [manageTireTableDataTabWise, setManageTireTableDataTabWise] = useState();
  const [responseData, setResponseData] = useState([]);
  const [tableCurrentRowsData, setTableCurrentRowsData] = useState();
  const [printData, setPrintData] = useState(manageTireListTableData);
  const [noData, setNoData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [action, setAction] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [masterData, setMasterData] = useState({});
  const [currentRoute, setCurrentRoute] = useState({});
  const [isTransferModalOpen, setTransferModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [flashMessageState, setFlashMessageState] = useState(locationObj);
  const [modalActionDone, setModalActionDone] = useState(commonUtils.generateRandomNumber());
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "VehicleId", value: "VehicleNumber" },
      { name: "Location", value: "Location" },
      { name: "Status", value: "Status" },
      { name: "Manufacturer", value: "Make" },
      { name: "Size", value: "Size" },
      { name: "Type", value: "Type" },
      { name: "Compound Name", value: "CompoundName" },
      { name: "Rating", value: "RatingName" },
    ],
    categoryData: {
      VehicleNumber: [],
      Location: [],
      Status: [],
      Make: [],
      Size: [],
      Type: [],
      CompoundName: [],
      RatingName: [],
    },
  });
  const [forceActionPopupClose, setForceActionPopupClose] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER
  const [newRouteClicked, setNewRouteClicked] = useState(false);

  const multipleRowsSelected = (rows) => {
    setAction([...rows]);
  };


  useEffect(() => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;

    manageTireListService
      .getLocationListByAccount(accountIds[0])
      .then((data) => {
        if (data.model !== undefined) setLocationList(data.model);
      });
    headerApiObj.getMasterDataWeb().then((data) => {
      if (data.model) setMasterData(data.model);
    });
    InitNotification();
  }, [globalAccountState]);

  useEffect(() => {
    ManageTireListResponseData();

    setTimeout(() => {
      window.localStorage.setItem("Count", 0)
    }, 3000);

  }, [globalAccountState, modalActionDone]);



  const ManageTireListResponseData = () => {
    let unitPreference =
      globalAccountState.accountInfo.userSetting.userMeasurementSystemValue;
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    let filterOptions = {
      categories: [
        { name: globalizationMapping.MANAGETIRE.VEHICLID, value: "VehicleNumber"},
        { name: globalizationMapping.MANAGETIRE.LOCATION, value: "Location" },
        { name: globalizationMapping.MANAGETIRE.STATUS, value: "Status" },
        { name: globalizationMapping.MANAGETIRE.MAKE, value: "Make" },
        { name: globalizationMapping.MANAGETIRE.SIZE, value: "Size" },
        { name: globalizationMapping.MANAGETIRE.TYPE, value: "Type" },
        { name: globalizationMapping.MANAGETIRE.COMPOUNDNAME, value: "CompoundName"},
        { name: "Rating", value: "RatingName" },
      ],
      categoryData: {
        VehicleNumber: [],
        Location: [],
        Status: [],
        Make: [],
        Size: [],
        Type: [],
        CompoundName: [],
        RatingName: [],
      },
    };
    setShowSpinner(true);
    manageTireListService.manageTiresByAccount(accountIds[0]).then((data) => {
      setResponseData(data.model);
      let manageTireListTableInfo = manageTireListDataSetter.setData(
        data,
        unitPreference
      );
      let filterOptionsInfo = manageTireListDataSetter.setFilterOptionsData(
        filterOptions,
        manageTireListTableInfo.rowsData
      );
      setOriginalManageTireListTableData(manageTireListTableInfo);
      manageTireListTableInfo.rowsData.length === 0
        ? setNoData(true)
        : setNoData(false);
      setFilterOptions(filterOptionsInfo);
      setShowColumns(manageTireListTableInfo.selectedColShow);

      let manageTireInitialTableInfo;
      let manageTireRowsDataByLocation;
      let manageTireSelectedPath;
      if (locationObj && locationObj.state && locationObj.state.dispositionId) {
        manageTireInitialTableInfo = manageTireListDataSetter.setFilteredData(
          data.model,
          locationObj.state.dispositionId,
          unitPreference
        );
        manageTireRowsDataByLocation =
          manageTireListDataSetter.getFilteredDataByLocation(
            data.model,
            locationIds,
            locationObj.state.dispositionId
          );
        manageTireSelectedPath = ManageTiresPaths.find(
          (path) => path.dispositionId === locationObj.state.dispositionId
        );
        setCurrentRoute(manageTireSelectedPath);
      } else {
        manageTireInitialTableInfo = manageTireListDataSetter.setFilteredData(
          data.model,
          null,
          unitPreference
        );
        manageTireRowsDataByLocation =
          manageTireListDataSetter.getFilteredDataByLocation(
            data.model,
            locationIds,
            null
          );
        manageTireSelectedPath = ManageTiresPaths.find(
          (path) => path.dispositionId === null
        );
        setCurrentRoute(manageTireSelectedPath);
      }
      let tireDataToShow = { ...manageTireInitialTableInfo };
      tireDataToShow.rowsData = [...manageTireRowsDataByLocation];
      setManageTireTableDataByLocation(tireDataToShow);
      setManageTireTableDataTabWise(manageTireInitialTableInfo);
      setTableCurrentRowsData(manageTireRowsDataByLocation);
      setManageTireListTableData(tireDataToShow);
      setShowColumns(manageTireInitialTableInfo.selectedColShow);
      setPrintData({
        reportType: "",
        reportName: globalizationMapping.MANAGETIREREPORTS.ManageTireAllTyre,
        rowsData: tireDataToShow.rowsData,
        headCells: manageTireInitialTableInfo.headCells,
        selectedColShow: manageTireInitialTableInfo.selectedColShow,
      });
      setShowSpinner(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //initialising notifications
  const InitNotification = () => {
    if (locationObj.state && locationObj.state.updatedSection) {
      switch (locationObj.state.updatedSection) {
        case "createnewtire":
        case "editoldtire":
        case "deletetire":
        case "inspecttire":
        case "removeinstalledtire":
        case "changetiredisposition":
          if (locationObj.state.updateStatus) {
            setShowNotification(true);
          }
          break;
        default:
          setTimeout(() => {
            setShowNotification(false);
          }, 5000);
          break;
      }
      setFlashMessageState(locationObj);
    }
  };

  const [compareTransfer, setCompareTransfer] = useState({})
  const initModalNotifications = (notificationState) => {
    switch (notificationState.state.updatedSection) {
      case "tranfertire":
      case "deletetire":
      case "changetiredisposition":
        if (notificationState.state.updateStatus) {
          setShowNotification(true);
        }
        break;

      default:
        setTimeout(() => {
          setShowNotification(false);
        }, 5000);
        break;
    }
    if (notificationState.state.updateStatus) {
      setModalActionDone(commonUtils.generateRandomNumber());
      setFlashMessageState(notificationState);
      setCompareTransfer(notificationState)
      setTimeout(() => {
        setShowNotification(false);
        setFlashMessageState({});
      }, 5000);
      setAction([]);
      window.scrollTo(0, 0);
    }
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false,
  });

  const onClickExport = (type) => {
    setForceActionPopupClose(true);
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]
        );
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    setForceActionPopupClose(true);
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);

      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    setForceActionPopupClose(true);
    setModalHandler({ handleEmail: true });
  };

  const onClickFilter = () => {
    setForceActionPopupClose(true);
    setShowFilter(true);
  };

  const forceActionPopupCloseToFalse = () => {
    setForceActionPopupClose(false);
  }

  // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    setCurrentFilter(appliedFilter)
    setSearchValue("");
    setFilterApplied(true);
    let originalTableDataStore;
    if (appliedFilter?.Location?.length == 0) {
      originalTableDataStore = [...tableCurrentRowsData];
    } else {
      originalTableDataStore = manageTireTableDataTabWise?.rowsData;
    }
    let filteredData = manageTireListDataSetter.filterTable(
      appliedFilter,
      originalTableDataStore
    );
    setManageTireListTableData({
      ...manageTireListTableData,
      rowsData: filteredData,
    });
    setPrintData({ ...printData, rowsData: filteredData });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setTableCurrentRowsData(filteredData);
    Object.values(appliedFilter).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter({
      VehicleNumber: [],
      Location: [],
      Status: [],
      Make: [],
      Size: [],
      Type: [],
      CompoundName: [],
      RatingName: []})
    setSearchValue("");
    setFilterApplied(false);
    setPrintData({
      ...printData,
      rowsData: manageTireTableDataByLocation?.rowsData,
    });
    setManageTireListTableData(manageTireTableDataByLocation);
    setTableCurrentRowsData(manageTireTableDataByLocation?.rowsData);
    setNoData(false);
    setOnlyFilterApplied(false);
  };
  // USED FOR SEARCHING THE TABLES
  const searchTableData = (searchVal) => {
    setForceActionPopupClose(true);
    setSearchValue(searchVal);
    let searchText = searchVal.toLowerCase();
    searchText === "" ? setFilterApplied(false) : setFilterApplied(true);
    let originalTableDataStore, filteredData;
    let updatedTableData = {};
    originalTableDataStore = [...tableCurrentRowsData];


    filteredData = manageTireListDataSetter.search(
      originalTableDataStore,
      searchText
    );
    updatedTableData = {
      rowsData: filteredData,
      headCells: originalManageTireListTableData?.headCells,
      selectedColShow: originalManageTireListTableData?.selectedColShow,
    };
    setManageTireListTableData(updatedTableData);
    setPrintData({
      reportType: "",
      reportName: globalizationMapping.MANAGETIRE.InstalledTires,
      rowsData: filteredData,
      headCells: originalManageTireListTableData?.headCells,
      selectedColShow: originalManageTireListTableData?.selectedColShow,
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };


  const changedRoute = (selectedRoute) => {
    setForceActionPopupClose(true);
    if (selectedRoute.path === currentRoute.path) return;
    if (currentRoute.path && selectedRoute.path !== currentRoute.path)
      resetTableFilter();
    let unitPreference =
      globalAccountState.accountInfo.userSetting.userMeasurementSystemValue;
    let accObj = accountInfoIdsFormatter(globalAccountState);
    let locationIds = accObj.locationIds;
    setCurrentRoute(selectedRoute);
    let manageTireListTableInfo = manageTireListDataSetter.setFilteredData(
      responseData,
      selectedRoute.dispositionId,
      unitPreference
    );
    let manageTireRowsDataByLocation =
      manageTireListDataSetter.getFilteredDataByLocation(
        responseData,
        locationIds,
        selectedRoute.dispositionId
      );
    let tireDataToShow = { ...manageTireListTableInfo };
    tireDataToShow.rowsData = [...manageTireRowsDataByLocation];
    setManageTireTableDataTabWise(manageTireListTableInfo);
    setManageTireTableDataByLocation(tireDataToShow);
    setManageTireListTableData(tireDataToShow);
    setTableCurrentRowsData(manageTireRowsDataByLocation);
    setShowColumns(manageTireListTableInfo.selectedColShow);

    const filters = {
      categories: [
        { name: globalizationMapping.MANAGETIRE.VEHICLID, value: "VehicleNumber"},
        { name: globalizationMapping.MANAGETIRE.LOCATION, value: "Location" },
        { name: globalizationMapping.MANAGETIRE.STATUS, value: "Status" },
        { name: globalizationMapping.MANAGETIRE.MAKE, value: "Make" },
        { name: globalizationMapping.MANAGETIRE.SIZE, value: "Size" },
        { name: globalizationMapping.MANAGETIRE.TYPE, value: "Type" },
        { name: globalizationMapping.MANAGETIRE.COMPOUNDNAME, value: "CompoundName"},
        { name: "Rating", value: "RatingName" },
      ],
      categoryData: {
        VehicleNumber: [],
        Location: [],
        Status: [],
        Make: [],
        Size: [],
        Type: [],
        CompoundName: [],
        RatingName: [],
      },
    };

    let filterOptionsInfo = manageTireListDataSetter.setFilterOptionsData(
      filters,
      tireDataToShow.rowsData
    );
    setFilterOptions(filterOptionsInfo);
    let selectedTab = '';
    if (selectedRoute.name === "Inventory") {
      selectedTab =
        globalizationMapping.MANAGETIREREPORTS.ManageTireInventoryTyre;
    } else if (selectedRoute.name === "Installed") {
      selectedTab =
        globalizationMapping.MANAGETIREREPORTS.ManageTireInstalledTyre;
    } else if (selectedRoute.name === "On Hold") {
      selectedTab = globalizationMapping.MANAGETIREREPORTS.ManageTireOnHoldTyre;
    } else if (selectedRoute.name === "Scrap") {
      selectedTab = globalizationMapping.MANAGETIREREPORTS.ManageTireScrapTyre;
    } else if (selectedRoute.name === "Repair") {
      selectedTab = globalizationMapping.MANAGETIREREPORTS.ManageTireRepairTyre;
    } else if (selectedRoute.name === "Retread") {
      selectedTab =
        globalizationMapping.MANAGETIREREPORTS.ManageTireRetreadTyre;
    } else {
      selectedTab = globalizationMapping.MANAGETIREREPORTS.ManageTireAllTyre;
    }
    setPrintData({
      reportType: "",
      reportName: selectedTab,
      rowsData: tireDataToShow.rowsData,
      headCells: manageTireListTableInfo.headCells,
      selectedColShow: manageTireListTableInfo.selectedColShow,
    });
    setNewRouteClicked(!newRouteClicked);
  };

  // eslint-disable-next-line no-unused-vars
  const onTransferActionClick = () => {
    setTransferModalOpen(true);
  };

  const globalizationMapping = new Globalization();
  const handleTransferModalClose = (transferNotificationData) => {
    setTransferModalOpen(false);
    initModalNotifications({ state: transferNotificationData });
  };

  const onDeleteActionClick = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = (transferNotificationData) => {
    setDeleteModalOpen(false);
    initModalNotifications({ state: transferNotificationData });
  };


  const actionChanged = (value) => {
    if (value === "Transfer") {
      onTransferActionClick();
    } else if (value === "Delete") {
      onDeleteActionClick();
    } else {
      return null;
    }
  };

  const toggleExportDropdown = () => {
    setForceActionPopupClose(true);
    setIsComponentVisible(!isComponentVisible);
  }

  return (
    <div className="container">
      <ManageTiresNavigation
        changedRoute={changedRoute}
        modalActionTracker={modalActionDone}
        globalAccountState={globalAccountState}
      />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="manage-tire-list-container">
          <Card className="manage-tire-list-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="manage-tire-search-table-container">
                    <form
                      className="manage-tire-search-table-container"
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span
                          className={
                            action.length > 1
                              ? "enable-table-toolbar-label-text"
                              : "disabled-table-toolbar-label-text"
                          }
                        >
                          {`${action.length} ${globalizationMapping.ADMIN.RowsSelected}`}
                        </span>
                      </InputLabel>
                      <Select
                        native
                        placeholder="Actions"
                        defaultValue="Actions"
                        className={
                          action.length > 1
                            ? "enable-manage-vehicle-action-dropdown"
                            : "disabled-manage-vehicle-action-dropdown"
                        }
                        variant="outlined"
                        IconComponent={ExpandMoreStyledIcon}
                        onChange={(e) => actionChanged(e.target.value)}
                        disabled={action.length <= 1}
                      >
                        <option value="Actions" disabled> Actions </option>
                        {currentRoute.path !== "manage-tire" && (
                          <>
                            <option value={"Transfer"}> {globalizationMapping.MANAGE_PARENT_ACCOUNT.OPTION2} </option>
                            {currentRoute.path !== "manageTireonHold" && (
                              <option value={"Delete"}> {globalizationMapping.MANAGE_PARENT_ACCOUNT.OPTION3} </option>
                            )}
                          </>
                        )}
                      </Select>
                    </form>
                  </div>

                  <div className="manage-tire-search-table-container">
                    <form
                      className="manage-tire-search-table-container"
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {globalizationMapping.REPORTCOMMONWORDS.SearchTable}
                        </span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={globalizationMapping.PLACEHOLDER.SEARCH}
                        value={searchValue}
                        onChange={(e) => searchTableData(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="keyword-search-icon-img"
                                alt=""
                                src={SearchIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                </div>

                <FilterPrintEmailExportSetButtons
                  onClickFilter={onClickFilter}
                  onClickEmail={onClickEmail}
                  onClickPrint={onClickPrint}
                  onClickExport={onClickExport}
                  toggleExportDropdown={toggleExportDropdown}
                  printData={printData}
                  ref={ref}
                  onlyFilterApplied={onlyFilterApplied}
                  noData={noData}
                  isComponentVisible={isComponentVisible}
                />
              </div>
              <div className="data-summary-table">
                {manageTireListTableData && (
                  <ManageTireListTable
                    tableData={manageTireListTableData}
                    showColumns={showColumns}
                    filterApplied={filterApplied}
                    multipleRowsSelected={multipleRowsSelected}
                    locationList={locationList}
                    masterData={masterData}
                    currentRoute={currentRoute}
                    initModalNotifs={initModalNotifications}
                    forceActionPopupClose={forceActionPopupClose}
                    forceActionPopupCloseToFalse={forceActionPopupCloseToFalse}
                  />
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {
        compareTransfer?.state?.updatedSection !== undefined ?
          (compareTransfer.state.updatedSection == "tranfertire" || compareTransfer.state.updatedSection == "deletetire" || compareTransfer.state.updatedSection == "changetiredisposition") &&
          showNotification && flashMessageState.state && (

            < FlashMessage
              status={flashMessageState.state.updateStatus}
              message={flashMessageState.state.successMessage}
              description={flashMessageState.state.successMessageDesc}
            />
          ) :
          showNotification && flashMessageState.state && Number(window.localStorage.getItem("Count")) > 0 && (
            <FlashMessage
              status={flashMessageState.state.updateStatus}
              message={flashMessageState.state.successMessage}
              description={flashMessageState.state.successMessageDesc}
            />
          )
      }
      {isTransferModalOpen && (
        <TransferTireModal
          closeTransferModal={handleTransferModalClose}
          locationList={locationList}
          tireIds={action}
          isSelectionMultiple={true}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteTireModal
          closeDeleteModal={handleDeleteModalClose}
          tireIds={action}
          isSelectionMultiple={true}
        />
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <EmailExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <ManageTireListFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
        newRouteClicked={newRouteClicked}
      ></ManageTireListFilter>

    </div>
  );
};
export default ManageTireList;
