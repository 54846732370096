import CommonService from '../../../services/common-api-service';
import { SERVER } from '../../../services/api-urls';


export default class QuickInspectListApis {
    cs;
    constructor() {
        this.cs = new CommonService();
    }
    getInstalledItemsOnVehiclePerPos = VehicleId => this.cs.httpConnection(`${SERVER.SERVICES.getInstalledItemsOnVehiclePerPos}${VehicleId}`, 'get', {});
    getVehicleDetails = VehicleId => this.cs.httpConnection(`${SERVER.SERVICES.getVehicleDetails}${VehicleId}`, 'get', {});
    getInspectionSummaryForVehicle = VehicleId => this.cs.httpConnection(`${SERVER.SERVICES.getInspectionSummaryForVehicle}${VehicleId}`, 'get', {});
    manageVehicleGetDetailsByAccount = parentAccountId => this.cs.httpConnection(`${SERVER.SERVICES.manageVehicleGetDetailsByAccount}${parentAccountId}`, 'get', {});
    getInspectionRecordForVehicle = VehicleId => this.cs.httpConnection(`${SERVER.SERVICES.getInspectionRecordForVehicle}${VehicleId}`, 'get', {});
    getPSAlertByVehicleId = VehicleId => this.cs.httpConnection(`${SERVER.SERVICES.getPSAlertByVehicleId}${VehicleId}`, 'get', {});
}
