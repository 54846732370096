import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";
class ManageLocationListSetter {
  constructor() {
    this.data = {};
    this.manageLocationListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item, _index) => {
      return (
        item.LocationName.toLowerCase().includes(searchText) ||
        item.AccountName.toLowerCase().includes(searchText) ||
        item.Country.toLowerCase().includes(searchText) ||
        item.State.toLowerCase().includes(searchText) ||
        item.City.toLowerCase().includes(searchText) ||
        item.ContactPerson.toLowerCase().includes(searchText) ||
        item.EmailAddress.toLowerCase().includes(searchText) ||
        item.PhoneNumber.toString().includes(searchText) ||
        item.LocationId.toString().includes(searchText)
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, _index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data) {
    // TABLE ROWS DATA
    const locationList = data.model;
    locationList.forEach((location, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */

      this.manageLocationListData.push({
        Key: index,
        AccountName: location.accountName ? location.accountName : 0,
        Country: location.country ? location.country : "",
        State: location.state ? location.state : "",
        City: location.city ? location.city : "",
        ContactPerson: location.contactPerson ? location.contactPerson : "",
        EmailAddress: location.emailAddress ? location.emailAddress : "",
        LocationId: location.locationId ? location.locationId : "",
        LocationName: location.locationName ? location.locationName : "",
        PhoneNumber: location.phoneNumber ? location.phoneNumber : 0,
      });
    });
    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.ManageLocation;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.manageLocationListData,
      headCells: this.headCells,
      selectedColShow: selectedColShow,
    };
  }

  setDataByLocation(data, accountId) {
    // TABLE ROWS DATA
    const locationList = data.model;
    let accountData = [];
    locationList.forEach((location, index) => {
      if (location.parentAccountId === accountId) {
        accountData.push({
          Key: index,
          AccountName: location.accountName ? location.accountName : 0,
          Country: location.country ? location.country : "",
          State: location.state ? location.state : "",
          City: location.city ? location.city : "",
          ContactPerson: location.contactPerson ? location.contactPerson : "",
          EmailAddress: location.emailAddress ? location.emailAddress : "",
          LocationId: location.locationId ? location.locationId : "",
          LocationName: location.locationName ? location.locationName : "",
          PhoneNumber: location.phoneNumber ? location.phoneNumber : 0,
        });
      }
    });
    return accountData;
  }
}

export default ManageLocationListSetter;
