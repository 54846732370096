import { FormHelperText, Grid, Select, TextField, makeStyles } from '@material-ui/core';
import React from 'react';
import "./create-vehicle.css";
import ExpandMoreStyledIcon from '../../common-components/expandMoreStyledIcon/expandMoreStyledIcon';
import CommonUtils from '../../services/utils';

const Styles = makeStyles({
    root: {
        "& input.MuiAutocomplete-inputFocused": {
            color: "#000000 !important",
            fontWeight: "bold"
        }
    },
    FormHelperText: {
        align: 'center'
    }
});


const OperationSpecifications = (props) => {

    const classes = Styles();
    const siteSeverityIndex = props.masterDataSet.siteSeverityIndexes;
    const commonUtils = new CommonUtils();
    return (
        <React.Fragment>
            <Grid className="step-form-inner-container" container spacing={2} >
                <Grid item sm={12} >
                    <p className="field-label-vehicle">{props.fieldNames.SITESEVERITYINDEX} <span className="required-mark">*</span></p>
                    <Select
                        required
                        native
                        IconComponent={ExpandMoreStyledIcon}
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.siteSeverityIndex}
                        onChange={(e) => props.onChangeListener('siteseverityindex', e.target.value)}
                    >
                        <option key={0} value={""} hidden> {` ${props.fieldNames.ENTER} ${props.fieldNames.SITESEVERITYINDEX}`} </option>
                        {siteSeverityIndex.map((index, key) =>
                            <option key={key + 1} value={index.siteSeverityIndex} className="list-color-change">
                                {index.siteSeverityIndexName}
                            </option>
                        )}
                    </Select>
                    <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.siteSeverityIndex}</FormHelperText>
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label-vehicle">{props.fieldNames.COMMENTS} </p>
                    <TextField
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.severityComments}
                        onChange={(e) => props.onChangeListener('severityComments', commonUtils.removeLeadingSpaces(e.target.value))}
                        placeholder={`${props.fieldNames.ENTER} ${props.fieldNames.COMMENTS}`}
                        inputProps={{ maxLength: 200 }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
export default OperationSpecifications;
