import React, { useEffect, useState, useContext } from "react";
import { StateContext } from "../../../../services/StateProvider";
import {
  accountInfoIdsFormatter,
  getAccountData,
  setDashboardGraphLocationData,
  getDashboardGraphLocationData,
} from "../../../../services/AccountInfoHandler";
import "./graph-table.css";
import "../../../../common-styles/TableFunctionOptions.css";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import TireBelowInflationTable from "../tire-below-inflation/table/custom-table";
import TireWithLowTreadDepthTable from "../tire-with-low-tread-depth/table/custom-table";
import TireConditionTable from "../tire-condition/table/custom-table";
import TireBelowInflationGraph from "../tire-below-inflation/graph/custom-graph";
import TireWithLowTreadDepthGraph from "../tire-with-low-tread-depth/graph/custom-graph";
import TireConditionGraph from "../tire-condition/graph/custom-graph";
import PdfExportModal from "../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import PrintExportModal from "../../../../common-components/modal-components/print-modal/PrintExportModal";
import EmailModalComponent from "../../../../common-components/modal-components/email-modal/EmailModalComponent";
import ReportDashboardService from "../../services/report-dashboard-apis";
import TireBelowInflationDataSetter from "../../services/tire-below-inlflation-utils";
import TireWithLowTreadDepthDataSetter from "../../services/tire-with-low-tread-depth-utils";
import TireConditionDataSetter from "../../services/tire-condition-utils";
import CommonUtils from "../../../../services/utils";
import MultiSelect from "react-multi-select-component";
import Globalization from "../../../../globalization";
import { useComponentVisible } from "../../../../services/CustomHooks";
import PrintEmailExportSetButtons from "../../../../common-components/filterPrintEmailExport-SetButtons/PrintEmailExportSetButton"


const GraphTable = (props) => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const [noData, setNoData] = useState(false);

  // react hooks to store table data
  const [tableData, setTableData] = useState({
    tireBelowInflationTableData: {
      rowsData: [],
      headCells: [],
    },
    tireWithLowTreadDepthTableData: {
      rowsData: [],
      headCells: [],
    },
    tireConditionTableData: {
      rowsData: [],
      headCells: [],
    },
  });

  const [printData, setPrintData] = useState(
    tableData.tireBelowInflationTableData
  );

  const [originalTableData, setOriginalTableData] = useState({
    tireBelowInflationTableData: {
      rowsData: [],
      headCells: [],
    },
    tireWithLowTreadDepthTableData: {
      rowsData: [],
      headCells: [],
    },
    tireConditionTableData: {
      rowsData: [],
      headCells: [],
    },
  });
  //show/hide columns react hook
  const [showColumns, setShowColumns] = useState();
  //display columns selected react hook
  const [displayColumns, setDisplayColumns] = useState([]);
  // coulmns list to display
  const [displayColOptions, setDisplayColOptions] = useState([]);
  // show/hide spinner vaiable
  const [showSpinner, setShowSpinner] = useState(false);
  const [byTireInflationGraphData, setByTireInflationGraphData] = useState();
  const [tireWithLowTreadDepthGraphData, setTireWithLowTreadDepthGraphData] = useState();
  // used for manipulation of location data underneath the graphs
  const [locationsData, setLocationsData] = useState();
  const [graphLocationIds, setGraphLocationIds] = useState();
  // Utils service for data manipulation
  const tireBelowInflationDataSetter = new TireBelowInflationDataSetter();
  const tireWithLowTreadDepthDataSetter = new TireWithLowTreadDepthDataSetter();
  const tireConditionDataSetter = new TireConditionDataSetter();
  const commonUtils = new CommonUtils();
  const globalizationMapping = new Globalization();
  const commonWordsTexts = globalizationMapping.REPORTCOMMONWORDS;
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  useEffect(() => {
    initLocationsDataSetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedNav.value, globalAccountState]);

  useEffect(() => {
    const reportDashboardService = new ReportDashboardService();
    const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    const accountIds = accountInfoObject.accountIds;
    const locationIds = accountInfoObject.locationIds;
    updateLocationsDataSetter();
    const graphLocationIdsLocal = dashboardGraphLocationIds();

    let requestObject = {};
    let updatedHeadCells;

    switch (props.selectedNav.value) {
      case "tiresBelowInflationCount":
        requestObject = {
          accountIds,
          locationIds,
          reportName: "Tire_Inflation",
        };
        const requestObjectTireBelowInflation = {
          accountIds,
          dateRangeType: "AllTime",
          fromDate: "",
          graphType: "Bar",
          locationIds: graphLocationIdsLocal,
          reportName: "GetTireBelowInflationGraph",
          reportOption: "",
          toDate: "",
        };

        setShowSpinner(true);

        reportDashboardService
          .getTireReportDashboardData(requestObject)
          .then((data) => {
            const tireBelowInflationFormattedData =
              tireBelowInflationDataSetter.setData(data);
            setTableData({
              ...tableData,
              tireBelowInflationTableData: {
                rowsData:
                  tireBelowInflationFormattedData.tireBelowInflationData,
                headCells: tireBelowInflationFormattedData.headCells,
              },
            });
            setOriginalTableData({
              ...originalTableData,
              tireBelowInflationTableData: {
                rowsData:
                  tireBelowInflationFormattedData.tireBelowInflationData,
                headCells: tireBelowInflationFormattedData.headCells,
              },
            });
            // sets the columns to be shown on display column
            updatedHeadCells = tireBelowInflationDataSetter.updatedHeadCells();
            setDisplayColumns(updatedHeadCells);
            setShowColumns(tireBelowInflationFormattedData.selectedColShow);
            setDisplayColOptions(tireBelowInflationFormattedData.headCells);
            tireBelowInflationFormattedData.tireBelowInflationData.length === 0
              ? setNoData(true)
              : setNoData(false);
            // setting printing data
            setPrintData({
              reportType: "",
              reportName: globalizationMapping.GRAPH.TireBelowInflation,
              rowsData: tireBelowInflationFormattedData.tireBelowInflationData,
              headCells: tireBelowInflationFormattedData.headCells,
              selectedColShow: tireBelowInflationFormattedData.selectedColShow,
            });
            setShowSpinner(false);
          });

        reportDashboardService
          .getGraph(requestObjectTireBelowInflation)
          .then((data) => {
            const tireBelowInflationGraphData = tireBelowInflationDataSetter.setGraphData(data);
            setByTireInflationGraphData(tireBelowInflationGraphData);
          });

        break;

      case "tiresWithLowTreadDepthCount":
        requestObject = {
          accountIds,
          locationIds,
          reportName: "Tire_Low_Tread",
        };
        const requestObjectTireWithLowTreadDepth = {
          accountIds,
          dateRangeType: "AllTime",
          fromDate: "",
          graphType: "Bar",
          locationIds: graphLocationIdsLocal,
          reportName: "GetTireBelowTreadDepthGraph",
          reportOption: "",
          toDate: "",
        };
        setShowSpinner(true);

        reportDashboardService
          .getTireReportDashboardData(requestObject)
          .then((data) => {
            const tireWithLowTreadDepthFormattedData =
              tireWithLowTreadDepthDataSetter.setData(data);
            setTableData({
              ...tableData,
              tireWithLowTreadDepthTableData: {
                rowsData:
                  tireWithLowTreadDepthFormattedData.tireWithLowTreadDepthData,
                headCells: tireWithLowTreadDepthFormattedData.headCells,
              },
            });
            setOriginalTableData({
              ...originalTableData,
              tireWithLowTreadDepthTableData: {
                rowsData:
                  tireWithLowTreadDepthFormattedData.tireWithLowTreadDepthData,
                headCells: tireWithLowTreadDepthFormattedData.headCells,
              },
            });
            // sets the columns to be shown on display column
            updatedHeadCells =
              tireWithLowTreadDepthDataSetter.updatedHeadCells();
            setDisplayColumns(updatedHeadCells);
            setShowColumns(tireWithLowTreadDepthFormattedData.selectedColShow);
            setDisplayColOptions(tireWithLowTreadDepthFormattedData.headCells);
            // setting printing data
            setPrintData({
              reportType: "",
              reportName: globalizationMapping.GRAPH.TireWithLowTreadDepth,
              rowsData:
                tireWithLowTreadDepthFormattedData.tireWithLowTreadDepthData,
              headCells: tireWithLowTreadDepthFormattedData.headCells,
              selectedColShow:
                tireWithLowTreadDepthFormattedData.selectedColShow,
            });
            tireWithLowTreadDepthFormattedData.tireWithLowTreadDepthData
              .length === 0
              ? setNoData(true)
              : setNoData(false);

            reportDashboardService
              .getGraph(requestObjectTireWithLowTreadDepth)
              .then((graphData) => {
                const tireWithLowTreadDepthGraph =
                  tireBelowInflationDataSetter.setGraphData(graphData);
                setTireWithLowTreadDepthGraphData(tireWithLowTreadDepthGraph);
              });
            setShowSpinner(false);
          });
        break;

      case "tireConditionCount":
        requestObject = {
          accountIds,
          locationIds: graphLocationIdsLocal,
          reportName: "Tire_Condition",
        };

        setShowSpinner(true);

        reportDashboardService
          .getTireReportDashboardData(requestObject)
          .then((data) => {
            const tireConditionFormattedData =
              tireConditionDataSetter.setData(data);
            setTableData({
              ...tableData,
              tireConditionTableData: {
                rowsData: tireConditionFormattedData.tireConditionData,
                headCells: tireConditionFormattedData.headCells,
              },
            });
            setOriginalTableData({
              ...originalTableData,
              tireConditionTableData: {
                rowsData: tireConditionFormattedData.tireConditionData,
                headCells: tireConditionFormattedData.headCells,
              },
            });
            // sets the columns to be shown on display column
            updatedHeadCells = tireConditionDataSetter.updatedHeadCells();
            setDisplayColumns(updatedHeadCells);
            setShowColumns(tireConditionFormattedData.selectedColShow);
            setDisplayColOptions(tireConditionFormattedData.headCells);
            // setting printing data
            setPrintData({
              reportType: "",
              reportName: globalizationMapping.GRAPH.TiresWithConditions,
              rowsData: tireConditionFormattedData.tireConditionData,
              headCells: tireConditionFormattedData.headCells,
              selectedColShow: tireConditionFormattedData.selectedColShow,
            });
            tireConditionFormattedData.tireConditionData.length === 0
              ? setNoData(true)
              : setNoData(false);
            setShowSpinner(false);
          });

        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedNav.value, globalAccountState, graphLocationIds]);

  // sets the template for location names
  const initLocationsDataSetter = () => {
    const accountInfoObject = getAccountData();
    const accountLocationsInfo = accountInfoObject.location;
    const locationsDataObject = [];

    accountLocationsInfo.forEach((item) => {
      locationsDataObject.push({
        value: item.value,
        label: item.label,
        selected: true,
      });
    });

    setLocationsData(locationsDataObject);
    setDashboardGraphLocationData(locationsDataObject);
  };

  // sets the template for location names
  const updateLocationsDataSetter = () => {
    const dashboardGraphLocationData = getDashboardGraphLocationData();
    let locationsDataObject = [];
    locationsDataObject = dashboardGraphLocationData;

    setLocationsData(locationsDataObject);
    setDashboardGraphLocationData(locationsDataObject);
  };

  // selected location ids for dashboard graph
  const dashboardGraphLocationIds = () => {
    const dashboardGraphLocationData = getDashboardGraphLocationData();
    const graphLocationIdsLocal = [];
    dashboardGraphLocationData.forEach((locationData) => {
      if (locationData.selected) {
        graphLocationIdsLocal.push(locationData.value);
      }
    });
    return graphLocationIdsLocal;
  };

  // sets the selected location ids for the graph
  const graphLocationIdSetter = (locationsDataObject) => {
    const updatedLocationIds = [];
    locationsDataObject.forEach((locationData) => {
      if (locationData.selected) {
        updatedLocationIds.push(locationData.value);
      }
    });
    setDashboardGraphLocationData(locationsDataObject);
    setGraphLocationIds(updatedLocationIds);
  };

  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false,
  });

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        const data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]
        );
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      const data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);
      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };
  const onClickEmail = () => {
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      const data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);
      setModalHandler({ handleEmail: true });
    } else {
      setModalHandler({ handleEmail: true });
    }
  };

  /**
   * FEATURE : SEARCH FUNCTION
   */
  const searchTableData = (event) => {
    const searchText = event.target.value.toLowerCase();
    let originalTableDataStore, filteredData;

    switch (props.selectedNav.value) {
      case "tiresBelowInflationCount":
        originalTableDataStore =
          originalTableData.tireBelowInflationTableData.rowsData;
        filteredData = tireBelowInflationDataSetter.search(
          originalTableDataStore,
          searchText
        );
        setTableData({
          ...tableData,
          tireBelowInflationTableData: {
            rowsData: filteredData,
            headCells: tableData.tireBelowInflationTableData.headCells,
          },
        });
        // setting printing data
        setPrintData({ ...printData, rowsData: filteredData });
        break;

      case "tiresWithLowTreadDepthCount":
        originalTableDataStore =
          originalTableData.tireWithLowTreadDepthTableData.rowsData;
        filteredData = tireWithLowTreadDepthDataSetter.search(
          originalTableDataStore,
          searchText
        );
        setTableData({
          ...tableData,
          tireWithLowTreadDepthTableData: {
            rowsData: filteredData,
            headCells: tableData.tireWithLowTreadDepthTableData.headCells,
          },
        });
        // setting printing data
        setPrintData({ ...printData, rowsData: filteredData });
        break;

      case "tireConditionCount":
        originalTableDataStore =
          originalTableData.tireConditionTableData.rowsData;
        filteredData = tireConditionDataSetter.search(
          originalTableDataStore,
          searchText
        );
        setTableData({
          ...tableData,
          tireConditionTableData: {
            rowsData: filteredData,
            headCells: tableData.tireConditionTableData.headCells,
          },
        });
        // setting printing data
        setPrintData({ ...printData, rowsData: filteredData });
        break;

      default:
        break;
    }
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  /**
   * FEATURE : DISPLAY COLUMNS FUNCTION
   */
  const changeDisplayColumns = (selected) => {
    setDisplayColumns(selected);
    let currentHeadCells, showColumnsUpdated;

    if (selected.length > 0) {
      switch (props.selectedNav.value) {
        case "tiresBelowInflationCount":
          currentHeadCells =
            originalTableData.tireBelowInflationTableData.headCells;
          showColumnsUpdated =
            tireBelowInflationDataSetter.setDisplayColumnData(
              currentHeadCells,
              selected
            );
          setShowColumns(showColumnsUpdated);
          // setting printing data
          setPrintData({ ...printData, selectedColShow: showColumnsUpdated });

          break;

        case "tiresWithLowTreadDepthCount":
          currentHeadCells =
            originalTableData.tireWithLowTreadDepthTableData.headCells;
          showColumnsUpdated =
            tireWithLowTreadDepthDataSetter.setDisplayColumnData(
              currentHeadCells,
              selected
            );
          setShowColumns(showColumnsUpdated);
          // setting printing data
          setPrintData({ ...printData, selectedColShow: showColumnsUpdated });
          break;

        case "tireConditionCount":
          currentHeadCells = originalTableData.tireConditionTableData.headCells;
          showColumnsUpdated = tireConditionDataSetter.setDisplayColumnData(
            currentHeadCells,
            selected
          );
          setShowColumns(showColumnsUpdated);
          // setting printing data
          setPrintData({ ...printData, selectedColShow: showColumnsUpdated });
          break;

        default:
          break;
      }
    }
    selected.length === 0 ? setNoData(true) : setNoData(false);
  };
  const formSubmitHandler = (e) => {
    //should prevent submit, and continue below?
    e.preventDefault();
    return false;
  };

  return (
    <div>
      {!showSpinner && (
        <div className="dashboard-graph-table-container">
          <div className="graph-container">
            <Card className="graph-container-height">
              <CardContent>
                {props.selectedNav.value === "tiresBelowInflationCount" && (
                  <TireBelowInflationGraph
                    byTireInflationGraphData={byTireInflationGraphData}
                    locationsData={locationsData}
                    setLocationsData={setLocationsData}
                    graphLocationIdSetter={graphLocationIdSetter}
                  />
                )}
                {props.selectedNav.value === "tiresWithLowTreadDepthCount" && (
                  <TireWithLowTreadDepthGraph
                    tireWithLowTreadDepthGraphData={
                      tireWithLowTreadDepthGraphData
                    }
                    locationsData={locationsData}
                    setLocationsData={setLocationsData}
                    graphLocationIdSetter={graphLocationIdSetter}
                  />
                )}
                {props.selectedNav.value === "tireConditionCount" && (
                  <TireConditionGraph
                    graphData={tableData.tireConditionTableData.rowsData}
                    locationsData={locationsData}
                    setLocationsData={setLocationsData}
                    graphLocationIdSetter={graphLocationIdSetter}
                  />
                )}
              </CardContent>
            </Card>
          </div>
          <div className="table-container">
            <Card className="">
              <CardContent>
                <div className="table-toolbar">
                  {/* <div className="table-title">
                                        {props.selectedNav.name} Summary
                                    </div> */}
                  <div className="table-filters">
                    <div className="search-table-container">
                      <form
                        className=""
                        noValidate
                        autoComplete="off"
                        onSubmit={formSubmitHandler}
                      >
                        <InputLabel className="filter-table-label">
                          <span className="table-toolbar-label-text">
                            {commonWordsTexts.SearchTable}
                          </span>
                        </InputLabel>
                        <TextField
                          className="search-table-bar"
                          id="input-with-icon-textfield"
                          variant="outlined"
                          placeholder={commonWordsTexts.Search}
                          onChange={searchTableData}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  className="keyword-search-icon-img"
                                  alt=""
                                  src={require("../../../../assets/icons8-search.svg")}
                                />
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ maxLength: 50 }}
                        />
                      </form>
                    </div>
                    <div
                      className="display-columns-container"
                      id="dashboard-display-column"
                    >
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {commonWordsTexts.DisplayColumns}
                        </span>
                      </InputLabel>
                      <MultiSelect
                        options={displayColOptions}
                        value={displayColumns}
                        onChange={changeDisplayColumns}
                        overrideStrings={{
                          selectSomeItems:
                            globalizationMapping.REPORTCOMMONWORDS.SELECT,
                          allItemsAreSelected:
                            globalizationMapping.REPORTCOMMONWORDS.AllColumns,
                          selectAll:
                            globalizationMapping.REPORTCOMMONWORDS.SelectAll,
                          search: globalizationMapping.REPORTCOMMONWORDS.SELECT,
                        }}
                      />
                    </div>
                  </div>
                  <PrintEmailExportSetButtons
                      onClickEmail={onClickEmail}
                      onClickPrint={onClickPrint}
                      onClickExport={onClickExport}
                      toggleExportDropdown={() => setIsComponentVisible(!isComponentVisible)}
                      ref={ref}
                      noData={noData}
                      isComponentVisible={isComponentVisible}
                    />
                </div>
                <div>
                  {props.selectedNav.value === "tiresBelowInflationCount" && (
                    <TireBelowInflationTable
                      tableData={tableData.tireBelowInflationTableData}
                      showColumns={showColumns}
                    />
                  )}
                  {props.selectedNav.value ===
                    "tiresWithLowTreadDepthCount" && (
                    <TireWithLowTreadDepthTable
                      tableData={tableData.tireWithLowTreadDepthTableData}
                      showColumns={showColumns}
                    />
                  )}
                  {props.selectedNav.value === "tireConditionCount" && (
                    <TireConditionTable
                      tableData={tableData.tireConditionTableData}
                      showColumns={showColumns}
                    />
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
          <PrintExportModal
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            tableData={printData}
            noGraph={false}
          />
          <PdfExportModal
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            tableData={printData}
            noGraph={false}
          />
          <EmailModalComponent
            modalHandler={modalHandler}
            setModalHandler={setModalHandler}
            tableData={printData}
          />
        </div>
      )}
    </div>
  );
};

export default GraphTable;
