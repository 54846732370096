import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import "../common-modal/CommonPopup.css";
import CommonUtils from "../../../services/utils";
const ExportVehicleCsv = (props) => {
  const [rowsData, setRowsData] = useState(props.exportCsv.rowsData);
  const [headCells, setHeadCells] = useState(props.exportCsv.headCells);
  const commonUtils = new CommonUtils();

  useEffect(() => {
    const headCellArr = [];
    let rowData = [];
    JSON.parse(JSON.stringify(props.exportCsv.headCells)).forEach(
      (headCell, _index) => {
        if (props.exportCsv.selectedColShow[headCell.value] === true) {
          if (headCell.value === null) {
            headCell.label = "";
          }
          if (
            headCell.label.includes(",") ||
            headCell.label.includes('"') ||
            headCell.label.includes("'") ||
            headCell.label.includes("\\")
          ) {
            headCell.label = headCell.label.replaceAll('"', '""');
          }

          headCellArr.push(headCell);
        }
      }
    );
    setHeadCells(headCellArr);

    props.exportCsv.rowsData.forEach((row, _index) => {
      row.forEach((elem) => {
        let rowJson = {};
        Object.keys(elem).forEach((key, _id) => {
          if (key !== "Key" && props.exportCsv.selectedColShow[key] === true) {
            rowJson[key] = elem[key];
          }
          return true;
        });
        rowData.push(rowJson);
      });
    });
    setRowsData(rowData);
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        { rowsData: rowsData, headCells: headCells },
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setRowsData(data.rowsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <CSVLink
      className="export-csv-link"
      filename={`${props.exportCsv.reportName}${".csv"}`}
      data={rowsData}
      headers={headCells}
      target="_blank"
    >
      CSV
    </CSVLink>
  );
};
export default ExportVehicleCsv;
