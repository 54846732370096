import {
  Button,
  FormHelperText,
  Grid,
  makeStyles,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import HeaderApi from "../../../common-components/header/services/header-apis";
import Spinner from "../../../common-components/spinner/spinner.js";
import Globalization from "../../../globalization";
import { StateContext } from "../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../services/AccountInfoHandler";
import "./edit-parent-details.css";
import ExpandMoreStyledIcon from "../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import CommonUtils from '../../../services/utils';

//Material UI Styling
const useStyles = makeStyles({
  save: {
    "&:hover": {
      opacity: 1,
    },
    background:
      "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: "Barlow",
    opacity: 0.8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none",
  },
  cancel: {
    "&:hover": {
      backgroundColor: "#EAF3FB",
      borderRadius: 18,
      border: "none",
      opacity: 1,
      boxShadow: "none",
    },
    background: "transparent",
    border: "none",
    borderRadius: 18,
    color: "#0055A4",
    opacity: 0.8,
    letterSpacing: 0,
    fontWeight: 600,
    zIndex: 0,
    boxShadow: "none",
    marginRight: 5,
  },
});

//Main component of Edit Parent
const ParentAccForm = () => {
  const globalizationMapping = new Globalization();
  const commonUtils = new CommonUtils();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const formCaptions = globalizationMapping.FORMS.ACCOUNTS.PARENTFORM;
  const validationTexts = globalizationMapping.FORMS.VALIDATIONTEXTS;
  const notificationTexts = globalizationMapping.FORMS.NOTIFICATIONTEXTS;
  const locationObj = useLocation();
  const history = useHistory();
  const styles = useStyles();
  const headerApiObj = new HeaderApi();

  const [createLocation, setCreateLocation] = useState(0)
  const [globalAccountState] = useContext(StateContext);
  const [accountDetails, setAccountDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [countryList, setCountryList] = useState([{}]);
  const [currentCountryIndex, setCurrentCountryIndex] = useState(-1);
  const [isAccountNameEdited, setIsAccountNameEdited] = useState(false);
  const [isAccountAddressLine1Edited, setIsAccountAddressLine1Edited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isZipCodeEdited, setIsZipCodeEdited] = useState(false);
  const [isEmailAddressEdited, setIsEmailAddressEdited] = useState(false);
  const [isPhoneNumberEdited, setIsPhoneNumberEdited] = useState(false);

  useEffect(() => {
    setShowSpinner(true);
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    headerApiObj.getCountryList().then((countryData) => {
      setCountryList(countryData.model);
      if (locationObj.pathname === "/editparentaccount") {
        if (locationObj.state && locationObj.state.accountId)
          accountIds[0] = locationObj.state.accountId;
        headerApiObj
          .getParentAccountDetails(accountIds[0])
          .then((accountData) => {
            if (accountData.model) {
              setAccountDetails(accountData.model);
              let currentCountryIndexLocal = countryData.model.findIndex(
                (country) =>
                  parseInt(country.countryId) ===
                  parseInt(accountData.model.country)
              );
              setCurrentCountryIndex(currentCountryIndexLocal);
            }
          });
      } else if (
        locationObj.state &&
        locationObj.state.fromPath &&
        locationObj.state.fromPath === "/create-location"
      ) {
        let currentCountryIndexLocal = countryData.model.findIndex(
          (country) =>
            parseInt(country.countryId) ===
            parseInt(locationObj.state.accountObj.country)
        );
        setCurrentCountryIndex(currentCountryIndexLocal);
        setAccountDetails(locationObj.state.accountObj);
      } else {
        setAccountDetails({
          accountAddressLine1: "",
          accountAddressLine2: "",
          accountName: "",
          city: "",
          country: "1",
          emailAddress: "",
          locations: null,
          parentAccountId: 0,
          phoneNumber: "",
          state: "",
          zipCode: "",
        });
        setCurrentCountryIndex(0);
      }
    });

    setTimeout(() => {
      setShowSpinner(false);
    }, 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState, locationObj]);

  const handleSelectChange = (key, value) => {
    switch (key) {
      case "accName":
        setAccountDetails({ ...accountDetails, accountName: value });
        setIsAccountNameEdited(true);
        break;
      case "addLine1":
        setAccountDetails({ ...accountDetails, accountAddressLine1: value });
        setIsAccountAddressLine1Edited(true);
        break;
      case "addLine2":
        setAccountDetails({ ...accountDetails, accountAddressLine2: value });
        break;
      case "country":
        {
          let currentCountryIndexLocal = countryList.findIndex(
            (country) => parseInt(country.countryId) === parseInt(value)
          );
          setCurrentCountryIndex(currentCountryIndexLocal);
          if (countryList[currentCountryIndex].states.length > 0) {
            setAccountDetails({
              ...accountDetails,
              country: value,
              state: "",
            });
          } else setAccountDetails({ ...accountDetails, country: value });
        }
        break;
      case "state":
        setAccountDetails({ ...accountDetails, state: value });
        break;
      case "city":
        setAccountDetails({ ...accountDetails, city: value });
        setIsCityEdited(true);
        break;
      case "zipCode":
        setAccountDetails({ ...accountDetails, zipCode: value });
        setIsZipCodeEdited(true);
        break;
      case "phone":
        setAccountDetails({ ...accountDetails, phoneNumber: value });
        setIsPhoneNumberEdited(true);
        break;
      case "email":
        setAccountDetails({ ...accountDetails, emailAddress: value });
        setIsEmailAddressEdited(true);
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    if (
      locationObj.pathname === "/editparentaccount" &&
      locationObj.state &&
      locationObj.state.accountId
    ) {
      history.push("/manage-parent-account");
    } else history.push("/home");
  };

  const validateForm = () => {
    let errorsLocal = {};
    let isValid = true;

    Object.keys(accountDetails).forEach((key) => {
      if (
        key !== "accountAddressLine2" &&
        key !== "state"
      ) {
        if (accountDetails[key] === "") {
          errorsLocal[key] = validationTexts.MISSINGFIELD;
          isValid = false;
        } else if (key === "accountName") {
          if (accountDetails[key].length > 200) {
            errorsLocal[key] =
              "Account Name should be less than 200 characters";
            isValid = false;
          }
          if (/^\s/.test(accountDetails[key])) {
            errorsLocal[key] = validationTexts.ALPHABETSONLY
            isValid = false;
          }
        } else if (key === "zipCode" && accountDetails[key] === "") {
          errorsLocal[key] = "ZipCode is invalid";
          isValid = false;
        } else if (
          key === "emailAddress" &&
          !accountDetails[key].match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
        ) {
          errorsLocal[key] = "Email Address is invalid. Must have @ and .com or the like";
          isValid = false;
        } 
        else if (
          key === "phoneNumber" &&
          !accountDetails[key].match(/\d{3}[ -]?\d{3}[ -]?\d{4}/)
        ) {
          errorsLocal[key] =
            "Phone number is invalid. Must be at least 10 digits";
          isValid = false;
        }
        else if (
          key === "city" &&
          !accountDetails[key].match(/^[A-Za-z "]+$/)
        ) {
          errorsLocal[key] = validationTexts.ALPHABETSONLY;
          isValid = false;
        }
      }

      return true;
    });

    setAccountDetails({
      ...accountDetails,
      accountName: accountDetails.accountName?.trim(),
      accountAddressLine1: accountDetails.accountAddressLine1?.trim(),
      accountAddressLine2: accountDetails.accountAddressLine2?.trim(),
      state: accountDetails.state?.trim(),
      city: accountDetails.city?.trim(),
      zipCode: accountDetails.zipCode?.trim(),
      phoneNumber: accountDetails.phoneNumber?.trim(),
      emailAddress: accountDetails.emailAddress?.trim(),
    });

    setErrors(errorsLocal);

    return isValid;
  };

  localStorage.setItem("createLocation", createLocation);

  const onSubmitClick = () => {
    setCreateLocation(createLocation + 1)
    let successStatus = false;
    if (validateForm()) {
      setShowSpinner(true);
      let date = new Date();
      date = date.toISOString();
      
      if (accountDetails.state === "" && currentCountryIndex !== -1 &&
      countryList[currentCountryIndex].states.length > 0) {
        accountDetails.state = countryList[currentCountryIndex].states[0].stateName;
        setAccountDetails({
          ...accountDetails,
          state: countryList[currentCountryIndex].states[0].stateName,
          updatedDate: date,
        });
      } else {
        setAccountDetails({ ...accountDetails, updatedDate: date });
      }


      if (locationObj.pathname === "/editparentaccount") {
        headerApiObj.updateParentAccountDetails(accountDetails).then((data) => {
          if (data.didError) {
            successStatus = false;
          } else {
            successStatus = true;
            setShowSpinner(false);
            history.push({
              pathname: "/home",
              state: {
                updatedSection: "parentaccountdetails",
                updateStatus: successStatus,
                successMessage: notificationTexts.ACCOUNTEDITSUCCESSMSG,
                successMessageDesc: notificationTexts.ACCEDITMSGDESC,
                failureMessage: notificationTexts.ACCFALIURE,
              },
            });
          }
        });
      } else {
        setShowSpinner(false);
        history.push({
          pathname: "/create-location",
          state: {
            previousPath: locationObj.pathname,
            accountObj: accountDetails,
            showNewAccNotification: false,
          },
        });
      }
    }
    setIsAccountNameEdited(false);
    setIsAccountAddressLine1Edited(false);
    setIsCityEdited(false);
    setIsZipCodeEdited(false);
    setIsEmailAddressEdited(false);
    setIsPhoneNumberEdited(false);
  };

  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME,
    },
    {
      path: "",
      name:
        locationObj.pathname === "/editparentaccount"
          ? globalHeaderTexts.EDITPARENTACC
          : globalHeaderTexts.CREATEPARENTACC,
    },
  ];

  return (
    <div className="standalone-new-header-container">
      <div className="edit-parent-account-main-page-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image-edit-parent">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {locationObj.pathname === "/editparentaccount"
                      ? globalHeaderTexts.EDITPARENTACC
                      : globalHeaderTexts.CREATEPARENTACC}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="manage-vehicle-list-container">
          <div className="edit-parent-container">
            <div className="edit-parent-inner-container">
              <div className="sub-header">
                <span>{formCaptions.ACCCAPTION}</span>
              </div>
              <div className="edit-details-message bottom-margin">
                <span>{formCaptions.ACCFORMDESC} </span>
              </div>

              <div className="account-details-grid">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <p className="field-label">
                      {formCaptions.ACCOUNTNAME}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    <TextField
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={accountDetails.accountName}
                      onChange={(e) =>
                        handleSelectChange("accName", commonUtils.removeLeadingSpaces(e.target.value))
                      }
                      placeholder={
                        " " +
                        formCaptions.ENTER +
                        " " +
                        formCaptions.ACCOUNTNAME
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                    {(accountDetails.accountName === "" && isAccountNameEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isAccountNameEdited ? "" : <FormHelperText>{errors.accountName}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {formCaptions.ADDRESSLINE} 1{" "}
                      <span className="required-mark">*</span>
                    </p>
                    <TextField
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={accountDetails.accountAddressLine1}
                      onChange={(e) => {
                        handleSelectChange("addLine1", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      placeholder={
                        globalizationMapping.PLACEHOLDER.ADDRESSLINE1
                      }
                      inputProps={{ maxLength: 100 }}
                    />
                    {(accountDetails.accountAddressLine1 === "" && isAccountAddressLine1Edited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isAccountAddressLine1Edited ? "" : <FormHelperText>{errors.accountAddressLine1}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">{formCaptions.ADDRESSLINE} 2</p>
                    <TextField
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={accountDetails.accountAddressLine2}
                      onChange={(e) => {
                        handleSelectChange("addLine2", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      placeholder={
                        globalizationMapping.PLACEHOLDER.ADDRESSLINE2
                      }
                      inputProps={{ maxLength: 100 }}
                    />
                    <FormHelperText>
                      {errors.accountAddressLine2}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {formCaptions.COUNTRY}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    <Select
                      native
                      className="edit-detail-input-box"
                      value={accountDetails.country}
                      variant="outlined"
                      helpertext={errors.country}
                      IconComponent={ExpandMoreStyledIcon}
                      onChange={(e) => {
                        handleSelectChange("country", e.target.value);
                      }}
                      inputProps={{
                        name: "Country",
                        id: "country-select-drop-down",
                      }}
                    >
                      {/* <option key={0} value={0}>-Select-</option> */}
                      {countryList.map((country, id) => (
                        <option key={id + 1} value={country.countryId}>
                          {country.countryName}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>{errors.country}</FormHelperText>
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">{globalHeaderTexts.STATE}</p>
                    {currentCountryIndex !== -1 &&
                      countryList[currentCountryIndex].states.length > 0 && (
                        <Select
                          native
                          className="edit-detail-input-box"
                          value={accountDetails.state}
                          variant="outlined"
                          // onFocus={handleCountryChange}
                          IconComponent={ExpandMoreStyledIcon}
                          onChange={(e) => {
                            handleSelectChange("state", e.target.value);
                          }}
                          inputProps={{
                            name: "State",
                            id: "state-select-drop-down",
                          }}
                        >
                          {countryList[currentCountryIndex].states.map(
                            (stateDetail, id) => (
                              <option key={id} value={stateDetail.stateName}>
                                {stateDetail.stateName}
                              </option>
                            )
                          )}
                        </Select>
                      )}
                    {currentCountryIndex !== -1 &&
                      !countryList[currentCountryIndex].states.length && (
                        <div>
                          <TextField
                            className="edit-detail-input-box"
                            variant="outlined"
                            placeholder={
                              " " +
                              formCaptions.ENTER +
                              " " +
                              globalHeaderTexts.STATE
                            }
                            value={accountDetails.state}
                            onChange={(e) => {
                              handleSelectChange("state", commonUtils.removeLeadingSpaces(e.target.value));
                            }}
                            inputProps={{ maxLength: 50 }}
                          />
                        </div>
                      )}
                    {currentCountryIndex === -1 && (
                      <div>
                        <TextField
                          disabled
                          className="edit-detail-input-box"
                          variant="outlined"
                          value={accountDetails.state}
                          onChange={(e) => {
                            handleSelectChange("state", commonUtils.removeLeadingSpaces(e.target.value));
                          }}
                          placeholder={
                            " " +
                            formCaptions.ENTER +
                            " " +
                            globalHeaderTexts.STATE
                          }
                          inputProps={{ maxLength: 50 }}
                        />
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.CITY}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    <TextField
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={accountDetails.city}
                      onChange={(e) => {
                        handleSelectChange("city", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      placeholder={
                        " " + formCaptions.ENTER + " " + globalHeaderTexts.CITY
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                    {(accountDetails.city === "" && isCityEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isCityEdited ? "" : <FormHelperText>{errors.city}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.ZIPPOSTAL}{" "}
                      <span className="required-mark">*</span>
                    </p>
                    <TextField
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={accountDetails.zipCode}
                      onChange={(e) => {
                        handleSelectChange("zipCode", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      placeholder={
                        " " +
                        formCaptions.ENTER +
                        " " +
                        globalHeaderTexts.ZIPPOSTAL
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                    {(accountDetails.zipCode === "" && isZipCodeEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isZipCodeEdited ? "" : <FormHelperText>{errors.zipCode}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">
                      {globalHeaderTexts.PHONE}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={accountDetails.phoneNumber}
                      onChange={(e) => {
                        handleSelectChange("phone", e.target.value);
                      }}
                      placeholder={
                        " " +
                        formCaptions.ENTER +
                        " " +
                        globalHeaderTexts.PHONE
                      }
                      inputProps={{ maxLength: 12 }}
                    />
                    {(accountDetails.phoneNumber !== "" && !accountDetails.phoneNumber?.match(/\d{3}[ -]?\d{3}[ -]?\d{4}/) && isPhoneNumberEdited) ? <FormHelperText>Phone number is invalid. Must be at least 10 digits</FormHelperText> : ''}
                    {(accountDetails.phoneNumber === "" && isPhoneNumberEdited) ? <FormHelperText>This field is required and must be at least 10 digits</FormHelperText> : ''}
                    {(isPhoneNumberEdited ? "" : <FormHelperText>{errors.phoneNumber}</FormHelperText>)}
                  </Grid>

                  <Grid item xs={4}>
                    <p className="field-label">{globalHeaderTexts.EMAIL}
                      <span className="required-mark"> *</span>
                    </p>
                    <TextField
                      className="edit-detail-input-box"
                      variant="outlined"
                      value={accountDetails.emailAddress}
                      onChange={(e) => {
                        handleSelectChange("email", commonUtils.removeLeadingSpaces(e.target.value));
                      }}
                      type="text"
                      placeholder={
                        globalizationMapping.PLACEHOLDER.EmailIds
                      }
                      inputProps={{ maxLength: 100 }}
                    />
                    {(accountDetails.emailAddress !== "" && !accountDetails.emailAddress?.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/) && isEmailAddressEdited) ? <FormHelperText>Email Address is invalid. Must have @ and .com or the like</FormHelperText> : ''}
                    {(accountDetails.emailAddress === "" && isEmailAddressEdited) ? <FormHelperText>This field is required</FormHelperText> : ''}
                    {(isEmailAddressEdited ? "" : <FormHelperText>{errors.emailAddress}</FormHelperText>)}
                  </Grid>
                </Grid>
              </div>
              <hr className="hr-divider" />
              <div className="action-buttons">
                <Button
                  className={styles.cancel}
                  variant="contained"
                  disableElevation={true}
                  onClick={() => handleCancel()}
                >
                  {globalizationMapping.FORMS.CANCEL}
                </Button>
                <Button
                  className={styles.save}
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  onClick={() => onSubmitClick()}
                >
                  {locationObj.pathname === "/createparentaccount"
                    ? globalizationMapping.FORMS.NEXT
                    : globalizationMapping.FORMS.SAVE}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ParentAccForm;
