import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "../scenes/reports-dashboard/components/dashboard/dashboard";
import ScrapSummary from "../scenes/tires-reports/components/scrap-tires/scrap-summary/components/main-container/ScrapSummary";
import RemovalReason from "../scenes/tires-reports/components/scrap-tires/removal-reason/components/main-container/ByRemovalReason";
import ByCompound from "../scenes/tires-reports/components/scrap-tires/by-compound/components/main-container/ByCompound";
import ByIndividualTire from "../scenes/tires-reports/components/scrap-tires/by-individual-tire/components/main-container/ByIndividualTire";
import ScrapAnalysis from "../scenes/tires-reports/components/scrap-tires/scrap-analysis/components/main-container/ScrapAnalysis";
import TireInstallation from "../scenes/tires-reports/components/tires-in-service/tire-installation/components/main-container/TireInstallation";
import TireHistory from "../scenes/tires-reports/components/tires-in-service/tire-history/components/main-container/TireHistory";
import FleetSurvey from "../scenes/vehicle-reports/components/fleet-survey/main-container/FleetSurvey";
import FleetData from "../scenes/vehicle-reports/components/fleet-data/main-container/FleetData";
import TireForecast from "../scenes/vehicle-reports/components/by-tires/tire-forecast/main-container/TireForecast";
import InServiceTireCostSummary from "../scenes/tires-reports/components/tires-in-service/in-service-tire-cost-summary/components/main-container/InServiceTireCostSummary";
import ByPercentageWorn from "../scenes/tires-reports/components/tires-in-service/by-percentage-worn/components/main-container/ByPercentageWorn";
import ByWearCondition from "../scenes/tires-reports/components/tires-in-service/by-wear-condition/components/main-container/ByWearCondition";
import TireRegister from "../scenes/tires-reports/components/other-tires/tire-register/components/main-container/TireRegister";
import TiresSentToRepair from "../scenes/tires-reports/components/other-tires/tires-sent-to-repair/components/main-container/TiresSentToRepair";
import TiresSentToRetread from "../scenes/tires-reports/components/other-tires/tires-sent-to-retread/components/main-container/TiresSentToRetread";
import TiresSentToInspectTireReport from "../scenes/tires-reports/components/other-tires/tires-sent-to-inspect-tire-report/components/main-container/TiresSentToInspectTireReport";
import AchievedHoursReport from "../scenes/tires-reports/components/other-tires/achieved-hours-report/components/main-container/AchievedHoursReport";
import RemovedTire from "../scenes/tires-reports/components/other-tires/removed-tire/components/main-container/RemovedTire";
import TiresByDisposition from "../scenes/tires-reports/components/other-tires/tires-by-disposition/components/main-container/TiresByDisposition";
import ByTireType from "../scenes/tires-reports/components/scrap-tires/by-tire-type/components/main-container/ByTireType";
import ByTireSize from "../scenes/tires-reports/components/scrap-tires/by-tire-size/components/main-container/ByTireSize";
import MonthlyTireLife from "../scenes/tires-reports/components/scrap-tires/monthly-tire-life/components/main-container/MonthlyTireLife";
import ByManufacturer from "../scenes/tires-reports/components/tires-in-service/by-manufacturer/components/main-container/ByManufacturer";
import BySize from "../scenes/tires-reports/components/tires-in-service/by-size/components/main-container/BySize";
import ByTiresInServiceTireType from "../scenes/tires-reports/components/tires-in-service/by-tire-type/components/main-container/ByTireType";
import ByVehicleModel from "../scenes/tires-reports/components/tires-in-service/by-vehicle-model/components/main-container/ByVehicleModel";
import ByVehicleWheelPosition from "../scenes/tires-reports/components/tires-in-service/by-vehicle-wheelposition/components/main-container/ByVehicleWheelPosition";
import ByVehicleId from "../scenes/tires-reports/components/tires-in-service/by-vehicle-id/components/main-container/ByVehicleId";
import ProjectedCostByTireType from "../scenes/tires-reports/components/tires-in-service/projected-cost-by-tire-type/components/main-container/ProjectedCostByTireType";
import ProjectedCostByTireSize from "../scenes/tires-reports/components/tires-in-service/projected-cost-by-tire-size/components/main-container/ProjectedCostByTireSize";
import TireForecastByTireType from "../scenes/tires-reports/components/tires-in-service/tire-forecast-by-tire-type/components/main-container/TireForecastByTireType";
import TireInflation from "../scenes/tires-reports/components/tires-in-service/tire-inflation/components/main-container/TireInflation";
import ScrapCostByTireSize from "../scenes/tires-reports/components/scrap-tires/scrap-cost-by-tire-size/components/main-container/ScrapCostByTireSize";
import ScrapHoursByTireSize from "../scenes/tires-reports/components/scrap-tires/scrap-hours-by-tire-size/components/main-container/ScrapHoursByTireSize";
import ScrapCostByTireType from "../scenes/tires-reports/components/scrap-tires/scrap-cost-by-tire-type/components/main-container/ScrapCostByTireType";
import ScrapHoursByTireType from "../scenes/tires-reports/components/scrap-tires/scrap-hours-by-tire-type/components/main-container/ScrapHoursByTireType";
import TwoAxleFourTires from "../scenes/survey-templates/components/2-Axle/components/2-Axle-4-Tires/TwoAxleFourTires";
import TwoAxleSixTires from "../scenes/survey-templates/components/2-Axle/components/2-Axle-6-Tires/TwoAxleSixTires";
import TwoAxleSixTiresTwo from "../scenes/survey-templates/components/2-Axle/components/2-Axle-6-Tires-2/TwoAxleSixTiresTwo";
import TwoAxleEightTires from "../scenes/survey-templates/components/2-Axle/components/2-Axle-8-Tires/TwoAxleEightTires";
import OneAxleTwoTires from "../scenes/survey-templates/components/1-Axle/components/1-Axle-2-Tires/OneAxleTwoTires";
import OneAxleFourTires from "../scenes/survey-templates/components/1-Axle/components/1-Axle-4-Tires/OneAxleFourTires";
import ThreeAxleSixTires from "../scenes/survey-templates/components/3-Axle/components/3-Axle-6-Tires/ThreeAxleSixTires";
import ThreeAxleTenTires from "../scenes/survey-templates/components/3-Axle/components/3-Axle-10-Tires/ThreeAxleTenTires";
import FourAxleEightTires from "../scenes/survey-templates/components/4-Axle/components/4-Axle-8-Tires/FourAxleEightTires";
import FiveAxleTenTires from "../scenes/survey-templates/components/5-Axle/components/5-Axle-10-Tires/FiveAxleTenTires";
import FiveAxleSixteenTires from "../scenes/survey-templates/components/5-Axle/components/5-Axle-16-Tires/FiveAxleSixteenTires";
import FiveAxleEighteenTires from "../scenes/survey-templates/components/5-Axle/components/5-Axle-18-Tires/FiveAxleEighteenTires";
import FiveAxleTwentyTires from "../scenes/survey-templates/components/5-Axle/components/5-Axle-20-Tires/FiveAxleTwentyTires";
import SixAxleTwelveTires from "../scenes/survey-templates/components/6-Axle/components/6-Axle-12-Tires/SixAxleTwelveTires";
import SevenAxleFourteenTires from "../scenes/survey-templates/components/7-Axle/components/7-Axle-14-Tires/SevenAxleFourteenTires";
import SevenAxleTwentySixTires from "../scenes/survey-templates/components/7-Axle/components/7-Axle-26-Tires/SevenAxleTwentySixTires";
import EightAxleSixteenTires from "../scenes/survey-templates/components/8-Axle/components/8-Axle-16-Tires/EightAxleSixteenTires";
import NineAxleEighteenTires from "../scenes/survey-templates/components/9-Axle/components/9-Axle-18-Tires/NineAxleEighteenTires";
import DailyJobSummary from "../scenes/tires-reports/components/tires-in-service/daily-job-summary/components/main-container/DailyJobSummary";
import TireInflationReport from "../scenes/vehicle-reports/components/by-tires/tire-inflation-report/main-container/TireInflationReport";
import TireRotation from "../scenes/vehicle-reports/components/by-tires/tire-rotation/main-container/TireRotation";
import TireFlipping from "../scenes/vehicle-reports/components/by-tires/tire-flipping/main-container/TireFlipping";
import TireNeedsForecast from "../scenes/tires-reports/components/tires-in-service/tire-needs-forecast/components/main-container/TireNeedsForecast";
import RimReport from "../scenes/rim-reports/components/rim-reports/components/rim-report/main-container/RimReport";
import RimsInService from "../scenes/rim-reports/components/rims-in-service-report/components/rim-report/main-container/RimsInService";
import NewVSScrap from "../scenes/tires-reports/components/other-tires/new-vs-scrap/components/main-container/NewVSScrap";
import AllTireReport from "../scenes/tires-reports/components/other-tires/all-tire-report/main-container/AllTireReport";

const ReportsRouting = ({ selectedRoute }) => {
  const history = useHistory();
  useEffect(() => {
    const route = selectedRoute.path ? selectedRoute.path : "reportsDashboard";
    history.push("/" + route);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  return (
    <div className="container">
      <main>
        <Switch>
          <Route path="/reportsDashboard" component={Dashboard} />
          <Route path="/scrapSummary" component={ScrapSummary} />
          <Route path="/byRemovalReason" component={RemovalReason} />
          <Route path="/byCompound" component={ByCompound} />
          <Route path="/byIndividualTire" component={ByIndividualTire} />
          <Route path="/scrapAnalysis" component={ScrapAnalysis} />
          <Route path="/byTireType" component={ByTireType} />
          <Route path="/byTireSize" component={ByTireSize} />
          <Route path="/monthlyTireLife" component={MonthlyTireLife} />
          <Route path="/tireInstallation" component={TireInstallation} />
          <Route path="/tireHistory" component={TireHistory} />
          <Route path="/fleetSurvey" component={FleetSurvey} />
          <Route path="/tireInflationReport" component={TireInflationReport} />
          <Route path="/fleetData" component={FleetData} />
          <Route path="/tireForecast" component={TireForecast} />
          <Route path="/inServiceTireCostSummary" component={InServiceTireCostSummary}/>
          <Route path="/byPercentageWorn" component={ByPercentageWorn} />
          <Route path="/byWearCondition" component={ByWearCondition} />
          <Route path="/byManufacturer" component={ByManufacturer} />
          <Route path="/bySize" component={BySize} />
          <Route path="/byTiresInServicesTireType" component={ByTiresInServiceTireType}/>
          <Route path="/byVehicleModel" component={ByVehicleModel} />
          <Route path="/byVehicleWheelPosition" component={ByVehicleWheelPosition}/>
          <Route path="/byVehicleId" component={ByVehicleId} />
          <Route path="/projectedCostByTireSize" component={ProjectedCostByTireSize}/>
          <Route path="/tireRegister" component={TireRegister} />
          <Route path="/tiresSentToRepair" component={TiresSentToRepair} />
          <Route path="/tiresSentToRetread" component={TiresSentToRetread} />
          <Route path="/tiresSentToInspectTireReport" component={TiresSentToInspectTireReport}/>
          <Route path="/achievedHoursReport" component={AchievedHoursReport} />
          <Route path="/projectedCostByTireType" component={ProjectedCostByTireType}/>
          <Route path="/removedTire" component={RemovedTire} />
          <Route path="/tiresByDisposition" component={TiresByDisposition} />
          <Route path="/tireForecastByTireType" component={TireForecastByTireType}/>
          <Route path="/newVSScrap" component={NewVSScrap} />
          <Route path="/tireInflation" component={TireInflation} />
          <Route path="/tireRotation" component={TireRotation} />
          <Route path="/tireFlipping" component={TireFlipping} />
          <Route path="/scrapCostByTireSize" component={ScrapCostByTireSize} />
          <Route path="/scrapHoursByTireSize" component={ScrapHoursByTireSize}/>
          <Route path="/scrapCostByTireType" component={ScrapCostByTireType} />
          <Route path="/scrapHoursByTireType" component={ScrapHoursByTireType}/>
          <Route path="/twoAxlesFourTires" component={TwoAxleFourTires} />
          <Route path="/twoAxlesSixTires" component={TwoAxleSixTires} />
          <Route path="/twoAxlesSixTiresTwo" component={TwoAxleSixTiresTwo} />
          <Route path="/twoAxlesEightTires" component={TwoAxleEightTires} />
          <Route path="/oneAxleTwoTires" component={OneAxleTwoTires} />
          <Route path="/oneAxleFourTires" component={OneAxleFourTires} />
          <Route path="/threeAxlesSixTires" component={ThreeAxleSixTires} />
          <Route path="/threeAxlesTenTires" component={ThreeAxleTenTires} />
          <Route path="/fourAxlesEightTires" component={FourAxleEightTires} />
          <Route path="/fiveAxlesTenTires" component={FiveAxleTenTires} />
          <Route path="/fiveAxlesSixteenTires" component={FiveAxleSixteenTires}/>
          <Route path="/fiveAxlesEighteenTires" component={FiveAxleEighteenTires}/>
          <Route path="/fiveAxlesTwentyTires" component={FiveAxleTwentyTires} />
          <Route path="/sixAxlesTwelveTires" component={SixAxleTwelveTires} />
          <Route path="/sevenAxlesFourteenTires" component={SevenAxleFourteenTires}/>
          <Route path="/sevenAxlesTwentySixTires" component={SevenAxleTwentySixTires}/>
          <Route path="/sevenAxlesTwentySixTires" component={SevenAxleTwentySixTires}/>
          <Route path="/eightAxlesSixteenTires" component={EightAxleSixteenTires} />
          <Route path="/nineAxlesEighteenTires" component={NineAxleEighteenTires}/>
          <Route path="/dailyJobSummary" component={DailyJobSummary} />
          <Route path="/tireNeedsForecast" component={TireNeedsForecast} />
          <Route path="/rimReport" component={RimReport} />
          <Route path="/rimsInService" component={RimsInService} />
          <Route path="/allTireReport" component={AllTireReport} />
        </Switch>
      </main>
    </div>
  );
};

export default ReportsRouting;
