import React, { useState, useEffect } from 'react'
import './SurveyTemplate.css'
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MailIcon from "../../../../assets/mail-icon.svg";
import EmailModalComponent from "../../../../common-components/modal-components/email-modal/EmailModalComponent";
import { SurveyTemplatePdfGenerator } from '../../../../common-components/survey-template-pdf/SurveyTemplatePdf'
import { makeStyles } from '@material-ui/core/styles';
import Globalization from '../../../../globalization';
import PrintIcon from "@material-ui/icons/Print";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CommonUtils from "../../../../services/utils";

const useStyles = makeStyles({
  root: {
    "& .MuiAutocomplete-inputFocused": {
      color: "#000000 !important"
    }
  }
})
const SurveyTemplate = ({ axleConfigurationDetails, setInspectionOrderLocation, locationData, locationNameSelected }) => {
  const commonUtils = new CommonUtils();
  const globalizationMapping = new Globalization();
  let configPosition = axleConfigurationDetails.configurationPosition.split(',');
  // eslint-disable-next-line no-unused-vars
  // Handle modal pop ups for export
  const [modalHandler, setModalHandler] = React.useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false
  });
  const [iframeDataUrl, setIframeDataUrl] = useState()

  const classes = useStyles()

  useEffect(() => {

    // OPEN PDF TO VIEW WITH I-FRAME
    let pdfDocGenerator = SurveyTemplatePdfGenerator(axleConfigurationDetails, locationNameSelected, 'create')
    pdfDocGenerator.getDataUrl((dataUrl) => {
      setIframeDataUrl(dataUrl)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickPrint = () => {
    SurveyTemplatePdfGenerator(axleConfigurationDetails, locationNameSelected, 'print')
  }
  const onClickEmail = () => {
    setModalHandler({ handleEmail: true });
  }
  const onClickDownload = () => {
    SurveyTemplatePdfGenerator(axleConfigurationDetails, locationNameSelected, 'download')
  }

  return (
    <div>
      <div className={locationData.length > 1 ? "survey-template-toolbar-multi" : "survey-template-toolbar-single"}>
        {locationData.length > 1 &&
          <div className="survey-template-location-selector-container">
            <div className="survey-template-location-selector">
              <div className="survey-template-location-label-container">
                <span className="survey-template-location-label">{globalizationMapping.REPORTCOMMONWORDS.LocationSelected} : </span>
                <span className="survey-template-location-label-text">{locationNameSelected}</span>
              </div>
              <Autocomplete
                size="small"
                id="survey-template-auto-complete"
                classes={{ root: classes.root }}
                options={locationData}
                getOptionLabel={(option) => option.label}
                style={{ width: "100%" }}
                onChange={(event, value) => setInspectionOrderLocation(event, value)}
                renderInput={(params) =>
                  <TextField {...params}
                    placeholder="Change Location"
                    variant="outlined" 
                    inputProps={{...params.inputProps, maxLength: 50 }}
                  />
                }
              />
            </div>
          </div>
        }
        <div className="survey-template-export-items">

          <button
            onClick={onClickPrint}
            className="survey-template-function-item"
          >
            <span style={{color:commonUtils.primaryBlue}}>
              <PrintIcon  fontSize="medium" style={{verticalAlign:"middle", marginBottom:5,marginLeft:5}}></PrintIcon>
            </span>
          </button>
          {localStorage.getItem("isEmailBtnShow") === "true" ?
            <button
              onClick={onClickEmail}
              className="survey-template-function-item"
            >
              <img className="mail-icon-img" alt="" src={MailIcon} />
              <span className="survey-template-toolbar-text">{globalizationMapping.MANAGE_PARENT_ACCOUNT.EMAIL}</span>
            </button>
          : null }

          <button
            onClick={onClickDownload}
            className="survey-template-function-item"
          >
             <span style={{color:commonUtils.primaryBlue}}>
                <FileCopyIcon fontSize="medium" style={{verticalAlign:"middle", marginBottom:8}}></FileCopyIcon>
              </span>
          </button>

        </div>
      </div>
      <div className="axle-config-template">
        <iframe src={iframeDataUrl} id="iframeContainer" title="title" className="survey-template-desktop"></iframe>
      </div>
      <div className="survey-template-mobile">
        <Grid spacing={3} container className="axle-analysis-template">
          <Grid container item sm={12} className="vehicle-basic-details axle-survey-template">
            <Grid item xs={6} sm={4}>
              <div className="small-heading-column-data underline-style">
                {/* Survey- Date */}
                {globalizationMapping.INSPECT.SURVEYDATE} </div>
            </Grid>
            <Grid item xs={6} sm={6}>
              <div className="small-heading-column-data underline-style">
                {/* Account Name */}
                {globalizationMapping.REPORTTABLE.ACCOUNTNAME}
              </div>
            </Grid>
            <Grid item xs={6} sm={2}>
              <div className="small-heading-column-data underline-style">
                {/* Insp ID */}
                {globalizationMapping.REPORTTABLE.INSPID}

              </div>
            </Grid>
          </Grid>
          <Grid item container sm={12} className="vehicle-basic-details axle-survey-template">
            <Grid item xs={6} sm={3}>
              <div className="template-box"></div>
            </Grid>
            <Grid item xs={6} sm={1}>
            </Grid>
            <Grid item xs={6} sm={4}>
              <div className="small-heading-column-data underline-style margin-top-1em">{globalizationMapping.REPORTCOMMONWORDS.MakeModel}</div>
            </Grid>
            <Grid item xs={6} sm={4}>
              <div className="small-heading-column-data underline-style margin-top-1em">{globalizationMapping.REPORTCOMMONWORDS.HourMeter}</div>
            </Grid>
          </Grid>
        </Grid>
        {configPosition.map((itemData, index) => {
          return <div key={index}>
            <Grid spacing={3} container className="axle-analysis-template">

              <Grid item container sm={12} className="vehicle-basic-details axle-survey-template">
                <Grid item sm={10} xs={8}>
                  <div className="xl-heading-column-data">{itemData}</div>
                </Grid>
                <Grid item sm={1} xs={2}>
                  <div className="small-heading-column-data">{globalizationMapping.REPORTCOMMONWORDS.CurrentNS}</div>
                </Grid>
                <Grid item sm={1} xs={2}>
                  <div className="small-heading-column-data end-align">{globalizationMapping.REPORTCOMMONWORDS.HotCold}</div>
                </Grid>

              </Grid>

              <Grid item container sm={12} className="vehicle-basic-details axle-survey-template">

                <Grid item xs={6} sm={2}>
                  <div className="small-heading-column-data underline-style left-spaceing">{globalizationMapping.REPORTTABLE.SIZE} </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTCOMMONWORDS.TireManuf} </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTTABLE.TYPE}</div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div className="small-heading-column-data underline-style">PR</div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div className="small-heading-column-data underline-style">Comp</div>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <div className="small-heading-column-data underline-style">(O) </div>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <div className="small-heading-column-data underline-style end-align"> (H)</div>
                </Grid>
              </Grid>

              <Grid item container sm={12} className="vehicle-basic-details axle-survey-template">

                <Grid item xs={6} sm={3}>
                  <div className="small-heading-column-data underline-style left-spaceing">
                    {globalizationMapping.REPORTCOMMONWORDS.CustomerNo}
                  </div>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <div className="small-heading-column-data underline-style">{globalizationMapping.FLEETSURVEY.SERIALNUMBER}  </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div className="small-heading-column-data underline-style">{globalizationMapping.REPORTCOMMONWORDS.TireHours}  </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div className="small-heading-column-data underline-style">Ind. Cod3 </div>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <div className="small-heading-column-data underline-style">(C) </div>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <div className="small-heading-column-data underline-style end-align"> (C)</div>
                </Grid>
              </Grid>

              <Grid item container sm={12} className="vehicle-basic-details axle-survey-template">
                <Grid item sm={10}>
                  <div className="small-heading-column-data underline-style left-spaceing">{globalizationMapping.REPORTTABLE.COMMENTS} </div>
                </Grid>
              </Grid>

              <Grid item container sm={12} className="vehicle-basic-details axle-survey-template">
                <Grid item sm={10}>
                  <div className="small-heading-column-data underline-style left-spaceing">-</div>
                </Grid>
              </Grid>

            </Grid>
          </div>

        })}
      </div>

      <EmailModalComponent
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        surveyTemplate={true}
        axleConfigurationDetails={axleConfigurationDetails}
        locationNameSelected={locationNameSelected}
      />
    </div>
  )
}

export default SurveyTemplate
