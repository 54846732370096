import Globalization from "../../../globalization/index";

class ByPercentageWornDataSetter {
  constructor() {
    this.byPercentageWornData = [];
    this.globalizationMapping = new Globalization();
    this.data = {};
    this.headCells = [];
  }
  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "AverageMiles")
        selectedColShow[headCell.value] = false;
      else selectedColShow[headCell.value] = true;
    });
    //
    return selectedColShow;
  }
  // initDisplayColumnData(headCells) {
  //     let selectedColShow = {}
  //     headCells.forEach(headCell => {
  //         selectedColShow[headCell.value] = headCell.value !== 'AverageMiles' ? true : false
  //     })
  //     //
  //     return selectedColShow
  // }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
        return (
          item.ManufacturerName.toLowerCase().includes(searchText) ||
          item.SizeName.toLowerCase().includes(searchText) ||
          item.TypeName.toLowerCase().includes(searchText) ||
          item.CompoundName.toLowerCase().includes(searchText) ||
          item.TireCount.toString().includes(searchText) ||
          item.PercentageWorn.toString().includes(searchText) ||
          item?.AverageHours?.toString().includes(searchText) ||
          item?.AverageMiles?.toString().includes(searchText)
        );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    let filterModifiedCategoryData = JSON.parse(
      JSON.stringify(filterOptions.categoryData)
    );
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(
            rowData[category.value]
          )
        ) {
          filterInitCategoryData[category.value].push(
            rowData[category.value].toString()
          );
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data,
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    //
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    //
    //

    let categoryKeys = Object.keys(appliedFilter);

    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey]))
          return rowData;
        return false;
      });
    });
    //
    return filteredTable;
  }

  avgValues = (byPercentageWornData) => {
    let TotalTires = 0,
      HoursSum = 0,
      HoursMile = 0;
    byPercentageWornData.forEach((item) => {
      TotalTires += item.TireCount;
      HoursSum += item.TotalAverageHours;
      HoursMile += item.TotalAverageMiles;
    });

    let TotalTiresSum = TotalTires;
    let HoursAvg = Math.round(HoursSum / TotalTires);
    let HoursMileAvg = Math.round(HoursMile / TotalTires);

    const avgValues = {
      ManufacturerName: "Total / Average",
      SizeName: "",
      TypeName: "",
      CompoundName: "",
      PercentageWorn: "",
      TireCount: TotalTiresSum,
      AverageHours: HoursAvg,
      AverageMiles: HoursMileAvg,
    };
    return avgValues;
  };

  setData(data) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      this.byPercentageWornData.push({
        Key: index,
        ManufacturerName: item.manufacturerName ? item.manufacturerName : "",
        SizeName: item.sizeName ? item.sizeName : "",
        TypeName: item.typeName ? item.typeName : "",
        CompoundName: item.compoundName ? item.compoundName : "",
        PercentageWorn: item.percentageWorn ? item.percentageWorn : 0,
        TireCount: item.tireCount ? item.tireCount : 0,
        AverageHours: item.averageHours ? item.averageHours : 0,
        TotalAverageHours: item.averageHours
          ? item.averageHours * item.tireCount
          : 0,
        AverageMiles: item.averageMiles ? item.averageMiles : 0,
        TotalAverageMiles: item.averageMiles
          ? item.averageMiles * item.tireCount
          : 0,
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
            item.dataType === "currency" ||
            item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);

    return {
      rowsData: this.byPercentageWornData,
      headCells: this.headCells,
      avgValues: this.avgValues(this.byPercentageWornData),
      selectedColShow: selectedColShow,
    };
  }
}

export default ByPercentageWornDataSetter;
