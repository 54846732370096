import React, { useEffect } from 'react'
import RedTire from '../../../../assets/RedTire.png'
import YellowTire from '../../../../assets/YellowTire.png'
import OrangeTire from '../../../../assets/OrangeTire.png'
import EmptyTire from '../../../../assets/tire-empty-new.png'
import GreenTire from '../../../../assets/GreenTire.png'
import EmptyTire_with_rim from '../../../../assets/EmptyTire_with_rim.png'
import RedTire_with_rim from '../../../../assets/RedTire_with_rim.png'
import YellowTire_with_rim from '../../../../assets/YellowTire_with_rim.png'
import OrangeTire_with_rim from '../../../../assets/OrangeTire_with_rim.png'
import GreenTire_with_rim from '../../../../assets/GreenTire_with_rim.png'
import Axle1 from '../../../../assets/axle-1-v.svg'
import Axle2 from '../../../../assets/axle-2-v.svg'
import Axle3 from '../../../../assets/axle-3-v.svg'
import Axle4 from '../../../../assets/axle-4-v.svg'
import Axle5 from '../../../../assets/axle-5-v.svg'
import Axle6 from '../../../../assets/axle-6-v.svg'
import Axle7 from '../../../../assets/axle-7-v.svg'
import Axle8 from '../../../../assets/axle-8-v.svg'
import Axle9 from '../../../../assets/axle-9-v.svg'
import './VehicleDiagram.css'

const VehicleDiagram = ({ data, tireListData, index, from }) => {
  useEffect(() => {
    initiateTireCreation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const imageUrl = [Axle1, Axle2, Axle3, Axle4, Axle5, Axle6, Axle7, Axle8, Axle9];
  let tireConfig = data;
  let tireList = tireListData;
  let configArray = tireConfig.split("");
  let maximumTires = Math.max.apply(null, configArray) / 2;
  let tireCnfPos = [];
  let tireInput = [];
  let wheelPosition = tireListData[index]?.wheelPosition

  const initiateTireCreation = () => {
    if (tireList.length !== 0) {
      generateTirePositions();
    }
    if (tireList.length !== 0) {
      formatTireList();
    }
  };
  const generateTirePositions = () => {
    for (let i = 0; i < configArray.length; i++) {
      let eachAxleTires = {};
      eachAxleTires["L"] = createTires(configArray[i], false, i);
      eachAxleTires["R"] = createTires(configArray[i], true, i);
      eachAxleTires["config"] = configArray[i];
      tireCnfPos.push(eachAxleTires);
    }
    return tireCnfPos;
  };

  const createTires = (eachConfig, rightTire, currentIndex) => {
    const oneSideTire = eachConfig / 2;
    const currentPrePos = currentIndex + 1;
    let items = [];
    let tirePositionNo = 1;
    let tirePositionStr = rightTire ? "R" : "L";
    let tirePosition = "";
    for (let i = 0; i < maximumTires; i++) {
      let hastire = false;
      if (rightTire) {
        hastire = i < oneSideTire;
      } else {
        hastire = i >= maximumTires - oneSideTire;
      }
      if (hastire) {
        if (oneSideTire > 1) {
          tirePosition = currentPrePos + changePositionKeys(tirePositionStr, tirePositionNo);
        } else {
          tirePosition = currentPrePos + tirePositionStr;
        }
        tirePositionNo++;
      }
      items.push({
        hastire: hastire,
        tirePos: tirePosition
      });
    }
    return items;
  };

  const changePositionKeys = (positionString, positionNo) => {
    let positionKey = "";
    if (isFinite(positionNo)) {
      if (positionString === "L") {
        positionKey = positionNo % 2 ? "LO" : "LI";
      } else {
        positionKey = positionNo % 2 ? "RI" : "RO";
      }
    }
    return positionKey;
  };
  const formatTireList = () => {
    tireList.forEach((obj) => {
      tireInput[obj.wheelPosition] = obj;
    });

    return tireInput;
  };
  let generateTirePositionData = generateTirePositions();
  let generateTireListData = formatTireList();

  const TireStatusAndRimImageSelector = (tirePositionElement, percentWorn, isSelected, hastTireId, hasMountedRimId) => {

    const imageSelector = () => { // select if image with rim, tire or both
      if (hastTireId) {
        switch (true) {
          case percentWorn > 75:
            return hasMountedRimId ? RedTire_with_rim : RedTire;
          case percentWorn > 50 && percentWorn <= 75:
            return hasMountedRimId ? OrangeTire_with_rim : OrangeTire;
          case percentWorn > 25 && percentWorn <= 50:
            return  hasMountedRimId ? YellowTire_with_rim : YellowTire;
          case percentWorn <= 25:
            return hasMountedRimId ? GreenTire_with_rim : GreenTire;
          default:
            return EmptyTire;
        }
      } else if (hasMountedRimId) {
        return EmptyTire_with_rim;
      } else {
        return EmptyTire;
      } 
  }
console.log(tireList,'tireList')
    for (let index = 0; index < tireList.length; index++) {
      if (tireList[index].wheelPosition === tirePositionElement) {
        if (hastTireId || hasMountedRimId) {
          return <img src={imageSelector()} alt="Tire" key={index} className={(isSelected) ? `${from}-selected-tire` : `${from}-not-selected`} />; 
        } else {
          return <img src={EmptyTire} className="remove-tire-empty-tire" alt="Tire" key={index} />;
        }
      } else if ((tireList[index].wheelPosition !== tirePositionElement) && (!hastTireId) && (!hasMountedRimId)) {
          return <img src={imageSelector()} className="remove-tire-empty-tire" alt="Tire" key={index} />;
        }
    } 
  };
  return (
    <div>
      <table className="tire-table rotate-vehicle-diagram">
        <tbody>
          <tr className="display-inline">
            <td className="display-block">
              {generateTirePositionData.map((item, index) => {
                if (item["R"] && item["R"][1] !== undefined) {
                  if (item["R"][1].hastire === true) {
                    let percentWorn = generateTireListData[item["R"][1].tirePos] && generateTireListData[item["R"][1].tirePos].percentageWorn;
                    let isR1Selected = item["R"][1]?.tirePos == wheelPosition
                    if (item["R"][1].tirePos) {
                      return TireStatusAndRimImageSelector(item["R"][1].tirePos, percentWorn, isR1Selected, generateTireListData[item["R"][1].tirePos]?.tireId, generateTireListData[item["R"][1].tirePos]?.mountedRimId);
                    } else {
                      return <img src={EmptyTire} className="remove-tire-empty-tire" alt="Tire" key={index} />;
                    }
                  } else {
                    return <img className="no-tire" key={index}></img>;
                  }
                }
              })}
            </td>
            <td className="display-block">
              {generateTirePositionData.map((item, index) => {
                if (item["R"] && item["R"][0] !== undefined) {
                  if (item["R"][0].hastire === true) {
                    let percentWorn = generateTireListData[item["R"][0].tirePos] && generateTireListData[item["R"][0].tirePos].percentageWorn;
                    let isR0Selected = item["R"][0]?.tirePos == wheelPosition
                    if (item["R"][0].tirePos) {
                    return TireStatusAndRimImageSelector(item["R"][0].tirePos, percentWorn, isR0Selected, generateTireListData[item["R"][0].tirePos]?.tireId, generateTireListData[item["R"][0].tirePos]?.mountedRimId);
                    } else {
                      return <img src={EmptyTire} className="remove-tire-empty-tire" alt="Tire" key={index} />;
                    }
                  } else {
                    return <img className="no-tire" key={index}></img>;
                  }
                }
              })}
            </td>
          </tr>
          <tr>
            {generateTirePositionData !== null &&
              Object.keys(generateTirePositionData).map((key, index) => {
                return (
                  <td className={`display-inline tire-axle-number axle-${key}`}>
                    <img src={imageUrl[key]} alt="Tire" key={index} />
                  </td>
                );
              })}
          </tr>

          <tr className="display-inline">
            <td className="display-block">
              {generateTirePositionData.map((item, index) => {
                if (item["L"] && item["L"][1] !== undefined) {
                  if (item["L"][1].hastire === true) {
                    let percentWorn = generateTireListData[item["L"][1].tirePos] && generateTireListData[item["L"][1].tirePos].percentageWorn;
                    let isL1Selected = item["L"][1]?.tirePos == wheelPosition
                    if (item["L"][1].tirePos) {
                    return  TireStatusAndRimImageSelector(item["L"][1].tirePos, percentWorn, isL1Selected, generateTireListData[item["L"][1].tirePos]?.tireId, generateTireListData[item["L"][1].tirePos]?.mountedRimId);
                    } else {
                      return <img src={EmptyTire} className="remove-tire-empty-tire" alt="Tire" key={index} />;
                    }
                  } else {
                    return <img className="no-tire" key={index}></img>;
                  }
                }
              })}
            </td>
            <td className="display-block">
              {generateTirePositionData.map((item, index) => {
                if (item["L"] && item["L"][0] !== undefined) {
                  if (item["L"][0].hastire === true) {
                    let percentWorn = generateTireListData[item["L"][0].tirePos] && generateTireListData[item["L"][0].tirePos].percentageWorn;
                    let isL0Selected = item["L"][0]?.tirePos == wheelPosition
                    if (item["L"][0].tirePos) {
                      return  TireStatusAndRimImageSelector(item["L"][0].tirePos, percentWorn, isL0Selected, generateTireListData[item["L"][0].tirePos]?.tireId, generateTireListData[item["L"][0].tirePos]?.mountedRimId);
                    } else {
                      return <img src={EmptyTire} className="remove-tire-empty-tire" alt="Tire" key={index} />;
                    }
                  } else {
                    return <img className="no-tire" key={index}></img>;
                  }
                }
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default VehicleDiagram;
