import { Badge, Button, FormHelperText, Grid, InputLabel, makeStyles, Select, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import "./create-tire.css";
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';
import deleteIcon from "../../../assets/delete-icon.svg";
import ExpandMoreStyledIcon from '../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon';

const Styles = makeStyles({
    root: {
        "& input.MuiAutocomplete-inputFocused": {
            color: "#000000 !important",
            fontWeight: "bold"
        }
    },
    input: {
        display: 'none'
    },
    FormHelperText: {
        align: 'center'
    },
    deleteBadge: {
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            top: '10px',
            right: '17px',
            background: 'transparent'
        }
    },
    expandIcon: {
        color: "#0055A4",
        cursor: "pointer",
        top: "calc(50% - 12px)",
        position: "absolute",
        pointerEvents: "none",
        right: "7px"
    }
});


const Description = props => {
    const classes = Styles();
    const manufacturersList = props.masterDataSet.tireManufacturers;
    const starRatings = props.masterDataSet.starRating;
    const plyRatings = props.masterDataSet.plyRating;
    const totalIndCodes = props.masterDataSet.tireIndCodes;
    const totalCompounds = props.masterDataSet.tireCompounds;
    const totalLoadRatings = props.masterDataSet.tireLoadRatings;
    const totalSpeedRatings = props.masterDataSet.tireSpeedRatings;

    return (
        <React.Fragment>
            <Grid className="step-form-inner-container" container spacing={2} >
                <Grid item sm={12} >
                    <p className="field-label">{props.fieldNames.MANUFACTURER} <span className="required-mark">*</span></p>
                    <Select
                        required
                        native
                        autoFocus={props.formHelperTexts.manufacturerId !== undefined}
                        className="enter-detail-input-box" variant="outlined"
                        value={props.stateVar.manufacturerId}
                        IconComponent={ExpandMoreStyledIcon}
                        onChange={e => { props.onChangeListener('manufacturer', parseInt(e.target.value, 10)); }}
                    >
                        <option key={0} value={""} hidden> {props.fieldNames.DROPDOWNPLACEHOLDER} </option>
                        {manufacturersList.map((mfcr, key) =>
                            <option key={key + 1} value={mfcr.manufacturerId} className="list-color-change">{mfcr.manufacturerName}</option>
                        )}
                    </Select>
                    {(props.stateVar.manufacturerId === 0 && props.isManufacturerEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                    {(props.isManufacturerEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.manufacturerId}</FormHelperText>)}
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.SIZE} <span className="required-mark">*</span></p>
                    <Autocomplete
                        size="small"
                        autoFocus={props.formHelperTexts.sizeId !== undefined}
                        className={props.stateVar.manufacturerId === 0 ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                        value={props.selectedSize}
                        onChange={(_event, newValue) => props.onChangeListener('sizeid', newValue)}
                        options={props.tireSizesByManuf}
                        getOptionLabel={options => (options.tireSizeName !== undefined ? options.tireSizeName : '')}
                        renderInput={params => <TextField {...params}
                            className={classes.root}
                            id="abc"
                            placeholder={props.fieldNames.SEARCHSIZE}
                            variant="outlined"
                            inputProps={{...params.inputProps, maxLength: 50 }}

                        />}
                        disabled={props.stateVar.manufacturerId === 0}
                    />
                    {(props.selectedSize?.tireSizeId === 0 && props.isSizeEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                    {(props.isSizeEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.sizeId}</FormHelperText>)}
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.TYPE} <span className="required-mark">*</span></p>
                    <Select
                        required
                        native
                        className={Object.keys(props.selectedSize).length === 0 ? "enter-detail-input-box disabled-input" : "enter-detail-input-box"}
                        variant="outlined"
                        value={props.stateVar.typeId}
                        IconComponent={ExpandMoreStyledIcon}
                        onChange={e => props.onChangeListener('typeid', parseInt(e.target.value, 10))}
                        displayEmpty
                        disabled={Object.keys(props.selectedSize).length === 0}>
                        <option key={0} value={""} hidden> {props.fieldNames.DROPDOWNPLACEHOLDER} </option>
                        {props.tireTypesBySize.map((type, key) => <option key={key + 1} value={type.typeId} className="list-color-change">{type.typeName}</option>)}
                    </Select>
                    {(props.stateVar.typeId === 0 && props.isTypeEdited) ? <FormHelperText>{`This field is required`}</FormHelperText> : ''}
                    {(props.isTypeEdited ? "" : <FormHelperText className={classes.FormHelperText}>{props.formHelperTexts.typeId}</FormHelperText>)}
                </Grid>
                {props.istireRadial ?
                    <Grid item sm={12}>
                        <p className="field-label">{props.fieldNames.STARRATING}</p>
                        <Select
                            required
                            native className="enter-detail-input-box"
                            variant="outlined" value={props.stateVar.starRatingId}
                            IconComponent={ExpandMoreStyledIcon}
                            onChange={e => props.onChangeListener('star', parseInt(e.target.value, 10))}
                        >
                            <option key={0} value={""} hidden> {props.fieldNames.DROPDOWNPLACEHOLDER} </option>
                            {starRatings.map((rating, key) =>
                                <option key={key + 1} value={rating.ratingId} className="list-color-change">{rating.ratingName}</option>
                            )}
                        </Select>
                    </Grid> :
                    <Grid item sm={12}>
                        <p className="field-label">{props.fieldNames.PLYRATING}</p>
                        <Select
                            required
                            native className="enter-detail-input-box"
                            variant="outlined" value={props.stateVar.plyId}
                            IconComponent={ExpandMoreStyledIcon}
                            onChange={e => props.onChangeListener('ply', parseInt(e.target.value, 10))} helperText=""
                        >
                            <option key={0} value={""} hidden> {props.fieldNames.DROPDOWNPLACEHOLDER} </option>
                            {plyRatings.map((rating, key) =>
                                <option key={key + 1} value={rating.ratingId} className="list-color-change">{rating.ratingName}</option>
                            )}
                        </Select>
                    </Grid>
                }
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.INDCODE}</p>
                    <Select
                        required
                        native
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.indCodeId}
                        onChange={e => props.onChangeListener('indcode', parseInt(e.target.value, 10))}
                        IconComponent={ExpandMoreStyledIcon}
                        displayEmpty
                        helperText="" >
                        <option key={0} value={""} hidden> {props.fieldNames.DROPDOWNPLACEHOLDER} </option>
                        {totalIndCodes.map((indCode, key) =>
                            <option key={key + 1} value={indCode.codeId} className="list-color-change">{indCode.codeName}</option>
                        )}
                    </Select>
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.COMPOUND}</p>
                    <Select
                        required
                        native
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.compoundId}
                        onChange={e => props.onChangeListener('compound', parseInt(e.target.value, 10, 10))}
                        IconComponent={ExpandMoreStyledIcon}
                        displayEmpty
                        helperText="" >
                        <option key={0} value={""} hidden> {props.fieldNames.DROPDOWNPLACEHOLDER} </option>
                        {totalCompounds.map((compound, key) =>
                            <option key={key + 1} value={compound.compoundId} className="list-color-change">{compound.compoundName}</option>
                        )}
                    </Select>
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.LOADRATING}</p>
                    <Select
                        required
                        native
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.loadRatingId}
                        onChange={e => props.onChangeListener('loadrtg', parseInt(e.target.value, 10))}
                        IconComponent={ExpandMoreStyledIcon}
                        displayEmpty
                        helperText="" >
                        <option key={0} value={""} hidden> {props.fieldNames.DROPDOWNPLACEHOLDER} </option>
                        {totalLoadRatings.map((load, key) =>
                            <option key={key + 1} value={load.ratingId} className="list-color-change">{load.ratingName}</option>
                        )}
                    </Select>
                </Grid>
                <Grid item sm={12}>
                    <p className="field-label">{props.fieldNames.SPEEDRATING}</p>
                    <Select
                        required
                        native
                        className="enter-detail-input-box"
                        variant="outlined"
                        value={props.stateVar.speedRatingId}
                        onChange={e => props.onChangeListener('speed', parseInt(e.target.value, 10))}
                        IconComponent={ExpandMoreStyledIcon}
                        helperText="" >
                        <option key={0} value={""} hidden> {props.fieldNames.DROPDOWNPLACEHOLDER} </option>
                        {totalSpeedRatings.map((speed, key) =>
                            <option key={key + 1} value={speed.speedRatingId} className="list-color-change">{speed.speedRatingName}</option>
                        )}
                    </Select>
                </Grid>
                <Grid item sm={12}>
                    <Grid className="upload-box-description" container spacing={1}>
                        <Grid item sm={6}>
                            <InputLabel
                                className="upload-label"
                            >{props.fieldNames.UPLOADCAPTION}</InputLabel>
                        </Grid>
                        <Grid item sm={6}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="image-input-hidden"
                                onChange={e => { props.onChangeListener('images', e.target); }}
                                type="file" />
                            <label htmlFor="image-input-hidden">
                                <Button
                                    className="upload-Button"
                                    variant="outlined"
                                    size="large"
                                    aria-label="upload picture"
                                    component="span"
                                    startIcon={<PhotoCameraRoundedIcon />} >
                                    {props.fieldNames.UPLOAD}
                                </Button>
                            </label>
                        </Grid>
                    </Grid>
                    <FormHelperText className={classes.FormHelperText}>{props.imageHelper}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    {(props.dbImages.length !== 0 || props.stateVar.imgsrc.length !== 0) &&
                        <p className="field-label-upload">{props.fieldNames.IMAGESCAPTION}</p>}
                    <Grid container spacing={1} className="uploaded-images">
                        {props.dbImages.length !== 0 && props.dbImages.map((source, key) =>
                            <Badge className={classes.deleteBadge} key={key}
                                badgeContent={<img alt="delete" src={deleteIcon} onClick={() => props.deleteDbImage(key)} />} color="primary" variant="standard">
                                <Grid item sm={4} className="uploaded-image">
                                    <img alt={"stored-img-".concat((key + 1).toString())} src={source} width="160px" height="160px" />
                                </Grid>
                            </Badge>
                        )}
                        {props.stateVar.imgsrc.length !== 0 && props.stateVar.imgsrc.map((source, key) =>
                            <Badge className={classes.deleteBadge} key={key}
                                badgeContent={<img alt="delete" src={deleteIcon} onClick={() => props.removeUploadedPhoto(key)} />} color="primary" variant="standard">
                                <Grid item sm={4} className="uploaded-image">
                                    <img alt={"chosen-img-".concat((key + 1).toString())} src={source} width="160px" height="160px" />
                                </Grid>
                            </Badge>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Description;
