import Globalization from "../../../globalization/index";

class ScrapCostByTireSizeDataSetter {
  constructor() {
    this.ScrapCostByTireSizeGraphData = [];
    this.globalizationMapping = new Globalization();
  }

  setGraphData(data) {
    let graphData = data.model.graphData;
    return graphData;
  }
  setDisplayColumns(graphData) {
    let displayColumns = [];
    graphData.chartlabels.forEach((item, index) => {
      displayColumns.push({
        key: item,
        keyIndex: index,
        value: item,
        label: item,
        count: graphData.chartData[0].data[index]
      });
    });
    return displayColumns;
  }
  setDisplayColumnData(ScrapCostByTireSizeGraphData, selectedCols) {
    let bS = { chartlabels: [], chartData: [{ data: [] }] };
    for (let graphIndex = 0; graphIndex < ScrapCostByTireSizeGraphData.chartlabels.length; graphIndex++) {
      for (const element of selectedCols) {
        if (element.value === ScrapCostByTireSizeGraphData.chartlabels[graphIndex]) {
          bS.chartlabels[graphIndex] = element.value;
          bS.chartData[0].data[graphIndex] = element.count;
          break;
        }
      }
    }
    bS.chartlabels = bS.chartlabels.filter((item) => item !== undefined);
    bS.chartData[0].data = bS.chartData[0].data.filter((item) => item !== undefined);
    return bS;
  }
}

export default ScrapCostByTireSizeDataSetter;
