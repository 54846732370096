import React, { useContext, useEffect, useState } from "react";
import "./create-rim.css";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import Breadcrumbs from "../../common-components/breadcrumbs/breadcrumbs";
import Globalization from "../../globalization";
import {
    Button,
    makeStyles,
    Step,
    withStyles,
    StepConnector,
} from "@material-ui/core";
import { useHistory, useParams, useLocation } from "react-router-dom";
import HeaderApi from "../../common-components/header/services/header-apis";
import { StateContext } from "../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../services/AccountInfoHandler";
import Spinner from "../../common-components/spinner/spinner";
import rightArrow from "../../assets/Arrow.svg";
import FlashMessage from "../../common-components/pop-up-notification/notification-snackbar";
import Identification from "./step-identification";
import Description from "./step-description";
import Costs from "./step-costs";
import "../create-vehicle/create-vehicle.css";
import ChangeLocationModal from "./modals/ChangeLocation";

const CreateRim = () => {
    const history = useHistory();
    const globalizationMapping = new Globalization();
    const globalHeaderTexts = globalizationMapping.HEADER;
    const globalCreateRimTexts = globalizationMapping.CREATERIMSTEPS;
    const [globalAccountState] = useContext(StateContext);
    const rimFormCaptions = globalizationMapping.FORMS;
    const validationTexts = rimFormCaptions.VALIDATIONTEXTS;
    const notificationTexts = rimFormCaptions.NOTIFICATIONTEXTS;
    const { RimId } = useParams();
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    today = `${mm}/${dd}/${yyyy}`;
    const locationObj = useLocation();
    const [isClonedClicked, setIsClonedClicked] = useState(false);

    const myBreadcrumbsName = () => {
        if ((locationObj.state && locationObj.state.option === "Clone") || isClonedClicked) {
            return globalHeaderTexts.CREATERIM;
        } else {
            if (RimId) {
                return globalHeaderTexts.EDITRIM;
            } else {
                return globalHeaderTexts.CREATERIM;
            }
        }
    }

    const myBreadcrumbs = [
        { path: "home", name: globalHeaderTexts.HOME },
        {
            path: "",
            name: myBreadcrumbsName()
        },
    ];
    const [showSpinner, setShowSpinner] = useState(true);
    const useStyles = makeStyles({
        width: "100%",
        next: {
            "&:hover": {
                opacity: 1,
            },
            background:
                "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
            borderRadius: 18,
            font: "Barlow",
            opacity: 0.8,
            fontWeight: 500,
            color: "#FFFFFF",
            boxShadow: "none",
        },
        previous: {
            "&:hover": {
                backgroundColor: "#EAF3FB",
                borderRadius: 18,
                border: "1px solid #EAF3FB",
                opacity: 1,
                boxShadow: "none",
            },
            background: "transparent",
            border: "1px solid #EAF3FB",
            borderRadius: 18,
            color: "#0055A4",
            opacity: 0.8,
            letterSpacing: 0,
            font: "Barlow",
            fontWeight: 600,
            zIndex: 0,
            boxShadow: "none",
            marginRight: "10px",
        },
        cancel: {
            "&:hover": {
                backgroundColor: "#EAF3FB",
                borderRadius: 18,
                border: "none",
                opacity: 1,
                boxShadow: "none",
            },
            background: "transparent",
            border: "none",
            borderRadius: 18,
            color: "#0055A4",
            opacity: 0.8,
            letterSpacing: 0,
            fontWeight: 600,
            zIndex: 0,
            boxShadow: "none",
            marginRight: 5,
        },
        rootStepper: {
            "& .MuiStepIcon-root": {
                color: "#CFDBEB",
                borderRadius: "50%",
                height: "35px",
                width: "35px",
                marginTop: "9px",
            },
            "& .MuiStepIcon-active": {
                color: "#FEDD00 !important",
                borderColor: "#0D54B5",
                border: "2px solid",
                borderRadius: "50%",
                height: "35px",
                width: "35px",
            },
            "& .MuiStepIcon-completed": {
                color: "#1BB121 !important",
            },
            "& .MuiStepIcon-text": {
                fill: "#0D0D0D",
                opacity: "0.4",
            },
            " & .MuiStepLabel-label": {
                marginTop: "12px",
                textAlign: "inherit",
            },
            "& .MuiStepLabel-root": {
                flexDirection: "row",
                marginLeft: "25%",
            },
            "& .MuiStepper-horizontal": {
                borderRadius: "4px",
                display: "flex",
                backgroundColor: "#E1EAF5 !important",
                border: "1px solid #D0D0D0 !important",
                opacity: "1",
            },
            "& .MuiStepIcon-active .MuiStepIcon-text": {
                fill: "#0D54B5 !important",
                opacity: "1 !important",
            },
            "& .MuiStepLabel-label": {
                marginLeft: "5%",
                fontSize: "1.187rem",
            },
        },
        rootStepper2: {
            "& .MuiStepIcon-root": {
                color: "#1BB121",
                borderRadius: "50%",
                border: "2px solid",
                borderColor: "#0D54B5",
                height: "35px",
                width: "35px",
                marginTop: "9px",
            },
            "& .MuiStepIcon-active": {
                color: "#FEDD00 !important",
                borderColor: "#0D54B5",
                border: "2px solid",
                borderRadius: "50%",
                height: "35px",
                width: "35px",
            },
            "& .MuiStepIcon-completed": {
                color: "#1BB121 !important",
                border: "none !important",
            },
            "& .MuiStepIcon-text": {
                fill: "#FFFFFF",
                opacity: "0.4",
            },
            " & .MuiStepLabel-label": {
                marginTop: "12px",
                textAlign: "inherit",
                fontSize: "1.187rem",
            },
            "& .MuiStepLabel-root": {
                flexDirection: "row",
                marginLeft: "25%",
            },
            "& .MuiStepper-horizontal": {
                borderRadius: "4px",
                display: "flex",
                backgroundColor: "#E1EAF5 !important",
                border: "1px solid #D0D0D0 !important",
                opacity: "1",
            },
            "& .MuiStepIcon-active .MuiStepIcon-text": {
                fill: "#0D54B5 !important",
                opacity: "1 !important",
            },
            "& .MuiStepLabel-label": {
                marginLeft: "5%",
            },
        },
    });
    const styling = useStyles();
    const [currentStep, setCurrentStep] = useState(0);
    const steps = getSteps();
    const [userPreferences, setUserPreferences] = useState({});
    const [trackingMethod, setTrackingMethod] = useState(1);
    const [vehicleIDPos, setVehicleIDPos] = useState();
    const [rimDetails, setRimDetails] = useState({
        barCode: "",
        brandNo: "",
        checkInterval: 0,
        costAdjustment: 0,
        currentHours: 0,
        currentMiles: 0,
        dispositionId: 1,
        vehicleId:"",
        wheelPosition:"",
        evalNo: "",
        hoursLeft: 0,
        inspectionIntervalHours: 0,
        inspectionIntervalMiles: 0,
        locationId: accountInfoIdsFormatter(globalAccountState).locationIds[0],
        lotNo: "",
        manufacturerId: 0,
        maxInflationRating: "",
        mileageType: 0,
        milesLeft: 0,
        netCost: 0,
        nextCheck: today,
        parentAccountId:
            accountInfoIdsFormatter(globalAccountState).accountIds.toString(),
        ponumber: "",
        purchaseCost: 0,
        registrationDate: today,
        repairCost: 0,
        repairCount: 0,
        rimSerialNo: "",
        sizeId: 0,
        soldAmount: 0,
        tireId: "",
        tireSerialNo: "",
        torqueSpec: "",
        typeId: 0,
        valveTypeId: 0,
        vehicleNumber: "",
        wheelPosition: "",
        rimInstallDate: "",
        lastChecked: "",
        inspector: globalAccountState.accountInfo.userSetting.userId,
        rimStatusId: 0,
    });
    const headerApiObj = new HeaderApi();
    const [masterData, setMasterData] = useState({});
    const [tireData, setTireData] = useState([]);
    const [showCreateClonedNotif, setCreateClonedNotif] = useState(false);
    const [showEditClonedNotif, setEditClonedNotif] = useState(false);
    const [rimClonedEditNotif, setRimClonedEditNotif] = useState({});
    const [rimClonedCreateNotif, setRimClonedCreateNotif] = useState({});
    const [isListingClonedClicked, setIsListingClonedClicked] = useState(false);
    const [accLocationDetails, setAccLocationDetails] = useState({});
    const [openChangeLocation, setOpenChangeLocation] = useState(false);
    const [isRimSerialNumberEdited, setIsRimSerialNumberEdited] = useState(false);
    const [isCurrentHoursEdited, setIsCurrentHoursEdited] = useState(false);
    const [isCurrentMilesEdited, setIsCurrentMilesEdited] = useState(false);
    const [isManufacturerEdited, setIsManufacturerEdited] = useState(false); // should apply to create vehicle and create tire
    const [isNewManufacturerNameEdited, setIsNewManufacturerNameEdited] = useState(false);
    const [isTypeEdited, setIsTypeEdited] = useState(false); // continue here
    const [isNewTypeNameEdited, setIsNewTypeNameEdited] = useState(false);
    const [isSizeEdited, setIsSizeEdited] = useState(false);
    const [isInspectionIntervalHoursEdited, setIsInspectionIntervalHoursEdited] = useState(false);
    const [isInspectionIntervalMilesEdited, setIsInspectionIntervalMilesEdited] = useState(false);
    const [isHoursLeftEdited, setIsHoursLeftEdited] = useState(false);
    const [isMilesLeftEdited, setIsMilesLeftEdited] = useState(false);
    const [vehicleListDetails, setVehicleListDetails] = useState([]);
    const [currentDispositionSate, setCurrentDispositionSate] = useState(rimDetails.dispositionId);



    const resetTextFieldIsEditState = () => {
        setIsRimSerialNumberEdited(false);
        setIsCurrentHoursEdited(false);
        setIsCurrentMilesEdited(false);
        setIsManufacturerEdited(false);
        setIsNewManufacturerNameEdited(false);
        setIsNewTypeNameEdited(false);
        setIsTypeEdited(false);
        setIsSizeEdited(false);
        setIsInspectionIntervalHoursEdited(false);
        setIsInspectionIntervalMilesEdited(false);
        setIsHoursLeftEdited(false);
        setIsMilesLeftEdited(false);

      }

    useEffect(() => {
        setShowSpinner(true);
        const accountInfoObject = accountInfoIdsFormatter(globalAccountState);
        const locationIds = accountInfoObject.locationIds;
        headerApiObj.getUserPreference().then(data => {
            if (data.model !== undefined) {
                setUserPreferences(data.model);
            }
        });
        headerApiObj.getLocationById(locationIds[0]).then(locationData => {
            if (locationData && locationData.model) {
                if (RimId === undefined) {
                    const locationJson = { ...rimDetails };
                    locationJson.locationId = locationData.model.locationId;
                    locationJson.parentAccountId = locationData.model.parentAccountId;
                    setRimDetails(locationJson);
                }
                setAccLocationDetails(locationData.model);
                headerApiObj.getVehicleByLocation(locationData.model.locationId).then(vehicleData => {
                    setVehicleListDetails(vehicleData.model);
                });
            }
        });

        headerApiObj.getMasterDataWeb().then(data => {
            if (data.model !== undefined) {
                setMasterData(data.model);
                if (RimId) {
                    headerApiObj.getRim(RimId).then(rimData => {
                        if (rimData.model !== undefined) {
                            setShowSpinner(false);
                            if (rimData.model) {
                                setRimDetails({
                                    ...rimDetails,
                                    ...rimData.model,  
                                    currentHours : rimData.model.currentHours ? parseInt(rimData.model.currentHours,10) : 0,
                                    currentMiles : rimData.model.currentMiles ? parseInt(rimData.model.currentMiles,10) : 0,
                                    hoursLeft : rimData.model.hoursLeft ? parseInt(rimData.model.hoursLeft,10) : 0,
                                    milesLeft : rimData.model.milesLeft ? parseInt(rimData.model.milesLeft,10) : 0,
                                    inspectionIntervalHours : rimData.model.inspectionIntervalHours ? parseInt(rimData.model.inspectionIntervalHours,10) : 0,
                                    inspectionIntervalMiles : rimData.model.inspectionIntervalMiles ? parseInt(rimData.model.inspectionIntervalMiles,10) : 0,
                                });
                            }
                            setType(rimData.model.manufacturerId);
                            if (rimData.model.vehicleNumber) {
                                setVehicleIDPos(
                                    `${rimData.model.vehicleNumber} / ${rimData.model.wheelPosition}`
                                );
                            }

                            let trackingMethod = parseInt(rimData.model.mileageType, 10) === 0 ? 1 : parseInt(rimData.model.mileageType, 10);
                            setTrackingMethod(trackingMethod);
                            if (locationObj.state && locationObj.state.option === "Clone") {
                                setIsListingClonedClicked(true);
                                setRimDetails({
                                    ...rimDetails,
                                    ...rimData.model,
                                    rimSerialNo: "",
                                    tireSerialNo: "",
                                    tireId: "",
                                });
                            }
                        }
                        setCurrentDispositionSate(rimData.model.dispositionId);
                    });
                }
            }
        });
        headerApiObj.getTiresByAccount(rimDetails.parentAccountId).then(data => {
            if (!RimId) {
                setShowSpinner(false);
            }
            if (data.model !== undefined) {
                setTireData(data.model);
            }
        });
        if (
            RimId === undefined ||
            (locationObj.state && locationObj.state.option === "Clone")
        ) {
            if (globalAccountState.accountInfo.location.length > 1) {
                setOpenChangeLocation(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalAccountState]);

    useEffect(() => {
        setType(rimDetails.manufacturerId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rimDetails]);

    useEffect(() => {
        setRimDetails({ ...rimDetails, mileageType: trackingMethod });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackingMethod]);
    function getSteps() {
        return [
            globalCreateRimTexts.IDENTIFICATION,
            globalCreateRimTexts.DESCRIPTION,
            globalCreateRimTexts.COSTS,
        ];
    }

    const getTotalSteps = () => steps.length;

    const ColorlibConnector = withStyles({
        alternativeLabel: {
            top: 22,
        },
        active: {
            "& $line": {
                content: `url(${rightArrow})`,
                textAlign: "center",
                width: "100%",
                height: "30px",
                border: "none",
                marginTop: "-10px",
                display: "inline-block",
            },
        },
        completed: {
            "& $line": {
                content: `url(${rightArrow})`,
                textAlign: "center",
                width: "100%",
                height: "30px",
                border: "none",
                marginTop: "-10px",
                display: "inline-block",
            },
        },
        line: {
            content: `url(${rightArrow})`,
            textAlign: "center",
            width: "100%",
            height: "30px",
            border: "none",
            marginTop: "-10px",
            display: "inline-block",
        },
    })(StepConnector);

    const HandleStepCase0 = (step) => {
        if (step === 1) {
            if (!validateIdForm()) return true;
        }
        else if (step === 2) {
            if (!validateIdForm() || !validateDescForm())
                return true;
        }
        else {
            if (!validateIdForm() || !validateDescForm() || !validateCostsForm())
                return true;
        }
    }

    const HandleStepCase1 = (step) => {
        if (step === 2) {
            if (!validateDescForm())
                return true;
        }
        else if (step === 3) {
            if (!validateDescForm() || !validateCostsForm())
                return true;
        }
    }

    const handleStep = (step) => {
        resetTextFieldIsEditState();
        if (step <= currentStep)
            setCurrentStep(step)
        else {
            switch (currentStep) {
                case 0:
                    if (HandleStepCase0(step)) return;
                    break;
                case 1:
                    if (HandleStepCase1(step)) return;
                    break;
                default:
                    break;
            }
            setCurrentStep(step)
        }
    }
    const [idFormErrors, setIdFormErrors] = useState({});
    const validateIdForm = () => {
        
        let isFormValid = true;
        const FormErrors = {};
        Object.keys(rimDetails).forEach(key => {
            if ((key === "rimSerialNo" && rimDetails[key] === "") || (
                key === "registrationDate" && (isNaN(parseInt(rimDetails[key], 10)) || String(rimDetails[key]) === "")
            )) {
                FormErrors[key] = validationTexts.MISSINGFIELD;
                isFormValid = false;
            } 
            if (rimDetails.dispositionId === 2) {
                if ( RimId && (key === "vehicleId" && rimDetails[key] === "")) {
                    FormErrors[key] = validationTexts.MISSINGFIELD;
                    FormErrors['dispositionId'] = 'Installing a rim must be initially saved as Inventory first';
                    isFormValid = false;
                } 
    
                if ( RimId && (rimDetails.vehicleId ||rimDetails.vehicleId === "" ) && (key === "wheelPosition" && rimDetails[key] === "")) {
                    FormErrors[key] = validationTexts.MISSINGFIELD;
                    isFormValid = false;
                } 
            }

            
            if (
                key === "currentHours" &&
                (trackingMethod === 3 || trackingMethod === 1) &&
                (rimDetails[key] == "" || parseInt(rimDetails[key], 10) < 0)
            ) {
                FormErrors[key] = validationTexts.CURRENTHOURSMILES;
                isFormValid = false;
            }  
            if (
                (key === "inspectionIntervalHours" || key === "hoursLeft")  &&
                (trackingMethod === 3 || trackingMethod === 1) &&
                (parseInt(rimDetails[key], 10) <= 0)
            ) {
                FormErrors[key] = "Value should not be < 0 or equal to 0";
                isFormValid = false;
            } 
            if (
                key === "currentMiles" &&
                (trackingMethod === 3 || trackingMethod === 2) &&
                (rimDetails[key] == "" || parseInt(rimDetails[key], 10) < 0)
            ) {
                FormErrors[key] = validationTexts.CURRENTHOURSMILES;
                isFormValid = false;
            } 
            
            if (( key === "inspectionIntervalMiles" || key === "milesLeft") &&
                (trackingMethod === 3 || trackingMethod === 2) &&
                (parseInt(rimDetails[key], 10) <= 0)) {
                FormErrors[key] = "Value should not be < 0 or equal to 0";
                isFormValid = false;
            }
       });
        setIdFormErrors(FormErrors);
        return isFormValid;
    };
    const [descFormErrors, setDescFormErrors] = useState({});
    const [isOtherManufSelected, setOtherManufSelected] = useState(false);
    const [otherManuf, setOtherManuf] = useState({});
    const [otherType, setOtherType] = useState({});
    const [rimMasterDetails, setRimMasterDetails] = useState([]);
    const [typeByManuf, setTypeByManuf] = useState([]);
    useEffect(() => {
        setRimMasterDetails([otherManuf, otherType]);
    }, [otherManuf, otherType]);
    const validateTotalForm = () => {
        const masterTireArr = rimMasterDetails;
        rimMasterDetails.forEach((obj, key) => {
            if (obj.description === "" || !obj.description) {
                masterTireArr.splice(key, 1);
            }
        });
        setRimMasterDetails(masterTireArr);
    };
    const handleTireMasterDataChange = (field, val) => {
        let date = new Date();
        date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        let newData = {};
        switch (field) {
            case "otherManuf":
                newData = {
                    requestDate: date,
                    fieldId: 6,
                    description: val,
                    status: "New",
                };
                setOtherManuf(newData);
                setIsNewManufacturerNameEdited(true);
                break;
            case "otherType":
                newData = {
                    requestDate: date,
                    fieldId: 8,
                    description: val,
                    status: "New",
                };
                setOtherType(newData);
                setIsNewTypeNameEdited(true);
                break;
            default:
                break;
        }
    };
    const validateDescForm = () => {
        let isFormValid = true;
        const FormErrors = {};
        Object.keys(rimDetails).forEach(key => {
            if (key === "manufacturerId" && !rimDetails[key]) {
                FormErrors[key] = validationTexts.MISSINGFIELD;
                isFormValid = false;
                // added validation for other manf.
            } 
            // else if (key === "manufacturerId" && isOtherManufSelected) {
            //     if (Object.values(otherManuf).length === 0 || otherManuf.description === "" || otherManuf.description === undefined) {
            //         isFormValid = false;
            //         FormErrors["otherManuf"] = validationTexts.MISSINGFIELD;
            //     }
            // }
            else {
                if (
                    (key === "typeId" && !rimDetails[key]) ||
                    (key === "sizeId" && !rimDetails[key])
                ) {
                    FormErrors[key] = validationTexts.MISSINGFIELD;
                    isFormValid = false;
                }
            }
        });
        setDescFormErrors(FormErrors);
        return isFormValid;
    };

    const [costFormErrors, setCostFormErrors] = useState({});
    const validateCostsForm = () => {
        let isFormValid = true;
        const FormErrors = {};

        Object.keys(rimDetails).forEach(key => {
            if ( (key === "repairCount" || key === "purchaseCost" || key === "repairCost"
            || key === "soldAmount") && parseInt(rimDetails[key], 10) < 0)
             {
                FormErrors[key] = "Value should not be < 0";
                isFormValid = false;
            } 
        });
        setCostFormErrors(FormErrors);
        return isFormValid;
    };

    const getStepComponent = (currentStepPassed) => {
        switch (currentStepPassed) {
            case 0:
                return (
                    <Identification
                        fieldNames={rimFormCaptions.RIM.RIMFORM}
                        RimId={RimId}
                        masterDataset={masterData}
                        tireData={tireData}
                        userPreferenceData={userPreferences}
                        rimModal={rimDetails}
                        trackBy={trackingMethod}
                        onTrackByChange={val => setTrackingMethod(parseInt(val, 10))}
                        onChangeListener={(field, val) => handleFieldChange(field, val)}
                        formHelperTexts={idFormErrors}
                        vehicleIDPos={vehicleIDPos}
                        isRimSerialNumberEdited={isRimSerialNumberEdited}
                        isCurrentHoursEdited={isCurrentHoursEdited}
                        isCurrentMilesEdited={isCurrentMilesEdited}
                        isInspectionIntervalHoursEdited={isInspectionIntervalHoursEdited}
                        isInspectionIntervalMilesEdited={isInspectionIntervalMilesEdited}
                        isHoursLeftEdited={isHoursLeftEdited}
                        isMilesLeftEdited={isMilesLeftEdited}
                        accLocationInfo={accLocationDetails}
                        vehicleListDetails={vehicleListDetails}
                        currentDispositionSate={currentDispositionSate}
                    />
                );
            case 1:
                return (
                    <Description
                        fieldNames={rimFormCaptions.RIM.RIMFORM}
                        RimId={RimId}
                        masterDataset={masterData}
                        tiredata={tireData}
                        userPreferenceData={userPreferences}
                        rimModal={rimDetails}
                        formHelperTexts={descFormErrors}
                        onChangeListener={(field, val) => handleFieldChange(field, val)}
                        isManufNew={isOtherManufSelected}
                        typeByManf={typeByManuf}
                        newManuf={otherManuf}
                        onExtraDataChange={(field, val) =>
                            handleTireMasterDataChange(field, val)
                        }
                        isManufacturerEdited = {isManufacturerEdited}
                        isNewManufacturerNameEdited = {isNewManufacturerNameEdited}
                        isTypeEdited = {isTypeEdited}
                        isNewTypeNameEdited={isNewTypeNameEdited}
                        isSizeEdited={isSizeEdited}
                    />
                );
            case 2:
                return (
                    <Costs
                        fieldNames={rimFormCaptions.RIM.RIMFORM}
                        RimId={RimId}
                        rimModal={rimDetails}
                        formHelperTexts={costFormErrors}
                        accLocationInfo={accLocationDetails}
                        onChangeListener={(field, val) => handleFieldChange(field, val)}
                    />
                );
            default:
                return;
        }
    };
    const handleFieldChange = (field, val) => {
        switch (field) {
            case "rimSerialNo":
                setRimDetails({ ...rimDetails, rimSerialNo: val });
                setIsRimSerialNumberEdited(true);
                break;
            case "registrationDate":
                if (val !== null) {
                    let date = val;
                    date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                    setRimDetails({ ...rimDetails, registrationDate: date });
                } else {
                    setRimDetails({ ...rimDetails, registrationDate: "" });
                }
                break;
            case "nextCheck":
                if (val !== null) {
                    let date = val;
                    date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                    setRimDetails({ ...rimDetails, nextCheck: date });
                } else {
                    setRimDetails({ ...rimDetails, nextCheck: "" });
                }
                break;
            case "ponumber":
                setRimDetails({ ...rimDetails, ponumber: val });
                break;
            case "brandNo":
                setRimDetails({ ...rimDetails, brandNo: val });
                break;
            case "barCode":
                setRimDetails({ ...rimDetails, barCode: val });
                break;
            case "lotNo":
                setRimDetails({ ...rimDetails, lotNo: val });
                break;
            case "dispositionId":
                if (val !== 2) { // not installed
                    setRimDetails({ ...rimDetails, 
                        dispositionId: Number(val),
                        vehicleId: "",
                        wheelPosition: ""});
                } else setRimDetails({ ...rimDetails, dispositionId: Number(val) });
                break;
            case "vehicleId":
                setRimDetails({ ...rimDetails, 
                    vehicleId: val,
                    wheelPosition: "" });
                break;
            case "wheelPosition":
                setRimDetails({ ...rimDetails, wheelPosition: val });
                break;
            case "tireId":
                setRimDetails({ ...rimDetails, tireId: val });
                break;
            case "currentHours":
                setRimDetails({ ...rimDetails, currentHours: val });
                setIsCurrentHoursEdited(true);
                break;
            case "inspectionIntervalHours":
                setRimDetails({ ...rimDetails, inspectionIntervalHours: val });
                setIsInspectionIntervalHoursEdited(true);
                break;
            case "hoursLeft":
                setRimDetails({ ...rimDetails, hoursLeft: val });
                setIsHoursLeftEdited(true);
                break;
            case "currentMiles":
                setRimDetails({ ...rimDetails, currentMiles: val });
                setIsCurrentMilesEdited(true);
                break;
            case "inspectionIntervalMiles":
                setRimDetails({ ...rimDetails, inspectionIntervalMiles: val });
                setIsInspectionIntervalMilesEdited(true);
                break;
            case "milesLeft":
                setRimDetails({ ...rimDetails, milesLeft: val });
                setIsMilesLeftEdited(true);
                break;
            case "manufacturerId":
                setType(val);
                setRimDetails({ ...rimDetails, manufacturerId: val });
                setIsManufacturerEdited(true);
                break;
            case "sizeId":
                setRimDetails({ ...rimDetails, sizeId: val });
                setIsSizeEdited(true);
                break;
            case "evalNo":
                setRimDetails({ ...rimDetails, evalNo: val });
                break;
            case "typeId":
                setRimDetails({ ...rimDetails, typeId: val });
                setIsTypeEdited(true);
                break;
            case "repairCount":
                let repairCountValue = val < 0 ? 0 : val;
                repairCountValue = repairCountValue === '' ? 0 :repairCountValue;
                setRimDetails({ ...rimDetails, repairCount: repairCountValue });
                break;
            case "valveTypeId":
                setRimDetails({ ...rimDetails, valveTypeId: val });
                break;
            case "maxInflationRating":
                setRimDetails({ ...rimDetails, maxInflationRating: Math.abs(val) });
                break;
            case "torqueSpec":
                setRimDetails({ ...rimDetails, torqueSpec: val });
                break;
            case "checkInterval":
                let checkIntervalValue = val < 0 ? 0 : val;
                checkIntervalValue = checkIntervalValue === '' ? 0 : checkIntervalValue;
                setRimDetails({ ...rimDetails, checkInterval: checkIntervalValue });
                break;
            case "purchaseCost":
                let purchaseCostValue = parseFloat(val) ? parseFloat(val) : 0;
                purchaseCostValue = purchaseCostValue < 0 ? 0 : purchaseCostValue;
                const costAdj =
                    purchaseCostValue  +
                    (parseFloat(rimDetails.repairCost)
                        ? parseFloat(rimDetails.repairCost)
                        : 0);
                const netCost =
                    costAdj -
                    (parseFloat(rimDetails.soldAmount)
                        ? parseFloat(rimDetails.soldAmount)
                        : 0);
                setRimDetails({
                    ...rimDetails,
                    purchaseCost: purchaseCostValue,
                    costAdjustment: costAdj,
                    netCost,
                });
                break;
            case "repairCost":
                let repairCostValue = parseFloat(val) ? parseFloat(val) : 0;
                repairCostValue = repairCostValue < 0 ? 0 : repairCostValue;
                const costAdjVal =
                    repairCostValue +
                    (parseFloat(rimDetails.purchaseCost)
                        ? parseFloat(rimDetails.purchaseCost)
                        : 0);
                const netCostVal =
                    costAdjVal -
                    (parseFloat(rimDetails.soldAmount)
                        ? parseFloat(rimDetails.soldAmount)
                        : 0);
                setRimDetails({
                    ...rimDetails,
                    repairCost: repairCostValue,
                    costAdjustment: costAdjVal,
                    netCost: netCostVal,
                });
                break;
            case "soldAmount":
                let soldAmountValue = parseFloat(val) ? parseFloat(val) : 0;
                soldAmountValue = soldAmountValue < 0 ? 0 : soldAmountValue;
                const netCostValSold =
                    (parseFloat(rimDetails.costAdjustment)
                        ? parseFloat(rimDetails.costAdjustment)
                        : 0) - soldAmountValue;
                setRimDetails({
                    ...rimDetails,
                    soldAmount: soldAmountValue,
                    netCost: netCostValSold,
                });
                break;
            case "rimStatusId":
                setRimDetails({ ...rimDetails, rimStatusId: val });
                break;

            default:
                break;
        }
    };
    function setType(val) {
        if (Object.keys(masterData).length && val) {
            const index = masterData.rimManufacturers.findIndex(mnf => mnf.manufacturerId === parseInt(val, 10));
            if (
                masterData.rimManufacturers[index].manufacturerName
                    .trim()
                    .toLowerCase() === "other"
            ) {
                setOtherManufSelected(true);
            } else {
                setOtherManufSelected(false);
            }
            const rimTypeByManf = [];
            masterData.rimTypes.forEach(type => {
                if (
                    type.typeName.trim().toLowerCase() === "other" &&
                    masterData.rimManufacturers[index].manufacturerName
                        .trim()
                        .toLowerCase() !== "other"
                ) {
                    rimTypeByManf.push(type);
                }
                if (type.manufacturerId === val) {
                    rimTypeByManf.push(type);
                }
            });

            let sortedRimTypeByManf = [...rimTypeByManf].sort(function (a, b) {
                return compareStrings(a.typeName, b.typeName);
            })

            setTypeByManuf(sortedRimTypeByManf);
        }
    }
    function compareStrings(a, b) {
        a = a.toLowerCase();
        b = b.toLowerCase();
        if (a < b) {
            return -1;
        } else {
            return a > b ? 1 : 0;
        }
    }
    const handleCancel = () => {
        let redirect;
        if (locationObj.state && locationObj.state.fromView) {
            redirect = "/view-rim/" + RimId;
        } else {
            redirect = "/manage-rim";
        }
        history.push(redirect);
    };
    const handlePreviousStep = () => {
        resetTextFieldIsEditState();
        if (currentStep !== 0) {
            setCurrentStep(currentStep => currentStep - 1);
            window.scrollTo(0, 0);
        }
    };
    const handleCloneClick = () => {
        setCreateClonedNotif(false);
        setEditClonedNotif(false);
        setIsClonedClicked(true);
        validateTotalForm();
        resetTextFieldIsEditState();

        if (validateIdForm() && validateDescForm() && validateCostsForm()) {
            setShowSpinner(true);
            let isMasterDataEmpty = true;
            for (const element of rimMasterDetails) {
                if (Object.keys(element).length !== 0) {
                    isMasterDataEmpty = false;
                    break;
                }
            }
            let successdesc;
            if (RimId && !isListingClonedClicked && !isClonedClicked) {
                successdesc = notificationTexts.RIMUPDATESUCCESS.replace(
                    "#1#1",
                    rimDetails.rimSerialNo
                );
                headerApiObj.updateRim(rimDetails).then(response => {
                    if (
                        (response.didError !== undefined && !response.didError) ||
                        !response.isError
                    ) {
                        if (!isMasterDataEmpty) {
                            headerApiObj
                                .createMasterData(rimMasterDetails)
                                .then(masterResponse => {
                                    if (!masterResponse.didError) {
                                        cloneSuccess(1, successdesc);
                                    }
                                });
                        } else {
                            cloneSuccess(1, successdesc);
                        }
                    } else {
                        if (
                            response.message ===
                            "A rim with the entered serial number already exists in this account"
                        ) {
                            handleStep(0);
                            setIdFormErrors({
                                ...idFormErrors,
                                rimSerialNo: validationTexts.RIMEXISTS,
                            });
                        }
                        setShowSpinner(false);
                    }
                });
            } else {
                if (isListingClonedClicked || isClonedClicked) {
                    delete rimDetails.rimId;
                }
                successdesc = notificationTexts.RIMCREATEDSUCCESS.replace(
                    "#1#1",
                    rimDetails.rimSerialNo
                );
                headerApiObj.createRim(rimDetails).then(response => {
                    if (
                        (response.didError !== undefined && !response.didError) ||
                        !response.isError
                    ) {
                        if (!isMasterDataEmpty) {
                            headerApiObj
                                .createMasterData(rimMasterDetails)
                                .then(masterResponse => {
                                    if (!masterResponse.didError) {
                                        cloneSuccess(2, successdesc);
                                    }
                                });
                        } else {
                            cloneSuccess(2, successdesc);
                        }
                    } else {
                        if (
                            response.message ===
                            "A rim with the entered serial number already exists in this account"
                        ) {
                            handleStep(0);
                            setIdFormErrors({
                                ...idFormErrors,
                                rimSerialNo: validationTexts.RIMEXISTS,
                            });
                        }
                        setShowSpinner(false);
                    }
                });
            }
        }
    };
    function cloneSuccess(type, successdesc) {
        setRimDetails({
            ...rimDetails,
            rimSerialNo: "",
            tireSerialNo: "",
            tireId: "",
        });
        handleStep(0);
        setShowSpinner(false);
        if (type === 1) {
            const successmessage = notificationTexts.RIMEDUTANDCLONED;
            setRimClonedEditNotif({
                notifmessage: successmessage,
                notifdesc: successdesc,
            });
            setEditClonedNotif(true);
        } else {
            const successmessage = notificationTexts.RIMANDCLONED;
            setRimClonedCreateNotif({
                notifmessage: successmessage,
                notifdesc: successdesc,
            });
            setCreateClonedNotif(true);
        }
        if (globalAccountState.accountInfo.location.length > 1) {
            setOpenChangeLocation(true);
        }
    }
    const handleNextStep = () => {
        resetTextFieldIsEditState();
        switch (currentStep) {
            case 0:
                if (!validateIdForm()) {
                    return;
                }
                break;
            case 1:
                if (!validateDescForm()) {
                    return;
                }
                break;
            case 2:
                if (!validateCostsForm()) {
                    return;
                }
                break;
            default:
                break;
        }
        if (currentStep !== getTotalSteps() - 1) {
            setCurrentStep(currentStep => currentStep + 1);
            window.scrollTo(0, 0);
        } else {
            submitRimCreationForm();
        }
    };
    function submitRimCreationForm() {
        validateTotalForm();
        resetTextFieldIsEditState();
        if (validateIdForm() && validateDescForm() && validateCostsForm()) {
            setShowSpinner(true);
            let isMasterDataEmpty = true;
            for (const element of rimMasterDetails) {
                if (Object.keys(element).length !== 0) {
                    isMasterDataEmpty = false;
                    break;
                }
            }
            if (
                (locationObj.state && locationObj.state.option === "Clone") ||
                isClonedClicked
            ) {
                delete rimDetails.rimId;
                createNewRim(isMasterDataEmpty);
                setIsClonedClicked(false);
            } else if (RimId && !isListingClonedClicked) {
                setCreate(create + 1)
                const updatedsection = "editoldrim";
                let successStatus = false;
                const successmessage = notificationTexts.RIMEDITED;
                const successdesc = notificationTexts.RIMUPDATESUCCESS.replace(
                    "#1#1",
                    rimDetails.rimSerialNo
                );
                const failuremsg = notificationTexts.RIMNOTEDITED;
                headerApiObj.updateRim(rimDetails).then(response => {
                    if (
                        (response.didError !== undefined && !response.didError) ||
                        !response.isError
                    ) {
                        successStatus = true;
                        if (!isMasterDataEmpty) {
                            headerApiObj
                                .createMasterData(rimMasterDetails)
                                .then(masterResponse => {
                                    if (!masterResponse.didError) {
                                        successRedirect(
                                            updatedsection,
                                            successStatus,
                                            successmessage,
                                            successdesc,
                                            failuremsg,
                                            1
                                        );
                                    }
                                });
                        } else {
                            successRedirect(
                                updatedsection,
                                successStatus,
                                successmessage,
                                successdesc,
                                failuremsg,
                                1
                            );
                        }
                    } else {
                        if (
                            response.message ===
                            "A rim with the entered serial number already exists in this account"
                        ) {
                            handleStep(0);
                            setIdFormErrors({
                                ...idFormErrors,
                                rimSerialNo: validationTexts.RIMEXISTS,
                            });
                        }
                        setShowSpinner(false);
                    }
                });
            } else {
                createNewRim(isMasterDataEmpty);
            }
        } else {
            setIsClonedClicked(false);
        }
    }

    const [create, setCreate] = useState(0);
    window.localStorage.setItem("Count", create)
    function createNewRim(isMasterDataEmpty) {
        setCreate(create + 1)
        let successStatus = false;
        const updatedsection = "createnewrim";
        const successmessage = notificationTexts.RIMCREATED;
        const successdesc = notificationTexts.RIMCREATEDSUCCESS.replace(
            "#1#1",
            rimDetails.rimSerialNo
        );
        const failuremsg = notificationTexts.RIMNOTCREATED;
        if (isListingClonedClicked) {
            delete rimDetails.rimId;
        }
        headerApiObj.createRim(rimDetails).then(response => {
            if (
                (response.didError !== undefined && !response.didError) ||
                !response.isError
            ) {
                successStatus = true;
                if (!isMasterDataEmpty) {
                    headerApiObj
                        .createMasterData(rimMasterDetails)
                        .then(masterResponse => {
                            if (!masterResponse.didError) {
                                successRedirect(
                                    updatedsection,
                                    successStatus,
                                    successmessage,
                                    successdesc,
                                    failuremsg,
                                    2
                                );
                            }
                        });
                } else {
                    successRedirect(
                        updatedsection,
                        successStatus,
                        successmessage,
                        successdesc,
                        failuremsg,
                        2
                    );
                }
            } else {
                if (
                    response.message ===
                    "A rim with the entered serial number already exists in this account"
                ) {
                    handleStep(0);
                    setIdFormErrors({
                        ...idFormErrors,
                        rimSerialNo: validationTexts.RIMEXISTS,
                    });
                }
                setShowSpinner(false);
            }
        });
    }
    function successRedirect(
        updatedsection,
        successStatus,
        successmessage,
        successdesc,
        failuremsg,
        _type
    ) {
        setShowSpinner(false);
        setIsListingClonedClicked(false);
        let redirect;
        if (locationObj.state && locationObj.state.fromView) {
            redirect = "/view-rim/" + RimId;
        } else {
            redirect = "/manage-rim";
        }
        history.push({
            pathname: redirect,
            state: {
                updatedSection: updatedsection,
                updateStatus: successStatus,
                successMessage: successmessage,
                successMessageDesc: successdesc,
                failureMessage: failuremsg,
            },
        });
    }
    const locationChanged = locationId => {
        const locationJson = { ...rimDetails };
        locationJson.locationId = locationId;
        setRimDetails(locationJson);
        setOpenChangeLocation(false);
    };
    return (
        <React.Fragment>
            <div className="standalone-new-header-container">
                <div className="create-rim-main-page-container">
                    <div className="create-rim-upper-half">
                        <div className="background-image-rim-dashboard">
                            <div className="dashboard-header">
                                <div>
                                    <Breadcrumbs breadcrumbs={myBreadcrumbs} />
                                    <div className="scrap-tires-title">
                                        {myBreadcrumbsName()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showCreateClonedNotif && (
                <FlashMessage
                    status={true}
                    message={rimClonedCreateNotif.notifmessage}
                    description={rimClonedCreateNotif.notifdesc}
                />
            )}
            {showEditClonedNotif && (
                <FlashMessage
                    status={true}
                    message={rimClonedEditNotif.notifmessage}
                    description={rimClonedEditNotif.notifdesc}
                />
            )}
            {showSpinner ? (
                <Spinner />
            ) : (
                <div className="manage-rim-list-container">
                    <div className="create-tire-inner-container">
                        <div className="step-indicator-container">
                            <div
                                className={
                                    RimId > 0 ? styling.rootStepper2 : styling.rootStepper
                                }
                            >
                                <Stepper
                                    alternativeLabel
                                    activeStep={currentStep}
                                    connector={<ColorlibConnector />}
                                >
                                    {steps.map((label, index) => (
                                        <Step key={label} onClick={() => handleStep(index)}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                            <div className="helper-message">
                                {" "}
                                {rimFormCaptions.MANDATORYFIELDS}{" "}
                                <span className="required-mark">*</span>{" "}
                            </div>
                            <div className="step-form-container">
                                {" "}
                                {getStepComponent(currentStep)}{" "}
                            </div>
                            <hr className="hr-divider-action" />
                            <div className="action-buttons">
                                <Button
                                    className={styling.cancel}
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleCancel}
                                >
                                    {rimFormCaptions.CANCEL}
                                </Button>
                                {currentStep > 0 && (
                                    <Button
                                        className={styling.previous}
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handlePreviousStep()}
                                    >
                                        {rimFormCaptions.PREVIOUS}
                                    </Button>
                                )}
                                {currentStep === steps.length - 1 ? (
                                    <React.Fragment>
                                        {rimDetails.dispositionId !== 2 ?  //installed 
                                            <Button
                                                className={styling.previous}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleCloneClick()}
                                            >
                                                {rimFormCaptions.SAVEANDCLONE}
                                            </Button>
                                            : null
                                        }
                                        <Button
                                            className={styling.next}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleNextStep()}
                                        >
                                            {rimFormCaptions.SUBMIT}
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Button
                                        className={styling.next}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleNextStep()}
                                    >
                                        {rimFormCaptions.NEXT}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {openChangeLocation && (
                <ChangeLocationModal
                    locationData={globalAccountState.accountInfo.location}
                    locationChanged={locationChanged}
                />
            )}
        </React.Fragment>
    );
};
export default CreateRim;
