/* eslint-disable no-useless-escape */
import React from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Radio,
  RadioGroup,
  InputLabel,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
} from "@material-ui/core";
import "../common-modal/CommonPopup.css";
import "./EmailModalComponent.css";
import TiresReportPDFMake from "../export-options/Tires-Report-pdf/TiresReportPDFMake";
import Spinner from "../../spinner/spinner";
import HeaderApi from "../../header/services/header-apis";
import ChipInput from "material-ui-chip-input";
import { SurveyTemplatePdfGenerator } from "../../survey-template-pdf/SurveyTemplatePdf";
import FleetSurveyPDF from "../export-options/fleet-survey-pdf/FleetSurveyPdf";
import TireInflationPDF from "../export-options/tire-inflation-pdf/TireInflationPdf";
import TireForecastPDF from "../export-options/tire-forecast-pdf/TireForecastPdf";
import { FleetDataTemplatePdfGenerator } from "../../fleet-data-pdf/FleetDataPdf";
import RemovedTirePDF from "../export-options/removed-tire-pdf/RemovedTirePdf";
import HomeDashboardPDF from "../export-options/home-dashboard-pdf/HomeDashboardPDF";
import DailyJobSummaryPDF from "../export-options/daily-job-summary-pdf/DailyJobSummaryPdf";
import TireNeedsForecastPDF from "../export-options/tire-needs-forecast-pdf/TireNeedsForecastPdf";
import Globalization from "../../../globalization";


function whatToExportSelection(props) {
  if (props?.surveyTemplate === true) {
    return "SurveyTemplate";
  } else if (props?.noGraph === true) {
    return "Table";
  } else if (props?.notable === true) {
    return "Graph";
  } else {
    return "All"
  }
}
class EmailModalComponent extends React.Component {
  globalizationMapping = new Globalization();

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
      value: whatToExportSelection(this.props),
      to: [],
      cc: [],
      toVal: "",
      ccVal: "",
      subject: "",
      toError: null,
      ccError: null,
      messageBody: "",
      spinner: false,
    };
  }
  handleRadioChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  };
  sendEmail = async () => {
    this.setState({ spinner: true });
    // checks whether the type of report matches survey template

    const generateAndSendEmail = (pdfData, reportName, setStateValue) => {
      pdfData.getBase64((base64Data) => {
        const headerApi = new HeaderApi();
        const email = {
          toAddress: this.state.to,
          reportName: reportName,
          subject: this.state.subject,
          ccAddress: this.state.cc,
          messageBody: this.state.messageBody,
          base64: base64Data,
        };
        headerApi.sendEmailReport(email).then((_data) => {
          this.setState({
            value: setStateValue,
            to: [],
            cc: [],
            toVal: "",
            ccVal: "",
            subject: "",
            toError: null,
            ccError: null,
            messageBody: "",
            spinner: false,
          });
          this.handleClose();
        });
      });
    }

    if (this.props.surveyTemplate) {
      const surveyTemplateDocData = SurveyTemplatePdfGenerator(
        this.props.axleConfigurationDetails,
        this.props.locationNameSelected,
        "create"
      );
      generateAndSendEmail(surveyTemplateDocData, this.props.axleConfigurationDetails.configurationText, "SurveyTemplate");

    } else if (this.props.fleetData) {
      const fleetDocData = FleetDataTemplatePdfGenerator(
        this.props.tableData,
        "create"
      );
      generateAndSendEmail(fleetDocData, "Fleet Data Report", "FleetData");

    } else if (this.props.removedTire) {
      const props = {
        tableData: this.props.tableData,
        printOrPdf: "email",
        value: this.state.value,
        email: this.state,
      };
      const tableGraph = new RemovedTirePDF(props);
      const removeTireData = await tableGraph.PDFMake();
      generateAndSendEmail(removeTireData, this.props.tableData.reportName, "Table");

    } else if (this.props.tireForecast) {
      const props = {
        tableData: this.props.tableData,
        printOrPdf: "email",
        value: this.state.value,
        email: this.state,
      };
      const tableGraph = new TireForecastPDF(props);
      const tireForecastData = await tableGraph.PDFMake();
      generateAndSendEmail(tireForecastData, this.props.tableData.reportName, "Table");

    } else if (this.props.tireInflation) {
      const props = {
        tableData: this.props.tableData,
        printOrPdf: "email",
        value: this.state.value,
        email: this.state,
      };
      const tableGraph = new TireInflationPDF(props);
      const tireInflationData = await tableGraph.PDFMake();
      generateAndSendEmail(tireInflationData, this.props.tableData.reportName, "Table");

    } else if (this.props.fleetSurvey) {
      const props = {
        tableData: this.props.tableData,
        printOrPdf: "email",
        value: this.state.value,
        email: this.state,
      };
      const tableGraph = new FleetSurveyPDF(props);
      const fleetSurveyData = await tableGraph.PDFMake();
      generateAndSendEmail(fleetSurveyData, this.props.tableData.reportName, "Table");

    } else if (this.props.home) {
      const tableGraph = new HomeDashboardPDF({
        floatingCardData: this.props.tableData,
      });
      const homeDashboardData = await tableGraph.PDFMake();
      generateAndSendEmail(homeDashboardData, "Home", "Table");

    } else if (this.props.dailyJobSummary) {
      const props = {
        tableData: this.props.tableData,
        printOrPdf: "email",
        value: this.state.value,
        email: this.state,
      };
      const tableGraph = new DailyJobSummaryPDF(props);
      const dailyJobSummaryData = await tableGraph.PDFMake();
      generateAndSendEmail(dailyJobSummaryData, this.props.tableData.reportName, "Table");

    } else if (this.props.tireNeedsForecast) {
      const props = {
        tableData: this.props.tableData,
        tableData2: this.props.tableData2,
        printOrPdf: "email",
        value: this.state.value,
        email: this.state,
      };
      const tableGraph = new TireNeedsForecastPDF(props);
      const tireNeedsForecastData = await tableGraph.PDFMake();
      generateAndSendEmail(tireNeedsForecastData, this.props.tableData.reportName, "Table");

    } else {
      const props = {
        tableData: this.props.tableData,
        printOrPdf: "email",
        value: this.state.value,
        email: this.state,
      };
      const tableGraph = new TiresReportPDFMake(props);
      const tireReportData = await tableGraph.PDFMake();
      generateAndSendEmail(tireReportData, this.props.tableData.reportName, "Table");

    }
  };
  /** CC functions */

  handleCCAddChip = (chip) => {
    this.setState({
      ccError: null,
    });
    const value = chip.trim();
    if (value && this.isCCValid(value)) {
      this.setState({
        cc: [...this.state.cc, value],
      });
    }
  };

  handleCCDelete = (item) => {
    this.setState({
      cc: this.state.cc.filter((i) => i !== item),
    });
  };

  isCCValid(email) {
    let error = null;
    if (this.isCCInList(email)) {
      error = `${email} has already been added.`;
    }
    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (error) {
      this.setState({ ccError: error });
      return false;
    }
    return true;
  }

  isCCInList(email) {
    return this.state.cc.includes(email);
  }
  /** To functions */
  handleToAddChip = (chip) => {
    this.setState({
      toError: null,
    });
    const value = chip.trim();
    if (value && this.isValid(value)) {
      this.setState({
        to: [...this.state.to, chip],
      });
    }
  };

  handleToDelete = (item) => {
    this.setState({
      to: this.state.to.filter((i) => i !== item),
    });
  };

  isValid(email) {
    let error = null;
    if (this.isToInList(email)) {
      error = `${email} has already been added.`;
    }
    if (!this.isEmail(email)) {
      error = `"${email}" is an invalid email address`;
    }
    if (error) {
      this.setState({ toError: error });
      return false;
    }
    return true;
  }

  isToInList(email) {
    return this.state.to.includes(email);
  }

  isEmail(email) {
    // /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  }
  handleClose = () => {
    this.setState({
      value: "Table",
      to: [],
      cc: [],
      subject: "",
      toError: null,
      ccError: null,
      messageBody: "",
      spinner: false,
    });
    this.props.setModalHandler(false);
  };
  render() {
    return (
      <div>
        <Modal
          disableScrollLock={true}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalParent"
          open={this.props.modalHandler.handleEmail}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.props.modalHandler.handleEmail}>
            <div className="modal">
              <div className="change-modal-container center-modal">
                <div className="change-modal-header">
                  {this.state.spinner === true && <Spinner />}
                  <div className="change-modal-title-container">
                    <div className="change-modal-title">
                      {this.globalizationMapping.POPUPWORDS.EMailHeader}
                    </div>
                    <div
                      className="close-icon-container"
                      onClick={this.handleClose}
                    >
                      <img
                        src={require("../../../assets/close-icon.svg")}
                        className="modal-close-icon"
                        alt="close-icon"
                      />
                    </div>
                  </div>
                  <div className="change-modal-description">
                    {this.globalizationMapping.REPORTCOMMONWORDS.SelectAllEmail}
                  </div>
                </div>
                {this.state.value !== "SurveyTemplate" && (
                  <div className="change-modal-body-container">
                    <FormControl component="fieldset">
                      <FormLabel className="modal-subtitle">
                        {this.globalizationMapping.POPUPWORDS.ChooseWhattoMail}
                      </FormLabel>
                      <RadioGroup row onChange={this.handleRadioChange}>
                        <FormControlLabel
                          className="modal-subtitle"
                          value="All"
                          checked={this.state.value === "All"}
                          control={<Radio color="primary" />}
                          label={
                            this.globalizationMapping.REPORTCOMMONWORDS
                              .SelectAll
                          }
                          disabled={this.props.noGraph || this.props.notable}
                        />
                        <FormControlLabel
                          className="modal-subtitle"
                          checked={this.state.value === "Graph"}
                          value="Graph"
                          control={<Radio color="primary" />}
                          label={this.globalizationMapping.POPUPWORDS.OnlyGraph}
                          disabled={this.props.noGraph}
                        />
                        <FormControlLabel
                          className="modal-subtitle"
                          checked={this.state.value === "Table"}
                          value="Table"
                          control={<Radio color="primary" />}
                          label={this.globalizationMapping.POPUPWORDS.OnlyTable}
                          disabled={this.props.notable}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                <div className="form-container">
                  <form className="" autoComplete="off">
                    <div className="div-bottom-margin">
                      <InputLabel className="filter-table-label">
                        <span className="label-color table-toolbar-label-text">
                          {this.globalizationMapping.EMAIL.TO}{" "}
                        </span>{" "}
                      </InputLabel>
                      <ChipInput
                        className={
                          "input " + (this.state.toError && " has-error")
                        }
                        helperText={this.state.toError}
                        disableUnderline={true}
                        value={this.state.to}
                        newChipKeys={["Enter", "Tab", ",", " "]}
                        placeholder={
                          this.globalizationMapping.PLACEHOLDER.EmailPlaceholder
                        }
                        onAdd={(chip) => this.handleToAddChip(chip)}
                        onDelete={(chip, _index) => this.handleToDelete(chip)}
                      />
                    </div>
                    <div className="div-bottom-margin">
                      <InputLabel className="filter-table-label">
                        <span className="label-color table-toolbar-label-text">
                          {this.globalizationMapping.EMAIL.CC}{" "}
                        </span>{" "}
                      </InputLabel>
                      <ChipInput
                        className={
                          "input " + (this.state.ccError && " has-error")
                        }
                        disableUnderline={true}
                        helperText={this.state.ccError}
                        value={this.state.cc}
                        newChipKeys={["Enter", "Tab", ",", " "]}
                        placeholder={
                          this.globalizationMapping.PLACEHOLDER.EmailPlaceholder
                        }
                        onAdd={(chip) => this.handleCCAddChip(chip)}
                        onDelete={(chip, _index) => this.handleCCDelete(chip)}
                      />
                    </div>
                    <div className="subject-div-bottom-margin">
                      <InputLabel className="filter-table-label">
                        <span className="label-color table-toolbar-label-text">
                          {this.globalizationMapping.EMAIL.SUBJECT}{" "}
                        </span>
                      </InputLabel>
                      <TextField
                        InputProps={{ disableUnderline: true, maxLength: 50 }}
                        className="input text-label"
                        defaultValue={this.state.subject}
                        onChange={(e) => {
                          this.setState({ subject: e.target.value });
                        }}
                      />
                    </div>
                    <InputLabel className="filter-table-label">
                      <span className="label-color table-toolbar-label-text">
                        {this.globalizationMapping.EMAIL.MESSAGE}{" "}
                      </span>
                    </InputLabel>
                    <TextField
                      InputProps={{ disableUnderline: true, maxLength: 50 }}
                      className="input text-label"
                      multiline
                      rows={2}
                      defaultValue={this.state.messageBody}
                      onChange={(e) => {
                        this.setState({ messageBody: e.target.value });
                      }}
                    />
                  </form>
                </div>
                <div className="border-bottom"></div>
                <div className="modal-button-group">
                  <div>
                    <button
                      className="change-modal-cancel-btn"
                      onClick={this.handleClose}
                    >
                      {this.globalizationMapping.EMAIL.CANCEL}
                    </button>
                  </div>
                  <div>
                    {this.state.value !== "" && (
                      <button
                        className="change-modal-proceed-btn"
                        onClick={this.sendEmail}
                      >
                        {this.globalizationMapping.EMAIL.SEND}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}
export default EmailModalComponent;
