import React, { useState, useEffect, useContext } from "react";
import { SurveyTemplateConfiguration } from "../../../../services/TemplateConfigData";
import "../../../../../../common-styles/SurveyTemplateContainer.css";
import TwoAxleNavigation from "../2-Axle-Navigation/TwoAxleNavigation";
import { Card, CardContent } from "@material-ui/core";
import SurveyTemplateService from "../../../../services/SurveyTemplateApis";
import Spinner from "../../../../../../common-components/spinner/spinner";
import SurveyTemplate from "../../../template/SurveyTemplate";
import { StateContext } from "../../../../../../services/StateProvider";
import {
  accountInfoIdsFormatter,
  getAccountData,
} from "../../../../../../services/AccountInfoHandler";

const TwoAxleSixTiresTwo = () => {
  const configName =
    SurveyTemplateConfiguration.ConfigurationNames.TwoAxlesSixTiresTwo;

  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);

  const surveyTemplateService = new SurveyTemplateService();
  const [showSpinner, setShowSpinner] = useState(true);
  const [axleConfigurationDetails, setAxleConfigurationDetails] = useState();
  let accountData = getAccountData();
  const [locationData, setLocationData] = useState(accountData.location);
  let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
  let locationIds = accountInfoObject.locationIds;
  const [locationIdSelected, setLocationIdSelected] = useState([
    locationIds[0],
  ]);
  const [locationNameSelected, setLocationNameSelected] = useState(
    accountData.location[0].label
  );

  useEffect(() => {

    locationIds = accountInfoObject.locationIds;
    setLocationIdSelected([locationIds[0]]);
    setLocationNameSelected(accountData.location[0].label);
    setLocationData(accountData.location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  useEffect(() => {
    let masterConfigRequestObject = {};

    setShowSpinner(true);
      surveyTemplateService
          .getSurveyTemplateMasterConfig(masterConfigRequestObject)
          .then((data) => {
            let currentAxleConfig;
            data.model.axleConfigs.forEach((config) => {
              if (config.configurationName === configName) {
                currentAxleConfig = config;
              }
            });
            let currentAxleConfigPosition;
            Object.keys(SurveyTemplateConfiguration.TirePositionConfig).forEach(
              (inspectionOrderKey) => {
                  currentAxleConfigPosition =
                    SurveyTemplateConfiguration.TirePositionConfig[
                      inspectionOrderKey
                    ][configName];
                }
            );
            currentAxleConfig.configurationPosition =
              currentAxleConfigPosition.toString();

            setAxleConfigurationDetails(currentAxleConfig);
            setShowSpinner(false);
          });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationIdSelected]);

  const setInspectionOrderLocation = (_events, newLocationValue) => {
    setLocationIdSelected([newLocationValue.value]);
    setLocationNameSelected(newLocationValue.label);
  };

  return (
    <div>
      <TwoAxleNavigation />
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="survey-template-container">
          <Card>
            <CardContent>
              <SurveyTemplate
                axleConfigurationDetails={axleConfigurationDetails}
                setInspectionOrderLocation={setInspectionOrderLocation}
                locationData={locationData}
                locationNameSelected={locationNameSelected}
              />
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};

export default TwoAxleSixTiresTwo;
