import React, { useState } from "react";
import "../../../../../common-styles/TableStyle.css";
import "./Table.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../common-components/no-data-found/NoDataFound";
import SearchIcon from "../../../../../assets/icons8-search.svg";
import VehicleListSetter from "../../../services/VehicleListUtils";
import { useHistory } from "react-router";
import Globalization from "../../../../../globalization";
// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 370,
  },
  container: {
    height: 580,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    zIndex: "2!important",
  },
  tableHeadRow: {
    zIndex: "2!important",
    color: "#2E2E2E !important",
    fontSize: "1em !important",
    fontFamily: "Barlow  !important",
    fontWeight: "500 !important",
  },
  next: {
    "& > *": {
      margin: theme.spacing(1),
      marginTop: "30px",
    },

  },
  prevNextBtn: {
    "&:hover": {
      opacity: 1,
    },
    width: "45%",
    border: 0,
    opacity: 0.8,
    color: "#0055A4",
    boxShadow: "none",
  },
}));

const VehicleIdListTable = ({ tableData, showColumns, vehicleId }) => {
  const [vehicleIdListTableData, setVehicleIdListTableData] =
    useState(tableData);
  const classes = useStyles();
  const vehicleIdSetter = new VehicleListSetter();
  const originalManageVehicleListTableData = tableData;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("VehicleNumber");
  const formSubmitHandler = (e) => {
    //should prevent submit, and continue below?
    e.preventDefault();
    return false;
  };
  const history = useHistory();
  const globalizationMapping = new Globalization();
  const [searchValue, setSearchValue] = useState("");

  // USED FOR SORTING
  function descendingComparator(a, b, sOrderBy) {
    return b[sOrderBy].localeCompare(a[sOrderBy], undefined, { numeric: true });
  }
  function getComparator(sOrder, sOrderBy) {
    return sOrder === "desc"
      ? (a, b) => descendingComparator(a, b, sOrderBy)
      : (a, b) => -descendingComparator(a, b, sOrderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const sorder = comparator(a[0], b[0]);
      if (sorder !== 0) {
        return sorder;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const searchTableData = (event) => {
    setSearchValue(event.target.value);
    const searchText = event.target.value.toLowerCase();
    const originalTableDataStore = originalManageVehicleListTableData.rowsData;
    const filteredData = vehicleIdSetter.search(
      originalTableDataStore,
      searchText
    );
    const updatedTableData = {
      rowsData: filteredData,
      headCells: originalManageVehicleListTableData.headCells,
      selectedColShow: originalManageVehicleListTableData.selectedColShow,
    };
    setVehicleIdListTableData(updatedTableData);
  };

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowClick = (row) => {
    history.push("/quick-inspect/" + row.VehicleId);
  };

  const vehicleIdClassIdentifier = (rowVehicleId, vehicleId, index) => {
    if (rowVehicleId.toString().toLowerCase() === vehicleId.toString().toLowerCase()) {
      return "selected-row-vehicle-id";
    } else if (index % 2 === 0) {
      return "table-row";
    } else {
      return "table-row-alternate";
    }
  }

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div
            className="search-table-container search-table-container-quick-inspect"
            style={{ width: "100%" }}
          >
            <form
              style={{ width: "100%" }}
              className="search-table-container1"
              noValidate
              autoComplete="off"
              onSubmit={formSubmitHandler}
            >
              <TextField
                style={{ width: "160%", padding: 10 }}
                className="search-table-bar"
                id="input-with-icon-textfield"
                variant="outlined"
                placeholder="Search vehicle ID"
                value={searchValue}
                onChange={searchTableData}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        className="keyword-search-icon-img"
                        alt={globalizationMapping.REPORTCOMMONWORDS.Search}
                        src={SearchIcon}
                      />
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 50 }}
              />
            </form>
          </div>

          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="a dense table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {vehicleIdListTableData.headCells.map((headCell) =>
                    showColumns[headCell.value] && headCell.keyIndex === 0 ? (
                      <TableCell
                        className={classes.tableHeadRow}
                        key={headCell.keyIndex}
                        align="left"
                        sortDirection={
                          orderBy === headCell.value ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === headCell.value}
                          direction={orderBy === headCell.value ? order : "asc"}
                          onClick={handleRequestSort("VehicleNumber")}
                        >
                          {`Vehicle ID`}
                          {orderBy === headCell.value ? (
                            <span className={classes.visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              <tbody>
                <tr className="table-bottom-line">
                  <td colSpan={vehicleIdListTableData.headCells.length}></td>
                </tr>
              </tbody>
              {vehicleIdListTableData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(
                    vehicleIdListTableData.rowsData,
                    getComparator(order, orderBy)
                  ).map((row, index) => {
                    return (
                      <TableRow
                        className={vehicleIdClassIdentifier(row.VehicleId, vehicleId, index)}
                        onClick={() => handleRowClick(row)}
                        tabIndex={-1}
                        key={row.VehicleId}
                      >
                        {showColumns.VehicleNumber && (
                          <TableCell
                            align="left"
                            className={
                              row.VehicleId.toString().toLowerCase() ===
                                vehicleId.toString().toLowerCase()
                                ? "selected-table-content-text"
                                : "table-content-text"
                            }
                          >
                            {row.VehicleNumber}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
              {vehicleIdListTableData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="no-match-found"
                      colSpan={8}
                      align="center"
                    >
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default VehicleIdListTable;
