import { Backdrop, IconButton, makeStyles, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Globalization from "../../../../globalization";
import "./history-table-graph-modal.css";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Line } from "react-chartjs-2";
import CommonUtils from "../../../../services/utils";

const useStyles = makeStyles({
  IconButtons: {
    color: "#1250B0",
    border: "1px solid"
  },
  IconButtonInactive: {
    color: "#a3bce3",
    border: "1px solid"
  }
});

export const HistoryGraphModal = (props) => {
  const classes = useStyles();
  const globalizationMapping = new Globalization();
  const graphTitles = globalizationMapping.GRAPH;

  const commonUtilsObj = new CommonUtils();

  const [graphTitle, setGraphTitle] = useState(graphTitles.milesTreadTitle);
  const [currentGraphData, setCurrentGraphData] = useState(props.mptdGraphData);
  const [currentGraph, setCurrentGraph] = useState(props.currentGraph);

  const gotoNext = () => {
    if (currentGraph < 4) setCurrentGraph((currentGraph) => currentGraph + 1);
  };

  const gotoPrev = () => {
    if (currentGraph > 0) setCurrentGraph((currentGraph) => currentGraph - 1);
  };

  useEffect(() => {
    props.setData();
    switch (currentGraph) {
      case 0:
        setCurrentGraphData(props.mptdGraphData);
        setGraphTitle(graphTitles.milesTreadTitle);
        break;
      case 1:
        setCurrentGraphData(props.hptdGraphData);
        setGraphTitle(graphTitles.hoursTreadTitle);
        break;
      case 2:
        setCurrentGraphData(props.cphGraphData);
        setGraphTitle(graphTitles.costHourTitle);
        break;
      case 3:
        setCurrentGraphData(props.pressureGraphData);
        setGraphTitle(graphTitles.pressureTitle);
        break;
      case 4:
        setCurrentGraphData(props.tdGraphData);
        setGraphTitle(graphTitles.treadTitle);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGraph]);

  return (
    <React.Fragment>
      <div className="container">
        <Modal
          open={true}
          onClose={props.onModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          disableScrollLock={true}
        >
          <div className="tire-graph-modal-container central-modal">
            <div className="graph-modal-header">
              <div className="graph-modal-title-container">
                <div className="graph-modal-action-buttons">
                  <IconButton
                    className={currentGraph === 0 ? classes.IconButtonInactive : classes.IconButtons}
                    onClick={gotoPrev}
                    size="small"
                    aria-label="toggle-left"
                    component="span"
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <div className="graph-modal-title">{graphTitle}</div>
                  <IconButton
                    className={currentGraph === 4 ? classes.IconButtonInactive : classes.IconButtons}
                    onClick={gotoNext}
                    size="small"
                    aria-label="toggle-left"
                    component="span"
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </div>
              </div>
              <div className="close-icon-container" onClick={props.onModalClose}>
                <img src={require("../../../../assets/close-icon.svg")} className="modal-close-icon" alt="close-icon" />
              </div>
            </div>
            <div className="graph-modal-graph-container">
              <Line data={currentGraphData} options={commonUtilsObj.viewTireLineOptions} />
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};
