import CommonService from '../../../services/common-api-service'
import { SERVER } from '../../../services/api-urls';


export default class AdminListApis {
    cs;
    constructor() {
        this.cs = new CommonService();
    }
    adminRequests = () => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getMasterDataRequests}`, 'get', {});
    }
    markAsCompleted = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.updateMasterData,"put",req);
    }
    logoApproval = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.logoApproval}${req}`,'get',{})
    }
    updateUserPreferences = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.updateUserPref,'put',req)
    }
    addVehicleManufacturer = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addVehicleManufacturers,'post',req)
    }
    addVehicleType = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addVehicleType,'post',req)
    }
    addVehicleModel = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addVehicleModel,'post',req)
    }
    addAxleConfig = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addAxleConfig,'post',req)
    }
    addRemovalReason = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addRemovalReason,'post',req)
    }
    addTireManufacturers = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addTireManufacturers,'post',req)
    }
    addTireSize = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addTireSize,'post',req)
    }
    addRimSize = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addRimSize,'post',req)
    }
    addRimType = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addRimType,'post',req)
    }
    addRimValve = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addRimValve,'post',req)
    }
    addTireTreadDepth = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addTireTreadDepth,'post',req)
    }
    addTireType = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addTireType,'post',req)
    }
    addTireCompound = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addTireCompound,'post',req)
    }
    addRimManufacturer = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.addRimManufacturer,'post',req)
    }
    deleteMaster = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.deleteMaster}${req}`,'put',null)
    }
    deleteTreadDepth = (req, treadId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.deleteTreadDepth}${treadId}`,'put',req)
    }
    deleteBroadcastMessage = (id) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.deleteBroadcastMessage}${id}`,'put',{})
    }
    deleteRimSize = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.deleteRimSize,'put',req)
    }
    deleteRimType = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.deleteRimType,'put',req)
    }
    deleteRimValve = (req) => {
        return this.cs.httpConnection(SERVER.SERVICES.deleteRimValve,'put',req)
    }
    getMasterDataWeb = () => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getMasterDataWeb}`,'get',{})
    }
    getParentAccountList = () => {
        return this.cs.httpConnection(SERVER.SERVICES.getParentAccount, "get", {});
    };
    vehicleFootPrint = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.vehicleFootPrint}${req}`,'post',null)
    };
    vehicleTireSize = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.vehicleTireSize}${req}`,'post',null)
    };
    manageTiresByAccount = (parentAccountId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.manageTiresByAccount}${parentAccountId}`, "get", {});
    };
    getTireById = (tireId) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.getTireById}${tireId}`, "get", {});
    };
    updateTire = (req) => {
        return this.cs.httpConnection(`${SERVER.SERVICES.editCreateTire}`,'put',req)
    }
}