import React, { useEffect, useState, useContext, useRef } from "react";
import "./home-dashboard.css";
import { Bar, Doughnut } from "react-chartjs-2";
import "react-quill/dist/quill.core.css";
import {
  makeStyles,
  Grid,
  Card,
  CardContent,
  InputLabel,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  TableSortLabel,
  TablePagination,
  Divider,
  Fab,
  Tooltip,
  Modal
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PrintIcon from "@material-ui/icons/Print";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Spinner from "../../../../common-components/spinner/spinner";
import EmailModalComponent from "../../../../common-components/modal-components/email-modal/EmailModalComponent";
import ReportDashboardService from "../../../../scenes/reports-dashboard/services/report-dashboard-apis";
import IndexSVG from "../../../../assets/index";
import Globalization from "../../../../globalization/index";
import { StateContext } from "../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../services/AccountInfoHandler";
import TiresService from "../../../../scenes/tires-reports/services/TiresApis";
import CommonUtils from "../../../../services/utils";
import HeaderApi from "../../../../common-components/header/services/header-apis";
import TickAllOk from "../../../../assets/tick-all-ok.svg";
import ImageAllOk from "../../../../assets/all-ok-image.svg";
import MailIcon from "../../../../assets/Dash_mail.svg";
import NoDataFound from "../../../../common-components/no-data-found/NoDataFound";
import { useHistory, useLocation } from "react-router-dom";
import HomeDashboardPDF from "../../../../common-components/modal-components/export-options/home-dashboard-pdf/HomeDashboardPDF";
import FlashMessage from "../../../../common-components/pop-up-notification/notification-snackbar";
import ManageTireListApis from "../../../manage-tire/services/ManageTireListApis";
import { useComponentVisible } from "../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import BroadcastMessages from "./broadcastMessages";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiPaper-rounded": {
      borderRadius: "14px",
      height: "100%"
    }
  },
  inputLabel: {
    "& label.MuiInputLabel-animated": {
      display: "inline-block",
      color: "#ffdc00",
      fontSize: "2em"
    }
  },
  inputLocationBold: {
    color: " #ffdc00 !important",
  },
  inputWelcome: {
    display: "inline-block",
    color: "#FFF !important",
    fontSize: "2em"
  },
  inputLocation: {
    display: "inline-block !important",
    color: "#FFF",
    fontSize: "1em"
  },
  inputView: {
    display: "inline-block !important",
    color: "#FFF !important",
    fontSize: "0.75em",
    cursor: "pointer"
  },
  listLocation: {
    backgroundColor: "#FFF",
    zIndex: 1,
    padding: 5
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600,
  },
  container: {
    height: 370
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    let firstVal = a[orderBy].toLowerCase().trim()
    let secondVal = b[orderBy].toLowerCase().trim()
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;

  } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "asc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function HomeDashboard() {
  // GLOBAL ACCOUNT STATE management
  const commonUtils = new CommonUtils();
  const manageTireListService = new ManageTireListApis();
  const headerApi = new HeaderApi();
  const history = useHistory();
  const classes = useStyles();
  const inputLocationValue = useRef();
  const [globalAccountState] = useContext(StateContext);
  const globalizationMapping = new Globalization();
  let inspectHeaders = globalizationMapping.INSPECT;
  let dashboard = globalizationMapping.HEADER;
  const globalHeaderTexts = globalizationMapping.HEADER;
  const globalTexts = globalizationMapping.HOMEDASHBOARD;


  const [greetingText, setGreetingText] = useState(globalTexts.GREETINGMORNING);
  const [userProfile, setUserProfile] = useState();
  const [locationName, setLocationName] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [selectedDropDown, setSelectedDropDown] = useState("All");
  const [floatingCardData, setFloatingCardData] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const locationObj = useLocation();
  const [flashMessageState, setFlashMessageState] = useState(locationObj);
  const [totalTire, setTotalTire] = useState();
  const [bySizeGraph, setBySizeGraph] = useState();
  const [byPercentageWorn, setPercentageWorn] = useState();
  const [byRemovalReason, setRemovalReason] = useState();
  const [recentActivityTable, setRecentActivityTable] = useState([]);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("actionDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [sizeDropDown, setSizeDropDown] = useState([]);
  const [oriSizeArray, setOriSizeArray] = useState([]);
  const [originalRAWSizeArray, setOriRAWSizeArray] = useState(null);
  const [TotalTireOptions, setTotalTireOptions] = useState(commonUtils.barOptions);
  const [SizeOptions, setSizeOptions] = useState(commonUtils.barOptions);
  const [PercentageWornOptions, setPercentageWornOptions] = useState(commonUtils.pieOptions);
  const [RemovalReasonOptions, setRemovalReasonOptions] = useState(commonUtils.pieOptions);
  const [modalHandler, setModalHandler] = useState({ handleEmail: false });
  const broadcastFlag = JSON.parse(localStorage.getItem("broadcastFag"));
  const [modalOpen, setModalOpen] = useState(false);
  const [broadCastData, setbroadCastData] = useState([]);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);


  // DASHBOARD NAVIGATION METHOD

  useEffect(() => {
    if (broadcastFlag === false) {
      setModalOpen(false);
    } else {
      setModalOpen(true);
      localStorage.setItem("broadcastFag", false);
    }

    setTimeout(() => {
      localStorage.setItem("createLocation", 0);
      localStorage.setItem("editLocation", 0);
    }, 5000);

  }, [broadcastFlag]);

  useEffect(() => {
    setShowSpinner(true);
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let locationIds = accountInfoObject.locationIds;
    headerApi.getLocationById(locationIds[0]).then((accountData) => {
      if (accountData.model) {
        localStorage.setItem("InspectionOrderId", JSON.parse(accountData.model.inspectionOrderId));
        localStorage.setItem("noOfTreadDepthReadings", accountData.model.noOfTreadDepthReadings);
        localStorage.setItem("defaultRemovalTreadDepth", accountData.model.defaultRemovalTreadDepth);
      }
    });
    let d = new Date();

    if (d.getHours() >= 0 && d.getHours() <= 12) {
      setGreetingText(globalTexts.GREETINGMORNING);
    } else if (d.getHours() >= 12 && d.getHours() <= 16) {
      setGreetingText(globalTexts.GREETINGNOON);
    } else {
      setGreetingText(globalTexts.GREETINGEVENING);
    }

    const colorCodes = commonUtils.getRandomColorHex();

    const svg = new IndexSVG();
    const reportDashboardService = new ReportDashboardService();
    // UPDATING THE GLOBAL ACCOUNT STATES

    let locationName = [];
    for (let item of globalAccountState.accountInfo.location) {
      locationName.push(item.label);
    }
    setLocationName(locationName);
    setAccountName(globalAccountState.accountInfo.account.accountName);
    if (locationName.length > 1) {
      inputLocationValue.current = "Locations";
    } else {
      inputLocationValue.current = "Location";
    }
    headerApi.getUserProfile().then((data) => setUserProfile(data.model));
    const requestObject = {
      accountIds: accountIds.toString(),
      locationIds: locationIds.toString()
    };
    headerApi.getBroadCastDetails().then((data) => {
      setbroadCastData(data.data);
    });
    reportDashboardService.getReportDashboardData(requestObject).then((data) => {
      let model = data.model;
      let floatingData = [
        {
          id: "totalTiresCount",
          name: globalizationMapping["totalTiresCount"],
          imgPath: svg["totalTiresCount"],
          count: model.totalTiresCount
        }
      ];
      Object.keys(model).forEach(function (key) {
        if (
          key !== "rimCount" &&
          key !== "totalTiresCount" &&
          key !== "removedTireCount" &&
          key !== "tiresBelowInflationCount" &&
          key !== "tiresWithLowTreadDepthCount" &&
          key !== "tireConditionCount"
        ) {
          floatingData.push({
            id: key,
            name: globalizationMapping[key],
            imgPath: svg[key],
            count: model[key]
          });
        }
      });
      setFloatingCardData(floatingData);
      if (locationObj.state !== undefined) {
        switch (locationObj.state.updatedSection) {
          case "editlocationdetails":
          case "accountnlocation":
          case "parentaccountdetails":
          case "editoldtire":
          case "inspecttire":
          case "removeinstalledtire":
          case "tranfertire":
          case "deletetire":
          case "deltevehicle":
          case "tranfervehicle":
          case "createlocation":
          case "editvehicle":
          case "createnewvehicle":
          case "changetiredisposition":
          case "editoldrim":
          case "createnewrim":
          case "deleteaccount":
            if (locationObj.state.updateStatus) {
              setShowNotification(true);
            }
            break;
          default:
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
            break;
        }
        setFlashMessageState(locationObj);
      }
    });
    //Total Tires Graph
    const tiresApis = new TiresService();
    const reqObjectTT = {
      accountIds: accountIds,
      locationIds: locationIds ? locationIds : [],
      graphType: "Bar",
      reportName: "GetTiresByDisposition",
      dateRangeType: "AllTime",
      fromDate: "",
      toDate: ""
    };
    tiresApis.getTiresGraph(reqObjectTT).then((data) => {
      if (data && data.model && data.model.graphData && data.model.graphData.chartlabels.length > 0) {
        let legendSize = 14;
        if (data.model.graphData.chartlabels.length >= 18) {
          legendSize = 8;
        } else if (data.model.graphData.chartlabels.length <= 7) {
          legendSize = 16;
        } else if (data.model.graphData.chartlabels.length >= 15) {
          legendSize = 12;
        } else if (data.model.graphData.chartlabels.length >= 10) {
          legendSize = 10;
        }
        setTotalTireOptions({
          ...TotalTireOptions,
          title: {
            ...TotalTireOptions.title,
            text: globalizationMapping.totalTiresCount
          },
          scales: {
            ...TotalTireOptions.scales,
            yAxes: [
              {
                ...TotalTireOptions.scales.yAxes[0],
                scaleLabel: {
                  ...TotalTireOptions.scales.yAxes[0].scaleLabel,
                  labelString: globalizationMapping.GRAPH["Inflation-y"]
                }
              }
            ],
            xAxes: [
              {
                ...SizeOptions.scales.xAxes[0],
                ticks: {
                  ...SizeOptions.scales.xAxes[0].ticks,
                  fontSize: legendSize,
                  fontColor: "#333"
                }
              }
            ]
          }
        });
        SizeOptions.scales.yAxes[0].scaleLabel.labelString = globalizationMapping.GRAPH["size-y"];
        SizeOptions.title.text = globalizationMapping.GRAPH.installTires;
        let chartLbl = [];
        for (const element of data.model.graphData.chartlabels) {
          let label = element.split(",");
          chartLbl.push(label[0]);
        }
        let tt = {
          labels: chartLbl,
          datasets: [
            {
              backgroundColor: colorCodes,
              fill: true,
              data: data.model.graphData.chartData[0].data
            }
          ]
        };
        setTotalTire(tt);
      } else setTotalTire(null);
    });
    const reqObjectSize = {
      accountIds: accountIds,
      locationIds: locationIds ? locationIds : [],
      graphType: "Bar",
      reportName: "GetInstalledByTireSize",
      dateRangeType: "AllTime",
      fromDate: "",
      toDate: ""
    };
    tiresApis.getTiresGraph(reqObjectSize).then((data) => {
      if (data && data.model && data.model.graphData && data.model.graphData.chartlabels.length > 0) {
        let legendSize = 14;
        if (data.model.graphData.chartlabels.length >= 25) {
          legendSize = 10;
        }
        setSizeOptions({
          ...SizeOptions,
          title: {
            ...SizeOptions.title,
            text: globalizationMapping.GRAPH.installTires
          },
          scales: {
            ...SizeOptions.scales,
            yAxes: [
              {
                ...SizeOptions.scales.yAxes[0],
                scaleLabel: {
                  ...SizeOptions.scales.yAxes[0].scaleLabel,
                  labelString: globalizationMapping.GRAPH["size-y"]
                }
              }
            ],
            xAxes: [
              {
                ...SizeOptions.scales.xAxes[0],
                ticks: {
                  ...SizeOptions.scales.xAxes[0].ticks,
                  fontSize: legendSize,
                  fontColor: "#333"
                }
              }
            ]
          }
        });
        let chartLbl = [];
        for (let i = 0; i < data.model.graphData.chartlabels.length; i++) {
          let label = data.model.graphData.chartlabels[i].split(",");
          let value = data.model.graphData.chartData[0].data[i];
          chartLbl.push({ label: label[0], value: value });
        }
        chartLbl.sort((a, b) => {
          if (a.label < b.label) return -1;
          else if (a.label > b.label) return 1;
          else return 0;
        });
        let dataArr = { labels: [], datas: [] };
        let dataobj = {};
        let countobj = {};
        chartLbl.forEach((obj) => {
          let count = dataobj[obj.label] ? dataobj[obj.label] : 0;
          dataobj[obj.label] = count + obj.value;
          let count2 = countobj[obj.label] ? countobj[obj.label] : 0;
          countobj[obj.label] = count2 + 1;
        });
        dataArr.datas = [];
        for (const key in dataobj) {
          if (Object.hasOwnProperty.call(dataobj, key)) {
            const element = dataobj[key];
            const element2 = countobj[key];
            dataArr.labels.push(key);
            dataArr.datas.push(element / element2);
          }
        }
        let size = {
          labels: dataArr.labels,
          datasets: [
            {
              backgroundColor: colorCodes,
              fill: true,
              data: dataArr.datas
            }
          ]
        };
        setBySizeGraph(size);
      } else setBySizeGraph(null);
    });
    const reqObjectPWorn = {
      accountIds: accountIds,
      locationIds: locationIds ? locationIds : [],
      graphType: "Pie",
      reportName: "GetInstalledByPercentageWorn",
      dateRangeType: "AllTime",
      fromDate: "",
      toDate: ""
    };
    tiresApis.getTiresGraph(reqObjectPWorn).then((data) => {
      setOriRAWSizeArray(data);
      let sizeArray = [];
      let uniqueSizeArray = [];
      let originalArray = [];
      if (accountIds[0] !== "0000")
        manageTireListService.manageTiresByAccount(accountIds[0]).then((tireData) => {
          const dataSize = tireData.model;
          dataSize.forEach((value) => {
            if (value.dispositionId === 7) {
              value.percentageWorn = value.percentageWorn === null ? 0 : value.percentageWorn;
              sizeArray.push({
                sizeName: value.sizeName,
                sizeId: value.sizeId,
                percentageWorn: value.percentageWorn
              });
            }
          });
          originalArray = [...sizeArray];
          const key = "sizeName";
          uniqueSizeArray = [...new Map(sizeArray.map((item) => [item[key], item])).values()];
          setOriSizeArray(originalArray);
          setSizeDropDown(uniqueSizeArray);
          if (data && data.model && data.model.graphData && data.model.graphData.chartlabels.length > 0) {
            let legendSize = 16;
            if (data.model.graphData.chartlabels.length > 4) {
              legendSize = 12;
            }
            setPercentageWornOptions({
              ...PercentageWornOptions,
              title: {
                ...PercentageWornOptions.title,
                text: globalizationMapping.GRAPH.installTires2
              },
              legend: {
                ...PercentageWornOptions.legend,
                labels: {
                  ...PercentageWornOptions.legend.labels,
                  fontSize: legendSize
                }
              }
            });
            let chartData = data.model.graphData.chartData;
            let totalValue = 0;
            chartData.forEach((value) => {
              totalValue += value;
            });
            chartData = chartData.map((value) => {
              return ((value / totalValue) * 100).toFixed(1);
            });
            let pw = {
              labels: data.model.graphData.chartlabels,
              datasets: [
                {
                  backgroundColor: commonUtils.percentageWornColors,
                  fill: true,
                  data: chartData
                }
              ]
            };
            setPercentageWorn(pw);
          } else setPercentageWorn(null);
        });
    });
    const reqObjectRReason = {
      accountIds: accountIds,
      locationIds: locationIds ? locationIds : [],
      graphType: "Pie",
      reportName: "GetScrapByRemovalReason",
      dateRangeType: "AllTime",
      fromDate: "",
      toDate: ""
    };
    tiresApis.getTiresGraph(reqObjectRReason).then((data) => {
      if (data && data.model && data.model.graphData && data.model.graphData.chartlabels.length > 0) {
        let legendSize = 12;
        if (data.model.graphData.chartlabels.length >= 18) {
          legendSize = 6;
        } else if (data.model.graphData.chartlabels.length <= 7) {
          legendSize = 16;
        } else if (data.model.graphData.chartlabels.length >= 15) {
          legendSize = 10;
        } else if (data.model.graphData.chartlabels.length >= 10) {
          legendSize = 8;
        }
        setRemovalReasonOptions({
          ...RemovalReasonOptions,
          title: {
            ...RemovalReasonOptions.title,
            text: globalizationMapping.GRAPH.scrapTires
          },
          legend: {
            ...RemovalReasonOptions.legend,
            labels: {
              ...RemovalReasonOptions.legend.labels,
              fontSize: legendSize
            }
          }
        });
        let totalValue = 0;
        let chartData = data.model.graphData.chartData;
        chartData.forEach((value) => {
          totalValue += value;
        });
        chartData = chartData.map((value) => {
          return ((value / totalValue) * 100).toFixed(1);
        });
        let rr = {
          labels: data.model.graphData.chartlabels,
          datasets: [
            {
              backgroundColor: colorCodes,
              fill: true,
              data: chartData
            }
          ]
        };
        setRemovalReason(rr);
      } else setRemovalReason(null);
      let accountID = JSON.parse(localStorage.getItem("accountInfo")).account.accountId;
      if (accountID !== "0000") {
        headerApi.getUserActions(accountID).then((data) => {
          if (data.model) {
            let recentActivity = [];
            Object.values(data.model).forEach((val) => {
              let event = val["actionDescription"];
              event = event.split(" ");
              let eventGlobalString = event[0].split(".");
              if (eventGlobalString[0] === "INSPECT") {
                event[0] = inspectHeaders[eventGlobalString[1]];
                if (eventGlobalString[1] === "UPDATEDHOURSFOR") {
                  eventGlobalString[1] = "UPDATEHOURS";
                }
                if (event[0]) {
                  val.actionDescription = `${event[0]}${" "}${event[1]}`;
                  val.event = inspectHeaders[eventGlobalString[1]];
                  recentActivity.push(val);
                }
              }
              return true;
            });
            setRecentActivityTable(recentActivity);
            setPage(0);
            let arr = [];
            if (data.model.length > 10) {
              arr.push(10);
            }
            if (data.model.length > 15) {
              arr.push(15);
            }
            if (data.model.length > 25) {
              arr.push(25);
            }
            arr.push(data.model.length);
            setArrRowsPerPageOptions(arr);
          }
        });
      }
    });
    setTimeout(() => {
      setShowSpinner(false);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const handleRequestSort = (property) => (_event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFloatingCardClick = (item) => {
    switch (item) {
      case "totalTiresCount":
        history.push({ pathname: "/manage-tire", state: { item: "All" } });
        break;
      case "tiresInInventoryCount":
        history.push({ pathname: "/manage-tire", state: { dispositionId: 8 } });
        break;
      case "scrapTireCount":
        history.push({ pathname: "/manage-tire", state: { dispositionId: 1 } });
        break;
      case "tiresInServiceCount":
        history.push({ pathname: "/manage-tire", state: { dispositionId: 7 } });
        break;
      case "vehicleCount":
        history.push("/manage-vehicle");
        break;
      case "removedTireCount":
        history.push({ pathname: "/manage-tire", state: { dispositionId: 4 } });
        break;
      default:
        break;
    }
  };
  const handleHomePrint = async () => {
    const pdfmake = new HomeDashboardPDF({
      floatingCardData: floatingCardData
    });
    const data = await pdfmake.PDFMake();
    const win = window.open("", "_blank");
    await data.print({}, win);
  };
  const handleHomeExport = async () => {
    const pdfmake = new HomeDashboardPDF({
      floatingCardData: floatingCardData
    });
    const dataPdf = await pdfmake.PDFMake();
    await dataPdf.download("home.pdf");
  };
  const onClickEmail = () => {
    setModalHandler({ handleEmail: true });
  };
  const setDropdownManufacturerValue = (event) => {
    setSelectedDropDown(event);
    if (event === "All") {
      let data = originalRAWSizeArray;
      if (data && data.model && data.model.graphData && data.model.graphData.chartlabels.length > 0) {
        let legendSize = 16;
        if (data.model.graphData.chartlabels.length > 4) {
          legendSize = 12;
        }
        setPercentageWornOptions({
          ...PercentageWornOptions,
          title: {
            ...PercentageWornOptions.title,
            text: globalizationMapping.GRAPH.installTires2
          },
          legend: {
            ...PercentageWornOptions.legend,
            labels: {
              ...PercentageWornOptions.legend.labels,
              fontSize: legendSize
            }
          }
        });
        let chartData = data.model.graphData.chartData;
        let totalValue = 0;
        chartData.forEach((value) => {
          totalValue += value;
        });
        chartData = chartData.map((value) => {
          return ((value / totalValue) * 100).toFixed(1);
        });
        let pw = {
          labels: data.model.graphData.chartlabels,
          datasets: [
            {
              backgroundColor: commonUtils.percentageWornColors,
              fill: true,
              data: chartData
            }
          ]
        };
        setPercentageWorn(pw);
      } else setPercentageWorn(null);
      return;
    }
    let percentageWornArray = [];
    let aCount = 0,
      bCount = 0,
      cCount = 0,
      dCount = 0;
    oriSizeArray.forEach((val) => {
      if (val.percentageWorn >= 0 && val.percentageWorn <= 25 && val.sizeName === event) {
        aCount = aCount + 1;
      } else if (val.percentageWorn > 25 && val.percentageWorn <= 50 && val.sizeName === event) {
        bCount = bCount + 1;
      } else if (val.percentageWorn > 50 && val.percentageWorn <= 75 && val.sizeName === event) {
        cCount = cCount + 1;
      } else if (val.percentageWorn > 75 && val.sizeName === event) {
        dCount = dCount + 1;
      } else {
        return;
      }
    });
    percentageWornArray.push(aCount);
    percentageWornArray.push(bCount);
    percentageWornArray.push(cCount);
    percentageWornArray.push(dCount);

    if (percentageWornArray.length > 0) {
      let legendSize = 16;
      if (percentageWornArray.length > 4) {
        legendSize = 12;
      }
      setPercentageWornOptions({
        ...PercentageWornOptions,
        title: {
          ...PercentageWornOptions.title,
          text: globalizationMapping.GRAPH.installTires2
        },
        legend: {
          ...PercentageWornOptions.legend,
          labels: {
            ...PercentageWornOptions.legend.labels,
            fontSize: legendSize
          }
        }
      });
      let chartData = percentageWornArray;
      let totalValue = 0;
      chartData.forEach((value) => {
        totalValue += value;
      });
      chartData = chartData.map((value) => {
        return ((value / totalValue) * 100).toFixed(1);
      });

      let chartlabels = ["<25%," + aCount + " Tire(s)", "<50%," + bCount + " Tire(s)", "<75%," + cCount + " Tire(s)", ">75%," + dCount + " Tire(s)"];

      let pw = {
        labels: chartlabels,
        datasets: [
          {
            backgroundColor: commonUtils.percentageWornColors,
            fill: true,
            data: chartData
          }
        ]
      };
      setPercentageWorn(pw);
    } else setPercentageWorn(null);
  };

  const handleRecentActivity = (vehicleId) => {
    history.push("/quick-inspect/" + vehicleId);
  };
  const modalBraodcast = () => {
    setModalOpen(true);
  };
  const modalClose = () => {
    setModalOpen(false);
  };


  const toolTipTitle = (item) => {
    let currentLanguage = sessionStorage.getItem("userLanguage") === 'en-uk' ? 'Tyres' : 'Tires';

    switch (item.id) {
      case "totalTiresCount":
        return `Go to Manage ${currentLanguage}: All`
      case "tiresInServiceCount":
        return `Go to Manage ${currentLanguage}: Installed`
      case "scrapTireCount":
        return `Go to Manage ${currentLanguage}: Scrap`
      case "tiresInInventoryCount":
        return `Go to Manage ${currentLanguage}: Inventory`
      case "vehicleCount":
        return "Go to Manage Vehicles"
      default:
        return ""
    }

  }

  const tooltipLocationName = (locationName) => {
    if (locationName.length > 0) {
      let locationNames = locationName.map((location) => { return location })
      let stringLocationNames = locationNames.join(", ");
      return stringLocationNames;
    } else return "";
  }

  return (
    <>
      <div className="home-container">
        {showNotification && flashMessageState.state && (Number(localStorage.getItem("createLocation")) > 0 || Number(localStorage.getItem("editLocation")) > 0) && (
          <FlashMessage status={flashMessageState.state.updateStatus} message={flashMessageState.state.successMessage} description={flashMessageState.state.successMessageDesc} />
        )}

        {showSpinner ? (
          <Spinner />
        ) : (
          <>
            <div className="home-upper-deck">
              <div className="background-image-home-deck">
                <div className="background-image-home-dashboard">
                  <Fab onClick={() => modalBraodcast()} className="fab-class" variant="extended" color="primary" size="small">
                    <AddIcon />
                    <div>{globalizationMapping.HIGHLIGHTS.HIGHLIGHTS}</div>
                  </Fab>
                  <div className="dashboard-header-home dashboard-header">
                    <div>
                      <div className={classes.inputLabel}>
                        <InputLabel className={classes.inputWelcome}>{globalHeaderTexts.WELCOME},</InputLabel>
                        &nbsp;&nbsp;&nbsp;
                        {userProfile?.firstName && <InputLabel className={classes.inputWelcome}>{`${userProfile.firstName}${"!"}`}</InputLabel>}
                        &nbsp;&nbsp;
                        <InputLabel>{greetingText}{"!"}</InputLabel>
                      </div>
                    </div>
                  </div>
                  {(totalTire || bySizeGraph || byPercentageWorn || byRemovalReason) && (
                    <div className="print-icon-home print-icon">
                      <button className="print-dialog table-function-item" onClick={() => handleHomePrint()}>
                        <span>
                          <PrintIcon style={{ verticalAlign: "middle", marginBottom: 5, marginLeft: 5 }}></PrintIcon>
                        </span>
                      </button>
                      {localStorage.getItem("isEmailBtnShow") === "true" ?
                        <button className="print-dialog table-function-item" onClick={onClickEmail}>
                          <img className="mail-icon-img" alt="" src={MailIcon} />
                          <span className={"table-toolbar-text"}> {globalizationMapping.MANAGE_PARENT_ACCOUNT.EMAIL}</span>
                        </button>
                        : null}
                      <button className="print-dialog table-function-item" style={{ marginRight: 50 }} onClick={() => setIsComponentVisible(!isComponentVisible)} ref={ref}>
                        <div className=" dropdown-icon-container" ref={ref}>
                          <span>
                            <FileCopyIcon style={{ verticalAlign: "middle", marginBottom: 8 }}></FileCopyIcon>
                          </span>
                          {isComponentVisible && (
                            <div className="export-dropdown-content">
                              <div onClick={() => handleHomeExport()} className="export-option">
                                {globalizationMapping.MANAGE_PARENT_ACCOUNT.PDF}
                              </div>
                              <Divider />
                            </div>
                          )}
                        </div>
                      </button>
                    </div>
                  )}
                  <div className={`home-location-header dashboard-title ${classes.inputLocation}`}>
                    <>
                      <InputLabel className={classes.inputLocation}>
                        {/* Showing information of selected Parent Account and Location: */}
                        {globalTexts.BELOWGREETINGLABEL}
                      </InputLabel>
                      &nbsp;
                      <InputLabel className={`${classes.inputLocation} ${classes.inputLocationBold}`}>{`${accountName}${" - "}${locationName.length}`}</InputLabel>
                      &nbsp;
                      <Tooltip title={tooltipLocationName(locationName)} arrow>
                        <InputLabel className={`${classes.inputLocation} ${classes.inputLocationBold}`}>{inputLocationValue.current}</InputLabel>
                      </Tooltip>
                    </>
                  </div>
                  <div className="card-views-container floating-card-padding">
                    {floatingCardData.map((item) => {
                      return (
                        <div key={item.id} onClick={() => handleFloatingCardClick(item.id)} className="floating-card" >
                          <Tooltip title={toolTipTitle(item)} placement="bottom-end" arrow>
                            <Card className="home-card-custom-style" style={{paddingBottom:30}}>
                              <CardContent>
                                <div>
                                  <div className="inner-card-container">
                                    <div className="floating-card-image">
                                      <img src={item.imgPath} alt="dashboard-card-icon-1" />
                                    </div>
                                    <div>
                                      <div className="floating-card-header">{item.name}</div>
                                      <div className="floating-card-value">{item.count}</div>
                                    </div>
                                  </div>
                                  <div className="view-link-floating-card">
                                    <div className="floating-card-value">
                                      <InputLabel className={classes.inputView}>
                                        {globalTexts.VIEW} &nbsp;
                                        <KeyboardArrowRight style={{ verticalAlign: "middle", color: commonUtils.primaryYellow }}></KeyboardArrowRight>
                                      </InputLabel>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>
                  <Modal open={modalOpen} className="header-change-modal-style" disableScrollLock={true} onClose={modalClose}>
                    <BroadcastMessages broadCastData={broadCastData} modalClose={modalClose}/>
                  </Modal>
                  {!showSpinner && !totalTire && !bySizeGraph && !byPercentageWorn && !byRemovalReason && (
                    <>
                      <div className="all-ok-container">
                        <div className="image-all-ok">
                          <img src={ImageAllOk} alt="tick-all-ok" />
                        </div>
                        <div className="text-container">
                          <div className="tick-all-ok">
                            <img src={TickAllOk} alt="tick-all-ok" />
                          </div>
                          <div className="all-ok-title-text">
                            {greetingText}, {userProfile?.firstName && `${userProfile.firstName}`}{"!"}
                          </div>
                          <div className="all-ok-sub-text">
                            {globalTexts.DATAWILLPOPULATE}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {!showSpinner && (totalTire || bySizeGraph || byPercentageWorn || byRemovalReason) && (
                    <>
                      <div className="grid-graph-home-dashboard-container">
                        <Grid className="card-views-container" container spacing={5}>
                          <Grid item sm={6} className={classes.root}>
                            <Card>
                              {totalTire && (
                                <Grid item={true} md={12} sm={12}>
                                  {Object.keys(totalTire).length > 0 && <Bar data={totalTire} height={200} options={TotalTireOptions} />}
                                </Grid>
                              )}
                              {!totalTire && (
                                <div className="no-data-container">
                                  <div className="no-data-container-text">{globalizationMapping.totalTiresCount}</div>
                                  <NoDataFound noDataType="graph"></NoDataFound>
                                </div>
                              )}
                            </Card>
                          </Grid>
                          <Grid item sm={6} className={classes.root}>
                            <Card>
                              {bySizeGraph && (
                                <Grid item={true} md={12} sm={12}>
                                  {Object.keys(bySizeGraph).length > 0 && <Bar data={bySizeGraph} height={190} options={SizeOptions} />}
                                </Grid>
                              )}
                              {!bySizeGraph && (
                                <div className="no-data-container">
                                  <div className="no-data-container-text">{globalizationMapping.GRAPH.installTires}</div>
                                  <NoDataFound noDataType="graph"></NoDataFound>
                                </div>
                              )}
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                      <div>
                        <Grid className="card-views-container" container spacing={5}>
                          <Grid item sm={6} className={classes.root}>
                            <Card>
                              {byPercentageWorn && (
                                <Grid item={true} md={12} sm={12}>
                                  <Select
                                    native
                                    IconComponent={ExpandMoreStyledIcon}
                                    className="sizeDropCSS"
                                    value={selectedDropDown}
                                    onChange={(event) => setDropdownManufacturerValue(event.target.value)}>
                                    <option value="All">{dashboard.ALL}</option>
                                    {sizeDropDown?.map((type) => {
                                      return <option value={type.sizeName} key={type.sizeName}>{type.sizeName}</option>;
                                    })}
                                  </Select>
                                  {Object.keys(byPercentageWorn).length > 0 && <Doughnut data={byPercentageWorn} height={200} options={PercentageWornOptions} />}
                                </Grid>
                              )}
                              {!byPercentageWorn && (
                                <div className="no-data-container">
                                  <div className="no-data-container-text">{globalizationMapping.GRAPH.installTires2}</div>
                                  <NoDataFound noDataType="graph"></NoDataFound>
                                </div>
                              )}
                            </Card>
                          </Grid>
                          <Grid item sm={6} className={classes.root}>
                            <Card>
                              {byRemovalReason && (
                                <Grid item={true} md={12} sm={12}>
                                  {Object.keys(byRemovalReason).length > 0 && <Doughnut data={byRemovalReason} height={200} options={RemovalReasonOptions} />}
                                </Grid>
                              )}
                              {!byRemovalReason && (
                                <div className="no-data-container">
                                  <div className="no-data-container-text">{globalizationMapping.GRAPH.scrapTires}</div>
                                  <NoDataFound noDataType="graph"></NoDataFound>
                                </div>
                              )}
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  )}
                  {recentActivityTable.length > 0 && (
                    <div className="container dashboard-header">
                      <div className={classes.root}>
                        <Paper className={classes.paper}>
                          <div className="table-toolbar" style={{ paddingLeft: "1%", paddingTop: "1%" }}>
                            <div className="table-filters">
                              <div className="search-table-container">
                                <label className="filter-table-label">
                                  <span className="view-parent-account-location-list-heading">
                                    {globalTexts.RECENTACTIVITY}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <TableContainer className={classes.container}>
                            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="a dense table"
                              size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell className="table-head-row" align="left" sortDirection={orderBy === "actionDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "actionDate"}
                                      direction={orderBy === "actionDate" ? order : "asc"}
                                      onClick={handleRequestSort("actionDate")}
                                    >
                                      <span>{globalTexts.DATE}</span>
                                    </TableSortLabel>
                                  </TableCell>
                                  <TableCell className="table-head-row" align="left" sortDirection={orderBy === "event" ? order : false}>
                                    <TableSortLabel active={orderBy === "event"} direction={orderBy === "event" ? order : "asc"} onClick={handleRequestSort("event")}>
                                      <span>{globalizationMapping.VEHICLEHISTORY.EVENT}</span>
                                    </TableSortLabel>
                                  </TableCell>
                                  <TableCell className="table-head-row" align="left" sortDirection={orderBy === "vehicleId" ? order : false}>
                                    <TableSortLabel active={orderBy === "vehicleId"} direction={orderBy === "vehicleId" ? order : "asc"} onClick={handleRequestSort("vehicleId")}>
                                      <span>{globalizationMapping.VEHICLELIST.VEHICLEID}</span>
                                    </TableSortLabel>
                                  </TableCell>
                                  <TableCell className="table-head-row" align="left" sortDirection={orderBy === "actionDescription" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "actionDescription"}
                                      direction={orderBy === "actionDescription" ? order : "asc"}
                                      onClick={handleRequestSort("actionDescription")}
                                    >
                                      <span>{globalHeaderTexts.COMMENTS}</span>
                                    </TableSortLabel>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {recentActivityTable.length > 0 && (
                                <TableBody>
                                  {stableSort(recentActivityTable, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                      return (
                                        <Tooltip title="go to Quick Inspect" placement="bottom-end" arrow key={row.actionId}>
                                          <TableRow
                                            key={row.actionId}
                                            className={index % 2 === 0 ? "table-row" : "table-row-alternate"}
                                            style={{ cursor: "pointer" }}
                                            tabIndex={-1}
                                            onClick={() => handleRecentActivity(row.vehicleId)}
                                          >
                                            <TableCell className="table-content-text">{commonUtils.formatISODate(row.actionDate)}</TableCell>
                                            <TableCell className="table-content-text">{row.event}</TableCell>
                                            <TableCell className="table-content-text">{row.vehicleId}</TableCell>
                                            <TableCell className="table-content-text">{row.actionDescription}</TableCell>
                                          </TableRow>
                                        </Tooltip>
                                      );
                                    })}
                                </TableBody>
                              )}
                              {recentActivityTable.length === 0 && (
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      className="no-match-found"
                                      colSpan={12}
                                      align="center"
                                    >
                                      <div className="no-data-container">
                                        <NoDataFound noDataType="table"></NoDataFound>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={arrRowsPerPageOptions}
                            component="div"
                            count={recentActivityTable.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'rows per page',
                              },
                              native: true,
                            }}
                          />
                        </Paper>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <EmailModalComponent home={true} modalHandler={modalHandler} setModalHandler={setModalHandler} tableData={floatingCardData} notable={true} />
          </>
        )}
      </div>
    </>
  );
}
export default HomeDashboard;
