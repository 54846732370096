import React, { useEffect, useState } from "react";
import "../../../../../../../common-styles/TableStyle.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization";
import CommonUtils from "../../../../../../../services/utils";

// CUSTOM STYLES FOR THE TABLE
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 600
  },
  container: {
    height: 625
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

// USED FOR SORTING
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
     let firstVal = a[orderBy].toLowerCase().trim()
     let secondVal = b[orderBy].toLowerCase().trim()
     if (secondVal < firstVal) {
       return -1;
     }
     if (secondVal > firstVal) {
       return 1;
     }
     return 0;

   } else {
    let firstVal = a[orderBy]
    let secondVal = b[orderBy]
    if (secondVal < firstVal) {
      return -1;
    }
    if (secondVal > firstVal) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TiresByDispositionTable = ({ tableData, showColumns }) => {

  const [tireForecastByTireTypeData, setTireForecastByTireTypeData] = useState(tableData);
  const [arrRowsPerPageOptions, setArrRowsPerPageOptions] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ManufacturerName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const globalizationMapping = new Globalization();
  const commonUtils = new CommonUtils();

  useEffect(() => {
    setTireForecastByTireTypeData(tableData);
    setPage(0);
    let arr = commonUtils.rowPerPageSelector(tireForecastByTireTypeData.rowsData.length);
    setArrRowsPerPageOptions(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, showColumns]);

  let lastPage = Math.floor(tireForecastByTireTypeData.rowsData.length / rowsPerPage);


  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer className={classes.container}>
            <Table 
              stickyHeader 
              className={classes.table} 
              aria-labelledby="tableTitle" 
              aria-label="a dense table"
              size="small"
              >
              <TableHead>
                <TableRow>
                  {tireForecastByTireTypeData.headCells.map((headCell) =>
                    showColumns[headCell.value] ? (
                      <TableCell
                        className="table-head-row"
                        key={headCell.keyIndex}
                        // align={headCell.numeric === true ? "right" : "left"}
                        align="left"
                        sortDirection={orderBy === headCell.value ? order : false}
                      >
                        <TableSortLabel active={orderBy === headCell.value} direction={orderBy === headCell.value ? order : "asc"} onClick={handleRequestSort(headCell.value)}>
                          {headCell.label}
                          {orderBy === headCell.value ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                        </TableSortLabel>
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              {tireForecastByTireTypeData.rowsData.length > 0 && (
                <TableBody>
                  {stableSort(tireForecastByTireTypeData.rowsData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow className={index % 2 === 0 ? "table-row" : "table-row-alternate"} tabIndex={-1} key={row.Key}>
                          {showColumns.ManufacturerName && <TableCell className="table-content-text">{row.ManufacturerName}</TableCell>}
                          {showColumns.SizeName && <TableCell className="table-content-text">{row.SizeName}</TableCell>}
                          {showColumns.TypeName && <TableCell className="table-content-text">{row.TypeName}</TableCell>}
                          {showColumns.CompoundName && <TableCell className="table-content-text">{row.CompoundName}</TableCell>}
                          {showColumns.TireCount && <TableCell className="table-content-text">{row.TireCount}</TableCell>}
                          {showColumns.CurrentHours && <TableCell className="table-content-text">{row.CurrentHours}</TableCell>}
                          {showColumns.ProjectedHours && <TableCell className="table-content-text">{row.ProjectedHours}</TableCell>}
                        </TableRow>
                      );
                    })}
                  {((page === lastPage - 1 && tireForecastByTireTypeData.rowsData.length % rowsPerPage === 0) ||
                    (page === lastPage && tireForecastByTireTypeData.rowsData.length % rowsPerPage !== 0) ||
                    rowsPerPage === tireForecastByTireTypeData.rowsData.length) && (
                    <TableRow className="total-avg-row">
                      {showColumns.ManufacturerName && <TableCell className="total-avg-text">{globalizationMapping.REPORTCOMMONWORDS.TotalAverage}</TableCell>}
                      {showColumns.SizeName && <TableCell className="total-avg-text"></TableCell>}
                      {showColumns.TypeName && <TableCell className="total-avg-text"></TableCell>}
                      {showColumns.CompoundName && <TableCell className="total-avg-text"></TableCell>}
                      {showColumns.TireCount && <TableCell className="total-avg-text">{tireForecastByTireTypeData.avgValues.TireCount}</TableCell>}
                      {showColumns.CurrentHours && <TableCell className="total-avg-text">{tireForecastByTireTypeData.avgValues.CurrentHours}</TableCell>}
                      {showColumns.ProjectedHours && <TableCell className="total-avg-text">{tireForecastByTireTypeData.avgValues.ProjectedHours}</TableCell>}
                    </TableRow>
                  )}
                </TableBody>
              )}
              {tireForecastByTireTypeData.rowsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell className="no-match-found" colSpan={tireForecastByTireTypeData.headCells.length} align="center">
                      <div className="no-data-container">
                        <NoDataFound noDataType="table"></NoDataFound>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={arrRowsPerPageOptions}
            component="div"
            count={tireForecastByTireTypeData.rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default TiresByDispositionTable;
