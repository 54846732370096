import React, { useState, useEffect } from "react";
import "./OtherTiresNavigation.css";
import Breadcrumbs from "../../../../../common-components/breadcrumbs/breadcrumbs";
import { IconButton, List, ListItem } from "@material-ui/core";
import Globalization from "../../../../../globalization/index";
import { useHistory } from "react-router-dom";
import { ReportsRoutePaths } from "../../../../../routing/ReportsRoutePaths";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const OtherTiresNavigation = () => {
  const globalizationMapping = new Globalization();
  const otherTireGlobalTexts = globalizationMapping.TIREREPORTS.OTHERTIRES;
  const otherTireNav = [
    otherTireGlobalTexts.TireRegisterReport,
    otherTireGlobalTexts.TireByDisposition,
    otherTireGlobalTexts.RemoveTireReport,
    otherTireGlobalTexts.TiresSentToRepair,
    otherTireGlobalTexts.TiresSentToRetread,
    otherTireGlobalTexts.TiresSentToInspectTireReport,
    otherTireGlobalTexts.NewVSScrap,
    otherTireGlobalTexts.AchievedHoursReport,
    otherTireGlobalTexts.AllTireReport,
  ];
  const otherTirePathNav = [
    "tireRegister",
    "tiresByDisposition",
    "removedTire",
    "tiresSentToRepair",
    "tiresSentToRetread",
    "tiresSentToInspectTireReport",
    "newVSScrap",
    "achievedHoursReport",
    "allTireReport"
  ];

  const getRouteName = (currentPathName) => {
    let pathSelected = ReportsRoutePaths.find(
      (route) => route.path === currentPathName
    );
    return pathSelected;
  };

  const getRoutePath = (_otherTireSelected, path) => {
    let pathSelected = ReportsRoutePaths.find((route) => route.path === path);
    return pathSelected;
  };

  let breadcrumbs = [];
  const history = useHistory();

  let currentPathName = history.location.pathname.split("/")[1];
  let currentRouteSelected = getRouteName(currentPathName);

  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);
  // eslint-disable-next-line no-unused-vars
  const [selectedOtherTire, setSelectedOtherTire] = useState(
    currentRouteSelected.name
  );

  breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME,
    },
    {
      path: "reportsDashboard",
      name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.REPORTSDASHBOARD,
    },
    {
      path: "",
      name:
        globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.TIRESREPORTS +
        " - " + otherTireGlobalTexts.OtherTires + ": " +
        selectedRoute.name,
    },
  ];

  const otherTireNavigation = (otherTireSelected, path) => {
    let routePath = getRoutePath(otherTireSelected, path);
    setSelectedOtherTire(otherTireSelected);
    setSelectedRoute(routePath);
  };
  const prev = () => {
    otherTireNav.forEach((item, index) => {
      if (selectedOtherTire === item && index !== 0) {
        setSelectedOtherTire(otherTireNav[index - 1]);
        otherTireNavigation(
          otherTireNav[index - 1],
          otherTirePathNav[index - 1]
        );
      }
    });
  };
  const next = () => {
    otherTireNav.forEach((item, index) => {
      if (selectedOtherTire === item && index !== otherTireNav.length - 1) {
        setSelectedOtherTire(otherTireNav[index + 1]);
        otherTireNavigation(
          otherTireNav[index + 1],
          otherTirePathNav[index + 1]
        );
      }
    });
  };
  useEffect(() => {
    history.push(selectedRoute.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  return (
    <div>
      <div className="other-tires-main-container">
        <div className="other-tires-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="other-tires-title">
                    {otherTireGlobalTexts.OtherTires}
                  </div>
                </div>
              </div>
              <div className=" other-button-other-tires-nav-container">
                <IconButton onClick={() => prev()} >
                  <ArrowBackIosRoundedIcon style={{color:'whitesmoke'}}></ArrowBackIosRoundedIcon>
                </IconButton>
                <div className="other-tires-nav-container">
                  {otherTireNav.map((item, index) => {
                    return (
                      <div
                        key={item}
                        className="other-tires-nav-card-container"
                        onClick={() =>
                          otherTireNavigation(item, otherTirePathNav[index])
                        }
                      >
                        <List
                          className={
                            selectedRoute.name === item
                              ? "active-other-tires-nav-card"
                              : "other-tires-nav-card"
                          }
                        >
                          <ListItem
                            button={selectedRoute.name === item}
                            autoFocus={selectedRoute.name === item}
                          >
                            <span
                              className={
                                selectedRoute.name === item
                                  ? "active-other-tires-nav-card-text"
                                  : "other-tires-nav-card-text"
                              }
                            >
                              {item}
                            </span>
                          </ListItem>
                        </List>
                      </div>
                    );
                  })}
                </div>
                <IconButton onClick={() => next()}>
                  <ArrowForwardIosRoundedIcon style={{color:'whitesmoke'}}></ArrowForwardIosRoundedIcon>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherTiresNavigation;
