import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Grid, InputLabel } from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
const useStyles = makeStyles({
  snackbar: {
    background: "linear-gradient(45deg, #3E9337 30%, #3E9337 90%)",
    top: "75px",
    left: "173px",
    // width: '834px',
    height: "auto",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    opacity: "1",
    textAlign: "left",
  },
  snackbarRed: {
    background: "linear-gradient(45deg, #D14449 30%, #D14449 90%)",
    top: "75px",
    left: "173px",
    // width: '834px',
    height: "auto",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    opacity: "1",
    textAlign: "left",
  },
  content: {
    textAlign: "left",
    font: "Barlow, medium",
    spacing: "0px",
    color: "#FFFFFF",
    opacity: "1",
    marginTop: "16px",
    // top: '92px',
    // left: '200px',
    // width: '349px',
    // height: '21px',
  },
  description: {
    opacity: " 0.7",
    fontSize: "15px",
    marginLeft: "-8px",
    color: "#FFFFFF",
    textAlign: "left",
    font: "Barlow, medium",
    marginBottom: "15px",
  },
  closeBtn: {
    marginTop: "15px",
  },
});

const FlashMessage = (props) => {
  const [open, setOpen] = useState(props.status);
  const styles = useStyles();

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    if (props.onClose) props.onClose();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        transitionDuration={1000}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        <SnackbarContent
          className={props.red === true ? styles.snackbarRed : styles.snackbar}
          message=""
          description=""
          action={
            <React.Fragment>
              <Grid
                container
                spacing={3}
                className="align-content-xs-flex-start"
              >
                <Grid
                  container
                  spacing={2}
                  className="align-content-xs-flex-start"
                >
                  <Grid item xs={11} className="align-content-xs-flex-start">
                    <InputLabel className={styles.content}>
                      {props.message}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={handleClose}
                      className={styles.closeBtn}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={styles.description}>
                    {props.description}
                  </InputLabel>
                </Grid>
              </Grid>
            </React.Fragment>
          }
        ></SnackbarContent>
      </Snackbar>
    </div>
  );
};
export default FlashMessage;
