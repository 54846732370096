import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Grid } from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
import Globalization from "../../../../../../../globalization";

const ByRemovalReasonGraph = ({ byRemovalReasonGraphData }) => {
  const [chartInfoData, setChartInfoData] = useState({});
  const globalizationMapping = new Globalization();
  const commonUtils = new CommonUtils();
  const options = commonUtils.pieOptions;

  options.title.text = globalizationMapping.TIREREPORTS.SCRAPTIRES.ByRemovalReason;
  options.legend.labels.fontSize = window.innerWidth <= 768 ? 8 : 15;
  options.legend.labels.padding = 21;
  options.legend.labels.fontStyle = "bold";
  options.legend.labels.fontColor = "black";
  options.plugins.datalabels.labels.value.font.size = "16";

  const chart = () => {
    let res = byRemovalReasonGraphData.reduce(function (obj, item) {
      obj[item.ReasonName] = (obj[item.ReasonName] || 0) + item.TireCount;
      return obj;
    }, {});
    let labelData = Object.keys(res);
    let valueData = Object.values(res);
    let totalValue = 0;
    valueData.forEach((value) => {
      totalValue += value;
    });
    valueData = valueData.map((value) => {
      return ((value / totalValue) * 100).toFixed(1);
    });

    if (byRemovalReasonGraphData.length > 0) {
      setChartInfoData({
        labels: labelData,
        datasets: [
          {
            backgroundColor: commonUtils.getRandomColorHex(),
            data: valueData,
          },
        ],
      });
    } else {
      setChartInfoData({});
    }
  };
  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byRemovalReasonGraphData]);

  return (
    <div className="container">
      <Grid md={12} sm={12} height={300} item container>
        {Object.keys(chartInfoData).length > 0 && (
          <Doughnut height={90} data={chartInfoData} options={options} />
        )}
      </Grid>

      {Object.keys(chartInfoData).length === 0 && (
        <div className="no-data-container">
          <NoDataFound noDataType="graph"></NoDataFound>
        </div>
      )}
    </div>
  );
};

export default ByRemovalReasonGraph;
