import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound";
// eslint-disable-next-line no-unused-vars
import Globalization from "../../../../../../../globalization/index";

const ScrapCostByTireTypeGraph = ({ scrapCostByTireTypeGraphData }) => {
  const [chartInfoData, setChartInfoData] = useState({});
  const commonUtils = new CommonUtils();
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const globalizationMapping = new Globalization();
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["scrap-cost-type-x"];
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["scrap-cost-type-y"];
  options.title.text = globalizationHeaders["scrap-cost-type-title"];

  const chart = () => {
    if (scrapCostByTireTypeGraphData.chartlabels.length > 0) {
      const colorCodes = commonUtils.getRandomColorHex();

      let newLabels = [];
      scrapCostByTireTypeGraphData.chartlabels.forEach((obj, index) => {
        newLabels.push([obj, scrapCostByTireTypeGraphData.manufacturerLabel[index]]);
      });
      setChartInfoData({
        labels: newLabels,
        datasets: [
          {
            backgroundColor: colorCodes,
            fill: true,
            hoverBorderColor: borderColor,
            hoverBorderWidth: "3",
            data: scrapCostByTireTypeGraphData.chartData[0].data
          }
        ]
      });
    } else {
      setChartInfoData({});
    }
  };
  useEffect(() => {
    chart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrapCostByTireTypeGraphData]);
  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && <Bar data={chartInfoData} height={120} options={options} />}
      </Grid>
      {Object.keys(chartInfoData).length === 0 && <NoDataFound noDataType="graph"></NoDataFound>}
    </div>
  );
};

export default ScrapCostByTireTypeGraph;
