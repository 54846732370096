import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";

class TireHistorySetter {
  constructor() {
    this.data = {};
    this.tireHistoryListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  setData(data) {
    const tireHistoryArr = data.tireHistory1;
    tireHistoryArr.forEach((tireHistory, historyIndex) => {

      const distanceSetter = () => {
        if (tireHistory.miles) {
          return tireHistory.miles;
        } else if (tireHistory.km) {
          return tireHistory.km;
        } else {
          return 0;
        }
      }

      this.tireHistoryListData.push({
        Key: historyIndex,
        EventId: tireHistory.eventId,
        Date: tireHistory.eventDate
          ? this.commonUtils.formatISODate(tireHistory.eventDate)
          : "",
        CreatedDate: tireHistory.createdDate
          ? this.commonUtils.formatISODate(tireHistory.createdDate)
          : "",
        Event: tireHistory.eventName ? tireHistory.eventName : "",
        VehicleNumber: tireHistory.vehicleNumber
          ? tireHistory.vehicleNumber
          : "-",
        TreadDepth: tireHistory.treadDepth ? tireHistory.treadDepth : 0,
        Pressure: tireHistory.pressure
          ? tireHistory.pressure + "(" + tireHistory.pressureType + ")"
          : 0,
        Hours: tireHistory.hours ? tireHistory.hours : 0,
        Distance: distanceSetter(),
        Cost: tireHistory.cost ? tireHistory.cost : 0,
        WheelPosition: tireHistory.wheelPosition
          ? tireHistory.wheelPosition
          : "",
        Comments: tireHistory.comments ? tireHistory.comments : "",
        Actions: "",
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.TireHistory;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];

      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    let selectedColShow = this.initDisplayColumnData(this.headCells);
    return {
      rowsData: this.tireHistoryListData,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.tireHistoryListData),
      selectedColShow: selectedColShow,
    };
  }
}
export default TireHistorySetter;
