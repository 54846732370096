import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";

class VehicleListSetter {
  constructor() {
    this.data = {};
    this.vehicleIdListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.VehicleId.toString().toLowerCase().includes(searchText) ||
        item.VehicleNumber.toString().toLowerCase().includes(searchText)
      );
    });
  }

  initDisplayColumnData(headCells) {
    const selectedColShow = {};
    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    const selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }

  setData(data, locationGlobal) {
    const locationList = data.model.locationList;
    locationGlobal.forEach((loc) => {
      locationList.forEach((location) => {
        if (
          loc.value.toString().toLowerCase().trim() ===
          location.locationId.toString().toLowerCase().trim()
        ) {
          const vehicleList = location.vehicleList;
          /** NOTE : the keys must match the headCells value to be able to SORT */
          vehicleList.forEach((vehicle, vehicleIndex) => {
            this.vehicleIdListData.push({
              Key: vehicleIndex,
              VehicleId: vehicle.vehicleId ? vehicle.vehicleId : 0,
              VehicleNumber: vehicle.vehicleNumber ? vehicle.vehicleNumber : "",
              // LastRead: vehicle.lastRead ? this.commonUtils.formatISODate(vehicle.lastRead) : "",
            });
          });
        }
      });
    });

    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.VehicleIdList;
    columnDefinitions.forEach((item, index) => {
      const colDefnString = item.text.split(".");
      const [masterKey, key] = colDefnString;
      const labelName = this.globalizationMapping[masterKey][key];
      const labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric:
          item.dataType === "number" ||
          item.dataType === "currency" ||
          item.dataType === "percentagedecimal"
            ? true
            : false,
      });
    });

    const selectedColShow = this.initDisplayColumnData(this.headCells);
    this.data = {
      rowsData: this.vehicleIdListData,
      headCells: this.headCells,
      selectedColShow,
    };
    return this.data;
  }
}
export default VehicleListSetter;
