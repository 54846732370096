import React,{useState,useEffect} from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound"
import Globalization from "../../../../../../../globalization/index"
// eslint-disable-next-line no-unused-vars


const ProjectedCostByTireTypeGraph = ({ projectedCostByTireTypeGraphData }) => {
  const commonUtils = new CommonUtils()
  const globalizationMapping = new Globalization()
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions
  const [chartInfoData, setChartInfoData] = useState({})
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["projected-cost-type-x"]
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["projected-cost-type-y"]
  options.title.text = globalizationHeaders["projected-cost-type-title"]
  options.plugins.datalabels.formatter = (value) => {
    return value;  
  }

  const chart = () => {
    let chartLabelsArr = [];
    let chartDataArr = [];
    chartLabelsArr = projectedCostByTireTypeGraphData.reduce(function(a, d) {
      if (a.indexOf(d.SizeName) === -1) {
        a.push(d.SizeName);
      }
      return a;
    }, []);
    for (const element of chartLabelsArr) {
      let costPerHour = 0;
      for (const value of projectedCostByTireTypeGraphData) {
        if (
          value.SizeName === element
        ) {
          costPerHour += parseFloat(value.CostPerHour)
        }
      }
      chartDataArr.push(costPerHour.toFixed(2));
    }
        const colorCodes = commonUtils.getRandomColorHex();
        setChartInfoData({
          labels: chartLabelsArr,
          datasets: [
            {
              backgroundColor: colorCodes,
              fill: true,
              hoverBorderColor: borderColor,
              hoverBorderWidth: "3",
              data: chartDataArr
            }
          ]
        });
  };
  useEffect(() => {
    chart();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[projectedCostByTireTypeGraphData])

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && (
          <Bar data={chartInfoData} height={120} options={options} />
        )}
      </Grid>
    {Object.keys(chartInfoData).length === 0 && 
      <div className="no-data-container">
        <NoDataFound noDataType="graph"></NoDataFound>
      </div>}
  </div>
  );
};

export default ProjectedCostByTireTypeGraph;
