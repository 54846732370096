import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import {TextField, Grid, Card, CardContent, Button, LinearProgress ,Typography , Box, CircularProgress  } from '@material-ui/core';
import "./LogIn.css";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import Globalization from "../../../globalization";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
  },
  proceed: {
    "&:hover": {
        opacity: 1
    },
    background:
        "transparent linear-gradient(90deg, #0055A4 0%, #0079E6 100%) 0% 0% no-repeat padding-box",
    borderRadius: 18,
    font: 'Barlow',
    opacity: .8,
    fontWeight: 500,
    color: "#FFFFFF",
    boxShadow: "none"
},
disabledButton: {
  background: "transparent linear-gradient(90deg, #bebfc046 0%, #bebfc046 100%) 0% 0% no-repeat padding-box",
  borderRadius: 18,
  font: "Barlow",
  opacity: 0.8,
  fontWeight: 500,
  color: "#FFFFFF",
  boxShadow: "none"
}
}));
//Main component of Edit Parent
const LogIn = () => {
    const history = useHistory();
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const globalizationMapping = new Globalization();

    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);
    const goToHomePage = () => {
        history.push({ pathname: "/home"});
    }

    const userProfile = JSON.parse(localStorage.getItem("accountInfo"));

  return (
   <div style={{height: window.innerHeight}} className="background-image-login-grad">
    <div className="background-image-login">
      <Grid container >
       <Card style={{width:'40%', height:360, marginLeft:'30%', marginTop:'14%', backgroundColor:'whitesmoke'}}>
                <CardContent style={{padding:0}}>
                  <Grid container>
                    <Grid item sm={6} style={{padding:10}}>
                      <div className="header-change-modal-title-error">Login</div>
                      <Grid container spacing={1} alignItems="flex-end" style={{marginTop:20, marginLeft:5}}>
                        <Grid item>
                        <AssignmentInd style={{fontSize:45, verticalAlign:'bottom', color:'#0055a4'}}></AssignmentInd >
                        </Grid>
                        <Grid item sm={9}>
                        {userProfile.userProfile.userId.length > 0 ? (
                            <Typography variant="body2" color='#0055a4' style={{fontSize:23, paddingBottom:5}}>{userProfile.userProfile.userId}</Typography>
                          ) : (
                            <CircularProgress size={28} />
                          )}
                            
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} alignItems="flex-end" style={{marginLeft:5}}>
                        <Grid item>
                          <AssignmentTurnedInIcon style={{fontSize:45, verticalAlign:'bottom', color:'#0055a4'}}></AssignmentTurnedInIcon >
                        </Grid>
                        <Grid item sm={9}>
                        
                        {userProfile.userProfile.userRole ? (
                            <Typography variant="body2" color='#0055a4' style={{fontSize:23, paddingBottom:5}}>{userProfile.userProfile.userRole}</Typography>
                          ) : (
                            <CircularProgress size={28} />
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} alignItems="flex-end" style={{marginTop:10,marginLeft:5}}>
                        <Grid item>
                        {(userProfile.userProfile.userRole && userProfile.userProfile.userId.length > 0) ? (
                            <LockOpenIcon style={{fontSize:45, verticalAlign:'bottom', color:'#0055a4'}}></LockOpenIcon >
                          ) : (
                            <LockIcon style={{fontSize:45, verticalAlign:'bottom', color:'#ffdc00'}}></LockIcon >
                          )}
                          
                        </Grid>
                        <Grid item sm={9}>
                           <TextField id="outlined-basic"
                           disabled
                          label="Password" 
                          variant="outlined" 
                          style={{fontSize:31, backgroundColor:'white'}}
                          value='********'
                          fullWidth/>
                        </Grid>
                      </Grid>
                     <Grid container spacing={1} alignItems="flex-end">
                      <Grid item sm={3}></Grid>
                        <Grid item sm={6}>
                          <Button
                          disabled ={progress < 100}
                                className={progress < 100 ? classes.disabledButton : classes.proceed}
                                variant="contained"
                                style={{width:'100%', marginTop:'27%'}}
                                onClick={goToHomePage}
                            >
                              {globalizationMapping.FORMS.PROCEED}
                            </Button>
                        </Grid>
                        <Grid item sm={3}></Grid>
                     </Grid>

                    </Grid>

                    <Grid item sm={6} style={{backgroundColor:'#0055a4', height:320}}>
                      <img src={require("../../../assets/logo.svg")} className="emtrack-logo" alt="emtrack-logo" style={{backgroundColor:'#0055a4', width:'70%', padding:'5%', margin:'12%' }}/>
                    </Grid>
                  </Grid>
                  <Typography variant="body2" color="textSecondary" style={{fontSize:10, paddingLeft:5}}>Data Initializing...</Typography>
                    <Box display="flex" alignItems="center" style={{padding:10}}>
                      <Box width="100%" mr={1}>
                        <LinearProgress variant="determinate" value={progress} />
                      </Box>
                    </Box>

                </CardContent>
            </Card>
    </Grid>
            
    </div>

                
   </div>
        
    
  );
};

export default LogIn;
