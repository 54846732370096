import React, { useEffect, useState, useContext } from "react";
import "./user-preferences.css";
import Breadcrumbs from "../../../common-components/breadcrumbs/breadcrumbs";
import HeaderApi from "../../../common-components/header/services/header-apis.js";
import FooterNavigation from "../../../common-components/footer/footer";
import { Select, Grid, Button, makeStyles, InputLabel, Badge } from "@material-ui/core";
import Options from "../../../globalization/userPreferences-code.json";
import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded";
import Globalization from "../../../globalization/index";
import deleteIcon from "../../../assets/delete-icon.svg";
import { StateContext } from "../../../services/StateProvider";
import ExpandMoreStyledIcon from "../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";


const Styles = makeStyles({
  root: {
    "& input.MuiAutocomplete-inputFocused": {
      color: "#000000 !important",
      fontWeight: "bold"
    }
  },
  input: {
    display: "none"
  },
  FormHelperText: {
    align: "center"
  },
  deleteBadge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "10px",
      right: "17px",
      background: "transparent"
    }
  }
});

const UserPreferences = () => {
  const classes = Styles();
  const [globalAccountState, globalAccountDispatch] = useContext(StateContext);
  const [imgsrc, setImgSrc] = useState([]);
  const [base64, setBase64] = useState(null);
  const [userPrefDetails, setUserPrefDetails] = useState({});
  const [optionLang] = useState(Options.userLanguage);
  const [optionPres] = useState(Options.userPressureUnit);
  const [optionMeas] = useState(Options.userMeasurementSystemValue);
  const [languageIndex, setLanguageIndex] = useState(0);
  const [pressureIndex, setPressureIndex] = useState(0);
  const [measurementIndex, setMeasurementIndex] = useState(0);
  const [tempUserPrefDetails, setTempUserPrefDetails] = useState(userPrefDetails);
  const [saveStatus, setSaveStatus] = useState(true);
  const [successStatus, setSuccessStatus] = useState(false);
  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const globalPrefTexts = globalizationMapping.USER_PREFERENCE;

  let language =
    Options.userLanguage.length > 0 &&
    Options.userLanguage.map((item, i) => {
      return (
        <option key={item.value} value={item.value}>
          {item.text}
        </option>
      );
    }, this);

  let pressure =
    Options.userPressureUnit.length > 0 &&
    Options.userPressureUnit.map((item, i) => {
      return (
        <option key={item.value} value={item.value}>
          {item.text}
        </option>
      );
    }, this);

  let measurementSystem =
    Options.userMeasurementSystemValue.length > 0 &&
    Options.userMeasurementSystemValue.map((item, i) => {
      return (
        <option key={item.value} value={item.value}>
          {item.text}
        </option>
      );
    }, this);

  const headerApi = new HeaderApi();

  useEffect(() => {
    headerApi.getUserPreference().then((data) => {
      if (data.model) {
        let index = optionLang.findIndex((x) => x.value === data.model.userLanguage);
        if (index !== -1) setLanguageIndex(index);
        index = optionPres.findIndex((x) => x.value.toString().toLowerCase().trim() === data.model.userPressureUnit.toString().toLowerCase().trim());
        if (index !== -1) setPressureIndex(index);
        index = optionMeas.findIndex((x) => x.value === data.model.userMeasurementSystemValue);
        if (index !== -1) setMeasurementIndex(index);
        setUserPrefDetails(data.model);
        setTempUserPrefDetails(data.model);
        if (data.model.logoLocation) {
          setBase64(data.model.logoLocation);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME
    },
    {
      path: "",
      name: globalHeaderTexts.PREFERENCE
    }
  ];

  const onClickSaveStatus = (event, value) => {
    switch (event) {
      case "save":
        {
          let date = new Date();
          date = date.toISOString();
          if (imgsrc.length > 0) {
            let timestamp = new Date().getTime();
            const fileUploadFormData = new FormData();
            fileUploadFormData.append("imgsrc", imgsrc[0], "0_" + timestamp + ".png");
            headerApi.uploadCustomerLogo(fileUploadFormData).then((data) => {
              if (data.didError === false) {
                headerApi
                  .updateUserPreference({
                    ...tempUserPrefDetails,
                    logoLocation: data.message,
                    updatedDate: date
                  })
                  .then((response) => {
                    if (response.didError === false) {
                      localStorage.setItem("accountInfouserMeasurementSystemValue", JSON.stringify(tempUserPrefDetails.userMeasurementSystemValue));
                      setSuccessStatus(true);
                      updateMasterDataDetails(data.message);
                      setProfileState(date);
                      setUserPrefDetails({
                        ...tempUserPrefDetails,
                        logoLocation: data.message,
                        updatedDate: date
                      });
                      setTempUserPrefDetails({
                        ...tempUserPrefDetails,
                        logoLocation: data.message,
                        updatedDate: date
                      });
                    } else {
                      //"failed api update"
                    }
                  });

                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                window.location.reload();
              }
            });
          } else {
            headerApi
              .updateUserPreference({
                ...tempUserPrefDetails,
                updatedDate: date
              })
              .then((response) => {
                if (response.didError === false) {
                  setSuccessStatus(true);
                  localStorage.setItem("accountInfouserMeasurementSystemValue", JSON.stringify(tempUserPrefDetails.userMeasurementSystemValue));
                  setProfileState(date);
                  setUserPrefDetails({
                    ...tempUserPrefDetails,
                    updatedDate: date
                  });
                  setTempUserPrefDetails({
                    ...tempUserPrefDetails,
                    updatedDate: date
                  });
                  window.location.reload();
                } else {
                  //failed api update
                }
              });
          }
        }
        break;
      case "edit":
        setSuccessStatus(false);
        break;
      default:
        break;
    }
    setSaveStatus(value);
  };

  const setProfileState = (date) => {
    sessionStorage.setItem("userLanguage", tempUserPrefDetails.userLanguage);
    let accountInfo = globalAccountState.accountInfo;
    let userSetting = accountInfo.userSetting;
    let updatedAccountData = {
      account: accountInfo.account,
      location: accountInfo.location,
      userProfile: accountInfo.userProfile,
      userSetting: {
        userId: userSetting.userId,
        userLanguage: tempUserPrefDetails.userLanguage,
        userMeasurementSystemValue: tempUserPrefDetails.userMeasurementSystemValue,
        userPressureUnit: tempUserPrefDetails.userPressureUnit,
        updatedBy: userSetting.userId,
        logoLocation: userSetting.logoLocation,
        updatedDate: new Date(),
        lastAccessedAccountId: userSetting.lastAccessedAccountId,
        lastAccessedLocationId: userSetting.lastAccessedLocationId,
        lastAccessedAccountName: userSetting.lastAccessedAccountName,
        lastAccessedLocationName: userSetting.lastAccessedLocationName
      }
    };
    globalAccountDispatch({ accountInfo: updatedAccountData, type: "UPDATE" });
    let index = optionLang.findIndex((x) => x.value === tempUserPrefDetails.userLanguage);
    setLanguageIndex(index);
    index = optionPres.findIndex((x) => x.value.toString().toLowerCase().trim() === tempUserPrefDetails.userPressureUnit.toString().toLowerCase().trim());
    if (index === -1) setPressureIndex(index);
    index = optionMeas.findIndex((x) => x.value === tempUserPrefDetails.userMeasurementSystemValue);
    setMeasurementIndex(index);
  };

  const changeUserPrefDetails = (key, value) => {
    switch (key) {
      case "userLanguage":
        setTempUserPrefDetails({ ...tempUserPrefDetails, userLanguage: value });
        break;
      case "userMeasurementSystemValue":
        setTempUserPrefDetails({
          ...tempUserPrefDetails,
          userMeasurementSystemValue: value
        });
        break;
      case "userPressureUnit":
        setTempUserPrefDetails({
          ...tempUserPrefDetails,
          userPressureUnit: value
        });
        break;
      default:
        break;
    }
  };

  const onCancel = (value) => {
    setTempUserPrefDetails(userPrefDetails);
    setSaveStatus(value);
    setSuccessStatus(false);
    setImgSrc([]);
    setBase64(userPrefDetails.logoLocation);
  };

  function onChangeUploadImg(val) {
    let reader = new FileReader();
    reader.readAsDataURL(val.files[0]);
    reader.onloadend = () => {
      let imageSet = reader.result;
      let imgsrc = [];
      imgsrc.push(val.files[0]);
      setImgSrc(imgsrc);
      setBase64(imageSet);

      val.value = null;
    };
  }

  function updateMasterDataDetails(desc) {
    let date = new Date();
    date = date.toISOString();

    let reqObj = [
      {
        requestDate: date,
        fieldId: 12,
        description: desc,
        status: "New"
      }
    ];
    headerApi.updateMaster(reqObj).then((data) => {
      if (data.didError === false) {
        sendLogoRequestEmail();
      }
    });
  }

  function sendLogoRequestEmail() {
    headerApi.sendLogoRequestEmail({ base64: base64 }).then((data) => {
      setTimeout(() => {
        window.history.back();
      }, 3000);
    });
  }

  function removeUploadedPhoto(key) {
    setBase64(null);
    setImgSrc([]);
    setTempUserPrefDetails({ ...tempUserPrefDetails, logoLocation: null });
  }

  return (
    <div className="my-profile-main-container">
      <div className="my-profile-upper-deck">
        <div className="background-map-image-user-preferences">
        <div className="dashboard-header">
            <div>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <div className="scrap-tires-title">{globalHeaderTexts.PREFERENCE}</div>
            </div>
          </div>
          <div className="profile-container">
            <div className="profile-inner-container">
              {successStatus && (
                <div className="success-message-pref bottom-margin">
                  <span className="success-span-message">
                    <label> {globalPrefTexts.SUCCESSMESSAGE} </label>
                  </span>
                </div>
              )}
              <div className="sub-header">
                <span>{globalPrefTexts.OPTIONS}</span>
              </div>
              <div className="edit-view-message bottom-margin">
                <span>{globalPrefTexts.MESSAGE1}</span>
              </div>
              <div>
                <table className="user-profile-table">
                  <tbody>
                    <tr>
                      <td>
                        <p className="table-sub-header">{globalPrefTexts.LANGUAGE}</p>
                        {saveStatus && optionLang.length > 0 && languageIndex >= 0 && <label className="save-table-details">{optionLang[languageIndex].text}</label>}
                        {!saveStatus && (
                          <Select
                            native
                            className="sub-edit-textbox"
                            IconComponent={ExpandMoreStyledIcon}
                            value={tempUserPrefDetails.userLanguage}
                            onChange={(e) => {
                              changeUserPrefDetails("userLanguage", e.target.value);
                            }}
                          >
                            {language}
                          </Select>
                        )}
                      </td>
                      <td>
                        <p className="table-sub-header">{globalPrefTexts.MEASUREMENTSYS}</p>
                        {saveStatus && measurementIndex !== -1 && optionMeas && optionMeas.length > 0 && (
                          <label className="save-table-details">{optionMeas[measurementIndex].text}</label>
                        )}
                        {!saveStatus && (
                          <Select
                            native
                            className="sub-edit-textbox"
                            IconComponent={ExpandMoreStyledIcon}
                            value={tempUserPrefDetails.userMeasurementSystemValue}
                            onChange={(e) => {
                              changeUserPrefDetails("userMeasurementSystemValue", e.target.value);
                            }}
                          >
                            {measurementSystem}
                          </Select>
                        )}
                      </td>
                      <td>
                        <p className="table-sub-header">{globalPrefTexts.PRESSUREUNIT}</p>
                        {saveStatus && pressureIndex !== -1 && optionPres && optionPres.length > 0 && (
                          <label className="save-table-details">{optionPres[pressureIndex].text}</label>
                        )}
                        {!saveStatus && (
                          <Select
                            native
                            className="sub-edit-textbox"
                            IconComponent={ExpandMoreStyledIcon}
                            value={tempUserPrefDetails.userPressureUnit}
                            onChange={(e) => {
                              changeUserPrefDetails("userPressureUnit", e.target.value);
                            }}
                          >
                            {pressure}
                          </Select>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td width="100%" colSpan="3">
                        <hr className="hr-divider bottom-margin" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="sub-header">
                          <span>{globalPrefTexts.CUSTROMERLOGO}</span>
                        </div>
                        {saveStatus && <img alt="Logo" width="250" src={userPrefDetails.logoLocation ? userPrefDetails.logoLocation : require("../../../assets/noImgFound.png")} />}
                        {!saveStatus && (
                          <Grid item sm={12}>
                            {base64 !== null && <p className="field-label-upload">{globalPrefTexts.UPLOADIMG}</p>}
                            <Grid container spacing={1} className="uploaded-images">
                              {base64 !== null && (
                                <Badge
                                  className={classes.deleteBadge}
                                  badgeContent={<img alt="delete" src={deleteIcon} onClick={() => removeUploadedPhoto()} />}
                                  color="primary"
                                  variant="standard"
                                >
                                  <Grid item sm={4} className="uploaded-image">
                                    <img alt="uploaded-logo" src={base64} width="160px" />
                                  </Grid>
                                </Badge>
                              )}
                            </Grid>
                          </Grid>
                        )}

                        {!saveStatus && (
                          <Grid className="upload-box" container spacing={1}>
                            <Grid item sm={6}>
                              <InputLabel className="upload-label">{globalPrefTexts.UPLOADIMGLABEL}</InputLabel>
                            </Grid>
                            <Grid item sm={6}>
                              <input
                                accept="image/*"
                                className={classes.input}
                                id="image-input-hidden"
                                onChange={(e) => {
                                  onChangeUploadImg(e.target);
                                }}
                                type="file"
                              />
                              <label htmlFor="image-input-hidden">
                                <Button
                                  className="upload-Button"
                                  variant="outlined"
                                  size="large"
                                  aria-label="upload picture"
                                  component="span"
                                  startIcon={<PhotoCameraRoundedIcon />}
                                >
                                  {globalPrefTexts.UPLOADBUTTON}
                                </Button>
                              </label>
                            </Grid>
                          </Grid>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <hr className="hr-divider bottom-margin" />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        {saveStatus && (
                          <button className="edit-save-button button-top-margin" onClick={() => onClickSaveStatus("edit", false)}>
                            {globalPrefTexts.EDITBUTTON}
                          </button>
                        )}
                        {!saveStatus && (
                          <div className="button-top-margin">
                            <button className="cancel-button" onClick={() => onCancel(true)}>
                              {globalPrefTexts.CANCELBUTTON}
                            </button>
                            &nbsp;&nbsp;
                            <button className="edit-save-button" onClick={() => onClickSaveStatus("save", true)}>
                              {globalPrefTexts.SAVEBUTTON}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="user-preference-footer-container" style={{marginTop:window.innerHeight - 112}}>
        <FooterNavigation />
      </div>
    </div>
  );
};
export default UserPreferences;
