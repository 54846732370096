import React, { useEffect, useContext, useState } from "react";
import "./ManageParentAccountList.css";
import "../../../../../common-styles/TableFunctionOptions.css";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
} from "@material-ui/core";
import ManageParentAccountListApis from "../../../services/ManageParentAccountListApis";
import { StateContext } from "../../../../../services/StateProvider";
import { accountInfoIdsFormatter } from "../../../../../services/AccountInfoHandler";
import Spinner from "../../../../../common-components/spinner/spinner";
import ManageParentAccountListTableViewOnly from "../table/ManageParentAccountListTableViewOnly";
import ManageParentAccountListSetter from "../../../services/ManageParentAccountListUtils";
import CommonUtils from "../../../../../services/utils";
import SearchIcon from "../../../../../assets/icons8-search.svg";
import EmailExportModal from "../../../../../common-components/modal-components/email-modal/EmailModalComponent";
import PrintExportModal from "../../../../../common-components/modal-components/print-modal/PrintExportModal";
import PdfExportModal from "../../../../../common-components/modal-components/pdf-modal/PdfExportModal";
import Breadcrumbs from "../../../../../common-components/breadcrumbs/breadcrumbs";
import HeaderApi from "../../../../../common-components/header/services/header-apis";
import Globalization from "../../../../../globalization";
import DeleteAccountModal from "../modal/delete-account/DeleteAccount";
import { useComponentVisible } from "../../../../../services/CustomHooks";
import ExpandMoreStyledIcon from "../../../../../common-components/expandMoreStyledIcon/expandMoreStyledIcon";
import FilterPrintEmailExportSetButtons from '../../../../../common-components/filterPrintEmailExport-SetButtons/FilterPrintEmailExportSetButtons'
import ManageParentListFilter from '../filter/ManageParentListFilter';


const ManageParentAccountListViewOnly = () => {
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState] = useContext(StateContext);
  const [showSpinner, setShowSpinner] = useState(true);
  const [manageParentAccountListTableData, setManageParentAccountListTableData] = useState();
  const [originalManageParentAccountListTableData, setOriginalManageParentAccountListTableData] = useState();
  const [showColumns, setShowColumns] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [printData, setPrintData] = useState(manageParentAccountListTableData);
  const [searchValue, setSearchValue] = useState("");
  const [noData, setNoData] = useState(false);
  const [onlyFilterApplied, setOnlyFilterApplied] = useState(false);
  const [action, setAction] = useState([]);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [tableCurrentRowsData, setTableCurrentRowsData] = useState();
  const [filterOptions, setFilterOptions] = useState({
    categories: [
      { name: "Country", value: "Country" },
      { name: "State", value: "State" },
      { name: "City", value: "City" },
    ],
    categoryData: {
      Country: [],
      State: [],
      City: [],
    },
  });
  const [modalHandler, setModalHandler] = useState({
    handlePdf: false,
    handleEmail: false,
    handlePrint: false,
  });

  const [forceActionPopupClose, setForceActionPopupClose] = useState(false);

  const globalization = new Globalization();
  const globalHeaderTexts = globalization.HEADER;
  const globalTexts = globalization.MANAGE_PARENT_ACCOUNT;
  const breadcrumbs = [
    {
      path: "home",
      name: globalHeaderTexts.HOME,
    },
    {
      path: "",
      name: globalHeaderTexts.MANAGEPARENTACCOUNT,
    },
  ];
  const commonUtils = new CommonUtils();
  const manageParentAccountListDataSetter = new ManageParentAccountListSetter();
  const manageParentAccountListService = new ManageParentAccountListApis();
  const headerApiObj = new HeaderApi();
  const [currentFilter, setCurrentFilter] = useState(filterOptions.categoryData); // CURRENT FILTER


  useEffect(() => {
    ManageParentAccountListResponseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAccountState]);

  const globalizationMapping = new Globalization();
  const ManageParentAccountListResponseData = () => {
    let accountInfoObject = accountInfoIdsFormatter(globalAccountState);
    let accountIds = accountInfoObject.accountIds;
    let filterOptions = {
      categories: [
        { name: globalizationMapping.MANAGELOCATION.COUNTRY, value: "Country" },
        { name: globalizationMapping.MANAGELOCATION.STATE, value: "State" },
        { name: globalizationMapping.MANAGELOCATION.CITY, value: "City" },
      ],
      categoryData: {
        Country: [],
        State: [],
        City: [],
      },
    };

    setShowSpinner(true);
    headerApiObj.getCountryList().then((countries) => {
      manageParentAccountListService
        .manageParentAccountGetDetailsByAccount(accountIds[0])
        .then((data) => {
          data.model.forEach((item, _index) => {
            let country = countries.model.filter((i) => {
              return i.countryId === parseInt(item.country);
            });
            if (country[0]) item.country = country[0].countryName;
            else item.country = "";
          });
          let manageParentAccountListTableInfo =
            manageParentAccountListDataSetter.setData(data);
          let filterOptionsInfo =
            manageParentAccountListDataSetter.setFilterOptionsData(
              filterOptions,
              manageParentAccountListTableInfo.rowsData
            );
          setManageParentAccountListTableData(manageParentAccountListTableInfo);
          setOriginalManageParentAccountListTableData(
            manageParentAccountListTableInfo
          );
          manageParentAccountListTableInfo.rowsData.length === 0
            ? setNoData(true)
            : setNoData(false);
          setFilterOptions(filterOptionsInfo);
          setTableCurrentRowsData(manageParentAccountListTableInfo.rowsData);
          setShowColumns(manageParentAccountListTableInfo.selectedColShow);
          setPrintData({
            reportType: "",
            reportName: globalization.MANAGEPARENTACCOUNT.AccountList,
            rowsData: manageParentAccountListTableInfo.rowsData,
            headCells: manageParentAccountListTableInfo.headCells,
            selectedColShow: manageParentAccountListTableInfo.selectedColShow,
          });
          setShowSpinner(false);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // filter options driver
  const multipleRowsSelected = (rows) => {
    setAction([...rows]);
  };

  const onClickExport = (type) => {
    if (type === "pdf") {
      let checkSortApplicable = document.querySelector("th[aria-sort] span");
      if (checkSortApplicable) {
        checkSortApplicable = checkSortApplicable.textContent;
        let data = commonUtils.exportTableSort(
          printData,
          checkSortApplicable.split("sorted ")[0],
          checkSortApplicable.split("sorted ")[1]
        );
        setPrintData(data);
        setModalHandler({ handlePdf: true });
      } else {
        setModalHandler({ handlePdf: true });
      }
    }
  };

  const onClickPrint = () => {
    setForceActionPopupClose(true);
    let checkSortApplicable = document.querySelector("th[aria-sort] span");
    if (checkSortApplicable) {
      checkSortApplicable = checkSortApplicable.textContent;
      let data = commonUtils.exportTableSort(
        printData,
        checkSortApplicable.split("sorted ")[0],
        checkSortApplicable.split("sorted ")[1]
      );
      setPrintData(data);

      setModalHandler({ handlePrint: true });
    } else {
      setModalHandler({ handlePrint: true });
    }
  };

  const onClickEmail = () => {
    setForceActionPopupClose(true);
    setModalHandler({ handleEmail: true });
  };

  const onClickFilter = () => {
    setForceActionPopupClose(true);
    setShowFilter(true);
  };

  const forceActionPopupCloseToFalse = () => {
    setForceActionPopupClose(false);
  }

  // CONFIRM APPLY FILTER
  const confirmApplyFilter = (appliedFilter) => {
    setCurrentFilter(appliedFilter);
    setSearchValue("");
    setFilterApplied(true);
    let originalTableDataStore =
      originalManageParentAccountListTableData?.rowsData;
    let filteredData = manageParentAccountListDataSetter.filterTable(
      appliedFilter,
      originalTableDataStore
    );
    setManageParentAccountListTableData({
      ...manageParentAccountListTableData,
      rowsData: filteredData,
    });
    setPrintData({ ...printData, rowsData: filteredData });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
    setTableCurrentRowsData(filteredData);
    Object.values(appliedFilter).every(x => x.length === 0) ? setOnlyFilterApplied(false) : setOnlyFilterApplied(true);
  };
  // RESET FILTER
  const resetTableFilter = () => {
    setCurrentFilter( {
      Country: [],
      State: [],
      City: [],
    }); // RESET CURRENT FILTER  
    setSearchValue("");
    setFilterApplied(false);
    setManageParentAccountListTableData(
      originalManageParentAccountListTableData
    );
    setNoData(false);
    setPrintData({
      ...printData,
      rowsData: originalManageParentAccountListTableData?.rowsData,
    });
    setOnlyFilterApplied(false);
  };
  // USED FOR SEARCHING THE TABLES
  const searchTableData = (event) => {
    setForceActionPopupClose(true);
    setSearchValue(event.target.value);
    let searchText = event.target.value.toLowerCase();
    searchText === "" ? setFilterApplied(false) : setFilterApplied(true);
    let originalTableDataStore, filteredData;
    originalTableDataStore = [...tableCurrentRowsData];

    filteredData = manageParentAccountListDataSetter.search(
      originalTableDataStore,
      searchText
    );

    let updatedTableData = {
      rowsData: filteredData,
      headCells: originalManageParentAccountListTableData?.headCells,
      selectedColShow:
        originalManageParentAccountListTableData?.selectedColShow,
    };
    setManageParentAccountListTableData(updatedTableData);
    setPrintData({
      reportType: "",
      reportName: globalization.MANAGEPARENTACCOUNT.AccountList,
      rowsData: filteredData,
      headCells: originalManageParentAccountListTableData?.headCells,
      selectedColShow:
        originalManageParentAccountListTableData?.selectedColShow,
    });
    filteredData.length === 0 ? setNoData(true) : setNoData(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault(); //should prevent submit, and continue below?
    return false;
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDelete = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const actionChanged = (action) => {
    if (action === "Delete") {
      handleDelete();
    }
  };

  const toggleExportDropdown = () => {
    setForceActionPopupClose(true);
    setIsComponentVisible(!isComponentVisible);
  }

  return (
    <div className="standalone-new-header-container">
      <div className="manage-parent-acct-main-container">
        <div className="my-profile-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="scrap-tires-title">
                    {globalHeaderTexts.MANAGEPARENTACCOUNT}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSpinner && <Spinner />}
      {!showSpinner && (
        <div className="manage-parent-account-list-container">
          <Card className="manage-parent-account-list-card">
            <CardContent>
              <div className="table-toolbar">
                <div className="table-filters">
                  <div className="search-table-container">
                    <form
                      className=""
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span
                          className={
                            action.length > 1
                              ? "enable-table-toolbar-label-text"
                              : "disabled-table-toolbar-label-text"
                          }
                        >
                          {action.length} {globalTexts.ROWSELECTED}
                        </span>
                      </InputLabel>
                      <Select
                        native
                        placeholder="Actions"
                        defaultValue="Actions"
                        className={
                          action.length > 1
                            ? "enable-manage-vehicle-action-dropdown"
                            : "disabled-manage-vehicle-action-dropdown"
                        }
                        variant="outlined"
                        value={"Actions"}
                        IconComponent={ExpandMoreStyledIcon}
                        onChange={(e) => actionChanged(e.target.value)}
                        disabled={action.length <= 1}
                      >
                        <option value={"Actions"} disabled>
                          Actions
                        </option>
                        <option value={"Delete"}>
                          {globalizationMapping.MANAGE_PARENT_ACCOUNT.OPTION3}
                        </option>
                      </Select>
                    </form>
                  </div>

                  <div className="search-table-container">
                    <form
                      className="search-table-container"
                      noValidate
                      autoComplete="off"
                      onSubmit={formSubmitHandler}
                    >
                      <InputLabel className="filter-table-label">
                        <span className="table-toolbar-label-text">
                          {globalTexts.SEARCHTABLE}
                        </span>
                      </InputLabel>
                      <TextField
                        className="search-table-bar"
                        id="input-with-icon-textfield"
                        variant="outlined"
                        placeholder={
                          globalizationMapping.REPORTCOMMONWORDS.Search
                        }
                        value={searchValue}
                        onChange={searchTableData}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                className="keyword-search-icon-img"
                                alt=""
                                src={SearchIcon}
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </form>
                  </div>
                </div>

                <FilterPrintEmailExportSetButtons
                  onClickFilter={onClickFilter}
                  onClickEmail={onClickEmail}
                  onClickPrint={onClickPrint}
                  onClickExport={onClickExport}
                  toggleExportDropdown={toggleExportDropdown}
                  printData={printData}
                  ref={ref}
                  onlyFilterApplied={onlyFilterApplied}
                  noData={noData}
                  isComponentVisible={isComponentVisible}
                />
              </div>

              <div className="data-summary-table">
                <ManageParentAccountListTableViewOnly
                  tableData={manageParentAccountListTableData}
                  showColumns={showColumns}
                  filterApplied={filterApplied}
                  multipleRowsSelected={multipleRowsSelected}
                  forceActionPopupClose={forceActionPopupClose}
                  forceActionPopupCloseToFalse={forceActionPopupCloseToFalse}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {isDeleteModalOpen && (
        <DeleteAccountModal
          closeDeleteModal={handleDeleteModalClose}
          // locationList={locationList}
          accountIds={action}
          isSelectionMultiple={true}
        />
      )}
      <PrintExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <PdfExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <EmailExportModal
        modalHandler={modalHandler}
        setModalHandler={setModalHandler}
        tableData={printData}
        noGraph={true}
      />
      <ManageParentListFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterOptions={filterOptions}
        ApplyFilter={confirmApplyFilter}
        currentFilter={currentFilter}
        resetTableFilter={resetTableFilter}
      ></ManageParentListFilter>
    </div>
  );
};

export default ManageParentAccountListViewOnly;
