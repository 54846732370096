import React, { useEffect, useState } from "react";
import HeaderRouting from "./services/header-routing";
import { BrowserRouter } from "react-router-dom";
import { StateProvider } from "./services/StateProvider";
import { AccountInfoUpdate, initialAccountState } from "./services/StateMap";
import "./App.css";
import { Routes } from "./auth/authRoute";
import { AuthProvider } from "./services/AuthProvider";
import FooterNavigation from "./common-components/footer/footer";
import { filterContext, filterAppliedBtn } from "./scenes/manage-vehicle/components/list-vehicles/main-container/ManageVehicleCotext";

const mainReducer = (state, action) => {
  return {
    accountInfo: AccountInfoUpdate(state.accountInfo, action),
  };
};

function App() {
  const [globalState, setGlobalState] = useState(false);
  const [holdFilters, setHoldFilters] = useState({})
  const [filtersAppliedValue, setFiltersAppliedValue] = useState(false)

  useEffect(() => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      )
    );
    if (oidcStorage && oidcStorage.access_token) {
      setGlobalState(true);
    } else if (window.location.pathname !== "/authentication/callback") {
      localStorage.setItem("loadedURL", window.location.pathname);
    } else {
      return;
    }

    let accountData = {
      account: { accountName: "", accountId: "0000" },
      location: [{ value: 0, label: "" }],
      userSetting: {
        userId: "",
        userLanguage: "en-us",
        userMeasurementSystemValue: "metric",
        userPressureUnit: "PSI",
        updatedBy: "",
        logoLocation: null,
        updatedDate: "",
        lastAccessedAccountId: "0000",
        lastAccessedLocationId: "0000",
        lastAccessedAccountName: "",
        lastAccessedLocationName: "",
      },
      userProfile: {
        countryCode: "",
        emailAddress: null,
        firstName: "",
        lastName: "",
        middleName: "",
        phoneNumber: "",
        updatedDate: "",
        userId: "",
        userRole: null,
      },
    };
    let accountDataT = accountData;
    accountData = JSON.stringify(accountData);
    localStorage.setItem("accountInfo", accountData);
    localStorage.setItem("accountInfouserMeasurementSystemValue", JSON.stringify(accountDataT.userSetting.userMeasurementSystemValue));
    localStorage.setItem("isEmailBtnShow", false);

    if (!localStorage.getItem("broadcastFag")) {
      localStorage.setItem("broadcastFag", true);
    }
  }, []);

  return (
    <AuthProvider>
      <StateProvider
        reducer={mainReducer}
        initialState={{ accountInfo: initialAccountState }}
      >
        <filterContext.Provider value={{ holdFilters: holdFilters, setHoldFilters: setHoldFilters }}>
          <filterAppliedBtn.Provider value={{ filtersAppliedValue: filtersAppliedValue, setFiltersAppliedValue: setFiltersAppliedValue }}>
        <BrowserRouter>
          {globalState === true && (
            <div className="app-content-container">
              <HeaderRouting />
            </div>
          )}
          <BrowserRouter children={Routes} basename={"/"}></BrowserRouter>
          {globalState === true && (
            <div >
              <FooterNavigation />
            </div>
          )}
        </BrowserRouter>
          </filterAppliedBtn.Provider>
        </filterContext.Provider>
      </StateProvider>
    </AuthProvider>
  );
}

export default App;