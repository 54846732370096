import Globalization from "../../../globalization/index";

class TireNeedsForecastDataSetter {
  constructor() {
    this.data = {};
    this.tireNeedsForecastData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
  }

  tableHeaderOrderSetter(headCells, timeArray) {
    let tableHeaders = headCells;
    // maintains the column order to be displayed (write them in the order you want to display table header)
    let headerOrder = [
      { columnNo: 1, value: "Manufacturer" },
      { columnNo: 2, value: "VehicleNumber" },
      { columnNo: 3, value: "Size" },
      { columnNo: 4, value: "Type" },
      { columnNo: 5, value: "maxcost" }
    ];

    let resultHeaderCell = [];
    let i = headerOrder.length + 1;
    timeArray.forEach((field) => {
      headerOrder.push({ columnNo: i, value: field });
      i++;
    });

    headerOrder.push({ columnNo: i, value: "TotalTires" });
    headerOrder.push({ columnNo: i + 1, value: "TotalCost" });

    headerOrder.forEach((headerOrder) => {
      let found = false;
      tableHeaders = tableHeaders.filter((headerItem) => {
        if (!found && headerItem.value === headerOrder.value) {
          resultHeaderCell.push(headerItem);
          found = true;
          return false;
        }
        return true;
      });
    });

    return resultHeaderCell;
  }

  // shows the headCells to be displayed first time on first time load
  updatedHeadCells() {
    let updatedHeadCells = [];
    this.headCells.forEach((headCell) => {
      if (headCell.value !== "BrandNumber") {
        updatedHeadCells.push(headCell);
      }
    });
    return updatedHeadCells;
  }

  formatData(item) {
    let dateString, registeredDate, installedDate;
    if (item.registeredDate) {
      dateString = item.registeredDate.split("/");
      registeredDate = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      registeredDate = null;
    }
    if (item.installedDate) {
      dateString = item.installedDate.split("/");
      installedDate = dateString[2] + "/" + dateString[0] + "/" + dateString[1];
    } else {
      installedDate = null;
    }

    let formattedData = {
      registeredDate: registeredDate,
      installedDate: installedDate
    };
    return formattedData;
  }

  initDisplayColumnData(headCells, timeArray) {
    let selectedColShow = {};
    headCells.forEach((headCell) => {
      if (headCell.value === "BrandNumber") {
        selectedColShow[headCell.value] = false;
      } else {
        if (
          headCell.value === "Manufacturer" ||
          headCell.value === "VehicleNumber" ||
          headCell.value === "Size" ||
          headCell.value === "Type" ||
          headCell.value === "maxcost" ||
          headCell.value === "TotalTires" ||
          headCell.value === "TotalCost"
        )
          selectedColShow[headCell.value] = true;
        else {
          if (timeArray.includes(headCell.value)) selectedColShow[headCell.value] = true;
        }
      }
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    //
    return selectedColShow;
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, index) => {
        if (
          !filterInitCategoryData[category.value].includes(rowData[category.value]) &&
          rowData[category.value].toString().split(" ")[1] !== "Tires" &&
          rowData[category.value].toString().split(" ")[1] !== "Cost" &&
          rowData[category.value].toString() !== ""
        ) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });

    // modifies the categories as needed by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }
  filterTable(appliedFilter, tableData, timeArray) {

    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        let rowDataKey = categoryKey;
        if (categoryKey === "Manufacturer") {
          rowDataKey = "FullManufacturer";
        }
        if (!appliedFilter[categoryKey].length) return true;
        return appliedFilter[categoryKey].includes(rowData[rowDataKey]);
      });
    });

    filteredTable.map((item, index) => {
      if (index === 0) {
        item.Manufacturer = item.FullManufacturer;
      } else {
        if (item.FullManufacturer) {
          if (index === 0) {
            item.Manufacturer = item.FullManufacturer
          } else if (index !== 0 && filteredTable[index - 1].FullManufacturer === item.FullManufacturer) {
            item.Manufacturer = "";
          } else {
            item.Manufacturer = item.FullManufacturer;
          }
        } else {
          item.Manufacturer = "";
        }
      }
    });

    let tempRowData = [];
    let manuName = "";
    let manuSumArray = new Array(timeArray.length).fill(0);
    let newIndex = 0;
    let manuCost = new Array(timeArray.length).fill(0);
    filteredTable.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */

      if (manuName === "") {
        manuName = item.FullManufacturer;
      } else if (manuName !== item.FullManufacturer) {
        newIndex++;
        let sumRow = {
          Key: newIndex,
          FullManufacturer: "",
          Manufacturer: "",
          VehicleNumber: "Total Tires",
          Size: "",
          Type: "",
          maxcost: "",
          TotalTires: 0,
          TotalCost: 0
        };

        newIndex++;
        let sumRowCost = {
          Key: newIndex,
          FullManufacturer: "",
          Manufacturer: "",
          VehicleNumber: "Total Cost",
          Size: "",
          Type: "",
          maxcost: "",
          TotalTires: 0,
          TotalCost: 0
        };

        let i = 0;
        timeArray.forEach((field) => {
          sumRow[field] = manuSumArray[i];
          sumRowCost[field] = manuCost[i];
          sumRow["TotalTires"] += manuSumArray[i];
          sumRowCost["TotalCost"] += manuCost[i];
          i++;
        });
        tempRowData.push(sumRow);
        tempRowData.push(sumRowCost);
        manuSumArray = new Array(timeArray.length).fill(0);
        manuCost = new Array(timeArray.length).fill(0);
        manuName = item.FullManufacturer;
      } else {
        console.warn(manuName, item.FullManufacturer, "e");
      }
      if (index !== 0) newIndex++;
      let rowData = {
        Key: newIndex,
        FullManufacturer: item.FullManufacturer ? item.FullManufacturer : "",
        Manufacturer: item.Manufacturer,
        VehicleNumber: item.VehicleNumber && typeof item.VehicleNumber !== "object" ? item.VehicleNumber : "",
        Size: item.Size ? item.Size : "",
        Type: item.Type ? item.Type : "",
        maxcost: item.maxcost ? item.maxcost : 0
      };
      let i = 0;
      timeArray.forEach((field) => {
        if (typeof item[field] === "number") rowData[field] = item[field];
        else rowData[field] = 0;
        let rowSumTires = 0;
        let maxCostRow = item.maxcost ? item.maxcost : 0;
        filteredTable.forEach((item, rowIndex) => {
          if (rowIndex === index) {
            timeArray.forEach((field) => {
              rowSumTires += typeof item[field] === "number" ? item[field] : 0;
            });

          }
        });

        rowData["TotalTires"] = rowSumTires;
        rowData["TotalCost"] = maxCostRow * rowSumTires;
        manuSumArray[i] += rowData[field];
        manuCost[i] += rowData[field] * rowData.maxcost;
        i++;
      });

      tempRowData.push(rowData);
      if (index === filteredTable.length - 1) {
        newIndex++;
        let sumRow = {
          Key: newIndex,
          FullManufacturer: "",
          Manufacturer: "",
          VehicleNumber: "Total Tires",
          Size: "",
          Type: "",
          maxcost: "",
          TotalTires: 0,
          TotalCost: 0
        };

        newIndex++;
        let sumRowCost = {
          Key: newIndex,
          FullManufacturer: "",
          Manufacturer: "",
          VehicleNumber: "Total Cost",
          Size: "",
          Type: "",
          maxcost: "",
          TotalTires: 0,
          TotalCost: 0
        };

        let i = 0;
        timeArray.forEach((field) => {
          sumRow[field] = manuSumArray[i];
          sumRowCost[field] = manuCost[i];
          sumRow["TotalTires"] += manuSumArray[i];
          sumRowCost["TotalCost"] += manuCost[i];
          i++;
        });
        tempRowData.push(sumRow);
        tempRowData.push(sumRowCost);
        manuSumArray = new Array(timeArray.length).fill(0);
        manuCost = new Array(timeArray.length).fill(0);
        manuName = item.FullManufacturer;
      }
    });
    return tempRowData;
  }

  calculateTotalAvg(rowsData) {
    let totalCost = 0;
    rowsData.forEach((rowData) => {
      totalCost += rowData.TotalCost;
    });
    return totalCost;
  }

  calculateGrandTotal(rowsData, timeArray) {
    let totalArray = new Array(timeArray.length).fill(0);
    let totalArrayCost = new Array(timeArray.length).fill(0);
    rowsData.forEach((row) => {
      let i = 0;
      if (row.VehicleNumber !== "Total Tires" && row.VehicleNumber !== "Total Cost") {
        timeArray.forEach((elem) => {
          let tireTotal = row[elem];
          let costTotal = row[elem] * row.maxcost;
          totalArray[i] += tireTotal;
          totalArrayCost[i] += costTotal;
          i++;
        });
      }
    });
    return [totalArray, totalArrayCost];
  }

  grandTotal = (tireNeedsForecastData, timeArray) => {
    let totals = this.calculateGrandTotal(tireNeedsForecastData, timeArray);
    return totals;
  };

  totalForPdf = (tireNeedsForecastData, timeArray) => {
    const total = {
      Manufacturer: "Grand Total",
      VehicleNumber: "Tires",
      Size: "",
      Type: "",
      maxcost: "",
      TotalCost: "",
      TotalTires: 0
    };
    const totalCost = {
      Manufacturer: "",
      VehicleNumber: "Cost",
      Size: "",
      Type: "",
      maxcost: "",
      TotalCost: 0,
      TotalTires: ""
    };
    //
    let grandSums = this.grandTotal(tireNeedsForecastData, timeArray);
    let i = 0;
    timeArray.map((field) => {
      total[field] = Number(grandSums[0][i]);
      totalCost[field] = Number(grandSums[1][i]);
      total["TotalTires"] += Number(grandSums[0][i]);
      totalCost["TotalCost"] += Number(grandSums[1][i]);
      i++;
    });

    return [total, totalCost];
  };

  setData(data, timeArray) {
    // TABLE ROWS DATA
    const reportData = data.model.reportData;
    let manuName = "";
    let manuSumArray = new Array(timeArray.length).fill(0);
    let newIndex = 0;
    let manuCost = new Array(timeArray.length).fill(0);
    reportData.forEach((item, index) => {
      /** NOTE : the keys must match the headCells value to be able to SORT */
      if (manuName === "") {
        manuName = item.Manufacturer;
      } else if (manuName !== item.Manufacturer) {

        newIndex++;
        let sumRow = {
          Key: newIndex,
          FullManufacturer: "",
          Manufacturer: "",
          VehicleNumber: "Total Tires",
          Size: "",
          Type: "",
          maxcost: "",
          TotalTires: 0,
          TotalCost: ""
        };

        newIndex++;
        let sumRowCost = {
          Key: newIndex,
          FullManufacturer: "",
          Manufacturer: "",
          VehicleNumber: "Total Cost",
          Size: "",
          Type: "",
          maxcost: "",
          TotalTires: "",
          TotalCost: 0
        };

        let i = 0;
        timeArray.forEach((field) => {
          sumRow[field] = manuSumArray[i];
          sumRowCost[field] = manuCost[i];
          sumRow["TotalTires"] += manuSumArray[i];
          sumRowCost["TotalCost"] += manuCost[i];
          i++;
        });
        this.tireNeedsForecastData.push(sumRow);
        this.tireNeedsForecastData.push(sumRowCost);
        manuSumArray = new Array(timeArray.length).fill(0);
        manuCost = new Array(timeArray.length).fill(0);
        manuName = item.Manufacturer;
      }

      if (index !== 0) newIndex++;

      const itemManufacturerChecker = () => {
        if (item.Manufacturer) {
          if (index === 0) {
            return item.Manufacturer;
          } else if (index !== 0 && reportData[index - 1].Manufacturer === item.Manufacturer) {
            return "";
          } else {
            return item.Manufacturer;
          }
        } else {
          return "";
        }
      }

      let rowData = {
        Key: newIndex,
        FullManufacturer: item.Manufacturer ? item.Manufacturer : "",
        Manufacturer: itemManufacturerChecker(),
        VehicleNumber: item.VehicleNumber && typeof item.VehicleNumber !== "object" ? item.VehicleNumber : "",
        Size: item.Size ? item.Size : "",
        Type: item.Type ? item.Type : "",
        maxcost: item.maxcost ? item.maxcost : 0
      };
      let i = 0;
      timeArray.forEach((field) => {
        if (typeof item[field] === "number") rowData[field] = item[field];
        else rowData[field] = 0;
        let rowSumTires = 0;
        let maxCostRow = item.maxcost ? item.maxcost : 0;
        data.model.reportData.forEach((item, rowIndex) => {
          if (rowIndex === index) {
            timeArray.forEach((field) => {
              rowSumTires += typeof item[field] === "number" ? item[field] : 0;
            });
          }
        });
        rowData["TotalTires"] = rowSumTires;
        rowData["TotalCost"] = maxCostRow * rowSumTires;
        manuSumArray[i] += rowData[field];
        manuCost[i] += rowData[field] * rowData.maxcost;
        i++;
      });

      if (rowData.TotalTires) this.tireNeedsForecastData.push(rowData);
      if (index === reportData.length - 1) {

        newIndex++;
        let sumRow = {
          Key: newIndex,
          FullManufacturer: "",
          Manufacturer: "",
          VehicleNumber: "Total Tires",
          Size: "",
          Type: "",
          maxcost: "",
          TotalTires: 0,
          TotalCost: ""
        };

        newIndex++;
        let sumRowCost = {
          Key: newIndex,
          FullManufacturer: "",
          Manufacturer: "",
          VehicleNumber: "Total Cost",
          Size: "",
          Type: "",
          maxcost: "",
          TotalTires: "",
          TotalCost: 0
        };

        let i = 0;
        timeArray.forEach((field) => {
          sumRow[field] = manuSumArray[i];
          sumRowCost[field] = manuCost[i];
          sumRow["TotalTires"] += manuSumArray[i];
          sumRowCost["TotalCost"] += manuCost[i];
          i++;
        });
        this.tireNeedsForecastData.push(sumRow);
        this.tireNeedsForecastData.push(sumRowCost);
        manuSumArray = new Array(timeArray.length).fill(0);
        manuCost = new Array(timeArray.length).fill(0);
        manuName = item.Manufacturer;
      }
    });

    // TABLE HEADER DATA
    const columnDefinitions = data.model.columnDefinitions;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = "";
      if (index < 5) labelName = this.globalizationMapping[masterKey][key.toUpperCase()];
      else labelName = item.fieldName;
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });

    this.headCells.push({
      key: "TotalTires",
      keyIndex: columnDefinitions.length,
      value: "TotalTires",
      label: "Total Tires",
      numeric: true
    });

    this.headCells.push({
      key: "TotalCost",
      keyIndex: columnDefinitions.length,
      value: "TotalCost",
      label: "Total Cost",
      numeric: true
    });

    let orderedHeadCells = this.tableHeaderOrderSetter(this.headCells, timeArray);
    let selectedColShow = this.initDisplayColumnData(this.headCells, timeArray);
    let grandTot = this.grandTotal(this.tireNeedsForecastData, timeArray);
    let grandTotals = this.totalForPdf(this.tireNeedsForecastData, timeArray);
    return {
      rowsData: this.tireNeedsForecastData,
      headCells: orderedHeadCells,
      avgValues: grandTotals[0],
      totalCost: grandTotals[1],
      selectedColShow: selectedColShow,
      total: grandTot
    };
  }

  search(originalTableData, searchText) {
    return originalTableData.filter((item) => {
      return (
        item.FullManufacturer.toLowerCase().includes(searchText) ||
        item.Size.toLowerCase().includes(searchText) ||
        item.Type.toLowerCase().includes(searchText) ||
        item.maxcost.toString().includes(searchText)
      );
    });
  }
}

export default TireNeedsForecastDataSetter;
