import Globalization from '../../globalization/index'

const globalizationMapping = new Globalization();

const ReportsRouteList = [
  {
    name: globalizationMapping.reportDashboard,
    path: 'reportsDashboard',
    groupName: globalizationMapping.SEARCHREPORT.REPORTDASHBOARD,
    groupNavPath: 'reportsDashboard'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapSummary,
    path: 'scrapSummary',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByRemovalReason,
    path: 'byRemovalReason',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByCompound,
    path: 'byCompound',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByIndividualTire,
    path: 'byIndividualTire',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapAnalysis,
    path: 'scrapAnalysis',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByTireType,
    path: 'byTireType',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ByTireSize,
    path: 'byTireSize',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapCostByTireSize,
    path: 'scrapCostByTireSize',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapHoursByTireSize,
    path: 'scrapHoursByTireSize',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapCostByTireType,
    path: 'scrapCostByTireType',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.ScrapHoursByTireType,
    path: 'scrapHoursByTireType',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.SCRAPTIRES.MonthlyTireLife,
    path: 'monthlyTireLife',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTSCRAPTIRE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.InServiceTireCostSummary,
    path: 'inServiceTireCostSummary',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireInstallation,
    path: 'tireInstallation',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireHistory,
    path: 'tireHistory',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },

  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByPercentageWorn,
    path: 'byPercentageWorn',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByWearCondition,
    path: 'byWearCondition',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByManufacturer,
    path: 'byManufacturer',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.BySize,
    path: 'bySize',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByTiresInServiceTireType,
    path: 'byTiresInServicesTireType',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByVehicleID,
    path: 'byVehicleId',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ProjectedCostByTireSize,
    path: 'projectedCostByTireSize',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ProjectedCostByTireType,
    path: 'projectedCostByTireType',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByVehicleModel,
    path: 'byVehicleModel',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.ByVehicleWheelPosition,
    path: 'byVehicleWheelPosition',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireForecastByTireType,
    path: 'tireForecastByTireType',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireInflation,
    path: 'tireInflation',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.DailyJobSummary,
    path: 'dailyJobSummary',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.TIRESINSERVICE.TireNeedsForecast,
    path: 'tireNeedsForecast',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREINSERVICE,
    groupNavPath: 'tireReports'
  },
  // other tires
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TireRegisterReport,
    path: 'tireRegister',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREREPORT,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TireByDisposition,
    path: 'tiresByDisposition',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREREPORT,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.RemoveTireReport,
    path: 'removedTire',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREREPORT,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToRepair,
    path: 'tiresSentToRepair',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREREPORT,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToRetread,
    path: 'tiresSentToRetread',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREREPORT,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.TiresSentToInspectTireReport,
    path: 'tiresSentToInspectTireReport',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREREPORT,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.NewVSScrap,
    path: 'newVSScrap',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREREPORT,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.AchievedHoursReport,
    path: 'achievedHoursReport',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREREPORT,
    groupNavPath: 'tireReports'
  },
  {
    name: globalizationMapping.TIREREPORTS.OTHERTIRES.AllTireReport,
    path: 'allTireReport',
    groupName: globalizationMapping.SEARCHREPORT.TIREREPORTTIREREPORT,
    groupNavPath: 'tireReports'
  },


// fleet data
  {
    name: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetDataAnalysis,
    path: 'fleetData',
    groupName: globalizationMapping.SEARCHREPORT.VEHICLEREPORTBYFLEET,
    groupNavPath: 'vehicleReports'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYFLEET.FleetSurvey,
    path: 'fleetSurvey',
    groupName: globalizationMapping.SEARCHREPORT.VEHICLEREPORTBYFLEET,
    groupNavPath: 'vehicleReports'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireInflation,
    path: 'tireInflationReport',
    groupName: globalizationMapping.SEARCHREPORT.VEHICLEREPORTBYTIRES,
    groupNavPath: 'vehicleReports'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireRotation,
    path: 'tireRotation',
    groupName: globalizationMapping.SEARCHREPORT.VEHICLEREPORTBYTIRES,
    groupNavPath: 'vehicleReports'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireFlipping,
    path: 'tireFlipping',
    groupName: globalizationMapping.SEARCHREPORT.VEHICLEREPORTBYTIRES,
    groupNavPath: 'vehicleReports'
  },
  {
    name: globalizationMapping.VEHICLEREPORTS.BYTIRES.TireForecast,
    path: 'tireForecast',
    groupName: globalizationMapping.SEARCHREPORT.VEHICLEREPORTBYTIRES,
    groupNavPath: 'vehicleReports'
  },
  {
    name: globalizationMapping.RIMREPORTS.RimReports.RimReport,
    path: 'rimReport',
    groupName: globalizationMapping.SEARCHREPORT.RIMREPORT,
    groupNavPath: 'rimReports'
  },
  {
    name: globalizationMapping.RIMREPORTS.RimReports.RimsInService,
    path: 'rimsInService',
    groupName: globalizationMapping.SEARCHREPORT.RIMREPORT,
    groupNavPath: 'rimReports'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsFourTires,
    path: 'twoAxlesFourTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE2AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsSixTires,
    path: 'twoAxlesSixTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE2AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsSixTiresTwo,
    path: 'twoAxlesSixTiresTwo',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE2AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.TwoAxelsEightTires,
    path: 'twoAxlesEightTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE2AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.ThreeAxelsSixTires,
    path: 'threeAxlesSixTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE3AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.ThreeAxelsTenTires,
    path: 'threeAxlesTenTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE3AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FourAxelsEightTires,
    path: 'fourAxlesEightTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE4AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsTenTires,
    path: 'fiveAxlesTenTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE5AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsSixteenTires,
    path: 'fiveAxlesSixteenTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE5AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsEighteenTires,
    path: 'fiveAxlesEighteenTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE5AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.FiveAxelsTwentyTires,
    path: 'fiveAxlesTwentyTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE5AXLES,
    groupNavPath: 'surveyTemplates'

  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.SixAxelsTwelveTires,
    path: 'sixAxlesTwelveTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE6AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.SevenAxelsFourteenTires,
    path: 'sevenAxlesFourteenTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE7AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.SevenAxelsTwentySixTires,
    path: 'sevenAxlesTwentySixTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE7AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.EightAxelsSixteenTires,
    path: 'eightAxlesSixteenTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE8AXLES,
    groupNavPath: 'surveyTemplates'
  },
  {
    name: globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION.NineAxelsEighteenTires,
    path: 'nineAxlesEighteenTires',
    groupName: globalizationMapping.SEARCHREPORT.SURVEYTEMPLATE9AXLES,
    groupNavPath: 'surveyTemplates'
  },
]

export default ReportsRouteList;
