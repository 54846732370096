import Globalization from "../../../globalization/index";
import ColumnDefinitions from "../../../services/ColumnDefinitions.json";
import CommonUtils from "../../../services/utils";
const columnsToHide = {
  7: ["Disposition"],
  8: ["VehicleId", "WheelPosition"],
  4: ["Disposition", "VehicleId", "WheelPosition"],
  1: [],
  2: ["VehicleId", "WheelPosition"],
  3: ["VehicleId", "WheelPosition"],
  All: []
};
class ManageTireListSetter {
  constructor() {
    this.data = {};
    this.manageTireListData = [];
    this.headCells = [];
    this.globalizationMapping = new Globalization();
    this.commonUtils = new CommonUtils();
  }

  initDisplayColumnData(headCells, unitPreference, dispositionId = "All") {
    let selectedColShow = {};

    Object.keys(columnsToHide).forEach((key) => {
      if (unitPreference === "imperial") {
        if (!columnsToHide[key].includes("Kilometers")) {
          columnsToHide[key].push("Kilometers");
        }
        if (!columnsToHide[key].includes("TreadDepthmm")) {
          columnsToHide[key].push("TreadDepthmm");
        }
        if (columnsToHide[key].includes("Miles")) {
          let i = columnsToHide[key].indexOf("Miles");
          columnsToHide[key].splice(i, 1);
        }
        if (columnsToHide[key].includes("TreadDepth32")) {
          let i = columnsToHide[key].indexOf("TreadDepth32");
          columnsToHide[key].splice(i, 1);
        }
      } else {
        if (!columnsToHide[key].includes("Miles")) {
          columnsToHide[key].push("Miles");
        }
        if (!columnsToHide[key].includes("TreadDepth32")) {
          columnsToHide[key].push("TreadDepth32");
        }
        if (columnsToHide[key].includes("Kilometers")) {
          let i = columnsToHide[key].indexOf("Kilometers");
          columnsToHide[key].splice(i, 1);
        }
        if (columnsToHide[key].includes("TreadDepthmm")) {
          let i = columnsToHide[key].indexOf("TreadDepthmm");
          columnsToHide[key].splice(i, 1);
        }
      }
    });

    headCells.forEach((headCell) => {
      selectedColShow[headCell.value] = columnsToHide[dispositionId.toString()] && columnsToHide[dispositionId.toString()].indexOf(headCell.value) >= 0 ? false : true;
    });
    return selectedColShow;
  }

  setDisplayColumnData(currentHeadCells, selectedCols) {
    let selectedColShow = {};
    currentHeadCells.forEach((headCell) => {
      selectedColShow[headCell.value] = false;
      selectedCols.forEach((selectedCol) => {
        if (selectedCol.value === headCell.value) {
          selectedColShow[headCell.value] = true;
        }
      });
    });
    return selectedColShow;
  }
  search(originalTableData, searchText) {
    return originalTableData.filter((item, _index) => {

      return (
        item.SerialNo.toLowerCase().includes(searchText) ||
        item.Brand.toLowerCase().includes(searchText) ||
        item.RimSerialNo.toLowerCase().includes(searchText) ||
        item.VehicleId.toString().includes(searchText) ||
        item.VehicleNumber.toLowerCase().includes(searchText) ||
        item.Location.toString().includes(searchText) ||
        item.LotNo.toLowerCase().includes(searchText) ||
        item.EvaluationNo.toLowerCase().includes(searchText) ||
        item.Disposition.toString().includes(searchText) ||
        item.Status.toLowerCase().includes(searchText) ||
        item.Make.toLowerCase().includes(searchText) ||
        item.Type.toLowerCase().includes(searchText) ||
        item.Size.toLowerCase().includes(searchText) ||
        item.CompoundName.toLowerCase().includes(searchText) ||
        item.RatingName.toLowerCase().includes(searchText) ||
        item.CurrentHours.toString().includes(searchText) ||
        item.Miles.toString().includes(searchText) ||
        item.TreadDepth32.toString().includes(searchText) ||
        item.WheelPosition.toLowerCase().includes(searchText) ||
        item.Location.toLowerCase().includes(searchText) ||
        item.Disposition.toLowerCase().includes(searchText) ||
        item.PercentageWorn.toString().includes(searchText.replace("%", ""))
      );
    });
  }

  setFilterOptionsData(filterOptions, rowsData) {
    let filterInitCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    let filterModifiedCategoryData = JSON.parse(JSON.stringify(filterOptions.categoryData));
    // adds the categories to filterInitCategoryData
    rowsData.forEach((rowData) => {
      filterOptions.categories.forEach((category, _index) => {
        if (!filterInitCategoryData[category.value].includes(rowData[category.value])) {
          filterInitCategoryData[category.value].push(rowData[category.value].toString());
        }
      });
    });
    // modiifies the categories as nneded by the filter UI and stores in filterModifiedCategoryData
    filterOptions.categories.forEach((category) => {
      filterInitCategoryData[category.value].forEach((data) => {
        filterModifiedCategoryData[category.value].push({
          category: category.value,
          value: data
        });
      });
    });
    // stores the data in original filterOptions
    filterOptions.categoryData = filterModifiedCategoryData;
    return filterOptions;
  }

  filterTable(appliedFilter, tableData) {
    let categoryKeys = Object.keys(appliedFilter);
    let filteredTable = tableData.filter((rowData) => {
      return categoryKeys.every((categoryKey) => {
        if (!appliedFilter[categoryKey].length) return true;
        if (appliedFilter[categoryKey].includes(rowData[categoryKey].toString())) return rowData;
        return false;
      });
    });
    return filteredTable;
  }

  setData(data, unitPreference) {
    // TABLE ROWS DATA
    const tireList = data.model;
    /** NOTE : the keys must match the headCells value to be able to SORT */
    tireList.forEach((tire, tireIndex) => {
      this.manageTireListData.push({
        Key: tire.tireId ? tire.tireId : tireIndex,
        SerialNo: tire.tireSerialNo ? tire.tireSerialNo.toUpperCase() : "",  // as requested by Jez
        Brand: tire.brandNo ? tire.brandNo : "",
        RimSerialNo: tire.rimSerialNo ? tire.rimSerialNo : "",
        VehicleId: tire.VehicleName ? tire.VehicleName : 0,
        WheelPosition: tire.wheelPosition ? tire.wheelPosition : "",
        VehicleNumber: tire.vehicleNumber ? tire.vehicleNumber : "",
        Location: tire.locationName ? tire.locationName : "",
        Disposition: tire.dispositionName ? tire.dispositionName : "",
        Status: tire.tireStatusName ? tire.tireStatusName : "",
        Make: tire.manufacturerName ? tire.manufacturerName : "",
        Type: tire.typeName ? tire.typeName : "",
        Size: tire.sizeName ? tire.sizeName : "",
        CompoundName: tire.compoundName ? tire.compoundName : "",
        RatingName: tire.ratingName ? tire.ratingName : "",
        EvaluationNo: tire.evaluationNo ? tire.evaluationNo : "",
        LotNo: tire.lotNo ? tire.lotNo : "",
        CurrentHours: tire.currentHours ? tire.currentHours : 0,
        Kilometers: tire.currentMiles ? tire.currentMiles : 0,
        Miles: tire.currentMiles ? tire.currentMiles : 0,
        TreadDepth32: tire.currentTreadDepth ? tire.currentTreadDepth : 0,
        TreadDepthmm: tire.currentTreadDepth ? tire.currentTreadDepth : 0,
        PercentageWorn: tire.percentageWorn ? tire.percentageWorn : 0,
        Actions: "",
        LastRead: tire.lastRead ? this.commonUtils.formatISODate(tire.lastRead) : ""
      });
    });
    // TABLE HEADER DATA
    const columnDefinitions = ColumnDefinitions.ManageTire;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });
    let selectedColShow = this.initDisplayColumnData(this.headCells, unitPreference);

    return {
      rowsData: this.manageTireListData,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.manageVehicleListData),
      selectedColShow: selectedColShow
    };
  }

  setFilteredData(tireList, dispositionId, unitPreference) {
    // TABLE ROWS DATA
    // const locationList = data.model.locationList;
    // locationList.forEach((location, index) =>
    /** NOTE : the keys must match the headCells value to be able to SORT */
    const data = [];
    tireList.forEach((tire, tireIndex) => {
      if (
        tire.dispositionId === dispositionId ||
        dispositionId === null
        // || (dispositionId === 8 && (tire.dispositionId === 3 || tire.dispositionId === 2)) ||
      ) {
        data.push({
          Key: tire.tireId ? tire.tireId : tireIndex,
          SerialNo: tire.tireSerialNo ? tire.tireSerialNo.toUpperCase() : "",
          Brand: tire.brandNo ? tire.brandNo : "",
          RimSerialNo: tire.rimSerialNo ? tire.rimSerialNo : "",
          VehicleId: tire.vehicleId ? tire.vehicleId : 0,
          VehicleNumber: tire.vehicleNumber ? tire.vehicleNumber : "",
          WheelPosition: tire.wheelPosition ? tire.wheelPosition : "",
          Location: tire.locationName ? tire.locationName : "",
          Disposition: tire.dispositionName ? tire.dispositionName : "",
          Status: tire.tireStatusName ? tire.tireStatusName : "",
          Make: tire.manufacturerName ? tire.manufacturerName : "",
          Type: tire.typeName ? tire.typeName : "",
          Size: tire.sizeName ? tire.sizeName : "",
          CompoundName: tire.compoundName ? tire.compoundName : "",
          RatingName: tire.ratingName ? tire.ratingName : "",
          EvaluationNo: tire.evaluationNo ? tire.evaluationNo : "",
          LotNo: tire.lotNo ? tire.lotNo : "",
          CurrentHours: tire.currentHours ? tire.currentHours : 0,
          Kilometers: tire.currentMiles ? tire.currentMiles : 0,
          Miles: tire.currentMiles ? tire.currentMiles : 0,
          TreadDepth32: tire.currentTreadDepth ? tire.currentTreadDepth : 0,
          TreadDepthmm: tire.currentTreadDepth ? tire.currentTreadDepth : 0,
          PercentageWorn: tire.percentageWorn ? tire.percentageWorn : 0,
          Actions: "",
          LastRead: tire.lastRead ? this.commonUtils.formatISODate(tire.lastRead) : ""
        });
      }
    });
    // });
    // TABLE HEADER DATA
    this.headCells = [];
    const columnDefinitions = ColumnDefinitions.ManageTire;
    columnDefinitions.forEach((item, index) => {
      let colDefnString = item.text.split(".");
      let masterKey = colDefnString[0];
      let key = colDefnString[1];
      let labelName = this.globalizationMapping[masterKey][key];
      let labelId = item.fieldName;

      this.headCells.push({
        key: labelId,
        keyIndex: index,
        value: labelId,
        label: labelName,
        numeric: item.dataType === "number" || item.dataType === "currency" || item.dataType === "percentagedecimal" ? true : false
      });
    });
    let selectedColShow = this.initDisplayColumnData(this.headCells, unitPreference, dispositionId || "All");
    return {
      rowsData: data,
      headCells: this.headCells,
      //avgValues: this.avgValues(this.manageVehicleListData),
      selectedColShow: selectedColShow
    };
  }

  getFilteredDataByLocation(tireList, selectedLocations, dispositionId) {
    let rowsData = [];
    tireList.forEach((tire, tireIndex) => {
      if (
        (tire.dispositionId === dispositionId || dispositionId === null) && //(dispositionId === 8 && (tire.dispositionId === 3 || tire.dispositionId === 2))
        selectedLocations.indexOf(tire.locationId) > -1
      ) {
        rowsData.push({
          Key: tire.tireId ? tire.tireId : tireIndex,
          SerialNo: tire.tireSerialNo ? tire.tireSerialNo.toUpperCase() : "",
          Brand: tire.brandNo ? tire.brandNo : "",
          RimSerialNo: tire.rimSerialNo ? tire.rimSerialNo : "",
          VehicleId: tire.vehicleId ? tire.vehicleId : 0,
          VehicleNumber: tire.vehicleNumber ? tire.vehicleNumber : "",
          WheelPosition: tire.wheelPosition ? tire.wheelPosition : "",
          Location: tire.locationName ? tire.locationName : "",
          Disposition: tire.dispositionName ? tire.dispositionName : "",
          Status: tire.tireStatusName ? tire.tireStatusName : "",
          Make: tire.manufacturerName ? tire.manufacturerName : "",
          Type: tire.typeName ? tire.typeName : "",
          Size: tire.sizeName ? tire.sizeName : "",
          CompoundName: tire.compoundName ? tire.compoundName : "",
          RatingName: tire.ratingName ? tire.ratingName : "",
          EvaluationNo: tire.evaluationNo ? tire.evaluationNo : "",
          LotNo: tire.lotNo ? tire.lotNo : "",
          CurrentHours: tire.currentHours ? tire.currentHours : 0,
          Kilometers: tire.currentMiles ? tire.currentMiles : 0,
          Miles: tire.currentMiles ? tire.currentMiles : 0,
          TreadDepth32: tire.currentTreadDepth ? tire.currentTreadDepth : 0,
          TreadDepthmm: tire.currentTreadDepth ? tire.currentTreadDepth : 0,
          PercentageWorn: tire.percentageWorn ? tire.percentageWorn : 0,
          Actions: "",
          LastRead: tire.lastRead ? this.commonUtils.formatISODate(tire.lastRead) : ""
        });
      }
    });

    return rowsData;
  }
}
export default ManageTireListSetter;
