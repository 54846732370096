import React,{useState,useEffect} from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@material-ui/core/Grid";
import CommonUtils from "../../../../../../../services/utils";
import NoDataFound from "../../../../../../../common-components/no-data-found/NoDataFound"
import Globalization from "../../../../../../../globalization/index"
// eslint-disable-next-line no-unused-vars


const ScrapHoursByTireTypeGraph= ({ scrapHoursByTireTypeGraphData }) => {
  const [chartInfoData, setChartInfoData] = useState({})
  const commonUtils = new CommonUtils()
  const borderColor = commonUtils.borderColor;
  const options = commonUtils.barOptions;
  const globalizationMapping = new Globalization()
  const globalizationHeaders = globalizationMapping.GRAPH;
  options.scales.xAxes[0].scaleLabel.labelString = globalizationHeaders["scrap-hours-type-x"]
  options.scales.yAxes[0].scaleLabel.labelString = globalizationHeaders["scrap-hours-type-y"]
  options.title.text = globalizationHeaders["scrap-hours-type-title"]

  const chart = () => {
   
      if (scrapHoursByTireTypeGraphData.chartlabels.length > 0) {
        const colorCodes = commonUtils.getRandomColorHex();
       
        setChartInfoData({
          labels: scrapHoursByTireTypeGraphData.chartlabels,
          datasets: [
            {
              backgroundColor: colorCodes,
              fill: true,
              hoverBorderColor: borderColor,
              hoverBorderWidth: "3",
              data: scrapHoursByTireTypeGraphData.chartData[0].data
            }
          ]
        });
      } else {
        setChartInfoData({});
      }
  };
  useEffect(() => {
    chart();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scrapHoursByTireTypeGraphData])

  return (
    <div className="percentage-worn-graph-container">
      <Grid item={true} md={12} sm={12}>
        {Object.keys(chartInfoData).length > 0 && (
          <Bar data={chartInfoData} height={120} options={options} />
        )}
      </Grid>
    {Object.keys(chartInfoData).length === 0 && 
      <div className="no-data-container">
        <NoDataFound noDataType="graph"></NoDataFound>
      </div>}
  </div>
  );
};

export default ScrapHoursByTireTypeGraph;
