import React, { useEffect, useState } from "react";
import "./RecordDetails.css";
import { Grid } from '@material-ui/core';
import Spinner from "../../../../../../../common-components/spinner/spinner";
import Globalization from "../../../../../../../globalization";


const RecordDetails = ({ eachTireData }) => {
    const [eachTireDataRecord, setEachTireDataRecord] = useState(eachTireData);
    const [showSpinner, setShowSpinner] = useState(true);
    const globalizationMapping = new Globalization();

    useEffect(() => {
        setEachTireDataRecord(eachTireData);
        setShowSpinner(false);
    }, [eachTireData]);
    let recordNo = eachTireDataRecord.Key;
    return (
        <div className="removed-tire-record-container">
            {showSpinner && <Spinner />}
            {!showSpinner && (
                <Grid className="removed-tire-record-detail" container spacing={3}>
                    <Grid item xs={12} md className="record-no">
                        <div className="parent-account-container">
                            <div className="account-info-text">{globalizationMapping.REPORTCOMMONWORDS.RecordNo}
                            </div>
                            <div className="account-text-record-details">{++recordNo}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md>
                        <div className="parent-account-container">
                            <div className="account-info-text">{globalizationMapping.REPORTTABLE.ACCOUNTNAME}
                            </div>
                            <div className="account-text-record-details">{eachTireDataRecord.ParentAccount}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md>
                        <div className="parent-account-container">
                            <div className="account-info-text">{globalizationMapping.REPORTTABLE.Location}
                            </div>
                            <div className="account-text-record-details">{eachTireDataRecord.Location}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md>
                        <div className="parent-account-container">
                            <div className="account-info-text">{globalizationMapping.REPORTTABLE.INSPECTEDBY}
                            </div>
                            <div className="account-text-record-details">{eachTireDataRecord.InspectedBy}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md>
                        <div className="parent-account-container">
                            <div className="account-info-text">{globalizationMapping.REPORTCOMMONWORDS.InspectionIDRecordID}
                            </div>
                            <div className="account-text-record-details">{eachTireDataRecord.InspectionId}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default RecordDetails;