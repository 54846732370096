import React, { useState, useEffect } from "react";
import "../../../../../../common-styles/SurveyTemplateNavigation.css";
import Breadcrumbs from "../../../../../../common-components/breadcrumbs/breadcrumbs";
import { Card, CardContent } from "@material-ui/core";
import Globalization from "../../../../../../globalization/index";
import { useHistory } from "react-router-dom";
import { ReportsRoutePaths } from "../../../../../../routing/ReportsRoutePaths";

const ThreeAxleNavigation = () => {
  const globalizationMapping = new Globalization();
  const threeAxleGlobalTexts =
    globalizationMapping.SURVEYTEMPLATES.FLEETDATACOLLECTION;
  const threeAxleNav = [
    threeAxleGlobalTexts.ThreeAxelsSixTires,
    threeAxleGlobalTexts.ThreeAxelsTenTires,
  ];

  let breadcrumbs = [
    {
      path: "home",
      name: globalizationMapping.HEADER.HOME,
    },
    {
      path: "reportsDashboard",
      name: globalizationMapping.REPORTSNAV.REPORTNAVHEADERS.REPORTSDASHBOARD,
    },
    {
      path: "",
      name: "Survey Templates - " + threeAxleGlobalTexts.FleetDataCollection,
    },
  ];

  const getRouteName = (currentPathName) => {
    let pathSelected = ReportsRoutePaths.find(
      (route) => route.path === currentPathName
    );
    return pathSelected;
  };

  const getRoutePath = (threeAxleSelected) => {
    let pathSelected = ReportsRoutePaths.find(
      (route) => route.name === threeAxleSelected
    );
    return pathSelected;
  };

  const history = useHistory();
  let currentPathName = history.location.pathname.split("/")[1];
  let currentRouteSelected = getRouteName(currentPathName);

  const [selectedRoute, setSelectedRoute] = useState(currentRouteSelected);

  // on click of tiles of navigating accross the templates
  const threeAxleNavigation = (threeAxleSelected) => {
    let routePath = getRoutePath(threeAxleSelected);
    setSelectedRoute(routePath);
  };

  useEffect(() => {
    history.push(selectedRoute.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  return (
    <div>
      <div className="axle-main-container">
        <div className="axle-upper-deck">
          <div className="background-img-container">
            <div className="background-map-image">
              <div className="dashboard-header">
                <div>
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                  <div className="axle-title">
                    {threeAxleGlobalTexts.FleetDataCollection} -{" "}
                    {threeAxleGlobalTexts.ThreeAxel}
                  </div>
                </div>
              </div>
              <div className="axle-nav-container">
                {threeAxleNav.map((item) => {
                  return (
                    <div
                      key={item}
                      className="axle-nav-card-container"
                      onClick={() => threeAxleNavigation(item)}
                    >
                      <Card
                        className={
                          selectedRoute.name === item
                            ? "active-axle-nav-card"
                            : "axle-nav-card"
                        }
                      >
                        <CardContent>
                          <span
                            className={
                              selectedRoute.name === item
                                ? "active-axle-nav-card-text"
                                : "axle-nav-card-text"
                            }
                          >
                            {item}
                          </span>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeAxleNavigation;
